import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { CreateCollaboratorInput } from '../domain/dtos/create-collaborator-input'
import { PermissionResource } from '../domain/dtos/permissions-resources-enum'
import { AccountUserNotFound } from '../domain/errors/account-user-not-found'
import { GeneralError } from '../domain/errors/general'
import { Envs } from '../envs'

enum ErrorCode {
  ACCOUNT_USER_NOT_FOUND = 'bff-web-account::user-not-found',
}
export class CreateCollaborator {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(input: CreateCollaboratorInput): Promise<null> {
    const response = await this.httpClient.request({
      method: HttpMethod.POST,
      url: `${Envs.BFF_WEB_URL}/user/roleplay/collaborators`,
      body: this.makeBody(input),
    })

    if (response.statusCode === HttpStatusCode.CREATED) {
      return null
    }

    switch (response.data.errorCode) {
      case ErrorCode.ACCOUNT_USER_NOT_FOUND:
        throw new AccountUserNotFound()
      default:
        throw new GeneralError()
    }
  }

  private makeBody({ email, permissions }: CreateCollaboratorInput) {
    return {
      email,
      permissions: Object.values(PermissionResource).map((resource) => {
        return { resource, granted: permissions.includes(resource), action: 'all' }
      }),
    }
  }
}
