import { Tabs, TabsContent, TabsList, TabsTrigger } from '@hub-la/shadcn'
import { memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useGetOffers } from '../../hooks/use-get-offers'
import { Home } from '../home/home'
import { SubscriptionsTable } from '../subscriptions-table'

export const DashboardSubscriptions = memo(() => {
  const [tab, setTab] = useState('home')
  const history = useHistory()
  const { t } = useTranslation()

  const { data: offers = [], isFetching, isLoading } = useGetOffers()

  const onTabChange = (tab: string) => {
    history.push(history.location.pathname + '?tab=' + tab + '#subscriptions')
    setTab(tab)
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const tab = urlParams.get('tab')
    if (tab) {
      setTab(tab)
    }
  }, [])

  return (
    <div className="flex flex-col gap-2">
      <h1 className="text-3xl leading-tight font-semibold">{t('title')}</h1>
      <Tabs value={tab} onValueChange={onTabChange}>
        <div className="w-full overflow-x-auto overflow-y-hidden border-b">
          <TabsList variant="underlined" className="border-none">
            <TabsTrigger value="home" variant="underlined">
              {t('tabs.home')}
            </TabsTrigger>

            <TabsTrigger value="subscriptions" variant="underlined">
              {t('tabs.subscriptions')}
            </TabsTrigger>
          </TabsList>
        </div>
        <TabsContent className="mt-4" value="home">
          <Home offers={offers} isFetching={isFetching} isLoading={isLoading} />
        </TabsContent>
        <TabsContent className="mt-4" value="subscriptions">
          <SubscriptionsTable offers={offers} isFetching={isFetching} isLoading={isLoading} />
        </TabsContent>
      </Tabs>
    </div>
  )
})
