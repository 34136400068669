import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useQuery } from '@tanstack/react-query'
import { GetHighlightInput } from '../../../../domain/dtos/member-area/settings/get-highlight-input'
import { GetHighlight } from '../../../../usecases/member-area/settings/get-highlight'

export const useGetHighlight = (input: GetHighlightInput) => {
  const container = useContainer()
  return useQuery(
    ['get-highlight', input.productId],
    () => new GetHighlight(container.get(HttpClient)).execute(input),
    {
      enabled: true,
      cacheTime: Infinity,
      refetchOnWindowFocus: false,
      retry: 3,
      retryDelay: 1000,
      onSuccess: async (data) => {
        return data
      },
    },
  )
}
