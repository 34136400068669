import { Button, Card, useToast } from '@hub-la/shadcn'
import { useQueryClient } from '@tanstack/react-query'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Invite } from '../../../../domain/dtos/get-pending-invites-output'
import { QueryKey } from '../../../../domain/enums/query-key'
import { useGetPendingInvites } from '../../../hooks/use-get-pending-invites'
import { usePostAcceptInvite } from '../../../hooks/use-post-accept-invite'
import { usePostRejectInvite } from '../../../hooks/use-post-reject-invite'
import { useGetUser } from '@hub-la/fe-get-user'

const InviteItem: React.FC<{
  productName: string
  productImage: string
  days: number | null
  onAccept: () => void
  onReject: () => void
  isLoading?: boolean
}> = ({ productName, productImage, days, onAccept, onReject, isLoading }) => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col md:flex-row items-center justify-between space-x-4 pb-4 last:pb-0 border-b border-zinc-700 last:border-b-0">
      <div className="flex md:items-center space-x-4">
        <img src={productImage} alt={productName} className="w-12 h-8 mt-1 md:mt-0 rounded-md object-cover" />
        <div className="flex-grow">
          <p className="text-sm text-primary/80">
            {t('pendingInvites.inviteMessageStart')} <span className="font-semibold text-primary">{productName}</span>{' '}
            {t('pendingInvites.inviteMessageMiddle')}
            <span className="font-semibold text-primary">
              {days ? t('pendingInvites.days', { days }) : t('pendingInvites.unlimited')}
            </span>{' '}
            {t('pendingInvites.inviteMessageEnd')}
          </p>
        </div>
      </div>
      <div className="flex space-x-2 justify-end md:justify-start w-full md:w-auto">
        <Button variant="default" size="sm" className="h-8" onClick={onAccept} disabled={isLoading}>
          {t('pendingInvites.accept')}
        </Button>
        <Button variant="ghost" size="sm" className="h-8" onClick={onReject} disabled={isLoading}>
          {t('pendingInvites.reject')}
        </Button>
      </div>
    </div>
  )
}

export const PendingInvites: React.FC = () => {
  const { t } = useTranslation()
  const { data } = useGetPendingInvites()
  const history = useHistory()
  const queryClient = useQueryClient()
  const { toast } = useToast()
  const { data: currentUser } = useGetUser()

  const {
    mutate: acceptInvite,
    isSuccess,
    isError: isAcceptError,
    error,
    variables: acceptVariables,
    isLoading: isAcceptLoading,
  } = usePostAcceptInvite({
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKey.listMemberGroups])
    },
  })

  const { mutate: rejectInvite, isLoading: isRejectLoading, variables: rejectInviteId } = usePostRejectInvite()

  const handleAccept = (needRequestUserInfo: boolean, invite: Invite) => {
    if (needRequestUserInfo) {
      history.push(`/invites/${invite?.id}/accept`)
      return
    }

    acceptInvite({
      inviteId: invite?.id,
      email: currentUser?.email ?? '',
      fullName: currentUser?.name ?? '',
      phoneNumber: currentUser?.phoneNumber ?? '',
    })
  }

  useEffect(() => {
    if (isSuccess) {
      toast({
        title: t('pendingInvites.successAccept'),
        duration: 3000,
      })
    }
    if (isAcceptError) {
      toast({
        title: error?.message ?? '',
        variant: 'destructive',
        duration: 3000,
      })
    }
  }, [isSuccess, isAcceptError, error])

  if ((data?.items ?? []).length === 0) {
    return null
  }

  return (
    <Card className="p-4 bg-secondary">
      <div className="space-y-4">
        {data?.items.map((invite) => (
          <InviteItem
            key={invite.id}
            productName={invite?.details?.product?.name}
            productImage={invite?.details?.product?.picture || '/default-product-image.jpg'}
            days={invite?.details?.days}
            isLoading={(acceptVariables?.inviteId === invite.id || rejectInviteId === invite.id) && isAcceptLoading}
            onAccept={() => handleAccept(data?.needRequestUserInfo, invite)}
            onReject={() => rejectInvite(invite.id)}
          />
        ))}
      </div>
    </Card>
  )
}
