import { UploadStatus } from '@hub-la/fe-asset'
import { CustomCover, SectionResponse } from '@hub-la/fe-post'
import { Button, Dialog, DialogContent, useToast } from '@hub-la/shadcn'
import { FormikProvider, useFormik } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { UpdateSectionRequest } from '../../../domain/dtos'
import { useSaveSection } from '../../hooks/use-save-section'

type Props = {
  open: boolean
  onClose: () => void
  section: SectionResponse
  productId: string
}

export const SectionEditorCover: React.FC<Props> = ({ onClose, open, section, productId }) => {
  const { toast } = useToast()
  const { t } = useTranslation()
  const { mutateAsync: saveSection, isLoading } = useSaveSection()
  const formik = useFormik({
    initialValues: {
      customCover: section.cover,
      customCoverStatus: UploadStatus.LOADED,
      customCoverProgress: 0,
    },
    enableReinitialize: true,
    onSubmit: async ({ customCover }) => {
      if (customCover === undefined) {
        toast({
          description: t('customCoverUpload.section.error'),
          variant: 'destructive',
        })
        return
      }

      const payload: UpdateSectionRequest = {
        name: section.name,
        coverAssetId: customCover.id,
        productId,
      }

      await saveSection({
        payload,
        id: section.id,
      })

      onClose()
    },
  })

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent>
        <FormikProvider value={formik}>
          <div className="flex flex-col">
            <CustomCover
              title={t('customCoverUpload.section.title')}
              description={t('customCoverUpload.section.description')}
              showSnackbar={false}
              data-testid="custom-cover"
            />

            <Button
              className="mt-4 w-full"
              loading={isLoading}
              onClick={formik.submitForm}
              disabled={!formik?.values?.customCover}
            >
              {t('button.save')}
            </Button>
          </div>
        </FormikProvider>
      </DialogContent>
    </Dialog>
  )
}
