import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject, injectable } from 'inversify'
import { Envs } from '../envs'

@injectable()
export class DuplicatePost {
  public constructor(@inject(HttpClient) private readonly httpClient: HttpClient) {}

  public async execute(payload: { sectionId: string; productId: string; postId: string }): Promise<void> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .request({
          url: `${Envs.BFF_MEMBERS_AREA_URL}/hub/posts/clone-and-move`,
          body: { productId: payload.productId, sectionId: payload.sectionId, postId: payload.postId },
          headers: {
            accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: HttpMethod.POST,
        })
        .then((response) => {
          if (!response.data || ![HttpStatusCode.OK, HttpStatusCode.CREATED].includes(response.statusCode)) {
            return reject(`Ocorreu um erro ao copiar o post`) //@TODO error code of bff
          }

          return resolve()
        })
        .catch(() => {
          return reject(`Ocorreu um erro ao copiar o post`)
        })
    })
  }
}
