export class Currency {
  private locale: string
  private currency: string

  constructor(locale = 'pt-BR', currency = 'BRL') {
    this.locale = locale
    this.currency = currency
  }

  public format(value: number): string {
    return new Intl.NumberFormat(this.locale, {
      style: 'currency',
      currency: this.currency,
    }).format(value)
  }
}
