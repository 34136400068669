import * as Yup from 'yup'
import { BRAZILIAN_BANKS } from '../../domain/constants/brazilian-banks'
import { BankAccountType } from '../../domain/enums/bank-account'

/**
 * Validate if the value is only numbers or a letter X or a letter P
 */
export const checkNumberRules = function (value) {
  const regex = /^(?:[0-9]+|[PX])$/i
  return regex.test(value ?? '')
}

export const bankAccountSchema = Yup.object().shape({
  bankAccount: Yup.object().shape({
    bankNumber: Yup.string()
      .required('errors.required')
      .test({
        message: 'errors.invalidBank',
        test: function (value) {
          return BRAZILIAN_BANKS.some((bank) => bank.code === value)
        },
      }),
    agencyNumber: Yup.string().required('errors.required'),
    agencyCheckNumber: Yup.string()
      .notRequired()
      .test({
        message: 'errors.invalidAgencyCheckNumber',
        test: (value) => (value ? checkNumberRules(value) : true),
      }),
    accountNumber: Yup.string().required('errors.required'),
    accountCheckNumber: Yup.string().required('errors.required').test({
      message: 'errors.invalidAccountCheckNumber',
      test: checkNumberRules,
    }),
    accountType: Yup.number()
      .required('errors.required')
      .test({
        message: 'errors.invalidAccountType',
        test: function (value) {
          return Object.values(BankAccountType).includes(value as BankAccountType)
        },
      }),
  }),
})
