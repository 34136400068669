import { Skeleton, TableCell, TableRow } from '@hub-la/shadcn'
import { times } from 'lodash'

export const TableLoading = ({ cols }: { cols: number }) => {
  return (
    <TableRow>
      {times(cols, (idx) => (
        <TableCell key={idx}>
          <Skeleton
            className={`h-4 w-[90px] ${idx === cols - 1 || idx === cols - 2 ? 'ml-auto' : ''} ${
              idx === cols - 1 ? 'pr-3' : ''
            }`}
          />
        </TableCell>
      ))}
    </TableRow>
  )
}
