import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { QueryKey, SectionList } from '@hub-la/fe-post'
import { isNil } from 'lodash'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ReorderSectionsInput } from '../../domain/dtos/reorder-sections-input'
import { SaveSection } from '../../usecases/save-section'

const initialData = {
  items: [],
  page: 0,
  pageSize: 0,
  total: 0,
}

export const useReorderSections = () => {
  const container = useContainer()
  const queryClient = useQueryClient()

  return useMutation(
    [QueryKey.reorderSections],
    (input: ReorderSectionsInput) => new SaveSection(container.get(HttpClient)).execute(input, input.id),
    {
      onMutate: (input: ReorderSectionsInput) => {
        queryClient.setQueryData<SectionList>([QueryKey.listSections], (sections): SectionList => {
          if (isNil(sections)) {
            return initialData
          }

          const item = sections.items.find((section) => section.id === input.id)

          if (isNil(item)) {
            return initialData
          }

          const newItems = Array.from(sections.items)
          const [removed] = newItems.splice(input.source, 1)
          newItems.splice(input.destination, 0, { ...removed, order: input.destination + 1 })

          return { items: newItems, page: sections.page, pageSize: sections.pageSize, total: sections.total }
        })
      },
    },
  )
}
