import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { WithdrawalRequestDto } from '../domain/dtos/withdrawal-request.dto'
import { Envs } from '../envs'

export class SubmitWithdrawalRequest {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(input: WithdrawalRequestDto): Promise<void> {
    const { amountInCents } = input

    const response = await this.httpClient.request({
      method: HttpMethod.POST,
      url: `${Envs.BFF_WEB_URL}/financial-statement/withdrawal`,
      body: { amountInCents },
    })

    switch (response.statusCode) {
      case HttpStatusCode.OK:
        return response.data
      case HttpStatusCode.CREATED:
        return response.data
      case HttpStatusCode.BAD_REQUEST:
        throw new Error('errors.invalidArgument')
      default:
        throw new Error('errors.general')
    }
  }
}
