import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Badge,
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Input,
  Label,
  ScrollArea,
  Separator,
  Skeleton,
  useToast,
} from '@hub-la/shadcn'
import { ChevronRight } from 'lucide-react'
import * as React from 'react'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import defaultImage from '../../assets/images/post-thumbnail-background-fallback.png'
import { useDuplicateSection } from '../../hooks/use-duplicate-section'
import { useGetProducts } from '../../hooks/use-get-products'

type Props = {
  open: boolean
  onClose: () => void
  sectionId: string
}

export const DuplicateModal: React.FC<Props> = ({ open, onClose, sectionId }) => {
  const params = useParams<{ productId: string }>()
  const [productId, setProductId] = useState<string>()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const { t } = useTranslation()
  const { data = [], isFetching } = useGetProducts()
  const { toast } = useToast()

  const { mutateAsync, isLoading, isError } = useDuplicateSection()

  const handleClose = () => {
    setProductId(undefined)
    setSearchTerm('')
    onClose()
  }

  const onConfirm = () => {
    if (productId) {
      mutateAsync({ sectionId, productId }).finally(() => {
        toast({
          title: isError ? 'Error' : 'Success',
          description: t(isError ? 'duplicateModal.error' : 'duplicateModal.success'),
          variant: isError ? 'destructive' : 'default',
        })
        handleClose()
      })
    }
  }

  const filteredData = data.filter(
    (product) => product.id !== params.productId && product.name.toLowerCase().includes(searchTerm.toLowerCase()),
  )

  const selectedProduct = useMemo(() => {
    return data.find((product) => product.id === productId)
  }, [data, productId])

  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <DialogContent className="max-h-[80vh] sm:max-w-lg overflow-y-auto">
        <DialogHeader>
          <div className="flex flex-row items-center mt-2">
            <Badge variant="secondary">{t('duplicateModal.title')}</Badge>
            {selectedProduct && (
              <>
                <ChevronRight className="w-4 h-4 mx-2 text-muted-foreground" />
                <div className="flex flex-row mr-auto items-center">
                  <Avatar className="w-4 h-4 mr-2 rounded-sm">
                    <AvatarImage
                      src={selectedProduct.picture ?? defaultImage}
                      alt={selectedProduct.name}
                      className="object-cover"
                    />
                    <AvatarFallback>{selectedProduct.name ? selectedProduct.name.charAt(0) : 'P'}</AvatarFallback>
                  </Avatar>
                  <Label>{selectedProduct.name}</Label>
                </div>
              </>
            )}
          </div>
        </DialogHeader>

        <div>
          <Input
            placeholder={t('duplicateModal.searchPlaceholder')}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="border-none focus-visible:outline-none focus-visible:ring-0 text-base"
          />
          <Separator />
        </div>

        <ScrollArea className="max-h-80">
          <div className="px-2">
            {isFetching ? (
              <div className="flex items-center space-x-4 rounded p-2">
                <Skeleton className="h-12 w-12 rounded" />
                <Skeleton className="h-4 w-32" />
              </div>
            ) : (
              filteredData.map((product) => (
                <div
                  key={product.id}
                  className={`flex items-center space-x-4 rounded p-2 cursor-pointer ${
                    product.id === productId ? 'bg-secondary' : 'hover:bg-secondary'
                  }`}
                  onClick={() => setProductId(product.id)}
                >
                  <Avatar className="w-12 h-9 rounded-lg">
                    <AvatarImage src={product.picture ?? defaultImage} alt={product.name} className="object-cover" />
                    <AvatarFallback>{product.name ? product.name.charAt(0) : 'P'}</AvatarFallback>
                  </Avatar>

                  <span>{product.name}</span>
                </div>
              ))
            )}
          </div>
        </ScrollArea>

        <DialogFooter className="flex gap-2 justify-end">
          <Button variant="outline" onClick={handleClose}>
            {t('duplicateModal.cancel')}
          </Button>

          <Button disabled={!productId} loading={isLoading} onClick={onConfirm}>
            {t('duplicateModal.confirm')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
