export type ChangeStatusInput = {
  productId: string
  offerId: string
  status: OfferStepStatus
}

export enum OfferStepStatus {
  IS_DRAFT = 'isDraft',
  IS_PUBLISHED = 'isPublished',
  IS_SELLING = 'isSelling',
  IS_WAITLISTED = 'isWaitlisted',
  IS_NOT_ENABLED = 'isNotEnabled',
  IS_NOT_SELLING = 'isNotSelling',
  IS_UNAVAILABLE = 'isUnavailable',
  IS_DELETED = 'isDeleted',
}
