import { Button } from '@hub-la/shadcn'
import { RefreshCw } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { IntegrationProvider } from '../../domain/dtos/integration-provider'
import { Offer } from '../../domain/dtos/offer'
import { SelectDateRangeCalendar } from './select-date-range-calendar'
import { SelectEvents } from './select-events'
import { SelectOffers } from './select-offers'

interface FiltersProps {
  filters: any
  setFilters: (filters: any) => void
  offers: Offer[]
  isLoading: boolean
  provider: IntegrationProvider
  refetch: () => void
}

export const Filters = ({ provider, setFilters, filters, offers, isLoading, refetch }: FiltersProps) => {
  const { t } = useTranslation()
  return (
    <div className="flex md:flex-row flex-col gap-3 justify-between">
      <div className="flex md:flex-row flex-col gap-3">
        {/* <div className="relative h-10">
          <Search className="absolute w-4 h-4 right-3 top-1/2 transform -translate-y-1/2 text-gray-500 z-10" />
          <Input
            type="text"
            placeholder="Buscar..."
            className="w-[250px]"
            value={filters.search}
            onChange={(e) => setFilters({ ...filters, search: e.target.value })}
          />
        </div> */}

        <SelectOffers
          offers={offers.filter((offer) => offer.id !== 'all')}
          value={filters.offerIds}
          onChange={(ids) => setFilters((prevState) => ({ ...prevState, offerIds: ids, page: 1 }))}
        />

        <SelectDateRangeCalendar filters={filters} setFilters={setFilters} />

        <SelectEvents
          value={filters.events}
          onChange={(value) => setFilters((prevState) => ({ ...prevState, events: value, page: 1 }))}
          provider={provider}
        />
      </div>
      <Button variant="outline" disabled={isLoading} onClick={() => refetch()}>
        <RefreshCw className="mr-2 h-4 w-4" />
        {t('buttons.syncItems')}
      </Button>
    </div>
  )
}
