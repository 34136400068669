import { memo } from 'react'
import { AffiliatesTable } from './affiliates-table'

export const DashboardAffiliates = memo(() => {
  return (
    <div>
      <h1 className="text-3xl font-semibold leading-tight mb-4">Afiliados</h1>

      <AffiliatesTable />
    </div>
  )
})
