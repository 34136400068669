import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation } from '@tanstack/react-query'
import { GetSectionProgress } from '../../usecases/get-section-progress'

export const useGetSectionProgress = () => {
  const container = useContainer()

  const { isSuccess, isError, error, isLoading, data, mutateAsync } = useMutation([], (sectionId?: string) =>
    new GetSectionProgress(container.get(HttpClient)).execute(sectionId),
  )

  return {
    isSuccess,
    isError,
    error,
    isLoading,
    data,
    mutateAsync,
  }
}
