import { container } from '@hub-la/fe-container'
import { UserInvoicesDetailsPage, UserInvoicesProvider } from '@hub-la/fe-user-invoices'
import { withRoleplayRedirect } from '../components/roleplay-redirect'

export const UserInvoicesDetailsInit = withRoleplayRedirect(() => {
  return (
    <UserInvoicesProvider container={container}>
      <UserInvoicesDetailsPage />
    </UserInvoicesProvider>
  )
})
