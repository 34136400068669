import { Badge, Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@hub-la/shadcn'

export const RemainingElements = ({ data = [], children }: { data: unknown[]; children }) => {
  const [firstElement] = data

  const length = data.length
  const amountRemainingElements = `${length - 1}`

  return (
    <div className="flex flex-row items-center">
      {length > 1 ? (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger className="cursor-pointer">
              <Badge variant="secondary" className="ml-1 text-xs" data-testid="remaining-elements">
                {amountRemainingElements}
              </Badge>
            </TooltipTrigger>
            <TooltipContent>
              <div className="flex flex-col gap-1">{data.map((item, index) => children(item))}</div>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      ) : (
        <div className="truncate max-w-40">{children(firstElement)}</div>
      )}
    </div>
  )
}
