import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useToast } from '@hub-la/shadcn'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { QueryKey } from '../../../domain/enums/query-key'
import { DeleteResource } from '../../../usecases/member-area/delete-resource'

export const useDeleteResource = () => {
  const { toast } = useToast()
  const { t } = useTranslation()
  const container = useContainer()
  const queryClient = useQueryClient()

  return useMutation(
    [QueryKey.deleteResource],
    (externalResourceId: string) => new DeleteResource(container.get(HttpClient)).execute(externalResourceId),
    {
      retry: false,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [QueryKey.getResources] })
      },
      onError: (error: Error) => {
        toast({ description: t(error.message), variant: 'destructive' })
      },
    },
  )
}
