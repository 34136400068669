import { ImageUploader } from '@hub-la/fe-asset'
import { useGetProfile } from '@hub-la/fe-get-user'
import { Button, Card, CardContent, CardFooter, CardHeader, CardTitle, Input, Label, Textarea } from '@hub-la/shadcn'
import React, { useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SocialMedia, UserProfile } from '../../../domain/dtos/profile'
import { useSetProfile } from '../../hooks/use-set-profile'

export const Profile: React.FC = () => {
  const { t } = useTranslation()
  const { data: currentProfile, isLoading: isFetching } = useGetProfile()

  const defaultValues = useMemo(() => {
    const values: UserProfile = {
      id: currentProfile?.id ?? '',
      firstName: currentProfile?.firstName ?? '',
      lastName: currentProfile?.lastName ?? '',
      about: currentProfile?.about ?? '',
      username: currentProfile?.username ?? '',
      picture: currentProfile?.picture ?? undefined,
      socialMedia: {
        instagram: currentProfile?.socialMedia?.instagram ?? undefined,
        twitter: currentProfile?.socialMedia?.twitter ?? undefined,
      },
    }

    return values
  }, [currentProfile])

  const {
    control,
    handleSubmit,
    formState: { isDirty },
    reset,
  } = useForm<UserProfile>({ defaultValues })

  useEffect(() => {
    reset(defaultValues)
  }, [currentProfile, defaultValues, reset])

  const { mutate: setProfile, isLoading } = useSetProfile()

  const onSubmit = (formData: UserProfile) => {
    setProfile(formData)
  }

  const socialMedias: (keyof SocialMedia)[] = ['instagram', 'twitter']

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4 my-4">
      <Card className="w-full max-w-2xl">
        <CardHeader>
          <CardTitle>{t('profile.title')}</CardTitle>
        </CardHeader>

        <CardContent className="flex flex-col gap-4">
          <div className="flex flex-col-reverse sm:flex-row gap-4 sm:items-center">
            <div className="flex flex-col flex-grow gap-4">
              <div className="space-y-1.5">
                <Label htmlFor="firstName">{t('profile.firstName')}</Label>

                <Controller
                  name="firstName"
                  control={control}
                  render={({ field }) => <Input id="firstName" disabled={isFetching} {...field} />}
                />
              </div>

              <div className="space-y-1.5">
                <Label htmlFor="lastName">{t('profile.lastName')}</Label>

                <Controller
                  name="lastName"
                  control={control}
                  render={({ field }) => <Input id="lastName" disabled={isFetching} {...field} />}
                />
              </div>
            </div>

            <div>
              <Controller
                name="picture"
                control={control}
                render={({ field }) => (
                  <ImageUploader
                    name="picture"
                    path="user-profile"
                    className="w-48 h-48 sm:max-w-32 sm:max-h-32"
                    value={field.value || ''}
                    onChange={(file) => field.onChange(file?.URL || '')}
                  />
                )}
              />
            </div>
          </div>

          <div className="space-y-1.5">
            <Label htmlFor="username">{t('profile.username')}</Label>

            <Controller
              name="username"
              control={control}
              render={({ field }) => <Input id="username" disabled={isFetching} {...field} />}
            />
          </div>

          <div className="space-y-1.5">
            <Label htmlFor="about">{t('profile.about')}</Label>

            <Controller
              name="about"
              control={control}
              render={({ field }) => <Textarea id="about" disabled={isFetching} {...field} />}
            />
          </div>

          {socialMedias.map((platform, i) => (
            <div key={i} className="flex flex-wrap gap-2">
              <Label htmlFor={platform} className="my-auto max-w-40">
                {t(`profile.social.${platform}`)}
              </Label>

              <Controller
                name={`socialMedia.${platform}`}
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    id={platform}
                    disabled={isFetching}
                    value={field.value || ''}
                    className="min-w-24 flex-1"
                  />
                )}
              />
            </div>
          ))}
        </CardContent>
        <CardFooter>
          {isDirty && (
            <Button type="submit" loading={isLoading} className="sm:ml-auto w-full sm:w-auto">
              {t('save')}
            </Button>
          )}
        </CardFooter>
      </Card>
    </form>
  )
}
