import { useContainer } from "@hub-la/fe-container"
import { HttpClient } from "@hub-la/fe-core-http-client"
import { useQuery } from "@tanstack/react-query"
import { QueryKey } from "../../domain/enums/query-key"
import { GetSmartInstallmentCycle } from "../../usecases/get-smart-installment-cycle"

export function useGetSmartInstallmentCycle(smartInstallmentCycleId: string) {
  const container = useContainer()

  return useQuery(
    [QueryKey.getSmartInstallmentCycle, smartInstallmentCycleId],
    () => new GetSmartInstallmentCycle(container.get(HttpClient)).execute(smartInstallmentCycleId),
    {
      retry: false,
      retryOnMount: false,
      refetchOnWindowFocus: false,
    })
}

