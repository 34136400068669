export enum QueryKey {
  getListCohorts = 'getListCohorts',
  getListOffers = 'getListOffers',
  getListProducts = 'getListProducts',
  putCohort = 'putCohort',
  postCohort = 'postCohort',
  getListGroups = 'getListGroups',
  getCohort = 'getCohort',
  getProduct = 'getProduct',
}
