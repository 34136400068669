import { Alert, AlertDescription, Input, RadioGroup } from '@hub-la/shadcn'
import { LightbulbIcon } from 'lucide-react'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { RedirectType } from '../../../../../domain/enums/redirect-type.enum'
import { OfferSection } from '../../../../components/offer-section'
import { RadioToggleChildren } from '../../../../components/radio-toggle-children'
import { UpsellCustomization } from '../../../../components/upsell/upsell-customization'
import { OfferSchemaValidationType } from '../../../../validations/offer-validation'

export const AfterPurchaseContent: React.FC = () => {
  const { t } = useTranslation()
  const { control } = useFormContext<OfferSchemaValidationType>()

  return (
    <OfferSection title={t('offer.afterPurchaseTab.title')}>
      <Controller
        name="afterPurchase.redirectType"
        control={control}
        render={({ field, fieldState }) => (
          <div className="space-y-4">
            <RadioGroup onValueChange={field.onChange} value={field.value} className="space-y-2">
              <RadioToggleChildren
                name="afterPurchase.redirectType"
                label={t('offer.afterPurchaseTab.userGroups.label')}
                value={RedirectType.USER_GROUPS}
              />
              <RadioToggleChildren
                name="afterPurchase.redirectType"
                label={t('offer.afterPurchaseTab.membersArea.label')}
                value={RedirectType.MEMBERS_AREA}
              />
              <RadioToggleChildren
                name="afterPurchase.redirectType"
                label={t('offer.afterPurchaseTab.externalUrl.label')}
                value={RedirectType.EXTERNAL_URL}
              >
                <div className="space-y-4 mt-4">
                  <Controller
                    name="afterPurchase.redirectUrl"
                    control={control}
                    render={({ field, fieldState }) => (
                      <div className="space-y-2">
                        <Input
                          {...field}
                          value={field.value ?? ''}
                          placeholder={t('offer.afterPurchaseTab.externalUrl.example')}
                          className={fieldState.error ? 'border-red-500' : ''}
                        />
                        {fieldState.error && (
                          <p className="text-sm text-red-500">{t(`fieldErrors.${fieldState.error.type}`)}</p>
                        )}
                      </div>
                    )}
                  />
                  <Alert variant="default">
                    <LightbulbIcon className="h-4 w-4" />
                    <AlertDescription>{t('offer.afterPurchaseTab.externalUrl.tip')}</AlertDescription>
                  </Alert>
                </div>
              </RadioToggleChildren>
              <RadioToggleChildren
                name="afterPurchase.redirectType"
                label={t('offer.afterPurchaseTab.upsell.label')}
                value={RedirectType.UPSELL}
              >
                <div className="w-full mt-4">
                  <UpsellCustomization />
                </div>
              </RadioToggleChildren>
            </RadioGroup>
            {fieldState.error && <p className="text-sm text-red-500">{fieldState.error.message}</p>}
          </div>
        )}
      />
    </OfferSection>
  )
}
