import { ScrollArea, Separator, Skeleton } from '@hub-la/shadcn'
import React from 'react'

export const Loading: React.FC = () => {
  return (
    <ScrollArea className="h-full w-full">
      <div className="space-y-10 px-4 pb-6">
        {/* Header Section Skeleton */}
        <div className="w-full">
          <div className="mb-4">
            <Skeleton className="w-24 h-8" />
          </div>

          <div className="flex flex-col md:flex-row justify-between items-start md:items-end gap-5">
            <div className="flex flex-col gap-2">
              <div className="flex items-center gap-3">
                <Skeleton className="w-32 h-10" />
                <Skeleton className="w-16 h-8" />
              </div>
              <Skeleton className="w-36 h-8" />
            </div>

            <div className="flex flex-col md:flex-row gap-2 w-full md:w-auto">
              <Skeleton className="w-32 h-10" />
            </div>
          </div>
        </div>

        <div className="w-full">
          <Separator className="mb-5" />
          <div className="flex items-center space-x-6 h-10">
            <div className="space-y-1">
              <Skeleton className="w-36 h-6" />
              <Skeleton className="w-24 h-6" />
            </div>
            <Separator className="w-px h-full" orientation="vertical" />
            <div className="space-y-1">
              <Skeleton className="w-36 h-6" />
              <Skeleton className="w-24 h-6" />
            </div>
            <Separator className="w-px h-full" orientation="vertical" />
            <div className="space-y-1">
              <Skeleton className="w-36 h-6" />
              <Skeleton className="w-24 h-6" />
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-4 mb-6">
          <div className="flex items-center w-full gap-2">
            <Skeleton className="w-48 h-8" />
          </div>
          <Separator />
          <div className="space-y-2">
            <div className="flex justify-between space-x-4">
              <Skeleton className="w-full h-12" />
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-4 mb-6">
          <div className="flex items-center w-full gap-2">
            <Skeleton className="w-48 h-8" />
          </div>
          <Separator />
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div>
              <Skeleton className="w-full h-20" />
              <Skeleton className="w-full h-6" />
              <Skeleton className="w-full h-6" />
              <Skeleton className="w-full h-6" />
            </div>
            <div>
              <Skeleton className="w-full h-20" />
              <Skeleton className="w-full h-6" />
              <Skeleton className="w-full h-6" />
              <Skeleton className="w-full h-6" />
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-4 mb-6">
          <div className="flex items-center w-full gap-2">
            <Skeleton className="w-48 h-8" />
          </div>
          <Separator />
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <Skeleton className="w-full h-6" />
            <Skeleton className="w-full h-6" />
            <Skeleton className="w-full h-6" />
            <Skeleton className="w-full h-6" />
          </div>
        </div>
      </div>
    </ScrollArea>
  )
}
