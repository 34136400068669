import { CohortDto } from '@hub-la/sdk-bff-product'
import { TicketSchemaValidationType } from '../../presentation/validations/ticket-validation'

export const TicketDefaultValuesMapper = (
  productId: string,
  data?: CohortDto | undefined,
): TicketSchemaValidationType => {
  const {
    logoUrl = null,
    primaryColor = '#878787E5',
    secondaryColor = '#0000001A',
    textColor = '#FFFFFF',
  } = data?.metadata ?? {}
  return {
    productId,
    name: data?.name ?? '',
    description: data?.description ?? '',
    isLimitedQuantity: !!data?.maxCapacity,
    logoUrl,
    primaryColor,
    maxCapacity: data?.maxCapacity ?? null,
    secondaryColor,
    textColor,
  }
}
