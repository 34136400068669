/* tslint:disable */
/* eslint-disable */
/**
 * BFF Web Product
 * API reference used to call the backend from the web frontend
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface AboutAssetDto
 */
export interface AboutAssetDto {
    /**
     * 
     * @type {string}
     * @memberof AboutAssetDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AboutAssetDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AboutAssetDto
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof AboutAssetDto
     */
    'thumbnailUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof AboutAssetDto
     */
    'type': AboutAssetDtoTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum AboutAssetDtoTypeEnum {
    VIDEO = 'video',
    IMAGE = 'image',
    YOUTUBE = 'youtube',
    WEB = 'web'
}


