import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { GetAffiliatesOutput } from '../domain/dtos/affiliate'
import { GeneralError } from '../domain/errors/general'
import { Envs } from '../envs'

export class GetAffiliates {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(input: { page: number; pageSize: number }, signal?: AbortSignal): Promise<GetAffiliatesOutput> {
    const response = await this.httpClient.request({
      method: HttpMethod.GET,
      url: `${Envs.BFF_WEB_URL}/affiliates`,
      params: {
        page: input.page,
        pageSize: input.pageSize,
      },
      signal,
    })

    if ([HttpStatusCode.OK, HttpStatusCode.CREATED].includes(response.statusCode)) {
      return response.data
    }

    throw new GeneralError()
  }
}
