import {
  Table,
  TableBody,
  TableCell,
  TableEmptyState,
  TableHead,
  TableHeader,
  TablePagination,
  TableRow,
  Pix,
} from '@hub-la/shadcn'
import isEmpty from 'lodash/isEmpty'
import times from 'lodash/times'
import { CreditCard, Barcode } from 'lucide-react'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Invoice } from '../../../../domain/dtos/invoice'
import { PaymentMethod } from '../../../../domain/enums/payment-method'
import { formatCurrency } from '../../../../domain/vos/format-currency'
import { useCheckHaveChanged } from '../../../hooks/use-check-have-changed'
import { useGetInvoices } from '../../../hooks/use-get-invoices'
import { InvoiceStatusStats } from './invoice-status-stats'
import { InvoiceTableLoading } from './invoice-table-loading'
import { SmartInstallmentChip } from './smart-installment-chip'

export const InvoiceTable = ({ offerId }) => {
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(25)
  const { t } = useTranslation()
  const {
    data = { items: [], total: 0, page, pageSize: pageSize, lastPage: 1 },
    isFetching,
    refetch,
  } = useGetInvoices({
    offerId: offerId,
    page: page,
    pageSize: pageSize,
  })
  const history = useHistory()

  const getHeadCells = () => [
    { key: 'total', label: t('table.total'), width: 'w-[90px]' },
    { key: 'status', label: t('table.status'), width: 'w-[70px]' },
    { key: 'paidAt', label: t('table.paidAt'), width: 'w-[110px]' },
  ]

  const canRefetch = useCheckHaveChanged({ page, pageSize })
  useEffect(() => {
    canRefetch && refetch()
  }, [canRefetch, refetch])

  const getIcon = (paymentMethod: PaymentMethod) => {
    switch (paymentMethod) {
      case PaymentMethod.PIX:
        return <Pix className="w-5 h-5" />
      case PaymentMethod.BOLETO:
        return <Barcode className="w-5 h-5" />
      case PaymentMethod.CREDIT_CARD:
        return <CreditCard className="w-5 h-5" />
      default:
        return null
    }
  }

  const isRowsEmpty = isEmpty(data?.items)
  const canRenderEmptyState = !isFetching && isRowsEmpty

  const redirectToInvoiceDetail = (id: string) => history.push('/user_invoices/' + id)

  return (
    <div className="flex flex-col w-full mt-8 space-y-4">
      <h4 className="text-2xl font-semibold mb-3">{t('invoiceHistory')}</h4>
      <div className="flex flex-col gap-4 rounded-lg">
        <Table className="border bg-background rounded-lg">
          <TableHeader>
            <TableRow>
              {getHeadCells().map((cell) => (
                <TableHead key={cell.key} className={cell.width}>
                  {cell.label}
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {isFetching ? (
              times(3, (idx) => <InvoiceTableLoading key={`skeleton-row-${idx}`} />)
            ) : canRenderEmptyState ? (
              <TableEmptyState title={t('table.emptyState')} />
            ) : (
              data?.items.map((invoice: Invoice) => (
                <TableRow
                  key={invoice.id}
                  onClick={() => redirectToInvoiceDetail(invoice.id)}
                  className="cursor-pointer"
                >
                  <TableCell className="py-2">
                    <div className="flex items-center gap-2">
                      {getIcon(invoice.paymentMethod)}
                      <span>{formatCurrency(invoice.totalCents / 100)}</span>
                    </div>
                  </TableCell>
                  <TableCell className="py-2">
                    <div className="flex gap-1">
                      <InvoiceStatusStats status={invoice.status} size="tiny" />
                      {invoice.smartInstallmentRef && (
                        <SmartInstallmentChip
                          status={invoice.status}
                          size="tiny"
                          installment={invoice.installment}
                          installments={invoice.installments}
                        />
                      )}
                    </div>
                  </TableCell>
                  <TableCell className="py-2 text-muted-foreground">
                    {invoice.paidAt ? moment(invoice.paidAt).format('D MMM. YYYY') : '-'}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>

        <TablePagination
          page={page}
          setPage={(page) => setPage(page)}
          lastPage={data?.lastPage ?? 1}
          pageSize={pageSize}
          setPageSize={(size) => setPageSize(size)}
        />
      </div>
    </div>
  )
}
