import { container } from '@hub-la/fe-container'
import { CreateOfferPage, EditOfferPage, OfferProvider } from '@hub-la/fe-offer'
import { useParams } from 'react-router-dom'

export const OfferInit = () => {
  const { offerId } = useParams<{ offerId: string }>()

  const isCreate = offerId === 'create'

  return <OfferProvider container={container}>{isCreate ? <CreateOfferPage /> : <EditOfferPage />}</OfferProvider>
}
