import { container } from '@hub-la/fe-container'
import { DashboardSalesProvider, InvoiceDetail } from '@hub-la/fe-dashboard-sales'
import { useHistory, useParams } from 'react-router-dom'

export const DashboardInvoiceDetailInit = () => {
  const { invoiceId } = useParams<{ invoiceId: string }>()
  const history = useHistory()
  return (
    <DashboardSalesProvider container={container}>
      <InvoiceDetail id={invoiceId} onClose={() => history.goBack()} />
    </DashboardSalesProvider>
  )
}
