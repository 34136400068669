import { Check, CircleDashed, IterationCw, X } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { ReceiverStatus } from '../../../domain/enums/receiver-status'

type PartnershipStatusTagProps = {
  status: ReceiverStatus
}

const getIcon = (status: ReceiverStatus) => {
  switch (status) {
    case ReceiverStatus.INVITED:
      return <CircleDashed className="w-4 h-4 text-ring" />
    case ReceiverStatus.CANCELLATION_REQUESTED:
      return <IterationCw className="w-4 h-4 text-amber-600" />
    case ReceiverStatus.REJECTED:
      return (
        <div className="bg-red-700 rounded-full w-4 h-4 flex justify-center items-center">
          <X className="text-primary-foreground w-3 h-3" strokeWidth={3} />
        </div>
      )
    case ReceiverStatus.ACCEPTED:
      return (
        <div className="bg-green-600 rounded-full w-4 h-4 flex justify-center items-center">
          <Check className="text-primary-foreground w-3 h-3" strokeWidth={3} />
        </div>
      )
    case ReceiverStatus.FINISHED:
      return (
        <div className="bg-muted-foreground rounded-full w-4 h-4 flex justify-center items-center">
          <Check className="text-primary-foreground w-3 h-3" strokeWidth={3} />
        </div>
      )
    case ReceiverStatus.CANCELED:
      return (
        <div className="bg-amber-600 rounded-full w-4 h-4 flex justify-center items-center">
          <X className="text-primary-foreground w-3 h-3" strokeWidth={3} />
        </div>
      )
    default:
      return <CircleDashed className="w-4 h-4 text-ring" />
  }
}

export const PartnershipStatusTag = ({ status }: PartnershipStatusTagProps) => {
  const { t } = useTranslation()

  return (
    <div className="flex items-center gap-1.5">
      {getIcon(status)}
      {t(`partnerships.status.${status}`)}
    </div>
  )
}
