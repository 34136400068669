import { cva, type VariantProps } from 'class-variance-authority'
import React from 'react'

const logoVariants = cva('', {
  variants: {
    size: {
      small: 'w-[69px] h-4',
      medium: 'w-[115px] h-[26px]',
      large: 'w-40 h-9',
    },
    hierarchy: {
      primary: 'text-primary',
      secondary: 'text-secondary',
    },
  },
  defaultVariants: {
    size: 'medium',
    hierarchy: 'primary',
  },
})

export interface LogoProps extends React.SVGProps<SVGSVGElement>, VariantProps<typeof logoVariants> {}

export const Logo: React.FC<LogoProps> = ({ size, hierarchy, className, ...props }) => {
  return (
    <svg
      className={logoVariants({ size, hierarchy, className })}
      viewBox="0 0 115 26"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="currentColor">
        <path d="M81.6528 12.4375C82.8182 11.5712 83.4136 10.408 83.4136 8.89819C83.4136 7.53653 82.9169 6.39705 81.9238 5.48096C80.9551 4.56546 79.7381 4.09497 78.3226 4.09497H71.4192V21.4279H84.0839V12.4375H81.6528ZM81.2285 18.7536H74.2751V6.76918H78.3226C79.589 6.76918 80.5577 7.75992 80.5577 9.04697C80.5577 10.3339 79.589 11.3247 78.3226 11.3247H77.1306V13.9H81.228V18.7536H81.2285Z" />
        <path d="M49.2409 4.09497H52.0968V21.4279H49.2409V13.9255H41.7913V21.4284H38.9353V4.09497H41.7913V11.2015H49.2409V4.09497Z" />
        <path d="M55.2158 4.09497H58.0717V15.51C58.0717 17.6147 59.3132 18.9764 61.7966 18.9764C64.28 18.9764 65.5213 17.6147 65.5213 15.51V4.09497H68.3773V15.6836C68.3773 17.5407 67.7563 19.0019 66.5399 20.1159C65.3229 21.2056 63.7338 21.7502 61.7966 21.7502C59.8599 21.7502 58.2702 21.2056 57.0538 20.1159C55.8368 19.0019 55.2164 17.5407 55.2164 15.6836V4.09497H55.2158Z" />
        <path d="M89.9825 18.7044H97.0601V21.4284H87.1265V4.09497H89.9825V18.7044Z" />
        <path d="M111.921 21.4279L110.704 17.9365H103.303L102.086 21.4279H98.9824L105.29 4.09497H108.718L115 21.4279H111.921ZM104.248 15.2623H109.761L107.004 7.43757L104.248 15.2623Z" />
      </g>
      {/* Icon's background shape (should retain its color) */}
      <path
        d="M17.1008 0H9.15324C6.51756 0 5.19982 0 3.78123 0.444165C2.23224 1.00252 1.01231 2.21065 0.448506 3.74466C0 5.14952 0 6.45472 0 9.0647V16.9353C0 19.5455 0 20.8503 0.448506 22.2553C1.01231 23.7893 2.23224 24.9975 3.78123 25.5557C5.19982 26 6.51756 26 9.15324 26H17.1008C19.7365 26 21.0539 26 22.4728 25.5557C24.0218 24.9975 25.2417 23.7893 25.8055 22.2553C26.254 20.8503 26.254 19.5455 26.254 16.9353V9.0647C26.254 6.45472 26.254 5.14952 25.8055 3.74466C25.2417 2.21065 24.0218 1.00252 22.4728 0.444165C21.0539 0 19.7365 0 17.1008 0Z"
        fill="#SpecificColor" // Replace with the desired color code
      />
      {/* Icon's internal details (should retain its specific color) */}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.3636 6.29229C22.3636 5.05326 21.3494 4.04883 20.0983 4.04883H14.3491C13.098 4.04883 12.0838 5.05326 12.0838 6.29229V8.5923C12.0838 9.83131 11.0695 10.8358 9.81839 10.8358H6.35374C5.10261 10.8358 4.08838 11.8402 4.08838 13.0792V19.9039C4.08838 21.1429 5.10261 22.1473 6.35374 22.1473H12.1029C13.354 22.1473 14.3682 21.1429 14.3682 19.9039V17.6038C14.3682 16.3648 15.3825 15.3603 16.6336 15.3603H20.0983C21.3494 15.3603 22.3636 14.356 22.3636 13.1169V6.29229Z"
        fill="#CDFF36"
      />
    </svg>
  )
}
