import { Auth } from '@hub-la/fe-core-auth'
import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { PostSignInByEmailCompleteRegistrationInput } from '../domain/dtos/post-sign-in-by-email-complete-registration-input'
import { ErrorCode } from '../domain/enums/error-code'
import { FirebaseAuthError } from '../domain/errors/firebase-auth-error'
import { GeneralError } from '../domain/errors/general'
import { InvalidCaptchaError } from '../domain/errors/invalid-captcha-error'
import { SignInNotfoundError } from '../domain/errors/signin-not-found-error'
import { UserDisabledError } from '../domain/errors/user-disabled-error'
import { Envs } from '../envs'

export class PostSignInByEmailCompleteRegistration {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
    @inject(Auth) private readonly auth: Auth,
  ) {}

  public async execute(input: PostSignInByEmailCompleteRegistrationInput): Promise<null> {
    if (!input?.captcha) {
      throw new InvalidCaptchaError()
    }

    const response = await this.httpClient.request({
      method: HttpMethod.POST,
      url: `${Envs.BFF_WEB_URL}/auth/signin/email/complete-registration`,
      body: input,
    })

    if (response.statusCode === HttpStatusCode.CREATED) {
      try {
        await this.auth.signInWithToken(response.data)
        return null
      } catch (e: any) {
        if (!e?.code) {
          throw new GeneralError('signUp')
        }

        switch (e?.code) {
          case 'auth/user-not-found':
            throw new SignInNotfoundError()
          default:
            throw new FirebaseAuthError(e.code)
        }
      }
    }

    switch (response.data.code) {
      case ErrorCode.USER_DISABLED:
        throw new UserDisabledError()
      case ErrorCode.INVALID_CAPTCHA:
        throw new InvalidCaptchaError()
      default:
        throw new GeneralError('signUp')
    }
  }
}
