import { useFormContext } from 'react-hook-form'
import { EventLocation } from '../../../../domain/dtos/product.dto'
import { useGetEvent } from '../../../hooks/use-get-event'
import { TicketSchemaValidationType } from '../../../validations/ticket-validation'
import { Ticket } from '../../ticket/ticket'

type CustomPreviewProps = {
  productId: string
}

export const CustomPreview = ({ productId }: CustomPreviewProps) => {
  const { data } = useGetEvent(productId)
  const { watch } = useFormContext<TicketSchemaValidationType>()
  const formValues = watch()
  const eventData = data?.eventData
  const location: EventLocation = eventData?.location ?? ({} as EventLocation)
  const { street = '', number = '', complement = '', neighborhood = '', city = '', state = '', zipCode = '' } = location

  return (
    <Ticket
      variant="big"
      name={formValues.name}
      eventName={data?.name ?? ''}
      userName="Nome do Participante"
      location={
        location?.street
          ? `${street}, ${number}, ${complement} - ${neighborhood}, ${city} - ${state}, ${zipCode}`
          : undefined
      }
      startDate={eventData?.startDate}
      endDate={eventData?.endDate}
      bgColor={formValues.primaryColor}
      textColor={formValues.textColor}
      secondaryColor={formValues.secondaryColor}
      eventLogo={formValues.logoUrl ?? ''}
      code="https://updates.hub.la/?utm_source=ticket-preview&utm_medium=qr-code"
    />
  )
}
