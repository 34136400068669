export const pt = {
  empty: 'Nenhum reembolso encontrado',
  export: {
    title: 'Exportar reembolsos',
    fileExtension: 'Extensão do arquivo',
    confirm: 'Exportar',
    cancel: 'Cancelar',
  },
  status: {
    created_one: '{{count}} dia',
    pending_one: '{{count}} dia',
    created_other: '{{count}} dias',
    pending_other: '{{count}} dias',
    created: 'Pendente',
    rejected: 'Recusado',
    failed: 'Falha',
    canceled: 'Cancelado',
    processing: 'Processando',
    accepted: 'Aprovado',
  },
  customerDetails: {
    title: 'Cliente',
    email: 'E-mail',
    phoneNumber: 'Telefone',
    paymentMethod: 'Método de pagamento',
  },
  filterStatus: {
    ALL: 'Todos',
    created: 'Pendente',
    accepted: 'Aprovado',
    rejected: 'Recusado',
    processing: 'Processando',
    failed: 'Falha',
    canceled: 'Cancelado',
  },
  acceptSuccess: 'Reembolso aprovado com sucesso',
  rejectSuccess: 'Reembolso recusado com sucesso',
  renewal: 'Renovação',
  purchase: 'Compra',
  paymentMethod: {
    credit_card: 'Cartão de crédito',
    bank_slip: 'Boleto',
    pix: 'PIX',
  },
  errors: {
    general: 'Algo deu errado. Tente novamente mais tarde.',
  },
}
