import { Card, CardContent, Label, useIsMobile } from '@hub-la/shadcn'
import { ChevronRight } from 'lucide-react'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { SubscriptionStatus } from '../../../../domain/enums/subscription-status'
import { SubscriptionType } from '../../../../domain/enums/subscription-type'
import { SubscriptionDateBuilder } from '../../../../usecases/subscription-date-builder'
import avatar from '../../../assets/placeholder-image.svg'

const textEllipsis = (text: string, maxLength: number) => {
  const ellipsis = '...'
  return text.length > maxLength ? text.slice(0, maxLength - ellipsis.length) + ellipsis : text
}

type Props = {
  id: string
  name: string
  picture?: string
  status: SubscriptionStatus
  type: SubscriptionType
  nextDueAt?: string
  inactivatedAt?: string
  credits?: number
}

export const Subscription: React.FC<Props> = ({
  id,
  name,
  picture,
  status,
  type,
  nextDueAt,
  credits,
  inactivatedAt,
}) => {
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const history = useHistory()

  const redirectToSubscriptionDetail = (id: string) => history.push('/user_subscriptions/' + id)

  const dateBuilder = new SubscriptionDateBuilder(
    status,
    type,
    nextDueAt,
    moment(new Date()).add(credits, 'day').toISOString(),
    inactivatedAt,
  )

  return (
    <Card className="cursor-pointer hover:bg-secondary" onClick={() => redirectToSubscriptionDetail(id)}>
      <CardContent className="flex items-center justify-between p-6 ">
        <div className="flex items-center gap-4">
          <img
            src={picture ?? avatar}
            alt={name}
            className="w-[70px] h-auto aspect-[218/142] rounded-lg object-cover "
          />
          <div className="flex flex-col gap-1">
            <Label>{isMobile ? textEllipsis(name, 20) : name}</Label>
            {type !== SubscriptionType.TYPE_ONE_TIME && (
              <p className="text-sm text-muted-foreground">
                {t(`subscription.date.${dateBuilder.getI18nKey()}`)} dia {dateBuilder.execute()}
              </p>
            )}
          </div>
        </div>
        <ChevronRight className="h-5 w-5 text-muted-foreground" />
      </CardContent>
    </Card>
  )
}
