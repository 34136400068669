import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { DuplicateShortLinkInput } from '../../../domain/dtos/duplicate-short-link-input'
import { QueryKey } from '../../../domain/enums/query-key'
import { DuplicateShortLink } from '../../../usecases/shortlinks/duplicate-short-link'

export const useDuplicateShortLink = () => {
  const container = useContainer()
  const queryClient = useQueryClient()

  return useMutation(
    [QueryKey.postDuplicateShortLink],
    (input: DuplicateShortLinkInput) =>
      new DuplicateShortLink(container.get(HttpClient)).execute(input.productId, input.shortLinkId),
    {
      retry: false,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [QueryKey.getShortLinks] })
      },
    },
  )
}
