import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useToast } from '@hub-la/shadcn'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { SetExternalMemberAreaInput } from '../../../domain/dtos/member-area/external-member-area'
import { QueryKey } from '../../../domain/enums/query-key'
import { SetExternalMemberArea } from '../../../usecases/member-area/set-external-member-area'

export const useSetExternalMemberArea = () => {
  const { toast } = useToast()
  const { t } = useTranslation()
  const container = useContainer()
  const queryClient = useQueryClient()

  return useMutation(
    [QueryKey.setExternalMemberArea],
    (input: SetExternalMemberAreaInput) => new SetExternalMemberArea(container.get(HttpClient)).execute(input),
    {
      retry: false,
      onError: (error: Error) => toast({ description: t(error.message), variant: 'destructive' }),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [QueryKey.getExternalMemberArea] })
      },
    },
  )
}
