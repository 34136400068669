export enum BankAccountType {
  UNSPECIFIED = 0,
  TYPE_CHECKING = 1,
  TYPE_SAVING = 2,
}

export enum DocumentType {
  UNSPECIFIED = 0,
  CNPJ = 1,
  CPF = 2,
  PASSPORT = 3,
  ABSENT = 100,
}

export enum VerificationStatus {
  UNSPECIFIED = 0,
  APPROVED = 1,
  REJECTED = 2,
  PENDING = 3,
}
