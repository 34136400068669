import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { Envs } from '../envs'
import { ChargeSmartInstallmentInput } from '../domain/dtos/charge-smart-installment'

export class ChargeSmartInstallment {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) { }

  public async execute(input: ChargeSmartInstallmentInput): Promise<undefined> {
    const response = await this.httpClient.request({
      method: HttpMethod.POST,
      url: `${Envs.BFF_MEMBER_PORTAL_URL}/pay/charge-smart-installment-invoice`,
      body: input
    })

    if (response.statusCode === HttpStatusCode.OK || response.statusCode === HttpStatusCode.CREATED) {
      return
    }

    throw new Error('Não foi possível realizar o pagamento da parcela')
  }
}
