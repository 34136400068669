import get from 'lodash/get'
import gt from 'lodash/gt'
import gte from 'lodash/gte'
import has from 'lodash/has'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import isNil from 'lodash/isNil'
import lt from 'lodash/lt'
import lte from 'lodash/lte'
import { Flag } from '../domain/dtos/flag'
import { WhereFilterOperator } from '../domain/dtos/where-filter-operator'
import { GetFlagsOutput } from './get-flags'

export class IsFlagEnabled {
  public constructor(private readonly flags: GetFlagsOutput) {}

  public isEnabled(flag: string, data?: unknown) {
    if (isEmpty(this.flags)) {
      return false
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const operators: Record<WhereFilterOperator, (value: any, other: any) => boolean> = {
      '==': isEqual,
      '!=': (value, other) => !isEqual(value, other),
      '<=': lte,
      '>': gt,
      '>=': gte,
      '<': lt,
      'array-contains': (value, other) => includes(value, other),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      'map-contains': (value, other) => other?.find((obj: any) => has(obj, value)),

      /**
       * @todo
       */
      'array-contains-any': () => false,
      'not-in': () => false,
      in: (value, other) => includes(other, value),
    }

    const flagByName: Flag | undefined = this.flags.find(({ name }) => isEqual(flag, name))

    /**
     * @description local fallback to critical flags when remote config does not fetch them
     */
    if (isNil(flagByName) && this.isEnabledByDefault(flag)) {
      return true
    }

    if (isNil(flagByName)) {
      return false
    }

    return typeof flagByName.value === 'boolean'
      ? flagByName.value
      : !Array.isArray(flagByName.value)
      ? false
      : flagByName.value.every(({ field, op, value }) => {
          const operator = operators[op ?? '==']

          return operator(get(data, field), value)
        })
  }

  public getValue(flag: string, data?: unknown): any {
    if (isEmpty(this.flags)) {
      return null
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const operators: Record<WhereFilterOperator, (value: string, other: any) => any> = {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      'map-contains': (value, other) => other?.find((obj: any) => has(obj, value))?.[value],

      /**
       * @todo
       */
      '==': () => null,
      '!=': () => null,
      '<=': () => null,
      '>': () => null,
      '>=': () => null,
      '<': () => null,
      'array-contains': () => null,
      'array-contains-any': () => null,
      'not-in': () => null,
      in: () => null,
    }

    const flagByName: Flag | undefined = this.flags.find(({ name }) => isEqual(flag, name))

    if (isNil(flagByName)) {
      return null
    }

    return typeof flagByName.value === 'boolean'
      ? flagByName.value
      : !Array.isArray(flagByName.value)
      ? null
      : flagByName.value.flatMap(({ field, op, value }) => {
          const operator = operators[op ?? '==']

          return operator(get(data, field), value) ?? []
        })
  }

  public getParams(flag: string): Record<string, unknown> {
    if (isEmpty(this.flags)) {
      return {}
    }

    const flagByName: Flag | undefined = this.flags.find(({ name }) => isEqual(flag, name))

    if (isNil(flagByName)) {
      return {}
    }

    return flagByName.params
  }

  private isEnabledByDefault(flag: string) {
    return ([] as string[]).includes(flag)
  }
}
