import {
  Button,
  CopyClipboardButton,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Label,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@hub-la/shadcn'
import isEmpty from 'lodash/isEmpty'
import times from 'lodash/times'
import { Copy, Globe } from 'lucide-react'
import moment from 'moment'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { SmartInstallmentInvoice } from '../../../domain/dtos/smart-installment-invoice'
import { InvoiceStatus } from '../../../domain/enums/invoice-status'
import { formatCurrency } from '../../../domain/vos/format-currency'
import { Envs } from '../../../envs'
import { InvoiceStatusStats } from '../../components/invoice-status-stats'
import { useCheckHaveChanged } from '../../hooks/use-check-have-changed'
import { useGetSmartInstallmentInvoices } from '../../hooks/use-get-smart-installment-invoices'
import { InvoiceTableLoading } from './invoice-table-loading'

export const InvoiceTable = ({ smartInstallmentId, status }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const {
    data = { items: [], total: 0, page: 1, pageSize: 50, lastPage: 1 },
    isFetching,
    refetch,
    isLoading,
  } = useGetSmartInstallmentInvoices({
    smartInstallmentId: smartInstallmentId,
    page: 1,
    pageSize: 50,
  })

  const getHeadCells = () => {
    return [
      {
        key: 'total',
        label: 'Valor',
        tooltip: t('smartInstallmentsInvoiceTable.tooltip.total'),
        sortable: false,
        width: 90,
      },
      {
        key: 'status',
        label: 'Status',
        tooltip: t('smartInstallmentsInvoiceTable.tooltip.status'),
        sortable: false,
        width: 110,
      },
      {
        key: 'id',
        label: 'ID da fatura',
        tooltip: t('smartInstallmentsInvoiceTable.tooltip.id'),
        sortable: false,
        width: 110,
      },
      {
        key: 'dueDate',
        label: 'Data do vencimento',
        tooltip: t('smartInstallmentsInvoiceTable.tooltip.dueDate'),
        sortable: false,
        width: 90,
      },
    ]
  }

  const canRefetch = useCheckHaveChanged({ status })
  useEffect(() => {
    canRefetch && refetch()
  }, [canRefetch, refetch])

  const isRowsEmpty = isEmpty(data?.items)
  const canRenderEmptyState = !isFetching && isRowsEmpty

  return (
    <div>
      <h3 className="text-xl font-semibold mb-2">Faturas</h3>
      <div className="rounded-md border bg-card">
        <Table>
          <TableHeader>
            <TableRow className="hover:bg-transparent">
              <TableHead>Data do vencimento</TableHead>
              <TableHead>Status</TableHead>
              <TableHead>Valor</TableHead>
              <TableHead>Ações</TableHead>
              <TableHead>ID da fatura</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {(isFetching && isRowsEmpty) || isLoading ? (
              <TableRow>
                <TableCell colSpan={5}>
                  <div className="grid gap-4">
                    {times(3, (idx) => (
                      <InvoiceTableLoading key={idx} />
                    ))}
                  </div>
                </TableCell>
              </TableRow>
            ) : (
              (data?.items ?? []).map((installment: SmartInstallmentInvoice) => (
                <TableRow
                  key={installment.invoiceId}
                  className={
                    installment.status === InvoiceStatus.DRAFT ? 'opacity-80 cursor-not-allowed' : ' cursor-pointer'
                  }
                  onClick={() => {
                    if (installment.status !== InvoiceStatus.DRAFT) {
                      history.push(`/dashboard/sales/${installment.invoiceId}`)
                      window.scrollTo({ top: 0, behavior: 'smooth' })
                    }
                  }}
                >
                  <TableCell className="py-2.5 min-w-24">
                    <div className="flex flex-col">
                      <p className="font-medium">
                        {moment(installment.dueDate || installment.scheduleEta).format('D MMM. YYYY')}
                      </p>
                      <p className="text-xs text-muted-foreground">
                        Parcela {installment.installment} de {installment.installments}
                      </p>
                    </div>
                  </TableCell>
                  <TableCell className="py-2">
                    <InvoiceStatusStats status={installment.status} />
                  </TableCell>
                  <TableCell className="py-2">{formatCurrency(installment.amount.totalCents / 100)}</TableCell>
                  <TableCell className="py-2">
                    {installment.status !== InvoiceStatus.DRAFT ? (
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <Button variant="secondary" size="sm">
                            <Globe className="h-4 w-4 mr-2" />
                            Página de pagamento
                          </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                          <DropdownMenuItem
                            onClick={(e) => {
                              e.stopPropagation()
                              window.open(`${Envs.INVOICE_PAYMENT_URL}/${installment.invoiceId}`, '_blank')
                            }}
                          >
                            <Globe className="h-4 w-4 mr-2" />
                            Ir para a página de pagamento
                          </DropdownMenuItem>
                          <DropdownMenuItem
                            onClick={(e) => {
                              e.stopPropagation()
                              navigator.clipboard.writeText(`${Envs.INVOICE_PAYMENT_URL}/${installment.invoiceId}`)
                            }}
                          >
                            <Copy className="h-4 w-4 mr-2" />
                            Copiar link de pagamento
                          </DropdownMenuItem>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    ) : (
                      <span className="text-sm text-muted-foreground">Ainda não disponível</span>
                    )}
                  </TableCell>
                  <TableCell className="py-2">
                    <CopyClipboardButton
                      value={installment.invoiceId}
                      copyContent={installment.invoiceId}
                      tooltipCopyLabel="Copiar ID da fatura"
                      tooltipCopiedLabel="ID da fatura copiado"
                    />
                  </TableCell>
                </TableRow>
              ))
            )}

            {canRenderEmptyState && (
              <TableRow>
                <TableCell colSpan={5}>
                  <div className="flex flex-col items-center justify-center h-32">
                    <Label className="text-sm">{t('empty')}</Label>
                    <Label className="text-sm">{t('emptySubtitle')}</Label>
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  )
}
