import { isEmpty } from 'lodash'
import { GetOfferIdsInput } from '../domain/dtos/get-offer-ids-input'

export class GetOfferIds {
  public execute(input: GetOfferIdsInput): string[] {
    const shouldIncludeAllProducts = input.offerIds.includes('all') || isEmpty(input.offerIds)
    let ids: string[] = []

    if (shouldIncludeAllProducts) {
      ids = input.offers.filter(({ id }) => id !== 'all').map(({ id }) => id)
    } else {
      for (const offerId of input.offerIds) {
        ids.push(offerId)
      }
    }

    return ids
  }
}
