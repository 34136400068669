import { Card, CardContent, CopyClipboardButton, Label, Pix } from '@hub-la/shadcn'
import { Barcode, CreditCard } from 'lucide-react'
import { TFunction, useTranslation } from 'react-i18next'
import { CardDetail } from '../../../../domain/dtos/card-detail'
import { CardBrand } from '../../../../domain/enums/card-brand'
import { InvoiceStatus } from '../../../../domain/enums/invoice-status'
import { PaymentMethod } from '../../../../domain/enums/payment-method'
import amex from '../../../assets/brands/amex.png'
import elo from '../../../assets/brands/elo.png'
import mastercard from '../../../assets/brands/mastercard.png'
import visa from '../../../assets/brands/visa.png'

const getIcon = (paymentMethod?: PaymentMethod, cardBrand?: CardBrand) => {
  if (paymentMethod === PaymentMethod.BOLETO) return <Barcode className="w-6 h-6" />

  if (paymentMethod === PaymentMethod.PIX) return <Pix className="w-6 h-6" />

  switch (cardBrand) {
    case CardBrand.CARD_BRAND_VISA:
      return <img src={visa} alt="Visa" className="w-8 h-auto object-contain" />
    case CardBrand.CARD_BRAND_MASTERCARD:
      return <img src={mastercard} alt="Mastercard" className="w-8 h-auto object-contain" />
    case CardBrand.CARD_BRAND_AMEX:
      return <img src={amex} alt="American Express" className="w-8 h-auto object-contain" />
    case CardBrand.CARD_BRAND_ELO:
      return <img src={elo} alt="Elo" className="w-8 h-auto object-contain" />
    default:
      return <CreditCard className="w-6 h-6" />
  }
}

const getText = (t: TFunction<'translation', undefined>, paymentMethod?: PaymentMethod, cardDetails?: CardDetail) => {
  if (paymentMethod === PaymentMethod.PIX) return 'Pix'

  if (paymentMethod === PaymentMethod.BOLETO) return 'Boleto'

  if (paymentMethod === PaymentMethod.CREDIT_CARD) {
    return cardDetails ? `${t(`cardBrand.${cardDetails?.brand}`)} •••• ${cardDetails?.last4}` : 'Cartão de crédito'
  }

  return ''
}

/** This should ideally be done on backend, just like the checkout does */
const parseImageString = (imageString?: string): string => {
  if (!imageString) return ''
  if (imageString.startsWith('http') || imageString.startsWith('https')) {
    return imageString
  } else {
    return `data:image;base64,${imageString}`
  }
}

type Props = {
  paymentMethod?: PaymentMethod
  cardDetails?: CardDetail
  softDescriptor?: string
  qrcodeData?: string
  qrcodeText?: string
  status: InvoiceStatus
}

export const PaymentDetails = ({
  paymentMethod,
  cardDetails,
  softDescriptor,
  qrcodeData,
  qrcodeText,
  status,
}: Props) => {
  const { t } = useTranslation()

  const shouldRenderQrCode = status === InvoiceStatus.UNPAID && qrcodeData && qrcodeText

  return (
    <Card>
      <CardContent className="p-6 flex flex-col gap-6">
        <div className="flex flex-col gap-4">
          <div>
            <Label>{t('paymentDetails.method')}</Label>
            <div className="flex items-center space-x-2 mt-1">
              {getIcon(paymentMethod, cardDetails?.brand)}
              <span className="text-sm text-muted-foreground">{getText(t, paymentMethod, cardDetails)}</span>
            </div>
          </div>

          {softDescriptor && (
            <div>
              <Label>{t('paymentDetails.description')}</Label>
              <p className="text-sm text-muted-foreground mt-1">{softDescriptor}</p>
            </div>
          )}
        </div>

        {shouldRenderQrCode && (
          <div className="flex flex-col gap-4 items-center text-center">
            <img
              src={parseImageString(qrcodeData)}
              alt="QR code pix"
              className="mx-auto max-w-40 max-h-40 p-3 border rounded-md bg-background"
            />

            <CopyClipboardButton
              copyContent={qrcodeText}
              label={t('pix.copyCode')}
              tooltipCopiedLabel={t('copyClipboard.pixCodeCopied')}
              tooltipCopyLabel={t('copyClipboard.copyPixCode')}
            />
          </div>
        )}
      </CardContent>
    </Card>
  )
}
