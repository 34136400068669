import * as Yup from 'yup'
import { Phone } from '../../domain/vos/phone'

export const signInPhoneSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .required('Campo obrigatório')
    .test({
      message: 'Digite um número de telefone válido',
      test: function (value) {
        return Phone.build(String(value)).isValid()
      },
    }),
})
