import * as Yup from 'yup'

export const connectIntegrationSchema = Yup.object().shape({
  apiKey: Yup.string().required('errors.validation.apiKey.required'),
})

export const saveRuleSchema = Yup.object().shape({
  products: Yup.array().min(1),
  offers: Yup.array().min(1),
  events: Yup.array().min(1),
  lists: Yup.array().min(1),
})

export const ruleSchema = ['products', 'offers', 'events', 'lists', 'tags']
export const connectSchema = ['apiKey']
