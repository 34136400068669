import { UpdateProductSettingsTrackersRequestDto } from '@hub-la/sdk-bff-product'
import { Input, Label } from '@hub-la/shadcn'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { Section } from '../../../components/section'
import { useGetSettings } from '../../../hooks/settings/use-get-settings'
import { useSaveSettings } from '../../../hooks/settings/use-save-settings'

type Values = Omit<UpdateProductSettingsTrackersRequestDto, 'facebook'>
const validationSchema = Yup.object().shape({})
const makeInitialValues = ({ googleAnalytics, tagManager }: Values): Values => {
  return {
    googleAnalytics: googleAnalytics ?? '',
    tagManager: tagManager ?? '',
  }
}

export const Pixels = () => {
  const { productId } = useParams<{ productId: string }>()
  const { mutateAsync: saveSettings } = useSaveSettings()
  const { data, isLoading } = useGetSettings({ productId, type: 'trackers' })
  const { t } = useTranslation()

  const formik = useFormik<Values>({
    validationSchema,
    validateOnMount: true,
    validateOnChange: true,
    enableReinitialize: true,
    initialValues: makeInitialValues({
      googleAnalytics: data?.googleAnalytics,
      tagManager: data?.tagManager,
    }),
    onSubmit: async (values) => {
      await saveSettings({ productId, type: 'trackers', data: values })
    },
  })

  return (
    <Section
      formik={formik}
      title={t('settings.trackers.googlePixels')}
      subtitle={t('settings.trackers.googleSubtitle')}
    >
      <div className="flex flex-col space-y-4">
        <div className="space-y-2">
          <Label htmlFor="tagManager">{t('settings.trackers.fields.tagManager')}</Label>

          <Input
            id="tagManager"
            name="tagManager"
            value={formik.values.tagManager || ''}
            onChange={formik.handleChange}
            disabled={isLoading}
            placeholder={t('settings.trackers.fields.facebook.placeholder')}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="googleAnalytics">{t('settings.trackers.fields.googleAnalytics')}</Label>

          <Input
            id="googleAnalytics"
            name="googleAnalytics"
            value={formik.values.googleAnalytics || ''}
            onChange={formik.handleChange}
            disabled={isLoading}
            placeholder={t('settings.trackers.fields.facebook.placeholder')}
          />
        </div>
      </div>
    </Section>
  )
}
