import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useToast } from '@hub-la/shadcn'
import { useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { UpdateResourceInput } from '../../../domain/dtos/member-area/update-resource-input'
import { QueryKey } from '../../../domain/enums/query-key'
import { UpdateResource } from '../../../usecases/member-area/update-resource'

export const useUpdateResource = () => {
  const { toast } = useToast()
  const { t } = useTranslation()
  const container = useContainer()

  return useMutation(
    [QueryKey.updateResource],
    (input: UpdateResourceInput) => {
      return new UpdateResource(container.get(HttpClient)).execute(input)
    },
    {
      retry: false,
      onError: (error: Error) => toast({ description: t(error.message), variant: 'destructive' }),
    },
  )
}
