import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { QueryKey, SectionList } from '@hub-la/fe-post'
import { isNil } from 'lodash'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { DeletePost } from '../../usecases/delete-post'

export const useDeletePost = () => {
  const container = useContainer()
  const queryClient = useQueryClient()

  return useMutation(
    [QueryKey.deletePost],
    (postId: string) => new DeletePost(container.get(HttpClient)).execute(postId),
    {
      onMutate: (postId) => {
        queryClient.setQueryData<SectionList>([QueryKey.listSections], (sections): SectionList => {
          if (isNil(sections)) {
            return {
              items: [],
              page: 0,
              pageSize: 0,
              total: 0,
            }
          }

          const items = sections.items.map((section) => {
            return {
              ...section,
              posts: section.posts.filter((post) => post.id !== postId),
            }
          })

          return { items, page: sections.page, pageSize: sections.pageSize, total: sections.total }
        })
      },
    },
  )
}
