import { Check, CheckCircle2, CircleDashed, PieChartIcon, X } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { SubscriptionStatus } from '../../../../domain/enums/subscription-status'

type SubscriptionStatusStatsProps = {
  status: SubscriptionStatus
}

const getVariant = (status: SubscriptionStatus) => {
  switch (status) {
    case SubscriptionStatus.ACTIVE:
      return (
        <div className="bg-green-600  rounded-full w-4 h-4 flex justify-center items-center">
          <Check className="text-primary-foreground w-3 h-3" strokeWidth={3} />
        </div>
      )
    case SubscriptionStatus.INCOMPLETE:
      return <CircleDashed className="w-4 h-4 text-ring" />
    case SubscriptionStatus.CANCELED:
      return <PieChartIcon className="w-4 h-4 text-red-700" />
    case SubscriptionStatus.INACTIVE:
      return (
        <div className="bg-muted-foreground  rounded-full w-4 h-4 flex justify-center items-center">
          <X className="text-primary-foreground w-3 h-3" strokeWidth={3} />
        </div>
      )
    default:
      return <CheckCircle2 className="w-4 h-4 text-green-600 " />
  }
}

export const SubscriptionStatusStats = ({ status }: SubscriptionStatusStatsProps) => {
  const { t } = useTranslation()

  return (
    <div className="flex items-center gap-1.5 text-base">
      {getVariant(status)}
      {t(`subscriptionStatus.${status}`)}
    </div>
  )
}
