import {
  Badge,
  Button,
  cn,
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  Input,
  Label,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Separator,
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from '@hub-la/shadcn'
import { FormikProvider, useFormik } from 'formik'
import { CheckIcon, ChevronsUpDown, X } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { ExternalReference } from '../../domain/dtos/external-reference'
import { IntegrationEventOption } from '../../domain/dtos/integration-event'
import { IntegrationProvider } from '../../domain/dtos/integration-provider'
import { Offer } from '../../domain/dtos/offer'
import { Product } from '../../domain/dtos/product'
import { Rule } from '../../domain/dtos/rule'
import { useCreateRule } from '../hooks/use-create-rule'
import { useEditRule } from '../hooks/use-edit-rule'
import { useGetAllProducts } from '../hooks/use-get-all-products'
import { useGetIntegrationEvents } from '../hooks/use-get-integration-events'
import { useGetLists } from '../hooks/use-get-lists'
import { useGetOffers } from '../hooks/use-get-offers'
import { useGetTags } from '../hooks/use-get-tags'
import { InputTags } from './input-tags'

export type CreateEditRuleForm = {
  name: string
  url: string
  products: Product[]
  offers: Offer[]
  lists: ExternalReference[]
  tags: ExternalReference[]
  events: IntegrationEventOption[]
}

export enum CreateEditRuleType {
  CREATE = 'create',
  EDIT = 'edit',
}

type Props = {
  isOpen: boolean
  integrationId: string
  provider: IntegrationProvider
  type: CreateEditRuleType
  rule: Rule | null
  schema?: string[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validation?: any
  onClose: () => void
  refetch: () => void
}

export const CreateEditRuleModal = (props: Props) => {
  const { integrationId, provider, isOpen, onClose, type, rule, schema, validation, refetch } = props
  const { t } = useTranslation()
  const events = useGetIntegrationEvents(provider)

  const needName = schema?.find((value) => value === 'name')
  const needUrl = schema?.find((value) => value === 'url')
  const needProducts = schema?.find((value) => value === 'products')
  const needOffers = schema?.find((value) => value === 'offers')
  const needEvents = schema?.find((value) => value === 'events')
  const needLists = schema?.find((value) => value === 'lists')
  const needTags = schema?.find((value) => value === 'tags')

  const { data: products = [] } = useGetAllProducts(true)
  const { data: offers = [] } = useGetOffers()

  const { data: lists = [] } = useGetLists({ integrationId }, !!needLists)
  const { data: tags = [] } = useGetTags({ integrationId }, !!needTags)

  const { mutateAsync: createRule, isLoading: isCreating } = useCreateRule()
  const { mutateAsync: editRule, isLoading: isEditing } = useEditRule()

  const formik = useFormik<CreateEditRuleForm>({
    initialValues: {
      name: rule?.name ?? '',
      url: rule?.url ?? '',
      products: rule?.products ?? [],
      offers: rule?.offers ?? [],
      events: events.filter((event) => rule?.events.includes(event.code)) ?? [],
      lists: rule?.lists ?? [],
      tags: rule?.tags ?? [],
    },
    validationSchema: validation,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: (params) => {
      switch (type) {
        case CreateEditRuleType.CREATE: {
          createRule({
            integrationId,
            provider,
            rule: {
              name: params.name,
              url: params.url,
              products: params.products,
              offers: params.offers,
              events: params.events.map((event) => event.code),
              lists: params.lists,
              tags: params.tags,
            },
          }).then(() => {
            formik.resetForm()
            onClose()
            refetch()
          })
          return
        }
        case CreateEditRuleType.EDIT: {
          editRule({
            integrationId,
            provider,
            rule: {
              id: rule?.id as string,
              name: params.name,
              url: params.url,
              products: params.products,
              offers: params.offers,
              events: params.events.map((event) => event.code),
              lists: params.lists,
              tags: params.tags,
              enabled: rule?.enabled as boolean,
            },
          }).then(() => {
            formik.resetForm()
            onClose()
            refetch()
          })
          return
        }
      }
    },
  })

  const isLoading = isCreating || isEditing

  return (
    <FormikProvider value={formik}>
      <Sheet open={isOpen} onOpenChange={onClose}>
        <SheetContent className="p-6 max-w-lg">
          <SheetHeader>
            <SheetTitle className="flex items-center gap-3">
              <span>
                {type === CreateEditRuleType.CREATE
                  ? t(`integrations.${provider}.rule.title.create`)
                  : t(`integrations.${provider}.rule.title.edit`)}
              </span>
            </SheetTitle>
            <SheetDescription>
              {type === CreateEditRuleType.CREATE && (
                <p className="text-sm font-normal text-muted-foreground">
                  {t(`integrations.${provider}.rule.description`)}
                </p>
              )}
            </SheetDescription>
          </SheetHeader>
          <div className="overflow-y-auto max-h-[calc(100vh-150px)] grid gap-4 py-4 px-1">
            {!!needName && (
              <div className="grid w-full items-center gap-1.5">
                <Label htmlFor="name">{t('rules.table.columns.name')}</Label>
                <Input
                  placeholder={t('rules.table.columns.name')}
                  id="name"
                  autoFocus
                  disabled={isLoading}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  className={formik.errors.name && formik.touched.name ? 'border-red-500' : ''}
                />
                {formik.touched.name && formik.errors.name && (
                  <p className="text-sm text-destructive mt-1">{t(formik.errors.name)}</p>
                )}
              </div>
            )}

            {!!needUrl && (
              <div className="grid w-full items-center gap-1.5">
                <Label htmlFor="url">{t('rules.table.columns.url')}</Label>
                <Input
                  placeholder="https://example.com"
                  id="url"
                  disabled={isLoading}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.url}
                  className={formik.errors.url && formik.touched.url ? 'border-red-500' : ''}
                />
                {formik.touched.url && formik.errors.url && (
                  <p className="text-sm text-destructive mt-1">{t(formik.errors.url)}</p>
                )}
              </div>
            )}

            {needProducts && (
              <div className="grid w-full items-center gap-1.5">
                <Label htmlFor="products">{t('rules.table.columns.products')}</Label>
                <Popover>
                  <PopoverTrigger asChild>
                    <Button variant="outline" role="combobox" className="h-auto gap-2 overflow-y-auto">
                      <div className="flex flex-row flex-wrap gap-2 flex-1">
                        {formik.values.products.map((product) => (
                          <Badge key={product.id} variant="secondary" className="flex-wrap gap-1">
                            {product.name}
                            <X
                              className="h-3 w-3 cursor-pointer"
                              onClick={(e) => {
                                e.stopPropagation()
                                formik.setFieldValue(
                                  'products',
                                  formik.values.products.filter((o) => o.id !== product.id),
                                )
                              }}
                            />
                          </Badge>
                        ))}
                      </div>
                      <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="w-[450px] p-0 z-[62]">
                    <Command>
                      <CommandInput placeholder={t('rules.table.columns.products')} />
                      <CommandList>
                        <CommandEmpty>{t('rules.createModal.noOptions')}</CommandEmpty>
                        {products.map((product) => {
                          const checked = formik.values.products.some((o) => o.id === product.id)
                          return (
                            <CommandItem
                              className="cursor-pointer"
                              key={product.id}
                              onSelect={() => {
                                formik.setFieldValue(
                                  'products',
                                  checked
                                    ? formik.values.products.filter((o) => o.id !== product.id)
                                    : [...formik.values.products, product],
                                )
                              }}
                            >
                              <div
                                className={cn(
                                  'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                                  checked
                                    ? 'bg-hubla-primary text-primary-foreground border-hubla-primary'
                                    : 'opacity-50 [&_svg]:invisible',
                                )}
                              >
                                <CheckIcon className={cn('h-4 w-4')} />
                              </div>
                              {product.name}
                            </CommandItem>
                          )
                        })}
                      </CommandList>
                    </Command>
                    <Separator />
                    <div className="flex justify-between">
                      {formik.values.products.length > 0 && (
                        <Button
                          variant="ghost"
                          className="w-full justify-center text-center rounded-none"
                          onClick={() => formik.setFieldValue('products', [])}
                        >
                          Limpar seleção
                        </Button>
                      )}
                      <Button
                        variant="ghost"
                        className="w-full justify-center text-center rounded-none"
                        onClick={() => formik.setFieldValue('products', products)}
                      >
                        Selecionar todos
                      </Button>
                    </div>
                  </PopoverContent>
                </Popover>
              </div>
            )}

            {needOffers && (
              <div className="grid w-full items-center gap-1.5">
                <Label htmlFor="offers">{t('rules.table.columns.offers')}</Label>
                <Popover>
                  <PopoverTrigger asChild>
                    <Button variant="outline" role="combobox" className="h-auto gap-2 overflow-y-auto">
                      <div className="flex flex-row flex-wrap gap-2 flex-1">
                        {formik.values.offers.map((offer) => (
                          <Badge key={offer.id} variant="secondary" className="flex-wrap gap-1">
                            {offer.name}
                            <X
                              className="h-3 w-3 cursor-pointer"
                              onClick={(e) => {
                                e.stopPropagation()
                                formik.setFieldValue(
                                  'offers',
                                  formik.values.offers.filter((o) => o.id !== offer.id),
                                )
                              }}
                            />
                          </Badge>
                        ))}
                      </div>
                      <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="w-[450px] h- p-0 z-[62]">
                    <Command>
                      <CommandInput placeholder={t('rules.table.columns.offers')} />
                      <CommandList>
                        <CommandEmpty>{t('rules.createModal.noOptions')}</CommandEmpty>
                        {offers
                          .filter(
                            (offer) =>
                              offer.id === 'all' || formik.values.products.some((p) => p.id === offer.product?.id),
                          )
                          .map((offer) => {
                            const checked = formik.values.offers.some((o) => o.id === offer.id)
                            return (
                              <CommandItem
                                className="cursor-pointer"
                                key={offer.id}
                                onSelect={() => {
                                  formik.setFieldValue(
                                    'offers',
                                    checked
                                      ? formik.values.offers.filter((o) => o.id !== offer.id)
                                      : [...formik.values.offers, offer],
                                  )
                                }}
                              >
                                <div
                                  className={cn(
                                    'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                                    checked
                                      ? 'bg-hubla-primary text-primary-foreground border-hubla-primary'
                                      : 'opacity-50 [&_svg]:invisible',
                                  )}
                                >
                                  <CheckIcon className={cn('h-4 w-4')} />
                                </div>
                                {offer.name}
                              </CommandItem>
                            )
                          })}
                      </CommandList>
                    </Command>
                    <Separator />
                    <div className="flex justify-between">
                      {formik.values.offers.length > 0 && (
                        <Button
                          variant="ghost"
                          className="w-full justify-center text-center rounded-none"
                          onClick={() => formik.setFieldValue('offers', [])}
                        >
                          Limpar seleção
                        </Button>
                      )}
                      <Button
                        variant="ghost"
                        className="w-full justify-center text-center rounded-none"
                        onClick={() => formik.setFieldValue('offers', offers)}
                      >
                        Selecionar todos
                      </Button>
                    </div>
                  </PopoverContent>
                </Popover>
              </div>
            )}

            {needEvents && (
              <div className="grid w-full items-center gap-1.5">
                <Label htmlFor="events">{t('rules.table.columns.events')}</Label>
                <Popover>
                  <PopoverTrigger asChild>
                    <Button variant="outline" role="combobox" className="h-auto gap-2 overflow-y-auto">
                      <div className="flex flex-row flex-wrap gap-2 flex-1">
                        {formik.values.events.map((event) => (
                          <Badge key={event.code} variant="secondary" className="flex-wrap gap-1">
                            {t(event.name)}
                            <X
                              className="h-3 w-3 cursor-pointer"
                              onClick={(e) => {
                                e.stopPropagation()
                                formik.setFieldValue(
                                  'events',
                                  formik.values.events.filter((e) => e.code !== event.code),
                                )
                              }}
                            />
                          </Badge>
                        ))}
                      </div>
                      <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="w-[450px] p-0 z-[62]">
                    <Command>
                      <CommandInput placeholder={t('rules.table.columns.events')} />
                      <CommandList>
                        <CommandEmpty>{t('rules.createModal.noOptions')}</CommandEmpty>
                        {Object.entries(
                          events.reduce((acc, event) => {
                            const group = event.group
                            if (!acc[group]) acc[group] = []
                            acc[group].push(event)
                            return acc
                          }, {} as Record<string, IntegrationEventOption[]>),
                        ).map(([group, groupEvents]) => (
                          <CommandGroup key={group} heading={group} className="border-b">
                            {groupEvents.map((event) => {
                              const checked = formik.values.events.some((e) => e.code === event.code)
                              return (
                                <CommandItem
                                  className="cursor-pointer"
                                  key={event.code}
                                  onSelect={() => {
                                    formik.setFieldValue(
                                      'events',
                                      checked
                                        ? formik.values.events.filter((e) => e.code !== event.code)
                                        : [...formik.values.events, event],
                                    )
                                  }}
                                >
                                  <div
                                    className={cn(
                                      'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                                      checked
                                        ? 'bg-hubla-primary text-primary-foreground border-hubla-primary'
                                        : 'opacity-50 [&_svg]:invisible',
                                    )}
                                  >
                                    <CheckIcon className={cn('h-4 w-4')} />
                                  </div>
                                  <div className="flex flex-col">
                                    <span>{t(event.name)}</span>

                                    <span className="text-xs text-gray-500">{event.code}</span>
                                  </div>
                                </CommandItem>
                              )
                            })}
                          </CommandGroup>
                        ))}
                      </CommandList>
                    </Command>
                    <Separator />
                    <div className="flex justify-between">
                      {formik.values.events.length > 0 && (
                        <Button
                          variant="ghost"
                          className="w-full justify-center text-center rounded-none"
                          onClick={() => formik.setFieldValue('events', [])}
                        >
                          Limpar seleção
                        </Button>
                      )}
                      <Button
                        variant="ghost"
                        className="w-full justify-center text-center rounded-none"
                        onClick={() => formik.setFieldValue('events', events)}
                      >
                        Selecionar todos
                      </Button>
                    </div>
                  </PopoverContent>
                </Popover>
              </div>
            )}
            {needLists && (
              <div className="grid w-full items-center gap-1.5">
                <Label htmlFor="lists">{t(`integrations.${provider}.rule.columns.lists`)}</Label>
                <Popover>
                  <PopoverTrigger asChild>
                    <Button variant="outline" role="combobox" className="h-auto gap-2 overflow-y-auto">
                      <div className="flex flex-row flex-wrap gap-1 flex-1">
                        {formik.values.lists.map((list) => (
                          <Badge key={list.id} variant="secondary" className="flex-wrap gap-1">
                            {list.name}
                            <X
                              className="h-3 w-3 cursor-pointer"
                              onClick={(e) => {
                                e.stopPropagation()
                                formik.setFieldValue(
                                  'lists',
                                  formik.values.lists.filter((o) => o.id !== list.id),
                                )
                              }}
                            />
                          </Badge>
                        ))}
                      </div>
                      <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="w-[450px] h- p-0 z-[62]">
                    <Command>
                      <CommandInput placeholder={t(`integrations.${provider}.rule.columns.lists`)} />
                      <CommandList>
                        <CommandEmpty>{t('rules.createModal.noOptions')}</CommandEmpty>
                        {lists.map((list) => {
                          const checked = formik.values.lists.some((l) => l.id === list.id)
                          return (
                            <CommandItem
                              className="cursor-pointer"
                              key={list.id}
                              onSelect={() => {
                                formik.setFieldValue(
                                  'lists',
                                  checked
                                    ? formik.values.lists.filter((o) => o.id !== list.id)
                                    : [...formik.values.lists, list],
                                )
                              }}
                            >
                              <div
                                className={cn(
                                  'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                                  checked
                                    ? 'bg-hubla-primary text-primary-foreground border-hubla-primary'
                                    : 'opacity-50 [&_svg]:invisible',
                                )}
                              >
                                <CheckIcon className={cn('h-4 w-4')} />
                              </div>
                              {list.name}
                            </CommandItem>
                          )
                        })}
                      </CommandList>
                    </Command>
                    <Separator />
                    <div className="flex justify-between">
                      {formik.values.lists.length > 0 && (
                        <Button
                          variant="ghost"
                          className="w-full justify-center text-center rounded-none"
                          onClick={() => formik.setFieldValue('lists', [])}
                        >
                          Limpar seleção
                        </Button>
                      )}
                      <Button
                        variant="ghost"
                        className="w-full justify-center text-center rounded-none"
                        onClick={() => formik.setFieldValue('lists', lists)}
                      >
                        Selecionar todos
                      </Button>
                    </div>
                  </PopoverContent>
                </Popover>
              </div>
            )}

            {needTags && ![IntegrationProvider.RDSTATION].includes(provider) && (
              <div className="grid w-full items-center gap-1.5">
                <Label htmlFor="tags">{t(`integrations.${provider}.rule.columns.tags`)}</Label>
                <Popover>
                  <PopoverTrigger asChild>
                    <Button variant="outline" role="combobox" className="h-auto">
                      <div className="flex flex-row flex-wrap gap-1 flex-1">
                        {formik.values.tags.map((tag) => (
                          <Badge key={tag.id} variant="secondary" className="flex-wrap gap-1">
                            {tag.name}
                            <X
                              className="h-3 w-3 cursor-pointer"
                              onClick={(e) => {
                                e.stopPropagation()
                                formik.setFieldValue(
                                  'tags',
                                  formik.values.tags.filter((o) => o.id !== tag.id),
                                )
                              }}
                            />
                          </Badge>
                        ))}
                      </div>

                      <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                    </Button>
                  </PopoverTrigger>

                  <PopoverContent className="w-[450px] h- p-0 z-[62]">
                    <Command>
                      <CommandInput placeholder={t(`integrations.${provider}.rule.columns.tags`)} />
                      <CommandList>
                        <CommandEmpty>{t('rules.createModal.noOptions')}</CommandEmpty>
                        {tags.map((tag) => {
                          const checked = formik.values.tags.some((l) => l.id === tag.id)
                          return (
                            <CommandItem
                              className="cursor-pointer"
                              key={tag.id}
                              onSelect={() =>
                                formik.setFieldValue(
                                  'tags',
                                  checked
                                    ? formik.values.tags.filter((o) => o.id !== tag.id)
                                    : [...formik.values.tags, tag],
                                )
                              }
                            >
                              <div
                                className={cn(
                                  'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                                  checked
                                    ? 'bg-hubla-primary text-primary-foreground border-hubla-primary'
                                    : 'opacity-50 [&_svg]:invisible',
                                )}
                              >
                                <CheckIcon className={cn('h-4 w-4')} />
                              </div>
                              {tag.name}
                            </CommandItem>
                          )
                        })}
                      </CommandList>
                    </Command>
                    <Separator />
                    <div className="flex justify-between">
                      {formik.values.tags.length > 0 && (
                        <Button
                          variant="ghost"
                          className="w-full justify-center text-center rounded-none"
                          onClick={() => formik.setFieldValue('tags', [])}
                        >
                          Limpar seleção
                        </Button>
                      )}
                      <Button
                        variant="ghost"
                        className="w-full justify-center text-center rounded-none"
                        onClick={() => formik.setFieldValue('tags', tags)}
                      >
                        Selecionar todos
                      </Button>
                    </div>
                  </PopoverContent>
                </Popover>
              </div>
            )}

            {needTags && [IntegrationProvider.RDSTATION].includes(provider) && (
              <div className="grid w-full items-center gap-1.5">
                <Label htmlFor="tags">{t(`integrations.${provider}.rule.columns.tags`)}</Label>
                <InputTags />
              </div>
            )}
          </div>
          <Button
            onClick={() => formik.submitForm()}
            disabled={!formik.isValid || !formik.dirty}
            loading={isLoading}
            className="mt-6"
          >
            {type === CreateEditRuleType.CREATE ? t('rules.buttons.create') : t('rules.buttons.edit')}
          </Button>
        </SheetContent>
      </Sheet>
    </FormikProvider>
  )
}
