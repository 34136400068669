import { Section } from '../domain/dtos'
import { PostPagination } from '../domain/dtos/post-pagination'

type GetPostPaginationInput = {
  postId?: string
  sections?: Section[]
}

export class GetPostPagination {
  execute({ postId, sections }: GetPostPaginationInput): PostPagination {
    if (!sections || !postId) {
      return { previous: null, next: null }
    }

    let previousPostId: string | null = null
    let nextPostId: string | null = null

    // Map all posts to a single array
    const allPosts = sections.flatMap((item) => item.posts)

    // Find the index of the post with the given postId
    const postIndex = allPosts.findIndex((post) => post.id === postId)

    // Check if there's a previous post
    if (postIndex > 0) {
      previousPostId = allPosts[postIndex - 1].id
    }

    // Check if there's a next post
    if (postIndex < allPosts.length - 1) {
      nextPostId = allPosts[postIndex + 1].id
    }

    return { previous: previousPostId, next: nextPostId }
  }
}
