/**
 * Asset file status
 */
export enum AssetStatus {
  /** Asset available */
  available = 'available',
  /** Asset not yet available, waiting for external upload to finish */
  uploading = 'uploading',
  /** Asset not yet available, it is still being processed by the server */
  processing = 'processing',
  /** Asset not available, due to a server error */
  error = 'error',
}
