import { Download, FileDown } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { FileExtension } from '../../domain/enums/file-extension'
import { useExportAffiliates } from '../hooks/use-export-affiliates'

import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Label,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@hub-la/shadcn'
import { useState } from 'react'

type Props = {
  isOpen: boolean
  handleVisibility: (isOpen: boolean) => void
}

export const ConfirmExportModal = ({ isOpen, handleVisibility }: Props) => {
  const [fileExtension, setFileExtension] = useState<FileExtension>(FileExtension.XLSX)
  const { t } = useTranslation()

  const { mutateAsync: exportAffiliates, isLoading } = useExportAffiliates()

  const handleExport = async () => {
    await exportAffiliates({ fileExtension })
    handleVisibility(false)
  }

  return (
    <Dialog open={isOpen} onOpenChange={handleVisibility}>
      <DialogTrigger asChild>
        <Button variant="outline">
          <Download className="mr-2 h-4 w-4" />
          Exportar
        </Button>
      </DialogTrigger>

      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{t('export.title')}</DialogTitle>
        </DialogHeader>

        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="fileExtension" className="text-right">
              {t('export.fileExtension')}
            </Label>

            <Select
              disabled={isLoading}
              onValueChange={(value) => setFileExtension(value as FileExtension)}
              defaultValue={fileExtension}
            >
              <SelectTrigger className="col-span-3">
                <SelectValue placeholder="Select file type" />
              </SelectTrigger>

              <SelectContent>
                <SelectItem value={FileExtension.XLSX}>xlsx</SelectItem>
                <SelectItem value={FileExtension.CSV}>csv</SelectItem>
                <SelectItem value={FileExtension.JSON}>json</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>

        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline" disabled={isLoading}>
              {t('export.cancel')}
            </Button>
          </DialogClose>

          <Button onClick={handleExport} disabled={isLoading}>
            <FileDown className="mr-2 h-4 w-4" />
            {t('export.confirm')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
