import React, { ImgHTMLAttributes, useState } from 'react'
import { Envs } from '../../../envs'

// Check if the test mode is enabled to avoid calling Imaginary
const isTestMode = Envs.NODE_ENV === 'test'

type Props = {
  sourceUrl: string
  processParameter: {
    width: number
    height?: string | number
    outputType: 'auto' | 'webp' | 'png' | 'jpeg'
    quality?: string | number
  }
  sourceName?: string
  ownerId?: string
} & ImgHTMLAttributes<HTMLImageElement>

export const ImaginaryImageProcessor: React.FC<Props> = ({
  sourceUrl,
  processParameter: { outputType, quality = 70, width, height },
  sourceName,
  ownerId,
  style,
  ...props
}) => {
  const format = outputType === 'auto' ? 'webp' : outputType

  // Define the endpoint based on whether resizing is needed
  const endpoint = width || height ? 'resize' : 'convert'

  // Construct the final base URL
  const baseUrl = `${Envs.IMAGINARY_SERVER_URL}/${endpoint}`

  const processOptionsQueryString = new URLSearchParams({
    url: sourceUrl,
    ...(width && { width: String(width) }), // only add width if it's provided
    ...(height && { height: String(height) }), // only add height if it's provided
    ...(quality && { quality: String(quality) }), // only add quality if it's provided
    type: format,
  }).toString()

  const isBase64 = sourceUrl.startsWith('data:image/png;base64,') // Check if the source is a Base64 string
  const imaginaryUrl = `${baseUrl}?${processOptionsQueryString}`

  const processWithWidth = width * 2
  const sourceType = 'url'
  const bffAssetProcessImageUrl = `${
    Envs.BFF_ASSET_URL
  }/image/process?source_type=${sourceType}&processParameter_type=${endpoint}&processParameter_width=${processWithWidth}&processParameter_outputType=${outputType}&processParameter_quality=${quality}&source_ownerId=${ownerId}&source_url=${encodeURIComponent(
    sourceUrl,
  )}&source_name=${sourceName}`

  const urlToRedirect = ownerId && sourceName ? bffAssetProcessImageUrl : imaginaryUrl

  // Call imaginary if the source is not a Base64 string and the test mode is not enabled
  const finalUrl = isBase64 || isTestMode ? sourceUrl : urlToRedirect
  const [imgSrc, setImgSrc] = useState(finalUrl)

  const handleImageError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    e.currentTarget.onerror = null
    setImgSrc(sourceUrl)
  }

  // eslint-disable-next-line jsx-a11y/alt-text
  return <img src={imgSrc} width={width} onError={handleImageError} style={style} height={height} {...props} />
}
