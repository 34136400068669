import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation } from '@tanstack/react-query'
import { GenerateWhitelistLinkRequest } from '../../domain/dtos/whitelist'
import { QueryKey } from '../../domain/enums/query-key'
import { GenerateWhitelistLink } from '../../usecases/generate-whitelist-link'

export const useGenerateWhitelistLink = () => {
  const container = useContainer()

  return useMutation(
    [QueryKey.generateWhitelistLink],
    async (input: GenerateWhitelistLinkRequest) =>
      await new GenerateWhitelistLink(container.get(HttpClient)).execute(input),
    {
      retry: false,
    },
  )
}
