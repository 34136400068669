import { Skeleton, TableCell, TableRow } from '@hub-la/shadcn'

type Props = {
  schema: string[]
}
export const RuleTableLoading = ({ schema }: Props) => {
  return (
    <TableRow>
      {schema.map((_, index) => (
        <TableCell key={index}>
          <Skeleton className="h-6 w-[70%]" />
        </TableCell>
      ))}

      <TableCell>
        <Skeleton className="h-6 w-[70%]" />
      </TableCell>

      <TableCell>
        <Skeleton className="h-6 w-[70%]" />
      </TableCell>
    </TableRow>
  )
}
