import * as Yup from 'yup'

export const saveRuleSchema = Yup.object().shape({
  products: Yup.array().min(1),
  offers: Yup.array().min(1),
  events: Yup.array().min(1),
  lists: Yup.array().max(1),
  tags: Yup.array().min(1),
})

export const ruleSchema = ['products', 'offers', 'events', 'lists', 'tags']
