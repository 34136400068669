import { Card, CardHeader, Skeleton } from '@hub-la/shadcn'

export const Loading = () => {
  return (
    <div className="w-full h-full">
      <Card className="min-h-[138px]">
        <CardHeader className="space-y-0 gap-1">
          <Skeleton className="animate-pulse bg-secondary h-5 w-4/5 rounded" />
          <Skeleton className="animate-pulse bg-secondary h-9 w-3/5 rounded" />
          <Skeleton className="animate-pulse bg-secondary h-5 w-full rounded" />
        </CardHeader>
      </Card>
    </div>
  )
}
