import { Asset, AssetMetadata, UploadStatus } from '@hub-la/fe-asset'
import { getHumanizedFileSize } from '@hub-la/fe-post'
import { File } from 'lucide-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { AttachedProgress } from '../../../../domain/dtos/attached-progress'

export interface IAttachmentStatus {
  asset: Asset
  isAvailable: boolean
  status: UploadStatus
  attachedAssets
  attachedProgress: Array<AttachedProgress> | null
}

export const AttachmentStatus: React.FC<IAttachmentStatus> = ({
  status,
  asset,
  isAvailable,
  attachedProgress,
  attachedAssets,
}: IAttachmentStatus): JSX.Element => {
  const { t } = useTranslation('translation')

  if (isAvailable) {
    const { size } = asset.metadata as AssetMetadata

    return (
      <div className="flex gap-1 items-center text-surface">
        <File size={16} />

        <span className="text-sm text-primary">{getHumanizedFileSize(size, true, 0)}</span>
      </div>
    )
  }

  switch (status) {
    case UploadStatus.CANCELED:
      return <span className="text-sm text-warning">{t('assets.canceled')}</span>
    case UploadStatus.ERROR:
      return <span className="text-sm text-error">{t('assets.canceled')}</span>
    case UploadStatus.STARTED:
      if (asset.file) {
        const { size } = asset.file

        return (
          <div className="flex gap-1 items-center text-surface">
            <File size={16} />

            <span className="text-sm text-primary">{getHumanizedFileSize(size, true, 0)}</span>
          </div>
        )
      }
      return <></>
    case UploadStatus.LOADING:
    case UploadStatus.LOADED:
      if (
        attachedProgress &&
        attachedAssets &&
        attachedProgress.findIndex((el) => el.attachedId === asset.id && el.progress !== 100) !== -1
      ) {
        const attachmentList = document.getElementById('publication-attachment-list')

        if (attachmentList !== null) {
          const sizeScroll = attachmentList?.scrollWidth
          attachmentList.scrollLeft = sizeScroll
        }

        return (
          <span className="text-sm text-primary">
            {t('mediaUpload.progress', {
              progress: Math.round(
                attachedProgress[attachedProgress.findIndex((el) => el.attachedId === asset.id)]?.progress,
              ),
            })}
          </span>
        )
      }

      if (asset.file) {
        const { size } = asset.file
        if (
          attachedProgress &&
          attachedProgress.findIndex((el) => el.attachedId === asset.id && el.progress === 100) !== -1
        ) {
          return (
            <div className="flex gap-1 items-center text-surface">
              <File size={16} />

              <span className="text-sm text-primary">{getHumanizedFileSize(size, true, 0)}</span>
            </div>
          )
        } else {
          // initial state filesize
          return (
            <div className="flex gap-1 items-center text-surface">
              <File size={16} />

              <span className="text-sm text-primary">{getHumanizedFileSize(size, true, 0)}</span>
            </div>
          )
        }
      }
      return <></>
    default:
      return <></>
  }
}
