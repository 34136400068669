import { Skeleton } from '@hub-la/shadcn'
import { DescriptionLoading } from './components/description-loading'
import { HeaderLoading } from './components/header-loading'
import { PaymentDetailsLoading } from './components/payment-details-loading'

export const Loading = () => {
  return (
    <>
      <Skeleton className="h-6 w-20" />

      <div className="flex flex-col gap-4">
        <HeaderLoading />

        <PaymentDetailsLoading />

        <DescriptionLoading />
      </div>
    </>
  )
}
