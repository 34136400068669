import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation } from '@tanstack/react-query'
import { PutMembersAreaSettingsRequest } from '../../domain/dtos'
import { PutMembersArea } from '../../usecases/put-members-area'

export const usePutMembersArea = () => {
  const container = useContainer()

  const { isSuccess, isError, error, isLoading, data, mutateAsync } = useMutation(
    [],
    (values: PutMembersAreaSettingsRequest) => {
      return new PutMembersArea(container.get(HttpClient)).execute(values)
    },
  )

  return {
    isSuccess,
    isError,
    error,
    isLoading,
    data,
    mutateAsync,
  }
}
