import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { UpdateReceiverStatusInput } from '../domain/dtos/update-receiver-status-input'
import { ReceiverStatus } from '../domain/enums/receiver-status'
import { GeneralError } from '../domain/errors/general'
import { Envs } from '../envs'

export class UpdateReceiverStatus {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(input: UpdateReceiverStatusInput): Promise<void> {
    const response = await this.httpClient.request({
      method: HttpMethod.POST,
      url: this.makeUrl(input.status),
      body: { data: { id: input.id, termsText: input.termsText } },
    })

    if ([HttpStatusCode.OK, HttpStatusCode.CREATED].includes(response.statusCode)) {
      return response.data
    }

    throw new GeneralError()
  }

  private makeUrl(status: ReceiverStatus): string {
    switch (status) {
      case ReceiverStatus.ACCEPTED:
        return `${Envs.FUNCTIONS_URL}/receiver/accept/pt`
      case ReceiverStatus.REJECTED:
        return `${Envs.FUNCTIONS_URL}/receiver/reject/pt`
      case ReceiverStatus.CANCELED:
        return `${Envs.FUNCTIONS_URL}/receiver/acceptCancellation/pt`
      case ReceiverStatus.CANCELLATION_REQUESTED:
        return `${Envs.FUNCTIONS_URL}/receiver/cancelRequestCancellation/pt`
      default:
        return ''
    }
  }
}
