import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useQuery } from '@tanstack/react-query'
import { GetIntegrationHistoriesInput } from '../../domain/dtos/get-integration-histories-input'
import { QueryKey } from '../../domain/dtos/query-keys'
import { GetHistories } from '../../usecases/get-histories'

export const useGetHistories = (input: GetIntegrationHistoriesInput, isLoading: boolean) => {
  const container = useContainer()

  const initialData = {
    page: 1,
    pageSize: 0,
    total: 0,
    lastPage: 1,
    items: [],
  }

  return useQuery(
    [QueryKey.getIntegrationHistories, JSON.stringify(input)],
    ({ signal }) => new GetHistories(container.get(HttpClient)).execute(input, signal),
    {
      retry: 3,
      retryOnMount: false,
      refetchOnWindowFocus: false,
      initialData,
      enabled: !isLoading,
    },
  )
}
