const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
  },
}

type BreakpointKey = keyof typeof breakpoints.values

export const useUtility = () => {
  return {
    breakpoints: {
      down: (key: BreakpointKey) => `(max-width: ${breakpoints.values[key]}px)`,
      up: (key: BreakpointKey) => `(min-width: ${breakpoints.values[key]}px)`,
    },
  }
}
