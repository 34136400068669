import React from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { InstallmentsSelect } from '../../../../../components/installments-select'

export const MaxInstallmentsSelector: React.FC = () => {
  const { t } = useTranslation()

  return (
    <Controller
      render={({ field: { onChange, value, name }, fieldState }) => (
        <InstallmentsSelect
          label={t('offer.pricingTab.bnplSettings.maxInstallments.label')}
          minInstallment={2}
          maxInstallment={12}
          error={Boolean(fieldState.error?.type)}
          helperText={fieldState.error?.type && t(`fieldErrors.${fieldState.error?.type}`)}
          onChange={onChange}
          value={value}
          name={name}
        />
      )}
      name="checkout.bnplSettings.maxInstallments"
    />
  )
}
