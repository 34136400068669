import { PostEventType, PostType } from '@hub-la/fe-post'
import {
  Button,
  Calendar,
  cn,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Switch,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@hub-la/shadcn'
import { useFormikContext } from 'formik'
import { CalendarIcon, Info, SquarePlay, Video } from 'lucide-react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { Values } from '../../../domain/dtos/values'
import { GetEventProviderByLink } from '../../../usecases/get-event-provider-by-link'
import { GetIntervalOptions } from '../../../usecases/get-interval-options'
import { isThirtyDaysFromToday } from '../../../usecases/is-thirty-days-from-today'
import { WhenNotifyMembersText } from './when-notify-members-text'

type Props = {
  postType: PostType | null
}

export const EventForm = ({ postType }: Props) => {
  const { t } = useTranslation()
  const { values, setFieldValue, handleChange } = useFormikContext<Values>()

  const startIntervalOptions = new GetIntervalOptions().execute()
  const endIntervalOptions = new GetIntervalOptions().execute(values.event?.start)
  const isStartThirtyDaysFromToday = new isThirtyDaysFromToday().execute(values.event?.date)

  const provider = new GetEventProviderByLink().execute(values.event?.link)

  const providers = {
    [PostEventType.discord]: <Video className="w-5 h-5" />,
    [PostEventType.zoom]: <Video className="w-5 h-5" />,
    [PostEventType.youtube]: <SquarePlay className="w-5 h-5" />,
    [PostEventType.meets]: <Video className="w-5 h-5" />,
    [PostEventType.other]: <Video className="w-5 h-5" />,
  }

  const Provider = providers[provider]

  if (postType !== 'event') {
    return null
  }

  return (
    <div className="flex flex-col gap-6">
      <h3 className="text-2xl font-semibold">{t('eventForm.title')}</h3>

      <div className="flex items-center space-x-2">
        {Provider}
        <Input value={values.event?.link} id="event.link" onChange={handleChange} placeholder={t('eventForm.link')} />
      </div>

      <div className="flex gap-2">
        <Popover>
          <PopoverTrigger asChild className="flex-[3]">
            <Button
              variant="outline"
              className={cn(
                'w-full justify-start text-left font-normal',
                !values.event?.date && 'text-muted-foreground',
              )}
            >
              <CalendarIcon className="mr-2 h-4 w-4" />
              {values.event?.date ? (
                values.event.date.format('DD [de] MMM. [de] YYYY')
              ) : (
                <span>Selecione a data e hora</span>
              )}
            </Button>
          </PopoverTrigger>

          <PopoverContent>
            <Calendar
              mode="single"
              selected={values.event?.date ? values.event.date.toDate() : undefined}
              onSelect={(date) => setFieldValue('event.date', moment(date))}
            />
          </PopoverContent>
        </Popover>

        <Select
          value={values.event?.start}
          onValueChange={(value) => {
            setFieldValue('event.start', value)
            setFieldValue('event.end', '')
          }}
        >
          <SelectTrigger className="flex-1">
            <SelectValue placeholder={t('eventForm.startTime')} />
          </SelectTrigger>

          <SelectContent>
            {startIntervalOptions.map((option) => (
              <SelectItem key={option} value={option}>
                {option}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>

        <Select value={values.event?.end} onValueChange={(value) => setFieldValue('event.end', value)}>
          <SelectTrigger className="flex-1">
            <SelectValue placeholder={t('eventForm.endTime')} />
          </SelectTrigger>

          <SelectContent>
            {endIntervalOptions.map((option) => (
              <SelectItem key={option} value={option}>
                {option}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>

      <div className="flex justify-between items-center">
        <div className="flex items-center gap-2">
          <Switch
            checked={values.event?.notifyMembers}
            onCheckedChange={() => setFieldValue('event.notifyMembers', !values.event?.notifyMembers)}
          />

          <span>{t('eventForm.notifyGroup')}</span>
        </div>

        <div className="flex items-center gap-2">
          <span>{t('eventForm.knowMore')}</span>

          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <Info className="w-5 h-5" />
              </TooltipTrigger>

              <TooltipContent>
                <WhenNotifyMembersText />
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </div>

      {isStartThirtyDaysFromToday && (
        <div className="flex gap-2">
          <Info />
          {t('eventForm.alertStartThirtyDays')}
        </div>
      )}
    </div>
  )
}
