import { Button, Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@hub-la/shadcn'
import { useTranslation } from 'react-i18next'

type ConfirmDeleteRuleModalProps = {
  isOpen: boolean
  isDeleting: boolean
  onClose: () => void
  onConfirm: () => void
}

export const ConfirmDeleteRuleModal = (props: ConfirmDeleteRuleModalProps) => {
  const { isOpen, isDeleting, onClose, onConfirm } = props
  const { t } = useTranslation()

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{t('rules.deleteModal.title')}</DialogTitle>
        </DialogHeader>

        <div className="py-4" data-testid="confirm-delete-rule-modal-content">
          <p className="text-base">{t('rules.deleteModal.description')}</p>
        </div>

        <DialogFooter>
          <Button variant="outline" onClick={onClose} disabled={isDeleting}>
            {t('buttons.cancel')}
          </Button>

          <Button onClick={onConfirm} disabled={isDeleting} loading={isDeleting}>
            {t('buttons.confirm')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
