import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { Affiliation } from '../domain/dtos/affiliation'
import { ListAffiliationsInput } from '../domain/dtos/list-affilitions-input'
import { Envs } from '../envs'

export class ListAffiliations {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(input: ListAffiliationsInput): Promise<Affiliation[]> {
    const response = await this.httpClient.request({
      method: HttpMethod.POST,
      url: `${Envs.BFF_WEB_URL}/receivers/affiliate/list-affiliations`,
      body: { filters: input.filters },
    })

    if ([HttpStatusCode.OK, HttpStatusCode.CREATED].includes(response.statusCode)) {
      return response.data
    }

    throw new Error('Erro ao buscar afiliações')
  }
}
