export const Envs = {
  BFF_WEB_URL: process.env['NX_BFF_WEB_URL'] ?? process.env['REACT_APP_HUB_API'],
  APP_URL: process.env['NEXT_PUBLIC_APP_URL'] ?? process.env['REACT_APP_APP_URL'] ?? process.env['NX_APP_URL'],
  SITE_URL: process.env['NX_SITE_URL'] ?? process.env['NEXT_PUBLIC_SITE_URL'] ?? process.env['REACT_APP_SITE_URL'],
  BFF_PRODUCT_URL: process.env['NX_BFF_PRODUCT_URL'] ?? process.env['REACT_APP_BFF_PRODUCT_URL'],
  BFF_MEMBERS_AREA_URL: process.env['NX_BFF_MEMBERS_AREA_URL'] ?? process.env['REACT_APP_BFF_MEMBERS_AREA_URL'],
  FUNCTIONS_URL: process.env['NX_FUNCTIONS_URL'] ?? process.env['REACT_APP_FUNCTIONS_URL'],
  TELEGRAM_BOT: process.env['NX_TELEGRAM_BOT'] ?? process.env['REACT_APP_TELEGRAM_BOT'],
  TELEGRAM_USERNAME: process.env['NX_TELEGRAM_BOT_USERNAME'] ?? process.env['REACT_APP_TELEGRAM_BOT_USERNAME'],
  BFF_ACCESS_URL: process.env['NX_BFF_ACCESS_URL'] ?? process.env['REACT_APP_BFF_ACCESS_URL'],
}
