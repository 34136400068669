export const pt = {
  title: 'Cupons',
  status: {
    ACTIVE: 'Ativo',
    INACTIVE: 'Inativo',
    EXPIRED: 'Expirado',
  },
  deleteCoupon: 'Deletar cupom',
  activateCoupon: 'Ativar cupom',
  pauseCoupon: 'Pausar cupom',
  shareCoupon: 'Compartilhar',
  goBack: 'Voltar',
  tooltipCopiedLabel: 'Id copiado!',
  tooltipCopyLabel: 'Copiar Id',
  table: {
    pageSize: '{{total}} por página',
  },
  empty: 'Nenhum cupom encontrado',
  emptySubtitle: 'Ainda não há cupons criados. Adicione cupons para começar a disponibilizá-los para seus clientes.',
  couponTable: {
    tooltip: {
      code: 'Código utilizado para o cupom.',
      status: 'Status atual do cupom.',
      usageCount: 'Quantidade de cupons utilizados.',
    },
  },
  couponDetails: {
    title: 'Detalhes do cupom',
    id: 'ID do cupom',
    createdAt: 'Data de criação',
    validUntil: 'Expira em',
  },
  exportXlsx: {
    email: 'Email para envio do relatório',
    title: 'Exportar faturas',
    smartInstallmentsTitle: 'Exportar parcelamento inteligente',
    cancel: 'Cancelar',
    confirm: 'Exportar',
    noOptions: 'Não há opções disponíveis',
    loading: 'Carregando...',
    products: 'Produtos',
    statuses: 'Status',
  },
  description: {
    title: 'Cupom de desconto',
    discount: 'Desconto',
    products: 'Produto(s)',
    plans: 'Plano(s)',
    paymentMethod: 'Método de pagamento',
    usagesCount: 'Utilização',
    isRecurrent: 'Recorrente para renovação',
    productsContent: {
      every: 'Todos os produtos',
      many: '{{products}} e <0>{{count}} outros</0>',
      specific: '{{products}}',
    },
  },
  paymentMethod: {
    credit_card: 'Cartão de crédito',
    pix: 'Pix',
    boleto: 'Boleto',
  },
  actions: {
    share: 'Copiar link do cupom',
    deactivate: 'Inativar cupom',
    activate: 'Ativar cupom',
    delete: 'Deletar',
  },
  share: {
    title: 'Compartilhe o link de checkout com cupom aplicado',
    subtitle:
      'Compartilhe o link de desconto com seus clientes. O desconto será aplicado automaticamente no checkout quando os clientes usarem o link.',
    copied: 'Link copiado com sucesso!',
  },
  planType: {
    MONTHLY: 'Mensal',
    QUARTERLY: 'Trimestral',
    SEMIANNUALLY: 'Semestral',
    ANNUALLY: 'Anual',
    LIFETIME: 'Pagamento único',
  },
  copyClipboard: {
    copyLink: 'Copiar link',
    linkcopied: 'Link copiado',
  },
  createCoupon: {
    title: 'Criar cupom de desconto',
    code: 'Código',
    codeHelperText: '{{current}}/{{maxLength}} dígitos restantes.',
    discountPercent: 'Desconto (%)',
    discountPercentHelperText: 'Entre 0% e 90%.',
    isOneTimeOffer: 'Em qual tipo de produto o cupom poderá ser utilizado?',
    applyToSpecificIntervals: 'Aplicar a planos específicos',
    intervals: 'Escolher planos',
    noOptions: 'Não há opções disponíveis',
    loading: 'Carregando...',
    applyToSpecificOffers: 'Aplicar a ofertas específicas',
    offers: 'Escolher ofertas',
    isLimitUsageEnabled: 'Limitar o número de cupons disponíveis',
    maxAllowedTotalUsages: 'Quantidade de cupons disponíveis',
    isValidUntilEnabled: 'Configurar uma data de expiração',
    isRecurrent: 'Aplicar desconto automático em renovações',
    cancel: 'Cancelar',
    confirm: 'Criar cupom',
    alreadyExists: 'Código ja em uso no momento. Tente outro código.',
    useOutsideCheckout: 'Permitir o uso do cupom fora do momento de compra',
  },
  filters: {
    search: 'Buscar',
    searchCode: 'Buscar código',
    create: 'Criar cupom',
    export: 'Exportar',
    autocomplete: {
      noOptions: 'Não há opções disponíveis',
      loading: 'Carregando...',
      products: 'Produtos',
    },
  },
  count: '{{count}} cupons',
  errors: {
    general: 'Algo deu errado. Tente novamente mais tarde.',
    'coupon-code-invalid': 'Código de cupom inválido.',
    'coupon-valid-until-invalid-value': 'Data de expiração inválida.',
    'coupon-items-invalid-amount': 'Itens inválidos.',
    'coupon-code-already-in-use': 'Erro ao criar o cupom {{couponCode}}. Tente novamente.',
  },
  success: {
    delete: 'Cupom deletado com sucesso!',
    activate: 'Cupom ativado com sucesso!',
    deactivate: 'Cupom desativado com sucesso!',
    create: 'Cupom {{couponCode}} foi criado com sucesso!',
  },
}
