import { Calendar, cn, Input, Label, Popover, PopoverContent, PopoverTrigger } from '@hub-la/shadcn'
import { ptBR } from 'date-fns/locale'
import { CalendarIcon } from 'lucide-react'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const CustomDatePicker = ({ value, setFieldValue }) => {
  const [inputValue, setInputValue] = useState('')
  const [isInvalid, setIsInvalid] = useState(false)

  const { t } = useTranslation()

  useEffect(() => {
    setInputValue(value ? moment(value).format('DD/MM/YYYY') : '')
    setIsInvalid(false)
  }, [value])

  const applyDateMask = (value) => {
    const cleanValue = value.replace(/\D/g, '')
    if (cleanValue.length <= 2) {
      return cleanValue
    } else if (cleanValue.length <= 4) {
      return `${cleanValue.slice(0, 2)}/${cleanValue.slice(2)}`
    } else {
      return `${cleanValue.slice(0, 2)}/${cleanValue.slice(2, 4)}/${cleanValue.slice(4, 8)}`
    }
  }

  const handleInputChange = (event) => {
    const newValue = applyDateMask(event.target.value)
    setInputValue(newValue)

    if (newValue.length === 10) {
      const parsedDate = moment(newValue, 'DD/MM/YYYY', true)
      if (
        parsedDate.isValid() &&
        !parsedDate.isAfter(moment()) &&
        !parsedDate.isBefore(moment().subtract(120, 'years'))
      ) {
        setIsInvalid(false)
        setFieldValue('identity.birthday', parsedDate.toDate())
      } else {
        setIsInvalid(true)
        setFieldValue('identity.birthday', null)
      }
    } else {
      setIsInvalid(false)
      setFieldValue('identity.birthday', null)
    }
  }

  const handleDateSelect = (date) => {
    if (date) {
      const formattedDate = moment(date).format('DD/MM/YYYY')
      setInputValue(formattedDate)
      setFieldValue('identity.birthday', date)
      setIsInvalid(false)
    }
  }

  return (
    <div className="flex flex-col">
      <div className="relative space-y-1.5">
        <Label>{t('birthday')}</Label>
        <Input
          value={inputValue}
          onChange={handleInputChange}
          placeholder="DD/MM/AAAA"
          className={`pr-10 ${isInvalid ? 'border-red-500 focus:ring-red-500' : ''}`}
          id="identity.birthday"
          maxLength={10} // Limita a entrada para 10 caracteres no formato DD/MM/YYYY
        />
        <Popover>
          <PopoverTrigger asChild>
            <CalendarIcon className="absolute right-3 top-[43px] transform -translate-y-1/2 h-5 w-5 text-muted-foreground cursor-pointer" />
          </PopoverTrigger>
          <PopoverContent className="z-[1000] w-auto p-0 " align="end">
            <Calendar
              mode="single"
              selected={value ? new Date(value) : undefined}
              onSelect={handleDateSelect}
              defaultMonth={value ? new Date(value) : undefined}
              showYearSwitcher
              locale={ptBR}
              disabled={(date) =>
                moment(date).isAfter(moment().endOf('day')) || moment(date).isBefore(moment().subtract(120, 'years'))
              }
            />
          </PopoverContent>
        </Popover>
      </div>
      {isInvalid && <p className={cn('text-sm mt-2', 'text-red-500')}>{isInvalid ? 'Data inválida' : ''}</p>}
    </div>
  )
}

export default CustomDatePicker
