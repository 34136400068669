import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useQuery } from '@tanstack/react-query'
import { GetShortLinksInput } from '../../../domain/dtos/get-short-links-input'
import { GetShortLinksOutput } from '../../../domain/dtos/get-short-links-output'
import { QueryKey } from '../../../domain/enums/query-key'
import { GetShortLinks } from '../../../usecases/shortlinks/get-short-links'

export const useGetShortLinks = (input: GetShortLinksInput) => {
  const container = useContainer()

  return useQuery<GetShortLinksOutput>(
    [QueryKey.getShortLinks, input.productId],
    ({ signal }) => new GetShortLinks(container.get(HttpClient)).execute(input.productId, signal),
    {
      retry: false,
      retryOnMount: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    },
  )
}
