interface InfoRowProps {
  label: string
  value?: string
}

export const InfoRow = ({ label, value }: InfoRowProps) => (
  <div className="flex items-center justify-between space-x-2">
    <span className="text-sm text-muted-foreground">{label}:</span>
    <span className="text-sm text-right">{value}</span>
  </div>
)
