import { Alert, AlertDescription, AlertTitle, Button, Input, Label } from '@hub-la/shadcn'
import { FormikProvider, useFormik } from 'formik'
import { useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useTranslation } from 'react-i18next'
import { VerifyCodeInvalidError } from '../../../domain/errors/verify-code-invalid-error'
import { useAuthRedirect } from '../../hooks/use-auth-redirect'
import { usePutCompleteRegistration } from '../../hooks/use-put-complete-registration'
import { completeRegistrationVerifySchema } from '../../validations/complete-registration-verify'
import { AuthCard } from '../auth-card/auth-card'
import { CountdownButton } from '../countdown-button'

type CompleteRegistrationVerifyCardProps = {
  authId?: string
  email: string
  password: string
  nextRetryDate?: Date | string
  retryCode: () => void
  loadingRetry?: boolean
}

const CompleteRegistrationVerifyCard = ({
  authId,
  email,
  password,
  nextRetryDate,
  retryCode,
  loadingRetry,
}: CompleteRegistrationVerifyCardProps) => {
  const [showSkipButton, setShowSkipButton] = useState<boolean>(false)

  const { t } = useTranslation()
  const { redirectAutoIfAuthenticated } = useAuthRedirect()
  const { executeRecaptcha } = useGoogleReCaptcha()

  const { isError, error, mutateAsync, isLoading } = usePutCompleteRegistration({
    onSuccess: () => {
      redirectAutoIfAuthenticated()
    },
    onError: (error) => {
      if (!(error instanceof VerifyCodeInvalidError)) {
        setShowSkipButton(true)
      }
    },
  })
  const form = useFormik({
    initialValues: {
      code: '',
    },
    onSubmit: async (values) => {
      if (!authId) return
      const captcha = await executeRecaptcha?.('complete_registration_verify')

      await mutateAsync({ code: parseInt(values.code), authId, password, captcha })
    },
    validationSchema: completeRegistrationVerifySchema,
    validateOnChange: true,
    validateOnMount: true,
  })

  return (
    <AuthCard
      showTerms={false}
      title="Verifique seu e-mail"
      description={
        <>
          Enviamos um código de verificação para
          <br />
          <strong>{email}</strong>
        </>
      }
    >
      <FormikProvider value={form}>
        <form onSubmit={form.handleSubmit} className="space-y-4 w-full">
          <div className="space-y-2">
            <Label htmlFor="code">Código de verificação</Label>
            <Input
              id="code"
              name="code"
              type="text"
              autoComplete="one-time-code"
              onChange={form.handleChange}
              value={form.values.code}
              className={form.touched.code && !!form.errors.code ? 'border-red-500' : ''}
            />
            {form.touched.code && !!form.errors.code && <p className="text-sm text-red-500">{t(form.errors.code)}</p>}
          </div>
          <Button type="submit" className="w-full" disabled={!form.isValid || isLoading}>
            {isLoading ? 'Confirmando...' : 'Confirmar código'}
          </Button>

          {nextRetryDate && (
            <CountdownButton endDate={nextRetryDate} onClick={() => retryCode()} isLoading={loadingRetry}>
              Reenviar código de verificação
            </CountdownButton>
          )}
          {showSkipButton && (
            <Button variant="outline" className="w-full" onClick={() => redirectAutoIfAuthenticated()} type="button">
              Pular essa etapa
            </Button>
          )}
        </form>
      </FormikProvider>

      {isError && (
        <Alert variant="destructive" className="mt-4">
          <AlertTitle>Erro</AlertTitle>
          <AlertDescription>{t((error as Error)?.message)}</AlertDescription>
        </Alert>
      )}
    </AuthCard>
  )
}

export { CompleteRegistrationVerifyCard }
