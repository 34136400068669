import { UpdateProductSettingsPageRequestDto } from '@hub-la/sdk-bff-product'
import { Input, Label, Switch } from '@hub-la/shadcn'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { Section } from '../../../components/section'
import { useSaveSettings } from '../../../hooks/settings/use-save-settings'

type Values = Pick<UpdateProductSettingsPageRequestDto, 'buyCta'>
const validationSchema = Yup.object().shape({})
const makeInitialValues = ({ buyCta }: Values): Values => {
  return {
    buyCta: {
      buttonText: buyCta?.buttonText ?? '',
      isEnabled: buyCta?.isEnabled ?? false,
    },
  }
}

type Props = {
  buyCta?: Values['buyCta']
  loading?: boolean
}

export const BuyCtaSection: React.FC<Props> = ({ buyCta, loading }) => {
  const { productId } = useParams<{ productId: string }>()
  const { mutateAsync: saveSettings } = useSaveSettings()
  const { t } = useTranslation()

  const formik = useFormik<Values>({
    validationSchema,
    validateOnMount: true,
    validateOnChange: true,
    enableReinitialize: true,
    initialValues: makeInitialValues({ buyCta }),
    onSubmit: async (values) => {
      await saveSettings({ productId, type: 'page', data: values })
    },
  })

  return (
    <Section
      title={t('settings.productPage.buyCta.title')}
      subtitle={t('settings.productPage.buyCta.subtitle')}
      formik={formik}
    >
      <div className="space-y-4">
        <div className="flex items-center space-x-2">
          <Switch
            id="buyCta-isEnabled"
            checked={formik.values.buyCta?.isEnabled}
            onCheckedChange={(checked) => formik.setFieldValue('buyCta.isEnabled', checked)}
            disabled={loading}
          />

          <Label htmlFor="buyCta-isEnabled">{t('settings.productPage.buyCta.input.switch')}</Label>
        </div>

        <p className="text-sm text-muted-foreground">{t('settings.productPage.buyCta.disclaimer')}</p>

        {formik.values.buyCta?.isEnabled && (
          <div className="space-y-2">
            <Label htmlFor="buyCta-buttonText">{t('settings.productPage.buyCta.input.text')}</Label>

            <Input
              id="buyCta-buttonText"
              name="buyCta.buttonText"
              value={formik.values.buyCta?.buttonText}
              onChange={formik.handleChange}
              maxLength={25}
              disabled={loading}
            />

            <p className="text-sm text-muted-foreground">{`${formik.values.buyCta?.buttonText.length}/25 caracteres.`}</p>
          </div>
        )}
      </div>
    </Section>
  )
}
