import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { PostAddFreeMembersInput } from '../../../domain/dtos/post-add-free-members-input'
import { PostAddFreeMembersResponse } from '../../../domain/dtos/post-add-free-members-response'
import { QueryKey } from '../../../domain/enums/query-key'
import { PostAddFreeMembers } from '../../../usecases/members/post-add-free-members'

export const usePostAddFreeMembers = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (data: PostAddFreeMembersResponse | null) => void
  onError?: () => void
}) => {
  const container = useContainer()
  const queryClient = useQueryClient()

  return useMutation(
    [QueryKey.postAddFreeMembers],
    (input: PostAddFreeMembersInput) => new PostAddFreeMembers(container.get(HttpClient)).execute(input),
    {
      retry: false,
      onSuccess: (data) => {
        queryClient.invalidateQueries({ queryKey: [QueryKey.getPendingInvites] })

        if (onSuccess) {
          onSuccess(data)
        }
      },
      onError: () => {
        if (onError) {
          onError()
        }
      },
    },
  )
}
