import { UploadStatus } from '../dtos/upload-status'

export class MediaUploadPdfError extends Error {
  constructor() {
    super()
    this.message = 'mediaUpload.error.pdf'
  }

  public getErrorType(): UploadStatus {
    return UploadStatus.ERROR
  }
}
