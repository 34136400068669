import { PermissionResource, useRoleplay } from '@hub-la/fe-roleplay'
import { Button, CopyClipboardButton, Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@hub-la/shadcn'
import { Trash2 } from 'lucide-react'
import { useTranslation } from 'react-i18next'

type RowInviteActionsProps = {
  onCancelInvite: () => void
  onCopyInviteLink: () => string
}

export const RowInviteActions = ({ onCancelInvite, onCopyInviteLink }: RowInviteActionsProps) => {
  const { t } = useTranslation()
  const { hasPermission } = useRoleplay()

  return (
    <div className="flex items-center justify-end space-x-2">
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <CopyClipboardButton
              copyContent={onCopyInviteLink()}
              tooltipCopiedLabel={t('copyClipboard.inviteLinkCopied')}
              tooltipCopyLabel={t('copyClipboard.copyInviteLink')}
            />
          </TooltipTrigger>
          <TooltipContent>
            <p>{t('tabs.invites.rowActions.copyLinkTooltip')}</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>

      {hasPermission(PermissionResource.PRODUCTS_MANAGE_MEMBERS) && (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button variant="ghost" size="icon" onClick={onCancelInvite}>
                <Trash2 className="h-4 w-4" />
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>{t('tabs.invites.rowActions.deleteInviteTooltip')}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      )}
    </div>
  )
}
