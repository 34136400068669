import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { ExternalContentDto } from '@hub-la/sdk-bff-product'
import { inject } from 'inversify'
import { GeneralError } from '../../domain/errors/general'
import { Envs } from '../../envs'

export class GetExternalMemberArea {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute({ productId }: { productId: string }): Promise<ExternalContentDto> {
    const response = await this.httpClient.request({
      method: HttpMethod.GET,
      url: `${Envs.BFF_PRODUCT_URL}/external-contents`,
      params: { productId },
    })

    if ([HttpStatusCode.OK, HttpStatusCode.CREATED].includes(response.statusCode)) {
      /** Since we can only set one external member area on the product, it's safe to assume that the
       * first position will always contain the desired data */
      return Array.isArray(response.data) && response.data.length > 0 ? response.data[0] : null
    }

    throw new GeneralError()
  }
}
