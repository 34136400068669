/* tslint:disable */
/* eslint-disable */
/**
 * BFF Web Product
 * API reference used to call the backend from the web frontend
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { EditOfferAfterPurchaseSettingsDto } from './edit-offer-after-purchase-settings-dto';
// May contain unused imports in some cases
// @ts-ignore
import { EditOfferCheckoutSettingsDto } from './edit-offer-checkout-settings-dto';
// May contain unused imports in some cases
// @ts-ignore
import { EditOfferPricingSettingsDto } from './edit-offer-pricing-settings-dto';
// May contain unused imports in some cases
// @ts-ignore
import { FunnelStrategyDto } from './funnel-strategy-dto';
// May contain unused imports in some cases
// @ts-ignore
import { UserRefDto } from './user-ref-dto';

/**
 * 
 * @export
 * @interface EditOfferResponseDto
 */
export interface EditOfferResponseDto {
    /**
     * 
     * @type {string}
     * @memberof EditOfferResponseDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditOfferResponseDto
     */
    'name': string;
    /**
     * 
     * @type {EditOfferPricingSettingsDto}
     * @memberof EditOfferResponseDto
     */
    'pricing': EditOfferPricingSettingsDto;
    /**
     * 
     * @type {EditOfferCheckoutSettingsDto}
     * @memberof EditOfferResponseDto
     */
    'checkout': EditOfferCheckoutSettingsDto;
    /**
     * 
     * @type {EditOfferAfterPurchaseSettingsDto}
     * @memberof EditOfferResponseDto
     */
    'afterPurchase': EditOfferAfterPurchaseSettingsDto;
    /**
     * 
     * @type {FunnelStrategyDto}
     * @memberof EditOfferResponseDto
     */
    'orderBump'?: FunnelStrategyDto;
    /**
     * 
     * @type {FunnelStrategyDto}
     * @memberof EditOfferResponseDto
     */
    'upsell'?: FunnelStrategyDto;
    /**
     * 
     * @type {boolean}
     * @memberof EditOfferResponseDto
     */
    'isPublished': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EditOfferResponseDto
     */
    'isAvailableForAffiliates': boolean | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof EditOfferResponseDto
     */
    'cohortIds': Array<string>;
    /**
     * 
     * @type {UserRefDto}
     * @memberof EditOfferResponseDto
     */
    'owner': UserRefDto;
    /**
     * 
     * @type {string}
     * @memberof EditOfferResponseDto
     */
    'installmentCostsPayer': EditOfferResponseDtoInstallmentCostsPayerEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum EditOfferResponseDtoInstallmentCostsPayerEnum {
    BUYER = 'buyer',
    SELLER = 'seller'
}


