import { ContainerProvider } from '@hub-la/fe-container'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { I18nextProvider } from 'react-i18next'
import i18n from './i18n'

const queryClient = new QueryClient()

export const FreeMembersProvider = ({ children, container }) => {
  return (
    <ContainerProvider container={container}>
      <I18nextProvider i18n={i18n}>
        <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
      </I18nextProvider>
    </ContainerProvider>
  )
}
