import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation } from '@tanstack/react-query'
import { DownloadCouponsXlsxInput } from '../../domain/dtos/download-coupon-xlsx-input'
import { QueryKey } from '../../domain/enums/query-key'
import { DownloadCouponsXlsx } from '../../usecases/download-coupons-xlsx'

export const useDownloadCouponsXlsx = (input: DownloadCouponsXlsxInput) => {
  const container = useContainer()

  return useMutation(
    [QueryKey.downloadCouponsXlsx],
    () => new DownloadCouponsXlsx(container.get(HttpClient)).execute(input),
    {
      retry: false,
    },
  )
}
