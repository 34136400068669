import { Card, CardContent, CardDescription, CardHeader, CardTitle, Switch } from '@hub-la/shadcn'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ModuleHighlightMockup from '../../../../assets/module-highlight-mockup.png'

const ToggleModulesHighlight = ({
  onChange,
  isLoading,
  defaultValue,
}: {
  onChange?: (checked: boolean) => void
  isLoading?: boolean
  defaultValue?: boolean
}) => {
  const [showThumbnailTitle, setShowThumbnailTitle] = useState<boolean | undefined>()

  const { t } = useTranslation()

  const handleToggle = useCallback(
    (checked: boolean) => {
      setShowThumbnailTitle(checked)
      if (onChange) {
        onChange(checked)
      }
    },
    [setShowThumbnailTitle, onChange],
  )

  useEffect(() => {
    if (!defaultValue) return
    setShowThumbnailTitle(defaultValue)
  }, [defaultValue])

  return (
    <Card className="w-full">
      <CardHeader>
        <CardTitle>{t('member-area.settings.toggleModuleHighlight.title')}</CardTitle>
        <CardDescription>{t('member-area.settings.toggleModuleHighlight.description')}</CardDescription>
      </CardHeader>

      <CardContent className="p-6 flex flex-col gap-4">
        <div className="flex items-center gap-2">
          <Switch
            id="show-thumbnail"
            checked={showThumbnailTitle}
            onCheckedChange={handleToggle}
            disabled={isLoading}
            data-testid="switch-thumbnail"
          />

          <label
            htmlFor="show-thumbnail"
            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            {t('member-area.settings.toggleModuleHighlight.options.thumbnail.label')}
          </label>
        </div>

        <p className="text-sm text-muted-foreground mt-3 mb-3">
          {t('member-area.settings.toggleModuleHighlight.options.thumbnail.description')}
        </p>

        <div className="max-h-80 lg:pl-20 lg:pt-8 rounded-md bg-muted relative overflow-hidden">
          <img
            className="w-full rounded-md"
            src={ModuleHighlightMockup}
            alt="Imagem representando o módulo em destaque e modo conteúdo em destaque"
          />
        </div>
      </CardContent>
    </Card>
  )
}

export default ToggleModulesHighlight
