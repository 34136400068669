import { TableCell, TableRow } from '@hub-la/shadcn'

export const MyPartnersTableLoading = () => {
  return (
    <TableRow className="hover:bg-transparent">
      <TableCell className="p-0">
        <div className="flex items-center gap-2">
          <div className="w-10 h-10 bg-secondary animate-pulse rounded" />

          <div className="flex flex-col">
            <div className="flex items-center gap-2">
              <div className="w-44 h-4 bg-secondary animate-pulse rounded" />
            </div>
          </div>
        </div>
      </TableCell>

      <TableCell className="p-0">
        <div className="flex items-center gap-2">
          <div className="w-16 h-4 bg-secondary animate-pulse rounded" />
        </div>
      </TableCell>

      <TableCell className="p-0">
        <div className="flex items-center gap-2">
          <div className="w-16 h-4 bg-secondary animate-pulse rounded" />
        </div>
      </TableCell>

      <TableCell className="p-0">
        <div className="flex items-center gap-2">
          <div className="w-30 h-4 bg-secondary animate-pulse rounded" />
        </div>
      </TableCell>
    </TableRow>
  )
}
