import { Button } from "@hub-la/shadcn"
import { ChevronRight } from "lucide-react"
import { useHistory } from "react-router-dom"

type CardProps = {
  href: string
  icon: React.ReactNode
  text: string
}

export const ActionButton: React.FC<CardProps> = ({ href, icon, text }) => {
  const history = useHistory()

  return (
    <Button variant="secondary" className="w-full px-4 py-3" onClick={() => history.push(href)}>
      <div className="w-full flex items-center gap-2">
        {icon}
        <span className="text-sm">{text}</span>
      </div>
    </Button>
  )
}


