import { Tabs, TabsContent, TabsList, TabsTrigger } from '@hub-la/shadcn'
import { memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { CreateProduct } from '../components/create-product'
import { Products } from './products'
import { Partnerships } from './partnerships'
import { Affiliates } from './affiliates'

type DashboardProductTabTypes = 'products' | 'invites' | 'affiliates'

export const DashboardProducts = memo(() => {
  const [tab, setTab] = useState<DashboardProductTabTypes>('products')

  const history = useHistory()
  const { t } = useTranslation()

  /** @todo The #products is temporarily due to the lack of navigation on chatpay-firebase */
  /** @description This have to be set to properly go back when seeing a subscription detail */
  const onTabChange = (newTab: string) => {
    history.push(`${history.location.pathname}?tab=${newTab}`)
    setTab(newTab as DashboardProductTabTypes)
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const tabParam = urlParams.get('tab')
    if (tabParam) {
      setTab(tabParam as DashboardProductTabTypes)
    }
  }, [])

  return (
    <div className="flex flex-col gap-2">
      <div className="flex justify-between items-center">
        <h1 className="text-3xl leading-tight font-semibold">{t('productsTitle')}</h1>

        {tab === 'products' && <CreateProduct />}
      </div>

      <Tabs value={tab} onValueChange={onTabChange}>
        <div className="w-full overflow-x-auto border-b">
          <TabsList variant="underlined">
            <TabsTrigger value="products" variant="underlined">
              {t('tabs.products')}
            </TabsTrigger>

            <TabsTrigger value="invites" variant="underlined">
              {t('tabs.invites')}
            </TabsTrigger>

            <TabsTrigger value="affiliates" variant="underlined">
              {t('tabs.affiliates')}
            </TabsTrigger>
          </TabsList>
        </div>

        <TabsContent value="products">
          <Products />
        </TabsContent>

        <TabsContent value="invites">
          <Partnerships />
        </TabsContent>

        <TabsContent value="affiliates">
          <Affiliates />
        </TabsContent>
      </Tabs>
    </div>
  )
})
