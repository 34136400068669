import { useParams } from 'react-router-dom'
import { useGetSettings } from '../../../hooks/settings/use-get-settings'
import { AdvantagesSection } from './advantages'
import { BuyCtaSection } from './buy-cta'
import { CoverSection } from './cover'
import { DescriptionSection } from './description'
import { FaqSection } from './faq'
import { MediaSection } from './media'

export const ProductPage = () => {
  const { productId } = useParams<{ productId: string }>()
  const { data, isLoading } = useGetSettings({ productId, type: 'page' })

  return (
    <div className="flex flex-col space-y-8">
      <CoverSection cover={data?.cover} />

      <BuyCtaSection buyCta={data?.buyCta} loading={isLoading} />

      <MediaSection assets={data?.assets} loading={isLoading} />

      <DescriptionSection description={data?.description} loading={isLoading} />

      <AdvantagesSection advantages={data?.advantages} loading={isLoading} />

      <FaqSection faq={data?.faq} loading={isLoading} />
    </div>
  )
}
