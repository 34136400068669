import { CohortDto } from '@hub-la/sdk-bff-product'
import {
  Button,
  Input,
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@hub-la/shadcn'
import { Info } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useGetCohortsList } from '../../../../../hooks/cohorts/use-get-cohorts-list'
import { useAddResource } from '../../../../../hooks/member-area/use-add-resource'
import { useGetAvailableResources } from '../../../../../hooks/member-area/use-get-available-resources'
import CohortsCard from '../cohorts-card'
import { useInitialState } from '../cohorts-card/get-initial-state'
import { ResourceCard } from './resource-card'

type Props = {
  open: boolean
  onClose: () => void
  offerId?: string
}

export const CreateGroupDrawer = (props: Props) => {
  const { open, onClose, offerId } = props
  const [selectedResource, setSelectedResource] = useState<string>()
  const [cohortSwitches, setCohortsSwitches] = useState<CohortDto[]>([])
  const [name, setName] = useState<string>('')

  const { productId } = useParams<{ productId: string }>()
  const { mutateAsync: addResource, isLoading: addingResource } = useAddResource()
  const { data: availableResources = [], isLoading: isLoadingResources } = useGetAvailableResources()
  const { data: cohortsData, isLoading: isLoadingGetCohorts } = useGetCohortsList({
    productId,
  })
  const { t } = useTranslation()
  const initialState = useInitialState({
    data: cohortsData?.items,
    cohorts: [],
    isLoadingData: isLoadingGetCohorts,
  })

  const onSubmit = async () => {
    if (!selectedResource) return
    await addResource({
      resourceId: selectedResource,
      offerId: offerId ?? '',
      cohortIds: cohortSwitches.map((cohort) => cohort.id),
      groupName: name,
    })
    onClose()
  }

  return (
    <Sheet open={open} onOpenChange={onClose}>
      <SheetContent className="sm:max-w-lg flex flex-col gap-6 overflow-y-auto">
        <SheetHeader>
          <SheetTitle>{t('member-area.groups.createGroupModal.title')}</SheetTitle>
        </SheetHeader>

        <div className="flex flex-col gap-6">
          <Input placeholder={t('Name')} id="groupName" onChange={(e) => setName(e.target.value)} value={name} />

          {availableResources.map((resource) => (
            <ResourceCard
              resource={resource}
              onChange={(resourceId) => setSelectedResource(resourceId)}
              selected={selectedResource === resource.id}
            />
          ))}

          <CohortsCard data={initialState} isLoadingData={isLoadingGetCohorts} onChange={setCohortsSwitches} />

          <Button disabled={!selectedResource || !name || isLoadingResources || addingResource} onClick={onSubmit}>
            {t('member-area.groups.createGroupModal.confirm')}
          </Button>

          <div className="flex items-center gap-2">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <Info className="h-4 w-4" />
                </TooltipTrigger>

                <TooltipContent>
                  <p>{t('member-area.groups.editResourceDrawer.toolTip')}</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>

            <p className="text-sm text-muted-foreground">{t('member-area.groups.createGroupModal.disclaimer')}</p>
          </div>
        </div>
      </SheetContent>
    </Sheet>
  )
}
