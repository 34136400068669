import { boolean, InferType, object, string } from 'yup'
import { YoutubeUrl } from '../../domain/vos/youtube-url'

const upsellSchemaValidation = object({
  productId: string().nullable().required(),
  offerId: string().nullable().required(),
  isYoutubeEmbed: boolean().required(),
  youtubeEmbedUrl: string().when('isYoutubeEmbed', {
    is: true,
    then: string()
      .url()
      .test('youtubeEmbedUrl', 'youtubeEmbedUrlError', (value) => {
        if (!value) {
          return false
        }

        const youtubeUrlIsValid = new YoutubeUrl(value).isValid()

        return youtubeUrlIsValid
      })
      .required(),
    otherwise: string().nullable(),
  }),
}).required()

export type UpsellSchemaValidationType = InferType<typeof upsellSchemaValidation>

export { upsellSchemaValidation }
