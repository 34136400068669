import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { DeleteRuleInput } from '../domain/dtos/delete-rule-input'
import { GeneralError } from '../domain/errors/general'
import { Envs } from '../envs'

export class DeleteRule {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient<void>,
  ) {}

  public async execute(input: DeleteRuleInput): Promise<void> {
    const response = await this.httpClient.request({
      method: HttpMethod.DELETE,
      url: `${Envs.BFF_WEB_URL}/integrations/${input.integrationId}/rules/${input.ruleId}`,
    })

    if (response.statusCode === HttpStatusCode.OK) {
      return
    }

    throw new GeneralError()
  }
}
