import { CohortDto } from '@hub-la/sdk-bff-product'

export class GetDefaultCohortId {
  public constructor() {}

  public execute(cohorts?: CohortDto[]) {
    const defaultCohort = (cohorts ?? [])?.find((cohort) => cohort?.isDefault)

    if (defaultCohort) {
      return defaultCohort?.id
    }

    return null
  }
}
