import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger, useIsMobile } from '@hub-la/shadcn'
import { Info } from 'lucide-react'

export const InfoTooltip = ({ title }: { title: string }) => {
  const isMobile = useIsMobile()

  return (
    <TooltipProvider>
      <Tooltip delayDuration={300}>
        <TooltipTrigger asChild>
          <Info className="h-4 w-4 text-muted-foreground cursor-pointer" />
        </TooltipTrigger>

        <TooltipContent side="top" className={isMobile ? 'touch-auto' : ''}>
          {title}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}
