import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'

import { useQuery } from '@tanstack/react-query'
import { ListProductsInput } from '../../domain/dtos/list-products-input'
import { QueryKey } from '../../domain/enums/query-key'
import { ListProducts } from '../../usecases/list-products'

export const useListProducts = (input: ListProductsInput) => {
  const container = useContainer()

  return useQuery(
    [QueryKey.listProducts, input.page, input.pageSize, input.orderBy, input.orderDir],
    ({ signal }) => new ListProducts(container.get(HttpClient)).execute(input, signal),
    {
      refetchOnWindowFocus: false,
      retry: 3,
    },
  )
}
