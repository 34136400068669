import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { GetMovementsOutput } from '../domain/dtos/get-movements-output'
import { GeneralError } from '../domain/errors/general'
import { Envs } from '../envs'

export class GetMovements {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(searchParams: string, after?: string): Promise<GetMovementsOutput> {
    const nextPage = after ? `&after=${after}` : ''

    const response = await this.httpClient.request({
      method: HttpMethod.GET,
      url: `${Envs.BFF_WEB_URL}/financial-statement/movements${searchParams}${nextPage}`,
    })

    if (response.statusCode === HttpStatusCode.OK) {
      return response.data
    }

    throw new GeneralError()
  }
}
