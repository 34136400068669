import { container } from '@hub-la/fe-container'
import { DashboardSales, DashboardSalesProvider } from '@hub-la/fe-dashboard-sales'

export const DashboardSalesInit = () => {
  return (
    <DashboardSalesProvider container={container}>
      <DashboardSales />
    </DashboardSalesProvider>
  )
}
