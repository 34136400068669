export const Events = {
  POST_DETAIL: {
    SIDEBAR_HEADER: {
      PREVIOUS_CLICKED: 'ContentPostSidebarHeaderPreviousClicked',
      NEXT_CLICKED: 'ContentPostSidebarHeaderNextClicked',
    },
    SIDEBAR_CONTENT: {
      SECTION_CLICKED: 'ContentPostSidebarContentSectionClicked',
      POST_CLICKED: 'ContentPostSidebarContentPostClicked',
    },
  },
  HUB: {
    POST_PDF_FULLSCREEN_CLICKED: 'ContentPostPdfFullscreenClicked',
    POST_VIEWED: 'ContentPostViewed',
    VIEWED: 'MyContentPageViewed',
    SUBMITTED: 'ContentPostSubmitted',
    REJECTED: 'ContentPageErrorViewed',
    CANCELED: 'ContentPageCancelDialogClicked',
    REMOVED: 'ContentPageRemoveDialogClicked',
    ALERT: 'ContentPageAlertViewed',
    CANCEL_DIALOG: 'ContentPageCancelDialogClicked',
    INVALID_FILE_EXTENSION: 'InvalidFileExtension',
    CONTENT_COVER: {
      EDIT_CLICKED: 'EditContentCoverClicked',
      ADD_IMAGE_SUBMITTED: 'EditContentCoverAddImageSubmitted',
      FINISH_CLICKED: 'EditContentCoverFinishClicked',
    },
    SECTION: {
      EDIT_CLICKED: 'ContentCreationSectionEditClicked',
      DELETE_CLICKED: 'ContentCreationSectionDeleteClicked',
      DELETE_SUCCESSFUL: 'ContentCreationSectionDeleteSuccessful',
      SAVE_CLICKED: 'ContentCreationSectionSaveClicked',
      ORDER_SUBMITTED: 'ContentCreationSectionOrderSubmitted',
      CREATE_CLICKED: 'ContentCreationCreateSectionClicked',
      MOVE_SUBMITTED: 'ContentCreationSectionMoveSubmitted',
    },
    PROGRESS: {
      SAVE_FAILED: 'ContentPostProgressSaveFailed',
      REPRODUCTION_ERROR: 'ContentPostProgressReproductionError',
      STARTED: 'ContentPostProgressStarted',
      ENDED: 'ContentPostProgressEnded',
    },
  },
  MAIN_MENU: {
    PROFILE_CLICKED: 'MainMenuProfileClicked',
    SWITCH_MODE_CLICKED: 'MainMenuUserModeSwitchClicked',
    MY_PRODUCTS_CLICKED: 'MainMenuMyProductsClicked',
    EDIT_PROFILE_CLICKED: 'MainMenuEditProfileClicked',
    VERIFY_BANK_ACCOUNT_CLICKED: 'MainMenuVerifyBankAccountClicked',
    BANK_ACCOUNT_CLICKED: 'MainMenuBankAccountClicked',
    AFFILIATE_SALES_CLICKED: 'MainMenuAffiliateSalesClicked',
    TIPS_CLICKED: 'MainMenuTipsClicked',
    FAQ_CLICKED: 'MainMenuFAQClicked',
    BLOG_CLICKED: 'MainMenuBlogClicked',
    LOGOUT_CLICKED: 'MainMenuLogoutClicked',
  },
}
