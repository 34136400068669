import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useQuery } from '@tanstack/react-query'
import { GetFlags } from '../../usecases/get-flags'
import { IsFlagEnabled } from '../../usecases/is-flag-enabled'

export const useFeatureFlag = () => {
  const container = useContainer()
  const getFlags = new GetFlags(container.get(HttpClient))

  const { data: flags = [], isLoading } = useQuery(['get-flags'], () => getFlags.execute(), {
    refetchOnWindowFocus: false,
    initialData: [],
  })

  const isFlagEnabled = (flag: string, data?: unknown) => new IsFlagEnabled(flags).isEnabled(flag, data)
  const getFlagValue = (flag: string, data?: unknown) => new IsFlagEnabled(flags).getValue(flag, data)
  const getFlagParams = (flag: string) => new IsFlagEnabled(flags).getParams(flag)

  return { isFlagEnabled, getFlagValue, getFlagParams, isLoading, flags }
}
