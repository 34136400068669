import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation } from '@tanstack/react-query'
import { GetAsset } from '../../usecases'

export const useGetAsset = () => {
  const container = useContainer()

  return useMutation([], (assetId: string) => new GetAsset(container.get(HttpClient)).execute(assetId))
}
