import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '../../domain/enums/query-keys'
import { GetSmartInstallment } from '../../usecases/get-smart-installment'

export const useGetSmartInstallment = (id: string) => {
  const container = useContainer()

  return useQuery(
    [QueryKey.getSmartInstallment, id],
    () => new GetSmartInstallment(container.get(HttpClient)).execute(id),
    {
      retry: 3,
      enabled: !!id,
    },
  )
}
