import { UploadStatus, useGetAssetsSignedUrl } from '@hub-la/fe-asset'
import { Button, Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@hub-la/shadcn'
import { FolderOpen, Plus, Upload, VideoIcon, X } from 'lucide-react'
import { useContext, useState } from 'react'
import { Video } from '../../../domain/dtos/video'
import { VideosUploaderContext } from '../../providers'
import { Input } from './input'

export const SendVideos = ({ open = false, onClose, sectionId }) => {
  const [videos, setVideos] = useState<Video[]>([])
  const { add } = useContext(VideosUploaderContext)
  const { mutateAsync, isLoading } = useGetAssetsSignedUrl()

  const hasVideos = videos.length > 0

  const handleDeleteVideo = (name: string) => () => {
    setVideos(videos.filter((video) => video.file.name !== name))
  }

  const addVideos = async () => {
    const signedUrls = await mutateAsync({
      assets: videos.map(({ file }) => ({ name: file.name, mimeType: file.type })),
      sectionId,
    })

    add(
      videos.map((video) => ({
        ...video,
        asset: signedUrls.find((signedUrl) => signedUrl.asset.name === video.file.name)!.asset,
        writeUrl: signedUrls.find((signedUrl) => signedUrl.asset.name === video.file.name)!.writeUrl,
        status: UploadStatus.NO_CALLED,
      })),
    )

    setVideos([])
    onClose()
  }

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="max-h-[90vh] sm:max-w-lg flex flex-col gap-4">
        <DialogHeader className="flex flex-col gap-2">
          <DialogTitle>Enviar vídeos em massa</DialogTitle>

          <div className="flex flex-col gap-2 text-sm text-muted-foreground">
            <p>
              Você pode adicionar vários vídeos de uma vez. Cada vídeo será uma publicação em rascunho na seção e
              usaremos o nome do arquivo como título.
            </p>

            <p>Aviso importante: Vídeos em rascunho serão deletados após 45 dias.</p>
          </div>
        </DialogHeader>

        {hasVideos ? (
          <div className="overflow-y-auto">
            <label className="w-full flex items-center justify-between px-2 h-12 border rounded-md mb-2 cursor-pointer">
              <Input onChange={setVideos} />

              <div className="flex items-center">
                <FolderOpen className="h-4 w-4 mr-2" />
                <span className="text-sm">Meu dispositivo</span>
              </div>

              <Plus className="h-4 w-4" />
            </label>

            {videos.map(({ file }, i) => (
              <div
                key={i}
                data-testid={file.name}
                className="flex items-center justify-between p-2 hover:bg-secondary rounded-md"
              >
                <div className="max-w-[95%] flex items-center gap-2">
                  <VideoIcon className="h-4 w-4" />
                  <span className="flex-1 text-sm truncate">{file.name}</span>
                </div>

                <X className="min-h-4 h-4 min-w-4 w-4 cursor-pointer" onClick={handleDeleteVideo(file.name)} />
              </div>
            ))}
          </div>
        ) : (
          <label className="flex flex-col items-center justify-center h-full cursor-pointer" data-testid="empty-state">
            <Input onChange={setVideos} />

            <div className="w-14 h-14 bg-secondary rounded-full flex items-center justify-center mb-2">
              <Upload className="h-6 w-6" />
            </div>

            <span className="mb-2">Adicione um ou mais vídeos</span>

            <p className="text-sm text-center">
              Você pode adicionar vídeos no formato:
              <strong>mov, mp4, avi, mkv, aiff, mid, wav e outros...</strong>
            </p>
          </label>
        )}

        <DialogFooter>
          <Button
            onClick={addVideos}
            loading={isLoading}
            disabled={!hasVideos}
            className="w-full"
            data-testid="add-videos-button"
          >
            Adicionar vídeos
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
