import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import moment from 'moment'
import { GetExportDeactivateMembersInput } from '../../domain/dtos/get-export-deactivates-members-input'
import { GeneralError } from '../../domain/errors/general'
import { Envs } from '../../envs'

export class GetExportDeactivateMembers {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(input: GetExportDeactivateMembersInput, signal?: AbortSignal) {
    const response = await this.httpClient.request({
      method: HttpMethod.GET,
      url: `${Envs.BFF_MEMBERS_AREA_URL}/members/deactivates/export`,
      params: this.makeParams(input),
      responseType: 'arraybuffer',
      signal,
    })

    if (response.statusCode === HttpStatusCode.OK || response.statusCode === HttpStatusCode.CREATED) {
      return this.makeOutput(response.data)
    }

    throw new GeneralError()
  }

  private makeParams(input: GetExportDeactivateMembersInput) {
    const body = {
      page: input.page,
      pageSize: input.pageSize,
      productId: input.productId,
      search: input.search,
      timezone: moment().format('Z'),
      orderBy: 'createdAt',
      orderDirection: 'DESC',
    }

    return body
  }

  private makeOutput(value: ArrayBuffer) {
    // Create a Blob from the ArrayBuffer
    const blob = new Blob([new Uint8Array(value)], { type: 'text/csv;charset=utf-8;' })

    // Create a URL for the Blob
    const blobURL = URL.createObjectURL(blob)

    // Create a download link
    const link = document.createElement('a')
    link.href = blobURL
    link.download = `export-members-${moment().format('YYYY-MM-DDT_HH-mm-ss')}.xlsx`

    // Trigger a click event to initiate the download
    link.click()

    // Clean up the Blob URL
    URL.revokeObjectURL(blobURL)
  }
}
