import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject, injectable } from 'inversify'
import { MovePostSectionRequest } from '../domain/dtos'
import { Envs } from '../envs'

@injectable()
export class MovePostSection {
  public constructor(@inject(HttpClient) private readonly httpClient: HttpClient) {}

  public async execute(payload: MovePostSectionRequest): Promise<void> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .request({
          url: `${Envs.BFF_MEMBERS_AREA_URL}/hub/posts/${payload.postId}/move`,
          body: { sectionId: payload.sectionId },
          headers: {
            accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: HttpMethod.PATCH,
        })
        .then((response) => {
          if (!response.data || ![HttpStatusCode.OK].includes(response.statusCode)) {
            return reject(`Ocorreu um erro ao mover o post`) //@TODO error code of bff
          }

          return resolve()
        })
        .catch(() => {
          return reject(`Ocorreu um erro ao mover o post`)
        })
    })
  }
}
