import { useTranslation } from 'react-i18next'
import { formatLocalNumber } from '../../../../domain/vos/format-local-number'
import { CardInfoBox } from '../../../components/card-info-box'
import { LoadingCard } from '../../../components/loading-card'

type TotalInvoicesProps = {
  amount?: number
  isLoading: boolean
}

export const TotalInvoices = ({ isLoading, amount }: TotalInvoicesProps) => {
  const { t } = useTranslation()

  if (isLoading) {
    return <LoadingCard />
  }

  return (
    <CardInfoBox
      title={t('totalInvoices.title')}
      description={t('totalInvoices.description')}
      current={formatLocalNumber(amount ?? 0)}
    />
  )
}
