import { Card, CardContent, CardHeader, CardTitle, Skeleton } from '@hub-la/shadcn'

export const PaymentDetailsLoading = () => {
  return (
    <Card className="w-full h-full">
      <CardHeader>
        <CardTitle>
          <Skeleton className="h-6 w-36" />
        </CardTitle>
      </CardHeader>

      <CardContent className="flex flex-col gap-6">
        <div className="flex flex-col gap-2">
          <Skeleton className="h-5 w-24" />

          <div className="flex items-center space-x-2">
            <Skeleton className="h-6 w-6 rounded-full" />
            <Skeleton className="h-5 w-32" />
          </div>
        </div>

        <div className="flex flex-col gap-2">
          <Skeleton className="h-5 w-32" />
          <Skeleton className="h-5 w-48" />
        </div>
      </CardContent>
    </Card>
  )
}
