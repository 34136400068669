import { Card, CardContent } from '@hub-la/shadcn'
import { ReceiptText } from 'lucide-react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { PendingInvoice } from '../../../../domain/dtos/pending-invoice'
import { formatCurrencyWithInstallments } from '../../../../domain/vos/format-currency'

type Props = {
  title: string
  type: 'overdue' | 'unpaid'
  invoices: PendingInvoice[]
}

export const PendingInvoices = ({ title, type, invoices }: Props) => {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <div className="my-6 flex flex-col gap-4">
      <p className="text-base">{t(title, { length: invoices.length })}</p>

      <div className="flex flex-col gap-4">
        {invoices.map(
          (invoice) =>
            invoice && (
              <Card
                className="cursor-pointer p-3 flex items-center justify-between transition-colors hover:bg-secondary"
                onClick={() => history.push(`/user_invoices/${invoice.invoiceId}`)}
              >
                <CardContent className="w-full p-2 flex flex-wrap gap-4 items-center justify-between">
                  <ReceiptText className={type === 'unpaid' ? 'text-muted-foreground' : 'text-red-500'} />

                  <div className="flex flex-1 flex-wrap gap-2 items-center justify-between">
                    <div className="flex flex-col gap-1">
                      <p className="text-base font-medium">{invoice.items.at(0)?.description}</p>

                      <p className="text-sm text-muted-foreground">
                        {t(`${type}.dueDate`)} {moment(invoice.dueDate).format('D MMM. YYYY')}
                      </p>
                    </div>

                    <p className="text-base font-medium">
                      {formatCurrencyWithInstallments(
                        invoice.installmentsAmount,
                        invoice.priceInCents / 100,
                        invoice.installmentsPriceInCents / 100,
                      )}
                    </p>
                  </div>
                </CardContent>
              </Card>
            ),
        )}
      </div>
    </div>
  )
}
