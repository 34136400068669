import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { GetInvoiceDetailRequestDto, GetInvoiceDetailResponseDto } from '../domain/dtos/get-invoice-details-request.dto'
import { GeneralError } from '../domain/errors/general'
import { Envs } from '../envs'

export class GetCreatorInvoiceDetails {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(input: GetInvoiceDetailRequestDto): Promise<GetInvoiceDetailResponseDto> {
    const response = await this.httpClient.request({
      method: HttpMethod.GET,
      url: `${Envs.BFF_WEB_URL}/dashboard/creator/invoices/${input.invoiceId}`,
    })

    if (response.statusCode === HttpStatusCode.OK) {
      return response.data
    }

    throw new GeneralError()
  }
}
