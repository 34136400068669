import { Button, Card, CardContent, CardHeader, CardTitle, Separator, WhatsApp } from '@hub-la/shadcn'
import { Mail, User } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { Phone } from '../../../domain/vos/phone'
import { CardRow } from './card-row'

type DetailsProps = {
  payerId?: string
  name?: string
  email?: string
  phoneNumber?: string
  paymentMethod?: string
}

export const Details = ({ payerId, name, email, phoneNumber, paymentMethod }: DetailsProps) => {
  const { t } = useTranslation()

  return (
    <div className="space-y-6">
      <Card>
        <CardHeader className="py-2 px-4">
          <CardTitle className="text-base font-semibold flex justify-between items-center">
            <div className="flex gap-2 items-center">
              <div className="w-7 h-7 bg-secondary rounded-full p-1.5">
                <User className="h-4 w-4" />
              </div>

              {name || t('customerDetails.title')}
            </div>

            <div className="flex space-x-2">
              <Button size="icon" variant="outline" onClick={() => window.open(`mailto:${email}`)}>
                <Mail className="w-4 h-4" />
              </Button>

              <Button size="icon" variant="outline" onClick={() => window.open(`https://wa.me/${phoneNumber}`)}>
                <WhatsApp className="w-[18px] h-[18px]" />
              </Button>
            </div>
          </CardTitle>
        </CardHeader>

        <Separator />

        <CardContent className="pt-4 space-y-4">
          {payerId && <CardRow title="ID" content={<span>{payerId}</span>} />}

          {email && <CardRow title={t('customerDetails.email')} content={<span>{email}</span>} />}

          {phoneNumber && (
            <CardRow
              title={t('customerDetails.phoneNumber')}
              content={<span>{Phone.build(phoneNumber).getFormattedNumber()}</span>}
            />
          )}

          {paymentMethod && (
            <CardRow
              title={t('customerDetails.paymentMethod')}
              content={<span>{t(`paymentMethod.${paymentMethod}`)}</span>}
            />
          )}
        </CardContent>
      </Card>
    </div>
  )
}
