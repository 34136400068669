import { AssetType } from '@hub-la/fe-asset'
import { Post } from '@hub-la/fe-post'
import { Thumbnail } from '@hub-la/fe-post-thumbnail'
import {
  Alert,
  AlertDescription,
  cn,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  ScrollArea,
} from '@hub-la/shadcn'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useGetSections } from '../../../../hooks/member-area/settings/use-get-sections'
import { Duration } from '../components/duration'

type PostSelectModalProps = {
  open: boolean
  onSelect?: (post: Post) => void
  onClose?: () => void
}

const PostSelectModal = ({ open, onSelect, onClose }: PostSelectModalProps) => {
  const { productId } = useParams<{ productId: string }>()
  const { data } = useGetSections({ productId })
  const { t } = useTranslation()

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-lg" data-testid="post-select-modal">
        <DialogHeader>
          <DialogTitle>{t('member-area.settings.postSelectModal.title')}</DialogTitle>
        </DialogHeader>

        <div className="flex flex-col gap-4">
          <p className="text-sm text-muted-foreground">{t('member-area.settings.postSelectModal.description')}</p>

          <ScrollArea className="h-[50vh] pr-4">
            {(data ?? [])?.map((section) => {
              const videoPosts = section?.posts?.filter(
                (post) => post?.cover?.type && [AssetType.video].includes(post?.cover?.type),
              )
              const noVideos = videoPosts.length <= 0

              return (
                <div key={section?.id} className="mb-6">
                  <h4 className="text-lg font-semibold mb-2">{section?.name}</h4>

                  {videoPosts.map((post) => (
                    <button
                      key={post?.id}
                      className={cn(
                        'w-full flex items-center gap-4 p-2 rounded-md mb-3 border border-input',
                        'hover:bg-accent hover:text-accent-foreground',
                        'transition-colors duration-200',
                      )}
                      onClick={() => onSelect && onSelect(post)}
                    >
                      <Thumbnail width={56} height={81} post={post} hasProgressBar={false} />

                      <div className="text-left">
                        <p className="font-medium">{post?.title}</p>

                        <Duration post={post} />
                      </div>
                    </button>
                  ))}
                  {noVideos && (
                    <Alert>
                      <AlertDescription>{t('member-area.settings.postSelectModal.none')}</AlertDescription>
                    </Alert>
                  )}
                </div>
              )
            })}

            {(data ?? []).length <= 0 && (
              <Alert>
                <AlertDescription>{t('member-area.settings.postSelectModal.none')}</AlertDescription>
              </Alert>
            )}
          </ScrollArea>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default PostSelectModal
