/**
 * Represents a class that checks if there are any pendencies or if a specific pendency exists.
 */
export class HasPendency {
  private pendencies: string[]

  /**
   * Creates an instance of HasPendency.
   * @param pendencies - An array of pendencies.
   */
  constructor(pendencies: string[]) {
    this.pendencies = pendencies
  }

  /**
   * Checks if there are any pendencies.
   * @returns A boolean indicating if there are any pendencies.
   */
  hasAnyPendency(): boolean {
    return this.pendencies.length > 0
  }

  /**
   * Checks if a specific pendency exists.
   * @param pendency - The pendency to check.
   * @returns A boolean indicating if the specified pendency exists.
   */
  hasPendency(pendency: string): boolean {
    return this.pendencies.includes(pendency)
  }

  /**
   * Checks if any of the specified pendencies exist.
   * @param pendencies - The list of pendencies to check.
   * @returns A boolean indicating if any of the specified pendencies exist.
   */
  hasAnyOfPendencies(pendencies: string[]): boolean {
    return pendencies.some((pendency) => this.pendencies.includes(pendency))
  }
}
