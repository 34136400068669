import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { InfiniteData, useMutation, useQueryClient } from '@tanstack/react-query'
import { CommentExtraInfos } from '../../domain/dtos/comment-extra-info'
import { CurrentUser } from '../../domain/dtos/current-user'
import { GetCommentsResponse } from '../../domain/dtos/get-comments-response'
import { PostCommentInput } from '../../domain/dtos/post-comment-input'

import { UserInfo } from '../../domain/dtos/user-info'
import { QueryKey } from '../../domain/enums/query-keys'
import { PostComment } from '../../usecases/post-comment'
import { useDiscussion } from '../providers/discussion'

export const usePostComment = ({
  onSuccess,
  currentUser,
}: {
  onSuccess?: () => void
  currentUser?: CurrentUser
} = {}) => {
  const container = useContainer()
  const queryClient = useQueryClient()

  const discussion = useDiscussion()

  const user = currentUser ?? discussion?.currentUser

  return useMutation(
    [QueryKey.postComment],
    (input: PostCommentInput) =>
      new PostComment(container.get(HttpClient)).execute({
        ...input,
        postId: discussion?.metadata?.postId,
      }),
    {
      retry: false,
      onSuccess: (data, input) => {
        if (onSuccess) {
          onSuccess()
        }
        queryClient.setQueryData<InfiniteData<GetCommentsResponse>>(
          [QueryKey.getComments, input.postId ?? discussion?.metadata?.postId, discussion?.orderDir],
          (commentsData): InfiniteData<GetCommentsResponse> => {
            if (!commentsData || !commentsData.pages || commentsData.pages.length === 0) {
              return {
                pages: [
                  {
                    items: [],
                    total: 1,
                    lastPage: 1,
                    page: 1,
                    pageSize: 10,
                  },
                ],
                pageParams: [null],
              }
            }

            const updatedPages = [...commentsData.pages]

            if (input.parentCommentId) {
              updatedPages.forEach((page, index) => {
                const updatedItems = page.items?.map((comment: CommentExtraInfos) => {
                  if (comment.id === input.parentCommentId) {
                    return {
                      ...comment,
                      repliesCount: (comment.repliesCount || 0) + 1,
                      latestReplyAt: new Date().toISOString(),
                    }
                  }
                  return comment
                })
                updatedPages[index] = { ...page, items: updatedItems }
              })
            } else if (data) {
              const newUserInfo: UserInfo = {
                name: user?.name ?? '',
                picture: user?.picture ?? null,
              }

              const newComment: CommentExtraInfos = {
                ...data,
                hasOwnerReply: false,
                hasUserLike: false,
                latestReplyAt: undefined,
                likesCount: 0,
                repliesCount: 0,
                wasEdited: false,
                user: newUserInfo,
                discussionOwner: null,
              }

              const firstPage = updatedPages[0]
              const updatedFirstPage = {
                ...firstPage,
                total: (firstPage.total || 0) + 1,
                items:
                  discussion?.orderDir?.toString().toLowerCase() === 'desc'
                    ? [newComment, ...(firstPage.items || [])]
                    : [...(firstPage.items || []), newComment],
              }
              updatedPages[0] = updatedFirstPage

              if (updatedFirstPage.total > updatedFirstPage.pageSize * updatedFirstPage.lastPage) {
                updatedFirstPage.lastPage += 1
              }
            }

            return {
              ...commentsData,
              pages: updatedPages,
            }
          },
        )

        queryClient.invalidateQueries([
          QueryKey.getComments,
          input.postId ?? discussion?.metadata?.postId,
          discussion?.orderDir,
        ])

        if (input.parentCommentId) {
          queryClient.invalidateQueries({ queryKey: [QueryKey.getCommentReplies, input.parentCommentId] })
        }
      },
    },
  )
}
