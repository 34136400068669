export interface Resource {
  id: string
  name: string
  description: string
  order: number
  resourceData: {
    maxMemberCount: number
    memberCount: number
  }
  type: ResourceType
}

export interface GroupResource {
  id: string
  name: string
  link: string
  resourceData: {
    maxMemberCount: number
    memberCount: number
  }
  resourceType: ResourceType
  status: ResourceStatus
  state: ResourceState
}

export enum ResourceType {
  whatsapp = 'whatsapp',
  telegram = 'telegram',
  telegram_channel = 'telegram_channel',
}

export enum ResourceStatus {
  pending = 'pending',
  installed = 'installed',
}

export enum ResourceState {
  idle = 'idle',
  ready = 'ready',
  standby = 'standby',
  full = 'full',
}
