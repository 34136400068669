import { CreateProductInput } from '../dtos/create-product-input'
import { ProductCategory } from '../enums/product-category'
import { ProductType } from '../enums/product-type'
import { CreateEventProductSchemaType } from './create-event-product-schema'

export const createEventProductMapper = (event: CreateEventProductSchemaType): CreateProductInput => {
  return {
    ...event,
    type: ProductType.EVENT,
    category: event.category as ProductCategory,
    eventData: {
      ...event.eventData,
      location: event.eventData.isLocationNotDefined
        ? null
        : {
            street: event.eventData.location.street ?? '',
            number: event.eventData.location.number ?? '',
            complement: event.eventData.location.complement ?? '',
            neighborhood: event.eventData.location.neighborhood ?? '',
            city: event.eventData.location.city ?? '',
            state: event.eventData.location.state ?? '',
            country: event.eventData.location.country ?? '',
            zipCode: event.eventData.location.zipCode ?? '',
            latitude: null,
            longitude: null,
          },
    },
  }
}
