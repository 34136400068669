import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useQuery } from '@tanstack/react-query'
import { GetTelegramCode } from '../../usecases/get-telegram-code'

export const useGetTelegramCode = () => {
  const container = useContainer()

  return useQuery([`get-telegram-code`], () => new GetTelegramCode(container.get(HttpClient)).execute(), {
    refetchOnWindowFocus: false,
    initialData: [],
    retry: false,
  })
}
