import { useTranslation } from 'react-i18next'
import { Offer } from '../../../../domain/dtos/offer'
import { PaymentMethod } from '../../../../domain/dtos/payment-method'
import { formatPercentage } from '../../../../domain/vos/format-percentage'
import { CardInfoBox } from '../../../components/card-info-box'
import { useGetConversionRate } from '../../../hooks/use-get-conversion-rate'
import { useRefetch } from '../../../hooks/use-refetch'
import { Loading } from './loading'

type PixConversionProps = {
  startDate: string
  endDate: string
  offerIds: string[]
  offers: Offer[]
  isLoading: boolean
}

export const PixConversion = (props: PixConversionProps) => {
  const { startDate, endDate, offers, offerIds, isLoading } = props
  const { t } = useTranslation()

  const { data, isFetching, refetch } = useGetConversionRate(
    { startDate, endDate, offers, offerIds, paymentMethod: PaymentMethod.PIX },
    isLoading,
  )

  useRefetch({ startDate, endDate, offers, offerIds }, refetch)

  if (isFetching || isLoading) {
    return <Loading />
  }

  const getVariation = () => {
    return {
      indicator: data.indicator,
      status: data.status,
      description: `${data.percentage} ${t('variation', { previous: formatPercentage(data.previous) })}`,
    }
  }

  return (
    <CardInfoBox
      title={t('pixConversion.title')}
      description={t('pixConversion.description')}
      current={formatPercentage(data.current)}
      variation={getVariation()}
    />
  )
}
