import { UploadStatus } from '@hub-la/fe-asset'
import { isEmpty } from 'lodash'
import { object, string } from 'yup'

export const validationSchema = ({ isEvent }) => {
  const whenIsEventCannotBeEmpty = (value) => {
    return isEvent ? !isEmpty(value) : true
  }

  const whenAssetIsNotReady = (value) => {
    return value !== UploadStatus.LOADING
  }

  return object({
    title: string().required(),
    customCoverStatus: string().test(whenAssetIsNotReady),
    attachedProgressStatus: string().test(whenAssetIsNotReady),
    mainMediaProgressStatus: string().test(whenAssetIsNotReady),
    event: object({
      link: string().url().test(whenIsEventCannotBeEmpty),
      date: string().test(whenIsEventCannotBeEmpty),
      start: string().test(whenIsEventCannotBeEmpty),
      end: string().test(whenIsEventCannotBeEmpty),
    }),
  })
}
