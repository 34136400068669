import {
  Button,
  Calendar,
  cn,
  Label,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@hub-la/shadcn'
import { format, set } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { CalendarIcon, Clock } from 'lucide-react'
import { Controller, useFormContext } from 'react-hook-form'

interface DateWithTimePickerProps {
  name: string
}

const DateWithTimePicker = ({ name }: DateWithTimePickerProps) => {
  const { control } = useFormContext()

  return (
    <div className="space-y-2">
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          const date = field.value ? new Date(field.value) : undefined

          const updateDateTime = (timeString: string | null, newDate?: Date) => {
            if (newDate) {
              if (timeString) {
                const [hours, minutes] = timeString.split(':').map(Number)
                const updatedDate = set(newDate, { hours, minutes, seconds: 0 })
                field.onChange(updatedDate.toISOString())
              } else {
                field.onChange(newDate.toISOString())
              }
            } else {
              field.onChange(null)
            }
          }

          return (
            <div className="flex gap-2">
              <div className="flex-1">
                <Label htmlFor={`${name}-date`}>Data</Label>

                <Popover>
                  <PopoverTrigger asChild>
                    <Button
                      variant="outline"
                      className={cn('w-full justify-start text-left font-normal', !date && 'text-muted-foreground')}
                    >
                      <CalendarIcon className="mr-2 h-4 w-4" />
                      {date ? format(date, 'PPP', { locale: ptBR }) : <span>Escolha uma data</span>}
                    </Button>
                  </PopoverTrigger>

                  <PopoverContent className="z-10002 w-auto p-0" align="start">
                    <Calendar
                      mode="single"
                      selected={date}
                      onSelect={(newDate) => updateDateTime(date ? format(date, 'HH:mm') : null, newDate)}
                      locale={ptBR}
                    />
                  </PopoverContent>
                </Popover>
              </div>

              <div className="w-[120px]">
                <Label htmlFor={`${name}-time`}>Hora</Label>
                <Select
                  onValueChange={(time) => updateDateTime(time, date)}
                  value={date ? format(date, 'HH:mm') : undefined}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Selecione">
                      {date ? format(date, 'HH:mm') : <Clock className="mr-2 h-4 w-4" />}
                    </SelectValue>
                  </SelectTrigger>

                  <SelectContent className="z-10002">
                    {Array.from({ length: 24 * 4 }, (_, i) => {
                      const hour = Math.floor(i / 4)
                      const minute = (i % 4) * 15
                      return `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`
                    }).map((time) => (
                      <SelectItem key={time} value={time}>
                        {time}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
            </div>
          )
        }}
      />
    </div>
  )
}

export { DateWithTimePicker }
