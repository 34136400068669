export type User = {
  id: string
  name: string
  email: string
  phoneNumber: string
  picture: string | null
  isCreator?: boolean
  isSeller?: boolean
  ims?: IM
  navigationTarget: UserType
}

export interface IM {
  whatsapp?: string
  telegram?: string
  telegramUsername?: string
}

export enum UserType {
  CREATOR = 'creator',
  BUYER = 'buyer',
}
