type CardRowProps = {
  title: string
  content: React.ReactElement
}

export const CardRow = ({ title, content }: CardRowProps) => {
  return (
    <div className="flex items-center space-x-2 justify-between">
      <p className="text-sm text-muted-foreground">{title}</p>
      <p className={`text-sm text-rimary text-right`}>{content || '–'}</p>
    </div>
  )
}
