import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { AddDailyCreditsBatchInput } from '../../domain/dtos/add-daily-credits-batch-input'
import { QueryKey } from '../../domain/enums/query-keys'
import { AddDailyCreditsBatch } from '../../usecases/add-daily-credits-batch'

export const useAddDailyCreditsBatch = () => {
  const container = useContainer()
  const { t } = useTranslation()

  return useMutation(
    [QueryKey.addDailyCreditsBatch],
    (input: AddDailyCreditsBatchInput) => new AddDailyCreditsBatch(container.get(HttpClient)).execute(input),
    {
      retry: false,
    },
  )
}
