import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { EditRuleInput } from '../domain/dtos/edit-rule-input'
import { mapBackendProvider } from '../domain/dtos/integration-provider'
import { GeneralError } from '../domain/errors/general'
import { Envs } from '../envs'

export class EditRule {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient<void>,
  ) {}

  public async execute(input: EditRuleInput): Promise<void> {
    const response = await this.httpClient.request({
      method: HttpMethod.PUT,
      url: `${Envs.BFF_WEB_URL}/integrations/${input.integrationId}/rules/${input.rule.id}`,
      body: this.makeBody(input),
    })

    if (response.statusCode === HttpStatusCode.OK) {
      return
    }

    throw new GeneralError()
  }

  private makeBody(input: EditRuleInput) {
    return {
      provider: mapBackendProvider[input.provider],
      rule: {
        name: input.rule.name,
        url: input.rule.url,
        productIds: input.rule.products.map(({ id }) => id),
        offerIds: input.rule.offers.map(({ id }) => id),
        events: input.rule.events,
        listIds: (input.rule.lists ?? []).map(({ id }) => id),
        tagIds: (input.rule.tags ?? []).map(({ id }) => id),
        enabled: input.rule.enabled,
      },
    }
  }
}
