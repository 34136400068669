import {
  Button,
  cn,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  useIsMobile,
} from '@hub-la/shadcn'
import { Ellipsis, Eye, EyeOff, Trash } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDeleteProduct } from '../../hooks/use-delete-product'
import { useToggleProductVisibility } from '../../hooks/use-toggle-product-visibility'
import { DeleteModal } from './delete-modal'
import { VisibilityModal } from './visibility-modal'

type Props = {
  productId: string
  isVisible: boolean
}

export const ProductActions: React.FC<Props> = ({ productId, isVisible }) => {
  const [toggleProductVisibilityOpen, setToggleProductVisibilityOpen] = useState<boolean>(false)
  const [deleteProductOpen, setDeleteProductOpen] = useState<boolean>(false)
  const isMobile = useIsMobile()

  const { mutate: toggleVisibility, isLoading: toggleVisibilityLoading } = useToggleProductVisibility()
  const { mutate: deleteProduct, isLoading: deleteProductLoading } = useDeleteProduct()
  const { t } = useTranslation()

  const onToggleVisibilityChange = () => {
    if (!isVisible) {
      toggleVisibility({ productId, isVisible: !isVisible })
    } else {
      setToggleProductVisibilityOpen(true)
    }
  }

  const onDeleteProduct = () => {
    setDeleteProductOpen(true)
  }

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant={isMobile ? 'secondary' : 'outline'}
            size="icon"
            className={cn('gap-2 h-9 w-9', isMobile && 'h-7 w-7 border')}
            loading={toggleVisibilityLoading || deleteProductLoading}
          >
            <Ellipsis className="h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>

        <DropdownMenuContent className="w-72 mx-6">
          <DropdownMenuItem onClick={onToggleVisibilityChange}>
            <div className="flex items-center gap-2">
              {isVisible ? <EyeOff className="h-4 w-4" /> : <Eye className="h-4 w-4" />}
              <span>{t(isVisible ? 'actions.hide' : 'actions.show')}</span>
            </div>
          </DropdownMenuItem>

          <DropdownMenuItem onClick={onDeleteProduct}>
            <div className="flex items-center gap-2">
              <Trash className="h-4 w-4" />
              <span>{t('actions.delete')}</span>
            </div>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <VisibilityModal
        open={toggleProductVisibilityOpen}
        onClose={() => setToggleProductVisibilityOpen(false)}
        onSubmit={() => {
          setToggleProductVisibilityOpen(false)
          toggleVisibility({ productId, isVisible: false })
        }}
      />

      <DeleteModal
        open={deleteProductOpen}
        onClose={() => setDeleteProductOpen(false)}
        onSubmit={() => {
          setDeleteProductOpen(false)
          deleteProduct(productId)
        }}
      />
    </>
  )
}
