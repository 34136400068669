import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation } from '@tanstack/react-query'
import { UpdateAssetRequest } from '../../domain/dtos/update-asset-request'
import { UpdateAsset } from '../../usecases/update-asset'

export const useUpdateAsset = () => {
  const container = useContainer()

  const { isSuccess, isError, error, isLoading, data, mutateAsync } = useMutation(
    [],
    ({ payload }: UpdateAssetRequest) => new UpdateAsset(container.get(HttpClient)).execute(payload),
  )

  return {
    isError,
    error,
    isLoading,
    data: data ? data : null,
    isSuccess,
    mutateAsync,
  }
}
