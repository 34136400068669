import * as Yup from 'yup'
import { BRAZILIAN_BANKS } from '../../domain/constants/brazilian-banks'
import { BANK_ACCOUNT_TYPE } from '../../domain/constants/bank-account-type'

export const bankAccountSchema = Yup.object().shape({
  bankNumber: Yup.string()
    .required('errors.validation.required')
    .test({
      message: 'errors.validation.invalidBank',
      test: function (value) {
        return BRAZILIAN_BANKS.some((bank) => bank.compe === value)
      },
    }),
  agencyNumber: Yup.string()
    .required('errors.validation.required')
    .min(4, 'errors.validation.invalidAgencyNumber')
    .max(6, 'errors.validation.invalidAgencyNumber'),
  accountNumber: Yup.string()
    .required('errors.validation.required')
    .min(5, 'errors.validation.invalidAccountNumber')
    .max(13, 'errors.validation.invalidAccountNumber'),
  accountType: Yup.number()
    .required('errors.validation.required')
    .test({
      message: 'errors.validation.invalidAccountType',
      test: function (value) {
        return BANK_ACCOUNT_TYPE.some((accountType) => accountType.code === value)
      },
    }),
})
