import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useToast } from '@hub-la/shadcn'
import { ChangeProductStatusInput } from '../../domain/dtos/change-product-status'
import { QueryKey } from '../../domain/enums/query-key'
import { ChangeProductStatus } from '../../usecases/change-product-status'

export const useChangeProductStatus = () => {
  const container = useContainer()
  const queryClient = useQueryClient()
  const { toast } = useToast()
  const { t } = useTranslation()

  return useMutation(
    [QueryKey.changeProductStatus],
    (input: ChangeProductStatusInput) => new ChangeProductStatus(container.get(HttpClient)).execute(input),
    {
      retry: false,
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries({ queryKey: [QueryKey.getProduct, variables.productId] })
      },
      onError: (error: Error) => {
        toast({
          title: 'Error',
          description: t(error.message),
          variant: 'destructive',
        })
      },
    },
  )
}
