import { Avatar, AvatarFallback, AvatarImage, Button, useIsMobile } from '@hub-la/shadcn'
import { ChevronDown, ChevronUp, Circle, LoaderCircle } from 'lucide-react'
import moment from 'moment'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { UserInfo } from '../../../domain/dtos/user-info'
import avatar from '../../assets/default-user-avatar.png'
import { useGetCommentReplies } from '../../hooks/use-get-replies'
import { Comment } from './comment'

type CommentReplyProps = {
  fullName?: string
  commentId: string
  latestReplyAt?: string | Date
  count: number
  hasOwnerReply?: boolean
  discussionOwner?: UserInfo | null
  toggleReplies: boolean
  setToggleReplies: (value: boolean) => void
}

export const CommentReplies = ({
  fullName,
  count,
  latestReplyAt,
  commentId,
  hasOwnerReply,
  discussionOwner,
  toggleReplies,
  setToggleReplies,
}: CommentReplyProps) => {
  const { t } = useTranslation()
  const isMobile = useIsMobile()

  const {
    data: repliesData,
    fetchNextPage,
    isFetching,
    isLoading,
    hasNextPage,
    isFetchingNextPage,
  } = useGetCommentReplies({ commentId, enabled: toggleReplies, pageSize: isMobile ? 2 : 5 })

  const lastReplyFormated = moment(latestReplyAt).locale('pt-br').fromNow()

  const handleOpenReplies = () => {
    setToggleReplies(!toggleReplies)
  }

  return (
    <>
      <div className="flex items-center mb-3">
        <Button variant="ghost" size="sm" onClick={handleOpenReplies}>
          {toggleReplies ? <ChevronUp className="h-4 w-4 mr-2" /> : <ChevronDown className="mr-2 h-4 w-4" />}
          <div className="flex items-center gap-2">
            {hasOwnerReply && (
              <Avatar className="h-6 w-6">
                <AvatarImage src={discussionOwner?.picture ?? avatar} className="object-cover" alt="Discussion owner" />
                <AvatarFallback>
                  {discussionOwner?.name ? discussionOwner.name.charAt(0).toUpperCase() : 'U'}
                </AvatarFallback>
              </Avatar>
            )}
            <Circle className="h-1 w-1 fill-current" />
            {count === 1 ? (
              <span className="text-sm"> {t('comment.replies.countSingular', { count })}</span>
            ) : (
              <span className="text-sm"> {t('comment.replies.countPlural', { count })}</span>
            )}
            {!isMobile && (
              <span className="text-xs text-muted-foreground">
                {t('comment.replies.lastAnswer', { date: lastReplyFormated })}
              </span>
            )}
          </div>
        </Button>
      </div>

      {toggleReplies && (
        <div className={`border-l ${isLoading ? 'border-transparent' : 'border-muted'} ml-4 pl-5`}>
          <div className="space-y-3">
            {(repliesData?.pages ?? []).map((group, i) => (
              <Fragment key={i}>
                {group?.items?.map((replyComment) => (
                  <Comment
                    isReply
                    key={replyComment.id}
                    commentId={replyComment.id}
                    parentCommentId={commentId}
                    comment={replyComment.message}
                    fullName={replyComment?.user?.name}
                    avatarUrl={replyComment?.user?.picture}
                    createdAt={replyComment.createdAt}
                    likes={replyComment.likesCount}
                    avatarSize={6}
                    isEdited={replyComment.wasEdited}
                    userId={replyComment?.userId}
                  />
                ))}
              </Fragment>
            ))}

            {isFetching && (
              <div className="w-full flex justify-center text-primary-foreground">
                <LoaderCircle className="w-8 h-8 animate-spin" />
              </div>
            )}

            {hasNextPage && (
              <Button
                variant="outline"
                onClick={() => fetchNextPage()}
                disabled={isFetchingNextPage}
                className="w-full"
              >
                Ver mais respostas
              </Button>
            )}
          </div>
        </div>
      )}
    </>
  )
}
