import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { Envs } from '../envs'
import { v4 } from 'uuid'
import { SubmitUpgradePlanInput } from '../domain/dtos/submit-upgrade-plan-input'

export class SubmitUpgradePlan {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(input: Partial<SubmitUpgradePlanInput>): Promise<void> {
    const response = await this.httpClient.request({
      method: HttpMethod.POST,
      url: `${Envs.BFF_WEB_URL}/pay/submit-upgrade`,
      body: this.makeBody(input),
    })

    if ([HttpStatusCode.OK, HttpStatusCode.CREATED].includes(response.statusCode)) {
      return
    }

    throw new Error(response.data?.code ?? 'GENERAL')
  }

  private makeBody(input: Partial<SubmitUpgradePlanInput>): SubmitUpgradePlanInput {
    return {
      id: v4(),
      subscriptionId: input.subscriptionId!,
      selectedOptionId: input.selectedOptionId!,
      installments: input.installments!,
    }
  }
}
