import { Alert, AlertTitle } from '@hub-la/shadcn'

type NotificationAlertProps = {
  title: string
  message: string
  variant?: 'default' | 'destructive'
}

export const NotificationAlert = ({ title, message, variant = 'default' }: NotificationAlertProps) => {
  return (
    <Alert variant={variant}>
      <AlertTitle>
        <strong>{title}</strong>
      </AlertTitle>

      {message}
    </Alert>
  )
}
