import { Skeleton } from '@hub-la/shadcn'
import { times } from 'lodash'

export const Loading = () => (
  <div className="grid grid-cols-1 md:grid-cols-4 gap-4 md:gap-6">
    {times(6).map((_, idx) => (
      <div key={idx} className="flex flex-col items-center space-y-2">
        <Skeleton className="w-full aspect-[218/142] rounded-md" />
        <Skeleton className="h-4 w-3/4" />
      </div>
    ))}
  </div>
)
