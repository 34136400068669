import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { QueryKey } from '../../domain/enums/query-key'
import { CreateCoupon } from '../../usecases/create-coupon'
import { Values } from '../components/create-coupon-modal'

export const useCreateCoupon = () => {
  const container = useContainer()
  const queryClient = useQueryClient()

  return useMutation(
    [QueryKey.createCoupon],
    (input: Values) => new CreateCoupon(container.get(HttpClient)).execute(input),
    {
      retry: false,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [QueryKey.getCoupons] })
      },
    },
  )
}
