import React from 'react'
import { Skeleton } from '@hub-la/shadcn'

export const Loading = () => {
  return (
    <div className="max-w-[588px] mt-8 mx-auto">
      <Skeleton className="h-[30px] w-full mb-3" />
      <Skeleton className="h-[30px] w-[120px] mb-3" />
      <Skeleton className="h-[30px] w-full" />

      <div className="flex flex-row items-center justify-between p-4 my-6 border border-gray-300 rounded-lg">
        <div className="flex flex-row gap-3">
          <Skeleton className="w-[82px] h-[52px]" />

          <div className="flex flex-col">
            <Skeleton className="w-[150px] h-[30px] mb-1" />
            <Skeleton className="w-[100px] h-[20px]" />
          </div>
        </div>

        <Skeleton className="w-[100px] h-[20px]" />
      </div>

      <div className="flex flex-col gap-3 mb-6">
        <Skeleton className="h-[56px] w-full" />
        <Skeleton className="h-[56px] w-full" />
        <div>
          <Skeleton className="w-[200px] h-[40px]" />
        </div>
      </div>

      <Skeleton className="h-[56px] w-full" />
    </div>
  )
}
