import { Auth } from '@hub-la/fe-core-auth'
import { HttpClient, HttpRequest, HttpResponse, HttpStatusCode } from '@hub-la/fe-core-http-client'
import axios, { AxiosResponse } from 'axios'
import { injectable } from 'inversify'

type AxiosError = {
  response: AxiosResponse
}

@injectable()
export class AxiosHttpClient extends HttpClient {
  private token: string | undefined

  public constructor(private readonly auth: Auth) {
    super()
  }

  public async request(data: HttpRequest): Promise<HttpResponse> {
    let axiosResponse: AxiosResponse

    if (!this.token) {
      this.token = await this.auth.getToken()
    }

    if (this.token) {
      data.headers = Object.assign({}, { authorization: `Bearer ${this.token}` })
    }

    // prevent replace authorization if it's already set
    if (this.token && Boolean(!data?.headers?.authorization && !data?.headers?.Authorization)) {
      data.headers = Object.assign(data.headers ?? {}, { authorization: `Bearer ${this.token}` })
    }
    let isTimeout = false
    try {
      axiosResponse = await axios.request({
        url: data.url,
        method: data.method,
        data: data.body,
        params: data.params,
        headers: data.headers,
        onUploadProgress: data.onUploadProgress,
        withCredentials: data.withCredentials,
        signal: data.signal,
        responseType: data.responseType,
        timeout: data.timeout,
      })
    } catch (error) {
      console.error(error)
      axiosResponse = (error as AxiosError).response
      if (error instanceof Error) {
        isTimeout = error.message?.includes('timeout')
      }
      if (!axiosResponse) {
        axiosResponse = { data: (error as Error)?.message } as any
      }
    }

    return {
      statusCode: axiosResponse?.status ?? (isTimeout ? HttpStatusCode.GATEWAY_TIMEOUT : undefined),
      data: axiosResponse?.data,
      headers: axiosResponse?.headers,
    }
  }
}
