import queryString from 'query-string'
import { EncodedQuery } from 'use-query-params'

/**
 * The QueryParamProvider default strategy for array serialization is not ideal for
 * our case. It converts arrays into repeated parameters, like so: { foo: [1, 2] } -> ?foo=1&foo2.
 * The disadvantage is that when we have only one value in the array (e.g. ?foo=1), this
 * can be interpreted as a single value.
 *
 * So, here we use the index strategy (e.g. ?foo[0]=1&...)
 */

const skipNulls = (obj: EncodedQuery) => Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== null))

const toString = (query: EncodedQuery) => {
  return queryString.stringify(skipNulls(query), {
    arrayFormat: 'index',
  })
}

const toObject = (searchString: string) => {
  return queryString.parse(searchString, { arrayFormat: 'index' })
}

export const getQueryOptions = () => ({
  searchStringToObject: toObject,
  objectToSearchString: toString,
  removeDefaultsFromUrl: true,
})
