import { useContainer } from '@hub-la/fe-container'
import { Auth } from '@hub-la/fe-core-auth'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { SignOut } from '../../usecases/sign-out'

export const useSignOut = () => {
  const container = useContainer()
  const auth = container.get(Auth)
  const httpClient = container.get(HttpClient)

  const execute = (): Promise<void> => {
    return new SignOut(httpClient, auth).execute()
  }

  return {
    execute,
  }
}
