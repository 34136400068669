import { container } from '@hub-la/fe-container'
import { EventLivePage, EventLiveProvider } from '@hub-la/fe-post'

export const EventLiveInit = () => {
  return (
    <EventLiveProvider container={container}>
      <EventLivePage />
    </EventLiveProvider>
  )
}
