import { useContainer } from '@hub-la/fe-container'
import { Auth } from '@hub-la/fe-core-auth'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation } from '@tanstack/react-query'
import { PostSignInByEmailCompleteRegistrationInput } from '../../domain/dtos/post-sign-in-by-email-complete-registration-input'
import { QueryKey } from '../../domain/dtos/query-keys'
import { PostSignInByEmailCompleteRegistration } from '../../usecases/post-sign-in-by-email-complete-registration'

export const usePostSignInByEmailCompleteRegistration = ({ onSuccess, onError }) => {
  const container = useContainer()

  return useMutation(
    [QueryKey.postSignInByEmailCompleteRegistration],
    (input: PostSignInByEmailCompleteRegistrationInput) =>
      new PostSignInByEmailCompleteRegistration(container.get(HttpClient), container.get(Auth)).execute(input),
    {
      retry: false,
      onSuccess: (data) => {
        onSuccess(data)
      },
      onError: () => {
        if (onError) {
          onError()
        }
      },
    },
  )
}
