/* tslint:disable */
/* eslint-disable */
/**
 * BFF Web Product
 * API reference used to call the backend from the web frontend
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { BNPLSettingsDto } from './bnplsettings-dto';

/**
 * 
 * @export
 * @interface CreateOfferCheckoutSettingsDto
 */
export interface CreateOfferCheckoutSettingsDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateOfferCheckoutSettingsDto
     */
    'paymentMethods': Array<CreateOfferCheckoutSettingsDtoPaymentMethodsEnum>;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOfferCheckoutSettingsDto
     */
    'showCouponField': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOfferCheckoutSettingsDto
     */
    'showBillingAddress': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOfferCheckoutSettingsDto
     */
    'showPhoneNumber'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOfferCheckoutSettingsDto
     */
    'showFullName'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateOfferCheckoutSettingsDto
     */
    'defaultPlan'?: CreateOfferCheckoutSettingsDtoDefaultPlanEnum;
    /**
     * 
     * @type {number}
     * @memberof CreateOfferCheckoutSettingsDto
     */
    'defaultInstallment'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOfferCheckoutSettingsDto
     */
    'purchaseButtonText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOfferCheckoutSettingsDto
     */
    'purchaseButtonTextColor'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOfferCheckoutSettingsDto
     */
    'purchaseButtonBgColor'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOfferCheckoutSettingsDto
     */
    'bgColor'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOfferCheckoutSettingsDto
     */
    'lightDarkMode'?: CreateOfferCheckoutSettingsDtoLightDarkModeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateOfferCheckoutSettingsDto
     */
    'layoutTemplate'?: CreateOfferCheckoutSettingsDtoLayoutTemplateEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateOfferCheckoutSettingsDto
     */
    'purchaseExperience'?: CreateOfferCheckoutSettingsDtoPurchaseExperienceEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateOfferCheckoutSettingsDto
     */
    'timerBgColor'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CreateOfferCheckoutSettingsDto
     */
    'timerSeconds'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOfferCheckoutSettingsDto
     */
    'logoUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOfferCheckoutSettingsDto
     */
    'logoPosition'?: CreateOfferCheckoutSettingsDtoLogoPositionEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateOfferCheckoutSettingsDto
     */
    'bannerDesktopUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOfferCheckoutSettingsDto
     */
    'bannerMobileUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOfferCheckoutSettingsDto
     */
    'bannerDisplayMode'?: CreateOfferCheckoutSettingsDtoBannerDisplayModeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateOfferCheckoutSettingsDto
     */
    'bannerSize'?: CreateOfferCheckoutSettingsDtoBannerSizeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateOfferCheckoutSettingsDto
     */
    'sideBannerDesktopUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOfferCheckoutSettingsDto
     */
    'sideBannerMobileUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOfferCheckoutSettingsDto
     */
    'guaranteeSealDesktopUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOfferCheckoutSettingsDto
     */
    'guaranteeSealMobileUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOfferCheckoutSettingsDto
     */
    'footerBannerDesktopUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOfferCheckoutSettingsDto
     */
    'footerBannerMobileUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOfferCheckoutSettingsDto
     */
    'footerBannerSize'?: CreateOfferCheckoutSettingsDtoFooterBannerSizeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateOfferCheckoutSettingsDto
     */
    'supportUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOfferCheckoutSettingsDto
     */
    'termsUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOfferCheckoutSettingsDto
     */
    'youtubeEmbedUrl'?: string | null;
    /**
     * 
     * @type {BNPLSettingsDto}
     * @memberof CreateOfferCheckoutSettingsDto
     */
    'bnplSettings': BNPLSettingsDto | null;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateOfferCheckoutSettingsDtoPaymentMethodsEnum {
    CREDIT_CARD = 'credit_card',
    BANK_SLIP = 'bank_slip',
    PIX = 'pix',
    BNPL = 'bnpl'
}
/**
    * @export
    * @enum {string}
    */
export enum CreateOfferCheckoutSettingsDtoDefaultPlanEnum {
    MONTHLY = 'monthly',
    QUARTERLY = 'quarterly',
    SEMIANNUALLY = 'semiannually',
    ANNUALLY = 'annually'
}
/**
    * @export
    * @enum {string}
    */
export enum CreateOfferCheckoutSettingsDtoLightDarkModeEnum {
    LIGHT = 'light',
    DARK = 'dark'
}
/**
    * @export
    * @enum {string}
    */
export enum CreateOfferCheckoutSettingsDtoLayoutTemplateEnum {
    NO_BANNER = 'no_banner',
    TOP_AND_BOTTOM_BANNER = 'top_and_bottom_banner',
    FULL_BANNER = 'full_banner'
}
/**
    * @export
    * @enum {string}
    */
export enum CreateOfferCheckoutSettingsDtoPurchaseExperienceEnum {
    ONE_STEP = 'one-step',
    TWO_STEPS = 'two-steps'
}
/**
    * @export
    * @enum {string}
    */
export enum CreateOfferCheckoutSettingsDtoLogoPositionEnum {
    LEFT = 'left',
    CENTER = 'center'
}
/**
    * @export
    * @enum {string}
    */
export enum CreateOfferCheckoutSettingsDtoBannerDisplayModeEnum {
    FULL_SCREEN = 'full_screen',
    FIT_CONTENT = 'fit_content'
}
/**
    * @export
    * @enum {string}
    */
export enum CreateOfferCheckoutSettingsDtoBannerSizeEnum {
    SMALL = 'small',
    MEDIUM = 'medium',
    LARGE = 'large'
}
/**
    * @export
    * @enum {string}
    */
export enum CreateOfferCheckoutSettingsDtoFooterBannerSizeEnum {
    SMALL = 'small',
    MEDIUM = 'medium',
    LARGE = 'large'
}


