import moment from 'moment'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Invoice } from '../../../../domain/dtos/invoice'
import { Offer } from '../../../../domain/dtos/offer'
import { PaymentMethod } from '../../../../domain/enums/payment-method'
import { RefundStrategy } from '../../../../domain/enums/refund-strategy.enum'
import { formatCurrency } from '../../../../domain/vos/format-currency'
import { useCreateRefundRequest } from '../../../hooks/use-create-refund-request'
import { useGetInvoices } from '../../../hooks/use-get-invoices'
import { createRefundRequestValidationSchema } from '../../../validations/create-refund-request-validation'
import { BankAccount, Pix } from './bank-account-form'
import { RefundPayerData } from './refund-payer-data'
import {
  Alert,
  AlertDescription,
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Textarea,
  toast,
  Toaster,
} from '@hub-la/shadcn'
import { FormikProvider, useFormik } from 'formik'

export type CreateRefundRequestValues = {
  invoiceId: string
  description: string
  isValidRefundRequest: boolean
  refundPayerData?: {
    strategy: RefundStrategy
    data: BankAccount | Pix
  }
}

type Props = {
  offers: Offer[]
  isOpen: boolean
  isLoading: boolean
  onClose: () => void
}

export const CreateRefundRequestModal = (props: Props) => {
  const { offers, isOpen, onClose, isLoading } = props

  const { t } = useTranslation()
  const [invoice, setInvoice] = useState<Invoice | null>(null)

  const offersIds = offers.map((x) => x.id)

  const { isLoading: isGetInvoicesLoading, data: invoices = [] } = useGetInvoices(offersIds)
  const { mutateAsync: createRefundRequest, isLoading: loading } = useCreateRefundRequest()

  const formik = useFormik<CreateRefundRequestValues>({
    initialValues: {
      invoiceId: '',
      description: '',
      isValidRefundRequest: false,
    },
    validationSchema: createRefundRequestValidationSchema(invoice?.paymentMethod),
    validateOnChange: true,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: async (values) => {
      await createRefundRequest(values).then(() => {
        toast({ description: t('snackbar.success') })
        onCloseModal()
      })
    },
  })

  const onCloseModal = () => {
    formik.resetForm()
    onClose()
  }

  const onSelectedInvoiceId = async (invoiceId: string) => {
    formik.setFieldValue('invoiceId', invoiceId)

    const invoice = (invoices ?? []).find((invoice) => invoice.id === invoiceId) ?? null
    formik.setFieldValue('isValidRefundRequest', isValidTermsRefundRequest(invoice?.paidAt))

    if (invoice?.paymentMethod === PaymentMethod.BOLETO) {
      formik.setFieldValue('refundPayerData.strategy', RefundStrategy.PIX)
    }

    setInvoice(invoice)
  }

  useEffect(() => {
    formik.setFieldValue('refundPayerData.data', undefined)
  }, [formik.values.refundPayerData?.strategy])

  const isValidTermsRefundRequest = (paidAt?: string) => {
    if (!paidAt) return false

    const currentDate = new Date()
    const ninetyDaysAgo = new Date()
    ninetyDaysAgo.setDate(currentDate.getDate() - 90)

    return new Date(paidAt).getTime() >= ninetyDaysAgo.getTime()
  }

  return (
    <FormikProvider value={formik}>
      <Dialog open={isOpen} onOpenChange={onCloseModal}>
        <DialogContent className="sm:max-w-[525px]">
          <DialogHeader>
            <DialogTitle className="text-2xl font-bold">Solicite seu reembolso</DialogTitle>
            <DialogDescription className="text-sm text-muted-foreground max-w-[90%]">
              Confirme as informações do pedido de reembolso e descreva o seu motivo. Antes, leia nossa{' '}
              <a
                href="/terms_of_use"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:underline"
              >
                política de reembolso
              </a>
            </DialogDescription>
          </DialogHeader>
          <div className="space-y-6">
            <Select onValueChange={onSelectedInvoiceId} defaultValue={formik.values.invoiceId}>
              <SelectTrigger className="w-full">
                <SelectValue placeholder="Selecione uma fatura" />
              </SelectTrigger>
              <SelectContent className="z-[100]">
                {!invoices.length && (
                  <SelectItem value="teste" disabled>
                    Você não possui transações passíveis de reembolso para este produto.
                  </SelectItem>
                )}
                {invoices.map((invoice) => {
                  const productName = invoice?.items.at(0)?.productName ? invoice.items.at(0)?.productName + ' - ' : ''
                  return (
                    <SelectItem key={invoice.id} value={invoice.id}>
                      {productName}
                      {`${formatCurrency(invoice.totalCents / 100)} - ${moment(invoice.paidAt).format(
                        'D MMM. YYYY HH:mm',
                      )} - ${t(`invoiceType.${invoice.type}`)}`}
                    </SelectItem>
                  )
                })}
              </SelectContent>
            </Select>

            <Textarea
              id="description"
              placeholder="Descreva o motivo do pedido. Quanto mais detalhes, maior a chance do produtor aprovar o pedido"
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={formik.errors.description && formik.touched.description ? 'border-red-500' : ''}
            />

            {invoice?.paymentMethod === PaymentMethod.BOLETO && <RefundPayerData />}
          </div>

          <DialogFooter>
            <Button
              className="w-full"
              loading={loading}
              disabled={!formik.isValid || !formik.dirty || isLoading || isGetInvoicesLoading}
              onClick={() => formik.submitForm()}
            >
              Solicitar reembolso
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </FormikProvider>
  )
}
