import { Card, CardContent, CardFooter, Separator } from '@hub-la/shadcn'
import { useTranslation } from 'react-i18next'
import { PaymentDetails as IPaymentDetails } from '../../../../domain/dtos/payment-details'
import { CreditCardLabel } from '../../../components/credit-card-label'
import { ChangePaymentMethodModal } from './change-payment-method-modal'

interface PaymentDetailsProps {
  subscriptionId: string
  offerId: string
  paymentDetails: IPaymentDetails
}

export const PaymentDetails = ({ paymentDetails, subscriptionId, offerId }: PaymentDetailsProps) => {
  const { t } = useTranslation()

  return (
    <Card className="h-full">
      <CardContent className="h-full flex flex-col justify-between gap-4 p-6">
        <div>
          <h3 className="mb-2">{t('paymentMethod')}</h3>
          <CreditCardLabel cardDetails={paymentDetails.cardDetail} />
        </div>
        {paymentDetails.softDescriptor && (
          <div>
            <h3 className="mb-2">{t('softDescriptor')}</h3>
            <p className="text-muted-foreground">{paymentDetails.softDescriptor}</p>
          </div>
        )}
      </CardContent>
      <Separator />
      <CardFooter className="pt-6">
        <ChangePaymentMethodModal subscriptionId={subscriptionId} offerId={offerId} />
      </CardFooter>
    </Card>
  )
}
