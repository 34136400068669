import moment from 'moment-timezone'

export type Shortcut = {
  id: string
  label: string
  startDate: string
  endDate: string
}

export class GetShortcuts {
  public execute(): Shortcut[] {
    const format = 'YYYY-MM-DDTHH:mm:ssZ'
    const today = moment()
    const endOfDay = today.clone().endOf('day').format(format)

    return [
      {
        id: 'today',
        label: 'Hoje',
        startDate: today.clone().startOf('day').format(format),
        endDate: endOfDay,
      },
      {
        id: 'last-7-days',
        label: 'Últimos 7 dias',
        startDate: today.clone().subtract(6, 'days').startOf('day').format(format),
        endDate: endOfDay,
      },
      {
        id: 'last-30-days',
        label: 'Últimos 30 dias',
        startDate: today.clone().subtract(29, 'days').startOf('day').format(format),
        endDate: endOfDay,
      },
      {
        id: 'last-3-months',
        label: 'Últimos 3 meses',
        startDate: today.clone().subtract(2, 'months').startOf('day').format(format),
        endDate: endOfDay,
      },
      {
        id: 'last-12-months',
        label: 'Últimos 12 meses',
        startDate: today.clone().subtract(11, 'months').startOf('day').format(format),
        endDate: endOfDay,
      },
      {
        id: 'this-month',
        label: 'Esse mês',
        startDate: today.clone().startOf('month').format(format),
        endDate: endOfDay,
      },
      {
        id: 'this-quarter',
        label: 'Esse trimestre',
        startDate: today.clone().startOf('quarter').format(format),
        endDate: endOfDay,
      },
      {
        id: 'this-year',
        label: 'Esse ano',
        startDate: today.clone().startOf('year').format(format),
        endDate: endOfDay,
      },
    ]
  }
}
