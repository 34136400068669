import { useContainer } from '@hub-la/fe-container'
import { Auth } from '@hub-la/fe-core-auth'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useQuery } from '@tanstack/react-query'
import Cookies from 'js-cookie'
import { QueryKey } from '../domain/dtos/query-keys'
import { AutoAuthenticateSession } from '../usecases/auto-authenticate-session'

export const useAutoAuthenticateSession = () => {
  const container = useContainer()
  const sessionToken = Cookies.get('__session')

  return useQuery(
    [QueryKey.AUTO_AUTHENTICATE_SESSION],
    () => new AutoAuthenticateSession(container.get(HttpClient), container.get(Auth)).execute(sessionToken),
    {
      retry: false,
      retryOnMount: false,
      refetchOnWindowFocus: false,
      initialData: undefined,
      enabled: Boolean(sessionToken),
    },
  )
}
