export const en = {
  postDetail: {
    sidebar: {
      current: 'You are in',
      header: {
        title: 'Contents',
        previous: 'Previous content',
        next: 'Next content',
      },
      contentQuantity: {
        one: 'content',
        many: 'contents',
      },
      sectinProgress: {
        counter: 'contents',
      },
      durationSubtitle: {
        article: 'Article',
        video: '',
        image: 'Image',
        pdf: 'Pages',
      },
      toggle: {
        mes: 'View Sections',
        hide: 'Hide sections',
      },
    },
    paywall: {
      title: `This content is only available to members of the community "<0>{{name}}</0>", be a member and have access to all content in this community... <1>Learn more</1>`,
      alreadyMember: 'Already a member',
      purchase: 'Buy now',
      onlyMembers: 'Only for members',
    },
    meeting: {
      type: {
        discord: 'Discord',
        meets: 'Google Meets',
        youtube: 'Youtube',
        zoom: 'Zoom Meeting',
        other: 'Live Meeting',
      },
      join: 'Join',
    },
    assets: {
      title: 'Attachments',
      download: 'Download',
    },
  },
  navBar: {
    profile: 'View profile',
    products: 'My Products',
    editProfile: 'Edit Profile',
    accountVerified: 'Account Verified',
    verifyAccount: 'Verify Account',
    bankAccount: 'Bank Account',
    affiliates: 'Affiliate Revenue',
    tipsAndResources: 'Tips and Resources',
    faq: 'Help',
    ourBlog: 'Our blog',
    logout: 'Logout',
    changeMode: {
      creator: 'Use Creator Mode',
      member: 'Use Member Mode',
    },
  },
}
