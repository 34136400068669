import { yupResolver } from '@hookform/resolvers/yup'
import {
  Button,
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  Input,
  Switch,
} from '@hub-la/shadcn'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { useSetExternalMemberArea } from '../../../hooks/member-area/use-set-external-member-area'

const formSchema = Yup.object({
  accessURL: Yup.string().url('O link deve ser uma URL válida').required('O link é obrigatório'),
  accessInstructionMessage: Yup.string().max(1050, 'Máximo de 1050 caracteres'),
  hasAccessInstructionMessage: Yup.boolean(),
})

type FormValues = Yup.InferType<typeof formSchema>

type Props = {
  initialData?: {
    accessURL: string
    accessInstructionMessage: string
    hasAccessInstructionMessage: boolean
    id: string
  }
}

export const ExternalMembersAreaForm: React.FC<Props> = ({ initialData }) => {
  const { productId } = useParams<{ productId: string }>()
  const { mutateAsync: setExternalMembersArea } = useSetExternalMemberArea()
  const { t } = useTranslation()

  const form = useForm<FormValues>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      accessURL: initialData?.accessURL ?? '',
      accessInstructionMessage: initialData?.accessInstructionMessage ?? '',
      hasAccessInstructionMessage: initialData?.hasAccessInstructionMessage ?? false,
    },
  })

  const onSubmit = (values: FormValues) => {
    setExternalMembersArea({
      ...values,
      productId,
      id: initialData?.id,
      hasAccessInstructionMessage: values.hasAccessInstructionMessage ?? false,
      accessInstructionMessage: values.accessInstructionMessage ?? '',
    })
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4">
        <Card className="my-6">
          <CardHeader>
            <CardTitle className="text-xl">Configurar área de membros externa</CardTitle>
          </CardHeader>

          <CardContent className="flex flex-col gap-8">
            <FormField
              control={form.control}
              name="accessURL"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('member-area.content.externalForm.label')}</FormLabel>

                  <FormControl>
                    <Input placeholder="https://" {...field} />
                  </FormControl>

                  <FormDescription>{t('member-area.content.externalForm.helperText')}</FormDescription>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="hasAccessInstructionMessage"
              render={({ field }) => (
                <FormItem className="flex flex-row items-center justify-between">
                  <div>
                    <FormLabel>{t('member-area.content.externalForm.switch')}</FormLabel>

                    <FormDescription>
                      Essa mensagem será exibida quando seu cliente tentar acessar seu produto pela Hubla
                    </FormDescription>
                  </div>

                  <FormControl>
                    <Switch checked={field.value} onCheckedChange={field.onChange} />
                  </FormControl>
                </FormItem>
              )}
            />

            {form.watch('hasAccessInstructionMessage') && (
              <FormField
                control={form.control}
                name="accessInstructionMessage"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('member-area.content.externalForm.instructions')}</FormLabel>

                    <FormControl>
                      <Input {...field} className="min-h-[100px]" maxLength={1050} />
                    </FormControl>

                    <FormDescription>{`${field.value?.length ?? 0}/1050 caracteres.`}</FormDescription>
                  </FormItem>
                )}
              />
            )}
          </CardContent>

          <CardFooter>
            {form.formState.isDirty && (
              <div className="flex justify-end gap-2">
                <Button
                  type="button"
                  variant="outline"
                  onClick={() => form.reset()}
                  disabled={form.formState.isSubmitting}
                >
                  Cancelar
                </Button>

                <Button type="submit" disabled={!form.formState.isValid || form.formState.isSubmitting}>
                  Salvar
                </Button>
              </div>
            )}
          </CardFooter>
        </Card>
      </form>
    </Form>
  )
}
