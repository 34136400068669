import { useContext } from 'react'
import { RoleplayPermissionContextValue } from '../../domain/dtos/roleplay-permission-context-value'
import { RoleplayPermissionContext } from '../providers/roleplay-permission-provider'

const useRoleplay = (): RoleplayPermissionContextValue => {
  const context = useContext(RoleplayPermissionContext)

  return context
}

export { useRoleplay }
