/**
 * GetCouponLinks class provides functionality to generate coupon links based on offer and applicability.
 */
import { CouponItem } from '../domain/dtos/coupon'
import { Offer } from '../domain/dtos/offer'
import { LifetimeInterval } from '../domain/enums/interval'
import { getPayCheckoutUrl } from '../presentation/utils/routes'

export class GetCouponLinks {
  /**
   * Executes the generation of coupon links based on the provided coupon code, applicability, and offer.
   *
   * @param couponCode - The coupon code to be included in the generated links.
   * @param appliableTo - An array of CouponItem objects representing the applicability of the coupon.
   * @param offer - An Offer object representing the details of the offer.
   * @returns An array of objects containing interval and corresponding link for each applicable coupon.
   */
  public execute(couponCode: string, appliableTo?: CouponItem[], offer?: Offer) {
    // If offer or applicability information is not provided, return an empty array.
    if (!offer || !appliableTo) {
      return []
    }

    // If no applicability specified, return a link for 'ALL' intervals.
    if (appliableTo.length === 0) {
      return [
        {
          interval: 'ALL',
          link: `${getPayCheckoutUrl()}/${offer.id}?coupon=${couponCode}`,
        },
      ]
    }

    // Find the matching offer in the appliableTo array based on offer id.
    const matchingOffer = appliableTo.find((item) => item.offerId === offer.id)

    // If no matching offer found, return an empty array.
    if (!matchingOffer) {
      return appliableTo
        .at(0)
        ?.intervals.filter((interval) => offer.intervals.includes(interval))
        .flatMap((interval) => ({
          interval,
          // Generate link with coupon code and, if applicable, plan information.
          link: `${getPayCheckoutUrl()}/${offer.id}?coupon=${couponCode}${
            interval === LifetimeInterval.LIFETIME ? '' : `&plan=${interval.toLowerCase()}`
          }`,
        }))
    }

    // If no specific intervals specified, return a link for 'ALL' intervals.
    if (matchingOffer.intervals.length === 0) {
      return [
        {
          interval: 'ALL',
          link: `${getPayCheckoutUrl()}/${matchingOffer.offerId}?coupon=${couponCode}`,
        },
      ]
    }

    // Generate links based on matching offer intervals and offer intervals.
    return matchingOffer.intervals
      .filter((interval) => offer.intervals.includes(interval))
      .flatMap((interval) => ({
        interval,
        // Generate link with coupon code and, if applicable, plan information.
        link: `${getPayCheckoutUrl()}/${matchingOffer.offerId}?coupon=${couponCode}${
          interval === LifetimeInterval.LIFETIME ? '' : `&plan=${interval.toLowerCase()}`
        }`,
      }))
  }
}
