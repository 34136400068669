import { useFormikContext } from 'formik'
import React from 'react'
import { Field, MAX_FILE_SIZE, UploadStatus } from '../../../domain/dtos'
import { GetFileMimetype, getValidFileExtensions } from '../../../usecases'
import { useUploadAsset } from '../../hooks'

type Props = {
  field: Field
  children?: React.ReactNode
  disabled?: boolean
  statusFieldName: string
}

export const AssetUploader = ({ field, children, disabled, statusFieldName }: Props) => {
  const { setFieldValue } = useFormikContext()
  const { mutateAsync } = useUploadAsset()

  const validExtensions = getValidFileExtensions(field)

  const handleUpload = async (file: File) => {
    const fileType = await new GetFileMimetype().execute(file)

    if (validExtensions && !validExtensions.split(',').includes(fileType)) {
      setFieldValue(statusFieldName, UploadStatus.FILE_NOT_ALLOWED)
      return
    }

    mutateAsync({ file, field })
  }

  return (
    <label htmlFor={field} className="cursor-pointer">
      <input
        type="file"
        data-max-size={MAX_FILE_SIZE}
        id={field}
        name={field}
        data-testid={field}
        accept={validExtensions}
        style={{ display: 'none', margin: 0, padding: 0 }}
        disabled={Boolean(disabled)}
        onChange={async (e) => {
          await handleUpload(e.target.files![0])
          e.target.value = ''
        }}
      />
      {children}
    </label>
  )
}
