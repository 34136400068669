import { container } from '@hub-la/fe-container'
import { DashboardSalesProvider, SmartInstallmentDetail } from '@hub-la/fe-dashboard-sales'
import { useHistory, useParams } from 'react-router-dom'

export const DashboardSmartInstallmentDetailInit = () => {
  const { smartInstallmentId } = useParams<{ smartInstallmentId: string }>()
  const history = useHistory()
  return (
    <DashboardSalesProvider container={container}>
      <SmartInstallmentDetail id={smartInstallmentId} onClose={() => history.goBack()} />
    </DashboardSalesProvider>
  )
}
