import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  Button,
} from '@hub-la/shadcn'
import { useDeleteCoupon } from '../hooks/use-delete-coupon'

type DeleteCouponModalProps = {
  couponId: string
  couponCode: string
  open: boolean
  onClose: () => void
  onSuccess?: () => void
}

export const DeleteCouponModal = ({ couponId, open, onClose, onSuccess }: DeleteCouponModalProps) => {
  const { mutateAsync: deleteCoupon, isLoading } = useDeleteCoupon()

  const onSubmit = () => {
    deleteCoupon(couponId).then(() => {
      onClose()
      onSuccess?.()
    })
  }

  return (
    <AlertDialog open={open} onOpenChange={(isOpen) => !isOpen && onClose()}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Deseja apagar o cupom de desconto?</AlertDialogTitle>

          <AlertDialogDescription>
            Essa ação é irreversível. Não será possível recuperar o cupom, nem visualizar seus dados de uso em futuros
            relatórios.
          </AlertDialogDescription>
        </AlertDialogHeader>

        <AlertDialogFooter className="sm:justify-end">
          <div className="flex flex-col-reverse sm:flex-row gap-2 sm:gap-4 w-full sm:w-auto">
            <AlertDialogCancel asChild>
              <Button variant="outline" onClick={onClose}>
                Cancelar
              </Button>
            </AlertDialogCancel>

            <AlertDialogAction asChild>
              <Button variant="destructive" onClick={onSubmit} disabled={isLoading}>
                {isLoading ? 'Apagando...' : 'Apagar cupom'}
              </Button>
            </AlertDialogAction>
          </div>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
