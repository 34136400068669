import { AssetStatus, AssetType } from '@hub-la/fe-asset'
import { PostStatus } from '@hub-la/fe-post'
import { Button, Checkbox, useIsMobile } from '@hub-la/shadcn'
import { useFormikContext } from 'formik'
import { X } from 'lucide-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Values } from '../../../domain/dtos/values'
import { GetPostId } from '../../../usecases/get-post-id'

export interface Props {
  handleClose: () => void
  isLoading: boolean
  isPublishing: boolean
  setIsPublishing: React.Dispatch<React.SetStateAction<boolean>>
}

export const Header: React.FC<Props> = (props) => {
  const { handleClose, isLoading, setIsPublishing, isPublishing } = props

  const { t } = useTranslation()
  const { values, isValid, setFieldValue, submitForm } = useFormikContext<Values>()
  const { status } = values
  const isMobile = useIsMobile()

  /** IF we are editing a post, we have it's id on the url as a param */
  const postId = new GetPostId().execute()
  const isEditing = !!postId

  const getPageTitle = () => (isEditing ? t('mediaUpload.header.update') : t('mediaUpload.header.create'))

  const getPublishButtonText = () =>
    isEditing && status === PostStatus.PUBLISHED ? t('mediaUpload.header.published') : t('mediaUpload.header.next')

  const isDraftLoading = !isPublishing && isLoading
  const isPublishLoading = isPublishing && isLoading

  const isMainMediaProcessingVideo =
    values.mainMedia?.type === AssetType.video &&
    [AssetStatus.processing, AssetStatus.uploading].includes(values.mainMedia.status)
  const isPublishDisabled = status === PostStatus.PUBLISHED || !isValid || isLoading || isMainMediaProcessingVideo
  const isDraftDisabled = !isValid || isLoading

  return (
    <header className="h-16 w-full px-4 fixed top-0 z-50 flex items-center justify-between border-b  bg-background">
      <div className="flex-1 flex gap-2 items-center">
        <button onClick={handleClose}>
          <X className="h-6 w-6" />
        </button>

        <h1 className="text-base font-medium">{getPageTitle()}</h1>
      </div>

      <div className="flex items-center space-x-4">
        {Boolean(!isMobile && !isEditing) && (
          <div className="hidden sm:flex items-center">
            <Checkbox
              id="create-new"
              checked={values.empty}
              onCheckedChange={(checked) => setFieldValue('empty', checked)}
            />

            <label htmlFor="create-new" className="ml-2 text-sm font-medium">
              Criar novo conteúdo em seguida
            </label>
          </div>
        )}

        <div className="flex items-center space-x-2">
          <Button
            size="sm"
            variant="outline"
            loading={isDraftLoading}
            disabled={isDraftDisabled}
            data-testid="draft-save-button"
            onClick={() => {
              setIsPublishing(false)
              submitForm()
            }}
          >
            {t('mediaUpload.header.save')}
          </Button>

          <Button
            size="sm"
            data-testid="publish-button"
            loading={isPublishLoading}
            disabled={isPublishDisabled}
            onClick={() => {
              setIsPublishing(true)
              setFieldValue('status', PostStatus.PUBLISHED)
              submitForm()
            }}
          >
            {getPublishButtonText()}
          </Button>
        </div>
      </div>
    </header>
  )
}
