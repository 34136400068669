import { Card, CardContent, CardHeader, CardTitle } from '@hub-la/shadcn'

type Props = {
  title: string
  current: number
}

export const CardInfoBox: React.FC<Props> = ({ title, current }: Props) => {
  return (
    <Card className="w-full">
      <CardHeader className="pb-3">
        <CardTitle className="text-lg font-medium">{title}</CardTitle>
      </CardHeader>

      <CardContent>
        <span className="text-3xl font-medium">{current}</span>
      </CardContent>
    </Card>
  )
}
