import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useQuery } from '@tanstack/react-query'
import { GetCouponsInput } from '../../domain/dtos/get-coupon-input'
import { QueryKey } from '../../domain/enums/query-key'
import { GetCoupons } from '../../usecases/get-coupons'

export const useGetCoupons = (input: GetCouponsInput, isLoading: boolean) => {
  const container = useContainer()

  return useQuery(
    [QueryKey.getCoupons, JSON.stringify(input)],
    ({ signal }) => new GetCoupons(container.get(HttpClient)).execute(input, signal),
    {
      retry: 3,
      retryOnMount: false,
      refetchOnWindowFocus: false,
      enabled: !isLoading,
      initialData: {
        page: 1,
        pageSize: 0,
        count: 0,
        coupons: [],
      },
    },
  )
}
