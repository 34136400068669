import { Dialog, DialogContent } from '@hub-la/shadcn'
import React from 'react'
import { useGetWithdrawalDetails } from '../../hooks/use-get-withdrawal-details'
import { LoadingModalContent } from '../invoice-modal/modal-content/loading-content'
import { WithdrawalContent } from './withdrawal-content/withdrawal-content'

export interface WithdrawalDetailsDialogProps {
  withdrawalId: string | undefined
  onClose: () => void
}

export const WithdrawalDetailsModal: React.FC<WithdrawalDetailsDialogProps> = ({ withdrawalId, onClose }) => {
  const { isLoading, isFetching, data } = useGetWithdrawalDetails(withdrawalId)

  const content =
    data && !isLoading && !isFetching ? (
      <WithdrawalContent withdrawal={data} key={`withdrawal-content-${withdrawalId}`} isLoading={isLoading} />
    ) : (
      <LoadingModalContent />
    )

  return (
    <Dialog open={!!withdrawalId} onOpenChange={(open) => !open && onClose()}>
      <DialogContent className="h-[80vh] max-h-[600px] sm:max-w-[425px] overflow-hidden">{content}</DialogContent>
    </Dialog>
  )
}
