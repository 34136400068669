export const pt = {
  cnpj: 'CNPJ',
  cpf: 'CPF',
  cpfHelper: 'Para verificar sua identidade, precisamos do seu CPF',
  fullName: 'Nome completo',
  fullNameHelper: 'Nome jurídico, como aparece no documento de identificação emitido pelo orgão público',
  businessName: 'Razão social',
  birthday: 'Data de nascimento',
  email: 'E-mail',
  phoneNumber: 'Telefone',
  step: {
    0: 'Dados da empresa',
    1: 'Dados pessoais',
    2: 'Dados bancários',
    3: 'Dados públicos',
    4: 'Verificar identidade',
    5: 'Revisar e finalizar',
  },
  accountVerification: {
    form: {
      modality: {
        title: 'Vamos ativar sua conta',
        subtitle: 'Escolha o tipo de empresa para começar',
        label: 'Tipo de empresa',
        2: 'Pessoa física (CPF)',
        1: 'Pessoa jurídica (CNPJ)',
        tooltip: {
          title: 'Essa ação requer atenção',
          subtitle:
            'A Hubla validará o tipo de cadastro selecionado, seja para pessoa física (CPF) ou jurídica (CNPJ), conforme os dados informados. <0>A alteração deste dado no futuro é extremamente trabalhoso</0>, mas requer um processo manual e nova verificação por parte da nossa equipe de prevenção. É imprescindível que a conta bancária para recebimento esteja vinculada ao mesmo ID fiscal (CPF/CNPJ) da entidade escolhida. Em caso de necessidade de alteração, por favor, entre em contato com o nosso suporte técnico. Para mais informações, visite nosso site de suporte.',
        },
      },
      person: {
        title: 'Verifique seus dados pessoais',
        subtitle: 'Nós coletamos esses dados para verificar sua identidade e manter a segurança da sua conta',
      },
      cpf: {
        label: 'Nome jurídico',
        tooltip: 'Responsável e proprietário legal da conta bancária que será cadastrada.',
      },
      cnpj: {
        companyLabel: 'Dados da empresa',
        ownerLabel: 'Nome completo conforme documento oficial',
        tooltip:
          'Responsável pela empresa, devendo estar autorizado a representar a empresa conforme quadro societário.',
      },
      payout: {
        title: 'Adicione sua conta bancária para receber por suas vendas',
        subtitle: 'Coletamos estes dados para atender melhor à sua empresa e cumprir os requisitos de parceiros financeiros'
      },
      bankAccount: {
        title: 'bancários',
        subtitle:
          'Registre uma conta bancária para receber seu faturamento. Lembrando que os dados da conta bancária cadastrada devem ser da mesma titularidade cadastrada acima.',
        bank: 'Banco',
        agency: 'Agência',
        agencyDigit: 'Dígito',
        account: 'Conta',
        accountDigit: 'Dígito',
        accountType: 'Tipo de conta',
        types: {
          0: 'Não especificado',
          1: 'Conta corrente',
          2: 'Conta poupança',
        },
      },
      billingAddress: {
        title: {
          1: 'Endereço do administrador da empresa',
          2: 'Endereço do titular da conta',
        },
        number: 'Número',
        complement: 'Complemento',
        street: 'Rua',
        postalCode: 'CEP',
        city: 'Cidade',
        state: 'Estado',
        neighborhood: 'Bairro',
      },
      softDescriptor: {
        title: 'Como o seu produto deve aparecer na fatura do cliente?',
        subtitle: 'Estes dados podem ser mostrados em extratos, faturas e recibos de pagamentos.',
        label: 'Descrição no extrato',
        tooltip:
          'A descrição no extrato é mostrada nos extratos bancários ou de cartão de crédito do seu cliente. Ela deve ter entre 5 e 11 caracteres, não pode conter caracteres especiais e não ser apenas números.',
        helperText: '{{length}} de 11 caracteres.',
      },
      idwall: {
        title: 'Verificar identidade',
        subtitle: 'Precisamos verificar sua identidade para manter a segurança da sua conta',
        label: 'Verificar a identidade de <0>{{name}}</0>',
        status: {
          success: 'Documento enviado',
          pending: 'Documento pendente',
        },
      },
      pixKey: {
        title: 'Chave Pix',
        subtitle:
          'Para saques na modalidade Pix, utilizaremos como chave o mesmo documento informado nos dados do titular da conta.',
      },
      reviewAndSave: {
        title: {
          success: 'Tudo pronto',
          error: 'Problema ao ativar sua conta',
          default: 'Revisar e finalizar',
        },
        subtitle: {
          success:
            'Obrigado por informar os dados necessários para continuar com a Hubla. Caso ocorra um problema iremos entrar em contato para coletar detalhes adicionais.',
          error:
            'As informações enviadas não passaram em nossas verificações de segurança. Por favor, confira as informações abaixo para erros de digitação. Se necessário, tente novamente.',
          default:
            'Agora falta pouco para você começar a vender. Confire os dados e clique em enviar para ativar sua conta.',
        },
      },
      close: 'Fechar',
      submit: 'Enviar dados para verificação',
      continue: 'Continuar',
    },
  },
  accountVerificationBanner: {
    close: 'Fechar',
    title: 'Cadastre seus dados bancários para começar a vender na plataforma.',
    cta: 'Cadastrar dados bancários',
  },
  updatePayout: {
    title: 'Adicione sua conta bancária para receber por suas vendas',
    cpf: {
      label: 'Dados do titular da conta',
      tooltip: 'Responsável e proprietário legal da conta bancária cadastrada.',
    },
    cnpj: {
      label: 'Dados do administrador da empresa',
      tooltip: 'Responsável pela empresa, devendo estar autorizado a representar a empresa conforme quadro societário.',
    },
    submit: 'Enviar dados',
  },
  confirmationModal: {
    create: {
      title: 'Cadastre seus dados para começar a vender',
      message: 'Para começar a vender dentro da plataforma, precisamos verificar suas informações pessoais.',
      cancel: 'Talvez mais tarde',
      confirm: 'Cadastrar dados',
    },
    update: {
      title: 'Cadastre uma conta bancária para realizar saques na plataforma',
      message:
        'Para realizar saques, precisamos que você informe uma conta bancária válida. Caso já tenha cadastrado, você pode atualizar os dados.',
      cancel: 'Talvez mais tarde',
      confirm: 'Atualizar dados bancários',
    },
  },
  roleplayModal: {
    create: {
      title: 'O administrador da conta ainda não verificou as informações bancárias',
      message: 'Entre em contato com o administrador da conta antes de tentar realizar essa ação.',
      cancel: 'Fechar',
    },
    update: {
      title: 'Os dados bancários do administrador da conta ainda não foram aprovados',
      message: 'Entre em contato com o administrador da conta antes de tentar realizar essa ação.',
      cancel: 'Fechar',
    },
  },
  documentTypeEnum: {
    0: 'Não especificado',
    1: 'CNPJ',
    2: 'CPF',
    3: 'Passaporte',
    100: 'Não declarado',
  },
  errors: {
    required: 'Campo obrigatório',
    invalidDocument: 'Documento inválido',
    invalidEmail: 'E-mail inválido',
    invalidPhoneNumber: 'Telefone inválido',
    invalidFullName: 'Nome precisa ser completo',
    invalidBankAccount: 'Conta bancária inválida',
    invalidAgency: 'Agência inválida',
    invalidAgencyCheckNumber: 'Dígito da agência inválido',
    invalidAccount: 'Conta inválida',
    invalidAccountType: 'Tipo de conta inválido',
    invalidAccountCheckNumber: 'Dígito da conta inválido',
    invalidBank: 'Banco inválido',
    invalidSoftDescriptor: 'Descrição não pode ser vazia',
    minSoftDescriptor: 'Descrição precisa ter pelo menos 5 caracteres',
    general: 'Ocorreu um erro, tente novamente mais tarde.',
    'not-found': 'A conta bancária informada não existe.',
    'different-ownership': 'A conta informada não é da mesma titularidade cadastrada.',
    'different-ownership-cnpj': 'A conta informada não é da mesma titularidade do CNPJ cadastrado.',
  },
  pendencies: {
    personalInfo: {
      title: 'Não conseguimos verificar sua identidade através das informações fornecidas.',
      subtitle:
        'Garanta que os dados pessoais informados são idênticos aos presentes no documento enviado para verificar a identidade.',
    },
    idwall: {
      picture: {
        title: 'Não conseguimos verificar sua identidade através das imagens fornecidas.',
        subtitle:
          'Certifique-se de que seu rosto está claramente visível e que não há reflexos ou obstruções na imagem, como óculos ou má iluminação.',
      },
      document: {
        title: 'Não conseguimos verificar seu documento através das imagens fornecidas.',
        subtitle: 'Certifique-se de que seu documento esteja válido ou envie um novo documento para validação.',
      },
    },
  },
  success: 'Dados bancários cadastrados com sucesso!',
}
