import { Button, Card, CardContent, Label, Pix } from '@hub-la/shadcn'
import { CreditCard, Barcode } from 'lucide-react'
import React, { useState } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { CardDetail } from '../../../../domain/dtos/card-detail'
import { CardBrand } from '../../../../domain/enums/card-brand'
import { PaymentMethod } from '../../../../domain/enums/payment-method'
import amex from '../../../assets/amex.png'
import elo from '../../../assets/elo.png'
import mastercard from '../../../assets/mastercard.png'
import visa from '../../../assets/visa.png'
import { ChangePaymentMethodModal } from './change-payment-method-modal'

type Props = {
  paymentMethod?: PaymentMethod
  cardDetails?: CardDetail
  subscriptionId: string
  offerId: string
}

const CardIcon: React.FC<{ src: string; alt: string }> = ({ src, alt }) => (
  <img src={src} alt={alt} className="w-[16px] h-auto object-contain object-center" />
)

const getIcon = (paymentMethod?: PaymentMethod, cardBrand?: CardBrand) => {
  if (paymentMethod === PaymentMethod.PIX) return <Pix className="w-4 h-4" />
  if (paymentMethod === PaymentMethod.BOLETO) return <Barcode className="w-4 h-4" />
  switch (cardBrand) {
    case CardBrand.CARD_BRAND_VISA:
      return <CardIcon src={visa} alt="Visa" />
    case CardBrand.CARD_BRAND_MASTERCARD:
      return <CardIcon src={mastercard} alt="Mastercard" />
    case CardBrand.CARD_BRAND_AMEX:
      return <CardIcon src={amex} alt="American Express" />
    case CardBrand.CARD_BRAND_ELO:
      return <CardIcon src={elo} alt="Elo" />
    default:
      return <CreditCard className="w-4 h-4" />
  }
}

const getText = (t: TFunction<'translation', undefined>, paymentMethod?: PaymentMethod, cardDetails?: CardDetail) => {
  if (paymentMethod === PaymentMethod.PIX) return 'Pix'
  if (paymentMethod === PaymentMethod.CREDIT_CARD) {
    return cardDetails ? `${t(`cardBrand.${cardDetails?.brand}`)} •••• ${cardDetails?.last4}` : 'Cartão de crédito'
  }
  return ''
}

export const PaymentDetails: React.FC<Props> = ({ paymentMethod, cardDetails, subscriptionId, offerId }) => {
  const [open, setOpen] = useState<boolean>(false)
  const { t } = useTranslation()
  return (
    <Card className="h-full">
      <CardContent className="h-full flex flex-col justify-between gap-4 p-6">
        <div className="w-full space-y-1.5">
          <Label>Método de pagamento</Label>
          <div className="flex items-center gap-2 text-muted-foreground">
            {getIcon(paymentMethod, cardDetails?.brand)}
            <p className="text-sm">{getText(t, paymentMethod, cardDetails)}</p>
          </div>
        </div>
        <Button variant="outline" className="w-full" onClick={() => setOpen(true)}>
          Alterar método de pagamento
        </Button>
      </CardContent>
      <ChangePaymentMethodModal
        open={open}
        onClose={() => setOpen(false)}
        subscriptionId={subscriptionId}
        offerId={offerId}
      />
    </Card>
  )
}
