import { Button, Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@hub-la/shadcn'
import { X } from 'lucide-react'
import { useTranslation } from 'react-i18next'

type Props = {
  open: boolean
  onClose: () => void
  onSubmit: () => void
}

export const DeleteModal: React.FC<Props> = ({ open, onClose, onSubmit }) => {
  const { t } = useTranslation()

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <div className="w-full flex items-center justify-between">
            <DialogTitle>{t('deleteModal.title')}</DialogTitle>

            <Button variant="ghost" size="icon" onClick={onClose}>
              <X className="h-4 w-4" />
            </Button>
          </div>
        </DialogHeader>

        <div className="flex flex-col gap-3">
          <p>{t('deleteModal.subtitle')}</p>
          <p className="font-bold">{t('deleteModal.warning')}</p>
        </div>

        <DialogFooter>
          <div className="w-full flex flex-col-reverse sm:flex-row gap-2 sm:justify-end">
            <Button variant="ghost" onClick={onClose}>
              {t('deleteModal.cancel')}
            </Button>

            <Button variant="destructive" onClick={onSubmit}>
              {t('deleteModal.submit')}
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
