import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { PostAcceptInviteInput } from '../domain/dtos/post-accept-invite-input'
import { Envs } from '../envs'

export enum ErrorCode {
  INVITE_ALREADY_PHONE_NUMBER = 'invite::already-phone-number',
  INVITE_EMAIL_MISMATCH = 'invite::email-mismatch',
  INVITE_NOT_FOUND = 'invite::not-found',
  INVITE_INVALID_TYPE = 'invite::invalid-type',
  INVITE_UNSUPPORTED_STATUS = 'invite::unsupported-status',
}

export class PostAcceptInvite {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(input: PostAcceptInviteInput, signal?: AbortSignal): Promise<null> {
    const response = await this.httpClient.request({
      method: HttpMethod.POST,
      url: `${Envs.BFF_MEMBERS_AREA_URL}/invites/${input.inviteId}/accept`,
      signal,
      body: this.makeBody(input),
    })

    // wait payments and warehouse create subscription
    await this.sleep(3000)

    if (response.statusCode === HttpStatusCode.OK || response.statusCode === HttpStatusCode.CREATED) {
      return response.data
    }

    switch (response.data.errorCode) {
      case ErrorCode.INVITE_ALREADY_PHONE_NUMBER:
        throw new Error(
          'O telefone informado já está associado a outra conta! Escolha outro telefone ou entre em contato com o produtor para alteração do e-mail de convite.',
        )
      case ErrorCode.INVITE_EMAIL_MISMATCH:
        throw new Error('O e-mail informado não corresponde ao e-mail do convite.')
      case ErrorCode.INVITE_NOT_FOUND:
        throw new Error('O convite não foi encontrado.')
      case ErrorCode.INVITE_INVALID_TYPE:
        throw new Error('O convite não possui um tipo válido..')
      case ErrorCode.INVITE_UNSUPPORTED_STATUS:
        throw new Error('O status do convite não está mais disponível.')
      default:
        throw new Error('Erro inesperado ao aceitar convite.')
    }
  }

  private makeBody(input: PostAcceptInviteInput) {
    const body = {
      input: {
        email: input.email,
        fullName: input.fullName,
        phoneNumber: input.phoneNumber,
      },
    }

    return body
  }

  private sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }
}
