import { useEffect, useMemo, useState } from 'react'
import { useGetSections } from './settings/use-get-sections'
import { useGetExternalMemberArea } from './use-get-external-member-area'

export enum MemberAreaType {
  Internal,
  External,
  None,
}

export const useGetMemberAreaType = ({ productId }: { productId: string }) => {
  const [memberAreaType, setMemberAreaType] = useState<MemberAreaType>(MemberAreaType.None)

  // Memoize the productId to ensure idempotency based on this key
  const idempotencyKey = useMemo(() => productId, [productId])

  const { data: sections, isLoading: isLoadingSections } = useGetSections({ productId })
  const { data: externalMemberArea, isLoading: isLoadingExternalMemberArea } = useGetExternalMemberArea({ productId })

  useEffect(() => {
    if (memberAreaType !== MemberAreaType.None && productId !== idempotencyKey) return
    if ((sections?.length ?? 0) > 0) {
      setMemberAreaType(MemberAreaType.Internal)
    } else if (externalMemberArea) {
      setMemberAreaType(MemberAreaType.External)
    }
  }, [sections, externalMemberArea, productId])

  return {
    memberAreaType,
    isLoading: isLoadingSections || isLoadingExternalMemberArea,
    setMemberAreaType,
    externalMemberArea,
    sections,
  }
}
