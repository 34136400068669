import { Badge, Input } from '@hub-la/shadcn'
import { useFormikContext } from 'formik'
import { X } from 'lucide-react'
import React, { useState } from 'react'
import { CreateEditRuleForm } from './create-edit-rule-modal'

const InputTags: React.FC = () => {
  const formik = useFormikContext<CreateEditRuleForm>()

  const [inputValue, setInputValue] = useState('')

  const handlePasteOnInput = (text: string) => {
    const withComma = text.split(',')
    const values = withComma.filter((value) => value.trim() !== '').map((value) => ({ id: value, name: value }))

    const newValues = [...formik.values.tags, ...values].filter(
      (value, index, self) => self.findIndex((t) => t.id === value.id) === index,
    )

    formik.setFieldValue('tags', newValues)
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => setInputValue(e.target.value)

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' || event.key === ',') {
      event.preventDefault()

      const tags = inputValue
        .replace(/[\n\s]+/g, ',')
        .split(',')
        .map((tag) => ({ id: tag, name: tag }))
        .filter((tag) => tag.name !== '')

      formik.setFieldValue('tags', [...formik.values.tags, ...tags])
      setInputValue('')
    }
  }

  const handleRemove = (id: string) => {
    formik.setFieldValue(
      'tags',
      formik.values.tags.filter((tag) => tag.id !== id),
    )
  }

  return (
    <div>
      <Input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        onPaste={(e) => {
          e.preventDefault()
          handlePasteOnInput(e.clipboardData.getData('text'))
        }}
        onBlur={(e) => {
          e.preventDefault()
          handlePasteOnInput(e.target.value)
          setInputValue('')
        }}
        className="w-full p-2 border rounded-md"
      />
      <div className="flex flex-wrap gap-2 my-2">
        {formik.values.tags.map((tag, index) => (
          <Badge key={index} variant="secondary" className="flex items-center gap-1 px-2 py-1">
            {tag.name}
            <button
              onClick={() => handleRemove(tag.id)}
              className="text-muted-foreground hover:text-secondary-foreground focus:outline-none"
            >
              <X size={14} />
            </button>
          </Badge>
        ))}
      </div>
      <p className="text-[0.8rem] text-muted-foreground">Preencha com as tags separadas por vírgula (ex: tag1, tag2)</p>
    </div>
  )
}

export { InputTags }
