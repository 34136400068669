import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useFormikContext } from 'formik'
import { useEffect } from 'react'
import { v4 } from 'uuid'
import { UpgradePaymentMethods } from '../../domain/dtos/payment-method'
import { PaymentMethod } from '../../domain/enums/payment-method'
import { GetSession } from '../../usecases/get-session'
import { Values } from '../pages/user-smart-installment-cycle/components/change-payment-method-modal'

export const useGetSession = () => {
  const container = useContainer()
  const { values, setFieldValue } = useFormikContext<Values>()

  const fetch = async () =>
    await new GetSession(container.get(HttpClient))
      .execute({
        merchantOrderId: v4(),
        paymentMethod:
          values.paymentMethod === UpgradePaymentMethods.PAYMENT_METHOD_CARD
            ? PaymentMethod.CREDIT_CARD
            : PaymentMethod.PIX,
      })
      .then((data) => {
        setFieldValue('sessionId', data.sessionId)
        setFieldValue('customerId', data.customerId)
      })

  useEffect(() => {
    fetch()
  }, [values.paymentMethod])
}
