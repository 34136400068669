import { Tabs, TabsList, TabsTrigger } from "@hub-la/shadcn"
import { layoutTypeFieldOptions } from "../../../../../../domain/constants/layout-type-options"
import { useTranslation } from "react-i18next"
import { CheckoutLayoutType } from "../../../../../../domain/enums/checkout-layout-type.enum"

interface LayoutSelectorProps {
  onChange: (theme: CheckoutLayoutType) => void
  value: CheckoutLayoutType
}

export const LayoutSelector = (props: LayoutSelectorProps) => {
  const { t } = useTranslation()

  return (
    <div>
      <h3 className="text-sm font-medium mb-1">{t('offer.checkoutTab.preferences.layout.label')}</h3>
      <Tabs
        value={props.value}
        onValueChange={(value) => props.onChange(value as CheckoutLayoutType)}
        className="max-w-max"
      >
        <TabsList className="flex">
          <>
            {layoutTypeFieldOptions.map((layoutType) => (
              <TabsTrigger value={layoutType.id} key={layoutType.id}>
                {layoutType.icon}
              </TabsTrigger>
            ))}
          </>
        </TabsList>
      </Tabs>
      <span className="text-sm text-muted-foreground">
        {t(`offer.checkoutTab.preferences.layout.${props.value}`)}
      </span>
    </div>
  )
}
