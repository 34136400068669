import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation } from '@tanstack/react-query'
import { EditRuleInput } from '../../domain/dtos/edit-rule-input'
import { EditRule } from '../../usecases/edit-rule'

export const useEditRule = () => {
  const container = useContainer()

  return useMutation([], (input: EditRuleInput) => new EditRule(container.get(HttpClient)).execute(input), {
    retry: false,
  })
}
