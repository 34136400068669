import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useToast } from '@hub-la/shadcn'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { GetAssociatedCohortsInput } from '../../../domain/dtos/member-area/get-associated-cohorts-input'
import { QueryKey } from '../../../domain/enums/query-key'
import { GetAssociatedCohorts } from '../../../usecases/member-area/get-associated-cohorts'

export const useGetAssociatedCohorts = (input: GetAssociatedCohortsInput) => {
  const { toast } = useToast()
  const { t } = useTranslation()
  const container = useContainer()

  return useQuery(
    [QueryKey.getAssociatedCohorts, input.productId],
    () => new GetAssociatedCohorts(container.get(HttpClient)).execute(input),
    {
      enabled: !!input.productId && !!input.resourceExternalId,
      retry: 3,
      retryOnMount: false,
      refetchOnWindowFocus: false,
      onError: (error: Error) => {
        toast({ description: t(error.message), variant: 'destructive' })
      },
    },
  )
}
