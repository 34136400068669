import { AffiliationProgramPage, AffiliationProgramProvider } from '@hub-la/fe-affiliation-program'
import { container } from '@hub-la/fe-container'

export const AffiliationProgramInit = () => {
  return (
    <AffiliationProgramProvider container={container}>
      <AffiliationProgramPage />
    </AffiliationProgramProvider>
  )
}
