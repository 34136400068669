import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation } from '@tanstack/react-query'
import { DeleteRuleInput } from '../../domain/dtos/delete-rule-input'
import { DeleteRule } from '../../usecases/delete-rule'

export const useDeleteRule = () => {
  const container = useContainer()

  return useMutation([], (input: DeleteRuleInput) => new DeleteRule(container.get(HttpClient)).execute(input), {
    retry: false,
  })
}
