import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { InitChangePaymentMethodOutput } from '../domain/dtos/init-change-payment-method-output'
import { Envs } from '../envs'

export class InitChangePaymentMethod {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) { }

  public async execute(subscriptionId: string): Promise<InitChangePaymentMethodOutput> {
    const response = await this.httpClient.request({
      method: HttpMethod.POST,
      url: `${Envs.BFF_WEB_URL}/pay/init-change-payment-method`,
      body: this.makeBody(subscriptionId),
    })

    if ([HttpStatusCode.OK, HttpStatusCode.CREATED].includes(response.statusCode)) {
      return response.data
    }

    throw new Error(response.data?.code ?? 'GENERAL')
  }

  private makeBody(subscriptionId: string) {
    return {
      subscriptionId,
    }
  }
}
