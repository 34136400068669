import { SectionResponse } from '@hub-la/fe-post'

type GetMoveSectionOptionsInput = {
  sectionId: string
  sections: SectionResponse[]
}

export class GetMoveSectionOptions {
  public execute({ sectionId, sections }: GetMoveSectionOptionsInput) {
    const options = sections
      .filter((it) => it.id !== sectionId)
      .map((it) => {
        return { id: it.id, name: it.name ? it.name : 'Seção sem nome' }
      })

    return options
  }
}
