import { ImageUploader } from '@hub-la/fe-asset'
import { Card, CardContent, Separator } from '@hub-la/shadcn'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ColorPicker } from '../../color-picker'

export const CustomContent = () => {
  const { t } = useTranslation()

  const productId = 'productId'

  return (
    <div>
      <h4 className="text-xl font-semibold">{t('ticket.custom.title')}</h4>

      <Card>
        <CardContent className="p-6 flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <p className="text-sm font-medium">{t('ticket.custom.primaryColor.label')}</p>
            <ColorPicker name="primaryColor" />
          </div>

          <Separator />

          <div className="flex flex-col gap-2">
            <p className="text-sm font-medium">{t('ticket.custom.textColor.label')}</p>
            <ColorPicker name="textColor" />
          </div>

          <Separator />

          <div className="flex justify-between items-start">
            <div>
              <p className="text-sm font-medium mb-1">{t('ticket.custom.logo.label')}</p>
              <p className="text-xs text-muted-foreground">{t('ticket.custom.logo.description')}</p>
            </div>

            <Controller
              name="logoUrl"
              render={({ field }) => (
                <ImageUploader
                  name={field.name}
                  path={`/ticket/logo-${productId}`}
                  className="w-24 h-14"
                  value={field.value}
                  onChange={(file) => field.onChange(file?.URL)}
                />
              )}
            />
          </div>
        </CardContent>
      </Card>
    </div>
  )
}
