import { PermissionResource, useRoleplay } from '@hub-la/fe-roleplay'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@hub-la/shadcn'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { ProductType } from '../../../domain/enums/product-type'
import { useGetProduct } from '../../hooks/use-get-product'
import { Affiliates } from './affiliates'
import { BasicInfo } from './basic-info'
import { MyPartners } from './my-partners'
import { ProductPage } from './product-page'
import { Trackers } from './trackers'
import { WelcomeMessage } from './welcome-message'

type Props = {
  offerId?: string
}

export const SettingsPage: React.FC<Props> = ({ offerId }) => {
  const [tab, setTab] = useState<string>(new URLSearchParams(window.location.search).get('tab') ?? 'basicInfo')

  const history = useHistory()
  const { t } = useTranslation()
  const { hasPermission } = useRoleplay()
  const { productId } = useParams<{ productId: string }>()
  const { data: product } = useGetProduct(productId)

  const isEvent = product?.type === ProductType?.EVENT

  const onTabChange = (tab: string) => {
    history.push(history.location.pathname + '?tab=' + tab + (isEvent ? '&event=true' : ''))
    setTab(tab)
  }

  return (
    <div className="flex flex-col gap-4 py-6">
      <h1 className="text-xl font-semibold">{t('menu.settings')}</h1>

      <Tabs value={tab} onValueChange={onTabChange} className="flex flex-col gap-2">
        <div className="w-full overflow-x-auto">
          <TabsList>
            <TabsTrigger value="basicInfo">{t('settings.tabs.basicInfo')}</TabsTrigger>

            <TabsTrigger value="productPage">{t('settings.tabs.productPage')}</TabsTrigger>

            <TabsTrigger value="trackers">{t('settings.tabs.trackers')}</TabsTrigger>

            {hasPermission(PermissionResource.PRODUCTS_MANAGE_CO_PRODUCTION) && (
              <TabsTrigger value="myPartners">{t('settings.tabs.myPartners')}</TabsTrigger>
            )}

            <TabsTrigger value="affiliates">{t('settings.tabs.affiliates')}</TabsTrigger>

            {!isEvent && <TabsTrigger value="welcomeMessage">{t('settings.tabs.welcomeMessage')}</TabsTrigger>}
          </TabsList>
        </div>

        <TabsContent value="basicInfo">
          <BasicInfo />
        </TabsContent>

        <TabsContent value="productPage">
          <ProductPage />
        </TabsContent>

        <TabsContent value="trackers">
          <Trackers />
        </TabsContent>

        {hasPermission(PermissionResource.PRODUCTS_MANAGE_CO_PRODUCTION) && (
          <TabsContent value="myPartners">
            <MyPartners offerId={offerId} />
          </TabsContent>
        )}

        <TabsContent value="affiliates">
          <Affiliates />
        </TabsContent>

        {!isEvent && (
          <TabsContent value="welcomeMessage">
            <WelcomeMessage />
          </TabsContent>
        )}
      </Tabs>
    </div>
  )
}
