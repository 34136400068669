export class GetAssetPlaybackId {
  execute(assetVideoUrl: string): string | undefined {
    const regex = /\/([a-zA-Z0-9]+)\.m3u8$/
    const matches = assetVideoUrl.match(regex)
    if (matches && matches.length > 1) {
      return matches[1]
    }
    return undefined
  }
}
