import { AssetApplicationSubType, AssetImageSubType, AssetType, AssetVideoSubType } from '../domain/dtos'
import { Field } from '../domain/dtos/field'
import { Envs } from '../envs'

export const getValidFileExtensions = (field: Field): string => {
  let data: string[] = []

  if (field === 'mainMedia') {
    data = Object.values(AssetImageSubType).map((item) => `${AssetType.image}/${item}`)
    data = data.concat(
      Object.values(AssetApplicationSubType).map((item) => `${AssetType.application}/${item}`),
      Object.values(AssetVideoSubType).map((item) => `${AssetType.video}/${item}`),
    )
  } else if (field === 'attachments') {
    data = [
      Envs.ATTACHMENT_FILE_MIMETYPE_ALLOWED,
      Envs.ATTACHMENT_IMAGE_MIMETYPE_ALLOWED,
      Envs.ATTACHMENT_VIDEO_MIMETYPE_ALLOWED,
    ]
  } else if (field === 'videoAsset') {
    data = [Envs.ATTACHMENT_VIDEO_MIMETYPE_ALLOWED]
  } else if (field === 'customCover' || field === 'desktopAsset' || field === 'mobileAsset') {
    data = [Envs.ATTACHMENT_IMAGE_MIMETYPE_ALLOWED]
  }

  return data.toString()
}
