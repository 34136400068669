import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { SaveAssetProgressRequest } from '../domain/dtos/save-asset-progress-request'
import { Envs } from '../envs'

export class SaveAssetProgress {
  public constructor(@inject(HttpClient) private readonly httpClient: HttpClient) {}

  public async execute(payload: SaveAssetProgressRequest): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .request({
          url: `${Envs.BFF_MEMBERS_AREA_URL}/assets/progress`,
          method: HttpMethod.PATCH,
          body: payload,
        })
        .then((response) => {
          if (![HttpStatusCode.NO_CONTENT, HttpStatusCode.OK].includes(response.statusCode)) {
            return reject(`Ocorreu um erro ao salvar o progresso ${payload.assetId}`)
          }

          return resolve(true)
        })
    })
  }
}
