import { HttpClient, HttpMethod, HttpResponse, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import isNil from 'lodash/isNil'
import { GetProductsOutput } from '../domain/dtos/get-products-output'
import { GetProductsResponse } from '../domain/dtos/get-products-response'
import { GeneralError } from '../domain/errors/general'
import { Envs } from '../envs'

export class GetAllProducts {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(prependOptionForAll: boolean): Promise<GetProductsOutput> {
    const response = await this.httpClient.request({
      method: HttpMethod.GET,
      url: `${Envs.BFF_PRODUCT_URL}/filters/products`,
    })

    if (response.statusCode === HttpStatusCode.OK) {
      return this.makeOutput(response, prependOptionForAll)
    }

    throw new GeneralError()
  }

  private makeOutput(response: HttpResponse<GetProductsResponse>, prependOptionForAll: boolean): GetProductsOutput {
    const data = response.data

    if (isNil(data)) {
      return []
    }

    const products = data.items ?? []

    if (prependOptionForAll) {
      products.unshift({
        id: 'all',
        name: 'Todos',
      })
    }

    return products
  }
}
