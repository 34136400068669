import { Badge, Button, Dialog, DialogContent, Pix } from '@hub-la/shadcn'
import { Barcode, CreditCard } from 'lucide-react'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { CouponItem } from '../../../domain/dtos/coupon'
import { LifetimeInterval, RecurringInterval } from '../../../domain/enums/interval'
import { PaymentMethod } from '../../../domain/enums/payment-method'
import { ContentRow } from './content-row'
import { EntitiesCards } from './entities-cards'

type DescriptionProps = {
  discount: number
  usagesCount: number
  maxAllowedTotalUsages: number
  appliableTo: CouponItem[]
  isRecurrent: boolean
  paymentMethods: PaymentMethod[]
}

export const Description = ({
  discount,
  usagesCount,
  maxAllowedTotalUsages,
  appliableTo,
  isRecurrent,
  paymentMethods,
}: DescriptionProps) => {
  const [openProductsModal, setOpenProductsModal] = useState<boolean>(false)
  const { t } = useTranslation()

  const getOfferNames = (): JSX.Element => {
    if (!appliableTo || !appliableTo.find((it) => it.offerId))
      return <Trans i18nKey="description.productsContent.every" />

    if (appliableTo.length > 3)
      return (
        <Trans
          i18nKey="description.productsContent.many"
          values={{
            products: appliableTo
              .slice(0, 3)
              .map((it) => it.offerName)
              .join(', '),
            count: appliableTo.length - 3,
          }}
          components={[<Button variant="link" onClick={() => setOpenProductsModal(true)} className="p-0 h-auto" />]}
        />
      )

    return (
      <Trans
        i18nKey="description.productsContent.specific"
        values={{ products: appliableTo.map((it) => it.offerName).join(', ') }}
      />
    )
  }

  const getUniqueIntervals = () => {
    const uniqueIntervals = [] as (RecurringInterval | LifetimeInterval)[]

    appliableTo.forEach((item) => {
      item.intervals.forEach((interval) => {
        if (!uniqueIntervals.includes(interval)) {
          uniqueIntervals.push(interval)
        }
      })
    })

    const uniqueIntervalsNames = uniqueIntervals.map((interval) => t(`planType.${interval}`))

    return uniqueIntervals.length > 0 ? uniqueIntervalsNames.join(', ') : 'Todos os planos'
  }

  return (
    <div className="space-y-6 my-4">
      <div>
        <div className="space-y-4">
          <ContentRow variant="middle" title={t('description.discount')} content={<span>{discount}%</span>} />

          <ContentRow
            variant="middle"
            title={t('description.usagesCount')}
            content={
              <Badge variant="outline">
                {usagesCount}/{maxAllowedTotalUsages ?? '∞'}
              </Badge>
            }
          />

          <ContentRow variant="middle" title={t('description.products')} content={<span>{getOfferNames()}</span>} />

          <ContentRow variant="middle" title={t('description.plans')} content={<span>{getUniqueIntervals()}</span>} />

          <ContentRow
            variant="middle"
            title={t('description.paymentMethod')}
            content={
              <div className="flex items-center space-x-2">
                {paymentMethods.map((paymentMethod) => (
                  <div key={paymentMethod} className="flex items-center space-x-1">
                    {paymentMethod === PaymentMethod.CREDIT_CARD && <CreditCard className="w-4 h-4" />}
                    {paymentMethod === PaymentMethod.PIX && <Pix className="w-4 h-4" />}
                    {paymentMethod === PaymentMethod.BOLETO && <Barcode className="w-4 h-4" />}
                    <span>{t(`paymentMethod.${paymentMethod}`)}</span>
                  </div>
                ))}
              </div>
            }
          />

          <ContentRow
            variant="middle"
            title={t('description.isRecurrent')}
            content={<span>{isRecurrent ? 'Sim' : 'Não'}</span>}
          />
        </div>
      </div>

      <Dialog open={openProductsModal} onOpenChange={setOpenProductsModal}>
        <DialogContent>
          <EntitiesCards
            products={
              appliableTo
                ? appliableTo.map((it) => ({
                    name: it.offerName ?? '',
                    id: it.offerId ?? '',
                  }))
                : []
            }
          />
        </DialogContent>
      </Dialog>
    </div>
  )
}
