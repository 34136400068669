export enum ProductStatus {
  DRAFT = 'isDraft',
  PUBLISHED = 'isPublished',
  SELLING = 'isSelling',
  WAITLISTED = 'isWaitlisted',
  NOT_ENABLED = 'isNotEnabled',
  NOT_SELLING = 'isNotSelling',
  UNAVAILABLE = 'isUnavailable',
  DELETED = 'isDeleted',
}
