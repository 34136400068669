import { Skeleton, TableCell, TableRow } from '@hub-la/shadcn'

export const TableRowLoading: React.FC = () => (
  <TableRow>
    <TableCell>
      <div className="flex gap-2 items-center">
        <Skeleton className="h-6 w-14 rounded-full" />

        <div className="w-full flex flex-col gap-2">
          <Skeleton className="h-10 w-40"></Skeleton>

          <Skeleton className="h-4 w-36"></Skeleton>
        </div>
      </div>
    </TableCell>

    <TableCell>
      <Skeleton className="h-6 w-14"></Skeleton>
    </TableCell>

    <TableCell>
      <Skeleton className="h-8 w-48"></Skeleton>
    </TableCell>

    <TableCell>
      <Skeleton className="h-5 w-14 rounded-full"></Skeleton>
    </TableCell>

    <TableCell>
      <Skeleton className="ml-2 h-2 w-4"></Skeleton>
    </TableCell>
  </TableRow>
)
