export enum QueryKey {
  // members
  getActiveMembers = 'getActiveMembers',
  getDeactivatesMembers = 'getDeactivatesMembers',
  postTransformFreeMembers = 'postTransformFreeMembers',
  putRemoveFreeSubscription = 'putRemoveFreeSubscription',
  postChangeMembersCohorts = 'postChangeMembersCohorts',

  // invite
  postAcceptInvite = 'postAcceptInvite',
  getAcceptInvite = 'getAcceptInvite',
  putAcceptInvite = 'putAcceptInvite',
  getPendingInvites = 'getPendingInvites',
  postAddFreeMembers = 'postAddFreeMembers',
  getExportActiveMembers = 'getExportActiveMembers',
  getExportDeactivateMembers = 'getExportDeactivateMembers',
  getShortLinks = 'getShortLinks',
  postCreateShortLink = 'postCreateShortLink',
  putShortLink = 'putShortLink',
  deleteShortLink = 'deleteShortLink',
  postDuplicateShortLink = 'postDuplicateShortLink',
  getCheckAvailableSlug = 'getCheckAvailableSlug',
  getProduct = 'getProduct',
  toggleProductVisibility = 'toggleProductVisibility',
  changeProductStatus = 'changeProductStatus',
  getSettings = 'getSettings',
  saveSettings = 'saveSettings',
  listMyPartners = 'listMyPartners',
  requestCancellation = 'requestCancellation',
  getWaitlist = 'getWaitlist',
  exportWaitlist = 'exportWaitlist',
  getOffersAndCohorts = 'getOffersAndCohorts',

  // Member Area
  getAvailableResources = 'getAvailableResources',
  getResources = 'getResources',
  addResource = 'addResource',
  bindResourceToProduct = 'bindResourceToProduct',
  unbindResourceToProduct = 'unbindResourceToProduct',
  updateResource = 'updateResource',
  getAssociatedCohorts = 'getAssociatedCohorts',
  deleteResource = 'deleteResource',
  getExternalMemberArea = 'getExternalMemberArea',
  setExternalMemberArea = 'setExternalMemberArea',

  // Offers
  getOffersList = 'getOffersList',
  postChangeStatus = 'postChangeStatus',
  patchOfferName = 'patchOfferName',
  getAllOffers = 'getAllOffers',

  // Cohorts
  getListCohorts = 'getListCohorts',
  patchCohortName = 'patchCohortName',

  // Tickets
  listEventTickets = 'listEventTickets',
}
