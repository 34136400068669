import { Button, Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@hub-la/shadcn'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useBindOrUnbindResourceToProduct } from '../../../../../hooks/member-area/use-bind-or-unbind-resource-to-product'

interface UnbindGroupCardInterface {
  open: boolean
  onClose: () => void
  offerId?: string
  resourceId?: string
}

export const UnbindGroupCard: React.FC<UnbindGroupCardInterface> = ({ open, onClose, offerId, resourceId }) => {
  const { t } = useTranslation()
  const { mutateAsync: unbindResource, isLoading: isUnbindingResourceLoading } = useBindOrUnbindResourceToProduct({
    action: 'unbind',
  })
  const handleOnSubmit = () => {
    unbindResource({ offerId, externalResourceId: resourceId })
      .then(() => onClose())
      .catch()
  }

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle className="flex justify-between items-center">
            {t('member-area.groups.unbindGroupModal.title')}
          </DialogTitle>
        </DialogHeader>

        <DialogFooter className="sm:justify-end">
          <div className="w-full sm:w-auto flex flex-col-reverse sm:flex-row gap-4 justify-end">
            <Button variant="outline" onClick={onClose} className="w-full sm:w-auto">
              {t('member-area.groups.unbindGroupModal.actionButtons.cancel')}
            </Button>

            <Button
              variant="destructive"
              onClick={handleOnSubmit}
              disabled={isUnbindingResourceLoading}
              className="w-full sm:w-auto"
            >
              {t('member-area.groups.unbindGroupModal.actionButtons.delete')}
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
