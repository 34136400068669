import { HttpClient, HttpMethod, HttpResponse } from '@hub-la/fe-core-http-client'
import { GetMembersAreaSettingsInput, GetMembersAreaSettingsResponse } from '../domain/dtos/get-members-area'
import { Envs } from '../envs'

export class GetMembersArea {
  public constructor(private readonly httpClient: HttpClient) {}

  public async execute(values: GetMembersAreaSettingsInput) {
    try {
      const response: HttpResponse<GetMembersAreaSettingsResponse> = await this.httpClient.request({
        url: `${Envs.BFF_MEMBERS_AREA_URL}/products/${values.productId}/settings/members-area`,
        method: HttpMethod.GET,
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })

      return response.data
    } catch (error) {
      throw new Error('Error get members area')
    }
  }
}
