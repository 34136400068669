export const Envs = {
  RUDDERSTACK_WRITE_KEY:
    process.env['NX_RUDDERSTACK_WRITE_KEY'] ??
    process.env['REACT_APP_RUDDERSTACK_TOKEN'] ??
    process.env['NEXT_PUBLIC_RUDDERSTACK_WRITE_KEY'],
  RUDDERSTACK_DATA_PLANE_URL:
    process.env['NX_RUDDERSTACK_DATA_PLANE_URL'] ??
    process.env['REACT_APP_RUDDERSTACK_DATA_PLANE_URL'] ??
    process.env['NEXT_PUBLIC_RUDDERSTACK_DATA_PLANE_URL'],
  RUDDERSTACK_IS_DEBUG:
    process.env['NX_RUDDERSTACK_IS_DEBUG'] ??
    process.env['REACT_APP_RUDDERSTACK_IS_DEBUG'] ??
    process.env['NEXT_PUBLIC_RUDDERSTACK_IS_DEBUG'],

  SITE_URL: process.env['NX_SITE_URL'] ?? process.env['REACT_APP_SITE_URL'] ?? '',
  BFF_WEB_URL: process.env['NX_BFF_WEB_URL'] ?? process.env['REACT_APP_HUB_API'],
  FUNCTIONS_URL: process.env['NX_FUNCTIONS_URL'] ?? process.env['REACT_APP_FUNCTIONS_URL'],

  ENV: process.env['ENVIRONMENT'] ?? process.env['REACT_APP_CUSTOM_NODE_ENV'],
  BFF_URL: process.env['NX_BFF_CHECKOUT_URL'] ?? process.env['REACT_APP_BFF_URL'],

  ATTACHMENT_FILE_MIMETYPE_ALLOWED:
    process.env['NX_FILE_MIMETYPE_ALLOWED'] ?? process.env['REACT_APP_FILE_MIMETYPE_ALLOWED'] ?? '',
  ATTACHMENT_VIDEO_MIMETYPE_ALLOWED:
    process.env['NX_VIDEO_MIMETYPE_ALLOWED'] ?? process.env['REACT_APP_VIDEO_MIMETYPE_ALLOWED'] ?? '',
  ATTACHMENT_IMAGE_MIMETYPE_ALLOWED:
    process.env['NX_IMAGE_MIMETYPE_ALLOWED'] ?? process.env['REACT_APP_IMAGE_MIMETYPE_ALLOWED'] ?? '',
  MUX_STREAM_URL: process.env['MUX_STREAM_URL'] ?? process.env['REACT_APP_MUX_STREAM_URL'] ?? '',

  TINYMCE_API_KEY: process.env['NX_TINYMCE_API_KEY'] ?? process.env['REACT_APP_TINYMCE'] ?? '',
  BFF_MEMBERS_AREA_URL: process.env['NX_BFF_MEMBERS_AREA_URL'] ?? process.env['REACT_APP_BFF_MEMBERS_AREA_URL'],
}
