import { container } from '@hub-la/fe-container'
import { UserGroupsPage, UserGroupsProvider } from '@hub-la/fe-user-groups'
import { withRoleplayRedirect } from '../components/roleplay-redirect'

export const UserGroupsInit = withRoleplayRedirect(() => {
  return (
    <UserGroupsProvider container={container}>
      <UserGroupsPage />
    </UserGroupsProvider>
  )
})
