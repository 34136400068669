import { UploadImage } from '@hub-la/fe-asset'
import * as tinymce from '@tinymce/tinymce-react'
import React, { useRef } from 'react'
import { Editor as TinyMCEEditor } from 'tinymce'
import * as uuid from 'uuid'
import { Envs } from '../../../../envs'

interface Props extends Partial<tinymce.IAllProps> {
  initialValue?: string
  value?: string
  placeholder?: string
  disabled?: boolean
  onEditorChange?: (data: string, editor: TinyMCEEditor) => void
}

export const Editor: React.FC<Props> = (props) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const { initialValue, value, placeholder, onEditorChange, disabled, init } = props

  return (
    <div className="w-full">
      <tinymce.Editor
        disabled={disabled}
        value={initialValue || value}
        apiKey={Envs.TINYMCE_API_KEY}
        init={{
          min_height: 400,
          branding: false,
          skin: 'oxide',
          placeholder: placeholder ?? 'Descreva sobre o seu produto...',
          menubar: false,
          content_style: `
          body { 
            font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
            font-size: 16px;
            line-height: 1.5;
          }
        `,
          fontsize_formats: '16px 18px 20px 24px',
          toolbar1: 'undo redo | fontsizeselect | bold italic | link image ',
          toolbar2: 'alignleft aligncenter alignright media',
          plugins: 'autoresize image media table link',
          allow_script_urls: true,
          elementpath: false,
          icons: 'thin',
          max_height: 1000,
          paste_data_images: true,
          automatic_uploads: true,
          images_upload_handler: async (blobInfo, success, failure, progress) => {
            await new UploadImage()
              .execute(blobInfo.blob() as File, `/description/${uuid.v4()}`, (percent) => {
                console.log({ percent, progress })
                progress?.(percent)
              })
              .then((fc) => (fc?.URL ? success(fc.URL) : failure('Fc is null')))
              .catch((e) => failure(e))
          },
          file_picker_types: 'image',
          file_picker_callback: (cb: any, value: any, meta: any) => {
            inputRef.current?.click()
            inputRef.current!.onchange = async (ev) => {
              const event = ev as any
              const f = event.target.files[0]
              const fc = await new UploadImage().execute(f, `/description/${uuid.v4()}`)
              cb(fc?.URL, { title: fc?.name, width: '100%', height: 'auto' })
            }
          },
          ...init,
        }}
        onEditorChange={(data, editor) => onEditorChange?.(data, editor)}
      />

      <input
        data-testid="editor-upload"
        disabled={disabled}
        ref={inputRef}
        className="hidden"
        type="file"
        multiple={false}
        max-size="2000"
        accept={Envs.ATTACHMENT_IMAGE_MIMETYPE_ALLOWED}
      />
    </div>
  )
}
