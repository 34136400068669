import { Card, CardContent, CardDescription, CardHeader } from '@hub-la/shadcn'
import { ReactNode } from 'react'
import { Indicator } from '../../domain/dtos/indicator'
import { Status } from '../../domain/dtos/status'
import { InfoTooltip } from './info-tooltip'
import { StatusBox } from './status-box'

type CardInfoBoxProps = {
  title: string
  description?: string
  current: string
  previous?: string
  variation?: {
    indicator?: Indicator
    status?: Status
    description: string
  }
  children?: ReactNode
}

export const CardInfoBox = ({ title, description, current, previous, variation, children }: CardInfoBoxProps) => {
  return (
    <div className="w-full h-full">
      <Card className="flex flex-col min-h-[128px] gap-2">
        <CardHeader className="pb-0 space-y-0">
          <div className="flex items-center gap-1">
            <p className="text-sm font-normal text-muted-foreground">{title}</p>
            {description && <InfoTooltip title={description} />}
          </div>
          <CardDescription className="flex flex-row gap-2 items-center">
            <h3 className="font-semibold text-4xl tracking-tight text-primary">{current}</h3>
            {previous && <span className="font-normal text-sm text-muted-foreground">{previous}</span>}
          </CardDescription>
        </CardHeader>
        <CardContent>
          {variation && (
            <div className="flex items-start flex-wrap">
              <StatusBox
                indicator={variation.indicator}
                status={variation.status}
                description={variation.description}
              />
            </div>
          )}
          {children}
        </CardContent>
      </Card>
    </div>
  )
}
