import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { GeneralError } from '../domain/errors/general'
import { Envs } from '../envs'

export class GetCohort {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(productId: string, cohortId: string, signal?: AbortSignal) {
    const response = await this.httpClient.request({
      method: HttpMethod.GET,
      url: `${Envs.BFF_PRODUCT_URL}/products/${productId}/cohorts/${cohortId}`,
      signal,
    })

    if ([HttpStatusCode.OK, HttpStatusCode.CREATED].includes(response.statusCode)) {
      return response.data
    }

    throw new GeneralError()
  }
}
