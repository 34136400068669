import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { SendRuleTestInput } from '../domain/dtos/send-rule-test-input'
import { GeneralError } from '../domain/errors/general'
import { Envs } from '../envs'

export class SendRuleTest {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient<void>,
  ) {}

  public async execute(input: SendRuleTestInput): Promise<void> {
    const response = await this.httpClient.request({
      method: HttpMethod.POST,
      url: `${Envs.BFF_WEB_URL}/integrations/${input.integrationId}/rules/${input.ruleId}/sandbox`,
    })

    if (response.statusCode === HttpStatusCode.CREATED) {
      return
    }

    throw new GeneralError()
  }
}
