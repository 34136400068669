import {
  Badge,
  Button,
  cn,
  Command,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Separator,
} from '@hub-la/shadcn'
import { CheckIcon, PlusCircle } from 'lucide-react'
import { paymentMethods } from '../../domain/constants/payment-method'

export const SelectPaymentMethod = ({ filters, setFilters }) => {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="outline">
          <PlusCircle className="h-4 w-4 mr-1" />
          <div className="flex items-center justify-start">
            {filters.methods.length > 0 ? (
              filters.methods.length === 1 ? (
                <>
                  <span className="text-left">Método</span>
                  <Separator orientation="vertical" className="mx-2 h-4" />
                  <Badge variant="secondary" className="mr-1 font-normal">
                    {paymentMethods.find((method) => method.id === filters.methods[0])?.label}
                  </Badge>
                </>
              ) : (
                <>
                  <span className="text-left">Método</span>
                  <Separator orientation="vertical" className="mx-2 h-4" />
                  {filters.methods.length > 2 ? (
                    <Badge variant="secondary" className="mr-1 font-normal">
                      {filters.methods.length} selecionados
                    </Badge>
                  ) : (
                    filters.methods.map((methodId) => (
                      <Badge key={methodId} variant="secondary" className="mr-1 font-normal">
                        {paymentMethods.find((method) => method.id === methodId)?.label}
                      </Badge>
                    ))
                  )}
                </>
              )
            ) : (
              <span className="text-left">Método de Pagamento</span>
            )}
          </div>
        </Button>
      </PopoverTrigger>

      <PopoverContent className="w-auto p-0 z-10002" align="start">
        <Command>
          <CommandInput placeholder="Método de Pagamento" />
          <CommandList>
            <CommandGroup>
              {paymentMethods.map(({ id, label }) => {
                const checked = filters.methods.includes(id)
                return (
                  <CommandItem
                    key={id}
                    onSelect={() => {
                      const newMethods = filters.methods.includes(id)
                        ? filters.methods.filter((method) => method !== id)
                        : [...filters.methods, id]
                      setFilters({ ...filters, methods: newMethods })
                    }}
                  >
                    <div
                      className={cn(
                        'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                        checked
                          ? 'bg-hubla-primary text-primary-foreground border-hubla-primary'
                          : 'opacity-50 [&_svg]:invisible',
                      )}
                    >
                      <CheckIcon className={cn('h-4 w-4')} />
                    </div>
                    {label}
                  </CommandItem>
                )
              })}
            </CommandGroup>
            {filters.methods.length > 0 && (
              <>
                <CommandSeparator />
                <CommandGroup>
                  <CommandItem
                    onSelect={() => setFilters({ ...filters, methods: [] })}
                    className="justify-center text-center"
                  >
                    Limpar filtros
                  </CommandItem>
                </CommandGroup>
              </>
            )}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}
