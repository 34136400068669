import { Loader2 } from 'lucide-react'
import { Document, Page, pdfjs } from 'react-pdf'

export interface Props {
  url?: string
  height: number | string
  width: number | string
}

export const Pdf = ({ url, height, width }: Props) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

  return (
    <Document
      loading={<Loader2 className="mr-2 h-4 w-4 animate-spin" />}
      file={url}
      renderMode="canvas"
      className="h-full flex items-center justify-center object-contain"
    >
      <Page
        key={'page_1'}
        width={width}
        height={height}
        className="document-pdf-page"
        pageNumber={1}
        renderAnnotationLayer={false}
      />
    </Document>
  )
}
