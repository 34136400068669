import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import * as React from 'react'

import { useIsMobile } from '../hooks'
import { cn } from '../utils'

const TooltipContext = React.createContext<{
  open: boolean
  setOpen: (open: boolean) => void
}>({
  open: false,
  setOpen: (open: boolean) => open,
})

const TooltipProvider = ({ children, ...props }: TooltipPrimitive.TooltipProviderProps) => {
  const [open, setOpen] = React.useState(false)

  return (
    <TooltipPrimitive.TooltipProvider {...props}>
      <TooltipContext.Provider value={{ open, setOpen }}>{children}</TooltipContext.Provider>
    </TooltipPrimitive.TooltipProvider>
  )
}

const Tooltip = ({ delayDuration = 200, children, ...props }: TooltipPrimitive.TooltipProps) => {
  const { open, setOpen } = React.useContext(TooltipContext)
  const isMobile = useIsMobile()

  return (
    <TooltipPrimitive.Root
      open={isMobile ? open : undefined}
      onOpenChange={isMobile ? setOpen : undefined}
      delayDuration={delayDuration}
      {...props}
    >
      {children}
    </TooltipPrimitive.Root>
  )
}

const TooltipTrigger = ({ children, ...props }: TooltipPrimitive.TooltipTriggerProps) => {
  const { open, setOpen } = React.useContext(TooltipContext)
  const isMobile = useIsMobile()

  return (
    <TooltipPrimitive.Trigger asChild={isMobile} data-testid="tooltip-trigger" {...props}>
      {isMobile ? (
        <div onClick={() => setOpen(!open)} data-testid="tooltip-mobile-trigger">
          {children}
        </div>
      ) : (
        children
      )}
    </TooltipPrimitive.Trigger>
  )
}

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
  <TooltipPrimitive.Content
    ref={ref}
    sideOffset={sideOffset}
    className={cn(
      'z-50 overflow-hidden rounded-md border bg-background px-3 py-1.5 text-sm text-foreground max-w-96 shadow-md animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
      className,
    )}
    data-testid="tooltip-content"
    {...props}
  />
))
TooltipContent.displayName = TooltipPrimitive.Content.displayName

export { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger }
