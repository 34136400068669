import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { Envs } from '../envs'

export class CheckGroupMemberUseCase {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(id: string): Promise<boolean> {
    const response = await this.httpClient.request({
      method: HttpMethod.POST,
      url: `${Envs.BFF_ACCESS_URL}/access/has-access-to-any`,
      body: this.makeBody(id),
    })

    if ([HttpStatusCode.OK, HttpStatusCode.CREATED].includes(response.statusCode)) {
      return response.data
    }

    throw new Error('Erro ao buscar dados de acesso')
  }

  private makeBody(id: string) {
    return { externalIds: [id] }
  }
}
