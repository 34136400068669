import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useQuery } from '@tanstack/react-query'
import { ListOffersInput } from '../../domain/dtos/list-offers-input'

import { QueryKey } from '../../domain/enums/query-key.enum'
import { ListOffers } from '../../usecases/list-offers.usecase'

export const useGetOffers = (input?: ListOffersInput) => {
  const container = useContainer()

  return useQuery(
    [QueryKey.getListOffers, input?.productId],
    ({ signal }) => new ListOffers(container.get(HttpClient)).execute(input, signal),
    {
      refetchOnWindowFocus: false,
      retry: 3,
      enabled: !!input?.productId,
      initialData: {
        page: 0,
        pageSize: 0,
        total: 0,
        lastPage: 0,
        items: [],
      },
    },
  )
}
