import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { SectionResponse } from '@hub-la/fe-post'
import { inject, injectable } from 'inversify'
import { CreateSectionRequest, UpdateSectionRequest } from '../domain/dtos'
import { Envs } from '../envs'

@injectable()
export class SaveSection {
  public constructor(@inject(HttpClient) private readonly httpClient: HttpClient) {}

  public async execute(
    payload: CreateSectionRequest | UpdateSectionRequest,
    sectionId?: string,
  ): Promise<SectionResponse> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .request({
          url: this.makeUrl(sectionId),
          body: payload,
          headers: {
            accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: sectionId ? HttpMethod.PUT : HttpMethod.POST,
        })
        .then((response) => {
          if (!response.data || ![HttpStatusCode.CREATED, HttpStatusCode.OK].includes(response.statusCode)) {
            return reject(`Ocorreu um erro ao ${sectionId ? 'salvar' : 'criar'} a seção`)
          }

          return resolve(this.makeData(response.data))
        })
        .catch(() => {
          return reject(`Ocorreu um erro ao ${sectionId ? 'salvar' : 'criar'} a seção`)
        })
    })
  }

  private makeData = (data: SectionResponse): SectionResponse => {
    return data
  }

  private makeUrl(sectionId?: string) {
    return sectionId
      ? `${Envs.BFF_MEMBERS_AREA_URL}/hub/sections/${sectionId}`
      : `${Envs.BFF_MEMBERS_AREA_URL}/hub/sections`
  }
}
