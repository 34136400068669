import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { GeneralError } from '../domain/errors/general'
import { Envs } from '../envs'
import { CohortSchemaValidationType } from '../presentation/validations/cohort-validation'

type CohortSchemaValidationTypeWithId = CohortSchemaValidationType & { productId: string; cohortId: string }

export class PutCohort {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(input: CohortSchemaValidationTypeWithId, signal?: AbortSignal) {
    const response = await this.httpClient.request({
      method: HttpMethod.PUT,
      url: `${Envs.BFF_PRODUCT_URL}/products/${input.productId}/cohorts/${input.cohortId}`,
      signal,
      body: this.makeBody(input),
    })

    if ([HttpStatusCode.OK, HttpStatusCode.CREATED].includes(response.statusCode)) {
      return response.data
    }

    throw new GeneralError()
  }

  // UpdateCohortRequestDto wait jv merge PR to change any
  private makeBody(input: CohortSchemaValidationTypeWithId): any {
    const sectionsIds = input.sections ?? []
    const groupsIds = input.groups ?? []

    const externalResourceIds = [...sectionsIds, ...groupsIds]

    return {
      name: input.name,
      externalResourceIds,
    }
  }
}
