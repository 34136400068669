import { Badge } from '@hub-la/shadcn'
import { useTranslation } from 'react-i18next'
import { MemberType } from '../../domain/enums/member-type'

type SubscriptionTagProps = {
  type: MemberType
}

export const SubscriptionTag = ({ type }: SubscriptionTagProps) => {
  const { t } = useTranslation()

  if (type === MemberType.BUYER) return null

  const isFreeSubscription = type === MemberType.FREE

  return <Badge className={isFreeSubscription ? 'bg-blue-100 text-blue-800' : ''}>{t(`memberType.${type}`)}</Badge>
}
