import { Avatar, AvatarFallback, AvatarImage, Button, Card, Skeleton } from '@hub-la/shadcn'
import { ChevronRight, Loader2 } from 'lucide-react'
import React, { useState } from 'react'
import { AccountCardProps } from '../../domain/dtos/account-card-props'

export const AccountCard: React.FC<AccountCardProps> = ({ userId, avatarUrl, name, email, loading, onClick }) => {
  const [isHovered, setIsHovered] = useState(false)
  const [selectedUser, setSelectedUser] = useState<string>()

  const handleSignIn = () => {
    onClick?.(userId)
    setSelectedUser(userId)
  }

  return (
    <Card
      className={`
        flex justify-between border-none shadow-none items-center p-2 cursor-pointer
        hover:bg-secondary transition-all duration-200
        ${loading ? 'pointer-events-none' : ''}
      `}
      onClick={!loading ? handleSignIn : undefined}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="flex items-center space-x-4">
        <Avatar className="h-10 w-10">
          <AvatarImage className="object-cover" src={avatarUrl} alt={name} />
          <AvatarFallback>
            {name ? name.charAt(0) : <Skeleton className="h-full w-full rounded-full bg-muted" />}
          </AvatarFallback>
        </Avatar>
        <div>
          <p className="text-sm font-medium text-secondary-foreground">{name}</p>
          <p className="text-sm text-muted-foreground">{email}</p>
        </div>
      </div>

      {isHovered && (
        <Button variant="ghost" size="sm" className="flex items-center space-x-1">
          <span>Acessar</span>
          <ChevronRight className="h-4 w-4" />
        </Button>
      )}

      {loading && selectedUser === userId && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
    </Card>
  )
}
