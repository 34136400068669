import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { QueryKey, SectionList } from '@hub-la/fe-post'
import { QueryObserverSuccessResult, useQuery } from '@tanstack/react-query'
import { ListSections } from '../../usecases/list-sections'

export const useListSections = ({ productId, queryKey }: { productId: string | undefined; queryKey?: string }) => {
  const container = useContainer()

  const queryKeyCustom = queryKey ? [QueryKey.listSections, queryKey] : [QueryKey.listSections]

  return useQuery(queryKeyCustom, () => new ListSections(container.get(HttpClient)).execute(productId), {
    enabled: !!productId,
    retry: 3,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    initialData: {
      items: [],
      page: 1,
      pageSize: 90,
      total: 10,
    },
  }) as QueryObserverSuccessResult<SectionList, never>
}
