import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useFormikContext } from 'formik'
import { get } from 'lodash'
import { AssetType, SaveAssetRequest } from '../../domain/dtos'
import { UploadStatus } from '../../domain/dtos/upload-status'
import { GetFileMimetype } from '../../usecases'
import { GenerateThumbnail } from '../../usecases/generate-thumbnail'
import { GetVideoThumbnail } from '../../usecases/get-video-thumbnail'
import { HasFileValidSize } from '../../usecases/has-file-valid-size'
import { useSaveAsset } from './use-save-asset'
import { useUpdateAsset } from './use-update-asset'
import { useUpdateAssetDuration } from './use-update-asset-duration'

export const useUploadAsset = () => {
  const saveAsset = useSaveAsset()
  const container = useContainer()
  const updateAsset = useUpdateAsset()
  const updateAssetDuration = useUpdateAssetDuration()

  const { setFieldValue } = useFormikContext()

  const mutateAsync = async ({ file, field }): Promise<void> => {
    const type = await new GetFileMimetype().execute(file)

    return new Promise(() => {
      const { name } = file

      const statusFields = {
        mainMedia: 'mainMediaProgressStatus',
        customCover: 'customCoverStatus',
        videoAsset: 'videoAssetStatus',
        desktopAsset: 'desktopAssetStatus',
        mobileAsset: 'mobileAssetStatus',
        logoAsset: 'logoAssetStatus',
      }
      const progressFields = {
        mainMedia: 'mainMediaProgress',
        customCover: 'customCoverProgress',
        videoAsset: 'videoAssetProgress',
        desktopAsset: 'desktopAssetProgress',
        mobileAsset: 'mobileAssetProgress',
        logoAsset: 'logoAssetProgress',
      }

      const statusField = statusFields[field]
      const progressField = progressFields[field]

      setFieldValue(statusField, UploadStatus.LOADING)

      const hasFileValidSize = new HasFileValidSize().execute(file.size)

      if (!hasFileValidSize) {
        return setFieldValue(statusField, UploadStatus.ERROR)
      }

      const asset: SaveAssetRequest = {
        name: name,
        mimeType: type,
        file,
      }

      saveAsset
        .mutateAsync({
          payload: asset,
          onUploadProgress: (percentage: number) => {
            setFieldValue(progressField, percentage)
          },
        })
        .then(async (data) => {
          if (data !== undefined) {
            if (data.type === AssetType.image) {
              setFieldValue(field, data)
            }

            if (data.type === AssetType.video) {
              setFieldValue(statusField, UploadStatus.PROCESSING)
              setFieldValue(field, data)

              const video = await new GetVideoThumbnail(container.get(HttpClient)).execute(data)

              setFieldValue(field, video)
            }

            if (data.type === AssetType.application) {
              const asset = data
              const thumbnail = await saveAsset.mutateAsync({
                payload: await new GenerateThumbnail().execute(asset),
                onUploadProgress: () => {
                  return
                },
              })

              asset.id = data.id
              asset.metadata = data.metadata || {
                duration: null,
                pages: null,
                size: 0,
                thumbnailUrl: null,
              }

              asset.metadata.thumbnailUrl = get(thumbnail, 'url')
              asset.metadata.size = get(data, 'metadata.size', 0)
              asset.url = data.url
              asset.progress = data.progress
              asset.name = data.name

              // Update Asset to save thumbnail url in database
              const updateAssetThumbnail = {
                assetId: asset.id,
                name: asset.name,
                metadata: asset.metadata,
              }

              await updateAsset.mutateAsync({ payload: updateAssetThumbnail })

              setFieldValue(field, asset)
            }

            await updateAssetDuration.mutateAsync(data.id, asset.file)
            setFieldValue(statusField, UploadStatus.SUCCESS)
          }
        })
        .catch(() => {
          setFieldValue(statusField, UploadStatus.ERROR)
        })
      return
    })
  }

  return {
    mutateAsync,
  }
}
