import { Button, CopyClipboardButton, Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@hub-la/shadcn'
import { ChevronLeft } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { SmartInstallment } from '../../../domain/dtos/smart-installment'
import { ReceiverRole } from '../../../domain/enums/receiver-role'
import { SmartInstallmentStatus } from '../../../domain/enums/smart-installment-status'
import { SmartInstallmentStatusStats } from '../../components/smart-installment-status-stats'
import { CancelInstallmentModal } from './cancel-installment-modal'

type Props = {
  smartInstallment: SmartInstallment
  role: ReceiverRole
  onChange: () => void
  onClose: () => void
}

export const Header = ({ smartInstallment, role, onChange, onClose }: Props) => {
  const history = useHistory()
  const { t } = useTranslation()

  const canCancel =
    role === ReceiverRole.ROLE_SELLER &&
    [SmartInstallmentStatus.ON_SCHEDULE, SmartInstallmentStatus.OFF_SCHEDULE].includes(smartInstallment.status)

  return (
    <div className="space-y-4 md:space-y-6">
      <div className="flex flex-col gap-4 md:flex-row md:items-center md:justify-between">
        <div className="space-y-2">
          <Button className="h-4 px-0" size="sm" variant="link" onClick={() => history.goBack()}>
            <ChevronLeft className="w-4 h-4 mr-2" />
            Voltar
          </Button>

          <div className="flex flex-col sm:flex-row sm:items-center gap-2 sm:gap-3 mb-2">
            <div className="flex flex-wrap items-center gap-1.5 max-w-full">
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <h3 className="text-2xl md:text-3xl font-semibold truncate max-w-[300px]">
                      {smartInstallment.payer?.identity?.fullName}
                    </h3>
                  </TooltipTrigger>
                  <TooltipContent>{smartInstallment.payer?.identity?.fullName}</TooltipContent>
                </Tooltip>
              </TooltipProvider>
              <span className="text-2xl md:text-3xl text-muted-foreground shrink-0">para</span>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <h3 className="text-2xl md:text-3xl font-semibold truncate max-w-[300px]">
                      {smartInstallment.invoiceRef?.items.at(0)?.productName ?? '-'}
                    </h3>
                  </TooltipTrigger>
                  <TooltipContent>{smartInstallment.invoiceRef?.items.at(0)?.productName ?? '-'}</TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
            <div className="flex flex-wrap items-center gap-2">
              <SmartInstallmentStatusStats status={smartInstallment.status} />
            </div>
          </div>

          <div>
            <CopyClipboardButton
              copyContent={smartInstallment.id}
              label={smartInstallment.id}
              tooltipCopiedLabel={t('copyClipboard.invoiceIdCopied')}
              tooltipCopyLabel={t('copyClipboard.copyInvoiceId')}
            />
          </div>
        </div>

        <div className="mt-4 md:mt-auto space-x-2">
          {canCancel && <CancelInstallmentModal smartInstallmentId={smartInstallment.id} onSuccess={onChange} />}
        </div>
      </div>
    </div>
  )
}
