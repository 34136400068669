import { HttpClient } from '@hub-la/fe-core-http-client'
import { useQuery } from '@tanstack/react-query'
import { useContainer } from '../../container'
import { GetNotifications } from '../../usecases/get-notifications'

export const useGetNotifications = () => {
  const container = useContainer()

  return useQuery(['notifications'], () => new GetNotifications(container.get(HttpClient)).execute(), {
    retry: false,
    refetchOnWindowFocus: false,
    cacheTime: 10,
  })
}
