import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { GeneralError } from '../../domain/errors/general'
import { Envs } from '../../envs'

export class DuplicateShortLink {
  public constructor(@inject(HttpClient) private readonly httpClient: HttpClient) {}

  public async execute(productId: string, shortLinkId: string): Promise<null> {
    const response = await this.httpClient.request({
      method: HttpMethod.POST,
      url: `${Envs.BFF_PRODUCT_URL}/products/${productId}/short-links/${shortLinkId}/duplicate`,
    })

    if (response.statusCode === HttpStatusCode.CREATED) {
      return response.data
    }

    switch (response.data.errorCode) {
      default:
        throw new GeneralError()
    }
  }
}
