import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { toast } from '@hub-la/shadcn'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { SavePostRequest } from '../../domain/dtos/save-post-request'
import { QueryKey } from '../../domain/enum/query-key'
import { SavePost } from '../../usecases/save-post'

export const useSavePost = () => {
  const container = useContainer()
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  return useMutation(
    [QueryKey.savePost],
    ({ payload, id }: SavePostRequest) => new SavePost(container.get(HttpClient)).execute(payload, id),
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries({ queryKey: [QueryKey.getSections] })
        queryClient.invalidateQueries({ queryKey: [QueryKey.getPost, variables.id] })
        toast({ description: t('publication.save.success') })
      },
      onError: (error: Error) => toast({ description: t('publication.save.error'), variant: 'destructive' }),
    },
  )
}
