import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation } from '@tanstack/react-query'
import { GetExportDeactivateMembersInput } from '../../../domain/dtos/get-export-deactivates-members-input'
import { QueryKey } from '../../../domain/enums/query-key'
import { GetExportDeactivateMembers } from '../../../usecases/members/get-export-deactivate-members'

export const useGetExportDeactivateMembers = () => {
  const container = useContainer()

  return useMutation(
    [QueryKey.getExportDeactivateMembers],
    (input: GetExportDeactivateMembersInput) =>
      new GetExportDeactivateMembers(container.get(HttpClient)).execute(input),
    {},
  )
}
