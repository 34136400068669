import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation } from '@tanstack/react-query'
import { AddDailyCreditsInput } from '../../domain/dtos/add-daily-credits-input'
import { QueryKey } from '../../domain/enums/query-keys'
import { AddDailyCredits } from '../../usecases/add-daily-credits'

export const useAddDailyCredits = () => {
  const container = useContainer()

  return useMutation(
    [QueryKey.addDailyCredits],
    (input: AddDailyCreditsInput) => new AddDailyCredits(container.get(HttpClient)).execute(input),
    {
      retry: false,
    },
  )
}
