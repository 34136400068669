import { ArrowDownRight, ArrowUpLeft } from 'lucide-react'
import React from 'react'

type MovementStatusProps = {
  amountInCents: number
  // transactionType: string
}

const getIcon = (amountInCents: number) => {
  return amountInCents >= 0 ? (
    <div className="bg-green-600/20   rounded-full w-4 h-4 flex justify-center items-center">
      <ArrowDownRight className="text-green-600 w-3 h-3" strokeWidth={3} />
    </div>
  ) : (
    <div className="bg-red-600/20  rounded-full w-4 h-4 flex justify-center items-center">
      <ArrowUpLeft className="text-red-600 w-3 h-3" strokeWidth={3} />
    </div>
  )
}

/**
 * Each transaction type has a different status symbol.
 * Red: to represent a bad movement, generally related to money going out the account
 * Orange: to represent a indefined movment, could become a bad or good movement
 * Green: to represent a good movement, generally related to money coming in
 */
export const MovementStatus: React.FC<MovementStatusProps> = ({ amountInCents }) => {
  return getIcon(amountInCents)
}
