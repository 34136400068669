import { container } from '@hub-la/fe-container'
import { DashboardSubscriptions, DashboardSubscriptionsProvider } from '@hub-la/fe-dashboard-subscriptions'

export const DashboardSubscriptionsInit = () => {
  return (
    <DashboardSubscriptionsProvider container={container}>
      <DashboardSubscriptions />
    </DashboardSubscriptionsProvider>
  )
}
