import { ReceiverStatus } from '../../../domain/enums/receiver-status'
import { AcceptCancellationRequest } from './accept-cancellation-request'
import { AcceptInvite } from './accept-invite'
import { RejectCancellationRequest } from './reject-cancellation-request'
import { RejectInvite } from './reject-invite'

type PartnershipActionsProps = {
  receiverId: string
  status: ReceiverStatus
  termsText: string
  commission: number
  expiresAt?: string
  onChange?: () => void
}

export const PartnershipActions = ({
  receiverId,
  status,
  termsText,
  commission,
  expiresAt,
  onChange,
}: PartnershipActionsProps) => {
  const renderButtons = () => {
    switch (status) {
      case ReceiverStatus.INVITED:
        return (
          <div className="flex flex-row gap-2 justify-end">
            <AcceptInvite
              receiverId={receiverId}
              termsText={termsText}
              commission={commission}
              expiresAt={expiresAt}
              onChange={onChange}
            />

            <RejectInvite receiverId={receiverId} onChange={onChange} />
          </div>
        )
      case ReceiverStatus.CANCELLATION_REQUESTED:
        return (
          <div className="flex flex-row gap-2 justify-end">
            <AcceptCancellationRequest receiverId={receiverId} onChange={onChange} />
            <RejectCancellationRequest receiverId={receiverId} onChange={onChange} />
          </div>
        )
      default:
        return null
    }
  }

  return renderButtons()
}
