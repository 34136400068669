import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { Envs } from '../envs'

export class GetTokenBySession {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(sessionToken: string | undefined): Promise<string | null> {
    const response = await this.httpClient.request({
      method: HttpMethod.POST,
      url: `${Envs.SITE_URL}/api/auth/get`,
      headers: {
        Authorization: sessionToken,
      },
      withCredentials: true,
    })

    if (response.statusCode === HttpStatusCode.OK) {
      return response?.data?.token
    }

    return null
  }
}
