export const pt = {
  errors: {
    accountUserNotFound: 'O usuário não foi encontrado. É necessario que o usuário tenha uma conta na Hubla.',
    emailInvalid: 'E-mail inválido',
    emailRequired: 'E-mail é obrigatório',
    permissionsRequired: 'Permissões são obrigatórias',
    signInNotfoundError: 'Não identificamos nenhuma conta com esse e-mail. <0>Crie sua conta</0>',
    signInPhoneNotfoundError: 'Não identificamos nenhuma conta com esse telefone. <0>Crie sua conta</0>',
    emailAlreadyExists: 'O e-mail informado já possui uma conta. <0>Faça seu login</0>',
    phoneNumberAlreadyExists: 'O telefone informado já possui uma conta. <0>Faça seu login</0>',
    verifyCodeInvalid: 'Código de verificação incorreto.',
    blockedUserAuthAttempt: 'Você excedeu o número de tentativas permitidas. Acesso bloqueado, aguarde 15 minutos.',
    codeExpired: 'Código expirado, solicite um novo código!',
    completeRegistration: 'Erro ao completar o cadastro.',
    completeRegistrationVerifyCode: 'Erro ao verificar o código para completar o cadastro.',
    invalidCaptcha: 'Verificação de captcha inválido, atualize a página e tente novamente.',
    recoverPasswordNotFound:
      'Recuperação de senha excluído ou expirado. Solicite novamente, caso o erro persista entre em contato com o nosso suporte.',
    unableToChangeEmail: 'Não foi possível alterar o e-mail, pois você já possui um e-mail.',
    emailAndPhoneAlreadyInUse: 'O e-mail e telefone informados já possuem uma conta. <0>Faça seu login</0>',
    signInWithoutPassword: 'Esse e-mail ainda não possui uma senha. <0>Redefina sua senha</0>',
    userDisabled: 'Conta desativada. Caso tenha dúvidas, entre em contato com o suporte.',
    isUsingPasswordEmailConflict:
      'Erro ao logar na conta com e-mail, tente entrar com telefone ou entrar em contato com o suporte.',
    isUsingPasswordError:
      'Erro ao logar na conta com e-mail. Tente novamente, caso o erro persista entre em contato com o suporte.',
    general:
      'Erro ao tentar criar sua conta. Tente novamente, caso o erro persista entre em contato com o nosso suporte.',
  },
  firebaseErrors: {
    authCodes: {
      'auth/claims-too-large': 'Seus dados de perfil são muito grandes. Por favor, entre em contato com o suporte.',
      'auth/wrong-password': 'Senha incorreta. <0>Redefina sua senha</0>',
      'auth/too-many-requests': 'Muitas tentativas de login, acesso bloqueado. Tente novamente mais tarde.',
      'auth/network-request-failed': 'Erro de conexão. Verifique sua conexão com a internet.',
      'auth/user-disabled': 'Conta desativada. Caso tenha dúvidas, entre em contato com o suporte.',
    },
    unknown: 'Erro desconhecido ao autenticar com o Auth.',
  },
  generalErrors: {
    default: 'Erro inesperado. Tente novamente, caso o erro persista entre em contato com o nosso suporte.',
    signUp:
      'Erro ao tentar criar sua conta. Tente novamente, caso o erro persista entre em contato com o nosso suporte.',
    signIn:
      'Erro ao tentar acessar sua conta. Tente novamente, caso o erro persista entre em contato com o nosso suporte.',
    recoverPassword:
      'Erro ao tentar redefinir sua senha. Tente novamente, caso o erro persista entre em contato com o nosso suporte.',
    completeRegistration:
      'Erro ao tentar criar sua senha. Tente novamente, caso o erro persista entre em contato com o nosso suporte.',
    verifyCode:
      'Erro ao tentar verificar o código. Tente novamente, caso o erro persista entre em contato com o nosso suporte.',
  },
  selectUserLoginNavigationTarget: {
    title: 'Escolha como acessar',
    creatorNavigationTarget: {
      title: 'Acessar como produtor',
      subtitle: 'Para fazer a gestão dos produtos que você vende',
    },
    userNavigationTarget: {
      title: 'Acessar minhas compras',
      subtitle: 'Para acessar um produto que comprou',
    },
    checkboxDontAskAgain: 'Não perguntar novamente',
  },
}
