import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation } from '@tanstack/react-query'
import { QueryKey } from '../../domain/dtos/query-keys'
import { DeleteCollaborator } from '../../usecases/delete-collaborator'

export const useDeleteCollaborator = ({ onSuccess }: { onSuccess?: () => void }) => {
  const container = useContainer()

  return useMutation(
    [QueryKey.postSignInRoleplay],
    (collaboratorUserId: string) => new DeleteCollaborator(container.get(HttpClient)).execute(collaboratorUserId),
    {
      retry: false,
      onSuccess: onSuccess,
    },
  )
}
