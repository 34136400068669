import { container } from '@hub-la/fe-container'
import { UserSmartInstallmentCyclePage, UserSmartInstallmentsProvider } from '@hub-la/fe-user-smart-installments'

export const UserSmartInstallmentCycleInit = () => {
  return (
    <UserSmartInstallmentsProvider container={container}>
      <UserSmartInstallmentCyclePage />
    </UserSmartInstallmentsProvider>
  )
}
