import { EditOfferResponseDtoInstallmentCostsPayerEnum } from '@hub-la/sdk-bff-product'
import {
  Label,
  RadioGroup,
  RadioGroupItem,
  Switch,
  TooltipContent,
  TooltipTrigger,
  TooltipProvider,
  Tooltip,
  Checkbox,
} from '@hub-la/shadcn'
import { Info, Loader } from 'lucide-react'
import { useEffect } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { InstallmentsSelect } from '../../../../../components/installments-select'
import { SwitchToggleChildren } from '../../../../../components/switch-toggle-children'
import { OfferSchemaValidationType } from '../../../../../validations/offer-validation'

export const Options = () => {
  const { t } = useTranslation()

  const { setValue, getValues, control } = useFormContext<OfferSchemaValidationType>()

  const priceType = useWatch({
    name: 'pricing.priceType',
    control,
  })
  const allowedDebit = getValues('pricing.smartInstallment.allowedDebit')

  const isRecurringPrice = priceType === 'recurring'
  useEffect(() => {
    if (!isRecurringPrice) {
      setValue('pricing.isMembershipFee', false)
    }
  }, [isRecurringPrice, setValue])

  return (
    <div className="space-y-6">
      <SwitchToggleChildren
        name="pricing.isSmartInstallment"
        label={t('offer.pricingTab.options.smartInstallments.title')}
      >
        <div className="space-y-3">
          <p className="text-sm text-muted-foreground">{t('offer.pricingTab.options.smartInstallments.description')}</p>
          <div className="p-3 border  rounded-md flex items-center space-x-4">
            <div className="p-3 border  rounded-md background">
              <Loader className="w-6 h-6 text-muted-foreground" />
            </div>
            <p className="text-sm text-muted-foreground">{t('offer.pricingTab.options.smartInstallments.warning')}</p>
          </div>

          <Controller
            name="pricing.smartInstallment.installments"
            rules={{ required: true }}
            render={({ field }) => (
              <InstallmentsSelect
                minInstallment={2}
                label={t('offer.pricingTab.options.smartInstallments.maxInstallments')}
                {...field}
              />
            )}
          />

          <RadioGroup
            defaultValue={allowedDebit.toString()}
            onValueChange={(value) =>
              setValue('pricing.smartInstallment.allowedDebit', value === 'true', {
                shouldDirty: true,
                shouldTouch: true,
              })
            }
          >
            <div className="space-y-2">
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="true" id="allowed-debit-true" />
                <Label htmlFor="allowed-debit-true">
                  {t('offer.pricingTab.options.smartInstallments.installmentsWithDebt.label')}
                </Label>
              </div>
              <p className="text-sm text-muted-foreground ml-6">
                {t('offer.pricingTab.options.smartInstallments.installmentsWithDebt.description')}
              </p>
            </div>
            <div className="space-y-2 mt-2">
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="false" id="allowed-debit-false" />
                <Label htmlFor="allowed-debit-false">
                  {t('offer.pricingTab.options.smartInstallments.installmentsWithoutDebt.label')}
                </Label>
              </div>
              <p className="text-sm text-muted-foreground ml-6">
                {t('offer.pricingTab.options.smartInstallments.installmentsWithoutDebt.description')}
              </p>
            </div>
          </RadioGroup>
        </div>
      </SwitchToggleChildren>

      <Controller
        name="isAvailableForAffiliates"
        control={control}
        render={({ field }) => (
          <div className="flex items-center space-x-2">
            <Checkbox id="affiliate-switch" checked={field.value} onCheckedChange={field.onChange} />
            <Label htmlFor="affiliate-switch">{t('offer.pricingTab.options.affiliatesSwitch')}</Label>
          </div>
        )}
      />

      <Controller
        name="installmentCostsPayer"
        render={({ field }) => (
          <div className="flex items-center space-x-2">
            <div className="flex items-center space-x-2">
              <Checkbox
                id="installment-cost-payer-switch"
                checked={field.value === EditOfferResponseDtoInstallmentCostsPayerEnum.SELLER}
                onCheckedChange={(checked) =>
                  field.onChange(
                    checked
                      ? EditOfferResponseDtoInstallmentCostsPayerEnum.SELLER
                      : EditOfferResponseDtoInstallmentCostsPayerEnum.BUYER,
                  )
                }
              />
              <Label htmlFor="installment-cost-payer-switch">
                {t('offer.pricingTab.options.installmentCostsPayerSwitch')}
              </Label>
            </div>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Info className="h-4 w-4" />
                </TooltipTrigger>
                <TooltipContent>{t('offer.pricingTab.options.installmentCostsPayerSwitchTooltip')}</TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        )}
      />
    </div>
  )
}
