import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { GroupResource } from '../../domain/dtos/groups'
import { GeneralError } from '../../domain/errors/general'
import { Envs } from '../../envs'
import { UpdateResourceInput } from '../../domain/dtos/member-area/update-resource-input'

export class UpdateResource {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(input: UpdateResourceInput): Promise<GroupResource> {
    const { id: externalResourceId, productId, cohortIds, ...restParams } = input
    const response = await this.httpClient.request({
      method: HttpMethod.PATCH,
      url: `${Envs.BFF_PRODUCT_URL}/resources/update-offer-resource/${externalResourceId}/${productId}`,
      body: { ...restParams, cohortsIds: cohortIds },
    })

    if (response.statusCode === HttpStatusCode.OK) {
      return (response.data ? response.data : {}) as GroupResource
    }

    throw new GeneralError()
  }
}
