import { UpdateAssetDuration } from '../../usecases/update-asset-duration'
import { useUpdateAsset } from './use-update-asset'

/**
 * @todo it could be better done in the backend
 * @description When the user uploads an asset image or video during the post creation page update asset size and duration.
 */
export const useUpdateAssetDuration = () => {
  const updateAsset = useUpdateAsset()

  const mutateAsync = async (assetId: string, assetFile?: File | null) => {
    const assetDurationPayload = await new UpdateAssetDuration().execute(assetId, assetFile)

    if (!assetDurationPayload) return

    updateAsset.mutateAsync({
      payload: assetDurationPayload,
    })
  }

  return {
    mutateAsync,
  }
}
