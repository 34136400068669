import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { SignedUrlResponse } from '../domain/dtos'
import { Envs } from '../envs'

type AssetSignedUrlInput = {
  name: string
  description?: string
  mimeType: string
}

export type GetAssetsSignedUrlInput = {
  sectionId: string
  assets: AssetSignedUrlInput[]
}

export type GetAssetsSignedUrlOutput = SignedUrlResponse[]

export type GetAssetsSignedUrlResponse = {
  signedUrls: SignedUrlResponse[]
}

export class GetAssetsSignedUrl {
  public constructor(@inject(HttpClient) private readonly httpClient: HttpClient<GetAssetsSignedUrlResponse>) {}

  public execute(input: GetAssetsSignedUrlInput): Promise<GetAssetsSignedUrlOutput> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .request({
          url: `${Envs.BFF_MEMBERS_AREA_URL}/hub/sections/${input.sectionId}/posts/batch`,
          body: {
            assets: input.assets,
          },
          method: HttpMethod.POST,
        })
        .then(({ statusCode, data }) => {
          if (statusCode === undefined) {
            return false
          }
          if (!data || ![HttpStatusCode.CREATED, HttpStatusCode.OK].includes(statusCode)) {
            return reject(`Ocorreu um erro na url assinada upload de arquivo`) //@TODO error code of bff
          }

          return resolve(data.signedUrls)
        })
        .catch(() => {
          return reject(`Ocorreu um erro na url assinada upload de arquivo`) //@TODO error code of bff
        })
    })
  }
}
