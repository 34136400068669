import { HttpClient } from '@hub-la/fe-core-http-client'
import { useQuery } from '@tanstack/react-query'
import { useContainer } from '../../container'
import { GetUser } from '../../usecases/get-user'

type Props = {
  enabled: boolean
}

export const useGetUser = (props?: Props) => {
  const container = useContainer()

  return useQuery(['user'], () => new GetUser(container.get(HttpClient)).execute(), {
    retry: false,
    refetchOnWindowFocus: false,
    cacheTime: 10,
    enabled: props?.enabled,
  })
}
