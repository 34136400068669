import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { PostIsUsingPasswordInput } from '../domain/dtos/post-is-using-password-input'
import { ErrorCode } from '../domain/enums/error-code'
import { GeneralError } from '../domain/errors/general'
import { InvalidCaptchaError } from '../domain/errors/invalid-captcha-error'
import { IsUsingPasswordEmailConflictError } from '../domain/errors/is-using-password-email-conflict-error'
import { IsUsingPasswordError } from '../domain/errors/is-using-password-error'
import { SignInNotfoundError } from '../domain/errors/signin-not-found-error'
import { Envs } from '../envs'

export class PostIsUsingPassword {
  public constructor(@inject(HttpClient) private readonly httpClient: HttpClient) {}

  public async execute(input: PostIsUsingPasswordInput): Promise<boolean> {
    if (!input?.captcha) {
      throw new InvalidCaptchaError()
    }

    const isUsingPassword = await this.isUsingPassword(input)

    return isUsingPassword
  }

  private async isUsingPassword(input: PostIsUsingPasswordInput): Promise<boolean> {
    const response = await this.httpClient.request({
      method: HttpMethod.POST,
      url: `${Envs.BFF_WEB_URL}/auth/is-using-password`,
      body: {
        email: input.email,
        captcha: input.captcha,
      },
    })

    if (response.statusCode === HttpStatusCode.CREATED || response.statusCode === HttpStatusCode.OK) {
      return response.data
    }

    switch (response.data.code) {
      case ErrorCode.USER_NOT_FOUND:
        throw new SignInNotfoundError()
      case ErrorCode.INVALID_CAPTCHA:
        throw new InvalidCaptchaError()
      case ErrorCode.IS_USING_PASSWORD:
        throw new IsUsingPasswordError()
      case ErrorCode.IS_USING_PASSWORD_EMAIL_CONFLICT:
        throw new IsUsingPasswordEmailConflictError()
      default:
        throw new GeneralError('signIn')
    }
  }
}
