import { Button } from '@hub-la/shadcn'
import { ArrowLeft } from 'lucide-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

export const GoBackButton: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()

  const handleOnClickGoBack = () => {
    history.goBack()
  }

  return (
    <Button
      variant="ghost"
      onClick={handleOnClickGoBack}
      className="flex items-center gap-2 p-2 text-sm"
      data-testid="go-back-button"
    >
      <ArrowLeft className="h-4 w-4" />
      {t('Receiver.inviteForm.back_button')}
    </Button>
  )
}
