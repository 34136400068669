import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { CohortDto } from '@hub-la/sdk-bff-product'
import { useQuery } from '@tanstack/react-query'
import { GetCohorts } from '../../usecases/get-cohorts'

export const useGetCohorts = (productId: string) => {
  const container = useContainer()

  return useQuery<CohortDto[]>(
    ['get-cohorts', productId],
    () => new GetCohorts(container.get(HttpClient)).execute(productId),
    {
      retry: false,
      retryOnMount: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      enabled: !!productId,
    },
  )
}
