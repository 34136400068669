import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { PutRemoveFreeSubscriptionInput } from '../../domain/dtos/put-remove-free-subscription-input'
import { GeneralError } from '../../domain/errors/general'
import { Envs } from '../../envs'

export class PutRemoveFreeSubscription {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(input: PutRemoveFreeSubscriptionInput, signal?: AbortSignal): Promise<null> {
    const response = await this.httpClient.request({
      method: HttpMethod.PUT,
      url: `${Envs.BFF_MEMBERS_AREA_URL}/members/remove-free-subscription`,
      signal,
      body: this.makeBody(input),
    })

    await this.sleep(2500) // wait payments backend queue to update list

    if (response.statusCode === HttpStatusCode.OK || response.statusCode === HttpStatusCode.CREATED) {
      return response.data
    }

    throw new GeneralError()
  }

  private makeBody(input: PutRemoveFreeSubscriptionInput) {
    const body = {
      productId: input.productId,
      userId: input.userId,
    }

    return body
  }

  private sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }
}
