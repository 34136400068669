import { Button, Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from '@hub-la/shadcn'
import { X } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReceiverStatus } from '../../../domain/enums/receiver-status'
import { useUpdateReceiverStatus } from '../../hooks/use-update-receiver-status'

type RejectCancellationRequestProps = {
  receiverId: string
  onChange?: () => void
}

export const RejectCancellationRequest = ({ receiverId, onChange }: RejectCancellationRequestProps) => {
  const [open, setOpen] = useState<boolean>(false)

  const { t } = useTranslation()
  const { mutateAsync: updateStatus, isLoading } = useUpdateReceiverStatus()

  const handleReject = async () => {
    await updateStatus({ id: receiverId, status: ReceiverStatus.CANCELLATION_REQUESTED })
    onChange?.()
    setOpen(false)
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="outline" size="sm">
          <X className="w-4 h-4 mr-1" />
          {t('partnerships.reject')}
        </Button>
      </DialogTrigger>

      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{t('partnerships.rejectCancellationRequest.title')}</DialogTitle>
        </DialogHeader>

        <DialogFooter className="sm:space-x-4 flex flex-col-reverse sm:flex-row justify-end items-center">
          <Button variant="outline" onClick={() => setOpen(false)}>
            {t('partnerships.back')}
          </Button>

          <Button variant="outline" onClick={handleReject} loading={isLoading}>
            {t('partnerships.reject')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
