import { RequireAtLeastOne } from 'type-fest'

interface UserReference {
  id?: string
  name?: string
  email?: string
  phoneNumber?: string
  picture?: string
}

export type Value = {
  percent: number
  isEnabled: boolean
}

export type Commission = RequireAtLeastOne<
  {
    sell?: Value
    renewal?: Value
  },
  'sell' | 'renewal'
>

export enum Type {
  affiliate = 'affiliate',
  partner = 'partner',
}

export enum Status {
  unknown = 'unknown',
  invited = 'invited',
  rejected = 'rejected',
  accepted = 'accepted',
  finished = 'finished',
  cancellation_requested = 'cancellation_requested',
}

export type Terms = {
  text: string
  agreedAt?: {
    _seconds: number
    _nanoseconds: number
  }
}

export interface Receiver {
  id: string
  user: UserReference
  owner: UserReference
  link: string
  totalSold: number
  commission: Commission
  type: Type
  status?: Status
  expiresAt?: {
    _seconds: number
    _nanoseconds: number
  }
  terms?: Terms
  isSuspended?: boolean
  kycStatus?: 'approved' | 'pending' | 'rejected'
  editedReceiverId?: string
}
