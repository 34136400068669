import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation } from '@tanstack/react-query'
import { DownloadCsvInput } from '../../domain/dtos/download-csv-input'
import { QueryKey } from '../../domain/dtos/query-keys'
import { DownloadCsv } from '../../usecases/download-csv'

export const useDownloadCsv = (input: DownloadCsvInput) => {
  const container = useContainer()

  return useMutation([QueryKey.downloadCsv], () => new DownloadCsv(container.get(HttpClient)).execute(input), {
    retry: false,
  })
}
