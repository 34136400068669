import { Button } from '@hub-la/shadcn'
import { ExternalLink } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { IntegrationProvider } from '../../../domain/dtos/integration-provider'

type Props = {
  provider: IntegrationProvider
}

export const ZapCertoCardActions = ({ provider }: Props) => {
  const { t } = useTranslation()

  return (
    <div className="w-full flex flex-col items-center justify-between gap-2">
      <Button className="w-full" onClick={() => (window.location.href = 'https://zp-c.com/hubla')}>
        Teste gratuitamente
      </Button>

      <Button
        variant="ghost"
        className="w-full text-muted-foreground hover:text-foreground"
        onClick={() => window.open(t(`integrations.${provider}.about.link`), '_blank')}
      >
        <span className="flex items-center gap-2">
          {t('aboutTab.seeMore')}
          <ExternalLink size={16} />
        </span>
      </Button>
    </div>
  )
}
