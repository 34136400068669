import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { GetWithdrawalDetailsDto, WithdrawalDto } from '../domain/dtos/withdrawal.dto'
import { GeneralError } from '../domain/errors/general'
import { Envs } from '../envs'

export class GetWithdrawalDetails {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(input: GetWithdrawalDetailsDto): Promise<WithdrawalDto> {
    const response = await this.httpClient.request({
      method: HttpMethod.GET,
      url: `${Envs.BFF_WEB_URL}/financial-statement/withdrawals/${input.withdrawalId}`,
    })

    if (response.statusCode === HttpStatusCode.OK) {
      return response.data
    }

    throw new GeneralError()
  }
}
