import { Input } from '@hub-la/shadcn'
import { Puzzle, Search } from 'lucide-react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { StringParam, useQueryParams, withDefault } from 'use-query-params'
import { IntegrationCard } from '../../components/integration-card/integration-card'
import { IntegrationCategory } from '../../components/integration-category/integration-category'
import { INTEGRATION_CATEGORIES_LIST } from '../../constants/integrations-list'

const normalizeString = (str) => {
  return str
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^a-z0-9\s]/g, '')
    .trim()
}

export const IntegrationsListPage = () => {
  const { t } = useTranslation()

  const [filters, setFilters] = useQueryParams({
    search: withDefault(StringParam, ''),
  })

  const filteredCategories = useMemo(() => {
    const normalizedSearchTerm = normalizeString(filters.search)
    return INTEGRATION_CATEGORIES_LIST.map((category) => ({
      ...category,
      integrations: category.integrations.filter((integration) => {
        const normalizedTitle = normalizeString(integration.title)
        const normalizedDescription = normalizeString(integration.description)
        return normalizedTitle.includes(normalizedSearchTerm) || normalizedDescription.includes(normalizedSearchTerm)
      }),
    })).filter((category) => category.integrations.length > 0)
  }, [filters.search])

  return (
    <div>
      <div className="mb-8 md:mb-0 p-2 md:p-12 pb-35 md:pb-36 w-full flex flex-col md:items-center gap-4 md:text-center rounded-xl bg-gradient-to-b from-hubla-primary/20 to-background/10">
        <div className="flex flex-row items-center gap-2">
          <Puzzle className="w-9 h-9 text-hubla-primary fill-current" />
          <h2 className="text-4xl font-bold">{t('title')}</h2>
        </div>
        <p className="max-w-lg font-normal text-base">{t('description')}</p>
        <div className="flex flex-col items-center md:w-1/2 w-full">
          <div className="relative w-full h-[50px] items-center gap-1.5">
            <Search className="absolute w-4 h-4 right-3 top-1/2 transform -translate-y-1/2 text-muted-foreground z-10" />
            <Input
              type="text"
              className="w-full h-[50px] bg-primary/5"
              placeholder="Buscar ferramentas..."
              id="search"
              value={filters.search}
              onChange={(e) => setFilters({ ...filters, search: e.target.value })}
            />
          </div>
        </div>
      </div>

      <div className="relative md:-mt-24 md:px-12 flex flex-col items-center">
        {filteredCategories.length > 0 ? (
          filteredCategories.map((category, idx) => (
            <IntegrationCategory key={idx} title={category.name} description={category.description}>
              {category.integrations.map((integration, idx) => (
                <IntegrationCard
                  key={idx}
                  title={integration.title}
                  description={integration.description}
                  provider={integration.provider}
                  disabled={!integration.enabled}
                  imageUrl={integration.icon}
                />
              ))}
            </IntegrationCategory>
          ))
        ) : (
          <div className="mt-4 mb-12 max-w-5xl flex flex-col w-full items-center">
            <h2 className="text-2xl font-semiboldmb-4">Busca: {filters.search}</h2>
            <p className="font-normal text-base text-muted-foreground mb-4">
              Nenhum registro encontrado, refaça a sua busca.
            </p>
          </div>
        )}
      </div>
    </div>
  )
}
