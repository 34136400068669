import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  useToast,
} from '@hub-la/shadcn'
import { Edit, MoreHorizontal, Trash } from 'lucide-react'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDeleteComment } from '../../hooks/use-delete-comment'
import { useDiscussion } from '../../providers/discussion'

type CommentOptionsProps = {
  isReply?: boolean
  parentCommentId?: string // Used to identify if the comment is a reply on threads
  commentId: string
  userId: string
  onEdit: () => void
}

export const CommentOptions = ({ userId, commentId, isReply, parentCommentId, onEdit }: CommentOptionsProps) => {
  const { t } = useTranslation()
  const { isOwnerOfComment } = useDiscussion()
  const { toast } = useToast()

  const { mutateAsync, isSuccess, isError, reset } = useDeleteComment({ isReply, parentCommentId })

  const handleDeleteComment = useCallback(async () => {
    await mutateAsync({ commentId })
  }, [commentId, mutateAsync])

  if (isSuccess) {
    toast({
      title: t('comment.snackbarFeedback.delete.success'),
      variant: 'default',
    })
    reset()
  }

  if (isError) {
    toast({
      title: t('comment.snackbarFeedback.delete.error'),
      variant: 'destructive',
    })
    reset()
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" size="icon">
          <MoreHorizontal className="h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        {isOwnerOfComment(userId) && (
          <DropdownMenuItem onClick={onEdit}>
            <Edit className="mr-2 h-4 w-4" />
            <span>{isReply ? t('comment.options.edit.answer') : t('comment.options.edit.unique')}</span>
          </DropdownMenuItem>
        )}
        <DropdownMenuItem onClick={handleDeleteComment}>
          <Trash className="mr-2 h-4 w-4" />
          <span>{isReply ? t('comment.options.delete.answer') : t('comment.options.delete.unique')}</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
