import { Skeleton } from '@hub-la/shadcn'
import React from 'react'

export const Loading: React.FC = () => {
  return (
    <div className={`flex flex-col space-y-4 md:flex-row md:space-x-4 md:space-y-0`}>
      <Skeleton className="flex-1 h-[166px]" />
      <Skeleton className="flex-1 h-[166px]" />

      <div className="flex-1 flex flex-col gap-2">
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </div>
    </div>
  )
}
