import { Button, Card, CardContent, CardHeader, CardTitle, Separator, Skeleton } from '@hub-la/shadcn'
import { ArrowLeft } from 'lucide-react'
import { CardRow } from './card-row'

export const Loading = () => {
  return (
    <div className="flex flex-col space-y-6">
      {/* Header Section */}
      <div className="flex flex-col lg:flex-row justify-between items-start lg:items-end space-y-5 lg:space-y-0 lg:space-x-5">
        <div className="space-y-1 md:w-auto">
          <Button className="h-4 px-0" size="sm" variant="link">
            <ArrowLeft className="w-4 h-4 mr-2" />
            Voltar
          </Button>
          <div className="flex items-center space-x-2">
            <Skeleton className="h-9 w-56" />
            <Skeleton className="h-6 w-20" />
          </div>
          <Skeleton className="h-4 w-40" />
        </div>
        <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2 w-full sm:w-auto">
          <Skeleton className="h-9 w-32" />
          <Skeleton className="h-9 w-32" />
        </div>
      </div>
      <Separator />
      <div className="grid grid-cols-1 lg:grid-cols-12 gap-6">
        <div className="col-span-1 lg:col-span-7 space-y-6">
          <Card>
            <CardHeader className="py-2 px-4">
              <CardTitle className="text-base font-semibold flex justify-between items-center">
                <div className="flex gap-2 items-center">
                  <span>Detalhes da compra</span>
                </div>
                <div className="flex space-x-2">
                  <Skeleton className="h-9 w-9" />
                  <Skeleton className="h-9 w-9" />
                </div>
              </CardTitle>
            </CardHeader>
            <Separator />
            <CardContent className="pt-4 space-y-4">
              <div className="flex flex-row justify-between items-start">
                <div className="flex flex-row gap-4">
                  <Skeleton className="h-10 w-10 rounded-lg" />
                  <div className="flex flex-col gap-1">
                    <div className="flex flex-row gap-2 items-center">
                      <Skeleton className="h-6 w-[70%]" />
                      <Skeleton className="h-6 w-20" />
                    </div>
                    <Skeleton className="h-6 w-[70%]" />
                  </div>
                </div>
                <div className="text-right">
                  <Skeleton className="h-6 w-20" />
                </div>
              </div>
            </CardContent>
          </Card>
          <div className="flex flex-col space-y-1.5">
            <span>Motivo</span>
            <Card>
              <CardContent className="p-4">
                <div className="flex flex-row gap-2 items-center">
                  <div className="w-10 h-10 rounded-lg bg-muted flex items-center justify-center">
                    <span>{'A'}</span>
                  </div>
                  <div className="flex flex-col w-full gap-2 text-sm">
                    <Skeleton className="h-6 w-[70%]" />
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
        <div className="col-span-1 lg:col-span-5 space-y-6">
          {/* Details Card */}
          <Card>
            <CardHeader className="py-2 px-4">
              <CardTitle className="text-base font-semibold flex justify-between items-center">
                <div className="flex gap-2 items-center">
                  <span>Cliente</span>
                </div>
                <div className="flex space-x-2">
                  <Skeleton className="h-9 w-9" />
                  <Skeleton className="h-9 w-9" />
                </div>
              </CardTitle>
            </CardHeader>
            <Separator />
            <CardContent className="pt-4 space-y-4">
              <CardRow title="Email" content={<Skeleton className="h-6 w-[70%]" />} />
              <CardRow title="Telefone" content={<Skeleton className="h-6 w-[70%]" />} />
              <CardRow title="Método de Pagamento" content={<Skeleton className="h-6 w-[70%]" />} />
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  )
}
