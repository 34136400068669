import {
  Button,
  cn,
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Input,
  Label,
} from '@hub-la/shadcn'
import { Field, FormikProvider, useFormik } from 'formik'
import * as Yup from 'yup'
import { Download } from 'lucide-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ExportAlreadyInProgressError } from '../../../domain/errors/export-already-in-progress'
import { ExportLongPeriodError } from '../../../domain/errors/export-long-period-error'
import { useDownloadInvoicesXlsx } from '../../hooks/use-download-invoices-xlsx'

type Props = {
  filters: any // Update this type to match your filters structure
  offers: any[] // Update this type to match your offers structure
}

export const ConfirmExportModal: React.FC<Props> = ({ filters, offers }) => {
  const { t } = useTranslation()
  const [showConfirmModal, setShowConfirmModal] = React.useState(false)
  const isLongPeriodError = verifyLongPeriod()

  const form = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: async (values) => {
      await exportXlsx()
        .then(() => {
          handleClose()
        })
        .catch(console.error)
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email('Email inválido').required('Email é obrigatório'),
    }),
  })

  const {
    mutateAsync: exportXlsx,
    isLoading: loading,
    isSuccess,
    isError,
    data,
    error,
    reset,
  } = useDownloadInvoicesXlsx({
    ...filters,
    offers,
    receiverEmail: form.values.email,
  })

  const handleClose = () => {
    form.resetForm()
    reset()
    setShowConfirmModal(false)
  }

  function getModalMessage() {
    if (isSuccess) return t('exportXlsx.successMessage', { email: data?.email })
    if (error instanceof ExportAlreadyInProgressError) return t('exportXlsx.conflictErrorMessage')
    if (error instanceof ExportLongPeriodError) return t('exportXlsx.longPeriodErrorMessage')
    return t('exportXlsx.generalErrorMessage')
  }

  function verifyLongPeriod() {
    const startDate = filters.startDate ? new Date(filters.startDate) : new Date()
    const endDate = filters.endDate ? new Date(filters.endDate) : new Date()
    const months = (endDate.getFullYear() - startDate.getFullYear()) * 12 + endDate.getMonth() - startDate.getMonth()
    return months > 12
  }

  if (isSuccess || isError) {
    return (
      <Dialog open={showConfirmModal} onOpenChange={handleClose}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{t('exportXlsx.title')}</DialogTitle>
          </DialogHeader>
          <div className="py-4">
            <p>{getModalMessage()}</p>
          </div>
          <div className="flex justify-end">
            <Button onClick={handleClose} variant="outline">
              {t('exportXlsx.getIt')}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    )
  }

  return (
    <Dialog
      open={showConfirmModal}
      onOpenChange={() => {
        setShowConfirmModal((prevState) => !prevState)
        if (showConfirmModal) {
          handleClose()
        }
      }}
    >
      <DialogTrigger asChild>
        <Button variant="outline" className="w-full">
          <Download className="h-4 w-4 mr-2" />
          {t('exportXlsx.confirm')}
        </Button>
      </DialogTrigger>
      <DialogContent className="w-full md:max-w-[425px] max-h-full overflow-y-auto my-2">
        <DialogHeader>
          <DialogTitle>{t('exportXlsx.title')}</DialogTitle>
        </DialogHeader>
        <div className="grid my-4">
          <div className="grid gap-2">
            {filters.search && (
              <div className="grid grid-cols-3 items-center gap-4">
                <Label>Busca</Label>
                <div className="col-span-2 h-auto p-2 border rounded-lg items-center text-sm background text-muted-foreground">
                  {filters.search}
                </div>
              </div>
            )}
            {filters.offerIds?.length > 0 && (
              <div className="grid grid-cols-3 items-center gap-4">
                <Label>Produtos</Label>
                <div className="col-span-2 p-2 border rounded-lg items-center text-sm background text-muted-foreground overflow-y-auto max-h-24">
                  {filters.offerIds.map((id) => offers.find((o) => o.id === id)?.name).join(', ')}
                </div>
              </div>
            )}
            {filters.startDate && filters.endDate && (
              <div className="grid grid-cols-3 items-center gap-x-4 gap-y-0.5">
                <Label>Data de criação</Label>
                <div
                  className={cn(
                    'col-span-2 h-auto p-2 border rounded-lg items-center text-sm background text-muted-foreground',
                    isLongPeriodError ? 'border-red-500' : '',
                  )}
                >
                  {new Date(filters.startDate).toLocaleDateString()} - {new Date(filters.endDate).toLocaleDateString()}
                </div>
                {isLongPeriodError && (
                  <div className="col-start-2 col-span-2 text-sm text-red-500 gap-2 pl-2">
                    {t('exportXlsx.longPeriodInputErrorMessage')}
                  </div>
                )}
              </div>
            )}
            {filters.statuses?.length > 0 && (
              <div className="grid grid-cols-3 items-center gap-4">
                <Label>Status</Label>
                <div className="col-span-2 h-auto p-2 border rounded-lg items-center text-sm background text-muted-foreground">
                  {filters.statuses.map((status) => t(`status.${status}`)).join(', ')}
                </div>
              </div>
            )}
          </div>
        </div>
        <FormikProvider value={form}>
          <form onSubmit={form.handleSubmit}>
            <div className="grid gap-2">
              <Label htmlFor="receiverEmail">{t('exportXlsx.email')}</Label>
              <Field
                as={Input}
                id="receiverEmail"
                name="email"
                placeholder="example@email.com"
                type="email"
                className={form.errors.email && form.touched.email && form.dirty ? 'border-red-500' : ''}
              />
              {form.errors.email && form.touched.email && form.dirty ? (
                <div className="text-sm text-red-500 gap-2">{form.errors.email}</div>
              ) : null}
            </div>
            <div className="flex justify-end space-x-2 mt-4">
              <DialogClose>
                <Button variant="outline" disabled={loading} onClick={handleClose}>
                  {t('exportXlsx.cancel')}
                </Button>
              </DialogClose>
              <Button
                type={'submit'}
                loading={loading}
                className="flex gap-2"
                disabled={!!form.errors.email || !form.values.email || isLongPeriodError}
              >
                <Download className="h-4 w-4" />
                {t('exportXlsx.confirm')}
              </Button>
            </div>
          </form>
        </FormikProvider>
      </DialogContent>
    </Dialog>
  )
}
