import { CreateCollaboratorPage, RoleplayProvider } from '@hub-la/fe-roleplay'
import { Button, Sheet, SheetContent, Tabs, TabsContent, TabsList, TabsTrigger, useIsMobile } from '@hub-la/shadcn'
import { UserPlus } from 'lucide-react'
import { memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Collaborators } from './collaborators/collaborators'
import { NavigationTarget } from './navigation-target/navigation-target'
import { Notifications } from './notifications/notifications'
import { Credentials } from './profile/credentials'
import { Profile } from './profile/profile'
import { AppearancePage } from './appearance/appearance'

export const DashboardSettings = memo(() => {
  const [tab, setTab] = useState('profile')
  const [createModal, setCreateModal] = useState<boolean>(false)

  const history = useHistory()
  const { t } = useTranslation()
  const isMobile = useIsMobile()

  const onTabChange = (newTab: string) => {
    history.push(`${history.location.pathname}?tab=${newTab}`)
    setTab(newTab)
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const tabFromUrl = urlParams.get('tab')

    if (tabFromUrl) {
      setTab(tabFromUrl)
    }
  }, [])

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row justify-between">
        <h2 className="text-3xl leading-tight font-semibold">{t('title')}</h2>
        {tab === 'collaborators' && (
          <Button className="max-w-full ml-auto overflow-x-hidden" onClick={() => setCreateModal(true)}>
            <UserPlus className="mr-2 h-4 w-4" />
            {t('collaboratorsList.button')}
          </Button>
        )}
      </div>

      <Tabs value={tab} onValueChange={onTabChange}>
        <div className="w-full overflow-x-auto">
          <TabsList variant="underlined">
            <TabsTrigger value="profile" variant="underlined">
              {t('tabs.profile')}
            </TabsTrigger>

            <TabsTrigger value="appearance" variant="underlined">
              {t('tabs.appearance')}
            </TabsTrigger>

            <TabsTrigger value="collaborators" variant="underlined">
              {t('tabs.collaborators')}
            </TabsTrigger>

            <TabsTrigger value="notifications" variant="underlined">
              {t('tabs.notifications')}
            </TabsTrigger>
          </TabsList>
        </div>

        <TabsContent value="profile">
          <Profile />
          <Credentials />
          <NavigationTarget />
        </TabsContent>

        <TabsContent value="appearance">
          <AppearancePage />
        </TabsContent>

        <TabsContent value="collaborators">
          <Collaborators />
        </TabsContent>

        <TabsContent value="notifications">
          <Notifications />
        </TabsContent>
      </Tabs>

      <Sheet open={createModal} onOpenChange={setCreateModal}>
        <SheetContent side={isMobile ? 'bottom' : 'right'} className="mx-auto p-10 h-full lg:max-w-2xl overflow-y-auto">
          <RoleplayProvider>
            <CreateCollaboratorPage onClose={() => setCreateModal(false)} />
          </RoleplayProvider>
        </SheetContent>
      </Sheet>
    </div>
  )
})
