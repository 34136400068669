import {
  Button,
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  Popover,
  PopoverContent,
  PopoverTrigger,
  useIsMobile,
} from '@hub-la/shadcn'
import { ChevronRight, Search } from 'lucide-react'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { Subscription } from '../../../../domain/dtos/subscription'
import avatar from '../../../assets/placeholder-image.svg'

const textEllipsis = (text: string, maxLength: number) => {
  const ellipsis = '...'
  return text.length > maxLength ? text.slice(0, maxLength - ellipsis.length) + ellipsis : text
}

type Props = {
  data: Subscription[]
  loading?: boolean
}

export const Autocomplete: React.FC<Props> = ({ data = [], loading }) => {
  const isMobile = useIsMobile()
  const history = useHistory()

  const redirectToSubscriptionDetail = (id?: string) => history.push('/user_subscriptions/' + id)
  return (
    <Popover>
      <PopoverTrigger asChild disabled={loading}>
        <Button variant="secondary" className="flex gap-2 justify-between w-full">
          Buscar...
          <Search className="h-4 w-4 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="p-0 w-[350px]" align="center">
        <Command>
          <CommandList>
            <CommandInput placeholder="Buscar por produto" />
            <CommandEmpty>{loading ? 'Carregando...' : 'Nenhuma assinatura encontrada.'}</CommandEmpty>
          </CommandList>
          <CommandGroup>
            {data?.map((option) => (
              <CommandItem
                key={option.id}
                onSelect={() => redirectToSubscriptionDetail(option.id)}
                className="flex items-center justify-between p-3 cursor-pointer bg-secondary hover:bg-secondary/50"
              >
                <div className="flex items-center gap-2">
                  <img
                    src={option.items.at(0)?.picture ?? avatar}
                    alt={option.items.at(0)?.productName}
                    className="w-[70px] h-[46px] rounded-lg object-cover border "
                  />
                  <span>
                    {isMobile
                      ? textEllipsis(option.items.at(0)?.productName || '', 20)
                      : option.items.at(0)?.productName}
                  </span>
                </div>
                <ChevronRight className="h-4 w-4" />
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  )
}
