import { cn } from '@hub-la/shadcn'
import { ImageIcon } from 'lucide-react'

export const Fallback = ({ isVideo = false }) => {
  return (
    <div
      data-testid="thumbnail-fallback"
      className={cn(
        'w-full h-full rounded flex justify-center items-center',
        isVideo ? 'bg-muted' : 'bg-gradient-to-t from-[rgba(26,28,24,1)] to-[rgba(26,28,24,0.6)]',
      )}
    >
      {isVideo && <ImageIcon className="w-5 h-5 text-muted-foreground" />}
    </div>
  )
}
