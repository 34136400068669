import { container } from '@hub-la/fe-container'
import { UserSmartInstallmentsPage, UserSmartInstallmentsProvider } from '@hub-la/fe-user-smart-installments'

export const UserSmartInstallmentsInit = () => {
  return (
    <UserSmartInstallmentsProvider container={container}>
      <UserSmartInstallmentsPage />
    </UserSmartInstallmentsProvider>
  )
}
