import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject, injectable } from 'inversify'
import { Profile } from '../domain/dtos/profile'

@injectable()
export class GetProfile {
  public constructor(@inject(HttpClient) private readonly httpClient: HttpClient) {}

  public async execute(): Promise<Profile> {
    const URI = process.env['NX_BFF_WEB_URL'] ?? process.env['REACT_APP_HUB_API']

    if (!URI) {
      throw new Error('errors.general')
    }

    const response = await this.httpClient.request({
      url: `${URI}/user/me/profile`,
      method: HttpMethod.GET,
    })

    switch (response.statusCode) {
      case HttpStatusCode.OK:
        return response.data
      default:
        throw new Error('errors.general')
    }
  }
}
