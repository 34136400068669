import { Check, CircleDashed, IterationCw, X } from 'lucide-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { InvoiceStatus } from '../../../domain/dtos/get-invoice-details-request.dto'
import { TransactionSource } from '../../../domain/dtos/get-moviments.dto'

type StatusTagProps = {
  status?: InvoiceStatus | TransactionSource
}

export const StatusTag: React.FC<StatusTagProps> = ({ status }) => {
  const { t } = useTranslation()

  const getIcon = () => {
    switch (status) {
      case InvoiceStatus.STATUS_PAID:
      case TransactionSource.WITHDRAWAL_SUCCEEDED:
        return (
          <div className="bg-green-600 rounded-full w-4 h-4 flex justify-center items-center">
            <Check className="text-primary-foreground w-3 h-3" strokeWidth={3} />
          </div>
        )
      case InvoiceStatus.STATUS_UNPAID:
      case InvoiceStatus.STATUS_DRAFT:
      case InvoiceStatus.STATUS_DISPUTED:
      case TransactionSource.WITHDRAWAL_PENDING:
        return <CircleDashed className="w-4 h-4 text-ring" />
      case InvoiceStatus.STATUS_OVERDUE:
      case InvoiceStatus.STATUS_EXPIRED:
        return (
          <div className="bg-red-700 rounded-full w-4 h-4 flex justify-center items-center">
            <X className="text-primary-foreground w-3 h-3" strokeWidth={3} />
          </div>
        )
      case InvoiceStatus.STATUS_VOID:
      case TransactionSource.WITHDRAWAL_CANCELED:
        return (
          <div className="bg-amber-600 rounded-full w-4 h-4 flex justify-center items-center">
            <X className="text-primary-foreground w-3 h-3" strokeWidth={3} />
          </div>
        )
      case InvoiceStatus.STATUS_PARTIALLY_PAID:
      case InvoiceStatus.STATUS_PARTIALLY_RETURNED:
      case InvoiceStatus.STATUS_CHARGEBACK:
        return (
          <div className="bg-amber-900 rounded-full w-4 h-4 flex justify-center items-center">
            <X className="text-primary-foreground w-3 h-3" strokeWidth={3} />
          </div>
        )
      case InvoiceStatus.STATUS_RETURNED:
      case TransactionSource.WITHDRAWAL_FAILED:
      case TransactionSource.WITHDRAWAL_FAILED_AFTER_SUCCEEDED:
        return <IterationCw className="w-4 h-4 text-muted-foreground" />
      default:
        return null
    }
  }

  const getLabel = () => {
    if (!status) return null
    if (status in InvoiceStatus) {
      return t(`invoiceStatus.${status}`)
    } else if (status in TransactionSource) {
      return t(`withdrawalStatus.${status}`)
    }
    return `${status}`
  }

  return (
    <div className="flex items-center gap-1.5" data-testid="status-tag">
      {getIcon()}
      {getLabel()}
    </div>
  )
}
