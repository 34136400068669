import React from 'react'
import { AppHeader } from './app-header'
import { GroupsList } from './components/group-list'
import { Header } from './components/header'
import { Pendencies } from './components/pendencies'
import { PendingInvites } from './components/pending-invites'

export const UserGroupsPage: React.FC = () => {
  return (
    <div className="flex flex-col container px-4 md:px-24">
      <AppHeader />
      <Pendencies />
      <div className="flex flex-col pt-0 md:pt-12 pb-12 gap-4">
        <Header />
        <PendingInvites />
        <GroupsList />
      </div>
    </div>
  )
}
