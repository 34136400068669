import { IFile } from '../domain/dtos'
import { UploadFileToFirebaseStorage } from './upload-file-to-firebase-storage'

export class UploadImage {
  public async execute(
    input: File,
    path: string,
    onLoad?: (n: number) => void,
    obfuscateFileName?: boolean,
  ): Promise<IFile | undefined> {
    return await new UploadFileToFirebaseStorage().upload(input, `/${path}`, onLoad, obfuscateFileName)
  }
}
