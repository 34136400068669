import { AvailableCarriers, useMultigatewayHandler } from '@hub-la/fe-tokenizer'
import { Button, Dialog, DialogContent, DialogHeader, DialogTitle, toast, useIsMobile } from '@hub-la/shadcn'
import { FormikProvider, useFormik } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { CardDetail } from '../../../../../domain/dtos/card-detail'
import { UpgradePaymentMethods } from '../../../../../domain/dtos/payment-method'
import { useInitChangePaymentMethod } from '../../../../hooks/use-init-change-payment-method'
import { useSubmitChangePaymentMethod } from '../../../../hooks/use-submit-change-payment-method'
import { ChoosePaymentMethods } from './choose-payment-method'
import { tokenValidationSchema } from './credit-card-token.schema'
import { ErrorComponent } from './error-component'
import { creditCardValidationSchema } from './fill-credit-card.schema'
import { Loading } from './loading'

type Props = {
  open: boolean
  onClose: () => void
  subscriptionId: string
  offerId: string
}

export type Values = {
  paymentMethod: UpgradePaymentMethods
  document: string
  gateway: AvailableCarriers
  subscriptionId?: string
  sessionId?: string
  customerId?: string
  creditCard: {
    number: string
    cvv: string
    expiration: string
    type: string | undefined
    holder: string
    token: string
    installments: number
    cardDetails?: CardDetail
  }
}

export const ChangePaymentMethodModal: React.FC<Props> = ({ open, onClose, subscriptionId, offerId }) => {
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const { data, isFetching, error: initError } = useInitChangePaymentMethod(subscriptionId, open)
  const { mutateAsync: changeMethod, reset, isSuccess, error: submitError, isLoading } = useSubmitChangePaymentMethod()
  const gateway = useMultigatewayHandler(offerId)

  const formik = useFormik<Values>({
    initialValues: {
      gateway,
      paymentMethod: data?.availablePaymentMethods.at(0) ?? UpgradePaymentMethods.PAYMENT_METHOD_CARD,
      document: '',
      creditCard: {
        number: '',
        cvv: '',
        expiration: '',
        type: undefined,
        token: '',
        holder: '',
        installments: 1,
      },
    },
    validationSchema: Yup.object().concat(
      gateway !== AvailableCarriers.YUNO ? creditCardValidationSchema : tokenValidationSchema,
    ),
    validateOnChange: true,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const payload = { ...values, gateway, subscriptionId }
      changeMethod(payload).then(() => {
        formik.resetForm()
        onClose()
      })
    },
  })

  React.useEffect(() => {
    if (submitError) {
      toast({
        variant: 'destructive',
        title: t('changePaymentMethodModal.errors.' + (submitError as Error)?.message) ?? '',
      })
    }
    if (isSuccess) {
      toast({
        title: t('changePaymentMethodModal.success'),
      })
    }
  }, [submitError, isSuccess])

  if (isFetching && !data) {
    return (
      <Dialog open={open} onOpenChange={onClose}>
        <DialogContent className={isMobile ? 'w-screen h-screen' : 'max-w-md'}>
          <Loading onClose={onClose} />
        </DialogContent>
      </Dialog>
    )
  }

  if (initError && !data) {
    return (
      <Dialog open={open} onOpenChange={onClose}>
        <DialogContent className={isMobile ? 'w-screen h-screen' : 'max-w-md'}>
          <ErrorComponent error={(initError as Error).message} />
        </DialogContent>
      </Dialog>
    )
  }

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className={isMobile ? 'w-screen h-screen' : 'max-w-md'}>
        <DialogHeader>
          <DialogTitle>{t('changePaymentMethodModal.title')}</DialogTitle>
          <p className="text-muted-foreground">{t('changePaymentMethodModal.subtitle')}</p>
        </DialogHeader>

        <div className="space-y-8">
          <FormikProvider value={formik}>
            <ChoosePaymentMethods
              paymentMethods={data?.availablePaymentMethods ?? []}
              offerId={offerId}
              installments={data?.installments ?? []}
              isSmartInstallmentsInProgress={data?.isSmartInstallmentsInProgress ?? false}
            />
          </FormikProvider>

          <div className="flex flex-col sm:flex-row gap-3">
            <Button variant="outline" onClick={onClose} className="flex-1">
              {t('changePaymentMethodModal.cancel')}
            </Button>
            <Button onClick={formik.submitForm} className="flex-1" disabled={isLoading} loading={isLoading}>
              {t('changePaymentMethodModal.save')}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
