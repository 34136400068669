import { UploadStatus } from '@hub-la/fe-asset'
import { useFormikContext } from 'formik'
import { Values } from '../../../../domain/dtos/member-area/settings/formik-values'

export const useUploaderFieldsLoading = () => {
  const { values } = useFormikContext<Values>()
  const { mobileAssetStatus, desktopAssetStatus, videoAssetStatus } = values
  const loadingStates = [UploadStatus.PROCESSING, UploadStatus.LOADING]

  return (
    (mobileAssetStatus && loadingStates.includes(mobileAssetStatus)) ||
    (desktopAssetStatus && loadingStates.includes(desktopAssetStatus)) ||
    (videoAssetStatus && loadingStates.includes(videoAssetStatus))
  )
}
