import {
  Alert,
  AlertDescription,
  Button,
  DialogDescription,
  DialogTitle,
  Input,
  Label,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Sheet,
  SheetContent,
  SheetFooter,
  SheetHeader,
  Switch,
  useToast,
} from '@hub-la/shadcn'
import { useFormik } from 'formik'
import { Lightbulb, Loader2 } from 'lucide-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { useChangeAffiliateCommission } from '../hooks/use-change-affiliate-commission'

type Props = {
  open: boolean
  onClose: () => void
  affiliate?: {
    affiliateId: string
    sellCommission: number
    renewalCommission: number
    useDefaultCommission: boolean
  }
}

type Values = {
  sell: number
  renewal: number
  type: 'sell_renewal' | 'sell' | 'renewal'
  useDefaultCommission: boolean
}

export const ChangeCommissionModal = ({ open, onClose, affiliate }: Props) => {
  const { t } = useTranslation()
  const { toast } = useToast()
  const { mutateAsync: changeCommission, isLoading } = useChangeAffiliateCommission()

  React.useEffect(() => {
    if (!open) {
      formik.resetForm()
    }
  }, [open, affiliate?.affiliateId])

  const formik = useFormik<Values>({
    validationSchema: Yup.object().shape({
      sell: Yup.number()
        .min(0)
        .max(90)
        .when('type', {
          is: (type: string) => type === 'sell' || type === 'sell_renewal',
          then: () => Yup.number().required('errors.required'),
        }),
      renewal: Yup.number()
        .min(0)
        .max(90)
        .when('type', {
          is: (type: string) => type === 'renewal' || type === 'sell_renewal',
          then: () => Yup.number().required('errors.required'),
        }),
      type: Yup.string().required('errors.required'),
      useDefaultCommission: Yup.boolean().required('errors.required'),
    }),
    validateOnMount: false,
    validateOnChange: true,
    enableReinitialize: true,
    initialValues: {
      sell: affiliate?.sellCommission ?? 0,
      renewal: affiliate?.renewalCommission ?? 0,
      type:
        (affiliate?.sellCommission && affiliate?.renewalCommission) ||
        (!affiliate?.sellCommission && !affiliate?.renewalCommission)
          ? 'sell_renewal'
          : affiliate?.sellCommission
          ? 'sell'
          : 'renewal',
      useDefaultCommission: affiliate?.useDefaultCommission ?? false,
    },
    onSubmit: async (values: Values) => {
      if (!affiliate) return

      const commissionValues = {
        affiliateId: affiliate.affiliateId,
        useDefaultCommission: values.useDefaultCommission,
        sellCommission: values.type === 'renewal' ? 0 : Number(values.sell),
        renewalCommission: values.type === 'sell' ? 0 : Number(values.renewal),
      }

      await changeCommission(commissionValues, {
        onSuccess: () => {
          formik.resetForm()
          onClose()
          toast({
            title: t('changeCommissionModal.toast.success'),
            duration: 10000,
          })
        },
        onError: () => {
          toast({
            title: t('changeCommissionModal.toast.defaultError'),
            variant: 'destructive',
            duration: 10000,
          })
        },
      })
    },
  })

  if (!affiliate) return null

  return (
    <Sheet open={open} onOpenChange={onClose}>
      <SheetContent className="p-6 max-w-lg overflow-y-auto">
        <SheetHeader>
          <DialogTitle className="text-lg text-primary font-semibold">{t('changeCommissionModal.title')}</DialogTitle>
          <DialogDescription className="text-sm text-gray-500">
            {t('changeCommissionModal.description')}
          </DialogDescription>
        </SheetHeader>
        <Alert className="mt-6">
          <Lightbulb className="h-4 w-4" />
          <AlertDescription>{t('changeCommissionModal.alert')}</AlertDescription>
        </Alert>

        <div className="space-y-6">
          <div className="flex flex-col gap-3 mt-6">
            <div className="flex items-center space-x-2">
              <Switch
                id="useDefaultCommission"
                checked={formik.values.useDefaultCommission}
                onCheckedChange={(checked) => formik.setFieldValue('useDefaultCommission', checked)}
              />
              <Label htmlFor="useDefaultCommission">Usar comissão padrão do programa de afiliados</Label>
            </div>

            {!formik.values.useDefaultCommission && (
              <>
                <div className="grid gap-2">
                  <Label htmlFor="type">{t('changeCommissionModal.input.type')}</Label>
                  <Select onValueChange={(value) => formik.setFieldValue('type', value)} value={formik.values.type}>
                    <SelectTrigger id="type">
                      <SelectValue placeholder={t('changeCommissionModal.input.typePlaceholder')} />
                    </SelectTrigger>
                    <SelectContent className="z-[100]">
                      <SelectItem value="sell_renewal">{t('changeCommissionModal.input.option_1')}</SelectItem>
                      <SelectItem value="sell">{t('changeCommissionModal.input.option_2')}</SelectItem>
                      <SelectItem value="renewal">{t('changeCommissionModal.input.option_3')}</SelectItem>
                    </SelectContent>
                  </Select>
                </div>

                {(formik.values.type === 'sell' || formik.values.type === 'sell_renewal') && (
                  <div className="grid gap-2">
                    <Label htmlFor="sell">{t('changeCommissionModal.input.sell')}</Label>
                    <Input
                      id="sell"
                      type="number"
                      min={0}
                      max={90}
                      className="max-w-[245px]"
                      value={formik.values.sell || 0}
                      onChange={(e) => {
                        const value = parseInt(e.target.value, 10)
                        formik.setFieldValue('sell', `${isNaN(value) ? 0 : Math.min(value, 90)}`)
                      }}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.sell && formik.errors.sell && (
                      <p className="text-sm text-destructive">{t(formik.errors.sell)}</p>
                    )}
                  </div>
                )}

                {(formik.values.type === 'renewal' || formik.values.type === 'sell_renewal') && (
                  <div className="grid gap-2">
                    <Label htmlFor="renewal">{t('changeCommissionModal.input.renewal')}</Label>
                    <Input
                      id="renewal"
                      type="number"
                      min={0}
                      max={90}
                      className="max-w-[245px]"
                      value={formik.values.renewal || 0}
                      onChange={(e) => {
                        const value = parseInt(e.target.value, 10)
                        formik.setFieldValue('renewal', `${isNaN(value) ? 0 : Math.min(value, 90)}`)
                      }}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.renewal && formik.errors.renewal && (
                      <p className="text-sm text-destructive">{t(formik.errors.renewal)}</p>
                    )}
                  </div>
                )}
              </>
            )}
          </div>

          <SheetFooter className="sm:justify-end gap-2">
            <Button variant="outline" onClick={onClose} type="button">
              {t('changeCommissionModal.cancel')}
            </Button>
            <Button
              variant="default"
              onClick={() => formik.handleSubmit()}
              type="button"
              disabled={!formik.isValid || formik.isSubmitting || isLoading}
            >
              {(formik.isSubmitting || isLoading) && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
              {t('changeCommissionModal.submit')}
            </Button>
          </SheetFooter>
        </div>
      </SheetContent>
    </Sheet>
  )
}
