import { Asset } from '@hub-la/fe-asset'
import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation } from '@tanstack/react-query'
import { SaveFile } from '../../usecases/save-file'

export const useSaveFile = () => {
  const container = useContainer()

  const { isSuccess, isError, error, isLoading, data, mutateAsync, variables } = useMutation(
    [],
    ({ asset }: { asset: Asset }) => new SaveFile(container.get(HttpClient)).execute(asset),
  )

  return {
    isSuccess,
    isError,
    error,
    isLoading,
    data,
    mutateAsync,
    variables,
  }
}
