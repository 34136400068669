import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation } from '@tanstack/react-query'
import { QueryKey } from '../../domain/enums/query-key.enum'
import { PutTicket } from '../../usecases/put-ticket.usecase'
import { TicketSchemaValidationType } from '../validations/ticket-validation'

export const usePutTicket = ({
  id,
  onSuccess,
  onError,
}: {
  id: string
  onSuccess?: () => void
  onError?: (error) => void
}) => {
  const container = useContainer()

  return useMutation(
    [QueryKey.putTicket],
    (input: TicketSchemaValidationType) =>
      new PutTicket(container.get(HttpClient)).execute({
        ...input,
        id,
      }),
    {
      retry: false,
      onSuccess,
      onError: (error) => {
        if (onError) {
          onError(error)
        }
      },
    },
  )
}
