import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation } from '@tanstack/react-query'
import { QueryKey } from '../../domain/dtos/query-keys'
import { GetTypeFormData } from '../../usecases/get-typeform-data'

export const useGetTypeformData = () => {
  const container = useContainer()

  return useMutation(
    [QueryKey.getTypeformData],
    (responseId: string) => new GetTypeFormData(container.get(HttpClient)).execute(responseId),
    {
      retry: false,
    },
  )
}
