import { PieChartIcon } from '@radix-ui/react-icons'
import { Check, CheckCircle2, CircleDashed, X } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { SubscriptionStatus } from '../../../domain/enums/subscription-status'

type SubscriptionStatusStatsProps = {
  status: SubscriptionStatus
}

const getStyles = (color: string, weight = 600) => {
  return { size: 16, className: `mr-1.5 text-${color}-${weight}` }
}

const getVariant = (status: SubscriptionStatus) => {
  switch (status) {
    case SubscriptionStatus.STATUS_ACTIVE:
      return (
        <div className="bg-green-600  rounded-full w-4 h-4 flex justify-center items-center">
          <Check className="text-primary-foreground w-3 h-3" strokeWidth={3} />
        </div>
      )
    case SubscriptionStatus.STATUS_INCOMPLETE:
      return <CircleDashed className="w-4 h-4 text-ring" />
    case SubscriptionStatus.STATUS_CANCELED:
      return <PieChartIcon className="w-4 h-4 text-red-700" />
    case SubscriptionStatus.STATUS_INACTIVE:
      return (
        <div className="bg-muted-foreground  rounded-full w-4 h-4 flex justify-center items-center">
          <X className="text-primary-foreground w-3 h-3" strokeWidth={3} />
        </div>
      )
    default:
      return <CheckCircle2 className="w-4 h-4 text-green-600 " />
  }
}

export const SubscriptionStatusStats = ({ status }: SubscriptionStatusStatsProps) => {
  const { t } = useTranslation()

  return (
    <div className="flex items-center gap-1">
      {getVariant(status)}
      {t(`subscriptionStatus.${status}`)}
    </div>
  )
}
