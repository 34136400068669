import { container } from '@hub-la/fe-container'
import { EditOrderPage, EditOrderProvider } from '@hub-la/fe-edit-order'

export const EditOrderInit = () => {
  return (
    <EditOrderProvider container={container}>
      <EditOrderPage />
    </EditOrderProvider>
  )
}
