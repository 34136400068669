import React from 'react'
import { useTranslation } from 'react-i18next'
import { CreditCard as CreditCardIcon } from 'lucide-react'
import { PaymentMethodTextBuilder } from '../../../../../usecases/payment-method-text-builder'
import { UpgradePaymentMethods } from '../../../../../domain/dtos/payment-method'

type Props = {
  cardBrand: string
  last4: string
}

export const CreditCard = ({ cardBrand, last4 }: Props) => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col items-center text-center space-y-4">
      <div className="p-2 rounded-md border  background self-center">
        <CreditCardIcon className="text-muted-foreground" />
      </div>

      <h2 className="text-xl font-semibold mt-6">{t('upgradePlanModal.creditCard.title')}</h2>

      <p className="text-sm text-muted-foreground mt-1">
        {t('upgradePlanModal.creditCard.subtitle', {
          method: new PaymentMethodTextBuilder().execute(UpgradePaymentMethods.PAYMENT_METHOD_CARD, last4, cardBrand),
        })}
      </p>

      <p className="text-sm text-muted-foreground mt-1">{t('upgradePlanModal.creditCard.footer')}</p>
    </div>
  )
}
