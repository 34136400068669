import { Card, CardContent } from '@hub-la/shadcn'
import { useTranslation } from 'react-i18next'
import { InvoiceAmountDetail } from '../../../../domain/dtos/invoice-amount-detail'
import { formatCurrency } from '../../../../domain/vos/format-currency'
import { ContentRow } from './content-row'

type Props = {
  id: string
  detail: InvoiceAmountDetail
}

export const Description = ({ id, detail }: Props) => {
  const { t } = useTranslation()

  return (
    <Card className="w-full h-full">
      <CardContent className="flex flex-col gap-6 p-6">
        <p className="text-sm text-muted-foreground">
          {t('invoiceDetails.description.invoice')}: {id}
        </p>

        <div className="flex flex-col gap-2">
          {detail.products.map((product, i) => (
            <ContentRow
              key={i}
              showDivider
              title={t('invoiceDetails.description.details.product', { productName: product.productName })}
              content={<span>{formatCurrency(product.priceCents / 100)}</span>}
            />
          ))}

          {detail.discountCents ? (
            <ContentRow
              showDivider
              title={t('invoiceDetails.description.details.discountCents', { couponCode: detail.couponCode })}
              content={<span className="text-red-500">- {formatCurrency(detail.discountCents / 100)}</span>}
            />
          ) : null}

          {detail.installmentFeeCents ? (
            <ContentRow
              showDivider
              title={t('invoiceDetails.description.details.installmentFeeCents')}
              content={<span>{formatCurrency(detail.installmentFeeCents / 100)}</span>}
            />
          ) : null}

          {detail.totalCents ? (
            <ContentRow
              title={t('invoiceDetails.description.details.totalCents')}
              fontWeight="bold"
              content={<span className="font-semibold">{formatCurrency(detail.totalCents / 100)}</span>}
            />
          ) : null}
        </div>
      </CardContent>
    </Card>
  )
}
