import { BillingAddress } from '../dtos/billing-address'

const formatAddress = (billingAddress: BillingAddress) => {
  const { street, number, neighborhood, city, state, complement, postalCode } = billingAddress

  return `${street ? street : ''}${number ? ', ' + number : ''}${complement ? ', ' + complement : ''} – ${
    neighborhood ? neighborhood + ', ' : ''
  }${city} – ${state ? state + ', ' : ''}${postalCode}`
}

export { formatAddress }
