import { Pix, Stream } from '@hub-la/shadcn'
import { Barcode, CreditCard } from 'lucide-react'
import { PaymentMethod } from '../enums/payment-method.enum'
import { PriceType } from '../enums/price-type.enum'

export type PaymentMethodItem = {
  name: PaymentMethod
  icon: JSX.Element
  priceTypeAllowed: PriceType[]
}

const paymentMethodsFieldOptions: PaymentMethodItem[] = [
  {
    name: PaymentMethod.CREDIT_CARD,
    icon: <CreditCard />,
    priceTypeAllowed: [PriceType.ONE_TIME, PriceType.RECURRING],
  },
  {
    name: PaymentMethod.PIX,
    icon: <Pix />,
    priceTypeAllowed: [PriceType.ONE_TIME, PriceType.RECURRING],
  },
  {
    name: PaymentMethod.BANK_SLIP,
    icon: <Barcode />,
    priceTypeAllowed: [PriceType.ONE_TIME],
  },
  {
    name: PaymentMethod.BNPL,
    icon: <Stream />,
    priceTypeAllowed: [PriceType.ONE_TIME, PriceType.RECURRING],
  },
]

export { paymentMethodsFieldOptions }
