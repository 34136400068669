import { Table, TableBody, TableCell, TableEmptyState, TableHead, TableHeader, TableRow } from '@hub-la/shadcn'
import isEmpty from 'lodash/isEmpty'
import times from 'lodash/times'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GroupResource, ResourceState } from '../../../../../domain/dtos/groups'
import { useUpdateResource } from '../../../../hooks/member-area/use-update-resource'
import { EditableText } from './editable-text/editable-text'
import { GroupActions } from './group-actions'
import MessagingIcon from './messaging-icon'
import { TableLoading } from './table-loading'

type Props = {
  data: GroupResource[]
  isLoading: boolean
  offerId?: string
}

export const GroupsTable: React.FC<Props> = ({ data: initialData, offerId, isLoading }) => {
  const [data, setData] = useState<GroupResource[]>([])
  const { t } = useTranslation()
  const { mutateAsync: updateResource, isLoading: isLoadingUpdateResource } = useUpdateResource()

  useEffect(() => {
    setData(initialData)
  }, [initialData])

  const headCells = [
    { key: 'name', label: 'Nome', width: '45%' },
    { key: 'id', label: 'ID do grupo', width: '20%' },
    { key: 'memberCount', label: 'Número de membros', width: '20%' },
    { key: 'action', label: '' },
  ]

  const isRowsEmpty = isEmpty(data)
  const canRenderEmptyState = !isLoading && isRowsEmpty && !!offerId

  const handleOnGroupRename = (
    id: string,
    value: string,
    productId: string,
    mutateAsyncFn = updateResource,
    callback?: () => void,
    cohortIds?: string[],
  ) => {
    const partialResource: Partial<GroupResource> = { id, name: value }
    mutateAsyncFn({ ...partialResource, productId, cohortIds })
      .then(() => {
        const updatedData = data.map((resource) => (resource.id === id ? { ...resource, name: value } : resource))
        setData(updatedData)
        callback && callback()
      })
      .catch()
  }

  return (
    <div className="flex flex-col gap-4 rounded-lg border bg-background overflow-hidden">
      <Table>
        <TableHeader>
          <TableRow className="hover:bg-background">
            {headCells.map((cell) => (
              <TableHead key={cell.key} style={{ width: cell.width ? cell.width : 'unset' }}>
                {cell.label}
              </TableHead>
            ))}
          </TableRow>
        </TableHeader>

        <TableBody>
          {isLoading || !offerId
            ? times(1, (i) => <TableLoading key={`skeleton-row-${i}`} />)
            : (data ?? []).map((resource) => (
                <TableRow key={resource.id}>
                  <TableCell className="py-2 font-medium">
                    <div className="flex flex-row items-center">
                      <MessagingIcon type={resource.resourceType} />
                      <EditableText
                        value={resource.name}
                        onSubmit={(value) => handleOnGroupRename(resource.id, value, offerId)}
                      />
                    </div>
                  </TableCell>

                  <TableCell className="py-2 text-sm text-muted-foreground">{resource.id}</TableCell>

                  <TableCell className="py-2">
                    {resource.resourceData?.memberCount}/{resource.resourceData?.maxMemberCount}
                  </TableCell>

                  <TableCell className="py-2">
                    <GroupActions
                      disabled={resource.state !== ResourceState.ready}
                      offerId={offerId}
                      resource={resource}
                      handleOnGroupRename={handleOnGroupRename}
                      isLoadingUpdateResource={isLoadingUpdateResource}
                    />
                  </TableCell>
                </TableRow>
              ))}
        </TableBody>
      </Table>

      {canRenderEmptyState && (
        <TableEmptyState title={t('member-area.groups.empty')} subtitle={t('member-area.groups.emptySubtitle')} />
      )}
    </div>
  )
}
