import { Button, WhatsApp } from '@hub-la/shadcn'
import { Mail, Smartphone } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'
import { UrlWithRedirectParam } from '../../../usecases/url-with-redirect-param'
import { AuthCard } from '../../components/auth-card/auth-card'
import { AuthLayout } from '../../components/auth-layout'
import { MetaTags } from '../../components/meta-tags'

const SignMethods = [
  {
    icon: <Mail />,
    label: 'Continuar com e-mail',
    route: '/signin/email',
  },
  {
    icon: <WhatsApp />,
    label: 'Continuar com WhatsApp',
    route: '/signin/whatsapp',
  },
  {
    icon: <Smartphone />,
    label: 'Continuar com SMS',
    route: '/signin/sms',
  },
]

const LoginCard = () => {
  const { t } = useTranslation()

  return (
    <AuthCard title="Faça seu login" description="Escolha como deseja prosseguir">
      <div className="flex flex-col gap-3">
        {SignMethods.map((method) => (
          <RouterLink
            key={method.route}
            to={new UrlWithRedirectParam().execute(method.route, window.location.search)}
            className="w-full"
          >
            <Button variant="outline" className="w-full gap-2">
              {method.icon}
              {method.label}
            </Button>
          </RouterLink>
        ))}
      </div>
      <div className="relative my-4">
        <hr />
        <span className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-background px-2 text-xs text-muted-foreground">
          {t('Ou')}
        </span>
      </div>
      <p className="text-center text-sm text-muted-foreground">
        {t('Não possui uma conta na Hubla?')}{' '}
        <RouterLink className="text-lime-600" to="/signup">
          Crie sua conta
        </RouterLink>
      </p>
    </AuthCard>
  )
}

const SignInPage = () => {
  return (
    <AuthLayout>
      <MetaTags />
      <LoginCard />
    </AuthLayout>
  )
}

export { SignInPage }
