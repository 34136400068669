import React from 'react'
import DefaultGroupImage from '../../assets/default-group-avatar.png'

interface DesktopProductHeaderProps {
  group: {
    picture?: string | null
    name: string
    owner: string
  }
}

export const DesktopProductHeader: React.FC<DesktopProductHeaderProps> = ({ group }) => {
  return (
    <div className="flex flex-col space-y-1.5 px-4 pt-3">
      <div className="flex items-center space-x-2">
        <div className="relative">
          <img
            src={group.picture ?? DefaultGroupImage}
            width={58}
            height={34}
            className="object-cover rounded"
            alt="Product image"
          />
        </div>
        <div className="flex flex-col">
          <span className="text-[9px] font-bold">{group.name}</span>
          <span className="text-[6px] text-muted-foreground">Por {group.owner}</span>
        </div>
      </div>
      <hr className="border-t " />
    </div>
  )
}
