import { Button, Card, CardContent, CardHeader, CardTitle, Separator, WhatsApp } from '@hub-la/shadcn'
import { Mail, User } from 'lucide-react'
import React from 'react'
import { Phone } from '../../../domain/vos/phone'
import { InfoRow } from '../../components/info-row'

interface CustomerCardProps {
  name: string
  picture?: string
  id: string
  email: string
  phone: string
}

export const CustomerCard: React.FC<CustomerCardProps> = (props) => {
  return (
    <div className="space-y-6">
      <Card>
        <CardHeader className="py-2 px-4">
          <CardTitle className="text-base font-semibold flex justify-between items-center">
            <div className="flex gap-2 items-center">
              <div className="w-7 h-7 bg-secondary rounded-full p-1.5">
                <User className="h-4 w-4" />
              </div>
              {props.name}
            </div>
            <div className="flex space-x-2">
              <Button variant="outline" size="icon" onClick={() => window.open(`mailto:${props.email}`)}>
                <Mail className="w-5 h-5" />
              </Button>
              <Button variant="outline" size="icon" onClick={() => window.open(`https://wa.me/${props.phone}`)}>
                <WhatsApp className="w-5 h-5" />
              </Button>
            </div>
          </CardTitle>
        </CardHeader>
        <Separator />
        <CardContent className="p-4 space-y-4">
          <InfoRow label="ID" value={props.id} />
          <InfoRow label="Email" value={props.email} />
          <InfoRow label="Telefone" value={Phone.build(props.phone).getFormattedNumber()} />
        </CardContent>
      </Card>
    </div>
  )
}
