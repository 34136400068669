import { useContainer } from '@hub-la/fe-container'
import { Analytics, AnalyticsEvent } from '@hub-la/fe-core-analytics'
import {
  Button,
  Calendar,
  cn,
  Dialog,
  DialogContent,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@hub-la/shadcn'
import { Field, FormikProvider, useFormik } from 'formik'
import { CalendarIcon } from 'lucide-react'
import moment from 'moment'
import React, { useState } from 'react'
import { DateRange } from 'react-day-picker'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { BookAccount } from '../../../domain/dtos/book-accounts'
import { ExportMovementsFilterDto } from '../../../domain/dtos/export-movements-request.dto'
import { ExportAlreadyInProgressError } from '../../../domain/errors/export-already-in-progress'
import { ExportLongPeriodError } from '../../../domain/errors/export-long-period'
import { GetDateRange } from '../../../usecases/get-date-range'
import { useExportMovements } from '../../hooks/use-export-movements'

export interface ExportMovementsDialogProps {
  open: boolean
  accountType: BookAccount
  onClose: () => void
}

enum ExportMovementsModalSteps {
  filterByDate = 'filterByDate',
  result = 'result',
}

export const ExportMovementsModal: React.FC<ExportMovementsDialogProps> = ({ open, accountType, onClose }) => {
  const { t } = useTranslation()
  const container = useContainer()
  const analytics = container.get(Analytics)
  const initialDateRange = new GetDateRange().execute(7)
  const form = useFormik({
    initialValues: {
      receiverEmail: '',
    },
    onSubmit: (values) => {
      handleExportMovements()
    },
    validationSchema: Yup.object().shape({
      receiverEmail: Yup.string().email('Email inválido').required('Email é obrigatório'),
    }),
  })

  const [exportFilter, setExportFilter] = useState<ExportMovementsFilterDto>({
    startDate: initialDateRange.startDate,
    endDate: initialDateRange.endDate,
    accountType: accountType,
    receiverEmail: form.values.receiverEmail,
  })
  const [step, setStep] = useState<ExportMovementsModalSteps>(ExportMovementsModalSteps.filterByDate)
  const [modalMessage, setModalMessage] = useState<string>('')
  const { mutateAsync, isLoading } = useExportMovements()
  const isLongPeriodError = verifyLongPeriod()

  const handleClose = () => {
    setStep(ExportMovementsModalSteps.filterByDate)
    setModalMessage('')
    form.resetForm()
    onClose()
  }

  const handleExportMovements = () => {
    analytics.track(AnalyticsEvent.WALLET.EXPORT_CLICKED, { accountType })
    mutateAsync({ ...exportFilter, receiverEmail: form.values.receiverEmail })
      .then((output) => {
        setModalMessage(t('exportMovementsModal.successMessage', { email: output.email }))
        setStep(ExportMovementsModalSteps.result)
      })
      .catch((error) => {
        if (error instanceof ExportAlreadyInProgressError) {
          setModalMessage(t('exportMovementsModal.conflictErrorMessage'))
        } else if (error instanceof ExportLongPeriodError) {
          setModalMessage(t('exportMovementsModal.longPeriodErrorMessage'))
        } else {
          setModalMessage(t('exportMovementsModal.generalErrorMessage'))
        }
        setStep(ExportMovementsModalSteps.result)
      })
  }

  const handleDateChange = (selected: DateRange | undefined) => {
    setExportFilter((prev) => ({
      ...prev,
      startDate: selected?.from ? moment(selected.from).startOf('day').toDate() : prev.startDate,
      endDate: selected?.to ? moment(selected.to).endOf('day').toDate() : prev.endDate,
    }))
  }

  const DateRangePickerPlaceholder = () => {
    const { startDate, endDate } = exportFilter
    const formattedStartDate = moment(startDate).format('D MMM. YYYY')
    const formattedEndDate = moment(endDate).format('D MMM. YYYY')
    return `${formattedStartDate} - ${formattedEndDate}`
  }

  function verifyLongPeriod() {
    const months =
      (exportFilter.endDate.getFullYear() - exportFilter.startDate.getFullYear()) * 12 +
      exportFilter.endDate.getMonth() -
      exportFilter.startDate.getMonth()
    return months > 12
  }

  const content = (
    <div className="space-y-4">
      <h3 className="text-lg font-semibold">{t('exportMovementsModal.title')}</h3>
      {step === ExportMovementsModalSteps.filterByDate ? (
        <>
          <Popover>
            <PopoverTrigger asChild>
              <Button
                variant="outline"
                className={cn('w-full justify-start text-left font-normal', isLongPeriodError ? 'border-red-500' : '')}
              >
                <CalendarIcon className="mr-2 h-4 w-4" />
                <span>{DateRangePickerPlaceholder()}</span>
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0 z-10001" align="start">
              <Calendar
                mode="range"
                selected={{
                  from: new Date(exportFilter.startDate),
                  to: new Date(exportFilter.endDate),
                }}
                onSelect={handleDateChange}
                numberOfMonths={2}
                disabled={(date) => date > new Date() || date < new Date('2000-01-01')}
              />
            </PopoverContent>
          </Popover>
          {isLongPeriodError && (
            <span className="text-sm text-red-500">{t('exportMovementsModal.longPeriodInputErrorMessage')}</span>
          )}
          <FormikProvider value={form}>
            <form onSubmit={form.handleSubmit}>
              <Field
                as={Input}
                id="receiverEmail"
                name="receiverEmail"
                placeholder="example@email.com"
                type="email"
                className={
                  form.errors.receiverEmail && form.touched.receiverEmail && form.dirty ? 'border-red-500' : ''
                }
              />
              {form.errors.receiverEmail && form.touched.receiverEmail && form.dirty ? (
                <div className="text-sm text-red-500 gap-2">{form.errors.receiverEmail}</div>
              ) : null}

              <div className="flex justify-end space-x-2 mt-4">
                <Button
                  variant="outline"
                  onClick={handleClose}
                  disabled={isLoading}
                  data-testid="export-movements-modal-close-btn"
                >
                  {t('exportMovementsModal.buttons.cancel')}
                </Button>
                <Button
                  type={'submit'}
                  loading={isLoading}
                  disabled={!!form.errors.receiverEmail || !form.values.receiverEmail || isLongPeriodError}
                >
                  {t('exportMovementsModal.buttons.export')}
                </Button>
              </div>
            </form>
          </FormikProvider>
        </>
      ) : (
        <>
          <p>{modalMessage}</p>
          <div className="flex justify-end">
            <Button onClick={handleClose} loading={isLoading}>
              {t('exportMovementsModal.buttons.getIt')}
            </Button>
          </div>
        </>
      )}
    </div>
  )

  return (
    <Dialog open={open} onOpenChange={(open) => !open && handleClose()}>
      <DialogContent>{content}</DialogContent>
    </Dialog>
  )
}
