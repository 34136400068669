import { onlyNumbers } from '@brazilian-utils/brazilian-utils'
import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { SubmitAccountVerificationInput } from '../domain/dtos/submit-account-verification'
import { DocumentType } from '../domain/enums/bank-account'
import { GeneralError } from '../domain/errors/general'
import { SoftDescriptor } from '../domain/vos/soft-descriptor'
import { Envs } from '../envs'
import { Values } from '../presentation/pages/account-verification-modal'
import { processUserName } from './process-user-name'
import { ReplaceAccents } from './replace-accents'

export class SubmitAccountVerification {
  public constructor(@inject(HttpClient) private readonly httpClient: HttpClient) {}

  public async execute(data: Values): Promise<void> {
    const response = await this.httpClient.request({
      method: HttpMethod.POST,
      url: this.makeUrl(),
      body: this.makeBody(data),
    })

    if ([HttpStatusCode.OK, HttpStatusCode.CREATED].includes(response.statusCode)) {
      return response.data
    } else {
      /** @todo: Handle backend errors */
      throw new GeneralError()
    }
  }

  private makeBody(data: Values): SubmitAccountVerificationInput {
    const replaceAccents = new ReplaceAccents().execute
    const { firstName, lastName } = processUserName(replaceAccents(data?.identity.fullName))
    return {
      ...data,
      identity: {
        ...data.identity,
        document: {
          ...data.identity.document,
          value: onlyNumbers(data.identity.document.value),
        },
        fullName: replaceAccents(data.identity.fullName),
        firstName,
        lastName,
        legalRepresentative:
          data.identity.document.type === DocumentType.CNPJ
            ? {
                fullName: replaceAccents(data.identity.legalRepresentative?.fullName ?? ''),
                document: {
                  type: data.identity.legalRepresentative?.document.type ?? DocumentType.CPF,
                  value: onlyNumbers(data.identity.legalRepresentative?.document.value ?? ''),
                  countryCode: data.identity.legalRepresentative?.document.countryCode ?? 'BR',
                },
              }
            : undefined,
      },
      businessConfiguration: {
        ...data.businessConfiguration,
        displayName: replaceAccents(data.identity.fullName),
        creditCard: {
          softDescriptor: SoftDescriptor.build(
            data.businessConfiguration.creditCard?.softDescriptor ?? '',
          ).withPrefix(),
        },
      },
    }
  }

  private makeUrl() {
    return `${Envs.BFF_WEB_URL}/kyc/upsert-business-and-identity`
  }
}
