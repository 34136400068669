import { Card, CardContent, CardHeader, CardTitle, Separator } from '@hub-la/shadcn'
import moment from 'moment'
import React from 'react'
import { InvoiceType } from '../../../domain/enums/invoice-type'
import { InfoRow } from './info-row'

type Props = {
  detail: {
    id: string
    type?: InvoiceType
    createdAt: string
    nextDueAt?: string
  }
}

export const Details: React.FC<Props> = (props) => {
  const { id, createdAt, nextDueAt } = props.detail

  return (
    <Card>
      <CardHeader className="py-4 px-4">
        <CardTitle className="text-base font-semibold flex justify-between items-center">
          Detalhes do parcelamento
        </CardTitle>
      </CardHeader>
      <Separator />
      <CardContent className="p-4 space-y-4">
        <InfoRow label="ID do parcelamento" value={id} />
        <InfoRow label="Data da compra" value={moment(createdAt).format('D MMM. YYYY HH:mm')} />
        <InfoRow label="Próximo vencimento" value={moment(nextDueAt).format('D MMM. YYYY HH:mm')} />
      </CardContent>
    </Card>
  )
}
