import {
  Button,
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
  Label,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@hub-la/shadcn'
import { ChevronsUpDown, Plus, RefreshCw, Trash2 } from 'lucide-react'
import { useEffect } from 'react'
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IntervalType } from '../../../../../../domain/enums/interval-type.enum'
import { PriceType } from '../../../../../../domain/enums/price-type.enum'
import { formatLocalNumber } from '../../../../../../domain/vos/format-local-number'
import { CurrencyField } from '../../../../../components/currency-field'
import { InstallmentsSelect } from '../../../../../components/installments-select'
import { PlansSelect } from '../../../../../components/plans-select'
import { PromotionField } from '../../../../../components/promotion-field'
import { useGetProduct } from '../../../../../hooks/use-get-product'
import { OfferSchemaValidationType } from '../../../../../validations/offer-validation'
import { AutomationSettings } from './automation-settings'

const accessDays = [
  {
    value: '1',
    label: 'accessDays.oneDay',
  },
  {
    value: '30',
    label: 'accessDays.oneMonth',
  },
  {
    value: '90',
    label: 'accessDays.threeMonths',
  },
  {
    value: '180',
    label: 'accessDays.sixMonths',
  },
  {
    value: '270',
    label: 'accessDays.nineMonths',
  },
  {
    value: '365',
    label: 'accessDays.oneYear',
  },
  {
    value: '730',
    label: 'accessDays.twoYears',
  },
  {
    value: '1095',
    label: 'accessDays.threeYears',
  },
  {
    value: '1460',
    label: 'accessDays.fourYears',
  },
  {
    value: '1825',
    label: 'accessDays.fiveYears',
  },
  {
    value: 'lifetime',
    label: 'accessDays.indefinite',
  },
]

const OneTimeCard = () => {
  const { t } = useTranslation()
  const { watch, trigger } = useFormContext<OfferSchemaValidationType>()
  const productId = watch('productId')
  const planPrice = watch('pricing.price')

  useEffect(() => {
    // debounce to trigger validation and prevent too many validation
    const timeout = setTimeout(() => {
      trigger(`pricing.oldPrice`)
    }, 500)

    return () => {
      clearTimeout(timeout)
    }
  }, [planPrice, trigger])

  // We actually use productId here, not the mainOfferId
  const canUseOneDayAccess = [
    '29pc8QieEHu2d8pOJcPB',
    '3UaL53hRRcYVK5dfti5V',
    'DMXQNwhxypcVOQ90YOMQ',
    'ElL5EvhPRria3646zfgm',
    'UcKksC0qsIyj1J2kCExj',
    'VUSzjUIC3GUd5hT6D4GK',
    'Wh1WmMHAea9ZyPHHqhTP',
    'bVYyCJkf1xYnQ2n9tb45',
    'fX1L6cnYWn0OD6nyjr5b',
    'fs6cxoaFhw9laaxM1Cg2',
    'poYY010xJK674ZccD0xU',
    'ufMarmyisi1eZg8e80bJ',
  ].includes(productId ?? '')

  return (
    <div>
      <div className="grid w-full items-center gap-4">
        <Controller
          render={({ field: { name, onChange, value, ...props }, fieldState }) => (
            <CurrencyField
              label={t('offer.pricingTab.pricing.price.label')}
              error={Boolean(fieldState.error?.type)}
              helperText={fieldState.error?.type && t(`fieldErrors.${fieldState.error?.type}`)}
              onChange={onChange}
              value={value}
              name={name}
              {...props}
            />
          )}
          name="pricing.price"
        />
        <Controller
          render={({ field: { name, onChange, value, ...props }, fieldState }) => (
            <PromotionField
              label={t('offer.pricingTab.pricing.promotion.label')}
              error={Boolean(fieldState.error?.type)}
              helperText={fieldState.error?.type && t(`fieldErrors.${fieldState.error?.type}`)}
              onChange={onChange}
              value={value}
              name={name}
              {...props}
            />
          )}
          name="pricing.oldPrice"
        />
        <Controller
          render={({ field: { onChange, value, name }, fieldState }) => (
            <InstallmentsSelect
              label={t('offer.pricingTab.pricing.installments.label')}
              maxInstallment={12}
              error={Boolean(fieldState.error?.type)}
              helperText={fieldState.error?.type && t(`fieldErrors.${fieldState.error?.type}`)}
              onChange={onChange}
              value={value}
              name={name}
            />
          )}
          name="pricing.installments"
        />
        <Controller
          render={({ field, fieldState }) => (
            <div className="space-y-2">
              <Label htmlFor="accessDays" className="block">
                {t('offer.pricingTab.pricing.accessDays.label')}
              </Label>
              <Select {...field} value={field.value} onValueChange={field.onChange} name="accessDays">
                <SelectTrigger className={`w-full ${fieldState.error ? 'border-red-500' : ''}`}>
                  <SelectValue placeholder={t('offer.pricingTab.pricing.accessDays.placeholder')} />
                </SelectTrigger>
                <SelectContent>
                  {accessDays
                    .filter(({ value }) => value !== '1' || canUseOneDayAccess)
                    .map(({ label, value }) => (
                      <SelectItem key={value} value={value}>
                        {t(label)}
                      </SelectItem>
                    ))}
                </SelectContent>
              </Select>
              {fieldState.error && <p className="text-sm text-red-500">{t(`fieldErrors.${fieldState.error.type}`)}</p>}
            </div>
          )}
          name="pricing.accessDays"
        />
      </div>
    </div>
  )
}

const maxIntstallmentsByInterval = {
  [IntervalType.MONTHLY]: 1,
  [IntervalType.QUARTERLY]: 3,
  [IntervalType.SEMIANNUALLY]: 6,
  [IntervalType.ANNUALLY]: 12,
}

const RecurringCard = ({
  productName,
  defaultInstallments,
  onDelete,
  showDelete,
  index,
}: {
  productName?: string
  // Index of FieldArray item
  index: number
  defaultInstallments: number
  onDelete: () => void
  showDelete: boolean
}) => {
  const { t } = useTranslation()
  const { watch, setValue, trigger } = useFormContext<OfferSchemaValidationType>()

  const plans = watch('pricing.plans') ?? []
  const plansSelected = plans.map((price) => price.interval as IntervalType)
  const planType = watch(`pricing.plans.${index}.interval`)
  const maxInstallment = maxIntstallmentsByInterval[planType]
  const planPrice = watch(`pricing.plans.${index}.price`)
  const formattedPrice = formatLocalNumber(planPrice / 100)

  const oldPrice = watch(`pricing.plans.${index}.oldPrice`)
  const formattedOldPrice = oldPrice ? formatLocalNumber(oldPrice / 100) : null

  useEffect(() => {
    // debounce 1s to trigger validation
    const timeout = setTimeout(() => {
      trigger(`pricing.plans.${index}.price`)
    }, 1000)

    return () => {
      clearTimeout(timeout)
    }
  }, [index, planPrice, trigger])

  useEffect(() => {
    setValue(`pricing.plans.${index}.installments`, maxIntstallmentsByInterval[planType as PriceType].toString())
  }, [index, planType, setValue])

  return (
    <Collapsible className="w-full rounded-lg border overflow-hidden bg-background shadow-sm">
      <CollapsibleTrigger className="flex w-full items-center justify-between px-6 py-4 bg-background">
        <div className="flex items-center gap-3">
          <div className="p-2 rounded-full bg-muted">
            <RefreshCw className="h-4 w-4 text-muted-foreground" />
          </div>
          <div className="text-left">
            <h4 className="text-sm font-medium">
              {productName} – Plano {t(`plans.${planType}`)}
            </h4>
            <p className="text-sm text-muted-foreground">
              {formattedOldPrice ? (
                <>
                  <span className="line-through">{formattedPrice}</span> {formattedOldPrice}
                </>
              ) : (
                formattedPrice
              )}
              {' / '}
              {t(`plansPerPeriod.${planType}`)}
            </p>
          </div>
        </div>
        <div className="flex items-center gap-3">
          {showDelete && (
            <Button variant="ghost" size="sm" onClick={() => onDelete()} type="button">
              <Trash2 className="h-4 w-4" />
            </Button>
          )}
          <ChevronsUpDown className="h-4 w-4 text-gray-500 transition-transform duration-200" />
        </div>
      </CollapsibleTrigger>
      <CollapsibleContent className="p-6">
        <div className="space-y-4">
          <Controller
            render={({ fieldState, field }) => (
              <PlansSelect
                label={t('offer.pricingTab.pricing.plan.label')}
                removePlans={plansSelected}
                error={Boolean(fieldState.error?.type)}
                helperText={fieldState.error?.type && t(`fieldErrors.${fieldState.error?.type}`)}
                {...field}
                onValueChange={(value) => {
                  field.onChange(value)
                }}
              />
            )}
            name={`pricing.plans.${index}.interval`}
          />
          <Controller
            rules={{ required: true }}
            render={({ field, fieldState }) => (
              <CurrencyField
                label={t('offer.pricingTab.pricing.price.label')}
                {...field}
                error={Boolean(fieldState.error?.type)}
                helperText={fieldState.error?.type && t(`fieldErrors.${fieldState.error?.type}`)}
              />
            )}
            name={`pricing.plans.${index}.price`}
          />
          <Controller
            name={`pricing.plans.${index}.oldPrice`}
            render={({ field, fieldState }) => (
              <PromotionField
                label={t('offer.pricingTab.pricing.promotion.label')}
                {...field}
                error={Boolean(fieldState.error?.type)}
                helperText={fieldState.error?.type && t(`fieldErrors.${fieldState.error?.type}`)}
              />
            )}
          />
          <Controller
            render={({ field, fieldState }) => (
              <InstallmentsSelect
                label={t('offer.pricingTab.pricing.installments.label')}
                maxInstallment={maxInstallment}
                error={Boolean(fieldState.error?.type)}
                helperText={fieldState.error?.type && t(`fieldErrors.${fieldState.error?.type}`)}
                {...field}
              />
            )}
            name={`pricing.plans.${index}.installments`}
          />
          {planType === IntervalType.MONTHLY && <AutomationSettings />}
        </div>
      </CollapsibleContent>
    </Collapsible>
  )
}

const pricesPlansType = [
  { plan: IntervalType.MONTHLY, maxInstallments: 1 },
  { plan: IntervalType.QUARTERLY, maxInstallments: 3 },
  { plan: IntervalType.SEMIANNUALLY, maxInstallments: 6 },
  { plan: IntervalType.ANNUALLY, maxInstallments: 12 },
]

const getNextNewPrice = (prices) => {
  if (prices.length >= pricesPlansType.length) {
    throw new Error('No more prices available')
  }

  const availablePricesPlan = pricesPlansType.filter((price) => !prices.some((p) => p.interval === price.plan))

  return {
    installments: availablePricesPlan[0]?.maxInstallments,
    price: 1000,
    interval: availablePricesPlan[0]?.plan,
    oldPrice: null,
  }
}

const PriceConfiguration = () => {
  const { t } = useTranslation()
  const { control } = useFormContext<OfferSchemaValidationType>()
  const [pricingPriceType] = useWatch({
    name: ['pricing.priceType'],
    control,
  })
  const productId = useWatch({
    name: 'productId',
    control,
  })
  const {
    fields: plans,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'pricing.plans',
  })

  const isRecurringPrice = pricingPriceType === 'recurring'

  const { data: product } = useGetProduct(productId ?? '')

  if (isRecurringPrice) {
    return (
      <div className="flex flex-col gap-4">
        {plans.map((plan, index) => {
          return (
            <RecurringCard
              key={`${plan.interval}-${index}`}
              productName={product?.name}
              index={index}
              defaultInstallments={plan.installments ?? 1}
              onDelete={() => remove(index)}
              showDelete={plans.length > 1 && index !== 0}
            />
          )
        })}
        <div>
          <Button
            variant="outline"
            size="sm"
            disabled={plans.length >= pricesPlansType.length}
            onClick={() => append(getNextNewPrice(plans))}
            type="button"
          >
            <Plus className="mr-2 h-4 w-4" />
            {t('offer.pricingTab.pricing.addPlan')}
          </Button>
        </div>
      </div>
    )
  }

  return (
    <div className="flex flex-col gap-6">
      <OneTimeCard />
    </div>
  )
}

export { PriceConfiguration }
