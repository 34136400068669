export enum ReceiverStatus {
  UNKNOWN = 'unknown',
  /**
   * Invite sent to partner
   */
  INVITED = 'invited',
  /**
   * Invite rejected by partner
   */
  REJECTED = 'rejected',
  /**
   * Invite accepted by parter, partner is active for sales
   */
  ACCEPTED = 'accepted',
  /**
   * Partnership canceled
   */
  CANCELED = 'canceled',
  /**
   * Partnership finished
   */
  FINISHED = 'finished',
  /**
   * if a partnership is active(accepted) and cancellation is requested by creator, waiting response of partner
   */
  CANCELLATION_REQUESTED = 'cancellation_requested',
}
