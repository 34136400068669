import { container, ContainerProvider } from '@hub-la/fe-container'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { I18nextProvider } from 'react-i18next'
import { Envs } from '../envs'
import i18n from '../i18n'

const queryClient = new QueryClient()

// In development mode, defining a container element for reCAPTCHA triggers an error
const isProduction = Envs.NODE_ENV === 'production'

export const AuthProvider = ({ children }) => {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={Envs.RECAPTCHA_KEY}
      container={
        isProduction
          ? {
              element: 'recaptcha-with-0',
              parameters: {
                badge: 'bottomleft',
              },
            }
          : undefined
      }
    >
      <ContainerProvider container={container}>
        <I18nextProvider i18n={i18n}>
          <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
        </I18nextProvider>
      </ContainerProvider>
      <div id="recaptcha-with-0" />
    </GoogleReCaptchaProvider>
  )
}
