import { boolean, object, string } from 'yup'
import { ShortLinkType } from '../../domain/enums/shortlink-type'
import { Envs } from '../../envs'

export const createShortLinkValidationSchema = () => {
  return object().shape({
    offerId: string().required(),
    name: string().required('shortlinks.errors.validation.required'),
    type: string().required(),
    redirectUrl: string()
      .url('shortlinks.errors.validation.mustBeUrl')
      .when('type', {
        is: (type: ShortLinkType) => {
          return [ShortLinkType.PRODUCT_EXTERNAL, ShortLinkType.MEMBER_AREA_EXTERNAL].includes(type)
        },
        then: string().required('shortlinks.errors.validation.required'),
      })
      .test('not-allowed-redirect', 'Não é permitido redirecionar para um outro link curto', (value) => {
        if (!value) return true
        return !value.includes(`${Envs.SITE_URL}/r`)
      }),
    utmSource: string().when('applyToSpecificUtms', {
      is: (applyToSpecificUtms: boolean, utmMedium: string, utmCampaign: string, utmContent: string, utmTerm: string) =>
        applyToSpecificUtms === true && !(utmMedium || utmCampaign || utmContent || utmTerm),

      then: string().test('at-least-one-utm', 'shortlinks.errors.validation.leastOne', function (value) {
        const { utmMedium, utmCampaign, utmContent, utmTerm } = this.parent
        return !!utmMedium || !!utmCampaign || !!utmContent || !!utmTerm || !!value
      }),
    }),
    utmMedium: string().when('applyToSpecificUtms', {
      is: (applyToSpecificUtms: boolean, utmSource: string, utmCampaign: string, utmContent: string, utmTerm: string) =>
        applyToSpecificUtms === true && !(utmSource || utmCampaign || utmContent || utmTerm),

      then: string().test('at-least-one-utm', 'shortlinks.errors.validation.leastOne', function (value) {
        const { utmSource, utmCampaign, utmContent, utmTerm } = this.parent
        return !!utmSource || !!utmCampaign || !!utmContent || !!utmTerm || !!value
      }),
    }),
    utmCampaign: string().when('applyToSpecificUtms', {
      is: (applyToSpecificUtms: boolean, utmSource: string, utmMedium: string, utmContent: string, utmTerm: string) =>
        applyToSpecificUtms === true && !(utmSource || utmMedium || utmContent || utmTerm),

      then: string().test('at-least-one-utm', 'shortlinks.errors.validation.leastOne', function (value) {
        const { utmSource, utmMedium, utmContent, utmTerm } = this.parent
        return !!utmSource || !!utmMedium || !!utmContent || !!utmTerm || !!value
      }),
    }),
    utmContent: string().when('applyToSpecificUtms', {
      is: (applyToSpecificUtms: boolean, utmSource: string, utmMedium: string, utmCampaign: string, utmTerm: string) =>
        applyToSpecificUtms === true && !(utmSource || utmMedium || utmCampaign || utmTerm),

      then: string().test('at-least-one-utm', 'shortlinks.errors.validation.leastOne', function (value) {
        const { utmSource, utmMedium, utmCampaign, utmTerm } = this.parent
        return !!utmSource || !!utmMedium || !!utmCampaign || !!utmTerm || !!value
      }),
    }),
    utmTerm: string().when('applyToSpecificUtms', {
      is: (
        applyToSpecificUtms: boolean,
        utmSource: string,
        utmMedium: string,
        utmCampaign: string,
        utmContent: string,
      ) => applyToSpecificUtms === true && !(utmSource || utmMedium || utmCampaign || utmContent),

      then: string().test('at-least-one-utm', 'shortlinks.errors.validation.leastOne', function (value) {
        const { utmSource, utmMedium, utmCampaign, utmContent } = this.parent
        return !!utmSource || !!utmMedium || !!utmCampaign || !!utmContent || !!value
      }),
    }),
    isSlugAvailable: boolean().required('shortlinks.errors.validation.required'),
  })
}
