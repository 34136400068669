import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'

import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '../../domain/enums/query-key'
import { ListAffiliations } from '../../usecases/list-affiliations'
import { ListAffiliationsInput } from '../../domain/dtos/list-affilitions-input'

export const useListAffiliations = (input: ListAffiliationsInput) => {
  const container = useContainer()

  return useQuery([QueryKey.listAffiliations], () => new ListAffiliations(container.get(HttpClient)).execute(input), {
    refetchOnWindowFocus: false,
    retry: 3,
  })
}
