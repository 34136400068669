import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { NewExperienceFeedback } from '../../domain/dtos/new-experience-feedback'
import { GeneralError } from '../../domain/errors/general'
import { Envs } from '../../envs'

export class RegisterUserFeedback {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(data: NewExperienceFeedback): Promise<void> {
    const response = await this.httpClient.request({
      method: HttpMethod.POST,
      url: `${Envs.BFF_WEB_URL}/user-feedback/new-experience`,
      body: { ...data },
    })

    if (response.statusCode === HttpStatusCode.CREATED) {
      return
    }

    throw new GeneralError()
  }
}
