import { useFormikContext } from 'formik'
import React, { memo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Values } from '.'
import { Installment } from '../../../../../domain/dtos/installment'
import { useField } from '../../../../hooks/use-field'
import { useYunoSecureFields } from '../../../../hooks/use-yuno-secure-fields'
import { CPF } from './cpf'
import { Input, Label } from '@hub-la/shadcn'

export const YunoCreditCard: React.FC<{ installments: Installment[] }> = memo(() => {
  const { t } = useTranslation()

  const { values, handleBlur, handleChange } = useFormikContext<Values>()
  const { isValid } = useField()
  const { isPanValid, isExpirationValid, isCvvValid, generateToken, error } = useYunoSecureFields(values.sessionId)

  const isFormValid =
    isPanValid && isExpirationValid && isCvvValid && isValid('document') && isValid('creditCard.holder')

  useEffect(() => {
    if (!isFormValid || !values.sessionId) {
      return
    }
    generateToken()
  }, [isFormValid, values.sessionId])

  return (
    <div className="flex flex-col space-y-4">
      <div className="flex flex-col space-y-2">
        <div className="flex flex-col mb-1 space-y-1">
          <div className="h-[42px]" id="pan" />
          <div className="flex space-x-2">
            <div className="h-[42px] w-full" id="expiration" />
            <div className="h-[42px] w-full" id="cvv" />
          </div>
          {error && (
            <p id="txt-card-error-j1Qd" className="text-red-500 text-xs">
              {error}
            </p>
          )}
        </div>

        <div className="space-y-2">
          <Label htmlFor="creditCard.holder">{t('changePaymentMethodModal.creditCard.holder')}</Label>
          <Input id="creditCard.holder" onBlur={handleBlur} onChange={handleChange} value={values.creditCard?.holder} />
        </div>

        <CPF />
      </div>
    </div>
  )
})
