import { ShadowIcon } from '@radix-ui/react-icons'
import { Check, CircleDashed, X } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { RefundStatus } from '../../domain/enums/refund-status'

type RefundStatusProps = {
  status: RefundStatus
  paidAt?: string
  createdAt?: string
}

const getStyles = (color: string) => {
  return { size: 16, className: `mr-1 text-${color}-700`, fill: color, style: { fillOpacity: 0.1 } }
}

export const RefundCurrentStatus = ({ status, paidAt, createdAt }: RefundStatusProps) => {
  const { t } = useTranslation()

  const dateDiff = (first: number, second: number) => {
    return Math.ceil((second - first) / (1000 * 60 * 60 * 24))
  }

  const getIcon = () => {
    switch (status) {
      case RefundStatus.CREATED:
      case RefundStatus.PENDING:
        return <CircleDashed className="w-4 h-4 text-outline" />

      case RefundStatus.FAILED:
        return (
          <div className="bg-amber-600  rounded-full w-4 h-4 flex justify-center items-center">
            <X className="text-primary-foreground w-3 h-3" strokeWidth={3} />
          </div>
        )

      case RefundStatus.REJECTED:
        return (
          <div className="bg-red-700  rounded-full w-4 h-4 flex justify-center items-center">
            <X className="text-primary-foreground w-3 h-3" strokeWidth={3} />
          </div>
        )
      case RefundStatus.CANCELED:
        return <X className="w-4 h-4 text-outline" />

      case RefundStatus.PROCESSING:
        return <ShadowIcon className="w-4 h-4" />

      case RefundStatus.ACCEPTED:
        return (
          <div className="bg-green-600  rounded-full w-4 h-4 flex justify-center items-center">
            <Check className="text-primary-foreground w-3 h-3" strokeWidth={3} />
          </div>
        )
    }
  }

  const daysBetween = paidAt && createdAt ? dateDiff(new Date(paidAt).getTime(), new Date(createdAt).getTime()) : null

  return (
    <div className={`flex items-center gap-1.5`}>
      {getIcon()}

      {paidAt && createdAt
        ? t(`status.${status}`, {
            count: daysBetween || 0, // Ensuring `count` is never null
          })
        : t(`status.${status}`)}
    </div>
  )
}
