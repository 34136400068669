import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { CreateRuleInput } from '../domain/dtos/create-rule-input'
import { mapBackendProvider } from '../domain/dtos/integration-provider'
import { GeneralError } from '../domain/errors/general'
import { Envs } from '../envs'

export class CreateRule {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient<void>,
  ) {}

  public async execute(input: CreateRuleInput): Promise<void> {
    const response = await this.httpClient.request({
      method: HttpMethod.POST,
      url: `${Envs.BFF_WEB_URL}/integrations/${input.integrationId}/rules`,
      body: this.makeBody(input),
    })

    if (response.statusCode === HttpStatusCode.CREATED) {
      return
    }

    throw new GeneralError()
  }

  private makeBody(input: CreateRuleInput) {
    return {
      provider: mapBackendProvider[input.provider],
      rule: {
        name: input.rule.name,
        url: input.rule.url,
        productIds: input.rule.products.map(({ id }) => id),
        offerIds: input.rule.offers.map(({ id }) => id),
        listIds: input.rule.lists.map(({ id }) => id),
        tagIds: input.rule.tags.map(({ id }) => id),
        events: input.rule.events,
      },
    }
  }
}
