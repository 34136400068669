import { HttpClient, HttpMethod, HttpResponse, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { isNil } from 'lodash'
import { GetOffersOutput } from '../domain/dtos/get-offers-output'
import { GetOffersResponse } from '../domain/dtos/get-offers-response'
import { GeneralError } from '../domain/errors/general'
import { Envs } from '../envs'

export class GetOffers {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(): Promise<GetOffersOutput> {
    const response = await this.httpClient.request({
      method: HttpMethod.GET,
      url: `${Envs.BFF_PRODUCT_URL}/filters/offers`,
    })

    if (response.statusCode === HttpStatusCode.OK) {
      return this.makeOutput(response)
    }

    throw new GeneralError()
  }

  private makeOutput(response: HttpResponse<GetOffersResponse>): GetOffersOutput {
    const data = response.data

    if (isNil(data)) {
      return []
    }

    const offers = [...(data.owner ?? [])].sort((a, b) => (a.product?.name ?? '').localeCompare(b.product?.name ?? ''))

    offers.unshift({
      id: 'all',
      name: 'Todos',
      product: {
        id: 'all',
        name: 'Todos',
      },
    })
    return [...new Set(offers)]
  }
}
