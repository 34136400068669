import { Dialog, DialogContent } from '@hub-la/shadcn'
import React from 'react'
import { useGetCreatorInvoiceDetails } from '../../hooks/use-get-creator-invoice-details'
import { CreatorContent } from './modal-content/creator-content'
import { LoadingModalContent } from './modal-content/loading-content'

export interface CreatorInvoiceDialogProps {
  invoiceId: string | undefined
  onClose: () => void
}

export const CreatorInvoiceModal: React.FC<CreatorInvoiceDialogProps> = ({ invoiceId, onClose }) => {
  const { isLoading, isFetching, data } = useGetCreatorInvoiceDetails(invoiceId)

  const content =
    data && !isLoading && !isFetching ? (
      <CreatorContent invoice={data} key={`creator-content-${invoiceId}`} />
    ) : (
      <LoadingModalContent />
    )

  return (
    <Dialog open={!!invoiceId} onOpenChange={(open) => !open && onClose()}>
      <DialogContent>{content}</DialogContent>
    </Dialog>
  )
}
