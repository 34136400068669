import { Ticket } from '@hub-la/fe-event'
import { Button, Table, TableBody, TableCell, TableEmptyState, TableHead, TableHeader, TableRow } from '@hub-la/shadcn'
import { Edit } from 'lucide-react'
import moment from 'moment'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory, useParams } from 'react-router-dom'
import { OrderDirection } from '../../../domain/dtos/orderDirection'
import { useListEventTickets } from '../../hooks/tickets/use-list-event-tickets'
import { useRefetch } from '../../hooks/waitlist/use-refetch'

export interface TicketsProps {
  offerId: string
}

// TODO: Implement pagination
// TODO: Implement sorting
export const Tickets: React.FC<TicketsProps> = ({ offerId }) => {
  const { productId } = useParams<{ productId: string }>()
  const [orderDirection, setOrderDirection] = useState<OrderDirection>(OrderDirection.DESC)
  const history = useHistory()
  const { t } = useTranslation()
  const { data, isLoading, refetch } = useListEventTickets({
    productId: offerId,
    orderDirection,
  })

  useRefetch({ productId, orderDirection }, refetch)

  const isRowsEmpty = data?.length === 0
  const canRenderEmptyState = !isLoading && isRowsEmpty

  return (
    <div className="h-full w-full space-y-3">
      <div className="flex items-baseline justify-between">
        <p className="text-sm">{data?.length} ingressos</p>
        <Button onClick={() => history.push(`/edit/${productId}/tickets/create?event=true`)} className="mt-4">
          {t('tickets.create')}
        </Button>
      </div>

      <div className="bg-background rounded-lg border">
        <Table>
          <TableHeader>
            <TableRow className="hover:bg-background">
              <TableHead>Ingressos</TableHead>
              <TableHead>Data de criação</TableHead>
              <TableHead className="w-10"></TableHead>
            </TableRow>
          </TableHeader>

          <TableBody>
            {data?.map((ticket) => (
              <TableRow key={ticket.id} className="hover:bg-accent">
                <TableCell className="font-medium">
                  <div className="flex items-center gap-2">
                    <Ticket
                      name={ticket.name}
                      width={48}
                      height={38}
                      variant={'icon'}
                      bgColor={ticket?.metadata?.primaryColor}
                      secondaryColor={ticket?.metadata?.secondaryColor}
                      textColor={ticket?.metadata?.textColor}
                    />

                    <div className="flex flex-col items-start">
                      <span>{ticket.name}</span>

                      <span className="text-xs text-muted-foreground">{ticket.id}</span>
                    </div>
                  </div>
                </TableCell>

                <TableCell>{moment(ticket.createdAt).format('DD MMM. YYYY')}</TableCell>

                <TableCell>
                  <Link to={`/edit/${productId}/tickets/${ticket.id}?event=true`} className="flex items-center gap-2">
                    <Button variant="ghost" size="icon">
                      <Edit className="h-4 w-4" />
                    </Button>
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        {canRenderEmptyState && (
          <TableEmptyState title={t('tickets.emptyState')} subtitle={t('tickets.emptyStateSubtitle')} />
        )}
      </div>
    </div>
  )
}
