import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ConfirmExportModal } from '../../components/confirm-export-modal'
import { useGetAffiliates } from '../../hooks/use-get-affiliates'
import { TableLoading } from './table-loading'

import {
  Badge,
  Button,
  CopyClipboardButton,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Table,
  TableBody,
  TableCell,
  TableEmptyState,
  TableHead,
  TableHeader,
  TablePagination,
  TableRow,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@hub-la/shadcn'
import { Info, MoreHorizontal, RefreshCcw } from 'lucide-react'
import { Affiliate } from '../../../domain/dtos/affiliate'
import { ChangeCommissionModal } from '../../components/change-commission-modal'

export const AffiliatesTable = () => {
  const { t } = useTranslation()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [openChangeCommissionModal, setOpenChangeCommissionModal] = useState<boolean>(false)
  const [selectedAffiliateData, setSelectedAffiliateData] = useState<{
    affiliateId: string
    sellCommission: number
    renewalCommission: number
    useDefaultCommission: boolean
  } | null>(null)
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(25)
  const { data, isFetching } = useGetAffiliates({ page, pageSize })
  const handleModalVisibility = (isOpen: boolean) => setIsModalOpen(isOpen)

  const headCells = [
    { key: 'name', label: 'Nome', sortable: false },
    { key: 'product', label: 'Produto', sortable: false },
    { key: 'commission', label: 'Comissão', tooltip: 'Venda/Renovação', sortable: false },
    { key: 'id', label: 'ID de afiliado', sortable: false },
    { key: 'createdAt', label: 'Data de afiliação', sortable: false },
    { key: 'options', label: '', sortable: false, width: 20 },
  ]

  const isRowsEmpty = !data?.items || data.items.length === 0
  const canRenderEmptyState = !isFetching && isRowsEmpty

  const getCommission = (affiliate: Affiliate) => {
    if (!affiliate.commission) {
      return (
        <TooltipProvider delayDuration={200}>
          <Tooltip>
            <TooltipTrigger>
              <Badge variant="secondary" className="font-medium">
                {t('defaultCommission')}
              </Badge>
            </TooltipTrigger>
            <TooltipContent>
              <p>{t('defaultCommissionTooltip')}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      )
    }

    return `${affiliate?.commission?.sell?.isEnabled ? affiliate?.commission?.sell?.percent : 0}% / ${
      affiliate?.commission?.renewal?.isEnabled ? affiliate?.commission?.renewal?.percent : 0
    }%`
  }

  const handleLocalDatetime = (datetime: string | undefined) => {
    if (!datetime) return undefined

    const date = new Date(datetime)
    return date
      .toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZone: 'America/Sao_Paulo',
      })
      .replace(/,/g, '')
      .replace(/\sde\s/g, ' ')
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-baseline justify-between">
        <p className="text-sm">{data?.total} afiliados</p>
        <ConfirmExportModal isOpen={isModalOpen} handleVisibility={handleModalVisibility} />
      </div>

      <div className="border bg-background w-full rounded-lg">
        <Table>
          <TableHeader>
            <TableRow className="hover:bg-background">
              {headCells.map((cell) => (
                <TableHead key={cell.key} style={cell.width ? { width: cell.width } : {}}>
                  <div className="flex items-center space-x-2">
                    <span>{cell.label}</span>
                    {cell.tooltip && (
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger>
                            <Info className="h-3 w-3" />
                          </TooltipTrigger>
                          <TooltipContent>{cell.tooltip}</TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    )}
                  </div>
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>

          <TableBody>
            {isFetching && isRowsEmpty
              ? Array.from({ length: pageSize }).map((_, i) => <TableLoading key={`skeleton-row-${i}`} />)
              : (data.items || []).map((affiliate) => (
                  <TableRow key={affiliate.id}>
                    <TableCell>{affiliate.user.name}</TableCell>

                    <TableCell>
                      <div className="flex flex-col">
                        <span>{affiliate.product?.name}</span>
                        {/*<span className="text-xs text-muted-foreground">{affiliate.product?.id}</span>*/}
                      </div>
                    </TableCell>

                    <TableCell>{getCommission(affiliate)}</TableCell>

                    <TableCell>
                      <CopyClipboardButton
                        copyContent={`${affiliate.id}`}
                        label={`${affiliate.id}`}
                        tooltipCopiedLabel={t('copyClipboard.affiliateIdCopied')}
                        tooltipCopyLabel={t('copyClipboard.copyAffiliateId')}
                      />
                    </TableCell>
                    <TableCell>{handleLocalDatetime(affiliate.createdAt)}</TableCell>
                    <TableCell className="py-2">
                      <div className="flex items-center justify-end gap-2 mr-3">
                        <DropdownMenu>
                          <DropdownMenuTrigger asChild>
                            <Button variant="ghost" size="sm">
                              <MoreHorizontal className="h-4 w-4" />
                            </Button>
                          </DropdownMenuTrigger>
                          <DropdownMenuContent align="end">
                            <DropdownMenuItem
                              onClick={() => {
                                setSelectedAffiliateData({
                                  affiliateId: affiliate.id,
                                  sellCommission: affiliate.commission?.sell.percent ?? 0,
                                  renewalCommission: affiliate.commission?.renewal.percent ?? 0,
                                  useDefaultCommission: !affiliate.commission,
                                })
                                setOpenChangeCommissionModal(true)
                              }}
                            >
                              <RefreshCcw className="mr-2 h-4 w-4" />
                              <span>{'Editar comissão'}</span>
                            </DropdownMenuItem>
                          </DropdownMenuContent>
                        </DropdownMenu>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>

        {canRenderEmptyState && <TableEmptyState title={t('empty')} />}
      </div>
      <div className="flex justify-end px-2 mt-3 mb-3">
        {!isRowsEmpty && (
          <TablePagination
            page={page}
            setPage={setPage}
            lastPage={data.lastPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
          />
        )}
      </div>
      <ChangeCommissionModal
        open={openChangeCommissionModal}
        onClose={() => setOpenChangeCommissionModal(false)}
        affiliate={selectedAffiliateData ?? undefined}
      />
    </div>
  )
}
