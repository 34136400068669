import { useEffect, useState } from 'react'
import { IsStepValid } from '../../usecases/is-step-valid'
import { FormSteps } from '../pages/account-verification-modal'

export function useSetInitialStep(isValid: (field: string) => boolean): FormSteps {
  const [step, setStep] = useState<FormSteps>(FormSteps.COMPANY_DATA)
  const isStepValid = new IsStepValid(isValid)

  useEffect(() => {
    switch (true) {
      case isStepValid.execute(FormSteps.VERIFY_IDENTITY):
        setStep(FormSteps.REVIEW_AND_SAVE)
        break
      case isStepValid.execute(FormSteps.PUBLIC_INFO):
        setStep(FormSteps.VERIFY_IDENTITY)
        break
      case isStepValid.execute(FormSteps.PERSONAL_INFO):
        setStep(FormSteps.PUBLIC_INFO)
        break
      default:
        setStep(FormSteps.COMPANY_DATA)
        break
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid])

  return step
}
