import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '../../domain/dtos/query-keys'
import { RoleplayReferenceOutput } from '../../domain/dtos/roleplay-reference-output'
import { GetRoleplayReference } from '../../usecases/get-roleplay-reference'

export const useGetRoleplayReference = ({ enabled }: { enabled: boolean }) => {
  const container = useContainer()

  return useQuery<RoleplayReferenceOutput | null>(
    [QueryKey.getRoleplayReference],
    () => new GetRoleplayReference(container.get(HttpClient)).execute(),
    {
      retry: false,
      retryOnMount: false,
      refetchOnWindowFocus: false,
      initialData: null,
      enabled,
    },
  )
}
