import { Button, DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@hub-la/shadcn'
import { Copy, Edit, MoreHorizontal, Trash } from 'lucide-react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ShortLink } from '../../../../domain/dtos/short-link'
import { CreateEditShortLinkType } from '../../../../domain/enums/create-edit-shortlink-type'
import { useDuplicateShortLink } from '../../../hooks/shortlinks/use-duplicate-short-link'
import { ConfirmDeleteShortLinkModal } from '../components/confirm-delete-shortlink-modal'

type MenuMoreOptionsProps = {
  productId: string
  shortLink: ShortLink
  setShowCreateEditModal: (state: {
    isOpen: boolean
    shortLink: ShortLink | null
    type: CreateEditShortLinkType
  }) => void
  onUpdate: () => void
}

export const MenuMoreOptions: React.FC<MenuMoreOptionsProps> = ({
  productId,
  shortLink,
  setShowCreateEditModal,
  onUpdate,
}) => {
  const { t } = useTranslation()
  const [showConfirmDeleteModalOpen, setShowConfirmDeleteModalOpen] = useState<boolean>(false)
  const { mutateAsync: duplicateShortLink } = useDuplicateShortLink()

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" size="sm">
            <MoreHorizontal className="h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-56" align="end">
          <DropdownMenuItem
            onClick={() => {
              setShowCreateEditModal({ isOpen: true, shortLink: shortLink, type: CreateEditShortLinkType.EDIT })
            }}
          >
            <Edit className="mr-2 h-4 w-4" />
            <span>{t('shortlinks.table.options.edit')}</span>
          </DropdownMenuItem>
          <DropdownMenuItem
            disabled={shortLink.isDefault}
            onClick={() => {
              duplicateShortLink({ productId, shortLinkId: shortLink.id }).then(() => onUpdate())
            }}
          >
            <Copy className="mr-2 h-4 w-4" />
            <span>{t('shortlinks.table.options.duplicate')}</span>
          </DropdownMenuItem>
          <DropdownMenuItem
            disabled={shortLink.isDefault}
            onClick={() => {
              setShowConfirmDeleteModalOpen(true)
            }}
          >
            <Trash className="mr-2 h-4 w-4" />
            <span>{t('shortlinks.table.options.delete')}</span>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <ConfirmDeleteShortLinkModal
        productId={productId}
        shortLinkId={shortLink.id}
        open={showConfirmDeleteModalOpen}
        onClose={() => setShowConfirmDeleteModalOpen(false)}
        onSuccess={() => onUpdate()}
      />
    </>
  )
}
