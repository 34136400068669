import { Button, CopyClipboardButton } from '@hub-la/shadcn'
import { ChevronLeft, Undo2 } from 'lucide-react'
import { useHistory } from 'react-router-dom'
import { RefundStatus } from '../../../domain/enums/refund-status'
import { Currency } from '../../../domain/vos/currency'
import { useAcceptRefund } from '../../hooks/use-accept-refund'
import { RejectRefundModal } from '../modals/reject-refund-modal'
import { RefundCurrentStatus } from '../refund-current-status'
type HeaderProps = {
  id: string
  status: RefundStatus
  name: string
  amount: number
  paidAt: string
  createdAt: string
  onClose: () => void
}

export const Header = ({ id, status, name, amount, paidAt, createdAt, onClose }: HeaderProps) => {
  const { mutateAsync: acceptRefund, isLoading } = useAcceptRefund()
  const canChangeStatus = [RefundStatus.CREATED, RefundStatus.PENDING].includes(status)
  const currency = new Currency()
  const history = useHistory()

  const onRefund = () => {
    acceptRefund(id).then(() => {
      onClose()
    })
  }

  return (
    <div className="flex flex-col space-y-4">
      <Button className="h-4 px-0 w-fit" size="sm" variant="link" onClick={() => history.goBack()}>
        <ChevronLeft className="w-4 h-4 mr-2" />
        Voltar
      </Button>

      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
        <div className="space-y-2 w-full sm:w-auto">
          <div className="flex flex-col sm:flex-row sm:items-center gap-2">
            <h3 className="text-2xl sm:text-3xl font-semibold truncate">
              {name} - {currency.format(amount)}
            </h3>
            <RefundCurrentStatus status={status} paidAt={paidAt} createdAt={createdAt} />
          </div>
          <CopyClipboardButton
            className="h-8 w-full sm:w-auto"
            labelClassName="truncate"
            copyContent={id}
            label={id}
            tooltipCopiedLabel={'Copiado'}
            tooltipCopyLabel={'Copiar ID'}
          />
        </div>

        {canChangeStatus && (
          <div className="flex flex-col sm:flex-row gap-2 w-full sm:w-auto">
            <Button className="w-full sm:w-auto" variant="outline" size="sm" onClick={onRefund} loading={isLoading}>
              <Undo2 className="mr-2 h-4 w-4" />
              Reembolsar
            </Button>
            <RejectRefundModal refundId={id} onSuccess={onClose} />
          </div>
        )}
      </div>
    </div>
  )
}
