import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useQuery } from '@tanstack/react-query'
import { GetConversionRateInput } from '../../domain/dtos/get-conversion-rate-input'
import { Indicator } from '../../domain/dtos/indicator'
import { QueryKey } from '../../domain/dtos/query-keys'
import { Status } from '../../domain/dtos/status'
import { GetConversionRate } from '../../usecases/get-conversion-rate'

export const useGetConversionRate = (input: GetConversionRateInput, isLoading: boolean) => {
  const container = useContainer()

  const offerIds = input.offers.filter(({ id }) => id !== 'all').map(({ id }) => id)
  const enabled = !isLoading && offerIds.length > 0
  const initialData = {
    current: 0,
    previous: 0,
    percentage: '0%',
    status: Status.neutral,
    indicator: Indicator.down,
  }

  return useQuery(
    [
      QueryKey.getConversionRate,
      JSON.stringify({
        startDate: input.startDate,
        endDate: input.endDate,
        offerIds: input.offerIds,
        paymentMethod: input.paymentMethod,
      }),
    ],
    ({ signal }) => {
      if (!enabled) {
        return Promise.resolve(initialData)
      }
      return new GetConversionRate(container.get(HttpClient)).execute(input, signal)
    },
    {
      retry: 3,
      retryOnMount: false,
      refetchOnWindowFocus: false,
      initialData,
      enabled,
    },
  )
}
