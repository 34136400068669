import { Accordion, AccordionContent, AccordionItem, AccordionTrigger, Skeleton } from '@hub-la/shadcn'

export const LoadingState = () => {
  const sections = [...Array(2)]

  return (
    <Accordion type="multiple" className="border rounded-lg w-full" defaultValue={sections.map((_, i) => i.toString())}>
      {[...Array(2)].map((_, i) => (
        <AccordionItem key={i} value={i.toString()} className="border-t border-muted first:border-t-0 w-full">
          <AccordionTrigger className="w-full px-3 py-2 gap-4">
            <div className="w-full flex gap-2">
              <Skeleton className="h-4 w-4" />

              <div className="w-full flex flex-col">
                <Skeleton className="h-5 w-3/4" />

                <div className="w-full flex flex-wrap gap-2 items-center justify-between mt-2">
                  <Skeleton className="h-3 w-20" />

                  <Skeleton className="h-1 w-32" />
                </div>
              </div>
            </div>
          </AccordionTrigger>

          <AccordionContent className="p-0">
            {[...Array(3)].map((_, i) => (
              <div key={i} className="cursor-pointer border-t border-muted">
                <div className="border-2 border-transparent px-3 py-2 flex gap-4 items-center">
                  <Skeleton className="h-4 w-4" />

                  <Skeleton className="h-[94px] w-[60px]" />

                  <div className="w-full flex flex-col gap-3">
                    <Skeleton className="h-4 w-full" />

                    <Skeleton className="h-3 w-20" />

                    <Skeleton className="h-1 w-full" />
                  </div>
                </div>
              </div>
            ))}
          </AccordionContent>
        </AccordionItem>
      ))}
    </Accordion>
  )
}
