import { LightDarkMode } from 'libs/frontend/modules/offer/src/lib/domain/enums/light-dark-mode.enum'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { OfferSection } from '../../../../../components/offer-section'
import { CheckboxOptions } from './checkbox-options'
import { LayoutSelector } from './layout-selector'
import { LightDarkThemeSelector } from './light-dark-theme-selector'
import { useFeatureFlag } from '@hub-la/fe-core-feature-flag'
import { PurchaseExperienceSelector } from './purchase-experience-selector'
import { useParams } from 'react-router-dom'

export const Preferences = () => {
  const { t } = useTranslation()

  const { offerId } = useParams<{ productId: string; offerId: string }>()

  const isCheckoutV4EnabledToOffer = !useFeatureFlag().isFlagEnabled('isCheckoutV4DisabledToOffer', {
    offerId,
  })

  return (
    <OfferSection title={t('offer.checkoutTab.preferences.title')}>
      {isCheckoutV4EnabledToOffer && (
        <>
          <Controller
            name="checkout.lightDarkMode"
            render={({ field }) => <LightDarkThemeSelector value={field.value} onChange={field.onChange} />}
          />
          <Controller
            name="checkout.checkoutlayoutType"
            render={({ field }) => <LayoutSelector value={field.value} onChange={field.onChange} />}
          />
          <Controller
            name="checkout.purchaseExperience"
            render={({ field }) => <PurchaseExperienceSelector value={field.value} onChange={field.onChange} />}
          />
          {/* <Controller */}
          {/*   name="checkout.priceFormat" */}
          {/*   render={({ field }) => ( */}
          {/*     <PriceFormatSelector */}
          {/*       value={field.value} */}
          {/*       onChange={field.onChange} */}
          {/*     /> */}
          {/*   )} */}
          {/* /> */}
        </>
      )}
      <CheckboxOptions />
    </OfferSection>
  )
}
