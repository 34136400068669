export enum QueryKey {
  downloadCsv = 'downloadCsv',
  getBalance = 'getBalance',
  getMovements = 'getMovements',
  getCreatorInvoiceDetails = 'getCreatorInvoiceDetails',
  getMovementsByInvoiceId = 'getMovementsByInvoiceId',
  getWithdrawalDetails = 'getWithdrawalDetails',
  requestWithdrawal = 'requestWithdrawal',
  exportMovements = 'exportMovements',
}
