import React, { memo } from 'react'
import { DelimitedArrayParam, StringParam, useQueryParams, withDefault } from 'use-query-params'
import { Filters } from '../components/filters'
import { ProductsTable } from './products-table'
import { ProductStatus } from '../../../domain/enums/product-status'
import { useTranslation } from 'react-i18next'

export const Products = memo(() => {
  const [filters, setFilters] = useQueryParams({
    search: withDefault(StringParam, ''),
    statuses: withDefault(DelimitedArrayParam, []),
  })
  const { t } = useTranslation()

  return (
    <div className="flex flex-col gap-4 mt-4">
      <Filters
        label={'Produto(s)'}
        filters={filters}
        setFilters={setFilters}
        status={Object.values(ProductStatus).map((status) => ({
          id: status,
          label: t(`products.status.${status}`),
        }))}
      />
      <ProductsTable filters={filters} />
    </div>
  )
})
