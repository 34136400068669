import { withAccountVerification, withApprovedPayout } from '@hub-la/fe-account-verification'
import { Button } from '@hub-la/shadcn'
import { useTranslation } from 'react-i18next'
import { GetBalanceOutput } from '../../../../domain/dtos/get-balance-output'
import { BalanceTile } from './balance-tile'
import { Loading } from './loading'

type BalancesProps = {
  data?: GetBalanceOutput
  isFetching: boolean
  onWithdrawRequest: () => void
}

const ButtonWithApprovedPayout = withApprovedPayout(
  withAccountVerification(Button, { tooltip: 'Você precisa cadastrar uma conta bancária para realizar saques' }),
  { tooltip: 'Você precisa ativar sua conta para realizar saques' },
)

export const Balances = ({ data, isFetching, onWithdrawRequest }: BalancesProps) => {
  const hasBalanceReserve = (data?.reservedInCents ?? 0) > 0
  const hasContestedBalance = (data?.contestedInCents ?? 0) > 0

  const { t } = useTranslation()

  if (!data || isFetching) {
    return <Loading />
  }

  return (
    <div className="grid grid-cols-6 grid-rows-2 gap-4 md:gap-2 h-full md:h-auto">
      <div className="col-span-6 row-span-2 md:col-span-2 flex flex-col gap-2">
        <BalanceTile
          data-testid="available-balance-tile"
          label={t('balances.available.label')}
          valueInCents={data?.availableInCents ?? 0}
          tooltip={t('balances.available.tooltip')}
          variant="active-main"
          className="flex-grow"
          cta={
            <ButtonWithApprovedPayout onClick={onWithdrawRequest} variant="default">
              {t('balances.requestWithdraw')}
            </ButtonWithApprovedPayout>
          }
          transferableInCents={data?.transferableInCents ?? 0}
          transferableLabel={t('balances.transferable.label')}
          transferableTooltip={t('balances.transferable.tooltip')}
        />
      </div>

      <BalanceTile
        data-testid="receivable-balance-tile"
        label={t('balances.receivable.label')}
        valueInCents={data?.receivableInCents ?? 0}
        tooltip={t('balances.receivable.tooltip')}
        variant="active-main"
        className="col-span-6 row-span-2 md:col-span-2"
      />

      {hasContestedBalance && (
        <BalanceTile
          data-testid="contested-balance-tile"
          label={t('balances.contested.label')}
          valueInCents={data?.contestedInCents ?? 0}
          tooltip={t('balances.contested.tooltip')}
          variant="neutral"
          className="col-span-6 row-span-2 md:col-span-2 md:row-span-1"
        />
      )}

      {hasBalanceReserve && (
        <BalanceTile
          data-testid="reserved-balance-tile"
          label={t('balances.reserved.label')}
          valueInCents={data?.reservedInCents ?? 0}
          tooltip={t('balances.reserved.tooltip')}
          variant="neutral"
          className="col-span-6 row-span-2 md:col-span-2 md:row-span-1"
        />
      )}
    </div>
  )
}
