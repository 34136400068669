import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Subscription } from '../../domain/dtos/subscription'
import { QueryKey } from '../../domain/enums/query-key'
import { SubscriptionStatus } from '../../domain/enums/subscription-status'
import { EnableSubscriptionAutorenew } from '../../usecases/enable-subscription-autorenew'

export const useEnableAutorenew = () => {
  const container = useContainer()
  const queryClient = useQueryClient()

  return useMutation(
    [QueryKey.enableSubscriptionAutorenew],
    (id: string) => new EnableSubscriptionAutorenew(container.get(HttpClient)).execute(id),
    {
      retry: false,
      onSuccess: async (_, id) => {
        queryClient.setQueryData<Subscription | undefined>([QueryKey.getSubscription, id], (old) => {
          if (old) {
            return {
              ...old,
              autoRenew: true,
              status: SubscriptionStatus.ACTIVE,
            }
          }
          return old
        })
      },
    },
  )
}
