import { CopyClipboard } from '@hub-la/fe-copy-clipboard'
import { OfferDtoPriceTypeEnum, OfferDtoStatusEnum } from '@hub-la/sdk-bff-product'
import {
  Badge,
  Button,
  CopyClipboardButton,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableEmptyState,
  TableHead,
  TableHeader,
  TablePagination,
  TableRow,
  useToast,
} from '@hub-la/shadcn'
import isEmpty from 'lodash/isEmpty'
import { Copy, Edit, MoreHorizontal, PlusCircle } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory, useParams } from 'react-router-dom'
import { OfferStepStatus } from '../../../domain/dtos/offers/change-status'
import { FormatCurrencyBRL } from '../../../domain/vos/format-currency-brl'
import { Envs } from '../../../envs'
import { MakeUpsellUrl } from '../../../usecases/offers/make-upsell-url'
import { useGetOffersList } from '../../hooks/offers/use-get-offers-list'
import { usePatchOfferName } from '../../hooks/offers/use-patch-offer-name'
import { usePostChangeStatus } from '../../hooks/offers/use-post-change-status'
import { EditableText } from '../member-area/groups/components/editable-text/editable-text'
import { PlansTag } from './components/plans-tag'
import { TableRowLoading } from './components/table-row-loading'

const OffersSwitch = ({ isDefault, status, onChange, loading }) => {
  const { t } = useTranslation()
  const [checked, setChecked] = useState<boolean>(status === OfferDtoStatusEnum.IS_SELLING)

  useEffect(() => {
    setChecked(status === OfferDtoStatusEnum.IS_SELLING)
  }, [status])

  if (isDefault) {
    return <Switch checked={checked} disabled title={t('offers.offerSwitchStatus.defaultIsNotPossible')} />
  }

  return (
    <Switch
      checked={checked}
      onCheckedChange={(checked) => {
        if (loading) return
        setChecked(checked)
        onChange()
      }}
    />
  )
}

interface OffersPageProps {
  isEvent?: boolean
}

export const OffersPage: React.FC<OffersPageProps> = ({ isEvent = false }) => {
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(25)

  const { productId } = useParams<{ productId: string }>()
  const history = useHistory()
  const { toast } = useToast()
  const { t } = useTranslation()

  const { data, isLoading, isFetching } = useGetOffersList({ productId, page, pageSize })
  const { mutate: updateOfferName } = usePatchOfferName()
  const { mutate: changeStatus } = usePostChangeStatus({ productId, page, pageSize })

  const copyOneClick = (offerId: string) => {
    if (!offerId) {
      toast({
        title: t('copyClipboard.error'),
        variant: 'destructive',
      })
      return
    }

    new CopyClipboard().execute(new MakeUpsellUrl().execute(offerId)).then(() => {
      toast({
        title: t('copyClipboard.successOneClick'),
        variant: 'default',
      })
    })
  }

  const handleSwitch = (offerId: string, actualStatus: OfferDtoStatusEnum) => {
    const status =
      actualStatus === OfferDtoStatusEnum.IS_SELLING ? OfferStepStatus.IS_DRAFT : OfferStepStatus.IS_SELLING
    changeStatus({ offerId, status })
  }

  const isRowsEmpty = isEmpty(data?.items)
  const canRenderEmptyState = !isLoading && isRowsEmpty

  return (
    <div className="flex flex-col gap-4 py-6">
      <div className="flex items-center justify-between">
        <h1 className="text-xl font-semibold h-9">{t('menu.offers')}</h1>

        <Link to={`/edit/${productId}/offers/create${isEvent ? '?event=true' : ''}`}>
          <Button size="sm" className="gap-2" data-testid="view-content-btn">
            <PlusCircle className="h-4 w-4" />
            {t('offers.create')}
          </Button>
        </Link>
      </div>

      {/* <p className="text-sm text-muted-foreground">{t('offers.count', { count: data?.total ?? 0 })}</p> */}

      <div className="bg-background rounded-lg border">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>{t('offers.table.name')}</TableHead>

              <TableHead>{t('offers.table.price')}</TableHead>

              <TableHead>{t('offers.table.checkout')}</TableHead>

              <TableHead></TableHead>

              <TableHead></TableHead>
            </TableRow>
          </TableHeader>

          <TableBody>
            {isLoading
              ? [...Array(5)].map((_, i) => <TableRowLoading key={`skeleton-row-${i}`} />)
              : (data?.items ?? []).map((offer) => (
                  <TableRow key={offer.id}>
                    <TableCell className="max-w-md">
                      <div className="flex items-center gap-2">
                        {offer.status && (
                          <OffersSwitch
                            isDefault={offer.isDefault}
                            status={offer.status}
                            loading={isFetching}
                            onChange={() => {
                              handleSwitch(offer.id || '', offer.status)
                              offer.status =
                                offer.status === OfferDtoStatusEnum.IS_SELLING
                                  ? OfferDtoStatusEnum.IS_DRAFT
                                  : OfferDtoStatusEnum.IS_SELLING
                            }}
                          />
                        )}

                        <div className="w-full flex flex-col">
                          <EditableText
                            value={offer.name}
                            onSubmit={(value) => {
                              if (!offer?.id) return

                              updateOfferName({
                                productId,
                                offerId: offer.id,
                                name: value,
                              })
                            }}
                          />

                          <span className="text-xs ml-1 text-muted-foreground">{offer?.id}</span>
                        </div>
                      </div>
                    </TableCell>

                    <TableCell>
                      {offer.priceType === OfferDtoPriceTypeEnum.ONE_TIME ? (
                        <span>{new FormatCurrencyBRL(offer.price ?? 0).getFormattedCurrency()}</span>
                      ) : (
                        <PlansTag plans={offer.plans ?? []} />
                      )}
                    </TableCell>

                    <TableCell>
                      <CopyClipboardButton
                        copyContent={`${Envs.PAY_URL}/${offer.id}`}
                        label={`${Envs.PAY_URL}/${offer.id}`}
                        tooltipCopiedLabel={t('copyClipboard.offerLinkCopied')}
                        tooltipCopyLabel={t('copyClipboard.copyOfferLink')}
                      />
                    </TableCell>

                    <TableCell>
                      {offer.isDefault && (
                        <Badge variant="secondary" className="font-medium rounded-full">
                          {t('offers.table.isDefault')}
                        </Badge>
                      )}
                    </TableCell>

                    <TableCell>
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <Button variant="ghost" className="p-0 h-8 w-8">
                            <MoreHorizontal className="h-4 w-4" />
                          </Button>
                        </DropdownMenuTrigger>

                        <DropdownMenuContent align="end" className="w-max">
                          <DropdownMenuItem
                            onClick={() => history.push(`/edit/${productId}/offers/${offer.id}`)}
                            className="w-full flex gap-2 justify-start"
                          >
                            <Edit className="h-4 w-4 block" />
                            {t('offers.options.edit')}
                          </DropdownMenuItem>

                          <DropdownMenuItem
                            onClick={() => copyOneClick(offer.id || '')}
                            className="w-full flex gap-2 justify-start"
                          >
                            <Copy className="h-4 w-4 block" />
                            {t('offers.options.copyOneClick')}
                          </DropdownMenuItem>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>

        {canRenderEmptyState && <TableEmptyState title={t('offers.emptyState')} />}
      </div>

      {!isRowsEmpty && (
        <TablePagination
          page={page}
          setPage={setPage}
          lastPage={data?.lastPage ?? 1}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      )}
    </div>
  )
}
