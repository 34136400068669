import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { SignInByPhoneNumberInput } from '../domain/dtos/sign-in-by-phone-number-input'
import { SignInByPhoneNumberOutput } from '../domain/dtos/sign-in-by-phone-number-output'
import { ErrorCode } from '../domain/enums/error-code'
import { BlockedUserAuthAttemptError } from '../domain/errors/blocked-user-auth-attempt-error'
import { GeneralError } from '../domain/errors/general'
import { InvalidCaptchaError } from '../domain/errors/invalid-captcha-error'
import { SignInPhoneNotfoundError } from '../domain/errors/sign-in-phone-not-found-error'
import { UserDisabledError } from '../domain/errors/user-disabled-error'
import { VerifyCodeInvalidError } from '../domain/errors/verify-code-invalid-error'
import { Envs } from '../envs'

export class PostSignInByPhoneNumber {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(input: SignInByPhoneNumberInput): Promise<SignInByPhoneNumberOutput | undefined> {
    if (!input?.captcha) {
      throw new InvalidCaptchaError()
    }

    const response = await this.httpClient.request({
      method: HttpMethod.POST,
      url: `${Envs.BFF_WEB_URL}/auth/signin/phone-number`,
      body: input,
    })

    if (response.statusCode === HttpStatusCode.CREATED || response.statusCode === HttpStatusCode.OK) {
      return response.data
    }

    switch (response.data.code) {
      case ErrorCode.BLOCKED_USER_AUTH_ATTEMPT:
        throw new BlockedUserAuthAttemptError()
      case ErrorCode.VERIFY_CODE_INVALID:
        throw new VerifyCodeInvalidError()
      case ErrorCode.INVALID_CAPTCHA:
        throw new InvalidCaptchaError()
      case ErrorCode.USER_NOT_FOUND:
        throw new SignInPhoneNotfoundError()
      case ErrorCode.USER_DISABLED:
        throw new UserDisabledError()
      default:
        throw new GeneralError('signIn')
    }
  }
}
