import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation } from '@tanstack/react-query'
import { SaveAssetInput } from '../../domain/dtos'
import { SaveAsset } from '../../usecases/save-asset'

export const useSaveAsset = () => {
  const container = useContainer()

  return useMutation([], ({ payload, onUploadProgress }: SaveAssetInput) => {
    return new SaveAsset(container.get(HttpClient), onUploadProgress).execute(payload)
  })
}
