import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation } from '@tanstack/react-query'
import { DownloadXlsxInput } from '../../domain/dtos/download-xlsx-input'
import { QueryKey } from '../../domain/enums/query-keys'
import { DownloadXlsx } from '../../usecases/download-xlsx'

export const useDownloadXlsx = (input: DownloadXlsxInput) => {
  const container = useContainer()

  return useMutation([QueryKey.downloadXlsx], () => new DownloadXlsx(container.get(HttpClient)).execute(input), {
    retry: false,
  })
}
