import { Lock as LockIcon } from 'lucide-react'

type LockProps = {
  fullSize?: boolean
}

const Lock = ({ fullSize = false }: LockProps) => {
  return (
    <div
      className={`
        absolute flex justify-center items-center rounded p-1
        ${fullSize ? 'left-0 top-0 w-full h-full' : 'left-2 top-2 max-w-10 max-h-9'}
        bg-background/50 backdrop-blur-sm
      `}
    >
      <LockIcon className="text-foreground" />
    </div>
  )
}

export default Lock
