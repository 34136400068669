import { AssetType } from '@hub-la/fe-asset'
import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { SectionList, SectionResponse } from '@hub-la/fe-post'
import { inject } from 'inversify'
import { Envs } from '../envs'

export class ListSections {
  public constructor(@inject(HttpClient) private readonly httpClient: HttpClient) {}

  public async execute(productId: string | undefined): Promise<SectionList> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .request({
          /** @todo: Infinite scroll pagination */
          url: `${Envs.BFF_MEMBERS_AREA_URL}/hub/sections/v2?productId=${productId}&page=1&pageSize=999&postPageSize=999`,
          method: HttpMethod.GET,
        })
        .then(({ statusCode, data }) => {
          if (![HttpStatusCode.NO_CONTENT, HttpStatusCode.OK].includes(statusCode)) {
            return reject(`Ocorreu um erro ao listar as seções`)
          }

          data.items.map((section: SectionResponse) => {
            section.posts.map((post) => {
              if (post.cover) {
                post.isEmptyCover = false
                post.isImage = post.cover.type === AssetType.image ?? false
                post.isVideo = post.cover.type === AssetType.video ?? false
                post.isFile = post.cover.type === AssetType.application ?? false
              } else {
                post.isEmptyCover = true
              }
              return post
            })
            return section
          })

          return resolve(data)
        })
    })
  }
}
