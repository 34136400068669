import * as Yup from 'yup'

export const connectIntegrationSchema = Yup.object().shape({
  url: Yup.string().required('errors.validation.url.required'),
  apiKey: Yup.string().required('errors.validation.apiKey.required'),
  secretToken: Yup.string().required('errors.validation.secretToken.required'),
})

export const saveRuleSchema = Yup.object().shape({
  products: Yup.array().min(1),
  offers: Yup.array().min(1),
  events: Yup.array().min(1),
})

export const ruleSchema = ['products', 'offers', 'events']
export const connectSchema = ['url', 'apiKey', 'secretToken']
