import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'

import { useQuery } from '@tanstack/react-query'
import { ListCohortsInput } from '../../domain/dtos/list-cohorts.dto'
import { QueryKey } from '../../domain/enums/query-key.enum'
import { ListCohorts } from '../../usecases/list-cohorts.usecase'

export const useListCohorts = (input?: ListCohortsInput) => {
  const container = useContainer()

  return useQuery(
    [QueryKey.getListCohorts],
    ({ signal }) => new ListCohorts(container.get(HttpClient)).execute(input, signal),
    {
      refetchOnWindowFocus: false,
      retry: 3,
    },
  )
}
