import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '../../domain/enums/query-key'
import { GetAffiliates } from '../../usecases/get-affiliates'

export const useGetAffiliates = ({ page, pageSize }: { page: number; pageSize: number }) => {
  const container = useContainer()

  return useQuery(
    [QueryKey.getAffiliates, page, pageSize],
    ({ signal }) => new GetAffiliates(container.get(HttpClient)).execute({ page, pageSize }, signal),
    {
      retry: 3,
      retryOnMount: false,
      refetchOnWindowFocus: false,
      initialData: {
        page: 1,
        pageSize: 0,
        items: [],
        total: 0,
        lastPage: 0,
      },
    },
  )
}
