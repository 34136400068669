import {
  Button,
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
  Label,
  Separator,
} from '@hub-la/shadcn'
import { Calendar, CalendarX, RepeatIcon } from 'lucide-react'
import moment from 'moment'
import 'moment/locale/pt-br'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { SubscriptionStatus } from '../../../../domain/enums/subscription-status'
import { SubscriptionType } from '../../../../domain/enums/subscription-type'
import { SubscriptionDateBuilder } from '../../../../usecases/subscription-date-builder'
import avatar from '../../../assets/placeholder-image.svg'
import { useEnableAutorenew } from '../../../hooks/use-enable-autorenew'
import { SubscriptionStatusStats } from './subscription-status-stats'

type Props = {
  product: {
    picture?: string
    name: string
    id: string
  }
  subscription: {
    id: string
    status: SubscriptionStatus
    type: SubscriptionType
    createdAt: string
    nextDueAt: string
    canceledAt: string
    inactivatedAt: string
    credits: number
  }
}

export const Header: React.FC<Props> = ({ product, subscription }) => {
  const { mutateAsync: enableAutorenew, isLoading } = useEnableAutorenew()
  const { t } = useTranslation()

  const getIcon = (status: SubscriptionStatus) => {
    switch (status) {
      case SubscriptionStatus.ACTIVE:
        return <RepeatIcon className="text-muted-foreground w-4 h-4" />
      case SubscriptionStatus.CANCELED:
        return <CalendarX className="text-muted-foreground w-4 h-4" />
      default:
        return <Calendar className="text-muted-foreground w-4 h-4" />
    }
  }

  const dateBuilder = new SubscriptionDateBuilder(
    subscription.status,
    subscription.type,
    subscription.nextDueAt,
    moment(new Date()).add(subscription.credits, 'day').toISOString(),
    subscription.inactivatedAt,
  )

  return (
    <Card>
      <CardHeader>
        <img
          src={product.picture ?? avatar}
          alt={product.name}
          className="w-[90px] h-auto aspect-[218/142] rounded-lg object-cover border"
        />
        <CardTitle>
          <div className="flex items-center gap-2">
            <h4 className="text-xl font-semibold">{product.name}</h4>
            <SubscriptionStatusStats status={subscription.status} />
          </div>
        </CardTitle>
        <CardDescription>{product.id}</CardDescription>
      </CardHeader>
      <Separator />
      <CardContent className="p-6">
        <div className="flex">
          <div className="flex flex-col gap-2 w-full">
            <Label>Data de inicio</Label>
            <div className="flex items-center gap-2 text-muted-foreground">
              <Calendar className="w-4 h-4" />
              <p>{moment(subscription.createdAt).format('D MMM. YYYY')}</p>
            </div>
          </div>
          {subscription.credits < 3650 && (
            <div className="flex flex-col gap-2 w-full">
              <Label>{t(`header.date.${dateBuilder.getI18nKey()}`)}</Label>
              <div className="flex items-center gap-2 text-muted-foreground">
                {getIcon(subscription.status)}
                <p>{dateBuilder.execute()}</p>
              </div>
            </div>
          )}
        </div>
      </CardContent>
      {subscription.status === SubscriptionStatus.CANCELED && (
        <>
          <Separator />
          <CardFooter>
            <div className="flex flex-col mt-4 gap-2">
              <Button
                loading={isLoading}
                className="w-full"
                onClick={() => enableAutorenew(subscription.id)}
                disabled={isLoading}
              >
                {t('actions.reactivate')}
              </Button>
              <p className="text-sm text-muted-foreground text-center">{t('actions.reactivateFooter')}</p>
            </div>
          </CardFooter>
        </>
      )}
    </Card>
  )
}
