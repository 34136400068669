import { Check, CircleDashed, X } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { CouponStatus } from '../../domain/enums/coupon-status'

type CouponStatusStatsProps = {
  status: CouponStatus
}

const getStyles = (color: string) => {
  return { size: 16, className: `mr-1 text-${color}-700`, fill: color, style: { fillOpacity: 0.1 } }
}

export const CouponStatusStats = ({ status }: CouponStatusStatsProps) => {
  const { t } = useTranslation()

  const getIcon = (status: CouponStatus) => {
    switch (status) {
      case CouponStatus.ACTIVE:
        return (
          <div className="bg-green-600  rounded-full w-4 h-4 flex justify-center items-center">
            <Check className="text-primary-foreground w-3 h-3" strokeWidth={3} />
          </div>
        )
      case CouponStatus.EXPIRED:
        return (
          <div className="bg-red-700 rounded-full w-4 h-4 flex justify-center items-center">
            <X className="text-primary-foreground w-3 h-3" strokeWidth={3} />
          </div>
        )
      case CouponStatus.INACTIVE:
        return <CircleDashed className="w-4 h-4 text-ring" />
      default:
        return (
          <div className="bg-green-600  rounded-full w-4 h-4 flex justify-center items-center">
            <Check className="text-primary-foreground w-3 h-3" strokeWidth={3} />
          </div>
        )
    }
  }

  return (
    <div className="flex items-center gap-1.5">
      {getIcon(status)}
      {t(`status.${status}`)}
    </div>
  )
}
