import { Alert, AlertDescription, AlertTitle, Button, Card, CardContent, Input } from '@hub-la/shadcn'
import { useFormikContext } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { EditCollaboratorValues } from '../../domain/dtos/edit-collaborator-values'
import { PermissionResource } from '../../domain/dtos/permissions-resources-enum'
import { PermissionField, PermissionFieldProps } from './permission-field'

const availablePermissions: PermissionFieldProps[] = [
  {
    name: 'permissions.home',
    value: PermissionResource.HOME,
    subpermissions: [
      {
        name: 'permissions.home_abandoned_checkout',
        value: PermissionResource.HOME_ABANDONED_CHECKOUT,
      },
    ],
  },
  {
    name: 'permissions.sales',
    value: PermissionResource.SALES,
  },
  {
    name: 'permissions.products',
    value: PermissionResource.PRODUCTS,
    subpermissions: [
      {
        name: 'permissions.products_manage_co_production',
        value: PermissionResource.PRODUCTS_MANAGE_CO_PRODUCTION,
      },
      {
        name: 'permissions.products_manage_members',
        value: PermissionResource.PRODUCTS_MANAGE_MEMBERS,
      },
      {
        name: 'permissions.products_manage_post_comments',
        value: PermissionResource.PRODUCTS_MANAGE_POST_COMMENTS,
      },
    ],
  },
  {
    name: 'permissions.subscriptions',
    value: PermissionResource.SUBSCRIPTIONS,
    subpermissions: [
      {
        name: 'permissions.subscriptions_deactivate',
        value: PermissionResource.SUBSCRIPTIONS_DEACTIVATE,
      },
      {
        name: 'permissions.subscriptions_add_daily_credits',
        value: PermissionResource.SUBSCRIPTIONS_ADD_DAILY_CREDITS,
      },
    ],
  },
  {
    name: 'permissions.reports',
    value: PermissionResource.REPORTS,
  },
  {
    name: 'permissions.clients',
    value: PermissionResource.CLIENTS,
  },
  {
    name: 'permissions.affiliates',
    value: PermissionResource.AFFILIATES,
  },
  {
    name: 'permissions.coupons',
    value: PermissionResource.COUPONS,
  },
  {
    name: 'permissions.refunds',
    value: PermissionResource.REFUNDS,
  },
  {
    name: 'permissions.financial',
    value: PermissionResource.WALLET,
  },
  {
    name: 'permissions.integrations',
    value: PermissionResource.INTEGRATIONS,
  },
]

interface CollaboratorFormProps {
  loading?: boolean
  isEdit?: boolean
  error?: Error | null
}

export const CollaboratorForm: React.FC<CollaboratorFormProps> = ({
  loading = false,
  isEdit = false,
  error = null,
}) => {
  const { t } = useTranslation()
  const { handleChange, handleBlur, values, errors, submitForm } = useFormikContext<EditCollaboratorValues>()

  return (
    <div className="space-y-8">
      <div className="space-y-4">
        {isEdit && (
          <div className="space-y-2">
            <label htmlFor="name" className="text-sm font-medium">
              {t('collaboratorForm.nameLabel')}
            </label>
            <Input
              id="name"
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              disabled={isEdit}
              className={errors.name ? 'border-red-500' : ''}
            />
            {errors.name && <p className="text-sm text-red-500">{t(errors.name)}</p>}
          </div>
        )}
        <div className="space-y-2">
          <label htmlFor="email" className="text-sm font-medium">
            {t('collaboratorForm.emailLabel')}
          </label>
          <Input
            id="email"
            type="email"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            disabled={isEdit}
            className={error || errors.email ? 'border-red-500' : ''}
          />
          {(error || errors.email) && (
            <p className="text-sm text-red-500">{error ? t(error.message) : t(errors.email ?? '')}</p>
          )}
        </div>
      </div>

      <Card>
        <CardContent className="p-6">
          <h3 className="text-lg font-bold mb-2">{t('collaboratorForm.permissionsTitle')}</h3>
          <div className="space-y-4">
            {availablePermissions.map((permission) => (
              <PermissionField
                key={permission.value}
                name={t(permission.name)}
                description={t('permissionsDescription.' + permission?.value)}
                value={permission.value}
                subpermissions={permission?.subpermissions}
              />
            ))}
          </div>
        </CardContent>
      </Card>

      {error && (
        <Alert variant="destructive">
          <AlertTitle>Error</AlertTitle>
          <AlertDescription>{t(error.message)}</AlertDescription>
        </Alert>
      )}

      <div className="flex justify-end">
        <Button onClick={submitForm} loading={loading}>
          {t(isEdit ? 'collaboratorForm.updateButton' : 'collaboratorForm.submitButton')}
        </Button>
      </div>
    </div>
  )
}
