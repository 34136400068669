import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { PostResponse } from '@hub-la/fe-post'
import { inject } from 'inversify'
import { Envs } from '../envs'

export class GetPost {
  public constructor(@inject(HttpClient) private readonly httpClient: HttpClient) {}

  public async execute(postId: string | null): Promise<PostResponse> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .request({
          url: `${Envs.BFF_MEMBERS_AREA_URL}/hub/posts/${postId}`,
          method: HttpMethod.GET,
          headers: {
            accept: 'application/json',
          },
        })
        .then(({ data, statusCode }) => {
          if (![HttpStatusCode.NO_CONTENT, HttpStatusCode.OK].includes(statusCode)) {
            return reject(`Ocorreu um erro para exibir o conteúdo`)
          }

          return resolve(data)
        })
    })
  }
}
