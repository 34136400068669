import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { InfiniteData, useMutation, useQueryClient } from '@tanstack/react-query'
import { GetCommentsResponse } from '../../domain/dtos/get-comments-response'
import { PutCommentInput } from '../../domain/dtos/put-comment-input'
import { QueryKey } from '../../domain/enums/query-keys'
import { PutComment } from '../../usecases/put-comment'
import { useDiscussion } from '../providers/discussion'

export const usePutComment = ({
  onSuccess,
  isReply,
  parentCommentId,
}: {
  onSuccess?: () => void
  isReply?: boolean
  parentCommentId?: string
}) => {
  const container = useContainer()
  const queryClient = useQueryClient()

  const discussion = useDiscussion()

  return useMutation(
    [QueryKey.putComment],
    (input: PutCommentInput) =>
      new PutComment(container.get(HttpClient)).execute({ ...input, postId: discussion.metadata.postId }),
    {
      retry: false,
      onSuccess: (_, input) => {
        if (onSuccess) {
          onSuccess()
        }

        if (isReply) {
          queryClient.setQueryData<InfiniteData<GetCommentsResponse>>(
            [QueryKey.getCommentReplies, parentCommentId],
            (commentsRepliesData): InfiniteData<GetCommentsResponse> => {
              if (!commentsRepliesData) {
                return {
                  pages: [],
                  pageParams: [],
                }
              }

              commentsRepliesData.pages = commentsRepliesData?.pages?.map((page) => {
                const oldItems = page.items ?? []
                const updatedItems = oldItems.map((comment) => {
                  if (comment.id === input.commentId) {
                    return {
                      ...comment,
                      message: input.message,
                      wasEdited: true,
                      updatedAt: new Date().toISOString(),
                    }
                  }

                  return comment
                })

                return {
                  ...page,
                  items: updatedItems,
                }
              })

              return commentsRepliesData
            },
          )
          return
        }

        queryClient.setQueryData<InfiniteData<GetCommentsResponse>>(
          [QueryKey.getComments, discussion?.metadata?.postId],
          (commentsData): InfiniteData<GetCommentsResponse> => {
            if (!commentsData) {
              return {
                pages: [],
                pageParams: [],
              }
            }

            commentsData.pages = commentsData?.pages.map((page) => {
              const oldItems = page.items ?? []
              const updatedItems = oldItems?.map((comment) => {
                if (comment.id === input.commentId) {
                  return {
                    ...comment,
                    message: input.message,
                  }
                }

                return comment
              })

              return {
                ...page,
                items: updatedItems,
              }
            })

            return commentsData
          },
        )
      },
    },
  )
}
