import React from 'react'
import { useTranslation } from 'react-i18next'
import { useReactivateRefundRequest } from '../../../hooks/use-reactivate-refund-request'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Button,
} from "@hub-la/shadcn"

type ConfirmReactivateRefundRequestModalProps = {
  refundId: string
  open: boolean
  onClose: () => void
  onSuccess?: () => void
}

export const ConfirmReactivateRefundRequestModal = ({
  refundId,
  open,
  onClose,
  onSuccess
}: ConfirmReactivateRefundRequestModalProps) => {
  const { t } = useTranslation()
  const { mutateAsync: reactivateRefundRequest, isLoading } = useReactivateRefundRequest()

  const onSubmit = () => {
    reactivateRefundRequest({ refundId })
      .then(() => {
        onClose()
        onSuccess?.()
      })
  }

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[525px]">
        <DialogHeader>
          <DialogTitle className="text-2xl font-semibold">
            {t('userRefund.modals.confirmReactivate.title')}
          </DialogTitle>
          <DialogDescription className="text-sm">
            {t('userRefund.modals.confirmReactivate.description')}
          </DialogDescription>
        </DialogHeader>
        <DialogFooter className="sm:justify-end">
          <div className="flex flex-col-reverse sm:flex-row gap-2 sm:gap-4 w-full sm:w-auto">
            <Button variant="outline" onClick={onClose}>
              {t('userRefund.buttons.cancel')}
            </Button>
            <Button
              variant="default"
              onClick={onSubmit}
              disabled={isLoading}
            >
              {t('userRefund.buttons.reactivateRefund')}
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
