import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { GetInvoicesSummariesInput } from '../domain/dtos/get-invoices-summaries-input'
import { GetInvoicesSummariesOutput } from '../domain/dtos/get-invoices-summaries-output'
import { GeneralError } from '../domain/errors/general'
import { Envs } from '../envs'
import { GetOfferIds } from './get-offer-ids'

export class GetInvoicesSummaries {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(input: GetInvoicesSummariesInput, signal?: AbortSignal): Promise<GetInvoicesSummariesOutput> {
    const response = await this.httpClient.request({
      method: HttpMethod.POST,
      url: `${Envs.BFF_WEB_URL}/invoices/summaries`,
      body: this.makeBody(input),
      signal,
    })

    if (response.statusCode === HttpStatusCode.OK || response.statusCode === HttpStatusCode.CREATED) {
      return response.data
    }

    throw new GeneralError()
  }

  private makeBody(input: GetInvoicesSummariesInput) {
    const productIds = new GetOfferIds().execute({ offerIds: input.offerIds, offers: input.offers })
    const body = {
      offerIds: productIds,
      filters: {
        startDate: input.startDate,
        endDate: input.endDate,
        status: input.statuses,
        type: input.types,
        paymentMethod: input.methods,
        search: input.search,
        utmSource: input.utmSource,
        utmMedium: input.utmMedium,
        utmCampaign: input.utmCampaign,
        utmContent: input.utmContent,
        utmTerm: input.utmTerm,
      },
      page: input.page,
      pageSize: input.pageSize,
      orderBy: input.orderBy,
      orderDirection: input.orderDirection,
    }

    return body
  }
}
