import { UpdateProductSettingsPageRequestDto } from '@hub-la/sdk-bff-product'
import { Button, Input } from '@hub-la/shadcn'
import { useFormik } from 'formik'
import get from 'lodash/get'
import { Plus, Trash2 } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { Section } from '../../../components/section'
import { useSaveSettings } from '../../../hooks/settings/use-save-settings'
import { InputEmoji } from '../components/input-emoji'

type Values = Pick<UpdateProductSettingsPageRequestDto, 'advantages'>
const validationSchema = Yup.object().shape({
  advantages: Yup.array()
    .nullable()
    .of(
      Yup.object().shape({
        emoji: Yup.string(),
        text: Yup.string().min(5, 'Texto muito curto').max(50, 'Texto muito longo').required('Texto é obrigatório'),
      }),
    )
    .max(6, 'O máximo de itens é 6'),
})
const makeInitialValues = ({ advantages }: Values): Values => {
  return {
    advantages: advantages ?? [{ emoji: '🚀', text: '' }],
  }
}

type Props = {
  advantages?: Values['advantages']
  loading?: boolean
}

export const AdvantagesSection: React.FC<Props> = ({ advantages, loading }) => {
  const { productId } = useParams<{ productId: string }>()
  const { mutateAsync: saveSettings } = useSaveSettings()
  const { t } = useTranslation()

  const formik = useFormik<Values>({
    validationSchema,
    validateOnMount: true,
    validateOnChange: true,
    enableReinitialize: true,
    initialValues: makeInitialValues({ advantages }),
    onSubmit: async (values) => {
      await saveSettings({ productId, type: 'page', data: values })
    },
  })

  return (
    <Section
      title={t('settings.productPage.advantages.title')}
      subtitle={t('settings.productPage.advantages.subtitle')}
      formik={formik}
    >
      <div className="space-y-4 w-full">
        {(formik.values.advantages ?? []).map((advantage, idx) => (
          <div key={idx} className="flex items-center gap-4">
            <InputEmoji
              onChange={(value) => formik.setFieldValue(`advantages[${idx}].emoji`, value)}
              value={advantage.emoji}
            />

            <div className="flex-grow">
              <Input
                placeholder={t('settings.productPage.advantages.input.subtitle')}
                name={`advantages[${idx}].text`}
                id={`advantages[${idx}].text`}
                value={advantage.text}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                maxLength={50}
                disabled={loading}
              />

              {get(formik.touched, `advantages[${idx}].text`) && get(formik.errors, `advantages[${idx}].text`) && (
                <p className="text-sm text-destructive mt-1">{t(get(formik.errors, `advantages[${idx}].text`))}</p>
              )}
            </div>

            <Button
              variant="ghost"
              size="icon"
              onClick={() =>
                formik.setFieldValue(
                  'advantages',
                  formik.values.advantages?.filter((_, index) => index !== idx),
                )
              }
            >
              <Trash2 className="h-4 w-4" />
            </Button>
          </div>
        ))}

        {(formik.values.advantages?.length ?? 0) < 6 && (
          <Button
            variant="outline"
            size="sm"
            onClick={() =>
              formik.setFieldValue('advantages', [...(formik.values.advantages ?? []), { emoji: '🚀', text: '' }])
            }
            loading={loading}
          >
            <Plus className="mr-2 h-4 w-4" />
            {t('settings.productPage.advantages.input.add')}
          </Button>
        )}
      </div>
    </Section>
  )
}
