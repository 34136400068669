import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation } from '@tanstack/react-query'
import { PutRecoverPasswordInput } from '../../domain/dtos/put-recover-password-input'
import { QueryKey } from '../../domain/dtos/query-keys'
import { PutRecoverPassword } from '../../usecases/put-recover-password'

export const usePutRecoverPassword = () => {
  const container = useContainer()

  return useMutation(
    [QueryKey.putRecoverPassword],
    (input: PutRecoverPasswordInput) => new PutRecoverPassword(container.get(HttpClient)).execute(input),
    {
      retry: false,
    },
  )
}
