import { Badge, cn } from '@hub-la/shadcn'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { WaitlistStatus } from '../../../../domain/enums/waitlist-status'

type Props = {
  status: WaitlistStatus
}

export const WaitlistStatusStats: React.FC<Props> = ({ status }) => {
  const { t } = useTranslation()

  const getVariantStyles = (): string => {
    switch (status) {
      case WaitlistStatus.convertedMember:
        return 'bg-green-100 text-green-800 border-green-300'
      case WaitlistStatus.notMember:
        return 'bg-yellow-100 text-yellow-800 border-yellow-300'
      default:
        return 'bg-green-100 text-green-800 border-green-300'
    }
  }

  return (
    <Badge variant="outline" className={cn('font-medium', getVariantStyles())}>
      {t(`waitlist.waitlistStatus.${status}`)}
    </Badge>
  )
}
