import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { GetInvoiceDetailResponseDto } from '../../../../domain/dtos/get-invoice-details-request.dto'
import { Money } from '../../../../domain/vos/money'

import { ScrollArea } from '@hub-la/shadcn'
import { ContentRow } from '../../content-row/content-row'
import { DottedDivider } from '../../dotted-divider/dotted-divider'
import { MovementsByInvoiceId } from '../../movements-by-invoice-id/movements-by-invoice-id'
import { StatusTag } from '../../status-tag/status-tag'
import { ContentSectionTitle } from './content-section-title/content-section-title'

interface CreatorContentProps {
  invoice: GetInvoiceDetailResponseDto
}

export const CreatorContent: React.FC<CreatorContentProps> = ({ invoice }) => {
  const { t } = useTranslation()

  const {
    totalValueCents,
    status,
    payerName,
    payerEmail,
    payerPhoneNumber,
    createdAt,
    paidAt,
    paymentMethod,
    recurrence,
    couponDiscountCents,
    couponName,
    affiliateCommission,
  } = invoice

  return (
    <ScrollArea className="h-96 w-full">
      <div className="flex items-center gap-1 mb-2">
        <h3 className="text-2xl font-semibold">{Money.build(totalValueCents / 100).getValue()}</h3>
        <StatusTag status={status} />
      </div>
      <ContentRow label={t('invoiceDetails.product')} value={invoice.offers.map((offer) => offer.name).join(', ')} />

      {/* Customer Data */}
      <ContentSectionTitle title={t('invoiceDetails.customerLabel')} />
      <ContentRow label={t('invoiceDetails.customer.name')} value={payerName} />
      <ContentRow label={t('invoiceDetails.customer.email')} value={payerEmail} />
      <ContentRow label={t('invoiceDetails.customer.cellphone')} value={payerPhoneNumber} />

      {/* Invoice Data */}
      <ContentSectionTitle title={t('invoiceDetails.invoiceLabel')} />
      <ContentRow
        label={t('invoiceDetails.payment.createdAt')}
        value={moment(createdAt).format('D [de] MMM. [de] YYYY')}
      />
      {paidAt && (
        <ContentRow label={t('invoiceDetails.payment.paidAt')} value={moment(paidAt).format('D [de] MMM. [de] YYYY')} />
      )}
      <ContentRow label={t('invoiceDetails.payment.methodLabel')} value={t(`invoicePaymentMethod.${paymentMethod}`)} />
      {recurrence && <ContentRow label={t('invoiceDetails.payment.recurrence')} value={t(`recurrent.${recurrence}`)} />}

      {/* Payment Data */}
      <ContentSectionTitle title={t('invoiceDetails.paymentLabel')} />
      <ContentRow
        label={t('invoiceDetails.payment.hasMembershipFee')}
        value={t(`invoiceDetails.payment.hasMembershipFee${invoice.hasMembershipFee ? 'True' : 'False'}`)}
        secondaryText={true}
      />
      <ContentRow
        label={t('invoiceDetails.payment.totalValueCents')}
        value={Money.build(invoice.totalValueCents / 100).getValue()}
        secondaryText={true}
      />
      {affiliateCommission && affiliateCommission.value && (
        <ContentRow
          label={t('invoiceDetails.payment.method.affiliateCommission')}
          value={Money.build(-affiliateCommission.value / 100).getValue()}
          secondaryText={true}
        />
      )}
      {!!couponDiscountCents && (
        <>
          <ContentRow
            label={t('invoiceDetails.payment.couponDiscount')}
            value={Money.build(-couponDiscountCents / 100).getValue()}
          />
          {couponName && <ContentRow label={t('invoiceDetails.payment.couponName')} value={couponName} />}
        </>
      )}
      {invoice.otherFeesValueCents > 0 && (
        <ContentRow
          label={t('invoiceDetails.payment.otherFee')}
          value={Money.build(-invoice.otherFeesValueCents / 100).getValue()}
          secondaryText={true}
        />
      )}
      <ContentRow
        label={t('invoiceDetails.payment.companyFee')}
        value={Money.build(-invoice.hublaFeeValueCents / 100).getValue()}
        tooltip={t('invoiceDetails.payment.companyFeeTooltipText')}
        secondaryText={true}
      />
      <DottedDivider />
      <ContentRow
        label={t('invoiceDetails.payment.netValueCents')}
        value={Money.build(invoice.netValueCents / 100).getValue()}
        variant="body1"
      />

      {/* Product */}
      <ContentSectionTitle title={t('invoiceDetails.product')} />
      {invoice.offers.map((offer) => (
        <ContentRow
          key={`content-row-${offer.id}`}
          label={t('invoiceDetails.group')}
          value={offer.name}
          secondValue={offer.priceCents ? Money.build(offer.priceCents / 100).getValue() : undefined}
        />
      ))}

      {/* Movements */}
      <ContentSectionTitle title={t('invoiceDetails.movements')} />
      <MovementsByInvoiceId invoiceId={invoice.id} />
    </ScrollArea>
  )
}
