import isEmpty from 'lodash/isEmpty'

export class Email {
  public constructor(private readonly email: string) {}

  public static build(email: string): Email {
    return new Email(String(email).toLowerCase().replace(/\s+/g, ''))
  }

  public isValid(): boolean {
    if (isEmpty(this.email)) {
      return false
    }

    /** @description Regex extracted from http://www.w3.org/TR/html5/forms.html#valid-e-mail-address */
    const regex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

    return regex.test(this.email)
  }

  public getValue(): string {
    return this.email
  }
}
