import { useContainer } from '@hub-la/fe-container'
import { Auth } from '@hub-la/fe-core-auth'
import { createContext, useCallback, useEffect, useState } from 'react'
import useLocalStorage from 'use-local-storage'
import { PermissionResource } from '../../domain/dtos/permissions-resources-enum'
import { RoleplayPermissionContextValue } from '../../domain/dtos/roleplay-permission-context-value'
import { UpdatedTokenAttributes } from '../../usecases/updated-token-attributes'
import { useSigninRoleplay } from '../hooks/use-signin-roleplay'
import { useSignOutRoleplay } from '../hooks/use-signout-roleplay'

const RoleplayPermissionContext = createContext<RoleplayPermissionContextValue>({
  roleplayUserId: null,
  permissions: [],
  isRoleplay: false,
  signInLoading: false,
  signOutLoading: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  signIn: async () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  signOut: async () => {},
  hasPermission: () => false,
})

const RoleplayPermissionProvider = ({ children }) => {
  const container = useContainer()
  const auth = container.get(Auth)

  const { mutateAsync: postSignIn, isLoading: signInLoading } = useSigninRoleplay()
  const { mutateAsync: postSignOut, isLoading: signOutLoading } = useSignOutRoleplay()

  const [roleplayUserId, setRoleplayUserId] = useState<string | null>(null)
  const [permissions, setPermissions] = useState<PermissionResource[]>([])
  const [isRoleplay, setIsRoleplay] = useLocalStorage<boolean>('isRoleplay', false)

  useEffect(() => {
    if (roleplayUserId === null) return

    setIsRoleplay(!!roleplayUserId)
  }, [roleplayUserId, setIsRoleplay])

  const updateRoleplayAttributes = async () => {
    const tokenAttributes = await new UpdatedTokenAttributes(auth).execute()
    if (!tokenAttributes) {
      setPermissions([])
      setRoleplayUserId(null)
      setIsRoleplay(undefined)
      return
    }

    const { roleplayResources, roleplayUserId } = tokenAttributes

    setPermissions(roleplayResources)
    setRoleplayUserId(roleplayUserId)
  }

  const signIn = useCallback(
    async (id: string) => {
      const token = await postSignIn(id)
      await auth.signInWithToken(token)
      await updateRoleplayAttributes()
    },
    [auth, postSignIn],
  )

  const hasPermission = (permission: PermissionResource | PermissionResource[]) => {
    if (!isRoleplay) return true

    if (Array.isArray(permission)) {
      return permission.some((p) => permissions.includes(p))
    }

    return permissions.includes(permission)
  }

  const signOut = useCallback(async () => {
    const token = await postSignOut()
    await auth.signInWithToken(token)
    await updateRoleplayAttributes()
  }, [auth, postSignOut])

  useEffect(() => {
    updateRoleplayAttributes()
    const unsubscribe = auth.listen(() => {
      updateRoleplayAttributes()
    })
    return () => {
      unsubscribe()
    }
  }, [])

  return (
    <RoleplayPermissionContext.Provider
      value={{
        roleplayUserId,
        permissions,
        isRoleplay: !!isRoleplay,
        signIn,
        signOut,
        hasPermission,
        signInLoading,
        signOutLoading,
      }}
    >
      {children}
    </RoleplayPermissionContext.Provider>
  )
}

export { RoleplayPermissionContext, RoleplayPermissionProvider }
