import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useToast } from '@hub-la/shadcn'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { QueryKey } from '../../../domain/enums/query-key'
import { GetExternalMemberArea } from '../../../usecases/member-area/get-external-member-area'

export const useGetExternalMemberArea = ({ productId }: { productId: string }) => {
  const { toast } = useToast()
  const { t } = useTranslation()
  const container = useContainer()

  return useQuery(
    [QueryKey.getExternalMemberArea, productId],
    () => new GetExternalMemberArea(container.get(HttpClient)).execute({ productId }),
    {
      enabled: !!productId,
      retry: 3,
      retryOnMount: false,
      refetchOnWindowFocus: false,
      onError: (error: Error) => toast({ description: t(error.message), variant: 'destructive' }),
    },
  )
}
