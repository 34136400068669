import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { PostTransformFreeMembersInput } from '../../domain/dtos/post-transform-free-members-input'
import { StatusTransformFreeMemberOutput } from '../../domain/dtos/post-transform-free-members-output'
import { PostTransformFreeMembersResponse } from '../../domain/dtos/post-transform-free-members-response'
import { GeneralError } from '../../domain/errors/general'
import { Envs } from '../../envs'

export class PostTransformFreeMembers {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient<PostTransformFreeMembersResponse>,
  ) {}

  public async execute(
    input: PostTransformFreeMembersInput,
    signal?: AbortSignal,
  ): Promise<StatusTransformFreeMemberOutput[]> {
    const response = await this.httpClient.request({
      method: HttpMethod.POST,
      url: `${Envs.BFF_MEMBERS_AREA_URL}/members/free-subscription-old-members`,
      signal,
      body: this.makeBody(input),
    })

    await this.sleep((input?.selectedItems?.length ?? 1) * 500) // waiting payments backend queue to update list

    if (response.statusCode === HttpStatusCode.OK || response.statusCode === HttpStatusCode.CREATED) {
      return this.makeOutput(input, response.data)
    }

    throw new GeneralError()
  }

  private makeBody(input: PostTransformFreeMembersInput) {
    const body = {
      productId: input.productId,
      userIds: input.userIds.map((id) => id),
      days: input.days,
      lifetime: input.lifetime,
    }

    return body
  }

  private makeOutput(
    input: PostTransformFreeMembersInput,
    data: PostTransformFreeMembersResponse | undefined,
  ): StatusTransformFreeMemberOutput[] | [] {
    if (!data?.status) {
      return []
    }

    return data.status.map((member) => {
      const selectedMember = input.selectedItems?.find((selectedMember) => selectedMember.id === member.userId)

      return {
        ...member,
        name: selectedMember?.name ?? '',
      }
    })
  }

  private sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }
}
