export enum SmartInstallmentStatus {
  STATUS_DRAFT = 'draft',
  STATUS_UNPAID = 'pending',
  STATUS_PAID = 'paid',
  STATUS_EXPIRED = 'expired',
  STATUS_RETURNED = 'refunded',
  STATUS_DISPUTED = 'in_protest',
  STATUS_CHARGEBACK = 'chargeback',
  STATUS_CANCELED = 'canceled', // only smart installment
  STATUS_PROCESSING = 'processing'
}

