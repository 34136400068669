/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AnalyticsProperties, AnalyticsProvider, UserIdentifyInput } from '@hub-la/fe-core-analytics'
import Cookies from 'js-cookie'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import platformDetect from 'platform-detect'
import tldjs from 'tldjs'
import { v4 } from 'uuid'
import { Envs } from './envs'

export class RudderStackAnalytics extends AnalyticsProvider {
  private readonly CACHE_COOKIE_NAME: string = 'anonIdRudder'

  public constructor(
    private readonly writeKey: string,
    private readonly dataPlaneUrl: string,
    private readonly isDebug: boolean,
  ) {
    super()

    !this.isDebug && this.rudderstack().then((rudderstack) => rudderstack.load(this.writeKey, this.dataPlaneUrl))
  }

  public identify(user: UserIdentifyInput): void {
    !this.isDebug &&
      this.rudderstack().then((rudderstack) => {
        const anonId = rudderstack.getAnonymousId()
        const isUUIDv4 = anonId?.indexOf('-') !== -1

        if (user) {
          this.validateIfOtherUserHasSameAnonymousId(anonId, user.id)
          if (!isUUIDv4 && user.id !== anonId) {
            this.reset()
          }
          rudderstack.identify(user.id, this.makeIdentifyTraitsByUser(user))
          this.addToCache(anonId, user.id)
        }
      })
  }

  public async track(event: string, properties?: Record<string, unknown>): Promise<void> {
    const rudderstack = await this.rudderstack()
    this.isDebug
      ? console.log(event, this.getDefaultProperties(properties))
      : rudderstack.track(event, this.getDefaultProperties(properties))
  }

  public async page(page: string, properties?: Record<string, unknown>): Promise<void> {
    const rudderstack = await this.rudderstack()

    if (!isEmpty(page)) {
      this.log('page', page, this.getDefaultProperties(properties), rudderstack.getAnonymousId())
      const apiObject = this.removeNullProperties(properties)
      rudderstack.page(page, page, this.getDefaultProperties(apiObject))
    }
  }

  public reset() {
    this.rudderstack().then((rudderstack) => {
      this.log('reset')
      rudderstack?.reset(true)
      this.clearCache()
    })
  }

  private getDefaultProperties(properties?: Record<string, unknown>) {
    const deviceType = platformDetect.formfactor

    const deviceOS = this.getOS(platformDetect)
    const hbId = RudderStackAnalytics.getAnalyticsId()
    return this.removeNullProperties({ ...properties, deviceType, deviceOS, hbId })
  }

  public static getAnalyticsId(): string | undefined {
    try {
      const hbIdKey = 'hb_id'
      let hbId = Cookies.get(hbIdKey)
      if (!hbId) {
        hbId = v4()
        const domain = window.location.hostname === 'localhost' ? 'localhost' : tldjs.getDomain(window.location.href)
        Cookies.set(hbIdKey, hbId, {
          sameSite: 'lax',
          expires: 20 * 365, // Expires after 20 years
          domain: `.${domain}`,
        })
      }
      return hbId
    } catch (e) {
      return undefined
    }
  }

  private getOS = (os: any): string => {
    let result = 'unknown'
    if (os?.windows) result = 'windows'
    else if (os?.android) result = 'android'
    else if (os?.chromeos) result = 'chromeos'
    else if (os?.macos) result = 'macos'
    else if (os?.ios) result = 'ios'
    else if (os?.tizen) result = 'tizen'
    else if (os?.linux || os?.linuxBased) result = 'linux'
    return result
  }

  private removeNullProperties = (obj: any): AnalyticsProperties => {
    const ret: AnalyticsProperties = {}

    Object.keys(obj).forEach((key) => {
      const value = obj[key]

      if (Array.isArray(value) && (value.length === 0 || value.every((item) => item === '' || isNil(item)))) {
        delete obj[key]
        return
      }
      if (typeof value === 'string' && value.trim() === '') {
        delete obj[key]
        return
      }
      if (!Array.isArray(value) && value instanceof Object && Object.keys(value).length === 0) {
        delete obj[key]
        return
      }
      if (isNil(value)) {
        delete obj[key]
        return
      }
      if (Array.isArray(value)) {
        ret[key] = value.filter((item) => item !== '' && !isNil(item))
        return
      }

      ret[key] = value
    })

    return ret
  }

  private makeIdentifyTraitsByUser = (user: UserIdentifyInput): AnalyticsProperties => {
    return this.removeNullProperties({
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phoneNumber: user.phoneNumber,
    })
  }

  /**
   * Checks if on this device, another user logged in and identified with the same anonymous_id
   * If this happened, force changing the anonymous_id before triggering an identify
   *
   * This was created because internal Hubla users can log in via a link in the admin dashboard, directly into a creator's account, without going through the logout process.
   * This causes the same anonymous_id to be used by more than one user, which causes these users' profiles to be merged into MixPanel
   * The same anonymous_id should NEVER be used for more than one user_id
   * @param currentAnonymousId
   * @param user
   */
  private validateIfOtherUserHasSameAnonymousId(currentAnonymousId: string, userId: string): void {
    const anonIdUsage = this.getFromCache()
    if (anonIdUsage[currentAnonymousId] && anonIdUsage[currentAnonymousId] !== userId) {
      this.reset()
    } else {
      this.addToCache(currentAnonymousId, userId)
    }
  }

  private addToCache(distinctId: string, userId: string): void {
    this.log('addToCache', distinctId, userId)
    const anonIdUsage = this.getFromCache()
    anonIdUsage[distinctId] = userId
    Cookies.set(this.CACHE_COOKIE_NAME, JSON.stringify(anonIdUsage), {
      domain: this.getCookieDomain(),
      sameSite: 'lax',
      expires: 30,
    })
  }

  private getFromCache(): Record<string, unknown> {
    const str = Cookies.get(this.CACHE_COOKIE_NAME) || '{}'
    return JSON.parse(str)
  }

  private clearCache = (): void => {
    this.rudderstack().then((rudderstack) => {
      this.log('clearCache', rudderstack.getAnonymousId())
      Cookies.set(this.CACHE_COOKIE_NAME, '')
    })
  }

  private getCookieDomain(): string {
    return Envs.NX_COOKIE_DOMAIN
  }

  private log(message?: any, ...optionalParams: any[]): void {
    if (this.isDebug) console.log('[RudderStackAnalytics]', message, ...optionalParams)
  }

  private async rudderstack() {
    if (typeof window === 'undefined' || typeof window.navigator === 'undefined') {
      return {
        track: () => {},
        load: () => {},
        page: () => {},
        identify: () => {},
        getAnonymousId: () => '',
        reset: () => {},
      }
    }
    const rudderstack = await import('rudder-sdk-js')

    return rudderstack
  }
}
