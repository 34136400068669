import { Card, CardContent, CardHeader, CardTitle, Separator, Skeleton } from '@hub-la/shadcn'

export const HeaderLoading = () => {
  return (
    <Card>
      <CardHeader className="w-full flex-row flex-wrap gap-2 items-center justify-between">
        <CardTitle className="flex-1">
          <Skeleton className="h-8 w-1/3" />
        </CardTitle>

        <div className="flex flex-wrap gap-2">
          <Skeleton className="h-9 w-20" />
          <Skeleton className="h-9 w-24" />
        </div>
      </CardHeader>

      <CardContent className="flex flex-col gap-6">
        <div className="flex justify-between">
          <div className="flex flex-col gap-0.5">
            <div className="mb-1 flex gap-2 items-center">
              <Skeleton className="h-5 w-20" />
              <Skeleton className="h-5 w-24" />
            </div>

            <Skeleton className="h-4 w-40 mb-1" />
            <Skeleton className="h-8 w-32 mb-1" />
            <Skeleton className="h-4 w-48" />
          </div>

          <Skeleton className="h-12 w-12 rounded-full" />
        </div>

        <Separator />

        <div className="flex flex-col gap-4">
          <div>
            <Skeleton className="h-5 w-16 mb-1" />
            <Skeleton className="h-4 w-40" />
          </div>

          <div>
            <Skeleton className="h-5 w-16 mb-1" />
            <Skeleton className="h-4 w-40" />
          </div>
        </div>
      </CardContent>
    </Card>
  )
}
