import { Thumbnail } from '@hub-la/fe-post-thumbnail'
import {
  Button,
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  Label,
  RadioGroup,
  RadioGroupItem,
} from '@hub-la/shadcn'
import { useFormikContext } from 'formik'
import { isEmpty } from 'lodash'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { highlightTypes, Values } from '../../../../../domain/dtos/member-area/settings/formik-values'
import MemberAreaAsset from '../../../../assets/mockup-memberarea-asset.svg'
import MemberAreaPost from '../../../../assets/mockup-memberarea-post.svg'
import MemberAreaRecommendaton from '../../../../assets/mockup-memberarea-recommendation.svg'
import { useUploaderFieldsLoading } from '../../../../hooks/member-area/settings/uploader-fields-loading'
import CardUploader from './card-uploader'
import PostSelectModal from './post-select-modal'
import { RadioLoading } from './radio-loading'
const bannerCustomOptions = Object.values(highlightTypes)

interface CustomHighlightProps {
  isLoading?: boolean
}

const CustomHighlight = ({ isLoading }: CustomHighlightProps) => {
  const { values, setFieldValue, submitForm, errors, dirty, touched } = useFormikContext<Values>()
  const { highlightType } = values

  const { t } = useTranslation()

  const [selectPostModalOpen, setSelectPostModalOpen] = useState<boolean>(false)

  const hasError = !isEmpty(errors)
  const uploaderLoading = useUploaderFieldsLoading()

  const isSaveButtonDisabled = uploaderLoading || isLoading || hasError

  return (
    <>
      <PostSelectModal
        open={selectPostModalOpen}
        onSelect={(post) => {
          setSelectPostModalOpen(false)
          setFieldValue('post', post)
        }}
        onClose={() => setSelectPostModalOpen(false)}
      />

      <Card>
        <CardHeader>
          <CardTitle>{t('member-area.settings.customHighlight.title')}</CardTitle>
          <CardDescription>{t('member-area.settings.customHighlight.description')}</CardDescription>
        </CardHeader>
        <div className="max-h-96 bg-secondary px-4 pt-6 mx-6 rounded-md">
          {[highlightTypes.RECOMMENDATION].includes(highlightType) && (
            <img src={MemberAreaRecommendaton} alt="MemberAreaRecommendaton" className="w-full h-full" />
          )}

          {[highlightTypes.POST].includes(highlightType) && (
            <img src={MemberAreaPost} alt="MemberAreaPost" className="w-full h-full sm:max-h-96" />
          )}

          {[highlightTypes.IMAGE, highlightTypes.VIDEO].includes(highlightType) && (
            <img src={MemberAreaAsset} alt="MemberAreaAsset" className="w-full h-full sm:max-h-96" />
          )}
        </div>
        <CardContent className="mt-6">
          {isLoading && <RadioLoading />}

          {!isLoading && (
            <RadioGroup className="flex flex-col" defaultValue={highlightTypes.RECOMMENDATION} value={highlightType}>
              {bannerCustomOptions.map((option, i) => (
                <div key={i} className="p-4 flex flex-col gap-4 border-b last-border-none">
                  <button
                    className="flex flex-col gap-4 text-left"
                    onClick={() => setFieldValue('highlightType', option)}
                  >
                    <div className="flex items-center gap-2">
                      <RadioGroupItem id={i.toString()} value={option} disabled={isLoading} />

                      <Label htmlFor={i.toString()}>
                        {t(`member-area.settings.customHighlight.options.${option}.label`)}
                      </Label>
                    </div>

                    <p className="text-sm text-muted-foreground">
                      {t(`member-area.settings.customHighlight.options.${option}.description`)}
                    </p>
                  </button>

                  {highlightType === highlightTypes.POST && option === highlightTypes.POST && (
                    <>
                      {values.post && (
                        <>
                          <Thumbnail width={179} height={296} post={values.post} />
                          <p>{values?.post?.title}</p>
                        </>
                      )}

                      <Button variant="outline" onClick={() => setSelectPostModalOpen(true)}>
                        {t(
                          values.post
                            ? 'member-area.settings.customHighlight.post.button.has'
                            : 'member-area.settings.customHighlight.post.button.without',
                        )}
                      </Button>
                    </>
                  )}

                  {highlightType === highlightTypes.VIDEO && option === highlightTypes.VIDEO && (
                    <CardUploader
                      field="videoAsset"
                      title={t('member-area.settings.customHighlight.inputs.videoAsset.title')}
                      subtitle={t('member-area.settings.customHighlight.inputs.videoAsset.subtitle')}
                      errorMessage={
                        hasError ? t('member-area.settings.customHighlight.inputs.videoAsset.errorMessage') : undefined
                      }
                    />
                  )}

                  {highlightType === highlightTypes.IMAGE && option === highlightTypes.IMAGE && (
                    <div className="flex flex-col lg:flex-row gap-4">
                      <CardUploader
                        field="desktopAsset"
                        label={t('member-area.settings.customHighlight.inputs.desktopAsset.label')}
                        title={t('member-area.settings.customHighlight.inputs.desktopAsset.title')}
                        subtitle={t('member-area.settings.customHighlight.inputs.desktopAsset.subtitle')}
                        errorMessage={
                          hasError
                            ? t('member-area.settings.customHighlight.inputs.desktopAsset.errorMessage')
                            : undefined
                        }
                      />

                      <CardUploader
                        field="mobileAsset"
                        label={t('member-area.settings.customHighlight.inputs.mobileAsset.label')}
                        title={t('member-area.settings.customHighlight.inputs.mobileAsset.title')}
                        subtitle={t('member-area.settings.customHighlight.inputs.mobileAsset.subtitle')}
                        errorMessage={
                          hasError
                            ? t('member-area.settings.customHighlight.inputs.mobileAsset.errorMessage')
                            : undefined
                        }
                      />
                    </div>
                  )}

                  {highlightType === option && dirty && touched && (
                    <Button
                      className="md:ml-auto md:w-min"
                      data-testid="save-option"
                      onClick={submitForm}
                      loading={isLoading}
                      disabled={isSaveButtonDisabled}
                    >
                      {t('member-area.settings.customHighlight.save')}
                    </Button>
                  )}
                </div>
              ))}
            </RadioGroup>
          )}
        </CardContent>
      </Card>
    </>
  )
}

export default CustomHighlight
