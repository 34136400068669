import React from 'react'
import { useTranslation } from 'react-i18next'
import { PaymentMethodItem } from '../../../../../../domain/constants/payment-methods-field-options'
import { PaymentMethod } from '../../../../../../domain/enums/payment-method.enum'
import { BnplPaymentMethod } from './bnpl-payment-method'
import { MaxInstallmentsSelector } from './max-installments-selector'

interface BnplSettingsProps {
  paymentMethod: PaymentMethodItem
  checkedPaymentMethod: boolean
}

export const BnplSettings: React.FC<BnplSettingsProps> = ({ paymentMethod, checkedPaymentMethod }) => {
  const { t } = useTranslation()

  if (paymentMethod.name !== PaymentMethod.BNPL) return null
  if (!checkedPaymentMethod) return null

  return (
    <div className="pl-12 pr-4 pb-4 flex flex-col justify-between gap-4">
      <p className="text-muted-foreground">{t('offer.pricingTab.bnplSettings.description')}</p>
      <MaxInstallmentsSelector />
      <BnplPaymentMethod />
    </div>
  )
}
