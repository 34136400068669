import React from 'react'
import { Badge } from '@hub-la/shadcn'
import { Repeat } from 'lucide-react'
import { InvoiceStatus } from '../../../../domain/enums/invoice-status'

type SmartInstallmentChipProps = {
  status: InvoiceStatus
  installment: number
  installments: number
  size: 'large' | 'small' | 'tiny'
}

export const SmartInstallmentChip = ({ status, size, installment, installments }: SmartInstallmentChipProps) => {
  const getVariant = (): 'default' | 'secondary' | 'destructive' | 'outline' => {
    switch (status) {
      case InvoiceStatus.DISPUTED:
      case InvoiceStatus.PAID:
        return 'default' // Assuming default is green/positive
      case InvoiceStatus.UNPAID:
      case InvoiceStatus.DRAFT:
        return 'secondary' // Assuming secondary is blue/informative
      case InvoiceStatus.EXPIRED:
      case InvoiceStatus.CANCELED:
        return 'destructive' // Assuming destructive is red/negative
      case InvoiceStatus.RETURNED:
      case InvoiceStatus.CHARGEBACK:
        return 'outline' // Using outline for attention
      default:
        return 'default'
    }
  }

  const getSizeClass = (): string => {
    switch (size) {
      case 'large':
        return 'text-sm px-3 py-1'
      case 'small':
        return 'text-xs px-2 py-0.5'
      case 'tiny':
        return 'text-xs px-1.5 py-0.5'
      default:
        return 'text-sm px-2.5 py-0.5'
    }
  }

  return (
    <Badge variant={getVariant()} className={`flex items-center gap-1 ${getSizeClass()}`}>
      <Repeat className="w-4 h-4" />
      {installment}/{installments}
    </Badge>
  )
}
