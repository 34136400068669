import { createContext, memo, useMemo, useState } from 'react'
import { Video } from '../../domain/dtos/video'

export type VideosUploaderContextProps = {
  videos: Video[]
  add: (videos: Video[]) => void
}

export const VideosUploaderContext = createContext<VideosUploaderContextProps>({
  videos: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  add: () => {},
} as VideosUploaderContextProps)

export const VideosUploaderProvider = memo((props: { children?: React.ReactElement }) => {
  const [videos, setVideos] = useState<Video[]>([])

  const add = async (v: Video[]) => setVideos([...v])

  const value = useMemo(() => ({ videos, add }), [videos])

  return <VideosUploaderContext.Provider value={value}>{props.children}</VideosUploaderContext.Provider>
})
