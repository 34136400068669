import { Alert, AlertDescription } from '@hub-la/shadcn'

export const PayoutMethodAlert = ({ type }) => {
  const message =
    type === 'BANK_ACCOUNT'
      ? 'Verifique se os dados informados estão corretos e se o documento do titular da conta bancária é o mesmo cadastrado na Hubla'
      : 'Sua chave PIX é inválida ou o banco não é aceito pela Hubla. Tente novamente usando a verificação por conta bancária'

  return (
    <Alert variant="default">
      <AlertDescription>{message}</AlertDescription>
    </Alert>
  )
}
