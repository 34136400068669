import {
  Badge,
  Button,
  cn,
  Command,
  CommandGroup,
  CommandItem,
  CommandList,
  Label,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@hub-la/shadcn'
import { Check, ChevronDown, X } from 'lucide-react'
import React from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { PaymentMethod } from '../../../../../../domain/enums/payment-method.enum'

const paymentMethodOptions = [PaymentMethod.CREDIT_CARD, PaymentMethod.PIX, PaymentMethod.BANK_SLIP]

interface BadgeTooltipProps {
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void
  text: string
  tooltip?: string
  className?: string
}

const BadgeTooltip: React.FC<BadgeTooltipProps> = ({ onClick, text, tooltip }) => {
  const PaymentMethodBadge: React.FC<BadgeTooltipProps> = ({ onClick, text, className }) => (
    <Badge variant="secondary" className={cn('rounded-sm font-normal px-2', className)} onClick={onClick}>
      {text}
      <X className="h-4 w-4 text-muted-foreground ml-1" />
    </Badge>
  )

  if (!tooltip) {
    return <PaymentMethodBadge onClick={onClick} text={text} />
  }

  return (
    <Tooltip>
      <TooltipTrigger disabled>
        <PaymentMethodBadge className="cursor-default" text={text} onClick={(e) => e.stopPropagation()} />
      </TooltipTrigger>
      <TooltipContent sideOffset={12}>
        <p className="w-full">{tooltip}</p>
      </TooltipContent>
    </Tooltip>
  )
}

export const BnplPaymentMethod: React.FC = () => {
  const { t } = useTranslation()

  return (
    <Controller
      render={({ field: { onChange, value, name } }) => (
        <Popover>
          <PopoverTrigger asChild id={name}>
            <div>
              <Label htmlFor={name}>{t('offer.pricingTab.bnplSettings.paymentMethod.label')}</Label>
              <Button variant="outline" className="w-full mt-1.5 flex gap-1 hover:bg-inherit" type="button">
                {value.map((selectedPaymentMethod) => (
                  <BadgeTooltip
                    text={t(`offer.pricingTab.bnplSettings.paymentMethod.${selectedPaymentMethod}`)}
                    onClick={(e) => {
                      e.stopPropagation()
                      if (value.length === 1) return
                      onChange(value.filter((v) => v !== selectedPaymentMethod))
                    }}
                    tooltip={
                      value.length === 1 ? t('offer.pricingTab.bnplSettings.paymentMethod.needOneOption') : undefined
                    }
                  />
                ))}
                <ChevronDown className="h-4 w-4 text-muted-foreground ml-auto" />
              </Button>
            </div>
          </PopoverTrigger>
          <PopoverContent align="start" className="p-0 min-w-[var(--radix-popover-trigger-width)]">
            <Command>
              <CommandList>
                <CommandGroup>
                  {paymentMethodOptions.map((paymentMethod) => (
                    <CommandItem
                      key={paymentMethod}
                      value={paymentMethod}
                      onSelect={(clicked) =>
                        onChange(
                          !Array.isArray(value)
                            ? [clicked]
                            : value.find((el) => el === clicked)
                            ? value.length > 1
                              ? value.filter((v) => v !== clicked)
                              : value
                            : [...value, clicked],
                        )
                      }
                    >
                      <Check
                        className={cn('mr-2 h-4 w-4', value.includes(paymentMethod) ? 'opacity-100' : 'opacity-0')}
                      />
                      <span>{t(`offer.pricingTab.bnplSettings.paymentMethod.${paymentMethod}`)}</span>
                    </CommandItem>
                  ))}
                </CommandGroup>
              </CommandList>
            </Command>
          </PopoverContent>
        </Popover>
      )}
      name="checkout.bnplSettings.paymentMethods"
    />
  )
}
