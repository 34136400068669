import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { AddDailyCreditsByEmailBatchInput } from '../domain/dtos/add-daily-credits-by-email-batch-input'
import { AddSubscriptionDailyCreditsByEmailInBatchOutput } from '../domain/dtos/add-subscription-daily-credits-by-email-batch-output'
import { GeneralError } from '../domain/errors/general'
import { Envs } from '../envs'

export class AddDailyCreditsByEmailBatch {
  public constructor(@inject(HttpClient) private readonly httpClient: HttpClient) {}

  public async execute(
    data: AddDailyCreditsByEmailBatchInput,
  ): Promise<AddSubscriptionDailyCreditsByEmailInBatchOutput> {
    const response = await this.httpClient.request({
      method: HttpMethod.POST,
      url: this.makeUrl(),
      body: data,
    })

    if ([HttpStatusCode.OK, HttpStatusCode.CREATED].includes(response.statusCode)) {
      return response.data
    } else {
      /** @todo: Handle backend errors */
      throw new GeneralError()
    }
  }

  private makeUrl() {
    return `${Envs.BFF_WEB_URL}/subscriptions/batch/add-daily-credits-by-email`
  }
}
