export enum QueryKey {
  savePost = 'savePost',
  getPost = 'getPost',
  listSections = 'listSections',
  deletePost = 'deletePost',
  movePost = 'movePost',
  reorderSections = 'reorderSections',
  reorderPosts = 'reorderPosts',
  saveSection = 'saveSection',
  duplicateSection = 'duplicateSection',
  getProducts = 'getProducts',
  duplicatePost = 'duplicatePost',
  getLiveLink = 'getLiveLink',
}
