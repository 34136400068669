import { BannerDisplayMode } from '../domain/enums/banner-display-mode.enum'
import { BannerSize } from '../domain/enums/banner-size.enum'

type Sizes = {
  desktop: string
  mobile: string
}

/**
 * @typedef {Object} Sizes
 * @property {string} desktop - The size for desktop view.
 * @property {string} mobile - The size for mobile view.
 */

/**
 * Class representing a hint for banner dimensions.
 * See the full table here: https://www.figma.com/design/LVWmxk3VuLDpAOQAbZF57C/Checkout-V4?node-id=4097-25094&t=Fk1wlqRplsxNk75d-4
 */
export class BannerHint {
  private readonly SIDE_BANNER_RATIOS: Record<BannerSize, Sizes> = {
    [BannerSize.SMALL]: { desktop: 'na proporção 16:2', mobile: 'na proporção 16:3' },
    [BannerSize.MEDIUM]: { desktop: 'na proporção 16:4', mobile: 'na proporção 16:6' },
    [BannerSize.LARGE]: { desktop: 'na proporção 16:6', mobile: 'na proporção 16:9' },
  }

  private readonly NORMAL_RATIOS: Record<BannerSize, Sizes> = {
    [BannerSize.SMALL]: { desktop: 'na proporção 16:9', mobile: 'na proporção 16:3' },
    [BannerSize.MEDIUM]: { desktop: 'na proporção 16:6', mobile: 'na proporção 16:6' },
    [BannerSize.LARGE]: { desktop: 'na proporção 16:9', mobile: 'na proporção 16:9' },
  }

  private readonly FULL_HEIGHTS: Record<BannerSize, Sizes> = {
    [BannerSize.SMALL]: { desktop: 'com altura de 110px', mobile: 'com altura de 110px' },
    [BannerSize.MEDIUM]: { desktop: 'com altura de 220px', mobile: 'com altura de 220px' },
    [BannerSize.LARGE]: { desktop: 'com altura de 340px', mobile: 'com altura de 340px' },
  }

  private readonly DEFAULT_DIMENSIONS: Sizes = {
    desktop: 'nas dimensões 1512px x 400px',
    mobile: 'nas dimensões 420px x 350px',
  }

  /**
   * Creates an instance of BannerHint.
   * @param {BannerDisplayMode} [bannerDisplayMode] - The layout of the banner.
   * @param {BannerSize} [bannerSize] - The size of the banner.
   * @param {string | null} [sideBannerDesktopUrl] - The URL of the side banner for desktop.
   */
  constructor(
    private readonly bannerDisplayMode?: BannerDisplayMode,
    private readonly bannerSize?: BannerSize,
    private readonly sideBannerDesktopUrl?: string | null,
  ) {}

  /**
   * Executes the hint generation based on the banner layout and size.
   * @returns {Sizes} The generated sizes for desktop and mobile.
   */
  public execute(): Sizes {
    if (!this.bannerDisplayMode || !this.bannerSize) {
      return this.DEFAULT_DIMENSIONS
    }

    if (this.bannerDisplayMode === BannerDisplayMode.FIT_CONTENT) {
      return this.sideBannerDesktopUrl ? this.SIDE_BANNER_RATIOS[this.bannerSize] : this.NORMAL_RATIOS[this.bannerSize]
    }

    if (this.bannerDisplayMode === BannerDisplayMode.FULL_SCREEN) {
      return this.FULL_HEIGHTS[this.bannerSize]
    }

    return this.DEFAULT_DIMENSIONS
  }
}
