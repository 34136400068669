import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useToast } from '@hub-la/shadcn'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { UserCredentials } from '../../domain/dtos/credentials'
import { QueryKey } from '../../domain/enums/query-key'
import { SetCredentials } from '../../usecases/set-credentials'

export const useSetCredentials = () => {
  const container = useContainer()
  const queryClient = useQueryClient()
  const { toast } = useToast()
  const { t } = useTranslation()

  return useMutation(
    [QueryKey.setCredentials],
    (input: UserCredentials) => new SetCredentials(container.get(HttpClient)).execute(input),
    {
      retry: false,
      onSuccess: () => queryClient.invalidateQueries(['user']),
      onError: (error: Error) =>
        toast({
          title: t(error.message),
          variant: 'destructive',
        }),
    },
  )
}
