import { FLAGS, useFeatureFlag } from '@hub-la/fe-core-feature-flag'
import { useGetIdentity, useGetPayout, VerificationStatus } from '@hub-la/fe-get-user'
import { Alert, AlertDescription, AlertTitle, Button } from '@hub-la/shadcn'
import { AlertTriangle, ArrowUpRight } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { UpdatePayoutProvider } from '../providers/update-payout-provider'
import { UpdatePayoutModal } from './update-payout-modal'

export const UpdatePayoutBanner = () => {
  const [open, setOpen] = useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const { t } = useTranslation()
  const identity = useGetIdentity()
  const payout = useGetPayout()

  const identityId = identity.data?.identity?.id
  const identityVerificationStatus = identity.data?.identity?.verification?.status

  const { isFlagEnabled } = useFeatureFlag()

  const shouldUpdateBankAccount = isFlagEnabled(FLAGS.KYC.SHOULD_UPDATE_BANK_ACCOUNT, {
    identityId,
  })

  const payoutHasToBeVerified =
    (payout.error as Error)?.message === 'GET_PAYOUT_ERROR' ||
    (!!payout.data && payout.data.verificationStatus !== VerificationStatus.APPROVED)

  const hasToUpdatePayout = identityVerificationStatus === VerificationStatus.APPROVED && payoutHasToBeVerified

  useEffect(() => {
    if (hasToUpdatePayout && shouldUpdateBankAccount) {
      setOpen(true)
    }
  }, [hasToUpdatePayout, shouldUpdateBankAccount])

  if (!open) return null

  return (
    <UpdatePayoutProvider>
      <div className="pt-10 px-10">
        <Alert
          variant="default"
          className="flex flex-col gap-3 sm:gap-0 sm:flex-row sm:items-center sm:justify-between"
        >
          <div className="flex items-center gap-2">
            <AlertTriangle className="h-4 w-4" />

            <div className="flex flex-col gap-2">
              <AlertTitle>Por questões regulatórias, precisamos que você atualize os seus dados bancários</AlertTitle>
              <AlertDescription>
                É rápido, e você ainda poderá ter um aumento na sua taxa de aprovação de cartão de crédito.
              </AlertDescription>
            </div>
          </div>

          <div className="flex items-center gap-2">
            <Button variant="outline" className="w-full text-primary" onClick={() => setIsModalOpen(true)}>
              {t('Atualizar dados bancários')}
              <ArrowUpRight className="ml-2 h-4 w-4" />
            </Button>
          </div>
        </Alert>
      </div>

      <UpdatePayoutModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onUpdate={() => {
          payout.refetch()
          setIsModalOpen(false)
        }}
      />
    </UpdatePayoutProvider>
  )
}
