import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '../../domain/enums/query-key'
import { GetOffers } from '../../usecases/get-offers'

export const useGetOffers = () => {
  const container = useContainer()

  return useQuery([QueryKey.getOffers], () => new GetOffers(container.get(HttpClient)).execute(), {
    retry: 3,
    retryOnMount: false,
    refetchOnWindowFocus: false,
    initialData: [],
  })
}
