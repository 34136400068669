import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useQuery } from '@tanstack/react-query'
import { GetTicketInput } from '../../domain/dtos/get-ticket-input.dto'
import { QueryKey } from '../../domain/enums/query-key.enum'
import { GetTicket } from '../../usecases/get-ticket.usecase'

export const useGetTicket = (
  input?: GetTicketInput & {
    onError?: (error: any) => void
  },
) => {
  const container = useContainer()

  return useQuery(
    [QueryKey.getTicket],
    ({ signal }) => new GetTicket(container.get(HttpClient)).execute(input, signal),
    {
      enabled: !!input?.productId,
      refetchOnWindowFocus: false,
      retry: 3,
      onError: (error) => {
        input?.onError?.(error)
      },
    },
  )
}
