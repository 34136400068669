import moment from 'moment-timezone'

export class FormatDate {
  private value: string | Date = ''

  public constructor(value: string | Date) {
    this.value = value
  }

  public static build(value: string | Date): FormatDate {
    return new FormatDate(value)
  }

  public getValue(options?: Intl.DateTimeFormatOptions) {
    const date = moment(this.value).tz('America/Sao_Paulo')

    return date.toDate().toLocaleString('pt-BR', options || { year: 'numeric', month: 'numeric', day: 'numeric' })
  }
}
