import { container } from '@hub-la/fe-container'
import { DashboardAffiliates, DashboardAffiliatesProvider } from '@hub-la/fe-dashboard-affiliates'

export const DashboardAffiliatesInit = () => {
  return (
    <DashboardAffiliatesProvider container={container}>
      <DashboardAffiliates />
    </DashboardAffiliatesProvider>
  )
}
