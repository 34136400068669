import { useGetUser } from '@hub-la/fe-get-user'
import { RoleplayProvider, SelectAccountRoleplayPage } from '@hub-la/fe-roleplay'
import { withRoleplayRedirect } from '../components/roleplay-redirect'

export const RoleplayInit = withRoleplayRedirect(() => {
  const { data: currentUser } = useGetUser()

  return (
    <RoleplayProvider>
      <SelectAccountRoleplayPage
        currentUser={{
          id: currentUser?.id ?? '',
          name: currentUser?.name ?? '',
          email: currentUser?.email ?? '',
          avatarUrl: currentUser?.picture ?? '',
        }}
      />
    </RoleplayProvider>
  )
})
