import {
  Badge,
  Button,
  cn,
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  Label,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@hub-la/shadcn'
import { CheckIcon, ChevronDown, ChevronUp, X } from 'lucide-react'
import { useRef, useState } from 'react'

interface Option {
  id: string
  label: string
  type: string
}

interface MultiSelectProps {
  options: Option[]
  value: string[]
  onChange: (value: string[]) => void
  label: string
  helperText?: string
  error?: boolean
  disabled?: boolean
  hasSelectAll?: boolean
}

export const MultiSelect = ({
  options,
  value,
  onChange,
  label,
  error,
  helperText,
  hasSelectAll,
  disabled,
}: MultiSelectProps) => {
  const [open, setOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const triggerRef = useRef<HTMLButtonElement>(null)

  const toggleOption = (optionId: string) => {
    const newValue = value.includes(optionId) ? value.filter((id) => id !== optionId) : [...value, optionId]
    onChange(newValue)
  }

  const filteredOptions = options.filter(
    (option) =>
      (option.label?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        option.type?.toLowerCase().includes(searchTerm.toLowerCase())) ??
      false,
  )

  const selectAll = () => {
    const allSelected = value.length === options.length
    onChange(allSelected ? [] : options.map((option) => option.id))
  }

  const clearAll = () => {
    onChange([])
  }

  const selectedOptions = options.filter((option) => value.includes(option.id))

  return (
    <div className="flex flex-col gap-2">
      <Label className="text-sm font-small">{label}</Label>

      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            disabled={disabled}
            ref={triggerRef}
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className={cn(
              'p-2 w-full justify-between overflow-x-auto min-h-[2.5rem]',
              error ? 'border-red-500' : '',
              'flex-wrap',
            )}
          >
            <div className="w-[90%] flex flex-wrap gap-1">
              {selectedOptions.length > 0 ? (
                selectedOptions.map((option) => (
                  <Badge key={option.id} variant="secondary" className="flex-wrap gap-1">
                    {option.label}

                    <X
                      className="h-3 w-3 cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation()
                        toggleOption(option.id)
                      }}
                    />
                  </Badge>
                ))
              ) : (
                <span className="text-muted-foreground">Selecionar</span>
              )}
            </div>

            {open ? (
              <ChevronUp className="h-4 w-4 shrink-0 opacity-50 mt-1 mb-auto" />
            ) : (
              <ChevronDown className="h-4 w-4 shrink-0 opacity-50 mt-1 mb-auto" />
            )}
          </Button>
        </PopoverTrigger>

        <PopoverContent
          className="p-0 w-[--radix-popover-trigger-width]"
          style={{ width: `${triggerRef?.current?.offsetWidth}px` }}
        >
          <Command className="max-h-60 flex flex-col">
            <CommandInput placeholder="Buscar" value={searchTerm} onValueChange={setSearchTerm} />
            <CommandList className="flex-grow overflow-auto">
              <CommandEmpty>Nenhum resultado</CommandEmpty>
              <CommandGroup>
                {filteredOptions.map((option) => (
                  <CommandItem
                    key={option.id}
                    onSelect={() => toggleOption(option.id)}
                    className="flex gap-2 items-center"
                  >
                    <div
                      className={cn(
                        'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                        value.includes(option.id)
                          ? 'bg-hubla-primary text-primary-foreground border-hubla-primary'
                          : 'opacity-50 [&_svg]:invisible',
                      )}
                    >
                      <CheckIcon className={cn('h-4 w-4')} />
                    </div>
                    <Label htmlFor={option.id}>{option.label}</Label>
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
            <div className="flex flex-row border-t">
              <div className="border-r w-full">
                <CommandItem className="justify-center text-center py-2" onSelect={selectAll}>
                  Selecionar tudo
                </CommandItem>
              </div>
              <div className="w-full">
                <CommandItem
                  disabled={selectedOptions.length === 0}
                  className="justify-center text-center py-2"
                  onSelect={clearAll}
                >
                  Limpar seleções
                </CommandItem>
              </div>
            </div>
          </Command>
        </PopoverContent>
      </Popover>
      {helperText && <p className={`text-sm ${error ? 'text-red-500' : 'text-muted-foreground'}`}>{helperText}</p>}
    </div>
  )
}
