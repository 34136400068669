import { PermissionResource, useRoleplay } from '@hub-la/fe-roleplay'
import {
  Button,
  cn,
  Logo,
  Sheet,
  SheetContent,
  SheetTrigger,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  useIsMobile,
} from '@hub-la/shadcn'
import {
  Banknote,
  Home,
  Menu,
  Puzzle,
  RefreshCw,
  Settings,
  Shapes,
  Tag,
  Ticket,
  Undo2,
  UsersIcon,
  Wallet,
} from 'lucide-react'
import React from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'

interface MenuSidebarProps {
  isToggleSidebar: boolean
  onToggleSidebar?: (isToggled: boolean) => void
}

export const menuItems = [
  { path: '/dashboard', icon: Home, label: 'Início', permissions: [PermissionResource.HOME] },
  {
    path: '/dashboard/sales',
    icon: Banknote,
    label: 'Vendas',
    permissions: [PermissionResource.SALES],
    additionalPaths: ['/dashboard/sales/'],
  },
  {
    path: '/dashboard/products',
    icon: Shapes,
    label: 'Produtos',
    permissions: [PermissionResource.PRODUCTS],
    additionalPaths: ['/edit/', '/co-production/'],
  },
  {
    path: '/dashboard/events',
    icon: Ticket,
    label: 'Eventos',
    permissions: [PermissionResource.PRODUCTS],
    additionalPaths: ['event=true'],
  },
  {
    path: '/dashboard/subscriptions',
    icon: RefreshCw,
    label: 'Assinaturas',
    permissions: [PermissionResource.SUBSCRIPTIONS],
    additionalPaths: ['/dashboard/subscriptions/'],
  },
  {
    path: '/dashboard/affiliates',
    icon: UsersIcon,
    label: 'Afiliados',
    permissions: [PermissionResource.AFFILIATES],
  },
  { path: '/dashboard/coupons', icon: Tag, label: 'Cupons', permissions: [PermissionResource.COUPONS] },
  { path: '/dashboard/refunds', icon: Undo2, label: 'Reembolsos', permissions: [PermissionResource.REFUNDS] },
  { path: '/dashboard/financial', icon: Wallet, label: 'Financeiro', permissions: [PermissionResource.WALLET] },
  {
    path: '/dashboard/integrations',
    icon: Puzzle,
    label: 'Integrações',
    permissions: [PermissionResource.INTEGRATIONS],
  },
  { path: '/dashboard/settings', icon: Settings, label: 'Configurações', hideRoleplay: true },
]

export const MenuSidebar: React.FC<MenuSidebarProps> = ({ isToggleSidebar, onToggleSidebar }) => {
  const history = useHistory()
  const location = useLocation()
  const isMobile = useIsMobile()
  const { isRoleplay, hasPermission } = useRoleplay()
  const menuWidth = isToggleSidebar ? 80 : 252

  const isSelected = (path: string, additionalPaths: string[] = []) => {
    const currentPath = location.pathname
    const currentSearch = location.search
    if (currentSearch.includes('event=true')) {
      if (additionalPaths.includes('event=true')) return true
      return false
    }
    // Fix for dashboard path
    if (path === '/dashboard' && (currentPath === '/dashboard' || currentPath === '/dashboard/')) return true
    if (currentPath.startsWith(path) && path !== '/dashboard') return true
    if (additionalPaths.some((additionalPath) => currentPath.includes(additionalPath))) return true
    return false
  }

  const MenuContent = () => {
    const filteredItems = menuItems.filter((menu) => {
      if (isRoleplay && menu.hideRoleplay) return false
      if (isRoleplay && menu.permissions) {
        return menu.permissions.some((permission) => hasPermission(permission))
      }
      return true
    })

    return (
      <div className="flex flex-col h-full px-3 py-2 gap-1">
        {filteredItems.map((item, idx) => {
          const isLastItem = idx === menuItems.length - 1
          return <MenuItem key={item.path} item={item} isLastItem={isLastItem} />
        })}
      </div>
    )
  }

  const MenuItem = ({ item, isLastItem }) => {
    const showTooltip = isToggleSidebar && !isMobile
    const itemContent = (
      <Button
        key={item.path}
        className={cn(
          'flex items-center rounded-xl space-x-4 h-12 lg:h-9 lg:space-x-2 w-full transition-all duration-300 ease-in-out cursor-pointer',
          isSelected(item.path, item.additionalPaths)
            ? 'bg-[#CDFF36] hover:bg-[#D7FF5E] text-sidebar-primary hover:text-sidebar-primary'
            : 'bg-transparent hover:bg-secondary-foreground/10 text-sidebar-foreground hover:text-secondary-foreground',
          showTooltip ? 'justify-center' : 'justify-start',
          isLastItem ? '!mt-auto !mb-4' : '',
        )}
        onClick={() => {
          history.push(item.path)
          if (isMobile) {
            onToggleSidebar?.(false)
          }
        }}
        variant="ghost"
      >
        <item.icon className="h-6 w-6 lg:h-4 lg:w-4 flex-shrink-0" />
        <span className={cn('text-lg lg:text-sm', showTooltip ? 'hidden' : 'block')}>{item.label}</span>
      </Button>
    )

    if (!showTooltip) {
      return itemContent
    }

    return (
      <TooltipProvider delayDuration={200}>
        <Tooltip>
          <TooltipTrigger asChild>{itemContent}</TooltipTrigger>
          <TooltipContent className="z-10002" side="right">
            {item.label}
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    )
  }

  if (isMobile) {
    return (
      <Sheet open={isToggleSidebar} onOpenChange={onToggleSidebar}>
        <SheetTrigger asChild>
          <Button size="icon" variant="outline" className="lg:hidden">
            <Menu className="h-5 w-5" />
          </Button>
        </SheetTrigger>
        <SheetContent side="left" className="pt-6 px-1 w-[80%] sm:w-[385px]">
          <Link to="/dashboard" className="inline-block ml-6 mb-4">
            <Logo hierarchy="primary" size={'medium'} />
          </Link>
          <MenuContent />
        </SheetContent>
      </Sheet>
    )
  }

  return (
    <nav className="hidden md:block flex-shrink-0" style={{ width: menuWidth }}>
      <div
        className={cn(
          'fixed left-0 top-[65px] h-[calc(100vh-65px)] border-r border-sidebar-border bg-sidebar-background overflow-y-auto transition-all duration-300 ease-in-out',
          isToggleSidebar ? 'w-20' : 'w-64',
          isRoleplay ? 'pt-14' : '',
        )}
      >
        <MenuContent />
      </div>
    </nav>
  )
}
