import { useFeatureFlag } from "@hub-la/fe-core-feature-flag"
import { useGetUser } from "@hub-la/fe-get-user"
import { useRoleplay } from '@hub-la/fe-roleplay'
import { SelectTrigger, SelectContent, SelectItem, Select, Label, SelectValue, Input } from "@hub-la/shadcn"
import { Controller } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { SwitchToggleChildren } from "../../../../../components/switch-toggle-children"

const installments = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

export const AutomationSettings = () => {
  const { t } = useTranslation()

  const renderValue = (installment: number) =>
    t('offer.pricingTab.pricing.automationSettings.disabled.placeholder', { installment })

  const { data } = useGetUser()
  const { roleplayUserId } = useRoleplay()

  const isPartialPDIEnabled = useFeatureFlag().isFlagEnabled('isPartialPDIEnabled', {
    userId: roleplayUserId ?? data?.id
  })

  if (!isPartialPDIEnabled) {
    return <></>
  }

  return (
    <SwitchToggleChildren
      name="pricing.isAutomationSettings"
      label={t('offer.pricingTab.pricing.automationSettings.title')}
    >
      <div>
        <div className="space-y-4 border rounded-lg p-4">
          <h3 className="text-sm">
            {t('offer.pricingTab.pricing.automationSettings.disabled.title')}
          </h3>
          <Controller
            name="pricing.automationSettings.disableAutoRenewalAfterCycles"
            render={({ field }) => (
              <div className="flex flex-col space-y-1.5">
                <Label>
                  {t('offer.pricingTab.pricing.automationSettings.disabled.label')}
                </Label>
                <Select value={field.value?.toString()} onValueChange={(value) => field.onChange(Number(value))}>
                  <SelectTrigger className={`w-full`}>
                    <SelectValue placeholder={renderValue(field.value)} />
                  </SelectTrigger>
                  <SelectContent>
                    {installments.map((installmentNumber) => (
                      <SelectItem key={installmentNumber} value={installmentNumber.toString()} className="notranslate">
                        {renderValue(installmentNumber)}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
            )}
          />
        </div>
        <div className="space-y-4 border rounded-lg p-4">
          <h3 className="text-sm">
            {t('offer.pricingTab.pricing.automationSettings.credits.title')}
          </h3>
          <Controller
            name="pricing.automationSettings.creditsToAddOnFinalCycle"
            render={({ field }) => (
              <div className="flex flex-col space-y-1.5">
                <Label>
                  {t('offer.pricingTab.pricing.automationSettings.credits.label')}
                </Label>
                <Input
                  onChange={field.onChange}
                  type="number"
                  min={1}
                  value={field.value}
                  placeholder={t('offer.pricingTab.pricing.automationSettings.credits.label')}
                />
              </div>
            )}
          />
        </div>
      </div>
    </SwitchToggleChildren >
  )
}
