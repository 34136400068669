import { useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { CohortForm } from '../../components/cohort-form/cohort-form'
import { useGetCohort } from '../../hooks/use-get-cohort'
import { usePutCohort } from '../../hooks/use-put-cohort'
import { CohortSchemaValidationType } from '../../validations/cohort-validation'

const EditCohortPage = () => {
  const history = useHistory()
  const { productId, cohortId } = useParams<{ productId: string; cohortId: string }>()
  const { mutate, isLoading } = usePutCohort({
    productId,
    cohortId,
    onSuccess: () => history.push(`/edit/${productId}/cohorts`),
  })
  const { data: cohortData } = useGetCohort(productId, cohortId)

  const defaultValues: CohortSchemaValidationType = useMemo(
    () => ({
      name: cohortData?.name ?? '',
      sections: (cohortData?.resources ?? [])
        .filter((resource) => resource.type === 'SECTION')
        .map((section) => section.externalId),
      groups: (cohortData?.resources ?? [])
        .filter((resource) => resource.type === 'GROUP')
        .map((group) => group.externalId),
    }),
    [productId, cohortData],
  )

  return (
    <CohortForm
      isEdit
      productId={productId}
      defaultValues={defaultValues}
      onSubmit={(data) => mutate(data)}
      isLoading={isLoading}
    />
  )
}

export { EditCohortPage }
