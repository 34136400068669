import { Button, cn } from '@hub-la/shadcn'
import { Dispatch, SetStateAction } from 'react'
import { RefundStatus } from '../../domain/enums/refund-status'
import { RefundCurrentStatus } from './refund-current-status'

interface StatusFilterProps {
  currentStatus: RefundStatus
  setCurrentStatus: Dispatch<SetStateAction<RefundStatus>>
}

export const StatusFilter = ({ currentStatus, setCurrentStatus }: StatusFilterProps) => {
  const handleStatusChange = (status: RefundStatus) => {
    setCurrentStatus(status)
  }

  const options = [
    { value: RefundStatus.CREATED, label: 'Pendentes' },
    { value: RefundStatus.ACCEPTED, label: 'Aprovados' },
    { value: RefundStatus.REJECTED, label: 'Recusados' },
    { value: RefundStatus.PROCESSING, label: 'Processando' },
    { value: RefundStatus.FAILED, label: 'Falhas' },
    { value: RefundStatus.CANCELED, label: 'Cancelados' },
  ]

  return (
    <div className="flex flex-wrap gap-2">
      {options.map((option) => (
        <Button
          key={option.value}
          variant={currentStatus === option.value ? 'default' : 'outline'}
          size="default"
          onClick={() => handleStatusChange(option.value)}
          className={cn('flex items-center', currentStatus === option.value ? 'border-2 border-primary' : '')}
        >
          <RefundCurrentStatus status={option.value} />
        </Button>
      ))}
    </div>
  )
}
