import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useInfiniteQuery } from '@tanstack/react-query'
import { QueryKey } from '../../domain/enums/query-keys'
import { GetCommentReplies } from '../../usecases/get-comment-replies'
import { useDiscussion } from '../providers/discussion'

export const useGetCommentReplies = ({
  enabled,
  commentId,
  pageSize,
}: {
  commentId: string
  enabled: boolean
  pageSize: number
}) => {
  const container = useContainer()

  const discussion = useDiscussion()

  return useInfiniteQuery(
    [QueryKey.getCommentReplies, commentId],
    ({ pageParam = 1 }) =>
      new GetCommentReplies(container.get(HttpClient)).execute({
        postId: discussion.metadata.postId,
        commentId,
        page: pageParam,
        pageSize,
      }),
    {
      enabled,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      getNextPageParam: (oldData) => {
        const nextPage = (oldData?.page ?? 0) + 1
        const lastPage = oldData?.lastPage ?? 0

        if (nextPage > lastPage) {
          return undefined
        }

        return nextPage
      },
      keepPreviousData: true,
    },
  )
}
