import { HttpClient, HttpMethod } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { Partner } from '../domain/dtos/partners'
import { Status } from '../domain/dtos/receiver'
import { FormatDate } from '../domain/vos/format-date'
import { Envs } from '../envs'

export class GetPartners {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  private partners: Partner = {
    receivers: [],
    partnersCommission: 0,
  }

  public async fetch(groupId: string, receiverId?: string): Promise<Partner> {
    const response = await this.httpClient.request({
      method: HttpMethod.POST,
      url: `${Envs.FUNCTIONS_URL}/receiver/listPartnersForGroupOwner/pt`,
      body: {
        data: {
          groupId,
          status: [Status.invited, Status.accepted],
        },
      },
    })

    if (!response.data.result) throw new Error('Falha ao buscar parceiros')

    const result = response.data.result

    const partners = receiverId ? result.filter((r) => r.id !== receiverId) : result

    this.partners.partnersCommission = partners.reduce((acc, obj) => {
      if (obj.status !== Status.accepted) {
        return acc
      }

      acc += obj.commission?.sell?.percent || 0
      return acc
    }, 0)

    if (receiverId) {
      const partner = result.find((r) => r.id === receiverId)

      if (partner) {
        this.partners.partnerEditing = partner
        this.partners.partnerName = partner.user.name || partner.user.email
      }
    }

    this.partners.receivers = partners.map((partner) => ({
      ...partner,
      expiresAt: partner.expiresAt ? FormatDate.build(partner.expiresAt).getValue() : '',
    }))

    return this.partners
  }
}
