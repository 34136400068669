/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { injectable } from 'inversify'
import { AnalyticsProperties } from './analytics-properties'
import { AnalyticsProvider } from './analytics-provider'
import { UserIdentifyInput } from './domain/dtos'

@injectable()
export class AnalyticsProviderMock extends AnalyticsProvider {
  public identify(user: UserIdentifyInput): void {}
  public page(event: string, properties?: AnalyticsProperties): void {}
  public track(event: string, properties?: AnalyticsProperties): void {}
  public reset(): void {}
}
