import { QueryParamConfig } from 'serialize-query-params/src/types'

/**
 * For flat arrays of strings, filters out undefined values during decode
 */
export const CommaDelimitedArrayParam: QueryParamConfig<
  (string | null)[] | null | undefined,
  (string | null)[] | null | undefined
> = {
  encode: encodeCommaDelimitedArray,
  decode: decodeCommaDelimitedArray,
}

/**
 * Encodes an array as a delimited string. For example,
 * ['a', 'b'] -> 'a;b' with entrySeparator=';'
 *
 * @param array The array to be encoded
 * @param entrySeparator The string used to delimit entries
 * @return The array as a string with elements joined by the
 * entry separator
 */
export function encodeCommaDelimitedArray(
  array: (string | null)[] | null | undefined,
  entrySeparator = ';',
): string | null | undefined {
  if (array == null) {
    return array
  }

  return array.join(entrySeparator)
}

/**
 * Decodes a delimited string into javascript array. For example,
 * 'a;b' -> ['a', 'b'] with entrySeparator=';'
 *
 * If an array is provided as input, only the first entry is used.
 *
 * @param {String} input The JSON string representation
 * @param entrySeparator The array as a string with elements joined by the
 * entry separator
 * @return {Array} The javascript representation
 */
export function decodeCommaDelimitedArray(
  input: string | (string | null)[] | null | undefined,
  entrySeparator = ';',
): (string | null)[] | null | undefined {
  const arrayStr = getEncodedValue(input, true)
  if (arrayStr == null) return arrayStr
  if (arrayStr === '') return []

  return arrayStr.split(entrySeparator)
}

/**
 * Interprets an encoded string and returns either the string or null/undefined if not available.
 * Ignores array inputs (takes just first element in array)
 * @param input encoded string
 */
function getEncodedValue(
  input: string | (string | null)[] | null | undefined,
  allowEmptyString?: boolean,
): string | null | undefined {
  if (input == null) {
    return input
  }
  // '' or []
  if (input.length === 0 && (!allowEmptyString || (allowEmptyString && input !== ''))) {
    return null
  }

  const str = input instanceof Array ? input[0] : input
  if (str == null) {
    return str
  }
  if (!allowEmptyString && str === '') {
    return null
  }

  return str
}
