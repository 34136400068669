import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { Section } from '@hub-la/fe-post'
import { useQuery } from '@tanstack/react-query'
import { GetSectionsInput } from '../../domain/dtos/get-sections-input'
import { QueryKey } from '../../domain/enum/query-key'
import { GetSections } from '../../usecases/get-sections'

export const useGetSections = (input: GetSectionsInput) => {
  const container = useContainer()

  return useQuery<Section[], Error, Section[]>(
    [QueryKey.getSections, input.productId],
    () => new GetSections(container.get(HttpClient)).execute(input),
    {
      retryOnMount: false,
      refetchOnWindowFocus: false,
      initialData: [],
      retry: false,
      enabled: !!input.productId && !!input.enabled,
    },
  )
}
