import { Skeleton, TableCell, TableRow } from '@hub-la/shadcn'

export const InvoiceTableLoading = () => {
  return (
    <TableRow>
      <TableCell className="py-2 px-4">
        <div className="flex flex-row gap-2">
          <Skeleton className="w-5 h-6" />
          <Skeleton className="w-16 h-6" />
        </div>
      </TableCell>
      <TableCell className="py-2 px-4">
        <Skeleton className="w-11 h-6" />
      </TableCell>
      <TableCell className="py-2 px-4">
        <Skeleton className="w-4/5 h-6" />
      </TableCell>
      <TableCell className="py-2 px-4">
        <Skeleton className="w-1/3 h-6" />
      </TableCell>
    </TableRow>
  )
}
