import {
  Badge,
  Button,
  cn,
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Separator,
} from '@hub-la/shadcn'
import { CheckIcon, PlusCircle } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { IntegrationEventOption } from '../../domain/dtos/integration-event'
import { IntegrationProvider } from '../../domain/dtos/integration-provider'
import { useGetIntegrationEvents } from '../hooks/use-get-integration-events'

type Props = {
  onChange: (ids: string[]) => void
  value: string[]
  provider: IntegrationProvider
}

export const SelectEvents = (props: Props) => {
  const events = useGetIntegrationEvents(props.provider)
  const { t } = useTranslation()
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="outline">
          <PlusCircle className="h-4 w-4 mr-1" />
          <span className="text-sm">Eventos</span>
          {props.value.length > 0 && (
            <>
              <Separator orientation="vertical" className="mx-2 h-4" />
              <Badge variant="secondary" className="rounded-sm px-1 font-normal lg:hidden">
                {props.value.length}
              </Badge>
              <div className="hidden space-x-1 lg:flex">
                {props.value.length > 2 ? (
                  <Badge variant="secondary" className="rounded-sm px-1 font-normal">
                    {props.value.length} selecionado(s)
                  </Badge>
                ) : (
                  events
                    .filter((option) => props.value.includes(option.code))
                    .map((option) => (
                      <Badge variant="secondary" key={option.code} className="rounded-sm px-1 font-normal">
                        {t(option.name)}
                      </Badge>
                    ))
                )}
              </div>
            </>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[350px] p-0" align="start">
        <Command>
          <CommandInput placeholder={t('rules.table.columns.events')} />
          <CommandList>
            <CommandEmpty>{t('rules.createModal.noOptions')}</CommandEmpty>
            {Object.entries(
              events.reduce((acc, event) => {
                const group = event.group
                if (!acc[group]) acc[group] = []
                acc[group].push(event)
                return acc
              }, {} as Record<string, IntegrationEventOption[]>),
            ).map(([group, groupEvents]) => (
              <CommandGroup key={group} heading={group} className="border-b">
                {groupEvents.map((event) => {
                  const isSelected = props.value.includes(event.code)
                  return (
                    <CommandItem
                      key={event.code}
                      onSelect={() => {
                        if (isSelected) {
                          const newArr = props.value.filter((value) => value !== event.code)
                          props.onChange(newArr)
                        } else {
                          const newArr = [...props.value]
                          newArr.push(event.code)
                          props.onChange(newArr)
                        }
                      }}
                    >
                      <div
                        className={cn(
                          'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                          isSelected
                            ? 'bg-hubla-primary text-primary-foreground border-hubla-primary'
                            : 'opacity-50 [&_svg]:invisible',
                        )}
                      >
                        <CheckIcon className={cn('h-4 w-4')} />
                      </div>
                      <div className="flex flex-col">
                        <span className="text-foreground">{t(event.name)}</span>

                        <span className="text-xs text-muted-foreground">{event.code}</span>
                      </div>
                    </CommandItem>
                  )
                })}
              </CommandGroup>
            ))}
          </CommandList>
        </Command>
        <Separator />
        <div className="flex justify-between">
          <Button
            disabled={props.value.length === 0}
            variant="ghost"
            className="w-full justify-center text-center rounded-none"
            onClick={() => props.onChange([])}
          >
            Limpar filtros
          </Button>
          <Button
            disabled={props.value.length === events.length}
            variant="ghost"
            className="w-full justify-center text-center rounded-none"
            onClick={() => props.onChange(events.map((event) => event.code))}
          >
            Selecionar todos
          </Button>
        </div>
      </PopoverContent>
    </Popover>
  )
}
