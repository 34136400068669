import { HttpClient, HttpMethod, HttpResponse } from '@hub-la/fe-core-http-client'
import {
  GetMembersAreaSettingsInput,
  GetMembersAreaSettingsResponse,
} from '../../../domain/dtos/member-area/settings/get-members-area'
import { GeneralError } from '../../../domain/errors/general'
import { Envs } from '../../../envs'

export class GetMembersArea {
  public constructor(private readonly httpClient: HttpClient) {}

  public async execute(values: GetMembersAreaSettingsInput) {
    try {
      const response: HttpResponse<GetMembersAreaSettingsResponse> = await this.httpClient.request({
        url: `${Envs.BFF_WEB_URL}/products/${values.productId}/settings/members-area`,
        method: HttpMethod.GET,
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })

      return response.data
    } catch (e) {
      throw new GeneralError()
    }
  }
}
