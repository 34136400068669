import { AssetResponse, AssetType } from '@hub-la/fe-asset'
import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'

export class GetPdfFileUrl {
  public constructor(@inject(HttpClient) private readonly httpClient: HttpClient) {}

  public async execute(asset?: AssetResponse): Promise<string | undefined> {
    if (asset?.url?.includes('/download') && asset.type === AssetType.application) {
      const file = await this.getAssetFileBuffer(asset)
      return this.toObjectUrl(file)
    }

    return asset?.url
  }

  private async getAssetFileBuffer(asset: AssetResponse) {
    if (asset.url) {
      const response = await this.httpClient.request({
        url: asset.url,
        method: HttpMethod.GET,
        responseType: 'arraybuffer',
      })

      if (response.statusCode === HttpStatusCode.OK) {
        return response.data
      }

      throw new Error()
    }
  }

  private toObjectUrl(buffer: any): string {
    const file = new Blob([buffer], { type: 'application/pdf' })
    const fileURL = URL.createObjectURL(file)

    return fileURL
  }
}
