import { SubscriptionStatus } from '../enums/subscription-status'

export const subscriptionStatus = [
  {
    id: SubscriptionStatus.ACTIVE,
    label: 'Ativa',
  },
  {
    id: SubscriptionStatus.CANCELED,
    label: 'Cancelada',
  },
  {
    id: SubscriptionStatus.INACTIVE,
    label: 'Inativa',
  },
  {
    id: SubscriptionStatus.INCOMPLETE,
    label: 'Incompleta',
  },
]
