import { HttpClient, HttpMethod } from '@hub-la/fe-core-http-client'
import { inject, injectable } from 'inversify'
import isNil from 'lodash/isNil'
import { Flag } from '../domain/dtos/flag'
import { Envs } from '../envs'

type GetFlagsResponse = {
  result: Record<
    string,
    {
      defaultValue: { value: string }
      valueType: string
    }
  >
}

export type GetFlagsOutput = Flag[]

@injectable()
export class GetFlags {
  public constructor(@inject(HttpClient) private readonly httpClient: HttpClient<GetFlagsResponse>) {}

  public async execute(): Promise<GetFlagsOutput> {
    const response = await this.httpClient.request({
      url: `${Envs.FUNCTIONS_URL}/featureFlag/getAll/pt`,
      method: HttpMethod.POST,
      body: {
        data: null,
      },
    })

    if (isNil(response.data)) {
      return []
    }

    return this.parseValues(response.data)
  }

  private parseValues(data: GetFlagsResponse) {
    const flagsAsJSON = Object.values(data.result).map(({ defaultValue }) => {
      try {
        return JSON.parse(defaultValue.value)
      } catch (error) {
        return {}
      }
    })

    return flagsAsJSON as unknown as GetFlagsOutput
  }
}
