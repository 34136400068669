import { Analytics, AnalyticsEvent } from '@hub-la/fe-core-analytics'
import { Auth } from '@hub-la/fe-core-auth'
import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { SignOut } from '@hub-la/fe-sign-out'
import { inject } from 'inversify'
import { GeneralError } from '../domain/errors/general'
import { Envs } from '../envs'

export class AuthenticateAuthLink {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
    @inject(Auth) private readonly auth: Auth,
    @inject(Analytics) private readonly analytics: Analytics,
  ) {}

  public async execute(id: string) {
    this.analytics.reset()
    await new SignOut(this.httpClient, this.auth).execute()
    localStorage.removeItem('currentUser')

    const response = await this.httpClient.request({
      method: HttpMethod.POST,
      url: `${Envs.FUNCTIONS_URL}/authLoginWithLink/pt`,
      body: {
        data: { id },
      },
    })

    if (response.statusCode === HttpStatusCode.CREATED || response.statusCode === HttpStatusCode.OK) {
      const result = response.data?.result

      this.auth.signInWithToken(result?.sessionToken)

      this.analytics.identify(result?.user)
      this.analytics.track(AnalyticsEvent.AUTHENTICATION.LOGIN_WITH_LINK_SUCCESSFUL, {
        userId: result?.user?.id,
        redirectUrl: result?.redirectUrl,
      })

      await new Promise((resolve) => setTimeout(resolve, 2000))

      return result
    }

    if (response.data?.error) {
      return Promise.reject(response.data?.error)
    }

    throw new GeneralError()
  }
}
