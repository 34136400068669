import { Asset } from '../domain/dtos/asset'
import { AssetType } from '../domain/dtos/asset-type'

export class GetAssetSize {
  public execute(asset: Asset): number {
    const { type, metadata } = asset
    if (!type || !metadata?.size) {
      return 0
    }

    if ([AssetType.image, AssetType.application, AssetType.video].includes(type as AssetType)) {
      return metadata.size
    }

    return 0
  }
}
