import { Envs } from '../envs'
import { TokenizerInterface, TokenizerToken } from './tokenizer'

interface CustomWindow {
  Iugu: {
    CreditCard: (number, month, year, firstName, lastName, cvv) => unknown
    createPaymentToken: (CreditCard: unknown, callable) => void
    setAccountID: (accountId: string) => void
    setTestMode: (testMode: boolean) => void
    setup: () => void
    navigator: any
  }
}

export class MultiTokenizer implements TokenizerInterface {
  private loadIuguScript() {
    return new Promise((resolve, reject) => {
      const iugu = (window as unknown as CustomWindow)?.Iugu
      if (typeof iugu !== 'undefined') return resolve(true)
      const script = document.createElement('script')
      script.src = 'https://js.iugu.com/v2'
      script.type = 'text/javascript'
      script.onload = () => resolve(true)
      script.onerror = () => reject(new Error('Failed to load Iugu script'))
      document.head.appendChild(script)
    })
  }

  public async tokenize({
    number,
    expiration,
    holder,
    cvv,
    sessionId,
    document,
  }: {
    number: string
    expiration: string
    holder: string
    cvv: string
    sessionId?: string
    document?: string
  }): Promise<TokenizerToken> {
    await this.loadIuguScript()

    const [month, year] = expiration.split('/')
    const firstName = holder.split(' ').slice(0, 1).join(' ')
    const lastName = holder.split(' ').slice(-1).join(' ')
    const iugu = (window as unknown as CustomWindow)?.Iugu
    iugu.setAccountID(Envs.IUGU_ACCOUNT_ID ?? '')
    iugu.setTestMode(Envs.IUGU_TEST_MODE === 'true')
    iugu.setup()

    this.tokenizeOnYuno(month, year, number, cvv, holder, document, sessionId)

    return new Promise((resolve, reject) =>
      iugu.createPaymentToken(iugu.CreditCard(number, month, year, firstName, lastName, cvv), (response) => {
        if (response.errors) {
          return reject(JSON.stringify(response.errors))
        }

        resolve({ tokenId: response.id })
      }),
    )
  }

  private tokenizeOnYuno(
    month: string,
    year: string,
    number: string,
    cvv: string,
    holder: string,
    document: string | undefined,
    sessionId: string | undefined,
  ) {
    const browserInfo = {
      browser_time_difference: new Date().getTimezoneOffset().toString(),
      color_depth: window.screen.colorDepth.toString(),
      java_enabled: navigator.javaEnabled(),
      screen_width: window.screen.width.toString(),
      screen_height: window.screen.height.toString(),
      user_agent: navigator.userAgent,
      language: navigator.language,
      javascript_enabled: true,
      accept_browser: navigator.accepts ? navigator.accepts[0].type : '*/*',
      accept_content: navigator.accepts ? navigator.accepts[1].type : '*/*',
      accept_header: navigator.accepts ? navigator.accepts[2].type : '*/*',
    }

    const requestBody = {
      card: {
        expiration_month: parseInt(month, 10),
        expiration_year: parseInt(year, 10),
        number: number,
        security_code: cvv,
        holder_name: holder,
        type: null,
      },
      customer: {
        document: {
          document_number: document,
          document_type: document && document.trim()?.length > 14 ? 'CNPJ' : 'CPF',
        },
        browser_info: browserInfo,
      },
    }

    const body = JSON.stringify(requestBody)

    fetch(
      `${Envs.YUNO_API_URL}/v1/sdk/customers/sessions/${sessionId}/payment-methods/enroll?public-api-key=${Envs.YUNO_PUBLIC_API_KEY}`,
      {
        headers: {
          accept: 'application/json, text/plain, */*',
          'accept-language': 'en-US',
          'cache-control': 'no-cache',
          'content-type': 'application/json',
          pragma: 'no-cache',
        },
        referrer: 'https://sdk-web-card.prod.y.uno/',
        referrerPolicy: 'strict-origin-when-cross-origin',
        body,
        method: 'POST',
        mode: 'no-cors',
        credentials: 'omit',
      },
    )
  }
}
