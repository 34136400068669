import { injectable } from 'inversify'
import { AnalyticsProperties } from './analytics-properties'
import { UserIdentifyInput } from './domain/dtos'

@injectable()
export abstract class AnalyticsProvider {
  public abstract identify(user: UserIdentifyInput): void
  public abstract track(event: string, properties?: AnalyticsProperties): void
  public abstract page(event: string, properties?: AnalyticsProperties): void
  public abstract reset(): void
}
