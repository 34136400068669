import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation } from '@tanstack/react-query'
import { DeactivateSubscriptionBatchInput } from '../../domain/dtos/deactivate-subscription-batch-input'
import { QueryKey } from '../../domain/enums/query-keys'
import { DeactivateSubscriptionBatch } from '../../usecases/deactivate-subscription-batch'

export const useDeactivateSubscriptionBatch = () => {
  const container = useContainer()

  return useMutation(
    [QueryKey.deactivateSubscriptionBatch],
    (input: DeactivateSubscriptionBatchInput) =>
      new DeactivateSubscriptionBatch(container.get(HttpClient)).execute(input),
    {
      retry: false,
    },
  )
}
