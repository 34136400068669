import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { GetCouponsInput } from '../domain/dtos/get-coupon-input'
import { GetCouponsOutput } from '../domain/dtos/get-coupon-output'
import { GeneralError } from '../domain/errors/general'
import { Envs } from '../envs'
import { GetOfferIds } from './get-offer-ids'

export class GetCoupons {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(input: GetCouponsInput, signal?: AbortSignal): Promise<GetCouponsOutput> {
    const response = await this.httpClient.request({
      method: HttpMethod.POST,
      url: `${Envs.BFF_WEB_URL}/coupons/list`,
      body: this.makeBody(input),
      signal,
    })

    if (response.statusCode === HttpStatusCode.OK || response.statusCode === HttpStatusCode.CREATED) {
      return response.data
    }

    throw new GeneralError()
  }

  private makeBody(input: GetCouponsInput) {
    const productIds = new GetOfferIds().execute({ offerIds: input.offerIds, offers: input.offers })
    const body = {
      filters: {
        offerIds: productIds,
        states: input.statuses,
        code: input.search?.toUpperCase(),
      },
      page: input.page,
      pageSize: input.pageSize,
      orderBy: input.orderBy,
      orderDirection: input.orderDirection,
    }

    return body
  }
}
