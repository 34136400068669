import { Card, CardContent, CardDescription, CardHeader, CardTitle, Switch } from '@hub-la/shadcn'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const TogglePdfWatermark = ({
  onChange,
  isLoading,
  defaultValue,
}: {
  onChange?: (checked: boolean) => void
  isLoading?: boolean
  defaultValue?: boolean
}) => {
  const [showThumbnailTitle, setShowThumbnailTitle] = useState<boolean | undefined>()
  const { t } = useTranslation()

  const handleToggle = useCallback(
    (checked: boolean) => {
      setShowThumbnailTitle(checked)
      if (onChange) {
        onChange(checked)
      }
    },
    [setShowThumbnailTitle, onChange],
  )

  useEffect(() => {
    if (!defaultValue) return
    setShowThumbnailTitle(defaultValue)
  }, [defaultValue])

  return (
    <Card className="w-full">
      <CardHeader>
        <CardTitle>{t('member-area.settings.togglePdfWatermark.title')}</CardTitle>
        <CardDescription>{t('member-area.settings.togglePdfWatermark.description')}</CardDescription>
      </CardHeader>

      <CardContent className="flex flex-col gap-4 p-6">
        <div className="flex items-center space-x-2">
          <Switch
            id="pdf-watermark"
            checked={showThumbnailTitle}
            onCheckedChange={handleToggle}
            disabled={isLoading}
            data-testid="switch-thumbnail"
          />

          <label
            htmlFor="pdf-watermark"
            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            {t('member-area.settings.togglePdfWatermark.options.thumbnail.label')}
          </label>
        </div>

        <p className="text-sm text-muted-foreground mt-3 mb-3">
          {t('member-area.settings.togglePdfWatermark.options.thumbnail.description')}
        </p>
      </CardContent>
    </Card>
  )
}

export default TogglePdfWatermark
