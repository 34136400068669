export class FormatCurrencyBRL {
  private value = 0

  public constructor(value: number) {
    this.value = value
  }

  public getFormattedCurrency(): string {
    return Number(this.value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
  }
}
