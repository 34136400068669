import { Lock } from 'lucide-react'
import Countdown from 'react-countdown'
import { useTranslation } from 'react-i18next'

type TimeToReleaseProps = {
  timeToRelease: Date
}

export const TimeToReleaseCard = ({ timeToRelease }: TimeToReleaseProps) => {
  const { t } = useTranslation()

  return (
    <div className="p-4 flex flex-col sm:flex-row bg-card rounded-lg shadow" data-testid="time-to-release-card">
      <div className="flex flex-1 items-center gap-4">
        <div className="p-2 bg-muted rounded">
          <Lock className="text-muted-foreground" size={24} />
        </div>

        <div className="flex flex-col">
          <p className="font-bold">{t('postDetail.releaseAt')}</p>

          <Countdown
            date={timeToRelease}
            renderer={({ days, hours, minutes, seconds }) => (
              <p className="text-muted-foreground">
                {days}d {hours}h {minutes}min {seconds}s
              </p>
            )}
          />
        </div>
      </div>
    </div>
  )
}
