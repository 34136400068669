import { container } from '@hub-la/fe-container'
import { FinancialStatement, FinancialStatementProvider } from '@hub-la/fe-financial-statement'

export const DashboardFinancialInit = () => {
  return (
    <FinancialStatementProvider container={container}>
      <FinancialStatement />
    </FinancialStatementProvider>
  )
}
