import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useQuery } from '@tanstack/react-query'
import { GetMembersAreaSettingsInput } from '../../domain/dtos/get-members-area'
import { GetMembersArea } from '../../usecases/get-members-area'

export const useGetMembersArea = (input: GetMembersAreaSettingsInput) => {
  const container = useContainer()
  return useQuery(
    ['get-members-area', input.productId],
    () => new GetMembersArea(container.get(HttpClient)).execute(input),
    {
      initialData: {},
      enabled: true,
      cacheTime: Infinity,
      refetchOnWindowFocus: false,
      retry: 3,
      retryDelay: 1000,
      onSuccess: async (data) => {
        return data
      },
    },
  )
}
