export const pt = {
  title: 'Minhas assinaturas',
  error: 'Houve um erro ao trazer as assinaturas, por favor recarregue a página ou entre em contato com o suporte.',
  activeSubscriptions: 'Ativas',
  inactiveSubscriptions: 'Inativas',
  subscriptionStatus: {
    active: 'Ativa',
    inactive: 'Inativa',
    canceled: 'Cancelada',
    incomplete: 'Incompleta',
  },
  invoiceStatus: {
    draft: 'Agendada',
    pending: 'Em aberto',
    paid: 'Paga',
    expired: 'Não paga',
    refunded: 'Reembolsada',
    in_protest: 'Contestada',
    chargeback: 'Chargeback',
    canceled: 'Cancelada',
  },
  actions: {
    cancel: 'Cancelar renovação',
    reactivate: 'Reativar renovação',
    reactivateFooter: 'Após a data de vencimento, sua assinatura se tornará inativa.',
  },
  table: {
    emptyState: 'Nenhuma fatura encontrada',
    total: 'Valor',
    status: 'Status',
    paidAt: 'Data de pagamento',
  },
  invoiceHistory: 'Histórico de cobranças',
  empty: 'Nenhuma assinatura encontrada',
  cancelSubscriptionHelper:
    'Se você cancelar sua assinatura agora, ainda poderá acessar o produto até <0>{{nextDueAt}}</0><1>.</1>',
  back: 'Voltar',
  cardBrand: {
    0: '',
    1: '',
    2: 'Mastercard',
    3: 'Visa',
    4: 'Elo',
    5: 'Amex',
    CARD_BRAND_UNSPECIFIED: '',
    CARD_BRAND_OTHER: '',
    CARD_BRAND_MASTERCARD: 'Mastercard',
    CARD_BRAND_VISA: 'Visa',
    CARD_BRAND_ELO: 'Elo',
    CARD_BRAND_AMEX: 'Amex',
  },
  header: {
    date: {
      active: 'Data de renovação',
      canceled: 'Data de encerramento',
      inactive: 'Data de encerramento',
      free: 'Data de encerramento',
    },
  },
  subscription: {
    date: {
      active: 'Renova',
      canceled: 'Encerra',
      inactive: 'Venceu',
      free: 'Encerra',
    },
  },
  planType: {
    monthly: 'Plano mensal',
    quarterly: 'Plano trimestral',
    semiannually: 'Plano semestral',
    annually: 'Plano anual',
  },
  planInterval: {
    monthly: 'Mensal',
    quarterly: 'Trimestral',
    semiannually: 'Semestral',
    annually: 'Anual',
  },
  billingCycle: {
    monthly: 'mês',
    quarterly: 'trimestre',
    semiannually: 'semestre',
    annually: 'ano',
  },
  planDetails: {
    title: 'Plano de assinatura',
    price: 'Preço',
    upgrade: 'Upgrade de plano',
  },
  paymentMethod: {
    pix: 'Pix',
    last4AndCardBrand: '{{brand}} •••• {{last4}}',
    last4Only: 'Cartão •••• {{last4}}',
    creditCard: 'Cartão de crédito',
  },
  upgradePlanModal: {
    title: 'Upgrade de plano',
    cancelUpgrade: 'Cancelar upgrade de plano',
    formTitle: 'Novo plano',
    subtitle:
      'Você está atualmente no <0>{{plan}}</0><1>, pagando <0>{{price}}</0><1> por <0>{{periodicity}}</0><1>. Seu plano renova dia <0>{{renewAt}}</0><1>.</1>',
    summary: 'Upgrade de plano {{from}} para {{to}}',
    credits: 'Créditos do {{plan}}',
    button: 'Pagar agora',
    buttonSubtitle:
      'Pagando com {{method}}. Para usar um método de pagamento diferente, primeiro altere-o na tela de assinatura e, em seguida, faça o upgrade do plano.',
    pix: {
      title:
        '<0>Escaneie o QR Code</0><1> ou utilize o <0>Pix Copia e Cola</0><1> no aplicativo que vai fazer o pagamento.</1>',
      copyCode: 'Copiar código',
      codeCopiedSuccessfully: 'Codigo copiado com sucesso!',
      footer: 'Seu pagamento será confirmado automaticamente.',
    },
    creditCard: {
      title: 'Ainda estamos processando seu pagamento',
      subtitle: 'O pagamento do seu upgrade de plano com {{method}} está demorando mais do que o normal.',
      footer: 'Aguarde a confirmação, ou cancele o upgrade para tentar novamente.',
    },
    paymentFailed: {
      title: 'Falha no pagamento',
      subtitle: 'Não conseguimos cobrar pelo método selecionado, portanto o upgrade não foi realizado.',
    },
    success: {
      title: 'Upgrade realizado com sucesso!',
      subtitle: 'Seu pagamento foi aprovado e sua assinatura agora está no plano selecionado.',
    },
    errors: {
      INVALID_PAYER_FOR_SUBSCRIPTION: 'Você só pode acessar assinaturas onde você é o pagador',
      SUBSCRIPTION_MISSING_DATA: 'Certifique-se de fornecer todos os dados necessários da assinatura',
      UNMAPPED_BILLING_CYCLE: 'O ciclo de cobrança selecionado não é reconhecido',
      SUBSCRIPTION_NOT_RECURRING: 'Atualizações estão disponíveis apenas para assinaturas recorrentes',
      SUBSCRIPTION_MISSING_BILLING_CYCLE: 'Certifique-se de que sua assinatura tenha um ciclo de cobrança',
      SUBSCRIPTION_WITH_TOO_MANY_OFFERS: 'Atualmente, não oferecemos suporte a assinaturas com várias ofertas',
      NO_PLAN_AVAILABLE_FOR_UPGRADE: 'Não há planos disponíveis para upgrade',
      UPGRADE_NOT_FOUND_AT_SUBSCRIPTION: 'Informações de upgrade não encontradas na assinatura',
      UPGRADE_INVOCE_REF_NOT_FOUND: 'Referência da fatura para upgrade não encontrada',
      INVALID_PAYMENT_METHOD: 'Seu método de pagamento é inválido ou não é suportado',
      PAYMENT_OPTION_NOT_FOUND: 'Não há opções de parcelamento disponíveis',
      SUBSCRIPTION_GET_FAILED: 'Não foi possível obter informações da assinatura',
      SUBSCRIPTION_UPGRADE_FAILED: 'Algo deu errado, por favor tente novamente mais tarde',
      SUBSCRIPTION_HAS_SMART_INSTALLMENTS: 'Não é possível fazer upgrade de assinaturas com parcelamento inteligente',
      GATEWAY_NOT_SUPPORTED: 'Gateway de pagamento não suportado',
      GENERAL: 'Algo deu errado, por favor tente novamente mais tarde',
    },
  },
  changePaymentMethodModal: {
    title: 'Alterar método de pagamento',
    subtitle: 'Escolha o novo método de pagamento desejado.',
    cancel: 'Cancelar',
    save: 'Salvar',
    pixDisabled: 'Não é possível selecionar o PIX como método de pagamento de compras e assinaturas parceladas.',
    paymentMethod: {
      pix: 'Pix',
      creditCard: 'Cartão de crédito',
    },
    documentLabelCreditCard: 'CPF/CNPJ do titular do cartão',
    documentLabel: 'CPF/CNPJ',
    creditCard: {
      number: 'Número do cartão',
      holder: 'Nome no cartão',
      errors: {
        number: 'Número do cartão inválido',
        holder: 'Nome no cartão inválido',
        expiration: 'Data de expiração inválida',
        cvv: 'CVV inválido',
        token: 'Falha ao tokenizar cartão de crédito',
      },
    },
    errors: {
      SUBSCRIPTION_MISSING_DATA: 'Certifique-se de fornecer todos os dados necessários da assinatura',
      SUBSCRIPTION_GET_FAILED: 'Não foi possível obter informações da assinatura',
      INVALID_PAYER_FOR_SUBSCRIPTION: 'Não é possível alterar o método de pagamento de uma assinatura que não é sua',
      PAYMENT_OPTION_NOT_FOUND: 'Não há opções de pagamento disponíveis para a troca',
      UPDATE_FUNDING_SOURCE_ERROR: 'Não foi possível atualizar o método de pagamento',
      NO_PAYMENT_METHOD_AVAILABLE: 'Não há métodos de pagamento disponíveis',
      GENERAL: 'Algo deu errado, por favor tente novamente mais tarde',
    },
    success: 'Método alterado com sucesso!',
  },
  pendingInvoiceCard: {
    overdueCard: '<0>Pagamento recusado:</0> <1>{{reason}}</1>',
    unpaidPix: '<0>Realize o pagamento da sua próxima renovação</0>',
    overduePix: '<0>Realize o pagamento da renovação.</0> <1>Sua assinatura será desativada dia {{cancelEta}}</1>',
    inactivePixSubscription: '<0>Pagamento da renovação não encontrada.</0> <1>Sua assinatura foi desativada</1>',
    product: 'Fatura de {{productName}}',
    date: {
      overdue: 'Venceu',
      unpaid: 'Vence',
    },
    actions: {
      pay: 'Pagar agora',
      download: 'Ver fatura',
    },
  },
}
