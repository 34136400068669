import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { GetShortLinksOutput } from '../../domain/dtos/get-short-links-output'
import { GetShortLinksResponse } from '../../domain/dtos/get-short-links-response'
import { GeneralError } from '../../domain/errors/general'
import { Envs } from '../../envs'

export class GetShortLinks {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(productId: string, signal?: AbortSignal): Promise<GetShortLinksOutput> {
    const response = await this.httpClient.request({
      method: HttpMethod.GET,
      url: `${Envs.BFF_PRODUCT_URL}/products/${productId}/short-links`,
      signal,
    })

    if (response.statusCode === HttpStatusCode.OK) {
      return this.makeResponse(response.data)
    }

    throw new GeneralError()
  }

  private makeResponse(response: GetShortLinksResponse): GetShortLinksOutput {
    const items = response.items ?? []

    return {
      items: items,
      total: items.length,
    }
  }
}
