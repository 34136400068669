import { useContainer } from '@hub-la/fe-container'
import { Analytics, AnalyticsEvent } from '@hub-la/fe-core-analytics'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@hub-la/shadcn'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GetDateRangeFromPeriod } from '../../../usecases/get-date-range-from-period'
import { GetPeriodFromDateRange } from '../../../usecases/get-period-from-date-range'

type TimeRangePickerProps = {
  initialRange?: { from: string; to: string }
  onChangeFromTo: (from: string, to: string) => void
}

export const TimeRangePicker: React.FC<TimeRangePickerProps> = ({ initialRange, onChangeFromTo }) => {
  const container = useContainer()
  const analytics = container.get(Analytics)
  const { t } = useTranslation()

  const timePeriods = ['7', '15', '30', '60', '90']

  const getPeriodFromDateRange = new GetPeriodFromDateRange()
  const getDateRangeFromPeriod = new GetDateRangeFromPeriod()

  const initialPeriod = initialRange ? String(getPeriodFromDateRange.execute(initialRange)) : ''
  const [selectedPeriod, setSelectedPeriod] = useState(initialPeriod)

  const handleRange = (value: string) => {
    analytics.track(AnalyticsEvent.WALLET.INTERVAL_CHANGED, { interval: `LAST_${value}_DAYS` })
    const dates = getDateRangeFromPeriod.execute(Number(value))
    setSelectedPeriod(value)
    onChangeFromTo(dates.from, dates.to)
  }

  const isInvalidPeriod = !timePeriods.includes(selectedPeriod)

  return (
    <Select value={isInvalidPeriod ? '' : selectedPeriod} onValueChange={handleRange} data-testid="time-range-picker">
      <SelectTrigger className="w-[160px]">
        <SelectValue placeholder={t('filters.date')} />
      </SelectTrigger>
      <SelectContent>
        {timePeriods.map((range) => (
          <SelectItem key={range} value={range} data-testid={`range-option-${range}`}>
            {t('filters.lastXDays', { range })}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}
