import { Badge, Card, CardContent, CardHeader, CardTitle, Skeleton } from '@hub-la/shadcn'
import { Check, CircleX, Folder, X } from 'lucide-react'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useGetListGroups } from '../../../hooks/use-get-list-groups'
import { useGetSections } from '../../../hooks/use-get-sections'
import MessagingIcon from '../../../pages/create-cohort/messaging-icon'
import { CohortSchemaValidationType } from '../../../validations/cohort-validation'

interface Props {
  productId: string
  mainOfferId?: string
}

export const AccessesPreview: React.FC<Props> = ({ productId, mainOfferId }) => {
  const { t } = useTranslation()

  const { data: groups, isFetching: isFetchingGroups } = useGetListGroups(mainOfferId)
  const { data: sections, isFetching: isFetchingSections } = useGetSections({ productId })

  const { watch } = useFormContext<CohortSchemaValidationType>()
  const sectionsSelected = watch('sections')
  const groupsSelected = watch('groups')

  const groupsTotal = groups?.length ?? 0
  const sectionsTotal = sections?.length ?? 0

  const showNotIncluded =
    (sectionsTotal > 0 && sectionsTotal !== sectionsSelected?.length) ||
    (groupsTotal > 0 && groupsTotal !== groupsSelected?.length)

  if (isFetchingSections || isFetchingGroups) {
    return (
      <div className="flex flex-col gap-12">
        <div className="flex flex-col gap-1">
          <h3 className="text-xl font-semibold">{t('cohort.accessesPreview.title')}</h3>
          <p className="text-muted-foreground">{t('cohort.accessesPreview.description')}</p>
        </div>
        <div className="py-6 flex items-center justify-center">
          <Skeleton className="h-12 w-12 rounded-full" />
        </div>
      </div>
    )
  }

  const nothingSelected =
    (!sectionsSelected || sectionsSelected.length === 0) && (!groupsSelected || groupsSelected.length === 0)

  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col gap-1">
        <h3 className="text-xl font-semibold">{t('cohort.accessesPreview.title')}</h3>
        <p className="text-muted-foreground">{t('cohort.accessesPreview.description')}</p>
      </div>

      <div className="grid grid-cols-2 gap-4 ">
        <div className="col-span-2 md:col-span-1">
          <Card>
            <CardHeader className="bg-green-100 py-4 text-green-900 rounded-t-md">
              <CardTitle className="text-base flex flex-row gap-2 items-center">
                <Check className="h-4 w-4" /> {t('cohort.accessesPreview.title')}
              </CardTitle>
            </CardHeader>
            <CardContent className="pt-6">
              <div className="flex flex-wrap gap-3">
                {nothingSelected ? (
                  <div className="flex items-center gap-2">
                    <CircleX className="h-4 w-4" />
                    <span className="text-sm">{t('cohort.nothingSelected')}</span>
                  </div>
                ) : (
                  <>
                    {sectionsSelected?.map((sectionSelected) => {
                      const section = sections?.find((section) => section.id === sectionSelected)
                      if (!section) return null

                      return (
                        <Badge key={section?.id} variant="secondary">
                          <Folder className="w-3 h-3 fill-green-500 text-green-500 mr-1.5" />
                          {section?.name}
                        </Badge>
                      )
                    })}
                    {groupsSelected?.map((groupSelected) => {
                      const group = groups?.find((group) => group.id === groupSelected)
                      if (!group) return null

                      return (
                        <Badge key={group?.id} variant="secondary">
                          <MessagingIcon type={group?.resourceType} />
                          {group?.name}
                        </Badge>
                      )
                    })}
                  </>
                )}
              </div>
            </CardContent>
          </Card>
        </div>
        <div className="col-span-2 md:col-span-1">
          <Card>
            <CardHeader className="bg-amber-100 py-4 text-amber-900 rounded-t-md">
              <CardTitle className="text-base flex flex-row gap-2 items-center">
                <X className="h-4 w-4" /> {t('cohort.accessesPreview.notIncluded.title')}
              </CardTitle>
            </CardHeader>
            <CardContent className="pt-6">
              <div className="flex flex-wrap gap-3">
                {sections
                  ?.filter((section) => !sectionsSelected?.includes(section.id))
                  .map((section) => (
                    <Badge key={section?.id} variant="secondary">
                      <Folder className="w-3 h-3 fill-green-500 text-green-500 mr-1.5" />
                      {section?.name}
                    </Badge>
                  ))}
                {groups
                  ?.filter((group) => !groupsSelected?.includes(group.id))
                  .map((group) => (
                    <Badge key={group?.id} variant="secondary">
                      <MessagingIcon type={group?.resourceType} />
                      {group?.name}
                    </Badge>
                  ))}
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  )
}
