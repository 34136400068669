import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { InfiniteData, useMutation, useQueryClient } from '@tanstack/react-query'
import { DeleteCommentInput } from '../../domain/dtos/delete-comment-input'
import { GetCommentsResponse } from '../../domain/dtos/get-comments-response'
import { QueryKey } from '../../domain/enums/query-keys'
import { DeleteComment } from '../../usecases/delete-comment'
import { useDiscussion } from '../providers/discussion'

export const useDeleteComment = ({ isReply, parentCommentId }: { isReply?: boolean; parentCommentId?: string }) => {
  const container = useContainer()
  const queryClient = useQueryClient()

  const discussion = useDiscussion()

  /**@todo: invalidate get comments query or update comments items */
  return useMutation(
    [QueryKey.deleteComment],
    (input: DeleteCommentInput) =>
      new DeleteComment(container.get(HttpClient)).execute({ ...input, postId: discussion.metadata.postId }),
    {
      retry: false,
      onSuccess: (data, input) => {
        if (isReply) {
          queryClient.setQueryData<InfiniteData<GetCommentsResponse>>(
            [QueryKey.getCommentReplies, parentCommentId],
            (data): InfiniteData<GetCommentsResponse> => {
              if (!data) {
                return {
                  pages: [],
                  pageParams: [],
                }
              }

              data.pages = data?.pages.map((page) => {
                page.items = page.items.filter((comment) => comment.id !== input.commentId)

                return page
              })

              return data
            },
          )

          queryClient.setQueryData<InfiniteData<GetCommentsResponse>>(
            [QueryKey.getComments, discussion?.metadata?.postId],
            (data): InfiniteData<GetCommentsResponse> => {
              if (!data) {
                return {
                  pages: [],
                  pageParams: [],
                }
              }

              data.pages = data?.pages.map((page) => {
                const oldItems = page.items ?? []
                const updatedItems = oldItems.map((comment) => {
                  if (comment.id === parentCommentId) {
                    return {
                      ...comment,
                      repliesCount: comment.repliesCount - 1,
                    }
                  }

                  return comment
                })

                return {
                  ...page,
                  items: updatedItems,
                }
              })
              return data
            },
          )

          return
        }

        queryClient.setQueryData<InfiniteData<GetCommentsResponse>>(
          [QueryKey.getComments, discussion?.metadata?.postId],
          (data): InfiniteData<GetCommentsResponse> => {
            if (!data) {
              return {
                pages: [],
                pageParams: [],
              }
            }

            // If delete comment, we decrease it to the first page because counter use the first page
            data.pages[0].total -= 1

            data.pages = data?.pages.map((page) => {
              const oldItems = page.items ?? []
              const itemsWithoutDeletedComment = oldItems.filter((comment) => comment.id !== input.commentId)

              return {
                ...page,
                items: itemsWithoutDeletedComment,
              }
            })
            return data
          },
        )
      },
    },
  )
}
