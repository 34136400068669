import { container } from '@hub-la/fe-container'
import { WhitelistPage, WhitelistProvider } from '@hub-la/fe-whitelist'

export const WhitelistInit = () => {
  return (
    <WhitelistProvider container={container}>
      <WhitelistPage />
    </WhitelistProvider>
  )
}
