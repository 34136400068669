import React from 'react'

interface OfferSectionProps {
  title?: string
  children: React.ReactNode
}

export const OfferSection: React.FC<OfferSectionProps> = ({ title, children }) => {
  return (
    <div className="space-y-4">
      {title && <h2 className="text-xl font-semibold">{title}</h2>}
      {children}
    </div>
  )
}
