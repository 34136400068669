import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject, injectable } from 'inversify'
import { Envs } from '../envs'

@injectable()
export class DeleteSection {
  public constructor(@inject(HttpClient) private readonly httpClient: HttpClient) {}

  public async execute(sectionId: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .request({
          url: `${Envs.BFF_MEMBERS_AREA_URL}/hub/sections/${sectionId}`,
          method: HttpMethod.DELETE,
        })
        .then((response) => {
          if (![HttpStatusCode.NO_CONTENT, HttpStatusCode.OK].includes(response.statusCode)) {
            return reject(`Ocorreu um erro ao deletar a seção ${sectionId}`)
          }
          return resolve(sectionId)
        })
    })
  }
}
