import { container } from '@hub-la/fe-container'
import { UserInvoicesDownloadPage, UserInvoicesProvider } from '@hub-la/fe-user-invoices'
import { withRoleplayRedirect } from '../components/roleplay-redirect'

export const UserInvoicesDownloadInit = withRoleplayRedirect(() => {
  return (
    <UserInvoicesProvider container={container}>
      <UserInvoicesDownloadPage />
    </UserInvoicesProvider>
  )
})
