export class CreditCardExpiration {
  public constructor(private readonly value: string) {}

  public static build(value: string): CreditCardExpiration {
    return new CreditCardExpiration(value)
  }

  public isValid(): boolean {
    try {
      const [month, y] = this.value.split('/').map((d) => parseInt(d, 10))

      let year = y
      const now = new Date()
      if (year >= 0 && year < 100) {
        const fullYear = now.getFullYear()
        const shortYear = fullYear % 100
        const m1 = fullYear - shortYear
        const m2 = m1 - 100

        const opt1 = year + m1
        const opt2 = year + m2

        year = Math.abs(fullYear - opt1) < Math.abs(fullYear - opt2) ? opt1 : opt2
      }

      const date = new Date(`${year}/${month}/${new Date(year, month, 0).getDate()}`)

      if (!(date instanceof Date) || isNaN(date.getTime()) || date.getTime() < now.getTime()) {
        return false
      }
    } catch (e) {
      return false
    }
    return true
  }

  public getValue() {
    return this.value
  }
}
