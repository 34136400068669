import { OfferAndCohortName } from '@hub-la/sdk-bff-product'
import {
  Alert,
  AlertDescription,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Input,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  toast,
} from '@hub-la/shadcn'
import { FormikProvider, useFormik } from 'formik'
import { Info } from 'lucide-react'
import { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { AddFreeMemberForm } from '../../../domain/dtos/add-free-member-form'
import { useGetOffersAndCohorts } from '../../hooks/members/use-get-offers-and-cohorts'
import { usePostAddFreeMembers } from '../../hooks/members/use-post-free-members'
import { addFreeMemberValidationSchema } from '../../validations/add-free-member-validation'
import { EmailsAutocomplete } from './emails-autocomplete'

type AddFreeMemberModalProps = {
  onClose: () => void
  open: boolean
  productId: string
  onSubmit: () => void
  isEvent?: boolean
}

export const AddFreeMemberModal: React.FC<AddFreeMemberModalProps> = ({
  onClose,
  open,
  onSubmit,
  productId,
  isEvent,
}) => {
  const { t } = useTranslation()
  const { data: offersAndCohorts } = useGetOffersAndCohorts(productId)

  const formik = useFormik<AddFreeMemberForm>({
    initialValues: {
      emails: [],
      days: null,
      lifetime: !!isEvent,
      offerId: (offersAndCohorts?.offers ?? []).find((offer) => offer.isOfferDefault)?.offerId ?? '',
    },
    onSubmit: (values) => {
      addFreeMembers({ ...values, productId })
    },
    validationSchema: addFreeMemberValidationSchema,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
  })

  const translationKey = isEvent ? 'addFreeParticipantsModal' : 'addFreeMembersModal'

  const memoizedOnClose = useCallback(() => {
    onClose()
    formik.resetForm()
  }, [onClose, formik])

  const memoizedOnSubmit = useCallback(() => {
    onSubmit()
    formik.resetForm()
  }, [onSubmit, formik])

  const {
    mutateAsync: addFreeMembers,
    data: freeMembers,
    isLoading: loadingAddFreeMembers,
  } = usePostAddFreeMembers({
    onSuccess: () => {
      memoizedOnSubmit()
      formik.resetForm()
      toast({
        variant: 'default',
        title: t(`${translationKey}.snackbar.success`),
      })
    },
    onError: () => {
      const errorFreeMembers = (freeMembers?.status ?? []).filter((member) => !member.isCreated)
      if (memoizedOnClose) {
        memoizedOnClose()
        formik.resetForm()
        toast({
          variant: 'destructive',
          title: t(`${translationKey}.snackbar.error.title`),
          description: (
            <Trans
              i18nKey={`${translationKey}.snackbar.error.description`}
              components={[<strong />]}
              values={{
                users: errorFreeMembers
                  ?.map((member) => `- ${member.receiverEmail} (${t(`singleInviteValidationEnum.${member.reason}`)})`)
                  .join(`\n`),
              }}
            />
          ),
        })
      }
    },
  })

  const getOptionName = (offer?: OfferAndCohortName) => {
    if (!offer) return ''
    return `${offer.offerName} ${offer.isOfferDefault ? '(Padrão)' : ''} - ${offer.cohortName}`
  }

  return (
    <FormikProvider value={formik}>
      <Dialog open={open} onOpenChange={memoizedOnClose}>
        <DialogContent className="sm:max-w-[510px]">
          <DialogHeader>
            <DialogTitle>{t(`${translationKey}.title`)}</DialogTitle>
          </DialogHeader>
          <div className="space-y-6">
            <p className="text-sm">{t(`${translationKey}.description`)}</p>

            <Select onValueChange={(value) => formik.setFieldValue('offerId', value)} value={formik.values.offerId}>
              <SelectTrigger className="w-full">
                <SelectValue placeholder={t(`${translationKey}.inputs.cohort.label`)} />
              </SelectTrigger>
              <SelectContent className="z-[6000]">
                {(offersAndCohorts?.offers ?? []).map((offer) => (
                  <SelectItem key={offer.offerId} value={offer.offerId}>
                    {getOptionName(offer)}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>

            <EmailsAutocomplete
              error={formik.touched.emails && !!formik.errors.emails}
              helperText={!!formik.errors.emails && formik.touched.emails ? t(formik.errors.emails) : null}
            />

            {!isEvent && (
              <Input
                id="days"
                type="number"
                placeholder={t(`${translationKey}.inputs.days.label`)}
                min={1}
                disabled={formik.values.lifetime}
                className={formik.errors.days && formik.touched.days ? 'border-red-500' : ''}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.days ?? ''}
              />
            )}

            {!isEvent && (
              <div className="flex items-center space-x-2">
                <Checkbox
                  id="lifetime"
                  checked={formik.values.lifetime}
                  onCheckedChange={(checked) => formik.setFieldValue('lifetime', checked)}
                />
                <label
                  htmlFor="lifetime"
                  className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                >
                  {t(`${translationKey}.inputs.lifetimeCheckbox`)}
                </label>
              </div>
            )}

            <Alert className="bg-muted">
              <Info className="h-4 w-4" />
              <AlertDescription>{t(`${translationKey}.alert`)}</AlertDescription>
            </Alert>
          </div>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => {
                memoizedOnClose()
                formik.resetForm()
              }}
              disabled={loadingAddFreeMembers}
            >
              {t(`${translationKey}.actions.cancel`)}
            </Button>
            <Button onClick={() => formik.submitForm()} disabled={!formik.isValid || loadingAddFreeMembers}>
              {t(`${translationKey}.actions.submit`)}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </FormikProvider>
  )
}
