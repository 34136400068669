import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'

import { useQuery } from '@tanstack/react-query'
import { GetOfferInput } from '../../domain/dtos/get-offer-input'
import { QueryKey } from '../../domain/enums/query-key.enum'
import { GetOffer } from '../../usecases/get-offer.usecase'

export const useGetOffer = (input?: GetOfferInput) => {
  const container = useContainer()

  return useQuery([QueryKey.getOffer], ({ signal }) => new GetOffer(container.get(HttpClient)).execute(input, signal), {
    refetchOnWindowFocus: false,
    retry: 3,
  })
}
