export enum UploadStatus {
  STARTED = 'STARTED',
  NO_CALLED = 'NO_CALLED',
  LOADED = 'LOADED',
  LOADING = 'LOADING',
  CANCELED = 'CANCELED',
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  FILE_NOT_ALLOWED = 'FILE_NOT_ALLOWED',
  PROCESSING = 'PROCESSING',
  SUCCESS = 'SUCCESS',
}
