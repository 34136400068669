import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { GeneralError } from '../domain/errors/general'
import { makeOfferInput } from '../domain/mappers/make-offer-input'
import { Envs } from '../envs'
import { OfferSchemaValidationType } from '../presentation/validations/offer-validation'

export class PostOffer {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(input: OfferSchemaValidationType, signal?: AbortSignal) {
    const response = await this.httpClient.request({
      method: HttpMethod.POST,
      url: `${Envs.BFF_PRODUCT_URL}/products/${input.productId}/offers`,
      signal,
      body: makeOfferInput(input),
    })

    if ([HttpStatusCode.OK, HttpStatusCode.CREATED].includes(response.statusCode)) {
      return response.data
    }

    if (response.data?.errorCode) {
      throw new Error(response.data.errorCode.replace(/:/g, '-'))
    }

    throw new GeneralError()
  }
}
