import { object, string } from 'yup'
import { Phone } from '../../domain/vos/phone'

export const acceptInviteValidationSchema = () => {
  return object({
    email: string().email().required(),
    fullName: string()
      .required('invitePage.errors.fullName.required')
      .matches(/.*\s.*/, 'invitePage.errors.fullName.firstAndLastName'),
    phoneNumber: string()
      .required('invitePage.errors.phoneNumber.required')
      .test({
        message: 'invitePage.errors.phoneNumber.invalid',
        test: function (value) {
          return Phone.build(String(value)).isValid()
        },
      }),
  });
}
