import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { GetPendingInvitesOutput } from '../../domain/dtos/get-pending-invites-output'
import { PostAcceptInviteInput } from '../../domain/dtos/post-accept-invite-input'
import { QueryKey } from '../../domain/enums/query-key'
import { PostAcceptInvite } from '../../usecases/post-accept-invite'

export const usePostAcceptInvite = ({ onSuccess }: { onSuccess?: () => void }) => {
  const queryClient = useQueryClient()
  const container = useContainer()

  return useMutation<null, Error, PostAcceptInviteInput, unknown>(
    [QueryKey.postAcceptInvite],
    (input: PostAcceptInviteInput) => new PostAcceptInvite(container.get(HttpClient)).execute(input),
    {
      retry: false,
      onSuccess: (_, variables) => {
        queryClient.setQueryData<GetPendingInvitesOutput | undefined>([QueryKey.getPendingInvites], (data) => {
          if (!data) {
            return data
          }

          return {
            ...data,
            items: data.items.filter((invite) => invite.id !== variables.inviteId),
          }
        })

        if (onSuccess) {
          onSuccess()
        }
      },
    },
  )
}
