import { Button, TableEmptyState, useToast } from '@hub-la/shadcn'
import isEmpty from 'lodash/isEmpty'
import { ArrowLeft } from 'lucide-react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useGetPendencies } from '../../hooks/use-get-pendencies'
import { AppHeader } from './components/app-header'
import { PendingInvoices } from './components/pending-invoices'
import { Loading } from './loading'

export const UserPendenciesPage = () => {
  const history = useHistory()
  const { toast } = useToast()
  const { t } = useTranslation()

  const { data = { overdue: [], unpaid: [] }, error, isFetching } = useGetPendencies()
  const isDataEmpty = isEmpty(data.overdue) && isEmpty(data.unpaid)
  const canRenderEmptyState = !isFetching && isDataEmpty

  useEffect(() => {
    if (error) {
      toast({
        description: t((error as Error)?.message) ?? '',
        variant: 'destructive',
      })
    }
  }, [error, toast, t])

  return (
    <div className="mb-4 flex flex-col container gap-4">
      <AppHeader />

      {isFetching ? (
        <Loading />
      ) : (
        <div className="w-full flex flex-col gap-4">
          <Button variant="ghost" onClick={() => history.goBack()} className="w-min gap-2">
            <ArrowLeft className="h-4 w-4" />
            {t('back')}
          </Button>

          <h1 className="text-3xl font-bold">{t('title')}</h1>

          {canRenderEmptyState ? (
            <TableEmptyState title={t('empty')} />
          ) : (
            <>
              {data.overdue.length > 0 && (
                <PendingInvoices title="overdue.title" type="overdue" invoices={data.overdue} />
              )}

              {data.unpaid.length > 0 && <PendingInvoices title="unpaid.title" type="unpaid" invoices={data.unpaid} />}
            </>
          )}
        </div>
      )}
    </div>
  )
}
