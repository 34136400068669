import React from 'react'
import { useTranslation } from 'react-i18next'

type ErrorComponentProps = {
  error?: string
}

export const ErrorComponent: React.FC<ErrorComponentProps> = ({ error }) => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col">
      <div data-testid="empty-state" className="w-full flex flex-col justify-center items-center py-30 px-8 h-full">
        <h2 className="text-xl font-semibold mt-6 text-center">{t(`upgradePlanModal.errors.${error}`)}</h2>
      </div>
    </div>
  )
}
