import { DeleteAsset } from '@hub-la/fe-asset'
import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation } from '@tanstack/react-query'
import { QueryKey } from '../../domain/enum/query-key'

export const useDeleteAsset = () => {
  const container = useContainer()

  return useMutation([QueryKey.deletePost], (assetId: string) =>
    new DeleteAsset(container.get(HttpClient)).execute(assetId),
  )
}
