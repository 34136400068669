import { Card, CardContent, CardHeader, CardTitle, Separator } from '@hub-la/shadcn'
import { useTranslation } from 'react-i18next'
import { Account, BillingAddress } from '../../../domain/dtos/account'
import { CardDetail } from '../../../domain/dtos/card-detail'
import { PaymentMethod } from '../../../domain/enums/payment-method'

type PaymentInfoRowProps = {
  label: string
  value: string | number | null | undefined
}

const PaymentInfoRow = ({ label, value }: PaymentInfoRowProps) => (
  <div className="flex gap-2 justify-between items-start">
    <p className="text-sm font-normal text-muted-foreground">{label}</p>

    <p className="max-w-sm text-sm font-medium text-right">{value || '–'}</p>
  </div>
)

type ChargeDetailProps = {
  payer: Account
  detail: {
    invoiceId: string
    createdAt: string
    paidAt?: string
    paymentMethod: PaymentMethod
    installments: number
    cardDetails?: CardDetail
    billingAddress?: BillingAddress
  }
}

export const ChargeDetail = ({ payer, detail }: ChargeDetailProps) => {
  const { t } = useTranslation()

  const handleLocalDatetime = (datetime: string | undefined) => {
    if (!datetime) return undefined

    const date = new Date(datetime)
    return date
      .toLocaleDateString('pt-BR', {
        weekday: 'short',
        day: '2-digit',
        month: 'short',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZone: 'America/Sao_Paulo',
      })
      .replace(/,/g, '')
      .replace(/\sde\s/g, ' ')
  }

  const handlePaymentMethodInfo = () => {
    if (detail.paymentMethod === PaymentMethod.CREDIT_CARD) {
      const cardDetails = detail.cardDetails

      return cardDetails ? `${t(`cardBrand.${cardDetails.brand}`)} •••• ${cardDetails.last4}` : 'Cartão de crédito'
    } else if (detail.paymentMethod === PaymentMethod.PIX || detail.paymentMethod === PaymentMethod.BOLETO) {
      return t(`paymentMethods.${detail.paymentMethod}`)
    }

    return t('paymentMethods.unknown')
  }

  const handleCardExpirationDate = () => {
    const { expMonth, expYear } = detail.cardDetails || {}
    if (!expMonth || !expYear) return '-'

    return `${expMonth} / ${expYear}`
  }

  const handleDocument = () => {
    const document = payer.identity?.document

    if (!document) return undefined

    // CPF
    if (document.length === 11) {
      return document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
    }

    // CNPJ
    if (document.length === 14) {
      return document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
    }

    return document
  }

  const handleAddress = () => {
    const address = detail.billingAddress

    if (!address) return undefined

    const hasNumber = address.number && address.number.length > 0
    const hasComplement = address.complement && address.complement.length > 0

    return `${address.street}${hasNumber ? `, ${address.number}` : ''} ${
      hasComplement ? `${address.complement}` : ''
    } - ${address.city} - ${address.state}, ${address.postalCode}`
  }

  const handleOrigin = () => {
    if (!detail.billingAddress) return undefined

    const displayNames = new Intl.DisplayNames(['pt'], { type: 'region' })

    return displayNames.of(detail.billingAddress.countryCode)
  }

  const installments = `${detail.installments}x`

  return (
    <Card>
      <CardHeader className="py-4 px-4">
        <CardTitle className="text-base">{t('invoiceDetail.chargeDetail.title')}</CardTitle>
      </CardHeader>

      <Separator />

      <CardContent className="pt-6 flex flex-col gap-3">
        <PaymentInfoRow label={t('invoiceDetail.chargeDetail.invoiceId')} value={detail.invoiceId} />

        <PaymentInfoRow
          label={t('invoiceDetail.chargeDetail.createdAt')}
          value={handleLocalDatetime(detail.createdAt)}
        />

        <PaymentInfoRow label={t('invoiceDetail.chargeDetail.paidAt')} value={handleLocalDatetime(detail.paidAt)} />
      </CardContent>

      <Separator />

      <CardContent className="pt-6 flex flex-col gap-3">
        <PaymentInfoRow label={t('invoiceDetail.chargeDetail.paymentMethod')} value={handlePaymentMethodInfo()} />

        <PaymentInfoRow label={t('invoiceDetail.chargeDetail.cardExpirationDate')} value={handleCardExpirationDate()} />

        <PaymentInfoRow label={t('invoiceDetail.chargeDetail.nameInCard')} value={detail.cardDetails?.holder} />

        <PaymentInfoRow label={t('invoiceDetail.chargeDetail.installmentsQuantity')} value={installments} />

        <PaymentInfoRow label={t('invoiceDetail.chargeDetail.document')} value={handleDocument()} />

        <PaymentInfoRow label={t('invoiceDetail.chargeDetail.address')} value={handleAddress()} />

        <PaymentInfoRow label={t('invoiceDetail.chargeDetail.origin')} value={handleOrigin()} />
      </CardContent>
    </Card>
  )
}
