// export enum Country {
//   af = "AF", ax = "AX", al = "AL", dz = "DZ", as = "AS", ad = "AD", ao = "AO", ai = "AI", aq = "AQ",
//   ag = "AG", ar = "AR", am = "AM", aw = "AW", au = "AU", at = "AT", az = "AZ", bs = "BS", bh = "BH",
//   bd = "BD", bb = "BB", by = "BY", be = "BE", bz = "BZ", bj = "BJ", bm = "BM", bt = "BT", bo = "BO",
//   ba = "BA", bw = "BW", bv = "BV", br = "BR", io = "IO", bn = "BN", bg = "BG", bf = "BF", bi = "BI",
//   kh = "KH", cm = "CM", ca = "CA", cv = "CV", ky = "KY", cf = "CF", td = "TD", cl = "CL", cn = "CN",
//   cx = "CX", cc = "CC", co = "CO", km = "KM", cg = "CG", cd = "CD", ck = "CK", cr = "CR", ci = "CI",
//   hr = "HR", cu = "CU", cy = "CY", cz = "CZ", dk = "DK", dj = "DJ", dm = "DM", do = "DO", ec = "EC",
//   eg = "EG", sv = "SV", gq = "GQ", er = "ER", ee = "EE", et = "ET", fk = "FK", fo = "FO", fj = "FJ",
//   fi = "FI", fr = "FR", gf = "GF", pf = "PF", tf = "TF", ga = "GA", gm = "GM", ge = "GE", de = "DE",
//   gh = "GH", gi = "GI", gr = "GR", gl = "GL", gd = "GD", gp = "GP", gu = "GU", gt = "GT", gg = "GG",
//   gn = "GN", gw = "GW", gy = "GY", ht = "HT", hm = "HM", va = "VA", hn = "HN", hk = "HK", hu = "HU",
//   is = "IS", in = "IN", id = "ID", ir = "IR", iq = "IQ", ie = "IE", im = "IM", il = "IL", eh = "EH",
//   it = "IT", jm = "JM", jp = "JP", je = "JE", jo = "JO", kz = "KZ", ke = "KE", ki = "KI", ye = "YE",
//   kr = "KR", kp = "KP", kw = "KW", kg = "KG", la = "LA", lv = "LV", lb = "LB", ls = "LS", zm = "ZM",
//   lr = "LR", ly = "LY", li = "LI", lt = "LT", lu = "LU", mo = "MO", mk = "MK", mg = "MG", zw = "ZW",
//   mw = "MW", my = "MY", mv = "MV", ml = "ML", mt = "MT", mh = "MH", mq = "MQ", mr = "MR", wf = "WF",
//   mu = "MU", yt = "YT", mx = "MX", fm = "FM", md = "MD", mc = "MC", mn = "MN", me = "ME", vi = "VI",
//   ms = "MS", ma = "MA", mz = "MZ", mm = "MM", na = "NA", nr = "NR", np = "NP", nl = "NL", vg = "VG",
//   nc = "NC", nz = "NZ", ni = "NI", ne = "NE", ng = "NG", nu = "NU", nf = "NF", mp = "MP", vn = "VN",
//   no = "NO", om = "OM", pk = "PK", pw = "PW", ps = "PS", pa = "PA", pg = "PG", py = "PY", ve = "VE",
//   pe = "PE", ph = "PH", pn = "PN", pl = "PL", pt = "PT", pr = "PR", qa = "QA", re = "RE", vu = "VU",
//   ro = "RO", ru = "RU", rw = "RW", bl = "BL", sh = "SH", kn = "KN", lc = "LC", mf = "MF", uz = "UZ",
//   pm = "PM", vc = "VC", ws = "WS", sm = "SM", st = "ST", sa = "SA", sn = "SN", rs = "RS", uy = "UY",
//   sc = "SC", sl = "SL", sg = "SG", sk = "SK", si = "SI", sb = "SB", so = "SO", za = "ZA", um = "UM",
//   gs = "GS", es = "ES", lk = "LK", sd = "SD", ss = "SS", sr = "SR", sj = "SJ", sz = "SZ", us = "US",
//   se = "SE", ch = "CH", sx = "SX", sy = "SY", tw = "TW", tj = "TJ", tz = "TZ", th = "TH", gb = "GB",
//   tl = "TL", tg = "TG", tk = "TK", to = "TO", tt = "TT", tn = "TN", tr = "TR", tm = "TM", ae = "AE",
//   tc = "TC", tv = "TV", ug = "UG", ua = "UA"
// }

type Country = {
  id: CountryCode
  name: string
}

export enum CountryCode {
  AR = 'AR', // Argentina
  AU = 'AU', // Australia
  AT = 'AT', // Austria

  BE = 'BE', // Belgium
  BO = 'BO', // Bolivia
  BR = 'BR', // Brazil
  BG = 'BG', // Bulgaria

  CA = 'CA', // Canada
  CL = 'CL', // Chile
  CO = 'CO', // Colombia
  CY = 'CY', // Cyprus
  CZ = 'CZ', // Czech Republic
  CH = 'CH', // Switzerland

  DK = 'DK', // Denmark
  DE = 'DE', // Germany

  EC = 'EC', // Ecuador
  EG = 'EG', // Egypt
  EE = 'EE', // Estonia
  ES = 'ES', // Spain

  FI = 'FI', // Finland
  FR = 'FR', // France

  GR = 'GR', // Greece
  GB = 'GB', // The United Kingdom

  HK = 'HK', // Hong Kong

  IN = 'IN', // India
  ID = 'ID', // Indonesia
  IE = 'IE', // Ireland
  IT = 'IT', // Italy

  JP = 'JP', // Japan

  LV = 'LV', // Latvia
  LT = 'LT', // Lithuania
  LU = 'LU', // Luxembourg

  MT = 'MT', // Malta
  MX = 'MX', // Mexico

  NL = 'NL', // Netherlands
  NZ = 'NZ', // New Zealand
  NO = 'NO', // Norway

  PY = 'PY', // Paraguay
  PL = 'PL', // Poland
  PT = 'PT', // Portugal

  RO = 'RO', // Romania

  SG = 'SG', // Singapore
  SK = 'SK', // Slovakia
  SI = 'SI', // Slovenia
  SE = 'SE', // Sweden

  TH = 'TH', // Thailand

  US = 'US', // The United States

  ZA = 'ZA', // South Africa
}

export const Countries: { [key: string]: Country } = {
  // A
  [CountryCode.AU]: {
    id: CountryCode.AU,
    name: 'Australia',
  },
  [CountryCode.AT]: {
    id: CountryCode.AT,
    name: 'Austria',
  },

  // B
  [CountryCode.BE]: {
    id: CountryCode.BE,
    name: 'Belgium',
  },
  [CountryCode.BO]: {
    id: CountryCode.BO,
    name: 'Bolivia',
  },
  [CountryCode.BR]: {
    id: CountryCode.BR,
    name: 'Brasil',
  },
  [CountryCode.BG]: {
    id: CountryCode.BG,
    name: 'Bulgaria',
  },

  // C
  [CountryCode.CA]: {
    id: CountryCode.CA,
    name: 'Canada',
  },
  [CountryCode.CL]: {
    id: CountryCode.CL,
    name: 'Chile',
  },
  [CountryCode.CO]: {
    id: CountryCode.CO,
    name: 'Colombia',
  },
  [CountryCode.CY]: {
    id: CountryCode.CY,
    name: 'Cyprus',
  },
  [CountryCode.CZ]: {
    id: CountryCode.CZ,
    name: 'Czech Republic',
  },
  [CountryCode.CH]: {
    id: CountryCode.CH,
    name: 'Switzerland',
  },

  // D
  [CountryCode.DK]: {
    id: CountryCode.DK,
    name: 'Denmark',
  },
  [CountryCode.DE]: {
    id: CountryCode.DE,
    name: 'Germany',
  },

  // E
  [CountryCode.EC]: {
    id: CountryCode.EC,
    name: 'Ecuador',
  },
  [CountryCode.EG]: {
    id: CountryCode.EG,
    name: 'Egypt',
  },
  [CountryCode.EE]: {
    id: CountryCode.EE,
    name: 'Estonia',
  },
  [CountryCode.ES]: {
    id: CountryCode.ES,
    name: 'Spain',
  },

  // F
  [CountryCode.FI]: {
    id: CountryCode.FI,
    name: 'Finland',
  },
  [CountryCode.FR]: {
    id: CountryCode.FR,
    name: 'France',
  },

  // G
  [CountryCode.GR]: {
    id: CountryCode.GR,
    name: 'Greece',
  },
  [CountryCode.GB]: {
    id: CountryCode.GB,
    name: 'United Kingdom',
  },

  // H
  [CountryCode.HK]: {
    id: CountryCode.HK,
    name: 'Hong Kong',
  },

  // I
  [CountryCode.IN]: {
    id: CountryCode.IN,
    name: 'India',
  },
  [CountryCode.ID]: {
    id: CountryCode.ID,
    name: 'Indonesia',
  },
  [CountryCode.IE]: {
    id: CountryCode.IE,
    name: 'Ireland',
  },
  [CountryCode.IT]: {
    id: CountryCode.IT,
    name: 'Italy',
  },

  // J
  [CountryCode.JP]: {
    id: CountryCode.JP,
    name: 'Japan',
  },

  // L
  [CountryCode.LV]: {
    id: CountryCode.LV,
    name: 'Latvia',
  },
  [CountryCode.LT]: {
    id: CountryCode.LT,
    name: 'Lithuania',
  },
  [CountryCode.LU]: {
    id: CountryCode.LU,
    name: 'Luxembourg',
  },

  // M
  [CountryCode.MT]: {
    id: CountryCode.MT,
    name: 'Malta',
  },
  [CountryCode.MX]: {
    id: CountryCode.MX,
    name: 'Mexico',
  },

  // N
  [CountryCode.NL]: {
    id: CountryCode.NL,
    name: 'Netherlands',
  },
  [CountryCode.NZ]: {
    id: CountryCode.NZ,
    name: 'New Zealand',
  },
  [CountryCode.NO]: {
    id: CountryCode.NO,
    name: 'Norway',
  },

  // P
  [CountryCode.PY]: {
    id: CountryCode.PY,
    name: 'Paraguay',
  },
  [CountryCode.PL]: {
    id: CountryCode.PL,
    name: 'Poland',
  },
  [CountryCode.PT]: {
    id: CountryCode.PT,
    name: 'Portugal',
  },

  // R
  [CountryCode.RO]: {
    id: CountryCode.RO,
    name: 'Romania',
  },

  // S
  [CountryCode.SG]: {
    id: CountryCode.SG,
    name: 'Singapore',
  },
  [CountryCode.SK]: {
    id: CountryCode.SK,
    name: 'Slovakia',
  },
  [CountryCode.SI]: {
    id: CountryCode.SI,
    name: 'Slovenia',
  },
  [CountryCode.SE]: {
    id: CountryCode.SE,
    name: 'Sweden',
  },

  // T
  [CountryCode.TH]: {
    id: CountryCode.TH,
    name: 'Thailand',
  },

  // U
  [CountryCode.US]: {
    id: CountryCode.US,
    name: 'United States',
  },

  // Z
  [CountryCode.ZA]: {
    id: CountryCode.ZA,
    name: 'South Africa',
  },
}
