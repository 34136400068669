import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '../../domain/enums/query-key'
import { ListMemberSubscriptions } from '../../usecases/list-member-subscriptions'

export const useListMemberSubscriptions = () => {
  const container = useContainer()

  return useQuery(
    [QueryKey.listMemberSubscriptions],
    () => new ListMemberSubscriptions(container.get(HttpClient)).execute(),
    {
      refetchOnWindowFocus: false,
      initialData: [],
      retry: false,
    },
  )
}
