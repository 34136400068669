import { container } from '@hub-la/fe-container'
import { EventDetails, UserEventsProvider } from '@hub-la/fe-user-events'

export const UserEventsDetailInit = () => {
  return (
    <UserEventsProvider container={container}>
      <EventDetails />
    </UserEventsProvider>
  )
}
