export class Money {
  private value = 0

  public constructor(value: number) {
    this.value = value
  }

  public static build(value: number): Money {
    return new Money(value)
  }

  public getValue() {
    const formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })

    return formatter.format(this.value)
  }
}
