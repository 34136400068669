import { Checkbox, Input, Label } from '@hub-la/shadcn'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TicketSchemaValidationType } from '../../../validations/ticket-validation'

export const QuantityField = () => {
  const { t } = useTranslation()
  const { control, watch } = useFormContext<TicketSchemaValidationType>()
  const isLimitedQuantity = watch('isLimitedQuantity')

  return (
    <div className="flex flex-col gap-4">
      <Controller
        name="isLimitedQuantity"
        control={control}
        render={({ field }) => (
          <div className="flex items-center space-x-2">
            <Checkbox id="isLimitedQuantity" checked={field.value} onCheckedChange={field.onChange} />
            <Label
              htmlFor="isLimitedQuantity"
              className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            >
              {t('ticket.maxCapacity.checkbox')}
            </Label>
          </div>
        )}
      />

      {isLimitedQuantity && (
        <div className="mt-4">
          <Controller
            name="maxCapacity"
            control={control}
            render={({ field, fieldState }) => (
              <div className="flex gap-4 items-center">
                <div className="w-24">
                  <Input
                    {...field}
                    type="number"
                    min={1}
                    onChange={(e) => {
                      const value = e.target.value
                      if (value === '' || value === '0') {
                        field.onChange('')
                        return
                      }

                      field.onChange(parseInt(value.replace(/\D/g, '')))
                    }}
                    className={fieldState.error ? 'border-red-500' : ''}
                    value={field.value ?? ''}
                  />

                  {fieldState.error && (
                    <p className="mt-1 text-red-500 text-xs">{t(`fieldErrors.${fieldState.error.type}`)}</p>
                  )}
                </div>

                <Label className="text-sm font-medium">{t('ticket.maxCapacity.label')}</Label>
              </div>
            )}
          />
        </div>
      )}
    </div>
  )
}
