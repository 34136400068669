export enum QueryKey {
  getOffers = 'getOffers',
  getActiveSubscriptions = 'getActiveSubscriptions',
  getSubscription = 'getSubscription',
  getSubscriptions = 'getSubscriptions',
  getInvoices = 'getInvoices',
  getNewSubscribers = 'getNewSubscribers',
  getCanceledSubscriptions = 'getCanceledSubscriptions',
  getInactiveSubscriptions = 'getInactiveSubscriptions',
  deactivateSubscription = 'deactivateSubscription',
  deactivateSubscriptionBatch = 'deactivateSubscriptionBatch',
  addDailyCredits = 'addDailyCredits',
  addDailyCreditsBatch = 'addDailyCreditsBatch',
  addDailyCreditsByEmailBatch = 'addDailyCreditsByEmailBatch',
  downloadXlsx = 'downloadXlsx',
}
