import { HttpClient, HttpMethod } from '@hub-la/fe-core-http-client'
import { highlightTypes, Values } from '../../../domain/dtos/member-area/settings/formik-values'
import { UpdateHighlightInput } from '../../../domain/dtos/member-area/settings/update-highlight-input'
import { GeneralError } from '../../../domain/errors/general'
import { Envs } from '../../../envs'

export class UpdateHighlight {
  public constructor(private readonly httpClient: HttpClient) {}

  public async execute(values: UpdateHighlightInput) {
    try {
      const payload = await this.makeData(values?.highlightType, values)

      const response = await this.httpClient.request({
        url: `${Envs.BFF_WEB_URL}/hub/highlights`,
        body: { ...payload, productId: values.productId },
        method: HttpMethod.POST,
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })

      return response
    } catch (e) {
      throw new GeneralError()
    }
  }

  private async makeData(type: highlightTypes, values: Values) {
    if (type === highlightTypes.POST) {
      return {
        postId: values.post?.id,
      }
    }
    if (type === highlightTypes.IMAGE) {
      return {
        image: {
          desktopAssetId: values.desktopAsset?.id,
          mobileAssetId: values.mobileAsset?.id,
        },
      }
    }
    if (type === highlightTypes.VIDEO) {
      return {
        video: {
          desktopAssetId: values.videoAsset?.id,
        },
      }
    }
    return null
  }
}
