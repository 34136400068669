import {
  Badge,
  CopyClipboardButton,
  Label,
  Pix,
  Table,
  TableBody,
  TableCell,
  TableEmptyState,
  TableHead,
  TableHeader,
  TablePagination,
  TableRow,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@hub-la/shadcn'
import isEmpty from 'lodash/isEmpty'
import times from 'lodash/times'
import { Barcode, ChevronDown, ChevronsUpDown, ChevronUp, CreditCard } from 'lucide-react'
import moment from 'moment'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Invoice } from '../../../../domain/dtos/invoice'
import { InvoiceStatus } from '../../../../domain/enums/invoice-status'
import { InvoiceType } from '../../../../domain/enums/invoice-type'
import { OrderDirection } from '../../../../domain/enums/order-direction'
import { PaymentMethod } from '../../../../domain/enums/payment-method'
import { formatCurrency } from '../../../../domain/vos/format-currency'
import { InvoiceStatusStats } from '../../../components/invoice-status-stats'
import { SmartInstallmentChip } from '../../../components/smart-installment-chip'
import { useGetInvoices } from '../../../hooks/use-get-invoices'
import { useRefetch } from '../../../hooks/use-refetch'
import { TableLoading } from './table-loading'

export const InvoicesTable = ({ filters, setFilters, offers, isLoading }) => {
  const { page, pageSize } = filters
  const [orderDirection, setOrderDirection] = useState<OrderDirection>(OrderDirection.DESC)
  const [orderBy, setOrderBy] = useState<string>('createdAt')

  const handlePage = (page: number) => {
    setFilters((previousState) => ({ ...previousState, page }))
  }

  const handlePageSize = (pageSize: number) => {
    setFilters((previousState) => ({ ...previousState, pageSize }))
  }

  const { t } = useTranslation()
  const history = useHistory()

  const {
    data = { items: [], total: 0, page, pageSize, lastPage: 1 },
    isFetching,
    refetch,
  } = useGetInvoices(
    {
      offers,
      offerIds: filters.offerIds,
      statuses: filters.statuses as InvoiceStatus[],
      types: filters.types as InvoiceType[],
      methods: filters.methods as PaymentMethod[],
      startDate: filters.startDate,
      endDate: filters.endDate,
      page: filters.page,
      pageSize: parseInt(filters.pageSize, 10),
      search: filters.search,
      utmSource: filters.utmSource,
      utmMedium: filters.utmMedium,
      utmCampaign: filters.utmCampaign,
      utmContent: filters.utmContent,
      utmTerm: filters.utmTerm,
      orderDirection,
      orderBy,
    },
    isLoading,
  )

  useRefetch({ offers, ...filters, orderDirection }, refetch)

  const getIcon = (paymentMethod: PaymentMethod) => {
    switch (paymentMethod) {
      case PaymentMethod.PIX:
        return <Pix className="h-4 w-4 mr-2" />
      case PaymentMethod.CREDIT_CARD:
        return <CreditCard className="h-4 w-4 mr-2" />
      case PaymentMethod.BOLETO:
        return <Barcode className="h-4 w-4 mr-2" />
      default:
        return
    }
  }

  const handleRequestSort = useCallback(
    (property: string) => {
      const isAsc = orderBy === property && orderDirection === OrderDirection.ASC
      setOrderDirection(isAsc ? OrderDirection.DESC : OrderDirection.ASC)
      setOrderBy(property)
    },
    [orderDirection, orderBy],
  )

  const isRowsEmpty = isEmpty(data?.items)
  const canRenderEmptyState = !isFetching && !isLoading && isRowsEmpty

  return (
    <div className="flex flex-col gap-4">
      <div className="rounded-md border bg-background">
        <Table>
          <TableHeader>
            <TableRow className="hover:bg-background">
              <TableHead>Valor total</TableHead>
              <TableHead>Status</TableHead>
              <TableHead>ID da fatura</TableHead>
              <TableHead>Cliente</TableHead>
              <TableHead>Produto</TableHead>
              <TableHead className="cursor-pointer bg-background" onClick={() => handleRequestSort('createdAt')}>
                <div className="flex items-center">
                  Data da criação
                  {orderBy === 'createdAt' ? (
                    orderDirection === OrderDirection.ASC ? (
                      <ChevronUp className="ml-2 h-4 w-4" />
                    ) : (
                      <ChevronDown className="ml-2 h-4 w-4" />
                    )
                  ) : (
                    <ChevronsUpDown className="ml-2 h-4 w-4" />
                  )}
                </div>
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {(isFetching && isRowsEmpty) || isLoading
              ? times(pageSize, (idx) => <TableLoading key={idx} />)
              : (data?.items ?? []).map((invoice: Invoice) => (
                  <TableRow
                    key={invoice.id}
                    onClick={() => {
                      history.push(`/dashboard/sales/${invoice.id}`)
                      window.scrollTo({ top: 0, behavior: 'smooth' })
                    }}
                    className="cursor-pointer"
                  >
                    <TableCell className="py-2.5">
                      <div className="flex items-center space-x-1">
                        {getIcon(invoice.paymentMethod)}
                        <Label className="text-base font-medium">
                          {formatCurrency(invoice.amount.totalCents / 100)}
                        </Label>
                      </div>
                    </TableCell>
                    <TableCell className="py-2.5 min-w-[200px]">
                      <div className="flex items-center gap-1">
                        <InvoiceStatusStats status={invoice.status} />
                        {invoice.smartInstallmentRef && (
                          <SmartInstallmentChip
                            installment={invoice.installment}
                            installments={invoice.smartInstallmentRef.installments}
                          />
                        )}
                      </div>
                    </TableCell>
                    <TableCell className="py-2.5 max-w-[140px]">
                      <CopyClipboardButton
                        copyContent={invoice.id}
                        label={invoice.id}
                        tooltipCopiedLabel="ID da fatura copiado"
                        tooltipCopyLabel="Copiar ID da fatura"
                        size="sm"
                        className="h-6 max-w-[120px] truncate"
                      />
                    </TableCell>
                    <TableCell className="py-2.5 max-w-[200px] truncate">
                      <Label className="text-sm">{invoice.payer?.identity?.fullName}</Label>
                    </TableCell>
                    <TableCell className="py-2.5 max-w-[200px] truncate">
                      <div className="flex items-center space-x-1">
                        <Label className="text-sm">{invoice.items.at(0)?.productName}</Label>
                        {invoice.items?.length && invoice.items?.length > 1 && (
                          <TooltipProvider>
                            <Tooltip>
                              <TooltipTrigger>
                                <Badge variant="secondary" className="ml-2">
                                  + {invoice.items?.length - 1}
                                </Badge>
                              </TooltipTrigger>
                              <TooltipContent className="whitespace-pre-line">
                                {invoice.items
                                  .slice(1)
                                  ?.map((ref) => ref.productName)
                                  .join(', ')}
                              </TooltipContent>
                            </Tooltip>
                          </TooltipProvider>
                        )}
                      </div>
                    </TableCell>
                    <TableCell className="py-2.5 max-w-[200px] truncate">
                      <Label className="text-sm">{moment(invoice.createdAt).format('D MMM. YYYY')}</Label>
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>

        {canRenderEmptyState && <TableEmptyState title={t('empty')} subtitle={t('emptySubtitle')} />}
      </div>
      <div className="flex justify-end">
        {!isRowsEmpty && (
          <TablePagination
            page={page}
            setPage={handlePage}
            lastPage={data.lastPage}
            pageSize={pageSize}
            setPageSize={handlePageSize}
          />
        )}
      </div>
    </div>
  )
}
