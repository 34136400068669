import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { WaitlistStatus } from '../../../domain/enums/waitlist-status'
import { useGetWaitlist } from '../../hooks/waitlist/use-get-waitlist'
import { useRefetch } from '../../hooks/waitlist/use-refetch'
import { ConversionsAmount } from './components/conversions-amount'
import { Filters } from './components/filters'
import { SubscribersAmount } from './components/subscribers-amount'
import { WaitlistTable } from './components/waitlist-table'

export const WaitlistPage: React.FC = () => {
  const [filters, setFilters] = useState<{ status: WaitlistStatus; page: number; pageSize: number }>({
    status: WaitlistStatus.allSubscribers,
    page: 1,
    pageSize: 25,
  })

  const { productId } = useParams<{ productId: string }>()
  const {
    data = {
      subscribers: [],
      count: 0,
      currentPage: 0,
      perPage: 25,
      subscribersAmount: 0,
      conversionsAmount: 0,
    },
    isLoading,
    refetch,
  } = useGetWaitlist({
    page: filters.page - 1,
    perPage: filters.pageSize,
    status: filters.status,
    productId,
  })

  useRefetch({ ...filters }, refetch)

  const isCardLoading = isLoading && data.count <= 0
  const { t } = useTranslation()

  return (
    <div className="flex flex-col py-6 gap-4">
      <h1 className="text-xl font-semibold">{t('menu.waitlist')}</h1>
      <Filters filters={filters} setFilters={setFilters} />

      <div className="flex flex-col sm:flex-row gap-2">
        <SubscribersAmount isLoading={isCardLoading} amount={data.subscribersAmount} />

        <ConversionsAmount isLoading={isCardLoading} amount={data.conversionsAmount} />
      </div>

      <WaitlistTable filters={filters} setFilters={setFilters} isLoading={isCardLoading} data={data} />
    </div>
  )
}
