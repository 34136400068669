import { cn } from '@hub-la/shadcn'
import React from 'react'
import { InfoTooltip } from '../../components/info-tooltip'

type ContentRowProps = {
  title: string
  content: React.ReactElement
  tooltip?: string
  fontWeight?: 'bold' | 'light'
  variant?: 'right' | 'middle'
  showDivider?: boolean
}

export const ContentRow = ({ title, content, tooltip, variant, showDivider = false }: ContentRowProps) => {
  const isRight = variant === 'right'
  const isMiddle = variant === 'middle'

  return (
    <div className={cn('flex items-center space-x-2', isMiddle ? 'grid grid-cols-3' : 'justify-between')}>
      <span className="text-sm text-muted-foreground flex items-center">
        {title}
        {tooltip && <InfoTooltip title={tooltip} />}
      </span>
      <span className={cn('text-sm', isRight && 'text-primary text-right', isMiddle && 'col-span-2 text-primary')}>
        {content}
      </span>
    </div>
  )
}
