// Meant to represent recurring intervals
// Used to billing cycle a subscription
// OBS: cannot be used together with LifetimeInterval
// Since there is not item that could have a recurring interval
// and LifetimeInterval at the same time
export enum RecurringInterval {
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  SEMIANNUALLY = 'SEMIANNUALLY',
  ANNUALLY = 'ANNUALLY',
}

// Meant to represent a state of something that is not renewable
// OBS: cannot be used together with RecurringInterval
// Since there is not item that could have a recurring interval
// and LifetimeInterval at the same time
export enum LifetimeInterval {
  LIFETIME = 'LIFETIME',
}
