import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '../../domain/enums/query-key'
import { GetPartners } from '../../usecases/get-partners'

export const useGetPartners = (groupId: string, receiverId?: string) => {
  const container = useContainer()

  return useQuery(
    [QueryKey.getPartners, groupId, receiverId],
    () => new GetPartners(container.get(HttpClient)).fetch(groupId, receiverId),
    {
      refetchOnWindowFocus: false,
      initialData: {
        receivers: [],
        partnersCommission: 0,
      },
      retry: false,
    },
  )
}
