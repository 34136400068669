import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation } from '@tanstack/react-query'
import { PostIsUsingPasswordInput } from '../../domain/dtos/post-is-using-password-input'
import { QueryKey } from '../../domain/dtos/query-keys'
import { PostIsUsingPassword } from '../../usecases/post-is-using-password'

export const usePostIsUsingPassword = (
  params: { onSuccess: (data: boolean) => void; onError: (error) => void } | undefined,
) => {
  const container = useContainer()

  return useMutation(
    [QueryKey.postIsUsingPassword],
    (input: PostIsUsingPasswordInput) => new PostIsUsingPassword(container.get(HttpClient)).execute(input),
    {
      retry: false,
      onSuccess: (data) => {
        if (params?.onSuccess) {
          params?.onSuccess(data)
        }
      },
      onError: (error) => {
        if (params?.onError) {
          params.onError(error)
        }
      },
    },
  )
}
