import { Asset, isValidType, MAX_FILE_SIZE, SaveAssetRequest, useSaveAsset } from '@hub-la/fe-asset'
import { Card, useTheme } from '@hub-la/shadcn'
import * as tinymce from '@tinymce/tinymce-react'
import { get, isFunction } from 'lodash'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Envs } from '../../../envs'
import { useDeleteAsset } from '../../hooks/use-delete-asset'

export interface IProps {
  initialValue?: string
  value?: string
  placeholder?: string
  disabled?: boolean
  onChange?: (data: string) => any
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>
}

export interface ImageToolsState {
  blob: Blob
  url: string
}

type OnUpload = {
  file: File
  onProgress?
  onSuccess?
  onFail?
}

const Editor: React.FunctionComponent<IProps> = (props) => {
  const { initialValue, value, placeholder, onChange, disabled, setErrorMessage } = props

  const inputRef = useRef<HTMLInputElement>(null)
  const { t } = useTranslation()
  const saveAsset = useSaveAsset()
  const removeAsset = useDeleteAsset()

  const onUpload = async ({ file, onProgress, onSuccess, onFail }: OnUpload) => {
    saveAsset
      .mutateAsync({
        payload: {
          name: file.name,
          mimeType: file.type,
          file,
        },
        onUploadProgress: (progress) => {
          onProgress && onProgress(progress)
        },
      })
      .then((data) => onSuccess && onSuccess(data))
      .catch((err) => onFail && onFail(err))
  }

  const makeFile = (data): File | null => {
    if (isFunction(data?.blob)) {
      return data.blob() as File
    }

    return get(data, 'target.files[0]', null)
  }

  const { theme } = useTheme()

  return (
    <Card className="mb-5">
      <div className="w-full h-full">
        <tinymce.Editor
          disabled={disabled}
          value={initialValue || value}
          apiKey={Envs.TINYMCE_API_KEY}
          onEditorChange={(data) => onChange?.(data)}
          init={{
            min_height: 400,
            max_height: 400,
            height: '100%',
            branding: false,
            skin: 'snow',
            placeholder: placeholder ?? t('content.form.content'),
            menubar: false,
            content_style: `
              body {
                font-size: 18px;
                margin: 0 1rem;
                color: ${theme === 'light' ? '#000' : '#fff'};
              } 
            `,
            fontsize_formats: '18px 22px 28px 30px',
            quickbars_insert_toolbar: 'quickimage quicktable link media',
            quickbars_selection_toolbar: 'bold italic bullist numlist link removeformat',
            toolbar1: 'formatselect customImageUpload customVideoUpload table link media undo redo',
            setup: (editor) => {
              /*
             @TODO : need implement https://www.tiny.cloud/docs/tinymce/6/tinydrive-upload/ when have license or found other solution to upload file inner tiny content
            editor.ui.registry.addButton("customVideoUpload", {
              onAction: () => {},
              icon: "embed",
            })*/
              editor.ui.registry.addButton('customImageUpload', {
                onAction: () =>
                  editor.windowManager.open({
                    title: t('content.dialog.insertImage'),
                    body: {
                      type: 'panel',
                      items: [
                        {
                          type: 'htmlpanel',
                          html: '',
                        },
                        {
                          type: 'dropzone',
                          name: 'imageUpload',
                        },
                      ],
                    },
                    buttons: [
                      {
                        type: 'cancel',
                        name: 'closeButton',
                        text: 'Cancelar',
                      },
                    ],
                    onChange: (api) => {
                      const data: any = api.getData()
                      try {
                        if (data) {
                          const { imageUpload } = data

                          const { name, type } = imageUpload[0]

                          isValidType(type, false)

                          const asset: SaveAssetRequest = {
                            name: name,
                            mimeType: type,
                            file: imageUpload[0],
                          }

                          // save temp asset to preview image on imageTools
                          api.block(t('content.dialog.loading'))
                          saveAsset
                            .mutateAsync({
                              payload: asset,
                              onUploadProgress: (percentage: number) => {
                                //
                              },
                            })
                            .then((data: any) => {
                              api.unblock()
                              editor.windowManager.close()
                              editor.windowManager.open({
                                title: t('content.dialog.editImage'),
                                body: {
                                  type: 'panel',
                                  items: [
                                    {
                                      type: 'htmlpanel',
                                      html: '',
                                    },
                                    {
                                      type: 'imagetools',
                                      name: 'imagePreview',
                                      currentState: {
                                        url: data.data.url,
                                        blob: imageUpload[0],
                                      },
                                    },
                                  ],
                                },
                                buttons: [
                                  {
                                    type: 'cancel',
                                    name: 'closeButton',
                                    text: 'Cancelar',
                                  },
                                  {
                                    type: 'submit',
                                    text: 'Salvar',
                                  },
                                ],
                                onCancel: (api) => {
                                  try {
                                    // remove temp data
                                    if (data) {
                                      const tempAsset: Asset = {
                                        id: data.data.id,
                                        name: data.data.name,
                                        mimeType: data.data.mimeType,
                                        file: data.data.file,
                                        ownerId: data.data.ownerId,
                                      }

                                      removeAsset.mutateAsync(tempAsset.id).then((res) => {
                                        api.close()
                                      })
                                    }
                                  } catch (e: any) {
                                    setErrorMessage(t(e.message))
                                  }
                                },
                                onSubmit: (api) => {
                                  // remove temp asset & save edited asset
                                  try {
                                    if (data) {
                                      const tempAsset: Asset = {
                                        id: data.data.id,
                                        name: data.data.name,
                                        mimeType: data.data.mimeType,
                                        file: data.data.file,
                                        ownerId: data.data.ownerId,
                                      }

                                      removeAsset.mutateAsync(tempAsset.id).then((res) => {
                                        // get edited pic
                                        const data: any = api.getData()
                                        const { imagePreview } = data
                                        const { name, type } = imagePreview.blob

                                        const asset: SaveAssetRequest = {
                                          name: name,
                                          mimeType: type,
                                          file: imagePreview.blob,
                                        }

                                        saveAsset
                                          .mutateAsync({
                                            payload: asset,
                                            onUploadProgress: (percentage: number) => {
                                              //
                                            },
                                          })
                                          .then((data) => {
                                            editor.execCommand('InsertImage', false, data.url)
                                            api.close()
                                          })
                                      })
                                    }
                                  } catch (e: any) {
                                    setErrorMessage(t(e.message))
                                  }
                                },
                              })
                            })
                        }
                      } catch (e: any) {
                        setErrorMessage(t(e.message))
                      }
                    },
                  }),
                icon: 'image',
              })
            },
            plugins: 'autoresize image media table link lists quickbars autolink paste',
            contextmenu: false,
            toolbar_location: 'bottom',
            elementpath: false,
            icons: 'thin',
            paste_data_images: false,
            automatic_uploads: true,
            language: 'pt_BR',
            images_upload_handler: async (blobInfo, success, failure, progress) => {
              const file = makeFile(blobInfo)
              if (!file) {
                return
              }
              onUpload({
                file,
                onProgress: progress,
                onFail: () => failure('Error during upload!'),
                onSuccess: (data) => success(data.url),
              })
            },
            file_picker_types: 'image',
            file_picker_callback: (cb: any) => {
              inputRef.current?.click()
              inputRef.current!.onchange = (event) => {
                const file = makeFile(event)
                if (!file) {
                  return
                }
                onUpload({
                  file,
                  onSuccess: (data) => cb(data.url, { title: data.name, width: '100%', height: 'auto' }),
                })
              }
            },
            link_title: false,
            link_default_protocol: 'https',
            default_link_target: '_blank',
            paste_as_text: true,
            paste_merge_formats: false,
            smart_paste: false,
            browser_spellcheck: true,
            end_container_on_empty_block: false,
            media_alt_source: false,
            media_live_embeds: false,
            media_poster: false,
            paste_preprocess: (plugin, args) => {
              // on paste convert to link automatically, because autolink plugin not convert link on paste
              args.content = args.content.replace(
                /(https?:\/\/[^\s]+)/g,
                '<a href="$1" target="_blank" rel="noopener noreferrer">$1</a>',
              )
            },
          }}
        />
        <input
          ref={inputRef}
          hidden={true}
          type="file"
          onChange={(event) => {
            const file = makeFile(event)
            if (!file) {
              return
            }
            onUpload({ file })
          }}
          data-testid="editor-upload"
          max-size={MAX_FILE_SIZE}
          accept={Envs.ATTACHMENT_IMAGE_MIMETYPE_ALLOWED}
        />
      </div>
    </Card>
  )
}

export { Editor }
