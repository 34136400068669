import { VerificationStatus } from '@hub-la/fe-get-user'
import { Check, CircleDashed, X } from 'lucide-react'
import React from 'react'
import { useTranslation } from 'react-i18next'

type StatusTagProps = {
  status?: VerificationStatus
}

export const StatusTag: React.FC<StatusTagProps> = ({ status }) => {
  const { t } = useTranslation()

  const getIcon = () => {
    switch (status) {
      case VerificationStatus.APPROVED:
        return (
          <div className="bg-green-600 rounded-full w-4 h-4 flex justify-center items-center">
            <Check className="text-primary-foreground w-3 h-3" strokeWidth={3} />
          </div>
        )
      case VerificationStatus.PENDING:
        return <CircleDashed className="w-4 h-4 text-ring" />
      case VerificationStatus.REJECTED:
        return (
          <div className="bg-red-700 rounded-full w-4 h-4 flex justify-center items-center">
            <X className="text-primary-foreground w-3 h-3" strokeWidth={3} />
          </div>
        )
      default:
        return <CircleDashed className="w-4 h-4 text-muted-foreground" />
    }
  }

  const getTextColor = () => {
    switch (status) {
      case VerificationStatus.APPROVED:
        return 'text-green-700'
      case VerificationStatus.PENDING:
        return 'text-blue-700'
      case VerificationStatus.REJECTED:
        return 'text-red-700'
      default:
        return 'text-secondary-foreground'
    }
  }

  return (
    <div className={`flex text-semibold items-center gap-1.5 ${getTextColor()}`} data-testid="status-tag">
      {getIcon()}
      {t(`bankAccount.statusTag.${status ?? 'default'}`)}
    </div>
  )
}
