import { Link } from 'react-router-dom'

export const Sidebar = () => {
  return (
    <div>
      <ul>
        <li>
          <Link to="/about">About</Link>
        </li>
        <li>
          <Link to="/groups">Groups</Link>
        </li>
        <li>
          <Link to="/events">Events</Link>
        </li>
        <li>
          <Link to="/about">Ajuda</Link>
        </li>
      </ul>
    </div>
  )
}
