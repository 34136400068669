import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useGetUser } from '@hub-la/fe-get-user'
import { useMutation } from '@tanstack/react-query'
import { SetOrderRequest } from '../../domain/dtos/product'
import { QueryKey } from '../../domain/enums/query-key'
import { Envs } from '../../envs'
import { SetOrder } from '../../usecases/set-order'

export const useSetOrder = () => {
  const container = useContainer()
  const { data: user } = useGetUser()

  return useMutation(
    [QueryKey.setOrder],
    (input: SetOrderRequest) => new SetOrder(container.get(HttpClient)).execute(input),
    {
      retry: false,
      onSuccess: () => window.location.assign(`${Envs.SITE_URL}/${user?.id}`),
    },
  )
}
