import { UpdateProductSettingsPageRequestDto } from '@hub-la/sdk-bff-product'
import {
  Button,
  Card,
  CardContent,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Textarea,
} from '@hub-la/shadcn'
import { useFormik } from 'formik'
import { Plus } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { Section } from '../../../components/section'
import { useSaveSettings } from '../../../hooks/settings/use-save-settings'

type Values = Pick<UpdateProductSettingsPageRequestDto, 'description'>
const validationSchema = Yup.object().shape({
  description: Yup.array()
    .nullable()
    .of(
      Yup.object().shape({
        title: Yup.string().max(50, 'Título muito longo!'),
        text: Yup.string().max(700, 'Texto muito longo!'),
      }),
    ),
})
const makeInitialValues = ({ description }: Values): Values => {
  return {
    description: description?.filter((it) => it.text && it.title) ?? [{ title: 'O que você vai aprender', text: '' }],
  }
}

const options = [
  'O que você vai aprender',
  'Resultados que você pode esperar',
  'Para quem é este grupo?',
  'Regras do grupo',
  'Desafios que você irá enfrentar',
  'Como funciona a interação',
]

type Props = {
  description?: Values['description']
  loading?: boolean
}

export const DescriptionSection: React.FC<Props> = ({ description, loading }) => {
  const { productId } = useParams<{ productId: string }>()
  const { mutateAsync: saveSettings } = useSaveSettings()
  const { t } = useTranslation()

  const formik = useFormik<Values>({
    validationSchema,
    validateOnMount: true,
    validateOnChange: true,
    enableReinitialize: true,
    initialValues: makeInitialValues({ description }),
    onSubmit: async (values) => {
      const filteredDescriptions = values?.description?.filter((it) => it.text && it.title)
      await saveSettings({
        productId,
        type: 'page',
        data: { description: filteredDescriptions?.length ? filteredDescriptions : null },
      })
    },
  })

  return (
    <Section
      title={t('settings.productPage.description.title')}
      subtitle={t('settings.productPage.description.subtitle')}
      formik={formik}
    >
      <div className="space-y-4 w-full">
        {(formik.values.description ?? []).map((description, idx) => (
          <Card key={idx}>
            <CardContent className="space-y-4 pt-6">
              <Select
                name={`description[${idx}].title`}
                value={description.title}
                onValueChange={(value) => formik.setFieldValue(`description[${idx}].title`, value)}
                disabled={loading}
              >
                <SelectTrigger className="w-full">
                  <SelectValue placeholder={t('settings.productPage.description.input.title')} />
                </SelectTrigger>

                <SelectContent>
                  {options.map((option) => (
                    <SelectItem key={option} value={option}>
                      {option}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>

              <Textarea
                name={`description[${idx}].text`}
                value={description.text}
                onChange={formik.handleChange}
                placeholder={t('settings.productPage.description.input.subtitle')}
                disabled={loading}
                className="min-h-[100px]"
              />

              <p className="text-sm text-muted-foreground">{`${description.text.length}/700 caracteres.`}</p>
            </CardContent>
          </Card>
        ))}
        {(formik.values.description?.length ?? 0) < 4 && (
          <Button
            variant="outline"
            size="sm"
            onClick={() =>
              formik.setFieldValue('description', [
                ...(formik.values.description ?? []),
                { title: 'O que você vai aprender', text: '' },
              ])
            }
            disabled={loading}
          >
            <Plus className="mr-2 h-4 w-4" />
            {t('settings.productPage.description.input.add')}
          </Button>
        )}
      </div>
    </Section>
  )
}
