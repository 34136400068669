import { useFeatureFlag } from '@hub-la/fe-core-feature-flag'
import { useGetUser } from '@hub-la/fe-get-user'
import { ScrollArea, Separator } from '@hub-la/shadcn'
import { memo, useEffect, useRef } from 'react'
import { SmartInstallmentAmountDetail } from '../../../domain/dtos/smart-installment-amount-detail'
import { SmartInstallmentInvoiceEvent } from '../../../domain/dtos/smart-installment-invoice'
import { InvoiceStatus } from '../../../domain/enums/invoice-status'
import { SubscriptionStatus } from '../../../domain/enums/subscription-status'
import { useGetSmartInstallment } from '../../hooks/use-get-smart-installment'
import { useGetSmartInstallmentInvoices } from '../../hooks/use-get-smart-installment-invoices'
import { CustomerCard } from './customer-card'
import { Details } from './details'
import { Header } from './header'
import { InvoiceTable } from './invoice-table'
import { Loading } from './loading'
import { SmartInstallmentProgress } from './smart-installment-progress'
import { SmartInstallmentTimeline } from './smart-installment-timeline'
import { SmartInstallmentPayerDetails } from './smart-intallment-payer-details'

export const SmartInstallmentDetail: React.FC<{ id: string; onClose: () => void }> = memo(({ id, onClose }) => {
  const scrollRef = useRef<HTMLDivElement>(null)
  const { data: smartInstallment, refetch: refetchSmartInstallment } = useGetSmartInstallment(id)
  const { data = { items: [], total: 0, page: 1, pageSize: 50, lastPage: 1 } } = useGetSmartInstallmentInvoices({
    smartInstallmentId: id,
    page: 1,
    pageSize: 50,
  })

  const { isFlagEnabled } = useFeatureFlag()
  const { data: currentUser } = useGetUser()
  const showTimeline = isFlagEnabled('isValidUserToShowTimeline', {
    id: currentUser?.id,
  })

  const events = data.items
    .map((smartInstallmentInvoice) => smartInstallmentInvoice.events)
    .filter((events) => !!events)
    .flat() as SmartInstallmentInvoiceEvent[][]

  const firstScheduled = data.items.find((item) => item.status === InvoiceStatus.DRAFT)
  const finalAmountDetail: SmartInstallmentAmountDetail | undefined =
    firstScheduled && smartInstallment
      ? {
          products:
            smartInstallment.invoiceRef?.amountDetail?.products?.map((product) => ({
              productName: product.productName,
              priceCents: firstScheduled.amount.subtotalCents + firstScheduled.amount.discountCents,
              quantity: product.quantity ?? 1,
            })) ?? [],
          discountCents: firstScheduled.amount.discountCents,
          couponCode: smartInstallment.invoiceRef?.amountDetail?.couponCode,
          installmentFeeCents: firstScheduled.amount.installmentFeeCents,
          totalCents: firstScheduled.amount.totalCents,
          nextDueAt: firstScheduled.dueDate || firstScheduled.scheduleEta,
        }
      : undefined

  const smartInstallmentCredits =
    (smartInstallment?.subscriptionRef?.credits ?? 0) + (smartInstallment?.creditsRemaining ?? 0)

  const creditsRemaining =
    smartInstallment?.subscriptionRef?.status === SubscriptionStatus.STATUS_ACTIVE ? smartInstallmentCredits : 0

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = 0
    }
  }, [smartInstallment])

  if (!smartInstallment) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <Loading />
  }

  return (
    <ScrollArea ref={scrollRef} className="h-full w-full overflow-auto">
      <div className="space-y-4 mb-8">
        <Header
          smartInstallment={smartInstallment}
          onChange={refetchSmartInstallment}
          role={smartInstallment.role}
          onClose={onClose}
        />
        <Separator />
        <SmartInstallmentPayerDetails smartInstallment={smartInstallment} />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="md:col-span-2 space-y-8">
          <SmartInstallmentProgress smartInstallment={smartInstallment} />
          <InvoiceTable smartInstallmentId={smartInstallment.id} status={smartInstallment.status} />

          {showTimeline && !!events.length && (
            <div>{showTimeline && !!events.length && <SmartInstallmentTimeline events={events} />}</div>
          )}
        </div>

        <div className="space-y-6">
          <CustomerCard
            name={smartInstallment.payer?.identity?.fullName ?? ''}
            id={smartInstallment.payer?.id ?? ''}
            email={smartInstallment.payer?.identity?.email ?? ''}
            phone={smartInstallment.payer?.identity?.phone ?? ''}
          />
          <Details
            detail={{
              createdAt: smartInstallment.createdAt,
              id: smartInstallment.id ?? '',
              type: smartInstallment.invoiceRef?.type,
              nextDueAt: smartInstallment.nextDueAt ?? undefined,
            }}
          />
        </div>
      </div>
    </ScrollArea>
  )
})
