import { useFormikContext } from 'formik'
import { X } from 'lucide-react'
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AddFreeMemberForm } from '../../../domain/dtos/add-free-member-form'
import { FilterArrayByEmailValidation } from '../../../usecases/members/filter-array-by-email-validation'

const filterArrayByEmailValidation = new FilterArrayByEmailValidation()

type EmailsAutocompleteProps = {
  helperText?: string | null
  error?: boolean
}

const EmailsAutocomplete: React.FC<EmailsAutocompleteProps> = ({ error, helperText }) => {
  const { t } = useTranslation()
  const formik = useFormikContext<AddFreeMemberForm>()
  const [inputValue, setInputValue] = useState('')
  const [emailError, setEmailError] = useState(false)
  const [emailHelperText, setEmailHelperText] = useState<string | null>(null)
  const [isFocused, setIsFocused] = useState(false)
  const textareaRef = useRef<HTMLTextAreaElement>(null)
  const wrapperRef = useRef<HTMLDivElement>(null)

  const isValidEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email.trim())
  }

  const handlePasteOnInput = (text: string) => {
    const emailsWithComma = text.split(',')
    const emailsWithNewLine = text.split('\n')
    const emails = [...emailsWithComma, ...emailsWithNewLine].filter(
      (email, index, self) => self.indexOf(email) === index,
    )

    const emailsArray = filterArrayByEmailValidation.execute(emails, formik.values.emails)
    formik.setFieldValue('emails', [...formik.values.emails, ...emailsArray])
    setInputValue('')
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(e.target.value)
    if (emailError) {
      setEmailError(false)
      setEmailHelperText(null)
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      const emails = inputValue
        .replace(/[\n\s]+/g, ',')
        .split(',')
        .map((email) => email.trim())
        .filter((email) => email !== '')

      const invalidEmails = emails.filter((email) => !isValidEmail(email))
      if (invalidEmails.length > 0) {
        setEmailError(true)
        setEmailHelperText(t('addFreeMembersModal.inputs.emails.invalidEmail'))
        return
      }

      const validEmails = emails.filter(isValidEmail)
      if (validEmails.length > 0) {
        const newEmails = filterArrayByEmailValidation.execute(validEmails, formik.values.emails)
        formik.setFieldValue('emails', [...formik.values.emails, ...newEmails])
        setInputValue('')
      }
    }
  }

  const handleRemoveEmail = (emailToRemove: string) => {
    const updatedEmails = formik.values.emails.filter((email) => email !== emailToRemove)
    formik.setFieldValue('emails', updatedEmails)
  }

  const focusTextarea = () => {
    if (textareaRef.current) {
      textareaRef.current.focus()
    }
  }

  return (
    <div className="space-y-2">
      <div
        ref={wrapperRef}
        className={`relative w-full min-h-[200px] p-3 border rounded-md bg-background ${
          isFocused ? 'ring-2 ring-ring ring-offset-background' : ''
        } ${error ? 'border-destructive' : ''}`}
        onClick={focusTextarea}
      >
        <div className="max-h-64 flex flex-wrap gap-2 overflow-y-auto [&::-webkit-scrollbar]:hidden">
          {formik.values.emails.map((email, index) => (
            <div
              key={index}
              className="px-2 max-w-full min-h-6 inline-flex items-center gap-1 text-sm bg-muted rounded-md break-all"
            >
              <span className="text-foreground">{email}</span>
              <button
                onClick={(e) => {
                  e.stopPropagation()
                  handleRemoveEmail(email)
                }}
                className="text-muted-foreground hover:text-secondary-foreground focus:outline-none "
                aria-label={t('addFreeMembersModal.inputs.emails.removeEmail')}
              >
                <X size={14} />
              </button>
            </div>
          ))}
          <textarea
            ref={textareaRef}
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            onFocus={() => setIsFocused(true)}
            onBlur={(e) => {
              setIsFocused(false)
              e.preventDefault()
              handlePasteOnInput(e.target.value)
              setInputValue('')
            }}
            onPaste={(e) => {
              e.preventDefault()
              handlePasteOnInput(e.clipboardData.getData('text'))
            }}
            placeholder={t('addFreeMembersModal.inputs.emails.label')}
            className="flex-1 p-0 min-w-40 w-full resize-none bg-transparent border-none outline-none whitespace-nowrap [&::-webkit-scrollbar]:hidden"
            rows={1}
          />
        </div>
      </div>
      <p className="text-sm text-muted-foreground">{t('addFreeMembersModal.inputs.emails.helperText')}</p>
      {emailError && <p className="text-sm text-destructive">{emailHelperText}</p>}
    </div>
  )
}

export { EmailsAutocomplete }
