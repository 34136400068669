import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation } from '@tanstack/react-query'
import { CompleteRegistrationInput } from '../../domain/dtos/complete-registration-input'
import { QueryKey } from '../../domain/dtos/query-keys'
import { PostCompleteRegistration } from '../../usecases/post-complete-registration'

export const usePostCompleteRegistration = (params: { onSuccess?: () => void; onError?: () => void }) => {
  const container = useContainer()

  return useMutation(
    [QueryKey.postCompleteRegistration],
    (input: CompleteRegistrationInput) => new PostCompleteRegistration(container.get(HttpClient)).execute(input),
    {
      retry: false,
      onSuccess: () => {
        if (params?.onSuccess) {
          params?.onSuccess()
        }
      },
      onError: () => {
        if (params?.onError) {
          params?.onError()
        }
      },
    },
  )
}
