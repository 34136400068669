import moment from 'moment'
import 'moment/locale/pt-br'

type EventDateProps = {
  date: Date
}

export const EventDate = ({ date }: EventDateProps) => {
  const day = date.getDate()
  const month = moment(date).locale('pt-br').format('MMM').toUpperCase()

  return (
    <div
      className="absolute left-8 top-8 w-full h-full rounded p-1 max-h-40 max-w-40 flex flex-col justify-center items-center bg-background/50 backdrop-blur-sm"
      data-testid="event-date"
    >
      <p className="w-32 leading-4 text-onSurface font-bold text-center">{day}</p>
      <p className="w-32 leading-[10px] text-onSurfaceVariant text-center text-[10px]">{month}</p>
    </div>
  )
}
