/* tslint:disable */
/* eslint-disable */
/**
 * BFF Web Product
 * API reference used to call the backend from the web frontend
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface PlanDto
 */
export interface PlanDto {
    /**
     * Creator-set price the extra offer will be sold by. Should override the extraOffer\'s original price.
     * @type {number}
     * @memberof PlanDto
     */
    'priceInCents': number;
    /**
     * When the buyer will be charged again.
     * @type {string}
     * @memberof PlanDto
     */
    'interval': PlanDtoIntervalEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum PlanDtoIntervalEnum {
    MONTHLY = 'MONTHLY',
    QUARTERLY = 'QUARTERLY',
    SEMIANNUALLY = 'SEMIANNUALLY',
    ANNUALLY = 'ANNUALLY'
}


