import { Button } from '@hub-la/shadcn'
import { ArrowRight, Store, User } from 'lucide-react'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { AuthCard } from './auth-card/auth-card'

const options = [
  {
    id: 'creator',
    iconColor: 'text-blue-500',
    iconBgColor: 'bg-blue-100',
    Icon: Store,
    title: 'Sou produtor e quero vender na Hubla',
    redirect: '/welcome-experience',
  },
  {
    id: 'buyer',
    iconColor: 'text-green-500',
    iconBgColor: 'bg-green-100',
    Icon: User,
    title: 'Quero acessar um produto que comprei',
    redirect: '/user_groups',
  },
]

const Option = ({ iconColor, iconBgColor, Icon, title, onClick }) => {
  return (
    <div
      className="flex items-center justify-between p-4 border rounded-lg cursor-pointer hover:bg-secondary/50 hover:border-primary transition-colors"
      onClick={onClick}
    >
      <div className="flex items-center gap-4">
        <div className={`p-3 ${iconBgColor} rounded-md flex justify-center items-center ${iconColor}`}>
          <Icon className="h-6 w-6" />
        </div>
        <p className="text-sm font-medium">{title}</p>
      </div>
      <Button variant="ghost" size="icon">
        <ArrowRight className="h-4 w-4" />
      </Button>
    </div>
  )
}

const LetsStartCard: React.FC = () => {
  const history = useHistory()

  return (
    <AuthCard showTerms={false} title="Vamos começar" description="Qual destas opções melhor descreve você?">
      <div className="flex flex-col gap-3">
        {options.map((option) => (
          <Option
            key={option.id}
            iconColor={option.iconColor}
            iconBgColor={option.iconBgColor}
            Icon={option.Icon}
            title={option.title}
            onClick={() => {
              if (option.redirect) {
                history.push(option.redirect)
              }
            }}
          />
        ))}
      </div>
    </AuthCard>
  )
}

export { LetsStartCard }
