import { Envs } from '../envs'

export const isAtSite = () => window.location.origin.includes(getSiteHost())

export const getAppHost = (): string => {
  return Envs.APP_HOST ?? 'http://localhost:3002'
}

export const getSiteHost = (): string => {
  return Envs.SITE_HOST ?? 'http://localhost:3000'
}

export const getAppUrl = (path: string) => {
  return `${getAppHost()}${path}`
}

export const getSiteUrl = (path: string) => {
  return `${getSiteHost()}${path}`
}

export const routes = {
  SETTINGS: '/dashboard/settings',
  DASHBOARD: '/dashboard',
  PURCHASES: '/user_groups',
  AFFILIATES: '/dashboard/products?tab=affiliates',
  COLLABORATORS: '/dashboard/settings?tab=collaborators',
  ROLEPLAY: '/roleplay',
  BLOG: 'https://blog.hub.la/',
  SUPPORT: 'https://help.hub.la/hc/pt-br',
}
