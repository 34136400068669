import moment, { Moment } from 'moment-timezone'
import { PeriodToMoment } from '../domain/dtos/period-to-moment'
import { Period } from '../domain/enums/period'

type DateTimeRange = {
  from: string
  to: string
}

export class GetDateRangeFromPeriod {
  public execute(period: Period): DateTimeRange {
    const today = moment()
    const startDate = today.clone().startOf(PeriodToMoment[period])
    const endDate = today.clone().endOf('day')

    const toDateString = (m: Moment) => m.format('YYYY-MM-DDTHH:mm:ssZ')

    return {
      from: toDateString(startDate),
      to: toDateString(endDate),
    }
  }
}
