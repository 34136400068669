import { Button } from '@hub-la/shadcn'
import { Trans, useTranslation } from 'react-i18next'
import { getAppUrl, getGroupUrl, getPayUrl } from '../utils/routes'

type Props = {
  productName: string
  groupId: string
}

export const Paywall = ({ productName, groupId }: Props) => {
  const { t } = useTranslation()

  return (
    <div className="px-6 md:px-10 flex flex-col md:flex-row gap-6">
      <div className="flex-1">
        <p>
          <Trans
            i18nKey={'postDetail.paywall.title'}
            values={{ name: productName }}
            components={[
              <span className="font-bold" />,

              <a href={getGroupUrl(groupId)} className="font-bold underline" />,
            ]}
          />
        </p>
      </div>

      <div className="w-full sm:w-auto flex flex-col-reverse sm:flex-row gap-2">
        <a href={getAppUrl(`/signin?redirectTo=${window.location.href}`)} className="w-full md:w-auto">
          <Button variant="ghost" className="w-full">
            {t('postDetail.paywall.alreadyMember')}
          </Button>
        </a>

        <a href={getPayUrl(`/${groupId}`)} className="w-full md:w-auto">
          <Button className="w-full">{t('postDetail.paywall.purchase')}</Button>
        </a>
      </div>
    </div>
  )
}
