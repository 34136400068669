import { SendVideos } from '@hub-la/fe-post'
import { Button, Popover, PopoverContent, PopoverTrigger, Separator } from '@hub-la/shadcn'
import { Book, FileText, Plus, Radio, Upload, Video } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

const PopoverItem = ({ icon: Icon, children, onClick }) => (
  <Button variant="ghost" className="w-full justify-start" onClick={onClick}>
    <Icon className="mr-2 h-4 w-4" />
    <span>{children}</span>
  </Button>
)

export const CreatePostButton = ({ productId, sectionId }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const [isVideosUploaderOpen, setIsVideosUploaderOpen] = useState(false)

  const handleCreatePost = (event, type) => {
    event.stopPropagation()
    let url = `/hub/create_post/${productId}/${sectionId}`
    if (type) {
      url += `?type=${type}`
    }
    history.push(url)
  }

  return (
    <>
      <SendVideos sectionId={sectionId} open={isVideosUploaderOpen} onClose={() => setIsVideosUploaderOpen(false)} />

      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            size="icon"
            className="shadow-sm absolute right-20 top-[60px] h-10 w-10 rounded-full"
          >
            <Plus className="h-4 w-4" />
          </Button>
        </PopoverTrigger>

        <PopoverContent className="p-0" align="end">
          <PopoverItem icon={Upload} onClick={() => setIsVideosUploaderOpen(true)}>
            {t('dashboard.popover.videos')}
          </PopoverItem>

          <Separator />

          <PopoverItem icon={Video} onClick={(e) => handleCreatePost(e, 'video')}>
            {t('dashboard.popover.video')}
          </PopoverItem>

          <PopoverItem icon={Book} onClick={(e) => handleCreatePost(e, 'ebook')}>
            {t('dashboard.popover.files')}
          </PopoverItem>

          <PopoverItem icon={FileText} onClick={(e) => handleCreatePost(e, 'post')}>
            {t('dashboard.popover.post')}
          </PopoverItem>

          <PopoverItem icon={Radio} onClick={(e) => handleCreatePost(e, 'event')}>
            {t('dashboard.popover.event')}
          </PopoverItem>
        </PopoverContent>
      </Popover>
    </>
  )
}
