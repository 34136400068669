import { Avatar, AvatarFallback, AvatarImage, Badge, Label } from '@hub-la/shadcn'
import moment from 'moment'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { UserInfo } from '../../../domain/dtos/user-info'
import { CommentMention } from '../../../usecases/comment-mention'
import avatar from '../../assets/default-user-avatar.png'
import { usePutComment } from '../../hooks/use-put-comment'
import { usePutLikeComment } from '../../hooks/use-put-like-comment'
import { useDiscussion } from '../../providers/discussion'
import { InputComment } from '../input-comment/input-comment'
import { CommentActions } from './actions'
import { CommentOptions } from './options'
import { CommentReplies } from './replies'

type DefaultCommentProps = {
  commentId: string
  parentCommentId?: string // Used to identify if the comment is a reply on threads
  likes?: number
  fullName?: string
  avatarUrl?: string | null
  avatarSize?: number
  comment: string
  isEdited?: boolean
  createdAt?: string | Date
  latestReplyAt?: string | Date
  isLiked?: boolean
  children?: React.ReactNode
  repliesCount?: number
  userId: string
  hasOwnerReply?: boolean
  discussionOwner?: UserInfo | null
}

type CommentReply = {
  isReply: true
  parentCommentId: string
}

type CommentProps = DefaultCommentProps & (CommentReply | { isReply?: false; parentCommentId?: never })

export const Comment = ({
  commentId,
  fullName,
  avatarUrl,
  avatarSize = 10,
  comment,
  isEdited,
  isReply,
  createdAt,
  isLiked,
  likes,
  repliesCount = 0,
  latestReplyAt,
  parentCommentId,
  userId,
  hasOwnerReply,
  discussionOwner,
}: CommentProps) => {
  const [like, setLike] = useState(isLiked)
  const [isEditing, setIsEditing] = useState(false)
  const [toggleReplies, setToggleReplies] = useState(false)

  const { t } = useTranslation()
  const { isDiscussionOwner, isOwnerOfComment, currentUser } = useDiscussion()

  const { mutateAsync: editComment, isLoading: editCommentLoading } = usePutComment({
    onSuccess: () => {
      setIsEditing(false)
    },
    isReply,
    parentCommentId,
  })
  const { mutateAsync: likeComment } = usePutLikeComment()

  const handleEdit = () => {
    setIsEditing(true)
  }

  const handleLike = () => {
    likeComment({ commentId })
    setLike(!like)
    /**@todo: update like comments data in cache */
  }

  // Show replies component only if there are any and the comment is not a reply
  const showReplies = repliesCount > 0 && !isReply
  const showCommentOptions = !isEditing && (isDiscussionOwner || isOwnerOfComment(userId))
  const commentButtonLabel = isEditing ? t('editInputComment.submit') : t('inputComment.submit')
  const createdFormated = moment(createdAt).locale('pt-br').fromNow()

  const { mention, message } = new CommentMention().execute(comment)

  return (
    <div className="justify-between flex flex-row gap-3 w-full">
      <div className="justify-between flex flex-row gap-3 w-full">
        <div className="flex gap-3 w-full">
          <Avatar className={`w-${avatarSize} h-${avatarSize} mt-1`}>
            <AvatarImage src={avatarUrl ?? avatar} alt="User avatar" className="object-cover" />
            <AvatarFallback>{currentUser?.name ? currentUser?.name?.charAt(0) : 'U'}</AvatarFallback>
          </Avatar>
          <div className="w-full">
            {isEditing && (
              <InputComment
                defaultComment={comment}
                onCancel={() => setIsEditing(false)}
                isLoading={editCommentLoading}
                onComment={(message) => {
                  editComment({ commentId, message })
                }}
                avatarUrl={currentUser?.picture}
                commentButtonLabel={commentButtonLabel}
                showAvatar={false}
                inputLabel={isReply ? t('editInputComment.replyLabel') : t('editInputComment.label')}
              />
            )}
            {!isEditing && (
              <>
                <div>
                  <div className="flew flew-row align-baseline gap-2 mb-1">
                    <Label>{fullName}</Label>
                    <span className="ml-2 text-xs text-muted-foreground">
                      {createdFormated} {isEdited && t('comment.editedFlag')}
                    </span>
                  </div>
                </div>
                <p className="mb-2 items-center text-sm whitespace-pre-wrap break-words">
                  {mention && (
                    <Badge variant="secondary" className="bg-secondary hover:bg-secondary h-5 px-1 mr-1">
                      <p className="text-primary">@{mention}</p>
                    </Badge>
                  )}
                  {message}
                </p>
              </>
            )}
            <CommentActions
              onLike={handleLike}
              isLiked={like}
              likes={likes}
              isReply={isReply}
              parentCommentId={isReply ? parentCommentId : commentId}
              onReplySuccess={() => setToggleReplies(true)}
              parentUserId={userId}
              parentUserName={fullName}
            />
            {showReplies && (
              <CommentReplies
                commentId={commentId}
                latestReplyAt={latestReplyAt}
                count={repliesCount}
                hasOwnerReply={hasOwnerReply}
                discussionOwner={discussionOwner}
                setToggleReplies={setToggleReplies}
                toggleReplies={toggleReplies}
              />
            )}
          </div>
        </div>
        {showCommentOptions && (
          <CommentOptions
            commentId={commentId}
            userId={userId}
            isReply={isReply}
            parentCommentId={parentCommentId}
            onEdit={handleEdit}
          />
        )}
      </div>
    </div>
  )
}
