import { AssetResponse } from '../domain/dtos'
import { MediaUploadDefaultError } from '../domain/errors/media-upload-default'
import { GetFileMimetype } from './get-file-mimetype'
import { GetUploadErrorMessage } from './get-upload-error-message'

type UploadAssetBySignedUrlInput = {
  file: File
  mimeType: string
  writeUrl: string
  onUploadProgress: (percentage: number) => void
  asset: AssetResponse
}

export class UploadAssetBySignedUrl {
  public async execute(input: UploadAssetBySignedUrlInput): Promise<AssetResponse> {
    const contentType = await new GetFileMimetype().execute(input.file)

    try {
      return new Promise((resolve, reject) => {
        // TODO: Refactor to use HttpClient
        const xhr = new XMLHttpRequest()
        xhr.open('PUT', input.writeUrl, true)

        xhr.onabort = function () {
          reject(new MediaUploadDefaultError())
        }

        xhr.upload.onprogress = function (progress) {
          input.onUploadProgress((progress.loaded / progress.total) * 100)
        }

        xhr.upload.onloadend = function (progress) {
          if (progress.lengthComputable) {
            input.onUploadProgress((progress.loaded / progress.total) * 100)
          }
        }
        xhr.onload = function () {
          if (xhr.status === 200) {
            resolve(input.asset)
          } else {
            reject(new GetUploadErrorMessage().execute(input.mimeType))
          }
        }
        xhr.onerror = function () {
          reject(new GetUploadErrorMessage().execute(input.mimeType))
        }

        xhr.setRequestHeader('Content-Type', contentType)

        xhr.send(input.file)
      })
    } catch (error) {
      throw new MediaUploadDefaultError()
    }
  }
}
