export const isCNPJValid = (cnpj = ''): boolean => {
  // TODO: CNPJ validate will change on july, 2026
  const cleanCNPJ = cnpj.replace(/[^\d]/g, '')

  if (cleanCNPJ.length !== 14 || /^(\d)\1+$/.test(cleanCNPJ)) {
    return false
  }

  const calculateDigit = (cnpj: string, length: number, weights: number[]): number => {
    let sum = 0
    let weightIndex = 0

    for (let i = 0; i < length; i++) {
      sum += parseInt(cnpj.charAt(i)) * weights[weightIndex]
      weightIndex = (weightIndex + 1) % weights.length
    }

    const remainder = sum % 11
    return remainder < 2 ? 0 : 11 - remainder
  }

  const firstCheck = calculateDigit(cleanCNPJ, 12, [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2])
  if (parseInt(cleanCNPJ.charAt(12)) !== firstCheck) return false

  const secondCheck = calculateDigit(cleanCNPJ, 13, [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2])
  if (parseInt(cleanCNPJ.charAt(13)) !== secondCheck) return false

  return true
}
