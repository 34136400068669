import { HttpClient, HttpMethod } from '@hub-la/fe-core-http-client'
import { SaveMembersAreaSettingsRequest } from '../../../domain/dtos/member-area/settings/save-members-area'
import { GeneralError } from '../../../domain/errors/general'
import { Envs } from '../../../envs'

export class SaveMembersArea {
  public constructor(private readonly httpClient: HttpClient) {}

  public async execute(values: SaveMembersAreaSettingsRequest) {
    const payload = this.makeData(values)

    try {
      const response = await this.httpClient.request({
        url: `${Envs.BFF_WEB_URL}/products/${values.productId}/settings/members-area`,
        body: payload,
        method: HttpMethod.PUT,
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })

      return response
    } catch (e) {
      throw new GeneralError()
    }
  }

  private makeData(values: SaveMembersAreaSettingsRequest) {
    if (values?.logoAsset === null) {
      return {
        ...values,
        logoAsset: undefined,
        logoAssetId: null,
      }
    }

    return {
      ...values,
      logoAsset: undefined,
      logoAssetId: values?.logoAsset?.id,
    }
  }
}
