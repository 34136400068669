import { boolean, InferType, number, object, string } from 'yup'

export const ticketSchemaValidation = object({
  name: string().required(),
  productId: string(),
  description: string(),
  isLimitedQuantity: boolean().required(),
  maxCapacity: number().nullable().when('isLimitedQuantity', {
    is: true,
    then: number().required(),
    otherwise: number().nullable(),
  }),
  primaryColor: string().required(),
  secondaryColor: string().required(),
  textColor: string().required(),
  logoUrl: string().nullable(),
}).required()

export type TicketSchemaValidationType = InferType<typeof ticketSchemaValidation>
