import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { toast } from '@hub-la/shadcn'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { ChangeCouponStatusInput } from '../../domain/dtos/change-coupon-status-input'
import { CouponStatus } from '../../domain/enums/coupon-status'
import { QueryKey } from '../../domain/enums/query-key'
import { ChangeCouponStatus } from '../../usecases/change-coupon-status'

export const useChangeCouponStatus = () => {
  const { t } = useTranslation()
  const container = useContainer()
  const queryClient = useQueryClient()

  return useMutation(
    [QueryKey.refundCoupon],
    (input: ChangeCouponStatusInput) => new ChangeCouponStatus(container.get(HttpClient)).execute(input),
    {
      retry: false,
      onSuccess: (_, input) => {
        queryClient.invalidateQueries({ queryKey: [QueryKey.getCoupon, input.id] })
        queryClient.invalidateQueries({ queryKey: [QueryKey.getCoupons] })
        toast({
          title: 'Sucesso',
          description: t(input.currentStatus === CouponStatus.ACTIVE ? 'success.deactivate' : 'success.activate'),
        })
      },
      onError: (error) => {
        toast({ title: 'Erro', description: (error as Error).message, variant: 'destructive' })
      },
    },
  )
}
