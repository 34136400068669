import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { UpgradePaymentMethods } from '../../domain/dtos/payment-method'
import { Subscription } from '../../domain/dtos/subscription'
import { PaymentMethod } from '../../domain/enums/payment-method'
import { QueryKey } from '../../domain/enums/query-key'
import { SubmitChangePaymentMethod } from '../../usecases/submit-change-payment-method'
import { Values } from '../pages/user-subscription-detail/components/change-payment-method-modal'

export const useSubmitChangePaymentMethod = () => {
  const container = useContainer()
  const queryClient = useQueryClient()

  return useMutation(
    [QueryKey.submitChangePaymentMethod],
    (input: Values) => new SubmitChangePaymentMethod(container.get(HttpClient)).execute(input),
    {
      retry: false,
      onSuccess: async (_, input) => {
        await new Promise((r) => setTimeout(r, 2000))
        queryClient.invalidateQueries([QueryKey.initUpgradePlan, input.subscriptionId])
        queryClient.invalidateQueries([QueryKey.initChangePaymentMethod, input.subscriptionId])
        queryClient.invalidateQueries([QueryKey.getSubscriptionValue, input.subscriptionId])
        queryClient.setQueryData<Subscription | undefined>([QueryKey.getSubscription, input.subscriptionId], (old) => {
          if (old) {
            return {
              ...old,
              cardDetails: input.creditCard.cardDetails,
              paymentMethod:
                input.paymentMethod === UpgradePaymentMethods.PAYMENT_METHOD_CARD
                  ? PaymentMethod.CREDIT_CARD
                  : PaymentMethod.PIX,
            }
          }
          return old
        })
      },
    },
  )
}
