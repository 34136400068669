import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useQuery } from '@tanstack/react-query'
import { GetRefundsInput } from '../../domain/dtos/get-refunds-input'
import { QueryKey } from '../../domain/enums/query-key'
import { GetRefunds } from '../../usecases/get-refunds'

export const useGetRefunds = (input: GetRefundsInput) => {
  const container = useContainer()

  return useQuery(
    [QueryKey.getRefunds],
    ({ signal }) => new GetRefunds(container.get(HttpClient)).execute(input, signal),
    {
      retry: 3,
      retryOnMount: false,
      refetchOnWindowFocus: false,
      enabled: !!input.offers,
      initialData: {
        page: 1,
        pageSize: 0,
        total: 0,
        lastPage: 1,
        items: [],
      },
    },
  )
}
