import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { CreateCouponInput, CreateCouponItem } from '../domain/dtos/create-coupon-input'
import { GeneralError } from '../domain/errors/general'
import { Envs } from '../envs'
import { Values } from '../presentation/components/create-coupon-modal'

export class CreateCoupon {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(input: Values): Promise<void> {
    const response = await this.httpClient.request({
      method: HttpMethod.POST,
      url: `${Envs.BFF_WEB_URL}/coupons/create`,
      body: this.makeBody(input),
    })

    if (response.statusCode === HttpStatusCode.CREATED) {
      return
    }

    throw new GeneralError(response.data?.errorCode)
  }

  private makeBody(input: Values): CreateCouponInput {
    return {
      code: input.code,
      validUntil: input.validUntil ? new Date(input.validUntil) : undefined,
      appliableTo: this.unifyOffersAndIntervals(input),
      discountPercent: input.discountPercent ?? 0,
      maxAllowedTotalUsages: input.maxAllowedTotalUsages,
      maxAllowedTotalUsagesPerUser: input.maxAllowedTotalUsagesPerUser,
      isRecurrent: input.isRecurrent,
      restrictedToCheckout: !input.useOutsideCheckout,
    }
  }

  private unifyOffersAndIntervals(input: Values): CreateCouponItem[] {
    const offers = input.applyToSpecificOffers ? input.offers.map((it) => it.id) : []
    const intervals = input.applyToSpecificIntervals ? input.intervals : []

    if (offers.length === 0 && intervals.length === 0) {
      return []
    }

    if (offers.length === 0) {
      return [{ intervals }]
    }

    if (intervals.length === 0) {
      return offers.map((offerId) => ({ offerId, intervals: [] }))
    }

    return offers.map((offerId) => ({ offerId, intervals }))
  }
}
