import { Badge, Card, CardContent, CardHeader, CardTitle, Label, Separator } from '@hub-la/shadcn'
import { memo } from 'react'
import { Currency } from '../../../domain/vos/currency'
import { useGetRefund } from '../../hooks/use-get-refund'
import { Details } from './details'
import { Header } from './header'
import { Loading } from './loading'
import { ProductImage } from './product-image'
import { RefundTimeline } from './refund-timeline'

type RefundDetailProps = {
  id: string
  onClose: () => void
}

export const RefundDetail = memo(({ id, onClose }: RefundDetailProps) => {
  const { data: refund } = useGetRefund(id)
  const currency = new Currency()

  if (!refund) {
    return <Loading />
  }

  return (
    <div className="h-full w-full overflow-auto">
      <div className="space-y-4 mb-8">
        <Header
          status={refund.status}
          id={refund.id}
          onClose={onClose}
          paidAt={refund.transaction.paidAt}
          createdAt={refund.createdAt}
          amount={refund.amount}
          name={refund.to.email ?? refund.to.phoneNumber ?? ''}
        />
        <Separator />
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-12 gap-6">
        <div className="col-span-1 lg:col-span-7 space-y-6">
          {refund.transaction.item && (
            <Card>
              <CardHeader className="p-4 px-4">
                <CardTitle className="text-base font-semibold flex justify-between items-center">
                  Detalhes da compra
                </CardTitle>
              </CardHeader>
              <Separator />
              <CardContent className="p-4 space-y-4">
                <div className="flex flex-col sm:flex-row justify-between items-start gap-4 sm:gap-0">
                  <div className="flex flex-row gap-4 w-full sm:w-auto">
                    <ProductImage url={refund.transaction.item.picture} />
                    <div className="flex flex-col gap-1">
                      <div className="flex flex-row gap-2 items-center flex-wrap">
                        <span className="text-base font-medium">{refund.transaction.item.name}</span>
                        <Badge variant="secondary">{refund.transaction.isRenewing ? 'Renovação' : 'Compra'}</Badge>
                      </div>
                      <span className="text-sm text-muted-foreground break-all">{refund.transaction.id}</span>
                    </div>
                  </div>
                  <div className="w-full sm:w-auto text-left sm:text-right">
                    <span className="text-base font-medium">{currency.format(refund.amount)}</span>
                  </div>
                </div>
                <Separator />
                <RefundTimeline paidAt={refund.transaction.paidAt} createdAt={refund.createdAt} />
              </CardContent>
            </Card>
          )}
          <div className="flex flex-col space-y-1.5">
            <Label className="text-base font-medium">Motivo</Label>
            <Card>
              <CardContent className="p-4">
                <div className="flex flex-row gap-2 items-center">
                  <div className="w-10 h-10 rounded-lg bg-muted flex items-center justify-center">
                    <span>{refund.to.email?.charAt(0).toUpperCase()}</span>
                  </div>
                  <div className="flex flex-col w-full gap-2 text-sm">{refund.description}</div>
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
        <div className="col-span-1 lg:col-span-5 space-y-6">
          <Details
            payerId={refund.to.id}
            name={refund.to.name}
            email={refund.to.email}
            phoneNumber={refund.to.phoneNumber}
            paymentMethod={refund.transaction.paymentMethod}
          />
        </div>
      </div>
    </div>
  )
})
