import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { DeleteShortLinkInput } from '../../../domain/dtos/delete-short-link-input'
import { QueryKey } from '../../../domain/enums/query-key'
import { DeleteShortLink } from '../../../usecases/shortlinks/delete-short-link'

export const useDeleteShortLink = () => {
  const container = useContainer()
  const queryClient = useQueryClient()

  return useMutation(
    [QueryKey.postDuplicateShortLink],
    (input: DeleteShortLinkInput) =>
      new DeleteShortLink(container.get(HttpClient)).execute(input.productId, input.shortLinkId),
    {
      retry: false,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [QueryKey.getShortLinks] })
      },
    },
  )
}
