import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
  Separator,
} from '@hub-la/shadcn'
import { ChevronsUpDown } from 'lucide-react'
import React, { ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { InvoiceAmountDetail } from '../../../domain/dtos/invoice-amount-detail'
import { ReceiverRole } from '../../../domain/enums/receiver-role'
import { formatCurrency } from '../../../domain/vos/format-currency'

interface ContentRowProps {
  title: string
  content: ReactNode
}

const ContentRow = ({ title, content }: ContentRowProps) => (
  <div className="pl-[2%] sm:pl-[30%] pr-6 w-full flex justify-between">
    <div className="text-sm text-muted-foreground">{title}</div>
    <div className="text-sm text-right text-muted-foreground">{content}</div>
  </div>
)

interface CollapsibleRowProps {
  title: string
  amount: number
  isNegative?: boolean
  isDefaultOpen?: boolean
  children: React.ReactNode
}

const CollapsibleRow = ({
  title,
  amount,
  isNegative = false,
  isDefaultOpen = false,
  children,
}: CollapsibleRowProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(isDefaultOpen)

  return (
    <Collapsible open={isOpen} onOpenChange={setIsOpen} className="space-y-2">
      <CollapsibleTrigger asChild>
        <div className="cursor-pointer w-full flex justify-between">
          <span className="text-sm text-muted-foreground">{title}</span>

          <div className="flex items-center justify-end gap-2">
            <span className="text-sm font-medium">
              {isNegative && '-'} {formatCurrency(amount / 100)}
            </span>

            <ChevronsUpDown className="w-4 h-4" />
          </div>
        </div>
      </CollapsibleTrigger>

      <CollapsibleContent className="space-y-1">{children}</CollapsibleContent>
    </Collapsible>
  )
}

type DescriptionProps = {
  invoiceId: string
  detail: InvoiceAmountDetail
  role: ReceiverRole
}

export const Description = ({ invoiceId, detail, role }: DescriptionProps) => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center w-full gap-2">
        <h2 className="text-lg font-semibold">{t('invoice.description.title')}</h2>
      </div>

      <Separator />

      <Card>
        <CardHeader className="bg-muted/50">
          <CardTitle className="text-lg font-semibold">{t('invoice.description.subtitle')}</CardTitle>
          <p className="text-sm font-light text-muted-foreground">
            {t('invoice.description.invoiceId', { invoiceId })}
          </p>
        </CardHeader>

        <Separator />

        <CardContent className="pt-6 flex flex-col gap-3">
          <CollapsibleRow title={t('invoice.description.valuePaidByCustomer')} amount={detail.totalCents}>
            {detail.products.map((product, i) => (
              <ContentRow
                key={i}
                title={product.productName}
                content={<span>{formatCurrency(product.priceCents / 100)}</span>}
              />
            ))}

            {detail.discountCents ? (
              <ContentRow
                title={t('invoice.description.coupon', { couponCode: detail.couponCode })}
                content={<span>- {formatCurrency(detail.discountCents / 100)}</span>}
              />
            ) : null}

            {detail.installmentFeeCents ? (
              <ContentRow
                title={t('invoice.description.installmentFeeCost')}
                content={<span>{formatCurrency(detail.installmentFeeCents / 100)}</span>}
              />
            ) : null}
          </CollapsibleRow>

          <Separator />

          <CollapsibleRow isNegative title={t('invoice.description.hublaTax')} amount={detail.platformAmountCents || 0}>
            <ContentRow
              title={t('invoice.description.hublaTax')}
              content={
                <a
                  className="text-sm font-medium text-primary hover:underline"
                  href="https://help.hub.la/hc/pt-br/quais-s%C3%A3o-as-taxas-da-hubla"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('invoice.description.knowMore')}
                </a>
              }
            />
          </CollapsibleRow>

          <Separator />

          <CollapsibleRow title={t('invoice.description.liquidValue')} amount={detail.subtotalCents}>
            <ContentRow
              title={t('invoice.description.valuePaidByCustomer')}
              content={<span>{formatCurrency(detail.totalCents / 100)}</span>}
            />

            {detail.platformAmountCents ? (
              <ContentRow
                title={t('invoice.description.hublaTax')}
                content={<span>- {formatCurrency(detail.platformAmountCents / 100)}</span>}
              />
            ) : null}

            {detail.installmentFeeCostCents ? (
              <ContentRow
                title={t('invoice.description.installmentFeeCost')}
                content={<span>- {formatCurrency(detail.installmentFeeCostCents / 100)}</span>}
              />
            ) : null}
          </CollapsibleRow>

          <Separator />

          <CollapsibleRow isDefaultOpen title={t('invoice.description.yourCommission')} amount={detail.netCents}>
            {detail.subtotalCents ? (
              <ContentRow
                title={t('invoice.description.liquidValue')}
                content={<span>{formatCurrency(detail.subtotalCents / 100)}</span>}
              />
            ) : null}

            {detail.affiliates?.map((affiliate, i) => (
              <ContentRow
                key={i}
                title={t('invoice.description.affiliateCommission', { name: affiliate.fullName })}
                content={<span>- {formatCurrency(affiliate.netCents / 100)}</span>}
              />
            ))}

            {detail.coProducers?.map((coProducer, i) => (
              <ContentRow
                key={i}
                title={t('invoice.description.coProducerCommission', { name: coProducer.fullName })}
                content={<span>- {formatCurrency(coProducer.netCents / 100)}</span>}
              />
            ))}

            {detail.netCents ? (
              <ContentRow
                title={t('invoice.description.yourCommissionAs', { role: t(`role.${role}`) })}
                content={<span>{formatCurrency(detail.netCents / 100)}</span>}
              />
            ) : null}
          </CollapsibleRow>
        </CardContent>
      </Card>
    </div>
  )
}
