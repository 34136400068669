import { Skeleton, TableCell, TableRow } from '@hub-la/shadcn'

export const FutureEventsTableLoading = () => {
  return (
    <TableRow>
      <TableCell className="p-2 space-y-2">
        <Skeleton className="w-44 h-5" />
        <Skeleton className="w-16 h-3" />
      </TableCell>

      <TableCell className="p-2 space-y-2">
        <Skeleton className="w-44 h-5" />
        <Skeleton className="w-16 h-3" />
      </TableCell>

      <TableCell className="p-2">
        <Skeleton className="w-32 h-5" />
      </TableCell>
    </TableRow>
  )
}
