import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useToast } from '@hub-la/shadcn'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { ListEventTicketsInputDto } from '../../../domain/dtos/tickets/list-event-tickets-input.dto'
import { QueryKey } from '../../../domain/enums/query-key'
import { ListEventTicketsUsecase } from '../../../usecases/tickets/list-event-tickets.usecase'

export const useListEventTickets = (input: ListEventTicketsInputDto) => {
  const { toast } = useToast()
  const { t } = useTranslation()
  const container = useContainer()

  return useQuery(
    [QueryKey.listEventTickets],
    () => new ListEventTicketsUsecase(container.get(HttpClient)).execute(input),
    {
      enabled: !!input.productId,
      refetchOnWindowFocus: false,
      retry: 3,
      onError: (error: Error) => {
        toast({ description: t(error.message), variant: 'destructive' })
      },
    },
  )
}
