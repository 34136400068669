import React, { memo } from 'react'
import { PaymentMethod } from '../../../domain/enums/payment-method'
import { PlanType } from '../../../domain/enums/plan-type'
import { useGetSubscription } from '../../hooks/use-get-subscription'
import { ClientAndProduct } from './client-and-product'
import { Details } from './details'
import { Header } from './header'
import { InvoiceTable } from './invoice-table'
import { Loading } from './loading'

import { ScrollArea } from '@hub-la/shadcn'

export const SubscriptionDetail: React.FC<{ id: string; onClose: () => void }> = memo(({ id, onClose }) => {
  const { data: subscription, refetch } = useGetSubscription(id)

  if (!subscription) {
    return <Loading />
  }

  return (
    <ScrollArea className="h-full w-full overflow-auto">
      <main className="grid grid-cols-1 space-y-6">
        <Header
          name={subscription.payer?.identity?.fullName ?? ''}
          planType={subscription.planType}
          status={subscription.status}
          credits={subscription.credits}
          role={subscription.role}
          id={subscription.id}
          onClose={onClose}
          onChange={refetch}
        />
        <Details
          detail={{
            id: subscription?.id ?? '',
            planType: subscription?.planType ?? PlanType.MONTHLY,
            createdAt: subscription?.createdAt ?? '',
            nextDueAt: subscription?.nextDueAt,
            canceledAt: subscription?.canceledAt,
            paymentMethod: subscription?.paymentMethod ?? PaymentMethod.CREDIT_CARD,
            credits: subscription?.credits ?? 0,
            status: subscription?.status,
          }}
        />
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="md:col-span-2">
            <InvoiceTable subscriptionId={subscription.id} />
          </div>
          <div className="md:col-span-1">
            <ClientAndProduct
              client={{
                name: subscription.payer?.identity?.fullName ?? '',
                id: subscription.payer?.id ?? '',
                email: subscription.payer?.identity?.email ?? '',
                phone: subscription.payer?.identity?.phone ?? '',
              }}
              products={(subscription.items ?? []).map((item) => ({
                name: item.productName,
                id: item.offerId,
              }))}
            />
          </div>
        </div>
      </main>
    </ScrollArea>
  )
})
