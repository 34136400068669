import { createContext, ReactNode, useContext } from 'react'

type CurrentUser = {
  firstName?: string
  picture?: string | null
  id?: string
  name?: string
}

type CurrentUserContextProps = {
  children: ReactNode
  currentUser?: CurrentUser
}

type CurrentUserContextValue = {
  currentUser: CurrentUser | null
}

const CurrentUserContext = createContext<CurrentUserContextValue | undefined>(undefined)

export const useCurrentUserContext = (): CurrentUserContextValue => {
  const context = useContext(CurrentUserContext)
  if (!context) {
    throw new Error('useCurrentUser must be used within a CurrentUserProvider')
  }
  return context
}

export const CurrentUserProvider = ({ currentUser, children }: CurrentUserContextProps) => {
  const value = {
    currentUser: currentUser ?? null,
  }

  return <CurrentUserContext.Provider value={value}>{children}</CurrentUserContext.Provider>
}
