import { HttpClient, HttpMethod, HttpResponse, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { isNil } from 'lodash'
import { GetOfferOutput } from '../domain/dtos/get-offer-output'
import { GetOffersResponse } from '../domain/dtos/get-offers-response'
import { GeneralError } from '../domain/errors/general'
import { Envs } from '../envs'

export class GetOffers {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(): Promise<GetOfferOutput> {
    const response = await this.httpClient.request({
      method: HttpMethod.GET,
      url: `${Envs.BFF_WEB_URL}/products/offers`,
    })

    if (response.statusCode === HttpStatusCode.OK) {
      return this.makeOutput(response)
    }

    throw new GeneralError()
  }

  private makeOutput(response: HttpResponse<GetOffersResponse>): GetOfferOutput {
    const data = response.data

    if (isNil(data)) {
      return []
    }

    const products = (data.owner ?? []).sort((a, b) => a.name.localeCompare(b.name))

    if (products.length === 1) {
      return [
        {
          id: products[0].id,
          name: products[0].name,
          intervals: products[0].intervals,
        },
      ]
    }

    return products
  }
}
