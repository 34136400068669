export const pt = {
  invoices: {
    title: 'Histórico de faturas',
    invoiceType: {
      sell: 'Venda',
      renewal: 'Renovação',
      upgrade: 'Upgrade',
    },
    productsFilter: {
      placeholder: 'Filtrar por produtos',
      notFound: 'Nenhum produto encontrado',
      all: 'Todos os produtos',
      products: 'Produtos',
    },
    table: {
      total: 'Valor',
      status: 'Status',
      product: 'Produto',
      type: 'Tipo',
      createdAt: 'Data de criação',
      empty: 'Nenhum dado encontrado',
    },
  },
  invoiceDetails: {
    header: {
      title: 'Geral',
      from: 'De',
      to: 'Para',
      date: {
        pending: 'Vence',
        paid: 'Pago',
        expired: 'Venceu',
        refunded: '',
        in_protest: 'Pago',
        chargeback: '',
        draft: 'Vence',
        canceled: '',
      },
      actions: {
        download: 'Exibir fatura',
        pay: 'Pagar agora',
      },
    },
    description: {
      title: 'Descrição',
      invoice: 'Fatura',
      details: {
        product: '{{productName}}',
        productInstallment: '1x parcela de {{productName}}',
        discountCents: 'Cupom de desconto - {{couponCode}}',
        installmentFeeCents: 'Juros de parcelamento',
        totalCents: 'Valor total',
      },
    },
  },
  invoiceDownload: {
    title: 'Detalhes da fatura',
    header: {
      title: 'Geral',
      invoiceId: 'ID Fatura',
      createdAt: 'Data de criação',
      paidAt: 'Data de pagamento',
    },
    chargeDetails: {
      charge: 'Cobrança',
      billing: 'Faturamento',
    },
    description: {
      title: 'Descrição',
      product: 'Produto: {{productName}}',
      discountCents: 'Cupom de desconto: {{couponCode}}',
      installmentFeeCents: 'Juros de parcelamento',
      totalCents: 'Valor total',
    },
  },
  back: 'Voltar',
  paymentDetails: {
    title: 'Detalhes do pagamento',
    method: 'Método de pagamento',
    description: 'Descrição na fatura do cartão',
  },
  invoiceStatus: {
    draft: 'Agendada',
    pending: 'Em aberto',
    paid: 'Paga',
    expired: 'Não paga',
    refunded: 'Reembolsada',
    in_protest: 'Contestada',
    chargeback: 'Chargeback',
    canceled: 'Cancelada',
  },
  cardBrand: {
    0: '',
    1: '',
    2: 'Mastercard',
    3: 'Visa',
    4: 'Elo',
    5: 'Amex',
  },
  pix: {
    copyCode: 'Copiar código',
    codeCopiedSuccessfully: 'Código copiado com sucesso!',
  },
  copyClipboard: {
    copyPixCode: 'Copiar código do PIX',
    pixCodeCopied: 'Código do PIX copiado',
  },
  error: 'Houve um erro ao trazer as faturas, por favor recarregue a página ou entre em contato com o suporte.',
}
