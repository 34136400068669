import { Button, TableCell, TableRow } from '@hub-la/shadcn'
import { toDate } from '@hub-la/utils'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { Phone } from '../../domain/vos/phone'

export const FreeMembersTableRow = ({ member, setDocumentIdToDelete, setConfirmPopup }) => {
  const { t } = useTranslation()

  const getContact = (user) => {
    if (!user.ims) return t('activity.noData')
    return new Phone(user.ims?.whatsapp || user.ims?.telegram || '').getFormattedNumber()
  }

  const handleRemoveClick = (documentId) => {
    setDocumentIdToDelete(documentId)
    setConfirmPopup(true)
  }

  return (
    <TableRow>
      <TableCell>
        {member.name || <span className="text-muted-foreground">{t('errors.nameNotRegistered')}</span>}
      </TableCell>
      <TableCell>{getContact(member)}</TableCell>
      <TableCell>{moment(toDate(member?.createdAt) ?? '').format('D MMM. YYYY HH:mm')}</TableCell>
      <TableCell className="flex justify-end">
        {member?.id && (
          <Button variant="destructive" size="sm" onClick={() => handleRemoveClick(member.id)}>
            {t('freeMembers.remove')}
          </Button>
        )}
      </TableCell>
    </TableRow>
  )
}
