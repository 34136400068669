import { useParams } from 'react-router-dom'
import { ProductType } from '../../../../domain/enums/product-type'
import { useGetProduct } from '../../../hooks/use-get-product'
import { AffiliatesDigital } from './digital'
import { AffiliatesEvent } from './event'

export const Affiliates = () => {
  const { productId } = useParams<{ productId: string }>()
  const { data: product } = useGetProduct(productId)

  if (product?.type === ProductType.EVENT) {
    return <AffiliatesEvent />
  }

  return <AffiliatesDigital />
}
