import { PermissionResource, useRoleplay } from '@hub-la/fe-roleplay'
import { Button, DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@hub-la/shadcn'
import { MoreHorizontal, RefreshCw, Trash2 } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { MemberType } from '../../../../domain/enums/member-type'

type RowParticipantsActionsProps = {
  type: MemberType
  onRemoveFreeMember: () => void
  onChangeCohort: () => void
}

export const RowParticipantActions = ({ type, onRemoveFreeMember, onChangeCohort }: RowParticipantsActionsProps) => {
  const { t } = useTranslation()
  const { hasPermission } = useRoleplay()

  const isFreeType = [MemberType.FREE].includes(type)

  if (!hasPermission(PermissionResource.PRODUCTS_MANAGE_MEMBERS) || type === MemberType.SELLER) {
    return null
  }

  return (
    <div className="flex items-center justify-end gap-2 mr-3">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" size="icon">
            <MoreHorizontal className="h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>

        <DropdownMenuContent align="end">
          <DropdownMenuItem onClick={onChangeCohort}>
            <RefreshCw className="mr-2 h-4 w-4" />
            <span>{t('tabs.participants.changeCohortMember')}</span>
          </DropdownMenuItem>

          {isFreeType && (
            <DropdownMenuItem onClick={onRemoveFreeMember}>
              <Trash2 className="mr-2 h-4 w-4" />
              <span>{t('tabs.participants.removeFreeMember')}</span>
            </DropdownMenuItem>
          )}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
}
