import {
  Button,
  CopyClipboardButton,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Skeleton,
} from '@hub-la/shadcn'
import { ChevronLeft } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

export const FreeMembersHeader = ({
  isLoading,
  product,
  resource,
  groupResource,
  onGenerateLink,
  isGeneratingLink,
}) => {
  const { t } = useTranslation()
  const [generatedLink, setGeneratedLink] = useState('')

  const handleGenerateLink = async () => {
    const link = await onGenerateLink()
    setGeneratedLink(link)
  }

  return (
    <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6">
      <div className="flex items-center mb-4 sm:mb-0">
        {!isLoading && resource?.picture && product && (
          <Link to={`/edit/${product.id}/cohorts`} className="mr-4">
            <ChevronLeft className="h-6 w-6" />
          </Link>
        )}
        {isLoading ? (
          <div className="animate-pulse bg-secondary w-8 h-8 rounded-full mr-4" />
        ) : (
          resource?.picture && <img src={resource.picture} alt={resource.name} className="w-8 h-8 rounded-full mr-4" />
        )}
        <div>
          {isLoading ? (
            <div className="animate-pulse bg-secondary h-6 w-32 mb-1" />
          ) : (
            product && <h2 className="text-xl font-bold">{product.name}</h2>
          )}
          {isLoading ? (
            <div className="animate-pulse bg-secondary h-4 w-24" />
          ) : (
            product && <p className="text-sm text-muted-foreground">{product.name}</p>
          )}
        </div>
      </div>

      <Dialog>
        <DialogTrigger asChild>
          <Button
            onClick={handleGenerateLink}
            disabled={isLoading || isGeneratingLink || groupResource?.state === 'full'}
          >
            {groupResource?.state === 'full' ? t('freeMembers.resourceFull') : t('freeMembers.generateInviteLink')}
          </Button>
        </DialogTrigger>
        <DialogContent className="flex flex-col gap-3">
          <DialogHeader>
            <DialogTitle>{t('freeMembers.generateLink.title')}</DialogTitle>
          </DialogHeader>

          <p className="text-center sm:text-left">{t('freeMembers.generateLink.description')}</p>

          {isGeneratingLink ? (
            <Skeleton className="h-8 w-full" />
          ) : (
            <CopyClipboardButton
              className="mx-auto w-full max-w-80 h-8"
              labelClassName="max-w-72"
              copyContent={generatedLink}
              label={generatedLink}
              tooltipCopiedLabel={t('copyClipboard.linkCopied')}
              tooltipCopyLabel={t('copyClipboard.copyLink')}
            />
          )}
        </DialogContent>
      </Dialog>
    </div>
  )
}
