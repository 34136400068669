import { Alert, AlertDescription, Button, Card, CardContent, CardHeader, CardTitle, Input } from '@hub-la/shadcn'
import { Check, Copy, ExternalLink } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export const WebhookAuthenticationTab = ({ secretToken }) => {
  const { t } = useTranslation()
  const [copied, setCopied] = useState(false)

  const copyToClipboard = () => {
    navigator.clipboard.writeText(secretToken)
    setCopied(true)
    setTimeout(() => setCopied(false), 2000)
  }

  return (
    <div className="max-w-3xl space-y-4">
      <div>
        <h2 className="text-lg font-bold mb-2">{t('integrations.webhook.auth.title')}</h2>
        <p className="text-sm font-normal text-muted-foreground">{t('integrations.webhook.auth.description')}</p>
      </div>
      <Card>
        <CardHeader>
          <CardTitle>{t('integrations.webhook.auth.token')}</CardTitle>
        </CardHeader>
        <CardContent className="space-y-4">
          <p className="text-sm font-normal text-muted-foreground">{t('integrations.webhook.auth.disclaimer')}</p>
          <div className="flex flex-row space-x-2">
            <Input
              placeholder={t('integrations.webhook.auth.tokenPlaceholder')}
              className="flex-grow font-mono"
              readOnly
              value={secretToken}
            />
            <Button variant="outline" size="icon" onClick={copyToClipboard}>
              {copied ? <Check className="h-4 w-4" /> : <Copy className="h-4 w-4" />}
            </Button>
          </div>
          <Alert variant="default">
            <AlertDescription>{t('integrations.webhook.auth.securityWarning')}</AlertDescription>
          </Alert>
        </CardContent>
      </Card>
      <Card>
        <CardHeader>
          <CardTitle>{t('integrations.webhook.auth.docs')}</CardTitle>
        </CardHeader>
        <CardContent>
          <a
            className="inline-flex items-center gap-2 hover:underline text-sm font-normal"
            href="https://hubla.gitbook.io/docs/webhooks/introducao"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>{t('integrations.webhook.auth.helpLink')}</span>
            <ExternalLink className="h-4 w-4" />
          </a>
        </CardContent>
      </Card>
    </div>
  )
}
