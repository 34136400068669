import { Alert, AlertDescription, AlertTitle } from '@hub-la/shadcn'
import { useFormikContext } from 'formik'
import { AlertTriangle } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { DocumentType } from '../../domain/enums/bank-account'
import { HasPendency } from '../../usecases/has-pendency'
import { Values } from '../pages/account-verification-modal'
import { CnpjForm } from './cnpj-form'
import { CpfForm } from './cpf-form'
import { FormHeader } from './form-header'

export const PersonForm: React.FC = () => {
  const { t } = useTranslation()
  const { values } = useFormikContext<Values>()
  const hasPendency = new HasPendency(values.pendencies).hasAnyOfPendencies([
    'identity.name',
    'identity.email',
    'identity.phone',
    'identity.document.value',
  ])

  return (
    <div className="flex flex-col space-y-6">
      <FormHeader
        title={t('accountVerification.form.person.title')}
        subtitle={t('accountVerification.form.person.subtitle')}
      />

      {hasPendency && (
        <Alert variant="destructive">
          <AlertTriangle className="h-4 w-4" />
          <AlertTitle>{t('pendencies.personalInfo.title')}</AlertTitle>
          <AlertDescription>{t('pendencies.personalInfo.subtitle')}</AlertDescription>
        </Alert>
      )}

      {values.identity.document.type === DocumentType.CPF ? <CpfForm /> : <CnpjForm />}
    </div>
  )
}
