import { injectable } from 'inversify'
import { User, UserType } from '../domain/dtos/user'
import { GetUser } from '../usecases/get-user'

@injectable()
export class GetUserMock extends GetUser {
  public override async execute(): Promise<User> {
    return Promise.resolve({
      id: '',
      name: '',
      email: '',
      phoneNumber: '',
      picture: '',
      navigationTarget: UserType.CREATOR,
    })
  }
}
