import { inject, injectable } from 'inversify'
import { HttpClient, HttpMethod } from '@hub-la/fe-core-http-client'

import { GetLiveLinkOutput } from '../domain/dtos/get-live-link-output'
import { GetPostInput } from '../domain/dtos/get-post-input'
import { Envs } from '../envs'

@injectable()
export class GetLiveLinkUseCase {
  public constructor(@inject(HttpClient) private readonly httpClient: HttpClient) {}

  public async fetch(input: GetPostInput): Promise<GetLiveLinkOutput> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .request({
          url: this.makeUrl(input),
          method: HttpMethod.GET,
          headers: {
            accept: 'application/json',
          },
        })
        .then(({ data }) => {
          return resolve({ url: data.event?.url ?? null, id: data.id })
        })
        .catch(() => {
          return reject(`Ocorreu um erro ao baixar o link da live`)
        })
    })
  }

  private makeUrl(input: GetPostInput) {
    return `${Envs.BFF_WEB_URL}/hub/posts/${input.postId}`
  }
}
