import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '../../domain/enums/query-key'
import { GetWhitelist } from '../../usecases/get-whitelist'

export const useGetWhitelist = (groupId: string) => {
  const container = useContainer()

  return useQuery(
    [QueryKey.getWhitelist, groupId],
    () => new GetWhitelist(container.get(HttpClient)).execute(groupId),
    {
      retry: 3,
      enabled: !!groupId,
      refetchOnWindowFocus: false,
    },
  )
}
