import { AvailableCarriers, useMultigatewayHandler } from '@hub-la/fe-tokenizer'
import React from 'react'
import { Installment } from '../../../../../domain/dtos/installment'
import { useGetSession } from '../../../../hooks/use-get-session'
import { FillCreditCard } from './fill-credit-card'
import { YunoCreditCard } from './yuno-credit-card'

type Props = {
  offerId: string
  installments: Installment[]
}

export const ChoosePaymentMethods: React.FC<Props> = ({ offerId, installments }) => {
  useGetSession()
  const gateway = useMultigatewayHandler(offerId)

  const creditCardField = () => {
    switch (gateway) {
      case AvailableCarriers.YUNO:
        return <YunoCreditCard installments={installments} />
      case AvailableCarriers.IUGU:
      case AvailableCarriers.MULTI:
      default:
        return <FillCreditCard installments={installments} offerId={offerId} />
    }
  }

  return <>{creditCardField()}</>
}
