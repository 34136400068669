import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { GenerateWhitelistLinkRequest } from '../domain/dtos/whitelist'
import { GeneralError } from '../domain/errors/general'
import { Envs } from '../envs'

export class GenerateWhitelistLink {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(input: GenerateWhitelistLinkRequest): Promise<string> {
    const response = await this.httpClient.request({
      method: HttpMethod.POST,
      url: `${Envs.FUNCTIONS_URL}/groupWhitelist/generateLink/pt`,
      body: { data: input },
    })

    if (response.statusCode === HttpStatusCode.OK || response.statusCode === HttpStatusCode.CREATED) {
      return response.data.result.link
    }

    throw new GeneralError()
  }
}
