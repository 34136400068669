import { useIsMobile } from '@hub-la/shadcn'
import { Widget } from '@typeform/embed-react'
import Cookies from 'js-cookie'
import { Loader2 } from 'lucide-react'
import { useHistory } from 'react-router-dom'
import { AuthLayout } from '../../components/auth-layout'
import { MetaTags } from '../../components/meta-tags'
import { useGetTypeformData } from '../../hooks/use-get-typeform-data'

const TypeformWrapper = () => {
  const isMobile = useIsMobile()
  const history = useHistory()

  const { mutateAsync, isLoading } = useGetTypeformData()

  const onSubmitForm = async (responseId: string) => {
    const response = await mutateAsync(responseId)
    Cookies.set(`typeform-first-experience`, JSON.stringify(response))
    history.push('/dashboard')
  }

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader2 className="h-8 w-8 animate-spin" />
      </div>
    )
  }

  if (isMobile) {
    return (
      <Widget
        id="nGHJDJZs"
        style={{ width: '100vw', height: '100vh' }}
        className="typeform-widget"
        onSubmit={(data) => onSubmitForm(data.responseId)}
      />
    )
  }

  return (
    <div className="container mx-auto min-h-[400px]">
      <div className="w-full h-[490px] min-h-[490px]">
        <Widget
          id="nGHJDJZs"
          style={{ width: '100%', height: '490px', minHeight: '490px' }}
          iframeProps={{
            style: 'min-height: 490px;',
          }}
          className="typeform-widget"
          onSubmit={(data) => onSubmitForm(data.responseId)}
        />
      </div>
    </div>
  )
}

const WelcomeExperiencePage = () => {
  return (
    <AuthLayout showTerms={false}>
      <MetaTags />
      <TypeformWrapper />
    </AuthLayout>
  )
}

export { WelcomeExperiencePage }
