import { layoutTypeFieldOptions } from 'libs/frontend/modules/offer/src/lib/domain/constants/layout-type-options'
import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { OfferSection } from '../../../../../components/offer-section'
import { AppearenceCollapsible } from './appearence-collapsible'
import { useFeatureFlag } from '@hub-la/fe-core-feature-flag'
import { BannerCollapsible } from './banner-collapsible'

export const Customization = () => {
  const { t } = useTranslation()

  const { watch, control } = useFormContext()

  const { offerId } = useParams<{ productId: string; offerId: string }>()

  const isCheckoutV4EnabledToOffer = !useFeatureFlag().isFlagEnabled('isCheckoutV4DisabledToOffer', {
    offerId,
  })
  const checkoutLayoutType = useWatch({
    control,
    name: 'checkout.checkoutlayoutType',
  })

  const selectedLayout = React.useMemo(
    () => layoutTypeFieldOptions.find((layoutType) => layoutType.id === watch('checkout.checkoutlayoutType')),
    [checkoutLayoutType],
  )

  return (
    <OfferSection title={t('offer.checkoutTab.customization.title')}>
      <AppearenceCollapsible />
      {isCheckoutV4EnabledToOffer ? (
        selectedLayout?.components.map((Component, index) => <Component key={index} />)
      ) : (
        <BannerCollapsible />
      )}
    </OfferSection>
  )
}
