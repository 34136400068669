import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation } from '@tanstack/react-query'
import { SendRuleTestInput } from '../../domain/dtos/send-rule-test-input'
import { SendRuleTest } from '../../usecases/send-rule-test'

export const useSendRuleTest = () => {
  const container = useContainer()

  return useMutation([], (input: SendRuleTestInput) => new SendRuleTest(container.get(HttpClient)).execute(input), {
    retry: false,
  })
}
