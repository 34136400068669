import { ClipboardFailed } from '../domain/errors/clipboard-failed'

export class CopyClipboard {
  public async execute(value: string) {
    return new Promise<void>((resolve, reject) => {
      if (!navigator.clipboard) {
        this.copyFallback(value).then(resolve).catch(reject)
        return
      }

      navigator.clipboard
        .writeText(value)
        .then(resolve)
        .catch(() => this.copyFallback(value).then(resolve).catch(reject))
    })
  }

  private copyFallback(value: string) {
    return new Promise<void>((resolve, reject) => {
      const text = document.createElement('input')
      text.value = value
      document.body.appendChild(text)
      text.select()
      const result = document.execCommand('copy')
      document.body.removeChild(text)

      if (!result) {
        return reject(new ClipboardFailed())
      }

      resolve()
    })
  }
}
