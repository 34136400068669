import * as React from 'react'
import { AppHeader } from '../../components/app-header'
import UserSubscriptions from './user-subscriptions'

export const UserSubscriptionsPage: React.FC = () => (
  <div className="flex flex-col container px-4 md:px-24">
    <AppHeader />
    <UserSubscriptions />
  </div>
)
