import { Button, Card, CardContent, Separator, Skeleton } from '@hub-la/shadcn'
import { ArrowLeft } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { AppHeader } from '../../components/app-header'

const Loading = () => {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <div className="flex flex-col container gap-4">
      <AppHeader />
      <div className="pb-10 px-4 sm:px-6 max-w-[946px] w-full mx-auto">
        <div className="mb-8">
          <Button variant="ghost" onClick={() => history.push('/user_groups')} className="flex items-center">
            <ArrowLeft className="mr-2" />
            {t('back')}
          </Button>
        </div>
        <div className="flex flex-col gap-4">
          <Card>
            <CardContent className="pt-6">
              <div className="flex items-center gap-4 mb-4">
                <Skeleton className="h-14 w-20 rounded" />
                <div>
                  <Skeleton className="h-6 w-40 mb-2" />
                  <Skeleton className="h-4 w-20" />
                </div>
              </div>
              <Separator className="my-4" />
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div>
                  <Skeleton className="h-6 w-36 mb-2" />
                  <Skeleton className="h-4 w-20" />
                </div>
                <div>
                  <Skeleton className="h-6 w-32 mb-2" />
                  <Skeleton className="h-4 w-full mb-1" />
                  <Skeleton className="h-4 w-24" />
                </div>
              </div>
            </CardContent>
          </Card>
          <Card>
            <CardContent className="flex justify-center py-8">
              <Skeleton className="h-[424px] w-[314px] rounded" />
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  )
}

export { Loading }
