import { UpgradePaymentMethods } from '../domain/dtos/payment-method'
import i18n from '../presentation/i18n'

export class PaymentMethodTextBuilder {
  public execute(paymentMethod?: UpgradePaymentMethods, last4?: string, cardBrand?: string): string {
    switch (paymentMethod) {
      case UpgradePaymentMethods.PAYMENT_METHOD_PIX:
        return i18n.t('paymentMethod.pix')
      case UpgradePaymentMethods.PAYMENT_METHOD_CARD:
        if (last4 && cardBrand) {
          return i18n.t('paymentMethod.last4AndCardBrand', {
            brand: i18n.t(`cardBrand.${cardBrand}`),
            last4,
          })
        } else if (last4) {
          return i18n.t('paymentMethod.last4Only', { last4 })
        } else {
          return i18n.t('paymentMethod.creditCard')
        }
      default:
        return ''
    }
  }
}
