import { memo } from 'react'
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params'
import { Offer } from '../../../domain/dtos/offer'
import { SmartInstallmentStatus } from '../../../domain/enums/smart-installment-status'
import { useGetSmartInstallmentsSummaries } from '../../hooks/use-get-smart-installments-summaries'
import { useRefetch } from '../../hooks/use-refetch'
import { CommaDelimitedArrayParam } from '../../utils/query-string-encoder'
import { Filters } from './filters'
import { SmartInstallmentsTable } from './smart-installments-table/smart-installments-table'
import { TotalPaid } from './total-paid/total-paid'
import { TotalScheduled } from './total-scheduled/total-scheduled'
import { TotalUnpaid } from './total-unpaid/total-unpaid'
import { Total } from './total/total'

type SmartInstallmentsProps = {
  offers: Offer[]
  isFetching: boolean
  isLoading: boolean
}

export const SmartInstallments = memo(({ offers, isFetching, isLoading }: SmartInstallmentsProps) => {
  const isLoadingOffers = isFetching || isLoading

  const [filters, setFilters] = useQueryParams({
    search: withDefault(StringParam, ''),
    offerIds: withDefault(CommaDelimitedArrayParam, []),
    statuses: withDefault(CommaDelimitedArrayParam, []),
    page: withDefault(NumberParam, 1),
    pageSize: withDefault(NumberParam, 25),
    dateRangeBy: withDefault(StringParam, 'createdAt'),
    startDate: withDefault(StringParam, undefined),
    endDate: withDefault(StringParam, undefined),
  })

  const {
    data: summaries,
    isFetching: isFetchingSummaries,
    refetch,
  } = useGetSmartInstallmentsSummaries(
    {
      offers,
      search: filters.search,
      offerIds: filters.offerIds as string[],
      statuses: filters.statuses as SmartInstallmentStatus[],
      page: filters.page,
      pageSize: parseInt(filters.pageSize.toString(), 10),
      startDate: filters.startDate,
      endDate: filters.endDate,
      dateRangeBy: filters.dateRangeBy,
    },
    isFetching,
  )

  useRefetch({ offers, ...filters, page: undefined, pageSize: undefined }, refetch)

  const total = (summaries?.totalPaid ?? 0) + (summaries?.totalScheduled ?? 0) + (summaries?.totalUnpaid ?? 0)
  const isCardLoading = isFetchingSummaries || isLoadingOffers

  return (
    <div className="flex flex-col gap-4">
      <Filters filters={filters} setFilters={setFilters} offers={offers} isLoading={isLoadingOffers} />

      <div className="grid grid-cols-1 md:grid-cols-4 gap-3">
        <Total isLoading={isCardLoading} amount={total} />
        <TotalPaid isLoading={isCardLoading} amount={summaries?.totalPaid} />
        <TotalUnpaid isLoading={isCardLoading} amount={summaries?.totalUnpaid} />
        <TotalScheduled isLoading={isCardLoading} amount={summaries?.totalScheduled} />
      </div>

      <SmartInstallmentsTable filters={filters} setFilters={setFilters} offers={offers} isLoading={isLoadingOffers} />
    </div>
  )
})
