import { Auth } from '@hub-la/fe-core-auth'
import { HttpClient, HttpMethod } from '@hub-la/fe-core-http-client'
import { inject, injectable } from 'inversify'

const SITE_URL = process.env['NX_SITE_URL'] ?? process.env['NEXT_PUBLIC_SITE_URL'] ?? process.env['REACT_APP_SITE_URL']

@injectable()
export class SignOut {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient<void>,
    @inject(Auth) private readonly auth: Auth,
  ) {}

  public async execute(): Promise<void> {
    await this.auth.signOut()

    localStorage.removeItem('currentUser')
    localStorage.removeItem('authUser')

    await this.httpClient.request({
      url: `${SITE_URL}/api/logout`,
      method: HttpMethod.POST,
      withCredentials: true,
    })
  }
}
