import { Tabs, TabsContent, TabsList, TabsTrigger } from '@hub-la/shadcn'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IntegrationProvider } from '../../../domain/dtos/integration-provider'
import { useGetIntegrationByProvider } from '../../hooks/use-get-integration-by-provider'
import { IntegrationAboutTab } from '../integration-about-tab'
import { IntegrationHistoryTab } from '../integration-history-tab'
import { IntegrationSettingsTab } from '../integration-settings-tab'
import { WebhookAuthenticationTab } from './webhook-authentication-tab'
import { ruleSchema, saveRuleSchema } from './webhook.schema'

type WebhookDetailProps = {
  provider: IntegrationProvider
}

export const WebhookDetail = ({ provider }: WebhookDetailProps) => {
  const { t } = useTranslation()
  const [tab, setTab] = useState('about')
  const { data: integration, refetch, isFetching, isLoading } = useGetIntegrationByProvider({ provider })

  const loading = isLoading || isFetching
  const hasIntegration = !!integration

  return (
    <Tabs value={tab} onValueChange={setTab}>
      <div className="w-full overflow-x-auto overflow-y-hidden border-b">
        <TabsList variant="underlined" className="border-none">
          <TabsTrigger value="about" variant="underlined">
            {t('tabs.about')}
          </TabsTrigger>

          {hasIntegration && (
            <>
              <TabsTrigger value="authentication" variant="underlined">
                {t('tabs.authentication')}
              </TabsTrigger>
              <TabsTrigger value="settings" variant="underlined">
                {t('tabs.settings')}
              </TabsTrigger>
              <TabsTrigger value="history" variant="underlined">
                {t('tabs.history')}
              </TabsTrigger>
            </>
          )}
        </TabsList>
      </div>

      <TabsContent className="mt-4" value="about">
        <IntegrationAboutTab
          provider={provider}
          integrationId={integration?.id}
          loading={loading}
          refetch={refetch}
          setTab={setTab}
        />
      </TabsContent>

      {hasIntegration && (
        <>
          <TabsContent className="mt-4" value="authentication">
            <WebhookAuthenticationTab secretToken={integration.auth?.secretToken} />
          </TabsContent>
          <TabsContent className="mt-4" value="settings">
            <IntegrationSettingsTab
              provider={provider}
              integrationId={integration.id}
              ruleSchema={ruleSchema}
              ruleValidationSchema={saveRuleSchema}
            />
          </TabsContent>
          <TabsContent className="mt-4" value="history">
            <IntegrationHistoryTab provider={provider} integrationId={integration.id} />
          </TabsContent>
        </>
      )}
    </Tabs>
  )
}
