import { Facebook } from './facebook'
import { Pixels } from './pixels'

export const Trackers = () => {
  return (
    <div className="flex flex-col space-y-4">
      <Facebook />
      <Pixels />
    </div>
  )
}
