/* eslint-disable @typescript-eslint/no-explicit-any */

import Long from 'long'

export function toDate(value: any | undefined | null): Date | null {
  if (value instanceof Date) {
    return value
  }
  if (typeof value === 'number') {
    return new Date(value * 1000)
  }
  if (typeof value === 'string') {
    return new Date(value)
  }

  if (Long.isLong(value?._seconds) && typeof value?._nanoseconds === 'number') {
    return new Date(value._seconds.low * 1e3 + value._nanoseconds / 1e6)
  }

  if (typeof value?._seconds === 'number' && typeof value?._nanoseconds === 'number') {
    return new Date(value._seconds * 1e3 + value._nanoseconds / 1e6)
  }

  if (Long.isLong(value?.seconds) && typeof value?.nanoseconds === 'number') {
    return new Date(value.seconds.low * 1e3 + value.nanoseconds / 1e6)
  }

  if (typeof value?.seconds === 'number' && typeof value?.nanoseconds === 'number') {
    return new Date(value.seconds * 1e3 + value.nanoseconds / 1e6)
  }

  return null
}
