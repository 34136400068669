export const BRAZILIAN_BANKS = [
  { compe: '104', name: 'Caixa Econômica Federal' },
  { compe: '237', name: 'Bradesco' },
  { compe: '341', name: 'Itaú Unibanco' },
  { compe: '260', name: 'Nubank - Nu Pagamentos S.A.' },
  { compe: '001', name: 'Banco do Brasil' },
  { compe: '033', name: 'Santander' },
  { compe: '212', name: 'Original' },
  { compe: '518', name: 'Mercado Crédito sociedade de crédito, financiamento e investimento s.a.' },
  { compe: '290', name: 'Pagbank - Pagseguro Internet S.A' },
  { compe: '336', name: 'Banco C6 S.A.' },
  { compe: '077', name: 'Banco Inter' },
  { compe: '208', name: 'Banco BTG Pactual' },
  { compe: '735', name: 'Neon' },
  { compe: '655', name: 'Banco Votorantim S.A.' },
  { compe: '269', name: 'HSBC Banco de Investimento' },
  { compe: '348', name: 'Banco XP S.A.' },
  { compe: '380', name: 'Picpay servicos s.a.' },
  { compe: '246', name: 'Banco ABC Brasil' },
  { compe: '465', name: 'Capital consig sociedade de crédito direto s.a.' },
  { compe: '144', name: 'Bexs' },
  { compe: '748', name: 'Sicredi' },
  { compe: '183', name: 'Socred SCM' },
  { compe: '088', name: 'Banco randon s.a.' },
  { compe: '478', name: 'Gazincred s.a. sociedade de crédito, financiamento e investimento' },
  { compe: '243', name: 'Banco Máxima' },
  { compe: '542', name: 'Cloud walk meios de pagamentos e servicos ltda' },
  { compe: '529', name: 'Pinbank brasil - pagamentos inteligentes s.a.' },
  { compe: '452', name: 'Credifit sociedade de crédito direto s.a.' },
  { compe: '425', name: 'Socinal S.A. - crédito, financiamento e investimento' },
  { compe: '534', name: 'Ewally tecnologia e servicos s.a.' },
  { compe: '362', name: 'CIELO S.A' },
  { compe: '119', name: 'Banco Western Union' },
  { compe: '428', name: 'Cred-system sociedade de crédito direto s.a.' },
  { compe: '335', name: 'Banco Digio S.A.' },
  { compe: '511', name: 'Magnum sociedade de crédito direto s.a.' },
  { compe: '084', name: 'Uniprime do Norte do Paraná' },
  { compe: '435', name: 'Delcred sociedade de crédito direto s.a.' },
  { compe: '322', name: 'Sulcredi / Crediluz - Abelardo Luz' },
  { compe: '099', name: 'Uniprime Central' },
  { compe: '756', name: 'Sicoob' },
  { compe: '462', name: 'Stark sociedade de crédito direto s.a.' },
  { compe: '070', name: 'Banco de Brasília - BRB' },
  { compe: '332', name: 'Acesso Soluções De Pagamento S.A.' },
  { compe: '450', name: 'FITBANK INSTITUICAO DE PAGAMENTOS ELETRONICOS S.A.' },
  { compe: '461', name: 'ASAAS IP S.A.' },
  { compe: '419', name: 'Numbrs sociedade de crédito direto s.a.' },
  { compe: '509', name: 'Celcoin instituicao de pagamento s.a.' },
  { compe: '312', name: 'Hscm - sociedade de crédito ao microempreendedor e à empresa de pequeno porte lt' },
  { compe: '359', name: 'Zema Crédito, Financiamento E Investimento' },
  { compe: '427', name: 'Cooperativa de credito dos servidores da universidade federal do espirito santo' },
  { compe: '407', name: 'Índigo investimentos distribuidora de títulos e valores mobiliários ltda.' },
  { compe: '276', name: 'Senff Financeira' },
  { compe: '281', name: 'Coopavel' },
  { compe: '355', name: 'Ótimo Sociedade De Crédito Direto S.A.' },
  { compe: '374', name: 'Realize crédito, financiamento e investimento s.a.' },
  { compe: '382', name: 'Fidúcia sociedade de crédito ao microempreendedor e à empresa de pequeno porte l' },
  { compe: '404', name: 'Sumup sociedade de crédito direto s.a.' },
  { compe: '368', name: 'Banco CSF' },
  { compe: '408', name: 'Bônuscred sociedade de crédito direto s.a.' },
  { compe: '350', name: 'Crehnor Laranjeiras' },
  { compe: '397', name: 'Listo sociedade de credito direto s.a.' },
  { compe: '330', name: 'Banco Bari' },
  { compe: '324', name: 'Cartos sociedade de crédito direto s.a.' },
  { compe: '396', name: 'Hub pagamentos s.a' },
  { compe: '753', name: 'Novo Banco Continental' },
  { compe: '451', name: 'J17 - sociedade de crédito direto s/a' },
  { compe: '384', name: 'Global finanças sociedade de crédito ao microempreendedor e à empresa de pequeno' },
  { compe: '546', name: 'U4crypto solucoes tecnologicas e financeiras sa' },
  { compe: '079', name: 'Banco Original do Agronegócio' },
  { compe: '358', name: 'Midway s.a. - crédito, financiamento e investimento' },
  { compe: '310', name: 'Vortx DTVM' },
  { compe: '403', name: 'Cora SCD S.A.' },
  { compe: '481', name: 'Superlógica sociedade de crédito direto s.a.' },
  { compe: '413', name: 'Banco bv s.a.' },
  { compe: '654', name: 'Banco Renner' },
  { compe: '041', name: 'Banrisul' },
  { compe: '010', name: 'Credicoamo' },
  { compe: '402', name: 'Cobuccio sociedade de crédito direto s.a.' },
  { compe: '712', name: 'Banco Ourinvest' },
  { compe: '610', name: 'Banco VR' },
  { compe: '218', name: 'Banco BS2' },
  { compe: '633', name: 'Banco Rendimento' },
  { compe: '273', name: 'Sulcredi - São Miguel' },
  { compe: '707', name: 'Daycoval' },
  { compe: '089', name: 'Cooperativa de Crédito Rural da Região da Mogiana' },
  { compe: '318', name: 'Banco BMG' },
  { compe: '069', name: 'Crefisa' },
  { compe: '653', name: 'Banco Indusval' },
  { compe: '637', name: 'Banco Sofisa' },
  { compe: '623', name: 'Banco Pan' },
  { compe: '224', name: 'Banco Fibra' },
  { compe: '630', name: 'Banco Smartbank S.A.' },
  { compe: '422', name: 'Banco Safra' },
  { compe: '213', name: 'Banco Arbi' },
  { compe: '125', name: 'Brasil plural' },
  { compe: '604', name: 'Banco Industrial' },
  { compe: '612', name: 'Banco Guanabara' },
  { compe: '136', name: 'Unicred' },
  { compe: '329', name: 'QI Sociedade De Crédito Direto S.A.' },
  { compe: '326', name: 'Parati CFI S.A.' },
  { compe: '746', name: 'Banco Modal' },
  { compe: '430', name: 'Cooperativa de credito rural seara - crediseara' },
  { compe: '021', name: 'Banestes' },
  { compe: '401', name: 'Iugu servicos na internet s/a' },
  { compe: '383', name: 'Juno' },
  { compe: '634', name: 'Banco Triângulo' },
  { compe: '468', name: 'Portoseg s.a. - credito, financiamento e investimento' },
  { compe: '421', name: 'Lar cooperativa de crédito - lar credi' },
  { compe: '389', name: 'Mercantil do Brasil' },
  { compe: '274', name: 'Money Plus SCMEPP' },
  { compe: '197', name: 'Stone Pagamentos' },
  { compe: '254', name: 'Banco Parana' },
  { compe: '301', name: 'BPP Instituição de Pagamento S.A.' },
  { compe: '096', name: 'Banco BM&FBovespa' },
  { compe: '047', name: 'Banese' },
  { compe: '323', name: 'Mercado Pago' },
  { compe: '133', name: 'Confederação Nac das CCC SOL' },
  { compe: '412', name: 'Banco Capital' },
  { compe: '095', name: 'Banco Confidence de Câmbio' },
  { compe: '444', name: 'Trinus sociedade de crédito direto s.a.' },
  { compe: '536', name: 'Neon pagamentos s.a.' },
  { compe: '121', name: 'Banco Agibank S.A' },
  { compe: '340', name: 'Super Pagamentos' },
  { compe: '130', name: 'Caruana SCFI' },
  { compe: '364', name: 'Efí S.A' },
  { compe: '082', name: 'Banco Topazio' },
  { compe: '004', name: 'Banco do Nordeste' },
  { compe: '085', name: 'Cooperativa Central de Crédito Urbano' },
  { compe: '159', name: 'Casa do Crédito' },
  { compe: '037', name: 'Banco do Estado do Pará' },
  { compe: '003', name: 'Banco da Amazônia' },
  { compe: '012', name: 'Banco Inbursa' },
  { compe: '016', name: 'Creditran' },
  { compe: '097', name: 'CCC Noroeste Brasileiro' },
  { compe: '406', name: 'Accredito - sociedade de crédito direto s.a.' },
  { compe: '439', name: 'Id corretora de títulos e valores mobiliários s.a.' },
  { compe: '025', name: 'Banco Alfa' },
  { compe: '537', name: 'Microcash sociedade de crédito ao microempreendedor e à empresa de pequeno porte' },
  { compe: '757', name: 'Banco Keb Hana' },
  { compe: '299', name: 'Sorocred Crédito, Financiamento E Investimento' },
  { compe: '755', name: 'Bank of America Merrill Lynch' },
  { compe: '741', name: 'Banco Ribeirão Preto S.A.' },
  { compe: '376', name: 'Banco JP Morgan' },
  { compe: '743', name: 'Banco Semear' },
  { compe: '098', name: 'Credialiança' },
  { compe: '600', name: 'Banco Luso Brasileiro' },
  { compe: '745', name: 'Citibank' },
  { compe: '613', name: 'Omni Banco' },
  { compe: '174', name: 'Pernambucanas Financiadora' },
  { compe: '286', name: 'Sulcredi - Ouro' },
  { compe: '457', name: 'UY3 SCD S/A' },
  { compe: '279', name: 'CCR de Primavera do Leste' },
  { compe: '349', name: 'Amaggi S.A.' },
  { compe: '131', name: 'Tullett Prebon' },
  { compe: '113', name: 'Magliano CCVM' },
  { compe: '479', name: 'Banco ItauBank' },
  { compe: '139', name: 'Intesa Sanpaolo' },
  { compe: '111', name: 'Oliveira Trust' },
  { compe: '128', name: 'MS Bank' },
  { compe: '129', name: 'UBS' },
  { compe: '467', name: 'Master s/a corretora de câmbio, títulos e valores mobiliários' },
  { compe: '454', name: 'Mérito distribuidora de títulos e valores mobiliários ltda.' },
  { compe: '343', name: 'FFA SCMEPP' },
  { compe: '321', name: 'Crefaz' },
  { compe: '298', name: "Vip's CC" },
  { compe: '132', name: 'ICBC' },
  { compe: '309', name: 'Cambionet CC' },
  { compe: '508', name: 'Avenue securities distribuidora de títulos e valores mobiliários ltda.' },
  { compe: '331', name: 'Fram Capital DTVM S.A.' },
  { compe: '475', name: 'Banco yamaha motor do brasil s.a.' },
  { compe: '325', name: 'Órama DTVM S.A.' },
  { compe: '259', name: 'Moneycorp' },
  { compe: '482', name: 'Sbcash sociedade de crédito direto s.a.' },
  { compe: '522', name: 'Red sociedade de crédito direto s.a.' },
  { compe: '063', name: 'Banco Bradescard' },
  { compe: '516', name: 'Fc financeira s.a. - crédito, financiamento e investimento' },
  { compe: '510', name: 'Ffcred sociedade de crédito direto s.a..' },
  { compe: '296', name: 'Vision S.A. Corretora De Cambio' },
  { compe: '532', name: 'Eagle sociedade de crédito direto s.a.' },
  { compe: '443', name: 'Credihome sociedade de crédito direto s.a.' },
  { compe: '138', name: 'Get Money' },
  { compe: '149', name: 'Facta CFI' },
  { compe: '463', name: 'Azumi distribuidora de títulos e valores mobiliários ltda.' },
  { compe: '315', name: 'PI Investimentos' },
  { compe: '459', name: 'Cooperativa de crédito mútuo de servidores públicos do estado de são paulo - cre' },
  { compe: '471', name: 'Cooperativa de economia e credito mutuo dos servidores publicos de pinhão - cres' },
  { compe: '525', name: 'Intercam corretora de câmbio ltda.' },
  { compe: '455', name: 'Fênix distribuidora de títulos e valores mobiliários ltda.' },
  { compe: '513', name: 'Atf credit sociedade de crédito direto s.a.' },
  { compe: '108', name: 'PortoCred' },
  { compe: '519', name: 'Lions trust distribuidora de títulos e valores mobiliários ltda.' },
  { compe: '398', name: 'Ideal corretora de títulos e valores mobiliários s.a.' },
  { compe: '386', name: 'Nu financeira s.a. - sociedade de crédito, financiamento e investimento' },
  { compe: '100', name: 'Planner' },
  { compe: '410', name: 'Planner sociedade de crédito ao microempreendedor s.a.' },
  { compe: '400', name: 'Cooperativa de crédito, poupança e serviços financeiros do centro oeste' },
  { compe: '371', name: 'Warren CVMC' },
  { compe: '512', name: 'Captalys distribuidora de títulos e valores mobiliários ltda.' },
  { compe: '393', name: 'Banco volkswagen s.a.' },
  { compe: '507', name: 'Gerencianet s.a. - crédito, financiamento e investimento' },
  { compe: '449', name: 'Dmcard sociedade de crédito direto s.a.' },
  { compe: '137', name: 'Multimoney' },
  { compe: '429', name: 'Crediare s.a. - crédito, financiamento e investimento' },
  { compe: '135', name: 'Gradual' },
  { compe: '180', name: 'CM Capital Markets CCTVM' },
  { compe: '143', name: 'Treviso' },
  { compe: '190', name: 'Servicoop' },
  { compe: '191', name: 'Nova Futura CTVM' },
  { compe: '320', name: 'China Construction Bank' },
  { compe: '189', name: 'HS Financeira' },
  { compe: '157', name: 'ICAP' },
  { compe: '091', name: 'Unicred RS' },
  { compe: '173', name: 'BRL Trust' },
  { compe: '268', name: 'Barigui Companhia Hipotecária' },
  { compe: '142', name: 'Broker' },
  { compe: '194', name: 'Parmetal DTVM' },
  { compe: '163', name: 'Commerzbank' },
  { compe: '146', name: 'Guitta' },
  { compe: '271', name: 'IB CCTVM' },
  { compe: '118', name: 'Standard Chartered' },
  { compe: '460', name: 'Unavanti sociedade de crédito direto s/a' },
  { compe: '172', name: 'Albatross' },
  { compe: '182', name: 'Dacasa Financeira' },
  { compe: '458', name: 'Hedge investments distribuidora de títulos e valores mobiliários ltda.' },
  { compe: '134', name: 'BGC Liquidez' },
  { compe: '521', name: 'Peak sociedade de empréstimo entre pessoas s.a.' },
  { compe: '241', name: 'Banco Clássico' },
  { compe: '196', name: 'Fair' },
  { compe: '300', name: 'Banco de la Nacion Argentina' },
  { compe: '266', name: 'Banco Cédula' },
  { compe: '265', name: 'Banco Fator' },
  { compe: '188', name: 'Ativa Investimentos' },
  { compe: '411', name: 'Via certa financiadora s.a. - crédito, financiamento e investimentos' },
  { compe: '145', name: 'Levycam' },
  { compe: '250', name: 'BCV' },
  { compe: '253', name: 'Bexs Corretora de Câmbio' },
  { compe: '527', name: 'Aticca - sociedade de crédito direto s.a.' },
  { compe: '370', name: 'Banco Mizuho' },
  { compe: '249', name: 'Banco Investcred Unibanco' },
  { compe: '535', name: 'Marú sociedade de crédito direto s.a.' },
  { compe: '366', name: 'Banco Société Générale' },
  { compe: '233', name: 'Banco Cifra' },
  { compe: '177', name: 'Guide' },
  { compe: '523', name: 'Hr digital - sociedade de crédito direto s/a' },
  { compe: '169', name: 'Banco Olé Bonsucesso' },
  { compe: '222', name: 'Banco Credit Agrícole Brasil' },
  { compe: '217', name: 'Banco John Deere' },
  { compe: '120', name: 'Banco Rodobens' },
  { compe: '127', name: 'compepe CVC' },
  { compe: '105', name: 'Lecca CFI' },
  { compe: '101', name: 'Renascença DTVM' },
  { compe: '140', name: 'Easynvest' },
  { compe: '447', name: 'Mirae asset wealth management (brazil) corretora de câmbio, títulos e valores mo' },
  { compe: '494', name: 'BROU' },
  { compe: '719', name: 'Banif' },
  { compe: '641', name: 'Banco Alvorada' },
  { compe: '126', name: 'BR Partners' },
  { compe: '117', name: 'Advanced Corretora' },
  { compe: '102', name: 'XP Investimentos' },
  { compe: '739', name: 'Banco Cetelem' },
  { compe: '080', name: 'B&T CC' },
  { compe: '652', name: 'Itaú Unibanco Holding S.A.' },
  { compe: '720', name: 'Banco rnx s.a.' },
  { compe: '011', name: 'Credit Suisse Hedging-Griffo' },
  { compe: '204', name: 'Banco Bradesco Cartões S.A.' },
  { compe: '539', name: 'Santinvest s.a. - credito, financiamento e investimentos' },
  { compe: '438', name: 'Planner trustee distribuidora de títulos e valores mobiliários ltda.' },
  { compe: '747', name: 'Rabobank' },
  { compe: '752', name: 'BNP Paribas' },
  { compe: '399', name: 'Kirton Bank' },
  { compe: '036', name: 'Banco Bradesco BBI S.A.' },
  { compe: '394', name: 'Banco Bradesco Financiamentos S.A.' },
  { compe: '107', name: 'Banco Bocom BBM S.A.' },
  { compe: '184', name: 'BANCO ITAÚ BBA S.A.' },
  { compe: '545', name: 'Senso' },
  { compe: '280', name: 'Avista S.A. Crédito, Financiamento e Investimento' },
  { compe: '751', name: 'Scotiabank' },
  { compe: '505', name: 'Banco Credit Suisse' },
  { compe: '477', name: 'Citibank NA' },
  { compe: '495', name: 'Bapro' },
  { compe: '488', name: 'JPMorgan Chase Bank' },
  { compe: '492', name: 'ING' },
  { compe: '456', name: 'Banco MUFG Brasil' },
  { compe: '464', name: 'Banco Sumitomo Mitsui' },
  { compe: '626', name: 'Banco Ficsa' },
  { compe: '611', name: 'Banco Paulista' },
  { compe: '487', name: 'Deutsche Bank' },
  { compe: '754', name: 'Banco Sistema' },
  { compe: '018', name: 'Banco Tricury' },
  { compe: '334', name: 'Banco besa s.a.' },
  { compe: '524', name: 'Wnt capital distribuidora de títulos e valores mobiliários s.a.' },
  { compe: '065', name: 'AndBank' },
  { compe: '528', name: 'Reag distribuidora de títulos e valores mobiliários s.a.' },
  { compe: '440', name: 'Cooperativa de economia e crédito mútuo brf - credibrf' },
  { compe: '328', name: 'Cooperativa de economia e crédito mútuo dos fabricantes de calçados de sapiranga' },
  { compe: '418', name: 'Zipdin soluções digitais sociedade de crédito direto s/a' },
  { compe: '414', name: 'Work sociedade de crédito direto s.a.' },
  { compe: '416', name: 'Lamara sociedade de crédito direto s.a.' },
  { compe: '448', name: 'Hemera distribuidora de títulos e valores mobiliários ltda.' },
  { compe: '195', name: 'Valor sociedade de crédito direto s.a.' },
  { compe: '390', name: 'Banco gm s.a.' },
  { compe: '445', name: 'Plantae s.a. - crédito, financiamento e investimento' },
  { compe: '377', name: 'Bms sociedade de crédito direto s.a.' },
  { compe: '313', name: 'Amazônia corretora de câmbio ltda.' },
  { compe: '017', name: 'BNY Mellon Banco' },
  { compe: '433', name: 'Br-capital distribuidora de títulos e valores mobiliários s.a.' },
  { compe: '391', name: 'Cooperativa de credito rural de ibiam - sulcredi/ibiam' },
  { compe: '423', name: 'Coluna s/a distribuidora de titulos e valores mobiliários' },
  { compe: '387', name: 'Banco toyota do brasil s.a.' },
  { compe: '360', name: 'Trinus capital distribuidora de títulos e valores mobiliários s.a.' },
  { compe: '378', name: 'Bbc leasing s.a. - arrendamento mercantil' },
  { compe: '319', name: 'OM DTVM' },
  { compe: '541', name: 'Fundo garantidor de creditos - fgc' },
  { compe: '530', name: 'Ser finance sociedade de crédito direto s.a.' },
  { compe: '078', name: 'Haitong' },
  { compe: '007', name: 'BNDES' },
  { compe: '092', name: 'BRK CFI' },
  { compe: '094', name: 'Banco Finaxis' },
  { compe: '024', name: 'Banco Bandepe' },
  { compe: '083', name: 'Banco da China' },
  { compe: '014', name: 'Natixis Brasil' },
  { compe: '076', name: 'Banco KDB' },
  { compe: '114', name: 'CECOOP' },
  { compe: '060', name: 'Confidence Câmbio' },
  { compe: '064', name: 'Goldman Sachs' },
  { compe: '141', name: 'Banco master de investimento s.a.' },
  { compe: '395', name: "F.d'gold - distribuidora de títulos e valores mobiliários ltda." },
  { compe: '040', name: 'Banco Cargill' },
  { compe: '093', name: 'Pólocred' },
  { compe: '075', name: 'ABN Amro' },
  { compe: '074', name: 'Banco J Safra' },
  { compe: '062', name: 'Hipercard' },
  { compe: '311', name: 'Dourada corretora de câmbio ltda.' },
  { compe: '015', name: 'UBS Brasil' },
  { compe: '066', name: 'Banco Morgan Stanley' },
  { compe: '081', name: 'Bancoseguro S.A.' },
  { compe: '506', name: 'Rji corretora de titulos e valores mobiliarios ltda' },
  { compe: '124', name: 'Woori Bank' },
  { compe: '484', name: 'Maf distribuidora de títulos e valores mobiliários s.a.' },
  { compe: '470', name: 'Cdc sociedade de crédito ao microempreendedor e à empresade pequeno porte ltda.' },
  { compe: '469', name: 'Lecca distribuidora de titulos e valores mobiliarios ltda' },
  { compe: '526', name: 'Monetarie sociedade de crédito direto s.a.' },
  { compe: '385', name: 'Cooperativa de economia e credito mutuo dos trabalhadores portuarios da grande v' },
  { compe: '426', name: 'Biorc financeira - crédito, financiamento e investimento s.a.' },
  { compe: '442', name: 'Magnetis - distribuidora de títulos e valores mobiliários ltda' },
  { compe: '289', name: 'Decyseo CC' },
  { compe: '283', name: 'RB Capital Investimentos DTVM' },
  { compe: '643', name: 'Banco Pine' },
  { compe: '285', name: 'Frente CC' },
  { compe: '293', name: 'Lastro RDV DTVM' },
  { compe: '365', name: 'Solidus CCVM S.A.' },
  { compe: '363', name: 'Socopa SC Paulista S.A.' },
  { compe: '288', name: 'Carol DTVM' },
  { compe: '270', name: 'Sagitur CC' },
  { compe: '381', name: 'Banco Mercedes-benz Do Brasil S.A.' },
  { compe: '354', name: 'Necton Investimentos S.A.' },
  { compe: '306', name: 'Portopar DTVM' },
  { compe: '373', name: 'UP.P SEP S.A.' },
  { compe: '367', name: 'Vitreo DTVM S.A.' },
  { compe: '029', name: 'Banco Itaú Consignado S.A.' },
  { compe: '473', name: 'Banco Caixa Geral - Brasil S.A.' },
  { compe: '122', name: 'Banco Bradesco Berj S.A.' },
  { compe: '352', name: 'Toro CTVM' },
  { compe: '292', name: 'BS2 DTVM S.A.' },
  { compe: '379', name: 'Cooperforte - cooperativa de economia e crédito mútuo dos funcionários de instit' },
  { compe: '272', name: 'AGK Corretora De Cambio' },
  { compe: '342', name: 'Creditas Sociedade De Crédito Direto S.A.' },
  { compe: '307', name: 'Terra Investimentos' },
  { compe: '278', name: 'Genial Investimentos CVM' },
]

export type BrazilianBankcompe = typeof BRAZILIAN_BANKS[number]['compe']
