import { HttpClient, HttpMethod, HttpResponse, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { isNil } from 'lodash'
import { GetProductsOutput } from '../domain/dtos/get-products-output'
import { GetProductsResponse } from '../domain/dtos/get-products-response'
import { Envs } from '../envs'

export class GetProducts {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(): Promise<GetProductsOutput> {
    const response = await this.httpClient.request({
      method: HttpMethod.GET,
      url: `${Envs.BFF_MEMBERS_AREA_URL}/products`,
    })

    if (response.statusCode === HttpStatusCode.OK) {
      return this.makeOutput(response)
    }

    throw new Error('Ocorreu um erro ao buscar os produtos')
  }

  private makeOutput(response: HttpResponse<GetProductsResponse>): GetProductsOutput {
    const data = response.data

    if (isNil(data)) {
      return []
    }

    return (data.items ?? []).map(({ id, name, picture }) => ({ id, name, picture }))
  }
}
