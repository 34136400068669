import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { GetConversionRateInput } from '../domain/dtos/get-conversion-rate-input'
import { GetConversionRateOutput } from '../domain/dtos/get-conversion-rate-output'
import { GeneralError } from '../domain/errors/general'
import { Envs } from '../envs'
import { GetOfferIds } from './get-offer-ids'

export class GetConversionRate {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(input: GetConversionRateInput, signal?: AbortSignal): Promise<GetConversionRateOutput> {
    const response = await this.httpClient.request({
      method: HttpMethod.POST,
      url: `${Envs.BFF_WEB_URL}/receiver/summary/conversion-rate`,
      body: this.makeBody(input),
      signal,
    })

    if (response.statusCode === HttpStatusCode.OK) {
      return response.data
    }

    throw new GeneralError()
  }

  private makeBody(input: GetConversionRateInput) {
    const offerIds = new GetOfferIds().execute({ offerIds: input.offerIds, offers: input.offers })

    return {
      paymentMethod: input.paymentMethod,
      startDate: input.startDate,
      endDate: input.endDate,
      offerIds,
    }
  }
}
