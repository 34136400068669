import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { OffersAndCohortsName } from '@hub-la/sdk-bff-product'
import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '../../../domain/enums/query-key'
import { GetOffersAndCohorts } from '../../../usecases/members/get-offers-and-cohorts'

export const useGetOffersAndCohorts = (productId?: string) => {
  const container = useContainer()

  return useQuery<OffersAndCohortsName>(
    [QueryKey.getOffersAndCohorts, productId],
    () => new GetOffersAndCohorts(container.get(HttpClient)).execute(productId),
    {
      retry: false,
      retryOnMount: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      enabled: !!productId,
    },
  )
}
