import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { PermissionResource } from '../domain/dtos/permissions-resources-enum'
import { PutCollaboratorInput } from '../domain/dtos/put-collaborator-input'
import { GeneralError } from '../domain/errors/general'
import { Envs } from '../envs'

export class PutCollaborator {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(input: PutCollaboratorInput): Promise<null> {
    const response = await this.httpClient.request({
      method: HttpMethod.PUT,
      url: `${Envs.BFF_WEB_URL}/user/roleplay/collaborators`,
      body: this.makeBody(input),
    })

    if (response.statusCode === HttpStatusCode.OK) {
      return null
    }

    throw new GeneralError()
  }

  private makeBody({ collaboratorUserId, permissions }: PutCollaboratorInput) {
    return {
      collaboratorUserId,
      permissions: Object.values(PermissionResource).map((resource) => {
        return { resource, granted: permissions.includes(resource), action: 'all' }
      }),
    }
  }
}
