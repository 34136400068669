import { AssetType } from '@hub-la/fe-asset'

export class GetPostType {
  public execute(postCoverType?: AssetType): 'article' | 'image' | 'pdf' | 'video' {
    if (!postCoverType) return 'article'
    if (postCoverType === AssetType.image) return 'image'
    if (postCoverType === AssetType.application) return 'pdf'
    if (postCoverType === AssetType.video) return 'video'

    // Se nenhum dos casos acima for atendido, retornar o tipo padrão
    return 'article'
  }
}
