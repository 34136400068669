import { injectable } from 'inversify'

const LOCAL_STORAGE_KEY = 'productsModulesHighlightVisualized'

@injectable()
export class SetModulesHighlightVisualized {
  public execute(productId: string) {
    if (typeof window === 'undefined' || typeof localStorage === 'undefined') {
      return
    }
    const productsModulesHighlightVisualizedStorage = localStorage.getItem(LOCAL_STORAGE_KEY)

    const productsIdsVisualized = productsModulesHighlightVisualizedStorage
      ? JSON.parse(productsModulesHighlightVisualizedStorage)
      : []

    const isProductIdOnVisualized = productsIdsVisualized.includes(productId)

    if (!isProductIdOnVisualized) {
      productsIdsVisualized.push(productId)
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(productsIdsVisualized))
    }
  }
}
