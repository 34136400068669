import { PermissionResource, useRoleplay } from '@hub-la/fe-roleplay'
import times from 'lodash/times'
import { Info } from 'lucide-react'
import moment from 'moment'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import {
  Checkbox,
  Label,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TablePagination,
  TableRow,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@hub-la/shadcn'

import { TableEmptyState } from '@hub-la/shadcn'
import { Member } from '../../../../domain/dtos/member'
import { MemberType } from '../../../../domain/enums/member-type'
import { Phone } from '../../../../domain/vos/phone'
import { GetCohortsContent } from '../../../../usecases/members/get-cohorts-content'
import { AddFreeMemberModal } from '../../../components/add-free-member-modal/add-free-member-modal'
import { GenericConfirmModal } from '../../../components/generic-confirm-modal'
import { useGetCohortsList } from '../../../hooks/cohorts/use-get-cohorts-list'
import { useGetActiveMembers } from '../../../hooks/members/use-get-active-members'
import { useGetExportActiveMembers } from '../../../hooks/members/use-get-export-active-members'
import { usePutRemoveFreeSubscription } from '../../../hooks/members/use-put-remove-free-subscription'
import { Actions } from './actions'
import { ChangeMembersCohortsModal } from './change-members-cohorts-modal'
import { MembersSelectedsBarActions } from './members-selecteds-bar-actions'
import { RowMemberActions } from './row-member-actions'

const SkeletonRow = () => (
  <TableRow>
    <TableCell>
      <Skeleton className="h-6 w-4/5" />
    </TableCell>
    <TableCell>
      <Skeleton className="h-6 w-[85%]" />
    </TableCell>
    <TableCell>
      <Skeleton className="h-6 w-[85%]" />
    </TableCell>
    <TableCell>
      <Skeleton className="h-6 w-[85%]" />
    </TableCell>
    <TableCell>
      <Skeleton className="h-6 w-[85%]" />
    </TableCell>
  </TableRow>
)

interface ActiveMembersTableProps {
  offerId: string
}

export const ActiveMembersTable = ({ offerId }: ActiveMembersTableProps) => {
  const { t } = useTranslation()
  const { productId } = useParams<{ productId: string }>()
  const { hasPermission } = useRoleplay()

  const getCohortsContent = new GetCohortsContent()

  const [types, setTypes] = useState<MemberType[]>([])
  const [search, setSearch] = useState<string>('')
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(25)

  const [openChangeMemberCohortsModal, setOpenChangeMemberCohortsModal] = useState<Member | null>(null)
  const [openChangeMembersCohortsModal, setOpenChangeMembersCohortsModal] = useState(false)

  const [selectedItems, setSelectedItems] = useState<Member[]>([])

  const [openAddFreeMemberModal, setOpenAddFreeMemberModal] = React.useState(false)
  const [openRemoveMemberModal, setOpenRemoveMemberModal] = React.useState<{
    isOpen: boolean
    userId: string
  }>({ isOpen: false, userId: '' })

  const { data: cohorts } = useGetCohortsList({
    productId,
    page,
    pageSize,
  })

  const { data: activeMembers, isLoading } = useGetActiveMembers({
    productId,
    page,
    pageSize,
    types,
    search,
  })

  const { mutateAsync, isLoading: removeFreeMemberLoading } = usePutRemoveFreeSubscription({
    onSuccess: () => {
      setOpenRemoveMemberModal({
        isOpen: false,
        userId: '',
      })
    },
  })

  const canRenderEmptyState = !isLoading && (activeMembers?.items ?? []).length <= 0

  const { mutate: exportActiveMembers } = useGetExportActiveMembers()

  const handleExportActiveMembers = () => {
    exportActiveMembers({
      productId,
      offerId,
      page: 1,
      pageSize: 5000,
      types,
      search,
    })
  }

  const isCheckAll = selectedItems.length === activeMembers?.items.length

  const handleOnChange = (checked: boolean, value: string): void => {
    if (!activeMembers) {
      return
    }

    if (value === 'all' && !checked) {
      setSelectedItems([])
      return
    }

    if (value === 'all') {
      setSelectedItems(activeMembers?.items)
      return
    }

    setSelectedItems((prevSelectedItems) => {
      const selectedMember = activeMembers?.items.find((member) => member.id === value)

      if (!selectedMember) {
        return prevSelectedItems
      }

      if (checked) {
        return [...prevSelectedItems, selectedMember]
      }

      return prevSelectedItems.filter((member) => member.id !== selectedMember.id)
    })
  }

  return (
    <div className="flex flex-col gap-4">
      <Actions
        onAddMember={() => setOpenAddFreeMemberModal(true)}
        onSearchChange={(search) => setSearch(search)}
        onSubscriptionTypeChange={(types) => setTypes(types as MemberType[])}
        onExportActiveMembers={handleExportActiveMembers}
      />
      <AddFreeMemberModal
        open={openAddFreeMemberModal}
        onClose={() => setOpenAddFreeMemberModal(false)}
        onSubmit={() => setOpenAddFreeMemberModal(false)}
        productId={productId}
      />
      <div className="border bg-background w-full rounded-lg">
        <Table>
          <TableHeader>
            <TableRow className="hover:bg-background">
              <TableHead>
                <Checkbox
                  checked={isCheckAll}
                  onCheckedChange={(checked) => handleOnChange(checked as boolean, 'all')}
                />
              </TableHead>
              <TableHead>
                <div className="flex items-center space-x-2">
                  <span>{t('tabs.members.table.member.label')}</span>
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger>
                        <Info className="h-3 w-3" />
                      </TooltipTrigger>
                      <TooltipContent>{t('tabs.members.table.member.tooltip')}</TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </div>
              </TableHead>
              <TableHead>{t('tabs.members.table.email.label')}</TableHead>
              <TableHead>{t('tabs.members.table.phoneNumber.label')}</TableHead>
              <TableHead>{t('tabs.members.table.cohorts.label')}</TableHead>
              <TableHead>
                <div className="flex items-center space-x-2">
                  <span>{t('tabs.members.table.expiresAt.label')}</span>
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger>
                        <Info className="h-3 w-3" />
                      </TooltipTrigger>
                      <TooltipContent>{t('tabs.members.table.expiresAt.tooltip')}</TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </div>
              </TableHead>
              <TableHead></TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {isLoading && times(3, (idx) => <SkeletonRow key={`skeleton-row-${idx}`} />)}

            {(activeMembers?.items || []).map((member) => (
              <TableRow key={member?.id}>
                <TableCell className="py-1">
                  <Checkbox
                    checked={selectedItems.some((it) => it.id === member?.id)}
                    onCheckedChange={(checked) => handleOnChange(checked as boolean, member?.id)}
                  />
                </TableCell>
                <TableCell className="py-2">
                  <div className="max-w-[150px]">
                    <Label className="text-sm font-medium truncate block">{member?.name}</Label>
                    <p className="text-xs text-muted-foreground truncate">{member?.id}</p>
                  </div>
                </TableCell>
                <TableCell className="py-2 max-w-[200px]">
                  <span className="truncate block" title={member?.email ?? '–'}>
                    {member?.email ?? '–'}
                  </span>
                </TableCell>
                <TableCell className="py-2">
                  {member?.phoneNumber ? Phone.build(member.phoneNumber).getFormattedNumber() : '–'}
                </TableCell>
                <TableCell className="py-2 max-w-[150px]">
                  <div className="truncate">{getCohortsContent.execute(member.cohortIds, cohorts?.items ?? [])}</div>
                </TableCell>
                <TableCell className="py-2">
                  {member?.isLifetime ? '∞' : member?.removalAt ? moment(member?.removalAt).format('D MMM. YYYY') : '-'}
                </TableCell>
                <TableCell className="py-2">
                  <RowMemberActions
                    type={member.type}
                    onRemoveFreeMember={() =>
                      setOpenRemoveMemberModal({
                        isOpen: true,
                        userId: member.id,
                      })
                    }
                    onChangeCohort={() => {
                      setOpenChangeMemberCohortsModal(member)
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        {canRenderEmptyState && <TableEmptyState title={t('emptyInvites')} subtitle={t('emptyInvitesSubtitle')} />}
      </div>
      <div className="flex justify-end px-2 mt-3 mb-3">
        <TablePagination
          page={page ?? 1}
          setPage={(page) => setPage(page)}
          lastPage={activeMembers?.lastPage ?? 1}
          pageSize={pageSize ?? 25}
          setPageSize={(pageSize) => setPageSize(pageSize)}
        />
      </div>

      <MembersSelectedsBarActions
        selectedItems={selectedItems}
        setOpenChangeMembersCohortsModal={setOpenChangeMembersCohortsModal}
        setSelectedItems={setSelectedItems}
      />
      <ChangeMembersCohortsModal
        isOpen={openChangeMembersCohortsModal}
        productId={productId}
        membersSelected={selectedItems}
        onClose={() => setOpenChangeMembersCohortsModal(false)}
      />
      {openChangeMemberCohortsModal && (
        <ChangeMembersCohortsModal
          isOpen={!!openChangeMemberCohortsModal}
          productId={productId}
          membersSelected={[openChangeMemberCohortsModal]}
          onClose={() => setOpenChangeMemberCohortsModal(null)}
        />
      )}
      {hasPermission(PermissionResource.PRODUCTS_MANAGE_MEMBERS) && (
        <GenericConfirmModal
          title={t('tabs.members.removeFreeMemberModal.title')}
          description={t('tabs.members.removeFreeMemberModal.description')}
          confirmText={t('tabs.members.removeFreeMemberModal.confirmText')}
          isOpen={openRemoveMemberModal?.isOpen}
          onCancel={() => {
            setOpenRemoveMemberModal({
              isOpen: false,
              userId: '',
            })
          }}
          onDelete={() => {
            mutateAsync({
              productId,
              userId: openRemoveMemberModal?.userId,
            })
          }}
          isLoading={removeFreeMemberLoading}
        />
      )}
    </div>
  )
}
