import { PartnerDtoStatusEnum } from '@hub-la/sdk-bff-product'
import { Circle } from 'lucide-react'
import { useTranslation } from 'react-i18next'

type PartnershipStatusTagProps = {
  status?: PartnerDtoStatusEnum
}

const getStyles = (color: string) => {
  return { size: 16, className: `mr-1 text-${color}-700`, fill: color, style: { fillOpacity: 0.1 } }
}

const getIcon = (status?: string) => {
  switch (status) {
    case PartnerDtoStatusEnum.INVITED:
    case PartnerDtoStatusEnum.CANCELLATION_REQUESTED:
      return <Circle {...getStyles('yellow')} />
    case PartnerDtoStatusEnum.REJECTED:
      return <Circle {...getStyles('gray')} />
    case PartnerDtoStatusEnum.ACCEPTED:
      return <Circle {...getStyles('green')} />
    case PartnerDtoStatusEnum.FINISHED:
      return <Circle {...getStyles('red')} />
    default:
      return <Circle {...getStyles('gray')} />
  }
}

export const PartnerStatusTag = ({ status }: PartnershipStatusTagProps) => {
  const { t } = useTranslation()

  return (
    <div className="flex items-center">
      {getIcon()}

      {t(`settings.myPartners.status.${status}`)}
    </div>
  )
}
