import { useContainer } from '@hub-la/fe-container'
import { Auth } from '@hub-la/fe-core-auth'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation } from '@tanstack/react-query'
import { PutCompleteRegistrationInput } from '../../domain/dtos/put-complete-registration-input'
import { QueryKey } from '../../domain/dtos/query-keys'
import { PutCompleteRegistration } from '../../usecases/put-complete-registration'

export const usePutCompleteRegistration = (params: { onSuccess?: () => void; onError?: (error: Error) => void }) => {
  const container = useContainer()

  return useMutation(
    [QueryKey.postCompleteRegistration],
    (input: PutCompleteRegistrationInput) =>
      new PutCompleteRegistration(container.get(HttpClient), container.get(Auth)).execute(input),
    {
      retry: false,
      onSuccess: () => {
        if (params?.onSuccess) {
          params?.onSuccess()
        }
      },
      onError: (error: Error) => {
        if (params?.onError) {
          params?.onError(error)
        }
      },
    },
  )
}
