import { useGetUser } from '@hub-la/fe-get-user'
import { useGetRoleplayReference, useRoleplay } from '@hub-la/fe-roleplay'
import { Users } from 'lucide-react'
import React from 'react'
import defaultImage from '../../../assets/default-avatar.jpg'
import { CoProducer } from './co-producer'

interface CoProducersListProps {
  newPartner: {
    name: string
    commission: number
  }
  partnersCommission: number
}

export const CoProducersList: React.FC<CoProducersListProps> = ({ newPartner, partnersCommission }) => {
  const { data: user } = useGetUser()
  const { isRoleplay } = useRoleplay()
  const { data: roleplayUserData } = useGetRoleplayReference({ enabled: isRoleplay })

  const currentUser = isRoleplay ? roleplayUserData : user

  const creatorCommission = 100 - newPartner.commission / 100 - partnersCommission

  return (
    <div
      className="bg-secondary p-8 rounded-2xl space-y-4 sm:space-y-0 sm:flex sm:flex-wrap sm:gap-6 sm:static fixed bottom-0 left-0 w-full sm:w-auto sm:rounded-2xl rounded-t-2xl"
      data-testid="co-producer-list-container"
    >
      <CoProducer
        commission={creatorCommission * 100}
        photo={currentUser?.picture || defaultImage}
        name={currentUser?.name || 'Minha comissão'}
      />
      <CoProducer name={newPartner.name} commission={newPartner.commission} photo={defaultImage} />
      <CoProducer commission={partnersCommission * 100} name="Outros" icon={<Users className="text-primary" />} />
    </div>
  )
}
