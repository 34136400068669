import React, { createContext, useContext, useState } from 'react'
import { OrderDir } from '../../domain/enums/order-dir'

type PropsWithChildren = {
  children?: React.ReactNode
  discussionOwnerId: string
  currentUser?: {
    name?: string
    picture?: string | null
    id?: string
  }
  type?: 'post'
  metadata?: any //@todo: change type based ou discussionType
}

export type DiscussionContextType = {
  isDiscussionOwner: boolean
  isOwnerOfComment: (commentOwnerId: string) => boolean
  orderDir: OrderDir
  setOrderDir: (direction: OrderDir) => void
} & PropsWithChildren

const DiscussionContext = createContext<DiscussionContextType>({} as DiscussionContextType)

export const useDiscussion = () => {
  return useContext(DiscussionContext)
}

/**@todo: change provider name (managment? manager?) */
export const DiscussionProvider = ({ type, metadata, children, discussionOwnerId, currentUser }: PropsWithChildren) => {
  const [orderDir, setOrderDir] = useState<OrderDir>(OrderDir.DESC)

  const isDiscussionOwner = discussionOwnerId === currentUser?.id

  const isOwnerOfComment = (commentOwnerId: string) => {
    return commentOwnerId === currentUser?.id
  }

  return (
    <DiscussionContext.Provider
      value={{
        type,
        metadata,
        discussionOwnerId,
        currentUser,
        isDiscussionOwner,
        isOwnerOfComment,
        orderDir,
        setOrderDir,
      }}
    >
      {children}
    </DiscussionContext.Provider>
  )
}
