import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { QueryKey } from '@hub-la/fe-post'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { MovePostSectionRequest } from '../../domain/dtos'
import { MovePostSection } from '../../usecases/move-post-section'

export const useMovePostSection = () => {
  const container = useContainer()
  const queryClient = useQueryClient()

  return useMutation(
    [QueryKey.movePost],
    async (payload: MovePostSectionRequest) => await new MovePostSection(container.get(HttpClient)).execute(payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [QueryKey.listSections] })
      },
    },
  )
}
