import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { GetInvoicesInput } from '../domain/dtos/get-invoices-input'
import { GetInvoicesOutput } from '../domain/dtos/get-invoices-output'
import { Envs } from '../envs'

export class GetInvoices {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(input: GetInvoicesInput, signal?: AbortSignal): Promise<GetInvoicesOutput> {
    const response = await this.httpClient.request({
      method: HttpMethod.POST,
      url: `${Envs.BFF_WEB_URL}/payer/invoices`,
      body: this.makeBody(input),
    })

    if (response.statusCode === HttpStatusCode.OK || response.statusCode === HttpStatusCode.CREATED) {
      return response.data
    }

    throw new Error('Não foi possível obter as faturas da assinatura')
  }

  private makeBody(input: GetInvoicesInput) {
    const body = {
      offerIds: [input.offerId],
      page: input.page,
      pageSize: input.pageSize,
    }

    return body
  }
}
