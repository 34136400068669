import moment from 'moment'
import 'moment/locale/pt-br'

export class FormatDate {
  private value: string | Date = ''

  public constructor(value: string | Date) {
    this.value = value
  }

  public getValue() {
    moment.locale('pt-br')
    const date = moment(this.value)

    return date.format('DD MMM YYYY')
  }

  public getHours() {
    moment.locale('pt-br')
    const date = moment(this.value)

    return date.format('HH:mm')
  }
}
