import { useAuth } from '@hub-la/fe-auth'
import { useContainer } from '@hub-la/fe-container'
import { Analytics, AnalyticsEvent } from '@hub-la/fe-core-analytics'
import { Card, CardContent, CardHeader, CardTitle, Label, RadioGroup, RadioGroupItem } from '@hub-la/shadcn'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { UserType } from '../../../domain/dtos/user-login-preferences-input'
import { usePutUserLoginPreferences } from '../../hooks/use-put-user-login-preferences'

export const NavigationTarget = () => {
  const { t } = useTranslation()
  const { mutateAsync } = usePutUserLoginPreferences()
  const { currentUser } = useAuth()

  const [selectedType, setSelectedType] = useState<UserType>()

  const container = useContainer()
  const analytics = container.get(Analytics)

  useEffect(() => {
    if (currentUser?.loginPreferences?.navigationTarget) {
      setSelectedType(currentUser.loginPreferences.navigationTarget)
    }
  }, [currentUser])

  const handleRadioChange = (type: UserType) => {
    const eventMap = {
      [UserType.CREATOR]: AnalyticsEvent.LOGIN_PREFERENCES.NAVIGATION_TARGET.CHANGE_TO_CREATOR_CLICKED,
      [UserType.BUYER]: AnalyticsEvent.LOGIN_PREFERENCES.NAVIGATION_TARGET.CHANGE_TO_BUYER_CLICKED,
    }

    analytics.track(eventMap[type])
    mutateAsync({ navigationTarget: type })
    setSelectedType(type)
  }

  return (
    <Card className="w-full max-w-2xl">
      <CardHeader>
        <CardTitle>{t('loginPreferences.title')}</CardTitle>
      </CardHeader>

      <CardContent className="flex flex-col gap-4">
        <RadioGroup
          value={selectedType}
          onValueChange={handleRadioChange}
          className="flex flex-col space-y-1 sm:flex-row sm:space-x-4 sm:space-y-0"
        >
          <div className="flex items-center space-x-2">
            <RadioGroupItem value={UserType.CREATOR} id="creator" />
            <Label htmlFor="creator">{t('loginPreferences.userType.creator')}</Label>
          </div>
          <div className="flex items-center space-x-2">
            <RadioGroupItem value={UserType.BUYER} id="buyer" />
            <Label htmlFor="buyer">{t('loginPreferences.userType.buyer')}</Label>
          </div>
        </RadioGroup>
      </CardContent>
    </Card>
  )
}
