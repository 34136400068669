import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { ErrorCode } from '../../domain/dtos/error-code'
import { GetAcceptInviteInput } from '../../domain/dtos/get-accept-invite-input'
import { GetAcceptInviteOutput } from '../../domain/dtos/get-accept-invite-output'
import { GeneralError } from '../../domain/errors/general'
import { InviteNotFound } from '../../domain/errors/invite-not-found'
import { Envs } from '../../envs'

export class GetAcceptInvite {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(input: GetAcceptInviteInput, signal?: AbortSignal): Promise<GetAcceptInviteOutput> {
    const response = await this.httpClient.request({
      method: HttpMethod.GET,
      url: `${Envs.BFF_MEMBERS_AREA_URL}/invites/${input.inviteId}/accept`,
      signal,
    })

    if (response.statusCode === HttpStatusCode.OK || response.statusCode === HttpStatusCode.CREATED) {
      return response.data
    }

    switch (response.data.errorCode) {
      case ErrorCode.INVITE_UNSUPPORTED_STATUS:
        return {
          errorCode: ErrorCode.INVITE_UNSUPPORTED_STATUS,
          ...response.data?.errorMetadata,
        }
      case ErrorCode.INVITE_NOT_FOUND:
        throw new InviteNotFound()
      default:
        throw new GeneralError()
    }
  }
}
