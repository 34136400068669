import { Button } from '@hub-la/shadcn'
import { ArrowLeft, ChevronLeft, X } from 'lucide-react'

interface IProps {
  onClick: () => void
  isClose?: boolean
}

export const NavigationButton = ({ onClick, isClose = false }: IProps): JSX.Element => {
  return (
    <Button data-testid="nav-button" onClick={onClick} variant="ghost" size="icon" className="p-0 text-[#314000]">
      {isClose ? (
        <X data-testid="btn-icon-close" className="h-6 w-6 md:h-8 md:w-8" />
      ) : (
        <>
          <ChevronLeft data-testid="btn-icon-mobile" className="h-6 w-6 md:hidden" />
          <ArrowLeft data-testid="btn-icon-desktop" className="hidden md:block h-8 w-8" />
        </>
      )}
    </Button>
  )
}
