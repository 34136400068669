import { container } from '@hub-la/fe-container'
import { MembersAreaPage, MembersAreaProvider } from '@hub-la/fe-members-area'

const MembersAreaInit = () => {
  return (
    <MembersAreaProvider container={container}>
      <MembersAreaPage />
    </MembersAreaProvider>
  )
}

export default MembersAreaInit
