import { cn } from '@hub-la/shadcn'
import { ChevronDown } from 'lucide-react'
import { Country } from 'react-phone-number-input'
import PhoneNumber from 'react-phone-number-input/mobile'
import { PhoneField } from './phone-field'

interface Props {
  value: string
  disabled: boolean
  label?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (value?: any | undefined) => void

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onBlur?: (e: React.FocusEvent<any, Element>) => void

  error?: boolean | undefined

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  helperText?: any

  // Default values are fine.
  defaultCountry?: Country
  placeholder?: string

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inputComponent?: any
  countrySelectProps?: object | undefined
  id?: string
}

/** Provides a component that shows the country flag inside the input field. */
export function PhoneNumberStyled(props: Props) {
  const defaultProps = {
    defaultCountry: 'BR' as Country,
    placeholder: '( )',
    inputComponent: PhoneField,
    countrySelectProps: {
      arrowComponent: ChevronDown,
    },
  }

  return (
    <div className="relative">
      <PhoneNumber
        {...defaultProps}
        {...props}
        className={cn('relative', 'PhoneInput', props.error && 'PhoneInput--error')}
      />

      {props.helperText && (
        <p className={cn('text-sm mt-2', props.error ? 'text-destructive' : 'text-muted-foreground')}>
          {props.helperText}
        </p>
      )}
    </div>
  )
}
