import { Skeleton } from '@hub-la/shadcn'
import { Search } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { useGetOverdueSmartInstallments } from '../../../hooks/use-get-overdue-smart-installments'
import { useHistory } from 'react-router-dom'
import { SmartInstallmentCard } from '../../../components/smart-installment-card'

export const OverdueSmartInstallmentsList = () => {
  const { data, isLoading } = useGetOverdueSmartInstallments()

  const { t } = useTranslation()

  const history = useHistory()

  if (isLoading) {
    return <OverdueSmartInstallmentsListLoading />
  }

  if (!data?.items?.length) {
    return <OverdueSmartInstallmentsEmpty />
  }

  return (
    <div className="flex flex-col gap-3">
      <h2 className="text-lg max-w-max">{t('pending')}</h2>
      {data?.items.map((smartInstallment) => (
        <SmartInstallmentCard
          onClick={() => history.push(`/user_invoices/${smartInstallment.id}`)}
          key={smartInstallment.id}
          smartInstallment={smartInstallment}
        />
      ))}
    </div>
  )
}

const OverdueSmartInstallmentsListLoading = () => {
  return (
    <div className="flex flex-col gap-3">
      <Skeleton className="w-[250px] h-[23px]" />
      {Array(3)
        .fill(0)
        .map((_, index) => (
          <Skeleton className="h-[94px]" key={index} />
        ))}
    </div>
  )
}

const OverdueSmartInstallmentsEmpty = () => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col items-center justify-center w-full h-full py-30">
      <div className="p-2 rounded-md border border-secondary bg-secondary/10">
        <Search />
      </div>
      <h2 className="mt-6 text-2xl font-semibold">{t('smartinstallmentcyclesempty')}</h2>
    </div>
  )
}
