export const pt = {
  postDetail: {
    errorComponent: {
      back: 'Voltar',
      myPurchases: 'Acessar minhas compras',
    },
    errors: {
      getPost: {
        'mod-product::offer-not-found':
          'A oferta ou desse conteúdo não existe, talvez você precise de uma máquina do tempo para acessá-lo.',
        'no-result':
          'Esse conteúdo não existe ou não está mais disponível, talvez você precise de uma máquina do tempo para acessá-lo.',
        forbidden:
          'Este conteúdo não está disponível para membros no momento, peça ao criador para publicar o conteúdo.',
        notFound:
          'Esse conteúdo não existe ou não está mais disponível, talvez você precise de uma máquina do tempo para acessá-lo.',
        general: 'Erro inesperado ao carregar o conteúdo, entre em contato com o suporte.',
      },
    },
    sidebar: {
      current: 'Você está em',
      header: {
        title: 'Conteúdos',
        previous: 'Anterior',
        next: 'Próximo',
      },
      sectinProgress: {
        counter: 'conteúdo(s)',
      },
      contentQuantity: {
        one: 'conteúdo',
        many: 'conteúdos',
      },
      durationSubtitle: {
        article: 'Publicação',
        video: '',
        image: 'Imagem',
        pdf: 'páginas',
      },
      toggle: {
        show: 'Ver seções',
        hide: 'Esconder seções',
      },
    },
    paywall: {
      title: `Este conteúdo está disponível apenas para membros da comunidade "<0>{{name}}</0>", seja um membro e tenha acesso a todos os conteúdos desta comunidade... <1>Saiba mais</1>`,
      alreadyMember: 'Já sou membro',
      purchase: 'Comprar agora',
      onlyMembers: 'Só para membros',
    },
    releaseAt: 'Disponível em',
    meeting: {
      type: {
        discord: 'Discord',
        meets: 'Google Meets',
        youtube: 'Youtube',
        zoom: 'Zoom Meeting',
        other: 'Live Meeting',
      },
      join: 'Participar',
      liveNow: 'AO VIVO AGORA',
    },
    assets: {
      title: 'Anexos',
      download: 'Download',
    },
  },
  pdf: {
    pagination: 'Página {{pageNumber}} de {{numPages}}',
  },
  navBar: {
    profile: 'Ver perfil',
    products: 'Meus Produtos',
    editProfile: 'Editar perfil',
    accountVerified: 'Conta verificada',
    verifyAccount: 'Verificar conta',
    bankAccount: 'Conta bancária',
    affiliates: 'Receitas de afiliados',
    tipsAndResources: 'Dicas e recursos',
    faq: 'Ajuda',
    ourBlog: 'Nosso blog',
    logout: 'Sair',
    changeMode: {
      creator: 'Usar Modo Criador',
      member: 'Usar Modo Membro',
    },
  },
}
