export const Envs = {
  ENV: process.env['ENVIRONMENT'] ?? process.env['REACT_APP_CUSTOM_NODE_ENV'],
  BFF_WEB_URL: process.env['NX_BFF_WEB_URL'] ?? process.env['REACT_APP_HUB_API'],
  BFF_PRODUCT_URL: process.env['NX_BFF_PRODUCT_URL'] ?? process.env['REACT_APP_BFF_PRODUCT_URL'],
  RDSTATION_CLIENT_ID: process.env['NX_RDSTATION_CLIENT_ID'] ?? process.env['REACT_APP_RDSTATION_CLIENT_ID'],
  RDSTATION_REDIRECT_URI: process.env['NX_RDSTATION_REDIRECT_URI'] ?? process.env['REACT_APP_RDSTATION_REDIRECT_URI'],
  CONTA_AZUL_CLIENT_ID: process.env['NX_CONTA_AZUL_CLIENT_ID'] ?? process.env['REACT_APP_CONTA_AZUL_CLIENT_ID'],
  CONTA_AZUL_REDIRECT_URI:
    process.env['NX_CONTA_AZUL_REDIRECT_URI'] ?? process.env['REACT_APP_CONTA_AZUL_REDIRECT_URI'],
}
