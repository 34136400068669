import { Analytics, AnalyticsEvent } from '@hub-la/fe-core-analytics'
import { Auth } from '@hub-la/fe-core-auth'
import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { SignUpByEmailInput } from '../domain/dtos/sign-up-by-email-input'
import { AnalyticsAuthMethod } from '../domain/enums/analytics-auth-method'
import { ErrorCode } from '../domain/enums/error-code'
import { EmailAlreadyExistsError } from '../domain/errors/email-already-exists-error'
import { EmailAndPhoneAlreadyInUseError } from '../domain/errors/email-and-phone-already-in-use-error'
import { FirebaseAuthError } from '../domain/errors/firebase-auth-error'
import { GeneralError } from '../domain/errors/general'
import { InvalidCaptchaError } from '../domain/errors/invalid-captcha-error'
import { PhoneNumberAlreadyExistsError } from '../domain/errors/phone-number-exists-error'
import { SignInNotfoundError } from '../domain/errors/signin-not-found-error'
import { Envs } from '../envs'

export class PostSignUpByEmail {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
    @inject(Auth) private readonly auth: Auth,
    @inject(Analytics) private readonly analytics: Analytics,
  ) {}

  public async execute(input: SignUpByEmailInput): Promise<null> {
    if (!input?.captcha) {
      throw new InvalidCaptchaError()
    }

    const response = await this.httpClient.request({
      method: HttpMethod.POST,
      url: `${Envs.BFF_WEB_URL}/auth/signup`,
      body: input,
    })

    if (response.statusCode === HttpStatusCode.CREATED) {
      try {
        const auth = await this.auth.signInWithToken(response.data)
        this.analytics.track(AnalyticsEvent.AUTHENTICATION.SUCCESSFUL, {
          user: auth?.user?.uid,
          type: AnalyticsAuthMethod.EMAIL,
          isNewUser: true,
        })

        return null
      } catch (e: any) {
        if (!e?.code) {
          throw new GeneralError('signUp')
        }

        switch (e?.code) {
          case 'auth/user-not-found':
            throw new SignInNotfoundError()
          default:
            throw new FirebaseAuthError(e.code)
        }
      }
    }

    switch (response.data.code) {
      case ErrorCode.EMAIL_ALREADY_IN_USE:
        throw new EmailAlreadyExistsError()
      case ErrorCode.PHONE_NUMBER_ALREADY_IN_USE:
        throw new PhoneNumberAlreadyExistsError()
      case ErrorCode.EMAIL_AND_PHONE_ALREADY_IN_USE:
        throw new EmailAndPhoneAlreadyInUseError()
      case ErrorCode.INVALID_CAPTCHA:
        throw new InvalidCaptchaError()
      default:
        throw new GeneralError('signUp')
    }
  }
}
