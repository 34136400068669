import { container } from '@hub-la/fe-container'
import { UserOverdueSmartInstallmentsPage, UserSmartInstallmentsProvider } from '@hub-la/fe-user-smart-installments'

export const UserOverdueSmartInstallmentsInit = () => {
  return (
    <UserSmartInstallmentsProvider container={container}>
      <UserOverdueSmartInstallmentsPage />
    </UserSmartInstallmentsProvider>
  )
}
