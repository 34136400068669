import { useContainer } from '@hub-la/fe-container'
import { Analytics } from '@hub-la/fe-core-analytics'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useQuery } from '@tanstack/react-query'
import { PostResponse, PostStatus } from '../../domain/dtos'
import { Events } from '../../domain/dtos/events'
import { GetPostInput } from '../../domain/dtos/get-post-input'
import { GetPostUseCase } from '../../usecases/get-post'
import { useGetSectionProgress } from './use-get-section-progress'
import { useMarkPostViewed } from './use-mark-post-viewed'
import { useRealtimeSections } from './use-realtime-sections'

export const useGetPost = (input: GetPostInput) => {
  const container = useContainer()
  const markPostViewed = useMarkPostViewed()
  const { mutateAsync: getSectionProgress } = useGetSectionProgress()
  const { dispatch } = useRealtimeSections()
  //const { data: currentUser } = useCurrentUser()
  //TOOD: Get the current user from the context
  const currentUser = { id: '1' }
  const currentUserId = currentUser?.id
  const analytics = container.get(Analytics)

  return useQuery<PostResponse, Error>(
    ['get-post', input.postId],
    () => new GetPostUseCase(container.get(HttpClient)).fetch(input),
    {
      retry: 3,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      onSuccess: async (data) => {
        if (!data.viewed && data.status === PostStatus.PUBLISHED) {
          await markPostViewed.mutateAsync(data.id)
          const sectionProgress = await getSectionProgress(data.sectionId)
          dispatch({ type: 'update', postId: data?.id, sectionId: data?.sectionId, viewed: true, sectionProgress })
        }
        analytics.track(Events.HUB.POST_VIEWED, {
          userId: currentUserId,
          productId: data?.product?.id,
          postId: input?.postId,
          name: data?.title,
        })
      },
    },
  )
}
