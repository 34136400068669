import { InfiniteData } from '@tanstack/react-query'
import { GetMovementsOutput } from '../../domain/dtos/get-movements-output'

export const useCalculateBalance = (data?: InfiniteData<GetMovementsOutput>, startAmountInCents?: number) => {
  if (!data) return

  let lastBalance = startAmountInCents ?? 0
  let lastMovementAmount = 0

  // Deep copy used to avoid object mutation, which can result in hard to debug scenarios
  const clone = JSON.parse(JSON.stringify(data)) as InfiniteData<GetMovementsOutput>

  clone.pages.forEach((page) => {
    page.movements.forEach((groupedMovement) => {
      groupedMovement.movements.forEach((movement) => {
        movement.balance = lastBalance - lastMovementAmount
        lastMovementAmount = movement.amountInCents
        lastBalance = movement.balance
      })
    })
  })

  return clone
}
