import { Button } from '@hub-la/shadcn'
import { FormikProvider, useFormik } from 'formik'
import { useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { Trans, useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'
import { SignInPhoneNotfoundError } from '../../domain/errors/sign-in-phone-not-found-error'
import { usePostSignInByPhoneNumber } from '../hooks/use-post-sign-in-by-phone-number'
import { signInPhoneSchema } from '../validations/signin-phone'
import { AuthCard } from './auth-card/auth-card'
import { PhoneNumberStyled } from './phone-number-field/phone-number-styled'
import { PhoneVerifyCodeCard } from './phone-verify-code-card'

type PhoneSignInCardProps = {
  isWhatsapp?: boolean
}

const PhoneSignInCard = ({ isWhatsapp = false }: PhoneSignInCardProps) => {
  const [verifyCode, setVerifyCode] = useState(false)

  const { executeRecaptcha } = useGoogleReCaptcha()
  const { mutate, isLoading, isError, error, data, reset } = usePostSignInByPhoneNumber({
    onSuccess: () => {
      setVerifyCode(true)
    },
  })
  const { t } = useTranslation()

  const form = useFormik({
    initialValues: {
      phoneNumber: '',
    },
    onSubmit: async (values) => {
      const captcha = await executeRecaptcha?.('phone_signin')

      mutate({ phoneNumber: values.phoneNumber, isWhatsapp, captcha })
    },
    validationSchema: signInPhoneSchema,
    validateOnChange: true,
  })

  if (verifyCode) {
    return (
      <PhoneVerifyCodeCard
        phoneNumber={form.values.phoneNumber}
        authId={data?.authId}
        nextRetryDate={data?.nextRetryDate}
        retryCode={async () => {
          const captcha = await executeRecaptcha?.('phone_signin')
          mutate({ phoneNumber: form.values.phoneNumber, isWhatsapp, captcha })
        }}
        loadingRetry={isLoading}
        isWhatsapp={isWhatsapp}
      />
    )
  }

  const isPhoneNotfound = Boolean(error) && error instanceof SignInPhoneNotfoundError
  const phoneErrorMessage = isPhoneNotfound
    ? t(error?.message)
    : form.touched.phoneNumber && !!form.errors.phoneNumber
    ? t(form.errors.phoneNumber)
    : null

  return (
    <AuthCard title="Faça seu login" description="Informe o número do seu telefone para continuar">
      <FormikProvider value={form}>
        <form onSubmit={form.handleSubmit}>
          <div className="flex flex-col gap-3 w-full">
            <PhoneNumberStyled
              label="Telefone com DDD"
              onChange={(value) => form.setFieldValue('phoneNumber', (value ?? '').toString())}
              value={form.values.phoneNumber}
              error={isPhoneNotfound || (form.touched.phoneNumber && !!form.errors.phoneNumber)}
              helperText={
                phoneErrorMessage && <Trans i18nKey={phoneErrorMessage} components={[<a href="/signup" />]} />
              }
            />
            <Button type="submit" disabled={!form.isValid || isLoading}>
              {isLoading ? 'Carregando...' : `Receber código por ${isWhatsapp ? 'WhatsApp' : 'SMS'}`}
            </Button>
          </div>
        </form>
      </FormikProvider>
      <div className="relative my-4">
        <hr />
        <span className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-background px-2 text-xs text-muted-foreground">
          {t('Ou')}
        </span>
      </div>
      <p className="text-center text-sm text-muted-foreground">
        {t('Não possui uma conta na Hubla?')}{' '}
        <RouterLink className="text-lime-600" to="/signup">
          Crie sua conta
        </RouterLink>
      </p>

      {/* <Snackbar open={isError && !isPhoneNotfound} onClose={() => reset()} variant="negative">
        {t((error as Error)?.message)}
      </Snackbar> */}
    </AuthCard>
  )
}

export { PhoneSignInCard }
