import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue, Skeleton } from '@hub-la/shadcn'
import { SortAsc, SortDesc } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { OrderDir } from '../../../domain/enums/order-dir'
import { useDiscussion } from '../../providers/discussion'

type CountProps = {
  total: number
  isLoading: boolean
}

const Count = ({ total, isLoading }: CountProps) => {
  const { setOrderDir, orderDir } = useDiscussion()
  const { t } = useTranslation()

  if (isLoading) return <Skeleton className="w-32 h-6 mb-4" />

  return (
    <div className="flex items-center justify-between mb-4">
      <p className="text-base font-bold">{total} comentários</p>
      <Select onValueChange={(value) => setOrderDir(value as OrderDir)} defaultValue={orderDir}>
        <SelectTrigger className="w-[180px]">
          <SelectValue placeholder="Ordenar por" />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value={OrderDir.DESC}>
            <div className="flex items-center">
              <SortDesc className="h-4 w-4 mr-2" />
              {t('orderComments.newest')}
            </div>
          </SelectItem>
          <SelectItem value={OrderDir.ASC}>
            <div className="flex items-center">
              <SortAsc className="h-4 w-4 mr-2" />
              {t('orderComments.oldest')}
            </div>
          </SelectItem>
        </SelectContent>
      </Select>
    </div>
  )
}

export { Count }
