import { memo } from 'react'
import { RefundsTable } from './refunds-table'

export const DashboardRefunds = memo(() => {
  return (
    <div>
      <h1 className="text-3xl font-semibold leading-tight mb-4">Pedidos de reembolso</h1>
      <RefundsTable />
    </div>
  )
})
