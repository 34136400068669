import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { GeneralError } from '../domain/errors/general'
import { Envs } from '../envs'

export class DeactivateSubscription {
  public constructor(@inject(HttpClient) private readonly httpClient: HttpClient) {}

  public async execute(id: string): Promise<void> {
    const response = await this.httpClient.request({
      method: HttpMethod.PUT,
      url: `${Envs.BFF_WEB_URL}/subscriptions/${id}/deactivate`,
    })

    if ([HttpStatusCode.OK, HttpStatusCode.CREATED].includes(response.statusCode)) {
      return response.data
    } else {
      /** @todo: Handle backend errors */
      throw new GeneralError()
    }
  }
}
