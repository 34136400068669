import isEmpty from 'lodash/isEmpty'
import times from 'lodash/times'
import moment from 'moment'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Currency } from '../../../domain/vos/currency'
import { RefundCurrentStatus } from '../../components/refund-current-status'
import { useGetRefunds } from '../../hooks/use-get-refunds'
import { TableLoading } from './table-loading'

import {
  cn,
  Sheet,
  SheetContent,
  Table,
  TableBody,
  TableCell,
  TableEmptyState,
  TableHead,
  TableHeader,
  TablePagination,
  TableRow,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@hub-la/shadcn'
import { RefundStatus } from '../../../domain/enums/refund-status'
import { ExportModal } from '../../components/modals/export-modal'
import { RefundDetail } from '../../components/refund-detail/refund-detail'
import { StatusFilter } from '../../components/status-filter'

export const RefundsTable = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(25)
  const [openRefundDetail, setOpenRefundDetail] = useState<string>()
  const [status, setStatus] = useState<RefundStatus>(RefundStatus.CREATED)
  const { data, isFetching } = useGetRefunds({ page, pageSize, status })

  const isMobile = window.innerWidth < 768
  const drawerPosition = isMobile ? 'bottom' : 'right'

  const format = (date?: string) => moment(date).format('D MMM. YYYY HH:mm')

  const headCells = [
    { key: 'total', label: 'Valor', sortable: false },
    { key: 'email', label: 'Cliente', sortable: false },
    { key: 'item', label: 'Produto', sortable: false },
    { key: 'status', label: 'Status', sortable: false },
    { key: 'paidAt', label: 'Pago em', sortable: false },
    { key: 'createdAt', label: 'Pedido em', sortable: false },
  ]
  const isRowsEmpty = isEmpty(data?.items)
  const canRenderEmptyState = !isFetching && isRowsEmpty
  const currency = new Currency()

  return (
    <div className="flex flex-col gap-4">
      <div className="lg:flex space-y-2 items-center lg:justify-between gap-4">
        <StatusFilter currentStatus={status} setCurrentStatus={setStatus} />
        <ExportModal />
      </div>
      <p className="text-sm font-medium whitespace-nowrap">{data.total} reembolsos</p>

      <div className="flex flex-col gap-4">
        <div className="rounded-lg border bg-background overflow-hidden">
          <Table>
            <TableHeader>
              <TableRow className="hover:bg-inherit">
                {headCells.map((cell) => (
                  <TableHead key={cell.key}>{cell.label}</TableHead>
                ))}
              </TableRow>
            </TableHeader>

            <TableBody>
              {isFetching && data.items.length === 0
                ? times(pageSize, (idx) => <TableLoading key={`skeleton-row-${idx}`} />)
                : (data?.items ?? []).map((refund) => (
                    <TableRow
                      key={refund.id}
                      onClick={() => history.push(`/dashboard/refunds/${refund.id}`)}
                      className="h-12 cursor-pointer"
                    >
                      <TableCell className="text-base font-medium py-2">{currency.format(refund.amount)}</TableCell>
                      <TableCell className="py-2 max-w-52">
                        <TooltipProvider>
                          <Tooltip>
                            <TooltipTrigger className="max-w-full truncate">{refund.to.email}</TooltipTrigger>
                            <TooltipContent className="max-w-full"> {refund.to.email}</TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                      </TableCell>
                      <TableCell className="py-2 max-w-16">
                        <TooltipProvider>
                          <Tooltip>
                            <TooltipTrigger className="max-w-full truncate">
                              {refund.transaction.item?.name}
                            </TooltipTrigger>
                            <TooltipContent className="max-w-full">{refund.transaction.item?.name}</TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                      </TableCell>
                      <TableCell className="py-2 text-nowrap">
                        <RefundCurrentStatus
                          status={refund.status}
                          paidAt={refund.transaction.paidAt}
                          createdAt={refund.createdAt}
                        />
                      </TableCell>
                      <TableCell className="py-2 text-nowrap">{format(refund.transaction.paidAt)}</TableCell>
                      <TableCell className="py-2 text-nowrap">{format(refund.createdAt)}</TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>

          {canRenderEmptyState && <TableEmptyState title={t('empty')} />}
        </div>

        {!isRowsEmpty && (
          <div className="flex items-center">
            <TablePagination
              page={page}
              setPage={setPage}
              lastPage={data.lastPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
            />
          </div>
        )}
      </div>

      <Sheet open={!!openRefundDetail} onOpenChange={() => setOpenRefundDetail(undefined)}>
        <SheetContent
          side={drawerPosition}
          className={cn('overflow-y-scroll  background', drawerPosition === 'bottom' ? 'h-[calc(100%-4rem)]' : '')}
        >
          <div className="p-4">
            {openRefundDetail && <RefundDetail id={openRefundDetail} onClose={() => setOpenRefundDetail(undefined)} />}
          </div>
        </SheetContent>
      </Sheet>
    </div>
  )
}
