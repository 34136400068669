import { Offer } from '../../../../domain/dtos/offer'
import { SelectOffersFilter } from './select-offers-filter'

type Props = {
  setFilters: (filters: { offerIds: string[] }) => void
  filters: { offerIds: string[] }
  offers: Offer[]
}

export const InvoicesTableFilters = ({ setFilters, filters, offers }: Props) => {
  return (
    <div className="flex">
      <SelectOffersFilter offers={offers} filters={filters} setFilters={setFilters} />
    </div>
  )
}
