import { Badge } from '@hub-la/shadcn'
import { useTranslation } from 'react-i18next'
import { formatCurrency } from '../../../../domain/vos/format-currency'

interface AmountByStatusProps {
  title: string
  installments: number
  amount: number
}

export const AmountByStatus = (props: AmountByStatusProps) => {
  const { t } = useTranslation()

  return (
    <div>
      <div className="text-sm flex items-center gap-2">
        <h3 className="text-muted-foreground">{props.title}</h3>
        {props.installments > 0 && (
          <Badge variant="secondary">{t('installmentsNumber', { installments: props.installments })}</Badge>
        )}
      </div>
      <p className="text-2xl font-medium">{formatCurrency(props.amount / 100)}</p>
    </div>
  )
}
