/* tslint:disable */
/* eslint-disable */
/**
 * BFF Web Product
 * API reference used to call the backend from the web frontend
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface AutomationSettingsPlanDto
 */
export interface AutomationSettingsPlanDto {
    /**
     * 
     * @type {string}
     * @memberof AutomationSettingsPlanDto
     */
    'interval': AutomationSettingsPlanDtoIntervalEnum;
    /**
     * 
     * @type {number}
     * @memberof AutomationSettingsPlanDto
     */
    'disableAutoRenewalAfterCycles': number;
    /**
     * 
     * @type {number}
     * @memberof AutomationSettingsPlanDto
     */
    'creditsToAddOnFinalCycle': number;
}

/**
    * @export
    * @enum {string}
    */
export enum AutomationSettingsPlanDtoIntervalEnum {
    MONTHLY = 'monthly',
    QUARTERLY = 'quarterly',
    SEMIANNUALLY = 'semiannually',
    ANNUALLY = 'annually'
}


