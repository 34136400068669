import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { MyCollaboratorsOutput } from '../domain/dtos/my-collaborators-output'
import { GeneralError } from '../domain/errors/general'
import { Envs } from '../envs'

export class GetMyCollaborators {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(): Promise<MyCollaboratorsOutput> {
    const response = await this.httpClient.request({
      method: HttpMethod.GET,
      url: `${Envs.BFF_WEB_URL}/user/roleplay/collaborators`,
    })

    /** @todo: my-access mocked response */
    if (response.statusCode === HttpStatusCode.OK) {
      return response.data
    }

    throw new GeneralError()
  }
}
