import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { GetPendingInvitesInput } from '../../domain/dtos/get-pending-invites-input'
import { GetPendingInvitesOutput } from '../../domain/dtos/get-pending-invites-output'
import { GeneralError } from '../../domain/errors/general'
import { Envs } from '../../envs'

export class GetPendingInvites {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(input: GetPendingInvitesInput, signal?: AbortSignal): Promise<GetPendingInvitesOutput> {
    const response = await this.httpClient.request({
      method: HttpMethod.GET,
      url: `${Envs.BFF_MEMBERS_AREA_URL}/invites/list/pending`,
      params: this.makeParams(input),
      signal,
    })

    if (response.statusCode === HttpStatusCode.OK || response.statusCode === HttpStatusCode.CREATED) {
      return response.data
    }

    throw new GeneralError()
  }

  private makeParams(input: GetPendingInvitesInput) {
    const body = {
      page: input.page,
      pageSize: input.pageSize,
      productId: input.productId,
      search: input.search,
      orderBy: 'createdAt',
      orderDir: 'DESC',
    }

    return body
  }
}
