export type IconTicketProps = {
  name: string
  width?: number
  height?: number
  bgColor?: string
  secondaryColor?: string
  textColor?: string
}

const TruncatedText = ({ name, textColor }) => {
  const maxLength = 14

  return (
    <text x="3" y="21.5" fill={textColor} fontSize="4px" fontWeight="bold">
      {name.length > maxLength ? name.substring(0, maxLength) + '...' : name}
    </text>
  )
}

const TicketIcon = ({
  name,
  width = 37,
  height = 25,
  bgColor = '#939393',
  secondaryColor = '#0000001A',
  textColor = '#fff',
}: IconTicketProps) => {
  return (
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37 25" fill="none">
      <g filter="url(#filter0_d_1100_68641)">
        <path
          d="M1 2.97183C1 1.88282 1.88282 1 2.97183 1H34.0282C35.1172 1 36 1.88282 36 2.97183V7.45775C36 7.45775 34.0282 7.9507 34.0282 10.9577C34.0282 13.9648 36 14.4577 36 14.4577V21.9014C36 22.9904 35.1172 23.8732 34.0282 23.8732H2.97183C1.88282 23.8732 1 22.9904 1 21.9014V14.4577C1 14.4577 2.97183 13.7676 2.97183 10.9577C2.97183 8.14789 1 7.45775 1 7.45775V2.97183Z"
          fill="white"
        ></path>
        <path
          d="M1 2.97183C1 1.88282 1.88282 1 2.97183 1H34.0282C35.1172 1 36 1.88282 36 2.97183V7.45775C36 7.45775 34.0282 7.9507 34.0282 10.9577C34.0282 13.9648 36 14.4577 36 14.4577V21.9014C36 22.9904 35.1172 23.8732 34.0282 23.8732H2.97183C1.88282 23.8732 1 22.9904 1 21.9014V14.4577C1 14.4577 2.97183 13.7676 2.97183 10.9577C2.97183 8.14789 1 7.45775 1 7.45775V2.97183Z"
          fill={bgColor}
        ></path>
      </g>
      <path
        d="M1 16.1836H36V21.9019C36 22.9909 35.1172 23.8737 34.0282 23.8737H2.97183C1.88282 23.8737 1 22.9909 1 21.9019V16.1836Z"
        fill={secondaryColor}
      ></path>
      {TruncatedText({ name, textColor })}
      <defs>
        <filter
          id="filter0_d_1100_68641"
          x="0.605634"
          y="0.802817"
          width="35.7887"
          height="23.662"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          ></feColorMatrix>
          <feOffset dy="0.197183"></feOffset>
          <feGaussianBlur stdDeviation="0.197183"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.101961 0 0 0 0 0.109804 0 0 0 0 0.0941176 0 0 0 0.16 0"
          ></feColorMatrix>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1100_68641"></feBlend>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1100_68641" result="shape"></feBlend>
        </filter>
      </defs>
    </svg>
  )
}

export { TicketIcon }
