import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { CompleteRegistrationInput } from '../domain/dtos/complete-registration-input'
import { CompleteRegistrationOutput } from '../domain/dtos/complete-registration-output'
import { ErrorCode } from '../domain/enums/error-code'
import { EmailAlreadyExistsError } from '../domain/errors/email-already-exists-error'
import { GeneralError } from '../domain/errors/general'
import { InvalidCaptchaError } from '../domain/errors/invalid-captcha-error'
import { UserDisabledError } from '../domain/errors/user-disabled-error'
import { Envs } from '../envs'

export class PostCompleteRegistration {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(input: CompleteRegistrationInput): Promise<CompleteRegistrationOutput> {
    if (!input?.captcha) {
      throw new InvalidCaptchaError()
    }

    const response = await this.httpClient.request({
      method: HttpMethod.POST,
      url: `${Envs.BFF_WEB_URL}/auth/complete-registration`,
      body: input,
    })

    if (response.statusCode === HttpStatusCode.CREATED) {
      return response.data
    }

    switch (response.data.code) {
      case ErrorCode.EMAIL_ALREADY_IN_USE:
        throw new EmailAlreadyExistsError()
      case ErrorCode.INVALID_CAPTCHA:
        throw new InvalidCaptchaError()
      case ErrorCode.USER_DISABLED:
        throw new UserDisabledError()
      default:
        throw new GeneralError('completeRegistration')
    }
  }
}
