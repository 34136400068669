import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '../../domain/dtos/query-keys'
import { GetAllProducts } from '../../usecases/get-all-products'

export const useGetAllProducts = (prependOptionForAll: boolean) => {
  const container = useContainer()

  return useQuery(
    [QueryKey.getAllProducts],
    () => new GetAllProducts(container.get(HttpClient)).execute(prependOptionForAll),
    {
      retry: 3,
      retryOnMount: false,
      refetchOnWindowFocus: false,
      initialData: [],
    },
  )
}
