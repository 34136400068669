import { MediaUploadDefaultError } from '../domain/errors/media-upload-default'
import { MediaUploadImageError } from '../domain/errors/media-upload-image'
import { MediaUploadPdfError } from '../domain/errors/media-upload-pdf'
import { MediaUploadVideoError } from '../domain/errors/media-upload-video'

export class GetUploadErrorMessage {
  public execute(type: string): Error {
    if (type.indexOf('image') !== -1) {
      return new MediaUploadImageError()
    }
    if (type.indexOf('pdf') !== -1) {
      return new MediaUploadPdfError()
    }
    if (type.indexOf('video') !== -1) {
      return new MediaUploadVideoError()
    }

    return new MediaUploadDefaultError()
  }
}
