import { Button, cn, CopyClipboardButton } from '@hub-la/shadcn'
import { ChevronLeft, Pause, Play, Share, Tag, Trash } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Offer } from '../../../domain/dtos/offer'
import { CouponStatus } from '../../../domain/enums/coupon-status'
import { CouponStatusStats } from '../../components/coupon-status-stats'
import { DeleteCouponModal } from '../../components/delete-coupon-modal'
import { ShareCouponModal } from '../../components/share-coupon-modal'
import { useChangeCouponStatus } from '../../hooks/use-change-coupon-status'

type HeaderProps = {
  id: string
  code: string
  status: CouponStatus
  onClose: () => void
  offers?: Offer[]
}

export const Header = ({ id, code, status, onClose, offers }: HeaderProps) => {
  const [isDeleteCouponOpen, setIsDeleteCouponOpen] = useState<boolean>(false)
  const [isShareOpen, setIsShareOpen] = useState<boolean>(false)
  const history = useHistory()
  const { mutateAsync: changeCouponStatus, isLoading } = useChangeCouponStatus()
  const { t } = useTranslation()

  const canChangeStatus = [CouponStatus.ACTIVE, CouponStatus.INACTIVE].includes(status)

  const onChangeStatus = () => {
    changeCouponStatus({ id, currentStatus: status })
  }

  return (
    <div className="flex flex-col lg:flex-row justify-between items-start lg:items-end space-y-5 lg:space-y-0 lg:space-x-5">
      <div className="space-y-1 md:w-auto">
        <Button className="h-4 px-0" size="sm" variant="link" onClick={() => history.goBack()}>
          <ChevronLeft className=" w-4 h-4 mr-2" />
          {t('goBack')}
        </Button>
        <div className="flex items-center space-x-2">
          <Tag className="w-6 h-6 " />
          <h3 className="text-3xl font-semibold">{code}</h3>
          <CouponStatusStats status={status} />
        </div>
        <CopyClipboardButton
          className="mx-auto w-full max-w-100 h-8"
          labelClassName="max-w-100 md:w-auto"
          copyContent={id}
          label={id}
          tooltipCopiedLabel={t('tooltipCopiedLabel')}
          tooltipCopyLabel={t('tooltipCopyLabel')}
        />
      </div>

      <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2 w-full md:w-auto">
        <Button variant="outline" onClick={() => setIsShareOpen(true)}>
          <Share className="mr-2 h-4 w-4" />
          {t('shareCoupon')}
        </Button>

        {canChangeStatus && (
          <Button variant="outline" onClick={onChangeStatus} loading={isLoading}>
            {status === CouponStatus.ACTIVE ? (
              <>
                <Pause className="mr-2 h-4 w-4" />
                {t('pauseCoupon')}
              </>
            ) : (
              <>
                <Play className="mr-2 h-4 w-4" />
                {t('activateCoupon')}
              </>
            )}
          </Button>
        )}

        <Button
          variant="outline"
          onClick={() => setIsDeleteCouponOpen(true)}
          className={cn('text-red-500', 'hover:text-red-600')}
        >
          <Trash className="mr-2 h-4 w-4 text-red-500" />
          {t('deleteCoupon')}
        </Button>
      </div>

      <ShareCouponModal
        id={id}
        open={isShareOpen}
        couponCode={code}
        offers={offers}
        onClose={() => setIsShareOpen(false)}
      />

      <DeleteCouponModal
        couponId={id}
        couponCode={code}
        open={isDeleteCouponOpen}
        onClose={() => setIsDeleteCouponOpen(false)}
        onSuccess={onClose}
      />
    </div>
  )
}
