import { CoProductionPage, CoProductionProvider } from '@hub-la/fe-co-production'
import { container } from '@hub-la/fe-container'

export const CoProductionInit = () => {
  return (
    <CoProductionProvider container={container}>
      <CoProductionPage />
    </CoProductionProvider>
  )
}
