export class GetVideoDuration {
  public async execute(file: File): Promise<number> {
    return new Promise((resolve, reject) => {
      const video = document.createElement('video')
      const objectURL = URL.createObjectURL(file)

      const onLoadedMetadata = (): void => {
        URL.revokeObjectURL(objectURL)
        video.removeEventListener('loadedmetadata', onLoadedMetadata)
        video.removeEventListener('error', onError)
        resolve(video.duration)
      }

      const onError = (error: ErrorEvent): void => {
        URL.revokeObjectURL(objectURL)
        video.removeEventListener('loadedmetadata', onLoadedMetadata)
        video.removeEventListener('error', onError)
        reject(error)
      }

      video.src = objectURL
      video.addEventListener('loadedmetadata', onLoadedMetadata)
      video.addEventListener('error', onError)
    })
  }
}
