import * as Yup from 'yup'

export const saveRuleSchema = Yup.object().shape({
  name: Yup.string(),
  url: Yup.string().url('errors.validation.url.mustBeUrl').required('errors.validation.url.required'),
  products: Yup.array().min(1),
  offers: Yup.array().min(1),
  events: Yup.array().min(1),
})

export const ruleSchema = ['name', 'url', 'products', 'offers', 'events']
