import { FLAGS, useFeatureFlag } from '@hub-la/fe-core-feature-flag'
import { AvailableCarriers } from '../../domain/enums/available-carriers'
import { MultigatewayHandler } from '../../usecases/multigateway-handler'

/**
 * Custom hook for handling multigateway logic.
 *
 * @param offerId - The ID of the offer.
 * @returns The gateway configured for this offer, if there's nothing configured, returns the defaultGateway.
 */
export const useMultigatewayHandler = (offerId: string) => {
  const { isFlagEnabled, getFlagValue, getFlagParams } = useFeatureFlag()

  const hasCustomGateway = isFlagEnabled(FLAGS.CHECKOUT.CHECKOUT_MULTIGATEWAY_FALLBACK_SETTINGS, {
    id: offerId,
  })
  const gatewaysForOffer = getFlagValue(FLAGS.CHECKOUT.CHECKOUT_MULTIGATEWAY_FALLBACK_SETTINGS, {
    id: offerId,
  })
  const gatewaysFromParams = getFlagParams(FLAGS.CHECKOUT.CHECKOUT_MULTIGATEWAY_FALLBACK_SETTINGS)

  return new MultigatewayHandler(hasCustomGateway, gatewaysForOffer, {
    defaultGateways: (gatewaysFromParams['defaultGateways'] ?? []) as AvailableCarriers[],
    allGateways: (gatewaysFromParams['allGateways'] ?? []) as AvailableCarriers[],
  }).execute()
}
