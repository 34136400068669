import { PaymentMethod } from '../enums/payment-method'

export const paymentMethods = [
  {
    id: PaymentMethod.CREDIT_CARD,
    label: 'Cartão de crédito',
  },
  {
    id: PaymentMethod.PIX,
    label: 'Pix',
  },
  {
    id: PaymentMethod.BOLETO,
    label: 'Boleto',
  },
]
