import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { GetSubscriptionsInput } from '../domain/dtos/get-subscriptions-input'
import { GetSubscriptionsOutput } from '../domain/dtos/get-subscriptions-output'
import { GeneralError } from '../domain/errors/general'
import { Envs } from '../envs'
import { GetOfferIds } from './get-offer-ids'

export class GetSubscriptions {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(input: GetSubscriptionsInput, signal?: AbortSignal): Promise<GetSubscriptionsOutput> {
    const response = await this.httpClient.request({
      method: HttpMethod.POST,
      url: `${Envs.BFF_WEB_URL}/subscriptions/list`,
      body: this.makeBody(input),
      signal,
    })

    if (response.statusCode === HttpStatusCode.OK || response.statusCode === HttpStatusCode.CREATED) {
      return response.data
    }

    throw new GeneralError()
  }

  private makeBody(input: GetSubscriptionsInput) {
    const offerIds = new GetOfferIds().execute({ offerIds: input.offerIds, offers: input.offers })

    const body = {
      offerIds,
      filters: {
        search: input.search,
        status: input.statuses,
        startDate: input.startDate,
        endDate: input.endDate,
        dateRangeBy: input.dateRangeBy,
        planType: input.planType,
      },
      page: input.page,
      pageSize: input.pageSize,
      orderBy: input.orderBy,
      orderDirection: input.orderDirection,
    }

    return body
  }
}
