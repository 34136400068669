import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useToast } from '@hub-la/shadcn'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { QueryKey } from '../../../domain/enums/query-key'
import { GetAvailableResources } from '../../../usecases/member-area/get-available-resources'

export const useGetAvailableResources = () => {
  const { toast } = useToast()
  const { t } = useTranslation()
  const container = useContainer()

  return useQuery(
    [QueryKey.getAvailableResources],
    () => {
      return new GetAvailableResources(container.get(HttpClient)).execute()
    },
    {
      retry: 3,
      retryOnMount: false,
      refetchOnWindowFocus: false,
      onError: (error: Error) => toast({ description: t(error.message), variant: 'destructive' }),
    },
  )
}
