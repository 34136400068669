import { useIsMobile } from '@hub-la/shadcn'
import React from 'react'
import { GoBackButton, InviteForm } from '../components'

export const CoProductionPage: React.FC = () => {
  const isMobile = useIsMobile()

  return (
    <div className="container mx-auto px-4">
      <div className={`grid grid-cols-12 gap-${!isMobile ? '8' : '3'} py-2 ${!isMobile ? 'pb-8' : 'pb-32'}`}>
        <div className="col-span-12 sm:col-span-3">
          <GoBackButton />
        </div>
        <div className="col-span-12 sm:col-span-9">
          <InviteForm />
        </div>
      </div>
    </div>
  )
}
