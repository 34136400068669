import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { ErrorCode } from '../../domain/dtos/error-code'
import { UpdateShortLinkInput } from '../../domain/dtos/update-short-link-input'
import { GeneralError } from '../../domain/errors/general'
import { ShortLinkAlreadyExists } from '../../domain/errors/short-link-already-exists'
import { Envs } from '../../envs'
import { CreateShortLinkValues } from '../../presentation/pages/shortlinks/components/create-edit-shortlink-modal'

export class UpdateShortLink {
  public constructor(@inject(HttpClient) private readonly httpClient: HttpClient) {}

  public async execute(
    productId: string,
    shortLinkId: string | undefined,
    input: CreateShortLinkValues,
  ): Promise<null> {
    if (!shortLinkId) {
      throw new GeneralError()
    }

    const response = await this.httpClient.request({
      method: HttpMethod.PUT,
      url: `${Envs.BFF_PRODUCT_URL}/products/${productId}/short-links/${shortLinkId}`,
      body: this.makeBody(input),
    })

    if (response.statusCode === HttpStatusCode.OK) {
      return response.data
    }

    switch (response.data.errorCode) {
      case ErrorCode.SHORTLINK_ALREADY_EXISTS:
        throw new ShortLinkAlreadyExists()
      default:
        throw new GeneralError()
    }
  }

  private makeBody(input: CreateShortLinkValues): UpdateShortLinkInput {
    return {
      offerId: input.offerId,
      name: input.name,
      slug: input.slug,
      type: input.type,
      redirectUrl: input.redirectUrl,
      utm: input.applyToSpecificUtms
        ? {
            source: input.utmSource,
            medium: input.utmMedium,
            campaign: input.utmCampaign,
            content: input.utmContent,
            term: input.utmTerm,
          }
        : null,
    }
  }
}
