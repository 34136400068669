import { Calendar } from '@hub-la/shadcn'
import { Calendar as CalendarIcon, CheckSquare } from 'lucide-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ExpiresDateOption } from './expires-date-option'

interface ExpiresDateProps {
  error?: boolean
  helperText?: string
  selectedExpiresDateOption?: 'withDeadline' | 'withoutDeadline'
  selectedDate: Date | null
  onChangeOption: (value: 'withDeadline' | 'withoutDeadline') => void
  onChangeExpiresDate: (value: Date | null) => void
}

export const ExpiresDate: React.FC<ExpiresDateProps> = ({
  error,
  helperText,
  selectedExpiresDateOption,
  selectedDate,
  onChangeOption,
  onChangeExpiresDate,
}) => {
  const { t } = useTranslation()

  return (
    <div>
      <div className="grid grid-cols-2 gap-6" data-testid="expires-date-container">
        <ExpiresDateOption
          name="expiresDateOption"
          label={t('Receiver.inviteForm.fields.end_date.with_end_date')}
          checked={'withDeadline' === selectedExpiresDateOption}
          value="withDeadline"
          icon={<CheckSquare className="w-6 h-6 text-primary" />}
          onChange={(value) => onChangeOption(value)}
          data-testid={`expires-date-option-0`}
        />
        <ExpiresDateOption
          name="expiresDateOption"
          label={t('Receiver.inviteForm.fields.end_date.without_end_date')}
          checked={'withoutDeadline' === selectedExpiresDateOption}
          value="withoutDeadline"
          icon={<CalendarIcon className="w-6 h-6 text-primary" />}
          onChange={onChangeOption}
          data-testid={`expires-date-option-1`}
        />
        {selectedExpiresDateOption === 'withDeadline' && (
          <div className="col-span-2" data-testid="date-picker-container">
            <Calendar
              mode="single"
              selected={selectedDate ?? undefined}
              onSelect={(value) => onChangeExpiresDate(value ?? null)}
            />
          </div>
        )}
      </div>
      {helperText && <p className={`mt-2 text-sm ${error ? 'text-red-500' : 'text-muted-foreground'}`}>{helperText}</p>}
    </div>
  )
}
