import React from 'react'
import { useFormContext } from 'react-hook-form'
import { OfferSchemaValidationType } from '../../validations/offer-validation'

export const MobileBanner: React.FC = () => {
  const { watch } = useFormContext<OfferSchemaValidationType>()
  const { bannerMobileUrl, creatorLogoUrl } = watch('checkout')
  return (
    <div style={{ maxHeight: '380px', position: 'relative' }}>
      <img
        style={{ maxHeight: '380px', objectFit: 'cover' }}
        width="100%"
        height="auto"
        src={bannerMobileUrl!}
        alt="banner mobile"
      />
      {creatorLogoUrl && (
        <img
          style={{
            maxHeight: '60px',
            maxWidth: '120px',
            objectFit: 'cover',
            position: 'absolute',
            bottom: '24px',
            left: '24px',
            zIndex: 1,
          }}
          width="100%"
          height="auto"
          src={creatorLogoUrl!}
          alt="logo do criador"
        />
      )}
    </div>
  )
}
