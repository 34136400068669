import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { toast } from '@hub-la/shadcn'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { QueryKey } from '../../domain/enums/query-key'
import { AcceptRefund } from '../../usecases/accept-refund'

export const useAcceptRefund = () => {
  const container = useContainer()
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  return useMutation([QueryKey.acceptRefund], (id: string) => new AcceptRefund(container.get(HttpClient)).execute(id), {
    retry: false,
    onSuccess: (_, input) => {
      queryClient.invalidateQueries({ queryKey: [QueryKey.getRefund, input] })
      queryClient.invalidateQueries({ queryKey: [QueryKey.getRefunds] })
      toast({ description: t('acceptSuccess') })
    },
    onError: (error: Error) => toast({ description: t(error.message), variant: 'destructive' }),
  })
}
