import { AuthLayout } from '../../../components/auth-layout'
import { MetaTags } from '../../../components/meta-tags'
import { PhoneSignInCard } from '../../../components/phone-sign-in-card'

const SignInWhatsappPage = () => {
  return (
    <AuthLayout>
      <MetaTags />
      <PhoneSignInCard isWhatsapp />
    </AuthLayout>
  )
}

export { SignInWhatsappPage }
