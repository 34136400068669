import { Skeleton, TableCell, TableRow } from '@hub-la/shadcn'
import { times } from 'lodash'

export const TableLoading = () => {
  return (
    <TableRow>
      {times(4, (idx) => (
        <TableCell key={idx}>
          <Skeleton className={`h-4 w-[90px]`} />
        </TableCell>
      ))}
    </TableRow>
  )
}
