import { AssetResponse, AssetStatus, UploadStatus } from '@hub-la/fe-asset'
import { PostResponse, PostStatus } from '@hub-la/fe-post'
import moment, { Moment } from 'moment'
import { Values } from '../domain/dtos/values'
import { GetCurrentDate } from './get-current-date'

export class GetInitialValues {
  public execute(post?: Partial<PostResponse>): Values {
    let mainMedia: AssetResponse | null = null
    let mainMediaProgressStatus = UploadStatus.NO_CALLED
    let mainMediaProgress = 0

    let customCover: AssetResponse | null = null
    let customCoverStatus = UploadStatus.NO_CALLED
    let customCoverProgress = 0

    let event: Partial<Record<string, Moment | boolean | string>> = {
      notifyMembers: true,
      end: '',
      start: '',
      date: new GetCurrentDate().execute(),
    }

    if (post?.cover) {
      mainMedia = post.cover

      mainMediaProgressStatus = UploadStatus.LOADED
      mainMediaProgress = 100
    }

    if (post?.cover && [AssetStatus.processing, AssetStatus.uploading].includes(post.cover.status)) {
      mainMedia = post.cover
      mainMediaProgressStatus = UploadStatus.PROCESSING
      mainMediaProgress = 0
    }

    if (post?.customCover) {
      customCover = post.customCover
      customCoverProgress = 100
      customCoverStatus = UploadStatus.LOADED
    }

    if (post?.event) {
      event = {
        date: moment(post?.event?.startTime),
        notifyMembers: post?.notify,
        end: moment(post?.event?.endTime).format('HH:mm'),
        link: post?.event?.url ?? '',
        start: moment(post?.event?.startTime).format('HH:mm'),
      }
    }

    return {
      empty: false,
      title: post?.title ?? '',
      subtitle: post?.subtitle ?? '',
      content: post?.content ?? '',
      status: post?.status ?? PostStatus.DRAFT,
      attachedAssets: [...(post?.attachments ?? [])],
      customCover,
      customCoverStatus,
      customCoverProgress,
      mainMedia,
      mainMediaProgressStatus,
      mainMediaProgress,
      attachedProgress: null,
      attachedProgressStatus: UploadStatus.NO_CALLED,
      event,
    }
  }
}
