import { ReactNode } from 'react'

type Props = {
  title: string
  description: string
  children: ReactNode
}

export const IntegrationCategory = ({ title, description, children }: Props) => {
  return (
    <div className="mb-12 max-w-5xl w-full">
      <h2 className="text-2xl font-semibold mb-1">{title}</h2>
      <p className="font-normal text-base text-muted-foreground mb-4">{description}</p>
      <div className="flex flex-col md:items-center gap-2">{children}</div>
    </div>
  )
}
