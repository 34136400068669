import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableEmptyState,
  TableHead,
  TableHeader,
  TablePagination,
  TableRow,
} from '@hub-la/shadcn'
import { flexRender, getCoreRowModel, PaginationState, useReactTable } from '@tanstack/react-table'
import isEmpty from 'lodash/isEmpty'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Refund } from '../../../../domain/dtos/refund'
import { useGetRefunds } from '../../../hooks/use-get-refunds'
import { useRefetch } from '../../../hooks/use-refetch'
import { columns } from './columns'
import { RefundActions } from './refund-actions'

export const RefundTable = ({ offers }) => {
  const { t } = useTranslation()
  const [pagination, setPagination] = useState<PaginationState>({
    pageSize: 25,
    pageIndex: 1,
  })

  const {
    data = { items: [], total: 0, page: pagination.pageIndex, pageSize: pagination.pageSize, lastPage: 1 },
    isFetching,
    refetch,
  } = useGetRefunds({ offers, page: pagination.pageIndex, pageSize: pagination.pageSize })

  const isRowsEmpty = isEmpty(data?.items)

  const canRenderLoadingState = isFetching && isRowsEmpty

  const tableData = useMemo(
    () => (canRenderLoadingState ? Array(pagination.pageSize).fill({}) : data.items),
    [canRenderLoadingState, data],
  )

  const tableColumns = useMemo(
    () =>
      canRenderLoadingState
        ? columns.map((column) => ({
            ...column,
            cell: () => <Skeleton className="h-[14px] w-[60%] rounded-sm" />,
          }))
        : columns,
    [canRenderLoadingState],
  )

  const table = useReactTable<Refund>({
    data: tableData,
    columns: tableColumns,
    state: {
      pagination,
    },
    enableRowSelection: true,
    manualSorting: true,
    manualPagination: true,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
  })

  useRefetch({ page: pagination.pageIndex, pageSize: pagination.pageSize }, refetch)

  return (
    <div className="space-y-2">
      <div className="rounded-lg border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id} className="hover:bg-inherit">
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {!canRenderLoadingState ? (
              (table.getRowModel().rows || []).map((row) => (
                <TableRow key={row.id} data-state={row.getIsSelected() && 'selected'} className="cursor-pointer">
                  {row.getVisibleCells().map((cell, cellIndex) => (
                    <TableCell
                      className={`text-foreground py-2.5 ${
                        cellIndex === 1 || cellIndex === 3 ? 'max-w-[160px] truncate' : ''
                      } ${cellIndex === 1 ? 'font-medium' : 'font-normal'}
                      ${cellIndex === 3 ? 'text-xs' : 'text-sm'}`}
                      key={cell.id}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                  <RefundActions onSuccess={refetch} refund={{ id: row.original.id, status: row.original.status }} />
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableHead colSpan={tableColumns.length}>
                  <TableEmptyState
                    title={t('refundTable.emptyState.title')}
                    subtitle={t('refundTable.emptyState.description')}
                  />
                </TableHead>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="flex justify-end">
        {!isRowsEmpty && (
          <TablePagination
            page={pagination.pageIndex}
            setPage={(page) => table.setPageIndex(page)}
            lastPage={data.lastPage}
            pageSize={pagination.pageSize}
            setPageSize={(pageSize) => table.setPageSize(pageSize)}
          />
        )}
      </div>
    </div>
  )
}
