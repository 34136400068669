export const en = {
  editGroup: {
    mobilePageTitle: 'Product',
  },
  navBar: {
    profile: 'View profile',
    products: 'My Products',
    editProfile: 'Edit Profile',
    accountVerified: 'Account Verified',
    verifyAccount: 'Verify Account',
    bankAccount: 'Bank Account',
    affiliates: 'Affiliate Revenue',
    tipsAndResources: 'Tips and Resources',
    faq: 'Help',
    ourBlog: 'Our blog',
    logout: 'Logout',
    changeMode: {
      creator: 'Use Creator Mode',
      member: 'Use Member Mode',
    },
  },
  pdf: {
    pagination: 'Page {{pageNumber}} of {{numPages}}',
  },
  dashboard: {
    header: {
      title: 'Member area',
      description: 'Share exclusive content for your members',
      createSection: 'Create module',
      recomendationInfo:
        'We will recommend that your new members consume your content from the first section of the page.',
    },
    button: {
      create: 'Create content',
      editList: 'Edit list',
    },
    labels: {
      draft: 'Draft',
    },
    menu: {
      publish: 'Publish content',
      edit: 'Edit content',
      view: 'View content',
      copyLink: 'Copy content link',
      linkCopied: 'Link copied to clipboard',
      addSectionCover: 'Add cover',
      editSectionCover: 'Edit cover',
      editCover: 'Edit cover',
      remove: 'Delete',
      moveTo: 'Move to',
    },
  },
  content: {
    title: 'Add media to your content',
    mAllowedTypes: 'You can send pdf, image or video',
    allowedTypes: 'You can send pdf, image or video',
    form: {
      title: 'Title of the content*',
      subtitle: 'Content subtitle',
      content: 'Write about this content...',
    },
    dialog: {
      insertImage: 'Insert image',
      editImage: 'Edit image',
      insertVideo: 'Insert video',
      loading: 'Loading ...',
    },
    advantages: {
      posts: 'Send articles, videos, e-books, and live recordings',
      customize: 'Customize the members area with your branding',
      events: 'Event managment with automated invitations and notifications',
    },
  },
  publication: {
    save: {
      success: 'Content saved successfully',
      error: 'Failed to save content',
    },
    publish: {
      success: 'Content successfully post',
      error: 'Failed to create content',
    },
  },
  mediaUpload: {
    processing: 'We are processing the video...',
    progress: '{{progress}}% Loaded...',
    progressWarning: 'Video loading',
    loadingThumbnail: 'Generating thumbnail...',
    waitLabel: 'Await...',
    success: 'Uploaded!',
    removeAsset: 'Remove',
    cancelRequest: 'Cancel',
    header: {
      create: 'New Publication',
      update: 'Update Publication',
      save: 'Save',
      saved: 'Saved!',
      published: 'Published',
      next: 'Publish',
    },
    modal: {
      delete: {
        title: 'Remove file',
        description: 'You can add another file again.',
        btnNext: 'Remove',
        btnPrev: 'Keep',
      },
      cancel: {
        title: 'Cancel loading',
        description: 'The file will be removed automatically but you can add another one again.',
        btnNext: 'Cancel',
        btnPrev: 'Keep loading',
      },
    },
    error: {
      default: 'Failed to upload file',
      image: 'Failed to upload image',
      media: 'Failed to upload media',
      video: 'Failed to upload video',
      pdf: 'Failed to upload pdf',
      size: 'File too big, select files under 256 GB',
      tryAgain: 'Try again',
      remove: 'Failed to remove file',
    },
  },
  mediaLoad: {
    error: {
      pdf: 'Failed to load pdf',
    },
  },
  customCoverUpload: {
    post: {
      title: 'Content cover',
      description:
        "Add an image that shows what's in your content. A good cover stands out and catches the attention of your members",
    },
    section: {
      title: 'Module cover',
      description:
        'Add a cover image to represent your content module. A good cover stands out and catches the attention of your members',
    },
    title: 'Edit Cover',
    subtitle: 'You can choose the cover you want for members to see your content',
    done: 'Done',
    sizeTip: 'The ideal dimension is 319x498px (9:16)',
    bottomText: 'Upload an image from your device to set the cover of your content.',
    cover: 'Cover',
  },
  confirmationModal: {
    title: 'Are you sure you want to delete the section and its contents?',
    subtitle:
      'Deleting the section will erase its contents. If you want to keep the contents, we recommend moving them from this section before deleting it.',
    confirm: 'Delete section and contents',
    cancel: 'Cancel',
  },
  editSection: {
    title: 'Edit section',
    done: 'Save',
    editOrder: 'Organize content',
    types: {
      text: 'Text',
      application: 'Pdf',
      video: 'Video',
      image: 'Image',
    },
  },
  emptyState: {
    title: 'Sections',
    subtitle: 'You can start by adding a section to better organize your content.',
    button: 'Add section',
  },
  assets: {
    title: 'Attachments',
    download: 'Download',
    processing: 'The file is still processing',
    error: 'There was an error processing the file',
    new: 'Add attachments',
    remove: 'Remove',
    canceled: 'Canceled',
    uploadError: 'Error loading attachment',
  },
  button: {
    addFile: 'Add media',
  },
}
