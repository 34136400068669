import { AvailableCarriers } from '../domain/enums/available-carriers'

export class MultigatewayHandler {
  private hasCustomGateway: boolean
  private gatewaysForOffer: AvailableCarriers[]
  private gatewaysFromParams: { defaultGateways: AvailableCarriers[]; allGateways: AvailableCarriers[] }

  constructor(
    hasCustomGateway: boolean,
    gatewaysForOffer: AvailableCarriers[],
    gatewaysFromParams: { defaultGateways: AvailableCarriers[]; allGateways: AvailableCarriers[] },
  ) {
    this.hasCustomGateway = hasCustomGateway
    this.gatewaysForOffer = gatewaysForOffer
    this.gatewaysFromParams = gatewaysFromParams
  }

  public execute(): AvailableCarriers {
    if (this.hasCustomGateway) {
      return this.getGatewayResult(this.gatewaysForOffer)
    } else {
      return this.getGatewayResult(this.gatewaysFromParams.defaultGateways)
    }
  }

  private getGatewayResult(gateways: AvailableCarriers[]): AvailableCarriers {
    if (gateways.length > 1) {
      return AvailableCarriers.MULTI
    } else {
      return gateways[0]
    }
  }
}
