type Props = {
  image?: string | null
}

export const Overlay = ({ image }: Props) => {
  return (
    <>
      {image && (
        <div
          data-testid="overlay-image"
          className="absolute inset-0 z-[-1] h-[37.5rem] bg-cover bg-center bg-no-repeat mix-blend-multiply"
          style={{
            backgroundImage: `linear-gradient(to bottom, rgba(255,255,255,0) 30%, rgba(255,255,255,1)), url('${image}')`,
          }}
        />
      )}

      <div
        data-testid="overlay-color"
        className="absolute inset-0 z-[-2] h-[37.5rem] bg-gradient-to-b from-[#12121260] to-transparent"
      />
    </>
  )
}
