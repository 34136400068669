import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useInfiniteQuery } from '@tanstack/react-query'
import { useLocation } from 'react-router-dom'
import { QueryKey } from '../../domain/dtos/query-keys'
import { GetMovements } from '../../usecases/get-movements'

export const useInfiniteGetMovements = (perPage: string) => {
  const container = useContainer()
  const { search } = useLocation()

  const getMovements = async ({ pageParam = undefined }) => {
    return new GetMovements(container.get(HttpClient)).execute(search, pageParam)
  }

  const options = {
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    enabled: !!search,
  }

  return useInfiniteQuery({
    queryKey: [QueryKey.getMovements, search, perPage],
    queryFn: getMovements,
    getNextPageParam: (lastPage) => lastPage?.after,
    ...options,
  })
}
