import { Badge } from '@hub-la/shadcn'
import { Calendar, Clock } from 'lucide-react'
import moment from 'moment'
import 'moment/locale/pt-br'

type Props = {
  startTime: Date
  endTime: Date
}

export const DateRange = ({ startTime, endTime }: Props) => {
  moment.locale('pt-br')

  const formatDate = () => {
    const start = moment(startTime)
    const dayOfWeek = start.format('dddd')
    const date = start.format('D [de] MMMM').toLowerCase()
    return `${dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1).replace('-feira', '')}, ${date}`
  }

  const formatHoursRange = () => {
    const start = moment(startTime)
    const end = moment(endTime)
    const startDate = start.format('HH:mm')
    const endDate = end.format('HH:mm')
    return `${startDate} às ${endDate}`
  }

  return (
    <div className="flex gap-2 items-center">
      <Badge variant="secondary" className="bg-secondary hover:bg-secondary rounded-full">
        <Calendar className="w-3 h- mr-1" />
        {formatDate()}
      </Badge>
      <Badge variant="secondary" className="bg-secondary hover:bg-secondary rounded-full">
        <Clock className="w-3 h- mr-1" />
        {formatHoursRange()}
      </Badge>
    </div>
  )
}
