import { useContainer } from '@hub-la/fe-container'
import { Analytics, AnalyticsEvent } from '@hub-la/fe-core-analytics'
import { Checkbox } from '@hub-la/shadcn'
import { Shapes, ShoppingBag } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { UserType } from '../../../domain/dtos/user-login-preferences-input'
import { AuthCard } from '../../components/auth-card/auth-card'
import { AuthLayout } from '../../components/auth-layout'
import { UserLoginNavigationTarget } from '../../components/user-login-navigation-target'
import { usePutUserLoginPreferences } from '../../hooks/use-put-user-login-preferences'

const NewSelectUserLoginPreferences = () => {
  const [dontAskAgain, setDontAskAgain] = useState(false)
  const { t } = useTranslation()
  const history = useHistory()

  const { mutateAsync } = usePutUserLoginPreferences()
  const container = useContainer()
  const analytics = container.get(Analytics)

  const handleNavigationTargetClick = (userType) => () => {
    if (dontAskAgain) {
      mutateAsync({ navigationTarget: userType })
    }
    analytics.track(
      userType === UserType.CREATOR
        ? AnalyticsEvent.LOGIN_PREFERENCES.NAVIGATION_TARGET.CREATOR_CLICKED
        : AnalyticsEvent.LOGIN_PREFERENCES.NAVIGATION_TARGET.BUYER_CLICKED,
      { dontAskAgain },
    )
    document.title = 'Hubla'
    history.push(userType === UserType.CREATOR ? '/dashboard' : '/user_groups')
  }

  return (
    <AuthLayout showTerms={false}>
      <AuthCard showTerms={false} title={t('selectUserLoginNavigationTarget.title')}>
        <div className="flex flex-col space-y-4 min-w-80">
          <UserLoginNavigationTarget
            title={t('selectUserLoginNavigationTarget.userNavigationTarget.title')}
            onClick={handleNavigationTargetClick(UserType.BUYER)}
            icon={<ShoppingBag />}
          />
          <UserLoginNavigationTarget
            title={t('selectUserLoginNavigationTarget.creatorNavigationTarget.title')}
            onClick={handleNavigationTargetClick(UserType.CREATOR)}
            icon={<Shapes />}
          />
          <div className="flex items-center space-x-2">
            <Checkbox
              id="dont-ask-again"
              checked={dontAskAgain}
              onCheckedChange={() => setDontAskAgain(!dontAskAgain)}
            />
            <label
              htmlFor="dont-ask-again"
              className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            >
              {t('selectUserLoginNavigationTarget.checkboxDontAskAgain')}
            </label>
          </div>
        </div>
      </AuthCard>
    </AuthLayout>
  )
}

export { NewSelectUserLoginPreferences }
