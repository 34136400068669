import { useQuery } from '@tanstack/react-query'

import { GetCreatorInvoiceDetails } from '../../usecases/get-creator-invoice-details'

import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { QueryKey } from '../../domain/dtos/query-keys'

export const useGetCreatorInvoiceDetails = (invoiceId: string | undefined) => {
  const container = useContainer()
  return useQuery(
    [QueryKey.getCreatorInvoiceDetails],
    () => new GetCreatorInvoiceDetails(container.get(HttpClient)).execute({ invoiceId: invoiceId ?? '' }),
    {
      enabled: !!invoiceId,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      retry: 3,
    },
  )
}
