import { useFeatureFlag } from '@hub-la/fe-core-feature-flag'
import { useGetUser } from '@hub-la/fe-get-user'
import { Stream } from '@hub-la/shadcn'
import { CircleDollarSign, TextSearch } from 'lucide-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActionButton } from './action-button'
import { OverdueSmartInstallmentActionButton } from './overdue-smart-installent-action-button'

export const Header: React.FC = () => {
  const { t } = useTranslation()
  const { data: currentUser } = useGetUser()

  const showHighTicket = useFeatureFlag().isFlagEnabled('isHighTicketEnable', {
    id: currentUser?.id,
  })

  return (
    <div className="flex flex-col lg:flex-row w-full gap-6 justify-between flex-wrap">
      <h1 className="text-xl sm:text-2xl lg:text-3xl font-semibold">
        {currentUser?.name ? t('hello', { name: currentUser.name.split(' ').at(0) }) : t('title')}
      </h1>
      <div className="flex flex-col lg:flex-row gap-3">
        {showHighTicket && (
          <>
            <OverdueSmartInstallmentActionButton />
            <ActionButton href="/user_smart_installments" icon={<Stream />} text={t('smartInstallment.cta')} />
          </>
        )}
        <ActionButton href="/user_invoices" icon={<CircleDollarSign />} text={t('userTransactions')} />
        <ActionButton href="/user_subscriptions" icon={<TextSearch />} text={t('userSubscriptions')} />
      </div>
    </div>
  )
}
