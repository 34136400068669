import { Button } from '@hub-la/shadcn'
import { Plus } from 'lucide-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ShortLink } from '../../../../domain/dtos/short-link'
import { CreateEditShortLinkType } from '../../../../domain/enums/create-edit-shortlink-type'
import { ShortLinksTable } from '../shortlinks-table/shortlinks-table'
import { CardInstructionUtm } from './card-instruction-utm'

type CardSectionShortLinksProps = {
  productId: string
  isLoading: boolean
  total: number
  items: ShortLink[]
  setShowCreateEditModal: (state: {
    isOpen: boolean
    shortLink: ShortLink | null
    type: CreateEditShortLinkType
  }) => void
  onUpdate: () => void
}

export const CardSectionShortLinks: React.FC<CardSectionShortLinksProps> = ({
  productId,
  isLoading,
  total,
  items,
  setShowCreateEditModal,
  onUpdate,
}) => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-between items-center">
        <h1 className="text-xl font-semibold">Links</h1>
        <Button
          variant="default"
          onClick={() =>
            setShowCreateEditModal({
              isOpen: true,
              type: CreateEditShortLinkType.CREATE,
              shortLink: null,
            })
          }
        >
          <Plus className="mr-2 h-4 w-4" />
          {t('shortlinks.buttons.addShortlink')}
        </Button>
      </div>

      <CardInstructionUtm />

      <ShortLinksTable
        productId={productId}
        isLoading={isLoading}
        total={total}
        items={items}
        setShowCreateEditModal={setShowCreateEditModal}
        onUpdate={onUpdate}
      />
    </div>
  )
}
