import { useEffect, useRef } from 'react'

export const useClickOutside = (callback: () => void) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const dropdownContent = document.querySelector('[role="menu"]')
      const clickedInDropdown = dropdownContent?.contains(event.target as Node)

      if (ref.current && !ref.current.contains(event.target as Node) && !clickedInDropdown) {
        callback()
      }
    }

    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        callback()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    document.addEventListener('keydown', handleEsc)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [callback])

  return ref
}
