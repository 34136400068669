export class VideoDuration {
  private value = 0

  public constructor(value: number) {
    this.value = value
  }

  public static build(value: number): VideoDuration {
    return new VideoDuration(value)
  }

  public format() {
    const hrs = ~~(this.value / 3600)
    const mins = ~~((this.value % 3600) / 60)
    const secs = ~~this.value % 60

    return '' + (hrs > 0 ? hrs + ':' : '') + (mins < 10 ? '0' + mins : mins) + ':' + (secs < 10 ? '0' + secs : secs)
  }
}
