import { Button } from '@hub-la/shadcn'
import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import { IsStepValid } from '../../usecases/is-step-valid'
import { useField } from '../hooks/use-field'
import { FormSteps, Values } from '../pages/account-verification-modal'
import { FormHeader } from './form-header'
import { WithdrawalForm } from './withdrawal-form'

type Props = {
  onComplete: () => void
  loading?: boolean
}

export const UpdatePayoutForm: React.FC<Props> = ({ onComplete, loading }) => {
  const { t } = useTranslation()
  const { isValid } = useField()
  const { values } = useFormikContext<Values>()

  const isStepValid = new IsStepValid(isValid).execute(FormSteps.WITHDRAW_INFO)

  return (
    <div className="flex flex-col space-y-4">
      <FormHeader
        title={t('accountVerification.form.payout.title')}
        subtitle={t('accountVerification.form.payout.subtitle')}
      />
      <WithdrawalForm documentValue={values.identity.document.value} fullName={values.identity.fullName} />
      <div className="self-end">
        <Button
          disabled={!isStepValid}
          variant="default"
          onClick={onComplete}
          className={loading ? 'opacity-50 cursor-not-allowed' : ''}
        >
          {loading ? 'Carregando...' : t('accountVerification.form.continue')}
        </Button>
      </div>
    </div>
  )
}
