import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useToast } from '@hub-la/shadcn'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { QueryKey } from '../../domain/enums/query-key'
import { GetAffiliationProgram } from '../../usecases/get-affiliation-program'

export const useGetAffiliationProgram = (id: string) => {
  const container = useContainer()
  const { toast } = useToast()
  const { t } = useTranslation()

  return useQuery(
    [QueryKey.getAffiliationProgram, id],
    () => new GetAffiliationProgram(container.get(HttpClient)).execute(id),
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!id,
      onError: (error: Error) =>
        toast({
          title: t(error.message),
          variant: 'destructive',
        }),
    },
  )
}
