import { Button } from '@hub-la/shadcn'
import { ArrowLeft } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

export const BackwardButton = () => {
  const history = useHistory()
  const { t } = useTranslation()

  return (
    <Button variant="secondary" className="max-w-max gap-1" onClick={() => history.goBack()}>
      <ArrowLeft className="w-4 h-4" />
      {t('back')}
    </Button>
  )
}
