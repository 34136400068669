export const pt = {
  title: 'Início',
  betterExperience:
    'Para ter uma melhor experiência de consumo dos gráficos e relatórios disponíveis, acesse pelo seu computador.',
  revenue: {
    title: 'Receita líquida',
    description:
      'Valor total das vendas no período após deduções, como: taxas da Hubla, porcentagens de co-produção, afiliados e concessões de reembolsos e chargebacks finalizados.',
  },
  totalSale: {
    title: 'Número de vendas',
    description: 'Total de vendas concretizadas durante o período.',
  },
  totalRefund: {
    title: 'Reembolsos',
    description: 'Total de reembolsos concedidos durante o período.',
  },
  abandonedCheckout: {
    title: 'Carrinho abandonado',
    description:
      'Total de usuários que abandonaram a sessão do carrinho de compras durante o período e não realizaram a compra após 20 minutos.',
    modal: {
      title: 'Exportar carrinho abandonado',
      cancel: 'Cancelar',
      confirm: 'Exportar',
      noOptions: 'Não há opções disponíveis',
      loading: 'Carregando...',
      products: 'Produtos',
    },
  },
  creditCardApprovalRate: {
    title: 'Aprovação de cartão de crédito',
    description:
      'Percentual de vendas com cartão de crédito que tiveram seu pagamento concretizados durante o período. Exclui-se casos de não aprovação por falta de limite no cartão.',
  },
  bankSlipConversion: {
    title: 'Conversão boleto',
    description: 'Percentual de vendas com boleto que tiveram seu pagamento concretizado durante o período.',
  },
  pixConversion: {
    title: 'Conversão Pix',
    description: 'Percentual de vendas com Pix que tiveram seu pagamento concretizado durante o período.',
  },
  averageTicket: {
    title: 'Ticket médio',
    description: 'Indicador resultante do valor bruto médio de todas as vendas concretizadas durante o período.',
  },
  variation: 'vs. {{previous}} período anterior',
  selectPeriod: {
    hourly: 'Hora',
    daily: 'Dia',
    weekly: 'Semana',
    monthly: 'Mês',
    yearly: 'Ano',
  },
  datepicker: {
    placeholder: 'Data',
  },
}
