import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { QueryKey } from '@hub-la/fe-post'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { DuplicatePost } from '../../usecases/duplicate-post'

export const useDuplicatePost = ({ onSuccess }: { onSuccess?: () => void }) => {
  const queryClient = useQueryClient()
  const container = useContainer()

  return useMutation(
    [QueryKey.duplicatePost],
    (payload: { sectionId: string; productId: string; postId: string }) =>
      new DuplicatePost(container.get(HttpClient)).execute(payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [QueryKey.listSections] })
        if (onSuccess) {
          onSuccess()
        }
      },
    },
  )
}
