import { useToast } from '@hub-la/shadcn'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { OfferDefaultValuesMapper } from '../../../domain/mappers/make-offer-default-values'
import { GetDefaultCohortId } from '../../../usecases/get-default-cohort-id.usecase'
import { useListCohorts } from '../../hooks/use-list-cohorts'
import { usePostOffer } from '../../hooks/use-post-offer'
import { OfferSchemaValidationType } from '../../validations/offer-validation'
import { OfferForm } from '../offer'

const CreateOfferPage = () => {
  const history = useHistory()
  const { productId } = useParams<{ productId: string }>()
  const { toast } = useToast()
  const { t } = useTranslation()

  const { mutate, isLoading } = usePostOffer({
    onSuccess: () => history.push(`/edit/${productId}/offers`),
    onError: (error) => {
      toast({
        title: t('backendErrors.' + error.message),
        variant: 'destructive',
      })
    },
  })

  const { data } = useListCohorts({
    productId,
    page: 1,
    pageSize: 999,
  })

  const defaultValues: OfferSchemaValidationType = useMemo(() => {
    const defaultCohortId = new GetDefaultCohortId().execute(data)

    if (!defaultCohortId) {
      return OfferDefaultValuesMapper(productId)
    }

    return OfferDefaultValuesMapper(productId, {
      cohortIds: [defaultCohortId],
    })
  }, [productId, data])

  return (
    <OfferForm
      productId={productId}
      onSubmit={(data) => {
        mutate(data)
      }}
      isSubmitting={isLoading}
      defaultValues={defaultValues}
    />
  )
}

export { CreateOfferPage }
