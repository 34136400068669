import { AssetType } from '@hub-la/fe-asset'
import { Post } from '@hub-la/fe-post'
import React from 'react'

interface Props {
  post: Post | null | undefined
  progress?: number
}

export const ProgressBar: React.FC<Props> = ({ post, progress }) => {
  const getDuration = (cover: any): number => {
    switch (cover.type) {
      case AssetType.application:
        return cover.metadata ? cover.metadata.pages ?? 0 : 0
      case AssetType.video:
        return cover.metadata ? cover.metadata.duration ?? 0 : 0
      default:
        return 0
    }
  }

  const calcPorcentageProgress = (progress: number, total: number) => {
    return (progress / total) * 100
  }

  const calcWidthBar = (): number => {
    const cover = post?.cover
    const viewed = post?.viewed

    if (cover) {
      const canCalculatePercentageProgress = [AssetType.application, AssetType.video].includes(cover.type)
      if (canCalculatePercentageProgress) {
        return calcPorcentageProgress(progress ?? (post.cover?.progress?.currentProgress || 0), getDuration(cover))
      }

      if (cover.type === AssetType.image) {
        return viewed ? 100 : 0
      }

      return 0
    }

    return viewed ? 100 : 0
  }

  const progressBar = calcWidthBar()

  return (
    <div className="absolute h-[3px] left-0 right-0 bottom-[4px] px-1">
      <div data-testid="publication-progress-content" className="relative bg-secondary h-[3px] rounded-full">
        {progressBar !== 0 && (
          <div
            data-testid="publication-progress-bar"
            className="absolute h-[3px] left-0 bottom-0 bg-primary rounded-full"
            style={{ width: `${progressBar}%` }}
          />
        )}
      </div>
    </div>
  )
}
