import {
  Button,
  Card,
  CardContent,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TablePagination,
  TableRow,
} from '@hub-la/shadcn'
import times from 'lodash/times'
import { ArrowLeft, Calendar, Repeat } from 'lucide-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

const Loading: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()

  const getHeadCells = () => {
    return [
      { key: 'total', label: t('table.total'), sortable: false, width: 90 },
      { key: 'status', label: t('table.status'), sortable: false, width: 70 },
      { key: 'paidAt', label: t('table.paidAt'), sortable: false, width: 110 },
    ]
  }

  return (
    <div className="pb-40 px-6 lg:px-0 max-w-[946px] w-full mx-auto">
      <div className="mb-8 self-start w-fit">
        <Button variant="ghost" onClick={() => history.goBack()} className="flex items-center">
          <ArrowLeft className="mr-2 h-4 w-4" />
          {t('back')}
        </Button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
        <div className="col-span-12">
          <Card>
            <CardContent className="p-6">
              <div className="flex items-center gap-4 mb-4">
                <Skeleton className="w-[90px] h-auto aspect-[218/142]" />
                <div>
                  <div className="flex items-center gap-2">
                    <Skeleton className="h-8 w-32" />
                    <Skeleton className="w-[59px] h-8" />
                  </div>
                  <Skeleton className="h-4 w-32 mt-1" />
                </div>
              </div>
              <hr className="my-4" />
              <div className="flex mt-4">
                <div className="flex flex-col gap-2 w-full">
                  <p className="text-base">Data de inicio</p>
                  <div className="flex items-center gap-2">
                    <Calendar className="text-muted-foreground" />
                    <Skeleton className="h-6 w-28" />
                  </div>
                </div>
                <div className="flex flex-col gap-2 w-full">
                  <p className="text-base">Data de renovação</p>
                  <div className="flex items-center gap-2">
                    <Repeat className="text-muted-foreground" />
                    <Skeleton className="h-6 w-28" />
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>

        <div className="col-span-12 md:col-span-6">
          <Card className="h-full">
            <CardContent className="p-6 flex flex-col gap-4">
              <div>
                <p className="text-base">Plano de assinatura</p>
                <Skeleton className="h-6 w-28 mt-1" />
              </div>
              <div>
                <p className="text-base">Preço</p>
                <Skeleton className="h-6 w-28 mt-1" />
              </div>
            </CardContent>
          </Card>
        </div>

        <div className="col-span-12 md:col-span-6">
          <Card className="h-full">
            <CardContent className="p-6 flex flex-col justify-between h-full">
              <div>
                <p className="text-base">Método de pagamento</p>
                <Skeleton className="h-6 w-28 mt-1" />
              </div>
            </CardContent>
          </Card>
        </div>
      </div>

      <div className="mt-8 w-full">
        <h2 className="text-2xl font-bold mb-3">{t('invoiceHistory')}</h2>
        <div className="flex flex-col gap-4">
          <Table>
            <TableHeader>
              <TableRow>
                {getHeadCells().map((cell) => (
                  <TableHead key={cell.key} style={{ width: cell.width }}>
                    {cell.label}
                  </TableHead>
                ))}
              </TableRow>
            </TableHeader>
            <TableBody>
              {times(3, (idx) => (
                <TableRow key={`skeleton-row-${idx}`}>
                  <TableCell>
                    <Skeleton className="h-6 w-20" />
                  </TableCell>
                  <TableCell>
                    <Skeleton className="h-6 w-16" />
                  </TableCell>
                  <TableCell>
                    <Skeleton className="h-6 w-24" />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <TablePagination
            page={1}
            setPage={() => {
              //
            }}
            lastPage={1}
            pageSize={25}
            setPageSize={() => {
              //
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default Loading
