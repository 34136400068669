import { SVGProps } from 'react'

type PartialLayoutProps = SVGProps<SVGSVGElement>

export const PartialLayout = (props: PartialLayoutProps) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M3 2C3 1.44772 3.44772 1 4 1H16C16.5523 1 17 1.44772 17 2V4C17 4.55228 16.5523 5 16 5H4C3.44772 5 3 4.55228 3 4V2Z" fill="currentColor" />
      <path d="M4 8C4 7.44772 4.44772 7 5 7H15C15.5523 7 16 7.44772 16 8V12C16 12.5523 15.5523 13 15 13H5C4.44772 13 4 12.5523 4 12V8Z" fill="currentColor" />
      <path d="M3 16C3 15.4477 3.44772 15 4 15H16C16.5523 15 17 15.4477 17 16V18C17 18.5523 16.5523 19 16 19H4C3.44772 19 3 18.5523 3 18V16Z" fill="currentColor" />
    </svg>
  )
}
