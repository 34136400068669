import { HttpClient } from '@hub-la/fe-core-http-client'
import { useQuery } from '@tanstack/react-query'
import { useContainer } from '../../container'
import { GetIdentity } from '../../usecases/get-identity'

export const useGetIdentity = () => {
  const container = useContainer()

  return useQuery(['getIdentity'], () => new GetIdentity(container.get(HttpClient)).execute(), {
    retry: false,
    refetchOnWindowFocus: false,
  })
}
