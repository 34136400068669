import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation } from '@tanstack/react-query'
import { GetExportActiveMembersInput } from '../../../domain/dtos/get-export-active-members-input'
import { QueryKey } from '../../../domain/enums/query-key'
import { GetExportActiveMembers } from '../../../usecases/members/get-export-active-members'

export const useGetExportActiveMembers = () => {
  const container = useContainer()

  return useMutation(
    [QueryKey.getExportActiveMembers],
    (input: GetExportActiveMembersInput) => new GetExportActiveMembers(container.get(HttpClient)).execute(input),
    {},
  )
}
