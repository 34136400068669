import { useContainer } from '@hub-la/fe-container'
import { Analytics, AnalyticsEvent } from '@hub-la/fe-core-analytics'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@hub-la/shadcn'
import { memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ArrayParam, createEnumParam, StringParam, useQueryParams, withDefault } from 'use-query-params'
import { BookAccount, bookAccounts } from '../../../domain/dtos/book-accounts'
import { GetDateRangeFromPeriod } from '../../../usecases/get-date-range-from-period'
import { AccountInfo } from '../../components/account-info/account-info'
import { CreatorInvoiceModal } from '../../components/invoice-modal/invoice-modal'
import { RatesAndTimelines } from '../../components/rates-and-timelines/rates-and-timelines'
import { WithdrawalDetailsModal } from '../../components/withdrawal-details-modal/withdrawal-details-modal'
import { WithdrawalRequestModal } from '../../components/withdrawal-request-modal/withdrawal-request-modal'
import { useGetBalance } from '../../hooks/use-get-balance'
import { useInfiniteGetMovements } from '../../hooks/use-infinite-get-movements'
import { Balances } from './balance/balances'
import { MovementsGrid } from './movements-grid/movements-grid'

export const FinancialStatement = memo(() => {
  const [invoiceId, setInvoiceId] = useState<string | undefined>()
  const [withdrawalId, setWithdrawalId] = useState<string | undefined>()
  const [isWithdrawalRequestModalOpen, setWithdrawalRequestModalOpen] = useState<boolean>(false)
  const { t } = useTranslation()
  const container = useContainer()
  const analytics = container.get(Analytics)

  const initialDateFilter = new GetDateRangeFromPeriod().execute(30)
  const BookAccountEnumParam = createEnumParam(bookAccounts)

  const [perPage, setPerPage] = useState('25')

  const [filters, setFilters] = useQueryParams({
    accountType: withDefault(BookAccountEnumParam, 'available'),
    startDate: withDefault(StringParam, initialDateFilter.from),
    endDate: withDefault(StringParam, initialDateFilter.to),
    transactionSources: withDefault(ArrayParam, []),
    limit: withDefault(StringParam, perPage),
    tab: withDefault(StringParam, 'available'),
  })

  const { data: balance, isLoading: loadingBalance, refetch: refetchBalance } = useGetBalance()
  const {
    data: movements,
    isLoading: loadingMovements,
    fetchNextPage,
    refetch: refetchMovements,
  } = useInfiniteGetMovements(perPage)

  useEffect(() => {
    setFilters((value) => ({
      ...value,
      limit: perPage,
    }))
  }, [perPage])

  const toggleWithdrawRequestModal = () => {
    analytics.track(AnalyticsEvent.WALLET.REQUEST_WITHDRAWAL_POPUP_CLICKED)
    setWithdrawalRequestModalOpen(!isWithdrawalRequestModalOpen)
  }

  const onMovementsClick = (id: string, type: 'withdrawal' | 'invoice') => {
    analytics.track(AnalyticsEvent.WALLET.MOVEMENT_CLICKED, { transactionType: type, externalId: id })
    if (type === 'withdrawal') {
      setWithdrawalId(id)
    } else if (type === 'invoice') {
      setInvoiceId(id)
    }
  }

  const onWithdrawalRequest = () => {
    refetchBalance()
    refetchMovements()
  }

  useEffect(() => setFilters(filters), [])

  useEffect(() => analytics.track(AnalyticsEvent.WALLET.VIEWED), [])

  const handleTabSelect = (value: string) => {
    setFilters({ tab: value })
    if (bookAccounts.includes(value as BookAccount)) {
      if (value === 'contested') {
        setFilters({ transactionSources: [], accountType: value as BookAccount })
      } else if (value === 'available') {
        setFilters({ transactionSources: [], accountType: value as BookAccount })
      } else if (value === 'receivable') {
        setFilters({ transactionSources: [], accountType: value as BookAccount })
      } else if (value === 'reserved') {
        setFilters({ transactionSources: [], accountType: value as BookAccount })
      }
    }
  }

  return (
    <div className="space-y-6">
      <h1 className="text-3xl font-semibold leading-tight">{t('title')}</h1>

      <Balances data={balance} isFetching={loadingBalance} onWithdrawRequest={toggleWithdrawRequestModal} />

      <Tabs value={filters.tab} onValueChange={handleTabSelect}>
        <div className="flex flex-row overflow-x-auto overflow-y-hidden">
          <TabsList variant="underlined">
            <TabsTrigger variant="underlined" value="available" data-testid="available-tab">
              {t('filters.bookAccounts.available')}
            </TabsTrigger>
            <TabsTrigger variant="underlined" value="receivable" data-testid="receivable-tab">
              {t('filters.bookAccounts.receivable')}
            </TabsTrigger>
            <TabsTrigger variant="underlined" value="contested" data-testid="contested-tab">
              {t('filters.bookAccounts.contested')}
            </TabsTrigger>
            <TabsTrigger variant="underlined" value="reserved" data-testid="reserved-tab">
              {t('filters.bookAccounts.reserved')}
            </TabsTrigger>
            <TabsTrigger variant="underlined" value="ratesAndTimelines" data-testid="ratesAndTimelines-tab">
              {t('filters.bookAccounts.ratesAndTimelines')}
            </TabsTrigger>
            <TabsTrigger variant="underlined" value="bankAccount" data-testid="bankAccount-tab">
              {t('filters.bookAccounts.bankAccount')}
            </TabsTrigger>
          </TabsList>
        </div>

        <TabsContent value="available">
          <MovementsGrid
            data={movements}
            onInvoiceClick={(invoiceId) => onMovementsClick(invoiceId, 'invoice')}
            onWithdrawalClick={(withdrawalId) => onMovementsClick(withdrawalId, 'withdrawal')}
            isLoading={loadingMovements}
            fetchNextPage={fetchNextPage}
            perPage={perPage}
            setPerPage={setPerPage}
            accountType="available"
            startBalance={balance?.availableInCents ?? 0}
            hideTotal={filters.transactionSources.length > 0}
            filters={filters}
            setFilters={setFilters}
          />
        </TabsContent>
        <TabsContent value="receivable">
          <MovementsGrid
            data={movements}
            onInvoiceClick={(invoiceId) => onMovementsClick(invoiceId, 'invoice')}
            onWithdrawalClick={(withdrawalId) => onMovementsClick(withdrawalId, 'withdrawal')}
            isLoading={loadingMovements}
            fetchNextPage={fetchNextPage}
            perPage={perPage}
            setPerPage={setPerPage}
            accountType="receivable"
            startBalance={balance?.receivableInCents ?? 0}
            hideTotal={filters.transactionSources.length > 0}
            filters={filters}
            setFilters={setFilters}
          />
        </TabsContent>
        <TabsContent value="contested">
          <MovementsGrid
            data={movements}
            onInvoiceClick={(invoiceId) => onMovementsClick(invoiceId, 'invoice')}
            isLoading={loadingMovements}
            fetchNextPage={fetchNextPage}
            perPage={perPage}
            setPerPage={setPerPage}
            accountType="contested"
            startBalance={balance?.contestedInCents ?? 0}
            hideTotal={filters.transactionSources.length > 0}
            filters={filters}
            setFilters={setFilters}
          />
        </TabsContent>
        <TabsContent value="reserved">
          <MovementsGrid
            data={movements}
            onInvoiceClick={(invoiceId) => onMovementsClick(invoiceId, 'invoice')}
            isLoading={loadingMovements}
            fetchNextPage={fetchNextPage}
            perPage={perPage}
            setPerPage={setPerPage}
            accountType="reserved"
            startBalance={balance?.reservedInCents ?? 0}
            hideTotal={filters.transactionSources.length > 0}
            filters={filters}
            setFilters={setFilters}
          />
        </TabsContent>
        <TabsContent value="bankAccount">
          <AccountInfo />
        </TabsContent>
        <TabsContent value="ratesAndTimelines">
          <RatesAndTimelines />
        </TabsContent>
      </Tabs>

      <CreatorInvoiceModal key={invoiceId} invoiceId={invoiceId} onClose={() => setInvoiceId(undefined)} />

      <WithdrawalDetailsModal withdrawalId={withdrawalId} onClose={() => setWithdrawalId(undefined)} />

      <WithdrawalRequestModal
        availableBalanceInCents={balance?.availableInCents ?? 0}
        open={isWithdrawalRequestModalOpen}
        onClose={() => setWithdrawalRequestModalOpen(false)}
        onRequest={onWithdrawalRequest}
      />
    </div>
  )
})
