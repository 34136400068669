import { AssetType } from '@hub-la/fe-asset'
import { Post } from '@hub-la/fe-post'

export class GetThumbnailImage {
  public execute(post: Post | null | undefined): string | null {
    if (post === null || post === undefined) {
      return null
    }

    // has custom cover
    if (post.customCover) {
      return post.customCover.url
    }

    if (post.cover) {
      if (post.cover.type === AssetType.video || post.cover.type === AssetType.application) {
        return post.cover.metadata?.thumbnailUrl ?? null
      } else {
        return post.cover.url
      }
    }

    return null
  }
}
