import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { GetBalanceOutput } from '../domain/dtos/get-balance-output'
import { GeneralError } from '../domain/errors/general'
import { Envs } from '../envs'

export class GetBalance {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(): Promise<GetBalanceOutput> {
    const response = await this.httpClient.request({
      method: HttpMethod.GET,
      url: `${Envs.BFF_WEB_URL}/financial-statement/balance`,
    })

    if (response.statusCode === HttpStatusCode.OK) {
      return response.data
    }

    throw new GeneralError()
  }
}
