import moment from 'moment'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'

import { Alert, AlertDescription, Button, Separator } from '@hub-la/shadcn'

import { ArrowLeft } from 'lucide-react'

import { PlanType } from '../../../domain/enums/plan-type'
import { SubscriptionStatus } from '../../../domain/enums/subscription-status'
import { SubscriptionType } from '../../../domain/enums/subscription-type'
import { useDisableAutorenew } from '../../hooks/use-disable-autorenew'
import { useGetSubscription } from '../../hooks/use-get-subscription'
import { Header } from './components/header'
import { InvoiceTable } from './components/invoice-table'
import { PaymentDetails } from './components/payment-details'
import { PendingInvoiceCard } from './components/pending-invoice-card'
import { PlanDetails } from './components/plan-details'
import Loading from './loading'

const UserSubscriptionDetail: React.FC = () => {
  const { subscriptionId } = useParams<{ subscriptionId: string }>()
  const { data: subscription, error, isFetching } = useGetSubscription(subscriptionId)
  const { mutateAsync: disableAutorenew, isLoading } = useDisableAutorenew()
  const { t } = useTranslation()
  const history = useHistory()

  if (isFetching && !subscription) {
    return <Loading />
  }

  const showPlanDetail = subscription?.type === SubscriptionType.TYPE_RECURRING
  const showPaymentDetail = subscription?.type !== SubscriptionType.TYPE_FREE
  const showTable = subscription?.type !== SubscriptionType.TYPE_FREE
  const canCancel =
    subscription?.status === SubscriptionStatus.ACTIVE &&
    [SubscriptionType.TYPE_GROUPED, SubscriptionType.TYPE_RECURRING].includes(subscription?.type) &&
    !subscription.isSoftPIDEnabled

  /** This is a workaround for Underblock and Raquel Vieira that will try to manage the subscriptions by their own */
  const isCancelNotEnabledForCreator = [
    'Business-Kqv1ZiWeAddjYshOer0Qr8wF2Rp1',
    'Business-8tv1MGwWDeYKdOIOzeZ47lKWA5l1',
    'Business-qIkxHWuTnKaW5AbLrmA8NT5toHq2',
  ].includes(subscription?.seller?.id ?? '')

  return (
    <div className="flex flex-col container my-8 px-4 md:px-64">
      <div className="w-full flex flex-col gap-4 pt-0 md:pt-12 pb-12">
        <Button variant="secondary" onClick={() => history.goBack()} className="w-min gap-2">
          <ArrowLeft className="h-4 w-4" />
          {t('back')}
        </Button>
      </div>

      <div className="grid gap-4 md:grid-cols-2">
        <PendingInvoiceCard
          subscriptionId={subscription?.id}
          cancelEta={moment(new Date()).add(subscription?.credits, 'day').toISOString()}
          status={subscription?.status}
        />

        <div className="col-span-full">
          <Header
            subscription={{
              id: subscription?.id ?? '',
              status: subscription?.status ?? SubscriptionStatus.ACTIVE,
              type: subscription?.type ?? SubscriptionType.TYPE_RECURRING,
              nextDueAt: subscription?.nextDueAt ?? '',
              canceledAt: subscription?.canceledAt ?? '',
              createdAt: subscription?.createdAt ?? '',
              inactivatedAt: subscription?.inactivatedAt ?? '',
              credits: subscription?.credits ?? 0,
            }}
            product={{
              id: subscription?.items.at(0)?.offerId ?? '',
              name: subscription?.items.at(0)?.productName ?? '',
              picture: subscription?.items.at(0)?.picture,
            }}
          />
        </div>

        {showPlanDetail ? (
          <div>
            <PlanDetails
              planType={subscription?.planType ?? PlanType.MONTHLY}
              subscriptionId={subscription?.id ?? ''}
              status={subscription?.status ?? SubscriptionStatus.INACTIVE}
            />
          </div>
        ) : null}

        {showPaymentDetail ? (
          <div>
            <PaymentDetails
              paymentMethod={subscription?.paymentMethod}
              cardDetails={subscription?.cardDetails}
              subscriptionId={subscription?.id ?? ''}
              offerId={subscription?.items.at(0)?.offerId ?? ''}
            />
          </div>
        ) : null}
      </div>

      {showTable ? <InvoiceTable offerId={subscription?.items.at(0)?.offerId} /> : null}

      {canCancel && !isCancelNotEnabledForCreator ? (
        <div className="pt-6 space-y-6">
          <Separator />

          <div className="space-y-3 w-full">
            <Button
              variant="destructive"
              className="w-full"
              onClick={() => disableAutorenew(subscription?.id)}
              disabled={isLoading}
            >
              {t('actions.cancel')}
            </Button>
            <p className="text-sm text-muted-foreground">
              <Trans
                i18nKey="cancelSubscriptionHelper"
                values={{
                  nextDueAt: moment(subscription?.nextDueAt ?? '').format('D MMM. YYYY'),
                }}
                components={[<strong />]}
              />
            </p>
          </div>
        </div>
      ) : null}
      {error ? (
        <Alert variant="destructive">
          <AlertDescription>{t((error as Error)?.message) ?? ''}</AlertDescription>
        </Alert>
      ) : null}
    </div>
  )
}

export default UserSubscriptionDetail
