import { pdfjs } from 'react-pdf'

export class GetPdfPages {
  public async execute(file: File): Promise<number> {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

    try {
      const document = await pdfjs.getDocument(URL.createObjectURL(file)).promise
      return document.numPages
    } catch (error) {
      return 0
    }
  }
}
