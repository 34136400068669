import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useQuery } from '@tanstack/react-query'
import { GetPendingInvitesInput } from '../../../domain/dtos/get-pending-invites-input'
import { GetPendingInvitesOutput } from '../../../domain/dtos/get-pending-invites-output'
import { QueryKey } from '../../../domain/enums/query-key'
import { GetPendingInvites } from '../../../usecases/members/get-pending-invites'

export const useGetPendingInvites = (input: GetPendingInvitesInput) => {
  const container = useContainer()

  return useQuery<GetPendingInvitesOutput>(
    [QueryKey.getPendingInvites, input.pageSize, input.page, input.search, input.productId],
    ({ signal }) => new GetPendingInvites(container.get(HttpClient)).execute(input, signal),
    {
      retry: false,
      retryOnMount: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    },
  )
}
