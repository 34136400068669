import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { QueryKey } from '../../../domain/enums/query-key'
import { CreateShortLink } from '../../../usecases/shortlinks/create-short-link'
import { CreateShortLinkValues } from '../../pages/shortlinks/components/create-edit-shortlink-modal'

export const useCreateShortLink = (productId: string) => {
  const container = useContainer()
  const queryClient = useQueryClient()

  return useMutation(
    [QueryKey.postCreateShortLink],
    (input: CreateShortLinkValues) => new CreateShortLink(container.get(HttpClient)).execute(productId, input),
    {
      retry: false,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [QueryKey.getShortLinks] })
      },
    },
  )
}
