import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation } from '@tanstack/react-query'
import { QueryKey } from '../../domain/dtos/query-keys'
import { RecoverPasswordInput } from '../../domain/dtos/recover-password-input'
import { PostRecoverPassword } from '../../usecases/post-recover-password'

export const usePostRecoverPassword = () => {
  const container = useContainer()

  return useMutation(
    [QueryKey.postRecoverPassword],
    (input: RecoverPasswordInput) => new PostRecoverPassword(container.get(HttpClient)).execute(input),
    {
      retry: false,
    },
  )
}
