import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { QueryKey } from '@hub-la/fe-post'
import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { DuplicateSection } from '../../usecases/duplicate-section'

type DuplicateSectionPayload = {
  sectionId: string
  productId: string
}

type DuplicateSectionResult = unknown

export const useDuplicateSection = (
  options?: Omit<UseMutationOptions<DuplicateSectionResult, Error, DuplicateSectionPayload>, 'mutationFn'>,
) => {
  const container = useContainer()

  return useMutation<DuplicateSectionResult, Error, DuplicateSectionPayload>(
    [QueryKey.duplicateSection],
    (payload: DuplicateSectionPayload) => new DuplicateSection(container.get(HttpClient)).execute(payload),
    options,
  )
}
