import { Button } from '@hub-la/shadcn'
import { useTranslation } from 'react-i18next'
import { IntegrationProvider } from '../../../domain/dtos/integration-provider'
import { useCreateIntegration } from '../../hooks/use-create-integration'

type WebhookCardProps = {
  provider: IntegrationProvider
  integrationId?: string
  loading: boolean
  refetch: () => void
  setTab: (name: string) => void
}

export const WebhookCardActions = ({ provider, integrationId, loading, refetch, setTab }: WebhookCardProps) => {
  const { t } = useTranslation()

  const { isLoading, mutateAsync: createIntegration } = useCreateIntegration()

  const handleCreateIntegration = async () => {
    await createIntegration({ provider })
    refetch()
  }

  return (
    <div className="w-full flex flex-col items-center justify-between gap-2">
      {!integrationId ? (
        <Button className="w-full" disabled={loading || isLoading} onClick={async () => handleCreateIntegration()}>
          {t(`buttons.activeIntegration`)}
        </Button>
      ) : (
        <>
          <Button
            variant="outline"
            className="w-full"
            disabled={loading || isLoading}
            onClick={() => setTab('authentication')}
          >
            {t(`buttons.authentication`)}
          </Button>

          <Button
            variant="outline"
            className="w-full"
            disabled={loading || isLoading}
            onClick={() => setTab('settings')}
          >
            {t(`buttons.settings`)}
          </Button>
        </>
      )}
    </div>
  )
}
