import { Button } from '@hub-la/shadcn'
import { Trash2 } from 'lucide-react'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ConfirmDeletePartnerModal } from './confirm-delete-partner'

type Props = {
  receiverId: string
  offerId?: string
  onChange?: () => void
}

export const MyPartnersActions: React.FC<Props> = ({ receiverId, offerId, onChange }) => {
  const [open, setOpen] = useState<boolean>(false)
  const history = useHistory()
  const handleEdit = () => {
    history.push(`/co-production/${offerId}/${receiverId}`)
  }

  return (
    <div className="flex items-center gap-2">
      <Button variant="outline" size="sm" onClick={handleEdit}>
        Editar
      </Button>

      <Button variant="ghost" size="icon" onClick={() => setOpen(true)}>
        <Trash2 className="h-4 w-4" />
      </Button>

      <ConfirmDeletePartnerModal
        receiverId={receiverId}
        open={open}
        onClose={() => setOpen(false)}
        onSuccess={onChange}
      />
    </div>
  )
}
