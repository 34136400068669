import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '../../domain/enums/query-key'
import { GetGroup } from '../../usecases/get-group'

export const useGetGroup = (groupId: string) => {
  const container = useContainer()

  return useQuery([QueryKey.getGroup, groupId], () => new GetGroup(container.get(HttpClient)).execute(groupId), {
    retry: 3,
    enabled: !!groupId,
    refetchOnWindowFocus: false,
  })
}
