import { Preferences } from './components/preferences/preferences'
import { Customization } from './components/customization/customization'
import { Options } from './components/options/options'

const CheckoutContent = () => {
  return (
    <>
      <Preferences />
      <Customization />
      <Options />
    </>
  )
}

export { CheckoutContent }
