import { Card, CardContent, cn, Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@hub-la/shadcn'
import { InfoIcon, RefreshCcw } from 'lucide-react'
import React from 'react'
import { Money } from '../../../../domain/vos/money'

type BalanceTileProps = React.HTMLAttributes<HTMLDivElement> & {
  cta?: React.ReactNode
  label: string
  tooltip: string
  valueInCents: number
  variant?: 'active-main' | 'neutral' | 'secondary'
  transferableInCents?: number
  transferableLabel?: string
  transferableTooltip?: string
}

export const BalanceTile: React.FC<BalanceTileProps> = ({
  cta,
  label,
  valueInCents,
  tooltip,
  variant,
  className,
  transferableInCents,
  transferableLabel,
  transferableTooltip,
  ...rest
}) => {
  const isActiveMain = variant === 'active-main'
  const isNeutral = variant === 'neutral'
  const isSecondary = variant === 'secondary'

  return (
    <Card className={cn('flex flex-col shadow-none justify-between h-full py-5 px-6', 'text', '', className)} {...rest}>
      <CardContent className="p-0 flex flex-col justify-between h-full space-y-4">
        <div className="space-y-1">
          <div className="flex items-center space-x-1">
            <p className={cn('text-sm', 'text')}>{label}</p>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <InfoIcon className={cn('w-3 h-3 text-muted-foreground', 'text')} />
                </TooltipTrigger>
                <TooltipContent>{tooltip}</TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
          <p
            className={cn('font-medium', {
              'text-3xl': isActiveMain,
              'text-xl': isNeutral,
              'text-lg': isSecondary,
            })}
          >
            {Money.build(valueInCents / 100).getValue()}
          </p>
          {(transferableInCents ?? 0) > 0 && transferableLabel && transferableTooltip && (
            <div className="flex items-center space-x-1 mt-2">
              <RefreshCcw className="w-3 h-3 mr-0.5 text-blue-700" />
              <p className={cn('text-sm', 'text')}>{transferableLabel}</p>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <InfoIcon className={cn('w-3 h-3 text-muted-foreground', 'text')} />
                  </TooltipTrigger>
                  <TooltipContent>{transferableTooltip}</TooltipContent>
                </Tooltip>
              </TooltipProvider>
              <p className={cn('text-sm font-medium', 'text')}>
                {Money.build((transferableInCents ?? 0) / 100).getValue()}
              </p>
            </div>
          )}
        </div>
        {cta}
      </CardContent>
    </Card>
  )
}
