export const pt = {
  createCollaborator: {
    title: 'Adicionar colaborador',
    description:
      'Adicione as informações do colaborador e defina suas permissões dentro da plataforma. Só um detalhe, o colaborador já precisa ter conta na Hubla.',
  },
  collaboratorForm: {
    nameLabel: 'Nome',
    emailLabel: 'E-mail',
    subpermissionsDetails: 'Detalhes',
    permissionsTitle: 'Permissões',
    permissionsHelpLink: 'Entenda os níveis de permissões disponíveis',
    submitButton: 'Adicionar colaborador',
    updateButton: 'Atualizar colaborador',
  },
  editCollaborator: {
    title: 'Editar colaborador',
    description: 'Edite as informações do colaborador e defina suas permissões dentro da plataforma.',
    success: {
      title: 'Sucesso',
      description: 'Permissões do colaborador foram atualizadas com sucesso.',
    },
  },
  deleteCollaboratorModal: {
    title: 'Remover {{collaboratorName}} como colaborador?',
    description: 'Tem certeza que deseja remover? Essa ação não pode ser desfeita.',
    buttons: {
      cancel: 'Cancelar',
      confirm: 'Remover',
    },
  },
  collaboratorsList: {
    emptyState: {
      title: 'Crie seu time de colaboradores.',
      description: 'Adicione seu primeiro colaborador e defina suas permissões.',
      button: 'Adicionar colaborador',
    },
    table: {
      head: {
        name: 'Colaborador',
        email: 'E-mail',
        permissions: 'Permissões',
        actions: '',
      },
      actions: {
        edit: 'Editar colaborador',
        delete: 'Remover colaborador',
      },
      button: 'Adicionar colaborador',
      empty: "Você ainda não tem colaboradores. Clique em 'Adicionar colaborador' para começar.",
    },
    title: 'Gestão de colaboradores',
    description: 'Adicione colaboradores e defina suas permissões dentro da plataforma.',
  },
  selectAccountRoleplay: {
    title: 'Boas vindas, {{firstName}}',
    helpText: 'Entenda a diferença de permissões de suas contas',
    yourAccount: 'Sua conta',
    accountsWithAccess: 'Acesse como colaborador',
  },
  roleplayModeAlert: {
    title: 'Visualizando a plataforma no modo colaborador.',
    button: 'Acessar outra conta',
  },
  permissions: {
    home: 'Início',
    home_abandoned_checkout: 'Carrinho abandonado',
    sales: 'Vendas',
    sales_total_net: 'Total líquido',
    sales_abandoned_checkout: 'Vendas abandonadas',
    sales_refunds_chargebacks: 'Reembolsos e contestações',
    sales_chart: 'Gráfico de vendas, renovações e reembolsos',
    sales_invoices_table: 'Tabela de faturas',
    products: 'Produtos',
    subscriptions: 'Assinaturas',
    subscriptions_deactivate: 'Desativar assinaturas',
    subscriptions_add_daily_credits: 'Adicionar dias de acesso na assinatura',
    reports: 'Relatórios',
    members: 'Membros',
    clients: 'Clientes',
    affiliates: 'Afiliados',
    coupons: 'Cupons',
    refunds: 'Reembolsos',
    financial: 'Financeiro',
    integrations: 'Integrações',
    products_manage_co_production: 'Gerenciar co-produtores',
    products_manage_members: 'Gerenciar membros',
    products_manage_post_comments: 'Gerenciar comentários de conteúdos',
    wallet: 'Carteira',
  },
  permissionsDescription: {
    home: 'Receita, vendas, carrinhos abandonados, taxa de reembolso, aprovação de cartão, conversão de Pix e ticket médio, tudo em um só lugar.',
    sales:
      'Visão geral de todas as faturas (pagas, pendentes ou reembolsadas) renovações, processar reembolsos, exportar relatório.',
    products:
      'Criação e gestão dos seus produtos. Configurações e ferramentas, acesso aos materiais da área de membros, personalização, checkout, convite e gestão de coprodução.',
    reports: 'Principais dados do negócio referente a crescimento, retenção, evolução de vendas e receita bruta.',
    members: 'Acompanhamento da entrada e saída de membros em seus produtos.',
    clients:
      'Centralizadas todas as informações de seus clientes. Dados cadastrais, de assinatura, de engajamento no grupo e conteúdos.',
    affiliates: 'Informações de pessoas afiliadas aos seus produtos e quantidade de vendas.',
    coupons: 'Criação, consulta e administração de cupons de descontos.',
    refunds: 'Consulta, aceite ou recusa das solicitações de reembolso de seus clientes.',
    wallet: 'Consulta ao saldo da conta, valores a receber, extrato de lançamentos e solicitação de saque.',
    integrations: 'Painel de configurações das integrações/automações disponíveis com nossa plataforma.',
  },
  errors: {
    accountUserNotFound: 'O usuário não foi encontrado. É necessario que o usuário tenha uma conta na Hubla.',
    emailInvalid: 'E-mail inválido',
    emailRequired: 'E-mail é obrigatório',
    permissionsRequired: 'Permissões são obrigatórias',
  },
  selectUserLoginNavigationTarget: {
    title: 'Escolha como acessar',
    creatorNavigationTarget: {
      title: 'Acessar como produtor',
      subtitle: 'Para fazer a gestão dos produtos que você vende',
    },
    userNavigationTarget: {
      title: 'Acessar minhas compras',
      subtitle: 'Para acessar um produto que comprou',
    },
    checkboxDontAskAgain: 'Não me perguntar novamente',
  },
}
