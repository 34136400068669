import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useQuery } from '@tanstack/react-query'
import { GetAcceptInviteInput } from '../../../domain/dtos/get-accept-invite-input'
import { GetAcceptInviteOutput } from '../../../domain/dtos/get-accept-invite-output'
import { QueryKey } from '../../../domain/enums/query-key'
import { GetAcceptInvite } from '../../../usecases/members/get-accept-invite'

export const useGetAcceptInvite = (input: GetAcceptInviteInput) => {
  const container = useContainer()

  return useQuery<GetAcceptInviteOutput>(
    [QueryKey.getAcceptInvite, input.inviteId],
    ({ signal }) => new GetAcceptInvite(container.get(HttpClient)).execute(input, signal),
    {
      retry: false,
      retryOnMount: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    },
  )
}
