import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { GeneralError } from '../../domain/errors/general'
import { GetAssociatedCohortsInput } from '../../domain/dtos/member-area/get-associated-cohorts-input'
import { CohortDto } from '@hub-la/sdk-bff-product'
import { Envs } from '../../envs'

export class GetAssociatedCohorts {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(input: GetAssociatedCohortsInput): Promise<CohortDto[]> {
    const { resourceExternalId, productId } = input
    const response = await this.httpClient.request({
      method: HttpMethod.GET,
      url: `${Envs.BFF_PRODUCT_URL}/resources/get-associated-cohorts/${resourceExternalId}/${productId}`,
    })

    if (response.statusCode === HttpStatusCode.OK) {
      return (Array.isArray(response.data) && response.data.length ? response.data : []) as CohortDto[]
    }

    throw new GeneralError()
  }
}
