import { Alert, AlertDescription } from '@hub-la/shadcn'
import isEmpty from 'lodash/isEmpty'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { SubscriptionStatus } from '../../../domain/enums/subscription-status'
import { useListMemberSubscriptions } from '../../hooks/use-list-member-subscriptions'
import { Subscription, UserDoesNotHaveSubscription } from './components'
import { Autocomplete } from './components/autocomplete'
import { Loading } from './loading'

const UserSubscriptions: React.FC = () => {
  const { data = [], error, isFetching } = useListMemberSubscriptions()
  const { t } = useTranslation()
  const history = useHistory()
  const isDataEmpty = isEmpty(data)
  const canRenderEmptyState = !isFetching && isDataEmpty
  const activeSubscriptions = data.filter((subscription) =>
    [SubscriptionStatus.ACTIVE, SubscriptionStatus.CANCELED].includes(subscription.status),
  )
  const inactiveSubscriptions = data.filter((subscription) =>
    [SubscriptionStatus.INACTIVE, SubscriptionStatus.INCOMPLETE].includes(subscription.status),
  )

  if (isFetching && isDataEmpty) {
    return <Loading />
  }

  return (
    <div className="flex flex-col pt-0 md:pt-12 pb-12 gap-4">
      <h1 className="text-xl sm:text-2xl lg:text-3xl font-semibold">{t('title')}</h1>
      <div className="w-full" style={{ maxWidth: '356px' }}>
        <Autocomplete data={data} loading={isFetching} />
      </div>
      {canRenderEmptyState ? <UserDoesNotHaveSubscription /> : null}

      {!canRenderEmptyState ? (
        <>
          {activeSubscriptions.length > 0 ? (
            <div className="my-6 space-y-4">
              <h2 className="text-lg font-semibold">{t('activeSubscriptions')}</h2>
              <div className="space-y-4">
                {activeSubscriptions.map(
                  (data) =>
                    data && (
                      <Subscription
                        key={data.id}
                        id={data.id}
                        name={data.items.at(0)?.productName ?? ''}
                        picture={data.items.at(0)?.picture}
                        status={data.status}
                        type={data.type}
                        credits={data.credits}
                        nextDueAt={data.nextDueAt}
                        inactivatedAt={data.inactivatedAt}
                      />
                    ),
                )}
              </div>
            </div>
          ) : null}

          {inactiveSubscriptions.length > 0 ? (
            <div className="my-6 space-y-4">
              <h2 className="text-lg font-semibold">{t('inactiveSubscriptions')}</h2>
              <div className="space-y-4">
                {inactiveSubscriptions.map(
                  (data) =>
                    data && (
                      <Subscription
                        key={data.id}
                        id={data.id}
                        name={data.items.at(0)?.productName ?? ''}
                        picture={data.items.at(0)?.picture}
                        status={data.status}
                        type={data.type}
                        credits={data.credits}
                        nextDueAt={data.nextDueAt}
                        inactivatedAt={data.inactivatedAt}
                      />
                    ),
                )}
              </div>
            </div>
          ) : null}
        </>
      ) : null}
      {error ? (
        <Alert variant="destructive" className="mt-4">
          <AlertDescription>{t((error as Error)?.message) ?? ''}</AlertDescription>
        </Alert>
      ) : null}
    </div>
  )
}

export default UserSubscriptions
