import { Tabs, TabsContent, TabsList, TabsTrigger } from '@hub-la/shadcn'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IntegrationProvider } from '../../../domain/dtos/integration-provider'
import { IntegrationAboutTab } from '../integration-about-tab'

type MetaPixelDetailProps = {
  provider: IntegrationProvider
}

export const MetaPixelDetail = ({ provider }: MetaPixelDetailProps) => {
  const { t } = useTranslation()
  const [tab, setTab] = useState('about')

  return (
    <Tabs value={tab}>
      <div className="w-full overflow-x-auto">
        <TabsList variant="underlined">
          <TabsTrigger value="about" variant="underlined">
            {t('tabs.about')}
          </TabsTrigger>
        </TabsList>
      </div>

      <TabsContent value="about">
        <IntegrationAboutTab
          provider={provider}
          loading={false}
          refetch={() => {
            //
          }}
          setTab={setTab}
        />
      </TabsContent>
    </Tabs>
  )
}
