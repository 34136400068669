export const WhenNotifyMembersText = () => (
  <div className="p-3">
    <p>A Hubla irá notificar o seu grupo quando você:</p>

    <ul>
      <li>
        <p>- Publicar uma nova live</p>
      </li>

      <li>
        <p>- Atualizar à data/horário</p>
      </li>

      <li>
        <p>- 10 min antes do início da live</p>
      </li>

      <li>
        <p>- Inicio da live</p>
      </li>

      <li>
        <p>- Adicionar a gravação da live</p>
      </li>
    </ul>
  </div>
)
