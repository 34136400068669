import React from 'react'
import { onlyNumbers } from '@brazilian-utils/brazilian-utils'
import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import NumberFormat from 'react-number-format'
import { Values } from '.'
import { UpgradePaymentMethods } from '../../../../../domain/dtos/payment-method'
import { Input, Label } from '@hub-la/shadcn'

/** CPF is brazilian unique person identifier number. */
export function CPF() {
  const { t } = useTranslation()
  const { values, handleBlur, handleChange } = useFormikContext<Values>()
  const { paymentMethod } = values

  const isCreditCard = paymentMethod === UpgradePaymentMethods.PAYMENT_METHOD_CARD
  const isCNPJ = onlyNumbers(values.document).length > 11

  const getLabel = () => {
    if (isCreditCard) {
      return isCNPJ ? 'CNPJ' : 'changePaymentMethodModal.documentLabelCreditCard'
    }
    return 'changePaymentMethodModal.documentLabel'
  }

  return (
    <div className="space-y-2">
      <Label htmlFor="document">{t(getLabel())}</Label>
      <NumberFormat
        id="document"
        data-testid="document"
        onBlur={handleBlur}
        format={isCNPJ ? '##.###.###/####-##' : '###.###.###-#####'}
        onChange={handleChange}
        type="tel"
        value={values.document}
        customInput={Input}
        className="w-full"
      />
    </div>
  )
}
