import {
  Badge,
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Label,
  Sheet,
  SheetContent,
  Table,
  TableBody,
  TableCell,
  TableEmptyState,
  TableHead,
  TableHeader,
  TableRow,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  useIsMobile,
} from '@hub-la/shadcn'
import { times } from 'lodash'
import { Edit, MoreHorizontal, Trash2 } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MyCollaboratorsOutput } from '../../../domain/dtos/my-collaborators-output'
import { ConfirmDeleteModal } from '../../components/confirm-delete-modal'
import { TableLoading } from '../../components/table-loading'
import { useDeleteCollaborator } from '../../hooks/use-delete-collaborator'
import { useGetMyCollaborators } from '../../hooks/use-get-my-collaborators'
import { EditCollaboratorPage } from './edit'

const MAX_VISIBLE_BADGES = 5

const TableCollaborators = ({
  rows,
  refetch,
  isLoading,
}: {
  rows?: MyCollaboratorsOutput
  refetch: () => void
  isLoading: boolean
}) => {
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const [editModal, setEditModal] = useState<string>()
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    collaboratorId: '',
    collaboratorName: '',
  })
  const { mutateAsync: deleteCollaborator, isLoading: isDeletingCollaborator } = useDeleteCollaborator({
    onSuccess: () => {
      setDeleteModal({ isOpen: false, collaboratorId: '', collaboratorName: '' })
      refetch()
    },
  })

  const headCells = [
    { key: 'name', label: t('collaboratorsList.table.head.name') },
    { key: 'permissions', label: t('collaboratorsList.table.head.permissions') },
    { key: 'actions', label: t('collaboratorsList.table.head.actions') },
  ]

  const canRenderEmptyState = !isLoading && rows?.length === 0

  const renderPermissionBadges = (permissions: string[]) => {
    if (permissions.length <= MAX_VISIBLE_BADGES) {
      return permissions.map((permission) => (
        <Badge key={permission} variant="outline" className="ml-1">
          {t(`permissions.${permission}`)}
        </Badge>
      ))
    }

    const visibleBadges = permissions.slice(0, MAX_VISIBLE_BADGES).map((permission) => (
      <Badge key={permission} variant="outline" className="ml-1">
        {t(`permissions.${permission}`)}
      </Badge>
    ))

    const remainingCount = permissions.length - MAX_VISIBLE_BADGES
    const remainingPermissions = permissions.slice(MAX_VISIBLE_BADGES)

    return (
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger className="cursor-pointer">
            <>
              {visibleBadges}
              <Badge className="ml-1" variant="outline">
                +{remainingCount}
              </Badge>
            </>
          </TooltipTrigger>
          <TooltipContent>
            <div className="flex flex-col gap-1">
              {remainingPermissions.map((permission) => (
                <span key={permission}>{t(`permissions.${permission}`)};</span>
              ))}
            </div>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    )
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="rounded-lg border bg-background overflow-hidden">
        <Table>
          <TableHeader>
            <TableRow>
              {headCells.map((cell) => (
                <TableHead key={cell.key}>{cell.label}</TableHead>
              ))}
            </TableRow>
          </TableHeader>

          <TableBody>
            {isLoading
              ? times(3, (idx) => <TableLoading key={`skeleton-row-${idx}`} />)
              : (rows ?? []).map((row) => (
                  <TableRow key={row?.userId}>
                    <TableCell className="py-3">
                      <div className="flex flex-col ">
                        <Label className="font-semibold">{row?.name}</Label>
                        <p className="text-muted-foreground">{row?.email}</p>
                      </div>
                    </TableCell>

                    <TableCell className="py-3">{renderPermissionBadges(row.permissions)}</TableCell>

                    <TableCell className="py-3">
                      <DropdownMenu>
                        <DropdownMenuTrigger>
                          <Button variant="ghost" size="sm">
                            <MoreHorizontal className="h-4 w-4" />
                          </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent>
                          <DropdownMenuGroup>
                            <DropdownMenuItem onClick={() => setEditModal(row.userId)}>
                              <Edit className="h-4 w-4 mr-2" />

                              {t('collaboratorsList.table.actions.edit')}
                            </DropdownMenuItem>
                            <DropdownMenuItem
                              onClick={() =>
                                setDeleteModal({
                                  isOpen: true,
                                  collaboratorId: row?.userId,
                                  collaboratorName: row?.name,
                                })
                              }
                            >
                              <Trash2 className="h-4 w-4 mr-2" />

                              {t('collaboratorsList.table.actions.delete')}
                            </DropdownMenuItem>
                          </DropdownMenuGroup>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>

        {canRenderEmptyState && <TableEmptyState title={t('collaboratorsList.table.empty')} />}
      </div>

      <Sheet open={!!editModal} onOpenChange={(open) => open === false && setEditModal(undefined)}>
        <SheetContent side={isMobile ? 'bottom' : 'right'} className="mx-auto p-10 h-full lg:max-w-2xl overflow-y-auto">
          <EditCollaboratorPage userId={editModal} onClose={() => setEditModal(undefined)} />
        </SheetContent>
      </Sheet>

      <ConfirmDeleteModal
        isOpen={deleteModal.isOpen}
        onClose={() => setDeleteModal({ isOpen: false, collaboratorId: '', collaboratorName: '' })}
        onConfirm={() => deleteCollaborator(deleteModal.collaboratorId)}
        isDeleting={isDeletingCollaborator}
        collaboratorName={deleteModal.collaboratorName}
      />
    </div>
  )
}

const CollaboratorsPage = () => {
  const { data, isLoading, refetch } = useGetMyCollaborators()

  return <TableCollaborators key="table-collaborators" rows={data ?? []} refetch={refetch} isLoading={isLoading} />
}

export { CollaboratorsPage }
