import { Skeleton } from '@hub-la/shadcn'
import { GripVerticalIcon } from 'lucide-react'

const SectionItemSkeleton = () => {
  const randomBoolean = Math.random() >= 0.5
  const randomSize = Math.floor(Math.random() * 100) + 150

  return (
    <div className="mb-2 border  rounded-lg shadow-none bg-background relative">
      <div className="px-4 py-3 flex justify-between items-center">
        <div className="flex items-center">
          <GripVerticalIcon className="text-muted-foreground mr-2" />
          <div className="flex flex-col sm:flex-row gap-3 sm:items-center">
            <Skeleton
              className="h-6"
              style={{
                width: randomSize,
              }}
            />
            {randomBoolean && <Skeleton className="h-3 w-20" />}
          </div>
        </div>
        <div className="flex items-center">
          <Skeleton className="h-3 w-6 mr-2" />
        </div>
      </div>
      <Skeleton className="h-px w-full" />
      <div className="px-4 py-6">
        <Skeleton className="h-3.5 w-32 mb-2" />
      </div>
    </div>
  )
}

export const SectionListSkeleton = () => {
  return (
    <div className="mt-6 mb-45 flex flex-col gap-2 w-full" data-testid="list">
      <SectionItemSkeleton />
      <SectionItemSkeleton />
      <SectionItemSkeleton />
      <SectionItemSkeleton />
      <SectionItemSkeleton />
    </div>
  )
}
