import { useContainer } from '@hub-la/fe-container'
import { CreateSectionButton, ListPostsProvider } from '@hub-la/fe-list-posts'

export const CreateSectionButtonWrapper = () => {
  const container = useContainer()

  return (
    <ListPostsProvider container={container}>
      <CreateSectionButton />
    </ListPostsProvider>
  )
}
