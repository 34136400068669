import {
  Badge,
  Button,
  CopyClipboardButton,
  Drawer,
  DrawerContent,
  Input,
  Separator,
  TablePagination,
  useMediaQuery,
  useUtility,
} from '@hub-la/shadcn'
import { Check, CircleDashed, LucideMousePointerClick, RefreshCw } from 'lucide-react'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism'
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params'
import { EventDelivery } from '../../domain/dtos/event-delivery'
import { IntegrationEvent } from '../../domain/dtos/integration-event'
import { IntegrationProvider } from '../../domain/dtos/integration-provider'
import { GetDateRangeFromPeriod } from '../../usecases/get-date-range-from-period'
import { GetEventName } from '../../usecases/get-event-name'
import { useGetHistories } from '../hooks/use-get-histories'
import { useGetOffers } from '../hooks/use-get-offers'
import { useRefetch } from '../hooks/use-refetch'
import { useRetryEventHistory } from '../hooks/use-retry-event-history'
import { CommaDelimitedArrayParam } from '../utils/query-string-encoder'
import { Filters } from './filters'
import { TableHistory } from './table-history/table-history'

type Props = {
  integrationId: string
  provider: IntegrationProvider
}

const CodeDisplay: React.FC<{ code: string; language?: string }> = ({ code, language = 'json' }) => {
  const formatCode = (input: string): string => {
    if (language !== 'json') return input

    let parsed: any
    try {
      // Try to parse the input as JSON
      parsed = JSON.parse(input)
    } catch (e) {
      // If parsing fails, try to remove outer quotes and parse again
      try {
        parsed = JSON.parse(input.replace(/^"/, '').replace(/"$/, '').replace(/\\"/g, '"'))
      } catch (e2) {
        // If both attempts fail, return the original input
        return input
      }
    }

    // If parsing succeeded, stringify with proper formatting
    return JSON.stringify(parsed, null, 2)
  }

  const parsed = formatCode(code)

  return (
    <div className="w-full overflow-x-hidden overflow-y-auto rounded-md bg-container-background relative">
      <CopyClipboardButton
        copyContent={parsed}
        tooltipCopiedLabel={'Copiado'}
        tooltipCopyLabel={'Copiar código'}
        className="absolute top-2 right-2"
      />

      <SyntaxHighlighter
        language={language}
        style={darcula}
        customStyle={{ margin: 0, borderRadius: '0.375rem', fontSize: '0.8rem', backgroundColor: 'transparent' }}
      >
        {parsed}
      </SyntaxHighlighter>
    </div>
  )
}

const OpenedHistoryDetails = ({
  history,
  isFetching,
  isLoadingRetry,
  isRetrying,
  handleOnRetryEventHistory,
  renderEventCell,
  renderStatusCodeCell,
}: {
  history: EventDelivery
  isFetching: boolean
  isLoadingRetry: boolean
  isRetrying: boolean
  handleOnRetryEventHistory: (eventDeliveryId: string) => void
  renderEventCell: (event: IntegrationEvent) => string | null
  renderStatusCodeCell: (status?: number | null) => React.ReactNode
}) => {
  return (
    <div className="flex flex-col gap-3">
      <div className="flex flex-row justify-between">
        <div className="flex items-center">
          <div className="p-2 bg-secondary rounded-lg mr-2">
            <LucideMousePointerClick className="w-4 h-4" />
          </div>
          <h3 className="text-lg font-semibold text-foreground">{renderEventCell(history.event)}</h3>
        </div>
        <div className="flex items-center gap-2">
          {history.statusCode ? (
            <div className="flex items-center gap-1.5">
              <div className="bg-green-600  rounded-full w-4 h-4 flex justify-center items-center">
                <Check className="text-primary-foreground w-3 h-3" strokeWidth={3} />
              </div>
              <p className="text-sm font-medium">Enviado</p>
            </div>
          ) : (
            <div className="flex items-center gap-1.5">
              <CircleDashed className="w-4 h-4 text-ring" />
              <p className="text-sm font-medium">Enviando...</p>
            </div>
          )}
          <Button
            variant="outline"
            size="sm"
            onClick={() => {
              if (history && history.id !== null) {
                handleOnRetryEventHistory(history.id)
              }
            }}
            disabled={isFetching || isLoadingRetry || isRetrying}
          >
            {isRetrying ? (
              <>
                <RefreshCw className="mr-2 h-4 w-4 animate-spin" />
                Processando...
              </>
            ) : (
              <>
                <RefreshCw className="mr-2 h-4 w-4" />
                Reenviar evento
              </>
            )}
          </Button>
        </div>
      </div>
      <Separator className="my-2" />
      <div className="flex flex-col gap-3">
        <div className="flex items-center">
          <p className="w-1/4 text-sm font-normal text-muted-foreground">Idempotência</p>
          <p className="flex flex-1 w-full text-sm font-medium">Chave - {history.id}</p>
        </div>
        <div className="flex items-center">
          <p className="w-1/4 text-sm font-normal text-muted-foreground">Horário</p>
          <p className="flex flex-1 w-full text-sm font-medium">
            {moment(history.createdAt).format('DD/MM/YYYY HH:mm:ss')}
          </p>
        </div>
        <div className="flex items-center">
          <p className="w-1/4 text-sm font-normal text-muted-foreground">Evento</p>
          <p className="flex flex-1 w-full text-sm font-medium">
            <Badge variant="outline" className="font-medium font-mono">
              <LucideMousePointerClick className="w-4 h-4 pr-1" />
              {renderEventCell(history.event)}
            </Badge>
          </p>
        </div>
        <div className="flex items-center">
          <p className="w-1/4 text-sm font-normal text-muted-foreground">Produto</p>
          <p className="flex flex-1 w-full text-sm font-medium">{history.product?.name || '-'}</p>
        </div>
        <div className="flex items-center">
          <p className="w-1/4 text-sm font-normal text-muted-foreground">Oferta</p>
          <p className="flex flex-1 w-full text-sm font-medium">{history.offer?.name || '-'}</p>
        </div>
        <div className="flex items-center">
          <p className="w-1/4 text-sm font-normal text-muted-foreground">ID da assinatura</p>
          <p className="flex flex-1 w-full text-sm font-medium">{history.subscriptionId}</p>
        </div>
        <div className="flex items-center">
          <p className="w-1/4 text-sm font-normal text-muted-foreground">ID da fatura</p>
          <p className="flex flex-1 w-full text-sm font-medium">{history.invoiceId}</p>
        </div>
        {history.userId && (
          <div className="flex items-center">
            <p className="w-1/4 text-sm font-normal text-muted-foreground">Usuário</p>
            <p className="flex flex-1 w-full text-sm font-medium">
              {history.user?.fullName} - {history.userId}
            </p>
          </div>
        )}
        {history.leadId && (
          <div className="flex items-center">
            <p className="w-1/4 text-sm font-normal text-muted-foreground">Lead</p>
            <p className="flex flex-1 w-full text-sm font-medium">{history.leadId}</p>
          </div>
        )}
      </div>
      <Separator className="my-2" />
      {history.statusCode && (
        <>
          <div className="flex flex-col gap-3">
            <div className="flex items-center">
              <p className="w-1/4 text-sm font-normal text-muted-foreground">Envios</p>
              <p className="flex flex-1 w-full text-sm font-medium">{history.retryCount}</p>
            </div>
            <div className="flex items-center">
              <p className="w-1/4 text-sm font-normal text-muted-foreground">Status retorno</p>
              <p className="flex flex-1 w-full text-sm font-medium">{renderStatusCodeCell(history.statusCode)}</p>
            </div>
            <div className="flex items-center">
              <p className="w-1/4 text-sm font-normal text-muted-foreground">Data do envio</p>
              <p className="flex flex-1 w-full text-sm font-medium">
                {moment(history.updatedAt).format('DD/MM/YYYY HH:mm:ss')}
              </p>
            </div>
            {history.url && (
              <div className="flex items-center">
                <p className="w-1/4 text-sm font-normal text-muted-foreground">Endpoint</p>
                <Input
                  className="w-3/4 font-normal text-muted-foreground"
                  type="text"
                  value={`${history.method} - ${history.url}`}
                />
              </div>
            )}
          </div>
          {history.headers && (
            <>
              <Separator className="my-2" />
              <div className="flex flex-col gap-2">
                <p className="text-lg font-semibold text-foreground">Cabeçalho</p>
                <CodeDisplay code={history.headers} language="json" />
              </div>
            </>
          )}
          {history.payload && (
            <>
              <Separator className="my-2" />
              <div className="flex flex-col gap-2">
                <p className="text-lg font-semibold text-foreground">Requisição</p>
                <CodeDisplay code={history.payload} language="json" />
              </div>
            </>
          )}
          {history.response && (
            <>
              <Separator className="my-2" />
              <div className="flex flex-col gap-2">
                <p className="text-lg font-semibold text-foreground">Resposta</p>
                <CodeDisplay code={history.response} language="json" />
              </div>
            </>
          )}
        </>
      )}
    </div>
  )
}

export const IntegrationHistoryTab = ({ integrationId, provider }: Props) => {
  const { t } = useTranslation()
  const { breakpoints } = useUtility()
  const isMobile = useMediaQuery(breakpoints.down('lg'))
  const tableRef = useRef<HTMLDivElement | null>(null)
  const [tableHeight, setTableHeight] = useState<number>(0)

  const initialDateFilter = new GetDateRangeFromPeriod().execute()

  const [openedHistory, setOpenedHistory] = useState<{ isOpen: boolean; history: EventDelivery | null }>({
    isOpen: false,
    history: null,
  })

  const [filters, setFilters] = useQueryParams({
    // search: withDefault(StringParam, ''),
    offerIds: withDefault(CommaDelimitedArrayParam, []),
    statuses: withDefault(CommaDelimitedArrayParam, []),
    events: withDefault(CommaDelimitedArrayParam, []),
    startDate: withDefault(StringParam, initialDateFilter.from),
    endDate: withDefault(StringParam, initialDateFilter.to),
    page: withDefault(NumberParam, 1),
    pageSize: withDefault(NumberParam, 25),
  })

  const { data: offers = [], isLoading } = useGetOffers()

  const {
    data: histories = { items: [], total: 0, page: filters.page, pageSize: filters.pageSize, lastPage: 1 },
    isFetching: isFetchingItems,
    isLoading: isLoadingItems,
    refetch,
  } = useGetHistories(
    {
      integrationId,
      offerIds: filters.offerIds as string[],
      dateStart: filters.startDate,
      dateEnd: filters.endDate,
      events: filters.events as IntegrationEvent[],
      page: parseInt(filters.page.toString(), 10),
      pageSize: parseInt(filters.pageSize.toString(), 10),
      // search: filters.search,
    },
    isLoading,
  )

  const { mutateAsync: retryEventHistory, isLoading: isLoadingRetry } = useRetryEventHistory()
  const [isRetrying, setIsRetrying] = useState(false)
  const handleOnRetryEventHistory = async (eventDeliveryId: string) => {
    setIsRetrying(true)
    try {
      await retryEventHistory({
        integrationId,
        historyId: eventDeliveryId,
      })
    } finally {
      setIsRetrying(false)
      refetch()
    }
  }

  const renderEventCell = (event: IntegrationEvent) => {
    const eventName = new GetEventName().execute(event)
    if (!eventName) return null
    return t(eventName)
  }

  const renderStatusCodeCell = (status?: number | null) => {
    if (!status) return <Badge variant="secondary">Em Processamento</Badge>
    if (status >= 0 && status < 400)
      return (
        <Badge
          className="bg-green-500/10 text-green-500 hover:bg-green-100"
          variant="secondary"
        >{`${status} OK`}</Badge>
      )
    if (status >= 400)
      return (
        <Badge className="bg-red-500/10 text-red-500 hover:bg-red-100" variant="secondary">{`${status} ERR`}</Badge>
      )
    return <Badge variant="default">{`${status}`}</Badge>
  }

  useRefetch({ ...filters }, refetch)

  const isFetching = isFetchingItems || isLoadingItems

  useEffect(() => {
    const tableElement = tableRef.current
    if (!tableElement) return

    const resizeObserver = new ResizeObserver(([firstElement]) => {
      if (firstElement.target === tableElement) {
        setTableHeight(firstElement.contentRect.height + 2.5) // 2.5 is border and padding
      }
    })

    resizeObserver.observe(tableElement)

    return () => {
      resizeObserver.unobserve(tableElement)
    }
  }, [tableRef])

  useRefetch({ ...filters }, refetch)

  return (
    <div className="flex flex-col gap-4">
      <Filters
        provider={provider}
        filters={filters}
        setFilters={setFilters}
        offers={offers}
        isLoading={isFetching}
        refetch={refetch}
      />

      <div className="flex flex-col md:flex-row justify-between flex-1 overflow-auto items-stretch">
        <div className="flex flex-col gap-4 flex-1 overflow-auto">
          <div className="flex flex-row">
            <div className="flex-1">
              <TableHistory
                ref={tableRef}
                items={histories.items}
                isFetching={isFetching}
                onHistoryDetail={setOpenedHistory}
                historyId={openedHistory.history?.id}
              />
            </div>

            {isMobile ? (
              <Drawer
                open={openedHistory.isOpen}
                onOpenChange={(open) => !open && setOpenedHistory({ isOpen: false, history: null })}
              >
                <DrawerContent>
                  <div className="overflow-y-auto max-h-[85vh] w-full p-5">
                    {openedHistory.history !== null && (
                      <OpenedHistoryDetails
                        history={openedHistory.history}
                        isFetching={isFetching}
                        isLoadingRetry={isLoadingRetry}
                        isRetrying={isRetrying}
                        handleOnRetryEventHistory={handleOnRetryEventHistory}
                        renderEventCell={renderEventCell}
                        renderStatusCodeCell={renderStatusCodeCell}
                      />
                    )}
                  </div>
                </DrawerContent>
              </Drawer>
            ) : (
              openedHistory.history !== null && (
                <div
                  className="border p-5 bg-background lg:rounded-r-lg flex-1 overflow-y-auto"
                  style={{ maxHeight: tableHeight }}
                >
                  <OpenedHistoryDetails
                    history={openedHistory.history}
                    isFetching={isFetching}
                    isLoadingRetry={isLoadingRetry}
                    isRetrying={isRetrying}
                    handleOnRetryEventHistory={handleOnRetryEventHistory}
                    renderEventCell={renderEventCell}
                    renderStatusCodeCell={renderStatusCodeCell}
                  />
                </div>
              )
            )}
          </div>
          <div className="flex justify-end">
            <TablePagination
              page={filters.page}
              setPage={(page) => setFilters((oldFilters) => ({ ...oldFilters, page }))}
              lastPage={histories.lastPage}
              pageSize={+filters.pageSize}
              setPageSize={(pageSize) => setFilters((oldFilters) => ({ ...oldFilters, pageSize }))}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
