import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useToast } from '@hub-la/shadcn'
import { useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { QueryKey } from '../../domain/enums/query-key.enum'
import { PostOffer } from '../../usecases/post-offer.usecase'
import { OfferSchemaValidationType } from '../validations/offer-validation'

export const usePostOffer = ({ onSuccess, onError }: { onSuccess?: () => void; onError?: (error) => void }) => {
  const { toast } = useToast()
  const { t } = useTranslation()
  const container = useContainer()

  return useMutation(
    [QueryKey.putOffer],
    (input: OfferSchemaValidationType) => new PostOffer(container.get(HttpClient)).execute(input),
    {
      retry: false,
      onSuccess,
      onError: (error: Error) => {
        toast({
          title: t(error.message),
          variant: 'destructive',
        })
        if (onError) {
          onError(error)
        }
      },
    },
  )
}
