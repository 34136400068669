import { ContainerProvider } from '@hub-la/fe-container'
import { QueryClientProvider, useQueryClient } from '@tanstack/react-query'
import { I18nextProvider } from 'react-i18next'
import i18n from '../../i18n'
import { CreateRealtimeSectionsProvider } from '../hooks'
import { CurrentUserProvider } from './current-user-provider'

const PostDetailInit = ({ children, currentUser, container }) => {
  const queryClient = useQueryClient()

  return (
    <ContainerProvider container={container}>
      <I18nextProvider i18n={i18n}>
        <QueryClientProvider client={queryClient}>
          <CurrentUserProvider currentUser={currentUser}>
            <CreateRealtimeSectionsProvider>{children}</CreateRealtimeSectionsProvider>
          </CurrentUserProvider>
        </QueryClientProvider>
      </I18nextProvider>
    </ContainerProvider>
  )
}

export { PostDetailInit }
