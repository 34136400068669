import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { CurrencyField } from '../../../../../components/currency-field'
import { InstallmentsSelect } from '../../../../../components/installments-select'
import { PromotionField } from '../../../../../components/promotion-field'

export const PriceConfiguration = () => {
  const { t } = useTranslation()

  return (
    <div className="pt-6 grid w-full items-center gap-4">
      <Controller
        render={({ field: { name, onChange, value, ...props }, fieldState }) => (
          <CurrencyField
            label={t('offer.pricingTab.pricing.price.label')}
            error={Boolean(fieldState.error?.type)}
            helperText={fieldState.error?.type && t(`fieldErrors.${fieldState.error?.type}`)}
            onChange={onChange}
            value={value}
            name={name}
            {...props}
          />
        )}
        name="pricing.price"
      />
      <Controller
        render={({ field: { name, onChange, value, ...props }, fieldState }) => (
          <PromotionField
            label={t('offer.pricingTab.pricing.promotion.label')}
            error={Boolean(fieldState.error?.type)}
            helperText={fieldState.error?.type && t(`fieldErrors.${fieldState.error?.type}`)}
            onChange={onChange}
            value={value}
            name={name}
            {...props}
          />
        )}
        name="pricing.oldPrice"
      />
      <Controller
        render={({ field: { onChange, value, name }, fieldState }) => (
          <InstallmentsSelect
            label={t('offer.pricingTab.pricing.installments.label')}
            maxInstallment={12}
            error={Boolean(fieldState.error?.type)}
            helperText={fieldState.error?.type && t(`fieldErrors.${fieldState.error?.type}`)}
            onChange={onChange}
            value={value}
            name={name}
          />
        )}
        name="pricing.installments"
      />
    </div>
  )
}
