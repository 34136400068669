import { Tabs, TabsContent, TabsList, TabsTrigger } from '@hub-la/shadcn'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { InvitesTable } from './invites-table/invites-table'
import { ParticipantsTable } from './participants-table/participants-table'

interface ParticipantsPageProps {
  offerId: string
}

export const ParticipantsPage = ({ offerId }: ParticipantsPageProps) => {
  const [actualTab, setActualTab] = useState('0')

  const { t } = useTranslation()

  return (
    <Tabs value={actualTab} onValueChange={setActualTab}>
      <div className="w-full overflow-x-auto">
        <TabsList>
          <TabsTrigger value="0">{t('tabs.participants.tabActive')}</TabsTrigger>
          <TabsTrigger value="1">{t('tabs.participants.tabInvites', { total: 12 })}</TabsTrigger>
        </TabsList>
      </div>

      <TabsContent value="0">
        <ParticipantsTable offerId={offerId} />
      </TabsContent>

      <TabsContent value="1">
        <InvitesTable />
      </TabsContent>
    </Tabs>
  )
}
