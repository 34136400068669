export enum ErrorCode {
  INVITE_ALREADY_PHONE_NUMBER = 'invite::already-phone-number',
  INVITE_EMAIL_MISMATCH = 'invite::email-mismatch',
  INVITE_NOT_FOUND = 'invite::not-found',
  INVITE_INVALID_TYPE = 'invite::invalid-type',
  INVITE_UNSUPPORTED_STATUS = 'invite::unsupported-status',
  USER_USER_WITH_MULTIPLE_ACCOUNTS = 'mod-user::user-with-multiple-accounts',
  SHORTLINK_ALREADY_EXISTS = 'already-exists',
  SHORTLINK_NOT_FOUND = 'not-found',
  SHORTLINK_FORBIDDEN = 'forbidden',
}
