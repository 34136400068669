import {
  Button,
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
  Separator,
} from '@hub-la/shadcn'
import { FormikContextType, FormikProvider } from 'formik'

type Props = {
  title?: string
  subtitle?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: FormikContextType<any>
  children?: React.ReactElement
}

export const Section: React.FC<Props> = ({ title, subtitle, children, formik }) => {
  return (
    <FormikProvider value={formik}>
      <Card className="max-w-2xl">
        {title && (
          <>
            <CardHeader>
              <CardTitle className="text-lg font-semibold">{title && title}</CardTitle>
              <CardDescription> {subtitle && subtitle}</CardDescription>
            </CardHeader>
            <Separator />
          </>
        )}
        <CardContent className="p-6">{children}</CardContent>
        {formik.dirty && (
          <>
            <Separator className="mb-6" />
            <CardFooter>
              <div className="flex justify-end space-x-2">
                <Button variant="outline" disabled={formik.isSubmitting} onClick={() => formik.resetForm()}>
                  Cancelar
                </Button>

                <Button
                  variant="default"
                  disabled={!formik.isValid}
                  loading={formik.isSubmitting}
                  onClick={() => formik.submitForm()}
                >
                  Salvar
                </Button>
              </div>
            </CardFooter>
          </>
        )}
      </Card>
    </FormikProvider>
  )
}
