import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue, Separator } from '@hub-la/shadcn'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MemberType } from '../../domain/enums/member-type'

const options = [MemberType.BUYER, MemberType.FREE]

type SelectSubscriptionTypeProps = {
  onChange: (subscriptionType: MemberType[]) => void
}

const SelectSubscriptionType = ({ onChange }: SelectSubscriptionTypeProps) => {
  const [subscriptionsType, setSubscriptionsType] = useState<string>('')

  const { t } = useTranslation()

  const handleValueChange = (value: string) => {
    if (value === 'clearFilters') {
      setSubscriptionsType('')
      onChange([])
      return
    }
    setSubscriptionsType(value)

    // Convert string value to array of MemberType
    const selectedTypes = value.split(',').filter(Boolean) as MemberType[]
    onChange(selectedTypes)
  }

  const selectedTypes = subscriptionsType.split(',').filter(Boolean) as MemberType[]

  return (
    <div className="min-w-[20px] md:w-auto w-full">
      <Select onValueChange={handleValueChange} value={subscriptionsType}>
        <SelectTrigger className="w-full gap-2">
          <SelectValue placeholder={t('tabs.members.actions.memberType.default')}>
            {selectedTypes.length === 1
              ? t(`tabs.members.actions.memberType.${selectedTypes[0]}`)
              : selectedTypes.length > 1
              ? `${t('tabs.members.actions.memberType.default')} (${selectedTypes.length})`
              : t('tabs.members.actions.memberType.default')}
          </SelectValue>
        </SelectTrigger>

        <SelectContent>
          {options.map((option) => (
            <SelectItem key={option} value={option}>
              <div className="flex items-center">
                <span>{t(`tabs.members.actions.memberType.${option}`)}</span>
              </div>
            </SelectItem>
          ))}

          {selectedTypes.length !== 0 && (
            <>
              <Separator />
              <SelectItem value={'clearFilters'}>Limpar filtros</SelectItem>
            </>
          )}
        </SelectContent>
      </Select>
    </div>
  )
}

export { SelectSubscriptionType }
