import { Tabs, TabsContent, TabsList, TabsTrigger } from '@hub-la/shadcn'
import { memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useGetOffers } from '../../hooks/use-get-offers'
import { Invoices } from '../invoices-table/invoices'
import { SmartInstallments } from '../smart-installments-table/smart-installments'

export const DashboardSales = memo(() => {
  const [tab, setTab] = useState('invoices')
  const { t } = useTranslation()
  const history = useHistory()

  const { data: offers = [], isFetching, isLoading } = useGetOffers()

  const onTabChange = (tab: string) => {
    history.push(history.location.pathname + '?tab=' + tab + '#sales')
    setTab(tab)
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const tab = urlParams.get('tab')
    if (tab) {
      setTab(tab)
    }
  }, [])

  return (
    <div className="flex flex-col gap-2">
      <h1 className="text-3xl leading-tight font-semibold">{t('title')}</h1>
      <Tabs value={tab} onValueChange={onTabChange}>
        <div className="w-full overflow-x-auto overflow-y-hidden border-b">
          <TabsList variant="underlined" className="border-none">
            <TabsTrigger value="invoices" variant="underlined">
              {t('tabs.invoices')}
            </TabsTrigger>

            <TabsTrigger value="smartInstallments" variant="underlined">
              {t('tabs.smartInstallments')}
            </TabsTrigger>
          </TabsList>
        </div>
        <TabsContent className="mt-4" value="invoices">
          <Invoices offers={offers} isFetching={isFetching} isLoading={isLoading} />
        </TabsContent>
        <TabsContent className="mt-4" value="smartInstallments">
          <SmartInstallments offers={offers} isFetching={isFetching} isLoading={isLoading} />
        </TabsContent>
      </Tabs>
    </div>
  )
})
