import { Asset, UploadStatus } from '@hub-la/fe-asset'
import { cn } from '@hub-la/shadcn'
import React from 'react'
import { AttachedProgress } from '../../../domain/dtos/attached-progress'
import { AttachmentButton } from './attachment-button/attachment-button'
import { AttachmentStatus } from './attachment-status/attachment-status'

export interface IPublicationAttachment {
  firstChild: boolean
  asset: Asset
  isAvailable: boolean
  lastChild: boolean
  isLoading: boolean
  attachedProgressStatus: UploadStatus
  attachedProgress: Array<AttachedProgress> | null
  attachedAssets
}

export const PostAttachment: React.FC<IPublicationAttachment> = (props) => {
  const {
    asset,
    firstChild,
    lastChild,
    isAvailable,
    isLoading,
    attachedProgressStatus,
    attachedProgress,
    attachedAssets,
  } = props

  return (
    <div
      className={cn(
        'my-1 p-4 flex flex-col gap-3 justify-between bg-primary/5 rounded-md',
        firstChild ? 'ml-0' : 'ml-1',
      )}
    >
      <p className="text-sm">{asset?.name ?? ''}</p>

      <div className="flex flex-col gap-1">
        <AttachmentStatus
          attachedAssets={attachedAssets}
          attachedProgress={attachedProgress}
          isAvailable={isAvailable}
          status={attachedProgressStatus}
          asset={asset}
        />

        <AttachmentButton
          attachedProgress={attachedProgress}
          isAvailable={isAvailable}
          id={asset.id}
          lastChild={lastChild}
          status={attachedProgressStatus}
          isLoading={isLoading}
        />
      </div>
    </div>
  )
}
