import { boolean, mixed, number, object } from 'yup'

export const transformFreeMemberValidationSchema = () => {
  return object({
    lifetime: boolean().required(),
    days: mixed()
      .nullable()
      .when('lifetime', {
        is: false,
        then: number()
          .typeError('addFreeMembersModal.errors.days.required')
          .required()
          .min(1, 'transformFreeMembersModal.errors.days.min'),
      }),
  })
}
