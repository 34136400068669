import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation } from '@tanstack/react-query'
import { PostSignInByEmailInput } from '../../domain/dtos/post-sign-in-by-email-input'
import { QueryKey } from '../../domain/dtos/query-keys'
import { PostSignInByEmail } from '../../usecases/post-sign-in-by-email'

export const usePostSignInByEmail = ({ onError }) => {
  const container = useContainer()

  return useMutation(
    [QueryKey.postSignInByEmail],
    (input: PostSignInByEmailInput) => new PostSignInByEmail(container.get(HttpClient)).execute(input),
    {
      retry: false,
      onError: () => {
        if (onError) {
          onError()
        }
      },
    },
  )
}
