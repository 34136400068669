import { Check, CircleDashed, X } from 'lucide-react'
import { useTranslation } from 'react-i18next'

type AffiliateStatusStatsProps = {
  status: 'pending' | 'enabled' | 'disabled'
}

const getIcon = (status: 'pending' | 'enabled' | 'disabled') => {
  switch (status) {
    case 'enabled':
      return (
        <div className="bg-green-600 rounded-full w-4 h-4 flex justify-center items-center">
          <Check className="text-primary-foreground w-3 h-3" strokeWidth={3} />
        </div>
      )
    case 'pending':
      return <CircleDashed className="w-4 h-4 text-ring" />
    case 'disabled':
      return (
        <div className="bg-red-700 rounded-full w-4 h-4 flex justify-center items-center">
          <X className="text-primary-foreground w-3 h-3" strokeWidth={3} />
        </div>
      )
    default:
      return <CircleDashed className="w-4 h-4 text-ring" />
  }
}

export const AffiliateStatusStats = ({ status }: AffiliateStatusStatsProps) => {
  const { t } = useTranslation()

  return (
    <div className="flex items-center gap-1.5">
      {getIcon(status)}
      <span>{t(`affiliates.status.${status}`)}</span>
    </div>
  )
}
