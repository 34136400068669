import { TemporaryBusinessResponse } from '@hub-la/fe-get-user'
import { Button, cn } from '@hub-la/shadcn'
import { useFormikContext } from 'formik'
import { Check, Circle } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HasPendency } from '../../usecases/has-pendency'
import { IsStepCompleted } from '../../usecases/is-step-completed'
import { useField } from '../hooks/use-field'
import { useSetInitialStep } from '../hooks/use-set-initial-step'
import { FormSteps, Values } from '../pages/account-verification-modal'

type Props = {
  activeStep: number
  setStep: (step: number) => void
  account?: TemporaryBusinessResponse
}

export const Steps: React.FC<Props> = ({ activeStep, setStep }) => {
  const [isInitialStepSet, setIsInitialStepSet] = useState<boolean>(false)

  const { t } = useTranslation()
  const { isValid } = useField()
  const step = useSetInitialStep(isValid)
  const { values } = useFormikContext<Values>()

  const hasPendency = new HasPendency(values.pendencies)
  const isStepCompleted = new IsStepCompleted(values)

  useEffect(() => {
    if (step !== FormSteps.COMPANY_DATA && !isInitialStepSet && activeStep === FormSteps.COMPANY_DATA) {
      setStep(step)
      setIsInitialStepSet(true)
    }
  }, [step, isInitialStepSet, activeStep, setStep])

  const steps = [
    FormSteps.COMPANY_DATA,
    FormSteps.PERSONAL_INFO,
    FormSteps.WITHDRAW_INFO,
    FormSteps.PUBLIC_INFO,
    FormSteps.VERIFY_IDENTITY,
    FormSteps.REVIEW_AND_SAVE,
  ]

  const mapErrorToStep = {
    [FormSteps.COMPANY_DATA]: false,
    [FormSteps.PERSONAL_INFO]: hasPendency.hasAnyOfPendencies([
      'identity.name',
      'identity.email',
      'identity.phone',
      'identity.document.value',
    ]),
    [FormSteps.PUBLIC_INFO]: false,
    [FormSteps.VERIFY_IDENTITY]: hasPendency.hasAnyOfPendencies([
      'liveness',
      'forense_id',
      'datamatch',
      'ocr',
      'data_bureaus',
      'facematch',
      'facelink',
      'hubla_vencimento_cnh',
      'SERPRO',
      'documentoscopia',
    ]),
    [FormSteps.REVIEW_AND_SAVE]: false,
  }

  return (
    <div className="flex flex-col space-y-4 h-full lg:pr-8">
      <div className="space-y-2">
        {steps.map((stepItem) => {
          const completed = isStepCompleted.execute(stepItem)
          const isActive = activeStep === stepItem
          const hasError = mapErrorToStep[stepItem]
          const isDisabled = false

          return (
            <Button
              key={stepItem}
              variant={isActive ? 'secondary' : 'ghost'}
              className={cn(
                'w-full justify-start',
                isDisabled && 'opacity-50 cursor-not-allowed',
                hasError && 'text-red-500',
              )}
              onClick={() => !isDisabled && setStep(stepItem)}
              disabled={isDisabled}
            >
              <div className="flex items-center space-x-2">
                <div
                  className={cn(
                    'w-6 h-6 rounded-full flex items-center justify-center',
                    completed ? 'bg-green-600' : isActive ? 'border-primary' : 'border-gray-300',
                    hasError && 'bg-red-600',
                  )}
                >
                  {completed ? (
                    <Check className="text-primary-foreground" size={14} />
                  ) : (
                    <Circle className={isActive ? 'text-primary' : 'text-muted-foreground'} size={14} />
                  )}
                </div>
                <span className={cn(isActive && 'font-medium')}>{t(`step.${stepItem}`)}</span>
              </div>
            </Button>
          )
        })}
      </div>
    </div>
  )
}
