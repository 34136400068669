import { AssetStatus, AssetType } from '@hub-la/fe-asset'
import { Post } from '@hub-la/fe-post'
import { cn } from '@hub-la/shadcn'
import { GetThumbnailImage } from '../../usecases/get-thumbnail-image'
import EventDate from './event-date'
import { Fallback } from './fallback'
import Lock from './locked'
import { Pdf } from './pdf'
import { ProgressBar } from './progress-bar'

type Props = {
  width: number | string
  height: number | string
  post: Post | null | undefined
  onClick?: () => void
  hasProgressBar?: boolean
  progress?: number
  eventStartTime?: Date
  lockIcon?: boolean
  lockContent?: boolean
}

/**
 * @deprecated Use `@hub-la/fe-thumbnail` instead
 */
export const Thumbnail = ({
  width,
  height,
  post,
  onClick,
  hasProgressBar = true,
  progress,
  eventStartTime,
  lockIcon,
  lockContent,
}: Props) => {
  const image = new GetThumbnailImage().execute(post)

  const hasCustomCover = !!post?.customCover
  const isPdf = AssetType.application === post?.cover?.type
  const isVideo = AssetType.video === post?.cover?.type
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
  const isProcessing = [AssetStatus.processing, AssetStatus.uploading].includes(post?.cover?.status!)
  const pdf = post?.cover?.url
  const locked = lockIcon || lockContent

  if (!hasCustomCover && isVideo && isProcessing) {
    return (
      <div
        className="flex items-center justify-center relative overflow-hidden rounded-md border border-input"
        style={{ width, height }}
        onClick={onClick}
      >
        <Fallback isVideo={true} />
      </div>
    )
  }

  if (!hasCustomCover && isPdf && !locked) {
    return pdf ? (
      <div
        className="flex items-center justify-center relative overflow-hidden rounded-md border border-input"
        style={{ width, height }}
        onClick={onClick}
        data-testid="thumbnail-pdf"
      >
        {locked && <Lock fullSize={lockContent} />}

        {post?.cover?.metadata?.thumbnailUrl ? (
          <img
            className={cn(
              'object-cover',
              typeof width === 'number' ? `w-[${width}px]` : `w-[${width}]`,
              typeof height === 'number' ? `h-[${height}px]` : `h-[${height}]`,
            )}
            src={post?.cover.metadata.thumbnailUrl}
            alt={post.title}
          />
        ) : (
          <Pdf url={post.cover?.url} width={width} height={height} />
        )}

        {hasProgressBar && !locked && <ProgressBar post={post} progress={progress} />}
      </div>
    ) : (
      <div
        className="flex items-center justify-center relative overflow-hidden rounded-md border border-input"
        style={{ width, height }}
        onClick={onClick}
      >
        {locked && <Lock fullSize={lockContent} />}

        <Fallback />
      </div>
    )
  }

  return image ? (
    <div
      className="flex items-center justify-center relative overflow-hidden rounded-md border border-input"
      style={{ width, height }}
      onClick={onClick}
    >
      {locked && <Lock fullSize={lockContent} />}

      {eventStartTime && !locked && <EventDate date={eventStartTime} />}

      <img
        className={cn(
          'object-cover',
          typeof width === 'number' ? `w-[${width}px]` : `w-[${width}]`,
          typeof height === 'number' ? `h-[${height}px]` : `h-[${height}]`,
        )}
        data-testid="thumbnail-image"
        src={hasCustomCover ? post?.customCover?.url : image}
        alt={post?.title}
      />

      {hasProgressBar && !locked && <ProgressBar post={post} progress={progress} />}
    </div>
  ) : (
    <div
      className="flex items-center justify-center relative overflow-hidden rounded-md border border-input"
      style={{ width, height }}
      onClick={onClick}
    >
      {locked && <Lock fullSize={lockContent} />}

      <Fallback />
    </div>
  )
}
