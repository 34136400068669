import { ShadowIcon } from '@radix-ui/react-icons'
import { Check, CircleDashed, X } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { RefundStatus } from '../../domain/enums/refund-status.enum'

const getStyles = () => {
  return { size: 16, className: 'mr-1', style: { fillOpacity: 0.1 } }
}

const getIcon = (status: RefundStatus) => {
  switch (status) {
    case RefundStatus.CREATED:
    case RefundStatus.PENDING:
      return <CircleDashed className="w-4 h-4 text-ring" />

    case RefundStatus.FAILED:
      return (
        <div className="bg-amber-600  rounded-full w-4 h-4 flex justify-center items-center">
          <X className="text-primary-foreground w-3 h-3" strokeWidth={3} />
        </div>
      )

    case RefundStatus.REJECTED:
      return (
        <div className="bg-red-700  rounded-full w-4 h-4 flex justify-center items-center">
          <X className="text-primary-foreground w-3 h-3" strokeWidth={3} />
        </div>
      )
    case RefundStatus.CANCELED:
      return <X className="w-4 h-4 text-ring" />

    case RefundStatus.PROCESSING:
      return <ShadowIcon className="w-4 h-4" />

    case RefundStatus.ACCEPTED:
      return (
        <div className="bg-green-600  rounded-full w-4 h-4 flex justify-center items-center">
          <Check className="text-primary-foreground w-3 h-3" strokeWidth={3} />
        </div>
      )
  }
}

export const RefundStatusStats = ({ status }: { status: RefundStatus }) => {
  const { t } = useTranslation()

  return (
    <div className="flex items-center gap-2">
      {getIcon(status)}
      {t(`refundStatus.${status}`)}
    </div>
  )
}
