import { useQuery } from '@tanstack/react-query'

import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { QueryKey } from '../../domain/dtos/query-keys'
import { GetWithdrawalDetails } from '../../usecases/get-withdrawal-details'

export const useGetWithdrawalDetails = (withdrawalId: string | undefined) => {
  const container = useContainer()
  return useQuery(
    [QueryKey.getWithdrawalDetails],
    () => new GetWithdrawalDetails(container.get(HttpClient)).execute({ withdrawalId: withdrawalId ?? '' }),
    {
      enabled: !!withdrawalId,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      retry: 3,
    },
  )
}
