import React from 'react'
import { Money } from '../../../../domain/vos/money'

type MovementAmountProps = {
  amount: number
}

/**
 * Formats the movement amount with the corresponding style.
 *  - Green with a positive sign in case of positive amounts
 *  - Red with negative sign in case of negative amounts
 */
export const MovementAmount: React.FC<MovementAmountProps> = ({ amount }) => {
  const getMoneyFromCents = (b: number) => new Money(b / 100).getValue()

  return (
    <span className={`text-sm ${amount >= 0 ? 'text-green-600' : 'text-red-600'}`}>
      {amount >= 0 ? '+' : '-'}
      {getMoneyFromCents(Math.abs(amount))}
    </span>
  )
}
