import { BigTicket, BigTicketProps } from './big'
import { IconTicketProps, TicketIcon } from './icon'

type IconTicketVariantProps = {
  variant: 'icon'
} & IconTicketProps

type BigTicketVariantProps = {
  variant: 'big'
} & BigTicketProps

type TicketProps = IconTicketVariantProps | BigTicketVariantProps

const Ticket = (props: TicketProps) => {
  if (props.variant === 'icon') {
    return (
      <TicketIcon
        name={props.name}
        bgColor={props.bgColor}
        secondaryColor={props.secondaryColor}
        textColor={props.textColor}
        width={props.width}
        height={props.height}
      />
    )
  }

  return (
    <BigTicket
      name={props.name}
      eventName={props.eventName}
      userName={props.userName}
      location={props.location}
      bgColor={props.bgColor}
      secondaryColor={props.secondaryColor}
      textColor={props.textColor}
      startDate={props.startDate}
      endDate={props.endDate}
      eventLogo={props.eventLogo}
      code={props?.code}
    />
  )
}

export { Ticket }
