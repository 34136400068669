import { isValid } from 'creditcard.js'
import { CreditCardType } from './credit-card-type'
export class CreditCardNumber {
  public constructor(private readonly value: string, private readonly type: CreditCardType) {}

  public static build(value: string): CreditCardNumber {
    const number = value.replace(/ /g, '')

    return new CreditCardNumber(number, CreditCardType.build(number))
  }

  public isValid(): boolean {
    return this.type.isValid() && isValid(this.value)
  }

  public getValue() {
    return this.value
  }

  public getBin() {
    return this.value.slice(0, 6)
  }

  public getMasked() {
    const length = this.value.length
    const last4Digits = this.value.slice(length - 4, length)

    return `**** ${last4Digits}`
  }
}
