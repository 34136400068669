/**
 * We use only one field for name. Still, the backend needs to receive the first and last
 * name separately. Here we build the first name as everything up until the last name.
 *
 * Also, we don't need to trim anything because the fullName validation is done at
 * {@link validationSchema}.
 */
export function processUserName(fullName?: string) {
  const splitName = fullName?.trim().split(' ')

  let firstName: string, lastName: string
  if (splitName?.length === 0) {
    firstName = lastName = ''
  } else if (splitName?.length === 1) {
    firstName = splitName[0]
    lastName = ''
  } else {
    firstName = splitName?.slice(0, -1).join(' ') ?? ''
    lastName = splitName?.slice(-1).join(' ') ?? ''
  }

  return { firstName, lastName }
}
