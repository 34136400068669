export enum SmartInstallmentInvoiceEventType {
  CREATED = 'created',
  PAYMENT_PAGE_CREATED = 'payment_page_created',
  PAID = 'paid',
  CANCELED = 'canceled',
  DISPUTED = 'disputed',
  COMPLETED = 'completed',
  INSTALLMENT_OVERDUE = 'installment_overdue',
  OVERDUE = 'overdue',
  EXPIRED = 'expired',
  ERROR = 'error',
  REGULARIZED = 'regularized',
  REFUNDED = 'refunded',
}
