import { Alert, AlertDescription, AlertTitle } from '@hub-la/shadcn'
import { Check, Circle, Key, X } from 'lucide-react'
import React from 'react'

interface ValidationType {
  needValidation: boolean
  isValid: boolean
  message: string
}

const ValidationItem: React.FC<ValidationType> = ({ needValidation, isValid, message }) => {
  const Icon = needValidation ? (isValid ? Check : X) : Circle
  const color = needValidation ? (isValid ? 'text-green-500' : 'text-red-500') : 'text-accent-foreground'

  return (
    <li className={`flex items-center space-x-2 ${color}`}>
      <Icon className={needValidation ? 'w-4 h-4' : 'w-1 h-1'} />
      <span className="text-sm">{message}</span>
    </li>
  )
}

interface PasswordStatusProps {
  passwordValue: string
}

export const PasswordStatus: React.FC<PasswordStatusProps> = ({ passwordValue }) => {
  const needValidation = passwordValue.length > 0

  const hasLowerCase = /[a-z]/.test(passwordValue)
  const hasUpperCase = /[A-Z]/.test(passwordValue)
  const hasNumber = /[0-9]/.test(passwordValue)
  const hasMinLength = passwordValue.length >= 8

  return (
    <Alert>
      <Key className="h-4 w-4" />
      <AlertTitle className="font-bold">Sua senha deve conter:</AlertTitle>
      <AlertDescription>
        <ul className="mt-2 space-y-1">
          <ValidationItem needValidation={needValidation} isValid={hasMinLength} message="8 ou mais caracteres" />
          <ValidationItem
            needValidation={needValidation}
            isValid={hasLowerCase}
            message="No mínimo uma letra minúscula (a-z)"
          />
          <ValidationItem
            needValidation={needValidation}
            isValid={hasUpperCase}
            message="No mínimo uma letra maiúscula (A-Z)"
          />
          <ValidationItem needValidation={needValidation} isValid={hasNumber} message="No mínimo um número (0-9)" />
        </ul>
      </AlertDescription>
    </Alert>
  )
}
