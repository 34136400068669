import { PermissionResource, useRoleplay } from '@hub-la/fe-roleplay'
import { Button, Input } from '@hub-la/shadcn'
import debounce from 'lodash/debounce'
import { Download, Search, UserPlus } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { SelectSubscriptionType } from '../../../components/select-subscription-type'

type ActionsProps = {
  onSearchChange: (value: string) => void
  onSubscriptionTypeChange: (value: string[]) => void
  onAddMember: () => void
  onExportActiveMembers: () => void
}

const Actions = ({ onSearchChange, onSubscriptionTypeChange, onAddMember, onExportActiveMembers }: ActionsProps) => {
  const { t } = useTranslation()
  const { hasPermission } = useRoleplay()

  const handleSearchChange = debounce((value: string) => onSearchChange(value), 500)

  return (
    <div className="flex flex-col space-y-4 md:flex-row md:justify-between md:items-center md:space-y-0">
      <div className="flex flex-col space-y-4 md:flex-row md:space-y-0 md:space-x-2 w-full md:w-auto">
        <div className="relative w-full md:w-[350px]">
          <Search className="absolute w-4 h-4 right-3 top-1/2 transform -translate-y-1/2 text-muted-foreground z-10" />
          <Input
            className="w-full"
            type="text"
            placeholder={t('tabs.members.actions.search.placeholder')}
            onChange={(e) => handleSearchChange(e.target.value)}
          />
        </div>
        <SelectSubscriptionType onChange={onSubscriptionTypeChange} />
      </div>
      <div className="flex flex-col space-y-4 md:flex-row md:space-y-0 md:space-x-2">
        {hasPermission(PermissionResource.PRODUCTS_MANAGE_MEMBERS) && (
          <Button variant="outline" className="w-full md:w-auto" onClick={onAddMember}>
            <UserPlus className="mr-2 h-4 w-4" />
            {t('tabs.members.actions.addMember')}
          </Button>
        )}
        <Button variant="outline" className="w-full md:w-auto" onClick={onExportActiveMembers}>
          <Download className="mr-2 h-4 w-4" />
          {t('tabs.members.actions.export')}
        </Button>
      </div>
    </div>
  )
}

export { Actions }
