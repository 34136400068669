import { PermissionResource, useRoleplay } from '@hub-la/fe-roleplay'
import { Button, Tooltip, TooltipContent, TooltipProvider, TooltipTrigger, useIsMobile } from '@hub-la/shadcn'
import { X } from 'lucide-react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Subscription } from '../../../domain/dtos/subscription'
import { SubscriptionStatus } from '../../../domain/enums/subscription-status'
import { AddDailyCreditsBatchModal } from '../subscription-detail/add-daily-credits-batch-modal'
import { DeactivateSubscriptionBatchModal } from '../subscription-detail/deactivate-subscription-batch-modal'

type Props = {
  selectedItems: Subscription[]
  onCancel: () => void
  onSuccess: () => void
}

export const Actions: React.FC<Props> = (props) => {
  const { selectedItems, onCancel, onSuccess } = props
  const [isCancelSubscriptionOpen, setIsCancelSubscriptionOpen] = useState<boolean>(false)
  const [isAddDailyCreditsOpen, setIsAddDailyCreditsOpen] = useState<boolean>(false)
  const { hasPermission } = useRoleplay()
  const { t } = useTranslation()
  const isMobile = useIsMobile()

  const hasStatus = (status: SubscriptionStatus) => {
    return selectedItems.some((selectedItem) => selectedItem.status === status)
  }

  const subscriptionIds = selectedItems.map((selectedItem) => selectedItem.id)

  const cannotDeactivate =
    hasStatus(SubscriptionStatus.INACTIVE) ||
    hasStatus(SubscriptionStatus.CANCELED) ||
    hasStatus(SubscriptionStatus.INCOMPLETE) ||
    !hasPermission(PermissionResource.SUBSCRIPTIONS_DEACTIVATE)
  const cannotAddDailyCredits =
    hasStatus(SubscriptionStatus.INACTIVE) ||
    hasStatus(SubscriptionStatus.INCOMPLETE) ||
    !hasPermission(PermissionResource.SUBSCRIPTIONS_ADD_DAILY_CREDITS)

  const getDeactivateButtonTooltip = () => {
    if (cannotDeactivate) {
      return t('Você não pode desativar assinaturas que já estão inativas.')
    }
    switch (true) {
      case hasStatus(SubscriptionStatus.INACTIVE):
        return t('actions.deactivateInactives')
      case hasStatus(SubscriptionStatus.ACTIVE):
        return t('actions.deactivateBtnTooltip')
      case hasStatus(SubscriptionStatus.CANCELED):
        return t('actions.deactivateCanceleds')
      case hasStatus(SubscriptionStatus.INCOMPLETE):
        return t('actions.deactivateIncompletes')
      case !hasPermission(PermissionResource.SUBSCRIPTIONS_DEACTIVATE):
        return t('actions.cannotDeactivate')
      default:
        return ''
    }
  }

  const getAddDailyCreditsButtonTooltip = () => {
    if (cannotAddDailyCredits) {
      return t(
        'Você não pode estender a data de expiração de assinaturas que já expiraram ou que nunca foram concluídas.',
      )
    }
    switch (true) {
      case hasStatus(SubscriptionStatus.ACTIVE):
        return t('actions.addBtnTooltip')
      case hasStatus(SubscriptionStatus.CANCELED):
        return t('actions.deactivateCanceleds')
      case hasStatus(SubscriptionStatus.INACTIVE):
        return t('actions.addInactives')
      case hasStatus(SubscriptionStatus.INCOMPLETE):
        return t('actions.addIncompletes')
      case !hasPermission(PermissionResource.SUBSCRIPTIONS_ADD_DAILY_CREDITS):
        return t('actions.cannotAdd')
      default:
        return ''
    }
  }

  if (selectedItems.length === 0) {
    return null
  }

  return (
    <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 bg-background border border-border rounded-lg shadow-xl p-4 z-10">
      <div className="flex flex-col md:flex-row items-center gap-4">
        <div className="flex w-full md:w-auto justify-between items-center">
          <span className="text-sm font-medium">{t('actions.title', { qnt: selectedItems.length })}</span>
          {isMobile && (
            <Button variant="ghost" size="icon" onClick={onCancel}>
              <X className="h-4 w-4" />
            </Button>
          )}
        </div>
        <div className={`flex ${isMobile ? 'flex-col w-full' : 'flex-row'} gap-2`}>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <div>
                  <Button
                    disabled={cannotDeactivate}
                    onClick={() => setIsCancelSubscriptionOpen(true)}
                    variant="outline"
                    size="sm"
                  >
                    <p className="text-destructive">{t('actions.deactivateBtn')}</p>
                  </Button>
                </div>
              </TooltipTrigger>
              <TooltipContent>
                <p>{getDeactivateButtonTooltip()}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <div>
                  <Button
                    disabled={cannotAddDailyCredits}
                    onClick={() => setIsAddDailyCreditsOpen(true)}
                    variant="outline"
                    size="sm"
                  >
                    <p className="text-primary">{t('actions.addBtn')}</p>
                  </Button>
                </div>
              </TooltipTrigger>
              <TooltipContent>
                <p>{getAddDailyCreditsButtonTooltip()}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
        {!isMobile && (
          <Button variant="ghost" size="icon" onClick={onCancel}>
            <X className="h-4 w-4" />
          </Button>
        )}
      </div>
      <DeactivateSubscriptionBatchModal
        subscriptionIds={subscriptionIds}
        open={isCancelSubscriptionOpen}
        onClose={() => setIsCancelSubscriptionOpen(false)}
        onSuccess={onSuccess}
      />
      <AddDailyCreditsBatchModal
        subscriptionIds={subscriptionIds}
        open={isAddDailyCreditsOpen}
        onClose={() => setIsAddDailyCreditsOpen(false)}
        onSuccess={onSuccess}
      />
    </div>
  )
}
