import { useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { CohortForm } from '../../components/cohort-form/cohort-form'
import { useGetListGroups } from '../../hooks/use-get-list-groups'
import { useGetSections } from '../../hooks/use-get-sections'
import { usePostCohort } from '../../hooks/use-post-cohort'
import { CohortSchemaValidationType } from '../../validations/cohort-validation'

const CreateCohortPage = () => {
  const history = useHistory()
  const { productId } = useParams<{ productId: string; cohortId: string }>()
  const { mutate, isLoading } = usePostCohort({
    productId,
    onSuccess: () => history.push(`/edit/${productId}/cohorts`),
  })

  const { data: sections } = useGetSections({
    productId,
  })
  const { data: groups } = useGetListGroups(productId)

  const defaultValues: CohortSchemaValidationType = useMemo(
    () => ({
      name: '',
      sections: sections?.map((section) => section.id) ?? [],
      groups: groups?.map((group) => group.id),
    }),
    [productId, groups, sections],
  )

  return (
    <CohortForm
      productId={productId}
      onSubmit={(data) => mutate(data)}
      isLoading={isLoading}
      defaultValues={defaultValues}
    />
  )
}

export { CreateCohortPage }
