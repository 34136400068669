export const Envs = {
  BFF_WEB_URL: process.env['NX_BFF_WEB_URL'] ?? process.env['REACT_APP_HUB_API'],
  FUNCTIONS_URL: process.env['NX_FUNCTIONS_URL'] ?? process.env['REACT_APP_FUNCTIONS_URL'],
  ENV: process.env['ENVIRONMENT'] ?? process.env['REACT_APP_CUSTOM_NODE_ENV'],

  ATTACHMENT_FILE_MIMETYPE_ALLOWED:
    process.env['NX_FILE_MIMETYPE_ALLOWED'] ?? process.env['REACT_APP_FILE_MIMETYPE_ALLOWED'] ?? '',
  ATTACHMENT_VIDEO_MIMETYPE_ALLOWED:
    process.env['NX_VIDEO_MIMETYPE_ALLOWED'] ?? process.env['REACT_APP_VIDEO_MIMETYPE_ALLOWED'] ?? '',
  ATTACHMENT_IMAGE_MIMETYPE_ALLOWED:
    process.env['NX_IMAGE_MIMETYPE_ALLOWED'] ?? process.env['REACT_APP_IMAGE_MIMETYPE_ALLOWED'] ?? '',
  MUX_STREAM_URL: process.env['MUX_STREAM_URL'] ?? process.env['REACT_APP_MUX_STREAM_URL'] ?? '',
  IMAGINARY_SERVER_URL:
    process.env['NX_IMAGINARY_SERVER_URL'] ??
    process.env['REACT_APP_IMAGINARY_SERVER_URL'] ??
    process.env['NEXT_PUBLIC_IMAGINARY_SERVER_URL'] ??
    '',
  NODE_ENV: process.env['NODE_ENV'],
  BFF_ASSET_URL:
    process.env['NX_BFF_ASSET_URL'] ?? process.env['REACT_APP_BFF_ASSET'] ?? process.env['NEXT_PUBLIC_BFF_ASSET'],
  BFF_MEMBERS_AREA_URL:
    process.env['NX_BFF_MEMBERS_AREA_URL'] ??
    process.env['REACT_APP_BFF_MEMBERS_AREA_URL'] ??
    process.env['NEXT_PUBLIC_BFF_MEMBERS_AREA_URL'],
}
