import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useToast } from '@hub-la/shadcn'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { QueryKey } from '../../../domain/enums/query-key'
import { GetResources } from '../../../usecases/member-area/get-resources'

export const useGetResources = (offerId?: string, hasProductAssociation = true) => {
  const { toast } = useToast()
  const { t } = useTranslation()
  const container = useContainer()

  return useQuery(
    [QueryKey.getResources, offerId, hasProductAssociation],
    () => {
      return new GetResources(container.get(HttpClient)).execute(offerId, hasProductAssociation)
    },
    {
      enabled: !!offerId,
      retry: 3,
      retryOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: 60000,
      onError: (error: Error) => {
        toast({ description: t(error.message), variant: 'destructive' })
      },
    },
  )
}
