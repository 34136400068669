import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '../../domain/enums/query-key'
import { GetGroupResourceDetails } from '../../usecases/get-group-resource-details'

export const useGetGroupResourceDetails = (groupResourceId: string) => {
  const container = useContainer()

  return useQuery(
    [QueryKey.getGroupResourceDetails, groupResourceId],
    () => new GetGroupResourceDetails(container.get(HttpClient)).execute(groupResourceId),
    {
      retry: 3,
      enabled: !!groupResourceId,
      refetchOnWindowFocus: false,
    },
  )
}
