import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { AddDailyCreditsBatchInput } from '../domain/dtos/add-daily-credits-batch-input'
import { GeneralError } from '../domain/errors/general'
import { Envs } from '../envs'

export class AddDailyCreditsBatch {
  public constructor(@inject(HttpClient) private readonly httpClient: HttpClient) {}

  public async execute(data: AddDailyCreditsBatchInput): Promise<void> {
    const response = await this.httpClient.request({
      method: HttpMethod.POST,
      url: this.makeUrl(),
      body: this.makeBody(data),
    })

    if ([HttpStatusCode.OK, HttpStatusCode.CREATED].includes(response.statusCode)) {
      return response.data
    } else {
      /** @todo: Handle backend errors */
      throw new GeneralError()
    }
  }

  private makeBody(data: AddDailyCreditsBatchInput): AddDailyCreditsBatchInput {
    return data
  }

  private makeUrl() {
    return `${Envs.BFF_WEB_URL}/subscriptions/batch/add-daily-credits`
  }
}
