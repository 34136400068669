import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  Separator,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@hub-la/shadcn'
import { useTranslation } from 'react-i18next'
import { InvoicePayerSession } from '../../../domain/dtos/invoice'

type Props = {
  detail: InvoicePayerSession
}

const UtmInfoRow = ({ label, value }) => (
  <div className="flex justify-between items-start mb-4">
    <p className="max-w-[180px] text-sm font-normal text-muted-foreground">{label}</p>

    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <p className="max-w-56 truncate text-sm font-medium text-right">{value}</p>
        </TooltipTrigger>
        <TooltipContent>{value}</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  </div>
)

export const UTMTracking: React.FC<Props> = (props) => {
  const { t } = useTranslation()
  const { utmSource, utmMedium, utmCampaign, utmTerm, utmContent } = props.detail

  return (
    <Card>
      <CardHeader className="py-4 px-4">
        <CardTitle className="text-base font-semibold">{t('invoice.utm.title')}</CardTitle>
      </CardHeader>
      <Separator />
      <CardContent className="p-4 space-y-4">
        <UtmInfoRow label={t('invoice.utm.source')} value={utmSource} />
        <UtmInfoRow label={t('invoice.utm.media')} value={utmMedium} />
        <UtmInfoRow label={t('invoice.utm.campaign')} value={utmCampaign} />
        <UtmInfoRow label={t('invoice.utm.content')} value={utmContent} />
        <UtmInfoRow label={t('invoice.utm.term')} value={utmTerm} />
      </CardContent>
    </Card>
  )
}
