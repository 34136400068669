import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import isNil from 'lodash/isNil'
import { QueryKey } from '../../domain/enums/query-key'
import { UpgradeStatus } from '../../domain/enums/upgrade-status'
import { GetUpgradeState } from '../../usecases/get-upgrade-state'

export const useGetUpgradeState = (subscriptionId?: string, enabled?: boolean) => {
  const container = useContainer()
  const queryClient = useQueryClient()

  return useQuery(
    [QueryKey.getUpgradeState, subscriptionId],
    () => new GetUpgradeState(container.get(HttpClient)).execute(subscriptionId),
    {
      refetchInterval: (data, query) => {
        if (isNil(data)) {
          return false
        }

        const canRetry = data.status === UpgradeStatus.PENDING

        if (query.state.error) {
          return false
        }

        return canRetry ? 2000 : false
      },
      retryOnMount: true,
      refetchOnWindowFocus: 'always',
      staleTime: Infinity,
      cacheTime: 0,
      enabled: !!subscriptionId && enabled,
      onSuccess: (data) => {
        if (data.status === UpgradeStatus.SUCCESS) {
          queryClient.invalidateQueries([QueryKey.initUpgradePlan, subscriptionId])
          queryClient.invalidateQueries([QueryKey.getSubscription, subscriptionId])
          queryClient.invalidateQueries([QueryKey.getSubscriptionValue, subscriptionId])
        }
      },
    },
  )
}
