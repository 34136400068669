import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { GetDeactivatesMembersInput } from '../../domain/dtos/get-deactivates-members-input'
import { GetDeactivatesMembersOutput } from '../../domain/dtos/get-deactivates-members-output'
import { GeneralError } from '../../domain/errors/general'
import { Envs } from '../../envs'

export class GetDeactivatesMembers {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(input: GetDeactivatesMembersInput, signal?: AbortSignal): Promise<GetDeactivatesMembersOutput> {
    const response = await this.httpClient.request({
      method: HttpMethod.GET,
      url: `${Envs.BFF_MEMBERS_AREA_URL}/members/deactivates/list`,
      params: this.makeParams(input),
      signal,
    })

    if (response.statusCode === HttpStatusCode.OK || response.statusCode === HttpStatusCode.CREATED) {
      return response.data
    }

    throw new GeneralError()
  }

  private makeParams(input: GetDeactivatesMembersInput) {
    const body = {
      page: input.page,
      pageSize: input.pageSize,
      productId: input.productId,
      search: input.search,
      orderBy: 'nextDueAt',
      orderDirection: 'DESC',
    }

    return body
  }
}
