import { getValidFileExtensions, MAX_FILE_SIZE, UploadStatus } from '@hub-la/fe-asset'

type Props = {
  onChange
}

export const Input = ({ onChange }: Props) => {
  const validExtensions = getValidFileExtensions('videoAsset')

  return (
    <input
      type="file"
      data-testid="input"
      data-max-size={MAX_FILE_SIZE}
      multiple
      accept={validExtensions}
      style={{ display: 'none', margin: 0, padding: 0 }}
      onChange={(e) => {
        if (e.target.files === null) return
        const files = Array.from(e.target.files).map((file) => ({ file, status: UploadStatus.NO_CALLED }))

        onChange((state) => [...state, ...files])
      }}
    />
  )
}
