import { useToast } from '@hub-la/shadcn'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { TicketDefaultValuesMapper } from '../../../domain/mappers/make-ticket-default-values'
import { TicketForm } from '../../components/ticket-form/ticket-form'
import { useGetEvent } from '../../hooks/use-get-event'
import { useGetTicket } from '../../hooks/use-get-ticket'
import { usePutTicket } from '../../hooks/use-put-ticket'
import { TicketSchemaValidationType } from '../../validations/ticket-validation'

export const EditTicketPage = () => {
  const history = useHistory()
  const { toast } = useToast()
  const { t } = useTranslation()
  const { productId, ticketId } = useParams<{ productId: string; ticketId: string }>()
  const { data: product } = useGetEvent(productId)

  const { mutate, isLoading: putTicketLoading } = usePutTicket({
    id: ticketId,
    onSuccess: () => history.push(`/edit/${productId}/tickets?event=true`),
    onError: (error) => {
      toast({
        description: t('backendErrors.' + error.message),
        variant: 'destructive',
      })
    },
  })

  const { data, isLoading } = useGetTicket({
    productId,
    ticketId,
    onError: (error) => {
      toast({
        description: t('backendErrors.' + error.message),
        variant: 'destructive',
      })
      history.push(`/edit/${productId}/tickets?event=true`)
    },
  })

  const defaultValues: TicketSchemaValidationType = useMemo(
    () => TicketDefaultValuesMapper(productId, data),
    [productId, data],
  )

  return (
    <TicketForm
      isEdit
      productId={productId}
      onSubmit={(data) =>
        mutate({
          ...data,
          productId: product?.mainOfferId ?? '',
          maxCapacity: data.isLimitedQuantity ? data.maxCapacity : null,
        })
      }
      defaultValues={defaultValues}
      isLoading={isLoading}
      isSubmitting={putTicketLoading}
    />
  )
}
