import { HttpClient, HttpMethod } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { Asset } from '../domain/dtos/asset'
import { UpdateAssetRequestPayload } from '../domain/dtos/update-asset-request'
import { Envs } from '../envs'

export class UpdateAsset {
  public constructor(@inject(HttpClient) private readonly httpClient: HttpClient) {}

  public async execute(payload: UpdateAssetRequestPayload): Promise<Asset> {
    return new Promise((resolve) => {
      this.httpClient
        .request({
          url: `${Envs.BFF_MEMBERS_AREA_URL}/assets`,
          body: payload,
          method: HttpMethod.PUT,
        })
        .then((response) => {
          if (response.statusCode === undefined) {
            return false
          }

          return resolve(response.data)
        })
    })
  }
}
