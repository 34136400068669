import { container } from '@hub-la/fe-container'
import { CreateTicketPage, EditTicketPage, TicketProvider } from '@hub-la/fe-event'
import { useParams } from 'react-router-dom'

export const TicketInit = () => {
  const { ticketId } = useParams<{ ticketId: string }>()

  const isCreate = ticketId === 'create'

  return <TicketProvider container={container}>{isCreate ? <CreateTicketPage /> : <EditTicketPage />}</TicketProvider>
}
