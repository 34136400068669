import React from 'react'

export function stopEventPropagation(event: React.MouseEvent<HTMLElement, MouseEvent>) {
  event.preventDefault()
  event.stopPropagation()

  if (event.nativeEvent && typeof event.nativeEvent.stopImmediatePropagation === 'function') {
    event.nativeEvent.stopImmediatePropagation()
  }
}
