import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { InvoiceStatus } from '../../../domain/enums/invoice-status'
import { useGetInvoice } from '../../hooks/use-get-invoice'
import { ChargeDetail } from './components/charge-detail'
import { Description } from './components/description'
import { Header } from './components/header'
import { PaymentDetails } from './components/payment-details'
import { Loading } from './loading'

export const UserInvoicesDownloadPage = () => {
  const { t } = useTranslation()

  const { invoiceId } = useParams<{ invoiceId: string }>()
  const { data: invoice, isFetching } = useGetInvoice(invoiceId)

  if (isFetching && !invoice) {
    return <Loading />
  }

  return (
    <div className="my-4 flex flex-col container gap-4">
      <div className="mb-5 flex justify-between items-center">
        <h2 className="text-2xl font-semibold text-muted-foreground">{t('invoiceDownload.title')}</h2>
        <img className="w-36" src={require('../../assets/hubla-logo.png')} alt="logo" />
      </div>

      <div className="flex flex-col gap-4">
        <Header
          invoice={{
            id: invoice?.id ?? '',
            status: invoice?.status ?? InvoiceStatus.PAID,
            dueDate: invoice?.dueDate ?? '',
            paidAt: invoice?.paidAt ?? '',
            createdAt: invoice?.createdAt ?? '',
            total: invoice?.totalCents ?? 0,
            installment: invoice?.installment ?? 1,
            installments: invoice?.installments ?? 1,
            smartInstallmentRef: invoice?.smartInstallmentRef,
          }}
        />

        <ChargeDetail
          payer={{
            fullName: invoice?.payer.identity?.fullName ?? '',
            email: invoice?.payer.identity?.email ?? '',
            phone: invoice?.payer.identity?.phone ?? '',
          }}
          seller={{
            fullName: invoice?.seller.identity?.fullName ?? '',
          }}
        />

        <PaymentDetails
          paymentMethod={invoice?.paymentMethod}
          cardDetails={invoice?.cardDetails}
          softDescriptor={invoice?.softDescriptor}
        />

        <Description
          detail={{
            products: invoice?.amountDetail?.products ?? [],
            totalCents: invoice?.amountDetail?.totalCents ?? 0,
            couponCode: invoice?.amountDetail?.couponCode,
            discountCents: invoice?.amountDetail?.discountCents,
            installmentFeeCents: invoice?.amountDetail?.installmentFeeCents,
          }}
        />
      </div>
    </div>
  )
}
