import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { ChangeMembersCohorts, ChangeMembersCohortsResult } from '@hub-la/sdk-bff-access'
import { inject } from 'inversify'
import { ChangeMembersCohortsInput } from '../../domain/dtos/members/change-members-cohorts-input'
import { GeneralError } from '../../domain/errors/general'
import { Envs } from '../../envs'

export class PostChangeMembersCohorts {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient<ChangeMembersCohortsResult>,
  ) {}

  public async execute(
    input: ChangeMembersCohortsInput,
    signal?: AbortSignal,
  ): Promise<ChangeMembersCohortsResult | null> {
    const response = await this.httpClient.request({
      method: HttpMethod.POST,
      url: `${Envs.BFF_ACCESS_URL}/access/change-members-cohorts`,
      signal,
      body: this.makeBody(input),
    })

    await this.sleep((input?.selectedMembers?.length ?? 1) * 500) // waiting payments backend queue to update list

    if (response.statusCode === HttpStatusCode.OK || response.statusCode === HttpStatusCode.CREATED) {
      return response?.data ?? null
    }

    throw new GeneralError()
  }

  private makeBody(input: ChangeMembersCohortsInput): ChangeMembersCohorts {
    const body = {
      members: input.selectedMembers.map((member) => ({
        memberId: member.id,
        currentCohorts: member.cohortIds,
        newCohorts: input.newCohorts,
      })),
    }

    return body
  }

  private sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }
}
