import { cn, Input, Label } from '@hub-la/shadcn'
import { ChevronDown } from 'lucide-react'
import { forwardRef } from 'react'
import { type Country } from 'react-phone-number-input'
import PhoneNumberInput from 'react-phone-number-input/mobile'
import 'react-phone-number-input/style.css'

const PhoneField = forwardRef<HTMLInputElement, any>((props, ref) => {
  return (
    <div className="space-y-1.5 w-full">
      <Label>Telefone</Label>
      <Input {...props} ref={ref} id={props.id || 'phoneNumber'} name="phoneNumber" data-testid="phoneNumber" />
    </div>
  )
})
PhoneField.displayName = 'PhoneField'

const PhoneNumberStyledInternal = forwardRef(({ className, ...props }: any, ref) => (
  <PhoneNumberInput
    {...props}
    ref={ref}
    className={cn(
      'relative',
      'w-full',
      '[&_.PhoneInputCountry]:absolute [&_.PhoneInputCountry]:z-[2] [&_.PhoneInputCountry]:p-[42px_0_11px_12px]',
      '[&_.PhoneInputCountry_.lucide-chevron-down]:ml-[6px]',
      '[&_.PhoneInputCountry_.lucide-chevron-down]:mt-[2px]',
      '[&_.PhoneInputCountry_.lucide-chevron-down]:w-[16px]',
      '[&_.PhoneInputCountry_.lucide-chevron-down]:h-[16px]',
      '[&_label[data-shrink="false"]]:pl-[59px] [&_label[data-shrink="false"]]:pt-[2px]',
      '[&_input]:pl-[75px]',
      className,
    )}
  />
))

interface Props {
  value: string
  disabled?: boolean
  onChange: (value?: any | undefined) => void
  onBlur?: (e: React.FocusEvent<any, Element>) => void
  error?: boolean | undefined
  helperText?: any
  defaultCountry?: Country
  placeholder?: string
  inputComponent?: any
  countrySelectProps?: object | undefined
  id?: string
}

export const PhoneNumber = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const defaultProps = {
    defaultCountry: 'BR' as Country,
    placeholder: '(11) 98765 4321',
    inputComponent: PhoneField,
    countrySelectProps: {
      arrowComponent: ChevronDown,
    },
  }

  return <PhoneNumberStyledInternal {...defaultProps} {...props} ref={ref} />
})
