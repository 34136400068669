import { HttpClient } from '@hub-la/fe-core-http-client'
import { useQuery } from '@tanstack/react-query'
import { useContainer } from '../../container'
import { GetPayout } from '../../usecases/get-payout'

export const useGetPayout = () => {
  const container = useContainer()

  return useQuery(['getPayout'], () => new GetPayout(container.get(HttpClient)).execute(), {
    retry: false,
    refetchOnWindowFocus: false,
  })
}
