import { useAuth } from '@hub-la/fe-auth'
import { Loader2 } from 'lucide-react'
import React from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { UserVerificationTelegramStep } from '../../../domain/enums/user-verification-telegram-step'
import { useGetTelegramCode } from '../../hooks/use-get-telegram-code'
import { useGetUserVerification } from '../../hooks/use-get-user-verification'

export const VerificationInitPage: React.FC = () => {
  const { currentUser } = useAuth()
  const { data: user, isFetching } = useGetUserVerification(currentUser?.id)
  const { data: telegramCode, isFetching: isFetchingCode } = useGetTelegramCode()
  const step: UserVerificationTelegramStep | undefined = user?.steps?.verificationTelegram
  const { search } = useLocation()

  if (isFetching || !currentUser || isFetchingCode) {
    return (
      <div className="flex justify-center items-center h-screen w-screen">
        <Loader2 className="h-12 w-12 animate-spin" />
      </div>
    )
  }

  if (!user) {
    return null
  }

  if (
    step === undefined ||
    step === UserVerificationTelegramStep.done ||
    step === UserVerificationTelegramStep.intro ||
    (step === UserVerificationTelegramStep.pending && !telegramCode)
  ) {
    return <Redirect to={{ pathname: '/verification-telegram/intro', search }} />
  }

  if (
    (step === UserVerificationTelegramStep.onboarding || step === UserVerificationTelegramStep.pending) &&
    telegramCode
  ) {
    return <Redirect to={{ pathname: '/verification-telegram/start', search }} />
  }

  if (step === UserVerificationTelegramStep.completed && !telegramCode && user.ims?.telegram) {
    return <Redirect to={{ pathname: '/verification-telegram/done', search }} />
  }

  return null
}
