import { Button, Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@hub-la/shadcn'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  open: boolean
  onClose: () => void
  onConfirm: (event) => void
}

export const ConfirmationModal: React.FC<Props> = ({ open, onClose, onConfirm }) => {
  const { t } = useTranslation()

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle className="text-xl font-bold mb-1">{t('confirmationModal.section.title')}</DialogTitle>

          <p className="text-base text-muted-foreground">{t('confirmationModal.section.subtitle')}</p>
        </DialogHeader>

        <DialogFooter className="flex gap-2 justify-end">
          <Button variant="outline" onClick={onClose}>
            {t('confirmationModal.section.cancel')}
          </Button>

          <Button onClick={onConfirm}>{t('confirmationModal.section.confirm')}</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
