import { InviteAcceptError, InviteAcceptErrorCode } from '../../domain/dtos/get-accept-invite-output'

export class CheckUserNeedInput {
  public execute(errorCode: InviteAcceptErrorCode, userErrors: InviteAcceptError[] = []): boolean {
    if (userErrors?.length === 0 || !userErrors) {
      return false
    }

    const needInput = userErrors.some((error) => error.code === errorCode)

    return needInput
  }
}
