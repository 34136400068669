import { container } from '@hub-la/fe-container'
import { TelegramVerificationProvider, VerificationInitPage } from '@hub-la/fe-telegram-verification'
import { withRoleplayRedirect } from '../components/roleplay-redirect'

export const TelegramVerificationInitInit = withRoleplayRedirect(() => {
  return (
    <TelegramVerificationProvider container={container}>
      <VerificationInitPage />
    </TelegramVerificationProvider>
  )
})
