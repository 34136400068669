import { ProductDtoTypeEnum } from '@hub-la/sdk-bff-product'
import { Product } from '../domain/dtos/product.dto'

export class IsEvent {
  private product: Product | undefined

  public constructor(product?: Product) {
    this.product = product
  }

  public execute(): boolean {
    if (!ProductDtoTypeEnum?.EVENT) return false

    return this.product?.type === ProductDtoTypeEnum?.EVENT
  }
}
