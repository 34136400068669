import { Skeleton } from '@hub-la/shadcn'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const LoadingModalContent: React.FC = () => {
  const { t } = useTranslation()

  return (
    <div className="space-y-4">
      <div className="flex items-center gap-1 mb-2">
        <Skeleton className="h-6 flex-grow" />
        <Skeleton className="h-6 w-12" />
      </div>
      <hr className="border-t " />

      {[...Array(7)].map((_, index) => (
        <div key={index} className="flex items-center justify-between mb-1">
          <Skeleton className="h-4 w-1/3" />
          <Skeleton className="h-4 w-1/4" />
        </div>
      ))}

      <h4 className="text-base font-medium my-4">{t('invoiceDetails.paymentLabel')}</h4>
      <hr className="border-t " />

      {[...Array(5)].map((_, index) => (
        <div key={index} className="flex items-center justify-between mb-1">
          <Skeleton className="h-4 w-1/3" />
          <Skeleton className="h-4 w-1/4" />
        </div>
      ))}

      <h4 className="text-base font-medium my-4">{t('invoiceDetails.product')}</h4>
      <hr className="border-t " />

      <div className="flex items-center justify-between mb-3">
        <Skeleton className="h-4 w-1/6" />
        <Skeleton className="h-4 w-1/3" />
        <Skeleton className="h-4 w-1/6" />
      </div>
    </div>
  )
}
