import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { RetryEventHistoryInput } from '../domain/dtos/retry-event-history-input'
import { GeneralError } from '../domain/errors/general'
import { Envs } from '../envs'

export class RetryEventHistory {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient<void>,
  ) {}

  public async execute(input: RetryEventHistoryInput): Promise<void> {
    const response = await this.httpClient.request({
      method: HttpMethod.POST,
      url: `${Envs.BFF_WEB_URL}/integrations/${input.integrationId}/events/${input.historyId}/retry`,
    })

    if (response.statusCode === HttpStatusCode.CREATED) {
      return
    }

    throw new GeneralError()
  }
}
