import { useContainer } from '@hub-la/fe-container'
import { useQuery } from '@tanstack/react-query'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { QueryKey } from '../../domain/enums/query-key.enum'
import { Product } from '../../domain/dtos/product.dto'
import { GetProduct } from '../../usecases/get-product'

export const useGetProduct = (productId: string) => {
  const container = useContainer()

  return useQuery<Product>(
    [QueryKey.getProduct, productId],
    () => new GetProduct(container.get(HttpClient)).execute(productId),
    {
      retry: false,
      retryOnMount: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      enabled: !!productId,
    },
  )
}
