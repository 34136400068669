import { container } from '@hub-la/fe-container'
import { DashboardHome, DashboardHomeProvider } from '@hub-la/fe-dashboard-home'
import { PermissionResource, useRoleplay } from '@hub-la/fe-roleplay'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { menuItems } from '../components/menu-sidebar'

export const DashboardHomeInit = () => {
  const { hasPermission } = useRoleplay()
  const history = useHistory()

  // Redirect user to the first menu item that he has permission
  useEffect(() => {
    const menuWithPermission = menuItems.find((item) => item.permissions && hasPermission(item.permissions))
    if (!menuWithPermission) return

    history.push(menuWithPermission.path)
  }, [hasPermission, history])

  if (!hasPermission(PermissionResource.HOME)) {
    return null
  }

  return (
    <DashboardHomeProvider container={container}>
      <DashboardHome />
    </DashboardHomeProvider>
  )
}
