export const pt = {
  summarie: {
    paid: {
      title: 'Total pago',
      description: 'placeholder',
    },
    overdue: {
      title: 'Atrasado',
      description: 'placeholder',
    },
    draft: {
      title: 'Agendado',
      description: 'placeholder',
    },
  },
  smartInstallmentCyclesEmpty: 'Você não possui parcelamentos em aberto',
  title: 'Você possui {{ amount }} em parcelas em aberto',
  overdueTitle: 'Você possui {{ amount }} em parcelas em aberto',
  paidInstallments: '{{installment}}/{{installments}} parcelas pagas',
  nextDueAt: 'Próximo vencimento {{date}}',
  scheduleOffAt: 'Atrasado desde {{date}}',
  scheduledDate: 'Vencimento dia {{date}}',
  overdueDate: 'Venceu dia {{date}}',
  paidDate: 'Vencimento dia {{date}}',
  back: 'Voltar',
  pending: 'Pendências',
  draftTotalAmount: 'Em aberto',
  oldExperience: {
    title: 'Você está usando a nova experiência de alunos.',
    cta: 'Clique aqui para usar a antiga experiência.',
  },
  newExperience: {
    title: 'Há uma nova experiência de alunos disponível na plataforma!',
    cta: 'Clique aqui para utilizá-la.',
  },
  cardBrand: {
    0: '',
    1: '',
    2: 'Mastercard',
    3: 'Visa',
    4: 'Elo',
    5: 'Amex',
    CARD_BRAND_UNSPECIFIED: '',
    CARD_BRAND_OTHER: '',
    CARD_BRAND_MASTERCARD: 'Mastercard',
    CARD_BRAND_VISA: 'Visa',
    CARD_BRAND_ELO: 'Elo',
    CARD_BRAND_AMEX: 'Amex',
  },
  paymentMethod: 'Metodo de pagamento',
  softDescriptor: 'Descrição na fatura do cartão',
  processingPayment: 'Processando pagamento...',
  cancel: 'Cancelar',
  installmentsNumber: '{{installments}} parcela(s)',
  installmentOfInstallments: 'Parcela {{installment}}/{{installments}}',
  changePaymentMethodModal: {
    cta: 'Alterar cartão de crédito',
    title: 'Alterar método de pagamento',
    subtitle: 'Escolha o novo método de pagamento desejado.',
    cancel: 'Cancelar',
    save: 'Salvar',
    pixDisabled: 'Não é possível selecionar o PIX como método de pagamento de compras e assinaturas parceladas.',
    paymentMethod: {
      pix: 'Pix',
      creditCard: 'Cartão de crédito',
    },
    documentLabelCreditCard: 'CPF/CNPJ do titular do cartão',
    documentLabel: 'CPF/CNPJ',
    creditCard: {
      number: 'Número do cartão',
      holder: 'Nome no cartão',
      errors: {
        number: 'Número do cartão inválido',
        holder: 'Nome no cartão inválido',
        expiration: 'Data de expiração inválida',
        cvv: 'CVV inválido',
        token: 'Falha ao tokenizar cartão de crédito',
      },
    },
  },
  chargeSmartInstallmentDialog: {
    cta: 'Pagar parcelas em aberto',
    title: 'Selecione a parcela em aberto que deseja realizar o pagamento',
    actions: {
      submit: 'Ir para o pagamento',
    },
  },
  smartInstallmentsList: {
    title: 'Parcelas',
  },
  smartInstallmentCycleDetails: {
    nextDueAt: 'Próximo vencimento',
    totalAmount: 'Total parcelado',
  },
  smartInstallmentCycleStatus: {
    on_schedule: 'Em dia',
    off_schedule: 'Atrasado',
    canceled: 'Cancelado',
    aborted: 'Cancelado',
    completed: 'Concluído',
  },
  smartInstallment: {
    overdueInstallments: 'Pagamentos pendentes',
    cta: 'Compras parceladas',
  },
}
