import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { RemoveWhitelistUserRequest } from '../../domain/dtos/whitelist'
import { QueryKey } from '../../domain/enums/query-key'
import { RemoveWhitelistUser } from '../../usecases/remove-whitelist-user'

export const useRemoveWhitelistUser = () => {
  const container = useContainer()
  const queryClient = useQueryClient()

  return useMutation(
    [QueryKey.removeWhitelistUser],
    (input: RemoveWhitelistUserRequest) => new RemoveWhitelistUser(container.get(HttpClient)).execute(input),
    {
      retry: false,
      onSuccess: () => queryClient.invalidateQueries([QueryKey.getWhitelist]),
    },
  )
}
