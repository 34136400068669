import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { ExportMovementsOutput } from '../domain/dtos/export-movements-output'
import { ExportMovementsFilterDto } from '../domain/dtos/export-movements-request.dto'
import { ExportAlreadyInProgressError } from '../domain/errors/export-already-in-progress'
import { ExportLongPeriodError } from '../domain/errors/export-long-period'
import { GeneralError } from '../domain/errors/general'
import { Envs } from '../envs'

export class ExportMovements {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(input: ExportMovementsFilterDto): Promise<ExportMovementsOutput> {
    const response = await this.httpClient.request({
      method: HttpMethod.POST,
      url: `${Envs.BFF_WEB_URL}/financial-statement/movements/export`,
      params: {
        ...input,
        receiverEmail: undefined,
      },
      body: {
        receiverEmail: input.receiverEmail || undefined,
      },
    })

    if ([HttpStatusCode.OK, HttpStatusCode.CREATED].includes(response.statusCode)) {
      return { email: response.data?.email ?? '' }
    }

    if (response.statusCode === HttpStatusCode.CONFLICT) {
      throw new ExportAlreadyInProgressError()
    }

    if (response.statusCode === HttpStatusCode.BAD_REQUEST && this.isMonthsDiffError(response.data)) {
      throw new ExportLongPeriodError()
    }

    throw new GeneralError()
  }

  private isMonthsDiffError(data: any): boolean {
    return !!(typeof data === 'object' && data?.errorPublicData?.errors?.['monthsDiff']?.length)
  }
}
