import { Card, CardContent, CardHeader, CardTitle, Skeleton } from '@hub-la/shadcn'

export const ChargeDetailLoading = () => {
  return (
    <div className="flex flex-col md:flex-row gap-4">
      <Card className="flex-1">
        <CardHeader>
          <CardTitle>
            <Skeleton className="h-6 w-24" />
          </CardTitle>
        </CardHeader>

        <CardContent className="flex flex-col gap-1">
          <Skeleton className="h-5 w-48 max-w-full" />
          <Skeleton className="h-5 w-40" />
          <Skeleton className="h-5 w-36" />
        </CardContent>
      </Card>

      <Card className="flex-1">
        <CardHeader>
          <CardTitle>
            <Skeleton className="h-6 w-28" />
          </CardTitle>
        </CardHeader>

        <CardContent className="flex flex-col gap-1">
          <Skeleton className="h-5 w-48 max-w-full" />
        </CardContent>
      </Card>
    </div>
  )
}
