import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { UpdatePayoutInput } from '../domain/dtos/update-payout'
import { GeneralError } from '../domain/errors/general'
import { Envs } from '../envs'
import { UpdatePayoutValues } from '../presentation/pages/update-payout-modal'

export class UpdatePayout {
  public constructor(@inject(HttpClient) private readonly httpClient: HttpClient) {}

  public async execute(data: UpdatePayoutValues): Promise<void> {
    const response = await this.httpClient.request({
      method: HttpMethod.POST,
      url: this.makeUrl(),
      body: this.makeBody(data),
    })

    if ([HttpStatusCode.OK, HttpStatusCode.CREATED].includes(response.statusCode)) {
      return response.data
    } else {
      /** @todo: Handle backend errors */
      throw new GeneralError()
    }
  }

  private makeBody(data: UpdatePayoutValues): UpdatePayoutInput {
    return data.isPixKey ? { type: { name: 'PIX' } } : { type: { ...data.bankAccount, name: 'BANK_ACCOUNT' } }
  }

  private makeUrl() {
    return `${Envs.BFF_WEB_URL}/kyc/upsert-payout`
  }
}
