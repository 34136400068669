import { CohortDto, CohortsByProductIdResponseDto } from '@hub-la/sdk-bff-product'
import { Button, Input, Sheet, SheetContent, SheetHeader, SheetTitle } from '@hub-la/shadcn'
import { UseMutateAsyncFunction } from '@tanstack/react-query'
import { ArrowLeft } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { GroupResource } from '../../../../../../domain/dtos/groups'
import { UpdateResourceInput } from '../../../../../../domain/dtos/member-area/update-resource-input'
import { useGetCohortsList } from '../../../../../hooks/cohorts/use-get-cohorts-list'
import { useGetAssociatedCohorts } from '../../../../../hooks/member-area/use-get-associated-cohorts'
import { useUpdateResource } from '../../../../../hooks/member-area/use-update-resource'
import CohortsCard from '../cohorts-card'
import { useInitialState } from '../cohorts-card/get-initial-state'

interface EditGroupDrawerInterface {
  open: boolean
  mainOfferId?: string
  onClose: () => void
  resource: GroupResource
  handleOnGroupRename: (
    id: string,
    value: string,
    productId: string,
    mutateAsyncFn: UseMutateAsyncFunction<GroupResource, Error, UpdateResourceInput, unknown>,
    callback?: () => void,
    cohortIds?: string[],
  ) => void
}

const EditGroupDrawer: React.FC<EditGroupDrawerInterface> = ({
  open,
  onClose,
  resource,
  handleOnGroupRename,
  mainOfferId,
}) => {
  const { t } = useTranslation()
  const { mutateAsync, isLoading } = useUpdateResource()
  const [name, setName] = useState<string>('')
  const { productId } = useParams<{ productId: string }>()
  const { data = [] as unknown as CohortsByProductIdResponseDto, isLoading: isLoadingGetCohorts } = useGetCohortsList({
    productId,
  })
  const [cohortSwitches, setCohortsSwitches] = useState<CohortDto[]>([])
  const { data: cohorts = [], isLoading: isCohortAssociationLoading } = useGetAssociatedCohorts({
    resourceExternalId: resource.id,
    productId: mainOfferId ?? '',
  })

  useEffect(() => {
    setName(resource.name)
  }, [resource])

  const initialState = useInitialState({
    data: data?.items,
    cohorts,
    isLoadingData: isLoadingGetCohorts || isCohortAssociationLoading,
  })

  const handleOnSubmit = () => {
    handleOnGroupRename(
      resource.id,
      name,
      mainOfferId ?? '',
      mutateAsync,
      onClose,
      cohortSwitches.map((cohort) => cohort.id),
    )
  }

  return (
    <Sheet open={open} onOpenChange={onClose}>
      <SheetContent className="sm:max-w-lg p-6 overflow-y-auto">
        <SheetHeader className="mb-6">
          <SheetTitle className="flex items-center gap-4">
            <ArrowLeft className="h-6 w-6 text-green-900 cursor-pointer" onClick={onClose} />

            {t('member-area.groups.editResourceDrawer.title')}
          </SheetTitle>
        </SheetHeader>

        <div className="flex flex-col gap-6">
          <Input placeholder={t('Nome')} id="nome" onChange={(e) => setName(e.target.value)} value={name} />

          <CohortsCard
            data={initialState}
            isLoadingData={isLoadingGetCohorts || isCohortAssociationLoading}
            onChange={setCohortsSwitches}
          />

          <div className="flex flex-col gap-4">
            <Button
              loading={isLoading}
              disabled={
                !name ||
                (name === resource.name &&
                  cohorts.map((c) => c.id).toString() === cohortSwitches.map((c) => c.id).toString())
              }
              onClick={handleOnSubmit}
            >
              {t('member-area.groups.editResourceDrawer.confirm')}
            </Button>

            <Button variant="outline" onClick={onClose} loading={isLoading}>
              {t('member-area.groups.editResourceDrawer.cancel')}
            </Button>
          </div>
        </div>
      </SheetContent>
    </Sheet>
  )
}

export default EditGroupDrawer
