import { HttpClient, HttpMethod, HttpResponse, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { Section } from '../domain/dtos'
import { GetSectionsInput } from '../domain/dtos/get-sections-input'
import { GetSectionsOutput } from '../domain/dtos/get-sections-output'
import { GeneralError } from '../domain/errors/general'
import { Envs } from '../envs'

export class GetSections {
  public constructor(@inject(HttpClient) private readonly httpClient: HttpClient) {}

  public async execute(input: GetSectionsInput): Promise<Section[]> {
    const response: HttpResponse<GetSectionsOutput> = await this.httpClient.request({
      method: HttpMethod.GET,
      url: `${Envs.BFF_MEMBERS_AREA_URL}/hub/sections/v2?productId=${input.productId}&page=1&pageSize=999&postPageSize=999`,
    })

    if (response.statusCode === HttpStatusCode.OK) {
      return this.makeData(response)
    }

    throw new GeneralError()
  }

  private makeData(response: HttpResponse<GetSectionsOutput>) {
    return response.data?.items ?? []
  }
}
