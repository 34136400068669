import { Card, CardContent, CardDescription, CardHeader, CardTitle, Separator } from '@hub-la/shadcn'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ModeToggle } from './mode-toggle'

export const AppearancePage = () => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col mt-4">
      <Card className="w-full max-w-2xl">
        <CardHeader className="gap-2 px-6 py-4">
          <CardTitle className="text-xl">{t('appearance.title')}</CardTitle>
          <CardDescription>{t('appearance.description')}</CardDescription>
        </CardHeader>
        <Separator />

        <CardContent className="p-6">
          <ModeToggle />
        </CardContent>
      </Card>
    </div>
  )
}
