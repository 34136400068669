import { Card, CardContent, Skeleton } from '@hub-la/shadcn'
import { times } from 'lodash'

export const PendingInvoicesLoading = () => {
  return (
    <div className="my-6 flex flex-col gap-4">
      <Skeleton className="h-6 w-1/4" />

      <div className="flex flex-col gap-4">
        {times(3).map((i) => (
          <Card key={i} className="p-3">
            <CardContent className="w-full p-2 flex flex-wrap gap-4 items-center justify-between">
              <Skeleton className="h-6 w-6" />

              <div className="flex flex-1 flex-wrap gap-2 items-center justify-between">
                <div className="flex flex-col gap-1">
                  <Skeleton className="h-5 w-40" />
                  <Skeleton className="h-4 w-32" />
                </div>

                <Skeleton className="h-5 w-24" />
              </div>
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  )
}
