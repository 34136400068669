import { Input } from '@hub-la/shadcn'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { OfferSection } from '../../../../../components/offer-section'
import { SwitchToggleChildren } from '../../../../../components/switch-toggle-children'

export const Options = () => {
  const { t } = useTranslation()

  return (
    <OfferSection title={t('offer.checkoutTab.options.title')}>
      <div className="space-y-4">
        <SwitchToggleChildren name="checkout.isYoutubeEmbed" label={t('offer.checkoutTab.options.youtubeEmbed.title')}>
          <Controller
            name="checkout.youtubeEmbedUrl"
            render={({ field, fieldState }) => (
              <div className="space-y-2">
                <Input
                  placeholder={t('offer.checkoutTab.options.youtubeEmbed.youtubeEmbedUrl')}
                  className={fieldState.error ? 'border-red-500' : ''}
                  {...field}
                />
                {fieldState.error && (
                  <p className="text-sm text-red-500">{t(`fieldErrors.${fieldState.error.type}`)}</p>
                )}
              </div>
            )}
          />
        </SwitchToggleChildren>
        <SwitchToggleChildren
          name="checkout.isCreatorSupport"
          label={t('offer.checkoutTab.options.creatorSupport.title')}
        >
          <Controller
            name="checkout.creatorSupportUrl"
            render={({ field, fieldState }) => (
              <div className="space-y-2">
                <Input
                  placeholder={t('offer.checkoutTab.options.creatorSupport.creatorSupportUrl')}
                  className={fieldState.error ? 'border-red-500' : ''}
                  {...field}
                />
                {fieldState.error && (
                  <p className="text-sm text-red-500">{t(`fieldErrors.${fieldState.error.type}`)}</p>
                )}
                <p className="text-xs text-muted-foreground mb-2">
                  {t('offer.checkoutTab.options.creatorSupport.description')}
                </p>
              </div>
            )}
          />
        </SwitchToggleChildren>
        <SwitchToggleChildren name="checkout.isTermsUrl" label={t('offer.checkoutTab.options.termsUrl.title')}>
          <Controller
            name="checkout.termsUrl"
            render={({ field, fieldState }) => (
              <div className="space-y-2">
                <Input
                  placeholder={t('offer.checkoutTab.options.termsUrl.termsUrl')}
                  className={fieldState.error ? 'border-red-500' : ''}
                  {...field}
                />
                {fieldState.error && (
                  <p className="text-sm text-red-500">{t(`fieldErrors.${fieldState.error.type}`)}</p>
                )}
                <p className="text-xs text-muted-foreground mb-2">
                  {t('offer.checkoutTab.options.termsUrl.description')}
                </p>
              </div>
            )}
          />
        </SwitchToggleChildren>
      </div>
    </OfferSection>
  )
}
