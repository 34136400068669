export interface GetMovementsRequestDto {
  invoiceId: string
}

/**
MovementDto - Front-end representation of a pre-existing backend interface.
This interface serves as a reproduction at the front-end level of an already existing interface.
For more detailed information and comments related to this interface, please refer to the
source file at libs/backend/modules/mod-financial-statement/src/dto/movements.dto.ts.
Note: Any modifications or updates to this interface should also be reflected in the
corresponding backend interface for consistency and proper functioning of the application.
*/
export interface MovementDto {
  id: string
  amountInCents: number
  type: string
  createdAt?: Date
  releaseDate?: Date
  externalId: string
  transactionSource: TransactionSource
}

/**
 * TransactionSource - Front-end representation of a pre-existing backend interface.
 *
 * This interface is designed to mirror an interface that already exists in the backend codebase.
 * For more detailed information and comments regarding this interface, please check the source
 * file at libs/backend/modules/mod-financial-statement/src/dto/movements.dto.ts.
 *
 * It is essential to keep both the front-end and backend interfaces in sync. Any changes made to
 * this interface should also be updated in the corresponding backend interface to ensure the
 * proper functioning of the application.
 */
export enum TransactionSource {
  SALE = 'SALE',
  SALE_RELEASED = 'SALE_RELEASED',
  RENEWAL = 'RENEWAL',
  RENEWAL_RELEASED = 'RENEWAL_RELEASED',
  UPGRADE = 'UPGRADE',
  UPGRADE_RELEASED = 'UPGRADE_RELEASED',
  WITHDRAWAL_PENDING = 'WITHDRAWAL_PENDING',
  WITHDRAWAL_TAX_FEE = 'WITHDRAWAL_TAX_FEE',
  WITHDRAWAL_SUCCEEDED = 'WITHDRAWAL_SUCCEEDED',
  WITHDRAWAL_FAILED_AFTER_SUCCEEDED = 'WITHDRAWAL_FAILED_AFTER_SUCCEEDED',
  WITHDRAWAL_FAILED = 'WITHDRAWAL_FAILED',
  WITHDRAWAL_CANCELED = 'WITHDRAWAL_CANCELED',
  REFUND = 'REFUND',
  CHARGEBACK_DISPUTE_OPENED = 'CHARGEBACK_DISPUTE_OPENED',
  CHARGEBACK_ACCEPTED_AFTER_DISPUTE = 'CHARGEBACK_ACCEPTED_AFTER_DISPUTE',
  CHARGEBACK_ACCEPTED_WITHOUT_DISPUTE = 'CHARGEBACK_ACCEPTED_WITHOUT_DISPUTE',
  CHARGEBACK_REJECTED = 'CHARGEBACK_REJECTED',
  INTERNAL_TRANSFER = 'INTERNAL_TRANSFER',
  MIGRATED_BALANCE = 'MIGRATED_BALANCE',
  RESERVED_BALANCE = 'RESERVED_BALANCE',
  RESERVED_BALANCE_RELEASED = 'RESERVED_BALANCE_RELEASED',
}
