import { EventLocationDto } from '@hub-la/sdk-bff-product'
import { Input, Label, Select, SelectContent, SelectItem, SelectTrigger, SelectValue, Switch } from '@hub-la/shadcn'
import { useFormik } from 'formik'
import { MapPinIcon } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { UF } from '../../../../domain/constants/brazilian-states'
import { Countries } from '../../../../domain/constants/countries'
import { CountryCode } from '../../../../domain/vos/country'
import { Section } from '../../../components/section'
import { useGetAddress } from '../../../hooks/settings/use-get-address'
import { useGetSettings } from '../../../hooks/settings/use-get-settings'
import { useSaveSettings } from '../../../hooks/settings/use-save-settings'
import { eventLocationValidationSchema } from '../../../validations/event-location-validation'

type Values = EventLocationDto & {
  isLocationNotDefined: boolean
}

const EventLocation = () => {
  const { t } = useTranslation()
  const { productId } = useParams<{ productId: string }>()
  const { data, isLoading } = useGetSettings({ productId, type: 'info' })
  const { mutateAsync: saveSettings } = useSaveSettings()

  const formik = useFormik<Values>({
    validateOnMount: true,
    validateOnChange: true,
    enableReinitialize: true,
    initialValues: {
      name: data?.eventData?.location?.name ?? '',
      street: data?.eventData?.location?.street ?? '',
      number: data?.eventData?.location?.number ?? '',
      complement: data?.eventData?.location?.complement ?? '',
      neighborhood: data?.eventData?.location?.neighborhood ?? '',
      city: data?.eventData?.location?.city ?? '',
      state: data?.eventData?.location?.state ?? '',
      country: data?.eventData?.location?.country ?? '',
      zipCode: data?.eventData?.location?.zipCode ?? '',
      isLocationNotDefined: !data?.eventData?.location,
    },
    validationSchema: eventLocationValidationSchema,
    onSubmit: async (values) => {
      saveSettings({
        productId,
        type: 'info',
        data: {
          ...data,
          eventData: {
            startDate: data?.eventData?.startDate ?? '',
            endDate: data?.eventData?.endDate ?? '',
            location: !values?.isLocationNotDefined
              ? {
                  name: values.name,
                  street: values.street,
                  number: values.number,
                  complement: values.complement,
                  neighborhood: values.neighborhood,
                  city: values.city,
                  state: values.state,
                  country: values.country,
                  zipCode: values.zipCode,
                }
              : undefined, // backend need accept null
          },
        },
      })
    },
  })

  useGetAddress(formik.values.country ?? '', formik.values.zipCode, (data) => {
    if (!data) return
    if (data.street) {
      formik.setFieldValue('street', data.street ?? '')
      formik.setFieldError('street', undefined)
    }
    if (data.city) {
      formik.setFieldValue('city', data.city ?? '')
      formik.setFieldError('city', undefined)
    }
    if (data.neighborhood) {
      formik.setFieldValue('neighborhood', data.neighborhood ?? '')
      formik.setFieldError('neighborhood', undefined)
    }
  })

  const isBrazil = formik.values.country === CountryCode.BR

  return (
    <Section formik={formik}>
      <div className="space-y-6">
        <div className="flex items-center space-x-2">
          <MapPinIcon className="h-5 w-5" />
          <h2 className="text-lg font-medium">{t('eventLocation.title')}</h2>
        </div>

        <div className="flex items-center space-x-2">
          <Switch
            id="isLocationNotDefined"
            checked={formik.values.isLocationNotDefined}
            onCheckedChange={(checked) => formik.setFieldValue('isLocationNotDefined', checked)}
          />
          <Label htmlFor="isLocationNotDefined">{t('eventLocation.isLocationNotDefined')}</Label>
        </div>

        {!formik.values.isLocationNotDefined && (
          <div className="space-y-4">
            <Select
              onValueChange={(value) => formik.setFieldValue('country', value)}
              defaultValue={formik.values.country}
              disabled={isLoading}
            >
              <SelectTrigger className="w-full">
                <SelectValue placeholder={t('eventLocation.country')} />
              </SelectTrigger>

              <SelectContent>
                {Object.keys(Countries).map((code) => (
                  <SelectItem key={code} value={code}>
                    {Countries[code]?.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            {formik.errors.country && <p className="text-sm text-red-500">{t(formik.errors.country)}</p>}

            <div className="grid grid-cols-2 gap-4">
              {isBrazil ? (
                <div>
                  <Select
                    onValueChange={(value) => formik.setFieldValue('state', value)}
                    defaultValue={formik.values.state}
                    disabled={isLoading}
                  >
                    <SelectTrigger className="w-full">
                      <SelectValue placeholder={t('eventLocation.state')} />
                    </SelectTrigger>

                    <SelectContent>
                      {UF.map((uf) => (
                        <SelectItem key={uf.name} value={uf.name}>
                          {uf.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  {formik.errors.state && <p className="text-sm text-red-500">{t(formik.errors.state)}</p>}
                </div>
              ) : (
                <div>
                  <Input id="state" placeholder={t('eventLocation.state')} {...formik.getFieldProps('state')} />
                  {formik.errors.state && <p className="text-sm text-red-500">{t(formik.errors.state)}</p>}
                </div>
              )}
              <div>
                <Input
                  id="city"
                  placeholder={t('eventLocation.city')}
                  disabled={isLoading}
                  {...formik.getFieldProps('city')}
                />
                {formik.errors.city && <p className="text-sm text-red-500">{t(formik.errors.city)}</p>}
              </div>
            </div>

            <div>
              <Input
                id="zipCode"
                placeholder={t('eventLocation.zipCode')}
                disabled={isLoading}
                {...formik.getFieldProps('zipCode')}
              />
              {formik.errors.zipCode && <p className="text-sm text-red-500">{t(formik.errors.zipCode)}</p>}
            </div>

            <div>
              <Input
                id="street"
                placeholder={t('eventLocation.street')}
                disabled={isLoading}
                {...formik.getFieldProps('street')}
              />
              {formik.errors.street && <p className="text-sm text-red-500">{t(formik.errors.street)}</p>}
            </div>

            <div>
              <Input
                id="number"
                placeholder={t('eventLocation.number')}
                disabled={isLoading}
                {...formik.getFieldProps('number')}
              />
              {formik.errors.number && <p className="text-sm text-red-500">{t(formik.errors.number)}</p>}
            </div>

            <div>
              <Input
                id="complement"
                placeholder={t('eventLocation.complement')}
                disabled={isLoading}
                {...formik.getFieldProps('complement')}
              />
              {formik.errors.complement && <p className="text-sm text-red-500">{t(formik.errors.complement)}</p>}
            </div>

            <div>
              <Input
                id="neighborhood"
                placeholder={t('eventLocation.neighborhood')}
                disabled={isLoading}
                {...formik.getFieldProps('neighborhood')}
              />
              {formik.errors.neighborhood && <p className="text-sm text-red-500">{t(formik.errors.neighborhood)}</p>}
            </div>
          </div>
        )}
      </div>
    </Section>
  )
}

export { EventLocation }
