import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { QueryKey, SectionList } from '@hub-la/fe-post'
import { isNil } from 'lodash'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { DeleteSection } from '../../usecases/delete-section'

export const useDeleteSection = () => {
  const container = useContainer()
  const queryClient = useQueryClient()

  return useMutation([], (id: string) => new DeleteSection(container.get(HttpClient)).execute(id), {
    onSuccess: (sectionId) => {
      queryClient.setQueryData<SectionList>([QueryKey.listSections], (sections): SectionList => {
        if (isNil(sections)) {
          return {
            items: [],
            page: 0,
            pageSize: 0,
            total: 0,
          }
        }

        const items = sections.items.filter((section) => section.id !== sectionId)

        return { items, page: sections.page, pageSize: sections.pageSize, total: sections.total }
      })
    },
  })
}
