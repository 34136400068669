import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { Asset, AssetResponse } from '../domain/dtos'
import { Envs } from '../envs'
import { sanatizeName } from './sanatize-name'

export class GetAsset {
  public constructor(@inject(HttpClient) private readonly httpClient: HttpClient) {}

  protected makeData = (data: AssetResponse): Asset => {
    const asset: Asset = {
      id: data.id,
      name: sanatizeName(data.name),
      url: data.url,
      metadata: data.metadata,
      mimeType: `${data.type}/${data.subType}`,
      file: null,
      ownerId: data.ownerId,
    }

    return asset
  }

  public async execute(assetId: string): Promise<Asset> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .request({
          url: `${Envs.BFF_MEMBERS_AREA_URL}/assets/${assetId}`,
          method: HttpMethod.GET,
        })
        .then((response) => {
          if (response.statusCode === HttpStatusCode.OK) {
            return resolve(this.makeData(response.data))
          }
        })
        .catch(() => {
          return reject('Ocorreu um erro ao buscar a thumb da url assinada')
        })
    })
  }
}
