import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { GeneralError } from '../../domain/errors/general'
import { Envs } from '../../envs'
import { AddNewResourceInput } from '../../domain/dtos/member-area/add-new-resource-input'

export class AddResource {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(input: AddNewResourceInput): Promise<void> {
    const response = await this.httpClient.request({
      method: HttpMethod.POST,
      url: `${Envs.FUNCTIONS_URL}/group/addResource/pt`,
      body: {
        data: {
          groupId: input.offerId,
          resourceId: input.resourceId,
          resourceInstallTo: 'allMembers',
          groupName: input.groupName,
          cohortIds: input.cohortIds,
        },
      },
    })

    if (response.statusCode === HttpStatusCode.OK || response.statusCode === HttpStatusCode.CREATED) {
      return
    }

    throw new GeneralError()
  }
}
