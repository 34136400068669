import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { FormikProvider, useFormik } from 'formik'
import { Button, Input, useToast } from '@hub-la/shadcn'

import { AcceptInviteForm } from '../../../../domain/dtos/accept-invite-form'
import { ErrorCode } from '../../../../domain/dtos/error-code'
import { InviteAcceptErrorCode } from '../../../../domain/dtos/get-accept-invite-output'
import { Envs } from '../../../../envs'
import { CheckUserNeedInput } from '../../../../usecases/members/check-need-input'
import { PhoneNumberStyled } from '../../../components/phone-number/phone-number-styled'
import { useGetAcceptInvite } from '../../../hooks/members/use-get-accept-invite'
import { usePostAcceptInvite } from '../../../hooks/members/use-post-accept-invite'
import { acceptInviteValidationSchema } from '../../../validations/accept-invite-validation'
import { ErrorStatus, InviteError } from './error'
import { Loading } from './loading'

export const InvitePage = () => {
  const { t } = useTranslation()
  const { inviteId } = useParams<{ inviteId: string }>()
  const { toast } = useToast()

  const { data, isLoading, error } = useGetAcceptInvite({
    inviteId,
  })

  const {
    mutateAsync,
    isLoading: acceptLoading,
    isError: isAcceptError,
    error: acceptError,
    reset,
  } = usePostAcceptInvite({
    onSuccess: () => {
      return window.location.assign(`${Envs.APP_URL}/signin?redirectTo=/user_groups`)
    },
  })

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null

    if (isAcceptError) {
      toast({
        variant: 'destructive',
        title: t(acceptError?.message || 'invitePage.inviteErrors.default'),
        duration: 6000,
      })
      timer = setTimeout(() => {
        reset()
      }, 6000)
    }

    return () => {
      if (timer) clearTimeout(timer)
    }
  }, [isAcceptError, acceptError, t, toast, reset])

  const form = useFormik<AcceptInviteForm>({
    initialValues: {
      email: data?.details?.receiverEmail || '',
      fullName: data?.details?.user?.name || '',
      phoneNumber: data?.details?.user?.phoneNumber || '',
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      mutateAsync({ ...values, inviteId })
    },
    validationSchema: acceptInviteValidationSchema,
  })

  const isUnsupportedStatus = data?.errorCode === ErrorCode.INVITE_UNSUPPORTED_STATUS
  const isAuth = !!data?.details?.user

  const inputsByError = [
    {
      errorCode: InviteAcceptErrorCode.USER_MISSING_EMAIL,
      input: (
        <Input
          id="email"
          type="email"
          onChange={form.handleChange}
          placeholder={t('invitePage.inputs.email.label')}
          value={form.values.email}
          disabled
          className="w-full"
        />
      ),
    },
    {
      errorCode: InviteAcceptErrorCode.USER_MISSING_NAME,
      input: (
        <Input
          id="fullName"
          type="text"
          placeholder={t('invitePage.inputs.fullName.label')}
          value={form.values.fullName}
          onBlur={form.handleBlur}
          onChange={form.handleChange}
          className={`w-full ${form.touched.fullName && form.errors.fullName ? 'border-red-500' : ''}`}
        />
      ),
    },
    {
      errorCode: InviteAcceptErrorCode.USER_MISSING_PHONE_NUMBER,
      input: (
        <div className="w-full">
          <PhoneNumberStyled
            disabled={false}
            id="phoneNumber"
            value={form.values.phoneNumber}
            onBlur={form.handleBlur}
            onChange={(value) => value && form.setFieldValue('phoneNumber', value.toString())}
            label={t('invitePage.inputs.phoneNumber.label')}
            error={form.touched.phoneNumber && !!form.errors.phoneNumber}
            helperText={form.touched.phoneNumber && !!form.errors.phoneNumber ? t(form.errors.phoneNumber) : null}
          />
        </div>
      ),
    },
  ]

  if (isLoading) {
    return <Loading />
  }

  if (error) {
    return <InviteError error={error} />
  }

  if (isUnsupportedStatus) {
    return <ErrorStatus details={data?.details} status={data?.status} errorCode={data?.errorCode} />
  }

  return (
    <div className="container mx-auto max-w-xl">
      <FormikProvider value={form}>
        <form onSubmit={form.handleSubmit}>
          <div className="max-w-[588px] mt-8 mx-auto">
            <h3 className="text-2xl font-semibold mb-3">
              {t('invitePage.title.invited', { productName: data?.details?.product?.name })}
            </h3>
            {!isAuth && <p className="text-sm mb-6">{t('invitePage.description.withoutAccount')}</p>}

            <div className="flex items-center justify-between p-4 my-6 border  rounded-md">
              <div className="flex gap-3">
                {data?.details?.product?.picture && (
                  <img
                    src={data?.details?.product?.picture}
                    width={82}
                    height={52}
                    alt="product logo"
                    className="rounded"
                  />
                )}
                <div>
                  <h4 className="text-lg font-semibold mb-0.5">{data?.details?.product?.name}</h4>
                  <p className="text-xs">{data?.details?.product?.ownerName}</p>
                </div>
              </div>
              <p className="text-xs">
                {!data?.details?.days
                  ? t('invitePage.inviteLifetime')
                  : t('invitePage.inviteFreeTime', {
                      days: data?.details?.days,
                    })}
              </p>
            </div>
            <div className="space-y-3">
              {inputsByError.map(
                (inputByError) =>
                  new CheckUserNeedInput().execute(inputByError.errorCode, data?.errors) && inputByError.input,
              )}
            </div>
            <Button className="w-full mt-6" type={'submit'} disabled={acceptLoading}>
              {t('invitePage.acceptInvite')}
            </Button>
          </div>
        </form>
      </FormikProvider>
    </div>
  )
}
