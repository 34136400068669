import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useQuery } from '@tanstack/react-query'
import { GetCollaboratorOutput } from '../../domain/dtos/get-collaborator-output'
import { QueryKey } from '../../domain/dtos/query-keys'
import { GetCollaborator } from '../../usecases/get-collaborator'

export const useGetCollaborator = (collaboratorUserId: string) => {
  const container = useContainer()

  return useQuery<GetCollaboratorOutput | null>(
    [QueryKey.getMyCollaborator, collaboratorUserId],
    () => new GetCollaborator(container.get(HttpClient)).execute(collaboratorUserId),
    {
      retry: false,
      retryOnMount: false,
      refetchOnWindowFocus: false,
      initialData: null,
    },
  )
}
