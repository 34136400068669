import {
  Alert,
  AlertDescription,
  Badge,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Input,
  toast,
} from '@hub-la/shadcn'
import { FormikProvider, useFormik } from 'formik'
import { Info } from 'lucide-react'
import { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { TransformFreeMemberForm } from '../../domain/dtos/transform-free-member-form'
import { usePostAddFreeMembers } from '../hooks/members/use-post-free-members'
import { transformFreeMemberValidationSchema } from '../validations/transform-free-member-validation'

type TransformFreeMemberModalProps = {
  onClose: () => void
  open: boolean
  onSubmit: (values: TransformFreeMemberForm) => void
  userIds: string[]
  productId: string
  isLoading: boolean
  isEvent?: boolean
}

export const TransformFreeMemberModal: React.FC<TransformFreeMemberModalProps> = ({
  onClose,
  open,
  onSubmit,
  userIds,
  productId,
  isLoading,
  isEvent,
}) => {
  const { t } = useTranslation()
  const formik = useFormik<TransformFreeMemberForm>({
    initialValues: {
      days: null,
      lifetime: !!isEvent,
      userIds,
      productId,
    },
    onSubmit: (values) => {
      onSubmit(values)
    },
    validationSchema: transformFreeMemberValidationSchema,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: true,
  })

  const translationKey = isEvent ? 'transformFreeParticipantModal' : 'transformFreeMembersModal'

  const memoizedOnClose = useCallback(() => {
    onClose()
    formik.resetForm()
  }, [onClose, formik])

  const memoizedOnSubmit = useCallback(
    (values: TransformFreeMemberForm) => {
      onSubmit(values)
      formik.resetForm()
    },
    [onSubmit, formik],
  )

  const { data: freeMembers, isLoading: loadingAddFreeMembers } = usePostAddFreeMembers({
    onSuccess: () => {
      memoizedOnSubmit(formik.values)
      toast({
        variant: 'default',
        title: t(`${translationKey}.snackbar.success`),
      })
    },
    onError: () => {
      const errorFreeMembers = (freeMembers?.status ?? []).filter((member) => !member.isCreated)
      memoizedOnClose()
      toast({
        variant: 'destructive',
        title: t(`${translationKey}.snackbar.error.title`),
        description: (
          <Trans
            i18nKey={`${translationKey}.snackbar.error.description`}
            components={[<strong />]}
            values={{
              users: errorFreeMembers
                ?.map((member) => `- ${member.receiverEmail} (${t(`singleInviteValidationEnum.${member.reason}`)})`)
                .join(`\n`),
            }}
          />
        ),
      })
    },
  })

  return (
    <FormikProvider value={formik}>
      <Dialog open={open} onOpenChange={memoizedOnClose}>
        <DialogContent className="sm:max-w-[510px]">
          <DialogHeader>
            <DialogTitle>{t('transformFreeMembersModal.title')}</DialogTitle>
            <DialogDescription>{t('transformFreeMembersModal.description')}</DialogDescription>
          </DialogHeader>
          <Badge variant="secondary" className="w-auto">
            {t('transformFreeMembersModal.selectedMembers', { total: userIds.length })}
          </Badge>

          {!isEvent && (
            <Input
              id="days"
              type="number"
              placeholder={t(`${translationKey}.inputs.days.label`)}
              min={1}
              disabled={formik.values.lifetime}
              className={formik.errors.days && formik.touched.days ? 'border-red-500' : ''}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.days ?? ''}
            />
          )}

          {!isEvent && (
            <div className="flex items-center space-x-2">
              <Checkbox
                id="lifetime"
                checked={formik.values.lifetime}
                onCheckedChange={(checked) => formik.setFieldValue('lifetime', checked)}
              />
              <label
                htmlFor="lifetime"
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                {t(`${translationKey}.inputs.lifetimeCheckbox`)}
              </label>
            </div>
          )}

          <Alert>
            <Info className="h-4 w-4" />
            <AlertDescription>{t(`${translationKey}.alert`)}</AlertDescription>
          </Alert>

          <DialogFooter>
            <Button variant="outline" onClick={memoizedOnClose} disabled={loadingAddFreeMembers}>
              {t(`${translationKey}.actions.cancel`)}
            </Button>
            <Button
              onClick={() => formik.submitForm()}
              disabled={!formik.isValid || loadingAddFreeMembers || isLoading}
            >
              {t(`${translationKey}.actions.submit`)}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </FormikProvider>
  )
}
