import { HttpClient, HttpMethod, HttpResponse, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { Section } from '@hub-la/fe-post'
import { inject } from 'inversify'
import { GetSectionsInput } from '../../../domain/dtos/member-area/settings/get-sections-input'
import { GetSectionsOutput } from '../../../domain/dtos/member-area/settings/get-sections-output'
import { GeneralError } from '../../../domain/errors/general'
import { Envs } from '../../../envs'

export class GetSections {
  public constructor(@inject(HttpClient) private readonly httpClient: HttpClient) {}

  public async execute(input: GetSectionsInput): Promise<Section[]> {
    const response: HttpResponse<GetSectionsOutput> = await this.httpClient.request({
      method: HttpMethod.GET,
      url: this.makeUrl(input),
    })

    if (response.statusCode === HttpStatusCode.OK) {
      return this.makeData(response)
    }

    throw new GeneralError()
  }

  private makeUrl(input: GetSectionsInput) {
    return `${Envs.BFF_WEB_URL}/hub/sections?productId=${input.productId}&page=1&pageSize=999&postPageSize=999`
  }

  private makeData(response: HttpResponse<GetSectionsOutput>) {
    return response.data?.items ?? []
  }
}
