import { container } from '@hub-la/fe-container'
import { DashboardEvents, EventProvider } from '@hub-la/fe-dashboard-products'

export const DashboardEventsInit = () => {
  return (
    <EventProvider container={container}>
      <DashboardEvents />
    </EventProvider>
  )
}
