import { Skeleton } from '@hub-la/shadcn'

export const RadioLoading = () => {
  return (
    <div className="flex flex-col gap-4">
      {[...Array(4)].map((_, i) => (
        <div key={i} className="p-4 flex flex-col gap-4 border rounded-md">
          <div className="flex items-center gap-2">
            <Skeleton className="h-4 w-4 rounded-full" />
            <Skeleton className="h-5 w-32" />
          </div>

          <Skeleton className="h-4 w-full max-w-lg" />
        </div>
      ))}
    </div>
  )
}
