import { useContainer } from '@hub-la/fe-container'
import { Analytics } from '@hub-la/fe-core-analytics'
import { CreatePostRequest, Events, makePostEventData, makePostSubmittedData } from '@hub-la/fe-post'
import { toast } from '@hub-la/shadcn'
import { FormikProvider, useFormik } from 'formik'
import { isNil } from 'lodash'
import { Loader2 } from 'lucide-react'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Values } from '../../../domain/dtos/values'
import { GetInitialValues } from '../../../usecases/get-initial-values'
import { GetPostId } from '../../../usecases/get-post-id'
import { GetPostType } from '../../../usecases/get-post-type'
import { routes } from '../../../utils/routes'
import { Header } from '../../components/header/header'
import { SectionsSidebar } from '../../components/sections-sidebar/sections-sidebar'
import { useGetPost } from '../../hooks/use-get-post'
import { useSavePost } from '../../hooks/use-save-post'
import { CreatePublication } from './create-publication'
import { validationSchema } from './validation-schema'

export interface Props {
  userId: string
  productId: string
  sectionId: string
}

export const CreatePost = ({ userId, productId, sectionId }: Props) => {
  const postId = new GetPostId().execute()
  const { isLoading, data } = useGetPost({ postId })
  const postType = new GetPostType().execute(data)
  const isEvent = postType === 'event'

  const [isPublishing, setIsPublishing] = useState(false)

  const initialValues = useMemo(() => new GetInitialValues().execute(data), [data])

  const formik = useFormik<Values>({
    validationSchema: validationSchema({ isEvent }),
    initialValues,
    enableReinitialize: true,
    validateOnMount: true,
    validateOnChange: true,
    onSubmit: () => handleSavePost(),
  })

  const { values } = formik

  const {
    title,
    content,
    subtitle,
    status,
    customCover,
    mainMedia,
    mainMediaProgressStatus,
    attachedAssets,
    event,
    empty,
  } = values

  const history = useHistory()
  const container = useContainer()
  const savePost = useSavePost()

  const { t } = useTranslation()

  const analytics = container.get(Analytics)

  const handleClose = (): void => {
    history.push(`${routes.EDIT_PRODUCT}/${productId}/#content`)
  }

  const handleSavePost = () => {
    const attachedIds = attachedAssets?.map((asset) => asset.id)
    const data: CreatePostRequest = {
      status,
      title,
      subtitle,
      content,
      attachmentAssetIds: [],
      sectionId,
    }

    if (isEvent) {
      const adjustTime = (time?: string) => {
        if (isNil(time)) {
          return undefined
        }
        const [hours, minutes] = time.split(':')
        return moment(event?.date)
          .set({ hours: parseInt(hours), minutes: parseInt(minutes), seconds: 0 })
          .toISOString()
      }

      data.notify = event?.notifyMembers
      data.eventUrl = event?.link
      data.eventStartTime = adjustTime(event?.start)
      data.eventEndTime = adjustTime(event?.end)
    }

    if (attachedIds) {
      data.attachmentAssetIds = attachedIds
    }

    if (mainMedia || mainMedia === null) {
      data.coverAssetId = mainMedia?.id ?? null
    }

    if (customCover || customCover === null) {
      data.customCoverAssetId = customCover?.id ?? null
    }

    savePost
      .mutateAsync({
        payload: data,
        id: postId,
      })
      .then(async (res) => {
        if (res) {
          analytics.track(
            Events.HUB.SUBMITTED,
            makePostSubmittedData({
              attachments: attachedAssets,
              userId,
              productId,
              isUpdatePost: !!postId,
              mainMediaProgressStatus,
              mainContentType: res?.cover?.type,
              subtitle,
              content,
            }),
          )

          if (empty) {
            formik.resetForm({ values: { ...initialValues, empty: true } })
            return
          }

          formik.resetForm({ values })

          // not close/redirect if editing, because creator want to stay in the same page to continue editing with sidebar
          if (postId) return
          handleClose()
        }
      })
  }

  useEffect(() => {
    analytics.track(Events.HUB.VIEWED, makePostEventData({ userId, productId }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (savePost.isError) {
    toast({
      description: t('publication.save.error'),
      variant: 'destructive',
    })
  }

  const loadingPost = isLoading
  // When uploading asset is in progress, we should validate the form schema with the progress status
  const uploadInProgress = Boolean(
    formik.errors?.mainMediaProgressStatus ||
      formik.errors?.attachedProgressStatus ||
      formik?.errors?.customCoverStatus,
  )

  return (
    <FormikProvider value={formik}>
      <Header
        isPublishing={isPublishing}
        setIsPublishing={setIsPublishing}
        isLoading={savePost.isLoading}
        handleClose={handleClose}
      />

      {postId && (
        <SectionsSidebar disabled={savePost.isLoading} isLoading={uploadInProgress} saveAlert={formik.dirty} />
      )}

      {(!loadingPost || !postId) && <CreatePublication productId={productId} userId={userId} postType={postType} />}
      {loadingPost && postId && (
        <div className="flex justify-center items-center h-screen w-screen">
          <Loader2 className="w-12 h-12 animate-spin" />
        </div>
      )}
    </FormikProvider>
  )
}
