import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation } from '@tanstack/react-query'
import { QueryKey } from '../../domain/dtos/query-keys'
import { SignInByPhoneNumberInput } from '../../domain/dtos/sign-in-by-phone-number-input'
import { PostSignInByPhoneNumber } from '../../usecases/post-sign-in-by-phone-number'

export const usePostSignInByPhoneNumber = (params: { onSuccess?: () => void }) => {
  const container = useContainer()

  return useMutation(
    [QueryKey.postSignInByPhoneNumber],
    (input: SignInByPhoneNumberInput) => new PostSignInByPhoneNumber(container.get(HttpClient)).execute(input),
    {
      retry: false,
      onSuccess: () => {
        if (params?.onSuccess) {
          params?.onSuccess()
        }
      },
    },
  )
}
