import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { GetIntegrationHistoriesInput } from '../domain/dtos/get-integration-histories-input'
import { GetIntegrationHistoriesOutput } from '../domain/dtos/get-integration-histories-output'
import { GeneralError } from '../domain/errors/general'
import { Envs } from '../envs'

export class GetHistories {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(
    input: GetIntegrationHistoriesInput,
    signal?: AbortSignal,
  ): Promise<GetIntegrationHistoriesOutput> {
    const response = await this.httpClient.request({
      method: HttpMethod.POST,
      url: `${Envs.BFF_WEB_URL}/integrations/${input.integrationId}/events/list`,
      body: this.makeBody(input),
      signal,
    })

    if (response.statusCode === HttpStatusCode.OK || response.statusCode === HttpStatusCode.CREATED) {
      return response.data
    }

    throw new GeneralError()
  }

  private makeBody(input: GetIntegrationHistoriesInput) {
    const body = {
      filters: {
        offerIds: input.offerIds,
        dateStart: input.dateStart,
        dateEnd: input.dateEnd,
        events: input.events,
        // search: input.search,
      },
      page: input.page,
      pageSize: input.pageSize,
    }

    return body
  }
}
