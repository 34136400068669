import { AccountDropdown } from '@hub-la/fe-account-dropdown'
import { useGetUser } from '@hub-la/fe-get-user'
import { useGetRoleplayReference, useRoleplay } from '@hub-la/fe-roleplay'
import { Button, cn, Logo, Tooltip, TooltipProvider, TooltipTrigger, useIsMobile } from '@hub-la/shadcn'
import { PanelLeftClose, PanelLeftOpenIcon } from 'lucide-react'
import { Link, useHistory } from 'react-router-dom'
import { MenuSidebar } from './menu-sidebar'

export const AppHeader = ({ isToggleSidebar, onToggleSidebar, hideSidebar }) => {
  const { isRoleplay } = useRoleplay()
  const isMobile = useIsMobile()
  const history = useHistory()

  // Use the memoized parameters in useGetUser
  const { data: currentUser } = useGetUser({
    enabled: !isRoleplay,
  })

  const { data: roleplayUser } = useGetRoleplayReference({ enabled: isRoleplay })

  return (
    <nav
      className={cn(
        'fixed w-full bg-sidebar-background backdrop-blur-lg border-b border-sidebar-border z-50 mx-0',
        isRoleplay && 'mt-10',
      )}
    >
      <div className="container px-4 sm:px-4 md:px-8 w-full flex items-center justify-between h-14 sm:h-16 max-w-none">
        {/* Logo and Sidebar Toggle */}
        <div className="flex items-center space-x-2">
          {/* Mobile Menu */}
          {isMobile && !hideSidebar && (
            <MenuSidebar isToggleSidebar={isToggleSidebar} onToggleSidebar={onToggleSidebar} />
          )}
          {/* Logo */}
          <Link to="/dashboard" className="md:inline-block hidden">
            <Logo hierarchy="primary" size={isMobile ? 'medium' : 'medium'} />
          </Link>
          {/* Desktop Sidebar Toggle Button */}
          {!isMobile && !hideSidebar && (
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button variant="ghost" onClick={() => onToggleSidebar(!isToggleSidebar)} className="ml-3">
                    {isToggleSidebar ? (
                      <PanelLeftOpenIcon className="w-5 h-5" />
                    ) : (
                      <PanelLeftClose className="w-5 h-5" />
                    )}
                  </Button>
                </TooltipTrigger>
              </Tooltip>
            </TooltipProvider>
          )}
        </div>
        {/* User Account or Sign In */}
        <div className="flex items-center space-x-2">
          {currentUser && !isRoleplay && (
            <AccountDropdown
              push={history.push}
              id={currentUser.id ?? ''}
              firstName={currentUser.name}
              picture={currentUser.picture}
              username={currentUser.id}
              bankAccount={undefined}
            />
          )}
          {isRoleplay && roleplayUser && (
            <AccountDropdown
              push={history.push}
              id={roleplayUser.userId}
              firstName={roleplayUser.name ?? undefined}
              picture={roleplayUser.picture ?? null}
              username={roleplayUser.username}
              bankAccount={undefined}
            />
          )}
        </div>
      </div>
    </nav>
  )
}
