export const getTermsOfUse = () => ` 
<strong>Termos de Serviços de Coprodução da Hubla</strong>
<br>
<br>
Estes Termos de Serviços de Coprodução da Hubla (“Termos”) são celebrados entre a Hubla Tecnologia Ltda.
(“Hubla”), com sede na Rua Senador Cesar Lacerda de Vergueiro, nº 380, 39º subdistrito, salas 61 62, no bairro Vila
Madalena, na cidade de São Paulo, Estado de São Paulo, CEP 05435-010, inscrita no CNPJ/MF sob o nº
36.062.381/0001-80, de um lado, e o Criador e o(s) Coprodutor(es), de outro lado. O Criador e o(s) Coprodutor(es)
são designados em conjunto como “Partes.”
<br>
<br>
Ao disponibilizarem o Produto na Plataforma Hubla, o Criador e o(s) Coprodutor(es) declaram que leram atentamente
e que estão de acordo com as regras e condições contidas nestes Termos. Adicionalmente, o(s) Coprodutor(es)
deverá(ão) concordar com os Termos de Uso da Plataforma e a Política de Privacidade, que determinam as condições
gerais aplicáveis à utilização da Plataforma Hubla para todos os membros cadastrados e sobre o tratamento de dados
pessoais necessários para a utilização da Plataforma. Todos os termos e condições constantes dos Termos de Uso e da
Política de Privacidade aplicam-se a estes Termos no que se refere à utilização da Plataforma Hubla como se aqui
houvessem sido transcritos. Caso não concorde com estes Termos, recomendamos que o Criador e o(s)
Coprodutor(es) não façam uso do presente Serviço.
<br>
<br>
Termos iniciados em letras maiúsculas não definidos neste Termo estão definidos nos Termos de Uso da Hubla.
<br>
<br>
<strong>1. OBJETIVO</strong>
<br>
<br>
O objetivo destes Termos é apresentar as condições e critérios para a criação e divulgação do Produto em formato
de Coprodução pela Plataforma da Hubla. A Hubla é detentora da Plataforma que permite que qualquer pessoa
física ou jurídica, maior de 18 (dezoito) anos ou devidamente representada pelo responsável legal, crie canais de
audiência em grupos de comunicação gerenciados por meio da Plataforma e realizem a venda de Produto digital no
formato de Coprodução.
<br>
<br>
Ao utilizarem a Plataforma, o Criador e o(s) Coprodutor(es) declaram que o Produto disponibilizado não infringe
direitos de terceiros e que serão integralmente responsáveis pelo Produto. A Hubla não é responsável pelos
Conteúdos e Produtos divulgados pelo Criador e Coprodutor(es) e não terá a obrigação de realizar qualquer
moderação prévia com relação aos Produtos, Conteúdos, informações, postagens e demais disponibilizadas pela
Plataforma.
<br>
<br>
A Hubla se reserva o direito de modificar estes Termos de maneira unilateral, garantido, entretanto, que os
membros serão devidamente notificados sobre eventuais alterações. A Hubla manterá pública e acessível
quaisquer novas versões deste Termo nos Canais da Hubla, além de disponibilizá-las no e-mail do membro
cadastrado.
<br>
<br>
<strong>2. COPRODUÇÃO NA PLATAFORMA DA HUBLA</strong>
<br>
<br>
A Coprodução é a maneira facilitada de disponibilizar um Produto digital que tenha sido criado em parceria entre o
Criador e o(s) Coprodutor(es). Desta forma, a funcionalidade de Coprodução da Plataforma da Hubla permitirá que
os Parceiros do Produto recebam os valores de acordo com a participação indicada pelo Criador no momento do
convite de parceria do Produto.
<br>
<br>
<strong>3. VIGÊNCIA</strong>
<br>
<br>
A “Data de Vigência” destes Termos iniciam-se na data em que o Produto for publicado pelo Criador na Plataforma
da Hubla e aceito pelo(s) Coprodutor(es). Estes Termos têm início na Data de Vigência informada pelo Criador e
continuarão em vigor até a data de expiração informada pelo Criador (“Data Final”). Estes Termos permanecerão
em vigor enquanto o Produto estiver disponível, exceto se o Criador cancelar a Parceria na Página do Produto ,mediante a 
concordância do(s) Coprodutor(es) ou em caso de violação dos Termos de Uso da Plataforma e/ou demais políticas da Hubla.
<br>
<br>
<strong>4. DISPONIBILIZAÇÃO DO PRODUTO</strong>
<br>
<br>
Para iniciar a disponibilização do Produto, o Criador deverá realizar o cadastro da Parceria na Plataforma e as
Partes deverão observar as seguintes condições:
<br>
<br>
<strong>4.1.CONDIÇÕES GERAIS DO PRODUTO</strong>
<br>
<br>
<strong>Informações gerais sobre o Produto</strong>
<br>
<br>
Ao submeter o Produto na Hubla, o Criador informará sobre a Coprodução e fornecerá algumas informações sobre o(s) Coprodutor(es).
<br>
<br>
<strong>Informações sobre os Coprodutor(es)</strong>
<br>
<br>
Para que o Produto seja submetido no formato de Coprodução, o Criador fornecerá informações e dados pessoais, como e-mail e celular
do(s) Coprodutor(es), para que sejam apresentadas as condições da Hubla indicadas no presente Termo. Ao submeter as informações, o Criador 
deverá aceitar previamente as condições previstas neste documento.
<br>
<br>
<strong>Percentuais de Criação</strong>
<br>
<br>
<strong>O Criador deverá indicar o percentual de contribuição do Criador e do(s) Coprodutor(es) nos Conteúdos inseridos no Produto</strong>
<br>
<br>
<strong>Taxas da Hubla</strong>
<br>
<br>
À presente relação, serão aplicáveis as Taxas da HUBLA descritas no item “5” abaixo.
<br>
<br>
<strong>Convite ao(s) Coprodutor(es) </strong>
<br>
<br>
A Hubla enviará o convite ao(s) Coprodutor(es), com a disponibilização dos presentes Termos e as informações sobre o
percentual de participação indicado pelo Criador.
<br>
<br>
<strong>Aceite do(s) Coprodutor(es)</strong>
<br>
<br>
O(s) Coprodutor(es) deverá(ão) aceitar integralmente as condições informadas pelo Criador para que o Produto seja disponibilizado pela
Plataforma. Desde já, o(s) Coprodutor(es) entende(m) e concorda(m) que é de única responsabilidade do Criador: (a) a indicação do prazo
de expiração dos Produtos e; (b) a definição dos percentuais de criação do Produto.
<br>
<br>
<strong>Solicitação do(s) Coprodutor(es)</strong>
<br>
<br>
Caso o(s) Coprodutor(es) não concorde(m) com as condições e/ou percentuais apresentados pelo Criador no momento da
disponibilização do Produto, deverá(ão) entrar em contato diretamente com o Criador.
<br>
<br>
<strong>Revisão das condições do Produto</strong>
<br>
<br>
O(s) Coprodutor(es) poderá(ão) solicitar a revisão das condições do Produto apresentadas pelo Criador. Se o Criador estiver de acordo,
solicitará à Hubla a revisão das condições definidas para o Produto e os Termos serão novamente encaminhados ao(s) Coprodutor(es).
Caso o Criador não esteja de acordo com a proposta do(s) Coprodutor(es), poderá cancelar o convite da Parceria, a seu exclusivo
critério.
<br>
<br>
<strong>Acompanhamento das Vendas</strong>
<br>
<br>
O Criador e o(s) Coprodutor(es)
poderão acompanhar a Parceria
por meio da sua área de acesso
logado.
<br>
<br>
<strong>Pagamento das vendas e alteração das condições</strong>
<br>
<br>
A Hubla irá disponibilizar ao Criador e Coprodutor(es), nas respectivas áreas de acesso logados, os valores líquidos relacionadas às vendas
dos Produtos para os Usuários Consumidores na Plataforma, de acordo com os percentuais definidos no momento do cadastro pelo
Criador. Desde já, o Criador e o(s) Coprodutor(es) autorizam a Hubla a deduzir as Taxas (“Taxa Hubla”, “Taxa de Processamento” “Taxa de Grupos”) do valor das vendas realizadas pela Plataforma.
O(s) Coprodutor(es) não poderão alterar qualquer condição prevista no presente Termo ou solicitar a edição da Parceria pela Plataforma.
<br>
<br>
<strong>Responsabilidade da Hubla</strong>
<br>
<br>
O Criador e o(s) Coprodutor(es) eximem a Hubla de qualquer controvérsia relacionada ao período de
disponibilização e vigência do Produto, as condições do Produto e aos percentuais relacionados à criação
de Conteúdos inseridos no Produto.
<br>
<br>
<strong>5. TAXAS HUBLA</strong>
<br>
<br>
TAXAS PARA UTILIZAÇÃO DA PLATAFORMA: As taxas listadas abaixo (“Taxas da Hubla) serão aplicadas a todas as vendas
realizadas pelas Partes por meio da Plataforma Hubla.
<br>
<br>
<strong>Taxas da Hubla</strong>
<br>
<br>
<strong>Taxa de Serviço HUBLA</strong>
<br>
<br>
Percentual padrão de 8,9% sobre o valor de cada venda realizada dentro da Plataforma;
Taxa de Processamento
R$ 2,49 por cada transação processada via Plataforma durante a vigência desse Termo de Uso;
Taxa de Grupos (aplicável única e exclusivamente em caso de utilização do serviço de Gestão de Grupos)
R$ 0,99 por cada fatura emitida para um USUÁRIO CONSUMIDOR e paga por ele para acesso ao Produto
<br>
<br>
<strong>6. ACOMPANHAMENTO DA CARTEIRA E SAQUE DOS VALORES</strong>
<br>
<br>
O Criador e o(s) Coprodutor(es) poderão solicitar o saque dos valores relacionados às vendas dos Produtos de
Coprodução, de acordo com as condições estabelecidas abaixo:
<br>
<br>
<strong>Caso a venda tenha sido realizada por cartão de crédito:</strong>
<br>
<br>
O saque poderá ser realizado no prazo de 30 (trinta) dias, a contar do fim do prazo de 7 (sete) dias para arrependimento
do Usuário Consumidor.
<br>
<br>
<strong>Caso a venda tenha sido realizada por boleto bancário ou pix:</strong>
<br>
<br>
O saque poderá ser realizado após 8 (oito) dias, a contar da compensação da venda;
<br>
<br>
As Partes declaram ciência que no momento da transferência do valor da venda, do gateway de pagamento para a
sua conta corrente, haverá incidência de uma taxa de saque, a ser informada no momento da transferência (“Taxa
de Saque”).
<br>
<br>
Aplicam-se ainda, as disposições previstas no item 5.3 “Adiantamento das Vendas”, item 5.4 “Valores de Venda
Líquidos”, item 5.5 “Retenções em virtude da plataforma de pagamento online” e seguintes dos Termos de Uso da
Plataforma.
<br>
<br>
<strong>7. NOTA FISCAL</strong>
<br>
<br>
A HUBLA emitirá mensalmente a Nota Fiscal devida para o Criador, bem como para a Coprodutor, tais notas serão emitidas
de forma individual e proporcional à participação de cada um no Produto.
A Nota Fiscal emitida pela HUBLA se refere aos valores retidos por ela à título de Taxas da HUBLA, no respectivo mês.
A Nota Fiscal relativa à compra feita pelo cliente final, será emitida pelo próprio Criador e/ou Coprodutor, o qual
poderá fazer uso de funcionalidade disponível para contratação na Plataform, para a realização de tal procedimento.
<br>
<br>
<strong>8. REGRAS DE USO DA PLATAFORMA HUBLA</strong>
<br>
<br>
<strong>8.1.NÃO FAÇA USO INDEVIDO DA PLATAFORMA</strong>
<br>
<br>
Toda e qualquer utilização dos Serviços da Hubla em desrespeito à Lei, aos nossos Termos e demais Políticas poderá
resultar na suspensão da conta, remoção do conteúdo ou até mesmo na desativação da conta. Logo, o Criador e
o(s) Coprodutor(es) não poderão utilizar a Plataforma Hubla para fins diversos dos indicados nas Políticas da Hubla,
tais como:
<br>
<br>
(a) Violar direitos de outras pessoas, fornecer informações ilegais, enganosas, discriminatórias, criminosas,
e/ou fraudulentas, incitar violência ou crimes, praticar discurso de ódio;
<br>
<br>
(b) Inserir Conteúdo e fotos de ordem pornográfica, que atentem contra a dignidade e a paz social, bem como
infrinjam explicitamente a legislação brasileira vigente;
<br>
<br>
(c) Divulgar anúncios ou serviços socialmente excludentes e/ou com conotação discriminatória, que de alguma
forma violem a Constituição Federal e os princípios constitucionais, a legislação vigente ou, ainda direitos
de terceiros, sendo expressamente vedada a divulgação de Conteúdo ou anúncios que possuam referências
discriminatórias relacionada à idade, raça, etnia, condições de saúde (incluindo gravidez), orientação
sexual, aspectos familiares, sexo, convicções políticas, religiosas, filosóficas, origem social, naturalidade,
nacionalidade ou quaisquer outras formas de discriminação, salvo quando a natureza da atividade a ser
exercida, pública e notoriamente assim exigir;
<br>
<br>
(d) Utilizar de aplicações, ferramentas, mecanismos, recursos ou soluções que violem a privacidade dos
titulares de dados pessoais ou utilizar os dados pessoais dos Usuários em desconformidade com as
finalidades descritas na Política de Privacidade da Hubla ou em desconformidade com a Legislação de
Proteção de Dados Aplicável;
<br>
<br>
(e) Violar direitos de propriedade intelectual de terceiros, bem como os direitos da Hubla;
<br>
<br>
(f) Publicar Conteúdo que infrinja o direito autoral e/ou a propriedade intelectual de terceiros;
<br>
<br>
(g) Criar Grupos com caráter ilícito e com objetivos e/ou indícios criminosos e/ou fraudulentos;
<br>
<br>
(h) Criar Grupos de Repasse;
<br>
<br>
(i) Repassar informações e/ou dicas entre Grupos, seja por meio de Grupo de Repasse ou de qualquer outra
forma.
<br>
<br>
<strong>9. AUTORIZAÇÃO DE IMAGEM</strong>
<br>
<br>
O Criador e o(s) Coprodutor(es) autorizam que a sua imagem seja veiculada na Plataforma Hubla e concedem,
mediante a concordância dos presentes Termos, de forma gratuita, irrevogável e irretratável, à Hubla, a autorização
para fotografar, filmar, gravar, registrar, captar e/ou de qualquer outra forma fixar e utilizar todos os elementos e
características de sua Imagem e Direitos Conexos, bem como quaisquer fotos ou vídeos relacionados à divulgação
do Produto na Plataforma Hubla.
<br>
<br>
Além disso, por envolver a associação de imagem do Criador e do(s) Coprodutor(es), as Partes se responsabilizam
quanto a preservação da sua imagem perante os Usuários Consumidores e a Sociedade. Caso as Partes se envolvam
em situações que tragam danos reputacionais ou pratiquem condutas eticamente reprováveis (“Situações de riscos
reputacionais”), que maculem a sua imagem e que, por sua vez, venham a prejudicar a Hubla, a Hubla poderá
rescindir os presentes Termos, sem prejuízo de indenização pelos eventuais prejuízos sofridos.
<br>
<br>
Na ocorrência de Situações de riscos reputacionais, a Hubla poderá, no prazo de 24h (vinte e quatro horas), retirar
de circulação todo e qualquer Produto e/ou Conteúdo que conste a imagem, nome, som de voz, marca,
pseudônimo ou qualquer outro atributo que remeta à personalidade do Criador e do(s) Coprodutor(es). A Hubla
fica, ainda, desde já autorizada a remover eventuais conteúdos dos grupos pagos, caso seja possível.
<br>
<br>
<strong>10. REDES SOCIAIS</strong>
<br>
<br>
As postagens do Criador e/ou Coprodutor(es) em redes sociais, quando relacionadas a divulgação do Produto ou
Conteúdo publicados pela Hubla, deverão, obrigatoriamente, ser identificadas como postagem publicitária,
conforme indicado no <a target="_blank" href="http://conar.org.br/pdf/CONAR_Guia-de-Publicidade-Influenciadores_2021-03-11.pdf">Guia de Publicidade por Influenciadores Digitais</a>,
com pelo menos uma das seguintes hashtags: “publicidade”, “anúncio”, “patrocinado”, “conteúdo pago”, “parceria paga”.
<br>
<br>
<strong>11. DIREITOS DE PROPRIEDADE INTELECTUAL DO PRODUTO E CONTEÚDO</strong>
<br>
<br>
Será de responsabilidade integral do Criador e do(s) Coprodutor(es) o cumprimento dos respectivos direitos de
propriedade intelectual relacionados aos Conteúdos dos Produtos disponibilizados na Plataforma da Hubla. O
Criador e o(s) Coprodutor(es) declaram que possuem todas as autorizações e licenças necessárias para utilizar
qualquer material relacionado aos Conteúdos, como por exemplo: imagem, voz e direitos conexos de terceiros,
fotografias, trilhas musicais, efeitos sonoros, locução, animações, letreiros, packshot, etc.).
<br>
<br>
Por estes Termos, o Criador e o(s) Coprodutor(es) licenciam e autorizam a Hubla, o uso temporário, específico e
exclusivo, de determinados direitos relacionados ao Produto e ao Conteúdo divulgados na Plataforma da Hubla.
A presente licença abrange o uso do nome pessoal e artístico, imagem, som de voz, aparência, perfil e características,
Conteúdos e os direitos autorais e conexos do Criador e do(s) Coprodutor(es).
<br>
<br>
<strong>12. CONFIDENCIALIDADE</strong>
A Hubla e as Partes deverão manter o sigilo e a não revelar a terceiros, em quaisquer circunstâncias, informações
decorrentes dos Serviços que são objeto dos presentes Termos. Assim, a Hubla, o Criador e o(s) Coprodutor(es)
tratarão de forma estritamente confidencial as informações levadas a seu conhecimento e somente as utilizarão no
âmbito dos serviços ora pactuados. Entende-se por "Informações Confidenciais" todas as informações exclusivas de
uma Parte ("Parte Reveladora") revelados à outra Parte ("Parte Receptora"), de modo verbal ou por escrito, que
sejam indicadas como confidenciais ou que pelo bom senso devam ser consideradas confidenciais em vista da
natureza das informações, especialmente em relação às informações fornecidas pelo Criador e/ou Coprodutor(es)
no momento do cadastro ou posteriormente, que serão utilizadas para fins dos presentes Termos. A Hubla e as
Partes concordam em resguardar o sigilo das Informações Confidenciais recebidas.
<br>
<br>
<strong>13. RESPONSABILIDADE DO CRIADOR E COPRODUTOR(ES)</strong>
<br>
<br>
O Criador e o(s) Coprodutor(es) serão exclusivamente responsáveis:
<br>
<br>
(i) por todos e quaisquer atos e/ou omissões realizados a partir do seu acesso na Plataforma ou por atos ou
omissões por ele ou por terceiros autorizados realizados durante a prestação dos Serviços;
<br>
<br>
(ii) por todo e qualquer conteúdo por eles carregados, enviados e/ou transmitidos na Plataforma Hubla;
<br>
<br>
(iii) pela reparação de todos e quaisquer danos, diretos ou indiretos (inclusive decorrentes de violação de
quaisquer direitos de Usuários, de terceiros, inclusive direitos patrimoniais, danos materiais, danos morais, de
propriedade intelectual, de confidencialidade e de personalidade), que sejam causados à Hubla, a qualquer
outro Usuário, ou, ainda, a qualquer terceiro, inclusive em virtude do descumprimento do disposto nestes
Termos ou de qualquer ato praticado a partir de seu acesso à Internet e à Plataforma.
<br>
<br>
<strong>14. POLÍTICA DE PRIVACIDADE</strong>
<br>
<br>
A Hubla tratará os dados pessoais do Criador e do(s) Coprodutor(es) para a realização do cadastro e para a
divulgação do Produto na Plataforma, de acordo com as disposições da Política de Privacidade da Hubla, que é
parte integrante destes Termos.
<br>
<br>
<strong>15. CANAIS DE COMUNICAÇÃO</strong>
<br>
<br>
Caso queira ou precise entrar em contato com a Hubla para qualquer dúvida que tiver em relação à Plataforma, por
favor, entre em contato conosco pelo suporte disponível no Site.
<br>
<br>
<strong>16. ASSINATURA ELETRÔNICA</strong>
<br>
<br>
As Partes aceitam e concordam com o processo de assinatura eletrônica destes Termos e reconhecem a assinatura
eletrônica como válida, plenamente eficaz e tendo o mesmo efeito e validade jurídica do processo manual de
assinatura para que estes Termos sejam considerados um título executivo extrajudicial, ainda que seja estabelecida
com certificação fora dos padrões ICP- BRASIL, conforme disposto pelo Art. 10, § 2º da Medida Provisória n. º
2.200/2001 em vigor no Brasil. Para tal, a Hubla manterá os logs de aceite aos presentes Termos. As Partes
concordam que não contestarão a autenticidade ou correção destes Termos pelo único motivo de sua assinatura
ter sido efetuada apenas em formato eletrônico.
<br>
<br>
<strong>17. INVALIDADE PARCIAL</strong>
<br>
<br>
A invalidade ou inexequibilidade de qualquer disposição contida nestes Termos não afetará a validade e
exequibilidade de qualquer outra disposição do mesmo e, se qualquer disposição for considerada de qualquer
forma inválida ou inexequível, as outras disposições aqui estabelecidas permanecerão em vigor e estes Termos
deverão ser interpretados como se as disposições invalidadas ou inexequíveis não existissem.
<br>
<br>
<strong>18. AUSÊNCIA DE VÍNCULO</strong>
<br>
<br>
As disposições destes Termos não implicam qualquer tipo de sociedade ou associação entre a Hubla e as Partes,
bem como vínculo empregatício do Criador e do(s) Coprodutor(es) com a Hubla, não resultando qualquer
responsabilidade solidária ou conjunta.
<br>
<br>
<strong>19. TOLERÂNCIA</strong>
<br>
<br>
O atraso ou abstenção do exercício do direito, por parte da Hubla, em exigir o cumprimento de qualquer obrigação
estabelecida nos presentes Termos não significará renúncia ou novação deste. A eventual tolerância da Hubla em
relação à violação de qualquer disposição dos presentes Termos não representará tolerância com violações
subsequentes, independentemente de tais violações ocorrerem sob circunstâncias similares ou diversas.
<br>
<br>
<strong>20. FORO</strong>
<br>
<br>
Estes Termos serão regidos e interpretados de acordo com as leis brasileiras e quaisquer disputas oriundas destes
Termos que não puderem ser amigavelmente solucionadas pelas Partes, deverão ser submetidas ao Foro da
Comarca da Capital do Estado de São Paulo.
`
