import { Banner } from '../components/banner'
import { Sidebar } from '../components/sidebar'

export const MembersAreaPage = () => {
  return (
    <div>
      <Sidebar />
      <Banner />
    </div>
  )
}
