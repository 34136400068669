import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation } from '@tanstack/react-query'
import { QueryKey } from '../../domain/enums/query-key'
import { RegisterUserFeedback } from '../usecases/register-user-feedback'
import { NewExperienceFeedback } from '../../domain/dtos/new-experience-feedback'

export const useRegisterUserFeedback = () => {
  const container = useContainer()

  return useMutation(
    [QueryKey.registerUserFeedback],
    (input: NewExperienceFeedback) => new RegisterUserFeedback(container.get(HttpClient)).execute(input),
    {
      retry: false,
    },
  )
}
