import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useQuery } from '@tanstack/react-query'
import { CountryCode } from '../../../domain/constants/country-code'
import { Address, GetAddress } from '../../../usecases/settings/get-address'

export const useGetAddress = (country: string, postalCode?: string, onSuccess?: (data: Partial<Address>) => void) => {
  const container = useContainer()

  return useQuery(
    ['get-address', postalCode],
    ({ signal }) => new GetAddress(container.get(HttpClient)).execute(postalCode!, signal),
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!postalCode && country === CountryCode.BR,
      onSuccess,
    },
  )
}
