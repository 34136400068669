import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { EditOfferResponseDto } from '@hub-la/sdk-bff-product'
import { inject } from 'inversify'
import { GetOfferInput } from '../domain/dtos/get-offer-input'
import { GeneralError } from '../domain/errors/general'
import { Envs } from '../envs'

export class GetOffer {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient<EditOfferResponseDto>,
  ) {}

  public async execute(input?: GetOfferInput, signal?: AbortSignal): Promise<EditOfferResponseDto | undefined> {
    const response = await this.httpClient.request({
      method: HttpMethod.GET,
      url: `${Envs.BFF_PRODUCT_URL}/products/${input?.productId}/offers/${input?.offerId}/edit`,
      signal,
    })

    if ([HttpStatusCode.OK, HttpStatusCode.CREATED].includes(response.statusCode)) {
      return response?.data
    }

    throw new GeneralError()
  }
}
