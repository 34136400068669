import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { GetSmartInstallemntCyclesSummarie } from '../domain/dtos/get-smart-installment-cycles-summarie'
import { Envs } from '../envs'
import { GetSmartInstallemntCycleOutput } from '../domain/dtos/get-smart-installment-cycle'

export class GetSmartInstallmentCycle {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) { }

  public async execute(smartInstallmentCycleId: string): Promise<GetSmartInstallemntCycleOutput | undefined> {
    const response = await this.httpClient.request({
      method: HttpMethod.POST,
      url: `${Envs.BFF_MEMBER_PORTAL_URL}/smart-installment-cycle`,
      body: {
        smartInstallmentCycleId
      }
    })

    if (response.statusCode === HttpStatusCode.OK || response.statusCode === HttpStatusCode.CREATED) {
      return response.data
    }

    throw new Error('Não foi possível buscar o valores pendentes, agendados e atrasados')
  }
}
