import { MAX_FILE_SIZE } from '../domain/dtos/max-file-size'

export class HasFileValidSize {
  public execute(size: number): boolean {
    if (size > MAX_FILE_SIZE) {
      return false
    }
    return true
  }
}
