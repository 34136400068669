import { Card, CardContent, CardHeader, cn, Stream } from "@hub-la/shadcn"
import { ChevronRight } from "lucide-react"
import { formatCurrency } from "../../../../domain/vos/format-currency"
import { useTranslation } from "react-i18next"
import { format } from "date-fns"
import { SmartInstallmentCycle } from "../../../../domain/dtos/smart-installment-cycle"
import { SmartInstallmentStatus } from "../../../../domain/enums/smart-installment-status"
import { SmartInstallmentCycleStatus } from "../../../../domain/enums/smart-installment-cycle-status.enum"
import { useHistory } from "react-router-dom"

export const SmartInstallmentCycleCard = ({ smartInstallmentCycle }: { smartInstallmentCycle: SmartInstallmentCycle }) => {
  const { t } = useTranslation()
  const history = useHistory()

  const isOverdue = SmartInstallmentCycleStatus.STATUS_OFF_SCHEDULE === smartInstallmentCycle.status

  const date = smartInstallmentCycle.scheduleOffAt ?
    t('scheduleOffAt', { date: format(new Date(smartInstallmentCycle.scheduleOffAt), 'dd MMM. yyyy') }) :
    t('nextDueAt', { date: format(new Date(smartInstallmentCycle.nextDueAt), 'dd MMM. yyyy') })

  return (
    <Card className="text-sm flex flex-col md:flex-row md:items-center hover:bg-secondary/80 cursor-pointer" onClick={() => history.push(`/user_smart_installments/${smartInstallmentCycle.id}`)}>
      <CardHeader className="pr-4">
        <div className="flex items-center">
          <div className={cn("rounded-full max-w-max p-4 [&>*]:w-5 [&>*]:h-5 bg-red-100 text-red-400 dark:bg-red-950", isOverdue ? "bg-red-100 text-red-400 dark:bg-red-950" : "bg-muted")}>
            <Stream />
          </div>
        </div>
      </CardHeader>
      <CardContent className="flex-1 flex items-center justify-between md:pb-0 md:pl-0">
        <div className="flex-1">
          <h4 className="font-medium">{smartInstallmentCycle.productName}</h4>
          <span className="text-muted-foreground">{t('paidInstallments', { installment: smartInstallmentCycle.paidInstallments, installments: smartInstallmentCycle.installments })}</span>
          <p className="text-muted-foreground">{date}</p>
        </div>
        <div>
          <p>{formatCurrency(smartInstallmentCycle.totalAmount / 100)}</p>
          <p className="text-muted-foreground">{t('draftTotalAmount')}</p>
        </div>
        <ChevronRight className="h-5 w-5 hidden md:block ml-4" />
      </CardContent>
    </Card>
  )
}
