import parsePhoneNumber from 'libphonenumber-js'
import { CountryCode } from './country'

export class Phone {
  public constructor(private readonly number: string) {}

  public static build(number: string): Phone {
    const parsedNumber = number.startsWith('+') ? number : `+${number}`
    return new Phone(parsedNumber)
  }

  public getFormattedNumber(): string {
    const parsedNumber = parsePhoneNumber(this.number)
    if (!parsedNumber) {
      return ''
    }
    if (parsedNumber.country === CountryCode.BR && parsedNumber.nationalNumber.length === 11) {
      return `${parsedNumber.formatNational()}`
    } else if (parsedNumber.country === CountryCode.BR && parsedNumber.nationalNumber.length === 10) {
      const ddd = this.number.slice(3, 5)
      const part1 = this.number.slice(5, 9)
      const part2 = this.number.slice(9)

      return `(${ddd}) ${part1}-${part2}`
    } else {
      return parsedNumber.formatInternational()
    }
  }
}
