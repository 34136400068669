import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@hub-la/shadcn'
import { useTranslation } from 'react-i18next'
import { useRequestCancellation } from '../../../../hooks/settings/use-request-cancellation'

type ConfirmDeletePartnerModalProps = {
  receiverId: string
  open: boolean
  onClose: () => void
  onSuccess?: () => void
}

export const ConfirmDeletePartnerModal = (props: ConfirmDeletePartnerModalProps) => {
  const { receiverId, open, onClose, onSuccess } = props
  const { t } = useTranslation()
  const { mutateAsync: requestCancellation, isLoading } = useRequestCancellation()

  const onSubmit = () => {
    requestCancellation(receiverId).then(() => {
      onClose()
      onSuccess?.()
    })
  }

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{t('settings.myPartners.deleteModal.title')}</DialogTitle>

          <DialogDescription>{t('settings.myPartners.deleteModal.description')}</DialogDescription>
        </DialogHeader>

        <DialogFooter className="sm:justify-start">
          <div className="w-full sm:w-auto flex flex-col-reverse sm:flex-row gap-2 sm:gap-4">
            <Button variant="outline" disabled={isLoading} onClick={onClose}>
              {t('settings.myPartners.deleteModal.cancel')}
            </Button>

            <Button variant="destructive" disabled={isLoading} onClick={onSubmit}>
              {isLoading ? 'Loading...' : t('settings.myPartners.deleteModal.confirm')}
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
