import { AboutAssetDtoTypeEnum, UpdateProductSettingsPageRequestDto } from '@hub-la/sdk-bff-product'
import { Button, Card, CardContent } from '@hub-la/shadcn'
import { useFormik } from 'formik'
import { GripVertical, Link, Trash2 } from 'lucide-react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { YoutubeObject } from '../../../../domain/dtos/youtube-object'
import { Section } from '../../../components/section'
import { useSaveSettings } from '../../../hooks/settings/use-save-settings'
import { InputMedia } from '../components/input-media'

type Values = Pick<UpdateProductSettingsPageRequestDto, 'assets'>
const validationSchema = Yup.object().shape({})
const makeInitialValues = ({ assets }: Values): Values => {
  return {
    assets: assets ?? undefined,
  }
}

type Props = {
  assets: Values['assets']
  loading?: boolean
}

export const MediaSection: React.FC<Props> = ({ assets, loading }) => {
  const { productId } = useParams<{ productId: string }>()
  const { mutateAsync: saveSettings } = useSaveSettings()
  const { t } = useTranslation()

  const formik = useFormik<Values>({
    validationSchema,
    validateOnMount: true,
    validateOnChange: true,
    enableReinitialize: true,
    initialValues: makeInitialValues({ assets }),
    onSubmit: async (values) => {
      await saveSettings({ productId, type: 'page', data: values })
    },
  })

  const onDragEnd = (result) => {
    if (!result.destination) return

    const reorderedAssets = Array.from(formik.values.assets ?? [])
    const [movedItem] = reorderedAssets.splice(result.source.index, 1)
    reorderedAssets.splice(result.destination.index, 0, movedItem)

    formik.setFieldValue('assets', reorderedAssets)
  }

  return (
    <Section
      title={t('settings.productPage.media.title')}
      subtitle={t('settings.productPage.media.subtitle')}
      formik={formik}
    >
      <div className="space-y-4">
        <div className="flex flex-col md:flex-row gap-4">
          <div className="flex flex-col gap-2 w-full">
            <h3 className="text-lg font-semibold">{t('settings.productPage.media.input.title')}</h3>
            <p className="text-sm text-muted-foreground">{t('settings.productPage.media.input.subtitle')}</p>
          </div>

          {(formik.values.assets?.length ?? 0) < 12 && (
            <InputMedia
              productId={productId}
              onImageInput={(file) =>
                formik.setFieldValue('assets', [
                  ...(formik.values.assets ?? []),
                  {
                    name: file?.name,
                    url: file?.URL,
                    type: AboutAssetDtoTypeEnum.IMAGE,
                  },
                ])
              }
              onYoutubeInput={(youtubeObject: YoutubeObject) =>
                formik.setFieldValue('assets', [
                  ...(formik.values.assets ?? []),
                  {
                    id: youtubeObject.id,
                    name: 'Video do YouTube',
                    thumbnailUrl: youtubeObject.thumbnail,
                    url: youtubeObject.embed,
                    type: AboutAssetDtoTypeEnum.YOUTUBE,
                  },
                ])
              }
              disabled={loading}
            />
          )}
        </div>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="assets">
            {(provided) => (
              <div className="space-y-3" {...provided.droppableProps} ref={provided.innerRef}>
                {(formik.values.assets ?? [])?.map((asset, idx) => (
                  <Draggable key={idx} draggableId={String(idx)} index={idx}>
                    {(provided) => (
                      <Card ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        <CardContent className="p-4">
                          <div className="flex items-center justify-between">
                            <div className="flex items-center gap-3">
                              <GripVertical className="text-muted-foreground" />

                              <img
                                src={asset.thumbnailUrl ?? asset.url}
                                alt={asset.name}
                                className="w-[61px] h-auto aspect-[218/142] rounded-md object-cover"
                              />

                              <div className="flex flex-col gap-1">
                                <p className="text-sm">{asset.name}</p>
                                {asset.type === AboutAssetDtoTypeEnum.YOUTUBE && (
                                  <div className="flex items-center gap-1">
                                    <Link className="w-3 h-3" />

                                    <p className="text-xs text-muted-foreground break-all">{asset.url}</p>
                                  </div>
                                )}
                              </div>
                            </div>

                            <Button
                              variant="ghost"
                              size="icon"
                              onClick={() =>
                                formik.setFieldValue(
                                  'assets',
                                  formik.values.assets?.filter((_, index) => index !== idx),
                                )
                              }
                            >
                              <Trash2 className="h-4 w-4" />
                            </Button>
                          </div>
                        </CardContent>
                      </Card>
                    )}
                  </Draggable>
                ))}

                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </Section>
  )
}
