import { container } from '@hub-la/fe-container'
import { FreeMembersPage, FreeMembersProvider } from '@hub-la/fe-free-members'

export const FreeMembersInit = () => {
  return (
    <FreeMembersProvider container={container}>
      <FreeMembersPage />
    </FreeMembersProvider>
  )
}
