import { PostStatus, PostSubmitted } from '../domain/dtos'

export const makePostSubmittedData = (data: PostSubmitted) => ({
  hasAttachment: data.attachments && data.attachments.length > 0 ? true : false,
  userId: data.userId,
  productId: data.productId,
  postStatus: data.isUpdatePost ? PostStatus.DRAFT : PostStatus.PUBLISHED,
  hasMainContent: data.mainMediaProgressStatus.length > 0 ? true : false,
  mainContentType: data.mainContentType,
  hasSubtitle: data.subtitle !== '' ? true : false,
  hasBodyContent: data.content !== '' ? true : false,
})
