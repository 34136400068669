/**
 * GetAvailableOptions class provides functionality to filter available offers based on applicability.
 */
import { CouponItem } from '../domain/dtos/coupon'
import { Offer } from '../domain/dtos/offer'

export class GetAvailableOptions {
  /**
   * Executes the filtering of available offers based on provided offers and applicability information.
   *
   * @param offers - An array of Offer objects representing all available offers.
   * @param appliableTo - An array of CouponItem objects representing the applicability of the offers.
   * @returns An array of Offer objects that are applicable based on the provided applicability information.
   */
  public execute(offers: Offer[], appliableTo: CouponItem[]): Offer[] {
    // Extract unique intervals from appliableTo array.
    const appliableToIntervals = [...new Set(appliableTo.flatMap((item) => item.intervals))]
    // Filter out the 'all' option from the available offers.
    const offersWithoutAllOption = offers.filter((offer) => offer.id !== 'all')

    // If no applicability information provided, return offers without the 'all' option.
    if (!appliableTo || appliableTo.length === 0) {
      return offersWithoutAllOption
    }

    const offersIdMap = offers.reduce((acc, item) => {
      acc[item.id] = item
      return acc
    }, {})

    // Filter appliableTo array to get applicable offers based on offerId.
    const appliableToOffers =
      appliableTo
        // Since appliableTo can only have it's intervals, filter out the ones without offerId.
        .filter((it) => it.offerId)
        // appliableTo can have deleted offers, so filter out the ones that are not in the offers array.
        .filter((it) => offers.map((it) => it.id).includes(it.offerId ?? ''))
        .map((it) => {
          return {
            id: it.offerId,
            name: it.offerName ? it.offerName : it?.offerId ? offersIdMap[it?.offerId ?? ''].name : 'Desconhecido',
            intervals: it.intervals,
          } as Offer
        }) ?? []

    // If applicable offers found, return them.
    if (appliableToOffers.length > 0) {
      return appliableToOffers
    }

    // Filter available offers based on common intervals with appliableTo.
    return offersWithoutAllOption.filter((offer) => {
      return (offer.intervals ?? []).some((interval) => appliableToIntervals.includes(interval))
    })
  }
}
