import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import moment from 'moment'
import { DownloadSmartInstallmentsXlsxInput } from '../domain/dtos/download-smart-installments-xlsx-input'
import { ExportSmartInstallmentsOutput } from '../domain/dtos/export-smart-installments-output'
import { ExportAlreadyInProgressError } from '../domain/errors/export-already-in-progress'
import { GeneralError } from '../domain/errors/general'
import { Envs } from '../envs'
import { GetOfferIds } from './get-offer-ids'

export class DownloadSmartInstallmentsXlsx {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(input: DownloadSmartInstallmentsXlsxInput): Promise<ExportSmartInstallmentsOutput> {
    const response = await this.httpClient.request({
      method: HttpMethod.POST,
      url: `${Envs.BFF_WEB_URL}/smart-installments/background-export`,
      body: this.makeBody(input),
    })

    if ([HttpStatusCode.OK, HttpStatusCode.CREATED].includes(response.statusCode)) {
      return response.data
    }

    if (response.statusCode === HttpStatusCode.CONFLICT) {
      throw new ExportAlreadyInProgressError()
    }

    throw new GeneralError()
  }

  private makeBody(input: DownloadSmartInstallmentsXlsxInput) {
    const offerIds = new GetOfferIds().execute({ offerIds: input.offerIds, offers: input.offers })

    return {
      offerIds,
      timezone: moment().format('Z'),
      filters: {
        status: input.statuses,
      },
      receiverEmail: input.receiverEmail || undefined,
    }
  }
}
