import { useHistory } from 'react-router-dom'

export const QueryParamAdapter = ({ children }) => {
  const history = useHistory()

  const adapter = {
    replace(location) {
      history.replace({ ...history.location, search: location.search }, location.state)
    },
    push(location) {
      history.push({ ...history.location, search: location.search }, location.state)
    },
    get location() {
      return history.location
    },
  }

  return children(adapter)
}
