import { AssetResponse } from '@hub-la/fe-asset'
import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useQuery } from '@tanstack/react-query'
import { GetPdfFileUrl } from '../../usecases/get-pdf-file-url'

export const useGetPdfFileUrl = (asset?: AssetResponse) => {
  const container = useContainer()

  const { isSuccess, isError, error, isLoading, data } = useQuery(
    ['get-pdf-file-url', asset?.id],
    () => new GetPdfFileUrl(container.get(HttpClient)).execute(asset),
    {
      initialData: null,
      refetchOnWindowFocus: false,
    },
  )

  return {
    isSuccess,
    isError,
    error,
    isLoading,
    data,
  }
}
