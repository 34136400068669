import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Button,
} from '@hub-la/shadcn'
import { useDeleteShortLink } from '../../../hooks/shortlinks/use-delete-short-link'

type ConfirmDeleteShortLinkModalProps = {
  productId: string
  shortLinkId: string
  open: boolean
  onClose: () => void
  onSuccess?: () => void
}

export const ConfirmDeleteShortLinkModal: React.FC<ConfirmDeleteShortLinkModalProps> = ({
  productId,
  shortLinkId,
  open,
  onClose,
  onSuccess,
}) => {
  const { t } = useTranslation()
  const { mutateAsync: deleteShortLink, isLoading } = useDeleteShortLink()

  const onSubmit = () => {
    deleteShortLink({ productId, shortLinkId }).then(() => {
      onClose()
      onSuccess?.()
    })
  }

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{t('shortlinks.modals.delete.title')}</DialogTitle>
          <DialogDescription>{t('shortlinks.modals.delete.description')}</DialogDescription>
        </DialogHeader>
        <DialogFooter className="sm:justify-end">
          <div className="flex flex-col-reverse sm:flex-row w-full sm:w-auto gap-2 sm:gap-4">
            <Button variant="outline" onClick={onClose}>
              {t('shortlinks.buttons.cancel')}
            </Button>
            <Button variant="destructive" onClick={onSubmit} disabled={isLoading} loading={isLoading}>
              {t('shortlinks.buttons.deleteLink')}
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
