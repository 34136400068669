import { injectable, multiInject } from 'inversify'
import { AnalyticsProperties } from './analytics-properties'
import { AnalyticsProvider } from './analytics-provider'
import { UserIdentifyInput } from './domain/dtos'

@injectable()
export class Analytics {
  public constructor(@multiInject(AnalyticsProvider) private readonly providers: AnalyticsProvider[]) {}

  public identify(user?: UserIdentifyInput): void {
    if (!user) return
    this.providers.forEach((provider) => {
      provider.identify(user)
    })
  }

  public track(event: string, properties?: AnalyticsProperties): void {
    this.providers.forEach((provider) => {
      provider.track(event, properties)
    })
  }

  public page(event: string, properties?: AnalyticsProperties): void {
    this.providers.forEach((provider) => {
      provider.page(event, properties)
    })
  }

  public reset(): void {
    this.providers.forEach((provider) => {
      provider?.reset()
    })
  }
}
