import { Envs } from '../../envs'

export const getAppHost = (): string => {
  return Envs.APP_URL ?? 'http://localhost:3008'
}

export const getSiteHost = (): string => {
  return Envs.SITE_URL ?? 'http://localhost:3000'
}

export const getPayHost = (): string => {
  return Envs.PAY_URL ?? 'http://localhost:3002'
}

export const getAppUrl = (path: string) => {
  return `${getAppHost()}${path}`
}

export const getSiteUrl = (path: string) => {
  return `${getSiteHost()}${path}`
}

export const getGroupUrl = (groupId: string | undefined) => {
  return getSiteUrl(groupId ? `/g/${groupId}` : '/')
}

export const getPayUrl = (groupId: string) => {
  return `${getPayHost()}${groupId}`
}
