import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { GetRefundsInput } from '../domain/dtos/get-refunds-input'
import { GetRefundsOutput } from '../domain/dtos/get-refunds-output'
import { Envs } from '../envs'

export class GetRefunds {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(input: GetRefundsInput, signal?: AbortSignal): Promise<GetRefundsOutput> {
    const response = await this.httpClient.request({
      method: HttpMethod.GET,
      url: `${Envs.BFF_WEB_URL}/payer/refunds`,
      params: this.makeParams(input),
      signal,
    })

    if (response.statusCode === HttpStatusCode.OK) {
      return response.data
    }

    throw new Error('Não foi possível obter os pedidos de reembolso.')
  }

  private makeParams(input: GetRefundsInput) {
    const params = {
      page: input.page,
      pageSize: input.pageSize,
    }

    return params
  }
}
