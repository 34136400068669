import { HttpClient, HttpMethod } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'

type Address = {
  ibgeCode: string
  zip: string
  street: string
  complement: string
  neighborhood: string
  number: string
  state: string
  city: string
  country: string
}

export class GetAddress {
  public constructor(@inject(HttpClient) private readonly httpClient: HttpClient) { }

  public async execute(postalCode: string): Promise<Partial<Address> | undefined> {
    const NON_DIGITS = /\D/g

    if (postalCode.replace(NON_DIGITS, '').length !== 8) {
      return undefined
    }

    const response = await this.httpClient.request({
      method: HttpMethod.GET,
      url: `https://viacep.com.br/ws/${postalCode}/json/`,
      headers: {
        authorization: 'Bearer null',
      },
    })

    const postalCodeData = response.data

    const address: Partial<Address> = {
      ibgeCode: (postalCodeData.ibge as string) ?? '',
      zip: (postalCodeData.cep as string) ?? '',
      street: (postalCodeData.logradouro as string) ?? '',
      neighborhood: (postalCodeData.bairro as string) ?? '',
      state: (postalCodeData.uf as string) ?? '',
      city: (postalCodeData.localidade as string) ?? '',
      country: 'BR',
    }

    return address
  }
}
