import { Button, cn, Progress } from '@hub-la/shadcn'
import { ImagePlus, Trash2 } from 'lucide-react'
import React, { useState } from 'react'
import { IFile } from '../../../domain/dtos'
import { Uploader } from './uploader'

type Props = {
  name: string
  path: string
  value?: string | null
  onChange: (file?: IFile) => void
  obfuscateFileName?: boolean
  className?: string
}

/**
 * ImageUploader component allows users to upload and display images.
 *
 * @param onChange - Callback function triggered when the image is changed or deleted.
 * @param value - The URL of the image to be displayed.
 * @param name - The name of the image uploader itself.
 * @param path - The path where the image will be stored, under private/${path}.
 * @param obfuscateFileName - Whether to obfuscate the file name.
 */
export const ImageUploader: React.FC<Props> = ({
  onChange,
  value,
  name,
  path,
  className = '',
  obfuscateFileName = false,
}: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [progress, setProgress] = useState<number>(0)

  const handleDelete = (event: React.MouseEvent) => {
    event.preventDefault()
    onChange(undefined)
  }

  const handleChange = (file?: IFile) => {
    onChange(file)
  }

  return (
    <div
      className={cn(
        'w-14 h-14 relative flex items-center justify-center overflow-hidden border border-dashed rounded-lg hover:bg-slate-100 transition',
        className,
      )}
    >
      {isLoading ? (
        <Progress value={progress} className="w-3/4 h-1" />
      ) : value ? (
        <>
          <img src={value} alt="" className="w-full h-full object-cover" />

          <Button variant="ghost" size="icon" className="absolute top-1 right-1 w-8 h-8" onClick={handleDelete}>
            <Trash2 className="h-4 w-4" />
          </Button>
        </>
      ) : (
        <Uploader
          onChange={handleChange}
          setLoading={setIsLoading}
          onLoad={setProgress}
          name={name}
          path={path}
          className="w-full h-full flex items-center justify-center"
          obfuscateFileName={obfuscateFileName}
        >
          <ImagePlus className="h-6 w-6 text-muted-foreground" />
        </Uploader>
      )}
    </div>
  )
}
