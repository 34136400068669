import { Skeleton, TableCell, TableRow } from '@hub-la/shadcn'

export const HistoryTableLoading = () => {
  return (
    <TableRow>
      <TableCell>
        <Skeleton className="h-6 w-[70%]" />
      </TableCell>
      <TableCell>
        <Skeleton className="h-6 w-[70%]" />
      </TableCell>
      <TableCell>
        <Skeleton className="h-6 w-[70%]" />
      </TableCell>
      <TableCell>
        <Skeleton className="h-6 w-[70%]" />
      </TableCell>
    </TableRow>
  )
}
