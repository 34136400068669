/**
 * File MIME type, as defined by {@link https://www.iana.org/assignments/media-types/media-types.xhtml IANA}
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types Common MIME types}
 */
export enum AssetType {
  application = 'application',
  image = 'image',
  video = 'video',
  audio = 'audio',
}
