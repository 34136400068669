import { SVGProps } from 'react'

type EmptyLayoutProps = SVGProps<SVGSVGElement>

export const EmptyLayout = (props: EmptyLayoutProps) => {
  return (
    <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M0 1C0 0.447715 0.447715 0 1 0H11C11.5523 0 12 0.447715 12 1V15C12 15.5523 11.5523 16 11 16H1C0.447715 16 0 15.5523 0 15V1Z" fill="currentColor" />
    </svg>
  )
}
