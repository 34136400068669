import { Check, CircleDashed, IterationCw, X } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { InvoiceStatus } from '../../domain/enums/invoice-status'

type InvoiceStatusStatsProps = {
  size?: any
  status: InvoiceStatus
}

const getIcon = (status: InvoiceStatus) => {
  switch (status) {
    case InvoiceStatus.PAID:
      return (
        <div className="bg-green-600  rounded-full w-4 h-4 flex justify-center items-center">
          <Check className="text-primary-foreground w-3 h-3" strokeWidth={3} />
        </div>
      )
    case InvoiceStatus.EXPIRED:
      return (
        <div className="bg-red-700  rounded-full w-4 h-4 flex justify-center items-center">
          <X className="text-primary-foreground w-3 h-3" strokeWidth={3} />
        </div>
      )
    case InvoiceStatus.DRAFT:
    case InvoiceStatus.DISPUTED:
      return <CircleDashed className="w-4 h-4 text-ring" />
    case InvoiceStatus.UNPAID:
      return (
        <div className="bg-amber-600  rounded-full w-4 h-4 flex justify-center items-center">
          <X className="text-primary-foreground w-3 h-3" strokeWidth={3} />
        </div>
      )
    case InvoiceStatus.RETURNED:
      return <IterationCw className="w-4 h-4 text-muted-foreground" />
    case InvoiceStatus.CHARGEBACK:
      return (
        <div className="bg-amber-900  rounded-full w-4 h-4 flex justify-center items-center">
          <X className="text-primary-foreground w-3 h-3" strokeWidth={3} />
        </div>
      )
    default:
      return null
  }
}

export const InvoiceStatusStats = ({ status }: InvoiceStatusStatsProps) => {
  const { t } = useTranslation()

  return (
    <div className="flex items-center gap-1.5">
      {getIcon(status)}
      {t(`invoiceStatus.${status}`)}
    </div>
  )
}
