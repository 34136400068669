import { Asset, AssetMetadata } from '../domain/dtos'
import { GetPdfPages } from './get-pdf-pages'
import { GetVideoDuration } from './get-video-duration'

export interface IProps {
  data: Asset
}

export class UpdateAssetDuration {
  public async execute(assetId: string, assetFile?: File | null) {
    if (!assetId || !assetFile) return

    const isCustomDurationFileType =
      assetFile.type.includes('video') || assetFile.type.includes('image') || assetFile.type.includes('pdf')

    if (!isCustomDurationFileType) {
      return {
        name: assetFile.name,
        assetId,
        metadata: {
          size: assetFile.size || 0,
        },
      }
    }

    const metadataAsset: Partial<AssetMetadata> = {
      size: assetFile.size || 0,
    }

    if (assetFile.type.includes('video')) {
      metadataAsset.duration = await new GetVideoDuration().execute(assetFile)
    }

    if (assetFile.type.includes('pdf')) {
      metadataAsset.pages = await new GetPdfPages().execute(assetFile)
    }

    return {
      name: assetFile.name,
      assetId,
      metadata: metadataAsset,
    }
  }
}
