import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { QueryKey } from '@hub-la/fe-post'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { SaveSectionRequest } from '../../domain/dtos'
import { SaveSection } from '../../usecases/save-section'

export const useSaveSection = () => {
  const container = useContainer()
  const queryClient = useQueryClient()

  return useMutation(
    [QueryKey.saveSection],
    ({ payload, id }: SaveSectionRequest) => new SaveSection(container.get(HttpClient)).execute(payload, id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [QueryKey.listSections] })
      },
    },
  )
}
