import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { PostSignInByEmailInput } from '../domain/dtos/post-sign-in-by-email-input'
import { PostSignInByEmailOutput } from '../domain/dtos/post-sign-in-by-email-output'
import { ErrorCode } from '../domain/enums/error-code'
import { BlockedUserAuthAttemptError } from '../domain/errors/blocked-user-auth-attempt-error'
import { GeneralError } from '../domain/errors/general'
import { InvalidCaptchaError } from '../domain/errors/invalid-captcha-error'
import { SignInNotfoundError } from '../domain/errors/signin-not-found-error'
import { UserDisabledError } from '../domain/errors/user-disabled-error'
import { Envs } from '../envs'

export class PostSignInByEmail {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(input: PostSignInByEmailInput): Promise<PostSignInByEmailOutput> {
    if (!input?.captcha) {
      throw new InvalidCaptchaError()
    }

    const response = await this.httpClient.request({
      method: HttpMethod.POST,
      url: `${Envs.BFF_WEB_URL}/auth/signin/email`,
      body: input,
    })

    if (response.statusCode === HttpStatusCode.CREATED) {
      return response.data
    }

    switch (response.data.code) {
      case ErrorCode.INVALID_CAPTCHA:
        throw new InvalidCaptchaError()
      case ErrorCode.USER_DISABLED:
        throw new UserDisabledError()
      case ErrorCode.USER_NOT_FOUND:
        throw new SignInNotfoundError()
      case ErrorCode.BLOCKED_USER_AUTH_ATTEMPT:
        throw new BlockedUserAuthAttemptError()
      default:
        throw new GeneralError('signIn')
    }
  }
}
