import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@hub-la/shadcn'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Product } from '../../../domain/dtos/product'

type Props = {
  opened: boolean
  onClose: () => void
  onConfirm: (productId?: string) => void
  title: string
  isLoading?: boolean
  products?: Product[]
}

export const SelectProductModal = ({ opened, isLoading, title, onClose, onConfirm, products }: Props) => {
  const [selectedProductId, setSelectedProductId] = useState<string | undefined>()
  const { t } = useTranslation()

  return (
    <Dialog open={opened} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
          <DialogDescription>{t('selectProductModal.description')}</DialogDescription>
        </DialogHeader>

        <div className="py-4">
          <Select onValueChange={setSelectedProductId} value={selectedProductId}>
            <SelectTrigger className="w-full">
              <SelectValue placeholder="Selecionar produto..." />
            </SelectTrigger>

            <SelectContent className="z-[72]">
              {(products ?? []).map(({ id, name }) => (
                <SelectItem key={id} value={id}>
                  {name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>

        <DialogFooter>
          <Button variant="outline" onClick={onClose}>
            {t('selectProductModal.cancelButton')}
          </Button>

          <Button onClick={() => onConfirm(selectedProductId)} disabled={!selectedProductId || isLoading}>
            {isLoading ? (
              <span className="loading loading-spinner loading-sm"></span>
            ) : (
              t('selectProductModal.confirmButton')
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
