import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useQuery } from '@tanstack/react-query'
import { GetListsInput } from '../../domain/dtos/get-lists-input'
import { QueryKey } from '../../domain/dtos/query-keys'
import { GetLists } from '../../usecases/get-lists'

export const useGetLists = (input: GetListsInput, enabled: boolean) => {
  const container = useContainer()

  return useQuery([QueryKey.getLists], () => new GetLists(container.get(HttpClient)).execute(input), {
    retry: 3,
    retryOnMount: false,
    refetchOnWindowFocus: false,
    initialData: [],
    enabled,
  })
}
