import { Container } from 'inversify'
import { createContext, useContext } from 'react'

export const ContainerContext = createContext<Container>({} as Container)

export const ContainerProvider = ({ children, container }) => {
  return <ContainerContext.Provider value={container}>{children}</ContainerContext.Provider>
}

export const useContainer = () => {
  return useContext(ContainerContext)
}
