import { PostResponse, SectionResponse } from '@hub-la/fe-post'
import { CalendarDays, Clock } from 'lucide-react'
import moment from 'moment'

export class GetReleaseAtText {
  public execute(entity: PostResponse | SectionResponse): { icon: React.ElementType; text: string } {
    if (entity.releaseAfterDays && entity.releaseAfterDays > 0) {
      return {
        icon: Clock,
        text: `${entity.releaseAfterDays} dias após a compra`,
      }
    } else if (entity.releaseAt) {
      return {
        icon: CalendarDays,
        text: `${moment(entity.releaseAt).format('L LT')}`,
      }
    }
    return {
      icon: Clock, // Default icon
      text: '',
    }
  }
}
