import { Analytics, AnalyticsEvent } from '@hub-la/fe-core-analytics'
import { Auth } from '@hub-la/fe-core-auth'
import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { AuthenticateWithCodeOutput } from '../domain/dtos/authenticate-with-code-output'
import { PhoneNumberVerifyCodeInput } from '../domain/dtos/phone-number-verify-code-input'
import { AnalyticsAuthMethod } from '../domain/enums/analytics-auth-method'
import { ErrorCode } from '../domain/enums/error-code'
import { BlockedUserAuthAttemptError } from '../domain/errors/blocked-user-auth-attempt-error'
import { CodeExpiredError } from '../domain/errors/code-expired-error'
import { FirebaseAuthError } from '../domain/errors/firebase-auth-error'
import { GeneralError } from '../domain/errors/general'
import { InvalidCaptchaError } from '../domain/errors/invalid-captcha-error'
import { SignInNotfoundError } from '../domain/errors/signin-not-found-error'
import { VerifyCodeInvalidError } from '../domain/errors/verify-code-invalid-error'
import { Envs } from '../envs'

export class AuthenticateWithCode {
  public constructor(
    @inject(HttpClient) private readonly httpClient: HttpClient,
    @inject(Auth) private readonly auth: Auth,
    @inject(Analytics) private readonly analytics: Analytics,
  ) {}

  public async execute(input: PhoneNumberVerifyCodeInput): Promise<AuthenticateWithCodeOutput> {
    if (!input?.captcha) {
      throw new InvalidCaptchaError()
    }

    const { token, isUsingPassword } = await this.verifyCode(input)

    if (!token) {
      throw new GeneralError()
    }

    try {
      const auth = await this.auth.signInWithToken(token)
      this.analytics.track(AnalyticsEvent.AUTHENTICATION.SUCCESSFUL, {
        user: auth?.user?.uid,
        type: input?.isWhatsapp ? AnalyticsAuthMethod.WHATSAPP : AnalyticsAuthMethod.SMS,
        isNewUser: false,
      })

      return {
        isUsingPassword,
      }
    } catch (e: any) {
      if (!e?.code) {
        throw new GeneralError('verifyCode')
      }

      switch (e?.code) {
        case 'auth/user-not-found':
          throw new SignInNotfoundError()
        default:
          throw new FirebaseAuthError(e.code)
      }
    }
  }

  private async verifyCode(input: PhoneNumberVerifyCodeInput): Promise<{
    token: string
    isUsingPassword: boolean
  }> {
    const response = await this.httpClient.request({
      method: HttpMethod.POST,
      url: `${Envs.BFF_WEB_URL}/auth/signin/phone-number/verify-code`,
      body: input,
    })

    if (response.statusCode === HttpStatusCode.CREATED || response.statusCode === HttpStatusCode.OK) {
      return response?.data
    }

    switch (response.data.code) {
      case ErrorCode.BLOCKED_USER_AUTH_ATTEMPT:
        throw new BlockedUserAuthAttemptError()
      case ErrorCode.CODE_EXPIRED:
        throw new CodeExpiredError()
      case ErrorCode.VERIFY_CODE_INVALID:
        throw new VerifyCodeInvalidError()
      default:
        throw new GeneralError('verifyCode')
    }
  }
}
