/* tslint:disable */
/* eslint-disable */
/**
 * BFF Web Product
 * API reference used to call the backend from the web frontend
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { ProductFilterRequestDto } from './product-filter-request-dto';

/**
 * 
 * @export
 * @interface ProductsByOwnerIdRequestDto
 */
export interface ProductsByOwnerIdRequestDto {
    /**
     * 
     * @type {number}
     * @memberof ProductsByOwnerIdRequestDto
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof ProductsByOwnerIdRequestDto
     */
    'pageSize': number;
    /**
     * 
     * @type {string}
     * @memberof ProductsByOwnerIdRequestDto
     */
    'orderBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductsByOwnerIdRequestDto
     */
    'orderDir'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductsByOwnerIdRequestDto
     */
    'type'?: ProductsByOwnerIdRequestDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ProductsByOwnerIdRequestDto
     */
    'timeScope'?: ProductsByOwnerIdRequestDtoTimeScopeEnum;
    /**
     * 
     * @type {ProductFilterRequestDto}
     * @memberof ProductsByOwnerIdRequestDto
     */
    'filters': ProductFilterRequestDto;
}

/**
    * @export
    * @enum {string}
    */
export enum ProductsByOwnerIdRequestDtoTypeEnum {
    DIGITAL = 'digital',
    EVENT = 'event'
}
/**
    * @export
    * @enum {string}
    */
export enum ProductsByOwnerIdRequestDtoTimeScopeEnum {
    PAST = 'past',
    FUTURE = 'future'
}


