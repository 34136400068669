import {
  Badge,
  Button,
  Table,
  TableBody,
  TableCell,
  TableEmptyState,
  TableHead,
  TableHeader,
  TablePagination,
  TableRow,
} from '@hub-la/shadcn'
import isEmpty from 'lodash/isEmpty'
import { Edit, PlusCircle } from 'lucide-react'
import moment from 'moment'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import { useGetCohortsList } from '../../hooks/cohorts/use-get-cohorts-list'
import { usePatchCohortName } from '../../hooks/cohorts/use-patch-cohort-name'
import { EditableText } from '../member-area/groups/components/editable-text/editable-text'
import { TableRowLoading } from './components/table-row-loading'

export const CohortsPage: React.FC = () => {
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(25)

  const { t } = useTranslation()
  const { productId } = useParams<{ productId: string }>()
  const { mutate: updateCohortName } = usePatchCohortName()
  const { data, isLoading } = useGetCohortsList({ productId, page, pageSize })

  const isRowsEmpty = isEmpty(data?.items)
  const canRenderEmptyState = !isLoading && isRowsEmpty

  return (
    <div className="flex flex-col gap-4 py-6">
      <div className="flex items-center justify-between">
        <h1 className="text-xl font-semibold">{t('menu.cohorts')}</h1>

        <Link to={`/edit/${productId}/cohorts/create`}>
          <Button size="sm" className="gap-2" data-testid="view-content-btn">
            <PlusCircle className="h-4 w-4" />
            {t('cohorts.create')}
          </Button>
        </Link>
      </div>

      {/* <p className="text-sm text-muted-foreground">{t('cohorts.count', { count: data?.total ?? 0 })}</p> */}

      <div className="bg-background rounded-lg border [&>div]:!overflow-hidden">
        <Table>
          <TableHeader>
            <TableRow className="hover:bg-background">
              <TableHead className="min-w-96 w-1/2">
                <span>{t('cohorts.table.name')}</span>
              </TableHead>

              <TableHead className="min-w-36 w-1/3">
                <span>{t('cohorts.table.createdAt')}</span>
              </TableHead>

              <TableHead></TableHead>

              <TableHead></TableHead>
            </TableRow>
          </TableHeader>

          <TableBody>
            {isLoading
              ? [...Array(5)].map((_, i) => <TableRowLoading key={`skeleton-row-${i}`} />)
              : (data?.items ?? []).map((cohort) => (
                  <TableRow key={cohort?.id}>
                    <TableCell className="py-2">
                      <EditableText
                        value={cohort.name}
                        onSubmit={(value) => {
                          updateCohortName({
                            productId: cohort.productId,
                            cohortId: cohort.id,
                            name: value,
                          })
                        }}
                      />

                      <span className="pl-1 text-xs text-muted-foreground">{cohort?.id}</span>
                    </TableCell>

                    <TableCell className="py-2">
                      {cohort?.createdAt ? moment(cohort?.createdAt).format('D MMM. YYYY') : '–'}
                    </TableCell>

                    <TableCell className="py-2">
                      {cohort.isDefault && (
                        <Badge variant="secondary" className="font-medium rounded-full">
                          {t('cohorts.table.isDefault')}
                        </Badge>
                      )}
                    </TableCell>

                    <TableCell className="py-2">
                      <Link to={`/edit/${productId}/cohorts/${cohort?.id}`}>
                        <Button variant="ghost" size="sm" className="mr-3">
                          <Edit className="h-4 w-4" />
                        </Button>
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </div>

      {canRenderEmptyState && <TableEmptyState title={t('cohorts.emptyState')} />}

      {!isRowsEmpty && (
        <TablePagination
          page={page}
          setPage={setPage}
          lastPage={data?.lastPage ?? 1}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      )}
    </div>
  )
}
