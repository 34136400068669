import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@hub-la/shadcn'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { WaitlistStatus } from '../../../../domain/enums/waitlist-status'

type Filters = {
  page: number
  pageSize: string
  status: WaitlistStatus
}

type Props = {
  filters: Filters
  setFilters: React.Dispatch<React.SetStateAction<Filters>>
}

export const SelectStatus: React.FC<Props> = ({ filters, setFilters }) => {
  const { t } = useTranslation()

  const handleOnSelect = (value: string) => {
    setFilters((prev) => ({ ...prev, status: value as WaitlistStatus }))
  }

  return (
    <Select value={filters.status} onValueChange={handleOnSelect}>
      <SelectTrigger className="w-full sm:w-44">
        <SelectValue placeholder="Status" />
      </SelectTrigger>

      <SelectContent>
        <SelectItem value={WaitlistStatus.allSubscribers}>{t('waitlist.waitlistStatus.allMembers')}</SelectItem>
        <SelectItem value={WaitlistStatus.convertedMember}>{t('waitlist.waitlistStatus.convertedMember')}</SelectItem>
        <SelectItem value={WaitlistStatus.notMember}>{t('waitlist.waitlistStatus.notMember')}</SelectItem>
      </SelectContent>
    </Select>
  )
}
