export const pt = {
  installmentSelectItem: 'Em até {{installments}}x',
  errors: {
    general: 'Erro inesperado. Tente novamente.',
  },
  fieldErrors: {
    required: 'Campo obrigatório',
    eitherSectionsOrGroups: 'Selecione pelo menos um módulo ou grupo',
  },
  plans: {
    monthly: 'Mensal',
    quarterly: 'Trimestral',
    semiannually: 'Semestral',
    annually: 'Anual',
  },
  paymentMethod: {
    credit_card: 'Cartão de crédito',
    bank_slip: 'Boleto',
    pix: 'PIX',
  },
  cohort: {
    header: {
      back: 'Voltar',
      create: 'Criar turma',
      edit: 'Salvar turma',
    },
    title: {
      new: 'Nova turma',
      edit: 'Editar turma',
    },
    tabs: {
      accesses: 'Acessos',
    },
    fields: {
      name: {
        label: 'Nome',
        placeholder: 'Digite o nome da turma',
      },
    },
    accessesTab: {
      title: 'Acessos',
      description: 'Determine o que os membros dessa turma terão acesso.',
      sections: {
        label: 'Módulo(s) de conteúdo',
        all: 'Todos os módulos ({{total}})',
      },
      groups: {
        label: 'Grupo(s)',
        all: 'Todos os grupos ({{total}})',
        groupName: 'Grupo de {{resourceType}}',
      },
    },
    nothingSelected: 'Nenhum recurso selecionado para essa turma',
    accessesPreview: {
      title: 'Acessos',
      description: 'Entenda quais acessos os membros dessa turma terão acesso.',
      sections: {
        label: 'Módulo(s) de conteúdo',
        empty: 'Nenhum módulo selecionado',
      },
      groups: {
        label: 'Grupo(s)',
        empty: 'Nenhum grupo selecionado',
      },
      notIncluded: {
        title: 'Não incluídos no acesso da turma',
      },
    },
  },
  resourceType: {
    whatsapp: 'Grupo de WhatsApp',
    telegram: 'Grupo de Telegram',
    telegram_channel: 'Canal de Telegram',
  },
  redirectType: {
    user_groups: 'Minhas compras',
    members_area: 'Área de membros',
    external_url: 'Página de obrigado externa',
    upsell: 'Upsell de 1-clique na Hubla',
  },
}
