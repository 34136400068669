import moment from 'moment'

interface DateRange {
  date: string
  time: string
  weekDay: string
}

export const formatDateRange = (startDate: Date, endDate: Date): DateRange => {
  const start = moment(startDate)
  const end = moment(endDate)

  // Se as datas de início e fim forem iguais
  if (start.isSame(end, 'day')) {
    return {
      date: `${start.format('D [de] MMM. YYYY')}`,
      time: `${start.format('dddd [às] HH:mm')}`,
      weekDay: `${start.format('dddd')}`,
    }
  }

  // Se os anos forem diferentes
  if (start.year() !== end.year()) {
    return {
      date: `${start.format('D [de] MMM [de] YYYY')} — ${end.format('D [de] MMM [de] YYYY')}`,
      time: `${start.format('dddd [às] HH:mm')}`,
      weekDay: `${start.format('dddd')}`,
    }
  }

  // Se os meses forem diferentes, mas no mesmo ano
  if (start.month() !== end.month()) {
    return {
      date: `${start.format('D [de] MMM')} — ${end.format('D [de] MMM [de] YYYY')}`,
      time: `${start.format('dddd [às] HH:mm')}`,
      weekDay: `${start.format('dddd')}`,
    }
  }

  // Se o mês e o ano forem os mesmos

  return {
    date: `${start.format('D')} — ${end.format('D [de] MMM [de] YYYY')}`,
    time: `${start.format('dddd [às] HH:mm')}`,
    weekDay: `${start.format('dddd')}`,
  }
}
