import { SectionResponse } from '@hub-la/fe-post'
import { Button, Input, Sheet, SheetContent, SheetHeader, SheetTitle } from '@hub-la/shadcn'
import { useFormik } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { useGetCohorts } from '../../hooks/use-get-cohorts'
import { useSaveSection } from '../../hooks/use-save-section'
import { MultiSelect } from '../multi-select'

export type Values = {
  name: string
  cohortIds: string[]
}

type Props = {
  open: boolean
  onClose: () => void
  section: SectionResponse
}

export const EditorSection: React.FC<Props> = ({ open, onClose, section }) => {
  const { productId } = useParams<{ productId: string }>()
  const { mutateAsync: saveSection, isLoading } = useSaveSection()
  const { data: cohorts = [] } = useGetCohorts(productId)
  const { t } = useTranslation()

  const { values, isValid, submitForm, handleChange, setFieldValue, resetForm, errors } = useFormik<Values>({
    initialValues: {
      name: section.name,
      cohortIds: section.cohortIds ?? [],
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(),
      cohortIds: Yup.array().min(1).required(),
    }),
    validateOnChange: true,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: async (values) => {
      await saveSection({
        id: section.id,
        payload: {
          name: values.name,
          cohortIds: values.cohortIds,
          productId,
        },
      }).then(() => {
        resetForm()
        onClose()
      })
    },
  })

  /** Reset form and states */
  const onCloseModal = () => {
    resetForm()
    onClose()
  }

  const cohortOptions = cohorts.map((cohort) => ({
    id: cohort.id,
    label: cohort.name,
    type: cohort.isDefault ? 'default' : 'regular',
  }))

  return (
    <Sheet open={open} onOpenChange={onCloseModal}>
      <SheetContent className="sm:max-w-lg">
        <SheetHeader className="flex justify-between items-center">
          <SheetTitle>{t('saveSection.title')}</SheetTitle>
        </SheetHeader>

        <div className="flex flex-col gap-4 mt-6">
          <Input
            id="name"
            name="name"
            type="text"
            placeholder={t('saveSection.name')}
            value={values.name}
            onChange={handleChange}
          />

          <div className="flex flex-col space-y-1.5">
            <MultiSelect
              options={cohortOptions}
              value={values.cohortIds}
              onChange={(newValue) => setFieldValue('cohortIds', newValue)}
              label={t('createSection.cohorts')}
              error={Boolean(errors?.cohortIds)}
              helperText={errors?.cohortIds ? t('createSection.cohortError') : undefined}
              disabled={isLoading}
              hasSelectAll={true}
            />
          </div>
        </div>

        <div className="flex flex-col gap-3 mt-6">
          <Button onClick={submitForm} disabled={!isValid || isLoading}>
            {t('saveSection.confirm')}
          </Button>

          <Button variant="outline" onClick={onCloseModal} disabled={isLoading}>
            {t('saveSection.cancel')}
          </Button>
        </div>
      </SheetContent>
    </Sheet>
  )
}
