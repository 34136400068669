import { useTranslation } from 'react-i18next'
import { SmartInstallment } from '../../../../domain/dtos/get-smart-installment-cycle'
import { SmartInstallmentStatus } from '../../../../domain/enums/smart-installment-status'
import { useHistory } from 'react-router-dom'
import { SmartInstallmentCard } from '../../../components/smart-installment-card'

export const SmartInstallmentsList = ({ smartInstallments }: { smartInstallments: SmartInstallment[] }) => {
  const sortedSmartInstallments = smartInstallments.sort((a, b) => a.installment - b.installment)

  const { t } = useTranslation()
  const history = useHistory()

  const hasToRedirect = (smartInstallment: SmartInstallment) => {
    if (
      smartInstallment.status === SmartInstallmentStatus.STATUS_PAID ||
      smartInstallment.status === SmartInstallmentStatus.STATUS_EXPIRED
    ) {
      return { onClick: () => history.push(`/user_invoices/${smartInstallment.id}`) }
    }

    return
  }

  return (
    <div className="flex flex-col gap-3">
      <h2 className="text-lg max-w-max font-semibold">{t('smartInstallmentsList.title')}</h2>
      {sortedSmartInstallments.map((smartInstallment) => (
        <SmartInstallmentCard
          key={smartInstallment.id}
          {...hasToRedirect(smartInstallment)}
          smartInstallment={smartInstallment}
        />
      ))}
    </div>
  )
}
