import { Envs } from '../envs'

export const getSiteHost = (): string => {
  if (window.location.port === '3002') {
    return 'http://localhost:3000'
  }
  return Envs.SITE_URL ?? ''
}

export const getSiteUrl = (path = '') => {
  const siteHost = getSiteHost()
  return (path || '').substring(0, 1) === '/' ? `${siteHost}${path}` : `${siteHost}/${path}`
}

export const getGroupUrl = (path?: string) => {
  return getSiteUrl('/g/') + path
}
