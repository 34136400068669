import { initializeApp } from 'firebase/app'

type Props = {
  apiKey: string
  authDomain: string
  projectId: string
  storageBucket: string
  appId: string
  messagingSenderId: string
}

export const initialize = ({ apiKey, authDomain, projectId, storageBucket, appId, messagingSenderId }: Props) => {
  const config = {
    apiKey,
    authDomain,
    projectId,
    storageBucket,
    messagingSenderId,
    appId,
  }

  initializeApp(config)
}
