export const pt = {
  title: 'Discussões',
  inputComment: {
    label: 'Adicione um comentário',
    cancel: 'Cancelar',
    submit: 'Comentar',
    charactersRemaining: '{{count}}/1050 caracteres',
  },
  replyInputComment: {
    label: 'Adicione uma resposta',
    submit: 'Responder',
  },
  editInputComment: {
    label: 'Edite seu comentário',
    replyLabel: 'Edite sua resposta',
    submit: 'Salvar alterações',
  },
  orderComments: {
    newest: 'Mais recentes',
    oldest: 'Mais antigos',
  },
  comment: {
    editedFlag: '(editado)',
    options: {
      edit: {
        unique: 'Editar comentário',
        answer: 'Editar resposta',
      },
      delete: {
        unique: 'Excluir comentário',
        answer: 'Excluir resposta',
      },
    },
    actions: {
      reply: 'Responder',
    },
    snackbarFeedback: {
      delete: {
        success: 'Comentário excluído com sucesso!',
        error: 'Erro ao excluir comentário. Tente novamente.',
      },
    },
    replies: {
      countPlural: '{{count}} respostas',
      countSingular: '{{count}} resposta',
      lastAnswer: 'última resposta {{date}}',
    },
  },
  unsubscribeNotification: {
    success: {
      title: 'Notificação de comentário desativado',
      description:
        'Você desativou a notificação de novos comentários. Caso queira reativá-la, acesse a página de perfil e ative a notificação.',
    },
    error: {
      title: 'Ocorreu um erro ao desativar a notificação',
      description: 'Tente novamente mais tarde ou entre em contato com o suporte.',
    },
  },
}
