import React, { memo } from 'react'
import { DelimitedArrayParam, StringParam, useQueryParams, withDefault } from 'use-query-params'
import { Filters } from '../components/filters'
import { useTranslation } from 'react-i18next'
import { PartnershipsTable } from './partnerships-table'
import { ReceiverStatus } from '../../../domain/enums/receiver-status'

export const Partnerships = memo(() => {
  const [filters, setFilters] = useQueryParams({
    search: withDefault(StringParam, ''),
    statuses: withDefault(DelimitedArrayParam, []),
  })
  const { t } = useTranslation()

  return (
    <div className="flex flex-col gap-4 mt-4">
      <Filters
        label={'Parceiro(s)'}
        filters={filters}
        setFilters={setFilters}
        status={Object.values(ReceiverStatus)
          .map((status) => ({
            id: status,
            label: t(`partnerships.status.${status}`),
          }))
          .filter((status) => status.id !== ReceiverStatus.UNKNOWN)}
      />
      <PartnershipsTable filters={filters} />
    </div>
  )
})
