import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { GetIntegrationRulesInput } from '../domain/dtos/get-integration-rules-input'
import { GetIntegrationRulesOutput } from '../domain/dtos/get-integration-rules-output'
import { GetIntegrationRulesResponse } from '../domain/dtos/get-integration-rules-response'
import { GeneralError } from '../domain/errors/general'
import { Envs } from '../envs'

export class GetIntegrationRules {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient<GetIntegrationRulesResponse>,
  ) {}

  public async execute(input: GetIntegrationRulesInput): Promise<GetIntegrationRulesOutput> {
    const response = await this.httpClient.request({
      method: HttpMethod.GET,
      url: `${Envs.BFF_WEB_URL}/integrations/${input.integrationId}/rules?page=${input.page}&pageSize=${input.pageSize}`,
    })

    if (response.data && response.statusCode === HttpStatusCode.OK) {
      return response.data
    }

    throw new GeneralError()
  }
}
