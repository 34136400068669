import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '../../domain/dtos/query-keys'
import { GetMyCollaborators } from '../../usecases/get-my-collaborators'

export const useGetMyCollaborators = () => {
  const container = useContainer()

  return useQuery([QueryKey.getMyCollaborators], () => new GetMyCollaborators(container.get(HttpClient)).execute(), {
    retry: 3,
    retryOnMount: false,
    refetchOnWindowFocus: false,
  })
}
