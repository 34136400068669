import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { QueryKey } from '../../domain/dtos/query-key'
import { SavePostRequest } from '../../domain/dtos/save-post-request'
import { SavePost } from '../../usecases/save-post'

export const useSavePost = () => {
  const container = useContainer()
  const queryClient = useQueryClient()

  return useMutation(
    [QueryKey.savePost],
    ({ payload, id }: SavePostRequest) => new SavePost(container.get(HttpClient)).execute(payload, id),
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries({ queryKey: [QueryKey.listSections] })
        queryClient.invalidateQueries({ queryKey: [QueryKey.getPost, variables.id] })
      },
    },
  )
}
