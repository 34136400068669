import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useToast } from '@hub-la/shadcn'
import { useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { RequestAffiliate } from '../../usecases/request-affiliate'

export const useRequestAffiliate = () => {
  const container = useContainer()
  const history = useHistory()
  const { toast } = useToast()
  const { t } = useTranslation()

  return useMutation((offerId: string) => new RequestAffiliate(container.get(HttpClient)).execute(offerId), {
    onSuccess: () => {
      history.push(`/dashboard/products?tab=affiliates`)
    },
    onError: (error: Error) =>
      toast({
        title: t(error.message),
        variant: 'destructive',
      }),
  })
}
