import React, { createContext, useContext } from 'react'
import { Container } from 'inversify'
import { Auth } from '@hub-la/fe-core-auth'
import { FirebaseAuth } from '@hub-la/fe-vendors-firebase'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { AxiosHttpClient } from '@hub-la/fe-vendors-axios'

const container = new Container()

container.bind(Auth).to(FirebaseAuth)
container.bind(HttpClient).to(AxiosHttpClient)

export default container

export const ContainerContext = createContext(container)

export const ContainerProvider = ({ children }) => (
  <ContainerContext.Provider value={container}>{children}</ContainerContext.Provider>
)

export const useContainer = () => {
  return useContext(ContainerContext)
}
