import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { GetCommentRepliesInput } from '../domain/dtos/get-comment-replies-input'
import { GetCommentsResponse } from '../domain/dtos/get-comments-response'
import { GeneralError } from '../domain/errors/general'
import { Envs } from '../envs'

export class GetCommentReplies {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient<GetCommentsResponse>,
  ) {}

  public async execute(input: GetCommentRepliesInput): Promise<GetCommentsResponse | undefined> {
    const response = await this.httpClient.request({
      method: HttpMethod.GET,
      url: `${Envs.BFF_MEMBERS_AREA_URL}/hub/posts/${input.postId}/comments/${input.commentId}/replies`,
      params: {
        page: input.page,
        pageSize: input?.pageSize,
        orderDir: input?.orderDir,
      },
    })

    if (response.statusCode === HttpStatusCode.CREATED || response.statusCode === HttpStatusCode.OK) {
      return response.data
    }

    throw new GeneralError()
  }
}
