import { Button, cn, useIsMobile } from '@hub-la/shadcn'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { CreatePostRequest, PostResponse, PostStatus } from '../../domain/dtos'
import { useSavePost } from '../hooks'

type AdminActionsProps = {
  post: PostResponse
  productId: string
  sectionId: string
  onPublish: () => void
}

export const AdminActions = ({ post, productId, sectionId, onPublish }: AdminActionsProps) => {
  const [publishing, setPublishing] = useState<boolean>(false)

  const isMobile = useIsMobile()
  const savePost = useSavePost()

  const isDraft = post?.status === PostStatus.DRAFT

  const handlePublish = () => {
    if (!post) return

    setPublishing(true)

    const attachedIds = post.attachments?.map((asset) => asset.id)

    const data: CreatePostRequest = {
      status: PostStatus.PUBLISHED,
      title: post.title,
      subtitle: post.subtitle,
      content: post.content ?? '',
      coverAssetId: post.cover?.id,
      attachmentAssetIds: [],
    }

    if (attachedIds) {
      data.attachmentAssetIds = attachedIds
    }

    savePost
      .mutateAsync({
        payload: post.id ? { ...data, id: post.id } : data,
        id: post.id,
      })
      .then(() => {
        onPublish()
      })
      .finally(() => setPublishing(false))
  }

  return (
    <div className={cn('flex flex-row gap-2 items-center', isMobile ? 'w-full' : 'w-auto ml-auto')}>
      <Link
        to={`/hub/create_post/${productId}/${sectionId}?postId=${post.id}`}
        className={isMobile ? 'w-full' : 'w-auto'}
      >
        <Button size="sm" variant="ghost" className="w-full" data-testid="edit-button">
          Editar
        </Button>
      </Link>

      <Button
        size="sm"
        className={isMobile ? 'w-full' : 'w-auto'}
        loading={publishing}
        disabled={!isDraft}
        onClick={handlePublish}
        data-testid="publish-button"
      >
        {isDraft ? 'Publicar' : 'Publicado!'}
      </Button>
    </div>
  )
}
