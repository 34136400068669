import {
  Badge,
  cn,
  Table,
  TableBody,
  TableCell,
  TableEmptyState,
  TableHead,
  TableHeader,
  TableRow,
  useIsMobile,
} from '@hub-la/shadcn'
import isEmpty from 'lodash/isEmpty'
import { LucideMousePointerClick } from 'lucide-react'
import moment from 'moment'
import 'moment/locale/pt' // Import the Portuguese locale
import { forwardRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { EventDelivery } from '../../../domain/dtos/event-delivery'
import { IntegrationEvent } from '../../../domain/dtos/integration-event'
import { GetEventName } from '../../../usecases/get-event-name'
import { HistoryTableLoading } from './history-table-loading'

moment.locale('pt') // Set the locale to Portuguese

type Props = {
  items: EventDelivery[]
  isFetching: boolean
  onHistoryDetail: (state: { isOpen: boolean; history: EventDelivery | null }) => void
  historyId?: string
}

export const TableHistory = forwardRef<HTMLDivElement, Props>(
  ({ items, isFetching, onHistoryDetail, historyId }, ref) => {
    const { t } = useTranslation()

    const isMobile = useIsMobile()

    // Automatically select the first item when the component mounts
    useEffect(() => {
      if (items.length > 0 && !isMobile) {
        handleOpenedDetail(items[0])
      } else {
        handleOpenedDetail(null)
      }
    }, [items])

    const isRowsEmpty = isEmpty(items)
    const canRenderEmptyState = !isFetching && isRowsEmpty
    const isLoading = isFetching && isRowsEmpty

    const renderEventCell = (event: IntegrationEvent) => {
      const eventName = new GetEventName().execute(event)
      if (!eventName) return null
      return (
        <div className="flex items-center text-xs md:text-sm">
          <LucideMousePointerClick className="w-4 h-4 mr-1" />
          {t(eventName)}
        </div>
      )
    }

    const renderStatusCodeCell = (status?: number | null) => {
      if (!status) return <Badge variant="secondary">-</Badge>
      if (status >= 0 && status < 400)
        return (
          <Badge
            className="bg-green-500/10 text-green-500 hover:bg-green-100"
            variant="secondary"
          >{`${status} OK`}</Badge>
        )
      if (status >= 400)
        return (
          <Badge className="bg-red-500/10 text-red-500 hover:bg-red-100" variant="secondary">{`${status} ERR`}</Badge>
        )
      return <Badge variant="default">{`${status}`}</Badge>
    }

    const handleOpenedDetail = (history: EventDelivery | null) => {
      onHistoryDetail({ isOpen: !!history, history })
    }

    const groupItemsByDate = (items: EventDelivery[]) => {
      const grouped = items.reduce((acc, item) => {
        const date = moment(item.createdAt).format('YYYY-MM-DD')
        if (!acc[date]) {
          acc[date] = []
        }
        acc[date].push(item)
        return acc
      }, {} as Record<string, EventDelivery[]>)

      return Object.entries(grouped).sort(([a], [b]) => moment(b).diff(moment(a)))
    }

    const groupedItems = groupItemsByDate(items)

    return (
      <div
        ref={ref}
        className={cn(
          'border bg-background rounded-lg flex-1',
          historyId && 'lg:border-r-0 lg:rounded-r-none',
          !isMobile && 'min-h-[500px]',
        )}
      >
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Status</TableHead>
              <TableHead>{isMobile ? 'Info' : 'Evento'}</TableHead>
              {!isMobile && (
                <>
                  <TableHead>Produto</TableHead>
                  <TableHead>Hora</TableHead>
                </>
              )}
            </TableRow>
          </TableHeader>
          <TableBody>
            {isLoading
              ? Array.from({ length: 25 }).map((_, i) => <HistoryTableLoading key={`skeleton-row-${i}`} />)
              : groupedItems.map(([date, histories]) => (
                  <>
                    <TableRow className="border-b-0">
                      <TableCell className="pt-4 pb-1 px-2" colSpan={4}>
                        <p className="text-xs font-normal text-muted-foreground">
                          {moment(date).format('ddd. DD [de] MMM. YYYY')}
                        </p>
                      </TableCell>
                    </TableRow>
                    {histories.map((history) => (
                      <TableRow
                        key={history.id}
                        onClick={() => handleOpenedDetail(history)}
                        className={cn(
                          historyId && history.id === historyId
                            ? 'border-l-2 border-l-hubla-primary bg-secondary/50'
                            : 'cursor-pointer',
                        )}
                      >
                        <TableCell className="  pl-2 pr-0 mr-0 max-w-[110px]">
                          {renderStatusCodeCell(history.statusCode)}
                        </TableCell>
                        <TableCell className=" ">
                          {isMobile ? (
                            <div className="flex flex-col">
                              <div>{renderEventCell(history.event)}</div>
                              <p className="font-normal text-muted-foreground truncate text-ellipsis overflow-hidden whitespace-nowrap">
                                {history.product?.name}
                              </p>
                              <p className="underline font-normal text-muted-foreground">
                                {moment(history.createdAt).format('HH:mm:ss')}
                              </p>
                            </div>
                          ) : (
                            renderEventCell(history.event)
                          )}
                        </TableCell>
                        {!isMobile && (
                          <>
                            <TableCell className="  flex-1">
                              <p className="font-normal text-muted-foreground truncate text-ellipsis overflow-hidden whitespace-nowrap">
                                {history.product?.name}
                              </p>
                            </TableCell>
                            <TableCell className=" ">
                              <p className="underline font-normal text-muted-foreground">
                                {moment(history.createdAt).format('HH:mm:ss')}
                              </p>
                            </TableCell>
                          </>
                        )}
                      </TableRow>
                    ))}
                  </>
                ))}
          </TableBody>
        </Table>
        {canRenderEmptyState && (
          <TableEmptyState title={t('histories.table.empty.title')} subtitle={t('histories.table.empty.subtitle')} />
        )}
      </div>
    )
  },
)
