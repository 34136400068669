import { HttpClient } from '@hub-la/fe-core-http-client'
import merge from 'deepmerge'
import { inject } from 'inversify'
import { isNil } from 'lodash'
import { AssetResponse, AssetStatus, AssetType } from '../domain/dtos'
import { GetAsset } from './get-asset'
import { waitFor } from './wait-for'

export class GetVideoThumbnail {
  public constructor(@inject(HttpClient) private readonly httpClient: HttpClient) {}

  public execute(data: AssetResponse): Promise<AssetResponse> {
    // When the thumbnail is not loaded yet, it has a string "playbackId" on it, so we need to wait until the webhook updates it with the correct id.
    const hasThumb = (url: string): boolean => (/^(.*?(\bplaybackId\b)[^$]*)$/.test(url) ? false : true)

    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      let isThumbLoaded = hasThumb(data.url)

      if (!isThumbLoaded) {
        do {
          await waitFor(2000) // 2 sec

          const asset = await new GetAsset(this.httpClient).execute(data.id)

          if (isNil(asset.url)) {
            return
          }

          if (hasThumb(asset.url)) {
            isThumbLoaded = true
            resolve(
              merge(data, {
                type: AssetType.video,
                status: AssetStatus.available,
                metadata: {
                  thumbnailUrl: asset.metadata?.thumbnailUrl,
                },
              }),
            )
          }
        } while (!isThumbLoaded)
      }

      data.type = AssetType.video

      resolve(data)
    })
  }
}
