import React from 'react'
import { AppHeader } from '../../components/app-header'
import { BackwardButton } from '../../components/backward-button'
import { SmartInstallmentCycle } from './components/smart-installment-cycle'

export const UserSmartInstallmentCyclePage: React.FC = () => {
  return (
    <div className="flex flex-col container px-4 md:px-24">
      <AppHeader />
      <div className="w-full max-w-[720px] flex flex-col mx-auto pt-0 md:pt-12 pb-12 gap-8">
        <BackwardButton />
        <SmartInstallmentCycle />
      </div>
    </div>
  )
}
