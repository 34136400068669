import { CohortDto } from '@hub-la/sdk-bff-product'

export class GetCohortsContent {
  public execute(cohortIds: string[], cohorts: CohortDto[]) {
    // Filter cohorts based on cohortIds
    const matchedCohorts = cohorts.filter((cohort) => cohortIds.includes(cohort.id))

    // Extract the names of the matched cohorts
    const names = matchedCohorts.map((cohort) => cohort.name)

    // Check if all cohortIds are matched
    if (names.length === cohortIds.length && names.length === cohorts.length) {
      return 'Todas'
    }

    // Construct the result string based on the number of matched names
    if (names.length > 2) {
      return `${names.slice(0, 2).join(', ')}, ...`
    } else {
      return names.join(', ')
    }
  }
}
