export class CreditCardHolder {
  public constructor(private readonly value: string) {}

  public static build(value: string): CreditCardHolder {
    return new CreditCardHolder(value.trim())
  }

  public isValid(): boolean {
    return this.value.split(' ').filter((n) => n.trim().length).length > 1
  }

  public getValue() {
    return this.value
  }
}
