import { Card, CardContent, Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle } from '@hub-la/shadcn'
import { UserPlus, Users } from 'lucide-react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CreateGroupDrawer } from '../create-group/create-group-drawer'
import { BindExistentGroupDrawer } from './bind-existent-group-drawer'

interface AddGroupCardInterface {
  open: boolean
  onClose: () => void
  offerId?: string
}

type options = 'add_group' | 'bind_existent_group'

export const AddGroupCard: React.FC<AddGroupCardInterface> = ({ open, onClose, offerId }) => {
  const { t } = useTranslation()
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [option, setOption] = useState<options>()

  const handleOnOptionClose = () => {
    setOption(undefined)
    setOpenModal(false)
    onClose()
  }

  const handleOnSelectOption = (option: options) => () => {
    setOpenModal(true)
    setOption(option)
    onClose()
  }

  return (
    <>
      <Sheet open={open} onOpenChange={onClose}>
        <SheetContent className="sm:max-w-lg flex flex-col gap-6 overflow-y-auto">
          <SheetHeader>
            <SheetTitle>{t('member-area.groups.addGroupModal.title')}</SheetTitle>
            <SheetDescription>{t('member-area.groups.addGroupModal.subTitle')}</SheetDescription>
          </SheetHeader>

          <div className="flex flex-col gap-2">
            <Card className="cursor-pointer hover:background" onClick={handleOnSelectOption('add_group')}>
              <CardContent className="p-4 flex gap-5 items-start">
                <UserPlus className="h-6 w-6 text-green-600" />

                <div className="flex flex-col gap-1">
                  <h4 className="font-semibold">{t('member-area.groups.addGroupModal.cards.newGroup.title')}</h4>

                  <p className="text-sm text-muted-foreground">
                    {t('member-area.groups.addGroupModal.cards.newGroup.description')}
                  </p>
                </div>
              </CardContent>
            </Card>

            <Card className="cursor-pointer hover:background" onClick={handleOnSelectOption('bind_existent_group')}>
              <CardContent className="p-4 flex gap-5 items-start">
                <Users className="h-6 w-6 text-green-600" />

                <div className="flex flex-col gap-1">
                  <h4 className="font-semibold">
                    {t('member-area.groups.addGroupModal.cards.bindExistentGroup.title')}
                  </h4>

                  <p className="text-sm text-muted-foreground">
                    {t('member-area.groups.addGroupModal.cards.bindExistentGroup.description')}
                  </p>
                </div>
              </CardContent>
            </Card>
          </div>
        </SheetContent>
      </Sheet>

      {option === 'add_group' && <CreateGroupDrawer open={openModal} onClose={handleOnOptionClose} offerId={offerId} />}

      {option === 'bind_existent_group' && (
        <BindExistentGroupDrawer open={openModal} onClose={handleOnOptionClose} offerId={offerId} />
      )}
    </>
  )
}
