import { useContainer } from '@hub-la/fe-container'
import { Analytics } from '@hub-la/fe-core-analytics'
import { Auth } from '@hub-la/fe-core-auth'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation } from '@tanstack/react-query'
import { PostSignInByEmailVerifyCodeInput } from '../../domain/dtos/post-sign-in-by-email-verify-code-input'
import { QueryKey } from '../../domain/dtos/query-keys'
import { PostSignInByEmailVerifyCode } from '../../usecases/post-sign-in-by-email-verify-code'

export const usePostSignInByEmailVerifyCode = ({ onError }) => {
  const container = useContainer()

  return useMutation(
    [QueryKey.postSignInByEmailVerifyCode],
    (input: PostSignInByEmailVerifyCodeInput) =>
      new PostSignInByEmailVerifyCode(container.get(HttpClient), container.get(Auth), container.get(Analytics)).execute(
        input,
      ),
    {
      retry: false,
      onError: (error: Error) => {
        if (onError) {
          onError(error)
        }
      },
    },
  )
}
