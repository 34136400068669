import { Card, CardContent } from '@hub-la/shadcn'
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import React from 'react'

type EventDateProps = {
  date: Date
}

/**
 * @deprecated Use `@hub-la/fe-thumbnail` instead
 */
const EventDate: React.FC<EventDateProps> = ({ date }) => {
  const day = format(date, 'd')
  const month = format(date, 'MMM', { locale: ptBR }).toUpperCase()

  return (
    <Card className="absolute left-2 top-2 w-10 h-10 bg-background/50 backdrop-blur-sm">
      <CardContent className="p-1 flex flex-col items-center justify-center h-full">
        <span className="text-sm font-bold leading-tight">{day}</span>
        <span className="text-xs text-muted-foreground leading-tight">{month}</span>
      </CardContent>
    </Card>
  )
}

export default EventDate
