import { Card, CardContent, CardFooter, CardHeader, Separator, MultiProgress } from '@hub-la/shadcn'
import { SmartInstallment, SmartInstallmentCycle } from '../../../../domain/dtos/get-smart-installment-cycle'
import { useTranslation } from 'react-i18next'
import { AmountByStatus } from './amount-by-status'
import { formatCurrency } from '../../../../domain/vos/format-currency'
import { format } from 'date-fns'
import { ChargeSmartInstallmentDialog } from './charge-smart-installment-dialog'

export const SmartInstallmentCycleDetails = ({
  smartInstallmentCycle,
  smartInstallments,
}: {
  smartInstallmentCycle: SmartInstallmentCycle
  smartInstallments: SmartInstallment[]
}) => {
  const { t } = useTranslation()

  const paidAmountPercentage = (smartInstallmentCycle.paidTotalAmount / smartInstallmentCycle.totalAmount) * 100
  const overdueAmountPercentage = (smartInstallmentCycle.overdueTotalAmount / smartInstallmentCycle.totalAmount) * 100

  return (
    <Card>
      <CardHeader className="text-sm">
        <div className="flex gap-2 items-center">
          <h1 className="text-lg font-medium">{smartInstallmentCycle.productName}</h1>
          <span>{t(`smartInstallmentCycleStatus.${smartInstallmentCycle.status}`)}</span>
        </div>
        <p className="text-muted-foreground">{smartInstallmentCycle.offerId}</p>
      </CardHeader>
      <CardContent className="flex flex-col gap-4">
        <MultiProgress
          segments={[
            { color: 'bg-green-400', value: paidAmountPercentage },
            { color: 'bg-red-400', value: overdueAmountPercentage },
          ]}
        />
        <div className="grid md:grid-cols-3 gap-4">
          <AmountByStatus
            title={t('summarie.paid.title')}
            installments={smartInstallmentCycle.paidInstallments}
            amount={smartInstallmentCycle.paidTotalAmount}
          />
          <AmountByStatus
            title={t('summarie.overdue.title')}
            installments={smartInstallmentCycle.overdueInstallments}
            amount={smartInstallmentCycle.overdueTotalAmount}
          />
          <AmountByStatus
            title={t('summarie.draft.title')}
            installments={smartInstallmentCycle.draftInstallments}
            amount={smartInstallmentCycle.draftTotalAmount}
          />
        </div>
        <Separator />
        <div className="flex justify-between text-sm">
          <div>
            <h3 className="mb-2">{t('smartInstallmentCycleDetails.totalAmount')}</h3>
            <p className="text-muted-foreground">{formatCurrency(smartInstallmentCycle.totalAmount / 100)}</p>
          </div>
          <div>
            <h3 className="mb-2">{t('smartInstallmentCycleDetails.nextDueAt')}</h3>
            <p className="text-muted-foreground">{format(new Date(smartInstallmentCycle.nextDueAt), 'dd MMM. yyyy')}</p>
          </div>
        </div>
      </CardContent>
      <Separator />
      <CardFooter className="pt-6">
        <ChargeSmartInstallmentDialog
          smartInstallments={smartInstallments}
          smartInstallmentCycleId={smartInstallmentCycle.id}
        />
      </CardFooter>
    </Card>
  )
}
