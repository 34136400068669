import { ScrollArea } from '@hub-la/shadcn'
import { getTermsOfUse } from '../../../../pages/utils'

export const ModalContent = () => {
  return (
    <div data-testid="modal-content-container">
      <h2 className="text-lg font-semibold text-center">Termos de uso</h2>
      <ScrollArea className="h-[60vh] mt-6 p-3 border border-gray-300 rounded-md text-muted-foreground">
        <div className="text-sm" dangerouslySetInnerHTML={{ __html: getTermsOfUse() }} />
      </ScrollArea>
    </div>
  )
}
