import { TabsList, TabsTrigger, Tabs } from "@hub-la/shadcn"
import { LightDarkMode } from "libs/frontend/modules/offer/src/lib/domain/enums/light-dark-mode.enum"
import { Moon, Sun } from "lucide-react"
import { useTranslation } from "react-i18next"

interface LightDarkThemeSelectorProps {
  onChange: (theme: LightDarkMode) => void
  value: LightDarkMode
}

export const LightDarkThemeSelector = (props: LightDarkThemeSelectorProps) => {
  const { t } = useTranslation()

  return (
    <div>
      <h3 className="text-sm font-medium mb-1">{t('offer.checkoutTab.preferences.theme.label')}</h3>
      <Tabs
        value={props.value}
        onValueChange={(value) => props.onChange(value as LightDarkMode)}
        className="max-w-max"
      >
        <TabsList className="flex">
          <TabsTrigger value={LightDarkMode.LIGHT}>
            <Sun className="h-4 w-4 " />
          </TabsTrigger>
          <TabsTrigger value={LightDarkMode.DARK}>
            <Moon className="h-4 w-4" />
          </TabsTrigger>
        </TabsList>
      </Tabs>
      <span className="text-sm text-muted-foreground">
        {t(`offer.checkoutTab.preferences.theme.${props.value}`)}
      </span>
    </div >
  )
}
