import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { GetRefundsOutput } from '../domain/dtos/refund'
import { RefundStatus } from '../domain/enums/refund-status'
import { GeneralError } from '../domain/errors/general'
import { Envs } from '../envs'

export class GetRefunds {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(
    input: { page: number; pageSize: number; status?: RefundStatus },
    signal?: AbortSignal,
  ): Promise<GetRefundsOutput> {
    const response = await this.httpClient.request({
      method: HttpMethod.GET,
      url: `${Envs.BFF_WEB_URL}/refunds/seller`,
      params: {
        page: input.page,
        pageSize: input.pageSize,
        status: input.status,
      },
      signal,
    })

    if ([HttpStatusCode.OK, HttpStatusCode.CREATED].includes(response.statusCode)) {
      return response.data
    }

    throw new GeneralError()
  }
}
