import { Card, CardContent, CardHeader, Skeleton } from '@hub-la/shadcn'

export const LoadingCard = () => {
  return (
    <Card className="w-full">
      <CardHeader className="pb-3">
        <Skeleton className="h-7 w-20 rounded" />
      </CardHeader>

      <CardContent>
        <Skeleton className="h-9 w-14 rounded" />
      </CardContent>
    </Card>
  )
}
