import { useContainer } from '@hub-la/fe-container'
import { Analytics } from '@hub-la/fe-core-analytics'
import { Events, makeSectionEventData, SectionResponse, stopEventPropagation } from '@hub-la/fe-post'
import { Button, DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@hub-la/shadcn'
import { Clock, Copy, Edit, MoreHorizontal, Trash2, Upload } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HasVisualizedHighlightModules } from '../../../usecases/has-visualized-highlight-modules'
import { useDeleteSection } from '../../hooks/use-delete-section'
import { EditorReleaseDays } from '../editor-release-days/editor-release-days'
import { EditorSection } from '../editor-section/editor-section'
import { ModalEnableModulesHighlight } from '../modal-enable-modules-highlight/modal-enable-modules-highlight'
import { SectionEditorCover } from '../section-cover-editor/section-cover-editor'
import { ConfirmationModal } from './confirmation-modal'
import { DuplicateModal } from './duplicate-modal'

export interface Props {
  section: SectionResponse
  productId: string
  userId: string
  isEditCoverOpen: boolean
  setIsEditCoverOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const SectionMenu: React.FC<Props> = ({ section, productId, userId, isEditCoverOpen, setIsEditCoverOpen }) => {
  const [confirmationModalOpen, setConfirmationModalOpen] = useState<boolean>(false)
  const [duplicateModalOpen, setDuplicateModalOpen] = useState<boolean>(false)
  const [releaseEditorOpen, setReleaseEditorOpen] = useState<boolean>(false)
  const [sectionEditorOpen, setSectionEditorOpen] = useState<boolean>(false)
  const [modulesHighlightModalOpen, setModulesHighlightModalOpen] = useState<boolean>(false)

  const { t } = useTranslation()
  const container = useContainer()
  const analytics = container.get(Analytics)
  const deleteSection = useDeleteSection()

  const hasPosts = section.posts.length > 0

  const handleEditSection = (event) => {
    stopEventPropagation(event)
    setSectionEditorOpen(true)
  }

  const handleEditCover = (event) => {
    stopEventPropagation(event)
    setIsEditCoverOpen(true)
  }

  const handleCloseCover = () => {
    const needOpenModal = new HasVisualizedHighlightModules().execute(productId)
    if (needOpenModal) {
      setModulesHighlightModalOpen(true)
    }
    setIsEditCoverOpen(false)
  }

  const handleReleaseDays = (event) => {
    stopEventPropagation(event)
    setReleaseEditorOpen(true)
  }

  const handleOpenConfirmModal = () => {
    analytics.track(
      Events.HUB.SECTION.DELETE_CLICKED,
      makeSectionEventData({
        userId,
        productId,
        sectionId: section.id,
      }),
    )
    setConfirmationModalOpen(true)
  }

  const handleDuplicateSection = (event) => {
    stopEventPropagation(event)
    setDuplicateModalOpen(true)
  }

  const handleDeleteSectionAfterConfirmation = () => {
    deleteSection.mutateAsync(section.id).then(() => {
      analytics.track(
        Events.HUB.SECTION.DELETE_SUCCESSFUL,
        makeSectionEventData({
          userId,
          productId,
          sectionId: section.id,
        }),
      )
    })
  }

  const handleDeleteSection = (event) => {
    stopEventPropagation(event)

    if (!hasPosts) {
      deleteSection.mutateAsync(section.id).then(() => {
        analytics.track(
          Events.HUB.SECTION.DELETE_SUCCESSFUL,
          makeSectionEventData({
            userId,
            productId,
            sectionId: section.id,
          }),
        )
      })
    } else {
      handleOpenConfirmModal()
    }
  }

  return (
    <>
      <ConfirmationModal
        open={confirmationModalOpen}
        onClose={() => setConfirmationModalOpen(false)}
        onConfirm={handleDeleteSectionAfterConfirmation}
      />

      <EditorSection open={sectionEditorOpen} onClose={() => setSectionEditorOpen(false)} section={section} />

      <EditorReleaseDays
        productId={productId}
        open={releaseEditorOpen}
        onClose={() => setReleaseEditorOpen(false)}
        section={section}
      />

      <ModalEnableModulesHighlight
        productId={productId}
        open={modulesHighlightModalOpen}
        onClose={() => setModulesHighlightModalOpen(false)}
      />

      <SectionEditorCover open={isEditCoverOpen} section={section} onClose={handleCloseCover} productId={productId} />

      <DuplicateModal open={duplicateModalOpen} onClose={() => setDuplicateModalOpen(false)} sectionId={section.id} />

      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" className="h-8 w-8 p-0">
            <MoreHorizontal className="h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>

        <DropdownMenuContent className="w-56">
          <DropdownMenuItem onSelect={handleEditSection}>
            <Edit className="mr-2 h-4 w-4" />
            <span>{t('dashboard.menu.editSection')}</span>
          </DropdownMenuItem>

          <DropdownMenuItem onSelect={handleEditCover}>
            <Upload className="mr-2 h-4 w-4" />
            <span>{t(section?.cover ? 'dashboard.menu.editSectionCover' : 'dashboard.menu.addSectionCover')}</span>
          </DropdownMenuItem>

          <DropdownMenuItem onSelect={handleDuplicateSection}>
            <Copy className="mr-2 h-4 w-4" />
            <span>{t('dashboard.menu.duplicateSection')}</span>
          </DropdownMenuItem>

          <DropdownMenuItem onSelect={handleReleaseDays}>
            <Clock className="mr-2 h-4 w-4" />
            <span>{t('dashboard.menu.editReleaseDays')}</span>
          </DropdownMenuItem>

          <DropdownMenuItem onSelect={handleDeleteSection}>
            <Trash2 className="mr-2 h-4 w-4" />
            <span>{t('dashboard.menu.remove')}</span>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  )
}
