import { Analytics, AnalyticsEvent } from '@hub-la/fe-core-analytics'
import { Auth } from '@hub-la/fe-core-auth'
import { HttpClient, HttpMethod } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { SignInByEmailInput } from '../domain/dtos/sign-in-by-email-input'
import { AnalyticsAuthMethod } from '../domain/enums/analytics-auth-method'
import { FirebaseAuthError } from '../domain/errors/firebase-auth-error'
import { GeneralError } from '../domain/errors/general'
import { InvalidCaptchaError } from '../domain/errors/invalid-captcha-error'
import { SignInWithoutPasswordError } from '../domain/errors/sign-in-without-password-error'
import { SignInWrongPasswordError } from '../domain/errors/sign-in-wrong-password-error'
import { SignInNotfoundError } from '../domain/errors/signin-not-found-error'
import { Envs } from '../envs'

export class AuthSignInByEmail {
  public constructor(
    @inject(HttpClient) private readonly httpClient: HttpClient,
    @inject(Auth) private readonly auth: Auth,
    @inject(Analytics) private readonly analytics: Analytics,
  ) {}

  public async execute(input: SignInByEmailInput): Promise<null> {
    if (!input?.captcha) {
      throw new InvalidCaptchaError()
    }

    try {
      const auth = await this.auth.signInWithPassword(input.email, input.password)
      this.analytics.track(AnalyticsEvent.AUTHENTICATION.SUCCESSFUL, {
        user: auth?.user?.uid,
        type: AnalyticsAuthMethod.EMAIL,
        isNewUser: false,
      })

      return null
    } catch (e: any) {
      switch (e?.code) {
        case 'auth/user-not-found':
          throw new SignInNotfoundError()
        case 'auth/wrong-password':
          await this.throwWrongPasswordError(input.email, input?.captcha)
          break
        default:
          throw new FirebaseAuthError(e.code)
      }

      throw new GeneralError()
    }
  }

  private async throwWrongPasswordError(email: string, captcha?: string): Promise<never> {
    const isUsingPassword = await this.isUsingPassword(email, captcha)

    if (!isUsingPassword) {
      throw new SignInWithoutPasswordError()
    }

    throw new SignInWrongPasswordError()
  }

  private async isUsingPassword(email: string, captcha?: string): Promise<boolean> {
    const response = await this.httpClient.request({
      method: HttpMethod.POST,
      url: `${Envs.BFF_WEB_URL}/auth/is-using-password`,
      body: {
        email,
        captcha,
      },
    })

    return response.data
  }
}
