import { Button } from '@hub-la/shadcn'
import { PlusCircle } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CreateSectionModal } from './create-section-modal'

export const CreateSectionButton = () => {
  const { t } = useTranslation()
  const [open, setOpen] = useState<boolean>(false)

  return (
    <>
      <Button data-testid="view-content-btn" variant="default" size="sm" onClick={() => setOpen(true)}>
        <PlusCircle className="mr-2 h-4 w-4" />
        {t('dashboard.header.createSection')}
      </Button>

      <CreateSectionModal isOpen={open} onClose={() => setOpen(false)} />
    </>
  )
}
