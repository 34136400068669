import { Avatar, AvatarFallback, AvatarImage } from '@hub-la/shadcn'
import React from 'react'
import defaultAvatar from '../../../../assets/default-avatar.jpg'
import { percentMask } from '../../../../pages/utils'

export interface CoProducerProps {
  commission: number
  name: string
  photo?: string | null
  icon?: React.ReactNode
}

export const CoProducer: React.FC<CoProducerProps> = ({ commission, icon, name, photo }) => {
  return (
    <div className="flex items-center justify-between p-2" data-testid="co-producer-container">
      <div className="flex items-center">
        <Avatar className="h-10 w-10 bg-background">
          {icon ? (
            <AvatarFallback>{icon}</AvatarFallback>
          ) : (
            <>
              <AvatarImage src={photo || defaultAvatar} alt={name} className="object-cover" />
              <AvatarFallback>{name ? name.charAt(0) : 'U'}</AvatarFallback>
            </>
          )}
        </Avatar>

        <span className="ml-2 max-w-[120px] truncate text-sm font-medium" data-testid="co-producer-name">
          {name}
        </span>
      </div>
      <span className="text-sm font-medium" data-testid="co-producer-commission">
        {percentMask(commission)}%
      </span>
    </div>
  )
}
