import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { PutCommentInput } from '../domain/dtos/put-comment-input'
import { GeneralError } from '../domain/errors/general'
import { Envs } from '../envs'

export class PutComment {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient<void>,
  ) {}

  public async execute(input: PutCommentInput): Promise<void> {
    const response = await this.httpClient.request({
      method: HttpMethod.PUT,
      url: `${Envs.BFF_MEMBERS_AREA_URL}/hub/posts/${input.postId}/comments/${input.commentId}`,
      body: this.makeBody(input),
    })

    if (response.statusCode === HttpStatusCode.CREATED || response.statusCode === HttpStatusCode.OK) {
      return
    }

    throw new GeneralError()
  }

  private makeBody = (input: PutCommentInput) => {
    return {
      message: input.message,
    }
  }
}
