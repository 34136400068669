import { Card, CardDescription, CardHeader, CardTitle, Skeleton } from '@hub-la/shadcn'
import { InfoTooltip } from './info-tooltip'

type CardInfoBoxProps = {
  title: string
  description?: string
  current: string
  isLoading?: boolean
}

export const CardInfoBox = ({ title, description, current, isLoading }: CardInfoBoxProps) => {
  if (isLoading) {
    return <Skeleton className="h-[104px] w-full" />
  }

  return (
    <div className="w-full h-full">
      <Card className="min-h-full">
        <CardHeader className="space-y-0">
          <CardDescription>
            <div className="text-muted-foreground flex items-center gap-1">
              {title}
              {description && <InfoTooltip title={description} />}
            </div>
          </CardDescription>
          <CardTitle className="text-3xl font-semibold tabular-nums">
            {current}
          </CardTitle>
        </CardHeader>
      </Card>
    </div>
  )
}
