import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation } from '@tanstack/react-query'
import { RetryEventHistoryInput } from '../../domain/dtos/retry-event-history-input'
import { RetryEventHistory } from '../../usecases/retry-event-history'

export const useRetryEventHistory = () => {
  const container = useContainer()

  return useMutation(
    [],
    (input: RetryEventHistoryInput) => new RetryEventHistory(container.get(HttpClient)).execute(input),
    {
      retry: false,
    },
  )
}
