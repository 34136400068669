import { Badge, Button } from '@hub-la/shadcn'
import { AlertTriangle } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { formatCurrency } from '../../../../domain/vos/format-currency'
import { useGetSmartInstallmentCyclesSummarie } from '../../../hooks/use-get-smart-installment-cycles-summarie'

export const OverdueSmartInstallmentActionButton = () => {
  const { t } = useTranslation()

  const history = useHistory()

  const { data } = useGetSmartInstallmentCyclesSummarie()

  if (!data?.amountTotalOverdueCents) {
    return <></>
  }

  return (
    <Button
      variant="secondary"
      className="w-full px-4 py-3 gap-2"
      onClick={() => history.push('/user_overdue_smart_installments')}
    >
      <div className="w-full flex items-center gap-2">
        <AlertTriangle className="text-red-400" />
        <span>{t('smartInstallment.overdueInstallments')}</span>
      </div>
      <Badge variant="destructive" className="rounded-full text-red-400">
        {formatCurrency(data?.amountTotalOverdueCents ?? 0)}
      </Badge>
    </Button>
  )
}
