import { PreviewCheckout } from '../../../../components/preview-checkout/preview-checkout'

interface PricingPreviewProps {
  productName?: string
  productOwner?: string
  productPicture?: string | null
}

export const PricingPreview = ({ productName = '', productOwner = '', productPicture = null }: PricingPreviewProps) => {
  return (
    <div className="sticky top-[73px] md:col-span-3 rounded-lg pt-8 pb-12 px-14 w-full h-full sm:max-h-[calc(100vh-73px)] flex flex-col justify-center bg-container-background">
      {/** @todo: Add the product details */}
      <PreviewCheckout
        group={{
          name: productName,
          owner: productOwner,
          picture: productPicture,
        }}
      />
    </div>
  )
}
