import { useEffect, useRef, useState } from 'react'

type Size = {
  width: number
  height: number
}

const useMeasure = (): [React.RefObject<HTMLDivElement>, Size] => {
  const ref = useRef<HTMLDivElement>(null)
  const [size, setSize] = useState<Size>({ width: 0, height: 0 })

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      const { width, height } = entries[0].contentRect
      setSize({ width, height })
    })

    if (ref.current) {
      resizeObserver.observe(ref.current)
    }

    return () => {
      resizeObserver.disconnect()
    }
  }, [ref.current])

  return [ref, size]
}

export { useMeasure }
