import { boolean, date, InferType, object, string } from 'yup'

export const createEventProductSchema = object({
  name: string().min(3, 'O nome do produto deve ter pelo menos 3 caracteres').required('Nome do produto é obrigatório'),
  description: string(),
  category: string().required(),
  eventData: object({
    startDate: date()
      .typeError('Data inválida')
      .test('isGreaterNow', 'A data/hora inicial deve ser maior que agora', function (startDate) {
        if (!startDate) return false
        return new Date(startDate) > new Date()
      })
      .required('Data inicial é obrigatória'),
    endDate: date()
      .required('Data final é obrigatória')
      .typeError('Data inválida')
      .test('isGreaterNow', 'A data inicial deve ser maior que agora', function (endDate) {
        if (!endDate) return false
        return new Date(endDate) > new Date()
      })
      .test('isGreater', 'A data final deve ser maior que a data inicial', function (endDate) {
        if (!endDate) return false
        return new Date(endDate) > new Date(this.parent.startDate)
      }),
    isLocationNotDefined: boolean(),
    location: object({
      city: string(),
      state: string(),
      country: string(),
      zipCode: string(),
      street: string(),
      number: string(),
      complement: string(),
      neighborhood: string(),
    })
      .required()
      .when('isLocationNotDefined', {
        is: true,
        then: object().nullable(),
        otherwise: object({
          city: string().required(),
          state: string().required(),
          country: string().required(),
          zipCode: string().required(),
          street: string().required(),
          number: string().required(),
          complement: string(),
          neighborhood: string().required(),
        }).required(),
      }),
  }).required(),
})

export type CreateEventProductSchemaType = InferType<typeof createEventProductSchema>
