import { CohortDto } from '@hub-la/sdk-bff-product'
import { Badge, cn, Label, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@hub-la/shadcn'
import { useTranslation } from 'react-i18next'

type CohortSelectProps = {
  onChange: (value: any) => void
  value: string
  disabled?: boolean
  options?: CohortDto[]
  helperText?: string
  error?: boolean
}

export const CohortSelect: React.FC<CohortSelectProps> = ({
  onChange,
  value,
  disabled,
  options = [],
  error,
  helperText,
}) => {
  const { t } = useTranslation()

  return (
    <div className="mt-6 w-full">
      <Label htmlFor="cohort-select" className={error ? 'text-destructive' : ''}>
        {t('tabs.participants.changeMembersCohortsModal.cohortsSelect.label')}
      </Label>

      <Select value={value} onValueChange={onChange} disabled={disabled}>
        <SelectTrigger id="cohort-select" className={cn('mt-2', error ? 'border-destructive' : '')}>
          <SelectValue placeholder={t('tabs.participants.changeMembersCohortsModal.cohortsSelect.placeholder')} />
        </SelectTrigger>

        <SelectContent className="z-[72]">
          {options.map((cohort) => (
            <SelectItem key={cohort.id} value={cohort.id} className="py-2 items-center gap-3">
              <span className="text-sm">{cohort.name}</span>

              {cohort.isDefault && (
                <Badge variant="secondary" className="text-xs">
                  {t('cohorts.table.isDefault')}
                </Badge>
              )}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>

      {helperText && (
        <p className={cn('text-sm mt-1', error ? 'text-destructive' : 'text-muted-foreground')}>{helperText}</p>
      )}
    </div>
  )
}
