import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDeactivateSubscription } from '../../hooks/use-deactivate-subscription'

import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  toast,
} from '@hub-la/shadcn'

type Props = {
  id: string
  open: boolean
  onClose: () => void
  onSuccess: () => void
}

export const DeactivateSubscriptionModal: React.FC<Props> = (props) => {
  const { id, open, onClose, onSuccess } = props
  const { mutate: deactivateSubscription, isLoading } = useDeactivateSubscription()
  const { t } = useTranslation()

  const onSubmit = () => {
    deactivateSubscription(id, {
      onSuccess: () => {
        onSuccess()
        onClose()
        toast({
          description: t('success') ?? '',
        })
      },
      onError: (error) => {
        toast({
          description: t((error as Error)?.message) ?? '',
          variant: 'destructive',
        })
      },
    })
  }

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Cancelar assinatura</DialogTitle>
          <DialogDescription>
            Ao cancelar as assinaturas, a renovação automática será desativada. Os membros manterão o acesso à
            assinatura até o final do período já pago, e a assinatura será encerrada na data de vencimento.
          </DialogDescription>
        </DialogHeader>
        <DialogFooter className="gap-2">
          <Button variant="outline" onClick={onClose}>
            Voltar
          </Button>
          <Button loading={isLoading} variant="destructive" onClick={onSubmit} disabled={isLoading}>
            Cancelar assinatura
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
