import React from 'react'
import { useTranslation } from 'react-i18next'
import { Card, CardContent, CardHeader, CardTitle, Separator } from "@hub-la/shadcn"
import { Book } from "lucide-react"

export const InstructionRules = () => {
  const { t } = useTranslation()

  const rules = [
    t('rules.rule1'),
    t('rules.rule2'),
    t('rules.rule3'),
    t('rules.rule4'),
    t('rules.rule5')
  ]

  return (
    <Card className="w-full">
      <CardHeader>
        <CardTitle className="flex items-center space-x-4">
          <Book className="w-6 h-6" />
          <h2 className="text-lg font-medium">{t('rules.title')}</h2>
        </CardTitle>
      </CardHeader>
      <CardContent className="flex flex-col divide-y">
        {rules.map((rule) => (
          <p className="text-sm font-medium block py-4">{rule}</p>
        ))}
      </CardContent>
    </Card>
  )
}
