import React from 'react'
import { AppHeader } from '../../components/app-header'
import { BackwardButton } from '../../components/backward-button'
import { Header } from './components/header'
import { OverdueSmartInstallmentsList } from './components/overdue-smart-installments-list'

export const UserOverdueSmartInstallmentsPage: React.FC = () => {
  return (
    <div className="flex flex-col container px-4 md:px-24">
      <AppHeader />
      <div className="flex flex-col pt-0 md:pt-12 pb-12 gap-8">
        <BackwardButton />
        <Header />
        <OverdueSmartInstallmentsList />
      </div>
    </div>
  )
}
