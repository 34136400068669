import { container } from '@hub-la/fe-container'
import { ExportPage, ExportProvider } from '@hub-la/fe-export'

export const ExportInit = () => {
  return (
    <ExportProvider container={container}>
      <ExportPage />
    </ExportProvider>
  )
}
