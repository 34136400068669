import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Card, Separator, Skeleton } from '@hub-la/shadcn'

export const Loading: React.FC = () => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col gap-1">
        <div className="flex justify-between items-center">
          <h2 className="text-2xl font-semibold">{t('upgradePlanModal.title')}</h2>
        </div>
        <Skeleton className="w-full h-4" />
      </div>

      <div className="flex flex-col gap-4">
        <h3 className="font-bold">Novo plano</h3>
        <Skeleton className="w-full h-14" />
        <Skeleton className="w-full h-14" />
      </div>

      <Card className="p-6 space-y-6">
        <Skeleton className="w-40 h-11" />
        <div className="space-y-2">
          <div className="flex justify-between items-center">
            <Skeleton className="w-40 h-4" />
            <Skeleton className="w-16 h-4" />
          </div>
          <div className="flex justify-between items-center">
            <Skeleton className="w-40 h-4" />
            <Skeleton className="w-16 h-4" />
          </div>
          <Separator className="my-2" />
          <div className="flex justify-between items-center">
            <Skeleton className="w-40 h-4" />
            <Skeleton className="w-16 h-4" />
          </div>
        </div>
      </Card>

      <Button disabled className="w-full">
        {t('upgradePlanModal.button')}
      </Button>
    </div>
  )
}
