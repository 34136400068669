import {
  Badge,
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Input,
  Label,
} from '@hub-la/shadcn'
import * as Yup from 'yup'
import { Field, FormikProvider, useFormik } from 'formik'
import { Download } from 'lucide-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ExportAlreadyInProgressError } from '../../../domain/errors/export-already-in-progress'
import { useDownloadSmartInstallmentsXlsx } from '../../hooks/use-download-smart-installments-xlsx'

type Props = {
  filters: any
  offers: any[]
}

export const ConfirmExportModal: React.FC<Props> = ({ filters, offers }) => {
  const { t } = useTranslation()

  const form = useFormik<{
    receiverEmail: string
  }>({
    initialValues: {
      receiverEmail: '',
    },
    enableReinitialize: true,
    onSubmit: async () => {
      await exportXlsx()
        .then(() => {
          form.resetForm()
          setShowConfirmModal(true)
        })
        .catch(console.error)
    },
    validationSchema: Yup.object().shape({
      receiverEmail: Yup.string().email('Email inválido').required('Email é obrigatório'),
    }),
  })

  const [showConfirmModal, setShowConfirmModal] = React.useState(false)

  const {
    mutateAsync: exportXlsx,
    isLoading: loading,
    isSuccess,
    isError,
    data,
    error,
    reset,
  } = useDownloadSmartInstallmentsXlsx({
    offerIds: offers.map((offer) => offer.id),
    statuses: filters.statuses,
    offers: offers,
    receiverEmail: form.values.receiverEmail,
  })

  function handleClose() {
    reset()
    form.resetForm()
    setShowConfirmModal(false)
  }

  function getModalMessage() {
    if (isSuccess) return t('exportXlsx.successMessage', { email: data?.email })
    if (error instanceof ExportAlreadyInProgressError) return t('exportXlsx.conflictErrorMessage')
    return t('exportXlsx.generalErrorMessage')
  }

  if (isSuccess || isError) {
    return (
      <Dialog open={showConfirmModal} onOpenChange={handleClose}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{t('exportXlsx.title')}</DialogTitle>
          </DialogHeader>
          <p>{getModalMessage()}</p>
          <div className="py-4"></div>
          <div className="flex justify-end">
            <Button onClick={handleClose} variant="outline">
              {t('exportXlsx.getIt')}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    )
  }

  return (
    <Dialog
      open={showConfirmModal}
      onOpenChange={() => {
        setShowConfirmModal((prevState) => !prevState)
        if (showConfirmModal) {
          handleClose()
        }
      }}
    >
      <DialogTrigger asChild>
        <Button variant="outline" className="w-full">
          <Download className="h-4 w-4 mr-2" />
          {t('exportXlsx.confirm')}
        </Button>
      </DialogTrigger>
      <DialogContent className="w-full md:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{t('exportXlsx.title')}</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div>
            <h3 className="mb-2 font-semibold">Filtros selecionados:</h3>
            {filters.search && <Badge className="mr-2 mb-2">Busca: {filters.search}</Badge>}
            {filters.offerIds?.length > 0 && (
              <Badge className="mr-2 mb-2">
                Produtos/Ofertas: {filters.offerIds.map((id) => offers.find((o) => o.id === id)?.name).join(', ')}
              </Badge>
            )}
            {filters.startDate && filters.endDate && (
              <Badge className="mr-2 mb-2">
                Data de criação: {new Date(filters.startDate).toLocaleDateString()} -{' '}
                {new Date(filters.endDate).toLocaleDateString()}
              </Badge>
            )}
            {filters.statuses?.length > 0 && (
              <Badge className="mr-2 mb-2">
                Status: {filters.statuses.map((status) => t(`status.${status}`)).join(', ')}
              </Badge>
            )}
          </div>
          <FormikProvider value={form}>
            <form onSubmit={form.handleSubmit}>
              <div className="grid gap-2">
                <Label htmlFor="receiverEmail">{t('exportXlsx.email')}</Label>
                <Field
                  as={Input}
                  id="receiverEmail"
                  name="receiverEmail"
                  placeholder="example@email.com"
                  type="email"
                  className={
                    form.errors.receiverEmail && form.touched.receiverEmail && form.dirty ? 'border-red-500' : ''
                  }
                />
                {form.errors.receiverEmail && form.touched.receiverEmail && form.dirty ? (
                  <div className="text-sm text-red-500 gap-2">{form.errors.receiverEmail}</div>
                ) : null}
              </div>

              <div className="flex justify-end space-x-2 mt-4">
                <DialogClose onClick={handleClose}>
                  <Button variant="outline" disabled={loading}>
                    {t('exportXlsx.cancel')}
                  </Button>
                </DialogClose>
                <Button
                  type="submit"
                  loading={loading}
                  className="flex gap-2"
                  disabled={!!form.errors.receiverEmail || !form.values.receiverEmail}
                >
                  <Download className="h-4 w-4" />
                  {t('exportXlsx.confirm')}
                </Button>
              </div>
            </form>
          </FormikProvider>
        </div>
      </DialogContent>
    </Dialog>
  )
}
