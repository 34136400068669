import { Moment } from 'moment'

export class isThirtyDaysFromToday {
  public execute(startDate?: Moment): boolean {
    if (!startDate) {
      return false
    }
    const today = new Date()
    const thirtyDaysFromToday = new Date(today)
    thirtyDaysFromToday.setDate(today.getDate() + 30)

    return startDate?.toDate() > thirtyDaysFromToday
  }
}
