import { Input } from '@hub-la/shadcn'
import { Search } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { SelectStatus } from './select-status'
import { useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

interface FiltersProps {
  setFilters: (filters: any) => void
  filters: any
  status: SelectStatus[]
  label: string
}

export const Filters: React.FC<FiltersProps> = ({ setFilters, filters, status, label }) => {
  const { t } = useTranslation()
  const [localSearch, setLocalSearch] = useState(filters.search)
  const [localStatus, setLocalStatus] = useState(filters.statuses)

  const debouncedSetFilters = useDebouncedCallback((value) => {
    setFilters(value)
  }, 300)

  return (
    <div className="flex flex-col md:flex-row justify-between gap-3">
      <div className="flex flex-col w-full md:w-auto md:flex-row flex-wrap gap-3">
        <div className="relative h-10">
          <Search className="absolute w-4 h-4 right-3 top-1/2 transform -translate-y-1/2 text-muted-foreground z-10" />
          <Input
            type="text"
            placeholder={t('search')}
            className="md:w-[250px] w-full"
            value={localSearch}
            onChange={(e) => {
              const value = e.target.value
              setLocalSearch(value)
              debouncedSetFilters({ ...filters, search: value, page: 1 })
            }}
          />
        </div>
        <SelectStatus
          label={label}
          status={status}
          value={localStatus}
          onChange={(value) => {
            setLocalStatus(value)
            debouncedSetFilters((prevState) => ({ ...prevState, statuses: value, page: 1 }))
          }}
        />
      </div>
    </div>
  )
}
