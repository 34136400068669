/* tslint:disable */
/* eslint-disable */
/**
 * BFF Web Product
 * API reference used to call the backend from the web frontend
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { EditFunnelStrategyDto } from './edit-funnel-strategy-dto';
// May contain unused imports in some cases
// @ts-ignore
import { EditOfferAfterPurchaseSettingsDto } from './edit-offer-after-purchase-settings-dto';
// May contain unused imports in some cases
// @ts-ignore
import { EditOfferCheckoutSettingsDto } from './edit-offer-checkout-settings-dto';
// May contain unused imports in some cases
// @ts-ignore
import { EditOfferPricingSettingsDto } from './edit-offer-pricing-settings-dto';

/**
 * 
 * @export
 * @interface EditOfferRequestDto
 */
export interface EditOfferRequestDto {
    /**
     * 
     * @type {string}
     * @memberof EditOfferRequestDto
     */
    'cohortId': string;
    /**
     * 
     * @type {string}
     * @memberof EditOfferRequestDto
     */
    'name': string;
    /**
     * 
     * @type {EditOfferPricingSettingsDto}
     * @memberof EditOfferRequestDto
     */
    'pricing': EditOfferPricingSettingsDto;
    /**
     * 
     * @type {EditOfferCheckoutSettingsDto}
     * @memberof EditOfferRequestDto
     */
    'checkout': EditOfferCheckoutSettingsDto;
    /**
     * 
     * @type {EditOfferAfterPurchaseSettingsDto}
     * @memberof EditOfferRequestDto
     */
    'afterPurchase': EditOfferAfterPurchaseSettingsDto;
    /**
     * 
     * @type {EditFunnelStrategyDto}
     * @memberof EditOfferRequestDto
     */
    'orderBump'?: EditFunnelStrategyDto | null;
    /**
     * 
     * @type {EditFunnelStrategyDto}
     * @memberof EditOfferRequestDto
     */
    'upsell'?: EditFunnelStrategyDto | null;
    /**
     * 
     * @type {boolean}
     * @memberof EditOfferRequestDto
     */
    'isAvailableForAffiliates'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof EditOfferRequestDto
     */
    'installmentCostsPayer'?: EditOfferRequestDtoInstallmentCostsPayerEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum EditOfferRequestDtoInstallmentCostsPayerEnum {
    BUYER = 'buyer',
    SELLER = 'seller'
}


