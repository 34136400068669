import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { PaymentMethod } from '../../../domain/enums/payment-method'
import { PlanType } from '../../../domain/enums/plan-type'
import { SubscriptionStatus } from '../../../domain/enums/subscription-status'

import { Separator } from '@hub-la/shadcn'
import { PaymentMethodIcon } from '../../components/payment-method-icon'

type Props = {
  detail: {
    id: string
    createdAt: string
    nextDueAt?: string
    canceledAt?: string
    planType: PlanType
    credits: number
    paymentMethod: PaymentMethod
    status: SubscriptionStatus
  }
}

const InfoRow: React.FC<{ label: string; value: React.ReactNode }> = ({ label, value }) => (
  <div className="space-y-1">
    <h4 className="text-sm text-muted-foreground font-medium leading-none">{label}</h4>
    <p className="text-sm">{value}</p>
  </div>
)

export const Details: React.FC<Props> = ({ detail }) => {
  const { createdAt, nextDueAt, canceledAt, planType, paymentMethod, credits, status } = detail
  const { t } = useTranslation()

  const formatDate = (date: string | undefined) => {
    return date ? moment(date).format('D MMM. YYYY HH:mm') : '-'
  }

  const infoItems = [
    { label: 'Data de criação', value: formatDate(createdAt) },
    ...(status !== SubscriptionStatus.ACTIVE && canceledAt
      ? [{ label: 'Data de cancelamento', value: formatDate(canceledAt) }]
      : []),
    ...(status !== SubscriptionStatus.INACTIVE ? [{ label: 'Data de vencimento', value: formatDate(nextDueAt) }] : []),
    ...(status === SubscriptionStatus.INACTIVE ? [{ label: 'Data de vencimento', value: formatDate(nextDueAt) }] : []),
    { label: 'Plano', value: t(`planType.${planType}`) },
    ...(status === SubscriptionStatus.ACTIVE ? [{ label: 'Dias restantes', value: `${credits} dias` }] : []),
    {
      label: 'Método de pagamento',
      value: (
        <div className="flex items-center space-x-2">
          <PaymentMethodIcon paymentMethod={paymentMethod} />
          <span>{t(`paymentMethod.${paymentMethod}`)}</span>
        </div>
      ),
    },
  ].filter(Boolean)

  return (
    <div className="w-full">
      <Separator className="mb-5" />
      <div className="flex items-start space-x-6 overflow-x-auto">
        {infoItems.map((item, index) => (
          <React.Fragment key={item.label}>
            <div className="flex-shrink-0 space-y-1">
              <InfoRow label={item.label} value={item.value} />
            </div>
            {index < infoItems.length - 1 && <Separator orientation="vertical" className="h-11" />}
          </React.Fragment>
        ))}
      </div>
    </div>
  )
}
