import { Card, CardContent, CardHeader, CardTitle, CopyClipboardButton, Separator } from '@hub-la/shadcn'
import { InvoicePayerSession } from '../../../domain/dtos/invoice'

type Props = {
  detail?: InvoicePayerSession
}

const InfoRow = ({ label, value }: { label: string; value?: string }) => (
  <div className="flex justify-between items-start mb-4">
    <div className="w-48 pr-2">
      <p className="text-sm">{label}</p>
    </div>
    <div className="pl-2 max-w-sm">
      {value && value.length ? (
        <CopyClipboardButton copyContent={value} label={value} tooltipCopiedLabel="Copiado" tooltipCopyLabel="Copiar" />
      ) : (
        '-'
      )}
    </div>
  </div>
)

export const CookieTracking: React.FC<Props> = (props) => {
  return (
    <Card>
      <CardHeader className="py-4 px-4">
        <CardTitle className="text-base font-semibold">Rastreamento de Cookies</CardTitle>
      </CardHeader>
      <Separator />
      <CardContent className="p-4 space-y-4">
        <InfoRow label="fbclid" value={props.detail?.fbclid} />
        <InfoRow label="gclid" value={props.detail?.gclid} />
      </CardContent>
    </Card>
  )
}
