import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '../../domain/enums/query-key'
import { InitChangePaymentMethod } from '../../usecases/init-change-payment-method'

export const useInitChangePaymentMethod = (subscriptionId: string, enabled: boolean) => {
  const container = useContainer()

  return useQuery(
    [QueryKey.initChangePaymentMethod, subscriptionId],
    () => new InitChangePaymentMethod(container.get(HttpClient)).execute(subscriptionId),
    {
      refetchOnWindowFocus: false,
      enabled: !!subscriptionId && enabled,
      retry: false,
    },
  )
}
