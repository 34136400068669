import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation } from '@tanstack/react-query'
import { QueryKey } from '../../domain/enums/query-keys'
import { CancelSmartInstallment } from '../../usecases/cancel-smart-installment'

export const useCancelSmartInstallment = () => {
  const container = useContainer()

  return useMutation(
    [QueryKey.cancelSmartInstallment],
    (id: string) => new CancelSmartInstallment(container.get(HttpClient)).execute(id),
    {
      retry: false,
    },
  )
}
