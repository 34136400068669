import { Auth as CoreAuth } from '@hub-la/fe-core-auth'
import { Auth, getAuth, signInWithCustomToken, signInWithEmailAndPassword, signOut, User } from 'firebase/auth'
import { injectable } from 'inversify'

@injectable()
export class FirebaseAuth extends CoreAuth {
  private readonly auth: Auth
  private token: string | undefined
  private expirationTime: number | undefined

  public constructor() {
    super()
    this.auth = getAuth()
  }

  public async getToken(): Promise<string | undefined> {
    if (this.token && this.expirationTime && Date.now() < this.expirationTime) {
      return this.token
    }

    try {
      const currentUser = this.getCurrentUser()
      if (currentUser) {
        const tokenResult = await currentUser.getIdTokenResult()
        this.token = tokenResult.token
        this.expirationTime = new Date(tokenResult.expirationTime).getTime()
        return this.token
      }

      return new Promise((resolve) =>
        this.auth.onAuthStateChanged((currentUser) => resolve(currentUser?.getIdToken(true))),
      )
    } catch (error) {
      console.error('Error getting token:', error)
      return undefined
    }
  }

  public signInWithToken(token: string) {
    return signInWithCustomToken(this.auth, token)
  }

  public signInWithPassword(email: string, password: string) {
    return signInWithEmailAndPassword(this.auth, email, password)
  }

  public signOut(): Promise<void> {
    this.token = undefined
    this.expirationTime = undefined
    return signOut(this.auth)
  }

  public listen(callable: (user: User | null) => void): () => void {
    return this.auth.onAuthStateChanged(callable)
  }

  private getCurrentUser(): User | null {
    return this.auth.currentUser
  }
}
