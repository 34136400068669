import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useQuery } from '@tanstack/react-query'
import { GetSmartInstallmentInvoicesInput } from '../../domain/dtos/get-smart-installment-invoices-input'
import { QueryKey } from '../../domain/enums/query-keys'
import { GetSmartInstallmentInvoices } from '../../usecases/get-smart-installment-invoices'

export const useGetSmartInstallmentInvoices = (input: GetSmartInstallmentInvoicesInput) => {
  const container = useContainer()

  const enabled = !!input.smartInstallmentId
  const initialData = {
    page: 1,
    pageSize: 0,
    total: 0,
    lastPage: 1,
    items: [],
  }

  return useQuery(
    [QueryKey.getSmartInstallmentInvoices, input.smartInstallmentId],
    () => {
      if (!enabled) {
        return Promise.resolve(initialData)
      }
      return new GetSmartInstallmentInvoices(container.get(HttpClient)).execute(input)
    },
    {
      retry: 3,
      retryOnMount: false,
      refetchOnWindowFocus: false,
      initialData,
      enabled,
    },
  )
}
