import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation } from '@tanstack/react-query'
import { SaveAssetProgressRequest } from '../../domain/dtos/save-asset-progress-request'
import { SaveAssetProgress } from '../../usecases/save-asset-progress'

export const useSaveAssetProgress = () => {
  const container = useContainer()

  return useMutation(
    [],
    (payload: SaveAssetProgressRequest) => new SaveAssetProgress(container.get(HttpClient)).execute(payload),
    {},
  )
}
