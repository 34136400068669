import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { GeneralError } from '../domain/errors/general'
import { Envs } from '../envs'
import { TicketSchemaValidationType } from '../presentation/validations/ticket-validation'

export class PostTicket {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(input: TicketSchemaValidationType, signal?: AbortSignal) {
    const response = await this.httpClient.request({
      method: HttpMethod.POST,
      url: `${Envs.BFF_PRODUCT_URL}/products/${input.productId}/cohorts/upsert-event-tickets`,
      signal,
      body: {
        ...input,
        isDefault: false,
        metadata: {
          logoUrl: input.logoUrl,
          primaryColor: input.primaryColor,
          secondaryColor: input.secondaryColor,
          textColor: input.textColor,
        },
      },
    })

    if ([HttpStatusCode.OK, HttpStatusCode.CREATED].includes(response.statusCode)) {
      return response.data
    }

    if (response.data?.errorCode) {
      throw new Error(response.data.errorCode.replace(/:/g, '-'))
    }

    throw new GeneralError()
  }
}
