import { Card, CardContent, CardHeader, Skeleton } from '@hub-la/shadcn'

export const Loading = () => {
  return (
    <div className="h-full w-full">
      <Card className="h-full">
        <CardHeader className="space-y-0 gap-1">
          <Skeleton className="animate-pulse bg-secondary h-6 w-4/5 rounded" />
          <Skeleton className="animate-pulse bg-secondary h-11 w-[155px] rounded" />
          <Skeleton className="animate-pulse bg-secondary h-6 w-[250px] rounded" />
        </CardHeader>
        <CardContent className="px-0">
          <div className="w-full max-h-80 px-6 space-y-1">
            <Skeleton className="animate-pulse bg-secondary h-[250px] w-full rounded" />
            <div className="flex flex-row justify-between">
              <Skeleton className="animate-pulse bg-secondary h-5 w-[75px] rounded" />
              <Skeleton className="animate-pulse bg-secondary h-5 w-[75px] rounded" />
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  )
}
