import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { ChangeProductStatusInput } from '../domain/dtos/change-product-status'
import { Product } from '../domain/dtos/product'
import { ProductStatus } from '../domain/dtos/product-status'
import { GeneralError } from '../domain/errors/general'
import { Envs } from '../envs'

export class ChangeProductStatus {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(input: ChangeProductStatusInput): Promise<Product> {
    const response = await this.httpClient.request({
      method: HttpMethod.PATCH,
      url: `${Envs.BFF_PRODUCT_URL}/products/${input.productId}/status`,
      body: this.makeBody(input),
    })

    if (response.statusCode === HttpStatusCode.OK || response.statusCode === HttpStatusCode.CREATED) {
      return response.data
    }

    throw new GeneralError()
  }

  private makeBody(input: ChangeProductStatusInput) {
    const status = {
      [ProductStatus.SELLING]: 'selling',
      [ProductStatus.NOT_SELLING]: 'notSelling',
      [ProductStatus.WAITLISTED]: 'waitlist',
    }
    return {
      status: status[input.status],
    }
  }
}
