import { UploadStatus } from '@hub-la/fe-asset'
import { Button } from '@hub-la/shadcn'
import { useFormikContext } from 'formik'
import { Trash2 } from 'lucide-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { AttachedProgress } from '../../../../domain/dtos/attached-progress'
import { Values } from '../../../../domain/dtos/values'

export interface IAttachmentButton {
  id: string
  status: UploadStatus
  isAvailable: boolean
  lastChild: boolean
  attachedProgress: Array<AttachedProgress> | null
  isLoading: boolean
}

export const AttachmentButton: React.FC<IAttachmentButton> = (props: IAttachmentButton): JSX.Element => {
  const { id, status, lastChild, isAvailable, isLoading, attachedProgress } = props

  const { t } = useTranslation()
  const { setFieldValue, values } = useFormikContext<Values>()

  const handleDelete = () => {
    const attachedAssets = values.attachedAssets.filter((asset) => asset.id !== id)
    setFieldValue('attachedAssets', attachedAssets)
  }

  if (
    status === UploadStatus.LOADING &&
    attachedProgress &&
    attachedProgress.findIndex((el) => el.attachedId === id && el.progress !== 100) !== -1
  ) {
    return (
      <Button variant="outline" size="sm">
        {t('mediaUpload.waitLabel')}
      </Button>
    )
  }

  if (status === UploadStatus.STARTED && lastChild) {
    return (
      <Button variant="outline" size="sm">
        {t('mediaUpload.waitLabel')}
      </Button>
    )
  }

  if (isAvailable || (attachedProgress && attachedProgress.findIndex((el) => el.progress === 100) !== -1)) {
    return (
      <Button className="flex gap-1" variant="outline" size="sm" onClick={handleDelete} loading={isLoading}>
        <Trash2 size={16} />

        {t('assets.remove')}
      </Button>
    )
  }

  return <></>
}
