import { CohortDto } from '@hub-la/sdk-bff-product'
import { useMemo } from 'react'

interface UseCohortDataParams {
  data?: CohortDto[]
  cohorts: CohortDto[]
  isLoadingData: boolean
}

export interface CohortsCardSwitch {
  cohort: CohortDto
  checked?: boolean
}

export const useInitialState = ({ data, cohorts, isLoadingData }: UseCohortDataParams) => {
  const cohortIds = useMemo(() => {
    if (!cohorts.length) {
      return []
    }
    return cohorts.map((cohort) => cohort.id)
  }, [cohorts])

  const switchCohorts = useMemo(() => {
    if (!cohorts.length) {
      return []
    }
    return cohorts.map((cohort) => ({ cohort: cohort, checked: true }))
  }, [cohorts])

  const memoizedCombinedData = useMemo(() => {
    if (isLoadingData) {
      return []
    }

    return [
      ...switchCohorts,
      ...(data
        ? data
            .filter((cohort) => !cohortIds.includes(cohort.id))
            .map((cohort) => ({
              cohort: cohort,
              checked: false,
            }))
        : []),
    ]
  }, [data, isLoadingData])

  return useMemo(() => {
    const hasAtLeastOneChecked = memoizedCombinedData.some((item) => item.checked)
    return memoizedCombinedData
      .map((cohortSwitch) => ({
        cohort: cohortSwitch.cohort,
        checked: hasAtLeastOneChecked ? cohortSwitch.checked : cohortSwitch.cohort.isDefault,
      }))
      .sort((a, b) => a.cohort.createdAt.localeCompare(b.cohort.createdAt))
  }, [memoizedCombinedData])
}
