import {
  Button,
  cn,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@hub-la/shadcn'
import { useTranslation } from 'react-i18next'

type GenericConfirmModalProps = {
  title: string
  description: string
  cancelText?: string
  confirmText?: string
  isOpen: boolean
  onCancel: () => void
  onDelete: () => void
  isLoading?: boolean
}

export const GenericConfirmModal: React.FC<GenericConfirmModalProps> = ({
  title,
  description,
  isOpen,
  onCancel,
  onDelete,
  isLoading,
  confirmText,
  cancelText,
}) => {
  const { t } = useTranslation()

  return (
    <Dialog open={isOpen} onOpenChange={onCancel}>
      <DialogContent className="sm:max-w-lg">
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>

          <DialogDescription>{description}</DialogDescription>
        </DialogHeader>

        <DialogFooter className="sm:justify-end">
          <div className="w-full sm:w-fit flex flex-col-reverse sm:flex-row gap-2 sm:gap-4">
            <Button variant="outline" size="lg" onClick={onCancel}>
              {cancelText || t('genericConfirmModal.cancel')}
            </Button>

            <Button
              size="lg"
              loading={isLoading}
              variant="destructive"
              onClick={onDelete}
              className={cn('relative', isLoading && 'text-transparent hover:text-transparent')}
            >
              {confirmText || t('genericConfirmModal.confirm')}
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
