export class SoftDescriptor {
  public constructor(private readonly value: string) {}

  private SOFT_DESCRIPTOR_PREFIX = 'HBL*'

  public static build(value: string): SoftDescriptor {
    return new SoftDescriptor(value)
  }

  public withoutPrefix(): string {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_prefix, ...sufix] = this.value.split('*')
    return sufix.length > 0 ? sufix.join('*') : this.value
  }

  public withPrefix(): string {
    const withoutPrefix = this.withoutPrefix()
    return `${this.SOFT_DESCRIPTOR_PREFIX}${withoutPrefix}`
  }
}
