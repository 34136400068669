import { useTranslation } from 'react-i18next'
import { InfoTooltip } from '../../../components/info-tooltip'
import { StatusBox } from '../../../components/status-box'
import { useGetActiveSubscriptions } from '../../../hooks/use-get-active-subscriptions'
import { useRefetch } from '../../../hooks/use-refetch'

import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from '@hub-la/shadcn'
import { CartesianGrid, Line, LineChart, XAxis, YAxis } from 'recharts'
import { formatDate } from '../../../../domain/vos/format-date'
import { formatLocalNumber } from '../../../../domain/vos/format-local-number'
import { Loading } from './loading'

export const ActiveSubscriptions = ({ filters, offers, isLoading }) => {
  const { period, startDate, endDate, offerIds } = filters
  const { t } = useTranslation()

  const { data, isFetching, refetch } = useGetActiveSubscriptions(
    { period, startDate, endDate, offers, offerIds },
    isLoading,
  )

  useRefetch({ period, startDate, endDate, offers, offerIds }, refetch)

  if (isFetching || isLoading) {
    return <Loading />
  }

  const chartConfig = {
    subscriptions: {
      label: 'Assinaturas Ativas',
      color: 'hsl(var(--chart-1))',
      opacity: 1,
    },
  }

  const values = data.datasets
    .at(0)
    ?.data.map((item) => Math.max(item.y || 0, item.comparative || 0))
    .filter((item) => item !== null)

  const maxValue = values && values?.length > 0 ? Math.max(...values) : 0

  const yAxisUpperLimit = maxValue > 0 ? Math.ceil(maxValue * 1.5) : 1

  return (
    <div className="w-full h-full">
      <Card className="flex flex-col gap-8">
        <CardHeader className="pb-0 space-y-0">
          <div className="flex items-center gap-2">
            <p className="text-sm font-normal text-muted-foreground">{t('activeSubscriptions.title')}</p>
            <InfoTooltip title={t('activeSubscriptions.description')} />
          </div>
          <CardDescription className="flex flex-col gap-2">
            <h3 className="font-semibold text-4xl tracking-tight text-primary">{formatLocalNumber(data.current)}</h3>
            <StatusBox
              indicator={data.indicator}
              status={data.status}
              description={`${data.percentage} ${t('variation', {
                previous: formatLocalNumber(data.previous),
              })}`}
            />
          </CardDescription>
        </CardHeader>

        <CardContent className="px-0">
          <ChartContainer config={chartConfig} className="h-[290px] px-6">
            <LineChart width={600} height={290} data={data.datasets.at(0)?.data}>
              <CartesianGrid stroke="hsl(var(--border))" strokeOpacity={0.5} vertical={false} />
              <XAxis
                key="date"
                dataKey="date"
                type="category"
                tickLine={false}
                axisLine={false}
                tickMargin={8}
                allowDuplicatedCategory={false}
                tickFormatter={(value: string) => formatDate(value, period)}
                interval="preserveStartEnd"
              />

              <YAxis hide key="y" dataKey="y" type="number" domain={[0, yAxisUpperLimit]} allowDataOverflow={false} />

              <ChartTooltip
                content={
                  <ChartTooltipContent
                    className="w-56 bg-card rounded-lg p-2 shadow-lg"
                    labelFormatter={(value: string) => formatDate(value, period, 'D MMM. YYYY')}
                    valueFormatter={(value: string) => formatLocalNumber(Number(value))}
                  />
                }
              />

              <Line
                dataKey="y"
                type="monotoneX"
                name={chartConfig['subscriptions'].label}
                stroke={chartConfig['subscriptions'].color}
                strokeOpacity={chartConfig['subscriptions'].opacity}
                strokeWidth={2}
                dot={false}
              />
            </LineChart>
          </ChartContainer>
        </CardContent>
      </Card>
    </div>
  )
}
