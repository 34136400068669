import { AccountDropdownProvider } from '@hub-la/fe-account-dropdown'
import { AccountVerificationBanner, UpdatePayoutBanner } from '@hub-la/fe-account-verification'
import { container } from '@hub-la/fe-container'
import { useRoleplay } from '@hub-la/fe-roleplay'
import { cn, useIsMobile } from '@hub-la/shadcn'
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { AppHeader } from './app-header'
import { MenuSidebar } from './menu-sidebar'

type Props = {
  children: React.ReactNode
  hideSidebar?: boolean
  disablePadding?: boolean
  disableAppHeader?: boolean
}

export const DashboardContainer: React.FC<Props> = ({ children, hideSidebar, disablePadding, disableAppHeader }) => {
  const [toggleSidebar, setToggleSidebar] = useState(() => localStorage.getItem('toggleSidebar') === 'true')
  const { isRoleplay } = useRoleplay()
  const isMobile = useIsMobile()
  const history = useHistory()

  useEffect(() => {
    localStorage.setItem('toggleSidebar', String(toggleSidebar))
  }, [toggleSidebar])

  const handleToggleSidebar = useCallback((value: boolean) => {
    setToggleSidebar(value)
  }, [])

  return (
    <div className="min-h-screen flex flex-row-reverse">
      {!disableAppHeader && (
        <AccountDropdownProvider container={container}>
          <AppHeader isToggleSidebar={toggleSidebar} onToggleSidebar={handleToggleSidebar} hideSidebar={hideSidebar} />
        </AccountDropdownProvider>
      )}

      <main
        className={cn(
          'flex-grow w-full bg-container-background',
          isRoleplay ? 'mt-[92px]' : disableAppHeader ? '' : 'mt-[52px]',
        )}
      >
        {!isRoleplay && history.location.pathname.includes('dashboard') ? (
          <>
            <AccountVerificationBanner />
            <UpdatePayoutBanner />
          </>
        ) : null}

        <div className={cn('mx-auto', disablePadding ? 'pt-2 sm:pt-3 pb-24' : 'container p-6 sm:p-10 pb-24')}>
          {children}
        </div>
      </main>

      {!isMobile && !hideSidebar && <MenuSidebar isToggleSidebar={toggleSidebar} />}
    </div>
  )
}
