import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation } from '@tanstack/react-query'

import { QueryKey } from '../../domain/dtos/query-keys'

import { useContainer } from '@hub-la/fe-container'
import { ExportMovementsFilterDto } from '../../domain/dtos/export-movements-request.dto'
import { ExportMovements } from '../../usecases/export-movements'

export const useExportMovements = () => {
  const container = useContainer()

  return useMutation(
    async (input: ExportMovementsFilterDto) => {
      return new ExportMovements(container.get(HttpClient)).execute(input)
    },
    {
      retry: false,
      mutationKey: [QueryKey.exportMovements],
    },
  )
}
