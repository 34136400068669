import { useContainer } from '@hub-la/fe-container'
import { Analytics } from '@hub-la/fe-core-analytics'
import { Auth } from '@hub-la/fe-core-auth'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation } from '@tanstack/react-query'
import { QueryKey } from '../../domain/dtos/query-keys'
import { SignInByEmailInput } from '../../domain/dtos/sign-in-by-email-input'
import { AuthSignInByEmail } from '../../usecases/auth-sign-in-by-email'

export const useAuthSignInByEmail = (params: { onSuccess: () => void; onError: (error) => void } | undefined) => {
  const container = useContainer()

  return useMutation(
    [QueryKey.putUserLoginPreferences],
    (input: SignInByEmailInput) =>
      new AuthSignInByEmail(container.get(HttpClient), container.get(Auth), container.get(Analytics)).execute(input),
    {
      retry: false,
      onSuccess: () => {
        if (params?.onSuccess) {
          params.onSuccess()
        }
      },
      onError: (error: Error) => {
        if (params?.onError) {
          params.onError(error)
        }
      },
    },
  )
}
