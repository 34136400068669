import {
  AccountVerificationProvider,
  BRAZILIAN_BANKS,
  UpdatePayoutModal,
  withAccountVerification,
} from '@hub-la/fe-account-verification'
import { GetIdentityResponse, GetPayoutResponse, VerificationStatus } from '@hub-la/fe-get-user'
import { Button, Card, CardContent, CardDescription, CardHeader, CardTitle, Separator } from '@hub-la/shadcn'
import { Edit } from 'lucide-react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Document } from '../../../domain/vos/document'
import { PayoutMethodAlert } from './payout-method-alert'
import { StatusTag } from './status-tag'

type Props = {
  data?: GetIdentityResponse
  payout?: GetPayoutResponse
  onFinish: () => void
}

const ButtonWithAccountVerification = withAccountVerification(Button, {
  tooltip: 'Você precisa ativar sua conta para cadastrar dados bancários',
})

const InfoRow = ({ label, value }: { label?: string; value: string | null | undefined }) => (
  <div className="flex flex-col space-y-1 mb-4">
    {label && <p className="text-sm text-muted-foreground">{label}</p>}
    <p className="text-sm">{value || 'Não disponível'}</p>
  </div>
)

export const BankAccount: React.FC<Props> = ({ payout, data, onFinish }) => {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()
  const isPayoutRejected = payout?.verificationStatus === VerificationStatus.REJECTED

  return (
    <>
      <Card className="w-full mb-6">
        <CardHeader>
          <div className="flex items-center">
            <div>
              <CardTitle className="text-xl gap-2">{t('Dados bancários')}</CardTitle>
              <CardDescription>{t('Informações sobre sua conta bancária')}</CardDescription>
            </div>
            <div className="mr-auto ml-4 flex justify-between items-center">
              <ButtonWithAccountVerification
                variant={isPayoutRejected ? 'default' : 'outline'}
                size="sm"
                onClick={() => setOpen(true)}
              >
                <Edit className="mr-2 h-4 w-4" />
                Atualizar
              </ButtonWithAccountVerification>
            </div>
            <StatusTag status={payout?.verificationStatus} />
          </div>
        </CardHeader>
        <Separator />
        <CardContent>
          <div className="grid grid-cols-1 gap-4 pt-4">
            {isPayoutRejected && <PayoutMethodAlert type={payout.type} />}
            {!payout ? (
              <p>Nenhuma informação bancária cadastrada</p>
            ) : payout?.type === 'PIX' ? (
              <InfoRow
                label={
                  data?.identity?.document.type
                    ? t(`bankAccount.pixKey`, {
                        keyType: t(`bankAccount.documentTypeEnum.${data?.identity?.document.type}`),
                      })
                    : undefined
                }
                value={payout.pixKey ? Document.build(payout.pixKey).getValue() : undefined}
              />
            ) : (
              <>
                <InfoRow
                  label={t('bankAccount.bank')}
                  value={
                    payout?.bankNumber &&
                    payout.bankNumber +
                      ' - ' +
                      (BRAZILIAN_BANKS.find((it) => it.code === payout?.bankNumber)?.name ?? '')
                  }
                />
                <InfoRow
                  label={t('bankAccount.agency')}
                  value={
                    (payout?.agencyNumber ?? '') + (payout?.agencyCheckNumber ? '-' + payout?.agencyCheckNumber : '')
                  }
                />
                <InfoRow
                  label={t('bankAccount.account')}
                  value={payout?.accountNumber && payout.accountNumber + '-' + payout.accountCheckNumber}
                />
                <InfoRow
                  label={t('bankAccount.accountType')}
                  value={payout?.accountType ? t(`bankAccount.bankAccountTypeEnum.${payout.accountType}`) : undefined}
                />
              </>
            )}
          </div>
        </CardContent>
      </Card>
      {/* Update Payout Modal */}
      <AccountVerificationProvider>
        <UpdatePayoutModal
          open={open}
          onClose={() => setOpen(false)}
          onUpdate={() => {
            onFinish()
            setOpen(false)
          }}
        />
      </AccountVerificationProvider>
    </>
  )
}
