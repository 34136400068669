import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { UserProfile } from '../../domain/dtos/profile'
import { QueryKey } from '../../domain/enums/query-key'
import { SetProfile } from '../../usecases/set-profile'

export const useSetProfile = () => {
  const container = useContainer()
  const queryClient = useQueryClient()

  return useMutation(
    [QueryKey.setProfile],
    (input: UserProfile) => new SetProfile(container.get(HttpClient)).execute(input),
    {
      retry: false,
      onSuccess: () => queryClient.invalidateQueries(['user']),
    },
  )
}
