import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation } from '@tanstack/react-query'
import { QueryKey } from '../../domain/enums/query-key.enum'
import { PostCohort } from '../../usecases/post-cohort.usecase'
import { CohortSchemaValidationType } from '../validations/cohort-validation'

export const usePostCohort = ({ productId, onSuccess }: { productId: string; onSuccess?: () => void }) => {
  const container = useContainer()

  return useMutation(
    [QueryKey.postCohort],
    (input: CohortSchemaValidationType) =>
      new PostCohort(container.get(HttpClient)).execute({
        ...input,
        productId,
      }),
    {
      retry: false,
      onSuccess,
    },
  )
}
