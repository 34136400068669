import { useGetMovementsByInvoiceId } from '../../hooks/use-get-movements-by-invoice-id'
import { Movements } from '../movements/movements'

interface CreatorInvoiceModalProps {
  invoiceId: string | undefined
}

export const MovementsByInvoiceId = ({ invoiceId }: CreatorInvoiceModalProps): JSX.Element => {
  const { isLoading, data } = useGetMovementsByInvoiceId(invoiceId)
  return <Movements movements={data} isLoading={isLoading} />
}
