import { Card } from '@hub-la/shadcn'
import { Package } from 'lucide-react'

type EntitiesCardsProps = {
  products: {
    name: string
    picture?: string
    id: string
  }[]
}

export const EntitiesCards = ({ products }: EntitiesCardsProps) => {
  return (
    <div className="h-full w-full">
      <div className="flex flex-col w-full space-y-3">
        <h2 className="text-base font-bold">Produto(s)</h2>

        {products.map((product) => (
          <Card key={product.id} className="p-4">
            <div className="flex flex-row gap-4">
              {product.picture ? (
                <div className="w-12 h-12 rounded-md flex justify-center items-center overflow-hidden">
                  <img src={product.picture} alt="product" className="w-full h-full object-cover" />
                </div>
              ) : (
                <div className="w-12 h-12 rounded-md flex justify-center items-center overflow-hidden bg-primary">
                  <Package className="text-primary-foreground" />
                </div>
              )}

              <div className="flex flex-col space-y-1">
                <p className="text-base">{product.name}</p>
                <p className="text-sm text-muted-foreground">{product.id}</p>
              </div>
            </div>
          </Card>
        ))}
      </div>
    </div>
  )
}
