export enum QueryKey {
  postComment = 'postComment',
  putComment = 'putComment',
  deleteComment = 'deleteComment',
  putLikeComment = 'putLikeComment',
  getReplies = 'getCommnetReplies',
  getCommentReplies = 'getCommentReplies',
  getComments = 'getComments',
  unsubscribeNotification = 'unsubscribeNotification',
}
