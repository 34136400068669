import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import NumberFormat from 'react-number-format'
import { BRAZILIAN_BANKS } from '../../../../domain/constants/brazilian-banks'
import { BANK_ACCOUNT_TYPE, BankAccountType } from '../../../../domain/constants/bank-account-type'
import { useField } from '../../../hooks/use-field'
import { CreateRefundRequestValues } from './create-refund-request-modal'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Input,
  Label,
  FormMessage,
  Button,
  cn,
  CommandList,
  ScrollArea,
  SelectTrigger,
  SelectValue,
  SelectGroup,
  SelectLabel,
  SelectItem,
  SelectContent,
  Select,
} from "@hub-la/shadcn"
import { CheckIcon, ChevronsUpDown } from "lucide-react"

export interface BankAccount {
  bankNumber: string
  agencyNumber: string
  agencyCheckNumber?: string
  accountNumber: string
  accountCheckNumber?: string
  accountType: BankAccountType
}

export interface Pix {
  pixKey: string
}

export const BankAccountForm = () => {
  const formik = useFormikContext<CreateRefundRequestValues>()
  const values = formik.values.refundPayerData?.data as BankAccount | undefined
  const { t } = useTranslation()
  const { hasError, getErrorText } = useField()

  return (
    <div className="space-y-6 mt-3">
      <div className='flex flex-col'>
        <Label htmlFor="bankNumber">{t('userRefund.bankAccountData.bankAccount.bankNumber')}</Label>
        <Popover>
          <PopoverTrigger asChild>
            <Button
              variant="outline"
              role="combobox"
              className="w-full justify-between"
            >
              {values?.bankNumber
                ? BRAZILIAN_BANKS.find((bank) => bank.compe === values.bankNumber)?.name
                : t('userRefund.bankAccountData.bankAccount.bankNumber')}
              <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-full z-[90] p-0">
            <Command>
              <CommandInput placeholder={t('userRefund.bankAccountData.bankAccount.bankNumber')} />
              <CommandList>
                <ScrollArea className="h-[200px]">
                  <CommandEmpty>Nenhum banco encontrado</CommandEmpty>
                  <CommandGroup>
                    {BRAZILIAN_BANKS.map((bank) => (
                      <CommandItem
                        key={bank.compe}
                        value={bank.compe}
                        onSelect={(currentValue) => {
                          formik.setFieldValue('refundPayerData.data.bankNumber', currentValue)
                        }}
                      >
                        {bank.name}
                      </CommandItem>
                    ))}
                  </CommandGroup>
                </ScrollArea>
              </CommandList>
            </Command>
          </PopoverContent>
        </Popover>
        {hasError('refundPayerData.data.bankNumber') && (
          <p className='text-[0.8rem] font-medium text-destructive'>{getErrorText('refundPayerData.data.bankNumber')}</p>
        )}
      </div>
      <div>
        <Label htmlFor="refundPayerData.data.agencyNumber">{t('userRefund.bankAccountData.bankAccount.agencyNumber')}</Label>
        <NumberFormat
          id="refundPayerData.data.agencyNumber"
          customInput={Input}
          value={values?.agencyNumber}
          onValueChange={(values) => {
            formik.setFieldValue('refundPayerData.data.agencyNumber', values.value)
          }}
          onBlur={formik.handleBlur}
          allowNegative={false}
          className={hasError('refundPayerData.data.agencyNumber') ? 'border-red-500' : ''}
        />
        {hasError('refundPayerData.data.agencyNumber') && (
          <p className='text-[0.8rem] font-medium text-destructive'>{getErrorText('refundPayerData.data.agencyNumber')}</p>
        )}
      </div>
      <div>
        <Label htmlFor="refundPayerData.data.accountNumber">{t('userRefund.bankAccountData.bankAccount.accountNumber')}</Label>
        <NumberFormat
          id="refundPayerData.data.accountNumber"
          customInput={Input}
          value={values?.accountNumber}
          onValueChange={(values) => {
            formik.setFieldValue('refundPayerData.data.accountNumber', values.value)
          }}
          onBlur={formik.handleBlur}
          allowNegative={false}
          className={hasError('refundPayerData.data.accountNumber') ? 'border-red-500' : ''}
        />
        {hasError('refundPayerData.data.accountNumber') && (
          <p className='text-[0.8rem] font-medium text-destructive'>{getErrorText('refundPayerData.data.accountNumber')}</p>
        )}
      </div>
      <div>
        <Label htmlFor="accountType">{t('userRefund.bankAccountData.bankAccount.accountType')}</Label>
        <Select>
          <SelectTrigger>
            <SelectValue placeholder={t('userRefund.bankAccountData.bankAccount.accountType')} />
          </SelectTrigger>
          <SelectContent className="z-[90]">
            <SelectGroup>
              {BANK_ACCOUNT_TYPE.map((accountType) => (
                <SelectItem value={accountType.code.toString()}>{accountType.name}</SelectItem>
              ))}
            </SelectGroup>
          </SelectContent>
        </Select>
        {hasError('refundPayerData.data.accountType') && (
          <p className='text-[0.8rem] font-medium text-destructive'>{getErrorText('refundPayerData.data.accountType')}</p>
        )}
      </div>
    </div >
  )
}
