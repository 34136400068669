import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '../../domain/enums/query-key'
import { GetPendingInvoiceForSubscription } from '../../usecases/get-pending-invoice-for-subscription'

export const useGetPendingInvoiceForSubscription = (id?: string) => {
  const container = useContainer()

  return useQuery(
    [QueryKey.getPendingInvoiceForSubscription, id],
    () => new GetPendingInvoiceForSubscription(container.get(HttpClient)).execute(id!),
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!id,
    },
  )
}
