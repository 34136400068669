import { cn } from '@hub-la/shadcn'
import { LockIcon } from 'lucide-react'

type Props = {
  fullSize?: boolean
}

export const Lock = ({ fullSize = false }: Props) => {
  return (
    <div
      className={cn(
        'absolute',
        fullSize ? 'left-0 top-0' : 'left-8 top-8',
        'w-full h-full',
        fullSize ? 'max-h-full max-w-full' : 'max-h-36 max-w-40',
        'rounded p-1 flex justify-center items-center bg-background/50 backdrop-blur-sm',
      )}
    >
      <LockIcon className="text-foreground" />
    </div>
  )
}
