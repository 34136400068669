import { boolean, object, string } from 'yup'

export const eventLocationValidationSchema = () => {
  return object().shape({
    isLocationNotDefined: boolean(),
    city: string().when('isLocationNotDefined', {
      is: true,
      then: string().nullable(),
      otherwise: string().required('eventLocation.requiredField'),
    }),
    state: string().when('isLocationNotDefined', {
      is: true,
      then: string().nullable(),
      otherwise: string().required('eventLocation.requiredField'),
    }),
    country: string().when('isLocationNotDefined', {
      is: true,
      then: string().nullable(),
      otherwise: string().required('eventLocation.requiredField'),
    }),
    zipCode: string().when('isLocationNotDefined', {
      is: true,
      then: string().nullable(),
      otherwise: string()
        .test('len', 'eventLocation.invalidZipCode', (val) => val?.replace(/\D/g, '')?.length === 8)
        .required('eventLocation.requiredField'),
    }),
    street: string().when('isLocationNotDefined', {
      is: true,
      then: string().nullable(),
      otherwise: string().required('eventLocation.requiredField'),
    }),
    number: string().when('isLocationNotDefined', {
      is: true,
      then: string().nullable(),
      otherwise: string().required('eventLocation.requiredField'),
    }),
    complement: string(),
    neighborhood: string().when('isLocationNotDefined', {
      is: true,
      then: string().nullable(),
      otherwise: string().required('eventLocation.requiredField'),
    }),
  })
}
