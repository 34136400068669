import { useAuth } from '@hub-la/fe-auth'
import { CopyClipboard } from '@hub-la/fe-copy-clipboard'
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  useToast,
} from '@hub-la/shadcn'
import { UseMutateAsyncFunction } from '@tanstack/react-query'
import { Copy, Edit, MoreHorizontal, Trash2, UserMinus, UserPlus } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { GroupResource, ResourceType } from '../../../../../domain/dtos/groups'
import { UpdateResourceInput } from '../../../../../domain/dtos/member-area/update-resource-input'
import { Envs } from '../../../../../envs'
import EditGroupDrawer from './edit-group-drawer'
import { RemoveGroupCard } from './remove-group-card'
import { UnbindGroupCard } from './unbind-group-card'

type Props = {
  disabled?: boolean
  offerId?: string
  resource: GroupResource
  handleOnGroupRename: (
    id: string,
    value: string,
    productId: string,
    mutateAsyncFn: UseMutateAsyncFunction<GroupResource, Error, UpdateResourceInput, unknown>,
    callback?: () => void,
    cohortIds?: string[],
  ) => void
  isLoadingUpdateResource: boolean
}

export const GroupActions: React.FC<Props> = ({
  disabled,
  offerId,
  resource,
  handleOnGroupRename,
  isLoadingUpdateResource,
}) => {
  const { id: resourceId, link, resourceType } = resource
  const { productId } = useParams<{ productId: string }>()
  const { toast } = useToast()
  const history = useHistory()
  const { t } = useTranslation()
  const { currentUser } = useAuth()
  const isVerifiedTelegram =
    Boolean(currentUser?.ims?.telegram) ||
    (resourceType !== ResourceType.telegram && resourceType !== ResourceType.telegram_channel)

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)
  const [unbindModalOpen, setUnbindModalOpen] = useState<boolean>(false)
  const [editResourceModalOpen, setEditResourceModalOpen] = useState<boolean>(false)

  const verificationTelegramLink = `/verification-telegram/intro?groupId=${offerId}`

  const handleCopy = () => {
    if (!isVerifiedTelegram) {
      history.push(verificationTelegramLink)
      return
    }
    new CopyClipboard().execute(link).then(() => toast({ description: t('member-area.groups.linkCopied') }))
  }

  const handleAddFreeMembers = () => {
    window.open(`${Envs.APP_URL}/free_members/${resourceId}/${productId}/${offerId}`)
  }

  const handleOnRemoveGroupCardToggle = (state: boolean) => () => {
    setDeleteModalOpen(state)
  }

  const handleOnUnbindGroupCardToggle = (state: boolean) => () => {
    setUnbindModalOpen(state)
  }

  const handleOnEditModalToggle = (state: boolean) => () => {
    setEditResourceModalOpen(state)
  }

  return (
    <>
      <div className="flex flex-row gap-2">
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <a href={isVerifiedTelegram ? link : verificationTelegramLink} target="_blank" rel="noreferrer">
                <Button variant="outline" size="sm" disabled={disabled}>
                  {t('member-area.groups.enter')}
                </Button>
              </a>
            </TooltipTrigger>
            {disabled ? <TooltipContent>{t('member-area.groups.actionsDisclaimer')}</TooltipContent> : null}
          </Tooltip>
        </TooltipProvider>

        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" size="icon" disabled={disabled}>
              <MoreHorizontal className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>

          <DropdownMenuContent>
            <DropdownMenuItem onSelect={handleCopy} className="flex gap-2">
              <Copy className="h-4 w-4" />

              <span>{t('member-area.groups.copyLink')}</span>
            </DropdownMenuItem>

            <DropdownMenuItem onSelect={handleAddFreeMembers} className="flex gap-2">
              <UserPlus className="h-4 w-4" />

              <span>{t('member-area.groups.addFreeMembers')}</span>
            </DropdownMenuItem>

            <DropdownMenuSeparator />

            <DropdownMenuItem
              onSelect={() => setEditResourceModalOpen(true)}
              disabled={isLoadingUpdateResource}
              className="flex gap-2"
            >
              <Edit className="h-4 w-4" />

              <span>{t('member-area.groups.editResource')}</span>
            </DropdownMenuItem>

            <DropdownMenuItem onSelect={() => setDeleteModalOpen(true)} className="flex gap-2">
              <Trash2 className="h-4 w-4" />

              <span>{t('member-area.groups.deleteResource')}</span>
            </DropdownMenuItem>

            <DropdownMenuItem onSelect={() => setUnbindModalOpen(true)} className="flex gap-2">
              <UserMinus className="h-4 w-4" />

              <span>{t('member-area.groups.unbindResource')}</span>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>

      {editResourceModalOpen && (
        <EditGroupDrawer
          open={editResourceModalOpen}
          onClose={handleOnEditModalToggle(false)}
          resource={resource}
          handleOnGroupRename={handleOnGroupRename}
          mainOfferId={offerId}
        />
      )}

      {deleteModalOpen && (
        <RemoveGroupCard
          open={deleteModalOpen}
          onClose={handleOnRemoveGroupCardToggle(false)}
          resourceId={resourceId}
        />
      )}

      {unbindModalOpen && (
        <UnbindGroupCard
          offerId={offerId}
          open={unbindModalOpen}
          onClose={handleOnUnbindGroupCardToggle(false)}
          resourceId={resourceId}
        />
      )}
    </>
  )
}
