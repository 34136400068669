import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation } from '@tanstack/react-query'
import { DownloadSmartInstallmentsXlsxInput } from '../../domain/dtos/download-smart-installments-xlsx-input'
import { QueryKey } from '../../domain/enums/query-keys'
import { DownloadSmartInstallmentsXlsx } from '../../usecases/download-smart-installments-xlsx'

export const useDownloadSmartInstallmentsXlsx = (input: DownloadSmartInstallmentsXlsxInput) => {
  const container = useContainer()

  return useMutation(
    [QueryKey.downloadSmartInstallmentsXlsx],
    () => new DownloadSmartInstallmentsXlsx(container.get(HttpClient)).execute(input),
    {
      retry: false,
    },
  )
}
