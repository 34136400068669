/* eslint-disable @typescript-eslint/no-var-requires */
import { forwardRef } from 'react'
import { Envs } from '../../../envs'
import FakePlayer from './fake-player'

let PlayerComponent

if (Envs.NODE_ENV === 'test') {
  // console.log to add fake-player to build bundle
  console.log(FakePlayer)
  PlayerComponent = require('./fake-player').default
} else {
  PlayerComponent = require('@mux/mux-player-react').default
}

type MuxPlayerProps = {
  src: string
  playbackId: string | undefined
  onPlay: () => void
  onSeeked: () => void
  onEnded: () => void
  startTime: number | undefined
  className?: string
}

// Fake mux-player to not break jest of chatpay-firebase, because not find mux-player-react in dependencies tree
// TODO: when migrate chatpay-firebase to nx, remove this fake player and mock only jest
const MuxPlayer = forwardRef<any, MuxPlayerProps>(
  ({ src, playbackId, onPlay, onSeeked, onEnded, startTime, className, ...props }: MuxPlayerProps, ref) => {
    return (
      <PlayerComponent
        ref={ref}
        src={src}
        streamType="on-demand"
        playbackId={playbackId}
        onPlay={onPlay}
        onSeeked={onSeeked}
        onEnded={onEnded}
        preload="metadata"
        startTime={startTime}
        className={className}
        {...props}
      />
    )
  },
)

export default MuxPlayer
