import { AssetResponse, UploadStatus } from '@hub-la/fe-asset'
import { Post } from '@hub-la/fe-post'

export enum highlightTypes {
  RECOMMENDATION = 'recommendation',
  POST = 'post',
  VIDEO = 'video',
  IMAGE = 'image',
}

export type Values = {
  highlightType: highlightTypes
  post?: Post | null
  videoAsset?: AssetResponse | null
  desktopAsset?: AssetResponse | null
  mobileAsset?: AssetResponse | null
  logoAsset?: AssetResponse | null
  showThumbnailTitle?: boolean
  desktopAssetStatus?: UploadStatus | null
  mobileAssetStatus?: UploadStatus | null
  videoAssetStatus?: UploadStatus | null
  logoAssetStatus?: UploadStatus | null
  postComment?: boolean | null
}
