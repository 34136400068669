import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useQuery } from '@tanstack/react-query'
import { GetOffersInput } from '../../../domain/dtos/get-offers-input'
import { GetOffersOutput } from '../../../domain/dtos/get-offers-output'
import { QueryKey } from '../../../domain/enums/query-key'
import { GetOffers } from '../../../usecases/shortlinks/get-offers'

export const useGetOffers = (input: GetOffersInput) => {
  const container = useContainer()

  return useQuery<GetOffersOutput>(
    [QueryKey.getAllOffers, input.productId],
    ({ signal }) => new GetOffers(container.get(HttpClient)).execute(input.productId, signal),
    {
      retry: false,
      retryOnMount: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    },
  )
}
