/* tslint:disable */
/* eslint-disable */
/**
 * BFF Web Product
 * API reference used to call the backend from the web frontend
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { UtmDto } from './utm-dto';

/**
 * 
 * @export
 * @interface CreateShortLinkRequestDto
 */
export interface CreateShortLinkRequestDto {
    /**
     * 
     * @type {string}
     * @memberof CreateShortLinkRequestDto
     */
    'offerId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateShortLinkRequestDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateShortLinkRequestDto
     */
    'redirectUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateShortLinkRequestDto
     */
    'slug'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateShortLinkRequestDto
     */
    'type': CreateShortLinkRequestDtoTypeEnum;
    /**
     * 
     * @type {UtmDto}
     * @memberof CreateShortLinkRequestDto
     */
    'utm'?: UtmDto | null;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateShortLinkRequestDtoTypeEnum {
    CHECKOUT = 'checkout',
    CHECKOUT_ONE_CLICK = 'checkout_one_click',
    PRODUCT = 'product',
    PRODUCT_EXTERNAL = 'product_external',
    MEMBERS_AREA = 'members_area',
    MEMBERS_AREA_EXTERNAL = 'members_area_external',
    EXTERNAL = 'external'
}


