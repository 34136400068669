import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation } from '@tanstack/react-query'
import { MarkPostViewed } from '../../usecases/mark-post-viewed'

export const useMarkPostViewed = (type: 'content' | 'meeting' = 'content') => {
  const container = useContainer()

  const { isSuccess, isError, error, isLoading, data, mutateAsync } = useMutation([], (postId: string) =>
    new MarkPostViewed(container.get(HttpClient)).execute(postId, type),
  )

  return {
    isSuccess,
    isError,
    error,
    isLoading,
    data,
    mutateAsync,
  }
}
