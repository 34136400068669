import { Button } from '@hub-la/shadcn'
import { Plus, Trash2 } from 'lucide-react'
import React from 'react'
import { FieldPath, useFormContext } from 'react-hook-form'
import { OfferSchemaValidationType } from '../validations/offer-validation'
import { OrderBumpSchemaValidationType } from '../validations/order-bump-validation'
import { CurrencyField } from './currency-field'

interface PromotionFieldProps {
  name: FieldPath<OfferSchemaValidationType> | FieldPath<OrderBumpSchemaValidationType>
  label: string
  error?: boolean
  helperText?: string
  onChange: (value: number | undefined) => void
  value: number | null
}

export const PromotionField: React.FC<PromotionFieldProps> = ({ name, label, error, helperText, onChange, value }) => {
  const { setValue } = useFormContext()

  if (value === null) {
    return (
      <div className="flex pt-2 pb-4 space-x-3">
        <Button
          variant="outline"
          onClick={() => {
            setValue(name, 0, {
              shouldDirty: true,
            })
          }}
          type="button"
        >
          <Plus className="h-4 w-4" />
          Adicionar preço promocional
        </Button>
      </div>
    )
  }

  return (
    <div className="flex space-x-2 items-start">
      <CurrencyField
        label={label}
        error={error}
        helperText={helperText}
        onChange={onChange}
        value={value}
        name={name}
      />
      <Button
        variant="ghost"
        size="icon"
        onClick={() => {
          setValue(name, null, {
            shouldDirty: true,
          })
        }}
        type="button"
        className="mt-5"
      >
        <Trash2 className="h-4 w-4" />
      </Button>
    </div>
  )
}
