import {
  Badge,
  Button,
  cn,
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Separator,
} from '@hub-la/shadcn'
import { CheckIcon, ChevronRight, PlusCircle } from 'lucide-react'
import React, { useMemo, useState } from 'react'
import { Offer, ProductRef } from '../../domain/dtos/offer'

interface SelectOffersProps {
  onChange: (ids: string[]) => void
  value: string[]
  offers: Offer[]
}

export const SelectOffers: React.FC<SelectOffersProps> = ({ onChange, value, offers }) => {
  const [searchTerm, setSearchTerm] = useState('')

  const groupedOffers = useMemo(() => {
    return offers.reduce((acc, offer) => {
      const productId = offer.product?.id || 'unknown'
      if (!acc[productId]) {
        acc[productId] = {
          product: offer.product || { id: 'unknown', name: 'Unknown Product' },
          offers: [],
        }
      }
      acc[productId].offers.push(offer)
      return acc
    }, {} as Record<string, { product: ProductRef; offers: Offer[] }>)
  }, [offers])

  const filteredGroupedOffers = useMemo(() => {
    if (!searchTerm) return groupedOffers
    return Object.entries(groupedOffers).reduce((acc, [productId, { product, offers }]) => {
      const filteredOffers = offers.filter((offer) => offer.name.toLowerCase().includes(searchTerm.toLowerCase()))
      if (product.name.toLowerCase().includes(searchTerm.toLowerCase()) || filteredOffers.length > 0) {
        acc[productId] = { product, offers: filteredOffers }
      }
      return acc
    }, {} as typeof groupedOffers)
  }, [groupedOffers, searchTerm])

  const handleItemChange = (itemId: string, isSingleOffer = false) => {
    let newValue: string[]
    if (isSingleOffer) {
      newValue = value.includes(itemId) ? value.filter((id) => id !== itemId) : [...value, itemId]
    } else {
      const productOffers = groupedOffers[itemId].offers
      const allProductOfferIds = productOffers.map((o) => o.id)
      const allSelected = allProductOfferIds.every((id) => value.includes(id))

      if (allSelected) {
        newValue = value.filter((id) => !allProductOfferIds.includes(id))
      } else {
        newValue = [...new Set([...value, ...allProductOfferIds])]
      }
    }

    onChange(newValue)
  }

  const isItemSelected = (itemId: string, isSingleOffer = false) => {
    if (isSingleOffer) {
      return value.includes(itemId)
    }
    const productOffers = groupedOffers[itemId].offers
    return productOffers.every((offer) => value.includes(offer.id))
  }

  const getSelectedProductsInfo = () => {
    return Object.values(groupedOffers).reduce((acc, { product, offers }) => {
      const selectedOffers = offers.filter((offer) => value.includes(offer.id))
      if (selectedOffers.length > 0) {
        acc.push({
          productName: product.name,
          selectedOffersCount: selectedOffers.length,
          totalOffersCount: offers.length,
        })
      }
      return acc
    }, [] as Array<{ productName: string; selectedOffersCount: number; totalOffersCount: number }>)
  }

  const renderFilterBadges = () => {
    const selectedProductsInfo = getSelectedProductsInfo()
    if (selectedProductsInfo.length === 0) return null

    if (selectedProductsInfo.length === 1) {
      const { productName, selectedOffersCount, totalOffersCount } = selectedProductsInfo[0]
      return (
        <Badge variant="secondary" className="rounded-sm px-1 font-normal">
          {productName}
          {totalOffersCount > 1 && ` (${selectedOffersCount}/${totalOffersCount})`}
        </Badge>
      )
    }

    return (
      <Badge variant="secondary" className="rounded-sm px-1 font-normal">
        {selectedProductsInfo.length} selecionados
      </Badge>
    )
  }

  function trimWithEllipsis(input: string, maxLength: number): string {
    if (input.length <= maxLength) return input
    return input.slice(0, maxLength - 3) + '...'
  }

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="outline">
          <PlusCircle className="h-4 w-4 mr-1" />
          <span className="text-sm">Produto</span>
          {value.length > 0 && (
            <>
              <Separator orientation="vertical" className="mx-2 h-4" />
              <Badge variant="secondary" className="rounded-sm px-1 font-normal lg:hidden">
                {value.length}
              </Badge>
              <div className="hidden space-x-1 lg:flex">{renderFilterBadges()}</div>
            </>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-80 p-0" align="start">
        <Command>
          <CommandInput placeholder="Produto(s)" value={searchTerm} onValueChange={setSearchTerm} />
          <CommandList>
            <CommandEmpty>Nenhum resultado encontrado.</CommandEmpty>
            {Object.entries(filteredGroupedOffers).map(([productId, { product, offers }]) => (
              <CommandGroup key={productId}>
                {offers.length === 1 ? (
                  <CommandItem onSelect={() => handleItemChange(offers[0].id, true)} className="px-2 py-1.5">
                    <div className="flex items-center w-full">
                      <div
                        className={cn(
                          'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                          isItemSelected(offers[0].id, true)
                            ? 'bg-hubla-primary text-primary-foreground border-hubla-primary'
                            : 'opacity-50 [&_svg]:invisible',
                        )}
                      >
                        <CheckIcon className={cn('h-4 w-4')} />
                      </div>
                      <span className="inline-flex truncate font-medium">{product.name}</span>
                    </div>
                  </CommandItem>
                ) : (
                  <>
                    <CommandItem onSelect={() => handleItemChange(productId)} className="px-2 py-1.5">
                      <div className="flex items-center w-full">
                        <div
                          className={cn(
                            'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                            isItemSelected(productId)
                              ? 'bg-hubla-primary text-primary-foreground border-hubla-primary'
                              : 'opacity-50 [&_svg]:invisible',
                          )}
                        >
                          <CheckIcon className={cn('h-4 w-4')} />
                        </div>
                        <span className="inline-flex truncate font-medium">{product.name}</span>
                        <span className="ml-2 text-sm">{offers.length} ofertas</span>
                      </div>
                    </CommandItem>
                    {offers.map((offer) => (
                      <CommandItem
                        key={offer.id}
                        onSelect={() => handleItemChange(offer.id, true)}
                        className="px-2 py-1.5 pl-8"
                      >
                        <div className="flex items-center w-full">
                          <div
                            className={cn(
                              'mr-2 inline-flex h-4 items-center justify-center rounded-sm border border-primary',
                              value.includes(offer.id)
                                ? 'bg-hubla-primary text-primary-foreground border-hubla-primary'
                                : 'opacity-50 [&_svg]:invisible',
                            )}
                          >
                            <CheckIcon className={cn('h-4 w-4')} />
                          </div>
                          <span className="truncate font-medium text-muted-foreground">
                            {trimWithEllipsis(product.name, 12)}
                          </span>
                          <ChevronRight className="mx-1 h-4 w-4" />
                          <span className="flex-grow truncate">{offer.name}</span>
                        </div>
                      </CommandItem>
                    ))}
                  </>
                )}
              </CommandGroup>
            ))}
          </CommandList>
        </Command>
        <Separator />
        {value.length > 0 && (
          <Button
            variant="ghost"
            className="w-full justify-center text-center rounded-none"
            onClick={() => onChange([])}
          >
            Limpar filtros
          </Button>
        )}
      </PopoverContent>
    </Popover>
  )
}
