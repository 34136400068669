import {
  Badge,
  Button,
  Calendar,
  Label,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Separator,
} from '@hub-la/shadcn'
import { CalendarIcon } from 'lucide-react'
import moment from 'moment'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

interface DateRange {
  startDate?: string
  endDate?: string
}

interface SelectDateRangeCalendar {
  onChange: (value?: DateRange) => void
  onSelectChange: (value?: string) => void
  onClearDate: () => void
  selectValue: string
  value: DateRange
  placeholder?: string
}

export const SelectDateRangeCalendar = (props: SelectDateRangeCalendar) => {
  const { t } = useTranslation()
  const [calendarOpen, setCalendarOpen] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)

  const onFilterChange = (newDate?: { to?: Date; from?: Date }) => {
    const startDate = newDate?.from ? moment(newDate?.from).startOf('day').format('YYYY-MM-DDTHH:mm:ssZ') : undefined
    const endDate = newDate?.to ? moment(newDate?.to).endOf('day').format('YYYY-MM-DDTHH:mm:ssZ') : undefined
    props.onChange({ startDate, endDate })
  }

  const getPlaceholder = () => {
    const { startDate, endDate } = props.value

    if (!startDate || !endDate) return

    const formattedStartDate = moment(startDate).format('D MMM. YYYY')
    const formattedEndDate = moment(endDate).format('D MMM. YYYY')
    return `${formattedStartDate} - ${formattedEndDate}`
  }

  return (
    <Popover open={calendarOpen} onOpenChange={setCalendarOpen}>
      <PopoverTrigger asChild>
        <Button variant="outline">
          <CalendarIcon className="mr-2 h-4 w-4" />
          <span>Período</span>
          {props.value.startDate && props.value.endDate && (
            <>
              <Separator orientation="vertical" className="mx-2 h-4" />
              <Badge variant="secondary" className="rounded-sm px-1 font-normal">
                {getPlaceholder()}
              </Badge>
            </>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0" align="start">
        <div className="p-3 flex">
          <div className="w-full">
            <Label className="mb-2 block">Filtrar por data</Label>
            <Select
              open={open}
              onOpenChange={() => setOpen((prevState) => !prevState)}
              onValueChange={(value) => props.onSelectChange(value)}
              value={props.selectValue}
            >
              <SelectTrigger className="w-full">
                <SelectValue placeholder="Filtrar por data" />
              </SelectTrigger>
              <SelectContent className="w-full z-10002">
                <SelectItem value="nextDueAt">{t('filters.dateRangeBy.nextDueAt')}</SelectItem>
                <SelectItem value="createdAt">{t('filters.dateRangeBy.createdAt')}</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>
        <Separator />
        <Calendar
          mode="range"
          selected={{
            from: props.value.startDate ? moment(props.value.startDate).toDate() : undefined,
            to: props.value.endDate ? moment(props.value.endDate).toDate() : undefined,
          }}
          defaultMonth={props.value.startDate ? moment(props.value.startDate).toDate() : undefined}
          numberOfMonths={2}
          onSelect={(selected) => onFilterChange(selected)}
        />
        <Separator />
        <Button className="w-full" variant="ghost" onClick={props.onClearDate}>
          Limpar filtro
        </Button>
      </PopoverContent>
    </Popover>
  )
}
