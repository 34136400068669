import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '../../domain/enums/query-key'
import { GetCoupon } from '../../usecases/get-coupon'

export const useGetCoupon = (id: string) => {
  const container = useContainer()

  return useQuery([QueryKey.getCoupon, id], () => new GetCoupon(container.get(HttpClient)).execute(id), {
    retry: 3,
    enabled: !!id,
    refetchOnWindowFocus: false,
  })
}
