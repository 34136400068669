export class Type {
  public id: string
  public name: string
  public regex: RegExp

  constructor(dict) {
    this.id = (dict.id as string) ?? ''
    this.name = (dict.name as string) ?? ''
    this.regex = dict.regex as RegExp
  }
}

export class CreditCardType {
  private static typesData = [
    {
      id: 'amex',
      name: 'American Express',
      regex: /^3[47][0-9]{13}$/,
    },
    {
      id: 'visa',
      name: 'Visa',
      regex: /^4[0-9]{12}(?:[0-9]{3})?$/,
    },
    {
      id: 'mastercard',
      name: 'MasterCard',
      regex: /^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$/,
    },
    {
      id: 'diners',
      name: 'Diners Club',
      regex: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
    },
    {
      id: 'elo',
      name: 'ELO',
      regex:
        /^(40117[8-9]|431274|438935|451416|457393|45763[1-2]|506(699|7[0-6][0-9]|77[0-8])|509\d{3}|504175|627780|636297|636368|65003[1-3]|6500(3[5-9]|4[0-9]|5[0-1])|6504(0[5-9]|[1-3][0-9])|650(4[8-9][0-9]|5[0-2][0-9]|53[0-8])|6505(4[1-9]|[5-8][0-9]|9[0-8])|6507(0[0-9]|1[0-8])|65072[0-7]|6509(0[1-9]|1[0-9]|20)|6516(5[2-9]|[6-7][0-9])|6550([0-1][0-9]|2[1-9]|[3-4][0-9]|5[0-8]))/,
    },
  ]

  public constructor(private readonly value: string | undefined) {}

  public static get types(): Type[] {
    return this.typesData.map((d) => new Type(d))
  }

  public static build(value: string): CreditCardType {
    const get = () => {
      for (const i in this.types) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.types.hasOwnProperty(i)) {
          const type = this.types[i]

          if (type.regex.test(value.replace(/ /g, ''))) {
            return type.id
          }
        }
      }

      return undefined
    }

    return new CreditCardType(get())
  }

  public isValid(): boolean {
    return !!this.value
  }

  public getValue() {
    return this.value
  }
}
