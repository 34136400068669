import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { toast } from '@hub-la/shadcn'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { QueryKey } from '../../domain/enums/query-key'
import { CreateRefundRequest } from '../../usecases/create-refund-request'
import { CreateRefundRequestValues } from '../pages/user-refunds/components/create-refund-request-modal'

export const useCreateRefundRequest = () => {
  const container = useContainer()
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  return useMutation(
    [QueryKey.postCreateRefundRequest],
    (input: CreateRefundRequestValues) => new CreateRefundRequest(container.get(HttpClient)).execute(input),
    {
      retry: false,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [QueryKey.getRefunds] })
      },
      onError: (error: Error) => {
        if (error.message === 'errors.alreadyExists') {
          toast({ description: t('errors.alreadyExists'), variant: "destructive" })
          return
        }
        toast({ description: t('errors.general'), variant: "destructive" })
      }
    },
  )
}
