import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '../../domain/enums/query-key'
import { GetInvoice } from '../../usecases/get-invoice'

export const useGetInvoice = (id: string) => {
  const container = useContainer()

  return useQuery([QueryKey.getInvoice, id], () => new GetInvoice(container.get(HttpClient)).execute(id), {
    refetchOnWindowFocus: false,
    retry: false,
    enabled: !!id,
  })
}
