import { Button, useIsMobile } from '@hub-la/shadcn'
import { AlertCircle } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { BackButton } from './back-button'
import { Overlay } from './overlay'
import { UserNavigation } from './user-navigation'

interface ErrorProps {
  errorKey?: string
}

export const Error = ({ errorKey }: ErrorProps) => {
  const { t } = useTranslation()
  const isMobile = useIsMobile()

  return (
    <div className="relative min-h-screen flex flex-col">
      {!isMobile && <Overlay />}

      <div className="px-4 sm:px-10 h-28 flex items-center">
        <div className="flex flex-1 gap-1 sm:gap-4 items-center">
          <BackButton />

          <h4 className="truncate max-w-[55vw] text-foreground text-2xl font-bold">
            {t('postDetail.errorComponent.back')}
          </h4>
        </div>

        <UserNavigation />
      </div>

      <div id="post-detail" className="px-4 sm:px-10 pb-30 flex justify-center bg-black/5">
        <div className="flex flex-col items-center justify-center w-full max-w-screen-xl aspect-video rounded-lg border border-foreground/20 bg-background overflow-hidden">
          <div className="flex justify-center items-center w-22 h-22 rounded-full bg-muted mb-4">
            <AlertCircle size={42} />
          </div>

          <p className="text-muted-foreground text-center">
            {errorKey ? t(`postDetail.${errorKey}`) : t('postDetail.errors.general')}
          </p>

          <Link to="/user_groups" className="mt-2">
            <Button variant="outline">{t('postDetail.errorComponent.myPurchases')}</Button>
          </Link>
        </div>
      </div>
    </div>
  )
}
