import { yupResolver } from '@hookform/resolvers/yup'
import { useGetUser } from '@hub-la/fe-get-user'
import { Button, Card, CardContent, CardFooter, CardHeader, CardTitle, Input, Label } from '@hub-la/shadcn'
import React, { useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { UserCredentials } from '../../../domain/dtos/credentials'
import { Email } from '../../../domain/vos/email'
import { useSetCredentials } from '../../hooks/use-set-credentials'

const validationSchema = Yup.object({
  id: Yup.string().required('errors.required'),
  email: Yup.string()
    .email('errors.invalidEmail')
    .required('errors.required')
    .test({
      message: 'errors.invalidEmail',
      test: function (value) {
        return Email.build(String(value)).isValid()
      },
    }),
  country: Yup.string().required('errors.required'),
})

export const Credentials: React.FC = () => {
  const { t } = useTranslation()
  const { data: currentUser, isLoading: isFetching } = useGetUser()

  const defaultValues = useMemo(() => {
    const values: UserCredentials = {
      id: currentUser?.id ?? '',
      email: currentUser?.email ?? '',
      country: 'BR',
    }

    return values
  }, [currentUser])

  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid, errors, touchedFields },
    setValue,
    reset,
  } = useForm<UserCredentials>({ defaultValues, resolver: yupResolver(validationSchema) })

  useEffect(() => {
    reset(defaultValues)
  }, [currentUser, defaultValues, reset])

  const { mutate: setCredentials, isLoading } = useSetCredentials()

  const onSubmit = (formData: UserCredentials) => {
    setCredentials(formData)
  }

  const errorMessage = errors.email && touchedFields.email ? errors.email.message : null

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4 my-4">
      <Card className="w-full max-w-2xl">
        <CardHeader>
          <CardTitle>{t('credentials.title')}</CardTitle>
        </CardHeader>

        <CardContent className="flex flex-col gap-4">
          <div className="space-y-1.5">
            <Label htmlFor="email">{t('credentials.email')}</Label>

            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <Input
                  id="email"
                  {...field}
                  onChange={(e) =>
                    setValue('email', e.target.value, { shouldValidate: true, shouldTouch: true, shouldDirty: true })
                  }
                  disabled={isFetching}
                />
              )}
            />
          </div>
          {errorMessage && <p className="text-sm text-destructive">{t(`${errorMessage}`)}</p>}
        </CardContent>
        <CardFooter>
          {isDirty && isValid && (
            <Button type="submit" loading={isLoading} className="sm:ml-auto w-full sm:w-auto">
              {t('save')}
            </Button>
          )}
        </CardFooter>
      </Card>
    </form>
  )
}
