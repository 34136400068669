import { Button } from '@hub-la/shadcn'
import { ArrowLeft } from 'lucide-react'
import { useHistory } from 'react-router-dom'
import { getGroupUrl } from '../utils/routes'

type BackButtonProps = {
  groupId?: string
}

export const BackButton = ({ groupId }: BackButtonProps) => {
  const history = useHistory()
  const backLink = getGroupUrl(groupId)

  const handleBack = () => history.goBack()

  if (backLink) {
    return (
      <a href={backLink} className="flex">
        <Button variant="ghost" className="gap-2" data-testid="back-button">
          <ArrowLeft className="h-4 w-4" />

          <span className="text-sm">Voltar</span>
        </Button>
      </a>
    )
  }

  return (
    <Button variant="ghost" className="gap-2" onClick={handleBack} data-testid="back-button">
      <ArrowLeft className="h-4 w-4" />

      <span className="text-sm">Voltar</span>
    </Button>
  )
}
