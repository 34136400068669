import { FileText, Image, Play, Text } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { Post } from '../../../domain/dtos'
import { VideoDuration } from '../../../domain/vos'
import { GetPostType } from '../../../usecases/get-post-type'

type DurationProps = {
  post: Post
}

const getIcon = (postType: 'video' | 'image' | 'pdf' | 'article') => {
  switch (postType) {
    case 'image':
      return <Image className="w-4 h-4 mr-0.5 text-muted-foreground" />
    case 'video':
      return <Play className="w-4 h-4 mr-0.5 text-muted-foreground fill-zinc-500" />
    case 'pdf':
      return <FileText className="w-4 h-4 mr-0.5 text-muted-foreground" />
    default:
      return <Text className="w-4 h-4 mr-0.5 text-muted-foreground" />
  }
}

export const Duration = ({ post }: DurationProps) => {
  const { t } = useTranslation()

  const postType = new GetPostType().execute(post?.cover?.type)

  const duration =
    post?.cover?.metadata?.pages ??
    (post.cover?.metadata?.duration && new VideoDuration(post.cover?.metadata?.duration ?? 0).format())

  return (
    <div className="flex gap-1 items-center">
      {getIcon(postType)}

      <span data-testid="video-duration" className="text-muted-foreground text-sm">
        {duration} {t(`postDetail.sidebar.durationSubtitle.${postType}`)}
      </span>
    </div>
  )
}
