import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '../../domain/enums/query-key'
import { InitUpgradePlan } from '../../usecases/init-upgrade-plan'

export const useInitUpgradePlan = (subscriptionId: string, enabled: boolean) => {
  const container = useContainer()

  return useQuery(
    [QueryKey.initUpgradePlan, subscriptionId],
    () => new InitUpgradePlan(container.get(HttpClient)).execute(subscriptionId),
    {
      refetchOnWindowFocus: false,
      enabled: !!subscriptionId && enabled,
      retry: false,
    },
  )
}
