import moment from 'moment'
import { Period } from './period'

export const PeriodToMoment: Record<Period, moment.unitOfTime.DurationConstructor> = {
  [Period.HOURLY]: 'hour',
  [Period.DAILY]: 'day',
  [Period.WEEKLY]: 'week',
  [Period.MONTHLY]: 'month',
  [Period.QUARTERLY]: 'quarter',
  [Period.YEARLY]: 'year',
}

export const FormatPeriodToMoment: Record<Period, string> = {
  [Period.HOURLY]: 'YYYY-MM-DDTHH:mm:ssZ',
  [Period.DAILY]: 'YYYY-MM-DD',
  [Period.WEEKLY]: 'YYYY-MM-DD',
  [Period.MONTHLY]: 'YYYY-MM',
  [Period.QUARTERLY]: 'YYYY-MM',
  [Period.YEARLY]: 'YYYY',
}

export const OutputFormatPeriodToMoment: Record<Period, string> = {
  [Period.HOURLY]: 'HH:mm',
  [Period.DAILY]: 'D MMM.',
  [Period.WEEKLY]: 'D MMM.',
  [Period.MONTHLY]: 'MMM, YYYY',
  [Period.QUARTERLY]: 'MMM, YYYY',
  [Period.YEARLY]: 'MMM, YYYY',
}
