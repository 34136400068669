import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useInfiniteQuery } from '@tanstack/react-query'
import { GetCommentsInput } from '../../domain/dtos/get-comments-input'
import { GetCommentsResponse } from '../../domain/dtos/get-comments-response'
import { QueryKey } from '../../domain/enums/query-keys'
import { GetComments } from '../../usecases/get-comments'

export const useGetComments = ({ postId, pageSize, orderDir }: GetCommentsInput) => {
  const container = useContainer()

  return useInfiniteQuery<GetCommentsResponse | undefined>(
    [QueryKey.getComments, postId, orderDir],
    ({ pageParam = 1 }) =>
      new GetComments(container.get(HttpClient)).execute({ postId, page: pageParam, pageSize, orderDir }),
    {
      refetchOnWindowFocus: false,
      getNextPageParam: (oldData) => {
        const nextPage = (oldData?.page ?? 0) + 1
        const lastPage = oldData?.lastPage ?? 0

        if (nextPage > lastPage) {
          return undefined
        }

        return nextPage
      },
    },
  )
}
