import { CountryCode } from './country-code'

type Country = {
  id: CountryCode
  name: string
}

export const Countries: { [key: string]: Country } = {
  // A
  [CountryCode.AF]: { id: CountryCode.AF, name: 'Afghanistan' },
  [CountryCode.AL]: { id: CountryCode.AL, name: 'Albania' },
  [CountryCode.DZ]: { id: CountryCode.DZ, name: 'Algeria' },
  [CountryCode.AD]: { id: CountryCode.AD, name: 'Andorra' },
  [CountryCode.AO]: { id: CountryCode.AO, name: 'Angola' },
  [CountryCode.AI]: { id: CountryCode.AI, name: 'Anguilla' },
  // [CountryCode.AQ]: { id: CountryCode.AQ, name: 'Antarctica' },
  [CountryCode.AG]: { id: CountryCode.AG, name: 'Antigua and Barbuda' },
  [CountryCode.AR]: { id: CountryCode.AR, name: 'Argentina' },
  [CountryCode.AM]: { id: CountryCode.AM, name: 'Armenia' },
  [CountryCode.AW]: { id: CountryCode.AW, name: 'Aruba' },
  [CountryCode.AU]: { id: CountryCode.AU, name: 'Australia' },
  [CountryCode.AT]: { id: CountryCode.AT, name: 'Austria' },
  [CountryCode.AZ]: { id: CountryCode.AZ, name: 'Azerbaijan' },

  // B
  [CountryCode.BS]: { id: CountryCode.BS, name: 'Bahamas' },
  [CountryCode.BH]: { id: CountryCode.BH, name: 'Bahrain' },
  [CountryCode.BD]: { id: CountryCode.BD, name: 'Bangladesh' },
  [CountryCode.BB]: { id: CountryCode.BB, name: 'Barbados' },
  [CountryCode.BY]: { id: CountryCode.BY, name: 'Belarus' },
  [CountryCode.BE]: { id: CountryCode.BE, name: 'Belgium' },
  [CountryCode.BZ]: { id: CountryCode.BZ, name: 'Belize' },
  [CountryCode.BJ]: { id: CountryCode.BJ, name: 'Benin' },
  [CountryCode.BM]: { id: CountryCode.BM, name: 'Bermuda' },
  [CountryCode.BT]: { id: CountryCode.BT, name: 'Bhutan' },
  [CountryCode.BO]: { id: CountryCode.BO, name: 'Bolivia' },
  [CountryCode.BA]: { id: CountryCode.BA, name: 'Bosnia and Herzegovina' },
  [CountryCode.BW]: { id: CountryCode.BW, name: 'Botswana' },
  [CountryCode.BR]: { id: CountryCode.BR, name: 'Brasil' },
  [CountryCode.BN]: { id: CountryCode.BN, name: 'Brunei' },
  [CountryCode.BG]: { id: CountryCode.BG, name: 'Bulgaria' },
  [CountryCode.BF]: { id: CountryCode.BF, name: 'Burkina Faso' },
  [CountryCode.BI]: { id: CountryCode.BI, name: 'Burundi' },

  // C
  [CountryCode.CV]: { id: CountryCode.CV, name: 'Cabo Verde' },
  [CountryCode.KH]: { id: CountryCode.KH, name: 'Cambodia' },
  [CountryCode.CM]: { id: CountryCode.CM, name: 'Cameroon' },
  [CountryCode.CA]: { id: CountryCode.CA, name: 'Canada' },
  [CountryCode.CF]: { id: CountryCode.CF, name: 'Central African Republic' },
  [CountryCode.TD]: { id: CountryCode.TD, name: 'Chad' },
  [CountryCode.CL]: { id: CountryCode.CL, name: 'Chile' },
  [CountryCode.CN]: { id: CountryCode.CN, name: 'China' },
  [CountryCode.CO]: { id: CountryCode.CO, name: 'Colombia' },
  [CountryCode.KM]: { id: CountryCode.KM, name: 'Comoros' },
  [CountryCode.CG]: { id: CountryCode.CG, name: 'Congo' },
  [CountryCode.CD]: { id: CountryCode.CD, name: 'Congo, Democratic Republic of the' },
  [CountryCode.CR]: { id: CountryCode.CR, name: 'Costa Rica' },
  [CountryCode.CI]: { id: CountryCode.CI, name: "Côte d'Ivoire" },
  [CountryCode.HR]: { id: CountryCode.HR, name: 'Croatia' },
  [CountryCode.CY]: { id: CountryCode.CY, name: 'Cyprus' },
  [CountryCode.CZ]: { id: CountryCode.CZ, name: 'Czech Republic' },

  // D
  [CountryCode.DK]: { id: CountryCode.DK, name: 'Denmark' },
  [CountryCode.DJ]: { id: CountryCode.DJ, name: 'Djibouti' },
  [CountryCode.DM]: { id: CountryCode.DM, name: 'Dominica' },
  [CountryCode.DO]: { id: CountryCode.DO, name: 'Dominican Republic' },

  // E
  [CountryCode.EC]: { id: CountryCode.EC, name: 'Ecuador' },
  [CountryCode.EG]: { id: CountryCode.EG, name: 'Egypt' },
  [CountryCode.SV]: { id: CountryCode.SV, name: 'El Salvador' },
  [CountryCode.GQ]: { id: CountryCode.GQ, name: 'Equatorial Guinea' },
  [CountryCode.ER]: { id: CountryCode.ER, name: 'Eritrea' },
  [CountryCode.EE]: { id: CountryCode.EE, name: 'Estonia' },
  [CountryCode.ET]: { id: CountryCode.ET, name: 'Ethiopia' },

  // F
  [CountryCode.FJ]: { id: CountryCode.FJ, name: 'Fiji' },
  [CountryCode.FI]: { id: CountryCode.FI, name: 'Finland' },
  [CountryCode.FR]: { id: CountryCode.FR, name: 'France' },

  // G
  [CountryCode.GA]: { id: CountryCode.GA, name: 'Gabon' },
  [CountryCode.GM]: { id: CountryCode.GM, name: 'Gambia' },
  [CountryCode.GE]: { id: CountryCode.GE, name: 'Georgia' },
  [CountryCode.DE]: { id: CountryCode.DE, name: 'Germany' },
  [CountryCode.GH]: { id: CountryCode.GH, name: 'Ghana' },
  [CountryCode.GR]: { id: CountryCode.GR, name: 'Greece' },
  [CountryCode.GD]: { id: CountryCode.GD, name: 'Grenada' },
  [CountryCode.GT]: { id: CountryCode.GT, name: 'Guatemala' },
  [CountryCode.GN]: { id: CountryCode.GN, name: 'Guinea' },
  [CountryCode.GW]: { id: CountryCode.GW, name: 'Guinea-Bissau' },
  [CountryCode.GY]: { id: CountryCode.GY, name: 'Guyana' },

  // H
  [CountryCode.HT]: { id: CountryCode.HT, name: 'Haiti' },
  [CountryCode.HN]: { id: CountryCode.HN, name: 'Honduras' },
  [CountryCode.HK]: { id: CountryCode.HK, name: 'Hong Kong' },
  [CountryCode.HU]: { id: CountryCode.HU, name: 'Hungary' },

  // I
  [CountryCode.IS]: { id: CountryCode.IS, name: 'Iceland' },
  [CountryCode.IN]: { id: CountryCode.IN, name: 'India' },
  [CountryCode.ID]: { id: CountryCode.ID, name: 'Indonesia' },
  [CountryCode.IQ]: { id: CountryCode.IQ, name: 'Iraq' },
  [CountryCode.IE]: { id: CountryCode.IE, name: 'Ireland' },
  [CountryCode.IL]: { id: CountryCode.IL, name: 'Israel' },
  [CountryCode.IT]: { id: CountryCode.IT, name: 'Italy' },

  // J
  [CountryCode.JM]: { id: CountryCode.JM, name: 'Jamaica' },
  [CountryCode.JP]: { id: CountryCode.JP, name: 'Japan' },
  [CountryCode.JO]: { id: CountryCode.JO, name: 'Jordan' },

  //K
  [CountryCode.KZ]: { id: CountryCode.KZ, name: 'Kazakhstan' },
  [CountryCode.KE]: { id: CountryCode.KE, name: 'Kenya' },
  [CountryCode.KI]: { id: CountryCode.KI, name: 'Kiribati' },
  [CountryCode.KW]: { id: CountryCode.KW, name: 'Kuwait' },
  [CountryCode.KG]: { id: CountryCode.KG, name: 'Kyrgyzstan' },

  // L
  [CountryCode.LA]: { id: CountryCode.LA, name: 'Laos' },
  [CountryCode.LV]: { id: CountryCode.LV, name: 'Latvia' },
  [CountryCode.LB]: { id: CountryCode.LB, name: 'Lebanon' },
  [CountryCode.LS]: { id: CountryCode.LS, name: 'Lesotho' },
  [CountryCode.LR]: { id: CountryCode.LR, name: 'Liberia' },
  [CountryCode.LY]: { id: CountryCode.LY, name: 'Libya' },
  [CountryCode.LI]: { id: CountryCode.LI, name: 'Liechtenstein' },
  [CountryCode.LT]: { id: CountryCode.LT, name: 'Lithuania' },
  [CountryCode.LU]: { id: CountryCode.LU, name: 'Luxembourg' },

  // M
  [CountryCode.MO]: { id: CountryCode.MO, name: 'Macau' },
  [CountryCode.MK]: { id: CountryCode.MK, name: 'North Macedonia' },
  [CountryCode.MG]: { id: CountryCode.MG, name: 'Madagascar' },
  [CountryCode.MW]: { id: CountryCode.MW, name: 'Malawi' },
  [CountryCode.MY]: { id: CountryCode.MY, name: 'Malaysia' },
  [CountryCode.MV]: { id: CountryCode.MV, name: 'Maldives' },
  [CountryCode.ML]: { id: CountryCode.ML, name: 'Mali' },
  [CountryCode.MT]: { id: CountryCode.MT, name: 'Malta' },
  [CountryCode.MH]: { id: CountryCode.MH, name: 'Marshall Islands' },
  [CountryCode.MQ]: { id: CountryCode.MQ, name: 'Martinique' },
  [CountryCode.MR]: { id: CountryCode.MR, name: 'Mauritania' },
  [CountryCode.MU]: { id: CountryCode.MU, name: 'Mauritius' },
  [CountryCode.MX]: { id: CountryCode.MX, name: 'Mexico' },
  [CountryCode.FM]: { id: CountryCode.FM, name: 'Micronesia' },
  [CountryCode.MD]: { id: CountryCode.MD, name: 'Moldova' },
  [CountryCode.MC]: { id: CountryCode.MC, name: 'Monaco' },
  [CountryCode.MN]: { id: CountryCode.MN, name: 'Mongolia' },
  [CountryCode.ME]: { id: CountryCode.ME, name: 'Montenegro' },
  [CountryCode.MA]: { id: CountryCode.MA, name: 'Morocco' },
  [CountryCode.MZ]: { id: CountryCode.MZ, name: 'Mozambique' },
  [CountryCode.MM]: { id: CountryCode.MM, name: 'Myanmar' },

  // N
  [CountryCode.NA]: { id: CountryCode.NA, name: 'Namibia' },
  [CountryCode.NR]: { id: CountryCode.NR, name: 'Nauru' },
  [CountryCode.NP]: { id: CountryCode.NP, name: 'Nepal' },
  [CountryCode.NL]: { id: CountryCode.NL, name: 'Netherlands' },
  [CountryCode.NZ]: { id: CountryCode.NZ, name: 'New Zealand' },
  [CountryCode.NI]: { id: CountryCode.NI, name: 'Nicaragua' },
  [CountryCode.NE]: { id: CountryCode.NE, name: 'Niger' },
  [CountryCode.NG]: { id: CountryCode.NG, name: 'Nigeria' },
  [CountryCode.NO]: { id: CountryCode.NO, name: 'Norway' },

  // O
  [CountryCode.OM]: { id: CountryCode.OM, name: 'Oman' },

  // P
  [CountryCode.PK]: { id: CountryCode.PK, name: 'Pakistan' },
  [CountryCode.PW]: { id: CountryCode.PW, name: 'Palau' },
  [CountryCode.PS]: { id: CountryCode.PS, name: 'Palestine' },
  [CountryCode.PA]: { id: CountryCode.PA, name: 'Panama' },
  [CountryCode.PG]: { id: CountryCode.PG, name: 'Papua New Guinea' },
  [CountryCode.PY]: { id: CountryCode.PY, name: 'Paraguay' },
  [CountryCode.PE]: { id: CountryCode.PE, name: 'Peru' },
  [CountryCode.PH]: { id: CountryCode.PH, name: 'Philippines' },
  [CountryCode.PL]: { id: CountryCode.PL, name: 'Poland' },
  [CountryCode.PT]: { id: CountryCode.PT, name: 'Portugal' },

  // Q
  [CountryCode.QA]: { id: CountryCode.QA, name: 'Qatar' },

  // R
  [CountryCode.RO]: { id: CountryCode.RO, name: 'Romania' },
  [CountryCode.RW]: { id: CountryCode.RW, name: 'Rwanda' },

  // S
  [CountryCode.KN]: { id: CountryCode.KN, name: 'Saint Kitts and Nevis' },
  [CountryCode.LC]: { id: CountryCode.LC, name: 'Saint Lucia' },
  [CountryCode.VC]: { id: CountryCode.VC, name: 'Saint Vincent and the Grenadines' },
  [CountryCode.WS]: { id: CountryCode.WS, name: 'Samoa' },
  [CountryCode.SM]: { id: CountryCode.SM, name: 'San Marino' },
  [CountryCode.ST]: { id: CountryCode.ST, name: 'Sao Tome and Principe' },
  [CountryCode.SA]: { id: CountryCode.SA, name: 'Saudi Arabia' },
  [CountryCode.SN]: { id: CountryCode.SN, name: 'Senegal' },
  [CountryCode.RS]: { id: CountryCode.RS, name: 'Serbia' },
  [CountryCode.SC]: { id: CountryCode.SC, name: 'Seychelles' },
  [CountryCode.SL]: { id: CountryCode.SL, name: 'Sierra Leone' },
  [CountryCode.SG]: { id: CountryCode.SG, name: 'Singapore' },
  [CountryCode.SK]: { id: CountryCode.SK, name: 'Slovakia' },
  [CountryCode.SI]: { id: CountryCode.SI, name: 'Slovenia' },
  [CountryCode.SO]: { id: CountryCode.SO, name: 'Somalia' },
  [CountryCode.ZA]: { id: CountryCode.ZA, name: 'South Africa' },
  [CountryCode.KR]: { id: CountryCode.KR, name: 'South Korea' },
  [CountryCode.SS]: { id: CountryCode.SS, name: 'South Sudan' },
  [CountryCode.ES]: { id: CountryCode.ES, name: 'Spain' },
  [CountryCode.LK]: { id: CountryCode.LK, name: 'Sri Lanka' },
  [CountryCode.SD]: { id: CountryCode.SD, name: 'Sudan' },
  [CountryCode.SR]: { id: CountryCode.SR, name: 'Suriname' },
  [CountryCode.SZ]: { id: CountryCode.SZ, name: 'Swaziland' },
  [CountryCode.SE]: { id: CountryCode.SE, name: 'Sweden' },
  [CountryCode.CH]: { id: CountryCode.CH, name: 'Switzerland' },

  // T
  [CountryCode.TW]: { id: CountryCode.TW, name: 'Taiwan' },
  [CountryCode.TJ]: { id: CountryCode.TJ, name: 'Tajikistan' },
  [CountryCode.TZ]: { id: CountryCode.TZ, name: 'Tanzania' },
  [CountryCode.TH]: { id: CountryCode.TH, name: 'Thailand' },
  [CountryCode.TL]: { id: CountryCode.TL, name: 'Timor-Leste' },
  [CountryCode.TG]: { id: CountryCode.TG, name: 'Togo' },
  [CountryCode.TO]: { id: CountryCode.TO, name: 'Tonga' },
  [CountryCode.TT]: { id: CountryCode.TT, name: 'Trinidad and Tobago' },
  [CountryCode.TN]: { id: CountryCode.TN, name: 'Tunisia' },
  [CountryCode.TR]: { id: CountryCode.TR, name: 'Turkey' },
  [CountryCode.TM]: { id: CountryCode.TM, name: 'Turkmenistan' },
  [CountryCode.TV]: { id: CountryCode.TV, name: 'Tuvalu' },

  // U
  [CountryCode.UG]: { id: CountryCode.UG, name: 'Uganda' },
  [CountryCode.AE]: { id: CountryCode.AE, name: 'United Arab Emirates' },
  [CountryCode.GB]: { id: CountryCode.GB, name: 'United Kingdom' },
  [CountryCode.US]: { id: CountryCode.US, name: 'United States' },
  [CountryCode.UY]: { id: CountryCode.UY, name: 'Uruguay' },
  [CountryCode.UZ]: { id: CountryCode.UZ, name: 'Uzbekistan' },

  // V
  [CountryCode.VU]: { id: CountryCode.VU, name: 'Vanuatu' },
  [CountryCode.VA]: { id: CountryCode.VA, name: 'Vatican City' },
  [CountryCode.VE]: { id: CountryCode.VE, name: 'Venezuela' },
  [CountryCode.VN]: { id: CountryCode.VN, name: 'Vietnam' },

  // Y
  [CountryCode.YE]: { id: CountryCode.YE, name: 'Yemen' },

  // Z
  [CountryCode.ZM]: { id: CountryCode.ZM, name: 'Zambia' },
  [CountryCode.ZW]: { id: CountryCode.ZW, name: 'Zimbabwe' },
}
