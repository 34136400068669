import { HttpClient, HttpMethod } from '@hub-la/fe-core-http-client'
import { PutMembersAreaSettingsRequest } from '../domain/dtos'
import { Envs } from '../envs'

export class PutMembersArea {
  public constructor(private readonly httpClient: HttpClient) {}

  public async execute(values: PutMembersAreaSettingsRequest) {
    try {
      const response = await this.httpClient.request({
        url: `${Envs.BFF_MEMBERS_AREA_URL}/products/${values.productId}/settings/members-area`,
        body: values,
        method: HttpMethod.PUT,
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })

      return response
    } catch (error) {
      throw new Error('Error saving members area settings')
    }
  }
}
