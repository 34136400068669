export enum QueryKey {
  putUserLoginPreferences = 'putUserLoginPreferences',
  postSignUpByEmail = 'postSignUpByEmail',
  postRecoverPassword = 'postRecoverPassword',
  putRecoverPassword = 'putRecoverPassword',
  postSignInByPhoneNumber = 'postSignInByPhoneNumber',
  authenticateWithCode = 'authenticateWithCode',
  getTypeformData = 'getTypeformData',
  postCompleteRegistration = 'postCompleteRegistration',
  putCompleteRegistration = 'putCompleteRegistration',
  postIsUsingPassword = 'postIsUsingPassword',
  postSignInByEmail = 'postSignInByEmail',
  postSignInByEmailVerifyCode = 'postSignInByEmailVerifyCode',
  postSignInByEmailCompleteRegistration = 'postSignInByEmailCompleteRegistration',
  getAuthLink = 'getAuthLink',
}
