export enum QueryKey {
  getAllIntegrations = 'getAllIntegrations',
  getIntegrationByProvider = 'getIntegrationByProvider',
  getAllProducts = 'getAllProducts',
  getOffers = 'getOffers',
  createIntegration = 'createIntegration',
  createRule = 'createRule',
  getLists = 'getLists',
  getTags = 'getTags',
  getIntegrationRules = 'getIntegrationRules',
  getIntegrationHistories = 'getIntegrationHistories',
}
