import { Input } from '@hub-la/shadcn'
import debounce from 'lodash/debounce'
import { Search } from 'lucide-react'
import React from 'react'
import { useTranslation } from 'react-i18next'

type ActionsProps = {
  onSearchChange: (value: string) => void
}

export const Actions: React.FC<ActionsProps> = ({ onSearchChange }) => {
  const { t } = useTranslation()

  const handleSearchChange = debounce((value: string) => onSearchChange(value), 500)

  return (
    <div className="flex flex-col space-y-4 md:flex-row md:justify-between md:items-center md:space-y-0">
      <div className="flex flex-col space-y-4 md:flex-row md:space-y-0 md:space-x-2 w-full md:w-auto">
        <div className="relative w-full md:w-[350px]">
          <Search className="absolute w-4 h-4 right-3 top-1/2 transform -translate-y-1/2 text-muted-foreground z-10" />
          <Input
            id="search"
            className="w-full"
            placeholder={t('tabs.invites.actions.search.placeholder')}
            onChange={(e) => handleSearchChange(e.target.value)}
          />
        </div>
      </div>
    </div>
  )
}
