import { Button } from '@hub-la/shadcn'
import { Timer } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import Countdown from 'react-countdown'

type CountdownButtonProps = {
  children: React.ReactNode
  onClick: () => void
  isLoading?: boolean
  endDate?: string | Date
}

const CountdownButton = ({ endDate, children, onClick, isLoading }: CountdownButtonProps) => {
  const [endCountdown, setEndCountdown] = useState(false)

  const handleClick = () => {
    onClick()
    setEndCountdown(false)
  }

  useEffect(() => {
    if (endDate) {
      setEndCountdown(false)
    }
  }, [endDate])

  return (
    <>
      {!endCountdown && (
        <div className="flex items-center gap-2 text-muted-foreground h-6">
          <Timer className="w-5 h-5" />
          <p className="text-sm">
            Aguarde{' '}
            <Countdown
              date={endDate}
              onComplete={() => setEndCountdown(true)}
              renderer={({ minutes, seconds }) => (
                <span className="font-medium">
                  {minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}
                </span>
              )}
            />{' '}
            segundos para reenviar o código
          </p>
        </div>
      )}
      {endCountdown && (
        <Button className="w-full" variant="outline" disabled={isLoading} onClick={handleClick} type="button">
          {children}
        </Button>
      )}
    </>
  )
}

export { CountdownButton }
