import { Button, Card, CardContent, Label, Skeleton } from '@hub-la/shadcn'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SubscriptionStatus } from '../../../../domain/enums/subscription-status'
import { formatCurrencyWithInstallments } from '../../../../domain/vos/format-currency'
import { useGetSubscriptionValue } from '../../../hooks/use-get-subscription-value'
import { UpgradePlanModal } from './upgrade-plan-modal'

type Props = {
  planType: string
  subscriptionId: string
  status: SubscriptionStatus
}

export const PlanDetails: React.FC<Props> = (props) => {
  const { planType, subscriptionId, status } = props
  const { t } = useTranslation()
  const { data: value } = useGetSubscriptionValue(subscriptionId)
  const [open, setOpen] = useState<boolean>(false)
  const canUpgradePlan = [SubscriptionStatus.ACTIVE].includes(status)

  return (
    <Card className="h-full">
      <CardContent className="p-6">
        <div className="flex flex-col gap-4">
          <div className="flex flex-col space-y-1.5 w-full">
            <Label>{t('planDetails.title')}</Label>
            <p className=" text-muted-foreground">{t(`planType.${planType}`)}</p>
          </div>
          <div className="flex flex-col space-y-1.5 w-full">
            <Label>{t('planDetails.price')}</Label>
            <p className=" text-muted-foreground">
              {value ? (
                formatCurrencyWithInstallments(
                  value.installmentsAmount,
                  value.totalInCents / 100,
                  value.installmentsPriceInCents / 100,
                )
              ) : (
                <Skeleton className="h-4 w-[70px]" />
              )}
            </p>
          </div>
          {canUpgradePlan && (
            <Button variant="outline" className="w-full" onClick={() => setOpen(true)}>
              {t('planDetails.upgrade')}
            </Button>
          )}
        </div>
        <UpgradePlanModal open={open} onClose={() => setOpen(false)} subscriptionId={subscriptionId} />
      </CardContent>
    </Card>
  )
}
