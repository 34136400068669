import { useTranslation } from 'react-i18next'
import { Account } from '../../../domain/dtos/account'
import { Document } from '../../../domain/vos/document'
import { Phone } from '../../../domain/vos/phone'

type InvoiceInfoProps = {
  payer: Account
  seller: Account
}

export const InvoiceInfo = ({ payer, seller }: InvoiceInfoProps) => {
  const { t } = useTranslation()

  return (
    <div className="grid md:grid-cols-2">
      <div className="flex flex-col gap-1">
        <h3 className="text-sm text-muted-foreground">{t('invoice.info.charge')}</h3>

        <p className="text-sm font-medium">{payer.identity?.fullName}</p>

        <p className="text-sm font-medium">{payer.identity?.email}</p>

        {payer.identity?.phone && (
          <p className="text-sm font-medium">{Phone.build(payer.identity?.phone).getFormattedNumber()}</p>
        )}
      </div>

      <div className="flex flex-col gap-1">
        <h3 className="text-sm text-muted-foreground">{t('invoice.info.billing')}</h3>

        <p className="text-sm font-medium">{seller.identity?.fullName}</p>

        {seller.identity?.document && (
          <p className="text-sm font-medium">{Document.build(seller.identity?.document).getValue()}</p>
        )}
      </div>
    </div>
  )
}
