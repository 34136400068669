import { Button, cn } from '@hub-la/shadcn'
import { ArrowUpRight } from 'lucide-react'
import Countdown from 'react-countdown'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { PostEventType } from '../../../domain/dtos'
import DiscordLiveLogo from '../../assets/discord-live.png'
import DiscordLogo from '../../assets/discord.png'
import MeetsLiveLogo from '../../assets/meets-live.png'
import MeetsLogo from '../../assets/meets.png'
import YoutubeLiveLogo from '../../assets/youtube-live.png'
import YoutubeLogo from '../../assets/youtube.png'
import ZoomLiveLogo from '../../assets/zoom-live.png'
import ZoomLogo from '../../assets/zoom.png'
import { getSiteUrl } from '../../utils/routes'

type EventProps = {
  type: string
  liveId: string
  startTime: Date
  endTime: Date
  started?: boolean
  onComplete?: () => void
}

export const EventCard = ({ type, startTime, endTime, liveId, started = false, onComplete }: EventProps) => {
  const { t } = useTranslation()

  if (endTime < new Date()) {
    return null
  }

  const getIcon = () => {
    switch (type) {
      case PostEventType.discord:
        return started ? DiscordLiveLogo : DiscordLogo
      case PostEventType.meets:
        return started ? MeetsLiveLogo : MeetsLogo
      case PostEventType.youtube:
        return started ? YoutubeLiveLogo : YoutubeLogo
      case PostEventType.zoom:
        return started ? ZoomLiveLogo : ZoomLogo
      default:
        return started ? ZoomLiveLogo : ZoomLogo
    }
  }

  return (
    <div
      className={cn(
        'rounded-lg p-4 flex flex-col sm:flex-row gap-4 sm:items-center justify-between',
        started ? 'bg-hubla-primary text-primary-foreground border-hubla-primary' : 'bg-background',
      )}
      data-testid="event-card"
    >
      <div className="flex flex-wrap gap-4 items-center">
        <div className={cn('w-14 aspect-square flex items-center justify-center rounded-sm overflow-hidden')}>
          <img src={getIcon()} alt="Meeting Logo" className="w-full h-full " />
        </div>

        <div className="flex flex-col">
          <p className="font-bold">{t(`postDetail.meeting.type.${type}`)}</p>

          {started ? (
            <p className="text-primary-foreground/70 break-all">
              {getSiteUrl('/live/' + liveId)
                .replace('https://', '')
                .replace('http://', '')}
            </p>
          ) : (
            <p className="text-muted-foreground">
              Faltam{' '}
              <Countdown
                date={startTime}
                renderer={({ days, hours, minutes, seconds }) => (
                  <>
                    {days}d {hours}h {minutes}min {seconds}s
                  </>
                )}
                onComplete={() => onComplete?.()}
              />
            </p>
          )}
        </div>
      </div>

      {started && (
        <Link to={'/live/' + liveId} className="w-full sm:w-auto">
          <Button
            variant="outline"
            className="w-full gap-2 text-foreground bg-background border-border"
            data-testid="join-live-button"
          >
            <ArrowUpRight className="h-4 w-4" />

            {t('postDetail.meeting.join')}
          </Button>
        </Link>
      )}
    </div>
  )
}
