import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { Comment } from '../domain/dtos/comment'
import { PostCommentInput } from '../domain/dtos/post-comment-input'
import { GeneralError } from '../domain/errors/general'
import { Envs } from '../envs'

export class PostComment {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient<Comment>,
  ) {}

  public async execute(input: PostCommentInput): Promise<Comment | undefined> {
    const response = await this.httpClient.request({
      method: HttpMethod.POST,
      url: `${Envs.BFF_MEMBERS_AREA_URL}/hub/posts/${input.postId}/comments`,
      body: this.makeBody(input),
    })

    if (response.statusCode === HttpStatusCode.CREATED || response.statusCode === HttpStatusCode.OK) {
      return response.data
    }

    throw new GeneralError()
  }

  private makeBody(input: PostCommentInput) {
    return {
      message: input.message,
      parentCommentId: input?.parentCommentId,
      mentionUserId: input?.mentionUserId,
    }
  }
}
