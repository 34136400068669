import { Skeleton } from '@hub-la/shadcn'
import { PendingInvoicesLoading } from './components/pending-invoices-loading'

export const Loading = () => {
  return (
    <div className="w-full flex flex-col gap-4">
      <Skeleton className="h-9 w-24" />

      <Skeleton className="h-9 w-48" />

      <PendingInvoicesLoading />

      <PendingInvoicesLoading />
    </div>
  )
}
