import { HttpClient } from '@hub-la/fe-core-http-client'
import { useContainer } from '@hub-la/fe-container'
import { useQuery } from '@tanstack/react-query'
import { useMarkPostViewed } from './use-mark-post-viewed'
import { GetPostInput } from '../../domain/dtos/get-post-input'
import { GetLiveLinkUseCase } from '../../usecases/get-live-link'
import { QueryKey } from '../../domain/dtos'

export const useGetLiveLink = (input: GetPostInput) => {
  const container = useContainer()
  const markPostViewed = useMarkPostViewed('meeting')
  return useQuery(
    [QueryKey.getLiveLink, input.postId],
    () => new GetLiveLinkUseCase(container.get(HttpClient)).fetch(input),
    {
      initialData: undefined,
      onSuccess: async (data) => {
        if (data) {
          await markPostViewed.mutateAsync(data.id)
          window.location.href = data.url
        }
      },
    },
  )
}
