import { yupResolver } from '@hookform/resolvers/yup'
import { OfferDto, OfferDtoStatusEnum, PlanDto, PlanDtoIntervalEnum, PlanPriceDto, PlanPriceDtoIntervalEnum, ProductDto, ProductDtoStatusEnum } from '@hub-la/sdk-bff-product'
import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Label,
  Input,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Textarea,
} from '@hub-la/shadcn'
import { getOfferPrice } from 'libs/frontend/modules/offer/src/lib/usecases/get-offer-price.usecase'
import React, { useEffect } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useGetOffers } from '../../../../../../../hooks/use-list-offers'
import { useGetProducts } from '../../../../../../../hooks/use-list-products'
import {
  orderBumpSchemaValidation,
  OrderBumpSchemaValidationType,
} from '../../../../../../../validations/order-bump-validation'
import { MultipleOrderBumpPreview } from './multiple-order-bump-preview'
import { getOfferLongerPlan } from 'libs/frontend/modules/offer/src/lib/usecases/get-offer-longer-plan'

interface MultipleOrderBumpModalProps {
  onCancel: () => void
  onSubmit: (values: OrderBumpSchemaValidationType & {
    offer: OfferDto | null;
    product: ProductDto | null,
    title?: string,
    description?: string,
    callToAction?: string,
    planInterval?: PlanPriceDtoIntervalEnum,
    price?: number,
    maxInstallments?: number
  }) => void
  open: boolean
  isEditing: boolean
  defaultValues?: any
  actionSave?: string
  actionCancel?: string
  orderBumps?: any[]
}

export const MultipleOrderBumpModal: React.FC<MultipleOrderBumpModalProps> = ({
  onCancel,
  onSubmit,
  open,
  defaultValues,
  actionSave,
  orderBumps,
  isEditing
}) => {
  const { t } = useTranslation()

  const form = useForm({
    defaultValues: {
      productId: '',
      offerId: '',
      title: '',
      callToAction: '',
      description: ''
    },
    resolver: yupResolver(orderBumpSchemaValidation),
    mode: 'onChange',
  })
  useEffect(() => {
    form.reset({
      productId: defaultValues?.productExtraOfferId ?? '',
      offerId: defaultValues?.id ?? '',
      title: defaultValues?.title ?? '',
      description: defaultValues?.description ?? '',
      callToAction: defaultValues?.callToAction ?? ''
    })
  }, [
    defaultValues?.id,
    defaultValues?.productExtraOfferId,
    defaultValues?.title,
    defaultValues?.description,
    defaultValues?.callToAction
  ])

  const selectedProductId = form.watch('productId')
  const selectedOfferId = form.watch('offerId')

  const { data: products, isLoading: isLoadingProducts } = useGetProducts({
    page: 1,
    pageSize: 999,
  })

  const availableProducts = (products?.items ?? []).filter(
    (product) => product.status === ProductDtoStatusEnum.IS_SELLING,
  )

  const { offerId } = useParams<{ offerId: string }>()

  const { data: offers, isLoading: isLoadingOffers } = useGetOffers({
    page: 1,
    pageSize: 999,
    productId: selectedProductId ?? '',
  })

  const availableOffers = (offers?.items ?? [])?.filter(
    (offer) => offer.id !== offerId && offer.status === OfferDtoStatusEnum.IS_SELLING && !(!isEditing && orderBumps?.some((orderBump) => offer.id === orderBump.id)),
  )

  const selectedProduct = products?.items?.find((product) => product.id === selectedProductId)
  const selectedOffer = offers?.items?.find((offer) => offer.id === selectedOfferId)

  useEffect(() => {
    form.resetField('offerId')
  }, [form.resetField, selectedProductId])

  const handleCancel = () => {
    form.reset()
    onCancel()
  }

  const handleSubmit = (values: OrderBumpSchemaValidationType) => {
    let plan: PlanPriceDto | undefined;

    if (!!selectedOffer?.plans?.length) {
      plan = getOfferLongerPlan(selectedOffer)
    }

    form.reset()

    onSubmit({
      ...values,
      offer: selectedOffer ?? null,
      product: selectedProduct ?? null,
      maxInstallments: plan?.installments,
      planInterval: plan?.interval,
      price: plan?.price ?? selectedOffer?.price
    })
  }

  return (
    <Dialog open={open} onOpenChange={handleCancel}>
      <DialogContent className="max-w-[462px] h-full max-h-[765px] overflow-y-scroll">
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)}>
            <DialogHeader>
              <DialogTitle>{t('orderBumpModal.title')}</DialogTitle>
              <DialogDescription>{t('orderBumpModal.description')}</DialogDescription>
            </DialogHeader>
            <div className="grid gap-4 py-4">
              <Controller
                control={form.control}
                name="productId"
                render={({ field, fieldState }) => (
                  <Select onValueChange={field.onChange} defaultValue={field.value} disabled={isLoadingProducts}>
                    <Label>{t('orderBumpModal.productsList.title')}</Label>
                    <SelectTrigger className={fieldState.error ? 'border-red-500' : ''}>
                      <SelectValue placeholder={t('orderBumpModal.productsList.title')} />
                    </SelectTrigger>
                    <SelectContent className="z-10002">
                      {availableProducts?.map((product) => (
                        <SelectItem key={product.id} value={product.id}>
                          {product.name}
                        </SelectItem>
                      ))}

                      {availableProducts?.length === 0 && (
                        <SelectItem value="empty" disabled>
                          {t('orderBumpModal.offersList.emptyAvailable')}
                        </SelectItem>
                      )}
                    </SelectContent>
                  </Select>
                )}
              />
              <Controller
                control={form.control}
                name="offerId"
                render={({ field, fieldState }) => (
                  <div>
                    <Label>{t('orderBumpModal.offersList.title')}</Label>
                    <Select onValueChange={field.onChange} defaultValue={field.value} disabled={isLoadingOffers}>
                      <SelectTrigger className={fieldState.error ? 'border-red-500' : ''}>
                        <SelectValue placeholder={t('orderBumpModal.offersList.title')} />
                      </SelectTrigger>
                      <SelectContent className="z-10002">
                        {availableOffers?.map((offer) => (
                          <SelectItem key={offer.id} value={offer.id ?? ''}>
                            {offer.name} - {getOfferPrice(offer)}
                          </SelectItem>
                        ))}

                        {availableOffers?.length === 0 && (
                          <SelectItem value="empty" disabled>
                            {t('orderBumpModal.offersList.emptyAvailable')}
                          </SelectItem>
                        )}
                      </SelectContent>
                    </Select>
                  </div>
                )}
              />
              <Controller
                control={form.control}
                name="title"
                render={({ field }) => (
                  <div>
                    <Label>Titulo</Label>
                    <Input {...field} />
                  </div>
                )}
              />
              <Controller
                control={form.control}
                name="callToAction"
                render={({ field }) => (
                  <div>
                    <Label>Call to action</Label>
                    <Input {...field} />
                  </div>
                )}
              />
              <Controller
                control={form.control}
                name="description"
                render={({ field }) => (
                  <div>
                    <Label>Descrição</Label>
                    <Textarea {...field} />
                  </div>
                )}
              />
              <MultipleOrderBumpPreview
                product={selectedProduct}
                offer={selectedOffer}
                description={form.watch("description")}
                callToAction={form.watch("callToAction")}
                title={form.watch("title")}
              />
            </div>
            <DialogFooter>
              <Button type="button" disabled={!form.formState.isValid} onClick={form.handleSubmit(handleSubmit)}>
                {actionSave ?? t('orderBumpModal.actions.save')}
              </Button>
            </DialogFooter>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  )
}
