import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '../../domain/enums/query-key'
import { GetFreeMembers } from '../../usecases/get-free-members'

export const useGetFreeMembers = (input: { groupId: string; groupResourceId: string }) => {
  const container = useContainer()

  return useQuery(
    [QueryKey.getFreeMembers, input.groupId, input.groupResourceId],
    () => new GetFreeMembers(container.get(HttpClient)).execute(input),
    {
      retry: 3,
      enabled: !!input.groupId && !!input.groupResourceId,
      refetchOnWindowFocus: false,
    },
  )
}
