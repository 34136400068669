import { Button, Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, Input } from '@hub-la/shadcn'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { YoutubeObject } from '../../../../domain/dtos/youtube-object'
import { YOUTUBE_REGEX, Youtube } from '../../../../domain/vos/youtube'

type Props = {
  open: boolean
  onClose: () => void
  onSubmit: (youtubeObject: YoutubeObject) => void
}

export const GetYoutubeUrlModal: React.FC<Props> = (props) => {
  const { open, onClose, onSubmit } = props
  const [value, setValue] = useState<string>('')
  const { t } = useTranslation()
  const youtube = new Youtube()
  const hasError = value.length > 0 && !value.match(YOUTUBE_REGEX)

  const handleSubmit = () => {
    const youtubeObject: YoutubeObject = {
      id: String(youtube.parseId(value)),
      thumbnail: youtube.getThumbnail(value),
      embed: youtube.getEmbed(value),
      url: value,
    }
    setValue('')
    onSubmit?.(youtubeObject)
    onClose()
  }

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle className="flex justify-between items-center">
            {t('settings.productPage.media.youtube.title')}
          </DialogTitle>
        </DialogHeader>

        <div className="py-4">
          <Input
            className={hasError ? 'border-red-500' : ''}
            onChange={(e) => setValue(e.target.value)}
            value={value}
            placeholder={t('settings.productPage.media.youtube.label')}
          />

          {hasError && <p className="text-red-500 text-sm mt-1">{t('settings.productPage.media.youtube.error')}</p>}
        </div>

        <DialogFooter>
          <Button variant="outline" onClick={onClose}>
            {t('settings.productPage.media.youtube.cancel')}
          </Button>

          <Button onClick={handleSubmit} disabled={hasError || value.length === 0}>
            {t('settings.productPage.media.youtube.add')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
