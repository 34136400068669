import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { QueryKey } from '@hub-la/fe-post'
import { useQuery } from '@tanstack/react-query'
import { GetProducts } from '../../usecases/get-products'

export const useGetProducts = () => {
  const container = useContainer()

  return useQuery([QueryKey.getProducts], () => new GetProducts(container.get(HttpClient)).execute(), {
    retry: 3,
    retryOnMount: false,
    refetchOnWindowFocus: false,
    initialData: [],
  })
}
