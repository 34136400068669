import { AccountDropdown, AccountDropdownProvider } from '@hub-la/fe-account-dropdown'
import { useContainer } from '@hub-la/fe-container'
import { useGetRoleplayReference, useRoleplay } from '@hub-la/fe-roleplay'
import { useHistory } from 'react-router-dom'
import useCurrentUser from '../hooks/use-current-user'

export const UserNavigation = () => {
  const history = useHistory()
  const container = useContainer()
  const { isRoleplay } = useRoleplay()
  const currentUser = useCurrentUser()
  const { data: roleplayUser } = useGetRoleplayReference({ enabled: isRoleplay })

  if (!currentUser) {
    return null
  }

  return (
    <AccountDropdownProvider container={container}>
      {currentUser && !isRoleplay && (
        <AccountDropdown
          push={history.push}
          id={currentUser?.id ?? ''}
          firstName={currentUser?.firstName ?? ''}
          picture={currentUser?.picture ?? null}
          username={currentUser?.id}
          bankAccount={3}
        />
      )}

      {currentUser && isRoleplay && roleplayUser && (
        <AccountDropdown
          push={history.push}
          id={roleplayUser.userId}
          firstName={roleplayUser.name ?? undefined}
          picture={roleplayUser.picture ?? null}
          username={roleplayUser.username}
          bankAccount={3}
        />
      )}
    </AccountDropdownProvider>
  )
}
