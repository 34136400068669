export const LoadingState = () => {
  return (
    <div className="flex items-center justify-center w-full h-screen" data-testid="loading">
      <div className="relative w-16 h-16">
        <div className="absolute top-0 left-0 w-full h-full border-4 border-white opacity-30 rounded-full"></div>
        <div className="absolute top-0 left-0 w-full h-full border-4 border-white border-t-transparent animate-spin rounded-full"></div>
      </div>
    </div>
  )
}
