import { AlertCircle, Eye, EyeOff, MessageSquare, UserCircle, Users } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { ResourceType } from '../../../../../../domain/dtos/groups'

type Props = {
  resourceType: ResourceType
}

export const GroupResourceDescription = ({ resourceType }: Props) => {
  const { t } = useTranslation()

  const DescriptionItem = ({ icon, text }: { icon: React.ReactNode; text: string }) => (
    <div className="flex items-center space-x-2">
      <div className="text-muted-foreground">{icon}</div>

      <p className="text-sm text-muted-foreground">{text}</p>
    </div>
  )

  const Description = () => {
    switch (resourceType) {
      case ResourceType.whatsapp:
        return (
          <>
            <DescriptionItem
              icon={<Users size={16} />}
              text={t(`member-area.groups.createGroupModal.description.${resourceType}.advantage_1`)}
            />

            <DescriptionItem
              icon={<AlertCircle size={16} />}
              text={t(`member-area.groups.createGroupModal.description.${resourceType}.advantage_2`)}
            />

            <DescriptionItem
              icon={<MessageSquare size={16} />}
              text={t(`member-area.groups.createGroupModal.description.${resourceType}.advantage_3`)}
            />
          </>
        )
      case ResourceType.telegram_channel:
        return (
          <>
            <DescriptionItem
              icon={<Users size={16} />}
              text={t(`member-area.groups.createGroupModal.description.${resourceType}.advantage_1`)}
            />

            <DescriptionItem
              icon={<Eye size={16} />}
              text={t(`member-area.groups.createGroupModal.description.${resourceType}.advantage_2`)}
            />

            <DescriptionItem
              icon={<MessageSquare size={16} />}
              text={t(`member-area.groups.createGroupModal.description.${resourceType}.advantage_3`)}
            />
          </>
        )
      case ResourceType.telegram:
        return (
          <>
            <DescriptionItem
              icon={<Users size={16} />}
              text={t(`member-area.groups.createGroupModal.description.${resourceType}.advantage_1`)}
            />

            <DescriptionItem
              icon={<UserCircle size={16} />}
              text={t(`member-area.groups.createGroupModal.description.${resourceType}.advantage_2`)}
            />

            <DescriptionItem
              icon={<EyeOff size={16} />}
              text={t(`member-area.groups.createGroupModal.description.${resourceType}.advantage_3`)}
            />
          </>
        )
      default:
        return null
    }
  }

  return (
    <div className="flex flex-col gap-1">
      <Description />
    </div>
  )
}
