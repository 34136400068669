import { InvoiceStatus } from '../enums/invoice-status'

export const invoiceStatus = [
  {
    id: InvoiceStatus.PAID,
    label: 'Paga',
  },
  {
    id: InvoiceStatus.UNPAID,
    label: 'Em aberto',
  },
  {
    id: InvoiceStatus.EXPIRED,
    label: 'Não paga',
  },
  {
    id: InvoiceStatus.DISPUTED,
    label: 'Contestada',
  },
  {
    id: InvoiceStatus.RETURNED,
    label: 'Reembolsada',
  },
  {
    id: InvoiceStatus.CHARGEBACK,
    label: 'Chargeback',
  },
]
