import { Skeleton, TableCell, TableRow } from '@hub-la/shadcn'

export const InvoicesTableLoading = () => {
  return (
    <TableRow>
      <TableCell>
        <div className="flex gap-2">
          <Skeleton className="w-[15%] h-6" />
          <Skeleton className="w-[60%] h-6" />
        </div>
      </TableCell>

      <TableCell>
        <Skeleton className="w-[70%] h-6" />
      </TableCell>

      <TableCell>
        <Skeleton className="w-[70%] h-6" />
      </TableCell>

      <TableCell>
        <Skeleton className="w-[70%] h-6" />
      </TableCell>

      <TableCell>
        <Skeleton className="w-[70%] h-6" />
      </TableCell>
    </TableRow>
  )
}
