import { FormikProvider, useFormik } from 'formik'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { Trans, useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'
import { SignInNotfoundError } from '../../../domain/errors/signin-not-found-error'
import { usePostIsUsingPassword } from '../../hooks/use-post-is-using-password'
import { usePostSignInByEmail } from '../../hooks/use-post-sign-in-by-email'
import { checkEmailSchema } from '../../validations/signin-email'
import { AuthCard } from '../auth-card/auth-card'
import { SignInByEmailVerifyCode } from './sign-in-by-email-verify-code'
import { SignInEmailWithPasswordCard } from './sign-in-by-email-with-pasword'

import { Alert, AlertDescription, Button, Input, Label } from '@hub-la/shadcn'

const SignInByEmailCard = () => {
  const { t } = useTranslation()

  const { executeRecaptcha } = useGoogleReCaptcha()
  const {
    mutateAsync: postSignInByEmail,
    isLoading: postSignInByEmailLoading,
    data: signInByEmail,
    isError: postSignInByEmailError,
    error: postSignInByEmailErrorData,
    reset: resetPostSignInByEmail,
  } = usePostSignInByEmail({
    onError: (error) => {
      const isEmailError = [SignInNotfoundError].some((errorType) => error instanceof errorType)
      if (isEmailError) {
        form.setFieldError('email', error.message)
      }
    },
  })
  const {
    mutate,
    isLoading,
    error,
    isError,
    reset,
    data: isUsingPassword,
  } = usePostIsUsingPassword({
    onSuccess: async (isUsingPassword) => {
      if (isUsingPassword) return

      const captcha = await executeRecaptcha?.('email_signin')

      postSignInByEmail({
        email: form.values.email,
        captcha,
      })
    },
    onError: (error) => {
      const isEmailError = [SignInNotfoundError].some((errorType) => error instanceof errorType)
      if (isEmailError) {
        form.setFieldError('email', error.message)
      }
    },
  })

  const form = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: async (values) => {
      const captcha = await executeRecaptcha?.('email_signin')

      mutate({ email: values.email, captcha })
    },
    validationSchema: checkEmailSchema,
  })

  if (!isUsingPassword && signInByEmail?.authId) {
    return (
      <SignInByEmailVerifyCode
        email={form.values.email}
        authId={signInByEmail?.authId}
        nextRetryDate={signInByEmail?.nextRetryDate}
        loadingRetry={postSignInByEmailLoading}
        retryCode={async () => {
          const captcha = await executeRecaptcha?.('email_signin')
          await postSignInByEmail({ email: form.values.email, captcha })
        }}
      />
    )
  }

  if (isUsingPassword) {
    return (
      <SignInEmailWithPasswordCard
        defaultEmail={form.values.email}
        resetEmail={() => {
          form.resetForm()
          reset()
          resetPostSignInByEmail()
        }}
      />
    )
  }

  const hideErrorOnSnackbar = [SignInNotfoundError].some((errorType) => error instanceof errorType)

  return (
    <AuthCard title="Faça seu login" description="Informe seus dados para continuar">
      <FormikProvider value={form}>
        <form onSubmit={form.handleSubmit} className="space-y-4 w-full">
          <div className="space-y-2">
            <Label htmlFor="email">E-mail</Label>
            <Input
              id="email"
              name="email"
              type="email"
              disabled={isUsingPassword}
              onChange={form.handleChange}
              value={form.values.email}
              autoFocus
            />
            {form.touched.email && form.errors.email && (
              <p className="text-sm text-red-500">
                <Trans i18nKey={form.errors.email} components={[<a href="/signup">Crie sua conta</a>]} />
              </p>
            )}
          </div>

          <Button className="w-full" type="submit" disabled={isLoading || postSignInByEmailLoading}>
            {isLoading || postSignInByEmailLoading ? 'Carregando...' : 'Continuar'}
          </Button>
        </form>
      </FormikProvider>

      <div className="relative my-4">
        <hr />
        <span className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-background px-2 text-xs text-muted-foreground">
          {t('Ou')}
        </span>
      </div>
      <p className="text-center text-sm text-muted-foreground">
        Não possui uma conta?{' '}
        <RouterLink className="text-lime-600" to="/signup">
          Crie sua conta
        </RouterLink>
      </p>

      {isError && !hideErrorOnSnackbar && (
        <Alert variant="destructive" className="mt-4">
          <AlertDescription>{t((error as Error)?.message)}</AlertDescription>
        </Alert>
      )}

      {postSignInByEmailError && !hideErrorOnSnackbar && (
        <Alert variant="destructive" className="mt-4">
          <AlertDescription>{t((postSignInByEmailErrorData as Error)?.message)}</AlertDescription>
        </Alert>
      )}
    </AuthCard>
  )
}

export { SignInByEmailCard }
