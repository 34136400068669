import { AssetApplicationSubType, AssetImageSubType, AssetType, AssetVideoSubType } from '../domain/dtos'
import { Envs } from '../envs'
import { GetUploadErrorMessage } from './get-upload-error-message'

export const isValidType = (type: string, isAttachment: boolean) => {
  const format = type.split('/')[0]
  const extension = type.split('/')[1]

  const isValid = {
    valid: false,
    type: format,
  }

  isValid.valid = Object.values(AssetType).includes(AssetType[format])

  if (!isValid.valid) {
    throw new GetUploadErrorMessage().execute(type)
  }

  switch (AssetType[format]) {
    case AssetType.image:
      isValid.valid = !isAttachment
        ? Object.values(AssetImageSubType).includes(AssetImageSubType[extension])
        : Object.values(Envs.ATTACHMENT_IMAGE_MIMETYPE_ALLOWED).includes(`${format}/${extension}`)
      break
    case AssetType.video:
      // attachment don't need verify subtype it allow video/*
      isValid.valid = !isAttachment ? Object.values(AssetVideoSubType).includes(AssetVideoSubType[extension]) : true
      break
    case AssetType.application:
      isValid.valid = !isAttachment
        ? Object.values(AssetApplicationSubType).includes(AssetApplicationSubType[extension])
        : Envs.ATTACHMENT_FILE_MIMETYPE_ALLOWED.split(',').some((mimetype) => mimetype.includes(extension))
      break
  }

  if (!isValid.valid) {
    throw new GetUploadErrorMessage().execute(type)
  }

  return isValid.valid
}
