import { PostEventType } from '@hub-la/fe-post'
import { isNil } from 'lodash'

export class GetEventProviderByLink {
  public execute(link?: string) {
    const youtubeRegex = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/)(?:watch\?v=)?([a-zA-Z0-9\-_]{11})/
    const zoomRegex = /^(https:\/\/zoom\.us\/j\/)[0-9]{9,10}/
    const googleMeetRegex = /^(https:\/\/meet\.google\.com\/)[a-zA-Z0-9\-_]{8,20}/
    const discordRegex = /^(https:\/\/discord\.gg\/)[a-zA-Z0-9\-_]{8,20}/

    if (isNil(link)) {
      return PostEventType.other
    }

    if (link.match(youtubeRegex)) {
      return PostEventType.youtube
    } else if (link.match(zoomRegex)) {
      return PostEventType.zoom
    } else if (link.match(googleMeetRegex)) {
      return PostEventType.meets
    } else if (link.match(discordRegex)) {
      return PostEventType.discord
    } else {
      return PostEventType.other
    }
  }
}
