import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation } from '@tanstack/react-query'
import { AddDailyCreditsByEmailBatchInput } from '../../domain/dtos/add-daily-credits-by-email-batch-input'
import { AddSubscriptionDailyCreditsByEmailInBatchOutput } from '../../domain/dtos/add-subscription-daily-credits-by-email-batch-output'
import { QueryKey } from '../../domain/enums/query-keys'
import { AddDailyCreditsByEmailBatch } from '../../usecases/add-daily-credits-by-email-batch'

export const useAddDailyCreditsByEmailBatch = ({
  onError,
  onSuccess,
}: {
  onError: (error: unknown, variables: AddDailyCreditsByEmailBatchInput, context: unknown) => unknown
  onSuccess: (
    data: AddSubscriptionDailyCreditsByEmailInBatchOutput,
    variables: AddDailyCreditsByEmailBatchInput,
    context: unknown,
  ) => unknown
}) => {
  const container = useContainer()

  return useMutation(
    [QueryKey.addDailyCreditsByEmailBatch],
    (input: AddDailyCreditsByEmailBatchInput) =>
      new AddDailyCreditsByEmailBatch(container.get(HttpClient)).execute(input),
    {
      retry: false,
      onError,
      onSuccess,
    },
  )
}
