import { Badge, Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@hub-la/shadcn'
import { ChevronRight } from 'lucide-react'
import { useHistory } from 'react-router-dom'
import { InvoiceStatus } from '../../domain/enums/invoice-status'

type InvoiceStatusStatsProps = {
  status?: InvoiceStatus
  installment: number
  installments: number
  size?: 'large' | 'small' | 'tiny'
  smartInstallmentId?: string
}

export const InvoiceInstallmentsStats = ({
  installment,
  installments,
  smartInstallmentId,
}: InvoiceStatusStatsProps) => {
  const history = useHistory()

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation() // Prevent event bubbling if parent is clickable
    if (smartInstallmentId) {
      history.push(`/dashboard/sales/smart-installments/${smartInstallmentId}`)
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <div
            className={`inline-flex items-center group ${smartInstallmentId ? 'cursor-pointer' : ''}`}
            onClick={handleClick}
          >
            <Badge variant="secondary" className="flex items-center gap-1">
              Parcela {installment} de {installments}
              {smartInstallmentId && (
                <ChevronRight className="w-0 h-0 opacity-0 -mr-1 transition-all group-hover:opacity-100 group-hover:mr-0 group-hover:h-[14px] group-hover:w-[14px]" />
              )}
            </Badge>
          </div>
        </TooltipTrigger>
        <TooltipContent>
          <p>
            Essa é uma fatura referente à uma parcela do Parcelamento Inteligente - Fatura {installment} de{' '}
            {installments}. Clique para ver mais detalhes.
          </p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}
