import { cn, Input, Label } from '@hub-la/shadcn'
import React from 'react'

interface InputWrapperProps extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: boolean
  helperText?: string
}

export const InputWrapper: React.FC<InputWrapperProps> = ({ error, helperText, placeholder, className, ...props }) => {
  return (
    <div className="space-y-1.5">
      <Label>{placeholder}</Label>
      <Input className={cn(className, error ? 'border-red-500 focus:ring-red-500' : '')} {...props} />
      {helperText && <p className={cn('text-xs', error ? 'text-red-500' : 'text-muted-foreground')}>{helperText}</p>}
    </div>
  )
}
