import React from 'react'
import { useTranslation } from 'react-i18next'
import { Search } from 'lucide-react'

export const UserDoesNotHaveSubscription: React.FC = () => {
  const { t } = useTranslation()

  return (
    <div data-testid="empty-state" className="w-full h-full flex flex-col justify-center items-center py-30">
      <div className="p-2 rounded-md border border-muted bg-muted">
        <Search className="text-muted-foreground" />
      </div>

      <h4 className="text-2xl font-semibold mt-6">{t('empty')}</h4>
    </div>
  )
}
