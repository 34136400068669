import { yupResolver } from '@hookform/resolvers/yup'
import { Badge, Button, Dialog, DialogContent, DialogHeader, DialogTitle, useToast } from '@hub-la/shadcn'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Member } from '../../../../domain/dtos/member'
import { useGetCohortsList } from '../../../hooks/cohorts/use-get-cohorts-list'
import { usePostChangeMembersCohorts } from '../../../hooks/members/use-post-change-members-cohorts'
import {
  changeMembersCohortsSchemaValidation,
  ChangeMembersCohortsSchemaValidationType,
} from '../../../validations/change-members-cohorts'
import { MultiSelect } from './multi-select'
import { SelectCohorts } from './select-cohorts'

type ChangeMembersCohortsModalProps = {
  isOpen: boolean
  productId: string
  membersSelected: Member[]
  onClose: () => void
}

export const ChangeMembersCohortsModal: React.FC<ChangeMembersCohortsModalProps> = ({
  isOpen,
  productId,
  onClose,
  membersSelected,
}) => {
  const { t } = useTranslation()
  const { toast } = useToast()

  const { data: cohorts, isLoading: isLoadingCohorts } = useGetCohortsList({
    productId,
    page: 1,
    pageSize: 999,
  })

  const isOneMemberSelected = membersSelected.length === 1

  const { mutate: changeMembersCohorts, isLoading } = usePostChangeMembersCohorts({
    onSuccess: (data) => {
      onClose()
      if ((data?.errors ?? []).length > 0) {
        const errorsMembersName = membersSelected
          .filter((member) => (data?.errors ?? []).includes(member.id))
          .map((member) => member.name)

        toast({
          title: t(
            isOneMemberSelected
              ? 'tabs.members.changeMembersCohortsModal.snackbar.failOne'
              : 'tabs.members.changeMembersCohortsModal.snackbar.failMany',
            { users: errorsMembersName },
          ),
          variant: 'destructive',
        })
      } else if ((data?.successes ?? []).length > 0) {
        toast({
          title: t(
            isOneMemberSelected
              ? 'tabs.members.changeMembersCohortsModal.snackbar.successOne'
              : 'tabs.members.changeMembersCohortsModal.snackbar.successMany',
            { count: data?.successes.length },
          ),
        })
      }
    },
    onError: () => {
      onClose()
      toast({
        title: t('tabs.members.changeMembersCohortsModal.snackbar.genericError'),
        variant: 'destructive',
      })
    },
  })

  const form = useForm<ChangeMembersCohortsSchemaValidationType>({
    defaultValues: {
      cohorts: [],
    },
    resolver: yupResolver(changeMembersCohortsSchemaValidation),
    mode: 'onChange',
  })

  const { isValid } = form.formState

  const handleConfirm = (data: ChangeMembersCohortsSchemaValidationType) => {
    changeMembersCohorts({
      newCohorts: data.cohorts,
      selectedMembers: membersSelected,
    })
  }

  return (
    <Dialog open={isOpen} onOpenChange={(open) => !open && onClose()}>
      <DialogContent className="sm:max-w-[588px]">
        <DialogHeader>
          <DialogTitle>{t('tabs.members.changeMembersCohortsModal.title')}</DialogTitle>
        </DialogHeader>
        <form onSubmit={form.handleSubmit(handleConfirm)} className="space-y-6">
          <div className="space-y-3">
            <p>{t('tabs.members.changeMembersCohortsModal.description')}</p>
            {membersSelected.length > 1 && (
              <Badge variant="secondary">
                {t('tabs.members.changeMembersCohortsModal.membersSelectTag', {
                  total: membersSelected.length,
                })}
              </Badge>
            )}
          </div>
          <Controller
            name="cohorts"
            control={form.control}
            render={({ field }) => <SelectCohorts {...field} offers={cohorts?.items ?? []} />}
          />
          <div className="flex justify-end space-x-2">
            <Button variant="outline" onClick={onClose} disabled={isLoading}>
              {t('tabs.members.changeMembersCohortsModal.cancel')}
            </Button>
            <Button type="submit" disabled={isLoading || !isValid}>
              {t('tabs.members.changeMembersCohortsModal.confirm')}
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  )
}
