import { User, UserCredential } from 'firebase/auth'
import { injectable } from 'inversify'

@injectable()
export abstract class Auth {
  public abstract getToken(): Promise<string | undefined>

  public abstract signInWithToken(token: string): Promise<UserCredential>

  public abstract signInWithPassword(email: string, password: string): Promise<UserCredential>

  public abstract signOut(): Promise<void>

  public abstract listen(callable: (user: User | null) => void): () => void
}
