import { useTranslation } from 'react-i18next'
import { CardInfoBox } from './card-info-box'
import { LoadingCard } from './loading-card'

type Props = {
  amount?: number
  isLoading: boolean
}

export const ConversionsAmount: React.FC<Props> = ({ isLoading, amount }: Props) => {
  const { t } = useTranslation()

  if (isLoading) {
    return <LoadingCard />
  }

  return <CardInfoBox title={t('waitlist.conversionsAmount')} current={amount ?? 0} />
}
