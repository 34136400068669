import { Button } from '@hub-la/shadcn'
import { useState } from 'react'
import { RefundStatus } from '../../../../domain/enums/refund-status.enum'
import { ConfirmCancelRefundRequestModal } from './confirm-cancel-refund-request-modal'
import { ConfirmReactivateRefundRequestModal } from './confirm-reactivate-refund-request-modal'

type RefundActionsProps = {
  refund: {
    id: string
    status: RefundStatus
  }
  onSuccess: () => void
}

export const RefundActions = ({ refund, onSuccess }: RefundActionsProps) => {
  const [showConfirmCancelModal, setConfirmCancelModal] = useState<boolean>(false)
  const [showConfirmReactivateModal, setConfirmReactivateModal] = useState<boolean>(false)

  return (
    <>
      <div className="flex flex-row items-center h-10">
        {refund.status === RefundStatus.CREATED ? (
          <Button
            variant="outline"
            size="sm"
            className="text-red-600 border-red-600 hover:bg-red-50"
            onClick={() => setConfirmCancelModal(true)}
          >
            Cancelar
          </Button>
        ) : null}
      </div>

      <ConfirmCancelRefundRequestModal
        refundId={refund.id}
        open={showConfirmCancelModal}
        onClose={() => setConfirmCancelModal(false)}
        onSuccess={onSuccess}
      />

      <ConfirmReactivateRefundRequestModal
        refundId={refund.id}
        open={showConfirmReactivateModal}
        onClose={() => setConfirmReactivateModal(false)}
        onSuccess={onSuccess}
      />
    </>
  )
}

export default RefundActions
