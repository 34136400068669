import React from 'react'
import { Input, Label } from '@hub-la/shadcn'

type Props = {
  pixKey: string
}

export const PixKeyForm: React.FC<Props> = ({ pixKey }) => {
  /** The string includes ponctuations */
  const isCNPJ = pixKey.length > 14

  return (
    <div className="space-y-4">
      <div className="space-y-2">
        <Label htmlFor="identity.document.value">{isCNPJ ? 'CNPJ' : 'CPF'}</Label>
        <Input id="identity.document.value" disabled={true} value={pixKey} className="w-full" />
      </div>
    </div>
  )
}
