import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import Cookies from 'js-cookie'
import { QueryKey } from '../../domain/dtos/query-keys'
import { Envs } from '../../envs'
import { SubmitAccountVerification } from '../../usecases/submit-account-verification'
import { Values } from '../pages/account-verification-modal'

export const useSubmitAccountVerification = () => {
  const container = useContainer()
  const queryClient = useQueryClient()

  return useMutation(
    [QueryKey.submitAccountVerification],
    (input: Values) => new SubmitAccountVerification(container.get(HttpClient)).execute(input),
    {
      retry: false,
      onSuccess: () => {
        Cookies.set('show-success-banner', 'true', { sameSite: 'lax', expires: 30, domain: Envs.COOKIE_DOMAIN })
        queryClient.invalidateQueries({ queryKey: ['getIdentity'] })
      },
    },
  )
}
