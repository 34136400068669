import { inject } from 'inversify'
import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { ListEventsInput } from '../domain/dtos/list-events-input'
import { ListEventsResponse } from '../domain/dtos/list-products-response'
import { Envs } from '../envs'
import { GeneralError } from '../domain/errors/general'

export class ListEventsUsecase {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(input: ListEventsInput): Promise<ListEventsResponse> {
    const response = await this.httpClient.request({
      method: HttpMethod.GET,
      url: `${Envs.BFF_PRODUCT_URL}/products?type=event&timeScope=${input.timeScope}`,
      params: {
        page: input.page,
        pageSize: input.pageSize,
      },
    })

    if ([HttpStatusCode.OK, HttpStatusCode.CREATED].includes(response.statusCode)) {
      return response.data
    }

    throw new GeneralError()
  }
}
