export enum InvoiceStatus {
  UNPAID = 'pending',
  PAID = 'paid',
  EXPIRED = 'expired',
  RETURNED = 'refunded',
  DISPUTED = 'in_protest',
  CHARGEBACK = 'chargeback',
  DRAFT = 'draft',
  CANCELED = 'canceled',
}
