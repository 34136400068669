import { Card, CardContent, CardHeader, CardTitle, Separator, Skeleton } from '@hub-la/shadcn'

export const DescriptionLoading = () => {
  return (
    <Card className="w-full h-full">
      <CardHeader>
        <CardTitle>
          <Skeleton className="h-7 w-32" />
        </CardTitle>
      </CardHeader>

      <CardContent className="flex flex-col gap-6">
        <div className="flex flex-col gap-2">
          <div className="w-full flex justify-between items-center">
            <Skeleton className="h-5 w-1/3" />

            <Skeleton className="h-5 w-1/4" />
          </div>

          <div className="w-full">
            <div className="flex justify-between items-center py-2">
              <Skeleton className="h-5 w-1/3" />

              <Skeleton className="h-5 w-1/4" />
            </div>

            <Separator className="my-2" />
          </div>

          <div className="w-full flex justify-between items-center">
            <Skeleton className="h-5 w-32" />
            <Skeleton className="h-5 w-1/4" />
          </div>
        </div>
      </CardContent>
    </Card>
  )
}
