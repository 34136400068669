import moment from 'moment-timezone'
import { Period } from '../domain/dtos/period'
import { PeriodToMoment } from '../domain/dtos/period-to-moment'

type SelectPeriod = { id: string; selected: boolean }
export class GetPeriods {
  public execute(period: Period, start: string, end: string): SelectPeriod[] {
    const periods: SelectPeriod[] = []
    const listPeriods = Object.keys(Period)

    for (const current of listPeriods) {
      const currentPeriod = Period[current]
      if (currentPeriod === Period.QUARTERLY || currentPeriod === Period.WEEKLY) continue

      const startDate = moment(start).startOf(PeriodToMoment[currentPeriod])
      const endDate = moment(end).endOf(PeriodToMoment[currentPeriod])
      const diff = Math.ceil(endDate.diff(startDate, PeriodToMoment[currentPeriod], true))

      if (diff > 2 && diff < 150) {
        periods.push({
          id: currentPeriod,
          selected: currentPeriod === period,
        })
      }
    }

    if (periods.length && periods.filter((period) => period.selected).length === 0) {
      periods[0].selected = true
    }
    return periods
  }
}
