import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useToast } from '@hub-la/shadcn'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { ListEventsInput } from '../../domain/dtos/list-events-input'
import { QueryKey } from '../../domain/enums/query-key'
import { ListEventsUsecase } from '../../usecases/list-events.usecase'

export const useListEvents = (input: ListEventsInput) => {
  const { toast } = useToast()
  const { t } = useTranslation()
  const container = useContainer()

  return useQuery(
    [QueryKey.listEvents, input.page, input.pageSize],
    () => new ListEventsUsecase(container.get(HttpClient)).execute(input),
    {
      retry: false,
      refetchOnWindowFocus: false,
      onError: (error: Error) => {
        toast({ description: t(error.message), variant: 'destructive' })
      },
    },
  )
}
