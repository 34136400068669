import React from 'react'
import { Label, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@hub-la/shadcn'
import { Controller, useFormContext } from 'react-hook-form'

interface TimePickerProps {
  name: string
}

export const TimePicker: React.FC<TimePickerProps> = ({ name }) => {
  const { control } = useFormContext()

  return (
    <div className="space-y-4">
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          const [hours = '', minutes = '', seconds = ''] = field.value ? field.value.split(':') : ['', '', '']

          const updateTime = (newHours: string, newMinutes: string, newSeconds: string) => {
            const formattedTime = `${newHours.padStart(2, '0')}:${newMinutes.padStart(2, '0')}:${newSeconds.padStart(
              2,
              '0',
            )}`
            field.onChange(formattedTime)
          }

          return (
            <div className="flex space-x-2">
              <div className="w-[90px]">
                <Label>Horas</Label>
                <Select onValueChange={(value) => updateTime(value, minutes, seconds)} value={hours}>
                  <SelectTrigger>
                    <SelectValue placeholder="hh">{hours || 'hh'}</SelectValue>
                  </SelectTrigger>
                  <SelectContent>
                    {Array.from({ length: 24 }, (_, i) => (
                      <SelectItem key={i} value={i.toString().padStart(2, '0')}>
                        {i.toString().padStart(2, '0')}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>

              <div className="w-[90px]">
                <Label>Minutos</Label>
                <Select onValueChange={(value) => updateTime(hours, value, seconds)} value={minutes}>
                  <SelectTrigger>
                    <SelectValue placeholder="mm">{minutes || 'mm'}</SelectValue>
                  </SelectTrigger>
                  <SelectContent>
                    {Array.from({ length: 60 }, (_, i) => (
                      <SelectItem key={i} value={i.toString().padStart(2, '0')}>
                        {i.toString().padStart(2, '0')}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>

              <div className="w-[90px]">
                <Label>Segundos</Label>
                <Select onValueChange={(value) => updateTime(hours, minutes, value)} value={seconds}>
                  <SelectTrigger>
                    <SelectValue placeholder="ss">{seconds || 'ss'}</SelectValue>
                  </SelectTrigger>
                  <SelectContent>
                    {Array.from({ length: 60 }, (_, i) => (
                      <SelectItem key={i} value={i.toString().padStart(2, '0')}>
                        {i.toString().padStart(2, '0')}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
            </div>
          )
        }}
      />
    </div>
  )
}
