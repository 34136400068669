import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useToast } from '@hub-la/shadcn'
import { useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { QueryKey } from '../../../domain/enums/query-key'
import { RequestCancellation } from '../../../usecases/settings/request-cancellation'

export const useRequestCancellation = () => {
  const container = useContainer()
  const { toast } = useToast()
  const { t } = useTranslation()

  return useMutation(
    [QueryKey.requestCancellation],
    (receiverId: string) => new RequestCancellation(container.get(HttpClient)).execute(receiverId),
    {
      retry: false,
      onError: (error: Error) => toast({ description: t(error.message), variant: 'destructive' }),
    },
  )
}
