import { cn, Command, CommandGroup, CommandInput, CommandItem, CommandList, useIsMobile } from '@hub-la/shadcn'
import { ChevronRight, Loader2 } from 'lucide-react'
import React, { useCallback, useRef, useState } from 'react'
import { getGroupUrl } from '../../../../utils/sessions-utils'
import { textEllipsis } from '../../../../utils/text-ellipsis'
import { useListMemberGroups } from '../../../hooks/use-list-member-groups'

const Autocomplete: React.FC = () => {
  const inputRef = useRef<HTMLInputElement>(null)

  const { data = [], isFetching } = useListMemberGroups()
  const isMobile = useIsMobile()

  const [open, setOpen] = useState(false)

  const handleKeyDown = useCallback(() => {
    if (!open) {
      setOpen(true)
    }
  }, [open])

  const options = data.map((option) => ({
    id: option.id,
    label: option.name,
    picture: option.pictureUrl,
  }))

  const handleBlur = useCallback(() => {
    setOpen(false)
  }, [])

  const redirectToGroupFeed = (id: string) => window.location.assign(getGroupUrl(id))

  return (
    <Command onKeyDown={handleKeyDown} onBlur={handleBlur} className="overflow-visible w-full max-w-[400px]">
      <div className="flex w-full items-center rounded-lg border border-border bg-secondary text-sm ">
        <CommandInput
          ref={inputRef}
          placeholder="Buscar"
          className="w-full p-3 h-9 rounded-lg outline-none bg-secondary text-secondary-foreground placeholder:text-muted-foreground"
        />
      </div>
      <div className={cn('relative animate-in fade-in-0 zoom-in-95 h-auto', open ? 'block' : 'hidden')}>
        <CommandList>
          <div className="absolute mt-2 z-50 w-full">
            <CommandGroup className="relative h-auto z-50 min-w-[8rem] overflow-hidden rounded-md border border-border shadow-md bg-secondary">
              {isFetching ? (
                <div className="h-28 flex items-center justify-center">
                  <Loader2 className="w-6 h-6 animate-spin text-primary" />
                </div>
              ) : (
                <>
                  {options.map((option) => (
                    <CommandItem
                      key={option.id}
                      value={option.label}
                      onMouseDown={(event) => {
                        event.preventDefault()
                        event.stopPropagation()
                      }}
                      onSelect={() => redirectToGroupFeed(option.id)}
                      className="flex select-text flex-row  items-center cursor-pointer gap-2 h-max p-2 px-3 rounded-md"
                    >
                      <img
                        src={option.picture}
                        alt={option.label}
                        className="w-[70px] h-[46px] rounded-lg object-cover"
                      />
                      <p className="flex-1 text-sm ">{isMobile ? textEllipsis(option.label, 20) : option.label}</p>
                      <ChevronRight className="w-4 h-4 " />
                    </CommandItem>
                  ))}
                </>
              )}
            </CommandGroup>
          </div>
        </CommandList>
      </div>
    </Command>
  )
}

export default Autocomplete
