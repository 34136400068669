import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation } from '@tanstack/react-query'
import { QueryKey } from '../../domain/enums/query-key.enum'
import { PutCohort } from '../../usecases/put-cohort.usecase'
import { CohortSchemaValidationType } from '../validations/cohort-validation'

export const usePutCohort = ({
  productId,
  cohortId,
  onSuccess,
}: {
  productId: string
  cohortId: string
  onSuccess?: () => void
}) => {
  const container = useContainer()

  return useMutation(
    [QueryKey.putCohort],
    (input: CohortSchemaValidationType) =>
      new PutCohort(container.get(HttpClient)).execute({
        ...input,
        cohortId,
        productId,
      }),
    {
      retry: false,
      onSuccess,
    },
  )
}
