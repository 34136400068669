import { useAuth } from '@hub-la/fe-auth'
import { useContainer } from '@hub-la/fe-container'
import { Analytics, AnalyticsEvent } from '@hub-la/fe-core-analytics'
import { Button } from '@hub-la/shadcn'
import { Loader2 } from 'lucide-react'
import QRCode from 'qrcode.react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { makeTrackingEventData } from '../../../domain/mappers/make-tracking-event-data'
import { Envs } from '../../../envs'
import { LinkHelperFAQ } from '../../components/link-helper-faq'
import { NavigationButton } from '../../components/navigation-button'
import { NotGroupMember } from '../../components/not-group-member'
import { useGetTelegramCode } from '../../hooks/use-get-telegram-code'
import { useGetUserVerification } from '../../hooks/use-get-user-verification'

export const VerificationQRCodePage = (): JSX.Element => {
  const { t } = useTranslation()
  const history = useHistory()
  const container = useContainer()
  const analytics = container.get(Analytics)

  const { currentUser } = useAuth()
  const { data: user, isFetching: isUserLoading } = useGetUserVerification(currentUser?.id)
  const { data: telegramCode } = useGetTelegramCode()
  const step = user?.steps?.verificationTelegram
  const urlSearch = useLocation().search
  const groupId = new URLSearchParams(urlSearch).get('groupId')

  const url = telegramCode ? `https://t.me/${Envs.TELEGRAM_BOT}?start=${telegramCode}` : undefined

  const trackingParams = {
    groupId: groupId,
    userId: user?.id,
    userEmail: user?.email ?? undefined,
    telegramId: user?.ims?.telegram ?? undefined,
    whatsappId: user?.ims?.whatsapp ?? undefined,
  }

  console.log({ isUserLoading, user })

  useEffect(() => {
    if (!isUserLoading && user && !step) {
      history.push({
        pathname: '/verification-telegram/intro',
        search: urlSearch,
      })
    }
  }, [user, isUserLoading, step, history, urlSearch])

  const handleClickBack = () => {
    analytics.track(AnalyticsEvent.VERIFICATION_TELEGRAM.QRCODE.BACK_CLICKED, makeTrackingEventData(trackingParams))
    history.push({
      pathname: '/verification-telegram/start',
      search: urlSearch,
    })
  }

  const handleClickNav = () => {
    analytics.track(AnalyticsEvent.VERIFICATION_TELEGRAM.QRCODE.NAV_BACK_CLICKED, makeTrackingEventData(trackingParams))
    history.goBack()
  }

  const handleClickFAQ = () => {
    analytics.track(AnalyticsEvent.VERIFICATION_TELEGRAM.QRCODE.FAQ_CLICKED, makeTrackingEventData(trackingParams))
  }

  if (isUserLoading || !user) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Loader2 className="h-12 w-12 animate-spin" />
      </div>
    )
  }

  return (
    <div className="flex flex-col flex-1 h-screen" data-testid="verification-qrcode-container">
      <div className="flex flex-row flex-1">
        <div className="flex flex-col flex-1 w-1/2 p-3 bg-background">
          <div className="flex flex-col items-start justify-center">
            <NavigationButton onClick={handleClickNav} />
          </div>
          <div className="flex flex-col flex-1 items-center justify-center">
            <div className="flex flex-col flex-1 space-y-3 justify-center w-[400px]">
              <h1 className="text-left text-4xl text-[#161F00] font-bold">{t('verificationTelegram.qrcode.title')}</h1>
              <p className="text-left text-[#747968]">{t('verificationTelegram.qrcode.subtitle')}</p>
              <div className="flex flex-col space-y-2 items-start justify-center">
                <Button className="w-full" variant="outline" size="lg" onClick={handleClickBack}>
                  {t('verificationTelegram.qrcode.buttonBack')}
                </Button>
                <LinkHelperFAQ onClick={handleClickFAQ} />
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row flex-1 w-1/2 bg-[#F7F8F2]">
          <div className="flex flex-col flex-1 items-center justify-center">
            {url ? (
              <>
                <div className="flex flex-col justify-center rounded p-4 border-4 border-[#58730024]">
                  <QRCode value={url} size={306} bgColor="#F7F8F2" />
                </div>
                <p className="text-sm text-[#747968] font-medium mt-2">{t('verificationTelegram.qrcode.textHelper')}</p>
              </>
            ) : (
              <p className="text-sm text-[#747968] font-medium">{t('verificationTelegram.qrcode.noCodeAvailable')}</p>
            )}
          </div>
        </div>
      </div>
      {groupId && <NotGroupMember groupId={groupId} />}
    </div>
  )
}

export default VerificationQRCodePage
