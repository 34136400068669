import { CreditCard } from 'lucide-react'
import { TFunction, useTranslation } from 'react-i18next'
import { CardDetail } from '../../domain/dtos/card-detail'
import { CardBrand } from '../../domain/enums/card-brand'
import amex from '../assets/amex.png'
import elo from '../assets/elo.png'
import mastercard from '../assets/mastercard.png'
import visa from '../assets/visa.png'

const CardIcon: React.FC<{ src: string; alt: string }> = ({ src, alt }) => (
  <img src={src} alt={alt} className="w-[16px] h-auto object-contain object-center" />
)

const getIcon = (cardBrand: CardBrand) => {
  switch (cardBrand) {
    case CardBrand.CARD_BRAND_VISA:
      return <CardIcon src={visa} alt="Visa" />
    case CardBrand.CARD_BRAND_MASTERCARD:
      return <CardIcon src={mastercard} alt="Mastercard" />
    case CardBrand.CARD_BRAND_AMEX:
      return <CardIcon src={amex} alt="American Express" />
    case CardBrand.CARD_BRAND_ELO:
      return <CardIcon src={elo} alt="Elo" />
    default:
      return <CreditCard className="w-4 h-4" />
  }
}
const getText = (t: TFunction<'translation', undefined>, cardDetails?: CardDetail) => {
  return cardDetails ? `${t(`cardBrand.${cardDetails?.brand}`)} •••• ${cardDetails?.last4}` : 'Cartão de crédito'
}

export const CreditCardLabel = ({ cardDetails }: { cardDetails: CardDetail }) => {
  const { t } = useTranslation()

  return (
    <div className="flex items-center gap-2 text-muted-foreground">
      {getIcon(cardDetails?.brand)}
      <p className="text-sm">{getText(t, cardDetails)}</p>
    </div>
  )
}
