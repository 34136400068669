/**
 * Note: most *Clicked events happens when the user clicks on the "Continuar"
 * button. This button is enabled when the UI is in a valid state (for more
 * details, take a look at the `CanStep` component), so these events are only
 * registered when the UI was without frontend validation errors.
 */
export const AnalyticsEvent = {
  CHECKOUT: {
    VIEWED: 'CheckoutPaymentViewed',
    SUBMITTED: 'CheckoutPurchaseSubmitted',
    SUCCESSFUL: 'CheckoutPurchaseSuccessful',
    FAILED: 'CheckoutPurchaseFailed',
    REJECTED: 'CheckoutPurchaseRejected',

    INSERT_COUPON_SUCCESS: 'CheckoutInsertCouponSuccess',
    INSERT_COUPON_FAILURE: 'CheckoutInsertCouponFailure',

    SUBSCRIPTION_PLAN_VIEWED: 'CheckoutSubscriptionPlanViewed',
    SUBSCRIPTION_PLAN_CHANGE_CLICKED: 'CheckoutSubscriptionPlanChangeClicked',
    SUBSCRIPTION_PLAN_CLICKED: 'CheckoutSubscriptionPlanClicked',

    PAYMENT_METHOD_VIEWED: 'CheckoutPaymentMethodViewed',
    // Change means choosing another payment method, while edit means editting
    // the details of the current payment method.
    PAYMENT_METHOD_CHANGE_CLICKED: 'CheckoutPaymentMethodChangeClicked',
    PAYMENT_METHOD_EDIT_CLICKED: 'CheckoutPaymentMethodEditClicked',
    PAYMENT_METHOD_CLICKED: 'CheckoutPaymentMethodClicked',

    CREDIT_CARD_DETAILS_VIEWED: 'CheckoutCreditCardDetailsViewed',
    CREDIT_CARD_DETAILS_CLICKED: 'CheckoutCreditCardDetailsClicked',

    PERSONAL_INFO_VIEWED: 'CheckoutPersonalInfoViewed',
    PERSONAL_INFO_EDIT_CLICKED: 'CheckoutPersonalInfoEditClicked',
    PERSONAL_INFO_CLICKED: 'CheckoutPersonalInfoClicked',

    LOGIN_VIEWED: 'CheckoutLoginViewed',
    LOGIN_SEND_CODE_CLICKED: 'CheckoutLoginSendCodeClicked',

    CONFIRMATION_VIEWED: 'CheckoutConfirmationViewed',
    BUY_CLICKED: 'CheckoutBuyClicked',

    UPSELL_VIEWED: 'CheckoutUpsellViewed',
    UPSELL_PLAN_SELECTED: 'UpsellPlanSelected',
    UPSELL_NEXT_CLICKED: 'CheckoutUpsellNextClicked',
    UPSELL_CANCEL_CLICKED: 'CheckoutUpsellCancelClicked',
    UPSELL_INSTALLMENTS_VIWED: 'CheckoutUpsellInstallmentsViwed',
    UPSELL_SUBMITTED: 'CheckoutUpsellSubmitted',
    UPSELL_INSTALLMENTS_CANCEL_CLICKED: 'CheckoutUpsellInstallmentsCancelClicked',

    ORDER_BUMP_ADD_CLICKED: 'CheckoutOrderBumpAddClicked',

    WITHOUT_LOGIN: {
      USER_WITH_MULTIPLE_ACCOUNTS: 'CheckoutWithoutLoginUserWithMultipleAccounts',
    },
  },
  VERIFICATION_TELEGRAM: {
    INTRO: {
      VIEWED: 'VerificationTelegramIntroViewed',
      START_CLICKED: 'VerificationTelegramIntroStartClicked',
      FAQ_CLICKED: 'VerificationTelegramIntroFAQClicked',
      NAV_BACK_CLICKED: 'VerificationTelegramIntroNavBackClicked',
    },
    START: {
      VIEWED: 'VerificationTelegramStartViewed',
      WEB_CLICKED: 'VerificationTelegramStartWebClicked',
      QRCODE_CLICKED: 'VerificationTelegramStartQRCodeClicked',
      FAQ_CLICKED: 'VerificationTelegramStartFAQClicked',
      NAV_BACK_CLICKED: 'VerificationTelegramStartNavBackClicked',
    },
    QRCODE: {
      VIEWED: 'VerificationTelegramQRCodeViewed',
      BACK_CLICKED: 'VerificationTelegramQRCodeBackClicked',
      FAQ_CLICKED: 'VerificationTelegramQRCodeFAQClicked',
      NAV_BACK_CLICKED: 'VerificationTelegramQRCodeNavBackClicked',
    },
    DONE: {
      VIEWED: 'VerificationTelegramDoneViewed',
      COMPLETED_CLICKED: 'VerificationTelegramDoneCompletedClicked',
      NAV_CLOSE_CLICKED: 'VerificationTelegramDoneNavCloseClicked',
    },
    MODAL_NOT_MEMBER: {
      CLICKED: 'VerificationTelegramNotMemberClicked',
    },
  },
  CHANGE_PAYMENT_METHOD: {
    SUBMITTED: 'MemberDashboardChangePaymentMethodSubmitted',
    SUCCESSFUL: 'MemberDashboardChangePaymentMethodSuccesful',
    REJECTED: 'MemberDashboardChangePaymentMethodRejected',
    VIEWED: 'MemberDashboardChangePaymentMethodViewed',
  },
  CHANGE_PLAN: {
    SUBMITTED: 'MemberDashboardChangePlanSubmitted',
    SUCCESSFUL: 'MemberDashboardChangePlanSuccesful',
    REJECTED: 'MemberDashboardChangePlanRejected',
    VIEWED: 'SubscriptionPlanIntervalViewed',
  },
  HUB: {
    VIEWED: 'MyContentPageViewed',
    SUBMITTED: 'ContentPostSubmitted',
    SUCCESSFUL: 'ContentPostSubmittedSucessFull',
    FAILED: 'ContentPostSubmittedSucessFailed',
    REJECTED: 'ContentPageErrorViewed',
    CANCELED: 'ContentPageCancelDialogClicked',
    REMOVED: 'ContentPageRemoveDialogClicked',
    ALERT: 'ContentPageAlertViewed',
    CANCEL_DIALOG: 'ContentPageCancelDialogClicked',
  },
  MAIN_MENU: {
    PROFILE_CLICKED: 'WebMainMenuProfileClicked',
    EDIT_PROFILE_CLICKED: 'WebMainMenuEditProfileClicked',
    PRODUCTS_CLICKED: 'WebMainMenuMyProductsClicked',
    PURCHASES_CLICKED: 'WebMainMenuMyPurchasesClicked',
    VERIFICATION_ACCOUNT_CLICKED: 'WebMainMenuVerificationAccountClicked',
    BANK_ACCOUNT_CLICKED: 'WebMainMenuBankAccountClicked',
    AFFILIATES_CLICKED: 'WebMainMenuAffiliateSalesClicked',
    SUPPORT_CLICKED: 'WebMainMenuFAQClicked',
    BLOG_CLICKED: 'WebMainMenuBlogClicked',
    GUIDE_CLICKED: 'WebMainMenuGuideClicked',
    LOGOUT_CLICKED: 'WebMainMenuLogoutClicked',
    COLLABORATORS_CLICKED: 'WebMainMenuCollaboratorsClicked',
    ROLEPLAY_CLICKED: 'WebMainMenuRoleplayClicked',
  },
  ANALYTICS: {
    GENERAL_FILTER_PRODUCT_CLICKED: 'GeneralFilterProductClicked',
    GENERAL_FILTER_COMPARASION_CLICKED: 'GeneralFilterComparisonClicked',
    GROWTH: {
      VIEWED: 'ReportGrowthViewed',
      TOOLTIP_ACTIVE_SUBSCRIBERS_GRAPHIC_CLICKED: 'ToolTipActiveSubscribersGraphicClicked',
      HOVER_ACTIVE_SUBSCRIBERS_GRAPHIC_CLICKED: 'HoverActiveSubscribersGraphicClicked',
      HOVER_SUBTITLE_ACTIVE_SUBSCRIBERS_GRAPHIC_CLICKED: 'HoverSubtitleActiveSubscribersGraphicClicked',
      TOOLTIP_GROSS_REVENUE_CLICKED: 'ToolTipGrossRevenueClicked',
      SEGMENT_GROSS_REVENUE_CLICKED: 'SegmentGrossRevenueClicked',
      HOVER_GRAPHIC_GROSS_REVENUE_CLICKED: 'HoverGraphicGrossRevenueClicked',
      HOVER_SUBTITLE_GROSS_REVENUE_CLICKED: 'HoverSubtitleGrossRevenueClicked',
      METRIC_GROWTH_ANALYSIS_TABLE_CLICKED: 'MetricGrowthAnalysisTableClicked',
      SEGMENT_METRIC_GROWTH_ANALYSIS_TABLE_CLICKED: 'SegmentMetricGrowthAnalysisTableClicked',
      VISUALIZATION_GROWTH_ANALYSIS_METRICS_CLICKED: 'VisualizationGrowthAnalysisMetricsClicked',
      HOVER_GRAPHIC_GROWTH_ANALYSIS_CLICKED: 'HoverGraphicGrowthAnalysisClicked',
      HOVER_SUBTITLE_GROWTH_ANALYSIS_CLICKED: 'HoverSubtitleGrowthAnalysisClicked',
      TOOLTIP_NEW_MEMBERS_GRAPHIC_CLICKED: 'ToolTipNewMembersGraphicClicked',
      HOVER_NEW_MEMBERS_GRAPHIC_CLICKED: 'HoverNewMembersGraphicClicked',
      HOVER_SUBTITLE_NEW_MEMBERS_GRAPHIC_CLICKED: 'HoverSubtitleNewMembersGraphicClicked',
      TOOTLIP_RECURRING_REVENUE_GRAPHIC_CLICKED: 'ToolTipRecurringRevenueGraphicClicked',
      HOVER_GRAPHIC_RECURRING_REVENUE_CLICKED: 'HoverGraphicRecurringRevenueClicked',
      HOVER_SUBTITLE_RECURRING_REVENUE_GRAPHIC_CLICKED: 'HoverSubtitleRecurringRevenueGraphicClicked',
    },
    RETENTION: {
      VIEWED: 'ReportRetentionViewed',
      DOWNLOAD_SUBSCRIBERS_LOST_CLICKED: 'DownloadSubscribersLostClicked',
      CONTACT_SUBSCRIBERS_LOST_CLICKED: 'ContactSubscribersLostClicked',
      DOWNLOAD_TOP_SUBSCRIBERS_CLICKED: 'DownloadTopSubscribersClicked',
      CONTACT_TOP_SUBSCRIBERS_CLICKED: 'ContactTopSubscribersClicked',
      VISUALIZATION_CHURN_TABLE_CLICKED: 'VisualizationChurnTableClicked',
      SEGMENT_CHURN_TABLE_CLICKED: 'SegmentChurnTableClicked',
      TOOLTIP_CHURN_RATE_CLICKED: 'ToolTipChurnRateClicked',
      SEGMENT_CHURN_RATE_CLICKED: 'SegmentChurnRateClicked',
      HOVER_GRAPHIC_CHURN_RATE_CLICKED: 'HoverGraphicChurnRateClicked',
      HOVER_SUBTITLE_CHURN_RATE_CLICKED: 'HoverSubtitleChurnRateClicked',
      TOOLTIP_REVENUE_LOSTPER_CHURN_CLICKED: 'ToolTipRevenueLostperChurnClicked',
      SEGMENT_REVENUE_POSTPER_CHURN_CLICKED: 'SegmentRevenueLostperChurnClicked',
      HOVER_GRAPHIC_REVENUE_LOSTPER_CHURN_CLICKED: 'HoverGraphicRevenueLostperChurnClicked',
      HOVER_SUBTITLE_REVENUE_LOSTPER_CHURN_CLICKED: 'HoverSubtitleRevenueLostperChurnClicked',
    },
  },
  SCHEDULED_COMMUNICATION: {
    CHANGE_PAYMENT_METHOD: {
      VIEWED: {
        WHATSAPP: 'PaymentChangePageViewedThroughWhatsappLink',
        EMAIL: 'PaymentChangePageViewedThroughEmailLink',
        UNKNOWN: 'PaymentChangePageViewedThroughUnknownLink',
      },
      SUBMITTED: {
        WHATSAPP: 'PaymentChangePageSubmittedThroughWhatsappLink',
        EMAIL: 'PaymentChangePageSubmittedThroughEmailLink',
        UNKNOWN: 'PaymentChangePageSubmittedThroughUnknownLink',
      },
    },
  },
  WALLET: {
    REQUEST_WITHDRAWAL_POPUP_CLICKED: 'WalletRequestWithdrawalPopupClicked',
    REQUEST_WITHDRAWAL_CLICKED: 'WalletRequestWithdrawalClicked',
    MOVEMENT_CLICKED: 'WalletMovementClicked',
    TAB_CLICKED: 'WalletTabClicked',
    EXPORT_POPUP_CLICKED: 'WalletExportPopupClicked',
    EXPORT_CLICKED: 'WalletExportClicked',
    INTERVAL_CHANGED: 'WalletIntervalChanged',
    TRANSACTION_TYPE_FILTER: 'WalletTransactionTypeFilter',
    VIEWED: 'WalletViewed',
  },
  LOGIN_PREFERENCES: {
    NAVIGATION_TARGET: {
      CREATOR_CLICKED: 'LoginPreferencesNavigationTargetCreatorClicked',
      BUYER_CLICKED: 'LoginPreferencesNavigationTargetBuyerClicked',
      CHANGE_TO_CREATOR_CLICKED: 'LoginPreferencesNavigationTargetChangeToCreatorClicked',
      CHANGE_TO_BUYER_CLICKED: 'LoginPreferencesNavigationTargetChangeToBuyerClicked',
    },
  },
  PUBLIC_INVOICE: {
    VIEWED: 'PublicInvoiceViewed',
    PAYMENT_SUBMITTED: 'PublicInvoicePaymentSubmitted',
    PAYMENT_ERROR: 'PublicInvoicePaymentError',
  },
  AUTHENTICATION: {
    SUCCESSFUL: 'AuthLoginSuccessful',
    LOGIN_WITH_LINK_SUCCESSFUL: 'AuthLoginWithLinkSuccessful',
  },
}
