import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { CreateIntegrationInput } from '../domain/dtos/create-integration-input'
import { CreateIntegrationOutput } from '../domain/dtos/create-integration-output'
import { mapBackendProvider } from '../domain/dtos/integration-provider'
import { AlreadyExists } from '../domain/errors/already-exists'
import { GeneralError } from '../domain/errors/general'
import { InvalidField } from '../domain/errors/invalid-field'
import { NotImplemented } from '../domain/errors/not-implemented'
import { UnauthorizedIntegration } from '../domain/errors/unauthorized-integration'
import { UnknownIntegration } from '../domain/errors/unknown-integration'
import { Envs } from '../envs'

enum ErrorCode {
  ALREADY_EXISTS = 'integration::already-exists',
  NOT_IMPLEMENTED = 'not-implemented',
  INVALID_FIELD = 'validator::invalid-field',
  ACTIVE_UNKNOWN_INTEGRATION = 'vendor::active-campaign::unkwown',
  ACTIVE_UNAUTHORIZED_INTEGRATION = 'vendor::active-campaign::unauthorized',
  ENOTAS_UNKNOWN_INTEGRATION = 'vendor::enotas::unkwown',
  ENOTAS_UNAUTHORIZED_INTEGRATION = 'vendor::enotas::unauthorized',
}

type CreateIntegrationError = {
  statusCode: number
  timestamp: string
  path: string
  error: string
  errorCode: string
  errorMetadata: { [key: string]: unknown }
  errorMessage: string
}

export class CreateIntegration {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient<CreateIntegrationOutput & CreateIntegrationError>,
  ) {}

  public async execute(input: CreateIntegrationInput): Promise<CreateIntegrationOutput> {
    const response = await this.httpClient.request({
      method: HttpMethod.POST,
      url: `${Envs.BFF_WEB_URL}/integrations`,
      body: { ...input, provider: mapBackendProvider[input.provider] },
    })

    if (response.statusCode === HttpStatusCode.CREATED && response?.data) {
      return response?.data
    }

    switch (response?.data?.errorCode) {
      case ErrorCode.INVALID_FIELD:
        throw new InvalidField(this.getErrorInput(response.data?.errorMetadata))
      case ErrorCode.ACTIVE_UNAUTHORIZED_INTEGRATION:
        throw new UnauthorizedIntegration()
      case ErrorCode.ENOTAS_UNAUTHORIZED_INTEGRATION:
        throw new UnauthorizedIntegration()
      case ErrorCode.ACTIVE_UNKNOWN_INTEGRATION:
        throw new UnknownIntegration()
      case ErrorCode.ALREADY_EXISTS:
        throw new AlreadyExists()
      case ErrorCode.NOT_IMPLEMENTED:
        throw new NotImplemented()
      default:
        throw new GeneralError()
    }
  }

  getErrorInput(errorMeta: object) {
    const inputsValue = {
      createActiveCampaignIntegrationRequestDtoUrl: 'url',
      createActiveCampaignIntegrationRequestDtoApiKey: 'apiKey',
    }

    const errorKey = Object.keys(errorMeta)[0] ?? ''
    return inputsValue[errorKey]
  }
}
