export enum QueryKey {
  getOffers = 'getOffers',
  getRevenue = 'getRevenue',
  getTotalSale = 'getTotalSale',
  getTotalRefund = 'getTotalRefund',
  getAbandonedCheckout = 'getAbandonedCheckout',
  getConversionRate = 'getConversionRate',
  getAverageTicket = 'getAverageTicket',
  downloadCsv = 'downloadCsv',
}
