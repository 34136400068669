import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useQuery } from '@tanstack/react-query'
import { GetInvoicesSummariesInput } from '../../domain/dtos/get-invoices-summaries-input'
import { QueryKey } from '../../domain/enums/query-keys'
import { GetInvoicesSummaries } from '../../usecases/get-invoices-summaries'

export const useGetInvoicesSummaries = (input: GetInvoicesSummariesInput, isLoading: boolean) => {
  const container = useContainer()

  const offerIds = input.offers.filter(({ id }) => id !== 'all').map(({ id }) => id)
  const enabled = !isLoading && offerIds.length > 0
  const initialData = {
    count: 0,
    netRevenue: 0,
    sumPaid: 0,
  }

  return useQuery(
    [QueryKey.getInvoicesSummaries],
    ({ signal }) => {
      if (!enabled) {
        return Promise.resolve(initialData)
      }
      return new GetInvoicesSummaries(container.get(HttpClient)).execute(input, signal)
    },
    {
      retry: 3,
      retryOnMount: false,
      refetchOnWindowFocus: false,
      initialData,
      enabled,
    },
  )
}
