import { Skeleton, TableCell, TableRow } from '@hub-la/shadcn'

export const TableLoading = () => {
  return (
    <TableRow className="cursor-pointer">
      <TableCell className="py-2.5">
        <div className="flex items-center space-x-1">
          <Skeleton className="w-5 h-5 rounded-full" />
          <Skeleton className="w-20 h-5 rounded" />
        </div>
      </TableCell>

      <TableCell className="py-2.5 min-w-[200px]">
        <div className="flex items-center gap-1">
          <Skeleton className="w-16 h-5 rounded" />
          <Skeleton className="w-10 h-5 rounded" />
        </div>
      </TableCell>

      <TableCell className="py-2.5 max-w-[140px]">
        <Skeleton className="w-24 h-6 rounded" />
      </TableCell>

      <TableCell className="py-2.5 max-w-[200px] truncate">
        <Skeleton className="w-36 h-5 rounded" />
      </TableCell>

      <TableCell className="py-2.5 max-w-[200px] truncate">
        <Skeleton className="w-36 h-5 rounded" />
      </TableCell>

      <TableCell className="py-2.5 max-w-[200px] truncate">
        <Skeleton className="w-24 h-5 rounded" />
      </TableCell>
    </TableRow>
  )
}
