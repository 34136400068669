import { ProductType } from '../domain/enums/product-type'

/**
 * @description This is temporarily, since in the future we gonna deploy the mfe in a different domain.
 */
export class GetDashboardUrl {
  public execute(productType: ProductType = ProductType.DIGITAL): string {
    return productType === ProductType.DIGITAL ? '/dashboard/products' : '/dashboard/events'
  }
}
