import moment from 'moment'
import { Period } from '../enums/period'

export const PeriodToMoment: Record<Period, moment.unitOfTime.DurationConstructor> = {
  [Period.HOURLY]: 'hour',
  [Period.DAILY]: 'day',
  [Period.WEEKLY]: 'week',
  [Period.MONTHLY]: 'month',
  [Period.QUARTERLY]: 'quarter',
  [Period.YEARLY]: 'year',
}
