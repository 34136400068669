import { AvailableCarriers } from '../domain/enums/available-carriers'
import { IuguTokenizer } from './iugu-tokenizer'
import { MultiTokenizer } from './multi-tokenizer'

export interface TokenizerToken {
  tokenId: string
}

export interface TokenizerInterface {
  tokenize(data: {
    number: string
    expiration: string
    holder: string
    cvv: string
    sessionId?: string
    document?: string
  }): Promise<TokenizerToken>
}

export class Tokenizer implements TokenizerInterface {
  private tokenizer: TokenizerInterface

  /**
   * When the carrier is 'yuno', the tokenization is done by the Yuno Secure Fields itself, there's no need to create a Tokenizer.
   * See file `libs/frontend/modules/checkout/src/lib/presentation/hooks/use-yuno-secure-fields.tsx` for an example.
   */
  constructor(carrier: AvailableCarriers) {
    if (carrier === AvailableCarriers.IUGU) {
      this.tokenizer = new IuguTokenizer()
    } else if (carrier === AvailableCarriers.MULTI) {
      this.tokenizer = new MultiTokenizer()
    } else {
      throw new Error('Invalid tokenizer type')
    }
  }

  public async tokenize(data: {
    number: string
    expiration: string
    holder: string
    cvv: string
    sessionId?: string
    document?: string
  }): Promise<TokenizerToken> {
    return this.tokenizer.tokenize(data)
  }
}
