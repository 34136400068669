import { Skeleton } from '@hub-la/shadcn'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { formatCurrency } from '../../../../domain/vos/format-currency'
import { useGetSmartInstallmentCyclesSummarie } from '../../../hooks/use-get-smart-installment-cycles-summarie'

export const Header: React.FC = () => {
  const { t } = useTranslation()

  const { data, isLoading } = useGetSmartInstallmentCyclesSummarie()

  const amount = (data?.amountTotalOverdueCents ?? 0) + (data?.amountTotalDraftCents ?? 0)

  if (isLoading) {
    return <Skeleton className="h-[36px] w-full" />
  }

  return (
    <h1 className="text-xl sm:text-2xl lg:text-3xl font-semibold">
      {t('title', { amount: formatCurrency(amount / 100) })}
    </h1>
  )
}
