import { useContainer } from '@hub-la/fe-container'
import { Analytics } from '@hub-la/fe-core-analytics'
import { Auth } from '@hub-la/fe-core-auth'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '../../domain/dtos/query-keys'
import { AuthenticateAuthLink } from '../../usecases/authenticate-auth-link'

export const useAuthenticateAuthLink = (
  authLinkId: string,
  {
    onSuccess,
    onError,
  }: {
    onSuccess: (data: any) => void
    onError: (error: any) => void
  },
) => {
  const container = useContainer()

  return useQuery(
    [QueryKey.getAuthLink],
    () =>
      new AuthenticateAuthLink(container.get(HttpClient), container.get(Auth), container.get(Analytics)).execute(
        authLinkId,
      ),
    {
      refetchOnWindowFocus: false,
      onError,
      onSuccess,
      retry: false,
    },
  )
}
