import { Badge, Button, Calendar, cn, Popover, PopoverContent, PopoverTrigger, Separator } from '@hub-la/shadcn'
import { CalendarIcon } from 'lucide-react'
import moment from 'moment'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GetPeriods } from '../../usecases/get-periods'
import { GetShortcuts } from '../../usecases/get-shortchuts'

export const SelectDateRangeCalendar = ({ filters, setFilters, isLoading }) => {
  const [calendarOpen, setCalendarOpen] = useState<boolean>(false)
  const { t } = useTranslation()

  const shortcuts = new GetShortcuts().execute()

  const onFilterChange = (newDate?: { to?: Date; from?: Date }) => {
    const startDate = moment(newDate?.from).startOf('day').format('YYYY-MM-DDTHH:mm:ssZ')
    const endDate = moment(newDate?.to).endOf('day').format('YYYY-MM-DDTHH:mm:ssZ')

    const periods = new GetPeriods().execute(filters.period, startDate ?? filters.startDate, endDate ?? filters.endDate)
    const selectedPeriod = periods.find((period) => period.selected)?.id ?? filters.period

    setFilters({ ...filters, period: selectedPeriod, startDate, endDate })
  }

  const getPlaceholder = () => {
    const { startDate, endDate } = filters
    const formattedStartDate = moment(startDate).format('D MMM. YYYY')
    const formattedEndDate = moment(endDate).format('D MMM. YYYY')
    const text = `${formattedStartDate} - ${formattedEndDate}`

    const shortcut = shortcuts.find((it) => it.startDate === startDate && it.endDate === endDate)

    return shortcut ? shortcut.label : text
  }

  const onSelectShortcut = (shortcut) => {
    const newDate = { from: moment(shortcut.startDate).toDate(), to: moment(shortcut.endDate).toDate() }
    onFilterChange(newDate)
    setCalendarOpen(false)
  }

  const hasSelectedShortcut = (shortcut) =>
    shortcut.period === filters.period &&
    shortcut.startDate === filters.startDate &&
    shortcut.endDate === filters.endDate

  return (
    <Popover open={calendarOpen} onOpenChange={setCalendarOpen}>
      <PopoverTrigger asChild>
        <Button variant="outline" disabled={isLoading}>
          <CalendarIcon className="mr-2 h-4 w-4" />
          <span>{t('datepicker.placeholder')}</span>
          <>
            <Separator orientation="vertical" className="mx-2 h-4" />
            <Badge variant="secondary" className="rounded-sm px-1 font-normal">
              {getPlaceholder()}
            </Badge>
          </>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0" align="start">
        <div className="flex grid-cols-3">
          <div className="p-2 col-span-1 flex flex-col border-r">
            {shortcuts.map((shortcut, idx) => (
              <Button
                key={idx}
                variant="ghost"
                size="sm"
                onClick={() => onSelectShortcut(shortcut)}
                className={cn('justify-start', hasSelectedShortcut(shortcut) && 'bg-muted')}
              >
                {shortcut.label}
              </Button>
            ))}
          </div>
          <div className="col-span-2">
            <Calendar
              mode="range"
              selected={{ from: moment(filters.startDate).toDate(), to: moment(filters.endDate).toDate() }}
              defaultMonth={moment(filters.startDate).toDate()}
              numberOfMonths={2}
              onSelect={(selected) => onFilterChange(selected)}
            />
          </div>
        </div>
      </PopoverContent>
    </Popover>
  )
}
