import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '../../domain/enums/query-key'
import { GetPendingInvites } from '../../usecases/get-pending-invites'

export const useGetPendingInvites = () => {
  const container = useContainer()

  return useQuery([QueryKey.getPendingInvites], () => new GetPendingInvites(container.get(HttpClient)).execute(), {
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
    initialData: {
      items: [],
      needRequestUserInfo: false,
    },
  })
}
