import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { PutLikeCommentInput } from '../domain/dtos/put-like-comment-input'
import { GeneralError } from '../domain/errors/general'
import { Envs } from '../envs'

export class PutLikeComment {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient<void>,
  ) {}

  public async execute(input: PutLikeCommentInput): Promise<void> {
    const response = await this.httpClient.request({
      method: HttpMethod.PUT,
      url: `${Envs.BFF_MEMBERS_AREA_URL}/posts/${input.commentId}/comments`, // TODO: change this endpoint
      body: input,
    })

    if (response.statusCode === HttpStatusCode.CREATED || response.statusCode === HttpStatusCode.OK) {
      return
    }

    throw new GeneralError()
  }
}
