import {
  Button,
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
  Label,
  Separator,
} from '@hub-la/shadcn'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { SmartInstallmentRef } from '../../../../domain/dtos/smart-installment-ref'
import { InvoiceStatus } from '../../../../domain/enums/invoice-status'
import { formatCurrency } from '../../../../domain/vos/format-currency'
import { Envs } from '../../../../envs'
import active_order from '../../../assets/active_order.svg'
import inactive_order from '../../../assets/inactive_order.svg'
import returned_order from '../../../assets/returned_order.svg'
import { InvoiceStatusBadge } from '../../../components/invoice-status-badge'
import { SmartInstallmentBadge } from '../../../components/smart-installment-badge'

type Props = {
  invoice: {
    id: string
    status: InvoiceStatus
    createdAt: string
    dueDate: string
    paidAt: string
    total: number
    installment: number
    installments: number
    smartInstallmentRef?: SmartInstallmentRef
  }
  product: {
    name: string
  }
  payer: {
    fullName: string
  }
  seller: {
    fullName: string
  }
}

const getIcon = (invoiceStatus?: InvoiceStatus) => {
  switch (invoiceStatus) {
    case InvoiceStatus.PAID:
    case InvoiceStatus.DISPUTED:
      return <img src={active_order} alt="active_order" className="w-14 h-auto object-contain object-center" />
    case InvoiceStatus.UNPAID:
    case InvoiceStatus.EXPIRED:
    case InvoiceStatus.DRAFT:
    case InvoiceStatus.CANCELED:
      return <img src={inactive_order} alt="inactive_order" className="w-14 h-auto object-contain object-center" />
    case InvoiceStatus.RETURNED:
    case InvoiceStatus.CHARGEBACK:
      return <img src={returned_order} alt="returned_order" className="w-14 h-auto object-contain object-center" />
    default:
      return null
  }
}

export const Header = ({ invoice, product, payer, seller }: Props) => {
  const history = useHistory()
  const { t } = useTranslation()

  const format = (date?: string) => moment(date).format('D MMM. YYYY HH:mm')

  const getDate = (): string => {
    moment.locale('pt-br')

    if ([InvoiceStatus.EXPIRED, InvoiceStatus.UNPAID, InvoiceStatus.DRAFT].includes(invoice.status)) {
      return format(invoice.dueDate)
    } else if ([InvoiceStatus.CANCELED, InvoiceStatus.RETURNED, InvoiceStatus.CHARGEBACK].includes(invoice.status)) {
      return ''
    } else {
      return format(invoice.paidAt)
    }
  }

  const getTotal = () => {
    if (invoice.installments > 1) {
      return `${invoice.installments}x de ${formatCurrency(invoice.total / invoice.installments / 100)}`
    }

    return formatCurrency(invoice.total / 100)
  }

  const canPayInvoice = [InvoiceStatus.UNPAID, InvoiceStatus.EXPIRED].includes(invoice.status)

  return (
    <Card>
      <CardHeader className="w-full flex-row flex-wrap gap-2 items-center justify-between">
        <div className="flex flex-col gap-1.5">
          <CardDescription>{product.name}</CardDescription>
          <div className="flex flex-row gap-2 items-center">
            <CardTitle>{getTotal()}</CardTitle>
            <div className="flex gap-2 items-center">
              <InvoiceStatusBadge status={invoice.status} />

              {invoice.smartInstallmentRef && (
                <SmartInstallmentBadge
                  status={invoice.status}
                  installment={invoice.installment}
                  installments={invoice.installments}
                />
              )}
            </div>
          </div>
          <CardDescription>
            {t(`invoiceDetails.header.date.${invoice.status}`)} {getDate()}
          </CardDescription>
        </div>
        <div className="flex justify-between">{getIcon(invoice.status)}</div>
      </CardHeader>

      <Separator />
      <CardContent className="flex flex-col p-6 gap-6">
        <div className="flex flex-col gap-4">
          <div>
            <Label>De</Label>
            <p className="text-sm text-muted-foreground">{seller.fullName}</p>
          </div>

          <div>
            <Label>Para</Label>
            <p className="text-sm text-muted-foreground">{payer.fullName}</p>
          </div>
        </div>
      </CardContent>
      <Separator />
      <CardFooter className="flex flex-row gap-2 p-6">
        {canPayInvoice && (
          <Button size="sm" onClick={() => window.open(`${Envs.INVOICE_URL}/${invoice?.id}`, '_blank')}>
            {t('invoiceDetails.header.actions.pay')}
          </Button>
        )}

        <Button size="sm" variant="outline" onClick={() => history.push(`/user_invoices/${invoice?.id}/pdf`, '_blank')}>
          {t('invoiceDetails.header.actions.download')}
        </Button>
      </CardFooter>
    </Card>
  )
}
