import { container } from '@hub-la/fe-container'
import { DashboardSettings, DashboardSettingsProvider } from '@hub-la/fe-dashboard-settings'
import { withRoleplayRedirect } from '../components/roleplay-redirect'

export const DashboardSettingsInit = withRoleplayRedirect(() => {
  return (
    <DashboardSettingsProvider container={container}>
      <DashboardSettings />
    </DashboardSettingsProvider>
  )
})
