import { Button, Input, Label, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@hub-la/shadcn'

import { useFormikContext } from 'formik'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { UF } from '../../domain/constants/brazilian-states'
import { IsStepValid } from '../../usecases/is-step-valid'
import { useField } from '../hooks/use-field'
import { useGetAddress as useGetAddressUseCase } from '../hooks/use-get-postal-code'
import { FormSteps, Values } from '../pages/account-verification-modal'

type Props = {
  onComplete: () => void
  loading?: boolean
}

export const BillingAddressForm: React.FC<Props> = ({ onComplete, loading }) => {
  const { t } = useTranslation()
  const { isValid } = useField()
  const { hasError } = useField()
  const getAddressUseCase = useGetAddressUseCase()
  const [isFetching, setIsFetching] = useState<boolean>(false)
  const { values, handleBlur, handleChange, setFieldValue } = useFormikContext<Values>()

  const fetchAdress = async (postalCode: string) => {
    setIsFetching(true)
    await getAddressUseCase
      .execute(postalCode)
      .then((address) => {
        if (!address) {
          return
        }
        setFieldValue('identity.billingAddress.street', address.street)
        setFieldValue('identity.billingAddress.neighborhood', address.neighborhood)
        setFieldValue('identity.billingAddress.state', address.state)
        setFieldValue('identity.billingAddress.city', address.city)
        setFieldValue('identity.billingAddress.ibgeCode', address.ibgeCode)
      })
      .finally(() => {
        setIsFetching(false)
      })
  }

  useEffect(() => {
    fetchAdress(values.identity.billingAddress.postalCode)
  }, [values.identity.billingAddress.postalCode])

  const isStepValid = new IsStepValid(isValid).execute(FormSteps.PERSONAL_INFO)

  return (
    <div className="flex flex-col space-y-1.5">
      <Label className="mb-1.5">
        {t(`accountVerification.form.billingAddress.title.${values.identity.document.type}`)}
      </Label>
      <Input
        id="identity.billingAddress.postalCode"
        value={values.identity.billingAddress.postalCode}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={isFetching}
        placeholder={t('accountVerification.form.billingAddress.postalCode')}
        className={hasError('identity.billingAddress.postalCode') ? 'border-red-500' : ''}
      />
      <Input
        id="identity.billingAddress.neighborhood"
        value={values.identity.billingAddress.neighborhood}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={isFetching}
        placeholder={t('accountVerification.form.billingAddress.neighborhood')}
        className={hasError('identity.billingAddress.neighborhood') ? 'border-red-500' : ''}
      />
      <Input
        id="identity.billingAddress.street"
        value={values.identity.billingAddress.street}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={isFetching}
        placeholder={t('accountVerification.form.billingAddress.street')}
        className={hasError('identity.billingAddress.street') ? 'border-red-500' : ''}
      />
      <div className="flex space-x-1.5">
        <Input
          id="identity.billingAddress.number"
          value={values.identity.billingAddress.number}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={t('accountVerification.form.billingAddress.number')}
          className={hasError('identity.billingAddress.number') ? 'border-red-500' : ''}
        />
        <Input
          id="identity.billingAddress.complement"
          value={values.identity.billingAddress.complement}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={t('accountVerification.form.billingAddress.complement')}
          className={hasError('identity.billingAddress.complement') ? 'border-red-500' : ''}
        />
      </div>
      <div className="flex space-x-1.5">
        <Input
          id="identity.billingAddress.city"
          value={values.identity.billingAddress.city}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={isFetching}
          placeholder={t('accountVerification.form.billingAddress.city')}
          className={hasError('identity.billingAddress.city') ? 'border-red-500' : ''}
        />
        <Select
          value={values.identity.billingAddress.state}
          onValueChange={(value) => setFieldValue('identity.billingAddress.state', value)}
          disabled={isFetching}
        >
          <SelectTrigger className="w-full">
            <SelectValue placeholder={t('accountVerification.form.billingAddress.state')} />
          </SelectTrigger>
          <SelectContent className="z-[6000]">
            {UF.map((uf) => (
              <SelectItem key={uf.code} value={uf.code}>
                {uf.code} - {uf.name}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
      <div className="self-end">
        <Button
          disabled={!isStepValid}
          onClick={onComplete}
          className={loading ? 'opacity-50 cursor-not-allowed' : ''}
          loading={loading}
        >
          {t('accountVerification.form.continue')}
        </Button>
      </div>
    </div>
  )
}
