import { useRoleplay } from '@hub-la/fe-roleplay'
import { Redirect } from 'react-router-dom'

/**
 * Redirect user to dashboard if they are in roleplay mode. This can be used to prevent users from accessing roleplay mode in the members' area, for example
 */
export const withRoleplayRedirect = (WrappedComponents: React.ComponentType) => {
  return (props) => {
    const { isRoleplay } = useRoleplay()

    if (isRoleplay) return <Redirect to="/dashboard" />

    return <WrappedComponents {...props} />
  }
}
