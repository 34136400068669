import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { GetInvoicesResponse } from '../domain/dtos/get-invoices-response'
import { Invoice } from '../domain/dtos/invoice'
import { InvoiceStatus } from '../domain/enums/invoice-status'
import { Envs } from '../envs'

export class GetInvoices {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(offerId: string[], signal?: AbortSignal): Promise<Invoice[]> {
    const response = await this.httpClient.request({
      method: HttpMethod.POST,
      url: `${Envs.BFF_WEB_URL}/payer/invoices`,
      body: this.makeBody(offerId),
      signal,
    })

    if (response.statusCode === HttpStatusCode.OK || response.statusCode === HttpStatusCode.CREATED) {
      return this.makeResponse(response.data)
    }

    throw new Error('Não foi possível obter as faturas.')
  }

  private makeBody(offerId: string[]) {
    const body = {
      offerIds: offerId,
      page: 1,
      pageSize: 1000,
      orderBy: 'paidAt',
      orderDirection: 'DESC',
    }

    return body
  }

  private makeResponse(data: GetInvoicesResponse): Invoice[] {
    if (data.total > 0) {
      return data.items.filter((invoice) => invoice.status === InvoiceStatus.PAID)
    }

    return []
  }
}
