export const pt = {
  confirmAccount: {
    buttonEdit: `Editar`,
  },
  notGroupMember: {
    title: `Este grupo não está vinculado a este usuário`,
    description: `Identificamos que este grupo não está vinculado a sua conta na Hubla. Você pode acessar seus produtos comprados na página de ”Meus Produtos.`,
    button: `Ir para Meus Produtos`,
  },
  verificationTelegram: {
    faq: {
      helper: `Entenda o`,
      link: `fluxo de verificação`,
    },
    intro: {
      title: `Verifique sua conta do Telegram para acessar o grupo`,
      subtitle: `Ao verificar o seu Telegram, o nosso robô irá analisar seus dados para garantir que você participe do grupo.`,
      button: `Começar`,
    },
    start: {
      title: `Siga o passo a passo para verificar`,
      subtitle: `Inicie uma conversa com o ChatPayBot no Telegram clicando botão ”Start” ou ”Iniciar” na parte inferior da tela.`,
      buttonStart: `Iniciar conversa`,
      buttonStartWeb: `Iniciar no Telegram Web`,
      buttonStartQRCode: `Iniciar via QR Code`,
      buttonAwaiting: `Aguardando verificação`,
    },
    qrcode: {
      title: `Leia o QR code para verificar sua conta do Telegram`,
      subtitle: `Inicie uma conversa com o ChatPayBot no Telegram clicando botão ”Start” ou ”Iniciar” na parte inferior da tela.`,
      buttonBack: `Voltar`,
      textHelper: `Aponte a câmera do seu telefone para o código`,
    },
    done: {
      title: `Verificação concluída com sucesso. Boas-vindas ao grupo, `,
      subtitle: `Esta é a sua conta de Telegram vinculada à sua conta Hubla para acessar seus grupos. Você pode editá-la na página do seu grupo.`,
      buttonGoGroup: `Ir para o grupo no Telegram`,
      buttonCompleted: `Concluir`,
    },
  },
}
