import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation } from '@tanstack/react-query'
import { GetAssetsSignedUrl, GetAssetsSignedUrlInput } from '../../usecases/get-assets-signed-url'

export const useGetAssetsSignedUrl = () => {
  const container = useContainer()

  return useMutation([], (input: GetAssetsSignedUrlInput) => {
    return new GetAssetsSignedUrl(container.get(HttpClient)).execute(input)
  })
}
