import { HttpClient, HttpMethod } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { GetIntegrationByProviderOutput } from '../domain/dtos/get-integration-by-provider-output'
import { mapBackendProvider } from '../domain/dtos/integration-provider'
import { Envs } from '../envs'

export class GetIntegrationByProvider {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(provider: string): Promise<GetIntegrationByProviderOutput | null> {
    const params = mapBackendProvider[provider]

    const response = await this.httpClient.request({
      method: HttpMethod.GET,
      url: `${Envs.BFF_WEB_URL}/integrations/provider/${params}`,
    })

    return response.data
  }
}
