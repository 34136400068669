import { formatCurrency } from '@brazilian-utils/brazilian-utils'
import {
  ChartContainer,
  Separator,
  TableCell,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@hub-la/shadcn'
import { Label, Pie, PieChart } from 'recharts'

export const InstallmentProgress = ({
  installments,
  paid,
  overdue,
  draft,
  paidInstallments,
  overdueInstallments,
  draftInstallments,
}) => {
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="relative p-2 w-56 z-50 space-y-1.5">
          <div className="flex flex-row items-center justify-between gap-1">
            <span>{installments} parcelas</span>
            <span>R$ {formatCurrency((paid + overdue + draft) / 100)}</span>
          </div>
          <Separator className="my-2 border-primary-foreground" />
          <div className="flex flex-row items-center justify-between gap-1">
            <div className="flex items-center gap-1">
              <div className="h-4 w-0.5 mr-1 rounded-sm bg-green-600" />
              <span className="font-semibold">{paidInstallments}</span>{' '}
              <span>{paidInstallments === 1 ? 'paga' : 'pagas'}</span>
            </div>
            <span>R$ {formatCurrency(paid / 100)}</span>
          </div>
          <div className="flex flex-row items-center justify-between gap-1">
            <div className="flex items-center gap-1">
              <div className="h-4 w-0.5 mr-1 rounded-sm bg-red-600" />
              <span className="font-semibold">{overdueInstallments}</span>{' '}
              <span>{overdueInstallments === 1 ? 'atrasada' : 'atrasadas'}</span>
            </div>
            <span>R$ {formatCurrency(overdue / 100)}</span>
          </div>
          <div className="flex flex-row items-center justify-between gap-1">
            <div className="flex items-center gap-1">
              <div className="h-4 w-0.5 mr-1 rounded-sm bg-muted-foreground" />
              <span className="font-semibold">{draftInstallments}</span>{' '}
              <span>{draftInstallments === 1 ? 'agendada' : 'agendadas'}</span>
            </div>
            <span>R$ {formatCurrency(draft / 100)}</span>
          </div>
        </div>
      )
    }
    return null
  }

  const chartConfig = {
    paid: {
      label: 'Paga',
      color: 'rgb(22 163 74)',
    },
    overdue: {
      label: 'Atrasada',
      color: 'rgb(220 38 38)',
    },
    draft: {
      label: 'Agendada',
      color: 'hsl(var(--secondary))',
    },
  }

  const chartData = [
    ...(installments > 0
      ? [
          {
            status: 'paid',
            value: paidInstallments,
            fill: chartConfig.paid.color,
          },
          {
            status: 'overdue',
            value: overdueInstallments,
            fill: chartConfig.overdue.color,
          },
          {
            status: 'draft',
            value: draftInstallments,
            fill: chartConfig.draft.color,
          },
        ]
      : [
          {
            status: 'empty',
            value: 1,
            fill: chartConfig.draft.color,
          },
        ]),
  ]

  const percentagePaid = (paidInstallments / installments) * 100

  return (
    <TableCell className="flex flex-row justify-between items-center gap-4">
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <div className="flex items-center gap-2 cursor-pointer">
              <ChartContainer config={chartConfig} className="min-w-8 aspect-square">
                <PieChart>
                  {paidInstallments > 0 ? (
                    <Pie
                      data={chartData}
                      dataKey="value"
                      nameKey="status"
                      innerRadius={11}
                      outerRadius={14}
                      strokeWidth={0}
                    >
                      {installments && (
                        <Label
                          content={({ viewBox }) => {
                            if (viewBox && 'cx' in viewBox && 'cy' in viewBox) {
                              return (
                                <text x={viewBox.cx} y={viewBox.cy} textAnchor="middle" dominantBaseline="middle">
                                  <tspan x={viewBox.cx} y={viewBox.cy} className="fill-foreground text-xs font-normal">
                                    {installments}
                                  </tspan>
                                </text>
                              )
                            }
                            return <span>{installments}</span>
                          }}
                        />
                      )}
                    </Pie>
                  ) : (
                    <>
                      <Pie
                        data={[
                          {
                            status: 'empty',
                            value: 1,
                            fill: chartConfig.draft.color,
                          },
                        ]}
                        dataKey="value"
                        nameKey="status"
                        innerRadius={10}
                        outerRadius={13}
                        strokeWidth={0}
                      />
                      <span className="text-muted-foreground">Sem parcelas</span>
                    </>
                  )}
                </PieChart>
              </ChartContainer>
              <div className="flex items-center gap-1">
                <span className="font-normal text-foreground">{percentagePaid.toFixed(0)}%</span>
                <span className="text-muted-foreground">pago</span>
              </div>
            </div>
          </TooltipTrigger>
          <TooltipContent>
            <CustomTooltip active payload={chartData} />
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>

      <div className="flex justify-start items-center gap-1 w-[120px]">
        {overdueInstallments > 0 ? (
          <div className="h-2 w-2 rounded-[2px] bg-red-600" />
        ) : (
          <div className="h-2 w-2 rounded-[2px] bg-transparent border border-muted-foreground" />
        )}
        <span className="text-foreground font-medium">{overdueInstallments}</span>
        <span className="text-muted-foreground">atrasada{overdueInstallments === 1 ? '' : 's'}</span>
      </div>
    </TableCell>
  )
}
