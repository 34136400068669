import { useContainer } from '@hub-la/fe-container'
import { Analytics } from '@hub-la/fe-core-analytics'
import { Events, makePostEventData, SectionResponse } from '@hub-la/fe-post'
import { DropdownMenuItem } from '@hub-la/shadcn'
import { DropdownMenuSub, DropdownMenuSubContent, DropdownMenuSubTrigger } from '@radix-ui/react-dropdown-menu'
import { ChevronRight, MoveVertical } from 'lucide-react'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { GetMoveSectionOptions } from '../../../usecases/get-move-section-options'
import { useMovePostSection } from '../../hooks/use-move-post-section'

type Props = {
  postId: string
  userId: string
  productId: string
  sectionId: string
  sections: SectionResponse[]
}

export const MoveSectionMenu: React.FC<Props> = ({ sections, postId, productId, userId, sectionId }) => {
  const { t } = useTranslation()
  const movePost = useMovePostSection()
  const container = useContainer()

  const onMove = (sectionId: string) => {
    movePost.mutateAsync({ sectionId, postId }).then(() => {
      analytics.track(
        Events.HUB.SECTION.MOVE_SUBMITTED,
        makePostEventData({
          userId,
          productId,
        }),
      )
    })
  }

  const analytics = container.get(Analytics)
  const options = new GetMoveSectionOptions().execute({ sectionId, sections })

  return (
    <DropdownMenuSub>
      <DropdownMenuSubTrigger asChild>
        <DropdownMenuItem>
          <MoveVertical className="text-primary mr-2 h-4 w-4" />
          <span className="flex-1 text-primary">{t('dashboard.menu.moveTo')}</span>
          <ChevronRight className="text-primary h-4 w-4" />
        </DropdownMenuItem>
      </DropdownMenuSubTrigger>

      <DropdownMenuSubContent className="max-h-44 w-56 overflow-y-auto">
        {options.map((option) => (
          <DropdownMenuItem key={option.id} onSelect={() => onMove(option.id)}>
            {option.name}
          </DropdownMenuItem>
        ))}
      </DropdownMenuSubContent>
    </DropdownMenuSub>
  )
}
