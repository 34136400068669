import { useSaveAssetProgress } from '@hub-la/fe-asset'
import { useContainer } from '@hub-la/fe-container'
import { Analytics } from '@hub-la/fe-core-analytics'
import { useCallback } from 'react'
import { Events } from '../../domain/dtos'
import { useGetSectionProgress } from './use-get-section-progress'
import { useRealtimeSections } from './use-realtime-sections'

export const useUserProgress = ({ assetId, sectionId, postId, progress }) => {
  const container = useContainer()

  const analytics = container.get(Analytics)

  const { dispatch, sections } = useRealtimeSections()
  const { mutateAsync: getSectionProgress } = useGetSectionProgress()
  const saveAssetProgress = useSaveAssetProgress()

  const updateUserProgress = useCallback(
    (currentTime: number, totalTime: number) => {
      dispatch({ type: 'update', sectionId, postId, currentTime })
      getSectionProgress(sectionId).then((sectionProgress) =>
        dispatch({ type: 'update', sectionId, postId, currentTime, sectionProgress }),
      )
      saveAssetProgress
        .mutateAsync({
          assetId,
          total: totalTime,
          currentProgress: currentTime,
        })
        .catch(() => {
          analytics.track(Events.HUB.PROGRESS.SAVE_FAILED, {
            assetId,
            type: 'video',
            userProgress: progress ?? 0,
            current: currentTime,
            total: totalTime,
          })
        })
    },
    [analytics, assetId, saveAssetProgress, sections, dispatch, sectionId, postId],
  )

  return { updateUserProgress }
}
