import { Button, Input, Label } from '@hub-la/shadcn'
import { useFormikContext } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import 'react-phone-number-input/style.css'
import { SoftDescriptor } from '../../domain/vos/soft-descriptor'
import { IsStepValid } from '../../usecases/is-step-valid'
import image from '../assets/soft-descriptor.png'
import { useField } from '../hooks/use-field'
import { FormSteps, Values } from '../pages/account-verification-modal'
import { FormHeader } from './form-header'

type Props = {
  onComplete: () => void
  loading?: boolean
}

export const SoftDescriptorForm: React.FC<Props> = ({ onComplete, loading }) => {
  const { t } = useTranslation()
  const { isValid } = useField()
  const { hasError, getErrorText } = useField()
  const { values, handleBlur, setFieldValue } = useFormikContext<Values>()

  const error = getErrorText('businessConfiguration.creditCard.softDescriptor')
  const isStepValid = new IsStepValid(isValid).execute(FormSteps.PUBLIC_INFO)

  const onSoftDescriptorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toUpperCase()

    if (value === '') {
      setFieldValue('businessConfiguration.creditCard.softDescriptor', '')
    }

    const regex = /^(?=.*[A-Z])[A-Z0-9]*$/

    if (regex.test(value)) {
      setFieldValue('businessConfiguration.creditCard.softDescriptor', value)
    }
  }

  return (
    <div className="flex flex-col space-y-6">
      <FormHeader
        title={t('accountVerification.form.softDescriptor.title')}
        subtitle={t('accountVerification.form.softDescriptor.subtitle')}
      />
      <div className="flex flex-col space-y-2">
        <div className="flex flex-row items-baseline space-x-2">
          <Label>{SoftDescriptor.build('').withPrefix()}</Label>
          <Input
            className={hasError('businessConfiguration.creditCard.softDescriptor') ? 'border-red-500' : ''}
            onBlur={handleBlur}
            onChange={onSoftDescriptorChange}
            value={values.businessConfiguration.creditCard?.softDescriptor}
            type="text"
            id="businessConfiguration.creditCard.softDescriptor"
            maxLength={11}
          />
        </div>
        <span className={`text-xs ${error ? 'text-red-500' : 'text-muted-foreground'}`}>
          {error ||
            t('accountVerification.form.softDescriptor.helperText', {
              length: values.businessConfiguration.creditCard?.softDescriptor.length,
            })}
        </span>
      </div>
      <div className="flex flex-col md:flex-row gap-6">
        <p className="text-sm text-secondary-foreground">{t('accountVerification.form.softDescriptor.tooltip')}</p>
        <img src={image} width={237} alt={'Detalhe do soft descriptor'} />
      </div>
      <div className="self-end">
        <Button
          variant="default"
          disabled={!isStepValid}
          onClick={onComplete}
          className={loading ? 'opacity-50 cursor-not-allowed' : ''}
        >
          {loading ? 'Carregando...' : t('accountVerification.form.continue')}
        </Button>
      </div>
    </div>
  )
}
