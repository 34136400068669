import { cn, Separator } from '@hub-la/shadcn'

type Props = {
  title: string
  content: React.ReactElement
  fontWeight?: 'bold' | 'normal'
  showDivider?: boolean
}

export const ContentRow = ({ title, content, fontWeight = 'normal', showDivider = false }: Props) => {
  return (
    <div className="w-full flex flex-col gap-2">
      <div className="flex gap-1 items-center justify-between">
        <div className="flex gap-2 items-center">
          <p className={cn('text-sm', fontWeight === 'bold' ? 'font-semibold' : 'font-normal')}>{title}</p>
        </div>

        {content}
      </div>

      {showDivider && <Separator className="my-4" />}
    </div>
  )
}
