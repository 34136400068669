export enum InvoiceEventType {
  CREATED = 'created',
  PAYMENT_PAGE_CREATED = 'payment_page_created',
  PROGRESS = 'progress',
  EXPIRED = 'expired',
  FAILED = 'failed',
  SUCCEDED = 'succeded',
  REFUNDED = 'refunded',
  SMART_INSTALLMENT_STARTED = 'smart_installment_started',
  SMART_INSTALLMENT_FAILED = 'smart_installment_failed',
  SMART_INSTALLMENT_SUCCEEDED = 'smart_installment_succeeded',
}
