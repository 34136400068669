import { PostResponse, PostType } from '@hub-la/fe-post'
import { isNil } from 'lodash'

export class GetPostType {
  public execute(post: PostResponse | undefined): PostType | null {
    if (!isNil(post?.event)) {
      return 'event'
    }

    return new URLSearchParams(window.location.search).get('type') as PostType | null
  }
}
