import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { RoleplayReferenceOutput } from '../domain/dtos/roleplay-reference-output'
import { GeneralError } from '../domain/errors/general'
import { Envs } from '../envs'

export class GetRoleplayReference {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(): Promise<RoleplayReferenceOutput> {
    const response = await this.httpClient.request({
      method: HttpMethod.GET,
      url: `${Envs.BFF_WEB_URL}/user/roleplay/reference`,
    })

    if (response.statusCode === HttpStatusCode.OK) {
      return response.data
    }

    throw new GeneralError()
  }
}
