import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  useIsMobile,
} from '@hub-la/shadcn'
import { useFormik } from 'formik'
import moment from 'moment'
import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { UpgradeSummary } from '../../../../../domain/dtos/init-upgrade-plan-output'
import { Installment } from '../../../../../domain/dtos/installment'
import { UpgradePaymentMethods } from '../../../../../domain/dtos/payment-method'
import { UpgradeStatus } from '../../../../../domain/enums/upgrade-status'
import { UpgradeAlreadyInProgressError } from '../../../../../domain/errors/upgrade-already-in-progress'
import { formatCurrency, formatCurrencyWithInstallments } from '../../../../../domain/vos/format-currency'
import { PaymentMethodTextBuilder } from '../../../../../usecases/payment-method-text-builder'
import { useGetUpgradeState } from '../../../../hooks/use-get-upgrade-state'
import { useInitUpgradePlan } from '../../../../hooks/use-init-upgrade-plan'
import { useSubmitUpgradePlan } from '../../../../hooks/use-submit-upgrade-plan'
import { ContentRow } from './content-row'
import { ErrorComponent } from './error-component'
import { Loading } from './loading'
import { Submitted } from './submitted'

type Props = {
  open: boolean
  onClose: () => void
  subscriptionId: string
}

export type Values = {
  plan: string
  installments: number
}

export const UpgradePlanModal: React.FC<Props> = ({ open, onClose, subscriptionId }) => {
  const [submitted, setSubmitted] = useState<boolean>(false)
  const { data: initUpgrade, isFetching, error } = useInitUpgradePlan(subscriptionId, open)
  const shouldFetchUpgradeState = (error instanceof UpgradeAlreadyInProgressError || submitted) && open
  const { data: upgradeState } = useGetUpgradeState(subscriptionId, shouldFetchUpgradeState)
  const isCreditCardPending =
    upgradeState?.status === UpgradeStatus.PENDING &&
    upgradeState?.paymentMethod.type === UpgradePaymentMethods.PAYMENT_METHOD_CARD
  const { t } = useTranslation()
  const submitUpgradePlan = useSubmitUpgradePlan()
  const isMobile = useIsMobile()

  const { values, submitForm, setFieldValue } = useFormik<Values>({
    initialValues: {
      plan: Object.keys(initUpgrade?.options ?? {}).at(0) ?? '',
      installments: 1,
    },
    validationSchema: Yup.object().shape({
      plan: Yup.string().required(),
      installments: Yup.number().required().min(1).max(12),
    }),
    validateOnChange: true,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (!initUpgrade) return
      const payload = {
        subscriptionId,
        selectedOptionId: Object.values(initUpgrade.options).find((it) => it.billingCycle === values.plan)?.id,
        installments: values.installments,
      }
      await submitUpgradePlan.mutateAsync(payload).then(() => {
        setSubmitted(true)
      })
    },
  })

  if (error instanceof UpgradeAlreadyInProgressError || (submitted && !isCreditCardPending)) {
    return (
      <Dialog open={open} onOpenChange={onClose}>
        <DialogContent className={isMobile ? 'w-full h-full' : 'max-w-md'}>
          <Submitted
            open={open}
            onClose={onClose}
            onCancel={() => setSubmitted(false)}
            subscriptionId={subscriptionId}
            upgradeState={upgradeState}
          />
        </DialogContent>
      </Dialog>
    )
  }

  const selectedPlan = initUpgrade?.options?.[values.plan] as UpgradeSummary
  const selectedInstallment = selectedPlan?.installments.find((it) => it.installmentAmount === values.installments)
  const availableOptions = Object.values(initUpgrade?.options ?? {})

  if (isFetching && !initUpgrade) {
    return (
      <Dialog open={open} onOpenChange={onClose}>
        <DialogContent className={isMobile ? 'w-full h-full' : 'max-w-md'}>
          <Loading />
        </DialogContent>
      </Dialog>
    )
  }

  if (error && !initUpgrade) {
    return (
      <Dialog open={open} onOpenChange={onClose}>
        <DialogContent className={isMobile ? 'w-full h-full' : 'max-w-md'}>
          <ErrorComponent error={(error as Error).message} />
        </DialogContent>
      </Dialog>
    )
  }

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className={isMobile ? 'w-full h-full' : 'max-w-md'}>
        <DialogHeader>
          <DialogTitle>{t('upgradePlanModal.title')}</DialogTitle>
        </DialogHeader>

        <div className="space-y-8">
          <p className="text-muted-foreground">
            <Trans
              i18nKey="upgradePlanModal.subtitle"
              values={{
                plan: t(`planType.${initUpgrade?.subscription.billingCycle.toLowerCase()}`),
                price: formatCurrencyWithInstallments(
                  initUpgrade?.subscription.paymentMethod.installments,
                  (initUpgrade?.subscription.priceInCents ?? 0) / 100,
                  initUpgrade?.subscription.paymentMethod.installmentPrice,
                ),
                periodicity: t(`billingCycle.${initUpgrade?.subscription.billingCycle.toLowerCase()}`),
                renewAt: moment(new Date()).add(initUpgrade?.subscription.renewalInDays, 'days').format('D MMM. YYYY'),
              }}
              components={[<strong />]}
            />
          </p>

          <div className="space-y-4">
            <h3 className="font-bold">{t('upgradePlanModal.formTitle')}</h3>
            <Select value={values.plan} onValueChange={(value) => setFieldValue('plan', value)}>
              <SelectTrigger className="w-full">
                <SelectValue placeholder="Plano de assinatura" />
              </SelectTrigger>
              <SelectContent className="z-[100]">
                {availableOptions.map((option) => (
                  <SelectItem key={option.billingCycle} value={option.billingCycle}>
                    {t(`planType.${option.billingCycle.toLowerCase()}`)}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>

            <Select
              value={values.installments.toString()}
              onValueChange={(value) => setFieldValue('installments', parseInt(value, 10))}
            >
              <SelectTrigger className="w-full">
                <SelectValue placeholder="Opções de parcelamento" />
              </SelectTrigger>
              <SelectContent className="z-[100]">
                {(selectedPlan?.installments ?? []).map((installment: Installment) => {
                  const shouldRenderAdvanceFee =
                    installment.advanceFee === 0 &&
                    initUpgrade?.subscription.paymentMethod.type === UpgradePaymentMethods.PAYMENT_METHOD_CARD

                  return (
                    <SelectItem key={installment.installmentAmount} value={installment.installmentAmount.toString()}>
                      {installment.installmentAmount}x de {formatCurrency(installment.installmentPrice)}{' '}
                      {shouldRenderAdvanceFee ? 'sem juros' : ''}
                    </SelectItem>
                  )
                })}
              </SelectContent>
            </Select>
          </div>

          <Card>
            <CardContent className="space-y-6">
              <p className="text-sm text-muted-foreground">
                {t('upgradePlanModal.summary', {
                  from: t(`planInterval.${initUpgrade?.subscription.billingCycle.toLowerCase()}`),
                  to: t(`planInterval.${values.plan.toLowerCase()}`),
                })}
              </p>
              <div className="space-y-2">
                <ContentRow
                  title={initUpgrade?.offer.name + ' - ' + t(`planType.${values.plan.toLowerCase()}`)}
                  content={
                    <p className="text-right">
                      {formatCurrencyWithInstallments(
                        values.installments,
                        selectedPlan?.price,
                        ((selectedInstallment?.totalPrice ?? 0) + selectedPlan?.prorata) / values.installments,
                      )}
                    </p>
                  }
                />
                <ContentRow
                  showDivider
                  title={t('upgradePlanModal.credits', {
                    plan: t(`planType.${initUpgrade?.subscription.billingCycle.toLowerCase()}`),
                  })}
                  content={<p>- {formatCurrency(selectedPlan?.prorata ?? 0)}</p>}
                />
                <ContentRow
                  fontWeight="bold"
                  title="Total"
                  content={
                    <p>
                      {formatCurrencyWithInstallments(
                        values.installments,
                        selectedInstallment?.installmentPrice,
                        selectedInstallment?.installmentPrice,
                      )}
                    </p>
                  }
                />
              </div>
            </CardContent>
          </Card>

          <div className="space-y-3">
            <Button
              onClick={submitForm}
              className="w-full"
              disabled={submitUpgradePlan.isLoading || isCreditCardPending}
              loading={submitUpgradePlan.isLoading || isCreditCardPending}
            >
              {t('upgradePlanModal.button')}
            </Button>
            <p className="text-sm text-muted-foreground text-center">
              {t('upgradePlanModal.buttonSubtitle', {
                method: t(
                  new PaymentMethodTextBuilder().execute(
                    initUpgrade?.subscription.paymentMethod.type,
                    initUpgrade?.subscription.paymentMethod.last4,
                    initUpgrade?.subscription.paymentMethod.cardBrand,
                  ),
                ),
              })}
            </p>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
