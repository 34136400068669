import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { CreateRefundRequestInput } from '../domain/dtos/create-refund-request-input'
import { Refund } from '../domain/dtos/refund'
import { ErrorCode } from '../domain/enums/error-code'
import { RefundStrategy } from '../domain/enums/refund-strategy.enum'
import { GeneralError } from '../domain/errors/general'
import { RefundAlreadyExists } from '../domain/errors/refund-already-exists'
import { Envs } from '../envs'
import { BankAccount } from '../presentation/pages/user-refunds/components/bank-account-form'
import { CreateRefundRequestValues } from '../presentation/pages/user-refunds/components/create-refund-request-modal'

export class CreateRefundRequest {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) { }

  public async execute(input: CreateRefundRequestValues): Promise<Refund> {
    const response = await this.httpClient.request({
      method: HttpMethod.POST,
      url: `${Envs.BFF_WEB_URL}/refunds/request`,
      body: this.makeBody(input),
    })

    if (response.statusCode === HttpStatusCode.CREATED) {
      return response.data
    }

    switch (response.data.errorCode) {
      case ErrorCode.ALREADY_EXISTS:
        throw new RefundAlreadyExists()
      default:
        throw new GeneralError()
    }
  }

  checkNumbers(bankAccount: BankAccount): BankAccount {
    bankAccount.accountCheckNumber = bankAccount.accountNumber.slice(-1)
    bankAccount.accountNumber = bankAccount.accountNumber.slice(0, bankAccount.accountNumber.length - 1)

    // Bancos em que é necessário informar o digito verificar da agência
    if (['001', '041', '237', '633'].includes(bankAccount.bankNumber)) {
      bankAccount.agencyCheckNumber = bankAccount.agencyNumber.slice(-1)
      bankAccount.agencyNumber = bankAccount.agencyNumber.slice(0, bankAccount.agencyNumber.length - 1)
    }

    return bankAccount
  }

  private makeBody(input: CreateRefundRequestValues): CreateRefundRequestInput {
    if (input.refundPayerData?.strategy === RefundStrategy.BANK_ACCOUNT) {
      this.checkNumbers(input.refundPayerData.data as BankAccount)
    }

    const body: CreateRefundRequestInput = {
      invoiceId: input.invoiceId,
      description: input.description,
      refundPayerData: input.refundPayerData,
    }

    return body
  }
}
