import { date, object } from 'yup'

export const eventDateValidationSchema = () => {
  return object().shape({
    startDate: date()
      .test('isGreaterNow', 'A data inicial deve ser maior que agora', function (startDate) {
        if (!startDate) return false
        return new Date(startDate) > new Date()
      })
      .required(),
    endDate: date()
      .required()
      .test('isGreater', 'A data final deve ser maior que a data inicial', function (endDate) {
        if (!endDate) return false
        return new Date(endDate) > new Date(this.parent.startDate)
      }),
  })
}
