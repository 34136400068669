import { UploadStatus } from '@hub-la/fe-asset'
import { useContainer } from '@hub-la/fe-container'
import { Analytics } from '@hub-la/fe-core-analytics'
import {
  CustomCover,
  Events,
  makePostContentCoverData,
  PostResponse,
  UpdatePostRequest,
  useSavePost,
} from '@hub-la/fe-post'
import { Button, Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, useToast } from '@hub-la/shadcn'
import { FormikProvider, useFormik } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  open: boolean
  onClose: () => void
  post: PostResponse
  productId: string
  userId: string
}

export const EditorCover: React.FC<Props> = ({ userId, onClose, open, post, productId }) => {
  const container = useContainer()
  const { t } = useTranslation()
  const { toast } = useToast()
  const { mutateAsync: savePost, isLoading } = useSavePost()
  const analytics = container.get(Analytics)

  const formik = useFormik({
    initialValues: {
      customCover: post.customCover,
      customCoverStatus: UploadStatus.LOADED,
      customCoverProgress: 0,
    },
    enableReinitialize: true,
    onSubmit: async ({ customCover }) => {
      if (customCover === undefined) {
        toast({
          title: 'Error',
          description: 'Não é possível salvar a capa do post sem uma imagem',
          variant: 'destructive',
        })
        return
      }

      const payload: UpdatePostRequest = {
        content: post.content,
        productId,
        id: post.id,
        title: post.title,
        status: post.status,
        customCoverAssetId: customCover !== null ? customCover.id : customCover,
      }

      if (post.attachments) {
        payload.attachmentAssetIds = post.attachments.map((attachment) => attachment.id)
      }

      if (post.cover) {
        payload.coverAssetId = post.cover.id
      }

      await savePost({
        id: post.id,
        payload,
      })

      analytics.track(
        Events.HUB.CONTENT_COVER.FINISH_CLICKED,
        makePostContentCoverData({
          userId,
          creatorId: post.authorId,
          productId,
          postId: post.id,
        }),
      )

      onClose()
    },
  })

  return (
    <Dialog open={open} onOpenChange={(isOpen) => !isOpen && onClose()}>
      <DialogContent className="sm:max-w-lg">
        <DialogHeader>
          <DialogTitle>{t('customCoverUpload.post.title')}</DialogTitle>
          <DialogDescription>{t('customCoverUpload.post.description')}</DialogDescription>
        </DialogHeader>

        <FormikProvider value={formik}>
          <div className="flex flex-col space-y-4">
            <CustomCover
              title={t('customCoverUpload.post.title')}
              description={t('customCoverUpload.post.description')}
              showSnackbar={false}
            />

            <Button onClick={formik.submitForm} loading={isLoading} className="w-full">
              {t('button.save')}
            </Button>
          </div>
        </FormikProvider>
      </DialogContent>
    </Dialog>
  )
}
