import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { toast } from '@hub-la/shadcn'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { ReactivateRefundRequestInput } from '../../domain/dtos/reactivate-refund-request-input'
import { QueryKey } from '../../domain/enums/query-key'
import { ReactivateRefundRequest } from '../../usecases/reactivate-refund-request'

export const useReactivateRefundRequest = () => {
  const container = useContainer()
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  return useMutation(
    [QueryKey.patchReactivateRefundRequest],
    (input: ReactivateRefundRequestInput) => new ReactivateRefundRequest(container.get(HttpClient)).execute(input),
    {
      retry: false,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [QueryKey.getRefunds] })
      },
      onError: () => {
        toast({ description: t("errors.general"), variant: "destructive" })
      }
    },
  )
}
