import { Skeleton, TableCell, TableRow } from '@hub-la/shadcn'

export const TableLoading = () => {
  const NUMBER_OF_COLUMNS = 6

  return (
    <TableRow>
      {[...Array(NUMBER_OF_COLUMNS)].map((_, index) => (
        <TableCell key={index}>
          <Skeleton className="w-[70%] h-6" />
        </TableCell>
      ))}
    </TableRow>
  )
}
