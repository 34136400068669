import { useContainer } from '@hub-la/fe-container'
import { Analytics, AnalyticsEvent } from '@hub-la/fe-core-analytics'
import { useState } from 'react'
import { BookAccount } from '../../domain/dtos/book-accounts'
import { TransactionSource } from '../../domain/dtos/get-moviments.dto'

export type TransactionSourceFilter = {
  id: string
  values: TransactionSource[]
}

const transactionSourceFilters: TransactionSourceFilter[] = [
  {
    id: 'sales',
    values: [TransactionSource.SALE],
  },
  {
    id: 'renewals',
    values: [TransactionSource.RENEWAL],
  },
  {
    id: 'upgrades',
    values: [TransactionSource.UPGRADE],
  },
  {
    id: 'withdrawals',
    values: [
      TransactionSource.WITHDRAWAL_PENDING,
      TransactionSource.WITHDRAWAL_TAX_FEE,
      TransactionSource.WITHDRAWAL_SUCCEEDED,
      TransactionSource.WITHDRAWAL_FAILED_AFTER_SUCCEEDED,
      TransactionSource.WITHDRAWAL_FAILED,
      TransactionSource.WITHDRAWAL_CANCELED,
    ],
  },
  {
    id: 'refunds',
    values: [TransactionSource.REFUND],
  },
  {
    id: 'chargebacks',
    values: [
      TransactionSource.CHARGEBACK_DISPUTE_OPENED,
      TransactionSource.CHARGEBACK_ACCEPTED_AFTER_DISPUTE,
      TransactionSource.CHARGEBACK_ACCEPTED_WITHOUT_DISPUTE,
      TransactionSource.CHARGEBACK_REJECTED,
    ],
  },
]

/**
 * Returns an array of transaction source ids. An id is returned, if
 * and only if, all sources connected to that id are included in the
 * array passed to the function
 */
const toTransactionIds = (sources: TransactionSource[]) => {
  return transactionSourceFilters
    .filter(({ values }) => values.every((value) => sources.includes(value)))
    .map(({ id }) => id)
}

export const useTransactionSourcesFilter = (
  selectedSources: TransactionSource[],
  onFilterChange: any,
  accountType: BookAccount,
) => {
  const [selectedSourceIds, setSelectedSourceIds] = useState<string[]>(toTransactionIds(selectedSources))
  const container = useContainer()
  const analytics = container.get(Analytics)

  const handleSourceSelect = ({ id, values }: TransactionSourceFilter) => {
    if (!isSourceSelected(id)) {
      analytics.track(AnalyticsEvent.WALLET.TRANSACTION_TYPE_FILTER, {
        transactionTypes: [...selectedSources, ...values],
      })
      onFilterChange({ transactionSources: [...selectedSources, ...values] })
      setSelectedSourceIds([...selectedSourceIds, id])
    }
  }

  const handleSourceUnselect = ({ id }: TransactionSourceFilter) => {
    if (isSourceSelected(id)) {
      onFilterChange({ transactionSources: selectedSourceIds.filter((s) => s !== id) })
      setSelectedSourceIds((selectedSourceIds) => selectedSourceIds.filter((s) => s !== id))
    }
  }

  const isSourceSelected = (id: string) => selectedSourceIds.includes(id)

  /**
   * Remove withdrawal filter from possible source filters when
   * showing movements from the receivable book account
   */
  const sourceFilters =
    accountType === 'receivable'
      ? transactionSourceFilters.filter((f) => f.id !== 'withdrawals')
      : accountType === 'contested' || accountType === 'reserved'
      ? []
      : transactionSourceFilters

  return {
    transactionSourceFilters: sourceFilters,
    handleSourceSelect,
    handleSourceUnselect,
    isSourceSelected,
  }
}
