import { UploadStatus } from '@hub-la/fe-asset'
import { isEmpty } from 'lodash'
import { useEffect, useState } from 'react'
import { Video } from '../../domain/dtos/video'

export const useVideoStatus = (videos: Video[]) => {
  const [status, setStatus] = useState<{ name: string; status: UploadStatus }[]>([])

  useEffect(() => {
    if (isEmpty(videos)) {
      return
    }

    setStatus(videos.map((video) => ({ name: video.file.name, status: UploadStatus.NO_CALLED })))
  }, [videos])

  const updateStatus = (name: string, status: UploadStatus) => {
    setStatus((_) => _.map((s) => (s.name === name ? { ...s, status } : s)))
  }

  const getStatus = (name: string): UploadStatus => {
    return status.find((s) => s.name === name)?.status ?? UploadStatus.NO_CALLED
  }

  return {
    updateStatus,
    getStatus,
  }
}
