import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation } from '@tanstack/react-query'

import { QueryKey } from '../../domain/dtos/query-keys'
import { WithdrawalRequestDto } from '../../domain/dtos/withdrawal-request.dto'

import { useContainer } from '@hub-la/fe-container'
import { SubmitWithdrawalRequest } from '../../usecases/submit-withdrawal-request'

export const useSubmitWithdrawalRequest = () => {
  const container = useContainer()

  return useMutation(
    async (input: WithdrawalRequestDto) => {
      return new SubmitWithdrawalRequest(container.get(HttpClient)).execute(input)
    },
    {
      retry: false,
      mutationKey: [QueryKey.requestWithdrawal],
    },
  )
}
