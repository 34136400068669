import { useHasAccountsAccess } from '@hub-la/fe-roleplay'
import { useCallback, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useAuth } from './use-auth'

const navigationTargetRoute = {
  buyer: '/user_groups',
  creator: '/dashboard/products',
}

type UseAuthRedirectResult = {
  selectUserLogin: boolean
  redirectAutoIfAuthenticated: () => Promise<void>
}

export const useAuthRedirect = (): UseAuthRedirectResult => {
  const history = useHistory()
  const location = useLocation()
  const { currentUser, isAuthenticated } = useAuth()
  const {
    isFetched,
    refetch,
    data: hasAccountsAccessData,
    isError,
  } = useHasAccountsAccess({
    enabled: false,
    userId: currentUser?.id,
  })

  const [selectUserLogin, setSelectUserLogin] = useState(false)
  const [isRedirecting, setIsRedirecting] = useState(false)

  const redirectAutoIfAuthenticated = useCallback(
    async (canFallbackRedirect = true) => {
      if (!currentUser) {
        return
      }

      if (isError) {
        return setSelectUserLogin(true)
      }

      const { data: hasAccountsAccess } = isFetched ? { data: hasAccountsAccessData } : await refetch()

      const params = new URLSearchParams(location.search)
      const redirectTo = params.get('redirectTo')

      if (redirectTo && redirectTo !== 'null' && redirectTo !== 'undefined' && !isRedirecting) {
        setIsRedirecting(true)
        document.title = 'Hubla'
        window.location.href = redirectTo
        return
      }

      if (hasAccountsAccess && !isRedirecting) {
        setIsRedirecting(true)
        document.title = 'Hubla'
        history.push('/roleplay')
        return
      }

      const navigationTarget = currentUser?.navigationTarget ?? currentUser?.loginPreferences?.navigationTarget
      const navigationTargetRoutePath = navigationTargetRoute[navigationTarget]

      if (navigationTargetRoutePath && !isRedirecting) {
        setIsRedirecting(true)
        document.title = 'Hubla'
        history.push(navigationTargetRoutePath)
        return
      }

      if (selectUserLogin) {
        return
      }

      if (history.location.pathname === '/signup') {
        history.push('/lets-start')
        return
      }

      if (canFallbackRedirect) {
        history.push('/')
      }
    },
    [currentUser, isError, isAuthenticated, isRedirecting, location.search, refetch, isFetched, hasAccountsAccessData],
  )

  return { selectUserLogin, redirectAutoIfAuthenticated }
}
