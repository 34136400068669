import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { NotificationSettings } from '../../domain/dtos/notifications'
import { QueryKey } from '../../domain/enums/query-key'
import { SetNotifications } from '../../usecases/set-notifications'

export const useSetNotifications = () => {
  const container = useContainer()
  const queryClient = useQueryClient()

  return useMutation(
    [QueryKey.setNotifications],
    (input: Partial<NotificationSettings>) => new SetNotifications(container.get(HttpClient)).execute(input),
    {
      retry: false,
      onSuccess: () => queryClient.invalidateQueries(['notifications']),
    },
  )
}
