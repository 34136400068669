import { array, boolean, mixed, number, object, string } from 'yup'

export const addFreeMemberValidationSchema = () => {
  return object({
    emails: array()
      .of(string().email())
      .min(1, 'addFreeMembersModal.errors.emails.min')
      .required('addFreeMembersModal.errors.emails.required'),
    lifetime: boolean().required(),
    days: mixed()
      .nullable()
      .when('lifetime', {
        is: false,
        then: number()
          .typeError('addFreeMembersModal.errors.days.required')
          .required('addFreeMembersModal.errors.days.required')
          .min(1, 'addFreeMembersModal.errors.days.min'),
      }),
  })
}
