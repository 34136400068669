import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '../../domain/enums/query-key'
import { GetProductDetails } from '../../usecases/get-product-details'

export const useGetProductDetails = (productId: string) => {
  const container = useContainer()

  return useQuery(
    [QueryKey.getProductDetails, productId],
    () => new GetProductDetails(container.get(HttpClient)).execute(productId),
    {
      retry: 3,
      enabled: !!productId,
      refetchOnWindowFocus: false,
    },
  )
}
