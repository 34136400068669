import { useTranslation } from 'react-i18next'
import { formatCurrency } from '../../../../domain/vos/format-currency'
import { CardInfoBox } from '../../../components/card-info-box'
import { LoadingCard } from '../../../components/loading-card'

type TotalUnpaidProps = {
  amount?: number
  isLoading: boolean
}

export const TotalUnpaid = ({ isLoading, amount }: TotalUnpaidProps) => {
  const { t } = useTranslation()

  if (isLoading) {
    return <LoadingCard />
  }

  return (
    <CardInfoBox
      title={t('smartInstallmentsSummaries.totalUnpaid.title')}
      description={t('smartInstallmentsSummaries.totalUnpaid.description')}
      current={formatCurrency(amount ?? 0)}
    />
  )
}
