import { Skeleton, TableCell, TableRow } from '@hub-la/shadcn'

export const TableLoading = () => {
  return (
    <TableRow>
      <TableCell className="py-2.5">
        <Skeleton className="w-full h-6" />
      </TableCell>

      <TableCell className="py-2.5">
        <Skeleton className="w-full h-6" />
      </TableCell>

      <TableCell className="py-2.5">
        <Skeleton className="w-4/5 h-6" />
      </TableCell>

      <TableCell className="py-2.5">
        <Skeleton className="w-full h-6" />
      </TableCell>

      <TableCell className="py-2.5">
        <Skeleton className="w-3/5 h-6" />
      </TableCell>

      <TableCell className="py-2.5">
        <Skeleton className="w-3/5 h-6" />
      </TableCell>
    </TableRow>
  )
}
