import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useQuery } from '@tanstack/react-query'

import { ListProductsInput } from '../../domain/dtos/list-products-input'
import { QueryKey } from '../../domain/enums/query-key.enum'
import { ListProducts } from '../../usecases/list-products.usecase'

export const useGetProducts = (input?: ListProductsInput) => {
  const container = useContainer()

  return useQuery(
    [QueryKey.getListProducts],
    ({ signal }) => new ListProducts(container.get(HttpClient)).execute(input, signal),
    {
      refetchOnWindowFocus: false,
      retry: 3,
      initialData: {
        page: 0,
        pageSize: 0,
        total: 0,
        lastPage: 0,
        items: [],
      },
    },
  )
}
