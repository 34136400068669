export const pt = {
  groupsOrder: {
    title: 'Ordem dos produtos',
    subtitle: 'Segure e arraste os produtos na ordem que deseja que eles apareçam em seu perfil',
    error: 'Ocorreu um erro ao carregar os produtos. Por favor, tente novamente.',
    noGroups: 'Nenhum produto encontrado. Crie um produto para começar.',
    save: 'Salvar',
    saving: 'Salvando...',
    saved: 'Salvo',
    created: 'Criado',
  },
  common: {
    name: 'Nome',
    id: 'Id',
    createdAt: 'Data de criação',
  },
  error: {
    general: 'Ocorreu um erro. Por favor, tente novamente mais tarde.',
  },
}
