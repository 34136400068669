import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { SetExternalMemberAreaInput } from '../../domain/dtos/member-area/external-member-area'
import { GeneralError } from '../../domain/errors/general'
import { Envs } from '../../envs'

export class SetExternalMemberArea {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(input: SetExternalMemberAreaInput): Promise<void> {
    const response = await this.httpClient.request({
      method: input.id ? HttpMethod.PATCH : HttpMethod.POST,
      url: `${Envs.BFF_PRODUCT_URL}/external-contents${input.id ? `/${input.id}` : ''}`,
      body: {
        ...input,
        accessInstructionMessage:
          input.accessInstructionMessage.length > 0 ? input.accessInstructionMessage : undefined,
      },
    })

    if (response.statusCode === HttpStatusCode.OK || response.statusCode === HttpStatusCode.CREATED) {
      return
    }

    throw new GeneralError()
  }
}
