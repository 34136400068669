import { AssetStatus } from '@hub-la/fe-asset'
import { PostResponse, PostStatus } from '@hub-la/fe-post'

export class GetPostType {
  public execute(post: PostResponse) {
    const type = post.isVideo ? 'Vídeo' : post.event ? 'Live' : 'Publicação'
    const status = post.cover?.status

    if (post.isVideo && status && [AssetStatus.processing, AssetStatus.uploading].includes(status)) {
      return 'Vídeo (Processando...)'
    }

    if (post.status === PostStatus.DRAFT) {
      return `${type} (Rascunho)`
    }

    return type
  }
}
