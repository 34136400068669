export const pt = {
  errors: {
    general: 'Erro inesperado. Tente novamente.',
  },
  fieldErrors: {
    required: 'Campo obrigatório',
    typeError: 'Valor inválido',
  },
  ticket: {
    header: {
      back: 'Voltar',
      create: 'Novo ingresso',
      edit: 'Editar ingresso',
    },
    title: {
      new: 'Criar ingresso',
      edit: 'Editar ingresso',
    },
    name: {
      label: 'Nome',
    },
    description: {
      label: 'Descrição',
    },
    maxCapacity: {
      checkbox: 'Limitar quantidade de ingressos disponíveis',
      label: 'Total de ingressos desse tipo',
    },
    custom: {
      title: 'Personalização',
      primaryColor: {
        label: 'Cor principal',
      },
      textColor: {
        label: 'Cor de texto',
      },
      logo: {
        label: 'Logo do criador',
        description: 'JPEG, PNG, GIF ou SVG acima de 300x300 pixels',
      },
    },
    accessesPreview: {
      title: 'Acessos',
      description: 'Entenda quais acessos os membros dessa turma terão acesso.',
      sections: {
        label: 'Módulo(s) de conteúdo',
        empty: 'Nenhum módulo selecionado',
      },
      groups: {
        label: 'Grupo(s)',
        empty: 'Nenhum grupo selecionado',
      },
      notIncluded: {
        title: 'Não incluídos no acesso da turma',
      },
    },
  },
}
