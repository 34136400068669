import parsePhoneNumber from 'libphonenumber-js'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import { CountryCode } from './country'

export class Phone {
  public constructor(private readonly number: string) {}

  public static build(number: string): Phone {
    return new Phone(number)
  }

  public getNationalNumber() {
    const parsedNumber = parsePhoneNumber(this.number)
    return parsedNumber ? Number(parsedNumber.nationalNumber) : undefined
  }

  public getNumber(): string {
    const parsedNumber = parsePhoneNumber(this.number)
    return parsedNumber ? `+${parsedNumber.countryCallingCode}${parsedNumber.nationalNumber}` : ''
  }

  public getFormattedNumber(): string {
    const parsedNumber = parsePhoneNumber(this.number)
    if (!parsedNumber) {
      return ''
    }
    if (parsedNumber.country === CountryCode.BR && parsedNumber.nationalNumber.length === 11) {
      return `+${parsedNumber.countryCallingCode} ${parsedNumber.formatNational()}`
    } else if (parsedNumber.country === CountryCode.BR && parsedNumber.nationalNumber.length === 10) {
      const countryCode = this.number.slice(0, 3)
      const ddd = this.number.slice(3, 5)
      const part1 = this.number.slice(5, 9)
      const part2 = this.number.slice(9)

      return `${countryCode} (${ddd}) ${part1}-${part2}`
    } else {
      return parsedNumber.formatInternational()
    }
  }

  public getIsoCountryCode() {
    const parsedNumber = parsePhoneNumber(this.number)
    return parsedNumber ? parsedNumber.country : undefined
  }

  public getCountryCode() {
    const parsedNumber = parsePhoneNumber(this.number)
    return parsedNumber ? Number(parsedNumber.countryCallingCode) : undefined
  }

  public isValid() {
    if (isEmpty(this.number)) {
      return false
    }

    try {
      const parsedNumber = parsePhoneNumber(this.number)

      if (!parsedNumber) {
        return false
      }

      const number = parsedNumber.nationalNumber

      if (isNil(parsedNumber.countryCallingCode)) {
        return false
      }

      if (isNil(number)) {
        return false
      }

      const length = number.toString().length

      return this.getIsoCountryCode() === CountryCode.BR ? [10, 11].includes(length) : parsedNumber.isValid()
    } catch (error) {
      return false
    }
  }
}
