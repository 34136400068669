import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation } from '@tanstack/react-query'
import { PostAcceptInviteInput } from '../../../domain/dtos/post-accept-invite-input'
import { QueryKey } from '../../../domain/enums/query-key'
import { PostAcceptInvite } from '../../../usecases/members/post-accept-invite'
export const usePostAcceptInvite = ({ onSuccess }: { onSuccess?: () => void }) => {
  const container = useContainer()

  return useMutation<null, Error, PostAcceptInviteInput, Error>(
    [QueryKey.postAcceptInvite],
    (input: PostAcceptInviteInput) => new PostAcceptInvite(container.get(HttpClient)).execute(input),
    {
      retry: false,
      onSuccess,
    },
  )
}
