export class CommentMention {
  public execute(comment: string) {
    // find name inside double curly braces and without double curly braces
    const mentions = comment.match(/{{(.*?)}}/g) || []
    const message = comment.replace(/{{(.*?)}}/g, '')

    const firstMention = mentions[0]
    const mention = firstMention?.replace(/{{(.*?)}}/g, '$1') || null

    // Backend replace curly braces with html entities to prevent fake mentions
    const messageWithCurlyBraces = message.replace(/&#123;/g, '{').replace(/&#125;/g, '}')

    return {
      mention,
      message: messageWithCurlyBraces,
    }
  }
}
