import { Badge, Card, CardContent, cn, RadioGroup, RadioGroupItem } from '@hub-la/shadcn'
import { useTranslation } from 'react-i18next'
import { Resource } from '../../../../../../domain/dtos/groups'
import MessagingIcon from '../messaging-icon'
import { GroupResourceDescription } from './group-resource-description'

type Props = {
  resource: Resource
  onChange: (resourceId: string) => void
  selected?: boolean
}

export const ResourceCard: React.FC<Props> = (props) => {
  const { resource, onChange, selected } = props
  const { t } = useTranslation()

  return (
    <Card
      className={cn('w-full cursor-pointer', selected ? 'border-primary' : '')}
      onClick={() => onChange(resource.id)}
    >
      <CardContent className="p-4">
        <div className="flex items-start gap-4">
          <RadioGroup value={selected ? resource.id : undefined} onValueChange={() => onChange(resource.id)}>
            <RadioGroupItem value={resource.id} id={resource.id} className="mt-1" />
          </RadioGroup>

          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-2">
              <h4 className="font-bold">
                {t(`member-area.groups.createGroupModal.description.${resource.type}.title`)}
              </h4>

              <Badge variant="secondary">
                <MessagingIcon type={resource.type} />
                {t(`member-area.groups.createGroupModal.description.${resource.type}.label`)}
              </Badge>
            </div>

            <GroupResourceDescription resourceType={resource.type} />
          </div>
        </div>
      </CardContent>
    </Card>
  )
}
