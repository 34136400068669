import { AssetResponse, AssetStatus, AssetType } from '@hub-la/fe-asset'
import { cn, Progress } from '@hub-la/shadcn'
import { Lock } from 'lucide-react'
import { useGetPdfFileUrl } from '../../hooks/use-get-pdf-file-url'
import { useMeasure } from '../../hooks/use-measure'
import { MediaDocumentPDF } from '../media-document-pdf/media-document-pdf'
import { Player } from '../player'
import { VideoProcessingCover } from '../video-processing-cover'

type PostDetailCoverProps = {
  cover?: AssetResponse
  customCover?: string
  sectionId: string
  postId: string
  hideContent?: boolean
  hideText?: string
  isLive?: boolean
}

export const PostDetailCover = ({
  cover,
  customCover,
  sectionId,
  postId,
  hideContent,
  hideText,
  isLive,
}: PostDetailCoverProps) => {
  const [ref, { height, width }] = useMeasure()
  const hasContent = !!(cover?.metadata?.thumbnailUrl ?? cover?.url ?? customCover)

  const { data: pdfFileUrl } = useGetPdfFileUrl(cover)

  /** @description: Hide the content for users that don't have access */
  if (hasContent && hideContent) {
    return (
      <div
        style={{
          height: width * 0.5625,
        }}
        ref={ref}
        id="detail-cover-box"
        data-testid="content-hidden"
        className={cn(
          'relative w-full rounded-none lg:rounded-lg overflow-hidden flex',
          isLive
            ? 'bg-gradient-to-r from-[#d8fd6c] via-[#dde2fe] to-[#79fbac] bg-[length:400%_400%] animate-background-wave border-4 border-transparent'
            : 'bg-transparent border',
        )}
      >
        <img
          className="w-full h-full aspect-video object-cover rounded-none lg:rounded-lg filter blur-3xl brightness-50"
          src={cover?.metadata?.thumbnailUrl ?? cover?.url ?? customCover}
          alt="hidden"
        />

        <div className="absolute top-1/2 left-1/2 flex flex-col gap-2 items-center transform -translate-x-1/2 -translate-y-1/2">
          <Lock />

          <p className="text-sm">{hideText}</p>
        </div>
      </div>
    )
  }

  /** @description: If it does not have a cover, but has a customCover, display it */
  if (!cover && customCover) {
    return (
      <div
        id="detail-cover-box"
        data-testid="custom-cover"
        className={cn(
          'relative w-full aspect-video overflow-hidden',
          isLive
            ? 'bg-gradient-to-r from-[#d8fd6c] via-[#dde2fe] to-[#79fbac] bg-[length:400%_400%] animate-background-wave border-4 border-transparent'
            : 'bg-transparent border',
        )}
      >
        <div
          className="absolute inset-0 blur-[2px] opacity-70 scale-150 transform"
          style={{
            backgroundImage: `url(${customCover})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />

        <div className="relative w-full h-full">
          <img
            src={customCover}
            alt="capa"
            className="w-full h-full aspect-video object-contain relative z-10"
            data-testid="image-viewer"
          />
        </div>
      </div>
    )
  }

  return (
    <div
      style={{
        height: width * 0.5625,
      }}
      ref={ref}
      id="detail-cover-box"
      key={cover?.id}
      className={cn(
        'w-full rounded-none lg:rounded-lg flex',
        isLive
          ? 'bg-gradient-to-r from-[#d8fd6c] via-[#dde2fe] to-[#79fbac] bg-[length:400%_400%] animate-background-wave border-4 border-transparent'
          : 'bg-transparent  bg-background',
      )}
    >
      {/** @description: Image */}
      {cover?.type === AssetType.image && (
        <div className="relative w-full aspect-video overflow-hidden">
          <div
            className="absolute inset-0 blur-[2px] opacity-70 scale-150 transform"
            style={{
              backgroundImage: `url(${cover.url})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          />

          <div className="relative w-full h-full">
            <img
              src={cover.url}
              alt={cover.name}
              className="w-full h-full aspect-video object-contain relative z-10"
              data-testid="image-viewer"
            />
          </div>
        </div>
      )}

      {/** @description: PDF file */}
      {cover?.type === AssetType.application && (
        <div
          className="w-full aspect-auto p-2 bg-background shadow-2xl sha rounded-none lg:rounded-lg flex h-full"
          data-testid="pdf-viewer"
        >
          {!pdfFileUrl && <Progress className="h-1 w-full" indicatorColor="bg-red-600" />}

          {pdfFileUrl && (
            <MediaDocumentPDF
              previewHeight={height - 52}
              progress={cover.progress?.currentProgress || 1}
              assetId={cover.id}
              url={pdfFileUrl}
              sectionId={sectionId}
              postId={postId}
            />
          )}
        </div>
      )}

      {/** @description: Video that have been processed */}
      {cover?.type === AssetType.video && cover.status !== AssetStatus.processing && (
        <div data-testid="video-player" className="h-full aspect-video flex">
          <Player
            progress={cover.progress?.currentProgress}
            duration={cover.metadata?.duration}
            assetId={cover.id}
            src={cover.url ?? ''}
            poster={cover.metadata?.thumbnailUrl}
            title={cover.name}
            sectionId={sectionId}
            postId={postId}
          />
        </div>
      )}

      <VideoProcessingCover cover={cover} />
    </div>
  )
}
