import { createContext, ReactNode, useEffect, useState } from 'react'
import { useAuth } from '../presentation/hooks/use-auth'
import { useAuthRedirect } from '../presentation/hooks/use-auth-redirect'

export const AuthRedirectContext = createContext<{
  isFirstLoading: boolean
  parentLoaded: boolean
}>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  isFirstLoading: true,
  parentLoaded: false,
})

type AuthRedirectProviderProps = {
  children: ReactNode
}

// Responsible for redirecting if authenticated when mount auth pages
const AuthRedirectProvider = ({ children }: AuthRedirectProviderProps) => {
  const { currentUser, isAuthenticated } = useAuth()
  const { redirectAutoIfAuthenticated: redirectAutoIfAuthenticatedHook } = useAuthRedirect()

  const [isFirstLoading, setFirstLoading] = useState(true)
  const [hasCheckedAuth, setHasCheckedAuth] = useState(false)
  const [hasCheckedAuthAndIsAuthenticated, setHasCheckedAuthAndIsAuthenticated] = useState(false)

  useEffect(() => {
    // Ignore when authentication has already been checked on mount or the current user is not yet loaded (undefined)
    if (hasCheckedAuth || currentUser === undefined) return
    setHasCheckedAuth(true)

    // If there is no current user, exit early (user is not authenticated)
    if (!currentUser) return

    // If the user is authenticated, redirect automatically if necessary
    redirectAutoIfAuthenticatedHook()
  }, [hasCheckedAuth, currentUser, isAuthenticated, redirectAutoIfAuthenticatedHook])

  useEffect(() => {
    if (isAuthenticated === null || hasCheckedAuthAndIsAuthenticated) return
    setFirstLoading(isAuthenticated)
    setHasCheckedAuthAndIsAuthenticated(true)
  }, [isAuthenticated, hasCheckedAuthAndIsAuthenticated])

  return (
    <AuthRedirectContext.Provider value={{ isFirstLoading, parentLoaded: true }}>
      {children}
    </AuthRedirectContext.Provider>
  )
}

export { AuthRedirectProvider }
