import { Badge, cn } from '@hub-la/shadcn'
import { Minus, TrendingDown, TrendingUp } from 'lucide-react'
import React from 'react'
import { Status } from '../../domain/dtos/status'

type StatusBoxProps = {
  description: string
  status?: 'positive' | 'negative' | 'neutral'
  indicator?: 'up' | 'down'
}

const getStatusColor = (status: string) => {
  switch (status) {
    case 'positive':
      return 'bg-green-600/10 text-green-600'
    case 'negative':
      return 'bg-orange-600/10 text-orange-600'
    default:
      return 'bg-gray-600/10 text-secondary-foreground'
  }
}

const getIcon = (status: string, indicator?: string) => {
  switch (status) {
    case 'positive':
      return indicator === 'down' ? <TrendingDown className="w-4 h-4 mr-1" /> : <TrendingUp className="w-4 h-4 mr-1" />
    case 'negative':
      return indicator === 'up' ? <TrendingUp className="w-4 h-4 mr-1" /> : <TrendingDown className="w-4 h-4 mr-1" />
    default:
      return <Minus className="w-4 h-4 mr-1" />
  }
}

export const StatusBox: React.FC<StatusBoxProps> = ({ indicator, status = 'neutral', description }) => {
  const [firstPart, ...rest] = description.split('vs.')
  const secondPart = rest.join('vs.')

  return (
    <div className="flex gap-1">
      {status !== Status.neutral && (
        <Badge
          variant="secondary"
          className={cn(
            'flex items-center px-1.5 py-0.5 font-medium tracking-normal text-xs hover:bg-current/10',
            getStatusColor(status),
          )}
        >
          <span className="flex items-center">
            {getIcon(status, indicator)}
            {firstPart.trim()}
          </span>
        </Badge>
      )}
      <span className="-tracking-normal flex text-xs font-normal items-center text-muted-foreground">
        {secondPart ? `vs. ${secondPart.trim()}` : ''}
      </span>
    </div>
  )
}
