export enum ExportStatus {
  pending = 'pending',
  complete = 'complete',
  error = 'error',
}

export enum ExportData {
  none = 'none',
  invoices = 'invoices',
  subscriptions = 'subscriptions',
  affiliates = 'affiliates',
  refunds = 'refunds',
  members = 'members',
  waitlist = 'waitlist',
}

export enum ExportTypes {
  csv = 'csv',
  json = 'json',
  xlsx = 'xlsx',
}

export interface ExportRequest {
  id: string
  userId: string
  status: ExportStatus
  link?: string
  data: ExportData
  type: ExportTypes
  param?: string
  errorData?: string
  isEnabled: boolean
  isDeleted: boolean
  createdAt: {
    _seconds: number
    _nanoseconds: number
  }
  updatedAt: {
    _seconds: number
    _nanoseconds: number
  }
}
