import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Input } from '@hub-la/shadcn'
import { ArrowLeft, Loader2 } from 'lucide-react'
import { useEffect, useMemo } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { ticketSchemaValidation, TicketSchemaValidationType } from '../../validations/ticket-validation'
import { QuantityField } from './components/quantity-field'
import { CustomContent } from './custom/custom-content'
import { CustomPreview } from './custom/custom-preview'

interface TicketHeader {
  disabled?: boolean
  isEdit?: boolean
  isLoading?: boolean
  isSubmitting?: boolean
}

const TicketHeader = ({ disabled, isEdit, isLoading, isSubmitting }: TicketHeader) => {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <div className="flex justify-between items-center py-4 px-8 border-b">
      <Button variant="ghost" onClick={() => history.goBack()} className="flex items-center">
        <ArrowLeft className="mr-2 h-4 w-4" />

        {t('ticket.header.back')}
      </Button>

      <Button type="submit" disabled={disabled} loading={isLoading || isSubmitting}>
        {isEdit ? t('ticket.header.edit') : t('ticket.header.create')}
      </Button>
    </div>
  )
}

type TicketFormProps = {
  isEdit?: boolean
  isLoading?: boolean
  isSubmitting?: boolean
  productId: string
  defaultValues?: TicketSchemaValidationType
  onSubmit: (data) => void
}

export const TicketForm = ({
  isEdit,
  productId,
  defaultValues,
  onSubmit,
  isLoading,
  isSubmitting,
}: TicketFormProps) => {
  const { t } = useTranslation()

  const form = useForm<TicketSchemaValidationType>({
    defaultValues: useMemo(() => defaultValues, [defaultValues]),
    resolver: yupResolver(ticketSchemaValidation),
    mode: 'onChange',
  })
  const { isValid, isDirty } = form.formState

  useEffect(() => {
    form.reset(defaultValues)
  }, [defaultValues, form])

  if (isLoading) {
    return (
      <FormProvider {...form}>
        <div className="min-h-screen">
          <TicketHeader isEdit={isEdit} isSubmitting={isLoading} />

          <div className="flex justify-center items-center min-h-screen">
            <Loader2 className="h-16 w-16 animate-spin" />
          </div>
        </div>
      </FormProvider>
    )
  }

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="min-h-screen">
        <TicketHeader
          disabled={!isValid || !isDirty}
          isEdit={isEdit}
          isLoading={isLoading}
          isSubmitting={isSubmitting}
        />

        <div className="w-full h-full min-h-[calc(100vh-75px)] flex flex-col sm:flex-row">
          <div className="px-14 py-8 pb-52 w-full sm:max-w-2xl">
            <h2 className="mb-8 text-2xl font-bold">{isEdit ? t('ticket.title.edit') : t('ticket.title.new')}</h2>

            <Controller
              name="name"
              render={({ field, fieldState }) => (
                <div className="mb-4">
                  <Input
                    {...field}
                    placeholder={t('ticket.name.label')}
                    className={fieldState.error ? 'border-red-500' : ''}
                  />
                  {fieldState.error && (
                    <p className="text-red-500 text-sm mt-1">{t(`fieldErrors.${fieldState.error.type}`)}</p>
                  )}
                </div>
              )}
            />

            <Controller
              name="description"
              render={({ field, fieldState }) => (
                <div className="mb-8">
                  <Input
                    {...field}
                    placeholder={t('ticket.description.label')}
                    className={fieldState.error ? 'border-red-500' : ''}
                  />
                  {fieldState.error && (
                    <p className="text-red-500 text-sm mt-1">{t(`fieldErrors.${fieldState.error.type}`)}</p>
                  )}
                </div>
              )}
            />

            <QuantityField />

            <div className="mt-8 flex flex-col gap-12">
              <CustomContent />
            </div>
          </div>

          <div className="px-14 py-8 flex-1 background border-l">
            <CustomPreview productId={productId} />
          </div>
        </div>
      </form>
    </FormProvider>
  )
}
