import { container } from '@hub-la/fe-container'
import { IntegrationsListPage, IntegrationsProvider } from '@hub-la/fe-integrations'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

export const DashboardIntegrationsInit = () => {
  const history = useHistory()
  const urlParams = new URLSearchParams(window.location.search)
  const provider = urlParams.get('provider')

  useEffect(() => {
    if (provider) {
      history.push(
        history.location.search
          ? `/dashboard/integrations/${provider}` + history.location.search
          : `/dashboard/integrations/${provider}`,
      )
    }
  }, [history, provider])

  return (
    <IntegrationsProvider container={container}>
      <IntegrationsListPage />
    </IntegrationsProvider>
  )
}
