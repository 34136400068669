import { Button } from '@hub-la/shadcn'
import { Reply, ThumbsUp } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { usePostComment } from '../../hooks/use-post-comment'
import { useDiscussion } from '../../providers/discussion'
import { InputComment } from '../input-comment/input-comment'

type CommentActionsProps = {
  likes?: number
  onLike?: () => void
  isLiked?: boolean
  isReply?: boolean
  parentCommentId: string
  onReplySuccess?: () => void
  parentUserId?: string
  parentUserName?: string
}

export const CommentActions = ({
  likes,
  onLike,
  isLiked,
  isReply,
  parentCommentId,
  onReplySuccess,
  parentUserId,
  parentUserName,
}: CommentActionsProps) => {
  const [replying, setReplying] = useState(false)
  const { t } = useTranslation()
  const { currentUser } = useDiscussion()

  const { mutateAsync, isLoading } = usePostComment({
    onSuccess: () => {
      if (isReply || !onReplySuccess) return
      onReplySuccess()
    },
  })

  const hasLikes = likes && likes > 0

  const handleReply = () => {
    setReplying(true)
  }

  const commentButtonLabel = replying ? t('replyInputComment.submit') : t('inputComment.submit')

  return (
    <div className="w-full">
      <div className="flex gap-2 mb-2">
        {/** Disabled like button to be implemented in the future */}
        {false && (
          <Button variant="ghost" size="sm" onClick={onLike} className="flex items-center">
            {isLiked ? <ThumbsUp className="w-4 h-4 mr-1" /> : <ThumbsUp className="w-4 h-4 mr-1" />}
            {hasLikes && <span>{likes}</span>}
          </Button>
        )}

        {!replying && (
          <Button variant="ghost" size="sm" onClick={handleReply} className="flex items-center">
            <Reply className="w-4 h-4 mr-1" />
            {t('comment.actions.reply')}
          </Button>
        )}
      </div>
      {(isLoading || replying) && (
        <InputComment
          avatarSize={10}
          onCancel={() => setReplying(false)}
          onComment={async (message, hasMention) => {
            setReplying(false)
            await mutateAsync({ message, parentCommentId, mentionUserId: hasMention ? parentUserId : undefined })
          }}
          isLoading={isLoading}
          avatarUrl={currentUser?.picture}
          commentButtonLabel={commentButtonLabel}
          isReply={replying}
          inputLabel={t('replyInputComment.label')}
          mentionUserId={parentUserId}
          mentionUserName={parentUserName}
        />
      )}
    </div>
  )
}
