import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { QueryKey } from '../../../domain/enums/query-key'
import { UpdateShortLink } from '../../../usecases/shortlinks/update-short-link'
import { CreateShortLinkValues } from '../../pages/shortlinks/components/create-edit-shortlink-modal'

export const useUpdateShortLink = (productId: string, shortLinkId: string | undefined) => {
  const container = useContainer()
  const queryClient = useQueryClient()

  return useMutation(
    [QueryKey.putShortLink, shortLinkId],
    (input: CreateShortLinkValues) =>
      new UpdateShortLink(container.get(HttpClient)).execute(productId, shortLinkId, input),
    {
      retry: false,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [QueryKey.getShortLinks] })
      },
    },
  )
}
