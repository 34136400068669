import React, { memo } from 'react'
import { DelimitedArrayParam, StringParam, useQueryParams, withDefault } from 'use-query-params'
import { Filters } from '../components/filters'
import { useTranslation } from 'react-i18next'
import { AffiliatesTable } from './affiliates-table'

export const Affiliates = memo(() => {
  const [filters, setFilters] = useQueryParams({
    search: withDefault(StringParam, ''),
    statuses: withDefault(DelimitedArrayParam, []),
  })
  const { t } = useTranslation()

  return (
    <div className="flex flex-col gap-4 mt-4">
      <Filters
        label={'Afiliado(s)'}
        filters={filters}
        setFilters={setFilters}
        status={Object.values(['pending', 'enabled', 'disabled']).map((status) => ({
          id: status,
          label: t(`affiliates.status.${status}`),
        }))}
      />
      <AffiliatesTable filters={filters} />
    </div>
  )
})
