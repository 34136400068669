import { Post, Section } from '../domain/dtos'

export class PaginationHelper {
  static getFirstPost(posts: Post[]): Post | null {
    return posts?.at(0) ?? null
  }

  static getLastPost(posts: Post[]): Post | null {
    return posts?.[posts.length - 1] ?? null
  }

  static getCurrentIndex(data: Section[], postId: string): number {
    return data.findIndex((obj) => obj.posts.some((post) => post.id === postId))
  }

  static getPrevious(
    isFirstPost: boolean,
    previousSection: Section | null,
    section: Section | undefined,
    pagination: { previous?: string | null },
  ): Post | null {
    if (isFirstPost && previousSection) {
      return this.getLastPost(previousSection.posts)
    }
    return section?.posts.find((post) => post.id === pagination?.previous) ?? null
  }

  static getNext(
    isLastPost: boolean,
    nextSection: Section | null,
    section: Section | undefined,
    pagination: { next?: string | null },
  ): Post | null {
    if (isLastPost && nextSection) {
      return this.getFirstPost(nextSection.posts)
    }
    return section?.posts.find((post) => post.id === pagination?.next) ?? null
  }

  static getPreviousSection(data: Section[], currentIndex: number): Section | null {
    return currentIndex >= 0 ? data[currentIndex - 1] : null
  }

  static getNextSection(data: Section[], currentIndex: number): Section | null {
    return currentIndex < data?.length - 1 ? data[currentIndex + 1] : null
  }

  static getIsFirstPost(posts: Post[], postId: string): boolean {
    return this.getFirstPost(posts)?.id === postId
  }

  static getIsLastPost(posts: Post[], postId: string): boolean {
    return this.getLastPost(posts)?.id === postId
  }

  static getPaginationData(
    data: Section[],
    postId: string,
    pagination: { next?: string | null; previous?: string | null },
  ): {
    nextPost: Post | null
    previousPost: Post | null
    isFirstPost: boolean
    isLastPost: boolean
    nextSection: Section | null
    previousSection: Section | null
  } {
    const currentIndex = this.getCurrentIndex(data, postId)
    const section = data?.[currentIndex]
    const previousSection = this.getPreviousSection(data, currentIndex)
    const nextSection = this.getNextSection(data, currentIndex)
    const isFirstPost = this.getIsFirstPost(section?.posts ?? [], postId)
    const isLastPost = this.getIsLastPost(section?.posts ?? [], postId)
    const previousPost = this.getPrevious(isFirstPost, previousSection, section, pagination)
    const nextPost = this.getNext(isLastPost, nextSection, section, pagination)

    return {
      nextPost,
      previousPost,
      isFirstPost,
      isLastPost,
      nextSection,
      previousSection,
    }
  }
}
