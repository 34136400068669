import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject, injectable } from 'inversify'

import { PostResponse } from '../domain/dtos'
import { GetPostInput } from '../domain/dtos/get-post-input'
import { GetPostError } from '../domain/errors/get-post'
import { Envs } from '../envs'

@injectable()
export class GetPostUseCase {
  public constructor(@inject(HttpClient) private readonly httpClient: HttpClient) {}

  public async fetch(input: GetPostInput): Promise<PostResponse> {
    const response = await this.httpClient.request({
      method: HttpMethod.GET,
      url: this.makeUrl(input),
    })

    if (
      (response.statusCode === HttpStatusCode.OK || response.statusCode === HttpStatusCode.CREATED) &&
      response?.data
    ) {
      return response?.data
    }

    throw new GetPostError(response?.data?.errorCode)
  }

  private makeUrl(input: GetPostInput) {
    return `${Envs.BFF_MEMBERS_AREA_URL}/hub/posts/${input.postId}`
  }
}
