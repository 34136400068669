import {
  Badge,
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableEmptyState,
  TableHead,
  TableHeader,
  TableRow,
} from '@hub-la/shadcn'
import { isEmpty } from 'lodash'
import { Edit, MoreVertical, Send, Trash2 } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { ExternalReference } from '../../../domain/dtos/external-reference'
import { IntegrationProvider } from '../../../domain/dtos/integration-provider'
import { Rule } from '../../../domain/dtos/rule'
import { GetEventName } from '../../../usecases/get-event-name'
import { CreateEditRuleType } from '../create-edit-rule-modal'
import { RemainingElements } from '../remaining-elements'
import { RuleTableLoading } from './rule-table-loading'

type Props = {
  provider: IntegrationProvider
  isFetching: boolean
  items: Rule[]
  schema: string[]
  onChangeRuleStatus: (rule: Rule) => (checked: boolean) => void
  onRuleDetail: (state: { isOpen: boolean; rule: Rule }) => void
  onRuleEdit: (state: { isOpen: boolean; rule: Rule | null; type: CreateEditRuleType }) => void
  onRuleDelete: (state: { isOpen: boolean; rule: Rule }) => void
  onRuleSendTest: (state: { isOpen: boolean; rule: Rule }) => void
}

export const TableRule = (props: Props) => {
  const { provider, isFetching, items, schema } = props
  const { onChangeRuleStatus, onRuleDetail, onRuleEdit, onRuleDelete, onRuleSendTest } = props
  const { t } = useTranslation()

  const isRowsEmpty = isEmpty(items)
  const canRenderEmptyState = !isFetching && isRowsEmpty
  const isLoading = isFetching && isRowsEmpty

  const handleOpenedRuleDetail = (rule: Rule) => () => onRuleDetail({ isOpen: true, rule })

  const getHeadCells = () => {
    const properties = schema.map((property) => {
      if (property === 'lists' || property === 'tags') {
        return {
          key: property,
          label: t(`integrations.${provider}.rule.columns.${property}`),
          sortable: false,
        }
      }

      return {
        key: property,
        label: t(`rules.table.columns.${property}`),
        sortable: false,
      }
    })

    return [
      ...properties,
      {
        key: 'status',
        label: t('rules.table.columns.status'),
        sortable: false,
        width: 100,
      },
      {
        key: 'actions',
        label: t('rules.table.columns.actions'),
        sortable: false,
        width: 80,
      },
    ]
  }

  const renderTableCell = (rule: Rule, property: string, index: number) => {
    const cellProps = {
      key: index,
      className: 'cursor-pointer',
      onClick: handleOpenedRuleDetail(rule),
    }

    switch (property) {
      case 'products':
      case 'lists':
      case 'offers':
        return (
          <TableCell {...cellProps}>
            <RemainingElements data={rule[property]}>
              {(item: ExternalReference) => <span>{item.name}</span>}
            </RemainingElements>
          </TableCell>
        )
      case 'events':
        return (
          <TableCell {...cellProps}>
            <RemainingElements data={rule[property]}>
              {(label: string) => {
                const eventName = new GetEventName().execute(label)
                return eventName ? <Badge variant="secondary">{t(eventName)}</Badge> : '-'
              }}
            </RemainingElements>
          </TableCell>
        )
      case 'tags':
        return (
          <TableCell {...cellProps}>
            {rule[property]?.length ? (
              <RemainingElements data={rule[property]}>
                {(item: ExternalReference) => <Badge variant="outline">{item.name}</Badge>}
              </RemainingElements>
            ) : (
              '-'
            )}
          </TableCell>
        )
      default:
        return (
          <TableCell {...cellProps}>
            <span>{rule[property]}</span>
          </TableCell>
        )
    }
  }

  const ActionButtons = ({ rule }: { rule: Rule }) => (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" size="icon" className="h-8 w-8" aria-label="Outras opções">
          <MoreVertical className="h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-[200px]">
        {provider === IntegrationProvider.WEBHOOK && (
          <DropdownMenuItem className="cursor-pointer" onSelect={() => onRuleSendTest({ isOpen: true, rule })}>
            <Send className="mr-2 h-4 w-4" />
            {t('buttons.sandbox')}
          </DropdownMenuItem>
        )}
        <DropdownMenuItem
          className="cursor-pointer"
          onSelect={() => onRuleEdit({ isOpen: true, type: CreateEditRuleType.EDIT, rule })}
        >
          <Edit className="mr-2 h-4 w-4" />
          {t('rules.buttons.edit')}
        </DropdownMenuItem>
        <DropdownMenuItem className="cursor-pointer" onClick={() => onRuleDelete({ isOpen: true, rule })}>
          <Trash2 className="mr-2 h-4 w-4" />
          {t('rules.buttons.delete')}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )

  return (
    <div className="rounded-lg border bg-background overflow-auto">
      <Table>
        <TableHeader>
          <TableRow>
            {getHeadCells().map((cell) => (
              <TableHead key={cell.key} className="bg-background">
                {cell.label}
              </TableHead>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {isLoading
            ? Array.from({ length: 25 }).map((_, i) => <RuleTableLoading key={`skeleton-row-${i}`} schema={schema} />)
            : items.map((rule) => (
                <TableRow key={rule.id}>
                  {schema.map((property, idx) => renderTableCell(rule, property, idx))}
                  <TableCell>
                    <Switch
                      checked={rule.enabled}
                      onCheckedChange={(checked) => onChangeRuleStatus(rule)(checked)}
                      aria-label={`Toggle rule ${rule.id}`}
                    />
                  </TableCell>
                  <TableCell className="p-0">
                    <div className="w-full flex justify-end pr-4">
                      <ActionButtons rule={rule} />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
        </TableBody>
      </Table>
      {canRenderEmptyState && (
        <TableEmptyState title={t('rules.table.empty.title')} subtitle={t('rules.table.empty.subtitle')} />
      )}
    </div>
  )
}
