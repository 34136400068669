import { Asset, AssetResponse, AssetStatus, AssetType, ImaginaryImageProcessor } from '@hub-la/fe-asset'
import { EventDate } from './event-date'
import { Fallback } from './fallback'
import { Lock } from './locked'
import { Pdf } from './pdf'

type Props = {
  /** The width to be applied to the thumbnail element */
  width: number
  /** The height to be applied to the thumbnail element */
  height: number | string
  /** The asset to be rendered on the thumbnail */
  asset?: Asset | AssetResponse | null
  /** Execute function when the thumbnail is clicked */
  onClick?: () => void
  /** If the thumbnail should have a progress bar */
  hasProgressBar?: boolean
  /** The progress of the thumbnail */
  progress?: number | null
  /** The max progress of the thumbnail */
  maxProgress?: number | null
  /** The event start time of the thumbnail. (Live feature) */
  eventStartTime?: Date
  /** If the thumbnail should have a lock icon */
  lockIcon?: boolean
  /** If the thumbnail should have a lock content. (Blur thumbnail asset) */
  lockContent?: boolean
  /** If viewed is true the progress bar will be 100% */
  viewed?: boolean
  /** The width to be applied to the image processor */
  resizeWidth?: number
  /** The height to be applied to the image processor */
  resizeHeight?: number
}

export const Thumbnail = ({
  width,
  height,
  onClick,
  hasProgressBar = true,
  progress,
  eventStartTime,
  lockIcon,
  lockContent,
  asset,
  maxProgress,
  viewed,
  resizeHeight,
  resizeWidth,
}: Props) => {
  const thumbnailImageUrl = asset?.metadata?.thumbnailUrl

  const isPdf = AssetType.application === asset?.type
  const isVideo = AssetType.video === asset?.type

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
  const isProcessing = [AssetStatus.processing, AssetStatus.uploading].includes(asset?.status!)
  const pdf = asset?.url
  const locked = lockIcon || lockContent

  if (isVideo && isProcessing) {
    return (
      <div
        className="relative overflow-hidden rounded-md border border-input/20"
        style={{ width, height, minWidth: width }}
        onClick={onClick}
      >
        <Fallback isVideo={true} />
      </div>
    )
  }

  if (isPdf) {
    return pdf || thumbnailImageUrl ? (
      <div
        className="relative overflow-hidden rounded-md border border-input/20"
        style={{ width, height, minWidth: width }}
        onClick={onClick}
        data-testid="thumbnail-pdf"
      >
        {locked && <Lock fullSize={lockContent} />}

        {thumbnailImageUrl ? (
          <ImaginaryImageProcessor
            className="object-cover"
            style={{ width, height, minWidth: width }}
            sourceUrl={thumbnailImageUrl}
            ownerId={asset?.ownerId}
            sourceName={asset?.name}
            processParameter={{
              outputType: 'webp',
              width: resizeWidth ?? width,
              quality: 90,
            }}
          />
        ) : (
          <Pdf url={asset?.url} width={width} height={height} />
        )}
      </div>
    ) : (
      <div
        className="relative overflow-hidden rounded-md border border-input/20"
        style={{ width, height, minWidth: width }}
        onClick={onClick}
      >
        {locked && <Lock fullSize={lockContent} />}

        <Fallback />
      </div>
    )
  }

  return thumbnailImageUrl ?? asset?.url ? (
    <div
      className="relative overflow-hidden rounded-md border border-input/20"
      style={{ width, height, minWidth: width }}
      onClick={onClick}
    >
      {locked && <Lock fullSize={lockContent} />}

      {eventStartTime && !locked && <EventDate date={eventStartTime} />}

      <ImaginaryImageProcessor
        className="object-cover"
        style={{ width, height, minWidth: width }}
        data-testid="thumbnail-image"
        sourceUrl={thumbnailImageUrl ?? asset?.url ?? ''}
        ownerId={asset?.ownerId}
        sourceName={asset?.name}
        processParameter={{
          outputType: 'webp',
          width: resizeWidth ?? width,
          quality: 90,
        }}
      />
    </div>
  ) : (
    <div
      className="relative overflow-hidden rounded-md border border-input/20"
      style={{ width, height, minWidth: width }}
      onClick={onClick}
    >
      {locked && <Lock fullSize={lockContent} />}

      <Fallback />
    </div>
  )
}
