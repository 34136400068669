import { Badge, CopyClipboardButton, Separator } from '@hub-la/shadcn'
import { Tag } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { OfferItem } from '../../../domain/dtos/invoice'
import { formatCurrency } from '../../../domain/vos/format-currency'

type ChargeDetailProps = {
  offers: OfferItem[]
}

export const Offers = ({ offers }: ChargeDetailProps) => {
  const { t } = useTranslation()

  if (!offers || !offers?.length) {
    return null
  }

  return (
    <div className="space-y-4">
      <h2 className="text-lg font-semibold">Ofertas</h2>
      <Separator />
      <div className="space-y-2">
        {offers.map((offer) => (
          <div className="flex items-center space-x-2 max-sm:flex-col max-sm:items-start max-sm:space-y-2">
            <div className="flex items-center space-x-2 flex-1 max-md:max-w-full">
              <Tag size={16} className="mr-2 text-muted-foreground" />
              <p className="text-sm truncate md:whitespace-break-spaces">
                {offer.productName} - {offer?.name} •{' '}
                {offer.prices?.at(0) &&
                  `${formatCurrency(offer.prices?.at(0)?.price ?? 0)} ${
                    offer.prices?.at(0)?.planInterval ? `/ ${t(`plan.${offer.prices?.at(0)?.planInterval}`)}` : ''
                  }`}
              </p>
              {offer.orderBumpTagNumber && (
                <Badge variant="secondary" className="whitespace-pre">
                  Order bump {offer.orderBumpTagNumber}
                </Badge>
              )}
            </div>
            <CopyClipboardButton
              copyContent={offer.id}
              label={offer.id}
              tooltipCopiedLabel={t('copyClipboard.offerIdCopied')}
              tooltipCopyLabel={t('copyClipboard.copyOfferId')}
            />
          </div>
        ))}
      </div>
    </div>
  )
}
