import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { Envs } from '../envs'
import { SubscriptionValue } from '../domain/dtos/subscription-value'

export class GetSubscriptionValue {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(id: string): Promise<SubscriptionValue> {
    const response = await this.httpClient.request({
      method: HttpMethod.GET,
      url: `${Envs.BFF_WEB_URL}/pay/get-subscription-value`,
      params: { subscriptionId: id },
    })

    if ([HttpStatusCode.OK, HttpStatusCode.CREATED].includes(response.statusCode)) {
      return response.data
    }

    throw new Error('Erro ao buscar dados da assinatura')
  }
}
