import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation } from '@tanstack/react-query'
import { CreateProductInput } from '../../domain/dtos/create-product-input'
import { QueryKey } from '../../domain/enums/query-key'
import { CreateProduct } from '../../usecases/create-product'

export const useCreateProduct = (props?: { onSuccess?: (id: string) => void; onError?: (error: Error) => void }) => {
  const container = useContainer()

  return useMutation<string, Error, CreateProductInput, Error>(
    [QueryKey.createProduct],
    (input: CreateProductInput) => new CreateProduct(container.get(HttpClient)).execute(input),
    {
      retry: false,
      onSuccess: props?.onSuccess,
      onError: props?.onError,
    },
  )
}
