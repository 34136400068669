const regex = /^[\w\-.+]+@([\w-]+\.)+[\w-]{2,24}$/

export class FilterArrayByEmailValidation {
  /**
   * Filter emails array by regex and remove duplicate emails
   *
   * @param emails emails array to be filtered
   * @param duplicateEmails emails to be removed from the array
   * @returns filtered emails array
   */
  public execute(emails: string[], duplicateEmails?: string[]) {
    if (!emails || emails.length === 0) return []

    return (
      emails
        // remove spaces of email string
        .map((email) => email.trim())
        // remove empty emails
        .filter((email) => email !== '')
        // remove invalid emails by regex
        .filter((email) => regex.test(email))
        // remove duplicate emails when has duplicateEmails
        .filter((email) => !duplicateEmails?.includes(email))
    )
  }
}
