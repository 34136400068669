import { useTranslation } from 'react-i18next'
import { Offer } from '../../../../domain/dtos/offer'
import { Status } from '../../../../domain/dtos/status'
import { formatLocalNumber } from '../../../../domain/vos/format-local-number'
import { CardInfoBox } from '../../../components/card-info-box'
import { useGetTotalSale } from '../../../hooks/use-get-total-sale'
import { useRefetch } from '../../../hooks/use-refetch'
import { Loading } from './loading'

type TotalSaleProps = {
  startDate: string
  endDate: string
  offerIds: string[]
  offers: Offer[]
  isLoading: boolean
}

export const TotalSale = (props: TotalSaleProps) => {
  const { startDate, endDate, offers, offerIds, isLoading } = props
  const { t } = useTranslation()

  const { data, isFetching, refetch } = useGetTotalSale({ startDate, endDate, offers, offerIds }, isLoading)

  useRefetch({ startDate, endDate, offers, offerIds }, refetch)

  if (isFetching || isLoading) {
    return <Loading />
  }

  const getVariation = () => {
    return data.status !== Status.neutral
      ? {
          indicator: data.indicator,
          status: data.status,
          description: `${data.percentage} ${t('variation', { previous: formatLocalNumber(data.previous) })}`,
        }
      : undefined
  }

  return (
    <CardInfoBox
      title={t('totalSale.title')}
      description={t('totalSale.description')}
      current={formatLocalNumber(data.current)}
      variation={getVariation()}
    />
  )
}
