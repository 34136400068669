import { useContainer } from '@hub-la/fe-container'
import { Auth } from '@hub-la/fe-core-auth'
import Cookies from 'js-cookie'
import { createContext, useContext, useEffect } from 'react'
import { Envs } from '../envs'
import { useAutoAuthenticateSession } from '../hooks/use-auto-authenticate-session'

const AuthContext = createContext({})

export const useAuth = () => {
  return useContext(AuthContext)
}

const leaveNewExperience = () => {
  Cookies.set('new_experience', 'false', {
    expires: 365,
    domain: Envs.COOKIE_DOMAIN,
    sameSite: 'lax',
  })

  const url = new URL(window.location.href)
  const hostnameParts = url.hostname.split('.')

  // Check if the current subdomain is `app`
  if (hostnameParts[0] === 'app2') {
    // Change the subdomain to `app2`
    hostnameParts[0] = 'app'
  } else {
    // Add `app2` as the subdomain
    hostnameParts.unshift('app')
  }
  url.hostname = hostnameParts.join('.')

  // Check if the current path contains "dashboard"
  if (url.pathname.includes('dashboard')) {
    url.pathname = '/dashboard'
  } else {
    url.pathname = '/user_groups'
  }

  try {
    ;(window as any).Appcues?.track('leave_new_experience')
    console.log('Left new experience')
  } catch (e) {
    console.error('Appcues is not setup up correctly')
  }

  // Redirect to the new URL in 300ms
  setTimeout(() => {
    window.location.href = url.toString()
  }, 300)
}

export const AutoAuthProvider = (props) => {
  const container = useContainer()
  const auth = container.get(Auth)
  useAutoAuthenticateSession()

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    if (params?.get('legacy') === 'true') {
      leaveNewExperience()
    }
  }, [])

  useEffect(() => {
    auth.listen(async (currentUser) => {
      if (!currentUser) return

      const idToken = await currentUser?.getIdToken(true)
      Cookies.set('__session', idToken, {
        expires: 15,
        domain: Envs.COOKIE_DOMAIN,
        secure: true,
        sameSite: 'strict',
      })
    })
  }, [auth])

  return <AuthContext.Provider value={{}}>{props.children}</AuthContext.Provider>
}
