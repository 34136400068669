export class GetReleaseTime {
  public execute(targetDate: Date): string {
    const currentDate = new Date()
    const millisecondsInADay = 24 * 60 * 60 * 1000
    const millisecondsInAnHour = 60 * 60 * 1000
    let timeToRelease = ''

    const remainingTime = targetDate.getTime() - currentDate.getTime()

    if (remainingTime >= millisecondsInADay) {
      const days = Math.round(remainingTime / millisecondsInADay)
      timeToRelease = `${days} dia${days > 1 ? 's' : ''}`
    } else {
      const hours = Math.ceil(remainingTime / millisecondsInAnHour)
      timeToRelease = `${hours}h`
    }

    return `Disponível em ${timeToRelease}.`
  }
}
