import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { UserCredentials } from '../domain/dtos/credentials'
import { Email } from '../domain/vos/email'
import { Envs } from '../envs'

export class SetCredentials {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(input: UserCredentials): Promise<void> {
    const response = await this.httpClient.request({
      method: HttpMethod.POST,
      url: `${Envs.FUNCTIONS_URL}/userInfo/setCredentials/pt`,
      body: { data: { ...input, email: Email.build(input.email).getValue() } },
    })

    if (response.statusCode === HttpStatusCode.OK || response.statusCode === HttpStatusCode.CREATED) {
      return
    }

    throw new Error(response.data.error.message)
  }
}
