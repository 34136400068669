import {
  CopyClipboardButton,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  Label,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Separator,
} from '@hub-la/shadcn'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Offer } from '../../domain/dtos/offer'
import { LifetimeInterval } from '../../domain/enums/interval'
import { GetAvailableOptions } from '../../usecases/get-available-options'
import { GetCouponLinks } from '../../usecases/get-coupon-links'
import { useGetCoupon } from '../hooks/use-get-coupon'

type ShareCouponModalProps = {
  id: string
  couponCode: string
  open: boolean
  onClose: () => void
  offers?: Offer[]
}

export const ShareCouponModal = ({ id, open, onClose, couponCode, offers = [] }: ShareCouponModalProps) => {
  const [offer, setOffer] = useState<Offer>()

  const { t } = useTranslation()

  /** @description The `(open ? id : '')` will prevent fetching the coupon if the modal is not open */
  const { data: coupon, isFetching } = useGetCoupon(open ? id : '')

  const options = new GetAvailableOptions().execute(offers, coupon?.appliableTo ?? [])
  const items = new GetCouponLinks().execute(couponCode, coupon?.appliableTo, offer) ?? []
  const shouldRenderTable = options && !isFetching && items.length > 0
  const isAvailableForAll = items.some((it) => it.interval === 'ALL' || it.interval === LifetimeInterval.LIFETIME)

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="max-w-md flex flex-col gap-4">
        <DialogHeader>
          <DialogTitle>Compartilhar link de desconto</DialogTitle>
          <DialogDescription>{t('share.subtitle')}</DialogDescription>
        </DialogHeader>
        <div className="py-4 flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <Label htmlFor="product">Selecione um produto</Label>

            <Select
              value={offer?.id}
              onValueChange={(value) => setOffer(options.find((o) => o.id === value))}
              disabled={isFetching}
            >
              <SelectTrigger id="product">
                <SelectValue placeholder="Selecione um produto" />
              </SelectTrigger>

              <SelectContent className="z-10002 w-80 h-60">
                {options.map((option) => (
                  <SelectItem key={option.id} value={option.id}>
                    {option.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          {shouldRenderTable && (
            <div className="border rounded-md">
              <div className="p-4 flex flex-wrap gap-2 items-center justify-between">
                {!isAvailableForAll && <span>Plano</span>}

                <span>Link do checkout com cupom aplicado</span>
              </div>

              <Separator />

              {items.map((item, index) => (
                <div key={index} className="w-full p-4 flex flex-wrap gap-2 items-center justify-between">
                  {!isAvailableForAll && <span>{t(`planType.${item.interval}`)}</span>}

                  <CopyClipboardButton
                    className="max-w-full"
                    labelClassName="max-w-44"
                    copyContent={item.link}
                    label={item.link}
                    tooltipCopiedLabel={t('copyClipboard.linkcopied')}
                    tooltipCopyLabel={t('copyClipboard.copyLink')}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  )
}
