import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '../../domain/enums/query-key'
import { GetRefund } from '../../usecases/get-refund'

export const useGetRefund = (id: string) => {
  const container = useContainer()

  return useQuery([QueryKey.getRefund, id], () => new GetRefund(container.get(HttpClient)).execute(id), {
    retry: 3,
    enabled: !!id,
    refetchOnWindowFocus: false,
  })
}
