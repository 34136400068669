import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useToast } from '@hub-la/shadcn'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { SaveSettingsInput } from '../../../domain/dtos/settings'
import { QueryKey } from '../../../domain/enums/query-key'
import { SaveSettings } from '../../../usecases/settings/save-settings'

export const useSaveSettings = () => {
  const container = useContainer()
  const queryClient = useQueryClient()
  const { toast } = useToast()
  const { t } = useTranslation()

  return useMutation(
    [QueryKey.saveSettings],
    (input: SaveSettingsInput) => new SaveSettings(container.get(HttpClient)).execute(input),
    {
      retry: false,
      onSuccess: (_, variables) => {
        if (variables.type === 'info') {
          queryClient.invalidateQueries({ queryKey: [QueryKey.getProduct, variables.productId] })
        }

        queryClient.invalidateQueries({ queryKey: [QueryKey.getSettings, variables.productId, variables.type] })
      },
      onError: (error: Error) => toast({ description: t(error.message), variant: 'destructive' }),
    },
  )
}
