import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { GetWaitlistInput, GetWaitlistOutput } from '../../domain/dtos/waitlist'
import { GeneralError } from '../../domain/errors/general'
import { Envs } from '../../envs'

export class GetWaitlist {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(input: GetWaitlistInput, signal?: AbortSignal): Promise<GetWaitlistOutput> {
    const response = await this.httpClient.request({
      method: HttpMethod.POST,
      url: `${Envs.FUNCTIONS_URL}/waitlist/subscribers/pt`,
      body: { data: { ...input } },
      signal,
    })

    if (response.statusCode === HttpStatusCode.OK || response.statusCode === HttpStatusCode.CREATED) {
      return response.data.result
    }

    throw new GeneralError()
  }
}
