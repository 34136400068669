export enum ERROR_TYPE {
  SUBMITTED_VIDEO_FAIL = 'SubmittedVideoFail',
  SUBMITTED_IMAGE_FAIL = 'SubmittedImageFail',
  SUBMITTED_PDF_FAIL = 'SubmittedPdfFail',
  SUBMITTED_ATTACHMENT_FAIL = 'SubmittedAttachmentFail',
  SAVE_ASSET_FAIL = 'SaveAssetFail',
  SUBMITTED_WITHOUT_REQUIRED_FAIL = 'SubmittedWhitoutRequiredFields',
  SUBMITTED_SIZE_FAIL = 'SubmittedSizeFail',
  REMOVE_ASSET_FAIL = 'SubmittedRemoveAssetFail',
  UPLOAD_ASSET_FAIL = 'UploadAssetFail',
  MEDIA_FILE_NOT_ALLOWED = 'UploadCoverExtensionNotAllowed',
}
