export class YoutubeUrl {
  private readonly value: string

  constructor(value: string) {
    this.value = value
  }

  public getVideoId(): string {
    const url = new URL(this.value)
    const searchParams = new URLSearchParams(url.search)
    return searchParams.get('v') as string
  }

  public isValid = (): boolean => {
    const youtubeRegex = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/

    return youtubeRegex.test(this.value)
  }
}
