import { container } from '@hub-la/fe-container'
import { DashboardRefundsProvider, RefundDetail } from '@hub-la/fe-dashboard-refunds'

import { useHistory, useParams } from 'react-router-dom'

export const DashboardRefundDetailInit = () => {
  const { refundId } = useParams<{ refundId: string }>()
  const history = useHistory()
  return (
    <DashboardRefundsProvider container={container}>
      <RefundDetail id={refundId} onClose={() => history.goBack()} />
    </DashboardRefundsProvider>
  )
}
