import { AssetType, AssetUploader, UploadStatus } from '@hub-la/fe-asset'
import { Alert, AlertDescription, Button, Card, CardContent, cn } from '@hub-la/shadcn'
import { useFormikContext } from 'formik'
import get from 'lodash/get'
import { AlertTriangle, Upload, X } from 'lucide-react'
import { useCallback, useEffect } from 'react'
import { Values } from '../../../../../domain/dtos/member-area/settings/formik-values'

export type Field = 'desktopAsset' | 'mobileAsset' | 'videoAsset' | 'logoAsset'

type CardUploaderProps = {
  label?: string
  field: Field
  title: string
  subtitle: string
  errorMessage?: string
  isLoading?: boolean
  onComplete?: () => void
  onDelete?: () => void
}

const statusFields = {
  videoAsset: 'videoAssetStatus',
  desktopAsset: 'desktopAssetStatus',
  mobileAsset: 'mobileAssetStatus',
  logoAsset: 'logoAssetStatus',
}

const CardUploader = ({ label, title, subtitle, field, errorMessage, onComplete, onDelete }: CardUploaderProps) => {
  const { values, setFieldValue, validateField, errors } = useFormikContext<Values>()

  const assetFieldData = values[field]
  const statusField = values[statusFields[field]]
  const fieldHasError = Boolean(errors[field])

  const getImage = useCallback(() => {
    const fieldType = get(assetFieldData, 'type') ?? get(assetFieldData, 'metadata.assetType')
    if (!fieldType) return null

    if ([AssetType.video, AssetType.application].includes(fieldType)) {
      return get(assetFieldData, 'metadata.thumbnailUrl')
    }

    if (fieldType === AssetType.image) {
      return get(assetFieldData, 'url')
    }
  }, [assetFieldData])

  const handleDelete = useCallback(
    (fieldName: string, statusFieldName: string) => {
      setFieldValue(fieldName, null)
      setFieldValue(statusFieldName, UploadStatus.NO_CALLED)
      validateField(fieldName)
      if (onDelete) {
        onDelete()
      }
    },
    [setFieldValue, validateField],
  )

  const image = getImage()

  useEffect(() => {
    if (!onComplete) return
    if (assetFieldData && statusField === UploadStatus.SUCCESS) {
      onComplete()
    }
  }, [statusField, assetFieldData])

  useEffect(() => {
    if (assetFieldData) {
      validateField(field)
    }
  }, [assetFieldData, field, validateField])

  const isLoading = statusField === UploadStatus.LOADING || statusField === UploadStatus.PROCESSING

  return (
    <div className="flex flex-col flex-1 gap-2">
      {label && <p className="text-sm font-medium">{label}</p>}

      <AssetUploader field={field} disabled={isLoading} statusFieldName={statusField}>
        <Card className="relative">
          <CardContent
            className={cn(
              'pt-6 min-h-52 h-full flex flex-col gap-2 items-center justify-center',
              !image ? 'bg-muted' : '',
            )}
          >
            {image && !isLoading ? (
              <>
                <Button
                  variant="ghost"
                  size="icon"
                  className="absolute top-2 right-2"
                  onClick={(e) => {
                    e.preventDefault()
                    handleDelete(field, statusField)
                  }}
                >
                  <X className="h-4 w-4" />
                </Button>

                <img src={image} className="object-cover max-h-80 h-full max-w-full" data-testid="image" alt="" />
              </>
            ) : (
              <>
                <div className="mb-2 bg-muted rounded-full">
                  {isLoading ? (
                    <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-primary"></div>
                  ) : (
                    <Upload className="h-6 w-6 text-muted-foreground" />
                  )}
                </div>

                <p className="text-base font-bold">{title}</p>

                <p className="text-sm text-muted-foreground">{subtitle}</p>
              </>
            )}
          </CardContent>
        </Card>
      </AssetUploader>

      {!isLoading && fieldHasError && (
        <Alert variant="destructive" className="mt-2">
          <AlertTriangle className="h-4 w-4" />
          <AlertDescription>{errorMessage}</AlertDescription>
        </Alert>
      )}
    </div>
  )
}

export default CardUploader
