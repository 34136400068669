import { PaymentMethod } from '@hub-la/payments-proto/lib/index.modules.marketplace.api.v1'

/**
 * Represents the request DTO for getting invoice details.
 */
export interface GetInvoiceDetailRequestDto {
  invoiceId: string
}

/**
 * Represents the response DTO for getting invoice details.
 * This is a reproduction at the front-end level of an existing DTO located at:
 * /hubla-nx/libs/backend/modules/mod-invoice/src/dto/response/get-invoice-detail-response.dto.ts
 */
export interface GetInvoiceDetailResponseDto {
  id: string
  payerName: string
  payerPhoneNumber: string
  payerEmail: string
  createdAt: Date
  paymentMethod: PaymentMethod
  recurrence?: string
  offers: OfferListItemDto[]
  couponName?: string
  couponDiscountCents?: number
  totalValueCents: number
  affiliateCommission?: CommissionItemDto | undefined
  coCreatorCommission: CommissionItemDto[]
  hublaFeeValueCents: number
  otherFeesValueCents: number
  netValueCents: number
  hasMembershipFee: boolean
  productType: string
  paidAt?: Date
  status: InvoiceStatus
}

/**
 * Represents a summary of an offer, with only the `id`, `name`, and `priceCents` attributes.
 * This is a reproduction at the front-end level of an existing DTO located at:
 * /hubla-nx/libs/backend/modules/mod-invoice/src/dto/response/offer.dto.ts
 */
export interface OfferListItemDto {
  id: string
  name: string
  priceCents?: number
}

/**
 * Represents a summary of a commission, with only the `id`, `name`, and `value` attributes.
 * This is a reproduction at the front-end level of an existing DTO located at:
 * /hubla-nx/libs/backend/modules/mod-invoice/src/dto/response/commission.dto.ts
 */
export interface CommissionItemDto {
  id: string
  name: string
  value: number
}

/**
 * Represents the possible status of an invoice.
 * This is a reproduction at the front-end level of an existing enum located at:
 * /hubla-nx/libs/backend/modules/mod-invoice/src/enums/invoice-status.enum.ts
 */
export enum InvoiceStatus {
  STATUS_UNSPECIFIED = 'STATUS_UNSPECIFIED',
  STATUS_DRAFT = 'STATUS_DRAFT',
  STATUS_UNPAID = 'STATUS_UNPAID',
  STATUS_PAID = 'STATUS_PAID',
  STATUS_PARTIALLY_PAID = 'STATUS_PARTIALLY_PAID',
  STATUS_OVERDUE = 'STATUS_OVERDUE',
  STATUS_VOID = 'STATUS_VOID',
  STATUS_RETURNED = 'STATUS_RETURNED',
  STATUS_PARTIALLY_RETURNED = 'STATUS_PARTIALLY_RETURNED',
  STATUS_CHARGEBACK = 'STATUS_CHARGEBACK',
  STATUS_DISPUTED = 'STATUS_DISPUTED',
  STATUS_EXPIRED = 'STATUS_EXPIRED',
}
