import { UploadStatus } from '../dtos'

export class MediaUploadImageError extends Error {
  constructor() {
    super()
    this.message = 'mediaUpload.error.image'
  }

  public getErrorType(): UploadStatus {
    return UploadStatus.ERROR
  }
}
