import { isEmpty } from 'lodash'
import moment from 'moment'
import { Dataset } from '../domain/dtos/chart-output'
import { Period } from '../domain/dtos/period'
import { PeriodToMoment } from '../domain/dtos/period-to-moment'
import { GetToday } from './get-today'

export type GetIndexOfTodayInput = {
  datasets: Dataset[]
  period: Period
}

export class GetIndexOfToday {
  public execute({ datasets, period }: GetIndexOfTodayInput) {
    if (isEmpty(datasets)) {
      return 0
    }

    let format = 'YYYY-MM-DD'
    switch (period) {
      case Period.HOURLY: {
        format = 'YYYY-MM-DDTHH:mm:ssZ'
        break
      }
      case Period.DAILY:
      case Period.WEEKLY: {
        format = 'YYYY-MM-DD'
        break
      }
      case Period.MONTHLY: {
        format = 'YYYY-MM'
        break
      }
    }

    const [array] = datasets

    const today = new GetToday().execute(format)

    const indexOfToday = array.data.findIndex(({ date }) => {
      return moment(date).isSame(today, PeriodToMoment[period])
    })

    return indexOfToday > -1 ? array.data[indexOfToday].date : undefined
  }
}
