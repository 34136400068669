import { Card, CardContent, CardHeader, CardTitle, Separator } from '@hub-la/shadcn'
import { CalendarIcon } from 'lucide-react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { SmartInstallmentRef } from '../../../../domain/dtos/smart-installment-ref'
import { InvoiceStatus } from '../../../../domain/enums/invoice-status'
import { formatCurrency } from '../../../../domain/vos/format-currency'
import { InvoiceStatusBadge } from '../../../components/invoice-status-badge'
import { SmartInstallmentBadge } from '../../../components/smart-installment-badge'

type Props = {
  invoice: {
    id: string
    status: InvoiceStatus
    createdAt: string
    dueDate: string
    paidAt: string
    total: number
    installment: number
    installments: number
    smartInstallmentRef?: SmartInstallmentRef
  }
}

export const Header = ({ invoice }: Props) => {
  const { t } = useTranslation()

  return (
    <Card>
      <CardHeader className="w-full flex-row flex-wrap gap-2 items-center justify-between">
        <CardTitle className="flex-1">{t('invoiceDownload.header.title')}</CardTitle>
      </CardHeader>

      <CardContent>
        <div className="flex flex-col gap-0.5">
          <div className="flex items-center gap-2">
            <h2 className="text-2xl font-semibold">{formatCurrency(invoice.total / 100)}</h2>

            <InvoiceStatusBadge status={invoice.status} />

            {invoice.smartInstallmentRef && (
              <SmartInstallmentBadge
                status={invoice.status}
                installment={invoice.installment}
                installments={invoice.installments}
              />
            )}
          </div>

          <p className="text-sm text-muted-foreground">
            {t('invoiceDownload.header.invoiceId')}: {invoice.id}
          </p>
        </div>

        <Separator className="my-4" />

        <div className="flex flex-col gap-4">
          <div>
            <p className="font-medium">{t('invoiceDownload.header.createdAt')}</p>

            <div className="flex items-center gap-2 text-muted-foreground">
              <CalendarIcon className="h-4 w-4" />

              <p>{moment(invoice.createdAt).format('D MMM. YYYY HH:mm')}</p>
            </div>
          </div>

          {invoice.status === InvoiceStatus.PAID && (
            <div>
              <p className="font-medium">{t('invoiceDownload.header.paidAt')}</p>

              <div className="flex items-center gap-2 text-muted-foreground">
                <CalendarIcon className="h-4 w-4" />

                <p>{moment(invoice.paidAt).format('D MMM. YYYY HH:mm')}</p>
              </div>
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  )
}
