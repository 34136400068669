import { Button, Card, CardContent, CardHeader, CardTitle, Separator, Skeleton } from '@hub-la/shadcn'
import { ChevronLeft } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { ContentRow } from './content-row'

export const Loading = () => {
  const { t } = useTranslation()

  return (
    <div className="h-full w-full overflow-auto">
      <div className="space-y-4 mb-8">
        {/* Header section */}
        <div className="flex flex-col lg:flex-row justify-between items-start lg:items-end space-y-5 lg:space-y-0 lg:space-x-5">
          <div className="space-y-1 md:w-auto">
            <Button className="h-4 px-0" size="sm" variant="link">
              <ChevronLeft className="w-4 h-4 mr-2" />
              {t('goBack')}
            </Button>
            <div className="flex items-center space-x-2">
              <Skeleton className="h-8 w-8" />
              <Skeleton className="h-9 w-32" />
              <Skeleton className="h-6 w-20" />
            </div>
            <Skeleton className="h-8 w-64" />
          </div>

          <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2 w-full md:w-auto">
            <Skeleton className="h-10 w-32" />
            <Skeleton className="h-10 w-32" />
            <Skeleton className="h-10 w-32" />
          </div>
        </div>

        <Separator />

        {/* Main content grid */}
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-6">
          {/* Description section */}
          <div className="col-span-1 lg:col-span-7 space-y-6">
            <div className="space-y-6 my-4">
              <div className="space-y-4">
                <ContentRow variant="middle" title="Desconto" content={<Skeleton className="h-4 w-32" />} />
                <ContentRow variant="middle" title="Utilização" content={<Skeleton className="h-4 w-32" />} />
                <ContentRow variant="middle" title="Produto(s)" content={<Skeleton className="h-4 w-32" />} />
                <ContentRow variant="middle" title="Plano(s)" content={<Skeleton className="h-4 w-32" />} />
                <ContentRow variant="middle" title="Método de pagamento" content={<Skeleton className="h-4 w-32" />} />
                <ContentRow
                  variant="middle"
                  title="Recorrente para renovação"
                  content={<Skeleton className="h-4 w-32" />}
                />
              </div>
            </div>
          </div>

          {/* Details section */}
          <div className="col-span-1 lg:col-span-5 space-y-6">
            <Card>
              <CardHeader className="py-4 px-4">
                <CardTitle className="text-base font-semibold flex justify-between items-center">
                  {t('couponDetails.title')}
                </CardTitle>
              </CardHeader>
              <Separator />
              <CardContent className="p-4 space-y-4">
                <ContentRow title="ID do cupom" content={<Skeleton className="h-4 w-32" />} />
                <ContentRow title="Data de criação" content={<Skeleton className="h-4 w-32" />} />
                <ContentRow title="Expira em" content={<Skeleton className="h-4 w-32" />} />
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </div>
  )
}
