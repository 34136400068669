import React from 'react'
import { TableCell, TableRow, Skeleton } from '@hub-la/shadcn'

export const TableLoading: React.FC = () => {
  return (
    <TableRow>
      <TableCell className="p-2">
        <div className="flex flex-col space-y-2">
          <Skeleton className="h-6 w-2/5" />
          <Skeleton className="h-6 w-3/4" />
        </div>
      </TableCell>
      <TableCell className="p-2">
        <Skeleton className="h-6 w-3/4" />
      </TableCell>
      <TableCell className="p-2">
        <div className="flex flex-row space-x-2">
          <Skeleton className="h-6 w-1/4" />
          <Skeleton className="h-6 w-1/4" />
          <Skeleton className="h-6 w-1/4" />
        </div>
      </TableCell>
      <TableCell className="p-2">
        <Skeleton className="h-6 w-3/4" />
      </TableCell>
      <TableCell className="p-2">
        <Skeleton className="h-6 w-1/2" />
      </TableCell>
    </TableRow>
  )
}
