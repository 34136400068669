import { useGetTemporaryBusiness } from '@hub-la/fe-get-user'
import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  ScrollArea,
  useToast,
} from '@hub-la/shadcn'
import { FormikProvider, useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SubmitAccountVerificationInput } from '../../domain/dtos/submit-account-verification'
import { DocumentType } from '../../domain/enums/bank-account'
import { BillingAddressForm } from '../components/billing-address-form'
import { IdWallForm } from '../components/idwall-form'
import { PersonForm } from '../components/person-form'
import ReviewAndSave from '../components/review-and-save'
import { SelectModality } from '../components/select-modality'
import { SoftDescriptorForm } from '../components/soft-descriptor-form'
import { Steps } from '../components/steps'
import { UpdatePayoutForm } from '../components/update-payout-form'
import { useSaveTemporaryBusiness } from '../hooks/use-save-temporary-business'
import { useSubmitAccountVerification } from '../hooks/use-submit-account-verification'
import { useUpdatePayout } from '../hooks/use-update-payout'
import { makeInitialValues, resetBankAccountValues } from '../validation/initial-values'
import { makeValidationSchema } from '../validation/validation-schema'
import { UpdatePayoutValues } from './update-payout-modal'

type Props = {
  open: boolean
  onClose?: () => void
  onFinish?: () => void
}

export enum FormSteps {
  COMPANY_DATA = 0,
  PERSONAL_INFO = 1,
  WITHDRAW_INFO = 2,
  PUBLIC_INFO = 3,
  VERIFY_IDENTITY = 4,
  REVIEW_AND_SAVE = 5,
}

export interface Values extends SubmitAccountVerificationInput, UpdatePayoutValues {
  pendencies: string[]
}

export const AccountVerificationModal: React.FC<Props> = ({ open, onClose, onFinish }) => {
  const [step, setStep] = useState<FormSteps>(FormSteps.COMPANY_DATA)
  const [isContinueLoading, setIsContinueLoading] = useState<boolean>(false)
  const [isConfirmationOpen, setIsConfirmationOpen] = useState<boolean>(false)

  const { t } = useTranslation()
  const { toast } = useToast()
  const {
    mutateAsync: submitAccountVerification,
    isLoading: isSubmitAccountLoading,
    error: submitAccountError,
    reset,
    isSuccess,
  } = useSubmitAccountVerification()
  const { mutateAsync: updatePayout, error: payoutError, isLoading: isPayoutLoading } = useUpdatePayout()
  const { mutateAsync: saveTemporaryBusiness } = useSaveTemporaryBusiness()
  const { data: account, refetch } = useGetTemporaryBusiness()

  const formik = useFormik<Values>({
    validationSchema: makeValidationSchema(),
    validateOnMount: true,
    validateOnChange: true,
    enableReinitialize: true,
    initialValues: makeInitialValues(account),
    onSubmit: async (values: Values) => {
      submitAccountVerification(values).then(() =>
        updatePayout({
          bankAccount: values.bankAccount,
          isPixKey: values.isPixKey,
        }),
      )
    },
  })

  const error = submitAccountError && payoutError
  const isLoading = isSubmitAccountLoading || isPayoutLoading

  const handleContinue = async () => {
    setIsContinueLoading(true)
    await saveTemporaryBusiness({
      businessIdentity: {
        payout: { isPixKey: formik.values.isPixKey, bankAccount: formik.values.bankAccount },
        businessConfiguration: formik.values.businessConfiguration,
        identity: {
          document: {
            countryCode: formik.values.identity.document.countryCode,
            type: formik.values.identity.document.type,
            value: formik.values.identity.document.value,
          },
          fullName: formik.values.identity.fullName,
          email: formik.values.identity.email,
          phone: formik.values.identity.phone,
          billingAddress: formik.values.identity.billingAddress,
          birthday: formik.values.identity.birthday,
          legalRepresentative:
            formik.values.identity.document.type === DocumentType.CNPJ
              ? {
                  fullName: formik.values.identity.legalRepresentative?.fullName ?? '',
                  document: {
                    type: formik.values.identity.legalRepresentative?.document.type ?? DocumentType.CPF,
                    value: formik.values.identity.legalRepresentative?.document.value ?? '',
                    countryCode: formik.values.identity.legalRepresentative?.document.countryCode ?? 'BR',
                  },
                }
              : undefined,
        },
      },
      sdkToken: formik.values.sdkToken,
    })
      .then(async () => {
        await refetch()
        setStep(step + 1)
      })
      .finally(() => {
        setIsContinueLoading(false)
      })
  }

  const handleSubmit = () => {
    formik.submitForm()
  }

  const handleClose = () => {
    if (formik.dirty) {
      setIsConfirmationOpen(true)
      return
    }

    onClose?.()
  }

  const steps = {
    [FormSteps.COMPANY_DATA]: <SelectModality onComplete={handleContinue} loading={isContinueLoading} />,
    [FormSteps.PERSONAL_INFO]: (
      <div className="space-y-12">
        <PersonForm />
        <BillingAddressForm onComplete={handleContinue} loading={isContinueLoading} />
      </div>
    ),
    [FormSteps.WITHDRAW_INFO]: <UpdatePayoutForm onComplete={handleContinue} loading={isContinueLoading} />,
    [FormSteps.PUBLIC_INFO]: <SoftDescriptorForm onComplete={handleContinue} loading={isContinueLoading} />,
    [FormSteps.VERIFY_IDENTITY]: <IdWallForm onComplete={handleContinue} loading={isContinueLoading} />,
    [FormSteps.REVIEW_AND_SAVE]: (
      <ReviewAndSave
        onComplete={handleSubmit}
        loading={isLoading}
        isSuccess={isSuccess}
        onFinish={onFinish}
        setStep={setStep}
      />
    ),
  }

  useEffect(() => {
    if (formik.values.isPixKey) {
      formik.setFieldValue('bankAccount', resetBankAccountValues())
    }
  }, [formik.values.isPixKey])

  useEffect(() => {
    if (error) {
      toast({
        variant: 'destructive',
        title: 'Erro',
        description: t((error as Error)?.message) ?? '',
      })
      reset()
    }
  }, [error, t, toast, reset])

  return (
    <>
      <Dialog open={open} onOpenChange={handleClose}>
        <DialogContent className="z-[70] max-w-full h-full p-0 flex flex-col">
          <div className="h-12 flex justify-between items-center border-b" />

          <ScrollArea className="flex-1 w-full">
            <FormikProvider value={formik}>
              <div className="flex flex-col p-4">
                <div className="grid grid-cols-1 md:grid-cols-12 gap-6">
                  <div className="md:col-span-3">
                    <Steps activeStep={step} setStep={setStep} account={account} />
                  </div>

                  <div className="md:col-span-9">
                    <div className="flex flex-col gap-6 max-w-[600px]">{steps[step]}</div>
                  </div>
                </div>
              </div>
            </FormikProvider>
          </ScrollArea>
        </DialogContent>
      </Dialog>

      <Dialog open={isConfirmationOpen} onOpenChange={setIsConfirmationOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Alterações não salvas</DialogTitle>
            <DialogDescription>As alterações realizadas não serão salvas</DialogDescription>
          </DialogHeader>

          <DialogFooter>
            <div className="flex flex-col sm:flex-row gap-2 w-full sm:w-auto">
              <Button variant="outline" onClick={() => setIsConfirmationOpen(false)}>
                Ficar na página
              </Button>

              <Button
                variant="destructive"
                onClick={() => {
                  setIsConfirmationOpen(false)
                  onClose?.()
                }}
              >
                Sair
              </Button>
            </div>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  )
}
