import { Button, CopyClipboardButton, Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@hub-la/shadcn'
import { ChevronLeft } from 'lucide-react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { PlanType } from '../../../domain/enums/plan-type'
import { ReceiverRole } from '../../../domain/enums/receiver-role'
import { SubscriptionStatus } from '../../../domain/enums/subscription-status'
import { SubscriptionStatusStats } from '../../components/subscription-status-stats'
import { AddDailyCreditsModal } from './add-daily-credits-modal'
import { DeactivateSubscriptionModal } from './deactivate-subscription-modal'

type Props = {
  id: string
  name: string
  onClose: () => void
  onChange: () => void
  planType: PlanType
  status: SubscriptionStatus
  credits: number
  role: ReceiverRole
}

export const Header: React.FC<Props> = ({ id, name, planType, status, onClose, onChange, credits, role }: Props) => {
  const [isDeactivateSubscriptionOpen, setIsDeactivateSubscriptionOpen] = useState<boolean>(false)
  const [isAddDailyCreditsOpen, setIsAddDailyCreditsOpen] = useState<boolean>(false)

  const { t } = useTranslation()
  const history = useHistory()

  const canDeactivate =
    ![SubscriptionStatus.INACTIVE, SubscriptionStatus.CANCELED, SubscriptionStatus.INCOMPLETE].includes(status) &&
    role === ReceiverRole.ROLE_SELLER
  const canAddDailyCredits =
    ![SubscriptionStatus.INACTIVE, SubscriptionStatus.INCOMPLETE].includes(status) && role === ReceiverRole.ROLE_SELLER

  return (
    <div className="flex flex-col md:flex-row md:items-center justify-between">
      <div className="space-y-2">
        <Button className="h-4 px-0" size="sm" variant="link" onClick={() => history.goBack()}>
          <ChevronLeft className=" w-4 h-4 mr-2" />
          Voltar
        </Button>
        <div className="flex flex-wrap items-center gap-1.5 max-w-full">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <h3 className="text-2xl md:text-3xl font-semibold truncate max-w-[300px]">{name}</h3>
              </TooltipTrigger>
              <TooltipContent>{name}</TooltipContent>
            </Tooltip>
          </TooltipProvider>
          <h3 className="text-muted-foreground text-2xl md:text-3xl font-semibold truncate max-w-[300px]">
            {t(`planType.${planType}`)}
          </h3>
          <SubscriptionStatusStats status={status} />
        </div>
        <div>
          <CopyClipboardButton
            copyContent={id}
            label={id}
            tooltipCopiedLabel={t('copyClipboard.subscriptionIdcopied')}
            tooltipCopyLabel={t('copyClipboard.copySubscriptionId')}
          />
        </div>
      </div>
      <div className="flex flex-col lg:w-auto h-full w-full lg:flex-row space-y-4 lg:space-y-0 lg:space-x-2">
        {canAddDailyCredits && (
          <div className="h-full md:pt-[68px]">
            <Button
              className="lg:w-auto w-full"
              variant="outline"
              size="sm"
              onClick={() => setIsAddDailyCreditsOpen(true)}
            >
              <p className="text-primary">Estender vencimento</p>
            </Button>
          </div>
        )}
        {canDeactivate && (
          <div className="h-full md:pt-[68px]">
            <Button
              className="lg:w-auto w-full"
              variant="destructive"
              size="sm"
              onClick={() => setIsDeactivateSubscriptionOpen(true)}
            >
              Cancelar assinatura
            </Button>
          </div>
        )}
      </div>

      <DeactivateSubscriptionModal
        id={id}
        open={isDeactivateSubscriptionOpen}
        onClose={() => setIsDeactivateSubscriptionOpen(false)}
        onSuccess={onChange}
      />
      <AddDailyCreditsModal
        id={id}
        open={isAddDailyCreditsOpen}
        onClose={() => setIsAddDailyCreditsOpen(false)}
        onSuccess={onChange}
        planType={planType}
        remainingCredits={credits}
      />
    </div>
  )
}
