import { useContainer } from '@hub-la/fe-container'
import { Analytics } from '@hub-la/fe-core-analytics'
import { Auth } from '@hub-la/fe-core-auth'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation } from '@tanstack/react-query'
import { AuthenticateWithCodeOutput } from '../../domain/dtos/authenticate-with-code-output'
import { PhoneNumberVerifyCodeInput } from '../../domain/dtos/phone-number-verify-code-input'
import { QueryKey } from '../../domain/dtos/query-keys'
import { AuthenticateWithCode } from '../../usecases/authenticate-with-code'

export const useAuthenticateWithCode = (params?: { onSuccess?: (data: AuthenticateWithCodeOutput) => void }) => {
  const container = useContainer()

  return useMutation(
    [QueryKey.authenticateWithCode],
    (input: PhoneNumberVerifyCodeInput) =>
      new AuthenticateWithCode(container.get(HttpClient), container.get(Auth), container.get(Analytics)).execute(input),
    {
      retry: false,
      onSuccess: (data: AuthenticateWithCodeOutput) => {
        if (params?.onSuccess) {
          params.onSuccess(data)
        }
      },
    },
  )
}
