import React from 'react'
import { useTranslation } from 'react-i18next'
import { CheckCircle } from 'lucide-react'

export const Success = () => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col space-y-3">
      <div className="flex flex-col items-center space-y-1">
        <CheckCircle className="w-10 h-10 text-green-500" />
        <h3 className="text-2xl font-semibold">{t('upgradePlanModal.success.title')}</h3>
      </div>
      <p className="text-muted-foreground">{t('upgradePlanModal.success.title')}</p>
    </div>
  )
}
