import { FLAGS, useFeatureFlag } from '@hub-la/fe-core-feature-flag'
import { useGetIdentity, VerificationStatus } from '@hub-la/fe-get-user'
import { Alert, AlertDescription, AlertTitle, Button } from '@hub-la/shadcn'
import Cookies from 'js-cookie'
import { AlertTriangle, ArrowUpRight, CheckCircle, Info, RefreshCw, X } from 'lucide-react'
import { useEffect, useState } from 'react'
import { Envs } from '../../envs'
import { AccountVerificationProvider } from '../providers/account-verification-provider'
import { AccountVerificationModal } from './account-verification-modal'

export const AccountVerificationBanner = () => {
  const [open, setOpen] = useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const { data, isFetched, refetch, error } = useGetIdentity()
  const showSuccessBanner = Cookies.get('show-success-banner') === 'true'

  const identityId = data?.identity?.id
  const status = data?.identity?.verification?.status
  const isVerified = status === VerificationStatus.APPROVED && !showSuccessBanner

  const isAccountNotFound = (error as Error)?.message === 'ACCOUNT_NOT_FOUND'

  const { isFlagEnabled } = useFeatureFlag()

  const shouldUpdateAddress = isFlagEnabled(FLAGS.KYC.IS_CUSTOM_REJECTED_MESSAGE_ON_KYC, {
    identityId,
  })

  useEffect(() => {
    if (isFetched) {
      const showBanner = (!isVerified && !error) || isAccountNotFound
      setOpen(showBanner)
    }
  }, [isVerified, error, isFetched, isAccountNotFound])

  if (!open) return null

  const getContent = (): {
    title: string
    description?: string
    cta?: string
    icon?: JSX.Element
    variant: 'default' | 'destructive'
    onClose?: () => void
  } => {
    if (status === VerificationStatus.APPROVED && showSuccessBanner) {
      return {
        title: 'Sua conta foi ativada! Agora você pode ativar as vendas de seus produtos e processar pagamentos',
        icon: <CheckCircle className="h-4 w-4" />,
        variant: 'default',
        onClose: () => {
          setOpen(false)
          Cookies.set('show-success-banner', 'false', { sameSite: 'lax', expires: 30, domain: Envs.COOKIE_DOMAIN })
        },
      }
    }

    let rejectedTitle =
      'Tivemos problema ao ativar sua conta. Atualize as informações necessárias para começar a vender.'
    let rejectedDescription = ''
    let rejectedCta = 'Resolver'
    if (shouldUpdateAddress) {
      rejectedTitle = 'A venda de novos produtos foi bloqueada até que os requisitos sejam atendidos.'
      rejectedDescription =
        'Por exigências legais e para garantir a segurança no processamento dos seus pagamentos, precisamos que informe o endereço do representante da sua empresa para regularizar sua conta e liberar a venda de novos produtos.'
      rejectedCta = 'Ver detalhes'
    }

    switch (status) {
      case VerificationStatus.PENDING:
        return {
          title: 'Validando seus dados... Em breve você poderá começar a vender',
          icon: <RefreshCw className="h-4 w-4" />,
          variant: 'default',
        }
      case VerificationStatus.REJECTED:
        return {
          title: rejectedTitle,
          description: rejectedDescription,
          cta: rejectedCta,
          icon: <Info className="h-4 w-4" />,
          variant: 'destructive',
        }
      default:
        return {
          title: 'Você ainda não pode processar pagamentos. Ative sua conta para começar a vender',
          cta: 'Ativar conta',
          icon: <AlertTriangle className="h-4 w-4" />,
          variant: 'destructive',
        }
    }
  }

  if (!isFetched) return null

  const content = getContent()

  return (
    <AccountVerificationProvider>
      <div className="pt-10 px-10">
        <Alert
          variant={content.variant}
          className="flex flex-col gap-3 sm:gap-0 sm:flex-row sm:items-center sm:justify-between"
        >
          <div className="flex items-center gap-2">
            {content.icon}

            <div className="flex flex-col gap-2">
              <AlertTitle>{content.title}</AlertTitle>

              {content.description && <AlertDescription>{content.description}</AlertDescription>}
            </div>
          </div>

          <div className="flex items-center gap-2">
            {content.cta && (
              <Button variant="outline" className="w-full text-primary" onClick={() => setIsModalOpen(true)}>
                {content.cta}
                <ArrowUpRight className="ml-2 h-4 w-4" />
              </Button>
            )}

            {content.onClose && (
              <Button variant="ghost" size="icon" onClick={content.onClose}>
                <X className="h-4 w-4" />
                <span className="sr-only">Close</span>
              </Button>
            )}
          </div>
        </Alert>
      </div>

      <AccountVerificationModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onFinish={() => {
          refetch()
          setIsModalOpen(false)
        }}
      />
    </AccountVerificationProvider>
  )
}
