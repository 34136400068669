
import { SVGProps } from 'react'

type StreamProps = SVGProps<SVGSVGElement>

export const Stream = (props: StreamProps) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M2.33341 10.6667C1.87508 10.6667 1.48272 10.5035 1.15633 10.1771C0.829943 9.85072 0.666748 9.45835 0.666748 9.00002C0.666748 8.54169 0.829943 8.14933 1.15633 7.82294C1.48272 7.49655 1.87508 7.33335 2.33341 7.33335C2.79175 7.33335 3.18411 7.49655 3.5105 7.82294C3.83689 8.14933 4.00008 8.54169 4.00008 9.00002C4.00008 9.45835 3.83689 9.85072 3.5105 10.1771C3.18411 10.5035 2.79175 10.6667 2.33341 10.6667ZM3.70842 15.4167L2.54175 14.25L6.16675 10.625L7.33342 11.7917L3.70842 15.4167ZM6.20842 7.33335L2.58341 3.70835L3.75008 2.54169L7.37508 6.16669L6.20842 7.33335ZM9.00008 17.3334C8.54175 17.3334 8.14939 17.1702 7.823 16.8438C7.49661 16.5174 7.33342 16.125 7.33342 15.6667C7.33342 15.2084 7.49661 14.816 7.823 14.4896C8.14939 14.1632 8.54175 14 9.00008 14C9.45842 14 9.85078 14.1632 10.1772 14.4896C10.5036 14.816 10.6667 15.2084 10.6667 15.6667C10.6667 16.125 10.5036 16.5174 10.1772 16.8438C9.85078 17.1702 9.45842 17.3334 9.00008 17.3334ZM9.00008 4.00002C8.54175 4.00002 8.14939 3.83683 7.823 3.51044C7.49661 3.18405 7.33342 2.79169 7.33342 2.33335C7.33342 1.87502 7.49661 1.48266 7.823 1.15627C8.14939 0.829881 8.54175 0.666687 9.00008 0.666687C9.45842 0.666687 9.85078 0.829881 10.1772 1.15627C10.5036 1.48266 10.6667 1.87502 10.6667 2.33335C10.6667 2.79169 10.5036 3.18405 10.1772 3.51044C9.85078 3.83683 9.45842 4.00002 9.00008 4.00002ZM11.7917 7.37502L10.6251 6.16669L14.2917 2.54169L15.4584 3.70835L11.7917 7.37502ZM14.2917 15.4167L10.6667 11.7917L11.8334 10.625L15.4584 14.25L14.2917 15.4167ZM15.6667 10.6667C15.2084 10.6667 14.8161 10.5035 14.4897 10.1771C14.1633 9.85072 14.0001 9.45835 14.0001 9.00002C14.0001 8.54169 14.1633 8.14933 14.4897 7.82294C14.8161 7.49655 15.2084 7.33335 15.6667 7.33335C16.1251 7.33335 16.5174 7.49655 16.8438 7.82294C17.1702 8.14933 17.3334 8.54169 17.3334 9.00002C17.3334 9.45835 17.1702 9.85072 16.8438 10.1771C16.5174 10.5035 16.1251 10.6667 15.6667 10.6667Z" fill="currentColor" />
    </svg>
  )
}




