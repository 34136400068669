import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { CohortDto } from '@hub-la/sdk-bff-product'
import { inject } from 'inversify'
import { PatchCohortNameInput } from '../../domain/dtos/cohorts/patch-cohort-name'
import { GeneralError } from '../../domain/errors/general'
import { Envs } from '../../envs'

export class PatchCohortName {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(input: PatchCohortNameInput, signal?: AbortSignal): Promise<CohortDto> {
    const response = await this.httpClient.request({
      method: HttpMethod.PATCH,
      url: `${Envs.BFF_PRODUCT_URL}/products/${input?.productId}/cohorts/${input?.cohortId}/name`,
      body: {
        name: input?.name,
      },
      signal,
    })

    if (response.statusCode === HttpStatusCode.OK || response.statusCode === HttpStatusCode.CREATED) {
      return response.data
    }

    throw new GeneralError()
  }
}
