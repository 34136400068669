import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useToast } from '@hub-la/shadcn'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { GetSettingsInput } from '../../../domain/dtos/settings'
import { QueryKey } from '../../../domain/enums/query-key'
import { GetSettings } from '../../../usecases/settings/get-settings'

export const useGetSettings = (input: GetSettingsInput) => {
  const container = useContainer()
  const { toast } = useToast()
  const { t } = useTranslation()

  return useQuery(
    [QueryKey.getSettings, input.productId, input.type],
    () => new GetSettings(container.get(HttpClient)).execute(input),
    {
      refetchOnWindowFocus: false,
      retry: 3,
      keepPreviousData: true,
      onError: (error: Error) => toast({ description: t(error.message), variant: 'destructive' }),
    },
  )
}
