export const percentMask = (value) => {
  const percent = (value / 100)
    .toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    })
    .replace('R$', '')

  return percent
}
