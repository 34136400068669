import { Card, CardContent, Skeleton } from '@hub-la/shadcn'

export const Loading = () => {
  return (
    <div className="max-w-2xl my-4 mx-auto flex flex-col gap-6">
      <div className="flex flex-col gap-2 items-center">
        <Skeleton className="h-7 w-64 mb-2" />
        <Skeleton className="h-4 w-full max-w-md" />
      </div>

      <div className="flex gap-4">
        {[...Array(2)].map((_, i) => (
          <Card key={i} className="w-full">
            <CardContent className="px-4 py-10 flex flex-col gap-4 items-center">
              <Skeleton className="h-5 w-5 rounded-full" />
              <Skeleton className="h-4 w-24" />
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  )
}
