import { AuthProvider, NewSelectUserLoginPreferences } from '@hub-la/fe-auth'
import { withRoleplayRedirect } from '../components/roleplay-redirect'

export const SelectUserNavigationInit = withRoleplayRedirect(() => {
  return (
    <AuthProvider>
      <NewSelectUserLoginPreferences />
    </AuthProvider>
  )
})
