import { OfferDto, ProductDto } from "@hub-la/sdk-bff-product"
import { Card, CardContent, CardHeader, CardTitle, Checkbox, Separator } from "@hub-la/shadcn"
import { ArrowBigDown } from "lucide-react"
import PlaceholderLogo from '../../../../../../../assets/placeholder-image.svg'
import { formatLocalNumber } from "../../../../../../../../domain/vos/format-local-number"
import { getOfferPrice } from "libs/frontend/modules/offer/src/lib/usecases/get-offer-price.usecase"

interface OrderbumpPreviewProps {
  title?: string
  callToAction?: string
  description?: string
  offer?: OfferDto
  product?: ProductDto
}

export const MultipleOrderBumpPreview = ({ offer, product, ...props }: OrderbumpPreviewProps) => {
  return (
    <div className="p-7 bg-muted w-full rounded-lg">
      <Card className="text-sm">
        <CardHeader className="p-3">
          <CardTitle className="text-red-500 text-sm flex items-center gap-1">
            <ArrowBigDown className="w-5 h-full" />
            {props.title || "Título"}
          </CardTitle>
        </CardHeader>
        <CardContent className="p-3 pt-0">
          <div className="flex flex-col space-y-1.5">
            <div className="flex items-center space-x-2">
              <Checkbox />
              <label className="text-sm font-medium leading-none">
                {props.callToAction || "call to action"}
              </label>
            </div>
            <p className="ml-6 text-muted-foreground">
              {props.description || "Descrição"}
            </p>
          </div>
          <div className="flex flex-col px-6">
            <Separator className="my-3" />
            <div className="flex gap-3">
              <img
                src={product?.picture ?? PlaceholderLogo}
                alt="Product logo"
                className="w-[70px] h-[46px] object-cover rounded"
              />
              <div className="space-y-1 text-sm">
                <p>{offer?.name ?? "Produto"}</p>
                <p>{offer && getOfferPrice(offer)}</p>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  )
}
