import { AssetType, AssetUploader, Field, UploadStatus } from '@hub-la/fe-asset'
import { Avatar, Button, cn, Progress, toast } from '@hub-la/shadcn'
import { useFormikContext } from 'formik'
import { get, isNil } from 'lodash'
import { Image, ImagePlayIcon, Loader2, Trash2Icon, Upload } from 'lucide-react'
import { useEffect } from 'react'
import { PostType } from '../../../domain/dtos'

type Props = {
  fieldName: Field
  postType?: PostType | null
  hasLoading?: boolean
  showSnackbar?: boolean
  className?: string
}

export const CardUploader = ({ fieldName, postType, hasLoading = false, showSnackbar = true }: Props) => {
  const { values, setFieldValue } = useFormikContext<object>()

  const statusFields = {
    customCover: 'customCoverStatus',
    mainMedia: 'mainMediaProgressStatus',
  }
  const progressFields = {
    customCover: 'customCoverProgress',
    mainMedia: 'mainMediaProgress',
  }

  const statusFieldName = statusFields[fieldName]
  const progressFieldName = progressFields[fieldName]
  const statusField = values[statusFieldName]
  const progressField = values[progressFieldName]
  const field = values[fieldName]

  const handleDelete = () => {
    if (isNil(values[fieldName])) {
      return
    }

    setFieldValue(fieldName, null)
    setFieldValue(statusFieldName, UploadStatus.NO_CALLED)
    setFieldValue(progressFieldName, 0)
  }

  const getImage = () => {
    if (fieldName === 'customCover') {
      return get(field, 'url', null)
    }

    const fieldType = get(field, 'type')

    if ([AssetType.video, AssetType.application].includes(fieldType)) {
      return get(field, 'metadata.thumbnailUrl')
    }

    if (fieldType === AssetType.image) {
      return get(field, 'url')
    }
  }

  const image = getImage()

  const getTitle = () => {
    if (fieldName === 'customCover') {
      return 'Adicionar capa'
    }

    switch (postType) {
      case 'event':
        return 'Adicione a gravação'
      case 'ebook':
        return 'Adicione um PDF'
      case 'video':
        return 'Adicione um vídeo'
      default:
        return 'Adicione uma imagem ou vídeo'
    }
  }

  const getSubtitle = () => {
    if (fieldName === 'customCover') {
      return null
    }

    switch (postType) {
      case 'event':
        return 'Você pode adicionar o vídeo depois. (.mp4, .avi, .mpeg...)'
      case 'ebook':
        return 'Formato .pdf, .xls, .doc...'
      case 'video':
        return 'Formato .mp4, .avi, .mpeg...'
      default:
        return 'Formatos .png, .jpg, .pdf, .mp4...'
    }
  }

  const subtitle = getSubtitle()
  const icon = fieldName === 'customCover' ? <Image /> : <Upload />

  const isLoading = statusField === UploadStatus.LOADING && hasLoading
  const isProcessing = statusField === UploadStatus.PROCESSING && hasLoading

  const shouldToast =
    [UploadStatus.ERROR, UploadStatus.SUCCESS, UploadStatus.FILE_NOT_ALLOWED].includes(statusField) && showSnackbar
  const variants = {
    [UploadStatus.FILE_NOT_ALLOWED]: 'destructive',
    [UploadStatus.ERROR]: 'destructive',
    [UploadStatus.SUCCESS]: 'default',
  }
  const messages = {
    [UploadStatus.ERROR]: 'Upload falhou',
    [UploadStatus.SUCCESS]: 'Upload feito com sucesso',
    [UploadStatus.FILE_NOT_ALLOWED]: 'Formato de arquivo não permitido',
  }

  const variant = variants[statusField]
  const description = messages[statusField]

  useEffect(() => {
    if (!shouldToast) return

    toast({ description, variant })
  }, [description, variant, shouldToast])

  return (
    <div
      className={cn(
        'w-full rounded-lg overflow-hidden',
        fieldName === 'mainMedia' ? 'h-96' : 'h-48 w-32',
        !image ? 'hover:bg-secondary/30 background border-2 border-dashed ' : '',
      )}
    >
      {isLoading ? (
        <div
          className="flex flex-col items-center text-center relative overflow-hidden h-full justify-center"
          data-testid="loading"
        >
          <div className="relative">
            <Progress value={progressField} className="w-16 h-16 -rotate-90" />
            <Loader2 className={'absolute inset-0 m-auto animate-spin mix-blend-difference'} />
          </div>

          <p className="mt-2 text-sm">{Math.round(progressField)}%</p>

          <p className="mt-1 text-base font-medium">Enviando arquivo...</p>
        </div>
      ) : isProcessing ? (
        <div
          className="flex flex-col items-center text-center relative overflow-hidden h-full justify-center"
          data-testid="processing"
        >
          <div className="relative h-16 w-16 bg-secondary flex justify-center items-center rounded-full">
            <ImagePlayIcon />
          </div>

          <p className="mt-3 text-base font-medium">Gerando capa do conteúdo...</p>

          <p className="mt-1 text-sm">
            Isso pode levar alguns minutos, mas você já pode <strong>salvar seu post</strong>.
          </p>
        </div>
      ) : image ? (
        <div
          className="flex flex-col items-center text-center relative overflow-hidden h-full justify-center"
          data-testid="image"
        >
          <img src={image} alt="" className="object-cover w-full h-full" />

          <Button variant="secondary" size="icon" className="absolute top-2 right-2" onClick={handleDelete}>
            <Trash2Icon className="h-4 w-4" />
          </Button>
        </div>
      ) : (
        <AssetUploader field={fieldName} statusFieldName={statusFieldName}>
          <div
            className="flex flex-col items-center text-center relative overflow-hidden h-full justify-center"
            data-testid="upload"
          >
            <Avatar className="w-14 h-14 flex items-center justify-center bg-secondary">{icon}</Avatar>

            <p className="mt-2 text-base">{getTitle()}</p>

            {subtitle && (
              <p className="mt-1 text-sm" data-testid="subtitle">
                {subtitle}
              </p>
            )}
          </div>
        </AssetUploader>
      )}
    </div>
  )
}
