import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation } from '@tanstack/react-query'
import { useHistory } from 'react-router-dom'
import { Invite } from '../../domain/dtos/invite'
import { InvitePartner } from '../../usecases/invite-partner'

export const useInvitePartner = () => {
  const container = useContainer()
  const history = useHistory()

  return useMutation((params: Invite) => new InvitePartner(container.get(HttpClient)).execute(params), {
    onSuccess: () => {
      setTimeout(() => history.goBack(), 1000)
    },
  })
}
