import { Input, Label } from '@hub-la/shadcn'
import React from 'react'
import { FieldPath } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { OfferSchemaValidationType } from '../validations/offer-validation'
import { OrderBumpSchemaValidationType } from '../validations/order-bump-validation'

const currencyFormatter = (value: string) => {
  if (!value) return '0'

  const amountFormatted = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(Number(value) / 100)

  return amountFormatted.replace(/^R\$\s*/, '')
}

interface CurrencyFieldProps {
  label: string
  name: FieldPath<OfferSchemaValidationType> | FieldPath<OrderBumpSchemaValidationType>
  error?: boolean
  helperText?: string
  onChange: (value: number | undefined) => void
  value: number | undefined
}

export const CurrencyField: React.FC<CurrencyFieldProps> = ({ name, label, error, helperText, onChange, value }) => {
  return (
    <div className="flex flex-col space-y-1.5">
      <Label htmlFor={name} className="block">
        {label}
      </Label>
      <div className="relative rounded-md shadow-sm">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <span className="text-muted-foreground sm:text-sm">R$</span>
        </div>
        <NumberFormat
          id={name}
          name={name}
          customInput={Input}
          decimalScale={2}
          fixedDecimalScale
          format={currencyFormatter}
          value={value}
          onValueChange={({ floatValue }) => onChange(floatValue)}
          className={`pl-9 ${
            error
              ? 'border-red-300 text-red-900 placeholder-red-300 focus:border-red-500 focus:ring-red-500'
              : 'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500'
          }`}
        />
      </div>
      {helperText && <p className={`mt-2 text-sm ${error ? 'text-red-600' : 'text-muted-foreground'}`}>{helperText}</p>}
    </div>
  )
}
