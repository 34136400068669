import { HttpClient, HttpMethod, HttpResponse, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import isNil from 'lodash/isNil'
import { GetListsInput } from '../domain/dtos/get-lists-input'
import { GetListsOutput } from '../domain/dtos/get-lists-output'
import { GeneralError } from '../domain/errors/general'
import { Envs } from '../envs'

export class GetLists {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient<GetListsOutput>,
  ) {}

  public async execute(input: GetListsInput): Promise<GetListsOutput> {
    const response = await this.httpClient.request({
      method: HttpMethod.GET,
      url: `${Envs.BFF_WEB_URL}/integrations/${input.integrationId}/provider/lists`,
    })

    if (response.statusCode === HttpStatusCode.OK) {
      return this.makeOutput(response)
    }

    throw new GeneralError()
  }

  private makeOutput(response: HttpResponse<GetListsOutput>): GetListsOutput {
    const data = response.data

    if (isNil(data)) {
      return []
    }

    return data
  }
}
