import { Check, CircleDashed, X } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { ProductStatus } from '../../domain/enums/product-status'

type ProductStatusTagProps = {
  status: ProductStatus
}

const getIcon = (status: ProductStatus) => {
  switch (status) {
    case ProductStatus.SELLING:
    case ProductStatus.PUBLISHED:
      return (
        <div className="bg-green-600 rounded-full w-4 h-4 flex justify-center items-center">
          <Check className="text-primary-foreground w-3 h-3" strokeWidth={3} />
        </div>
      )
    case ProductStatus.WAITLISTED:
      return (
        <div className="bg-amber-600 rounded-full w-4 h-4 flex justify-center items-center">
          <Check className="text-primary-foreground w-3 h-3" strokeWidth={3} />
        </div>
      )
    case ProductStatus.DRAFT:
      return <CircleDashed className="w-4 h-4 text-ring" />
    case ProductStatus.NOT_ENABLED:
    case ProductStatus.NOT_SELLING:
      return (
        <div className="bg-muted-foreground rounded-full w-4 h-4 flex justify-center items-center">
          <X className="text-primary-foreground w-3 h-3" strokeWidth={3} />
        </div>
      )
    case ProductStatus.UNAVAILABLE:
      return (
        <div className="bg-red-700 rounded-full w-4 h-4 flex justify-center items-center">
          <X className="text-primary-foreground w-3 h-3" strokeWidth={3} />
        </div>
      )
    default:
      return <CircleDashed className="w-4 h-4 text-ring" />
  }
}

export const ProductStatusTag = ({ status }: ProductStatusTagProps) => {
  const { t } = useTranslation()

  return (
    <div className="flex items-center gap-1.5">
      {getIcon(status)}
      <span>{t(`products.status.${status ?? ProductStatus.SELLING}`)}</span>
    </div>
  )
}
