import { Button } from '@hub-la/shadcn'
import { ExternalLink } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IntegrationProvider } from '../../../domain/dtos/integration-provider'
import { ConnectAccountModal } from '../connect-account-modal'
import { DisableIntegrationModal } from '../disable-integration-modal'
import { connectIntegrationSchema, connectSchema } from './themembers.schema'

type Props = {
  provider: IntegrationProvider
  integrationId?: string
  loading: boolean
  refetch: () => void
  setTab: (name: string) => void
}

export const TheMembersCardActions = ({ provider, integrationId, loading, refetch, setTab }: Props) => {
  const { t } = useTranslation()
  const [showConnectAccount, setShowConnectAccount] = useState(false)
  const [showDisableIntegration, setShowDisableIntegration] = useState(false)

  return (
    <div className="w-full flex flex-col items-center justify-between gap-2">
      {!integrationId ? (
        <Button className="w-full" loading={loading} onClick={() => setShowConnectAccount(true)}>
          {t(`buttons.activeIntegration`)}
        </Button>
      ) : (
        <>
          <Button className="w-full" variant="outline" loading={loading} onClick={() => setTab('settings')}>
            {t(`buttons.settings`)}
          </Button>
          <Button
            className="w-full"
            variant="outline"
            loading={loading}
            onClick={() => setShowDisableIntegration(true)}
          >
            {t(`buttons.disable`)}
          </Button>
        </>
      )}

      {!integrationId ? (
        <ConnectAccountModal
          schema={connectSchema}
          validation={connectIntegrationSchema}
          provider={provider}
          isOpen={showConnectAccount}
          onClose={() => setShowConnectAccount(false)}
          onSubmit={() => {
            setShowConnectAccount(false)
            refetch()
          }}
        />
      ) : (
        <DisableIntegrationModal
          integrationId={integrationId}
          provider={provider}
          isOpen={showDisableIntegration}
          onClose={() => setShowDisableIntegration(false)}
          onConfirm={() => {
            setShowDisableIntegration(false)
            refetch()
          }}
        />
      )}

      <Button
        variant="ghost"
        className="w-full text-muted-foreground hover:text-foreground"
        onClick={() => window.open(t(`integrations.${provider}.about.link`), '_blank')}
      >
        <span className="flex items-center gap-2">
          {t('aboutTab.seeMore')}
          <ExternalLink size={16} />
        </span>
      </Button>
    </div>
  )
}
