import { AuthLayout } from '../../../components/auth-layout'
import { MetaTags } from '../../../components/meta-tags'
import { SignInByEmailCard } from '../../../components/sign-in-by-email/sign-in-by-email'

const SignInEmailPage = () => {
  return (
    <AuthLayout>
      <MetaTags />
      <SignInByEmailCard />
    </AuthLayout>
  )
}

export { SignInEmailPage }
