import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import moment from 'moment'
import { DownloadCouponsXlsxInput } from '../domain/dtos/download-coupon-xlsx-input'
import { GeneralError } from '../domain/errors/general'
import { Envs } from '../envs'
import { GetOfferIds } from './get-offer-ids'

export class DownloadCouponsXlsx {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(input: DownloadCouponsXlsxInput): Promise<void> {
    const response = await this.httpClient.request({
      method: HttpMethod.POST,
      url: `${Envs.BFF_WEB_URL}/coupons/export`,
      responseType: 'arraybuffer',
      body: this.makeBody(input),
    })

    if (response.statusCode === HttpStatusCode.CREATED) {
      return this.makeOutput(response.data)
    }

    throw new GeneralError()
  }

  private makeOutput(value: ArrayBuffer) {
    // Create a Blob from the ArrayBuffer
    const blob = new Blob([new Uint8Array(value)], { type: 'text/csv;charset=utf-8;' })

    // Create a URL for the Blob
    const blobURL = URL.createObjectURL(blob)

    // Create a download link
    const link = document.createElement('a')
    link.href = blobURL
    link.download = `export-coupons-${moment().format('YYYY-MM-DDT_HH-mm-ss')}.xlsx` // The desired filename

    // Trigger a click event to initiate the download
    link.click()

    // Clean up the Blob URL
    URL.revokeObjectURL(blobURL)
  }

  private makeBody(input: DownloadCouponsXlsxInput) {
    const offerIds = new GetOfferIds().execute({ offerIds: input.offerIds, offers: input.offers })

    return {
      offerIds,
      filters: {
        states: input.statuses,
      },
    }
  }
}
