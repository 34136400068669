import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useToast } from '@hub-la/shadcn'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { ListOffersInput } from '../../../domain/dtos/offers/list-offers'
import { QueryKey } from '../../../domain/enums/query-key'
import { GetOffersList } from '../../../usecases/offers/get-offers-list'

export const useGetOffersList = (input?: ListOffersInput) => {
  const { toast } = useToast()
  const { t } = useTranslation()
  const container = useContainer()

  return useQuery(
    [QueryKey.getOffersList, input?.productId, input?.page, input?.pageSize],
    ({ signal }) => new GetOffersList(container.get(HttpClient)).execute(input, signal),
    {
      refetchOnWindowFocus: false,
      retry: 3,
      keepPreviousData: true,
      onError: (error: Error) => toast({ description: t(error.message), variant: 'destructive' }),
    },
  )
}
