import React from 'react'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger, Badge } from '@hub-la/shadcn'

type UtmTagsProps = {
  utm?: {
    source?: string
    medium?: string
    campaign?: string
    content?: string
    term?: string
  }
}

export const UTMTags: React.FC<UtmTagsProps> = ({ utm }) => {
  const utmOrder = ['source', 'medium', 'campaign', 'content', 'term']

  const utmEntries = Object.entries(utm ?? {})
    .filter(([key]) => utmOrder.includes(key))
    .sort((a, b) => utmOrder.indexOf(a[0]) - utmOrder.indexOf(b[0]))

  const visibility = 3
  const visibleElements = utmEntries.slice(0, visibility)
  const extraElements = utmEntries.slice(visibility)
  const hasMoreItems = extraElements.length > 0

  return (
    <div className="flex flex-row items-center gap-1">
      <TooltipProvider>
        {visibleElements.map(([key, value], index) => (
          <Tooltip key={index}>
            <TooltipTrigger>
              <Badge variant="secondary" className="text-xs">
                {value}
              </Badge>
            </TooltipTrigger>
            <TooltipContent>
              <p>utm_{key}</p>
            </TooltipContent>
          </Tooltip>
        ))}
        {hasMoreItems && (
          <Tooltip>
            <TooltipTrigger>
              <Badge variant="secondary" className="text-xs">
                + {extraElements.length}
              </Badge>
            </TooltipTrigger>
            <TooltipContent>
              <p>{extraElements.map(([key, value]) => `utm_${key}: ${value}`).join(', ')}</p>
            </TooltipContent>
          </Tooltip>
        )}
      </TooltipProvider>
    </div>
  )
}
