import { Skeleton, TableCell, TableRow } from '@hub-la/shadcn'

export const TableLoading = () => {
  return (
    <TableRow>
      <TableCell className="py-2">
        <Skeleton className="h-4 w-[90px]" />
      </TableCell>

      <TableCell className="py-2">
        <div className="flex flex-col space-y-1">
          <Skeleton className="h-4 w-[180px]" />
          <Skeleton className="h-3 w-[62px]" />
        </div>
      </TableCell>
    </TableRow>
  )
}
