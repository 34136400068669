import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation } from '@tanstack/react-query'
import { PatchCohortNameInput } from '../../../domain/dtos/cohorts/patch-cohort-name'
import { QueryKey } from '../../../domain/enums/query-key'
import { PatchCohortName } from '../../../usecases/cohorts/patch-cohort-name'

export const usePatchCohortName = () => {
  const container = useContainer()

  return useMutation(
    [QueryKey.patchCohortName],
    (input: PatchCohortNameInput) => new PatchCohortName(container.get(HttpClient)).execute(input),
    {
      retry: false,
    },
  )
}
