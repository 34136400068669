import { HelpCircle } from 'lucide-react'
import { useTranslation } from 'react-i18next'

interface IProps {
  onClick?: () => void
}

export const LinkHelperFAQ = ({ onClick }: IProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-row justify-center md:justify-start items-center">
      <p className="text-sm text-[#1A1C18] font-medium mr-2">{t('verificationTelegram.faq.helper')}</p>
      <a
        data-testid="link-to-faq"
        href="https://help.hub.la/hc/pt-br/articles/8779431638036"
        target="_blank"
        rel="noopener noreferrer"
        onClick={onClick}
        className="flex items-center text-sm text-[#1A1C18] font-bold underline"
      >
        {t('verificationTelegram.faq.link')}
        <HelpCircle className="w-4 h-4 ml-1 text-[#1A1C18]" />
      </a>
    </div>
  )
}
