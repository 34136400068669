import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { ArrowLeft, ArrowRight } from 'lucide-react'
import { Button, Card } from '@hub-la/shadcn'
import times from 'lodash/times'
import { Autocomplete } from './components/autocomplete'

// Componente Skeleton personalizado
const Skeleton: React.FC<{ className?: string }> = ({ className }) => (
  <div className={`animate-pulse bg-muted rounded ${className}`} />
)

export const Loading: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <div className="pb-10 px-4 sm:px-6 lg:px-0 max-w-[946px] w-full mx-auto">
      <div className="mb-8 self-start w-fit">
        <Button variant="ghost" onClick={() => history.goBack()} className="gap-2">
          <ArrowLeft className="h-4 w-4" />
          {t('back')}
        </Button>
      </div>
      <div className="mb-4">
        <h1 className="text-4xl font-bold">{t('title')}</h1>
      </div>
      <div className="mb-4">
        <Autocomplete data={[]} loading={true} />
      </div>

      <div className="my-6 space-y-4">
        <div>
          <p className="text-lg">{t('activeSubscriptions')}</p>
        </div>
        <div className="space-y-4">
          {times(4).map((_, idx) => (
            <Card
              key={idx}
              className="p-3 flex items-center justify-between cursor-pointer transition-colors hover:bg-muted"
            >
              <div className="flex items-center gap-2">
                <Skeleton className="w-[70px] h-[45.6px]" />
                <div className="space-y-1">
                  <Skeleton className="h-5 w-[120px]" />
                  <Skeleton className="h-4 w-[240px]" />
                </div>
              </div>
              <ArrowRight className="h-6 w-6" />
            </Card>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Loading
