export enum PermissionResource {
  HOME = 'home',
  HOME_ABANDONED_CHECKOUT = 'home_abandoned_checkout',
  SALES = 'sales',
  SUBSCRIPTIONS = 'subscriptions',
  SUBSCRIPTIONS_DEACTIVATE = 'subscriptions_deactivate',
  SUBSCRIPTIONS_ADD_DAILY_CREDITS = 'subscriptions_add_daily_credits',
  PRODUCTS = 'products',
  PRODUCTS_MANAGE_MEMBERS = 'products_manage_members',
  REPORTS = 'reports',
  MEMBERS = 'members', // TODO: Remove this permission resource when the new members page is ready
  CLIENTS = 'clients',
  AFFILIATES = 'affiliates',
  COUPONS = 'coupons',
  REFUNDS = 'refunds',
  WALLET = 'wallet',
  INTEGRATIONS = 'integrations',
  PRODUCTS_MANAGE_CO_PRODUCTION = 'products_manage_co_production',
  PRODUCTS_MANAGE_POST_COMMENTS = 'products_manage_post_comments',
}
