import { PlanPriceDto } from '@hub-la/sdk-bff-product'
import { Badge, Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@hub-la/shadcn'
import { useTranslation } from 'react-i18next'
import { FormatCurrencyBRL } from '../../../../domain/vos/format-currency-brl'

const PlansTag = ({ plans = [] }: { plans: PlanPriceDto[] }) => {
  const { t } = useTranslation()

  const firstPlan = plans[0]
  const plansLeft = plans.slice(1)

  return (
    <div className="flex items-center gap-1">
      <span className="text-muted-foreground">
        {new FormatCurrencyBRL(firstPlan.price).getFormattedCurrency()} / {t('offers.plans.' + firstPlan.interval)}
      </span>
      {plansLeft.length > 0 && (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Badge variant="secondary" className="font-medium cursor-pointer rounded-full">
                +{plansLeft.length}
              </Badge>
            </TooltipTrigger>
            <TooltipContent>
              <div className="whitespace-pre-line">
                {plansLeft
                  .map(
                    (plan) =>
                      `${new FormatCurrencyBRL(plan.price).getFormattedCurrency()} / ${t(
                        'offers.plans.' + plan.interval,
                      )}`,
                  )
                  .join('\n')}
              </div>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      )}
    </div>
  )
}

export { PlansTag }
