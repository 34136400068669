/* tslint:disable */
/* eslint-disable */
/**
 * BFF Web Product
 * API reference used to call the backend from the web frontend
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface OrderBumpDto
 */
export interface OrderBumpDto {
    /**
     * Allows the buyer to purchase a second product pre-configured by the seller by simply       selecting it during the checkout process.
     * @type {string}
     * @memberof OrderBumpDto
     */
    'name': OrderBumpDtoNameEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum OrderBumpDtoNameEnum {
    ORDER_BUMP = 'ORDER_BUMP',
    UPSELL = 'UPSELL'
}


