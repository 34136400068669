import { useGetNotifications } from '@hub-la/fe-get-user'
import { Button, Card, CardContent, CardHeader, CardTitle, Label, Separator, Switch } from '@hub-la/shadcn'
import React, { useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { NotificationSettingsData, NotificationSettingsOptions } from '../../../domain/dtos/notifications'
import { useSetNotifications } from '../../hooks/use-set-notifications'

const DEFAULT_NOTIFICATION_OPTIONS: NotificationSettingsOptions = {
  adminJoined: false,
  memberJoined: false,
  nonMemberJoined: false,
  purchased: false,
  removed: false,
  whitelisted: false,
  postOwnerCommentAlert: false,
  threadParticipationReplyAlert: false,
  bankSlipGenerated: false,
  checked: false,
  postCreated: false,
}

const DEFAULT_NOTIFICATION_DATA: NotificationSettingsData = {
  paymentMethod: false,
  productName: false,
  userName: false,
  userEmail: false,
  userPhone: false,
}

const NOTIFICATION_OPTION_ORDER: (keyof NotificationSettingsOptions)[] = [
  'adminJoined',
  'memberJoined',
  'nonMemberJoined',
  'purchased',
  'removed',
  'whitelisted',
  'postOwnerCommentAlert',
  'threadParticipationReplyAlert',
]

const NOTIFICATION_DATA_ORDER: (keyof NotificationSettingsData)[] = [
  'paymentMethod',
  'productName',
  'userName',
  'userEmail',
  'userPhone',
]

interface FormValues {
  options: NotificationSettingsOptions
  data: NotificationSettingsData
  sound: boolean
}

export const Notifications: React.FC = () => {
  const { t } = useTranslation()
  const { mutate: setNotifications, isLoading } = useSetNotifications()
  const { data: currentNotifications, isLoading: isFetching } = useGetNotifications()

  const defaultValues = useMemo(() => {
    return {
      options: currentNotifications?.options ?? DEFAULT_NOTIFICATION_OPTIONS,
      data: currentNotifications?.data ?? DEFAULT_NOTIFICATION_DATA,
      sound: currentNotifications?.sound ?? false,
    }
  }, [currentNotifications])

  const {
    control,
    handleSubmit,
    formState: { isDirty },
    reset,
  } = useForm<FormValues>({ defaultValues })

  useEffect(() => {
    reset(defaultValues)
  }, [currentNotifications, defaultValues, reset])

  const onSubmit = (formData: FormValues) => {
    setNotifications(formData)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4 my-4 ">
      <Card className="w-full max-w-2xl">
        <CardHeader>
          <CardTitle className="text-lg">{t('notifications.receiveAbout')}</CardTitle>
        </CardHeader>

        <CardContent className="flex flex-col gap-6">
          <div className="flex flex-col gap-4">
            {NOTIFICATION_OPTION_ORDER.map((key, idx) => (
              <div className="flex flex-col gap-3" key={key}>
                <div className="flex items-center justify-between">
                  <Label htmlFor={key}>{t(`notifications.options.${key}`)}</Label>

                  <Controller
                    name={`options.${key}`}
                    control={control}
                    render={({ field }) => (
                      <Switch disabled={isFetching} id={key} checked={field.value} onCheckedChange={field.onChange} />
                    )}
                  />
                </div>

                {idx !== NOTIFICATION_OPTION_ORDER.length - 1 && <Separator />}
              </div>
            ))}
          </div>
        </CardContent>
      </Card>

      <Card className="w-full max-w-2xl">
        <CardHeader>
          <CardTitle className="text-lg">{t('notifications.informationInNotifications')}</CardTitle>
        </CardHeader>

        <CardContent className="flex flex-col gap-6">
          <div className="flex flex-col gap-4">
            {NOTIFICATION_DATA_ORDER.map((key, idx) => (
              <div className="flex flex-col gap-3" key={key}>
                <div className="flex items-center justify-between">
                  <Label htmlFor={key}>{t(`notifications.data.${key}`)}</Label>

                  <Controller
                    name={`data.${key}`}
                    control={control}
                    render={({ field }) => (
                      <Switch disabled={isFetching} id={key} checked={field.value} onCheckedChange={field.onChange} />
                    )}
                  />
                </div>

                {idx !== NOTIFICATION_DATA_ORDER.length - 1 && <Separator />}
              </div>
            ))}
          </div>
        </CardContent>
      </Card>

      <Card className="w-full max-w-2xl">
        <CardHeader>
          <CardTitle className="text-lg">{t('notifications.sound')}</CardTitle>
        </CardHeader>

        <CardContent className="flex flex-col gap-6">
          <div className="flex flex-col gap-4">
            <div className="flex items-center justify-between">
              <Label htmlFor="notification-sound">{t('notifications.enableSound')}</Label>

              <Controller
                name="sound"
                control={control}
                render={({ field }) => (
                  <Switch
                    disabled={isFetching}
                    id="notification-sound"
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                )}
              />
            </div>
          </div>
        </CardContent>
      </Card>

      {isDirty && (
        <Button type="submit" loading={isLoading} className="sm:ml-auto w-full sm:w-auto">
          {t('save')}
        </Button>
      )}
    </form>
  )
}
