import { Card, CardContent, CardHeader, CardTitle } from '@hub-la/shadcn'
import { useTranslation } from 'react-i18next'
import { InvoiceAmountDetail } from '../../../../domain/dtos/invoice-amount-detail'
import { formatCurrency } from '../../../../domain/vos/format-currency'
import { ContentRow } from './content-row'

type Props = {
  detail: InvoiceAmountDetail
}

export const Description = ({ detail }: Props) => {
  const { t } = useTranslation()

  return (
    <Card className="w-full h-full">
      <CardHeader>
        <CardTitle>{t('invoiceDownload.description.title')}</CardTitle>
      </CardHeader>

      <CardContent className="flex flex-col gap-6">
        <div className="flex flex-col gap-2">
          {detail.products.map((product, i) => (
            <ContentRow
              key={i}
              showDivider
              title={t('invoiceDownload.description.product', { productName: product.productName })}
              content={<p className="text-base text-muted-foreground">{formatCurrency(product.priceCents / 100)}</p>}
            />
          ))}

          {detail.discountCents ? (
            <ContentRow
              showDivider
              title={t('invoiceDownload.description.discountCents', { couponCode: detail.couponCode })}
              content={<p className="text-base text-red-500">- {formatCurrency(detail.discountCents / 100)}</p>}
            />
          ) : null}

          {detail.installmentFeeCents ? (
            <ContentRow
              showDivider
              title={t('invoiceDownload.description.installmentFeeCents')}
              content={
                <p className="text-base text-muted-foreground">{formatCurrency(detail.installmentFeeCents / 100)}</p>
              }
            />
          ) : null}

          {detail.totalCents ? (
            <ContentRow
              title={t('invoiceDownload.description.totalCents')}
              fontWeight="bold"
              content={<p className="text-base font-bold">{formatCurrency(detail.totalCents / 100)}</p>}
            />
          ) : null}
        </div>
      </CardContent>
    </Card>
  )
}
