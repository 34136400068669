import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation } from '@tanstack/react-query'
import { CreateIntegrationInput } from '../../domain/dtos/create-integration-input'
import { CreateIntegrationOutput } from '../../domain/dtos/create-integration-output'
import { CreateIntegration } from '../../usecases/create-integration'

export const useCreateIntegration = () => {
  const container = useContainer()

  return useMutation<CreateIntegrationOutput, Error, CreateIntegrationInput>(
    async (params: CreateIntegrationInput) => new CreateIntegration(container.get(HttpClient)).execute(params),
    {
      retry: false,
    },
  )
}
