import React from 'react'

const CardLoading: React.FC = () => {
  return (
    <div className="flex items-center justify-between p-4 border-2  rounded-lg">
      <div className="flex items-center space-x-4">
        <div className="w-10 h-10 bg-secondary rounded-full animate-pulse" />
        <div className="space-y-2">
          <div className="h-4 bg-secondary rounded w-36 animate-pulse" />
          <div className="h-4 bg-secondary rounded w-60 animate-pulse" />
        </div>
      </div>
    </div>
  )
}

export const SelectAccountLoadingState: React.FC = () => {
  return (
    <div className="space-y-4">
      <CardLoading />
      <CardLoading />
      <CardLoading />
    </div>
  )
}
