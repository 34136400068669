import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Badge,
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Input,
  Label,
  ScrollArea,
  Separator,
  Skeleton,
  useToast,
} from '@hub-la/shadcn'
import { ChevronRight } from 'lucide-react'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import defaultImage from '../../assets/images/post-thumbnail-background-fallback.png'
import { useDuplicatePost } from '../../hooks/use-duplicate-post'
import { useGetProducts } from '../../hooks/use-get-products'
import { useListSections } from '../../hooks/use-list-sections'

type Props = {
  open: boolean
  onClose: () => void
  postId: string
}

export const DuplicatePostModal: React.FC<Props> = ({ open, onClose, postId }) => {
  const params = useParams<{ productId: string }>()
  const [productId, setProductId] = useState<string>()
  const [selectedSectionId, setSelectedSectionId] = useState<string>()
  const [currentStep, setCurrentStep] = useState<'selectProduct' | 'selectSection'>('selectProduct')
  const [searchTerm, setSearchTerm] = useState<string>('')

  const { t } = useTranslation()
  const { toast } = useToast()
  const { data: productsData = [], isFetching: isFetchingProducts } = useGetProducts()
  const { data: sectionsData, isFetching: isFetchingSections } = useListSections({
    productId,
    queryKey: `duplicate-modal-${postId}-${productId}`,
  })

  const { mutate, isLoading } = useDuplicatePost({
    onSuccess: () => {
      toast({
        title: t('duplicatePostModal.success'),
      })
      handleClose()
    },
  })

  const handleClose = () => {
    setProductId(undefined)
    setSelectedSectionId(undefined)
    setCurrentStep('selectProduct')
    setSearchTerm('')
    onClose()
  }

  const onConfirmProduct = () => {
    setCurrentStep('selectSection')
  }

  const filteredProducts = useMemo(() => {
    return productsData.filter((product) => product.name.toLowerCase().includes(searchTerm.toLowerCase()))
  }, [productsData, searchTerm])

  const filteredSections = useMemo(() => {
    return sectionsData?.items.filter((section) => section.name.toLowerCase().includes(searchTerm.toLowerCase())) || []
  }, [sectionsData, searchTerm])

  const selectedProduct = useMemo(() => {
    return productsData.find((product) => product.id === productId)
  }, [productsData, productId])

  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <DialogContent className="max-h-[80vh] sm:max-w-lg overflow-y-auto">
        <DialogHeader>
          <div className="flex flex-row items-center mt-2">
            <Badge variant="secondary">
              {t(
                currentStep === 'selectProduct'
                  ? 'duplicatePostModal.product.title'
                  : 'duplicatePostModal.section.title',
              )}
            </Badge>
            {selectedProduct && (
              <>
                <ChevronRight className="w-4 h-4 mx-2 text-muted-foreground" />
                <div className="flex flex-row mr-auto items-center">
                  <Avatar className="w-4 h-4 mr-2 rounded-sm">
                    <AvatarImage
                      src={selectedProduct.picture ?? defaultImage}
                      alt={selectedProduct.name}
                      className="object-cover"
                    />
                    <AvatarFallback>{selectedProduct?.name ? selectedProduct.name.charAt(0) : 'P'}</AvatarFallback>
                  </Avatar>
                  <Label>{selectedProduct.name}</Label>
                </div>
              </>
            )}
          </div>
        </DialogHeader>

        <div>
          <Input
            placeholder={t('duplicateModal.searchPlaceholder')}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="border-none focus-visible:outline-none focus-visible:ring-0 text-base"
          />
          <Separator />
        </div>

        <ScrollArea className="max-h-80">
          {currentStep === 'selectProduct' ? (
            isFetchingProducts ? (
              <Skeleton className="w-full h-28" />
            ) : (
              filteredProducts.map((product) => (
                <div
                  key={product.id}
                  onClick={() => setProductId(product.id)}
                  className={`flex items-center space-x-2 p-2 rounded cursor-pointer ${
                    product.id === productId ? 'bg-secondary' : 'hover:bg-secondary'
                  }`}
                >
                  <Avatar className="w-12 h-9 rounded-lg">
                    <AvatarImage src={product.picture ?? defaultImage} alt={product.name} className="object-cover" />
                    <AvatarFallback>{product.name ? product.name.charAt(0) : 'P'}</AvatarFallback>
                  </Avatar>
                  <span>{product.name}</span>
                </div>
              ))
            )
          ) : isFetchingSections ? (
            <Skeleton className="w-full h-28" />
          ) : (
            filteredSections.map((section) => (
              <div
                key={section.id}
                onClick={() => setSelectedSectionId(section.id)}
                className={`flex items-center space-x-2 p-2 rounded cursor-pointer ${
                  section.id === selectedSectionId ? 'bg-secondary' : 'hover:bg-secondary'
                }`}
              >
                <span>{section.name}</span>
              </div>
            ))
          )}
        </ScrollArea>

        <DialogFooter className="flex gap-2 justify-end">
          <Button variant="outline" onClick={handleClose}>
            {t('duplicatePostModal.cancel')}
          </Button>

          {currentStep === 'selectProduct' ? (
            <Button disabled={!productId} loading={isLoading || isFetchingSections} onClick={onConfirmProduct}>
              {t('duplicatePostModal.product.confirm')}
            </Button>
          ) : (
            <Button
              disabled={!selectedSectionId}
              loading={isLoading || isFetchingSections}
              onClick={() => mutate({ sectionId: selectedSectionId ?? '', productId: productId ?? '', postId })}
            >
              {t('duplicatePostModal.confirm')}
            </Button>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
