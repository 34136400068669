import { SVGProps } from 'react'

type SideBannerProps = SVGProps<SVGSVGElement>

export const SideBannerLayout = (props: SideBannerProps) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M3 2C3 1.44772 3.44772 1 4 1H16C16.5523 1 17 1.44772 17 2V4C17 4.55228 16.5523 5 16 5H4C3.44772 5 3 4.55228 3 4V2Z" fill="#E4E4E7" />
      <path d="M3 8C3 7.44772 3.44772 7 4 7H10C10.5523 7 11 7.44772 11 8V12C11 12.5523 10.5523 13 10 13H4C3.44772 13 3 12.5523 3 12V8Z" fill="#E4E4E7" />
      <path d="M13 8C13 7.44772 13.4477 7 14 7H16C16.5523 7 17 7.44772 17 8V12C17 12.5523 16.5523 13 16 13H14C13.4477 13 13 12.5523 13 12V8Z" fill="black" />
      <path d="M3 16C3 15.4477 3.44772 15 4 15H16C16.5523 15 17 15.4477 17 16V18C17 18.5523 16.5523 19 16 19H4C3.44772 19 3 18.5523 3 18V16Z" fill="#E4E4E7" />
    </svg>
  )
}


