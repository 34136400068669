import { Asset, AssetType } from '@hub-la/fe-asset'
import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { saveAs } from 'file-saver'
import { inject } from 'inversify'
import { Envs } from '../envs'

export class SaveFile {
  public constructor(@inject(HttpClient) private readonly httpClient: HttpClient) {}

  public async execute(asset: Asset) {
    if (asset.url) {
      if (asset.type === AssetType.video) {
        const videoDownloadUrl = this.getVideoDownloadUrl(asset)
        saveAs(videoDownloadUrl, asset.name)
        return
      }

      await this.downloadAssetFromStorage(asset)
    }
  }

  private getVideoDownloadUrl(asset: Asset): string {
    const removeFileExtension = (fileName = '') => fileName.replace(/\.[^/.]+$/, '')
    const muxStreamUrl = Envs.MUX_STREAM_URL

    const assetId = removeFileExtension(asset.url).replace(`${muxStreamUrl}/`, '')
    const assetDownloadName = removeFileExtension(asset.name)
    const videoUrlDownload = `${muxStreamUrl}/${assetId}/low.mp4?download=${assetDownloadName}`

    return videoUrlDownload
  }

  public async downloadAssetFromStorage(asset: Asset) {
    if (asset.url) {
      const response = await this.httpClient.request({
        url: asset.url,
        method: HttpMethod.GET,
        responseType: 'arraybuffer',
      })

      if (response.statusCode === HttpStatusCode.OK) {
        this.makeOutput(asset, response.data)
        return
      }

      throw new Error()
    }
  }

  private makeOutput(asset: Asset, value: string) {
    const fileType = `${asset.type}/${asset.subType}`
    const file = new Blob([value], { type: fileType })
    saveAs(file, asset.name)
  }
}
