import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import moment from 'moment'
import { DownloadCsvInput } from '../domain/dtos/download-csv-input'
import { GeneralError } from '../domain/errors/general'
import { Envs } from '../envs'
import { GetOfferIds } from './get-offer-ids'

export class DownloadCsv {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(input: DownloadCsvInput): Promise<void> {
    const response = await this.httpClient.request({
      method: HttpMethod.POST,
      url: `${Envs.BFF_WEB_URL}/leads/dashboard/export-csv`,
      body: this.makeBody(input),
    })

    if (response.statusCode === HttpStatusCode.CREATED) {
      return this.makeOutput(response.data)
    }

    throw new GeneralError()
  }

  private makeOutput(value: string) {
    const link = document.createElement('a')
    const blob = new Blob([value], { type: 'text/csv;charset=utf-8;' })
    const url = URL.createObjectURL(blob)

    link.setAttribute('href', url)
    link.setAttribute('download', `export-leads-${moment().format('YYYY-MM-DDTHH:mm:ss')}.csv`)
    document.body.appendChild(link)

    link.click()
  }

  private makeBody(input: DownloadCsvInput) {
    const offerIds = new GetOfferIds().execute({ offerIds: input.offerIds, offers: input.offers })

    return {
      offerIds,
      startDate: input.startDate,
      endDate: input.endDate,
    }
  }
}
