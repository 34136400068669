import { Label, RadioGroupItem, Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@hub-la/shadcn'
import { Info } from 'lucide-react'
import React from 'react'
import { FieldPath, useFormContext } from 'react-hook-form'
import { OfferSchemaValidationType } from '../validations/offer-validation'

type RadioToggleChildrenProps = {
  name: FieldPath<OfferSchemaValidationType>
  label: string
  value: string
  children?: React.ReactNode
  tooltip?: string
  disabled?: boolean
}

export const RadioToggleChildren: React.FC<RadioToggleChildrenProps> = ({
  name,
  label,
  children,
  tooltip,
  value,
  disabled,
}) => {
  const { watch } = useFormContext<OfferSchemaValidationType>()
  const radioGroupValue = watch(name)
  const isToggled = radioGroupValue === value

  return (
    <div className="flex flex-col">
      <div className="flex items-center gap-2">
        <div className="flex items-center space-x-2">
          <RadioGroupItem value={value} id={`${name}-${value}`} disabled={disabled} checked={isToggled} />
          <Label htmlFor={`${name}-${value}`} className={`${disabled ? 'text-muted-foreground' : ''}`}>
            {label}
          </Label>
        </div>
        {tooltip && (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Info className="h-4 w-4 text-muted-foreground cursor-help" />
              </TooltipTrigger>
              <TooltipContent>
                <p>{tooltip}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )}
      </div>
      {Boolean(isToggled && children) && <div className="ml-0 md:ml-6">{children}</div>}
    </div>
  )
}
