import { Check, CircleOff, X } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { SmartInstallmentStatus } from '../../domain/enums/smart-installment-status'

type SmartInstallmentStatusStatsProps = {
  status: SmartInstallmentStatus
  size?: any
}

const getIcon = (status: SmartInstallmentStatus) => {
  const iconSize = 20 // Super small size for icons

  switch (status) {
    case SmartInstallmentStatus.COMPLETED:
      return (
        <div className="bg-purple-400/80  rounded-full w-4 h-4 flex justify-center items-center">
          <Check className="text-primary-foreground w-3 h-3" strokeWidth={3} />
        </div>
      )
    case SmartInstallmentStatus.ON_SCHEDULE:
      return (
        <div className="bg-green-600  rounded-full w-4 h-4 flex justify-center items-center">
          <Check className="text-primary-foreground w-3 h-3" strokeWidth={3} />
        </div>
      )
    case SmartInstallmentStatus.OFF_SCHEDULE:
      return (
        <div className="bg-red-600  rounded-full w-4 h-4 flex justify-center items-center">
          <X className="text-primary-foreground w-3 h-3" strokeWidth={3} />
        </div>
      )
    case SmartInstallmentStatus.CANCELED:
      return (
        <div className="bg-muted-foreground  rounded-full w-4 h-4 flex justify-center items-center">
          <X className="text-primary-foreground w-3 h-3" strokeWidth={3} />
        </div>
      )
    default:
      return (
        <CircleOff
          size={iconSize}
          className="mr-1 text-secondary-foreground"
          fill-current
          style={{ fillOpacity: 0.1 }}
        />
      )
  }
}

export const SmartInstallmentStatusStats = ({ status }: SmartInstallmentStatusStatsProps) => {
  const { t } = useTranslation()

  return (
    <div className="flex items-center gap-2">
      {getIcon(status)}
      {t(`smartInstallmentStatus.${status}`)}
    </div>
  )
}
