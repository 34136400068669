import React from 'react'
import { useFormContext } from 'react-hook-form'
import CheckoutMockup from '../../assets/checkout-mockup.svg'
import PhoneBottom from '../../assets/phone-bottom.svg'
import PhoneTop from '../../assets/phone-top.svg'
import { OfferSchemaValidationType } from '../../validations/offer-validation'
import { MobileBanner } from './mobile-banner'
import { ProductHeader } from './product-header'
import { Timer } from './timer'

interface MobilePreviewProps {
  group: {
    picture?: string | null
    name: string
    owner: string
  }
}

export const MobilePreview: React.FC<MobilePreviewProps> = ({ group }) => {
  const { watch } = useFormContext<OfferSchemaValidationType>()
  const { isTimer, timerBgColor, bannerMobileUrl, creatorLogoUrl } = watch('checkout')

  return (
    <div className="bg-secondary max-w-[275px] rounded-lg overflow-hidden max-h-[600px] relative shadow-2xl">
      <img className="w-full h-auto" src={PhoneTop} alt="Phone top mockup" />

      {isTimer && timerBgColor && <Timer />}

      {bannerMobileUrl && <MobileBanner />}

      {creatorLogoUrl && !bannerMobileUrl && (
        <img className="max-h-[60px] max-w-[120px] object-cover mt-6 ml-6" src={creatorLogoUrl} alt="Creator logo" />
      )}

      <ProductHeader group={group} />

      <img className="w-full h-auto mb-[179px]" src={CheckoutMockup} alt="Checkout mockup" />

      <img className="absolute bottom-0 left-0 w-full h-auto" src={PhoneBottom} alt="Phone bottom mockup" />
    </div>
  )
}
