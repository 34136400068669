import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { useToast } from '@hub-la/shadcn'
import { QueryKey } from '../../domain/enums/query-key.enum'
import { GetListGroups } from '../../usecases/get-list-groups'

export const useGetListGroups = (offerId?: string, hasProductAssociation = true) => {
  const container = useContainer()
  const { toast } = useToast()
  const { t } = useTranslation()

  return useQuery(
    [QueryKey.getListGroups, offerId, hasProductAssociation],
    () => {
      return new GetListGroups(container.get(HttpClient)).execute(offerId, hasProductAssociation)
    },
    {
      enabled: !!offerId,
      retry: 3,
      retryOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: 60000,
      onError: (error: Error) => {
        toast({
          title: 'Error',
          description: t(error.message),
          variant: 'destructive',
        })
      },
    },
  )
}
