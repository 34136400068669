import { IntegrationEvent, IntegrationEventOption } from '../../domain/dtos/integration-event'
import { IntegrationProvider } from '../../domain/dtos/integration-provider'

export const useGetIntegrationEvents = (provider: IntegrationProvider): IntegrationEventOption[] => {
  const events = [
    // Lead
    { code: IntegrationEvent.LEAD_CREATED, name: 'rules.events.leadCreated', group: 'Lead' },
    { code: IntegrationEvent.LEAD_ABANDONED_CHECKOUT, name: 'rules.events.leadAbandonedCheckout', group: 'Lead' },
    { code: IntegrationEvent.LEAD_CONVERTED, name: 'rules.events.leadConverted', group: 'Lead' },

    // Member
    { code: IntegrationEvent.CUSTOMER_MEMBER_ADDED, name: 'rules.events.customerMemberAdded', group: 'Membro' },
    { code: IntegrationEvent.CUSTOMER_MEMBER_REMOVED, name: 'rules.events.customerMemberRemoved', group: 'Membro' },

    // Subscription
    { code: IntegrationEvent.SUBSCRIPTION_CREATED, name: 'rules.events.subscriptionCreated', group: 'Assinatura' },
    { code: IntegrationEvent.SUBSCRIPTION_ACTIVATED, name: 'rules.events.subscriptionActivated', group: 'Assinatura' },
    {
      code: IntegrationEvent.SUBSCRIPTION_DEACTIVATED,
      name: 'rules.events.subscriptionDeactivated',
      group: 'Assinatura',
    },
    { code: IntegrationEvent.SUBSCRIPTION_EXPIRING, name: 'rules.events.subscriptionExpiring', group: 'Assinatura' },
    {
      code: IntegrationEvent.SUBSCRIPTION_RENEW_DISABLED,
      name: 'rules.events.subscriptionRenewDisabled',
      group: 'Assinatura',
    },
    {
      code: IntegrationEvent.SUBSCRIPTION_RENEW_ENABLED,
      name: 'rules.events.subscriptionRenewEnabled',
      group: 'Assinatura',
    },
    { code: IntegrationEvent.INVOICE_CREATED, name: 'rules.events.invoiceCreated', group: 'Fatura' },
    { code: IntegrationEvent.INVOICE_STATUS_UPDATED, name: 'rules.events.invoiceStatusUpdated', group: 'Fatura' },
    {
      code: IntegrationEvent.INVOICE_PAYMENT_SUCCEEDED,
      name: 'rules.events.invoicePaymentSucceeded',
      group: 'Fatura',
    },
    {
      code: IntegrationEvent.INVOICE_PAYMENT_FAILED,
      name: 'rules.events.invoicePaymentFailed',
      group: 'Fatura',
    },
    {
      code: IntegrationEvent.INVOICE_EXPIRED,
      name: 'rules.events.invoiceExpired',
      group: 'Fatura',
    },
    {
      code: IntegrationEvent.INVOICE_REFUNDED,
      name: 'rules.events.invoiceRefunded',
      group: 'Fatura',
    },

    {
      code: IntegrationEvent.REFUND_REQUEST_CREATED,
      name: 'rules.events.refundRequestCreated',
      group: 'Solicitação de Reembolso',
    },
    {
      code: IntegrationEvent.REFUND_REQUEST_ACCEPTED,
      name: 'rules.events.refundRequestAccepted',
      group: 'Solicitação de Reembolso',
    },
    {
      code: IntegrationEvent.REFUND_REQUEST_CANCELED,
      name: 'rules.events.refundRequestCanceled',
      group: 'Solicitação de Reembolso',
    },
    {
      code: IntegrationEvent.REFUND_REQUEST_REJECTED,
      name: 'rules.events.refundRequestRejected',
      group: 'Solicitação de Reembolso',
    },

    {
      code: IntegrationEvent.ABANDONED_CHECKOUT,
      name: 'rules.events.abandonedCheckout',
      group: 'Eventos (v1)',
    },
    {
      code: IntegrationEvent.PENDING_SALE,
      name: 'rules.events.pendingSale',
      group: 'Eventos (v1)',
    },
    {
      code: IntegrationEvent.NEW_SALE,
      name: 'rules.events.newSale',
      group: 'Eventos (v1)',
    },
    {
      code: IntegrationEvent.NEW_USER,
      name: 'rules.events.newUser',
      group: 'Eventos (v1)',
    },
    {
      code: IntegrationEvent.CANCELED_SUBSCRIPTION,
      name: 'rules.events.canceledSubscription',
      group: 'Eventos (v1)',
    },
    {
      code: IntegrationEvent.IN_PROTEST_SALE,
      name: 'rules.events.inProtestSale',
      group: 'Eventos (v1)',
    },
    {
      code: IntegrationEvent.REFUND_REQUESTED,
      name: 'rules.events.refundRequested',
      group: 'Eventos (v1)',
    },
    {
      code: IntegrationEvent.CANCELED_SALE,
      name: 'rules.events.canceledSale',
      group: 'Eventos (v1)',
    },
    {
      code: IntegrationEvent.EXPIRED_SALE,
      name: 'rules.events.expiredSale',
      group: 'Eventos (v1)',
    },
    {
      code: IntegrationEvent.REFUNDED_SALE,
      name: 'rules.events.refundedSale',
      group: 'Eventos (v1)',
    },
    {
      code: IntegrationEvent.REFUSED_SALE,
      name: 'rules.events.refusedSale',
      group: 'Eventos (v1)',
    },
    {
      code: IntegrationEvent.CHARGEBACK_SALE,
      name: 'rules.events.chargebackSale',
      group: 'Eventos (v1)',
    },
  ]

  switch (provider) {
    case IntegrationProvider.WEBHOOK: {
      const elegibles = [
        IntegrationEvent.LEAD_ABANDONED_CHECKOUT,
        IntegrationEvent.CUSTOMER_MEMBER_ADDED,
        IntegrationEvent.CUSTOMER_MEMBER_REMOVED,
        IntegrationEvent.ABANDONED_CHECKOUT,
        IntegrationEvent.PENDING_SALE,
        IntegrationEvent.NEW_SALE,
        IntegrationEvent.NEW_USER,
        IntegrationEvent.CANCELED_SUBSCRIPTION,
        IntegrationEvent.IN_PROTEST_SALE,
        IntegrationEvent.REFUND_REQUESTED,
        IntegrationEvent.CANCELED_SALE,
        IntegrationEvent.SUBSCRIPTION_CREATED,
        IntegrationEvent.SUBSCRIPTION_ACTIVATED,
        IntegrationEvent.SUBSCRIPTION_DEACTIVATED,
        IntegrationEvent.SUBSCRIPTION_EXPIRING,
        IntegrationEvent.SUBSCRIPTION_RENEW_ENABLED,
        IntegrationEvent.SUBSCRIPTION_RENEW_DISABLED,
        IntegrationEvent.INVOICE_CREATED,
        IntegrationEvent.INVOICE_STATUS_UPDATED,
        IntegrationEvent.INVOICE_PAYMENT_SUCCEEDED,
        IntegrationEvent.INVOICE_PAYMENT_FAILED,
        IntegrationEvent.INVOICE_EXPIRED,
        IntegrationEvent.INVOICE_REFUNDED,
        IntegrationEvent.REFUND_REQUEST_CREATED,
        IntegrationEvent.REFUND_REQUEST_ACCEPTED,
        IntegrationEvent.REFUND_REQUEST_REJECTED,
        IntegrationEvent.REFUND_REQUEST_CANCELED,
      ]

      return events.filter((event) => elegibles.includes(event.code))
    }

    case IntegrationProvider.SELL_FLUX: {
      const elegibles = [
        IntegrationEvent.ABANDONED_CHECKOUT,
        IntegrationEvent.PENDING_SALE,
        IntegrationEvent.NEW_SALE,
        IntegrationEvent.IN_PROTEST_SALE,
        IntegrationEvent.CANCELED_SALE,
      ]

      return events.filter((event) => elegibles.includes(event.code))
    }

    case IntegrationProvider.THE_MEMBERS:
    case IntegrationProvider.LEAD_LOVERS: {
      const elegibles = [IntegrationEvent.CUSTOMER_MEMBER_ADDED, IntegrationEvent.CUSTOMER_MEMBER_REMOVED]

      return events.filter((event) => elegibles.includes(event.code))
    }

    case IntegrationProvider.ACTIVE_CAMPAIGN:
    default: {
      const elegibles = [
        IntegrationEvent.ABANDONED_CHECKOUT,
        IntegrationEvent.PENDING_SALE,
        IntegrationEvent.NEW_SALE,
        IntegrationEvent.NEW_USER,
        IntegrationEvent.CANCELED_SUBSCRIPTION,
        IntegrationEvent.EXPIRED_SALE,
        IntegrationEvent.REFUNDED_SALE,
        IntegrationEvent.REFUSED_SALE,
        IntegrationEvent.CHARGEBACK_SALE,
        IntegrationEvent.IN_PROTEST_SALE,
        IntegrationEvent.REFUND_REQUESTED,
        IntegrationEvent.CANCELED_SALE,
      ]

      return events.filter((event) => elegibles.includes(event.code))
    }

    case IntegrationProvider.HUBSPOT: {
      const elegibles = [
        IntegrationEvent.LEAD_ABANDONED_CHECKOUT,
        IntegrationEvent.INVOICE_CREATED,
        IntegrationEvent.INVOICE_STATUS_UPDATED,
        IntegrationEvent.INVOICE_PAYMENT_SUCCEEDED,
        IntegrationEvent.INVOICE_PAYMENT_FAILED,
        IntegrationEvent.INVOICE_EXPIRED,
        IntegrationEvent.INVOICE_REFUNDED,
      ]

      return events.filter((event) => elegibles.includes(event.code))
    }

    case IntegrationProvider.RDSTATION: {
      const elegibles = [
        IntegrationEvent.CUSTOMER_MEMBER_ADDED,
        IntegrationEvent.CUSTOMER_MEMBER_REMOVED,
        IntegrationEvent.ABANDONED_CHECKOUT,
        IntegrationEvent.SUBSCRIPTION_CREATED,
        IntegrationEvent.SUBSCRIPTION_ACTIVATED,
        IntegrationEvent.SUBSCRIPTION_DEACTIVATED,
        IntegrationEvent.SUBSCRIPTION_EXPIRING,
        IntegrationEvent.SUBSCRIPTION_RENEW_ENABLED,
        IntegrationEvent.SUBSCRIPTION_RENEW_DISABLED,
        IntegrationEvent.INVOICE_CREATED,
        IntegrationEvent.INVOICE_PAYMENT_SUCCEEDED,
        IntegrationEvent.INVOICE_PAYMENT_FAILED,
        IntegrationEvent.INVOICE_EXPIRED,
        IntegrationEvent.INVOICE_REFUNDED,
        IntegrationEvent.REFUND_REQUEST_CREATED,
        IntegrationEvent.REFUND_REQUEST_ACCEPTED,
        IntegrationEvent.REFUND_REQUEST_REJECTED,
        IntegrationEvent.REFUND_REQUEST_CANCELED,
      ]

      return events.filter((event) => elegibles.includes(event.code))
    }

    case IntegrationProvider.CONTA_AZUL: {
      const elegibles = [
        IntegrationEvent.INVOICE_CREATED,
        IntegrationEvent.INVOICE_PAYMENT_SUCCEEDED,
        IntegrationEvent.INVOICE_PAYMENT_FAILED,
        IntegrationEvent.INVOICE_EXPIRED,
        IntegrationEvent.INVOICE_REFUNDED,
      ]

      return events.filter((event) => elegibles.includes(event.code))
    }
  }
}
