import { container } from '@hub-la/fe-container'
import { CouponDetail, DashboardCouponsProvider } from '@hub-la/fe-dashboard-coupons'

import { useHistory, useParams } from 'react-router-dom'

export const DashboardCouponDetailInit = () => {
  const { couponId } = useParams<{ couponId: string }>()
  const history = useHistory()
  return (
    <DashboardCouponsProvider container={container}>
      <CouponDetail id={couponId} onClose={() => history.goBack()} />
    </DashboardCouponsProvider>
  )
}
