import { Card, CardContent, CardHeader, CardTitle } from '@hub-la/shadcn'
import { useTranslation } from 'react-i18next'
import { Phone } from '../../../../domain/vos/phone'

type ChargeDetailProps = {
  payer: {
    fullName: string
    email: string
    phone: string
  }
  seller: {
    fullName: string
  }
}

export const ChargeDetail = ({ payer, seller }: ChargeDetailProps) => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col md:flex-row gap-4">
      <Card className="flex-1">
        <CardHeader>
          <CardTitle>{t('invoiceDownload.chargeDetails.charge')}</CardTitle>
        </CardHeader>

        <CardContent className="flex flex-col  gap-1 text-muted-foreground">
          <p>{payer?.fullName}</p>

          <p>{payer?.email}</p>

          {payer?.phone && <p>{Phone.build(payer?.phone).getFormattedNumber()}</p>}
        </CardContent>
      </Card>

      <Card className="flex-1">
        <CardHeader>
          <CardTitle>{t('invoiceDownload.chargeDetails.billing')}</CardTitle>
        </CardHeader>

        <CardContent className="flex flex-col gap-1 text-muted-foreground">
          <p>{seller?.fullName}</p>
        </CardContent>
      </Card>
    </div>
  )
}
