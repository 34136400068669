import { CouponStatus } from '../enums/coupon-status'

export const couponStatus = [
  {
    id: CouponStatus.ACTIVE,
    label: 'Ativo',
  },
  {
    id: CouponStatus.INACTIVE,
    label: 'Inativo',
  },
  {
    id: CouponStatus.EXPIRED,
    label: 'Expirado',
  },
]
