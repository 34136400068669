import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@hub-la/shadcn'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDeleteResource } from '../../../../../hooks/member-area/use-delete-resource'

interface RemoveGroupCardInterface {
  open: boolean
  onClose: () => void
  resourceId?: string
}

export const RemoveGroupCard: React.FC<RemoveGroupCardInterface> = ({ open, onClose, resourceId }) => {
  const { t } = useTranslation()
  const { mutateAsync: deleteResource, isLoading: isDeleteResourceLoading } = useDeleteResource()

  const handleOnSubmit = () => {
    resourceId &&
      deleteResource(resourceId)
        .then(() => onClose)
        .catch()
  }
  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-lg">
        <DialogHeader className="flex flex-col gap-2">
          <DialogTitle className="flex justify-between items-center">
            {t('member-area.groups.deleteGroupModal.title')}
          </DialogTitle>

          <DialogDescription>{t('member-area.groups.deleteGroupModal.subTitle')}</DialogDescription>
        </DialogHeader>

        <DialogFooter className="sm:justify-end">
          <div className="w-full sm:w-auto flex flex-col-reverse sm:flex-row gap-2">
            <Button variant="outline" onClick={onClose}>
              {t('member-area.groups.deleteGroupModal.actionButtons.cancel')}
            </Button>

            <Button variant="destructive" onClick={handleOnSubmit} disabled={isDeleteResourceLoading}>
              {t('member-area.groups.deleteGroupModal.actionButtons.delete')}
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
