import { useEffect } from 'react'
import { useCheckHaveChanged } from './use-check-have-changed'

export const useRefetch = (params: Record<string, unknown>, refetch: () => void) => {
  const canRefetch = useCheckHaveChanged(params)

  useEffect(() => {
    canRefetch && refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canRefetch])
}
