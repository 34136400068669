import { Skeleton, TableCell, TableRow } from '@hub-la/shadcn'

export const TableLoading = () => {
  return (
    <TableRow>
      <TableCell className="py-2">
        <Skeleton className="h-4 w-16" />
      </TableCell>

      <TableCell className="py-2">
        <Skeleton className="h-4 w-32" />
      </TableCell>

      <TableCell className="py-2">
        <Skeleton className="h-4 w-32" />
      </TableCell>

      <TableCell className="py-2">
        <Skeleton className="h-4 w-24" />
      </TableCell>

      <TableCell className="py-2">
        <Skeleton className="h-4 w-24" />
      </TableCell>

      <TableCell className="py-2">
        <Skeleton className="h-4 w-28" />
      </TableCell>
    </TableRow>
  )
}
