import { Alert, AlertDescription, AlertTitle, Button, Card, CardContent, Label } from '@hub-la/shadcn'
import { useFormikContext } from 'formik'
import { CheckCircle } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { HasPendency } from '../../usecases/has-pendency'
import { IsStepValid } from '../../usecases/is-step-valid'
import { useField } from '../hooks/use-field'
import { FormSteps, Values } from '../pages/account-verification-modal'
import { FormHeader } from './form-header'

type Props = {
  onComplete: () => void
  loading?: boolean
}

export const IdWallForm: React.FC<Props> = ({ onComplete, loading }) => {
  const [done, setDone] = useState<boolean>(false)

  const { t } = useTranslation()
  const { isValid } = useField()
  const { values, setFieldValue } = useFormikContext<Values>()

  const isStepValid = new IsStepValid(isValid).execute(FormSteps.VERIFY_IDENTITY)
  const hasPicturePendency = new HasPendency(values.pendencies).hasAnyOfPendencies([
    'liveness',
    'forense_id',
    'datamatch',
    'ocr',
    'data_bureaus',
    'facematch',
    'facelink',
  ])
  const hasDocumentPendency = new HasPendency(values.pendencies).hasAnyOfPendencies([
    'hubla_vencimento_cnh',
    'SERPRO',
    'documentoscopia',
  ])

  useEffect(() => {
    if (!window?.['idwSDKWeb']) {
      const script = document.createElement('script')
      script.src = 'https://sdkweb-lib.idwall.co/index.js'
      script.async = true
      document.body.appendChild(script)
    }

    window?.['idwSDKWeb']?.({
      /** @todo Store this token on Vault */
      token: 'U2FsdGVkX1/4GpAqHzvEndI/jHUxK4y/wOEvZ4GPG6ac58L23A==',
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onRender: () => {},
      onComplete: ({ token }) => {
        setFieldValue('sdkToken', token)
        setDone(true)
      },
      onError: (error) => {
        alert(error)
      },
    })
  }, [window?.['idwSDKWeb']])

  return (
    <div className="flex flex-col space-y-6">
      <FormHeader
        title={t('accountVerification.form.idwall.title')}
        subtitle={t('accountVerification.form.idwall.subtitle')}
      />
      {hasPicturePendency && (
        <Alert variant="destructive">
          <AlertTitle>{t('pendencies.idwall.picture.title')}</AlertTitle>
          <AlertDescription>{t('pendencies.idwall.picture.subtitle')}</AlertDescription>
        </Alert>
      )}
      {hasDocumentPendency && (
        <Alert variant="destructive">
          <AlertTitle>{t('pendencies.idwall.document.title')}</AlertTitle>
          <AlertDescription>{t('pendencies.idwall.document.subtitle')}</AlertDescription>
        </Alert>
      )}
      <div className="flex flex-col space-y-2">
        <Label>
          <Trans
            i18nKey="accountVerification.form.idwall.label"
            components={[<strong />]}
            values={{ name: values.identity.legalRepresentative?.fullName || values.identity.fullName }}
          />
        </Label>
        <Card>
          <CardContent>
            {done ? (
              <div className="flex flex-col items-center justify-center py-15 px-8 h-full">
                <CheckCircle className="text-green-500 h-10 w-10" />
                <h4 className="mt-6 text-center text-xl font-semibold">Documento enviado!</h4>
              </div>
            ) : (
              <div data-idw-sdk-web className="mx-auto p-0" />
            )}
          </CardContent>
        </Card>
      </div>
      <div className="self-end">
        <Button
          variant="default"
          disabled={!isStepValid}
          onClick={onComplete}
          className={loading ? 'opacity-50 cursor-not-allowed' : ''}
        >
          {loading ? 'Carregando...' : t('accountVerification.form.continue')}
        </Button>
      </div>
    </div>
  )
}
