export const YOUTUBE_REGEX = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/

type Quality = 'low' | 'medium' | 'high' | 'max'

export class Youtube {
  public parseId(url: string) {
    const match = url.match(YOUTUBE_REGEX)
    return match && match[7].length === 11 ? match[7] : false
  }

  public getThumbnail(url: string, quality: Quality = 'medium') {
    const videoId = this.parseId(url)
    const res = {
      low: 'sddefault',
      medium: 'mqdefault',
      high: 'hqdefault',
      max: 'maxresdefault',
    }
    return `https://img.youtube.com/vi/${videoId}/${res[quality]}.jpg`
  }

  public getEmbed(url: string) {
    const videoId = this.parseId(url)
    return `https://www.youtube.com/embed/${videoId}`
  }
}
