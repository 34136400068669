import { isSecurityCodeValid } from 'creditcard.js'

export class CardVerificationValue {
  public constructor(private readonly value: string, private readonly number: string) {}

  public static build(value: string, number: string): CardVerificationValue {
    return new CardVerificationValue(value, number)
  }

  public isValid(): boolean {
    return isSecurityCodeValid(this.number, this.value)
  }

  public getValue() {
    return this.value
  }
}
