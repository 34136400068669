import { container } from '@hub-la/fe-container'
import { DashboardCoupons, DashboardCouponsProvider } from '@hub-la/fe-dashboard-coupons'

export const DashboardCouponsInit = () => {
  return (
    <DashboardCouponsProvider container={container}>
      <DashboardCoupons />
    </DashboardCouponsProvider>
  )
}
