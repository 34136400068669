import { CreditCardType } from '@hub-la/fe-tokenizer'
import { useFormikContext } from 'formik'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import NumberFormat from 'react-number-format'
import { Values } from '.'
import { Installment } from '../../../../../domain/dtos/installment'
import { useGetCreditCardToken } from '../../../../hooks/use-get-credit-card-token'
import { useIsCreditCardFieldsValid } from '../../../../hooks/use-is-credit-card-valid'
import amex from '../../../../assets/amex.png'
import diners from '../../../../assets/diners.png'
import elo from '../../../../assets/elo.png'
import hipercard from '../../../../assets/hipercard.png'
import mastercard from '../../../../assets/mastercard.png'
import visa from '../../../../assets/visa.png'
import { useField } from '../../../../hooks/use-field'
import { CPF } from './cpf'
import { cn, Input } from '@hub-la/shadcn'

const Type = ({ isActive, src, alt }) => (
  <img className={cn('w-[30px]', isActive ? 'opacity-100' : 'opacity-20')} src={src} alt={alt} />
)

const CardInput = React.forwardRef((props: any, ref) => (
  <Input
    {...props}
    ref={ref}
    className={cn('rounded-t-md rounded-b-none border-b-0', 'focus:border-b focus:rounded-b-md', props.className)}
  />
))

const ValidInput = React.forwardRef((props: any, ref) => (
  <Input
    {...props}
    ref={ref}
    className={cn(
      'rounded-bl-md rounded-tr-none rounded-br-none border-r-0',
      'focus:border-r focus:rounded-r-md',
      props.className,
    )}
  />
))

const CvvInput = React.forwardRef((props: any, ref) => (
  <Input {...props} ref={ref} className={cn('rounded-br-md rounded-tl-none rounded-bl-none', props.className)} />
))

type Props = {
  offerId: string
  installments: Installment[]
}

export const FillCreditCard: React.FC<Props> = (props) => {
  const { offerId } = props
  const { values, handleBlur, handleChange, setValues } = useFormikContext<Values>()
  const { hasError, getErrorText } = useField()
  const { mutate: getToken } = useGetCreditCardToken(offerId)

  const { t } = useTranslation()

  const isCreditCardValid = useIsCreditCardFieldsValid()

  const creditCards = {
    amex,
    diners,
    elo,
    hipercard,
    mastercard,
    visa,
  }

  useEffect(() => {
    if (!isCreditCardValid) {
      return
    }

    getToken()
  }, [isCreditCardValid])

  return (
    <div className="flex flex-col space-y-4">
      <div className="relative">
        <NumberFormat
          format="#### #### #### ####"
          placeholder={t('changePaymentMethodModal.creditCard.number')}
          data-testid={'credit-card-number'}
          onBlur={handleBlur}
          id="creditCard.number"
          type="tel"
          value={values.creditCard.number}
          customInput={CardInput}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const value: string = event.target.value

            setValues({
              ...values,
              creditCard: {
                ...values.creditCard,
                number: value,
                type: CreditCardType.build(value).getValue(),
              },
            })
          }}
          className={cn(hasError('creditCard.number') || hasError('creditCard.token') ? 'border-red-500' : '')}
        />
        <div className="flex absolute top-[15px] right-[5px]">
          {CreditCardType.types.map(({ id, name }) => (
            <Type key={id} isActive={id === values.creditCard.type} src={creditCards[id]} alt={name} />
          ))}
        </div>
      </div>

      <div className="grid grid-cols-2 gap-0">
        <NumberFormat
          id="creditCard.expiration"
          onBlur={handleBlur}
          format="##/##"
          mask={['M', 'M', 'Y', 'Y']}
          placeholder="MM/AA"
          onChange={handleChange}
          type="tel"
          value={values.creditCard.expiration}
          customInput={ValidInput}
          className={cn(hasError('creditCard.expiration') ? 'border-red-500' : '')}
        />

        <CvvInput
          type="tel"
          placeholder="CVV"
          id="creditCard.cvv"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.creditCard.cvv}
          className={cn(hasError('creditCard.cvv') ? 'border-red-500' : '')}
        />
      </div>
      <p className="text-red-500 text-xs" id="txt-card-error-j1Qd">
        {t(
          getErrorText('creditCard.number') ||
            getErrorText('creditCard.token') ||
            getErrorText('creditCard.expiration') ||
            getErrorText('creditCard.cvv'),
        )}
      </p>

      <Input
        placeholder={t('changePaymentMethodModal.creditCard.holder')}
        id="creditCard.holder"
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.creditCard.holder}
        className={cn(hasError('creditCard.holder') ? 'border-red-500' : '')}
      />

      <CPF />
    </div>
  )
}
