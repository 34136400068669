import { Button, TablePagination } from '@hub-la/shadcn'
import merge from 'lodash/merge'
import { PlusCircle } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IntegrationProvider } from '../../domain/dtos/integration-provider'
import { Rule } from '../../domain/dtos/rule'
import { useDeleteRule } from '../hooks/use-delete-rule'
import { useEditRule } from '../hooks/use-edit-rule'
import { useGetIntegrationRules } from '../hooks/use-get-integration-rules'
import { useSendRuleTest } from '../hooks/use-send-rule-test'
import { ConfirmDeleteRuleModal } from './confirm-delete-rule-modal'
import { ConfirmSendRuleTestModal } from './confirm-send-rule-test-modal'
import { CreateEditRuleModal, CreateEditRuleType } from './create-edit-rule-modal'
import { RuleDetailDrawer } from './rule-detail-drawer'
import { TableRule } from './table-rule/table-rule'

const rule: Rule = {
  id: '',
  integrationId: '',
  products: [],
  offers: [],
  name: '',
  url: '',
  events: [],
  lists: [],
  tags: [],
  enabled: true,
  createdAt: new Date().toISOString(),
}

type IntegrationSettingsTabProps = {
  integrationId: string
  provider: IntegrationProvider
  ruleSchema: string[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ruleValidationSchema: any
}

export const IntegrationSettingsTab = (props: IntegrationSettingsTabProps) => {
  const { integrationId, provider, ruleSchema, ruleValidationSchema } = props
  const { t } = useTranslation()
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(25)
  const [createEditRule, setCreateEditRule] = useState<{
    isOpen: boolean
    rule: Rule | null
    type: CreateEditRuleType
  }>({
    isOpen: false,
    type: CreateEditRuleType.CREATE,
    rule: null,
  })
  const [openedRule, setOpenedRule] = useState<{ isOpen: boolean; rule: Rule }>({
    isOpen: false,
    rule,
  })
  const [confirmDeleteRule, setConfirmDeleteRule] = useState<{ isOpen: boolean; rule: Rule }>({
    isOpen: false,
    rule,
  })
  const [confirmSendRuleTest, setConfirmSendRuleTest] = useState<{ isOpen: boolean; rule: Rule }>({
    isOpen: false,
    rule,
  })
  const {
    data: rules = { items: [], total: 0, page, pageSize, lastPage: 1 },
    isFetching,
    isLoading,
    refetch,
  } = useGetIntegrationRules({ integrationId, page, pageSize })
  const { mutateAsync: editRule } = useEditRule()
  const { mutateAsync: deleteRule, isLoading: isDeleting } = useDeleteRule()
  const { mutateAsync: sendRuleTest, isLoading: isSendRuleTest } = useSendRuleTest()

  const handleOnChangeRuleStatus = (rule: Rule) => (checked: boolean) => {
    editRule({
      integrationId,
      provider,
      rule: merge<Rule, Partial<Rule>>(rule, { enabled: checked }) as Rule,
    })
  }

  const handleOnDeleteRule = (rule: Rule) => async () => {
    await deleteRule({
      integrationId,
      ruleId: rule.id,
    })

    setConfirmDeleteRule({
      isOpen: false,
      rule,
    })

    setOpenedRule({
      isOpen: false,
      rule,
    })

    refetch()
  }

  const handleOnSendRuleTest = (rule: Rule) => async () => {
    await sendRuleTest({
      integrationId,
      ruleId: rule.id,
    })

    setConfirmSendRuleTest({
      isOpen: false,
      rule,
    })
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-baseline justify-between">
        <p className="text-sm">{rules.total} regra(s)</p>

        <Button
          variant="outline"
          onClick={() =>
            setCreateEditRule({
              isOpen: true,
              type: CreateEditRuleType.CREATE,
              rule: null,
            })
          }
        >
          <PlusCircle className="mr-2 h-4 w-4" />
          {t('buttons.addRule')}
        </Button>
      </div>

      <div className="flex flex-col gap-4">
        <TableRule
          provider={provider}
          isFetching={(isFetching || isLoading) && rules.items.length === 0}
          items={rules.items}
          schema={ruleSchema}
          onChangeRuleStatus={handleOnChangeRuleStatus}
          onRuleDetail={setOpenedRule}
          onRuleEdit={setCreateEditRule}
          onRuleDelete={setConfirmDeleteRule}
          onRuleSendTest={setConfirmSendRuleTest}
        />

        <TablePagination
          page={page}
          setPage={(page) => {
            setPage(page)
            refetch()
          }}
          lastPage={rules.lastPage}
          pageSize={pageSize}
          setPageSize={(pageSize) => {
            setPageSize(pageSize)
            refetch()
          }}
        />
      </div>

      <CreateEditRuleModal
        integrationId={integrationId}
        provider={provider}
        rule={createEditRule.rule}
        type={createEditRule.type}
        isOpen={createEditRule.isOpen}
        schema={ruleSchema}
        validation={ruleValidationSchema}
        onClose={() =>
          setCreateEditRule({
            isOpen: false,
            type: CreateEditRuleType.CREATE,
            rule: null,
          })
        }
        refetch={refetch}
      />

      <ConfirmDeleteRuleModal
        isOpen={confirmDeleteRule.isOpen}
        isDeleting={isDeleting}
        onClose={() => {
          setConfirmDeleteRule({
            isOpen: false,
            rule,
          })
        }}
        onConfirm={handleOnDeleteRule(confirmDeleteRule.rule)}
      />

      {provider === IntegrationProvider.WEBHOOK && (
        <ConfirmSendRuleTestModal
          isOpen={confirmSendRuleTest.isOpen}
          rule={confirmSendRuleTest.rule}
          isLoading={isSendRuleTest}
          onClose={() => {
            setConfirmSendRuleTest({
              isOpen: false,
              rule,
            })
          }}
          onConfirm={handleOnSendRuleTest(confirmSendRuleTest.rule)}
        />
      )}

      <RuleDetailDrawer
        provider={provider}
        isOpen={openedRule.isOpen}
        rule={openedRule.rule}
        schema={ruleSchema}
        onEdit={() =>
          setCreateEditRule({
            isOpen: true,
            type: CreateEditRuleType.EDIT,
            rule: openedRule.rule,
          })
        }
        isDeleting={isDeleting}
        onSwitch={handleOnChangeRuleStatus(openedRule.rule)}
        onDelete={() => setConfirmDeleteRule({ isOpen: true, rule: openedRule.rule })}
        onClose={() =>
          setOpenedRule({
            isOpen: false,
            rule,
          })
        }
      />
    </div>
  )
}
