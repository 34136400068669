import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useQuery } from '@tanstack/react-query'
import { GetTagsInput } from '../../domain/dtos/get-tags-input'
import { QueryKey } from '../../domain/dtos/query-keys'
import { GetTags } from '../../usecases/get-tags'

export const useGetTags = (input: GetTagsInput, enabled: boolean) => {
  const container = useContainer()

  return useQuery([QueryKey.getTags], () => new GetTags(container.get(HttpClient)).execute(input), {
    retry: 3,
    retryOnMount: false,
    refetchOnWindowFocus: false,
    initialData: [],
    enabled,
  })
}
