import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '../../domain/dtos/query-keys'
import { GetRecoverPassword } from '../../usecases/get-recover-password'

export const useGetRecoverPassword = ({ token }: { token: string }) => {
  const container = useContainer()

  return useQuery(
    [QueryKey.putRecoverPassword],
    () =>
      new GetRecoverPassword(container.get(HttpClient)).execute({
        token,
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  )
}
