import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { OfferDtoStatusEnum, OffersByProductIdResponseDto } from '@hub-la/sdk-bff-product'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ChangeStatusInput } from '../../../domain/dtos/offers/change-status'
import { QueryKey } from '../../../domain/enums/query-key'
import { PostChangeStatus } from '../../../usecases/offers/post-change-status'

export const usePostChangeStatus = ({ productId, page, pageSize }) => {
  const container = useContainer()
  const queryClient = useQueryClient()

  return useMutation(
    [QueryKey.postChangeStatus],
    (input: Omit<ChangeStatusInput, 'productId'>) =>
      new PostChangeStatus(container.get(HttpClient)).execute({
        productId,
        ...input,
      }),
    {
      retry: false,
      onSuccess: (_, variables) => {
        queryClient.setQueryData<OffersByProductIdResponseDto>(
          [QueryKey.getOffersList, productId, page, pageSize],
          (data) => {
            return {
              ...data,
              items: (data?.items ?? []).map((item) => {
                if (item.id === variables.offerId) {
                  return {
                    ...item,
                    status: variables.status as unknown as OfferDtoStatusEnum, // cast to sdk enum
                  }
                }

                return item
              }),
              page: data?.page ?? 0,
              pageSize: data?.pageSize ?? 0,
              lastPage: data?.lastPage ?? 0,
              total: data?.total ?? 0,
            }
          },
        )
      },
    },
  )
}
