import { FormSteps } from '../presentation/pages/account-verification-modal'

export class IsStepValid {
  private isValid: (field: string) => boolean

  constructor(isValid: (field: string) => boolean) {
    this.isValid = isValid
  }

  public execute(step: FormSteps): boolean {
    switch (step) {
      case FormSteps.COMPANY_DATA:
        return (
          this.isValid('identity.document.type') &&
          this.isValid('identity.document.value') &&
          this.isValid('identity.fullName')
        )
      case FormSteps.WITHDRAW_INFO:
        return (
          this.isValid('identity.document.value') &&
          this.isValid('bankAccount.bankNumber') &&
          this.isValid('bankAccount.accountNumber') &&
          this.isValid('bankAccount.accountNumberCheck') &&
          this.isValid('bankAccount.accountType') &&
          this.isValid('bankAccount.agencyNumber')
        )
      case FormSteps.PERSONAL_INFO:
        return (
          this.isValid('identity.legalRepresentative.fullName') &&
          this.isValid('identity.email') &&
          this.isValid('identity.phone') &&
          this.isValid('identity.document.value') &&
          this.isValid('identity.legalRepresentative.document.value') &&
          this.isValid('identity.document.countryCode') &&
          this.isValid('identity.billingAddress.postalCode') &&
          this.isValid('identity.billingAddress.neighborhood') &&
          this.isValid('identity.billingAddress.street') &&
          this.isValid('identity.billingAddress.number') &&
          this.isValid('identity.billingAddress.city') &&
          this.isValid('identity.billingAddress.state')
        )
      case FormSteps.PUBLIC_INFO:
        return this.isValid('businessConfiguration.creditCard.softDescriptor')
      case FormSteps.VERIFY_IDENTITY:
        return this.isValid('sdkToken')
      default:
        return false
    }
  }
}
