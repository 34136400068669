import { HttpClient } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { GetMyAccess } from './get-my-access'

export class HasAccountsAccess {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(): Promise<boolean> {
    const data = await new GetMyAccess(this.httpClient).execute()

    return (data ?? []).length > 0
  }
}
