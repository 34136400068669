import { PostResponse } from '../domain/dtos'

export class IsPostContentHidden {
  public execute(post: PostResponse | undefined) {
    return post?.roleType === 'creator'
      ? false
      : post?.roleType === 'not-member'
      ? true
      : Boolean(post?.releaseAt && new Date(post?.releaseAt ?? 0) > new Date())
  }
}
