import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { QueryKey } from '../../domain/enums/query-key'
import { ChangeAffiliateCommission } from '../../usecases/change-affiliate-commission'

export type ChangeAffiliateCommissionInput = {
  affiliateId: string
  sellCommission: number
  renewalCommission: number
  useDefaultCommission: boolean
}

export const useChangeAffiliateCommission = () => {
  const container = useContainer()
  const queryClient = useQueryClient()

  return useMutation(
    [QueryKey.changeAffiliateCommission],
    (input: ChangeAffiliateCommissionInput) => new ChangeAffiliateCommission(container.get(HttpClient)).execute(input),
    {
      retry: false,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [QueryKey.getAffiliates] })
      },
    },
  )
}
