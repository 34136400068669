import { Post } from '@hub-la/fe-post'
import { FileImage, FileText, Image, PlayCircle } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { VideoDuration } from '../../../../../domain/dtos/member-area/settings/video-duration'
import { GetPostType } from '../../../../../usecases/member-area/settings/get-post-type'

type Props = {
  post: Post
}

const iconMap = {
  article: FileText,
  image: Image,
  pdf: FileImage,
  video: PlayCircle,
}

export const Duration = ({ post }: Props) => {
  const { t } = useTranslation()

  const postType = new GetPostType().execute(post?.cover?.type)
  const Icon = iconMap[postType]

  const duration =
    post?.cover?.metadata?.pages ??
    (post.cover?.metadata?.duration && new VideoDuration(post.cover?.metadata?.duration ?? 0).format())

  return (
    <div className="flex items-center mt-2">
      {Icon && <Icon className="text-muted-foreground" size={18} />}

      <span className="text-muted-foreground text-xs ml-1" data-testid="video-duration">
        {duration} {t(`member-area.settings.durationSubtitle.${postType}`)}
      </span>
    </div>
  )
}
