import { container } from '@hub-la/fe-container'
import { DashboardSubscriptionsProvider, SubscriptionDetail } from '@hub-la/fe-dashboard-subscriptions'
import { useHistory, useParams } from 'react-router-dom'

export const DashboardSubscriptionDetailInit = () => {
  const { subscriptionId } = useParams<{ subscriptionId: string }>()
  const history = useHistory()
  return (
    <DashboardSubscriptionsProvider container={container}>
      <SubscriptionDetail id={subscriptionId} onClose={() => history.goBack()} />
    </DashboardSubscriptionsProvider>
  )
}
