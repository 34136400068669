import { useParams } from 'react-router-dom'
import { useGetSmartInstallmentCycle } from '../../../hooks/use-get-smart-installment-cycle'
import { SmartInstallmentCycleDetails } from './smart-intallment-cycle-details'
import { SmartInstallmentsList } from './smart-installments-list'
import { Skeleton } from '@hub-la/shadcn'
import { PaymentDetails } from './payment-details'

export const SmartInstallmentCycle = () => {
  const { smartInstallmentCycleId } = useParams<{ smartInstallmentCycleId: string }>()

  const { data, isLoading } = useGetSmartInstallmentCycle(smartInstallmentCycleId)

  if (isLoading) {
    return (
      <>
        <Skeleton className="h-[512px] md:h-[372px] w-full" />
        <Skeleton className="h-[300px] w-full" />
        <div className="flex flex-col gap-3">
          <Skeleton className="w-[250px] h-[23px]" />
          {Array(3)
            .fill(0)
            .map((_, index) => (
              <Skeleton className="h-[94px]" key={index} />
            ))}
        </div>
      </>
    )
  }

  return (
    <>
      {data?.smartInstallmentCycle && (
        <SmartInstallmentCycleDetails
          smartInstallmentCycle={data.smartInstallmentCycle}
          smartInstallments={data.smartInstallments}
        />
      )}
      {data?.paymentDetails && (
        <PaymentDetails
          paymentDetails={data.paymentDetails}
          subscriptionId={data.smartInstallmentCycle.subscriptionId}
          offerId={data.smartInstallmentCycle.offerId}
        />
      )}
      {data?.smartInstallmentCycle && <SmartInstallmentsList smartInstallments={data.smartInstallments} />}
    </>
  )
}
