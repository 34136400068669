import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation } from '@tanstack/react-query'
import { GenerateTelegramCodeUseCase } from '../../usecases/generate-telegram-code.usecase'

export const useGenerateTelegramCode = () => {
  const container = useContainer()

  const { isError, error, isLoading, data, mutateAsync, isSuccess } = useMutation(
    (groupId?: string) => new GenerateTelegramCodeUseCase(container.get(HttpClient)).execute(groupId),
    {},
  )

  return {
    isError,
    error,
    data,
    isLoading,
    isSuccess,
    mutateAsync,
  }
}
