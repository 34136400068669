import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Input,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@hub-la/shadcn'
import { Field, FormikProvider, useFormik } from 'formik'
import { Plus } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { ProductCategory } from '../../../domain/enums/product-category'
import { ProductType } from '../../../domain/enums/product-type'
import { initialValues } from '../../../domain/factories/initial-values'
import { makeValidationSchema } from '../../../domain/factories/validation-schema'
import { useCreateProduct } from '../../hooks/use-create-product'

type Values = {
  name: string
  category?: ProductCategory
  description: string
}

export const CreateProduct = () => {
  const [open, setOpen] = useState<boolean>(false)

  const { mutateAsync: createProduct, isLoading } = useCreateProduct()
  const { t } = useTranslation()
  const history = useHistory()

  const formik = useFormik<Values>({
    validationSchema: makeValidationSchema(),
    validateOnMount: true,
    validateOnChange: true,
    enableReinitialize: true,
    initialValues,
    onSubmit: async (values) => {
      await createProduct({
        ...values,
        type: ProductType.DIGITAL,
        category: values.category ?? ProductCategory.other,
      }).then((id) => {
        setOpen(false)
        history.push(`/edit/${id}/#introduction`)
      })
    },
  })

  const { setFieldValue, submitForm, isValid, resetForm, dirty } = formik
  const onSubmit = () => submitForm()
  const onClose = () => {
    setOpen(false)
    resetForm()
  }

  return (
    <FormikProvider value={formik}>
      <Dialog>
        <DialogTrigger asChild>
          <Button size="sm" onClick={() => setOpen(true)}>
            <Plus className="mr-2 h-4 w-4" /> Novo produto
          </Button>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              <h4 className="text-2xl font-bold">Novo produto</h4>
            </DialogTitle>
          </DialogHeader>
          <div className="space-y-4 py-4">
            <Field name="name">
              {({ field, meta }: any) => (
                <div>
                  <Input
                    {...field}
                    placeholder="Nome do produto"
                    maxLength={70}
                    className={meta.touched && meta.error ? 'border-red-500' : ''}
                  />
                  {meta.touched && meta.error && <p className="text-red-500 text-sm mt-1">{meta.error}</p>}
                </div>
              )}
            </Field>

            <Field name="category">
              {({ field, meta }: any) => (
                <div>
                  <Select onValueChange={(value) => setFieldValue('category', value)} value={field.value}>
                    <SelectTrigger className="w-full">
                      <SelectValue placeholder="Selecione uma categoria" />
                    </SelectTrigger>

                    <SelectContent className="z-[1002]">
                      {Object.values(ProductCategory).map((category) => (
                        <SelectItem key={category} value={category}>
                          {t(`products.category.${category}`)}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              )}
            </Field>

            <Field name="description">
              {({ field, meta }: any) => (
                <div>
                  <Input
                    {...field}
                    placeholder="Descrição"
                    maxLength={354}
                    className={meta.touched && meta.error ? 'border-red-500' : ''}
                  />
                  <p
                    className={`text-xs mt-1 ${meta.touched && meta.error ? 'text-red-500' : 'text-muted-foreground'}`}
                  >
                    {meta.touched && meta.error ? meta.error : `${field.value.length}/350 caracteres.`}
                  </p>
                </div>
              )}
            </Field>
          </div>
          <DialogFooter>
            <Button variant="outline" onClick={onClose} disabled={isLoading} className="w-full">
              Cancelar
            </Button>
            <Button onClick={onSubmit} disabled={!isValid || !dirty || isLoading} className="w-full">
              {isLoading ? 'Carregando...' : 'Criar produto'}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </FormikProvider>
  )
}
