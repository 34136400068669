import React from 'react'

interface ContentSectionTitleProps {
  title: string
  color?: 'redSecondary'
}

export const ContentSectionTitle: React.FC<ContentSectionTitleProps> = ({ title, color }) => {
  const textColorClass = color === 'redSecondary' ? 'text-red-600' : 'text-primary'

  return (
    <div className="py-4">
      <h4 className={`text-lg font-semibold mb-2 mt-4 ${textColorClass}`}>{title}</h4>
      <hr className="border-t " data-testid="divider" />
    </div>
  )
}
