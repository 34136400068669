import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useQuery } from '@tanstack/react-query'
import { CheckGroupMemberUseCase } from '../../usecases/check-group-member.usecase'

export const useCheckGroupMember = (groupId: string) => {
  const container = useContainer()
  return useQuery(
    [`check-member-${groupId}`],
    () => new CheckGroupMemberUseCase(container.get(HttpClient)).execute(groupId),
    {
      refetchOnWindowFocus: false,
      initialData: true,
      retry: false,
    },
  )
}
