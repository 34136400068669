const magicNumberToMimeType: { [key: string]: string } = {
  '52617221': 'application/x-rar-compressed', // RAR v1.5 - v4.0
  '526172211a0700': 'application/x-rar-compressed', // RAR v5.0+
  '504b0304': 'application/zip', // ZIP
}

export class GetFileMimetype {
  public async execute(file: File) {
    const fileType = file.type

    // If the file type is already known, return it
    if (fileType) {
      return fileType
    }

    // Fallback to reading the file header maigc number
    const bufferType = await this.getTypeByBuffer(file)
    if (bufferType) {
      return bufferType
    }

    // Fallback to reading the data url from the file
    const dataUrlType = await this.getTypeByDataUrl(file)
    if (dataUrlType) {
      return dataUrlType
    }

    // No type was found, return default binary type to ensure the file is uploaded without type
    return 'application/octect-stream'
  }

  public async getTypeByDataUrl(file: File) {
    const reader = new FileReader()
    reader.readAsDataURL(file)

    return new Promise<string>((resolve, reject) => {
      reader.onload = () => {
        const result = reader.result as string
        const type = result.match(/data:(.*);base64/)?.[1]
        resolve(type || '')
      }
      reader.onerror = reject
    });
  }

  public async getTypeByBuffer(file: File) {
    const reader = new FileReader()

    const readAsArrayBuffer = (file: File): Promise<ArrayBuffer> => {
      return new Promise((resolve, reject) => {
        reader.onload = () => resolve(reader.result as ArrayBuffer)
        reader.onerror = reject
        reader.readAsArrayBuffer(file)
      })
    }

    try {
      const buffer = await readAsArrayBuffer(file)

      const fileMagicNumber = Array.from(new Uint8Array(buffer).slice(0, 8))
        .map((byte) => byte.toString(16).padStart(2, '0'))
        .join('')

      if (!fileMagicNumber) {
        return ''
      }

      const shortMagicNumber = fileMagicNumber.slice(0, 8)

      return magicNumberToMimeType[shortMagicNumber] || ''
    } catch (error) {
      return ''
    }
  }
}
