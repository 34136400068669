import { useContainer } from "@hub-la/fe-container"
import { HttpClient } from "@hub-la/fe-core-http-client"
import { useQuery } from "@tanstack/react-query"
import { QueryKey } from "../../domain/enums/query-key"
import { GetOverdueSmartInstallments } from "../../usecases/get-overdue-smart-installments.usecase"

export function useGetOverdueSmartInstallments() {
  const container = useContainer()

  return useQuery(
    [QueryKey.getOverdueSmartInstallments],
    () => new GetOverdueSmartInstallments(container.get(HttpClient)).execute(),
    {
      retry: false,
      retryOnMount: false,
      refetchOnWindowFocus: false,
    })
}

