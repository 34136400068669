import { Label } from '@hub-la/shadcn'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useGetListGroups } from '../../../hooks/use-get-list-groups'
import { useGetSections } from '../../../hooks/use-get-sections'
import { MultiSelect } from '../../multi-select'

type AccessesContentProps = {
  productId: string
  mainOfferId?: string
}

export const AccessesContent: React.FC<AccessesContentProps> = ({ productId, mainOfferId }) => {
  const { t } = useTranslation()
  const { data: sections, isFetching } = useGetSections({ productId })
  const { data: groups, isFetching: isFetchingGroups } = useGetListGroups(mainOfferId)

  return (
    <div className="flex flex-col gap-4">
      <Label className="text-base">{t('cohort.accessesTab.title')}</Label>

      <div className="flex flex-col gap-4">
        <Controller
          name="sections"
          render={({ field, fieldState }) => (
            <MultiSelect
              {...field}
              options={
                sections?.map((sections) => ({
                  id: sections.id,
                  label: sections.name.split(' - ')[0],
                  type: sections.name.split(' - ')[1],
                })) ?? []
              }
              label={'Módulo(s) de conteúdo'}
              error={Boolean(fieldState.error?.type)}
              helperText={fieldState.error?.type && t(`fieldErrors.${fieldState.error?.type}`)}
              disabled={isFetching}
              hasSelectAll
            />
          )}
        />

        <Controller
          name="groups"
          render={({ field, fieldState }) => {
            return (
              <MultiSelect
                {...field}
                options={
                  groups?.map((group) => ({
                    id: group.id,
                    label: group.name.split(' - ')[0],
                    type: group.name.split(' - ')[1],
                  })) ?? []
                }
                label={'Grupo(s)'}
                error={Boolean(fieldState.error?.type)}
                helperText={fieldState.error?.type && t(`fieldErrors.${fieldState.error?.type}`)}
                disabled={isFetchingGroups}
                hasSelectAll
              />
            )
          }}
        />
      </div>
    </div>
  )
}
