import { AvailableCarriers, useMultigatewayHandler } from '@hub-la/fe-tokenizer'
import { Button, Tooltip, TooltipContent, TooltipProvider, TooltipTrigger, Pix } from '@hub-la/shadcn'
import { useFormikContext } from 'formik'
import { CreditCard } from 'lucide-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Values } from '.'
import { Installment } from '../../../../../domain/dtos/installment'
import { UpgradePaymentMethods } from '../../../../../domain/dtos/payment-method'
import { useGetSession } from '../../../../hooks/use-get-session'
import { CPF } from './cpf'
import { FillCreditCard } from './fill-credit-card'
import { YunoCreditCard } from './yuno-credit-card'

type Props = {
  paymentMethods: UpgradePaymentMethods[]
  offerId: string
  installments: Installment[]
  isSmartInstallmentsInProgress: boolean
}

export const ChoosePaymentMethods: React.FC<Props> = ({
  paymentMethods,
  offerId,
  installments,
  isSmartInstallmentsInProgress,
}) => {
  useGetSession()
  const { t } = useTranslation()
  const { setFieldValue, values } = useFormikContext<Values>()
  const gateway = useMultigatewayHandler(offerId)
  const shouldDisablePix = (paymentMethod: UpgradePaymentMethods) =>
    paymentMethod === UpgradePaymentMethods.PAYMENT_METHOD_PIX && isSmartInstallmentsInProgress

  const paymentMethodText = {
    [UpgradePaymentMethods.PAYMENT_METHOD_PIX]: t('changePaymentMethodModal.paymentMethod.pix'),
    [UpgradePaymentMethods.PAYMENT_METHOD_CARD]: t('changePaymentMethodModal.paymentMethod.creditCard'),
  }

  const paymentMethodIcon = {
    [UpgradePaymentMethods.PAYMENT_METHOD_PIX]: <Pix className="h-4 w-4" />,
    [UpgradePaymentMethods.PAYMENT_METHOD_CARD]: <CreditCard className="h-4 w-4" />,
  }

  const creditCardField = () => {
    switch (gateway) {
      case AvailableCarriers.YUNO:
        return <YunoCreditCard installments={installments} />
      case AvailableCarriers.IUGU:
      case AvailableCarriers.MULTI:
      default:
        return <FillCreditCard installments={installments} offerId={offerId} />
    }
  }

  return (
    <>
      <div className="flex gap-2 pb-2">
        {paymentMethods.map((paymentMethod) =>
          shouldDisablePix(paymentMethod) ? (
            <TooltipProvider key={paymentMethod}>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button variant="outline" size="sm" disabled className="flex items-center gap-2">
                    {paymentMethodIcon[paymentMethod]}
                    {paymentMethodText[paymentMethod]}
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  <p>{t('changePaymentMethodModal.pixDisabled')}</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          ) : (
            <Button
              key={paymentMethod}
              variant={values.paymentMethod === paymentMethod ? 'default' : 'outline'}
              size="sm"
              onClick={() => setFieldValue('paymentMethod', paymentMethod)}
              className="flex items-center gap-2"
            >
              {paymentMethodIcon[paymentMethod]}
              {paymentMethodText[paymentMethod]}
            </Button>
          ),
        )}
      </div>

      {values.paymentMethod === UpgradePaymentMethods.PAYMENT_METHOD_CARD && creditCardField()}
      {values.paymentMethod === UpgradePaymentMethods.PAYMENT_METHOD_PIX && <CPF />}
    </>
  )
}
