import { Button, Popover, PopoverContent, PopoverTrigger } from '@hub-la/shadcn'
import { Palette } from 'lucide-react'
import { HexColorPicker } from 'react-colorful'
import { Controller, FieldPath, useFormContext, useWatch } from 'react-hook-form'
import { TicketSchemaValidationType } from '../validations/ticket-validation'

type ColorPickerProps = {
  name: FieldPath<TicketSchemaValidationType>
}

const ColorPicker = ({ name }: ColorPickerProps) => {
  const { control } = useFormContext<TicketSchemaValidationType>()
  const color = useWatch({ name, control })

  return (
    <div className="flex gap-2">
      <div
        className="flex justify-center items-center max-w-[170px] w-full h-10 rounded border"
        style={{ backgroundColor: typeof color === 'string' && color ? color : '#f20000' }}
      >
        <span className="text-primary-foreground text-shadow" style={{ textShadow: '1px 1px 1px rgba(0, 0, 0, 1)' }}>
          {color}
        </span>
      </div>

      <Popover>
        <PopoverTrigger asChild>
          <Button variant="outline" size="icon">
            <Palette className="h-4 w-4" />
          </Button>
        </PopoverTrigger>

        <PopoverContent className="w-auto p-0">
          <Controller
            name={name}
            control={control}
            render={({ field }) => (
              <HexColorPicker
                color={field.value?.toString()}
                onChange={(newColor) => {
                  field.onChange(newColor)
                }}
              />
            )}
          />
        </PopoverContent>
      </Popover>
    </div>
  )
}

export { ColorPicker }
