import generatePdfThumbnails from 'pdf-thumbnails-generator'
import * as uuid from 'uuid'
import { DataUrlToFile } from './data-url-to-file'

type Thumbnail = {
  thumbnail: string
}

type GeneratePdfThumbnailsOutput = Thumbnail[]

export class GenerateThumbnail {
  public async execute(data) {
    const thumbnailFileName = `${data.name}_thumbnail.png`
    const thumbnail = await this.getThumbnail(data.url)
    const thumbnailFile = await new DataUrlToFile().execute(thumbnail, thumbnailFileName)

    return {
      id: uuid.v4(),
      name: thumbnailFileName,
      mimeType: thumbnailFile.type,
      file: thumbnailFile,
    }
  }

  private async getThumbnail(url: string): Promise<string> {
    if (typeof window === 'undefined') {
      return ''
    }

    const thumbnails: GeneratePdfThumbnailsOutput = await generatePdfThumbnails(url, 211)
    const thumbnail = thumbnails[0].thumbnail
    return thumbnail
  }
}
