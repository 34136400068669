import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation } from '@tanstack/react-query'
import { UpdateReceiverStatusInput } from '../../domain/dtos/update-receiver-status-input'
import { QueryKey } from '../../domain/enums/query-key'
import { UpdateReceiverStatus } from '../../usecases/update-receiver-status'

export const useUpdateReceiverStatus = () => {
  const container = useContainer()

  return useMutation<void, Error, UpdateReceiverStatusInput, Error>(
    [QueryKey.updateReceiverStatus],
    (input: UpdateReceiverStatusInput) => new UpdateReceiverStatus(container.get(HttpClient)).execute(input),
    {
      retry: false,
    },
  )
}
