import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '../../../domain/enums/query-key'
import { ListMyPartners } from '../../../usecases/settings/list-my-partners'

export const useListMyPartners = (input: { id: string; page: number; pageSize: number }) => {
  const container = useContainer()

  return useQuery(
    [QueryKey.listMyPartners, input.id, input.page, input.pageSize],
    ({ signal }) => new ListMyPartners(container.get(HttpClient)).execute(input, signal),
    {
      refetchOnWindowFocus: false,
      retry: 3,
    },
  )
}
