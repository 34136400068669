import { Label } from '@hub-la/shadcn'
import { differenceInDays, format } from 'date-fns'
import { pt } from 'date-fns/locale'
import { ArrowRight, CalendarIcon, CalendarX2, Info } from 'lucide-react'

type RefundTimelineProps = {
  paidAt: string
  createdAt: string
}

export const RefundTimeline: React.FC<RefundTimelineProps> = ({ paidAt, createdAt }) => {
  const daysDifference = differenceInDays(new Date(createdAt), new Date(paidAt))
  const isDestructive = daysDifference > 7

  const formatDate = (date: string) => {
    return format(new Date(date), "EEE, d 'de' MMM. yyyy HH:mm", { locale: pt })
  }

  return (
    <div className="space-y-2">
      <div className="flex flex-col sm:flex-row sm:items-center gap-4">
        <div className="flex flex-col space-y-1.5">
          <Label>Data do pagamento</Label>
          <div className="flex items-center border rounded-md px-3 py-2">
            <CalendarIcon className="w-4 h-4 text-primary" />
            <span className="ml-2 text-sm">{formatDate(paidAt)}</span>
          </div>
        </div>
        <ArrowRight className="hidden sm:block w-4 h-4 text-muted-foreground mx-4 mt-6" />
        <div className="flex flex-col space-y-1.5">
          <Label>Data do pedido de reembolso</Label>
          <div className="flex items-center border rounded-md px-3 py-2">
            {isDestructive ? (
              <CalendarX2 className="w-4 h-4 text-destructive" />
            ) : (
              <CalendarIcon className="w-4 h-4 text-primary" />
            )}
            <span className="ml-2 text-sm">{formatDate(createdAt)}</span>
          </div>
        </div>
      </div>

      <p className="text-xs text-muted-foreground flex items-center gap-2">
        <Info className="w-3 h-3 text-muted-foreground hidden sm:block" />
        {isDestructive
          ? 'O cliente solicitou o reembolso após o período de 7 dias estabelecido pelo Código de Defesa do Consumidor.'
          : 'O cliente solicitou o reembolso dentro do período de 7 dias estabelecido pelo Código de Defesa do Consumidor.'}
      </p>
    </div>
  )
}
