import { withAccountVerification, withApprovedPayout } from '@hub-la/fe-account-verification'
import { Button, CopyClipboardButton, Dialog, DialogContent, DialogHeader, DialogTitle } from '@hub-la/shadcn'
import { DollarSign, RefreshCcw } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Money } from '../../../domain/vos/money'
import { Envs } from '../../../envs'

type GetLinkModalProps = {
  affiliationId?: string
  linkDetails?: {
    offerId: string
    offerName: string
    commissioning: {
      sell: {
        isEnabled: boolean
        percent: number
        maxAmountCents: number
      }
      renewal: {
        isEnabled: boolean
        percent: number
        maxAmountCents: number
      }
    }
  }[]
}

const ButtonWithAccountVerification = withApprovedPayout(
  withAccountVerification(Button, {
    tooltip: 'Você precisa ativar sua conta para acessar os links de afiliado',
  }),
)

export const GetLinkModal = ({ affiliationId, linkDetails }: GetLinkModalProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const { t } = useTranslation()

  return (
    <div className="flex flex-row gap-2 justify-end">
      <ButtonWithAccountVerification variant="outline" onClick={() => setOpen(true)}>
        Links
      </ButtonWithAccountVerification>
      <Dialog open={open} onOpenChange={(isOpen) => setOpen(isOpen)}>
        <DialogContent className="flex flex-col">
          <DialogHeader>
            <DialogTitle>{t('affiliates.linksModal.title')}</DialogTitle>
          </DialogHeader>

          <p className="text-sm text-muted-foreground">{t('affiliates.linksModal.description')}</p>

          <div className="mt-3 max-h-96 flex flex-col gap-4 overflow-y-auto">
            {(linkDetails || []).map((linkDetail, index) => {
              const checkoutLink = `${Envs.PAY_CHECKOUT_URL}/${linkDetail?.offerId}?ref=${affiliationId}`

              return (
                <div key={index} className="border rounded-md p-4 flex flex-col">
                  <div className="flex flex-col gap-2">
                    <label className="text-sm font-medium" htmlFor={`link-${index}`}>
                      {`Checkout – ${linkDetail.offerName}`}
                    </label>

                    <CopyClipboardButton
                      copyContent={checkoutLink}
                      label={checkoutLink}
                      tooltipCopiedLabel={t('copyClipboard.linkCopied')}
                      tooltipCopyLabel={t('copyClipboard.copyLink')}
                    />
                  </div>

                  {linkDetail?.commissioning?.sell?.percent > 0 && linkDetail?.commissioning?.renewal?.percent > 0 && (
                    <div className="mt-4 flex space-x-4">
                      {linkDetail?.commissioning?.sell?.percent > 0 && (
                        <div className="flex items-start space-x-2">
                          <DollarSign className="h-5 w-5 text-muted-foreground" />

                          <div>
                            <p className="text-sm font-medium">
                              {t('affiliates.linksModal.linkComission.sellMaxAmount', {
                                maxAmountCents: Money.build(
                                  (linkDetail?.commissioning?.sell?.maxAmountCents ?? 0) / 100,
                                ).getValue(),
                              })}
                            </p>

                            <p className="text-sm text-muted-foreground">
                              {t('affiliates.linksModal.linkComission.sellComissionPercent', {
                                percent: linkDetail?.commissioning?.sell?.percent,
                              })}
                            </p>
                          </div>
                        </div>
                      )}

                      {linkDetail?.commissioning?.renewal?.percent > 0 && (
                        <div className="flex items-start space-x-2">
                          <RefreshCcw className="h-5 w-5 text-muted-foreground" />

                          <div>
                            <p className="text-sm font-medium">
                              {t('affiliates.linksModal.linkComission.renewalMaxAmount', {
                                maxAmountCents: Money.build(
                                  (linkDetail?.commissioning?.renewal?.maxAmountCents ?? 0) / 100,
                                ).getValue(),
                              })}
                            </p>

                            <p className="text-sm text-muted-foreground">
                              {t('affiliates.linksModal.linkComission.renewalComissionPercent', {
                                percent: linkDetail?.commissioning?.renewal?.percent,
                              })}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )
            })}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}
