export const pt = {
  Receiver: {
    notificationCard: {
      action_title: 'Você tem ações de parceria pendentes',
      action_subtitle: 'Você tem convites e solicitações pendentes',
      action_button: 'Ver pendências',

      invite_title: 'Novo convite de parceria',
      invite_subtitle: 'Você tem um convite de parceria pra aceitar',
      invite_button: 'Ver convite',

      request_title: 'Solicitação de cancelamento de parceria',
      request_subtitle: 'Você tem uma solicitação de cancelamento de parceria',
      request_button: 'Ver solicitação',
    },
    inviteForm: {
      invite_title: 'Convidar parceiro',
      edit_title: 'Editar parceiro',
      back_button: 'Voltar',
      fields: {
        email: 'E-mail do parceiro',
        commission: 'Comissão do parceiro',
        sales_percentage: 'Porcentagem das vendas',
        end_date_partnership: 'Data de encerramento da parceria',
        end_date: {
          title: 'Data de encerramento',
          with_end_date: 'Com data de encerramento',
          without_end_date: 'Sem data de encerramento',
        },
        terms_of_use: {
          prefix: 'Aceito os',
          link: 'termos de uso',
          suffix: 'da parceria.',
        },
      },
      defaultName: 'Novo parceiro',
      invite_submit_button: 'Convidar parceiro',
      edit_submit_button: 'Salvar',
      instruction: 'A parceria começa assim que o parceiro aceitar o convite.',
      edit_instruction: 'Ao salvar, as novas condições só entrarão em vigor após o aceite pelo parceiro.',
    },
    inviteList: {
      accept: 'Aceitar',
      reject: 'Rejeitar',
      noExpires: 'Sem data final',
    },
    partnersList: {
      noExpires: 'Sem data final',
      desktopList: {
        header: {
          productName: 'Produtos',
          expires: 'Data encerramento',
          commission: '% das vendas',
          status: 'Status',
          actions: 'Ações',
        },
      },
    },
    acceptCancellationModal: {
      title: 'Deseja aceitar cancelamento de parceria?',
      conditions: 'Condições da parceria',
      commission: 'Percentual nas vendas de {{commission}}%',
      expires: 'Até {{expires}}',
      noExpires: 'Sem data final',
      notes: '+ Condições de emissão de notas',
      confirm: 'Aceitar',
      termsOfUse: {
        link: 'Termos de uso',
        suffix: 'da parceria.',
      },
    },
    rejectCancellationModal: {
      title: 'Recusar cancelamento de parceria?',
      goBack: 'Voltar',
      reject: 'Recusar solicitação',
      expires: 'até {{expires}}',
      noExpires: 'sem data de expiração',
    },
    acceptInviteModal: {
      title: 'Confirmar parceria',
      conditions: 'Condições da parceria',
      commission: 'Percentual nas vendas de {{commission}}%',
      expires: 'Até {{expires}}',
      noExpires: 'Sem data final',
      notes: '+ Condições de emissão de notas',
      acceptTerms: 'Para aceitar a parceria é preciso concordar com os termos de uso.',
      confirm: 'Confirmar',
    },
    rejectInviteModal: {
      title: 'Recusar o convite de parceria?',
      goBack: 'Voltar',
      reject: 'Recusar convite',
      expires: 'até {{expires}}',
      noExpires: 'sem data de expiração',
    },
    cancellationRequestModal: {
      title: 'Deseja cancelar vínculo com parceiro?',
      alert: 'Para efetivar o cancelamento, o parceiro terá que aceitar.',
      no: 'Não',
      yes: 'Sim',
      expires: 'até {{expires}}',
      noExpires: 'sem data de expiração',
    },
    cancelCancellationRequestModal: {
      title: 'Deseja cancelar a solicitação de cancelamento de parceria?',
      no: 'Não',
      yes: 'Sim',
    },
    errors: {
      ReceiverUserNotFound: 'Usuário não encontrado',
      ReceiverInviteEmptyEmailOrUserid: 'Você precisa especificar um e-mail ou um id de usuário',
      ReceiverInviteSameEmailThatCreator: 'Você precisa especificar um e-mail diferente do seu usuário',
      ReceiverGroupNotFound: 'O id do grupo não foi encontrado',
      ReceiverGroupHasAnotherOwner: 'Você não é dono do grupo especificado',
      ReceiverInviteExpiresAtInvalid: 'A data de encerramento precisa ser uma data futura',
      ReceiverCommissionGreaterThanZero: 'A comissão precisa ser maior que zero',
      ReceiverRenewalCommissionNeedGreaterZero: 'A comissão precisa ser maior que zero',
      ReceiverRenewalCommissionGreater100: 'A soma da sua comissão e dos parceiros não pode ser maior que 100%',
      ReceiverSellCommissionNeedGreaterZero: 'A comissão precisa ser maior que zero',
      ReceiverSellCommissionGreater100: 'A soma da sua comissão e dos parceiros não pode ser maior que 100%',
      ReceiverNotExists: 'O parceiro especificado não existe',
      ReceiverHasNotGroup: 'O parceiro não tem um grupo',
      ReceiverForbiddenUser: 'Ação proibida: usuário',
      ReceiverAcceptInviteForbiddenStatus: 'Ação proibida: status',
      ReceiverOtherUser: 'O parceiro não existe ou não pertence a este usuário',
      ReceiverInvalidStatus: 'O parceiro não tem um status válido',
      ReceiverEditGroupRequired: 'Id do grupo é obrigatório',
      ReceiverInviteUserAlreadyInvited: 'Esse usuário ja foi convidado para co-produção neste grupo',
      ReceiverAcceptInviteUserAlreadyCoproduction: 'Esse usuário ja foi convidado para co-produção neste grupo',
      ReceiverInviteAccountIdInvalid: 'O usuário não tem um id de conta válido',
      general: 'Não conseguimos identificar o erro ocorrido... Tente novamente!',
    },
  },
}
