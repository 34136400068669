import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '../../domain/enum/query-key'
import { GetPost } from '../../usecases/get-post'

export const useGetPost = ({ postId }: { postId: string | null }) => {
  const container = useContainer()

  return useQuery([QueryKey.getPost, postId], () => new GetPost(container.get(HttpClient)).execute(postId), {
    enabled: !!postId,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    retry: 3,
  })
}
