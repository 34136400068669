/* tslint:disable */
/* eslint-disable */
/**
 * BFF Web Product
 * API reference used to call the backend from the web frontend
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { OfferRefDto } from './offer-ref-dto';
// May contain unused imports in some cases
// @ts-ignore
import { UtmDto } from './utm-dto';

/**
 * 
 * @export
 * @interface ShortLinkDto
 */
export interface ShortLinkDto {
    /**
     * 
     * @type {string}
     * @memberof ShortLinkDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ShortLinkDto
     */
    'ownerId': string;
    /**
     * 
     * @type {OfferRefDto}
     * @memberof ShortLinkDto
     */
    'offer': OfferRefDto;
    /**
     * 
     * @type {string}
     * @memberof ShortLinkDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ShortLinkDto
     */
    'redirectUrl': string;
    /**
     * 
     * @type {number}
     * @memberof ShortLinkDto
     */
    'clickCount': number;
    /**
     * 
     * @type {boolean}
     * @memberof ShortLinkDto
     */
    'isDefault': boolean;
    /**
     * 
     * @type {string}
     * @memberof ShortLinkDto
     */
    'slug'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShortLinkDto
     */
    'type': ShortLinkDtoTypeEnum;
    /**
     * 
     * @type {UtmDto}
     * @memberof ShortLinkDto
     */
    'utm': UtmDto | null;
}

/**
    * @export
    * @enum {string}
    */
export enum ShortLinkDtoTypeEnum {
    CHECKOUT = 'checkout',
    CHECKOUT_ONE_CLICK = 'checkout_one_click',
    PRODUCT = 'product',
    PRODUCT_EXTERNAL = 'product_external',
    MEMBERS_AREA = 'members_area',
    MEMBERS_AREA_EXTERNAL = 'members_area_external',
    EXTERNAL = 'external'
}


