import { useContainer } from "@hub-la/fe-container"
import { HttpClient } from "@hub-la/fe-core-http-client"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { QueryKey } from "../../domain/enums/query-key"
import { ChargeSmartInstallment } from "../../usecases/charge-smart-installment.usecase"
import { ChargeSmartInstallmentInput } from "../../domain/dtos/charge-smart-installment"
import { toast } from "@hub-la/shadcn"
import { GetSmartInstallemntCycleOutput, SmartInstallment } from "../../domain/dtos/get-smart-installment-cycle"
import { SmartInstallmentStatus } from "../../domain/enums/smart-installment-status"

export function useChargeSmartInstallment() {
  const queryClient = useQueryClient()
  const container = useContainer()

  return useMutation(
    [QueryKey.getSmartInstallmentCycle],
    (input: ChargeSmartInstallmentInput) => new ChargeSmartInstallment(container.get(HttpClient)).execute(input),
    {
      retry: false,
      onSuccess: async (_, input) => {
        await queryClient.cancelQueries({ queryKey: [QueryKey.getSmartInstallmentCycle, input.sourceInvoiceId] })

        queryClient.setQueryData([QueryKey.getSmartInstallmentCycle, input.sourceInvoiceId], (old: any) => {
          const smartInstallmentIndex = old
            .smartInstallments
            .findIndex((smartIntallment: SmartInstallment) => smartIntallment.installment === input.installment)

          const oldSmartInstallments = [...old.smartInstallments]

          oldSmartInstallments[smartInstallmentIndex] = {
            ...oldSmartInstallments[smartInstallmentIndex],
            status: SmartInstallmentStatus.STATUS_PROCESSING
          }

          return {
            ...old,
            smartInstallments: [...oldSmartInstallments]
          }
        })
      },
      onError: (e: Error, input, context) => {
        toast({ variant: "destructive", title: e.message })
      },
      onSettled: (data, e, input) => {
        setTimeout(() => {
          queryClient.invalidateQueries({ queryKey: [QueryKey.getSmartInstallmentCycle, input.sourceInvoiceId] })
        }, 20000)
      },
    })
}

