import { Tabs, TabsContent, TabsList, TabsTrigger } from '@hub-la/shadcn'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { CreateSectionButtonWrapper } from '../../components/create-section-button-wrapper'
import { useGetResources } from '../../hooks/member-area/use-get-resources'
import { Content } from './content'
import { Groups } from './groups'
import { CreateGroup } from './groups/components/create-group'
import CustomMemberArea from './settings'

type Props = {
  offerId?: string
}

export const MemberAreaPage: React.FC<Props> = ({ offerId }) => {
  const [tab, setTab] = useState<string>(new URLSearchParams(window.location.search).get('tab') ?? 'content')
  const { t } = useTranslation()
  const history = useHistory()
  const { data = [], isLoading } = useGetResources(offerId)

  const onTabChange = (tab: string) => {
    history.push(history.location.pathname + '?tab=' + tab)
    setTab(tab)
  }

  return (
    <div className="flex flex-col gap-2 py-6">
      <div className="flex justify-between items-center">
        <h1 className="text-xl font-semibold h-9">Área de membros</h1>
        <div className="flex gap-2">
          {tab === 'content' && <CreateSectionButtonWrapper />}
          {tab === 'groups' && <CreateGroup offerId={offerId} isLoading={isLoading} disabled={data?.length > 50} />}
        </div>
      </div>

      <Tabs value={tab} onValueChange={onTabChange} className="flex flex-col">
        <div className="w-full overflow-x-auto">
          <TabsList>
            <TabsTrigger value="content">{t('member-area.tabs.content')}</TabsTrigger>
            <TabsTrigger value="groups">{t('member-area.tabs.groups')}</TabsTrigger>
            <TabsTrigger value="settings">{t('member-area.tabs.settings')}</TabsTrigger>
          </TabsList>
        </div>

        <TabsContent value="content">
          <Content />
        </TabsContent>

        <TabsContent value="groups">
          <Groups offerId={offerId} />
        </TabsContent>

        <TabsContent value="settings">
          <CustomMemberArea />
        </TabsContent>
      </Tabs>
    </div>
  )
}
