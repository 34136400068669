import { CohortProvider, CreateCohortPage, EditCohortPage } from '@hub-la/fe-cohort'
import { container } from '@hub-la/fe-container'
import { useParams } from 'react-router-dom'

export const CohortInit = () => {
  const { cohortId } = useParams<{ cohortId: string }>()

  const isCreate = cohortId === 'create'

  return <CohortProvider container={container}>{isCreate ? <CreateCohortPage /> : <EditCohortPage />}</CohortProvider>
}
