import { CohortDto } from '@hub-la/sdk-bff-product'
import {
  Badge,
  Card,
  CardContent,
  Skeleton,
  Switch,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@hub-la/shadcn'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

export interface CohortsCardSwitch {
  cohort: CohortDto
  checked?: boolean
}

interface CohortsCardProps {
  data: CohortsCardSwitch[]
  onChange: (cohorts: CohortDto[]) => void
  isLoadingData: boolean
}

const CohortsCard: React.FC<CohortsCardProps> = ({ data, onChange, isLoadingData }) => {
  const { t } = useTranslation()
  const [cohortSwitches, setCohortSwitches] = useState<CohortsCardSwitch[]>([])

  useEffect(() => {
    if (!isLoadingData) {
      setCohortSwitches(data)
    }
  }, [data, isLoadingData])

  const memoizedChange = useMemo(() => {
    return cohortSwitches.filter((cohortSwitch) => cohortSwitch.checked).map((item) => item.cohort)
  }, [cohortSwitches])

  useEffect(() => {
    onChange(memoizedChange)
  }, [cohortSwitches])

  const handleChange = (index: number) => () => {
    const activeSwitchesCount = cohortSwitches.filter((switchItem) => switchItem.checked).length
    if (activeSwitchesCount === 1 && cohortSwitches[index].checked) {
      return
    }

    const updatedSwitches = cohortSwitches.map((switchItem, i) =>
      i === index ? { ...switchItem, checked: !switchItem.checked } : switchItem,
    )

    setCohortSwitches(updatedSwitches)
  }

  const handleTooltip = (index: number) => {
    const updatedSwitches = cohortSwitches.map((switchItem, i) =>
      i === index ? { ...switchItem, checked: !switchItem.checked } : switchItem,
    )
    const activeSwitches = updatedSwitches.filter((switchItem) => switchItem.checked).length
    return activeSwitches === 0
  }

  return (
    <Card>
      <CardContent className="p-4 flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <span>{t('member-area.groups.editResourceDrawer.cohorts')}</span>

          <p className="text-sm text-muted-foreground">{t('member-area.groups.editResourceDrawer.toolTip')}</p>
        </div>

        {isLoadingData ? (
          <>
            <Skeleton className="h-6 w-1/2" />
            <Skeleton className="h-6 w-1/2" />
          </>
        ) : (
          cohortSwitches.map((switchItem, i) => (
            <div key={switchItem.cohort.id} className="flex items-center">
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <div className="flex items-center gap-2">
                      <Switch
                        checked={switchItem.checked}
                        onCheckedChange={handleChange(i)}
                        id={`switch-${switchItem.cohort.id}`}
                      />

                      <label htmlFor={`switch-${switchItem.cohort.id}`} className="text-sm">
                        {switchItem.cohort.name}
                      </label>
                    </div>
                  </TooltipTrigger>

                  {handleTooltip(i) && (
                    <TooltipContent>
                      <p>{t('member-area.groups.editResourceDrawer.shouldHaveCohort')}</p>
                    </TooltipContent>
                  )}
                </Tooltip>
              </TooltipProvider>

              {switchItem.cohort.isDefault && (
                <Badge variant="secondary" className="ml-2">
                  Padrão
                </Badge>
              )}
            </div>
          ))
        )}
      </CardContent>
    </Card>
  )
}

export default React.memo(CohortsCard)
