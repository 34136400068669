import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { QueryKey } from '../../domain/dtos/query-keys'
import { SaveTemporaryBusinessInput } from '../../domain/dtos/save-temporary-business-input'
import { SaveTemporaryBusiness } from '../../usecases/save-temporary-business'

export const useSaveTemporaryBusiness = () => {
  const container = useContainer()
  const queryClient = useQueryClient()

  return useMutation(
    [QueryKey.submitAccountVerification],
    (input: SaveTemporaryBusinessInput) => new SaveTemporaryBusiness(container.get(HttpClient)).execute(input),
    {
      retry: false,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['getTemporaryBusiness'] })
      },
    },
  )
}
