import { Label, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@hub-la/shadcn'
import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import { BRAZILIAN_BANKS } from '../../domain/constants/brazilian-banks'
import { BankAccountType } from '../../domain/enums/bank-account'
import { useField } from '../hooks/use-field'
import { UpdatePayoutValues } from '../pages/update-payout-modal'
import { InputWrapper } from './input-wrapper'

export const BankAccountForm = () => {
  const { t } = useTranslation()
  const { hasError, getErrorText } = useField()
  const { values, handleChange, handleBlur, setFieldValue } = useFormikContext<UpdatePayoutValues>()

  const needsAgencyDigit = () => ['001', '041', '237', '633'].includes(values.bankAccount.bankNumber ?? '')
  const shouldAcceptTextAtAccountCheckNumber = () => ['001', '237'].includes(values.bankAccount.bankNumber ?? '')
  const shouldAcceptMoreDigitsAtAccountCheckNumber = () => ['752'].includes(values.bankAccount.bankNumber ?? '')

  return (
    <div className="flex flex-col space-y-4">
      <div className="flex flex-col space-y-1.5">
        <Label className="mb-1">{t('accountVerification.form.bankAccount.bank')}</Label>
        <Select
          value={values.bankAccount.bankNumber}
          onValueChange={(value) => setFieldValue('bankAccount.bankNumber', value)}
        >
          <SelectTrigger className="w-full">
            <SelectValue placeholder={t('accountVerification.form.bankAccount.bank')} />
          </SelectTrigger>
          <SelectContent className="z-[6000]">
            {BRAZILIAN_BANKS.map((bank) => (
              <SelectItem key={bank.code} value={bank.code}>
                {bank.code} - {bank.name}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
        <div className="flex w-full space-x-3">
          <InputWrapper
            id="bankAccount.agencyNumber"
            type="tel"
            value={values.bankAccount.agencyNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={t('accountVerification.form.bankAccount.agency')}
            error={hasError('bankAccount.agencyNumber')}
            helperText={getErrorText('bankAccount.agencyNumber')}
          />
          {needsAgencyDigit() && (
            <InputWrapper
              id="bankAccount.agencyCheckNumber"
              type="text"
              value={values.bankAccount.agencyCheckNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              maxLength={1}
              placeholder={t('accountVerification.form.bankAccount.agencyDigit')}
              error={hasError('bankAccount.agencyCheckNumber')}
              helperText={getErrorText('bankAccount.agencyCheckNumber')}
            />
          )}
        </div>
      </div>
      <div className="flex flex-col space-y-1.5">
        <Label className="mb-1">{t('accountVerification.form.bankAccount.accountType')}</Label>
        <Select
          value={values.bankAccount.accountType.toString()}
          onValueChange={(value) => setFieldValue('bankAccount.accountType', parseInt(value) as BankAccountType)}
        >
          <SelectTrigger>
            <SelectValue placeholder={t('accountVerification.form.bankAccount.accountType')} />
          </SelectTrigger>
          <SelectContent className="z-[6000]">
            {Object.values(BankAccountType)
              .filter((x): x is number => typeof x === 'number')
              .map((type) => (
                <SelectItem key={type} value={type.toString()}>
                  {t(`accountVerification.form.bankAccount.types.${type}`)}
                </SelectItem>
              ))}
          </SelectContent>
        </Select>
        <div className="flex w-full space-x-3">
          <InputWrapper
            id="bankAccount.accountNumber"
            type="tel"
            value={values.bankAccount.accountNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={t('accountVerification.form.bankAccount.account')}
            error={hasError('bankAccount.accountNumber')}
            helperText={getErrorText('bankAccount.accountNumber')}
          />
          {shouldAcceptTextAtAccountCheckNumber() ? (
            <InputWrapper
              id="bankAccount.accountCheckNumber"
              type="text"
              value={values.bankAccount.accountCheckNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              maxLength={1}
              placeholder={t('accountVerification.form.bankAccount.accountDigit')}
              error={hasError('bankAccount.accountCheckNumber')}
              helperText={getErrorText('bankAccount.accountCheckNumber')}
            />
          ) : (
            <InputWrapper
              id="bankAccount.accountCheckNumber"
              type="tel"
              value={values.bankAccount.accountCheckNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              maxLength={shouldAcceptMoreDigitsAtAccountCheckNumber() ? 3 : 1}
              placeholder={t('accountVerification.form.bankAccount.accountDigit')}
              error={hasError('bankAccount.accountCheckNumber')}
              helperText={getErrorText('bankAccount.accountCheckNumber')}
            />
          )}
        </div>
      </div>
    </div>
  )
}
