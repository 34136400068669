import { HttpClient, HttpMethod, HttpResponse, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import isNil from 'lodash/isNil'
import { GetTagsInput } from '../domain/dtos/get-tags-input'
import { GetTagsOutput } from '../domain/dtos/get-tags-output'
import { GeneralError } from '../domain/errors/general'
import { Envs } from '../envs'

export class GetTags {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient<GetTagsOutput>,
  ) {}

  public async execute(input: GetTagsInput): Promise<GetTagsOutput> {
    const response = await this.httpClient.request({
      method: HttpMethod.GET,
      url: `${Envs.BFF_WEB_URL}/integrations/${input.integrationId}/provider/tags`,
    })

    if (response.statusCode === HttpStatusCode.OK) {
      return this.makeOutput(response)
    }

    throw new GeneralError()
  }

  private makeOutput(response: HttpResponse<GetTagsOutput>): GetTagsOutput {
    const data = response.data

    if (isNil(data)) {
      return []
    }

    return data
  }
}
