import { InvoiceType } from '../enums/invoice-type'

export const invoiceType = [
  {
    id: InvoiceType.SELL,
    label: 'Compra',
  },
  {
    id: InvoiceType.RENEWAL,
    label: 'Renovação',
  },
  {
    id: InvoiceType.UPGRADE,
    label: 'Upgrade de plano',
  },
]
