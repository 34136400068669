import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { RecoverPasswordInput } from '../domain/dtos/recover-password-input'
import { RecoverPasswordOutput } from '../domain/dtos/recover-password-output'
import { ErrorCode } from '../domain/enums/error-code'
import { GeneralError } from '../domain/errors/general'
import { InvalidCaptchaError } from '../domain/errors/invalid-captcha-error'
import { Envs } from '../envs'

export class PostRecoverPassword {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(input: RecoverPasswordInput): Promise<RecoverPasswordOutput> {
    if (!input?.captcha) {
      throw new InvalidCaptchaError()
    }

    const response = await this.httpClient.request({
      method: HttpMethod.POST,
      url: `${Envs.BFF_WEB_URL}/auth/recover-password`,
      body: input,
    })

    if (response.statusCode === HttpStatusCode.CREATED || response.statusCode === HttpStatusCode.OK) {
      return response.data
    }

    switch (response.data.code) {
      case ErrorCode.INVALID_CAPTCHA:
        throw new InvalidCaptchaError()
      default:
        throw new GeneralError('recoverPassword')
    }
  }
}
