import { Auth } from '@hub-la/fe-core-auth'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { GetTokenBySession } from './get-token-by-session'

export class AutoAuthenticateSession {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
    private readonly auth: Auth,
  ) {}

  public async execute(sessionToken: string | undefined) {
    if (!sessionToken) return

    const token = await new GetTokenBySession(this.httpClient).execute(sessionToken)

    if (!token) return

    return await this.auth.signInWithToken(token)
  }
}
