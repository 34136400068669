import { AuthLayout } from '../../../components/auth-layout'
import { MetaTags } from '../../../components/meta-tags'
import { PhoneSignInCard } from '../../../components/phone-sign-in-card'

const SignInSmsPage = () => {
  return (
    <AuthLayout>
      <MetaTags />
      <PhoneSignInCard />
    </AuthLayout>
  )
}

export { SignInSmsPage }
