import { container } from '@hub-la/fe-container'
import { EditProductProvider, InvitePage } from '@hub-la/fe-edit-product'

export const InviteInit = () => {
  return (
    <EditProductProvider container={container}>
      <InvitePage />
    </EditProductProvider>
  )
}
