import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { GetOffersOutput } from '../../domain/dtos/get-offers-output'
import { GetOffersResponse } from '../../domain/dtos/get-offers-response'
import { GeneralError } from '../../domain/errors/general'
import { Envs } from '../../envs'

export class GetOffers {
  public constructor(@inject(HttpClient) private readonly httpClient: HttpClient) {}

  public async execute(productId: string, signal?: AbortSignal): Promise<GetOffersOutput> {
    const response = await this.httpClient.request({
      method: HttpMethod.GET,
      url: `${Envs.BFF_PRODUCT_URL}/products/${productId}/offers`,
      params: { page: 1, pageSize: 100 },
      signal,
    })

    if (response.statusCode === HttpStatusCode.OK) {
      return this.makeResponse(response.data)
    }

    throw new GeneralError()
  }

  private makeResponse(response: GetOffersResponse): GetOffersOutput {
    return response.items ?? []
  }
}
