export class TimeString {
  private readonly value: string

  constructor(value: string) {
    this.value = value
  }

  public toSeconds(): number {
    const [hours, minutes, seconds] = this.value.split(':').map((time) => parseInt(time))

    return hours * 3600 + minutes * 60 + seconds
  }

  public parseSeconds(seconds?: number | null): string {
    if (!seconds) {
      return '00:00:00'
    }

    const hours = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)
    const remainingSeconds = seconds % 60

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds
      .toString()
      .padStart(2, '0')}`
  }

  public isValid = (): boolean => {
    const timeRegex = /^([01]?[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/

    return timeRegex.test(this.value)
  }
}
