import { formatCNPJ, formatCPF, isValidCNPJ, isValidCPF, onlyNumbers } from '@brazilian-utils/brazilian-utils'

export class Document {
  private constructor(private readonly value: string) {}

  public static build(value: string): Document {
    return new Document(value)
  }

  public isValid(): boolean {
    if (onlyNumbers(this.value).length === 14) {
      return isValidCNPJ(this.value)
    } else {
      return isValidCPF(this.value)
    }
  }

  public getValue(): string {
    if (onlyNumbers(this.value).length === 14) {
      if (this.isValid()) {
        return formatCNPJ(this.value)
      }
    } else {
      if (this.isValid()) {
        return formatCPF(this.value)
      }
    }
    return this.value
  }
}
