import { container } from '@hub-la/fe-container'
import { TelegramVerificationProvider, VerificationIntroPage } from '@hub-la/fe-telegram-verification'
import { withRoleplayRedirect } from '../components/roleplay-redirect'

export const TelegramVerificationIntroInit = withRoleplayRedirect(() => {
  return (
    <TelegramVerificationProvider container={container}>
      <VerificationIntroPage />
    </TelegramVerificationProvider>
  )
})
