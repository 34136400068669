export enum QueryKey {
  postCreateCollaborators = 'postCreateCollaborators',
  putCollaborator = 'putCollaborator',
  getMyAccess = 'getMyAccess',
  getMyCollaborator = 'getMyCollaborator',
  getMyCollaborators = 'getMyCollaborators',
  postSignInRoleplay = 'postSignInRoleplay',
  postSignOutRoleplay = 'postSignOutRoleplay',
  getRoleplayReference = 'getRoleplayReference',
  hasAccountsAccess = 'hasAccountsAccess',
}
