import { FormSteps, Values } from '../presentation/pages/account-verification-modal'
import { DocumentType } from '../domain/enums/bank-account'
import { boolean, number, object, string } from 'yup'
import { isCNPJValid } from '../presentation/components/cnpj-validation'
import { validateCPF } from '../presentation/components/cpf-validation'

const companyDataSchema = object({
  identity: object({
    document: object({
      type: number().required().oneOf([DocumentType.CPF, DocumentType.CNPJ]),
      value: string().required(),
    }),
    fullName: string().when('document.type', {
      is: DocumentType.CNPJ,
      then: (schema) => schema.required('Nome da empresa é obrigatório para CNPJ'),
      otherwise: (schema) => schema.optional(),
    }),
  }).test('cnpj-validation', 'CNPJ inválido', function (value) {
    if (value?.document?.type === DocumentType.CNPJ) {
      const cnpj = value.document.value ?? ''
      if (!isCNPJValid(cnpj)) return false
    }

    return true
  }),
})

const withdrawInfoSchema = object({
  isPixKey: boolean(),
  bankAccount: object().when('isPixKey', {
    is: false,
    then: object({
      bankNumber: string().required(),
      accountType: number().required(),
      agencyNumber: string().required(),
      accountNumber: string().required(),
      accountCheckNumber: string().required(),
    }),
  }),
})

const personalInfoSchema = object({
  identity: object({
    fullName: string().required(),
    email: string().email().required(),
    phone: string().required(),
    document: object({
      type: number().required().oneOf([DocumentType.CPF, DocumentType.CNPJ]),
      value: string().when('type', {
        is: DocumentType.CPF,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.notRequired(),
      }),
    }),
    legalRepresentative: object({
      fullName: string(),
      document: object({
        type: number(),
        value: string().test('cpf', 'CPF do representante legal inválido', validateCPF),
        countryCode: string(),
      }),
    }),
    billingAddress: object({
      postalCode: string().required(),
      neighborhood: string().required(),
      street: string().required(),
      number: string().required(),
      city: string().required(),
      state: string().required(),
    }),
  }),
})

const publicInfoSchema = object({
  businessConfiguration: object({
    creditCard: object({
      softDescriptor: string().required(),
    }),
  }),
})

const verifyIdentitySchema = object({
  sdkToken: string().required(),
})

export class IsStepCompleted {
  private readonly values?: Values
  private readonly schemas = {
    [FormSteps.COMPANY_DATA]: companyDataSchema,
    [FormSteps.WITHDRAW_INFO]: withdrawInfoSchema,
    [FormSteps.PERSONAL_INFO]: personalInfoSchema,
    [FormSteps.PUBLIC_INFO]: publicInfoSchema,
    [FormSteps.VERIFY_IDENTITY]: verifyIdentitySchema,
  }

  constructor(values?: Values) {
    this.values = values
  }

  public execute(step: FormSteps): boolean {
    try {
      const schema = this.schemas[step]
      if (!schema) return false
      return schema.isValidSync(this.values, {
        abortEarly: true,
        strict: false,
      })
    } catch (error) {
      return false
    }
  }
}
