import * as Yup from 'yup'
import { Phone } from '../../domain/vos/phone'

export const signUpSchema = Yup.object().shape({
  email: Yup.string().email('E-mail inválido').required('Campo obrigatório'),
  phoneNumber: Yup.string()
    .required('Campo obrigatório')
    .test({
      message: 'Digite um número de telefone válido',
      test: function (value) {
        return Phone.build(String(value)).isValid()
      },
    }),
  password: Yup.string()
    .required('Campo obrigatório')
    .min(8, 'A senha deve ter no mínimo 8 caracteres')
    .matches(/[a-z]/, 'A senha deve ter no mínimo uma letra minúscula')
    .matches(/[A-Z]/, 'A senha deve ter no mínimo uma letra maiúscula')
    .matches(/[0-9]/, 'A senha deve ter no mínimo um número'),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], 'As senhas devem ser idênticas.')
    .required('Campo obrigatório'),
})
