import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useQuery } from '@tanstack/react-query'
import { Product } from '../../domain/dtos/product'
import { QueryKey } from '../../domain/enums/query-key'
import { GetAvailableProducts } from '../../usecases/get-available-products'

export const useGetAvailableProducts = () => {
  const container = useContainer()

  return useQuery<Product[]>(
    [QueryKey.getAvailableProducts],
    () => new GetAvailableProducts(container.get(HttpClient)).execute(),
    {
      retry: 3,
    },
  )
}
