import {
  Alert,
  AlertDescription,
  AlertTitle,
  Button,
  Calendar,
  Checkbox,
  cn,
  Input,
  Label,
  Popover,
  PopoverContent,
  PopoverTrigger,
  RadioGroup,
  RadioGroupItem,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from '@hub-la/shadcn'
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { useFormik } from 'formik'

import { CalendarIcon, LightbulbIcon } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { useGetCohorts } from '../../hooks/use-get-cohorts'
import { useSaveSection } from '../../hooks/use-save-section'
import { MultiSelect } from '../multi-select'

export type Values = {
  name: string
  releaseAfterDays?: number | null
  releaseAt?: Date | null
  cohortIds: string[]
}

type Props = {
  isOpen: boolean
  onClose: () => void
}

const DEFAULT_VALUES = [2, 4, 7]
const CUSTOM_VALUE = -1

export const CreateSectionModal: React.FC<Props> = (props) => {
  const { isOpen, onClose } = props
  const [scheduleRelease, setScheduleRelease] = useState<boolean>(false)
  const [releaseType, setReleaseType] = useState<'releaseAfterDays' | 'releaseAtDate'>('releaseAfterDays')
  const [customValue, setCustomValue] = useState<number | string>()
  const { productId } = useParams<{ productId: string }>()
  const { mutateAsync: createSection, isLoading } = useSaveSection()
  const { data: cohorts = [], isSuccess } = useGetCohorts(productId)
  const { t } = useTranslation()
  const [releaseTime, setReleaseTime] = useState<string>('00:00')

  const { values, isValid, submitForm, handleChange, setFieldValue, resetForm, errors } = useFormik<Values>({
    initialValues: {
      name: '',
      releaseAfterDays: 7,
      cohortIds: [],
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(),
      cohortIds: Yup.array().min(1).required(),
    }),
    validateOnChange: true,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: async (values) => {
      let finalReleaseDate: Date | null = null
      if (scheduleRelease && releaseType === 'releaseAtDate' && values.releaseAt) {
        const [hours, minutes] = releaseTime.split(':').map(Number)
        finalReleaseDate = new Date(values.releaseAt)
        finalReleaseDate.setHours(hours, minutes)
      }

      await createSection({
        payload: {
          name: values.name,
          cohortIds: values.cohortIds,
          releaseAfterDays:
            scheduleRelease && releaseType === 'releaseAfterDays'
              ? values.releaseAfterDays === CUSTOM_VALUE
                ? Number(customValue)
                : values.releaseAfterDays
              : null,
          releaseAt: finalReleaseDate,
          productId,
        },
      }).then(() => {
        resetForm()
        onClose()
      })
    },
  })

  const handleScheduleReleaseChange = (checked: boolean) => {
    setScheduleRelease(checked)
  }

  const onCloseModal = () => {
    resetForm()
    setReleaseTime('00:00')
    onClose()
  }

  const handleCustomValue = (event) => {
    const inputValue = event.target.value
    const sanitizedValue = inputValue.replace(/[^\d]/g, '')
    const numericValue = parseInt(sanitizedValue, 10)
    if (sanitizedValue === '') {
      setCustomValue('')
      return
    }
    if (!isNaN(numericValue)) {
      const clampedValue = Math.min(Math.max(numericValue, 1), 99)
      setCustomValue(clampedValue)
      return
    }
  }

  useEffect(() => {
    const defaultCohort = cohorts.find((cohort) => cohort.isDefault)
    if (defaultCohort) {
      setFieldValue('cohortIds', [defaultCohort.id])
    }
  }, [isSuccess, isOpen, setFieldValue, cohorts])

  const cohortOptions = cohorts.map((cohort) => ({
    id: cohort.id,
    label: cohort.name,
    type: cohort.isDefault ? 'default' : 'regular',
  }))

  return (
    <Sheet open={isOpen} onOpenChange={onCloseModal}>
      <SheetContent className="sm:max-w-lg flex flex-col gap-6 overflow-y-auto">
        <SheetHeader>
          <SheetTitle className="flex gap-1.5 items-center">{t('createSection.title')}</SheetTitle>
        </SheetHeader>

        <div className="flex flex-col gap-8">
          <div className="flex flex-col gap-4">
            <Input
              id="name"
              name="name"
              type="text"
              placeholder={t('createSection.name')}
              value={values.name}
              onChange={handleChange}
            />

            <MultiSelect
              options={cohortOptions}
              value={values.cohortIds}
              onChange={(newValue) => setFieldValue('cohortIds', newValue)}
              label={t('createSection.cohorts')}
              error={Boolean(errors?.cohortIds)}
              helperText={errors?.cohortIds ? t('createSection.cohortError') : undefined}
              disabled={isLoading}
              hasSelectAll={true}
            />
          </div>
          <div className="flex flex-col gap-4">
            <h4 className="text-lg font-semibold">{t('createSection.options')}</h4>

            <div className="flex items-center space-x-2">
              <Checkbox
                id="schedule-release"
                checked={scheduleRelease}
                onCheckedChange={handleScheduleReleaseChange}
                disabled={isLoading}
              />
              <Label htmlFor="schedule-release">{t('createSection.scheduleRelease')}</Label>
            </div>
          </div>
        </div>

        {scheduleRelease && (
          <div className="flex flex-col gap-4 pl-6">
            <Alert>
              <LightbulbIcon className="h-4 w-4 mt-1" />
              <AlertTitle className="text-sm">Recomendação</AlertTitle>
              <AlertDescription>{t('createSection.disclaimer')}</AlertDescription>
            </Alert>
            <div className="flex flex-col space-y-1.5">
              <Label htmlFor="releaseDate">{t('editReleaseDays.model')}</Label>
              <Select
                onValueChange={(value) => setReleaseType(value as 'releaseAfterDays' | 'releaseAtDate')}
                value={releaseType}
              >
                <SelectTrigger className="w-52">
                  <SelectValue placeholder={t('createSection.releaseType')} />
                </SelectTrigger>

                <SelectContent className="z-[61]">
                  <SelectItem value="releaseAfterDays">{t('createSection.releaseAfterDays')}</SelectItem>
                  <SelectItem value="releaseAtDate">{t('createSection.releaseAtDate')}</SelectItem>
                </SelectContent>
              </Select>
            </div>

            {releaseType === 'releaseAtDate' ? (
              <div className="grid gap-1.5">
                <Label htmlFor="releaseDate">{t('editReleaseDays.onSpecificDate')}</Label>
                <Popover>
                  <PopoverTrigger asChild>
                    <Button
                      variant={'outline'}
                      className={cn(
                        'w-full justify-start text-left font-normal',
                        !values.releaseAt && 'text-muted-foreground',
                      )}
                    >
                      <CalendarIcon className="mr-2 h-4 w-4" />
                      {values.releaseAt ? (
                        format(values.releaseAt as Date, 'PPP', { locale: ptBR })
                      ) : (
                        <span>Selecione uma data</span>
                      )}
                    </Button>
                  </PopoverTrigger>

                  <PopoverContent className="z-10002 w-auto p-0">
                    <Calendar
                      mode="single"
                      selected={values.releaseAt || undefined}
                      onSelect={(date: Date | undefined) => setFieldValue('releaseAt', date || null)}
                    />
                  </PopoverContent>
                </Popover>
                <div>
                  <Label htmlFor="releaseTime">Horário</Label>
                  <input
                    type="time"
                    id="releaseTime"
                    value={releaseTime}
                    onChange={(e) => setReleaseTime(e.target.value)}
                    className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                  />
                </div>
              </div>
            ) : (
              <>
                <RadioGroup
                  value={String(values.releaseAfterDays)}
                  onValueChange={(value) => setFieldValue('releaseAfterDays', parseInt(value, 10))}
                >
                  {DEFAULT_VALUES.map((value) => (
                    <div key={value} className="flex items-center space-x-2">
                      <RadioGroupItem value={String(value)} id={`release-${value}`} />

                      <Label htmlFor={`release-${value}`}>{`${value} dias`}</Label>
                    </div>
                  ))}
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value={String(CUSTOM_VALUE)} id="release-custom" />

                    <Label htmlFor="release-custom">{t('createSection.customValue')}</Label>
                  </div>
                </RadioGroup>

                {values.releaseAfterDays === CUSTOM_VALUE && (
                  <Input
                    placeholder={t('createSection.customValueLabel')}
                    value={customValue}
                    onChange={handleCustomValue}
                    type="text"
                    inputMode="numeric"
                    min={1}
                    max={99}
                    pattern="[1-9][0-9]?"
                  />
                )}
              </>
            )}
          </div>
        )}

        <div className="flex flex-col gap-3 pt-3">
          <Button onClick={submitForm} disabled={!isValid || isLoading}>
            {t('createSection.confirm')}
          </Button>

          <Button variant="outline" onClick={onCloseModal} disabled={isLoading}>
            {t('createSection.cancel')}
          </Button>
        </div>
      </SheetContent>
    </Sheet>
  )
}
