export enum AssetApplicationSubType {
  // .pdf
  'pdf' = 'pdf',
  // .doc
  'msword' = 'msword',
  // .docx
  'vnd.openxmlformats-officedocument.wordprocessingml.document' = 'vnd.openxmlformats-officedocument.wordprocessingml.document',
  // .xls
  'vnd.ms-excel' = 'vnd.ms-excel',
  // .xlsm
  'vnd.ms-excel.sheet.macroenabled.12' = 'vnd.ms-excel.sheet.macroenabled.12',
  // .xlsx
  'vnd.openxmlformats-officedocument.spreadsheetml.sheet' = 'vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  // .ppt
  'vnd.ms-powerpoint' = 'vnd.ms-powerpoint',
  // .pptx
  'vnd.openxmlformats-officedocument.presentationml.presentation' = 'vnd.openxmlformats-officedocument.presentationml.presentation',
  // .pages
  'vnd.apple.pages' = 'vnd.apple.pages',
  // .gdoc
  'vnd.google-apps.document' = 'vnd.google-apps.document',
  // .gslides
  'vnd.google-apps.presentation' = 'vnd.google-apps.presentation',
  // .gsheets
  'vnd.google-apps.spreadsheet' = 'vnd.google-apps.spreadsheet',
  // .zip
  'x-zip-compressed' = 'x-zip-compressed',
  // .octet-stream
  'octet-stream' = 'octet-stream',
  // .epub+zip
  'epub+zip' = 'epub+zip',
  // .txt
  'txt' = 'txt',
  // .docx
  'docx' = 'docx',
  // .xltx
  'xltx' = 'xltx',
  // .x-msdownload
  'x-msdownload' = 'x-msdownload',
  // .vnd.android.package-archive
  'vnd.android.package-archive' = 'vnd.android.package-archive',
  // .rar
  'rar' = 'rar',
  'vnd.rar' = 'vnd.rar',
  'x-rar-compressed' = 'x-rar-compressed',
  // .zip
  'zip' = 'zip',
}

export enum AssetImageSubType {
  // .png
  'png' = 'png',
  // .gif
  'gif' = 'gif',
  // .jpeg
  'jpeg' = 'jpeg',
  // .jpg
  'jpg' = 'jpg',
  // .webp
  'webp' = 'webp',
  // .bmp
  'bmp' = 'bmp',
  // .svg
  'svg+xml' = 'svg+xml',
  // .psd
  'vnd.adobe.photoshop' = 'vnd.adobe.photoshop',
  // .psd
  'psd' = 'psd',
  // .dng
  'x-adobe-dng' = 'x-adobe-dng',
  'dng' = 'dng',
}

export enum AssetVideoSubType {
  '*' = '*',
  mp4 = 'mp4',
  avi = 'avi',
  webm = 'webm',
  ogg = 'ogg',
  quicktime = 'quicktime',
  mpeg = 'mpeg',
  'x-matroska' = 'x-matroska',
  mkv = 'mkv',
}

export enum AudioSubType {
  // .mp3
  mp3 = 'mp3',
  // .wav
  wav = 'wav',
  // .ogg
  ogg = 'ogg',
  // .mpeg / .mp3
  mpeg = 'mpeg',
  // .mid / .mid
  mid = 'mid',
  // .midi / .midi
  'x-midi' = 'x-midi',
  // .opus
  opus = 'opus',
  // .weba
  weba = 'webm',
  // .3gp
  '3gpp' = '3gpp',
  // .3g2
  '3gpp2' = '3gpp2',
  // .aac
  aac = 'aac',
}

/**
 * File MIME subtype, as defined by {@link https://www.iana.org/assignments/media-types/media-types.xhtml IANA}
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types Common MIME types}
 * @see {@link https://developers.google.com/drive/api/v3/mime-types Google MIME Types}
 */
export type AssetSubType = AssetApplicationSubType | AssetImageSubType | AssetVideoSubType | AudioSubType
