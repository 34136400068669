import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { PostTransformFreeMembersInput } from '../../../domain/dtos/post-transform-free-members-input'
import { StatusTransformFreeMemberOutput } from '../../../domain/dtos/post-transform-free-members-output'
import { QueryKey } from '../../../domain/enums/query-key'
import { PostTransformFreeMembers } from '../../../usecases/members/post-transform-free-members'
import { GetActiveMembersOutput } from '../../../domain/dtos/get-active-members-output'
import { GetDeactivatesMembersOutput } from '../../../domain/dtos/get-deactivates-members-output'

export const usePostTransformFreeMembers = ({
  onSuccess,
}: {
  onSuccess: (data: StatusTransformFreeMemberOutput[]) => void
}) => {
  const container = useContainer()
  const queryClient = useQueryClient()

  return useMutation(
    [QueryKey.postTransformFreeMembers],
    (input: PostTransformFreeMembersInput) => new PostTransformFreeMembers(container.get(HttpClient)).execute(input),
    {
      retry: false,
      onSuccess: (responseData, input) => {
        const [activeMembersKey, activeMembersCachedData] = queryClient.getQueriesData<GetActiveMembersOutput>([
          QueryKey.getActiveMembers,
        ])[0]

        if (activeMembersCachedData) {
          queryClient.setQueryData(activeMembersKey, {
            ...activeMembersCachedData,
            total: activeMembersCachedData.total + (input?.selectedItems?.length || 0),
            pageSize: activeMembersCachedData.pageSize + (input?.selectedItems?.length || 0),
            items: [...activeMembersCachedData.items, ...(input?.selectedItems || [])],
          })
        }

        const [deactiveMembersKey, deactiveMembersCachedData] = queryClient.getQueriesData<GetDeactivatesMembersOutput>(
          [QueryKey.getDeactivatesMembers],
        )[0]

        if (deactiveMembersCachedData) {
          const filteredData = deactiveMembersCachedData.items.filter(
            (r) => !input?.selectedItems?.some((t) => r.id === t.id),
          )
          const changedElements = input?.selectedItems?.length || 0
          queryClient.setQueryData(deactiveMembersKey, {
            ...deactiveMembersCachedData,
            total: deactiveMembersCachedData.total - changedElements,
            pageSize: deactiveMembersCachedData.pageSize - changedElements,
            items: filteredData,
          })
        }

        onSuccess(responseData)
      },
    },
  )
}
