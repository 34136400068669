import { useToast } from '@hub-la/shadcn'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { OfferDefaultValuesMapper } from '../../../domain/mappers/make-offer-default-values'
import { useGetOffer } from '../../hooks/use-get-offer'
import { usePutOffer } from '../../hooks/use-put-offer'
import { OfferSchemaValidationType } from '../../validations/offer-validation'
import { OfferForm } from '../offer'

const EditOfferPage = () => {
  const history = useHistory()
  const { productId, offerId } = useParams<{ productId: string; offerId: string }>()
  const { toast } = useToast()
  const { t } = useTranslation()

  const { mutate, isLoading: putOfferLoading } = usePutOffer({
    id: offerId,
    onSuccess: () => history.push(`/edit/${productId}/offers`),
    onError: (error) => {
      toast({
        title: t('backendErrors.' + error.message),
        variant: 'destructive',
      })
    },
  })

  const { data, isLoading } = useGetOffer({
    productId,
    offerId,
  })
  const defaultValues: OfferSchemaValidationType = useMemo(
    () => OfferDefaultValuesMapper(productId, data),
    [productId, data],
  )

  return (
    <OfferForm
      productId={productId}
      onSubmit={(data) => mutate(data)}
      defaultValues={defaultValues}
      offerId={offerId}
      isLoading={isLoading}
      isSubmitting={putOfferLoading}
    />
  )
}

export { EditOfferPage }
