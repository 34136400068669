import { ConfirmExportModal } from './confirm-export-modal'
import { SelectStatus } from './select-status'

export const Filters = ({ setFilters, filters }) => {
  return (
    <div className="flex gap-3 md:gap-2 flex-col sm:flex-row justify-between items-start md:items-center">
      <SelectStatus filters={filters} setFilters={setFilters} />

      <ConfirmExportModal />
    </div>
  )
}
