import { useIsMobile } from '@hub-la/shadcn'
import { useEffect } from 'react'
import { PostDetailProps } from '../../../domain/dtos/post-detail-props'
import { GetReleaseTime } from '../../../usecases/get-release-time'
import { AdminActions } from '../../components/admin-actions'
import { BackButton } from '../../components/back-button'
import { Error } from '../../components/error'
import { LoadingState } from '../../components/loading-state'
import { Overlay } from '../../components/overlay'
import { Paywall } from '../../components/paywall'
import { PostContent } from '../../components/post-content'
import { UserNavigation } from '../../components/user-navigation'
import useCurrentUser from '../../hooks/use-current-user'
import { useGetPost } from '../../hooks/use-get-post'
import { useGetSections } from '../../hooks/use-get-sections'

export const PostDetail = ({ postId }: PostDetailProps) => {
  const isMobile = useIsMobile()
  const currentUser = useCurrentUser()

  const { data: post, refetch: refetchPost, isError, error, isFetching } = useGetPost({ postId })
  const { refetch: refetchSections } = useGetSections({ productId: post?.product?.id ?? '' })

  const isAdmin = post?.roleType === 'creator'
  const canAccess = post?.roleType !== 'not-member'

  const onPublish = () => {
    refetchPost()
    refetchSections()
  }

  /**
   * Refetch post and sections because on sign-in with cookie the token updated async.
   */
  useEffect(() => {
    if (canAccess || !currentUser?.id) return
    refetchPost()
    refetchSections()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canAccess, currentUser?.id])

  if (isError) {
    return <Error errorKey={error?.message} />
  }

  return (
    <div className="bg-zinc-950 relative max-w-screen-2xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="mx-auto max-w-[120rem]">
        {!isMobile && <Overlay image={post?.product?.cover} />}

        {post ? (
          <div className="flex flex-col">
            <div className="w-full py-2 px-4 lg:p-6 flex gap-6 items-center justify-between">
              <BackButton groupId={post?.currentOfferId} />

              {isAdmin && (
                <AdminActions
                  post={post}
                  productId={post?.product?.id}
                  sectionId={post?.sectionId}
                  onPublish={onPublish}
                />
              )}

              <UserNavigation />
            </div>

            {!canAccess && <Paywall productName={post?.product.name} groupId={post?.currentOfferId} />}

            <PostContent
              post={post}
              timeToRelease={post?.releaseAt ? new GetReleaseTime().execute(new Date(post?.releaseAt)) : undefined}
              canAccess={canAccess}
              isFetching={isFetching}
            />
          </div>
        ) : (
          <LoadingState />
        )}
      </div>
    </div>
  )
}
