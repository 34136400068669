import { HttpClient } from '@hub-la/fe-core-http-client'
import { useQuery } from '@tanstack/react-query'
import { useContainer } from '../../container'
import { GetProfile } from '../../usecases/get-profile'

export const useGetProfile = () => {
  const container = useContainer()

  return useQuery(['profile'], () => new GetProfile(container.get(HttpClient)).execute(), {
    retry: false,
    refetchOnWindowFocus: false,
    cacheTime: 10,
  })
}
