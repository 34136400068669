import { Button, useIsMobile } from '@hub-la/shadcn'
import { X } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { Member } from '../../../../domain/dtos/member'

interface MembersSelectedsActionsBarProps {
  selectedItems: Member[]
  setSelectedItems: (items: Member[]) => void
  setOpenChangeMembersCohortsModal: (open: boolean) => void
}

export const MembersSelectedsBarActions = ({
  selectedItems,
  setSelectedItems,
  setOpenChangeMembersCohortsModal,
}: MembersSelectedsActionsBarProps) => {
  const { t } = useTranslation()
  const isMobile = useIsMobile()

  if (selectedItems.length === 0) return null

  return (
    <div className="fixed bottom-0 left-1/2 z-10 p-4 w-full md:w-auto transform -translate-x-1/2 bg-background border border-border rounded-t-lg shadow-lg">
      <div className="flex flex-col md:flex-row gap-4 items-center">
        <div className="w-full md:w-auto flex justify-between">
          <p className="text-foreground">
            {t('tabs.participants.changeMembersCohortsBar.selecteds', { total: selectedItems.length })}
          </p>

          {isMobile && <X className="cursor-pointer" onClick={() => setSelectedItems([])} />}
        </div>

        <div className="flex flex-col md:flex-row gap-2">
          <Button variant="outline" size="sm" onClick={() => setOpenChangeMembersCohortsModal(true)}>
            {t('tabs.participants.changeMembersCohortsBar.changeCohorts', { count: selectedItems.length })}
          </Button>
        </div>

        {!isMobile && <X className="cursor-pointer" onClick={() => setSelectedItems([])} />}
      </div>
    </div>
  )
}
