import { Input } from '@hub-la/shadcn'
import { forwardRef } from 'react'

interface PhoneFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  disabled?: boolean
}

export const PhoneField = forwardRef<HTMLInputElement, PhoneFieldProps>((props, ref) => {
  const { disabled = false, ...rest } = props

  return (
    <Input
      {...rest}
      ref={ref}
      type="tel"
      name="phoneNumber"
      disabled={disabled}
      data-testid="phoneNumber"
      className="w-full"
    />
  )
})

PhoneField.displayName = 'PhoneField'
