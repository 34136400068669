import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AppHeader } from '../../components/app-header'
import { useGetOffers } from '../../hooks/use-get-offers'
import { InvoicesTable } from './components/invoices-table'
import { InvoicesTableFilters } from './components/invoices-table-filters'

export const UserInvoicesPage = () => {
  const { data: offers = [] } = useGetOffers()
  const [filters, setFilters] = useState<{ offerIds: string[] }>({ offerIds: ['all'] })

  const { t } = useTranslation()

  return (
    <div className="flex flex-col container px-4 md:px-24">
      <AppHeader />

      <div className="w-full flex flex-col gap-4 pt-0 md:pt-12 pb-12">
        <h1 className="text-xl sm:text-2xl lg:text-3xl font-semibold">{t('invoices.title')}</h1>

        <InvoicesTableFilters offers={offers} filters={filters} setFilters={setFilters} />

        <InvoicesTable offers={offers} filters={filters} />
      </div>
    </div>
  )
}
