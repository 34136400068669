import { Envs } from '../envs'
import { TokenizerInterface, TokenizerToken } from './tokenizer'

interface CustomWindow {
  Iugu: {
    CreditCard: (number, month, year, firstName, lastName, cvv) => unknown
    createPaymentToken: (CreditCard: unknown, callable) => void
    setAccountID: (accountId: string) => void
    setTestMode: (testMode: boolean) => void
    setup: () => void
  }
}

export class IuguTokenizer implements TokenizerInterface {
  private loadIuguScript() {
    return new Promise((resolve, reject) => {
      const iugu = (window as unknown as CustomWindow)?.Iugu
      if (typeof iugu !== 'undefined') return resolve(true)
      const script = document.createElement('script')
      script.src = 'https://js.iugu.com/v2'
      script.type = 'text/javascript'
      script.onload = () => resolve(true)
      script.onerror = () => reject(new Error('Failed to load Iugu script'))
      document.head.appendChild(script)
    })
  }

  public async tokenize({
    number,
    expiration,
    holder,
    cvv,
  }: {
    number: string
    expiration: string
    holder: string
    cvv: string
  }): Promise<TokenizerToken> {
    await this.loadIuguScript()

    const [month, year] = expiration.split('/')
    const firstName = holder.split(' ').slice(0, 1).join(' ')
    const lastName = holder.split(' ').slice(-1).join(' ')

    const iugu = (window as unknown as CustomWindow)?.Iugu

    iugu.setAccountID(Envs.IUGU_ACCOUNT_ID ?? '')
    iugu.setTestMode(Envs.IUGU_TEST_MODE === 'true')
    iugu.setup()

    return new Promise((resolve, reject) =>
      iugu.createPaymentToken(iugu.CreditCard(number, month, year, firstName, lastName, cvv), (response) => {
        if (response.errors) {
          return reject(JSON.stringify(response.errors))
        }

        resolve({ tokenId: response.id })
      }),
    )
  }
}
