import { Badge, useIsMobile } from '@hub-la/shadcn'
import { motion } from 'framer-motion'
import { Calendar } from 'lucide-react'
import { memo } from 'react'
import { useHistory } from 'react-router-dom'
import { FormatDate } from '../../../../domain/vos/format-date'
import { getGroupUrl } from '../../../../utils/sessions-utils'
import { textEllipsis } from '../../../../utils/text-ellipsis'
import avatar from '../../../assets/placeholder-image.svg'

export interface GroupProps {
  id: string
  name: string
  picture?: string
  type: 'digital' | 'event'
  eventData?: {
    startDate: string
    endDate: string
  }
}

export const Group = memo(({ id, name, picture, type, eventData }: GroupProps) => {
  const startDate = eventData?.startDate ? new FormatDate(eventData.startDate).getValue() : null
  const endDate = eventData?.endDate ? new FormatDate(eventData.endDate).getValue() : null
  const history = useHistory()
  const isMobile = useIsMobile()

  const redirectToGroupFeed = () => {
    if (type === 'event') {
      history.push(`/user_events/${id}`)
    } else {
      window.location.assign(getGroupUrl(id))
    }
  }

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
    hover: { scale: 1.05, transition: { duration: 0.3 } },
  }

  return (
    <motion.div
      className="p-1 cursor-pointer relative "
      onClick={redirectToGroupFeed}
      variants={itemVariants}
      whileHover="hover"
    >
      {startDate && endDate && (
        <Badge className="absolute top-2 left-2 z-10 shadow-sm" variant="default">
          <Calendar className="w-3 h-3 mr-1" />
          {`${startDate}${startDate === endDate ? '' : ' - ' + endDate}`}
        </Badge>
      )}
      <motion.img
        src={picture ?? avatar}
        alt={name}
        className="w-full h-auto aspect-[218/142] rounded-2xl md:rounded-3xl object-cover shadow-lg "
        whileHover={{ scale: 1.07 }}
        transition={{ duration: 0.3 }}
      />
      <motion.p
        className="mt-2 text-center text-xs md:text-sm"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.2 }}
      >
        {isMobile ? textEllipsis(name, 20) : name}
      </motion.p>
    </motion.div>
  )
})
