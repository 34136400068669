import { AlertTriangle, Check, HelpCircle, X } from 'lucide-react'
import { InvoiceStatus } from '../../domain/enums/invoice-status'

type SmartInstallmentBadgeProps = {
  status: InvoiceStatus
  installment: number
  installments: number
}

const getStatusInfo = (status: InvoiceStatus) => {
  switch (status) {
    case InvoiceStatus.PAID:
    case InvoiceStatus.DISPUTED:
      return { icon: Check, bgColor: 'bg-green-600', textColor: 'text-green-600' }
    case InvoiceStatus.UNPAID:
    case InvoiceStatus.DRAFT:
      return { icon: HelpCircle, bgColor: 'bg-blue-600', textColor: 'text-blue-600' }
    case InvoiceStatus.EXPIRED:
    case InvoiceStatus.CANCELED:
      return { icon: X, bgColor: 'bg-red-600', textColor: 'text-red-600' }
    case InvoiceStatus.RETURNED:
    case InvoiceStatus.CHARGEBACK:
      return { icon: AlertTriangle, bgColor: 'bg-amber-600', textColor: 'text-amber-600' }
    default:
      return { icon: Check, bgColor: 'bg-green-600', textColor: 'text-green-600' }
  }
}

export const SmartInstallmentBadge = ({ status, installment, installments }: SmartInstallmentBadgeProps) => {
  const { icon: Icon, bgColor, textColor } = getStatusInfo(status)

  return (
    <div className={`inline-flex items-center rounded-full border ${textColor} px-2.5 py-0.5 text-xs font-semibold`}>
      <div className={`${bgColor} rounded-full w-4 h-4 flex justify-center items-center mr-1.5`}>
        <Icon className="text-primary w-3 h-3" strokeWidth={3} />
      </div>
      {installment}/{installments}
    </div>
  )
}
