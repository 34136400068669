import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation } from '@tanstack/react-query'
import { DisableIntegrationInput } from '../../domain/dtos/disable-integration-input'
import { DisableIntegrationById } from '../../usecases/disable-integration-by-id'

export const useDisableIntegrationById = () => {
  const container = useContainer()

  return useMutation(
    async (params: DisableIntegrationInput) =>
      new DisableIntegrationById(container.get(HttpClient)).execute(params.integrationId),
    {
      retry: false,
    },
  )
}
