import { HttpClient } from '@hub-la/fe-core-http-client'
import { useQuery } from '@tanstack/react-query'
import { useContainer } from '../../container'
import { GetTemporaryBusiness } from '../../usecases/get-temporary-business'

export const useGetTemporaryBusiness = () => {
  const container = useContainer()

  return useQuery(['getTemporaryBusiness'], () => new GetTemporaryBusiness(container.get(HttpClient)).execute(), {
    retry: false,
    refetchOnWindowFocus: false,
  })
}
