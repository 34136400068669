import { useGetIdentity } from '@hub-la/fe-get-user'
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  Separator,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@hub-la/shadcn'
import { Barcode, CreditCard, HelpCircle, QrCode } from 'lucide-react'
import { Trans, useTranslation } from 'react-i18next'
import { Money } from '../../../domain/vos/money'

const InfoRow = ({
  label,
  value,
  tooltip,
}: {
  label: string
  value: string | React.ReactNode | null | undefined
  tooltip?: string
}) => (
  <div className="items-start mb-2">
    <div className="w-auto pr-2 flex items-center">
      <p className="text-sm text-muted-foreground">{label}</p>
      {tooltip && (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger>
              <HelpCircle className="w-4 h-4 ml-1 text-muted-foreground" />
            </TooltipTrigger>
            <TooltipContent>
              <p>{tooltip}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      )}
    </div>
    <div>
      <p className="text-sm">{value || 'Não disponível'}</p>
    </div>
  </div>
)

export const RatesAndTimelines = () => {
  const { data, isLoading } = useGetIdentity()
  const { t } = useTranslation()

  return (
    <div className="max-w-2xl py-6">
      <Card className="w-full">
        <CardHeader>
          <CardTitle className="text-lg">{t('ratesAndTimelines.title')}</CardTitle>
          <CardDescription className="text-base">{t('ratesAndTimelines.subtitle')}</CardDescription>
        </CardHeader>
        <Separator />
        <CardContent>
          <div className="grid grid-cols-1 gap-8 my-6">
            <InfoRow
              label={t('ratesAndTimelines.rates')}
              value={
                isLoading
                  ? undefined
                  : data
                  ? t('ratesAndTimelines.ratesText', {
                      commission: data?.businessConfiguration.commissions?.commissionPercent.toFixed(2),
                      transactionFee: Money.build(
                        (data?.businessConfiguration.commissions?.transactionFeeCents ?? 0) / 100,
                      ).getValue(),
                    }) +
                    `\n` +
                    data?.businessConfiguration.commissions?.featureFeeCents
                      .map((fee) =>
                        t(`ratesAndTimelines.featureRatesText.${fee.feature}`, {
                          transactionFee: Money.build(fee.feeCents / 100).getValue(),
                        }),
                      )
                      .join('\n')
                  : 'Aguardando ativação da conta...'
              }
            />
            <InfoRow
              label={t('ratesAndTimelines.settlementDays')}
              value={
                isLoading ? undefined : data ? (
                  <>
                    <div className="flex items-center mt-0.5">
                      <div className="rounded-full mr-2 bg-secondary p-1">
                        <CreditCard className=" h-3 w-3" />
                      </div>
                      {t('ratesAndTimelines.creditCardSettlementDays', {
                        days: data.businessConfiguration.creditCard.settlementDays,
                      })}
                    </div>
                    <div className="flex items-center mt-2">
                      <div className="rounded-full mr-2 bg-secondary p-1">
                        <Barcode className=" h-3 w-3" />
                      </div>
                      {t('ratesAndTimelines.boletoSettlementDays', {
                        days: data.businessConfiguration.ledger.escrowDays,
                      })}
                    </div>
                    <div className="flex items-center mt-2">
                      <div className="rounded-full mr-2 bg-secondary p-1">
                        <QrCode className=" h-3 w-3" />
                      </div>
                      {t('ratesAndTimelines.pixSettlementDays', {
                        days: data.businessConfiguration.ledger.escrowDays,
                      })}
                    </div>
                  </>
                ) : (
                  'Aguardando ativação da conta...'
                )
              }
            />
            <InfoRow
              label={t('ratesAndTimelines.securityReserveTitle')}
              value={
                isLoading ? undefined : data ? (
                  <Trans
                    i18nKey="ratesAndTimelines.securityReserveDescription"
                    values={{
                      currentPercent: data?.businessConfiguration.ledger.balanceReservedPercent,
                    }}
                    components={[
                      // eslint-disable-next-line jsx-a11y/anchor-has-content
                      <a
                        href="https://help.hub.la/hc/pt-br/reserva-de-seguran%C3%A7a"
                        target="_blank"
                        rel="noreferrer"
                        aria-label="Saiba mais sobre a reserva de segurança"
                        className="underline"
                      />,
                    ]}
                  />
                ) : (
                  'Aguardando ativação da conta...'
                )
              }
            />
          </div>
        </CardContent>
      </Card>
    </div>
  )
}
