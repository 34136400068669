import { injectable } from 'inversify'

const LOCAL_STORAGE_KEY = 'productsModulesHighlightVisualized'

@injectable()
export class HasVisualizedHighlightModules {
  public execute(productId: string): boolean {
    if (typeof window === 'undefined' || typeof localStorage === 'undefined') {
      return false
    }
    const productsModulesHighlightVisualizedStorage = localStorage.getItem(LOCAL_STORAGE_KEY)

    const productsIdsVisualized = productsModulesHighlightVisualizedStorage
      ? JSON.parse(productsModulesHighlightVisualizedStorage)
      : []

    const isProductIdOnVisualized = productsIdsVisualized.includes(productId)

    return !isProductIdOnVisualized
  }
}
