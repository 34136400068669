import { Skeleton, TableCell, TableRow } from '@hub-la/shadcn'

export const PartnershipsTableLoading = () => {
  return (
    <TableRow>
      <TableCell className="p-2">
        <div className="flex items-center space-x-4">
          <Skeleton className="w-16 h-10 rounded" />

          <div className="space-y-2">
            <Skeleton className="w-44 h-5" />
            <Skeleton className="w-16 h-3" />
          </div>
        </div>
      </TableCell>

      <TableCell className="p-2">
        <Skeleton className="w-16 h-5" />
      </TableCell>

      <TableCell className="p-2">
        <Skeleton className="w-32 h-5" />
      </TableCell>

      <TableCell className="p-2">
        <Skeleton className="w-32 h-5" />
      </TableCell>
    </TableRow>
  )
}
