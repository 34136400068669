import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation } from '@tanstack/react-query'
import { FileExtension } from '../../domain/enums/file-extension'
import { QueryKey } from '../../domain/enums/query-key'
import { ExportRefunds } from '../../usecases/export-refunds'

export const useExportRefunds = () => {
  const container = useContainer()

  return useMutation(
    [QueryKey.exportRefunds],
    ({ fileExtension }: { fileExtension: FileExtension }) =>
      new ExportRefunds(container.get(HttpClient)).execute(fileExtension),
    {
      retry: false,
      onSuccess: (id) => {
        window.open(`/export/${id}`, '_blank')
      },
    },
  )
}
