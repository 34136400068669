import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'

import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '../../domain/enums/query-key.enum'
import { GetCohort } from '../../usecases/get-cohort.usecase'

export const useGetCohort = (productId: string, cohortId: string) => {
  const container = useContainer()

  return useQuery(
    [QueryKey.getCohort, productId, cohortId],
    ({ signal }) => new GetCohort(container.get(HttpClient)).execute(productId, cohortId, signal),
    {
      refetchOnWindowFocus: false,
      retry: 3,
      initialData: [],
    },
  )
}
