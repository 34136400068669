import { Button } from '@hub-la/shadcn'
import { ChevronLeft } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { IntegrationProvider } from '../../../domain/dtos/integration-provider'
import { ActiveCampaignDetail } from '../../components/active-campaign/active-campaign-detail'
import { CademiDetail } from '../../components/cademi/cademi-detail'
import { ContaAzulDetail } from '../../components/conta-azul/conta-azul-detail'
import { ENotasDetail } from '../../components/enotas/enotas-detail'
import { GDigitalDetail } from '../../components/g-digital/g-digital-detail'
import { GoogleTagManagerDetail } from '../../components/google-tag-manager/google-tag-manager-detail'
import { HublaPixelDetail } from '../../components/hubla-pixel/hubla-pixel-detail'
import { HubSpotDetail } from '../../components/hubspot/hubspot-detail'
import { LeadLoversDetail } from '../../components/lead-lovers/leadlovers-detail'
import { MemberkitDetail } from '../../components/memberkit/memberkit-detail'
import { MetaPixelDetail } from '../../components/meta-pixel/meta-pixel-detail'
import { NotazzDetail } from '../../components/notazz/notazz-detail'
import { OmieDetail } from '../../components/omie/omie-detail'
import { RDStationDetail } from '../../components/rdstation/rdstation-detail'
import { SellFluxDetail } from '../../components/sell-flux/sell-flux-detail'
import { TheMembersDetail } from '../../components/themembers/themembers-detail'
import { TutoryDetail } from '../../components/tutory/tutory-detail'
import { WebhookDetail } from '../../components/webhook/webhook-detail'
import { ZapCertoDetail } from '../../components/zapcerto/zapcerto-detail'
import { ZapierDetail } from '../../components/zapier/zapier-detail'

type IntegrationDetailPageProps = {
  provider: IntegrationProvider
}

export const IntegrationDetailPage = ({ provider }: IntegrationDetailPageProps) => {
  const { t } = useTranslation()
  const history = useHistory()

  const details = {
    [IntegrationProvider.ACTIVE_CAMPAIGN]: <ActiveCampaignDetail provider={provider} />,
    [IntegrationProvider.WEBHOOK]: <WebhookDetail provider={provider} />,
    [IntegrationProvider.GOOGLE_TAG_MANAGER]: <GoogleTagManagerDetail provider={provider} />,
    [IntegrationProvider.META_PIXEL]: <MetaPixelDetail provider={provider} />,
    [IntegrationProvider.E_NOTAS]: <ENotasDetail provider={provider} />,
    [IntegrationProvider.ZAPIER]: <ZapierDetail provider={provider} />,
    [IntegrationProvider.HUBLA_PIXEL]: <HublaPixelDetail provider={provider} />,
    [IntegrationProvider.SELL_FLUX]: <SellFluxDetail provider={provider} />,
    [IntegrationProvider.GDIGITAL]: <GDigitalDetail provider={provider} />,
    [IntegrationProvider.ZAPCERTO]: <ZapCertoDetail provider={provider} />,
    [IntegrationProvider.CADEMI]: <CademiDetail provider={provider} />,
    [IntegrationProvider.MEMBERKIT]: <MemberkitDetail provider={provider} />,
    [IntegrationProvider.LEAD_LOVERS]: <LeadLoversDetail provider={provider} />,
    [IntegrationProvider.THE_MEMBERS]: <TheMembersDetail provider={provider} />,
    [IntegrationProvider.HUBSPOT]: <HubSpotDetail provider={provider} />,
    [IntegrationProvider.OMIE]: <OmieDetail provider={provider} />,
    [IntegrationProvider.RDSTATION]: <RDStationDetail provider={provider} />,
    [IntegrationProvider.CONTA_AZUL]: <ContaAzulDetail provider={provider} />,
    [IntegrationProvider.TUTORY]: <TutoryDetail provider={provider} />,
    [IntegrationProvider.NOTAZZ]: <NotazzDetail provider={provider} />,
  }

  return (
    <div className="flex flex-col space-y-2">
      <div className="flex flex-col items-start space-y-4">
        <Button
          className="h-5 px-0"
          variant="link"
          size="sm"
          onClick={() => history.push({ pathname: '/dashboard/integrations' })}
        >
          <ChevronLeft className=" w-4 h-4 mr-2" />
          Voltar
        </Button>
        <div className="flex items-center gap-3">
          <img src={t(`integrations.${provider}.icon`)} width={40} height={40} alt={provider} className="w-10 h-10" />
          <h1 className="text-3xl font-semibold">{t(`integrations.${provider}.title`)}</h1>
        </div>
      </div>

      {details[provider]}
    </div>
  )
}
