import { container } from '@hub-la/fe-container'
import { DashboardProducts, DashboardProductsProvider } from '@hub-la/fe-dashboard-products'

export const DashboardProductsInit = () => {
  return (
    <DashboardProductsProvider container={container}>
      <DashboardProducts />
    </DashboardProductsProvider>
  )
}
