import * as Yup from 'yup'
import { DocumentType } from '../../domain/enums/bank-account'
import { Document } from '../../domain/vos/document'
import { Email } from '../../domain/vos/email'
import { Phone } from '../../domain/vos/phone'
import { bankAccountSchema } from './bank-account-schema'

export const makeValidationSchema = () => {
  const validationSchema = Yup.object().shape({
    isPixKey: Yup.boolean(),
    bankAccount: Yup.mixed().when('isPixKey', {
      is: false,
      then: bankAccountSchema.fields.bankAccount,
    }),
    identity: Yup.object().shape({
      document: Yup.object().shape({
        type: Yup.number()
          .required('errors.required')
          .oneOf([DocumentType.CNPJ, DocumentType.CPF], 'errors.invalidModality'),
        value: Yup.string()
          .required('errors.required')
          .test({
            message: 'errors.invalidDocument',
            test: function (value) {
              return Document.build(String(value)).isValid()
            },
          }),
      }),
      fullName: Yup.string()
        .required('errors.required')
        .test({
          name: 'fullName',
          message: 'errors.invalidFullName',
          test: function (value) {
            // At least two words, none of them empty.
            return (
              String(value)
                .split(' ')
                .filter((x) => x !== '').length >= 2
            )
          },
        }),
      email: Yup.string()
        .email('errors.invalidEmail')
        .required('errors.required')
        .test({
          message: 'errors.invalidEmail',
          test: function (value) {
            return Email.build(String(value)).isValid()
          },
        }),
      phone: Yup.string()
        .required('errors.required')
        .test({
          message: 'errors.invalidPhoneNumber',
          test: function (value) {
            return Phone.build(String(value)).isValid()
          },
        }),
      billingAddress: Yup.object().shape({
        postalCode: Yup.string()
          .required('errors.required')
          .test({
            message: 'errors.invalidPostalCode',
            name: '',
            test: function (value) {
              return String(value).replace(/\D/g, '').length === 8;
            },
          }),
        neighborhood: Yup.string().max(100).required('errors.required'),
        street: Yup.string().max(100).required('errors.required'),
        number: Yup.string().required('errors.required'),
        complement: Yup.string().max(30),
        city: Yup.string().max(50).required('errors.required'),
        state: Yup.string().max(50).required('errors.required'),
      }),
      legalRepresentative: Yup.object().when('document.type', {
        is: DocumentType.CNPJ,
        then: Yup.object().shape({
          fullName: Yup.string()
            .required('errors.required')
            .test({
              name: 'fullName',
              message: 'errors.invalidFullName',
              test: function (value) {
                return (
                  String(value)
                    .split(' ')
                    .filter((x) => x !== '').length >= 2
                )
              },
            }),
          document: Yup.object().shape({
            type: Yup.number().required('errors.required').oneOf([DocumentType.CPF], 'errors.invalidModality'),
            value: Yup.string()
              .required('errors.required')
              .test({
                message: 'errors.invalidDocument',
                test: function (value) {
                  return Document.build(String(value)).isValid()
                },
              }),
          }),
        }),
        otherwise: Yup.object().shape({}),
      }),
    }),
    businessConfiguration: Yup.object().shape({
      creditCard: Yup.object().shape({
        softDescriptor: Yup.string()
          .required('errors.invalidSoftDescriptor')
          .min(5, 'errors.minSoftDescriptor')
          .max(11),
      }),
    }),
    sdkToken: Yup.string().required(),
  })

  return validationSchema
}
