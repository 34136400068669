import {
  Button,
  CopyClipboardButton,
  Separator,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@hub-la/shadcn'
import {
  Banknote,
  ChevronLeft,
  ChevronRight,
  CircleX,
  Clock,
  FileIcon,
  Flag,
  Info,
  InfoIcon,
  IterationCw,
  TvMinimal,
} from 'lucide-react'
import moment from 'moment'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { SmartInstallmentInvoiceEvent } from '../../../domain/dtos/smart-installment-invoice'
import { SmartInstallmentInvoiceEventType } from '../../../domain/enums/smart-installment-invoice-event-type.enum'

type Props = {
  events: SmartInstallmentInvoiceEvent[][]
}

export const SmartInstallmentTimeline: React.FC<Props> = ({ events }) => {
  const [showAllEvents, setShowAllEvents] = useState(false)

  const sortEvents = events.sort((a, b) => new Date(a[0].date).getTime() - new Date(b[0].date).getTime())

  const handleToggleShowAllEvents = () => {
    setShowAllEvents((state) => !state)
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center w-full gap-2">
        <h2 className="text-lg font-semibold">Histórico de eventos</h2>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger>
              <Info className="h-4 w-4" />
            </TooltipTrigger>
            <TooltipContent>
              <p>Históricos cronológico dos eventos que ocorreram com essa fatura.</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
      <Separator />
      <div className="space-y-2">
        {Array.from(sortEvents)
          .reverse()
          .map((event, index) => {
            const showConnector = index !== events.length - 1
            return event.length === 1 ? (
              <Item
                icon={<EventIcon type={event[0].type} />}
                title={event[0].title}
                link={event[0].link}
                description={event[0].description}
                time={event[0].date}
                showConnector={showConnector}
                installments={event[0].installments}
                installment={event[0].installment}
                dueDate={event[0].dueDate}
              />
            ) : (
              <ItemList items={event} showConnector={showConnector} />
            )
          })}
      </div>
    </div>
  )
}

const Item: React.FC<{
  icon: React.ReactNode
  title: string
  description?: string
  time: string
  link?: string
  installment?: number
  installments?: number
  dueDate?: string
  showConnector?: boolean
  controls?: JSX.Element
}> = ({ icon, installment, dueDate, installments, title, description, time, link, showConnector = true, controls }) => {
  const { t } = useTranslation()

  return (
    <div>
      <div className="p-1">
        <div className="flex gap-4">
          <div className="flex flex-col items-center">
            <div className="rounded-full bg-secondary p-2">{icon}</div>
          </div>
          <div className="flex flex-col">
            <div className="flex gap-2 items-center">
              <p className="text-sm">
                <Trans
                  i18nKey={title}
                  values={{
                    link,
                    installment,
                    installments,
                  }}
                  components={
                    link
                      ? [
                          // eslint-disable-next-line jsx-a11y/anchor-has-content
                          <a
                            href={link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-primary hover:underline"
                          />,
                        ]
                      : undefined
                  }
                />
              </p>
              {link && (
                <CopyClipboardButton
                  copyContent={link}
                  tooltipCopiedLabel={t('copyClipboard.eventIdCopied')}
                  tooltipCopyLabel={t('copyClipboard.copyEventId')}
                  size="icon"
                />
              )}
            </div>
            {description && (
              <p className="leading-tight text-sm text-muted-foreground whitespace-pre-line">
                {t(description, { dueDate: moment(dueDate).format('DD [de] MMM[.] YYYY') })}
              </p>
            )}
            <p className="text-sm mt-0.5 text-muted-foreground">{moment(time).format('D MMM. YYYY HH:mm:ss')}</p>
            {controls}
          </div>
        </div>
      </div>
    </div>
  )
}

const ItemList: React.FC<{ items: SmartInstallmentInvoiceEvent[]; showConnector: boolean }> = ({
  items,
  showConnector,
}) => {
  const [index, setIndex] = useState<number>(0)

  return (
    <Item
      icon={<EventIcon type={items[index].type} />}
      title={items[index].title}
      link={items[index].link}
      description={items[index].description}
      time={items[index].date}
      showConnector={showConnector}
      controls={
        <div className="flex items-center mt-2">
          <Button
            variant="ghost"
            size="icon"
            onClick={() => setIndex((prev) => (prev === 0 ? items.length - 1 : prev - 1))}
          >
            <ChevronLeft className="h-4 w-4" />
          </Button>
          <span className="text-xs text-muted-foreground">
            {index + 1} / {items.length}
          </span>
          <Button
            variant="ghost"
            size="icon"
            onClick={() => setIndex((prev) => (prev === items.length - 1 ? 0 : prev + 1))}
          >
            <ChevronRight className="h-4 w-4" />
          </Button>
        </div>
      }
    />
  )
}

const EventIcon: React.FC<{ type: SmartInstallmentInvoiceEventType | undefined }> = ({ type }) => {
  const commonStyles = 'h-4 w-4'

  switch (type) {
    case SmartInstallmentInvoiceEventType.CREATED:
      return <TvMinimal className={`${commonStyles} text-muted-foreground`} />
    case SmartInstallmentInvoiceEventType.COMPLETED:
      return <TvMinimal className={`${commonStyles} text-green-700`} />
    case SmartInstallmentInvoiceEventType.REGULARIZED:
      return <TvMinimal className={`${commonStyles} text-muted-foreground`} />
    case SmartInstallmentInvoiceEventType.CANCELED:
      return <TvMinimal className={`${commonStyles} text-orange-700`} />
    case SmartInstallmentInvoiceEventType.OVERDUE:
      return <TvMinimal className={`${commonStyles} text-red-700`} />
    case SmartInstallmentInvoiceEventType.DISPUTED:
      return <Flag className={`${commonStyles} text-orange-600`} />
    case SmartInstallmentInvoiceEventType.ERROR:
      return <CircleX className={`${commonStyles} text-red-700`} />
    case SmartInstallmentInvoiceEventType.INSTALLMENT_OVERDUE:
      return <Clock className={`${commonStyles} text-red-700`} />
    case SmartInstallmentInvoiceEventType.REFUNDED:
      return <IterationCw className={`${commonStyles} text-muted-foreground`} />
    case SmartInstallmentInvoiceEventType.PAID:
      return <Banknote className={`${commonStyles} text-green-700`} />
    case SmartInstallmentInvoiceEventType.PAYMENT_PAGE_CREATED:
      return <FileIcon className={`${commonStyles} text-muted-foreground`} />
    default:
      return <InfoIcon className={`${commonStyles} text-muted-foreground`} />
  }
}
