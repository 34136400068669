import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { PartnersByProductIdResponseDto } from '@hub-la/sdk-bff-product'
import { inject } from 'inversify'
import { GeneralError } from '../../domain/errors/general'
import { Envs } from '../../envs'

export class ListMyPartners {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(
    input: { id: string; page: number; pageSize: number },
    signal?: AbortSignal,
  ): Promise<PartnersByProductIdResponseDto> {
    const response = await this.httpClient.request({
      method: HttpMethod.GET,
      url: `${Envs.BFF_PRODUCT_URL}/products/${input.id}/partners`,
      params: {
        page: input.page,
        pageSize: input.pageSize,
      },
      signal,
    })

    if ([HttpStatusCode.OK, HttpStatusCode.CREATED].includes(response.statusCode)) {
      return response.data
    }

    throw new GeneralError()
  }
}
