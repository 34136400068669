export const pt = {
  exportRequest: {
    report: 'Relatório de Exportação:',
    status: 'Status',
    type: 'Tipo de Arquivo',
    createdAt: 'Criado em',
    complete: 'Baixar',
    error: 'Erro',
    pending: 'Pendente',
    affiliates: 'Afiliados',
    invoices: 'Faturas',
    subscriptions: 'Assinaturas',
    members: 'Membros',
    refunds: 'Reembolsos',
    waitlist: 'Lista de Espera',
  },
  error: {
    general: 'Ocorreu um erro. Por favor, tente novamente mais tarde.',
  },
}
