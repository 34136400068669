import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { GetInactiveSubscriptionsInput } from '../domain/dtos/get-inactive-subscriptions-input'
import { GetInactiveSubscriptionsOutput } from '../domain/dtos/get-inactive-subscriptions-output'
import { GeneralError } from '../domain/errors/general'
import { Envs } from '../envs'
import { GetOfferIds } from './get-offer-ids'

export class GetInactiveSubscriptions {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(
    input: GetInactiveSubscriptionsInput,
    signal?: AbortSignal,
  ): Promise<GetInactiveSubscriptionsOutput> {
    const response = await this.httpClient.request({
      method: HttpMethod.POST,
      url: `${Envs.BFF_WEB_URL}/receiver/summary/subscriptions/inactivated`,
      body: this.makeBody(input),
      signal,
    })

    if (response.statusCode === HttpStatusCode.OK) {
      return response.data
    }

    throw new GeneralError()
  }

  private makeBody(input: GetInactiveSubscriptionsInput) {
    const offerIds = new GetOfferIds().execute({ offerIds: input.offerIds, offers: input.offers })

    const body = {
      startDate: input.startDate,
      endDate: input.endDate,
      offerIds,
    }

    return body
  }
}
