import { Refund } from '../../../../domain/dtos/refund'
import { RefundStatusStats } from '../../../components/refund-status-stats'
import { ColumnDef } from '@tanstack/react-table'
import { RefundStatus } from '../../../../domain/enums/refund-status.enum'
import moment from 'moment'
import { formatCurrency } from '../../../../domain/vos/format-currency'
import { PaymentMethodIcon } from './payment-method-icon'
import { PaymentMethod } from '../../../../domain/enums/payment-method'

export const columns: ColumnDef<Refund>[] = [
  {
    header: 'Valor',
    accessorKey: 'amount',
    cell: ({ row }) => {
      const total = row.getValue<number>('amount')
      const paymentMethod = row.original.transaction?.paymentMethod as PaymentMethod

      return (
        <div className="flex items-center gap-2">
          <PaymentMethodIcon paymentMethod={paymentMethod} />
          <p>{formatCurrency(total)}</p>
        </div>
      )
    },
  },
  {
    header: 'Status',
    accessorFn: (refund) => refund.status,
    cell: ({ getValue }) => {
      return <RefundStatusStats status={getValue() as RefundStatus} />
    },
  },
  {
    header: 'Produto',
    accessorFn: (refund) => refund?.transaction?.item?.name,
    cell: ({ getValue }) => <span>{(getValue() as string) ?? ''}</span>,
  },
  {
    header: 'Data da compra',
    accessorFn: (refund) => refund.transaction?.paidAt,
    cell: ({ getValue }) => {
      const paidAt = getValue() as string
      return paidAt ? <>{moment(paidAt).format('D MMM. YYYY')}</> : null
    },
  },
  {
    header: 'Data do pedido',
    accessorFn: (refund) => refund.createdAt,
    cell: ({ getValue }) => {
      const createdAt = getValue() as string
      return createdAt ? <>{moment(createdAt).format('D MMM. YYYY')}</> : null
    },
  },
]
