import { WaitlistStatus } from '../enums/waitlist-status'

export interface GetWaitlistInput {
  page: number
  productId: string
  perPage: number
  status: WaitlistStatus
}

export interface GetWaitlistOutput {
  subscribers: Subscriber[]
  count: number
  currentPage: number
  perPage: number
  subscribersAmount: number
  conversionsAmount: number
}

export interface Subscriber {
  id: string
  waitlistId: string
  productId: string
  name: string
  email: string
  phoneNumber: string
  member: WaitlistMember
  createdAt: {
    _seconds: number
    _nanoseconds: number
  }
}

export interface WaitlistMember {
  status: WaitlistStatus
  memberId?: string
  groupId?: string
}

export enum FileExtension {
  XLSX = 'xlsx',
  CSV = 'csv',
  JSON = 'json',
}
