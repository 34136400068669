import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation } from '@tanstack/react-query'
import { PatchOfferNameInput } from '../../../domain/dtos/offers/patch-offer-name'
import { QueryKey } from '../../../domain/enums/query-key'
import { PatchOfferName } from '../../../usecases/offers/patch-offer-name'

export const usePatchOfferName = () => {
  const container = useContainer()

  return useMutation(
    [QueryKey.patchOfferName],
    (input: PatchOfferNameInput) => new PatchOfferName(container.get(HttpClient)).execute(input),
    {
      retry: false,
    },
  )
}
