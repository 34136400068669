import { Card, CardContent, CardHeader, CardTitle, Separator, Skeleton } from '@hub-la/shadcn'

export const HeaderLoading = () => {
  return (
    <Card>
      <CardHeader className="w-full flex-row flex-wrap gap-2 items-center justify-between">
        <CardTitle className="flex-1">
          <Skeleton className="h-6 w-40" />
        </CardTitle>
      </CardHeader>

      <CardContent>
        <div className="flex flex-col gap-1">
          <div className="flex items-center gap-2">
            <Skeleton className="h-8 w-32" />
            <Skeleton className="h-6 w-20" />
            <Skeleton className="h-6 w-24" />
          </div>

          <Skeleton className="h-4 w-64 max-w-full" />
        </div>

        <Separator className="my-4" />

        <div className="flex flex-col gap-4">
          <div>
            <Skeleton className="h-5 w-32 mb-2" />
            <div className="flex items-center gap-2">
              <Skeleton className="h-4 w-4" />
              <Skeleton className="h-4 w-36" />
            </div>
          </div>

          <div>
            <Skeleton className="h-5 w-32 mb-2" />
            <div className="flex items-center gap-2">
              <Skeleton className="h-4 w-4" />
              <Skeleton className="h-4 w-36" />
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  )
}
