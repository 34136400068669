export const Envs = {
  BFF_WEB_URL: process.env['NX_BFF_WEB_URL'] ?? process.env['REACT_APP_HUB_API'],
  FUNCTIONS_URL: process.env['NX_FUNCTIONS_URL'] ?? process.env['REACT_APP_FUNCTIONS_URL'],

  APP_HOST: process.env['NEXT_PUBLIC_APP_URL'] ?? process.env['REACT_APP_APP_URL'] ?? process.env['NX_APP_URL'],
  SITE_HOST: process.env['NEXT_PUBLIC_SITE_URL'] ?? process.env['REACT_APP_SITE_URL'] ?? process.env['NX_SITE_URL'],

  RUDDERSTACK_WRITE_KEY:
    process.env['NX_RUDDERSTACK_WRITE_KEY'] ??
    process.env['REACT_APP_RUDDERSTACK_TOKEN'] ??
    process.env['NEXT_PUBLIC_RUDDERSTACK_WRITE_KEY'],
  RUDDERSTACK_DATA_PLANE_URL:
    process.env['NX_RUDDERSTACK_DATA_PLANE_URL'] ??
    process.env['REACT_APP_RUDDERSTACK_DATA_PLANE_URL'] ??
    process.env['NEXT_PUBLIC_RUDDERSTACK_DATA_PLANE_URL'],
  RUDDERSTACK_IS_DEBUG:
    process.env['NX_RUDDERSTACK_IS_DEBUG'] ??
    process.env['REACT_APP_RUDDERSTACK_IS_DEBUG'] ??
    process.env['NEXT_PUBLIC_RUDDERSTACK_IS_DEBUG'],
  COOKIE_DOMAIN:
    process.env['NX_COOKIE_DOMAIN'] ??
    process.env['REACT_APP_COOKIE_DOMAIN'] ??
    process.env['NEXT_PUBLIC_COOKIE_DOMAIN'],
}
