import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject, injectable } from 'inversify'
import { Envs } from '../envs'

@injectable()
export class DeleteAsset {
  public constructor(@inject(HttpClient) private readonly httpClient: HttpClient) {}

  public async execute(assetId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .request({
          url: `${Envs.BFF_MEMBERS_AREA_URL}/assets/${assetId}`,
          method: HttpMethod.DELETE,
        })
        .then((response) => {
          if (![HttpStatusCode.NO_CONTENT, HttpStatusCode.OK].includes(response.statusCode)) {
            return reject(`Ocorreu um erro ao deletar o asset ${assetId}`)
          }

          return resolve(response.data)
        })
    })
  }
}
