import { AssetResponse } from '@hub-la/fe-asset'
import { HttpClient, HttpMethod, HttpResponse } from '@hub-la/fe-core-http-client'
import { Post } from '@hub-la/fe-post'
import { highlightTypes } from '../../../domain/dtos/member-area/settings/formik-values'
import { GetHighlightInput } from '../../../domain/dtos/member-area/settings/get-highlight-input'
import { GeneralError } from '../../../domain/errors/general'
import { Envs } from '../../../envs'

export interface AssetHighlight {
  mobile?: AssetResponse
  desktop?: AssetResponse
}

export interface GetHighlightResponse {
  type: highlightTypes
  post?: Post
  image?: AssetHighlight
  video?: AssetHighlight
  recommendations?: Post[]
}

export class GetHighlight {
  public constructor(private readonly httpClient: HttpClient) {}

  public async execute(values: GetHighlightInput) {
    try {
      const response: HttpResponse<GetHighlightResponse> = await this.httpClient.request({
        url: `${Envs.BFF_WEB_URL}/hub/highlights`,
        params: { productId: values.productId },
        method: HttpMethod.GET,
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })

      return response.data
    } catch (e) {
      throw new GeneralError()
    }
  }
}
