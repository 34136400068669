import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation } from '@tanstack/react-query'
import { DownloadInvoicesXlsxInput } from '../../domain/dtos/download-invoices-xlsx-input'
import { QueryKey } from '../../domain/enums/query-keys'
import { DownloadInvoicesXlsx } from '../../usecases/download-invoices-xlsx'

export const useDownloadInvoicesXlsx = (input: DownloadInvoicesXlsxInput) => {
  const container = useContainer()

  return useMutation(
    [QueryKey.downloadInvoicesXlsx],
    () => new DownloadInvoicesXlsx(container.get(HttpClient)).execute(input),
    {
      retry: false,
    },
  )
}
