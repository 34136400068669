import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { PutCancelInviteInput } from '../../../domain/dtos/put-cancel-invite-input'
import { QueryKey } from '../../../domain/enums/query-key'
import { PutCancelInvite } from '../../../usecases/members/put-cancel-invite'

export const usePutCancelInvite = ({ onSuccess }: { onSuccess?: () => void }) => {
  const container = useContainer()
  const queryClient = useQueryClient()

  return useMutation(
    [QueryKey.putAcceptInvite],
    (input: PutCancelInviteInput) => new PutCancelInvite(container.get(HttpClient)).execute(input.inviteId),
    {
      retry: false,
      onSuccess: () => {
        if (onSuccess) {
          onSuccess()
        }
        queryClient.invalidateQueries({ queryKey: [QueryKey.getPendingInvites] })
      },
    },
  )
}
