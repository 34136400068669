import moment from 'moment'

export class GetIntervalOptions {
  public execute(startTime = '00:00:00') {
    const start = moment(startTime, 'HH:mm:ss')

    // Take a end point
    const end = moment('23:59:59', 'HH:mm:ss')
    const options: Array<string> = []

    while (start.isSameOrBefore(end)) {
      // add 15 minutes to the starting point
      options.push(start.add(15, 'm').format('HH:mm'))
    }

    return options.filter((option) => option !== '00:00')
  }
}
