import { Button, Calendar, cn, Popover, PopoverContent, PopoverTrigger } from '@hub-la/shadcn'
import { CalendarIcon } from 'lucide-react'
import moment from 'moment'
import { useState } from 'react'
import { GetShortcuts } from '../../usecases/get-shortchuts'

export const SelectDateRangeCalendar = ({ filters, setFilters }) => {
  const [calendarOpen, setCalendarOpen] = useState<boolean>(false)

  const shortcuts = new GetShortcuts().execute()

  const onFilterChange = (newDate?: { to?: Date; from?: Date }) => {
    const startDate = moment(newDate?.to).startOf('day').format('YYYY-MM-DDTHH:mm:ssZ')
    const endDate = moment(newDate?.from).endOf('day').format('YYYY-MM-DDTHH:mm:ssZ')

    setFilters({ ...filters, startDate, endDate, page: 1 })
  }

  const Placeholder = () => {
    const { startDate, endDate } = filters
    const formattedStartDate = moment(startDate).format('D MMM. YYYY')
    const formattedEndDate = moment(endDate).format('D MMM. YYYY')
    const text = `${formattedStartDate} - ${formattedEndDate}`

    const shortcut = shortcuts.find((it) => it.startDate === startDate && it.endDate === endDate)

    return shortcut ? shortcut.label : text
  }

  const handleShortcutClick = (shortcut) => {
    const newDate = { to: moment(shortcut.startDate).toDate(), from: moment(shortcut.endDate).toDate() }
    onFilterChange(newDate)
    setCalendarOpen(false)
  }

  const hasSelectedShortcut = (shortcut) =>
    shortcut.period === filters.period &&
    shortcut.startDate === filters.startDate &&
    shortcut.endDate === filters.endDate

  return (
    <Popover open={calendarOpen} onOpenChange={setCalendarOpen}>
      <PopoverTrigger asChild>
        <Button variant="outline">
          <CalendarIcon className="mr-2 h-4 w-4" />
          <span>{Placeholder()}</span>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0" align="start">
        <div className="flex grid-cols-3">
          <div className="p-2 col-span-1 flex flex-col border-r">
            {shortcuts.map((shortcut, idx) => (
              <Button
                key={idx}
                variant="ghost"
                size="sm"
                onClick={() => handleShortcutClick(shortcut)}
                className={cn('justify-start', hasSelectedShortcut(shortcut) && 'bg-muted')}
              >
                {shortcut.label}
              </Button>
            ))}
          </div>
          <div className="col-span-2">
            <Calendar
              mode="range"
              selected={{ from: moment(filters.startDate).toDate(), to: moment(filters.endDate).toDate() }}
              defaultMonth={moment(filters.startDate).toDate()}
              numberOfMonths={2}
              onSelect={(selected) => onFilterChange(selected)}
            />
          </div>
        </div>
      </PopoverContent>
    </Popover>
  )
}
