import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@hub-la/shadcn'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface CloseAlertModalProps {
  onClose: () => void
  onConfirm: () => void
  open: boolean
  isLoading?: boolean
}

export const CloseAlertModal: React.FC<CloseAlertModalProps> = ({ onClose, onConfirm, open }) => {
  const { t } = useTranslation()

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[500px]">
        <DialogHeader>
          <DialogTitle className="text-2xl font-semibold">{t('closeAlertModal.title')}</DialogTitle>
          <DialogDescription className="text-base">{t('closeAlertModal.description')}</DialogDescription>
        </DialogHeader>
        <DialogFooter className="sm:justify-end">
          <div className="flex flex-col sm:flex-row gap-2 w-full sm:w-auto">
            <Button variant="outline" onClick={onConfirm} type="button">
              {t('closeAlertModal.leave')}
            </Button>
            <Button onClick={onClose} type="button">
              {t('closeAlertModal.stay')}
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
