import { isEqual, isNil } from 'lodash'
import { usePrevious } from './use-previous'

export const useCheckHaveChanged = (args) => {
  const previous = usePrevious(args)
  const current = args

  if (isNil(previous)) {
    return
  }

  return !isEqual(previous, current)
}
