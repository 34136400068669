import React from 'react'
import { Loader } from 'lucide-react'
import { useGetLiveLink } from '../../hooks/use-get-live-link'
import { useParams } from 'react-router-dom'

export const EventLivePage = () => {
  const { postId } = useParams<{ postId: string }>()
  useGetLiveLink({ postId })

  return (
    <div className="w-full text-center h-[408px] relative" data-testid="loading">
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
        <Loader className="h-16 w-16 animate-spin text-white" />
      </div>
    </div>
  )
}
