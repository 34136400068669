import { Button, Card, CardContent } from '@hub-la/shadcn'
import { ArrowRight } from 'lucide-react'
import React from 'react'

export type UserLoginNavigationTargetProps = {
  icon: React.ReactNode
  title: string
  subtitle?: string
  loading?: boolean
  onClick?: () => void
}

export const UserLoginNavigationTarget: React.FC<UserLoginNavigationTargetProps> = ({
  icon,
  title,
  subtitle,
  loading,
  onClick,
}) => {
  return (
    <Card
      className="cursor-pointer hover:bg-secondary-100 hover:border-primary transition-colors"
      onClick={!loading ? onClick : undefined}
    >
      <CardContent className="p-4 flex items-center justify-between">
        <div className="flex items-center gap-4">
          <div className="p-3 border rounded-md bg-secondary flex justify-center items-center">{icon}</div>
          <div>
            <p className="text-base font-medium">{title}</p>
            {subtitle && <p className="text-sm text-muted-foreground">{subtitle}</p>}
          </div>
        </div>
        <Button variant="ghost" size="icon" disabled={loading}>
          <ArrowRight className="h-4 w-4" />
        </Button>
      </CardContent>
    </Card>
  )
}
