import moment from 'moment-timezone'

export class GetPeriodFromDateRange {
  public execute({ from, to }: { from: string; to: string }): number {
    const startDate = moment(from).tz('America/Sao_Paulo')
    const endDate = moment(to).tz('America/Sao_Paulo')

    const diffInDays = endDate.diff(startDate, 'days')
    return diffInDays
  }
}
