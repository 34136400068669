import { useGetIdentity, useGetPayout } from '@hub-la/fe-get-user'
import { Button, Dialog, DialogContent, ScrollArea, Separator, useToast } from '@hub-la/shadcn'
import { FormikProvider, useFormik } from 'formik'
import { X } from 'lucide-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { BrazilianBankCode } from '../../domain/constants/brazilian-banks'
import { BankAccountType } from '../../domain/enums/bank-account'
import { WithdrawalForm } from '../components/withdrawal-form'
import { useUpdatePayout } from '../hooks/use-update-payout'
import { bankAccountSchema } from '../validation/bank-account-schema'

type Props = {
  open: boolean
  onClose?: () => void
  onUpdate?: () => void
}

export type UpdatePayoutValues = {
  bankAccount: {
    bankNumber: BrazilianBankCode
    agencyNumber: string
    agencyCheckNumber?: string
    accountNumber: string
    accountCheckNumber: string
    accountType: BankAccountType
  }
  isPixKey: boolean
}

export const UpdatePayoutModal: React.FC<Props> = ({ open, onClose, onUpdate }) => {
  const { t } = useTranslation()
  const { toast } = useToast()
  const { data } = useGetIdentity()
  const { data: payout } = useGetPayout()
  const { mutateAsync: updatePayout, isLoading, error, reset, isSuccess } = useUpdatePayout()

  const formik = useFormik<UpdatePayoutValues>({
    validationSchema: Yup.object().shape({
      isPixKey: Yup.boolean(),
      bankAccount: Yup.mixed().when('isPixKey', {
        is: false,
        then: bankAccountSchema.fields.bankAccount,
      }),
    }),
    validateOnMount: true,
    validateOnChange: true,
    enableReinitialize: true,
    initialValues: {
      bankAccount: {
        bankNumber: '001' as BrazilianBankCode,
        agencyNumber: '',
        agencyCheckNumber: '',
        accountNumber: '',
        accountCheckNumber: '',
        accountType: BankAccountType.TYPE_CHECKING,
      },
      isPixKey: payout?.type === 'PIX' ?? false,
    },
    onSubmit: async (values: UpdatePayoutValues) => {
      updatePayout(values).then(() => {
        onUpdate?.()
      })
    },
  })

  React.useEffect(() => {
    if (error) {
      toast({
        variant: 'destructive',
        title: 'Erro',
        description: t((error as Error)?.message) ?? '',
      })
      reset()
    }
    if (isSuccess) {
      toast({
        title: 'Sucesso',
        description: t('success'),
      })
      reset()
    }
  }, [error, isSuccess, t, toast, reset])

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="max-w-full h-full m-0 p-4">
        <FormikProvider value={formik}>
          <div className="flex flex-col gap-6">
            <div className="flex gap-2 cursor-pointer" onClick={onClose}>
              <X className="h-6 w-6" />
              <span className="text-base">{t('accountVerification.form.close')}</span>
            </div>

            <Separator />

            <ScrollArea className="h-[calc(100vh-200px)]">
              <div className="flex flex-col gap-6 max-w-[600px] mx-auto">
                <h3 className="text-2xl font-semibold">{t('updatePayout.title')}</h3>

                <WithdrawalForm documentValue={data?.identity.document.value} fullName={data?.identity.fullName} />

                <div className="self-end">
                  <Button
                    variant="default"
                    disabled={!formik.isValid}
                    onClick={() => formik.submitForm()}
                    loading={isLoading}
                  >
                    {t('updatePayout.submit')}
                  </Button>
                </div>
              </div>
            </ScrollArea>
          </div>
        </FormikProvider>
      </DialogContent>
    </Dialog>
  )
}
