import { DocumentType, VerificationStatus } from '../enums/bank-account'

interface IdentityBillingAddress {
  name: string
  street: string
  number: string
  neighborhood: string
  complement?: string
  city: string
  state: string
  postalCode: string
  countryCode: string
}

interface IdentityDocument {
  type: DocumentType
  /** The document value without any formatting or separators */
  value: string
  /** The issuer Two-letter country code (ISO 3166-1 alpha-2 UPPER-CASE). */
  countryCode: string
}

/** Rejection info is only available when validation status is REJECTED. */
interface IdentityVerificationRejectionInfo {
  /** The raw provider verification code. */
  providerCode: string
  /** The description/reason why this verification was rejected. */
  providerDesc: string
}

interface IdentityVerification {
  /** The verification provider. */
  provider: string
  /** The verification status. */
  status: VerificationStatus
  /** Rejection info, if status is REJECTED. */
  rejectionInfo?: IdentityVerificationRejectionInfo
}

interface BusinessLedger {
  // days to release the value due pix invoices
  escrowDays: number
  balanceReservedPercent: number
}

enum BusinessConfigurationFeature {
  /** FEATURE_UNSPECIFIED - Default value, do not use. */
  FEATURE_UNSPECIFIED = 0,
  /** FEATURE_GROUPS - Purchased products that have active groups */
  FEATURE_GROUPS = 1,
}

interface FeatureFeeCents {
  feature: BusinessConfigurationFeature
  feeCents: number
}

interface BusinessComission {
  commissionPercent: number
  transactionFeeCents: number
  featureFeeCents: FeatureFeeCents[]
}

export interface GetIdentityResponse {
  identity: {
    id: string
    fullName: string
    firstName: string
    lastName: string
    email: string
    phone: string
    birthday?: Date
    billingAddress: IdentityBillingAddress
    document: IdentityDocument
    verification?: IdentityVerification
  }
  businessConfiguration: {
    displayName: string
    creditCard: {
      settlementDays: number
      softDescriptor: string
    }
    commissions: BusinessComission
    ledger: BusinessLedger
  }
}
