import { container } from '@hub-la/fe-container'
import { UserSubscriptionsDetailPage, UserSubscriptionsProvider } from '@hub-la/fe-user-subscriptions'

export const UserSubscriptionsDetailInit = () => {
  return (
    <UserSubscriptionsProvider container={container}>
      <UserSubscriptionsDetailPage />
    </UserSubscriptionsProvider>
  )
}
