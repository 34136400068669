import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '../../domain/dtos/query-keys'
import { GetMyAccess } from '../../usecases/get-my-access'

export const useGetMyAccess = () => {
  const container = useContainer()

  return useQuery([QueryKey.getMyAccess], () => new GetMyAccess(container.get(HttpClient)).execute(), {
    retry: 3,
    retryOnMount: false,
    refetchOnWindowFocus: false,
    initialData: [],
  })
}
