export enum ProductCategory {
  homeAndConstruction = 'homeAndConstruction',
  privateclub = 'privateclub',
  culinaryAndGastronomy = 'culinaryAndGastronomy',
  design = 'design',
  personalDevelopment = 'personalDevelopment',
  law = 'law',
  education = 'education',
  investmentsAndFinancialEducation = 'investmentsAndFinancialEducation',
  professionalEducation = 'professionalEducation',
  entrepreneurship = 'entrepreneurship',
  entertainment = 'entertainment',
  spirituality = 'spirituality',
  games = 'games',
  hobbies = 'hobbies',
  languages = 'languages',
  digitalMarketing = 'digitalMarketing',
  milesAndTrips = 'milesAndTrips',
  fashionAndBeauty = 'fashionAndBeauty',
  musicAndArts = 'musicAndArts',
  news = 'news',
  promotionsAndCashback = 'promotionsAndCashback',
  relationship = 'relationship',
  signalAndTradingRooms = 'signalAndTradingRooms',
  healthAndFitness = 'healthAndFitness',
  other = 'other',
}
