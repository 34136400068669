import { AccountDropdown, AccountDropdownProvider } from '@hub-la/fe-account-dropdown'
import { useContainer } from '@hub-la/fe-container'
import { useGetUser } from '@hub-la/fe-get-user'
import { useGetRoleplayReference, useRoleplay } from '@hub-la/fe-roleplay'
import { Logo, ThemeSwitcher, useIsMobile } from '@hub-la/shadcn'
import { memo } from 'react'
import { Link, useHistory } from 'react-router-dom'

export const AppHeader = memo(() => {
  const { isRoleplay } = useRoleplay()
  const { data: roleplayUser } = useGetRoleplayReference({ enabled: isRoleplay })
  const history = useHistory()
  const container = useContainer()
  const { data: currentUser } = useGetUser()
  const isMobile = useIsMobile()

  return (
    <header>
      <div className="mx-auto py-4 flex justify-between items-center">
        <div className="flex items-center">
          <Link to="/user_groups">
            <Logo hierarchy="primary" size={isMobile ? 'medium' : 'medium'} />
          </Link>
        </div>

        <div className="flex items-center space-x-2">
          {currentUser && (
            <AccountDropdownProvider container={container}>
              {currentUser && !isRoleplay && (
                <AccountDropdown
                  push={history.push}
                  id={currentUser.id}
                  firstName={currentUser.name ?? undefined}
                  picture={currentUser.picture ?? null}
                  username={currentUser.id}
                />
              )}
              {currentUser && isRoleplay && roleplayUser && (
                <AccountDropdown
                  push={history.push}
                  id={roleplayUser.userId}
                  firstName={roleplayUser.name ?? undefined}
                  picture={roleplayUser.picture ?? null}
                  username={roleplayUser.username}
                />
              )}
            </AccountDropdownProvider>
          )}
        </div>
      </div>
    </header>
  )
})
