import { ReactNode } from 'react'
import BackgroundGradient from '../assets/background-gradient.webp'

interface BackgroundProps {
  primaryColor: string
  secondaryColor: string
  showTerms?: boolean
  children?: ReactNode
}

export const Background = ({ children }: BackgroundProps) => {
  return (
    <div
      className="relative w-full h-full min-h-screen py-6 pb-20 flex justify-center items-center bg-[#121212] bg-cover bg-center bg-repeat"
      style={{ backgroundImage: `url(${BackgroundGradient})` }}
    >
      {children}
    </div>
  )
}
