import { CreditCardHolder } from '@hub-la/fe-tokenizer'
import * as Yup from 'yup'
import { UpgradePaymentMethods } from '../../../../../domain/dtos/payment-method'

export const tokenValidationSchema = Yup.object().shape({
  creditCard: Yup.object().when(['paymentMethod'], {
    is: (paymentMethod) => paymentMethod === UpgradePaymentMethods.PAYMENT_METHOD_CARD,
    then: Yup.object().shape({
      holder: Yup.string().test({
        name: 'holder',
        message: 'changePaymentMethodModal.creditCard.errors.holder',
        test: (value = '') => {
          return CreditCardHolder.build(value).isValid()
        },
      }),
      token: Yup.string().required('changePaymentMethodModal.creditCard.errors.token'),
    }),
  }),
})
