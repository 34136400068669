export const BRAZILIAN_BANKS = [
  { code: '260', name: 'Nubank - Nu Pagamentos S.A.' },
  { code: '001', name: 'Banco do Brasil' },
  { code: '077', name: 'Banco Inter' },
  { code: '341', name: 'Itaú Unibanco' },
  { code: '104', name: 'Caixa Econômica Federal' },
  { code: '033', name: 'Santander' },
  { code: '237', name: 'Bradesco' },
  { code: '336', name: 'Banco C6 S.A.' },
  { code: '290', name: 'Pagbank - Pagseguro Internet S.A' },
  { code: '380', name: 'Picpay servicos s.a.' },
  { code: '003', name: 'Banco da Amazônia' },
  { code: '004', name: 'Banco do Nordeste' },
  { code: '021', name: 'Banestes' },
  { code: '025', name: 'Banco Alfa' },
  { code: '037', name: 'Banco do Estado do Pará' },
  { code: '041', name: 'Banrisul' },
  { code: '047', name: 'Banese' },
  { code: '069', name: 'Crefisa' },
  { code: '070', name: 'Banco de Brasília - BRB' },
  { code: '079', name: 'Banco Original do Agronegócio' },
  { code: '081', name: 'Bancoseguro S.A.' },
  { code: '082', name: 'Banco Topazio' },
  { code: '084', name: 'Uniprime do Norte do Paraná' },
  { code: '085', name: 'Cooperativa Central de Crédito Urbano - Ailos' },
  { code: '089', name: 'Cooperativa de Crédito Rural da Região da Mogiana' },
  { code: '093', name: 'Pólocred' },
  { code: '094', name: 'Banco Finaxis' },
  { code: '097', name: 'CCC Noroeste Brasileiro' },
  { code: '099', name: 'Uniprime Central' },
  { code: '107', name: 'Banco Bocom BBM S.A.' },
  { code: '120', name: 'Banco Rodobens' },
  { code: '125', name: 'Brasil plural' },
  { code: '130', name: 'Caruana SCFI' },
  { code: '133', name: 'Confederação Nac das CCC SOL - Cresol' },
  { code: '136', name: 'Unicred' },
  { code: '174', name: 'Pernambucanas Financiadora' },
  { code: '197', name: 'Stone Pagamentos' },
  { code: '208', name: 'Banco BTG Pactual' },
  { code: '212', name: 'Original' },
  { code: '213', name: 'Banco Arbi' },
  { code: '218', name: 'Banco BS2' },
  { code: '224', name: 'Banco Fibra' },
  { code: '243', name: 'Banco Máxima' },
  { code: '246', name: 'Banco ABC Brasil' },
  { code: '274', name: 'Money Plus SCMEPP' },
  { code: '280', name: 'Avista S.A. Crédito, Financiamento e Investimento' },
  { code: '299', name: 'Sorocred Crédito, Financiamento E Investimento' },
  { code: '301', name: 'BPP Instituição de Pagamento S.A.' },
  { code: '310', name: 'Vortx DTVM' },
  { code: '318', name: 'Banco BMG' },
  { code: '323', name: 'Mercado Pago' },
  { code: '329', name: 'QI Sociedade De Crédito Direto S.A.' },
  { code: '335', name: 'Banco Digio S.A.' },
  { code: '348', name: 'Banco XP S.A.' },
  { code: '364', name: 'Efí S.A' },
  { code: '376', name: 'Banco JP Morgan' },
  { code: '382', name: 'Fidúcia sociedade de crédito ao microempreendedor e à empresa de pequeno porte l' },
  { code: '383', name: 'Juno' },
  { code: '389', name: 'Mercantil do Brasil' },
  { code: '396', name: 'Hub pagamentos s.a' },
  { code: '403', name: 'Cora SCD S.A.' },
  { code: '404', name: 'Sumup sociedade de crédito direto s.a.' },
  { code: '406', name: 'Accredito - sociedade de crédito direto s.a.' },
  { code: '413', name: 'Banco bv s.a.' },
  { code: '414', name: 'Work sociedade de crédito direto s.a.' },
  { code: '422', name: 'Banco Safra' },
  { code: '435', name: 'Delcred sociedade de crédito direto s.a.' },
  { code: '450', name: 'FITBANK INSTITUICAO DE PAGAMENTOS ELETRONICOS S.A.' },
  { code: '461', name: 'ASAAS IP S.A.' },
  { code: '470', name: 'Cdc sociedade de crédito ao microempreendedor e à empresade pequeno porte ltda.' },
  { code: '481', name: 'Superlógica sociedade de crédito direto s.a.' },
  { code: '487', name: 'Deutsche Bank' },
  { code: '509', name: 'Celcoin instituicao de pagamento s.a.' },
  { code: '529', name: 'Pinbank brasil - pagamentos inteligentes s.a.' },
  { code: '536', name: 'Neon pagamentos s.a.' },
  { code: '542', name: 'Cloud walk meios de pagamentos e servicos ltda' },
  { code: '600', name: 'Banco Luso Brasileiro' },
  { code: '604', name: 'Banco Industrial' },
  { code: '611', name: 'Banco Paulista' },
  { code: '612', name: 'Banco Guanabara' },
  { code: '613', name: 'Omni Banco' },
  { code: '623', name: 'Banco Pan' },
  { code: '630', name: 'Banco Smartbank S.A.' },
  { code: '633', name: 'Banco Rendimento' },
  { code: '634', name: 'Banco Triângulo' },
  { code: '637', name: 'Banco Sofisa' },
  { code: '643', name: 'Banco Pine' },
  { code: '653', name: 'Banco Indusval' },
  { code: '654', name: 'Banco Renner' },
  { code: '655', name: 'Banco Votorantim S.A.' },
  { code: '707', name: 'Daycoval' },
  { code: '741', name: 'Banco Ribeirão Preto S.A.' },
  { code: '743', name: 'Banco Semear' },
  { code: '745', name: 'Citibank' },
  { code: '748', name: 'Sicredi' },
  { code: '755', name: 'Bank of America Merrill Lynch' },
  { code: '756', name: 'Sicoob' },
]

export type BrazilianBankCode = typeof BRAZILIAN_BANKS[number]['code']
