import { Card, CardContent, CardHeader, CardTitle, Separator } from '@hub-la/shadcn'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { ContentRow } from './content-row'

type DetailsProps = {
  id: string
  createdAt: string
  validUntil: string
}

export const Details = ({ id, createdAt, validUntil }: DetailsProps) => {
  const { t } = useTranslation()

  return (
    <div className="space-y-6">
      <Card>
        <CardHeader className="py-4 px-4">
          <CardTitle className="text-base font-semibold flex justify-between items-center">
            {t('couponDetails.title')}
          </CardTitle>
        </CardHeader>
        <Separator />
        <CardContent className="p-4 space-y-4">
          <ContentRow title={t('couponDetails.id')} content={<span>{id}</span>} />

          <ContentRow
            title={t('couponDetails.createdAt')}
            content={<span>{moment(createdAt).format('D MMM. YYYY HH:mm')}</span>}
          />

          <ContentRow
            title={t('couponDetails.validUntil')}
            content={<span>{validUntil ? moment(validUntil).format('D MMM. YYYY HH:mm') : '-'}</span>}
          />
        </CardContent>
      </Card>
    </div>
  )
}
