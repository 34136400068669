import { FileText } from 'lucide-react'
import { useTranslation } from 'react-i18next'

export const EmptyState = () => {
  const { t } = useTranslation()

  return (
    <div data-testid="empty-state" className="flex flex-col items-center justify-center text-center my-12">
      <FileText className="w-13 h-13 text-muted-foreground" />

      <h2 className="text-2xl font-semibold mt-2 mb-2">{t('emptySection.title')}</h2>

      <p className="text-muted-foreground max-w-[548px]">{t('emptySection.subtitle')}</p>
    </div>
  )
}
