import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation } from '@tanstack/react-query'
import { QueryKey } from '../../domain/dtos/query-keys'
import { SigninRoleplay } from '../../usecases/signin-roleplay'

export const useSigninRoleplay = () => {
  const container = useContainer()

  return useMutation(
    [QueryKey.postSignInRoleplay],
    (roleplayUserId: string) => new SigninRoleplay(container.get(HttpClient)).execute(roleplayUserId),
    {
      retry: false,
    },
  )
}
