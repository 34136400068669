import { useEffect } from 'react'
import { Envs } from '../envs'

export const useAppcues = () => {
  useEffect(() => {
    const currentUserString = localStorage.getItem('currentUser')

    if (!currentUserString) return

    const currentUser = JSON.parse(currentUserString)

    if (!currentUser.id || !currentUser.isSeller) return

    try {
      console.log(`Identifying ${currentUser.id}`)
      window.Appcues?.identify(currentUser.id)
    } catch (e) {
      console.error('Appcues is not setup up correctly')
    }
  }, [])
}
