import { PermissionResource, useRoleplay } from '@hub-la/fe-roleplay'
import { Button, DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@hub-la/shadcn'
import { MoreHorizontal, RefreshCcw, Trash2 } from 'lucide-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { MemberType } from '../../../../domain/enums/member-type'
import { SubscriptionTag } from '../../../components'

type RowMemberActionsProps = {
  type: MemberType
  onRemoveFreeMember: () => void
  onChangeCohort: () => void
}

export const RowMemberActions: React.FC<RowMemberActionsProps> = ({ type, onRemoveFreeMember, onChangeCohort }) => {
  const { t } = useTranslation()
  const { hasPermission } = useRoleplay()

  const isFreeType = [MemberType.FREE].includes(type)

  return (
    <div className="flex items-center justify-end gap-2 mr-3">
      <SubscriptionTag type={type} />
      {hasPermission(PermissionResource.PRODUCTS_MANAGE_MEMBERS) && type !== MemberType.SELLER && (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" size="sm">
              <MoreHorizontal className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem onClick={onChangeCohort}>
              <RefreshCcw className="mr-2 h-4 w-4" />
              <span>{t('tabs.members.changeCohortMember')}</span>
            </DropdownMenuItem>
            {isFreeType && (
              <DropdownMenuItem onClick={onRemoveFreeMember}>
                <Trash2 className="mr-2 h-4 w-4" />
                <span>{t('tabs.members.removeFreeMember')}</span>
              </DropdownMenuItem>
            )}
          </DropdownMenuContent>
        </DropdownMenu>
      )}
    </div>
  )
}
