import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useQuery } from '@tanstack/react-query'
import { GetUserVerificationUseCase } from '../../usecases/get-user-verification'

export const useGetUserVerification = (userId?: string) => {
  const container = useContainer()

  return useQuery(
    [`get-user-verification-${userId}`],
    () => new GetUserVerificationUseCase(container.get(HttpClient)).execute(userId),
    {
      refetchOnWindowFocus: false,
      initialData: [],
      retry: false,
      enabled: !!userId,
    },
  )
}
