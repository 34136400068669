import { PostResponse, SectionResponse } from '@hub-la/fe-post'
import { useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable, DroppableProps } from 'react-beautiful-dnd'
import { reorder } from '../../../usecases/reorder'
import { useReorderPosts } from '../../hooks/use-reorder-posts'
import { PostItem } from '../post-item/post-item'
import { EmptyState } from './empty-state'

// This component is a workaround to avoid the following error
// on the lib itself with react 18 strict mode:
// react-beautiful-dnd: Unable to find draggable with id
// https://github.com/atlassian/react-beautiful-dnd/issues/2407
const StrictModeDroppable = ({ children, ...props }: DroppableProps) => {
  const [enabled, setEnabled] = useState(false)
  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true))
    return () => {
      cancelAnimationFrame(animation)
      setEnabled(false)
    }
  }, [])

  if (!enabled) {
    return null
  }

  return <Droppable {...props}>{children}</Droppable>
}

type Props = {
  posts: PostResponse[]
  productId: string
  sectionId: string
  userId: string
  sections: SectionResponse[]
}

export const PostList: React.FC<Props> = ({ posts, sections, productId, sectionId, userId }) => {
  const reorderPosts = useReorderPosts()

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const destination = result.destination.index
    const source = result.source.index
    const section = sections.find((section) => section.id === sectionId)

    if (!section) {
      return
    }

    reorderPosts.mutateAsync({
      postIds: reorder<PostResponse>(posts, source, destination).map((post) => post.id),
      destination,
      source,
      id: sectionId,
      name: section.name,
      productId,
    })
  }

  const hasPosts = posts.length > 0

  return (
    <div className="flex flex-col space-y-2" data-testid="posts-container">
      {hasPosts ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <StrictModeDroppable droppableId="posts">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {posts.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.draggableProps}>
                        <PostItem
                          dragHandleProps={provided.dragHandleProps}
                          productId={productId}
                          post={item}
                          sectionId={sectionId}
                          userId={userId}
                          sections={sections}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}

                {provided.placeholder}
              </div>
            )}
          </StrictModeDroppable>
        </DragDropContext>
      ) : (
        <EmptyState />
      )}
    </div>
  )
}
