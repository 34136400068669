import { formatDate } from '../../../domain/vos/format-date'
import { GetComparativeDateRange } from '../../../usecases/get-comparative-date-range'
import { SelectDateRangeCalendar } from '../../components/select-date-range-calendar'
import { SelectOffers } from '../../components/select-offers'

export const Filters = ({ setFilters, filters, offers, isLoading }) => {
  const { startAt, endAt } = new GetComparativeDateRange().execute(filters.period, filters.startDate, filters.endDate)
  return (
    <div className="flex flex-col md:flex-row md:items-center gap-3">
      <SelectOffers
        offers={offers}
        value={filters.offerIds}
        onChange={(value) => setFilters((prevState) => ({ ...prevState, offerIds: value, page: 1 }))}
        isLoading={isLoading}
      />
      <SelectDateRangeCalendar filters={filters} setFilters={setFilters} />
      <p className="text-sm text-muted-foreground">
        em comparação com o período anterior
        {` (${formatDate(startAt, filters.period)} - ${formatDate(endAt, filters.period)})`}.
      </p>
    </div>
  )
}
