import { Table, TableBody, TableCell, TableEmptyState, TableHead, TableHeader, TableRow } from '@hub-la/shadcn'
import times from 'lodash/times'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { Affiliation } from '../../../domain/dtos/affiliation'
import { useListAffiliations } from '../../hooks/use-list-affiliations'
import { AffiliateStatusStats } from './affiliate-status-stats'
import { AffiliatesTableLoading } from './affiliates-table-loading'
import { GetLinkModal } from './get-link-modal'
import { useRefetch } from '../../hooks/use-refetch'

interface AffiliatesTable {
  filters: any
}

export const AffiliatesTable: React.FC<AffiliatesTable> = ({ filters }) => {
  const { search, statuses: status } = filters
  const { data, isFetching, refetch } = useListAffiliations({
    filters: {
      search,
      status: status.length ? status : undefined,
    },
  })
  const { t } = useTranslation()

  const headCells = [
    { key: 'product', label: 'Produto', sortable: false, width: 250 },
    { key: 'status', label: 'Status', sortable: false, width: 90 },
    { key: 'commissioning', label: 'Comissão', sortable: false, width: 60, tooltip: 'Comissão de venda/renovação' },
    { key: 'createdAt', label: 'Data de afiliação', sortable: false, width: 90 },
    { key: 'actions', label: '', sortable: false, width: 40 },
  ]

  const isRowsEmpty = !data || data.length === 0
  const canRenderEmptyState = !isFetching && isRowsEmpty

  useRefetch({ ...filters }, refetch)

  return (
    <div className="flex flex-col gap-4 mt-2">
      <div className="rounded-md border bg-background">
        <Table>
          <TableHeader>
            <TableRow>
              {headCells.map((cell) => (
                <TableHead key={cell.key} className="font-medium" style={{ minWidth: cell.width }}>
                  {cell.label}
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>

          <TableBody>
            {isFetching
              ? times(10, (i) => <AffiliatesTableLoading key={`skeleton-row-${i}`} />)
              : (data || []).map((affiliation: Affiliation) => (
                  <TableRow key={affiliation.affiliationId}>
                    <TableCell className="font-medium">
                      <div className="flex items-center space-x-4">
                        <img
                          src={affiliation.offerPicture ?? '/placeholder-image.svg'}
                          alt={affiliation.offerName}
                          className="w-16 h-auto aspect-[218/142] rounded object-cover"
                        />

                        <div>
                          <p className="font-medium">{affiliation.offerName}</p>
                          <p className="text-xs text-muted-foreground">{affiliation.offerId}</p>
                        </div>
                      </div>
                    </TableCell>

                    <TableCell>
                      <AffiliateStatusStats status={affiliation.affiliationStatus} />
                    </TableCell>

                    <TableCell>
                      {affiliation.commission.sell.percent}% / {affiliation.commission.renewal.percent}%
                    </TableCell>

                    <TableCell>{moment(affiliation.createdAt).format('D MMM. YYYY')}</TableCell>

                    <TableCell className="justify-end">
                      {affiliation.affiliationStatus !== 'disabled' && (
                        <GetLinkModal affiliationId={affiliation.affiliationId} linkDetails={affiliation.linkDetails} />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>

        {canRenderEmptyState && <TableEmptyState title={t('empty')} />}
      </div>
    </div>
  )
}
