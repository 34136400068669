import { InferType, object, string } from 'yup'

const transformNumber = (originalValue) => {
  if (!originalValue) return 0

  const value = String(originalValue).replace(/\D/g, '')

  return Number(value) / 100
}

const orderBumpSchemaValidation = object({
  productId: string().nullable().required(),
  offerId: string().nullable().required(),
  title: string(),
  description: string(),
  callToAction: string()
  /* Is used to validate the oldTotal field with offer price */
}).required()

export type OrderBumpSchemaValidationType = InferType<typeof orderBumpSchemaValidation>

export { orderBumpSchemaValidation }
