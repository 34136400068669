export const pt = {
  editGroup: {
    mobilePageTitle: 'Produto',
  },
  navBar: {
    profile: 'Ver perfil',
    products: 'Meus Produtos',
    editProfile: 'Editar perfil',
    accountVerified: 'Conta verificada',
    verifyAccount: 'Verificar conta',
    bankAccount: 'Conta bancária',
    affiliates: 'Receitas de afiliados',
    tipsAndResources: 'Dicas e recursos',
    faq: 'Ajuda',
    ourBlog: 'Nosso blog',
    logout: 'Sair',
    changeMode: {
      creator: 'Usar Modo Criador',
      member: 'Usar Modo Membro',
    },
  },
  closeAlertModal: {
    title: 'Alterações não salvas',
    description: 'As alterações realizadas não foram salvas. Deseja sair mesmo assim?',
    stay: 'Ficar na página',
    leave: 'Sair e descartar',
  },
  loadingAlertModal: {
    title: 'Aguarde antes de sair',
    description: 'Estamos processando o upload de um arquivo. Aguarde até que o processo seja concluído.',
    stay: 'Ok, aguardar na página',
  },
  pdf: {
    pagination: 'Página {{pageNumber}} de {{numPages}}',
  },
  dashboard: {
    header: {
      title: 'Área de membros',
      description: 'Compartilhe conteúdo exclusivo para seus membros',
      createSection: 'Criar módulo',
      recomendationInfo:
        'Iremos recomendar que seus novos membros consumam seu conteúdo a partir da primeira seção da página.',
    },
    button: {
      addPost: 'Adicionar conteúdo',
      editList: 'Editar lista',
    },
    popover: {
      videos: 'Video',
      files: 'Ebook',
      event: 'Lives & Eventos',
      post: 'Publicação',
    },
    labels: {
      draft: 'Rascunho',
    },
    menu: {
      edit: 'Editar conteúdo',
      view: 'Visualizar conteúdo',
      editCover: 'Editar capa',
      remove: 'Deletar',
      moveTo: 'Mover para',
    },
  },
  eventForm: {
    title: 'Dados da live',
    link: 'Link da live',
    date: 'Data',
    startTime: 'Início',
    endTime: 'Término',
    notifyGroup: 'Notificar o grupo',
    knowMore: 'Saiba mais',
    alertStartThirtyDays: 'Lives com data de início superior a 30 dias não serão notificadas no início da live.',
  },
  content: {
    title: 'Adicione uma mídia ao seu conteúdo',
    allowedTypes: 'Você pode enviar arquivos em pdf, imagem ou vídeo',
    form: {
      title: 'Título do conteúdo*',
      subtitle: 'Subtítulo do conteúdo',
      content: 'Escreva sobre esse conteúdo...',
    },
    dialog: {
      insertImage: 'Inserir imagem',
      editImage: 'Editar imagem',
      insertVideo: 'Inserir vídeo',
      loading: 'Carregando...',
    },
    advantages: {
      posts: 'Envie artigos, vídeos, e-books e gravações de lives',
      customize: 'Personalize a área de membros com o seu branding',
      events: 'Gestão de eventos com convites e notificações automatizadas',
    },
  },
  publication: {
    save: {
      success: 'Conteúdo salvo com sucesso',
      error: 'Falha ao salvar conteúdo',
    },
    publish: {
      success: 'Conteúdo publicado com sucesso',
      error: 'Falha ao criar conteúdo',
    },
  },
  mediaUpload: {
    progress: '{{progress}}% Carregados...',
    processing: 'Estamos processando o vídeo ...',
    progressWarning: 'Vídeo em carregamento',
    loadingThumbnail: 'Gerando thumbnail...',
    waitLabel: 'Aguarde...',
    success: 'Carregado!',
    removeAsset: 'Remover',
    cancelRequest: 'Cancelar',
    header: {
      create: 'Novo conteúdo',
      update: 'Editar conteúdo',
      save: 'Salvar',
      saved: 'Salvo!',
      published: 'Publicado',
      next: 'Publicar',
    },
    modal: {
      delete: {
        title: 'Remover aquivo',
        description: 'Você poderá adicionar um outro arquivo novamente.',
        btnNext: 'Remover',
        btnPrev: 'Manter',
      },
      cancel: {
        title: 'Cancelar carregamento',
        description: 'O arquivo será removido automaticamente mas você poderá adicionar outro novamente.',
        btnNext: 'Cancelar',
        btnPrev: 'Manter o carregamento',
      },
    },
    error: {
      default: 'Falha ao enviar o arquivo',
      image: 'Falha ao enviar a imagem',
      video: 'Falha ao enviar o vídeo',
      pdf: 'Falha ao enviar o pdf',
      media: 'Falha ao enviar mídia',
      size: 'Arquivo muito grande, selecione arquivos abaixo de 256 GB',
      tryAgain: 'Tente novamente',
      remove: 'Falha ao remover arquivo',
    },
  },
  mediaLoad: {
    error: {
      pdf: 'Falha ao carregar o pdf.',
    },
  },
  customCoverUpload: {
    post: {
      title: 'Capa do conteúdo',
      description:
        'Adicione uma imagem que mostre o que há no seu conteúdo. Uma boa capa se destaca e chama a atenção dos seus membros.',
    },
    title: 'Editar capa',
    subtitle: 'Você pode escolher a capa que quiser para os membros verem seu conteúdo',
    done: 'Concluir',
    sizeTip: 'O tamanho ideal é de 319x498px (9:16)',
    bottomText: 'Envie uma imagem do seu dispositivo para definir a capa do seu conteúdo.',
    cover: 'Capa',
  },
  confirmationModal: {
    title: 'Tem certeza que deseja excluir a seção e seus conteúdos?',
    subtitle:
      'Ao excluir esta seção, você apagará os conteúdos dela. Se você deseja mantê-los, recomendamos mover para outra seção antes de excluir esta.',
    confirm: 'Excluir seção e conteúdos',
    cancel: 'Cancelar',
  },
  editSection: {
    title: 'Editar seção',
    done: 'Salvar',
    editOrder: 'Organizar conteúdos',
    types: {
      text: 'Texto',
      application: 'Pdf',
      video: 'Video',
      image: 'Imagem',
    },
  },
  editReleaseDays: {
    title: 'Agendar liberação do conteúdo',
    subtitle:
      'Escolha o prazo de dias que seu membro terá que esperar para ter acesso ao módulo após a data da compra.',
    cancel: 'Cancelar agendamento',
    save: 'Salvar agendamento',
    customValue: 'Outro prazo',
    customValueLabel: 'Prazo personalizado (máximo de 99 dias)',
    tooltip:
      'Diversos criadores diminuem seus pedidos de reembolso ao agendar a liberação de parte do seu conteúdo após 7 dias.',
  },
  emptyState: {
    title: 'Seções',
    subtitle: 'Você pode começar adicionando uma seção para organizar melhor seus conteúdos.',
    button: 'Adicionar módulo',
  },
  assets: {
    title: 'Anexos',
    new: 'Adicionar anexos',
    download: 'Download',
    processing: 'O arquivo ainda está sendo processado',
    error: 'Houve um erro ao processar o arquivo',
    remove: 'Remover',
    canceled: 'cancelado',
    uploadError: 'Erro ao carregar o anexo',
  },
  button: {
    addFile: 'Adicionar mídia',
  },
}
