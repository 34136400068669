import { Button, Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@hub-la/shadcn'
import { useFormikContext } from 'formik'
import { Info } from 'lucide-react'
import moment from 'moment'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import NumberFormat from 'react-number-format'
import { useField } from '../hooks/use-field'
import { Values } from '../pages/account-verification-modal'
import CustomDatePicker from './custom-date-picker'
import { PhoneNumber } from './fields/phone-number'
import { InputWrapper } from './input-wrapper'

const NumberFormatWrapper = ({ error, helperText, ...props }) => (
  <NumberFormat customInput={InputWrapper} error={error} helperText={helperText} {...props} />
)

export const CpfForm = () => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false)

  const { t } = useTranslation()
  const { hasError, getErrorText } = useField()
  const { values, handleBlur, handleChange, setFieldValue } = useFormikContext<Values>()

  return (
    <div className="flex flex-col space-y-4">
      <div className="flex items-center space-x-1">
        <span>{t('accountVerification.form.cpf.label')}</span>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant="ghost"
                className="h-6 w-6 p-0"
                onMouseEnter={() => setIsTooltipOpen(true)}
                onMouseLeave={() => setIsTooltipOpen(false)}
              >
                <Info className="h-4 w-4" />
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>{t('accountVerification.form.cpf.tooltip')}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
      <div className="flex flex-col space-y-4">
        <InputWrapper
          error={hasError('identity.fullName')}
          helperText={getErrorText('identity.fullName') || t('fullNameHelper')}
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.identity.fullName}
          type="text"
          id="identity.fullName"
          placeholder={t('fullName')}
        />
        <NumberFormatWrapper
          error={hasError('identity.document.value')}
          helperText={getErrorText('identity.document.value') || t('cpfHelper')}
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.identity.document.value}
          type="tel"
          id="identity.document.value"
          placeholder={t('cpf')}
          format="###.###.###-##"
        />
        <CustomDatePicker
          value={values.identity.birthday ? moment(values.identity.birthday) : null}
          setFieldValue={setFieldValue}
        />
        <InputWrapper
          error={hasError('identity.email')}
          helperText={getErrorText('identity.email')}
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.identity.email}
          type="email"
          id="identity.email"
          placeholder={t('email')}
        />
        <PhoneNumber
          onChange={(value) => value && setFieldValue('identity.phone', value.toString())}
          value={values.identity.phone}
          error={hasError('identity.phone')}
          id="identity.phone"
          placeholder={t('phoneNumber')}
          helperText={getErrorText('identity.phone')}
          onBlur={handleBlur}
        />
      </div>
    </div>
  )
}
