import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@hub-la/shadcn'
import { Download, Undo2 } from 'lucide-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useRefundInvoice } from '../../hooks/use-refund-invoice'

type Props = {
  invoiceId: string
}

export const RefundInvoiceModal: React.FC<Props> = (props) => {
  const { invoiceId } = props
  const { mutateAsync: refundInvoice, isLoading, isError, error, isSuccess } = useRefundInvoice()

  const { t } = useTranslation()

  const [isRefundInvoiceOpen, setIsRefundInvoiceOpen] = React.useState<boolean>(false)

  const onSubmit = () => {
    refundInvoice(invoiceId).finally(() => {
      setIsRefundInvoiceOpen(true)
    })
  }

  function getModalMessage() {
    if (isSuccess) return t('success')
    return t((error as Error)?.message)
  }

  if (isSuccess || isError) {
    return (
      <Dialog open={isRefundInvoiceOpen} onOpenChange={() => setIsRefundInvoiceOpen((prevState) => !prevState)}>
        <DialogContent className="z-10002">
          <DialogHeader>
            <DialogTitle>{t('exportXlsx.title')}</DialogTitle>
          </DialogHeader>
          <div className="py-4">
            <p>{getModalMessage()}</p>
          </div>
          <div className="flex justify-end">
            <DialogClose>
              <Button variant="outline">{t('exportXlsx.getIt')}</Button>
            </DialogClose>
          </div>
        </DialogContent>
      </Dialog>
    )
  }

  return (
    <Dialog open={isRefundInvoiceOpen} onOpenChange={() => setIsRefundInvoiceOpen((prevState) => !prevState)}>
      <DialogTrigger asChild>
        <Button variant="outline" className="w-full">
          <Undo2 className="w-4 h-4 mr-2" />
          Reembolsar fatura
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px] z-10002">
        <DialogHeader>
          <DialogTitle>Reembolsar fatura</DialogTitle>
        </DialogHeader>
        <DialogDescription>
          Ao reembolsar uma fatura, o membro perderá o acesso ao seu produto. Essa ação é irreversível.
        </DialogDescription>
        <DialogFooter className="gap-2">
          <DialogClose>
            <Button variant="outline" className="w-full">
              Cancelar
            </Button>
          </DialogClose>
          <Button onClick={onSubmit} loading={isLoading} className="flex gap-2">
            <Download className="h-4 w-4" />
            Reembolsar fatura
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
