import { ChevronDown } from 'lucide-react'
import { Country } from 'react-phone-number-input'
import PhoneNumber from 'react-phone-number-input/mobile'
import { PhoneField } from './phone-field'

/**
 * About the `no-explicit-any` bypass:
 *
 * The lib itself uses any, so there's nothing we can do about it here.
 */
interface Props {
  value: string
  label: string
  disabled?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (value?: any | undefined) => void

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onBlur?: (e: React.FocusEvent<any, Element>) => void

  error?: boolean | undefined

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  helperText?: any

  // Default values are fine.
  defaultCountry?: Country
  placeholder?: string

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inputComponent?: any
  countrySelectProps?: object | undefined
  id?: string
}

/** Provides a component that shows the country flag inside the input field. */
export const PhoneNumberStyled = (props: Props) => {
  const defaultProps = {
    defaultCountry: 'BR' as Country,
    placeholder: '( )',
    inputComponent: PhoneField,
    countrySelectProps: {
      arrowComponent: ChevronDown,
    },
  }

  return (
    <div className="w-full flex flex-col">
      <PhoneNumber
        {...defaultProps}
        {...props}
        className="relative w-full [&_.PhoneInputCountry]:absolute [&_.PhoneInputCountry]:z-10 [&_.PhoneInputCountry]:top-6 [&_.PhoneInputCountry]:left-2.5 [&_.PhoneInputCountry]:pt-2 [&_.PhoneInputCountry]:pb-2 [&_input]:pl-16 [&_input]:w-full"
      />
    </div>
  )
}
