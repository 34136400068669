export const Envs = {
  RUDDERSTACK_WRITE_KEY: String(
    process.env['NX_RUDDERSTACK_WRITE_KEY'] ??
      process.env['REACT_APP_RUDDERSTACK_TOKEN'] ??
      process.env['NEXT_PUBLIC_RUDDERSTACK_WRITE_KEY'],
  ),
  RUDDERSTACK_DATA_PLANE_URL: String(
    process.env['NX_RUDDERSTACK_DATA_PLANE_URL'] ??
      process.env['REACT_APP_RUDDERSTACK_DATA_PLANE_URL'] ??
      process.env['NEXT_PUBLIC_RUDDERSTACK_DATA_PLANE_URL'],
  ),
  RUDDERSTACK_IS_DEBUG: String(
    process.env['NX_RUDDERSTACK_IS_DEBUG'] ??
      process.env['REACT_APP_RUDDERSTACK_IS_DEBUG'] ??
      process.env['NEXT_PUBLIC_RUDDERSTACK_IS_DEBUG'],
  ),
}
