import { Label } from '@hub-la/shadcn'
import React from 'react'

export interface OptionProps {
  icon: React.ReactNode
  label: string
  value: 'withDeadline' | 'withoutDeadline'
  checked: boolean
}

export interface ExpiresDateOptionProps extends OptionProps {
  name: string
  onChange: (value: 'withDeadline' | 'withoutDeadline') => void
  error?: boolean
}

export const ExpiresDateOption: React.FC<ExpiresDateOptionProps> = ({
  icon,
  label,
  name,
  onChange,
  value,
  error,
  checked,
  ...props
}) => {
  return (
    <Label className="cursor-pointer w-full h-full">
      <input
        type="radio"
        className="peer sr-only"
        checked={checked}
        name={name}
        value={value}
        onClick={() => onChange(value)}
        {...props}
      />
      <div
        className={`
        w-full h-full p-4 rounded border transition-colors duration-200
        ${error ? 'border-red-500' : 'border-gray-300'}
        peer-checked:bg-primary-100 peer-checked:border-primary
        flex flex-col items-center
      `}
      >
        {icon}
        <p className="mt-2 text-sm max-w-[70%] text-center">{label}</p>
      </div>
    </Label>
  )
}
