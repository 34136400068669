import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useToast } from '@hub-la/shadcn'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { BindOrUnbindResourceToProductInput } from '../../../domain/dtos/member-area/bind-or-unbind-resource-to-product-input'
import { QueryKey } from '../../../domain/enums/query-key'
import { BindOrUnbindResourceToProduct } from '../../../usecases/member-area/bind-or-unbind-resource-to-product'

interface UseBindResourceToProductInterface {
  action: 'bind' | 'unbind'
}

export const useBindOrUnbindResourceToProduct = ({ action }: UseBindResourceToProductInterface) => {
  const { toast } = useToast()
  const { t } = useTranslation()
  const container = useContainer()
  const queryClient = useQueryClient()

  return useMutation(
    action === 'bind' ? [QueryKey.bindResourceToProduct] : [QueryKey.unbindResourceToProduct],
    (input: BindOrUnbindResourceToProductInput) => {
      return new BindOrUnbindResourceToProduct(container.get(HttpClient)).execute(input, action)
    },
    {
      retry: false,
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries({
          queryKey: [QueryKey.getResources, variables.offerId, true],
          refetchType: 'all',
        })
        queryClient.invalidateQueries({
          queryKey: [QueryKey.getResources, variables.offerId, false],
          refetchType: 'all',
        })
      },
      onError: (error: Error) => toast({ description: t(error.message), variant: 'destructive' }),
    },
  )
}
