import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { ChangeMembersCohortsResult } from '@hub-la/sdk-bff-access'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ChangeMembersCohortsInput } from '../../../domain/dtos/members/change-members-cohorts-input'
import { QueryKey } from '../../../domain/enums/query-key'
import { PostChangeMembersCohorts } from '../../../usecases/members/change-members-cohorts'

export const usePostChangeMembersCohorts = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (data: ChangeMembersCohortsResult | null) => void
  onError?: () => void
}) => {
  const container = useContainer()
  const queryClient = useQueryClient()

  return useMutation(
    [QueryKey.postChangeMembersCohorts],
    (input: ChangeMembersCohortsInput) => new PostChangeMembersCohorts(container.get(HttpClient)).execute(input),
    {
      retry: false,
      onSuccess: (data) => {
        if (onSuccess) {
          onSuccess(data)
        }
      },
      onError: () => {
        if (onError) {
          onError()
        }
      },
      onSettled: async () => {
        queryClient.invalidateQueries({ queryKey: [QueryKey.getListCohorts] })
        queryClient.invalidateQueries({ queryKey: [QueryKey.getActiveMembers] })
      },
    },
  )
}
