import { AssetResponse, AssetStatus } from '@hub-la/fe-asset'
import { AlertCircle } from 'lucide-react'

type Props = {
  cover?: AssetResponse
}

/** @description We should display this fallback when video that is still being processed by Mux */
export const VideoProcessingCover = ({ cover }: Props) => {
  if (cover?.status !== AssetStatus.processing) {
    return null
  }

  return (
    <div
      data-testid="video-is-processing"
      className="relative h-full bg-secondary flex flex-col justify-center items-center"
    >
      <div className="bg-secondary w-22 h-22 flex justify-center items-center rounded-full">
        <AlertCircle size={42} />
      </div>

      <p className="text-muted-foreground text-center mt-5.5 max-w-sm">
        Vídeo ainda está sendo processado para uma melhor qualidade.
        <br /> Isso pode levar alguns minutos.
      </p>
    </div>
  )
}
