import { Button, Label, useIsMobile, useToast } from '@hub-la/shadcn'
import { X } from 'lucide-react'
import { useCallback, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Member } from '../../domain/dtos/member'
import { usePostTransformFreeMembers } from '../hooks/members/use-post-transform-free-members'
import { TransformFreeMemberModal } from './transform-free-member-modal'

type Props = {
  selectedItems: Member[]
  productId: string
  onCancel: () => void
  onSuccess: () => void
}

export const DrawerTransformFreeMembers: React.FC<Props> = ({ selectedItems, onCancel, onSuccess, productId }) => {
  const { t } = useTranslation()
  const { toast } = useToast()
  const [transformFreeMemberModalOpen, setTransformFreeMemberModalOpen] = useState<boolean>(false)
  const isMobile = useIsMobile()

  const { data, mutateAsync, isLoading, reset } = usePostTransformFreeMembers({
    onSuccess: () => {
      onSuccess()
      setTransformFreeMemberModalOpen(false)
    },
  })

  const successMembersToTransform = (data ?? []).filter((member) => member.isCreated)
  const errorMembersToTransform = (data ?? []).filter((member) => !member.isCreated)
  const showDrawer = selectedItems?.length > 0

  const showErrorToast = useCallback(() => {
    toast({
      variant: 'destructive',
      title: t('drawerTransformFreeMembers.snackbar.error', {
        users: errorMembersToTransform?.map((member) => member.name).join(', '),
      }),
      description: (
        <Trans i18nKey={'drawerTransformFreeMembers.snackbar.errorDescription'} components={{ strong: <strong /> }} />
      ),
    })
    reset()
  }, [errorMembersToTransform, t, toast, reset])

  const showSuccessToast = useCallback(() => {
    toast({
      title: t('drawerTransformFreeMembers.snackbar.success', { total: successMembersToTransform?.length }),
      duration: 3000,
    })
    setTimeout(reset, 3000)
  }, [successMembersToTransform, t, toast, reset])

  useEffect(() => {
    if (errorMembersToTransform.length > 0) {
      showErrorToast()
    }
  }, [errorMembersToTransform, showErrorToast])

  useEffect(() => {
    if (successMembersToTransform.length > 0) {
      showSuccessToast()
    }
  }, [successMembersToTransform, showSuccessToast])

  if (!showDrawer) return null

  return (
    <>
      <div className="fixed bottom-6 left-1/2 transform -translate-x-1/2 bg-background border  rounded-lg shadow-lg p-4 z-10 w-full md:w-auto">
        <div className="flex flex-col md:flex-row items-center gap-4">
          <div className="flex justify-between w-full md:w-auto">
            <Label>{t('drawerTransformFreeMembers.title', { total: selectedItems?.length })}</Label>

            {isMobile && <X className="w-4 h-4 cursor-pointer" onClick={onCancel} />}
          </div>

          <div className="flex flex-col md:flex-row gap-2">
            <Button
              variant="outline"
              size="sm"
              onClick={() => setTransformFreeMemberModalOpen(true)}
              disabled={isLoading}
            >
              {t('drawerTransformFreeMembers.actions.transform')}
            </Button>
          </div>

          <X className="hidden md:block cursor-pointer ml-auto w-4 h-4" onClick={onCancel} />
        </div>
      </div>

      <TransformFreeMemberModal
        open={transformFreeMemberModalOpen}
        onClose={() => setTransformFreeMemberModalOpen(false)}
        onSubmit={(value) => mutateAsync({ ...value, selectedItems })}
        userIds={selectedItems.map((member) => member?.id)}
        productId={productId}
        isLoading={isLoading}
      />
    </>
  )
}
