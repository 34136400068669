import { HttpClient } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { AssetResponse } from '../domain/dtos/asset-response'
import { SaveAssetRequest } from '../domain/dtos/save-asset-request'
import { SignedUrlResponse } from '../domain/dtos/signed-url-response'
import { GetAssetSignedUrl } from './get-asset-signed-url'
import { UploadAssetBySignedUrl } from './upload-asset-by-signed-url'

export class SaveAsset {
  public constructor(
    @inject(HttpClient) private readonly httpClient: HttpClient,
    private readonly onUploadProgres: (percentage: number) => void,
  ) {}

  public async execute(payload: SaveAssetRequest): Promise<AssetResponse> {
    const signedURL: SignedUrlResponse = await new GetAssetSignedUrl(this.httpClient).execute(payload)

    const data = await new UploadAssetBySignedUrl().execute({
      asset: signedURL.asset,
      file: payload.file,
      writeUrl: signedURL.writeUrl,
      mimeType: payload.mimeType,
      onUploadProgress: this.onUploadProgres,
    })

    return data
  }
}
