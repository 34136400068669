import { Card, CardContent, CardDescription, CardHeader } from '@hub-la/shadcn'
import { useTranslation } from 'react-i18next'
import { Offer } from '../../../../domain/dtos/offer'
import { formatLocalNumber } from '../../../../domain/vos/format-local-number'
import { InfoTooltip } from '../../../components/info-tooltip'
import { StatusBox } from '../../../components/status-box'
import { useGetAbandonedCheckout } from '../../../hooks/use-get-abandoned-checkout'
import { useRefetch } from '../../../hooks/use-refetch'
import { ConfirmExportModal } from './confirm-export-modal'
import { Loading } from './loading'

type AbandonedCheckoutProps = {
  startDate: string
  endDate: string
  offerIds: string[]
  offers: Offer[]
  isLoading: boolean
}

export const AbandonedCheckout = (props: AbandonedCheckoutProps) => {
  const { startDate, endDate, offers, offerIds, isLoading } = props

  const { t } = useTranslation()

  const { data, isFetching, refetch } = useGetAbandonedCheckout({ startDate, endDate, offers, offerIds }, isLoading)

  useRefetch({ startDate, endDate, offers, offerIds }, refetch)

  if (isFetching || isLoading) {
    return <Loading />
  }

  return (
    <div className="w-full h-full">
      <Card className="flex flex-col min-h-[128px] gap-2">
        <CardHeader className="pb-0 space-y-0 flex flex-col lg:flex-row justify-between">
          <div className="w-full">
            <div className="flex items-center gap-2">
              <p className="text-sm font-normal text-muted-foreground">{t('abandonedCheckout.title')}</p>
              <InfoTooltip title={t('abandonedCheckout.description')} />
            </div>
            <CardDescription className="flex flex-row gap-2 items-center">
              <h3 className="font-semibold text-4xl tracking-tight text-primary">{formatLocalNumber(data.current)}</h3>
            </CardDescription>
          </div>
          <div className="w-full lg:w-auto">
            <ConfirmExportModal filters={{ startDate, endDate, offerIds }} offers={offers} />
          </div>
        </CardHeader>
        <CardContent>
          <div className="flex items-start flex-wrap">
            <StatusBox
              indicator={data.indicator}
              status={data.status}
              description={t('variation', { previous: formatLocalNumber(data.previous) })}
            />
          </div>
        </CardContent>
      </Card>
    </div>
  )
}
