import { Auth } from '@hub-la/fe-core-auth'
import Cookies from 'js-cookie'
import decodeToken from 'jwt-decode'
import { TokenAttributes } from '../domain/dtos/token-attributes'
import { Envs } from '../envs'

export class UpdatedTokenAttributes {
  constructor(private readonly auth: Auth) {}

  public async execute() {
    const token = await this.getToken()
    if (!token) return null

    await this.updateStorageToken(token)

    return this.parseToData(token)
  }

  private async getToken() {
    // when next
    if (this.isSiteDomain()) {
      const session = Cookies.get('__session')
      return session
    }

    return await this.auth.getToken()
  }

  private updateStorageToken(token: string) {
    return new Promise((resolve) => {
      const userStorage = JSON.parse(localStorage.getItem('authUser') ?? '{}')
      localStorage.setItem(
        'authUser',
        JSON.stringify({
          ...userStorage,
          accessToken: token,
        }),
      )
      if (!this.isSiteDomain()) {
        Cookies.set('__session', token, { expires: 15, domain: Envs.COOKIE_DOMAIN })
      }

      resolve(true)
    })
  }

  private parseToData(token: string) {
    const attributes = decodeToken<TokenAttributes>(token) ?? {}

    const attributesRoleplayUserId = attributes?.roleplayUserId
    const attributesResource = attributes?.roleplayResources

    if (!attributesRoleplayUserId || !attributesResource) return null

    return {
      token,
      roleplayUserId: attributesRoleplayUserId,
      roleplayResources: attributesResource,
    }
  }

  // Check if is site next domain
  private isSiteDomain() {
    return window?.location?.origin?.includes(Envs.SITE_URL as string)
  }
}
