import { Tabs, TabsList, TabsTrigger } from '@hub-la/shadcn'
import { Monitor, Smartphone } from 'lucide-react'
import { useTranslation } from 'react-i18next'

export enum View {
  Mobile = 'mobile',
  Desktop = 'desktop',
}

interface SelectViewProps {
  onChange: (mode: View) => void
  value: View
}

export const SelectView = ({ onChange, value }: SelectViewProps) => {
  const { t } = useTranslation()

  return (
    <Tabs value={value} onValueChange={(value) => onChange(value as View)} className="mx-auto">
      <TabsList>
        <TabsTrigger value={View.Mobile}>
          <Smartphone className="mr-2 h-4 w-4 text-muted-foreground" />
          <span className="text-sm text-secondary-foreground">{t('checkoutSettings.view.mobile')}</span>
        </TabsTrigger>

        <TabsTrigger value={View.Desktop}>
          <Monitor className="mr-2 h-4 w-4 text-muted-foreground" />
          <span className="text-sm text-secondary-foreground">{t('checkoutSettings.view.desktop')}</span>
        </TabsTrigger>
      </TabsList>
    </Tabs>
  )
}
