import { Button, cn, Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@hub-la/shadcn'
import { Check } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReceiverStatus } from '../../../domain/enums/receiver-status'
import { useUpdateReceiverStatus } from '../../hooks/use-update-receiver-status'

type AcceptCancellationRequestProps = {
  receiverId: string
  onChange?: () => void
}

export const AcceptCancellationRequest = ({ receiverId, onChange }: AcceptCancellationRequestProps) => {
  const [open, setOpen] = useState<boolean>(false)

  const { t } = useTranslation()
  const { mutateAsync: updateStatus, isLoading } = useUpdateReceiverStatus()

  const handleCancellation = async () => {
    await updateStatus({ id: receiverId, status: ReceiverStatus.CANCELED })
    onChange?.()
    setOpen(false)
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <Button variant="outline" size="sm" disabled={isLoading} onClick={() => setOpen(true)}>
        <Check className="w-4 h-4 mr-1" />
        {t('partnerships.cancel')}
      </Button>

      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t('partnerships.acceptCancellationRequest.title')}</DialogTitle>
        </DialogHeader>

        <DialogFooter className={cn('sm:space-x-4', 'flex flex-col-reverse sm:flex-row justify-end items-center')}>
          <Button variant="outline" onClick={() => setOpen(false)}>
            {t('partnerships.back')}
          </Button>

          <Button variant="outline" onClick={handleCancellation} loading={isLoading}>
            {t('partnerships.cancel')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
