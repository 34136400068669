/* tslint:disable */
/* eslint-disable */
/**
 * BFF Web Product
 * API reference used to call the backend from the web frontend
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface ProductFilterRequestDto
 */
export interface ProductFilterRequestDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductFilterRequestDto
     */
    'status'?: Array<ProductFilterRequestDtoStatusEnum>;
    /**
     * 
     * @type {string}
     * @memberof ProductFilterRequestDto
     */
    'search'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ProductFilterRequestDtoStatusEnum {
    IS_DRAFT = 'isDraft',
    IS_PUBLISHED = 'isPublished',
    IS_SELLING = 'isSelling',
    IS_WAITLISTED = 'isWaitlisted',
    IS_NOT_ENABLED = 'isNotEnabled',
    IS_NOT_SELLING = 'isNotSelling',
    IS_UNAVAILABLE = 'isUnavailable',
    IS_DELETED = 'isDeleted'
}


