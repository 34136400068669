export enum ErrorCode {
  VERIFY_CODE_INVALID = 'verify-code-invalid',
  BLOCKED_USER_AUTH_ATTEMPT = 'blocked-user-auth-attempt',
  EMAIL_ALREADY_IN_USE = 'email-already-in-use',
  PHONE_NUMBER_ALREADY_IN_USE = 'phone-number-already-in-use',
  EMAIL_AND_PHONE_ALREADY_IN_USE = 'email-and-phone-already-in-use',
  INVALID_CAPTCHA = 'invalid-captcha',
  CODE_EXPIRED = 'code-expired',
  SEND_SMS_CODE = 'send-sms-code',
  SEND_WHATSAPP_CODE = 'send-whatsapp-code',
  SIGN_IN_BY_PHONE = 'sign-in-by-phone',
  SIGN_IN_BY_PHONE_VERIFY_CODE = 'sign-in-by-phone-verify-code',
  RECOVER_PASSWORD_NOT_FOUND = 'recover-password-not-found',
  RECOVER_PASSWORD = 'recover-password',
  SEND_RECOVER_PASSWORD_EMAIL = 'send-recover-password-email',
  USER_NOT_FOUND = 'user-not-found',
  COMPLETE_REGISTRATION = 'complete-registration',
  COMPLETE_REGISTRATION_VERIFY_CODE = 'complete-registration-verify-code',
  UNABLE_TO_CHANGE_EMAIL = 'unable-to-change-email',
  EMAIL_CODE = 'email-code',
  USER_DISABLED = 'user-disabled',
  IS_USING_PASSWORD = 'is-using-password',
  IS_USING_PASSWORD_EMAIL_CONFLICT = 'is-using-password-email-conflict',
}
