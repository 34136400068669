import { CopyClipboard } from '@hub-la/fe-copy-clipboard'
import { Button, useToast } from '@hub-la/shadcn'
import { Copy } from 'lucide-react'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

type Props = {
  qrcode: string
  code: string
}

export const Pix = ({ code, qrcode }: Props) => {
  const { t } = useTranslation()
  const { toast } = useToast()
  const [isLoading, setIsLoading] = useState(false)

  const handleCopyClipboard = async (code: string) => {
    setIsLoading(true)
    try {
      await new CopyClipboard().execute(code)
      toast({
        title: t('upgradePlanModal.pix.codeCopiedSuccessfully'),
        description: '',
        duration: 3000,
      })
    } catch (error) {
      console.error('Failed to copy:', error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className="flex flex-col space-y-2">
      <div className="flex flex-col items-center space-y-6 text-center">
        <p className="self-center">
          <Trans i18nKey="upgradePlanModal.pix.title" components={[<strong />]} />
        </p>

        {!qrcode ? (
          <div className="w-40 h-40 bg-secondary animate-pulse" />
        ) : (
          <img
            src={qrcode}
            alt="QR code pix"
            className="max-w-[160px] max-h-[160px] p-3 border border-gray-300 rounded-md self-center"
          />
        )}

        <Button
          variant="outline"
          className="w-full"
          onClick={() => handleCopyClipboard(code)}
          disabled={!code}
          loading={isLoading}
        >
          {<Copy className="mr-2 h-4 w-4" />}
          {t('upgradePlanModal.pix.copyCode')}
        </Button>
      </div>
      <p className="text-left text-sm text-muted-foreground">{t('upgradePlanModal.pix.footer')}</p>
    </div>
  )
}
