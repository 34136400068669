import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation } from '@tanstack/react-query'
import { QueryKey } from '../../domain/dtos/query-keys'
import { UserLoginPreferencesInput } from '../../domain/dtos/user-login-preferences-input'
import { PutUserLoginPreferences } from '../../usecases/put-user-login-preferences'

export const usePutUserLoginPreferences = () => {
  const container = useContainer()

  return useMutation(
    [QueryKey.putUserLoginPreferences],
    (input: UserLoginPreferencesInput) => new PutUserLoginPreferences(container.get(HttpClient)).execute(input),
    {
      retry: false,
    },
  )
}
