import { container } from '@hub-la/fe-container'
import { IntegrationDetailPage, IntegrationProvider, IntegrationsProvider } from '@hub-la/fe-integrations'
import { useParams } from 'react-router-dom'

export const IntegrationDetailInit = () => {
  const params = useParams<{ integration: IntegrationProvider }>()

  return (
    <IntegrationsProvider container={container}>
      <IntegrationDetailPage provider={params.integration} />
    </IntegrationsProvider>
  )
}
