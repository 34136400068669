export const pt = {
  dropdown: {
    header: {
      showProfile: 'Ver perfil',
      editProfile: 'Editar perfil',
    },
    links: {
      settings: 'Configurações',
      dashboard: 'Dashboard de produtor',
      dashboardFromMembersPage: 'Acessar dashboard de produtor',
      purchaseFromMembersPage: 'Minhas compras',
      purchases: 'Acessar minhas compras',
      affiliates: 'Afiliados',
      blog: 'Hubla blog',
      support: 'Suporte & ajuda',
      bankAccount: 'Conta bancária',
      collaborators: 'Gestão de colaboradores',
      selectAccount: 'Acessar outra conta',
      logout: 'Sair da conta',
      appearance: {
        title: 'Aparência',
        submenu: {
          light: 'Modo claro',
          dark: 'Modo escuro',
          system: 'Seguir preferência do sistema',
        },
      },
    },
  },
  leaveNewExperienceModal: {
    title: 'Ajude-nos a melhorar!',
    subtitle: 'O que fez você optar por voltar para a experiência anterior?',
    input: {
      helperText: 'Motivo *',
      placeholder: 'Explique o motivo para voltar para a experiência anterior',
    },
    footer: {
      confirm: 'Voltar para a experiência anterior',
      cancel: 'Cancelar',
    },
  },
}
