import {
  Badge,
  Button,
  cn,
  Command,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Separator,
} from '@hub-la/shadcn'
import { CheckIcon, PlusCircle } from 'lucide-react'
import { invoiceType } from '../../domain/constants/invoice-type'

export const SelectType = ({ filters, setFilters }) => {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="outline">
          <PlusCircle className="h-4 w-4 mr-1" />
          <span className="text-sm">Tipo</span>
          {filters.types.length > 0 && (
            <>
              <Separator orientation="vertical" className="mx-2 h-4" />
              <Badge variant="secondary" className="px-1 font-normal lg:hidden">
                {invoiceType.find((type) => type.id === filters.types[0])?.label}
              </Badge>
              <div className="hidden space-x-1 lg:flex">
                {filters.types.length > 2 ? (
                  <Badge variant="secondary" className="px-1 font-normal">
                    {filters.types.length} selecionados
                  </Badge>
                ) : (
                  filters.types.map((typeId) => (
                    <Badge variant="secondary" key={typeId} className="px-1 font-normal">
                      {invoiceType.find((type) => type.id === typeId)?.label}
                    </Badge>
                  ))
                )}
              </div>
            </>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0 z-10002" align="start">
        <Command>
          <CommandInput placeholder="Tipo de Fatura" />
          <CommandList>
            <CommandGroup>
              {invoiceType.map(({ id, label }) => {
                const checked = filters.types.includes(id)
                return (
                  <CommandItem
                    key={id}
                    onSelect={() => {
                      const newTypes = filters.types.includes(id)
                        ? filters.types.filter((type) => type !== id)
                        : [...filters.types, id]
                      setFilters({ ...filters, types: newTypes })
                    }}
                  >
                    <div
                      className={cn(
                        'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                        checked
                          ? 'bg-hubla-primary text-primary-foreground border-hubla-primary'
                          : 'opacity-50 [&_svg]:invisible',
                      )}
                    >
                      <CheckIcon className={cn('h-4 w-4')} />
                    </div>
                    {label}
                  </CommandItem>
                )
              })}
            </CommandGroup>
            {filters.types.length > 0 && (
              <>
                <CommandSeparator />
                <CommandGroup>
                  <CommandItem
                    onSelect={() => setFilters({ ...filters, types: [] })}
                    className="justify-center text-center"
                  >
                    Limpar filtros
                  </CommandItem>
                </CommandGroup>
              </>
            )}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}
