import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { SaveTemporaryBusinessInput } from '../domain/dtos/save-temporary-business-input'
import { GeneralError } from '../domain/errors/general'
import { Envs } from '../envs'
export class SaveTemporaryBusiness {
  public constructor(@inject(HttpClient) private readonly httpClient: HttpClient) {}

  public async execute(data: SaveTemporaryBusinessInput): Promise<void> {
    const response = await this.httpClient.request({
      method: HttpMethod.POST,
      url: this.makeUrl(),
      body: this.makeBody(data),
    })

    if ([HttpStatusCode.OK, HttpStatusCode.CREATED].includes(response.statusCode)) {
      return response.data
    } else {
      /** @todo: Handle backend errors */
      throw new GeneralError()
    }
  }

  private makeBody(data: SaveTemporaryBusinessInput): SaveTemporaryBusinessInput {
    return {
      ...data,
    }
  }

  private makeUrl() {
    return `${Envs.BFF_WEB_URL}/kyc/save-temporary-business`
  }
}
