import { Button, CopyClipboardButton } from '@hub-la/shadcn'
import { ChevronLeft } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { InvoiceStatus } from '../../../domain/enums/invoice-status'
import { ReceiverRole } from '../../../domain/enums/receiver-role'
import { SmartInstallmentStatus } from '../../../domain/enums/smart-installment-status'
import { InvoiceInstallmentsStats } from '../../components/invoice-installments-stats'
import { InvoiceStatusStats } from '../../components/invoice-status-stats'
import { RefundInvoiceModal } from './refund-invoice-modal'

type Props = {
  id: string
  total: string
  status: InvoiceStatus
  smartInstallmentStatus?: SmartInstallmentStatus
  installment: number
  installments: number
  role: ReceiverRole
  onChange: () => void
  onClose: () => void
  smartInstallmentId?: string
}

export const Header = ({
  id,
  total,
  status,
  smartInstallmentStatus,
  installment,
  installments,
  role,
  onChange,
  onClose,
  smartInstallmentId,
}: Props) => {
  const history = useHistory()
  const { t } = useTranslation()

  const canRefund = role === ReceiverRole.ROLE_SELLER && status === InvoiceStatus.PAID

  return (
    <div className="flex flex-col md:flex-row md:items-center justify-between">
      <div className="space-y-2">
        <Button className="h-4 px-0" size="sm" variant="link" onClick={() => history.goBack()}>
          <ChevronLeft className=" w-4 h-4 mr-2" />
          Voltar
        </Button>
        <div className="flex items-center gap-3 mb-2">
          <h3 className="text-3xl font-semibold">{total}</h3>
          <InvoiceStatusStats status={status} />
          {smartInstallmentStatus && (
            <InvoiceInstallmentsStats
              installment={installment}
              installments={installments}
              smartInstallmentId={smartInstallmentId}
            />
          )}
        </div>
        <div>
          <CopyClipboardButton
            copyContent={id}
            label={id}
            tooltipCopiedLabel={t('copyClipboard.invoiceIdCopied')}
            tooltipCopyLabel={t('copyClipboard.copyInvoiceId')}
          />
        </div>
      </div>
      <div className="mt-4 space-x-2 h-full">{canRefund && <RefundInvoiceModal invoiceId={id} />}</div>
    </div>
  )
}
