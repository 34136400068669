import { Button, Card, CardContent, Separator, Skeleton } from '@hub-la/shadcn'
import { ArrowLeft, CreditCard, RefreshCw } from 'lucide-react'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { AppHeader } from './app-header'

export const Loading: React.FC = () => {
  const history = useHistory()

  return (
    <div className="flex flex-col gap-4">
      <AppHeader />
      <div className="max-w-2xl mx-auto px-4 sm:px-6 lg:px-8 pb-10 container">
        <Button variant="ghost" className="mb-6 pl-0" onClick={() => history.goBack()}>
          <ArrowLeft className="mr-2 h-4 w-4" />
          <Skeleton className="h-4 w-28" />
        </Button>

        <Card>
          <CardContent className="p-6">
            <div className="flex flex-col sm:flex-row items-start sm:items-center gap-4 mb-6">
              <Skeleton className="w-24 h-[66px] rounded-lg" />
              <div>
                <Skeleton className="h-8 w-40 mb-2" />
                <Skeleton className="h-4 w-32" />
              </div>
            </div>

            <Separator className="my-6" />

            <div className="grid grid-cols-2 sm:grid-cols-1 gap-6">
              {[1, 2].map((i) => (
                <div key={i} className="flex items-start gap-3">
                  {i === 1 ? <CreditCard className="mt-1" /> : <RefreshCw className="mt-1" />}
                  <div>
                    <Skeleton className="h-5 w-36 mb-1" />
                    <Skeleton className="h-4 w-24" />
                  </div>
                </div>
              ))}
            </div>
          </CardContent>
        </Card>

        <div className="flex flex-row justify-end space-x-4 mt-6">
          <Button variant="outline" onClick={() => history.goBack()}>
            Cancelar
          </Button>
          <Button loading>Afiliar</Button>
        </div>
      </div>
    </div>
  )
}
