export class GetUtmsAndCleanUrl {
  public execute(value: string): { url: string; params: { [key: string]: string | undefined } } {
    let redirectUrl = value
    const redirectParams: { [key: string]: string | undefined } = {}

    // Encontrar a parte da query da URL
    const queryStartIndex = value.indexOf('?')
    if (queryStartIndex !== -1) {
      // Extrair a parte da query da URL
      const searchParams = value.slice(queryStartIndex + 1)

      // Dividir os parâmetros da query
      const paramsArray = searchParams.split('&')

      // Filtrar e extrair os parâmetros 'utm_*'
      const utmParams = paramsArray.filter((param) => param.startsWith('utm_'))

      // Remover os parâmetros 'utm_*' do link final
      redirectUrl = queryStartIndex !== -1 ? value.slice(0, queryStartIndex) : value

      // Atualizar os valores dos parâmetros 'utm_*'
      utmParams.forEach((param) => {
        const [key, val] = param.split('=')
        redirectParams[key] = val
      })

      // Remover os parâmetros 'utm_*' da lista de parâmetros
      const cleanedParamsArray = paramsArray.filter((param) => !param.startsWith('utm_'))

      // Reconstruir a parte da query sem os parâmetros 'utm_*'
      const cleanedQuery = cleanedParamsArray.join('&')

      // Reconstruir a URL final sem os parâmetros 'utm_*'
      redirectUrl += cleanedQuery ? '?' + cleanedQuery : ''
    }

    return { url: redirectUrl, params: redirectParams }
  }
}
