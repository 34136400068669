import { container } from '@hub-la/fe-container'
import { EditProductPage, EditProductProvider } from '@hub-la/fe-edit-product'

export const EditProductInit = () => {
  return (
    <EditProductProvider container={container}>
      <EditProductPage />
    </EditProductProvider>
  )
}
