import { container } from '@hub-la/fe-container'
import { UserSubscriptionsPage, UserSubscriptionsProvider } from '@hub-la/fe-user-subscriptions'

export const UserSubscriptionsInit = () => {
  return (
    <UserSubscriptionsProvider container={container}>
      <UserSubscriptionsPage />
    </UserSubscriptionsProvider>
  )
}
