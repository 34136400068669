import React, { useEffect, useMemo, useState } from 'react'
import { useCheckHaveChanged } from '../../hooks/use-check-have-changed'
import { useGetInvoices } from '../../hooks/use-get-invoices'

import { formatCurrency } from '@brazilian-utils/brazilian-utils'
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableEmptyState,
  TableHead,
  TableHeader,
  TablePagination,
  TableRow,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@hub-la/shadcn'
import { flexRender, getCoreRowModel, PaginationState, useReactTable } from '@tanstack/react-table'
import { Info } from 'lucide-react'
import { useHistory } from 'react-router-dom'
import { InvoiceStatus } from '../../../domain/enums/invoice-status'
import { columns } from './invoice-table-columns'

type Props = {
  subscriptionId: string
}

export const InvoiceTable: React.FC<Props> = ({ subscriptionId }) => {
  const [pagination, setPagination] = useState<PaginationState>({
    pageSize: 25,
    pageIndex: 1,
  })

  const {
    data = {
      items: [],
      total: 0,
      page: pagination.pageIndex,
      pageSize: pagination.pageSize,
      lastPage: 1,
    },
    isFetching,
    refetch,
  } = useGetInvoices({
    subscriptionId: subscriptionId,
    page: pagination.pageIndex,
    pageSize: pagination.pageSize,
  })

  const canRefetch = useCheckHaveChanged({ page: pagination.pageIndex, pageSize: pagination.pageSize })

  useEffect(() => {
    canRefetch && refetch()
  }, [canRefetch, refetch])

  const tableData = useMemo(() => (isFetching ? Array(pagination.pageSize).fill({}) : data.items), [isFetching, data])

  const history = useHistory()

  const tableColumns = useMemo(
    () =>
      isFetching
        ? columns.map((column) => ({
            ...column,
            cell: () => <Skeleton className="h-[14px] w-[60%] rounded-sm" />,
          }))
        : columns,
    [isFetching],
  )

  const table = useReactTable({
    data: tableData,
    columns: tableColumns,
    state: {
      pagination,
    },
    manualPagination: true,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
  })

  const InfoTooltip = ({ title }: { title: string }) => {
    return (
      <TooltipProvider>
        <Tooltip delayDuration={100}>
          <TooltipTrigger asChild>
            <span className="cursor-pointer">
              <Info className="h-3 w-3 text-muted-foreground" />
            </span>
          </TooltipTrigger>
          <TooltipContent side="top" align="center">
            <p className="text-sm">{title}</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    )
  }

  const getLtv = () => {
    const total = table
      .getRowModel()
      .rows.filter((row) => row.original.status === InvoiceStatus.PAID)
      .reduce((acc, row) => acc + row.original.amount.totalCents, 0)

    return (
      <Card className="flex flex-col min-h-[128px] gap-2">
        <CardHeader className="pb-0 space-y-0">
          <div className="flex items-center gap-1">
            <p className="text-sm font-normal text-muted-foreground">LTV</p>
            <InfoTooltip
              title="LTV (Valor Vitalício da Assinatura): Representa o total de receita gerada por um usuário durante todo o
              período em que a assinatura esteve ativa."
            />
          </div>
        </CardHeader>
        <CardDescription className="px-6 font-semibold text-4xl tracking-tight text-primary">
          R$ {formatCurrency(total / 100)}
        </CardDescription>
        <CardContent></CardContent>
      </Card>
    )
  }

  const getTotalMonths = () => {
    const paidInvoicesCount = data.items.filter((invoice) => invoice.status === InvoiceStatus.PAID).length

    return (
      <Card className="flex flex-col min-h-[128px] gap-2">
        <CardHeader className="space-y-0 pb-0">
          <div className="flex items-center gap-1">
            <p className="text-sm font-normal text-muted-foreground">Período Ativo</p>
            <InfoTooltip title="Período Ativo é uma métrica que representa a duração total, em meses, que essa assinatura ficou ativa." />
          </div>
        </CardHeader>
        <CardDescription className="px-6 font-semibold text-4xl tracking-tight text-primary">
          {paidInvoicesCount}
        </CardDescription>
        <CardContent></CardContent>
      </Card>
    )
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-2">
        {getLtv()}
        {getTotalMonths()}
      </div>
      <div className="flex items-center w-full gap-2">
        <h2 className="text-lg font-bold">Faturas</h2>
      </div>
      <div className="rounded-md border bg-background">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  onClick={() => {
                    const cellValue = row.getAllCells()[2].getValue()
                    history.push(`/dashboard/sales/${cellValue}`)
                    window.scrollTo({ top: 0, behavior: 'smooth' })
                  }}
                  className="cursor-pointer"
                >
                  {row.getVisibleCells().map((cell, cellIndex) => (
                    <TableCell
                      className={`text-foreground py-2.5 ${cellIndex === 2 ? 'max-w-[100px] truncate' : ''}
            ${cellIndex === 2 ? 'text-xs' : 'text-sm'}`}
                      key={cell.id}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableEmptyState title="Vazio" />
            )}
          </TableBody>
        </Table>
      </div>
      <div className="flex justify-end">
        <TablePagination
          page={pagination.pageIndex}
          setPage={(page) => table.setPageIndex(page)}
          lastPage={data.lastPage}
          pageSize={pagination.pageSize}
          setPageSize={(pageSize) => table.setPageSize(pageSize)}
        />
      </div>
    </div>
  )
}
