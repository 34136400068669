import { boolean, number, object, string } from 'yup'
import { PaymentMethod } from '../../domain/enums/payment-method'
import { RefundStrategy } from '../../domain/enums/refund-strategy.enum'
import { bankAccountSchema } from './bank-account-validation'

export const createRefundRequestValidationSchema = (paymentMethod?: PaymentMethod) => {
  return object().shape({
    invoiceId: string().required('errors.validation.required'),
    description: string().min(1).required('errors.validation.required'),
    isValidRefundRequest: boolean().required().oneOf([true]),
    refundPayerData: object().when({
      is: () => paymentMethod === PaymentMethod.BOLETO,
      then: object().shape({
        strategy: number().required('errors.validation.required'),
        data: object().when('strategy', {
          is: (strategy: RefundStrategy) => strategy === RefundStrategy.PIX,
          then: object().shape({
            pixKey: string().required('errors.validation.required'),
          }),
          otherwise: bankAccountSchema,
        }),
      }),
    }),
  })
}
