import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { ChangeCouponStatusInput } from '../domain/dtos/change-coupon-status-input'
import { CouponStatus } from '../domain/enums/coupon-status'
import { GeneralError } from '../domain/errors/general'
import { Envs } from '../envs'

export class ChangeCouponStatus {
  public constructor(@inject(HttpClient) private readonly httpClient: HttpClient) {}

  public async execute(input: ChangeCouponStatusInput): Promise<void> {
    const response = await this.httpClient.request({
      method: HttpMethod.PATCH,
      url: this.makeUrl(input.currentStatus),
      body: { couponId: input.id },
    })

    if ([HttpStatusCode.OK, HttpStatusCode.CREATED].includes(response.statusCode)) {
      return response.data
    } else {
      throw new GeneralError(response.data.errorCode)
    }
  }

  private makeUrl(status: CouponStatus): string {
    const method = status === CouponStatus.ACTIVE ? 'disable' : 'enable'
    return `${Envs.BFF_WEB_URL}/coupons/${method}`
  }
}
