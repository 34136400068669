import { ParseFirebaseDateInput } from '../../domain/dtos/waitlist/parse-firebase-date-input'

export class ParseFirebaseDate {
  private date: ParseFirebaseDateInput

  public constructor(date: ParseFirebaseDateInput) {
    this.date = date
  }

  public toDate(): Date {
    return new Date(this.date._seconds * 1000 + this.date._nanoseconds / 1000000)
  }
}
