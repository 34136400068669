import { container } from '@hub-la/fe-container'
import { useGetUser } from '@hub-la/fe-get-user'
import { PostDetail, PostDetailInit as PostDetailContainer } from '@hub-la/fe-post'
import { useGetRoleplayReference, useRoleplay } from '@hub-la/fe-roleplay'
import { useTheme } from '@hub-la/shadcn'
import { useParams } from 'react-router-dom'

export const PostDetailInit = () => {
  const { setTheme } = useTheme()
  setTheme('dark')

  const params = useParams<{ postId: string }>()
  const { data: user } = useGetUser()

  const { isRoleplay } = useRoleplay()
  const { data: roleplayUserData } = useGetRoleplayReference({ enabled: isRoleplay })
  const currentUser = isRoleplay ? roleplayUserData : user
  const currentUserId = isRoleplay ? roleplayUserData?.userId : user?.id
  const firstName = currentUser?.name ? currentUser?.name.split(' ')[0] : ''

  return (
    <PostDetailContainer
      container={container}
      currentUser={
        user
          ? {
              id: currentUserId,
              name: currentUser?.name,
              firstName,
              picture: currentUser?.picture,
            }
          : null
      }
    >
      <PostDetail container={container} postId={params.postId} />
    </PostDetailContainer>
  )
}
