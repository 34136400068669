import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { Invite } from '../domain/dtos/invite'
import { Envs } from '../envs'

export class InvitePartner {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(params: Invite): Promise<void> {
    const response = await this.httpClient.request({
      method: HttpMethod.POST,
      url: `${Envs.FUNCTIONS_URL}/receiver/${params.id ? 'edit' : 'invite'}/pt`,
      body: {
        data: { ...params, id: params.id },
      },
    })

    if (response.statusCode === HttpStatusCode.OK || response.statusCode === HttpStatusCode.CREATED) {
      return
    }

    throw new Error('Falha ao convidar parceiro')
  }
}
