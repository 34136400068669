import { Auth } from '@hub-la/fe-core-auth'
import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { PutCompleteRegistrationInput } from '../domain/dtos/put-complete-registration-input'
import { ErrorCode } from '../domain/enums/error-code'
import { BlockedUserAuthAttemptError } from '../domain/errors/blocked-user-auth-attempt-error'
import { FirebaseAuthError } from '../domain/errors/firebase-auth-error'
import { GeneralError } from '../domain/errors/general'
import { InvalidCaptchaError } from '../domain/errors/invalid-captcha-error'
import { SignInNotfoundError } from '../domain/errors/signin-not-found-error'
import { UserDisabledError } from '../domain/errors/user-disabled-error'
import { VerifyCodeInvalidError } from '../domain/errors/verify-code-invalid-error'
import { Envs } from '../envs'

export class PutCompleteRegistration {
  public constructor(
    @inject(HttpClient) private readonly httpClient: HttpClient,
    @inject(Auth) private readonly auth: Auth,
  ) {}

  public async execute(input: PutCompleteRegistrationInput): Promise<void> {
    const token = await this.verifyCode(input)

    if (!token) {
      throw new GeneralError()
    }

    if (!input?.captcha) {
      throw new InvalidCaptchaError()
    }

    try {
      await this.auth.signInWithToken(token)
    } catch (e: any) {
      if (!e?.code) {
        throw new GeneralError('completeRegistration')
      }

      switch (e?.code) {
        case 'auth/user-not-found':
          throw new SignInNotfoundError()
        default:
          throw new FirebaseAuthError(e.code)
      }
    }
  }

  private async verifyCode(input: PutCompleteRegistrationInput): Promise<string> {
    const response = await this.httpClient.request({
      method: HttpMethod.PUT,
      url: `${Envs.BFF_WEB_URL}/auth/complete-registration`,
      body: input,
    })

    if (response.statusCode === HttpStatusCode.CREATED || response.statusCode === HttpStatusCode.OK) {
      return response?.data
    }

    switch (response.data.code) {
      case ErrorCode.BLOCKED_USER_AUTH_ATTEMPT:
        throw new BlockedUserAuthAttemptError()
      case ErrorCode.VERIFY_CODE_INVALID:
        throw new VerifyCodeInvalidError()
      case ErrorCode.INVALID_CAPTCHA:
        throw new InvalidCaptchaError()
      case ErrorCode.USER_DISABLED:
        throw new UserDisabledError()
      default:
        throw new GeneralError('completeRegistration')
    }
  }
}
