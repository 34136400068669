import { initialize } from '@hub-la/fe-vendors-firebase'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from './app/app'

initialize({
  apiKey: process.env.NX_API_KEY,
  appId: process.env.NX_APP_ID,
  authDomain: process.env.NX_AUTH_DOMAIN,
  messagingSenderId: process.env.NX_MESSAGING_SENDER_ID,
  projectId: process.env.NX_PROJECT_ID,
  storageBucket: process.env.NX_STORAGE_BUCKET,
})

const container = document.getElementById('root')
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!)
root.render(
  <StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </StrictMode>,
)
