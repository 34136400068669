import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import { CreateRefundRequestValues } from './create-refund-request-modal'
import { RefundStrategy } from '../../../../domain/enums/refund-strategy.enum'
import { BankAccountForm, Pix } from './bank-account-form'
import { useField } from '../../../hooks/use-field'
import { RadioGroup, RadioGroupItem, Input, Label, FormMessage } from '@hub-la/shadcn'
import { useEffect } from 'react'

export const RefundPayerData = () => {
  const formik = useFormikContext<CreateRefundRequestValues>()
  const { hasError, getErrorText } = useField()
  const { t } = useTranslation()

  const handleRefundStrategyChange = (value: string) => {
    formik.setFieldValue('refundPayerData.strategy', +value)
  }

  const pixValue = formik.values.refundPayerData?.data as Pix | undefined

  useEffect(() => {
    formik.setFieldValue('refundPayerData.strategy', RefundStrategy.PIX)
  }, [])

  return (
    <div className="space-y-6">
      <div className="space-y-2">
        <h4 className="text-lg font-semibold">{t('userRefund.bankAccountData.title')}</h4>
        <p className="text-sm text-muted-foreground max-w-[90%]">{t('userRefund.bankAccountData.description')}</p>
      </div>
      <RadioGroup
        defaultValue={RefundStrategy.PIX.toString()}
        onValueChange={handleRefundStrategyChange}
        value={formik.values.refundPayerData?.strategy.toString()}
        className="space-y-2"
      >
        <div className="flex items-center space-x-2">
          <RadioGroupItem value={RefundStrategy.PIX.toString()} id={RefundStrategy.PIX.toString()} />
          <Label htmlFor={RefundStrategy.PIX.toString()}>{t('userRefund.bankAccountData.pix.pixKey')}</Label>
        </div>
        {formik.values.refundPayerData?.strategy === RefundStrategy.PIX && (
          <div>
            <Label htmlFor="refundPayerData.data.pixKey">{t('userRefund.bankAccountData.pix.pixKey')}</Label>
            <Input
              id="refundPayerData.data.pixKey"
              value={pixValue?.pixKey}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={hasError('refundPayerData.data.pixKey') ? 'border-red-500' : ''}
            />
            {hasError('refundPayerData.data.pixKey') && (
              <FormMessage>{getErrorText('refundPayerData.data.pixKey')}</FormMessage>
            )}
          </div>
        )}
        <div className="flex items-center space-x-2">
          <RadioGroupItem value={RefundStrategy.BANK_ACCOUNT.toString()} id={RefundStrategy.BANK_ACCOUNT.toString()} />
          <Label htmlFor={RefundStrategy.BANK_ACCOUNT.toString()}>{t('userRefund.bankAccountData.title')}</Label>
        </div>
        {formik.values.refundPayerData?.strategy === RefundStrategy.BANK_ACCOUNT && <BankAccountForm />}
      </RadioGroup>
    </div>
  )
}
