import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation } from '@tanstack/react-query'
import { FileExtension } from '../../../domain/dtos/waitlist'
import { QueryKey } from '../../../domain/enums/query-key'
import { ExportWaitlist } from '../../../usecases/waitlist/export-waitlist'

export const useExportWaitlist = () => {
  const container = useContainer()

  return useMutation(
    [QueryKey.exportWaitlist],
    ({ productId, fileExtension }: { productId: string; fileExtension: FileExtension }) =>
      new ExportWaitlist(container.get(HttpClient)).execute(productId, fileExtension),
    {
      retry: false,
      onSuccess: (id) => {
        window.open(`/export/${id}`, '_blank')
      },
    },
  )
}
