import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { QueryKey } from '../../domain/enums/query-key'
import { SubmitChangePaymentMethod } from '../../usecases/submit-change-payment-method'
import { Values } from '../pages/user-smart-installment-cycle/components/change-payment-method-modal'

export const useSubmitChangePaymentMethod = () => {
  const container = useContainer()

  return useMutation(
    [QueryKey.submitChangePaymentMethod],
    (input: Values) => new SubmitChangePaymentMethod(container.get(HttpClient)).execute(input),
    {
      retry: false,
    },
  )
}
