import { formatCurrency } from '@brazilian-utils/brazilian-utils'
import { Badge } from '@hub-la/shadcn'
import { SmartInstallment } from '../../../domain/dtos/smart-installment'

type Props = {
  smartInstallment: SmartInstallment
}

export const SmartInstallmentProgress = ({ smartInstallment }: Props) => {
  const totalAmount = smartInstallment.amountTotalCents / 100
  const paidAmount = smartInstallment.amountTotalPaidCents / 100
  const overdueAmount = smartInstallment.amountTotalOverdueCents / 100
  const paidPercentage = (paidAmount / totalAmount) * 100
  const overduePercentage = (overdueAmount / totalAmount) * 100

  return (
    <div className="bg-card border rounded-lg p-4 md:p-6 space-y-4 md:space-y-6">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 md:gap-8">
        <div>
          <div className="flex items-center gap-1">
            <div className="h-2 w-2 rounded-full bg-green-600" />
            <div className="text-muted-foreground">Total pago</div>
            <Badge variant="secondary">{smartInstallment.installmentTotalPaid}</Badge>
          </div>
          <div className="text-3xl font-semibold">R$ {formatCurrency(smartInstallment.amountTotalPaidCents / 100)}</div>
        </div>
        <div>
          <div className="flex items-center gap-1">
            <div className="h-2 w-2 rounded-full bg-red-600" />
            <div className="text-muted-foreground">Total atrasado</div>
            <Badge variant="secondary">{smartInstallment.installmentTotalOverdue}</Badge>
          </div>
          <div className="text-3xl font-semibold">
            R$ {formatCurrency(smartInstallment.amountTotalOverdueCents / 100)}
          </div>
        </div>
        <div>
          <div className="flex items-center gap-1">
            <div className="h-2 w-2 rounded-full bg-muted-foreground" />
            <div className="text-muted-foreground">Total restante</div>
            <Badge variant="secondary">{smartInstallment.installmentTotalDraft}</Badge>
          </div>
          <div className="text-3xl font-semibold">
            R${' '}
            {formatCurrency((smartInstallment.amountTotalDraftCents + smartInstallment.amountTotalOverdueCents) / 100)}
          </div>
        </div>
      </div>

      <div className="relative h-2 bg-secondary rounded-full overflow-hidden">
        <div
          className="absolute h-full bg-green-600 rounded-l-full transition-all duration-300"
          style={{ width: `${paidPercentage}%` }}
        />
        <div
          className="absolute h-full bg-red-600 transition-all duration-300"
          style={{ width: `${overduePercentage}%`, left: `${paidPercentage}%` }}
        />
      </div>

      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
        {smartInstallment.nextDueAt && (
          <div className="w-full sm:w-auto">
            <div className="text-muted-foreground text-sm md:text-base">
              Próxima parcela vence{' '}
              <span className="font-medium text-primary block sm:inline mt-1 sm:mt-0">
                {new Date(smartInstallment.nextDueAt).toLocaleDateString('pt-BR', {
                  weekday: 'long',
                  day: '2-digit',
                  month: 'short',
                  year: 'numeric',
                })}
              </span>
            </div>
            <div className="font-medium mt-1">
              R$ {formatCurrency(smartInstallment.amountTotalCents / smartInstallment.installments / 100)}
            </div>
          </div>
        )}
        <div className="w-full sm:w-auto text-left sm:text-right">
          <div className="text-muted-foreground text-sm md:text-base">Total parcelado</div>
          <div className="font-medium">R$ {formatCurrency(smartInstallment.amountTotalCents / 100)}</div>
        </div>
      </div>
    </div>
  )
}
