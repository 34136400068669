import { SmartInstallmentStatus } from '../enums/smart-installment-status'

export const smartInstallmentStatus = [
  {
    id: SmartInstallmentStatus.ON_SCHEDULE,
    label: 'Em dia',
  },
  {
    id: SmartInstallmentStatus.OFF_SCHEDULE,
    label: 'Atrasado',
  },
  {
    id: SmartInstallmentStatus.CANCELED,
    label: 'Cancelado',
  },
  {
    id: SmartInstallmentStatus.COMPLETED,
    label: 'Concluído',
  },
]
