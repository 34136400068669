import {
  CopyClipboardButton,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableEmptyState,
  TableHead,
  TableHeader,
  TablePagination,
  TableRow,
} from '@hub-la/shadcn'
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  PaginationState,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'
import isEmpty from 'lodash/isEmpty'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Subscription } from '../../../domain/dtos/subscription'
import { OrderDirection } from '../../../domain/enums/order-direction'
import { useGetSubscriptions } from '../../hooks/use-get-subscriptions'
import { useRefetch } from '../../hooks/use-refetch'
import { Actions } from './actions'
import { columns } from './columns'

export const SubscriptionsTable = ({ filters, offers, offersLoading }) => {
  const [sorting, setSorting] = useState<SortingState>([])
  const [pagination, setPagination] = useState<PaginationState>({
    pageSize: filters.pageSize,
    pageIndex: filters.page,
  })

  const { t } = useTranslation()

  const history = useHistory()

  const { search, offerIds, dateRangeBy, startDate, endDate, statuses, planType } = filters

  const {
    data = {
      items: [] as Subscription[],
      total: 0,
      page: pagination.pageIndex,
      pageSize: pagination.pageSize,
      lastPage: 1,
    },
    refetch,
    isFetching,
  } = useGetSubscriptions(
    {
      search,
      offers,
      offerIds,
      page: pagination.pageIndex,
      dateRangeBy,
      startDate,
      endDate,
      statuses,
      pageSize: pagination.pageSize,
      orderBy: sorting.length ? sorting[0].id : '',
      orderDirection: sorting.length
        ? sorting[0].desc
          ? OrderDirection.DESC
          : OrderDirection.ASC
        : OrderDirection.DESC,
      planType,
    },
    offersLoading,
  )

  const isRowsEmpty = isEmpty(data?.items)

  const canRenderLoadingState = offersLoading || isFetching
  const canRenderEmptyState = !canRenderLoadingState && isRowsEmpty

  const tableData = useMemo(
    () => (canRenderLoadingState ? Array(pagination.pageSize).fill({}) : data.items),
    [canRenderLoadingState, data.items, pagination.pageSize],
  )

  const tableColumns = useMemo(
    () =>
      canRenderLoadingState
        ? columns.map((column) => ({
            ...column,
            cell: () => <Skeleton className="h-6 w-[60%]" />,
          }))
        : columns,
    [canRenderLoadingState],
  )

  const table = useReactTable<Subscription>({
    data: tableData,
    columns: tableColumns,
    state: {
      sorting,
      pagination,
    },
    enableRowSelection: true,
    manualSorting: true,
    manualPagination: true,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  useRefetch({ offers, ...filters }, refetch)

  const selectedItems = table.getSelectedRowModel().rows.map((value) => value.original)

  return (
    <div className="flex flex-col gap-4">
      <div className="rounded-md border bg-background">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow className="hover:bg-background" key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {(table.getRowModel().rows || []).map((row) => (
              <TableRow
                onClick={() => history.push(`subscriptions/${row.getValue('id')}`)}
                key={row.id}
                data-state={row.getIsSelected() && 'selected'}
                className="cursor-pointer"
              >
                {row.getVisibleCells().map((cell, cellIndex) => (
                  <TableCell
                    className={`text-foreground py-2 min-h-[47px] ${
                      cellIndex === 1 || cellIndex === 3 ? 'max-w-[160px] truncate' : ''
                    } ${cellIndex === 1 ? 'font-medium' : 'font-normal'}
                        ${cellIndex === 3 ? 'text-xs' : 'text-sm'}`}
                    key={cell.id}
                  >
                    {cellIndex === 3 ? (
                      <CopyClipboardButton
                        copyContent={row.getValue('id')}
                        label={row.getValue('id')}
                        tooltipCopiedLabel="ID da assinatura copiado"
                        tooltipCopyLabel="Copiar ID da assinatura"
                        size="sm"
                        className="h-6 max-w-[120px] truncate"
                      />
                    ) : (
                      flexRender(cell.column.columnDef.cell, cell.getContext())
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>

        {canRenderEmptyState && <TableEmptyState title={t('empty')} subtitle={t('emptySubtitle')} />}
      </div>
      <div className="flex justify-end">
        {!isRowsEmpty && (
          <TablePagination
            page={pagination.pageIndex}
            setPage={(page) => table.setPageIndex(page)}
            lastPage={data.lastPage}
            pageSize={pagination.pageSize}
            setPageSize={(pageSize) => table.setPageSize(pageSize)}
          />
        )}
      </div>
      <Actions
        selectedItems={selectedItems}
        onCancel={() => {
          table.resetRowSelection()
        }}
        onSuccess={refetch}
      />
    </div>
  )
}
