import { TemporaryBusinessResponse } from '@hub-la/fe-get-user'
import { BrazilianBankCode } from '../../domain/constants/brazilian-banks'
import { BankAccountType, DocumentType } from '../../domain/enums/bank-account'
import { SoftDescriptor } from '../../domain/vos/soft-descriptor'
import { Values } from '../pages/account-verification-modal'

export const resetBankAccountValues = () => ({
  bankNumber: '',
  agencyNumber: '',
  agencyCheckNumber: '',
  accountNumber: '',
  accountCheckNumber: '',
  accountType: '',
})

export const makeInitialValues = (account?: TemporaryBusinessResponse): Values => {
  /** @description Those transformations are used to fix legacy data */
  const softDescriptor = account?.businessIdentity?.businessConfiguration?.creditCard?.softDescriptor ?? ''
  const formattedSoftDescriptor = SoftDescriptor.build(
    softDescriptor.length > 11 ? softDescriptor.slice(0, 11) : softDescriptor,
  ).withoutPrefix()
  const phone = account?.businessIdentity?.identity?.phone
  const formattedPhone = phone ? (phone.startsWith('+') ? phone : `+${phone}`) : ''
  /** End transformations */

  return {
    bankAccount: {
      bankNumber: (account?.businessIdentity?.payout?.bankAccount.bankNumber ?? '001') as BrazilianBankCode,
      agencyNumber: account?.businessIdentity?.payout?.bankAccount.agencyNumber ?? '',
      agencyCheckNumber: account?.businessIdentity?.payout?.bankAccount.agencyCheckNumber ?? '',
      accountNumber: account?.businessIdentity?.payout?.bankAccount.accountNumber ?? '',
      accountCheckNumber: account?.businessIdentity?.payout?.bankAccount.accountCheckNumber ?? '',
      accountType: (account?.businessIdentity?.payout?.bankAccount.accountType ??
        BankAccountType.TYPE_CHECKING) as BankAccountType,
    },
    isPixKey: account?.businessIdentity?.payout?.isPixKey ?? false,
    identity: {
      fullName: account?.businessIdentity?.identity?.fullName ?? '',
      firstName: account?.businessIdentity?.identity?.firstName ?? '',
      lastName: account?.businessIdentity?.identity?.lastName ?? '',
      email: account?.businessIdentity?.identity?.email ?? '',
      phone: formattedPhone,
      birthday: account?.businessIdentity?.identity?.birthday,
      document: {
        value: account?.businessIdentity?.identity?.document?.value ?? '',
        type: account?.businessIdentity?.identity?.document?.type === 1 ? DocumentType.CNPJ : DocumentType.CPF,
        countryCode: account?.businessIdentity?.identity?.document?.countryCode ?? 'BR',
      },
      billingAddress: {
        ibgeCode: account?.businessIdentity?.identity?.billingAddress?.ibgeCode ?? '',
        street: account?.businessIdentity?.identity?.billingAddress?.street ?? '',
        number: account?.businessIdentity?.identity?.billingAddress?.number ?? '',
        complement: account?.businessIdentity?.identity?.billingAddress?.complement ?? '',
        neighborhood: account?.businessIdentity?.identity?.billingAddress?.neighborhood ?? '',
        city: account?.businessIdentity?.identity?.billingAddress?.city ?? '',
        state: account?.businessIdentity?.identity?.billingAddress?.state ?? '',
        postalCode: account?.businessIdentity?.identity?.billingAddress?.postalCode ?? '',
        countryCode: account?.businessIdentity?.identity?.billingAddress?.countryCode ?? 'BR',
      },
      legalRepresentative: {
        fullName: account?.businessIdentity?.identity?.legalRepresentative?.fullName ?? '',
        document: {
          value: account?.businessIdentity?.identity?.legalRepresentative?.document?.value ?? '',
          type:
            account?.businessIdentity?.identity?.legalRepresentative?.document?.type === 1
              ? DocumentType.CNPJ
              : DocumentType.CPF,
          countryCode: account?.businessIdentity?.identity?.legalRepresentative?.document?.countryCode ?? 'BR',
        },
      },
    },
    businessConfiguration: {
      displayName: account?.businessIdentity?.identity?.fullName ?? '',
      creditCard: {
        softDescriptor: formattedSoftDescriptor,
      },
    },
    sdkToken: account?.sdkToken ?? '',
    pendencies: account?.pendencies ?? [],
  }
}
