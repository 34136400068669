import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useQuery } from '@tanstack/react-query'
import { isNil } from 'lodash'
import { ExportRequest, ExportStatus } from '../../domain/dtos/export-request'
import { QueryKey } from '../../domain/enums/query-key'
import { GetExportRequest } from '../../usecases/get-export-request'

export const useGetExportRequest = (id: string) => {
  const container = useContainer()

  return useQuery<ExportRequest>(
    [QueryKey.getExportRequest],
    () => new GetExportRequest(container.get(HttpClient)).execute(id),
    {
      refetchInterval: (data, query) => {
        if (isNil(data)) {
          return false
        }

        const canRetry = data.status === ExportStatus.pending
        if (query.state.error) {
          return false
        }

        return canRetry ? 2000 : false
      },
      retryOnMount: true,
      refetchOnWindowFocus: 'always',
      staleTime: Infinity,
      cacheTime: 0,
      enabled: !!id,
    },
  )
}
