import { container } from '@hub-la/fe-container'
import { UserRefundPage, UserRefundProvider } from '@hub-la/fe-user-refund'
import { withRoleplayRedirect } from '../components/roleplay-redirect'

export const UserRefundsInit = withRoleplayRedirect(() => {
  return (
    <UserRefundProvider container={container}>
      <UserRefundPage />
    </UserRefundProvider>
  )
})
