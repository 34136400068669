import { Button, Card, CardContent, CardHeader, CardTitle, Separator, WhatsApp } from '@hub-la/shadcn'
import { Mail, User } from 'lucide-react'
import React, { ElementType } from 'react'
import { useTranslation } from 'react-i18next'
import { Phone } from '../../../domain/vos/phone'

interface InfoRowProps {
  label: string
  value: string
  icon?: ElementType
}

const InfoRow = ({ label, value }: InfoRowProps) => (
  <div className="flex items-center justify-between gap-2">
    <span className="text-sm text-muted-foreground">{label}:</span>
    <span className="text-sm text-right">{value}</span>
  </div>
)

interface EntitiesCardsProps {
  client: {
    name: string
    picture?: string
    id: string
    email: string
    phone: string
  }
}

export const EntitiesCards = ({ client }: EntitiesCardsProps) => {
  const { t } = useTranslation()

  const getInitialLettersOfTheName = (fullname?: string) => {
    const names = (fullname || '').trim().split(' ')
    const initials = names.map((name) => name.charAt(0).toUpperCase())

    return initials.join('')
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle className="text-base font-semibold flex justify-between items-center gap-1.5">
          <div className="flex gap-3 items-center">
            <div className="rounded-full min-w-10 min-h-10 flex items-center justify-center bg-secondary">
              {client.name ? (
                <span className="font-medium leading-none">{getInitialLettersOfTheName(client.name)}</span>
              ) : (
                <User />
              )}
            </div>

            <span className="text-lg font-semibold">{client.name}</span>
          </div>

          <div className="flex gap-2">
            <Button size="icon" variant="outline" onClick={() => window.open(`mailto:${client.email}`)}>
              <Mail className="w-4 h-4" />
            </Button>

            <Button size="icon" variant="outline" onClick={() => window.open(`https://wa.me/${client.phone}`)}>
              <WhatsApp className="w-[17px] h-[17px]" />
            </Button>
          </div>
        </CardTitle>
      </CardHeader>

      <Separator />

      <CardContent className="pt-6 flex flex-col gap-4">
        <InfoRow label={t('invoice.entities.id')} value={client.id} />
        <InfoRow label={t('invoice.entities.email')} value={client.email} />
        <InfoRow label={t('invoice.entities.phoneNumber')} value={Phone.build(client.phone).getFormattedNumber()} />
      </CardContent>
    </Card>
  )
}
