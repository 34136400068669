import { Analytics, AnalyticsEvent } from '@hub-la/fe-core-analytics'
import { Auth } from '@hub-la/fe-core-auth'
import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { PostSignInByEmailVerifyCodeInput } from '../domain/dtos/post-sign-in-by-email-verify-code-input'
import { AnalyticsAuthMethod } from '../domain/enums/analytics-auth-method'
import { ErrorCode } from '../domain/enums/error-code'
import { BlockedUserAuthAttemptError } from '../domain/errors/blocked-user-auth-attempt-error'
import { CodeExpiredError } from '../domain/errors/code-expired-error'
import { FirebaseAuthError } from '../domain/errors/firebase-auth-error'
import { GeneralError } from '../domain/errors/general'
import { InvalidCaptchaError } from '../domain/errors/invalid-captcha-error'
import { SignInNotfoundError } from '../domain/errors/signin-not-found-error'
import { UserDisabledError } from '../domain/errors/user-disabled-error'
import { VerifyCodeInvalidError } from '../domain/errors/verify-code-invalid-error'
import { Envs } from '../envs'

export class PostSignInByEmailVerifyCode {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
    @inject(Auth) private readonly auth: Auth,
    @inject(Analytics) private readonly analytics: Analytics,
  ) {}

  public async execute(input: PostSignInByEmailVerifyCodeInput): Promise<null> {
    if (!input?.captcha) {
      throw new InvalidCaptchaError()
    }

    const response = await this.httpClient.request({
      method: HttpMethod.POST,
      url: `${Envs.BFF_WEB_URL}/auth/signin/email/verify-code`,
      body: input,
    })

    if (response.statusCode === HttpStatusCode.CREATED) {
      try {
        const auth = await this.auth.signInWithToken(response.data?.token)
        this.analytics.track(AnalyticsEvent.AUTHENTICATION.SUCCESSFUL, {
          user: auth?.user?.uid,
          type: AnalyticsAuthMethod.EMAIL,
          isNewUser: false,
        })
        return null
      } catch (e: any) {
        if (!e?.code) {
          throw new GeneralError('signIn')
        }

        switch (e?.code) {
          case 'auth/user-not-found':
            throw new SignInNotfoundError()
          default:
            throw new FirebaseAuthError(e.code)
        }
      }
    }

    switch (response.data.code) {
      case ErrorCode.INVALID_CAPTCHA:
        throw new InvalidCaptchaError()
      case ErrorCode.BLOCKED_USER_AUTH_ATTEMPT:
        throw new BlockedUserAuthAttemptError()
      case ErrorCode.CODE_EXPIRED:
        throw new CodeExpiredError()
      case ErrorCode.VERIFY_CODE_INVALID:
        throw new VerifyCodeInvalidError()
      case ErrorCode.USER_DISABLED:
        throw new UserDisabledError()
      default:
        throw new GeneralError('signIn')
    }
  }
}
