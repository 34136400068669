import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useQuery } from '@tanstack/react-query'
import { GetSubscriptionsInput } from '../../domain/dtos/get-subscriptions-input'
import { QueryKey } from '../../domain/enums/query-keys'
import { GetSubscriptions } from '../../usecases/get-subscriptions'

export const useGetSubscriptions = (input: GetSubscriptionsInput, isLoading: boolean) => {
  const container = useContainer()

  const offerIds = input.offers.filter(({ id }) => id !== 'all').map(({ id }) => id)
  const enabled = !isLoading && offerIds.length > 0
  const initialData = {
    page: 1,
    pageSize: 0,
    total: 0,
    lastPage: 1,
    items: [],
  }

  return useQuery(
    [QueryKey.getSubscriptions, JSON.stringify(input)],
    ({ signal }) => {
      if (!enabled) {
        return Promise.resolve(initialData)
      }
      return new GetSubscriptions(container.get(HttpClient)).execute(input, signal)
    },
    {
      retry: 3,
      retryOnMount: false,
      refetchOnWindowFocus: false,
      initialData,
      enabled,
    },
  )
}
