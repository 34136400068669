import { Helmet } from 'react-helmet'

const MetaTags = () => {
  return (
    <Helmet>
      <title>Hubla - Login</title>
      <meta
        name="description"
        content="Faça login na Hubla para acessar sua conta e gerenciar seus negócios digitais. Entre com e-mail, WhatsApp ou SMS. Não tem uma conta? Crie a sua agora."
      />
      <meta
        name="og:description"
        content="Faça login na Hubla para acessar sua conta e gerenciar seus negócios digitais. Entre com e-mail, WhatsApp ou SMS. Não tem uma conta? Crie a sua agora."
      />
      <meta name="og:title" content="Hubla - Login" />
      <meta name="twitter:title" content="Hubla - Login" />
    </Helmet>
  )
}

export { MetaTags }
