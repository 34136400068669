import { useContainer } from "@hub-la/fe-container"
import { HttpClient } from "@hub-la/fe-core-http-client"
import { useQuery } from "@tanstack/react-query"
import { QueryKey } from "../../domain/enums/query-key"
import { GetInProgressSmartInstallmentCycles } from "../../usecases/get-in-progress-smart-installment-cycles"
import { useToast } from "@hub-la/shadcn"

export function useGetInProgressSmartInstallmentCycles() {
  const container = useContainer()
  const { toast } = useToast()

  return useQuery([QueryKey.getInProgressSmartInstallmentCycles], () => new GetInProgressSmartInstallmentCycles(container.get(HttpClient)).execute(), {
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
    onError: (e: Error) => toast({ variant: "destructive", title: e.message })
  })
}

