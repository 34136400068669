import moment from 'moment'
import { ColumnDef } from '@tanstack/react-table'
import { DataTableColumnHeader } from '../../components/data-table-column-header'
import { PaymentMethodIcon } from '../../components/payment-method-icon'
import { formatCurrency } from '../../../domain/vos/format-currency'
import { Invoice } from '../../../domain/dtos/invoice'
import { InvoiceStatusStats } from '../../components/invoice-status-stats'
import { InvoiceStatus } from '../../../domain/enums/invoice-status'

export const columns: ColumnDef<Invoice>[] = [
  {
    accessorKey: 'payer.identity.fullName',
    header: 'Cliente',
    cell: ({ row }) => {
      return (
        <div className="flex items-center space-x-2">
          <PaymentMethodIcon paymentMethod={row.original.paymentMethod} />
          <span>{formatCurrency(row.original.amount.totalCents / 100)}</span>
        </div>
      )
    },
  },
  {
    accessorKey: 'status',
    header: 'Status',
    cell: ({ row }) => {
      const status: InvoiceStatus = row.getValue('status')

      return <InvoiceStatusStats status={status} />
    },
  },
  {
    accessorKey: 'id',
    header: 'ID da fatura',
  },
  {
    accessorKey: 'createdAt',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Data da criação" />,
    cell: ({ row }) => {
      const createdAt: string = row.getValue('createdAt')

      if (!createdAt) {
        return null
      }

      return <>{moment(createdAt).format('D MMM. YYYY')}</>
    },
  },
  {
    accessorKey: 'nextDueAt',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Data de vencimento" />,
    cell: ({ row }) => {
      const nextDueAt: string = row.getValue('nextDueAt')

      if (!nextDueAt) {
        return <>-</>
      }

      return <>{moment(nextDueAt).format('D MMM. YYYY')}</>
    },
  },
]
