import { container } from '@hub-la/fe-container'
import { DashboardRefunds, DashboardRefundsProvider } from '@hub-la/fe-dashboard-refunds'

export const DashboardRefundsInit = () => {
  return (
    <DashboardRefundsProvider container={container}>
      <DashboardRefunds />
    </DashboardRefundsProvider>
  )
}
