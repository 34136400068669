import { Pix } from '@hub-la/shadcn'
import { BarcodeIcon, CreditCard } from 'lucide-react'
import { PaymentMethod } from '../../../../domain/enums/payment-method'

const getIcon = (paymentMethod: PaymentMethod) => {
  switch (paymentMethod) {
    case PaymentMethod.PIX:
      return <Pix className="h-4 w-4" />
    case PaymentMethod.CREDIT_CARD:
      return <CreditCard className="h-4 w-4" />
    default:
      return <BarcodeIcon className="h-4 w-4" />
  }
}

export const PaymentMethodIcon = ({ paymentMethod }: { paymentMethod: PaymentMethod }) => {
  return <>{getIcon(paymentMethod)}</>
}
