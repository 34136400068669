import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { PutCollaboratorInput } from '../../domain/dtos/put-collaborator-input'
import { QueryKey } from '../../domain/dtos/query-keys'
import { PutCollaborator } from '../../usecases/put-collaborator'

export const usePutCollaborator = ({ onSuccess, onError }: { onSuccess: () => void; onError?: () => void }) => {
  const container = useContainer()
  const queryClient = useQueryClient()

  return useMutation(
    [QueryKey.putCollaborator],
    (input: PutCollaboratorInput) => new PutCollaborator(container.get(HttpClient)).execute(input),
    {
      retry: false,
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries({ queryKey: [QueryKey.getMyCollaborators] })
        queryClient.invalidateQueries({ queryKey: [QueryKey.getMyCollaborator, variables.collaboratorUserId] })
        onSuccess && onSuccess()
      },
      onError: () => {
        onError && onError()
      },
    },
  )
}
