import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { CreateShortLinkInput } from '../../domain/dtos/create-short-link-input'
import { ErrorCode } from '../../domain/dtos/error-code'
import { GeneralError } from '../../domain/errors/general'
import { ShortLinkAlreadyExists } from '../../domain/errors/short-link-already-exists'
import { Envs } from '../../envs'
import { CreateShortLinkValues } from '../../presentation/pages/shortlinks/components/create-edit-shortlink-modal'

export class CreateShortLink {
  public constructor(@inject(HttpClient) private readonly httpClient: HttpClient) {}

  public async execute(productId: string, input: CreateShortLinkValues): Promise<null> {
    const response = await this.httpClient.request({
      method: HttpMethod.POST,
      url: `${Envs.BFF_PRODUCT_URL}/products/${productId}/short-links`,
      body: this.makeBody(input),
    })

    if (response.statusCode === HttpStatusCode.CREATED) {
      return response.data
    }

    switch (response.data.errorCode) {
      case ErrorCode.SHORTLINK_ALREADY_EXISTS:
        throw new ShortLinkAlreadyExists()
      default:
        throw new GeneralError()
    }
  }

  private makeBody(input: CreateShortLinkValues): CreateShortLinkInput {
    const body: CreateShortLinkInput = {
      offerId: input.offerId,
      name: input.name,
      slug: input.slug,
      type: input.type,
      redirectUrl: input.redirectUrl,
      utm: input.applyToSpecificUtms
        ? {
            source: input.utmSource,
            medium: input.utmMedium,
            campaign: input.utmCampaign,
            content: input.utmContent,
            term: input.utmTerm,
          }
        : undefined,
    }

    return body
  }
}
