import {
  Button,
  Checkbox,
  cn,
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@hub-la/shadcn'
import { MixerHorizontalIcon } from '@radix-ui/react-icons'
import { Check } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { Offer } from '../../../../domain/dtos/offer'

type Props = {
  offers: Offer[]
  filters: { offerIds: string[] }
  setFilters: (filters: { offerIds: string[] }) => void
}

export const SelectOffersFilter = ({ offers, filters, setFilters }: Props) => {
  const { t } = useTranslation()

  const handleOfferIds = (offerId: string) => {
    const oldOfferIds = filters.offerIds
    let newOfferIds: string[] = []

    if (offerId === 'all') {
      newOfferIds = oldOfferIds.includes('all') ? [] : ['all']
    } else {
      if (oldOfferIds.includes('all')) {
        newOfferIds = [offerId]
      } else {
        newOfferIds = oldOfferIds.includes(offerId)
          ? oldOfferIds.filter((id: string) => id !== offerId)
          : [...oldOfferIds, offerId]
      }
    }

    setFilters({ ...filters, offerIds: newOfferIds })
  }

  const shouldIncludeAllOffers = filters.offerIds.includes('all') || filters.offerIds.length === 0

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="outline" className="w-full bg-secondary sm:w-auto gap-2">
          <MixerHorizontalIcon className="h-4 w-4" />

          {shouldIncludeAllOffers ? (
            <span>{t('invoices.productsFilter.all')}</span>
          ) : (
            <span>
              {t('invoices.productsFilter.products')} ({filters.offerIds.length})
            </span>
          )}
        </Button>
      </PopoverTrigger>

      <PopoverContent className="w-auto p-0">
        <Command>
          <CommandInput placeholder={t('invoices.productsFilter.placeholder')} />

          <CommandList>
            <CommandEmpty>{t('invoices.productsFilter.notFound')}</CommandEmpty>

            <CommandGroup>
              {offers.map(({ id, name }) => {
                const checked =
                  id === 'all' ? shouldIncludeAllOffers : !shouldIncludeAllOffers && filters.offerIds.includes(id)
                return (
                  <CommandItem key={id} onSelect={() => handleOfferIds(id)} className="flex items-center gap-2">
                    <Checkbox checked={checked} onCheckedChange={() => handleOfferIds(id)} />

                    <span>{name}</span>

                    {checked && <Check className={cn('ml-auto h-4 w-4')} />}
                  </CommandItem>
                )
              })}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}
