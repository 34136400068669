import moment, { Moment } from 'moment-timezone'
import { Period } from '../domain/dtos/period'
import { FormatPeriodToMoment, PeriodToMoment } from '../domain/dtos/period-to-moment'

type DateTimeRange = {
  startAt: string
  endAt: string
}
export class GetComparativeDateRange {
  public execute(period: Period, start: string, end: string): DateTimeRange {
    const startDate = moment(start).parseZone().startOf(PeriodToMoment[period])
    const endDate = moment(end).parseZone().endOf(PeriodToMoment[period])

    const toDateString = (m: Moment) => m.format(FormatPeriodToMoment[period])
    const isFullMonthSelection = (startDate: Moment, endDate: Moment) => {
      return (
        startDate.date() !== endDate.date() &&
        startDate.year() === endDate.year() &&
        startDate.month() === endDate.month() &&
        startDate.date() === 1 &&
        endDate.date() === endDate.daysInMonth()
      )
    }

    const isFullMonth = isFullMonthSelection(startDate, endDate)
    if (isFullMonth) {
      const previousStartDate = startDate.clone().subtract(1, 'month').startOf('month')
      const previousEndDate = previousStartDate.clone().endOf('month')

      return {
        startAt: toDateString(previousStartDate),
        endAt: toDateString(previousEndDate),
      }
    }

    const diff = Math.ceil(endDate.diff(startDate, PeriodToMoment[period], true))

    const previousStartDate = startDate.clone().subtract(diff, PeriodToMoment[period]).startOf(PeriodToMoment[period])
    const previousEndDate = startDate.clone().subtract(1, PeriodToMoment[period]).endOf(PeriodToMoment[period])

    return {
      startAt: toDateString(previousStartDate),
      endAt: toDateString(previousEndDate),
    }
  }
}
