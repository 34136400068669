export enum BankAccountType {
  TYPE_UNSPECIFIED = 0,
  TYPE_CHECKING = 1,
  TYPE_SAVING = 2,
}

export const BANK_ACCOUNT_TYPE = [
  { code: BankAccountType.TYPE_CHECKING, name: 'Conta corrente' },
  { code: BankAccountType.TYPE_SAVING, name: 'Conta poupança' },
  { code: BankAccountType.TYPE_UNSPECIFIED, name: 'Conta não especificada' },
]
