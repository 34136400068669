/* eslint-disable @typescript-eslint/no-unused-vars */
import { Analytics } from './analytics'
import { AnalyticsProperties } from './analytics-properties'
import { injectable } from 'inversify'

@injectable()
export class AnalyticsMock extends Analytics {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public override track(event: string, properties?: AnalyticsProperties): void {}
}
