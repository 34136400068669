import {
  cn,
  Table,
  TableBody,
  TableCell,
  TableEmptyState,
  TableHead,
  TableHeader,
  TablePagination,
  TableRow,
} from '@hub-la/shadcn'
import isEmpty from 'lodash/isEmpty'
import times from 'lodash/times'
import { ChevronDown, ChevronsUpDown, ChevronUp, EyeOff } from 'lucide-react'
import moment from 'moment'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { OrderDirection } from '../../../domain/enums/order-direction'
import placeholderAvatar from '../../assets/placeholder-image.svg'
import { ProductStatusTag } from '../../components/product-status-tag'
import { useListProducts } from '../../hooks/use-list-products'
import { ProductsTableLoading } from './products-table-loading'
import { useRefetch } from '../../hooks/use-refetch'

interface ProductsTableProps {
  filters: any
}

export const ProductsTable: React.FC<ProductsTableProps> = ({ filters }) => {
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(25)
  const [orderBy, setOrderBy] = useState<string>('createdAt')
  const [orderDir, setOrderDir] = useState<OrderDirection>(OrderDirection.DESC)

  const { search, statuses: status } = filters

  const { t } = useTranslation()
  const {
    data = { items: [], lastPage: 0, page: 1, pageSize: 1, total: 0 },
    isFetching,
    refetch,
  } = useListProducts({
    page,
    pageSize,
    orderBy,
    orderDir,
    filters: {
      search,
      status: status.length ? status : undefined,
    },
  })

  const handleRequestSort = useCallback(
    (property: string) => {
      const isAsc = orderBy === property && orderDir === OrderDirection.ASC
      setOrderDir(isAsc ? OrderDirection.DESC : OrderDirection.ASC)
      setOrderBy(property)
    },
    [orderDir, orderBy],
  )

  const headCells = [
    { key: 'product', label: 'Produto', sortable: false, width: 200 },
    { key: 'status', label: 'Status', sortable: false, width: 60 },
    { key: 'createdAt', label: 'Data de criação', sortable: true, width: 110 },
  ]

  const isRowsEmpty = isEmpty(data.items)
  const canRenderEmptyState = !isFetching && isRowsEmpty

  useRefetch({ ...filters }, refetch)

  return (
    <div className="flex flex-col gap-4 mt-2">
      <div className="flex flex-col gap-4 bg-background rounded-md border">
        <Table>
          <TableHeader>
            <TableRow>
              {headCells.map((cell) => (
                <TableHead
                  key={cell.key}
                  style={{ minWidth: cell.width }}
                  className={cn('font-medium', { 'cursor-pointer': cell.sortable })}
                  onClick={cell.sortable ? () => handleRequestSort(cell.key) : undefined}
                >
                  <div className="flex items-center">
                    {cell.label}

                    {cell.sortable &&
                      (cell.key === orderBy ? (
                        orderDir === OrderDirection.ASC ? (
                          <ChevronUp className="ml-2 h-4 w-4" />
                        ) : (
                          <ChevronDown className="ml-2 h-4 w-4" />
                        )
                      ) : (
                        <ChevronsUpDown className="ml-2 h-4 w-4" />
                      ))}
                  </div>
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>

          <TableBody>
            {isFetching
              ? times(pageSize, (i) => <ProductsTableLoading key={`skeleton-row-${i}`} />)
              : data.items.map((product) => (
                  <TableRow key={product.id}>
                    <TableCell>
                      <Link to={`/edit/${product.id}/#content`} className="flex items-center space-x-4">
                        <div className="relative">
                          <img
                            src={product.picture || placeholderAvatar}
                            alt={product.name}
                            className="w-16 h-auto aspect-[218/142] rounded object-cover"
                          />

                          {product.isVisible === false && (
                            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded">
                              <EyeOff className="text-primary-foreground" />
                            </div>
                          )}
                        </div>

                        <div>
                          <p className="text-base font-medium">{product.name}</p>
                          <p className="text-sm text-muted-foreground">{product.mainOfferId}</p>
                        </div>
                      </Link>
                    </TableCell>

                    <TableCell>
                      <ProductStatusTag status={product.status} />
                    </TableCell>

                    <TableCell>{moment(product.createdAt).format('D MMM. YYYY HH:mm:ss')}</TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>

        {canRenderEmptyState && <TableEmptyState title={t('empty')} />}
      </div>

      {!isRowsEmpty && (
        <TablePagination
          page={page}
          setPage={setPage}
          lastPage={data.lastPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      )}
    </div>
  )
}
