export const Envs = {
  BFF_WEB_URL: process.env['NX_BFF_WEB_URL'] ?? process.env['REACT_APP_HUB_API'],
  BFF_ACCESS_URL: process.env['NX_BFF_ACCESS_URL'] ?? process.env['REACT_APP_BFF_ACCESS'],
  APP_URL: process.env['NEXT_PUBLIC_APP_URL'] ?? process.env['REACT_APP_APP_URL'] ?? process.env['NX_APP_URL'],
  SITE_URL: process.env['NX_SITE_URL'] ?? process.env['NEXT_PUBLIC_SITE_URL'] ?? process.env['REACT_APP_SITE_URL'],
  BFF_MEMBERS_AREA_URL: process.env['NX_BFF_MEMBERS_AREA_URL'] ?? process.env['REACT_APP_BFF_MEMBERS_AREA_URL'],
  BFF_PRODUCT_URL: process.env['NX_BFF_PRODUCT_URL'] ?? process.env['REACT_APP_BFF_PRODUCT_URL'],
  FUNCTIONS_URL: process.env['NX_FUNCTIONS_URL'] ?? process.env['REACT_APP_FUNCTIONS_URL'],
  TINYMCE_API_KEY: process.env['NX_TINYMCE_API_KEY'] ?? process.env['REACT_APP_TINYMCE'] ?? '',
  ATTACHMENT_IMAGE_MIMETYPE_ALLOWED:
    process.env['NX_IMAGE_MIMETYPE_ALLOWED'] ?? process.env['REACT_APP_IMAGE_MIMETYPE_ALLOWED'] ?? '',
  ATTACHMENT_FILE_MIMETYPE_ALLOWED:
    process.env['NX_FILE_MIMETYPE_ALLOWED'] ?? process.env['REACT_APP_FILE_MIMETYPE_ALLOWED'] ?? '',
  PAY_URL: process.env['NX_PAY_URL'] ?? process.env['REACT_APP_PAY_CHECKOUT_URL'],
}
