import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useQuery } from '@tanstack/react-query'
import { ResourceType } from '../../domain/dtos/free-member'
import { QueryKey } from '../../domain/enums/query-key'
import { GetResourceDetails } from '../../usecases/get-resource-details'

export const useGetResourceDetails = (resourceType?: ResourceType) => {
  const container = useContainer()

  return useQuery(
    [QueryKey.getResourceDetails, resourceType],
    () => new GetResourceDetails(container.get(HttpClient)).execute(resourceType),
    {
      retry: 3,
      enabled: !!resourceType,
      refetchOnWindowFocus: false,
    },
  )
}
