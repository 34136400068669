import { Skeleton } from '@hub-la/shadcn'
import { ChargeDetailLoading } from './components/charge-detail-loading'
import { DescriptionLoading } from './components/description-loading'
import { HeaderLoading } from './components/header-loading'
import { PaymentDetailsLoading } from './components/payment-details-loading'

export const Loading = () => {
  return (
    <div className="my-4 flex flex-col container gap-4">
      <div className="mb-5 flex justify-between items-center">
        <Skeleton className="h-8 w-64" />
        <Skeleton className="w-36 h-12" />
      </div>

      <HeaderLoading />

      <ChargeDetailLoading />

      <PaymentDetailsLoading />

      <DescriptionLoading />
    </div>
  )
}
