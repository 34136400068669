import { Button, cn, Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@hub-la/shadcn'
import { PlusCircle } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AddGroupCard } from '../add-group'

type Props = {
  disabled?: boolean
  offerId?: string
  isLoading?: boolean
}

export const CreateGroup: React.FC<Props> = ({ disabled, offerId, isLoading }) => {
  const [open, setOpen] = useState<boolean>(false)
  const { t } = useTranslation()

  return (
    <div className="min-w-min self-end">
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              size="sm"
              variant="default"
              loading={isLoading}
              onClick={() => !disabled && setOpen(true)}
              className={cn(isLoading || !offerId ? 'opacity-50 cursor-not-allowed' : '')}
            >
              <PlusCircle className="mr-2 h-4 w-4" />

              {t('member-area.groups.createGroup')}
            </Button>
          </TooltipTrigger>

          {disabled && (
            <TooltipContent>
              <p>{t('member-area.groups.addDisclaimer')}</p>
            </TooltipContent>
          )}
        </Tooltip>
      </TooltipProvider>

      <AddGroupCard open={open} onClose={() => setOpen(false)} offerId={offerId} />
    </div>
  )
}
