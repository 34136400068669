import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import tinycolor from 'tinycolor2'
import { OfferSchemaValidationType } from '../../validations/offer-validation'

export const Timer: React.FC = () => {
  const { watch } = useFormContext<OfferSchemaValidationType>()
  const { timerSeconds, timerBgColor } = watch('checkout')
  const { t } = useTranslation()

  const textColor = tinycolor(timerBgColor).isLight() ? 'text-primary' : 'text-primary-foreground'

  return (
    <div className="w-full p-2 text-center relative" style={{ backgroundColor: String(timerBgColor) }}>
      <span className={`text-sm ${textColor}`}>
        {t('checkoutSettings.timerText', {
          timer: timerSeconds,
        })}
      </span>
    </div>
  )
}
