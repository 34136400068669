import moment from 'moment'
import { OutputFormatPeriodToMoment } from '../dtos/period-to-moment'
import { Period } from '../enums/period'

export const formatDate = (dateString: string, period: Period, customFormat?: string): string => {
  const date = moment(dateString).locale('pt-br')
  if (date.isValid()) {
    return customFormat ? date.format(customFormat) : date.format(OutputFormatPeriodToMoment[period])
  }

  return 'Invalid date'
}
