import { PostResponse, SectionResponse } from '@hub-la/fe-post'
import { reorder } from './reorder'

type ReorderPostsInput = {
  posts: PostResponse[]
  source: number
  destination: number
  sections: SectionResponse[]
  id: string
}

export class ReorderPosts {
  public execute({ posts, source, destination, sections, id }: ReorderPostsInput) {
    const reordered = reorder(posts, source, destination) as PostResponse[]

    return sections.map((section) => {
      if (section.id === id) {
        return {
          ...section,
          posts: reordered,
        }
      }

      return section
    })
  }
}
