import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { PutRecoverPasswordInput } from '../domain/dtos/put-recover-password-input'
import { ErrorCode } from '../domain/enums/error-code'
import { AccountUserNotFound } from '../domain/errors/account-user-not-found'
import { BlockedUserAuthAttemptError } from '../domain/errors/blocked-user-auth-attempt-error'
import { GeneralError } from '../domain/errors/general'
import { InvalidCaptchaError } from '../domain/errors/invalid-captcha-error'
import { RecoverPasswordNotFoundError } from '../domain/errors/recover-password-not-found-error'
import { Envs } from '../envs'

export class PutRecoverPassword {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(input: PutRecoverPasswordInput): Promise<null> {
    if (!input?.captcha) {
      throw new InvalidCaptchaError()
    }

    const response = await this.httpClient.request({
      method: HttpMethod.PUT,
      url: `${Envs.BFF_WEB_URL}/auth/recover-password/${input.token}`,
      body: input,
    })

    if (response.statusCode === HttpStatusCode.CREATED || response.statusCode === HttpStatusCode.OK) {
      return null
    }

    switch (response.data.code) {
      case ErrorCode.BLOCKED_USER_AUTH_ATTEMPT:
        throw new BlockedUserAuthAttemptError()
      case ErrorCode.USER_NOT_FOUND:
        throw new AccountUserNotFound()
      case ErrorCode.RECOVER_PASSWORD_NOT_FOUND:
        throw new RecoverPasswordNotFoundError()
      case ErrorCode.INVALID_CAPTCHA:
        throw new InvalidCaptchaError()
      default:
        throw new GeneralError('recoverPassword')
    }
  }
}
