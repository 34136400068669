import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { GetAffiliatesOutput } from '../domain/dtos/affiliate'
import { GeneralError } from '../domain/errors/general'
import { Envs } from '../envs'

type ChangeAffiliateCommissionInput = {
  affiliateId: string
  sellCommission: number
  renewalCommission: number
  useDefaultCommission: boolean
}

export class ChangeAffiliateCommission {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(input: ChangeAffiliateCommissionInput): Promise<GetAffiliatesOutput> {
    const response = await this.httpClient.request({
      method: HttpMethod.POST,
      url: `${Envs.BFF_WEB_URL}/affiliates/change-commission`,
      body: {
        receiverId: input.affiliateId,
        commission: input.useDefaultCommission
          ? null
          : {
              sell: input.sellCommission,
              renewal: input.renewalCommission,
            },
      },
    })

    if ([HttpStatusCode.OK, HttpStatusCode.CREATED].includes(response.statusCode)) {
      return response.data
    }

    throw new GeneralError(response.data?.errorCode)
  }
}
