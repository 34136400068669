export const pt = {
  eventsTitle: 'Eventos',
  productsTitle: 'Produtos',
  empty: 'Nenhum resultado encontrado',
  search: 'Buscar...',
  tabs: {
    products: 'Meus produtos',
    invites: 'Minhas co-produções',
    affiliates: 'Minhas afiliações',
  },
  eventTabs: {
    futures: 'Futuros',
    past: 'Passados',
  },
  fieldErrors: {
    min: 'O campo deve ter no mínimo 3 caracteres',
    isGreater: 'A data final deve ser maior que a data inicial',
    typeError: 'Campo obrigatório',
    required: 'Campo obrigatório',
    isGreaterNow: 'A data deve ser maior que agora',
  },
  copyClipboard: {
    copyLink: 'Copiar link de afiliado',
    linkCopied: 'Link de afiliado copiado',
  },
  products: {
    category: {
      homeAndConstruction: 'Casa e Construção',
      privateclub: 'Clube Privado',
      culinaryAndGastronomy: 'Culinária e Gastronomia',
      design: 'Design',
      personalDevelopment: 'Desenvolvimento Pessoal',
      law: 'Direito',
      education: 'Educação',
      investmentsAndFinancialEducation: 'Educação Financeira',
      professionalEducation: 'Educação Profissional',
      entrepreneurship: 'Empreendedorismo',
      entertainment: 'Entretenimento',
      spirituality: 'Espiritualidade',
      games: 'Games',
      hobbies: 'Hobbies',
      languages: 'Idiomas',
      digitalMarketing: 'Marketing Digital',
      milesAndTrips: 'Milhas e Viagens',
      fashionAndBeauty: 'Moda e Beleza',
      musicAndArts: 'Música e Artes',
      news: 'Notícias',
      promotionsAndCashback: 'Promoções e Cashback',
      relationship: 'Relacionamentos',
      signalAndTradingRooms: 'Salas de Sinais e Negociações',
      healthAndFitness: 'Saúde e Fitness',
      other: 'Outro',
    },
    status: {
      isDraft: 'Rascunho',
      isPublished: 'Publicado',
      isSelling: 'Vendendo',
      isWaitlisted: 'Lista de espera',
      isNotEnabled: 'Desativado',
      isNotSelling: 'Inativo',
      isUnavailable: 'Recusado',
      isDeleted: 'Deletado',
    },
    noOption: 'Nenhuma opção',
  },
  affiliates: {
    status: {
      pending: 'Pendente',
      enabled: 'Ativo',
      disabled: 'Inativo',
    },
    linksModal: {
      title: 'Seus links de afiliado',
      description: 'Compartilhe o seu link como afiliado para receber sobre as vendas',
      linkComission: {
        sellMaxAmount: 'Até {{maxAmountCents}} por venda',
        sellComissionPercent: '{{percent}}% de comissão',
        renewalMaxAmount: 'Até {{maxAmountCents}} por renovação',
        renewalComissionPercent: '{{percent}}% de comissão',
      },
      snackbar: {
        copiedCheckoutLink: 'Link copiado para a área de transferência!',
      },
    },
  },
  partnerships: {
    status: {
      invited: 'Pendente',
      cancellation_requested: 'Cancelamento solicitado',
      rejected: 'Recusado',
      accepted: 'Ativo',
      canceled: 'Inativo',
      finished: 'Expirado',
    },
    acceptInvite: {
      title: 'Confirmar parceria',
      message: 'Você deseja aceitar essa parceria?',
      label: 'Aceito os termos de uso da parceria.',
      commission: 'Percentual nas vendas de {{commission}}%',
      expiresAt: 'Até {{expiresAt}}',
      noExpires: 'Sem data final',
      notes: '+ Condições de emissão de notas',
      terms: 'Termos de uso',
    },
    rejectInvite: {
      title: 'Recusar o convite de parceria?',
      message: 'Você deseja recusar essa parceria?',
    },
    acceptCancellationRequest: {
      title: 'Deseja aceitar cancelamento de parceria?',
      message: 'Você deseja aceitar esse cancelamento de parceria?',
    },
    rejectCancellationRequest: {
      title: 'Recusar cancelamento de parceria?',
      message: 'Você deseja recusar esse cancelamento de parceria?',
    },
    accept: 'Aceitar',
    reject: 'Recusar',
    cancel: 'Cancelar',
    back: 'Voltar',
  },
}
