export const PrivacyPolicyInit = () => {
  return (
    <div className="h-screen">
      <object
        aria-label="Privacy Policy"
        data="https://firebasestorage.googleapis.com/v0/b/chatpay-cd120.appspot.com/o/public%2Fapp%2Fprivacy_policy.pdf?alt=media"
        width="100%"
        height="100%"
        type="application/pdf"
      />
    </div>
  )
}
