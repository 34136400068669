import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation } from '@tanstack/react-query'
import { QueryKey } from '../../domain/enums/query-keys'
import { RefundInvoice } from '../../usecases/refund-invoice'

export const useRefundInvoice = () => {
  const container = useContainer()

  return useMutation(
    [QueryKey.refundInvoice],
    (id: string) => new RefundInvoice(container.get(HttpClient)).execute(id),
    {
      retry: false,
    },
  )
}
