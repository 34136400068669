import { ScrollArea } from '@hub-la/shadcn'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { WithdrawalDto } from '../../../../domain/dtos/withdrawal.dto'
import { Money } from '../../../../domain/vos/money'
import { ContentRow } from '../../content-row/content-row'
import { ContentSectionTitle } from '../../invoice-modal/modal-content/content-section-title/content-section-title'
import { Movements } from '../../movements/movements'
import { StatusTag } from '../../status-tag/status-tag'

interface WithdrawalContentProps {
  withdrawal: WithdrawalDto
  isLoading: boolean
}

export const WithdrawalContent: React.FC<WithdrawalContentProps> = ({ withdrawal, isLoading }) => {
  const { t } = useTranslation()

  const {
    amountInCents,
    bankAccountNumber,
    bankAgency,
    bankNumber,
    bankAccountType,
    movements,
    taxInCents,
    rejectionInfo,
  } = withdrawal

  const amount = Money.build(amountInCents / 100).getValue()
  const tax = Money.build((taxInCents / 100) * -1).getValue()
  const amountWithTax = Money.build((amountInCents + taxInCents) / 100).getValue()
  const isPixKey = bankAccountNumber === null || undefined

  return (
    <ScrollArea className="h-full w-full pr-4">
      <div className="space-y-2">
        {/* Content sections */}
        <div className="flex items-center gap-2 mb-4">
          <h3 className="text-2xl font-semibold">{amountWithTax}</h3>
          {movements && <StatusTag status={movements[0]?.transactionSource} />}
        </div>

        {/* Rejection Section */}
        {rejectionInfo && (
          <div className="bg-red-50 p-4 rounded-lg">
            <ContentSectionTitle color="redSecondary" title={t('withdrawalDetails.rejectionInfo.title')} />
            <ContentRow
              variant="body2"
              label={t(`withdrawalDetails.rejectionInfo.reasons.${rejectionInfo}`, rejectionInfo)}
              value={''}
            />
          </div>
        )}

        {/* Bank Account Section */}
        <div>
          <ContentSectionTitle title={t('withdrawalDetails.bankAccount.title')} />
          <div className="mt-2 space-y-2">
            {/* Added vertical spacing between rows */}
            {isPixKey ? (
              <ContentRow label={t('withdrawalDetails.isPixKey')} value={''} />
            ) : (
              <>
                <ContentRow label={t('withdrawalDetails.bankAccount.bankName')} value={`${bankNumber}`} />
                <ContentRow label={t('withdrawalDetails.bankAccount.bankAgency')} value={`${bankAgency}`} />
                <ContentRow label={t('withdrawalDetails.bankAccount.bankNumber')} value={`${bankAccountNumber}`} />
                <ContentRow
                  label={t('withdrawalDetails.bankAccount.bankAccountType')}
                  value={t(`withdrawalDetails.bankAccount.bankType.${bankAccountType}`)}
                />
              </>
            )}
          </div>
        </div>

        {/* Payment Section */}
        <div>
          <ContentSectionTitle title={t('withdrawalDetails.paymentData.title')} />
          <div className="mt-2 space-y-2">
            <ContentRow label={t('withdrawalDetails.paymentData.amount')} value={amountWithTax} />
            <ContentRow
              secondaryText
              tooltip={t('withdrawalDetails.paymentData.tooltip', { value: Money.build(taxInCents / 100).getValue() })}
              label={t('withdrawalDetails.paymentData.tax')}
              value={tax}
            />
            <div className="border-t border-dashed  my-4" />
            <ContentRow variant="body1" bold label={t('withdrawalDetails.paymentData.total')} value={amount} />
          </div>
        </div>

        {/* Movements Section */}
        <div>
          <ContentSectionTitle title={t('invoiceDetails.movements')} />
          <div className="mt-2">
            <Movements movements={movements} isLoading={isLoading} />
          </div>
        </div>
      </div>
    </ScrollArea>
  )
}
