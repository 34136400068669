import React from 'react'
import { Separator } from '@hub-la/shadcn'

type Props = {
  title: string
  content: React.ReactElement
  fontWeight?: 'bold' | 'normal'
  showDivider?: boolean
}

export const ContentRow: React.FC<Props> = ({ title, content, fontWeight = 'normal', showDivider = false }) => {
  return (
    <div className="flex flex-col w-full gap-2">
      <div className="flex items-center justify-between gap-1">
        <div className="flex items-center gap-2">
          <p className={`text-base ${fontWeight === 'bold' ? 'font-bold' : 'font-normal'}`}>{title}</p>
        </div>
        {content}
      </div>
      {showDivider && <Separator className="my-2" />}
    </div>
  )
}
