import { Asset, AssetResponse, AssetStatus, GetAssetSize } from '@hub-la/fe-asset'
import { Card, CardContent, CardFooter } from '@hub-la/shadcn'
import { Download, File, FileText, Image as ImageIcon, Paperclip } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { HumanFileSize } from '../../../usecases/human-file-size'
import { useSaveFile } from '../../hooks'

export interface IProps {
  assets: AssetResponse[]
}

const isSupportedImageType = (fileName: string) => {
  const supportedExtensions = ['jpg', 'jpeg', 'png', 'gif', 'webp']
  const extension = fileName.split('.').pop()?.toLowerCase()
  return extension ? supportedExtensions.includes(extension) : false
}

const isPDF = (fileName: string) => {
  return fileName.toLowerCase().endsWith('.pdf')
}

export const PostAttachmentView = ({ assets }: IProps) => {
  const { t } = useTranslation()
  const saveFile = useSaveFile()

  const downloadAsset = async (asset: Asset) => {
    await saveFile.mutateAsync({ asset })
  }

  return (
    <div className="grid grid-cols-4 gap-2">
      {assets.map((asset: AssetResponse) => {
        const assetSize = new GetAssetSize().execute(asset as unknown as Asset)
        const isProcessing = asset.status === AssetStatus.processing || asset.status === AssetStatus.uploading
        const hasError = asset.status === AssetStatus.error
        const isDownloading = saveFile.isLoading && saveFile.variables?.asset?.id === asset?.id
        const canShowThumbnail = isSupportedImageType(asset.name) && asset.url
        const isPDFFile = isPDF(asset.name)
        const isDisabled = isDownloading || isProcessing || hasError

        return (
          <Card
            className={`bg-secondary/40 transition-colors col-span-2 lg:col-span-1 flex flex-col justify-between ${
              isDisabled ? 'opacity-50 cursor-not-allowed' : 'hover:bg-secondary/40 cursor-pointer'
            }`}
            key={asset.id}
            onClick={() => !isDisabled && downloadAsset(asset as unknown as Asset)}
          >
            <CardContent className="p-4 flex flex-col min-h-[240px] gap-2">
              {canShowThumbnail ? (
                <div className="w-full h-32 relative mb-2">
                  <img src={asset.url} alt={asset.name} className="w-full h-full object-cover rounded-md" />
                </div>
              ) : isPDFFile ? (
                <div className="w-full h-32 rounded-lg relative mb-2 bg-secondary/30 flex items-center justify-center">
                  <FileText className="w-16 h-16 text-muted-foreground" />
                </div>
              ) : (
                <div className="w-full h-32 rounded-lg relative mb-2 bg-secondary/30 flex items-center justify-center">
                  <File className="w-16 h-16 text-muted-foreground" />
                </div>
              )}

              <p className="text-sm break-words">{asset.name}</p>

              {isProcessing && <p className="text-sm text-muted-foreground">{t('assets.processing')}</p>}

              {hasError && <p className="text-sm text-muted-foreground">{t('assets.error')}</p>}

              {assetSize !== 0 && (
                <div className="flex items-center gap-1 ">
                  {canShowThumbnail ? (
                    <ImageIcon className="w-4 h-4 text-foreground" />
                  ) : isPDFFile ? (
                    <FileText className="w-4 h-4 text-foreground" />
                  ) : (
                    <Paperclip className="w-4 h-4 text-foreground" />
                  )}
                  <span className="text-xs text-muted-foreground">
                    {new HumanFileSize().execute(assetSize, true, 0)}
                  </span>
                </div>
              )}
            </CardContent>
            <CardFooter className="p-4 border-t">
              <div className="w-full flex items-center justify-center gap-2 text-sm font-medium">
                <Download className="w-4 h-4" />
                {t('postDetail.assets.download')}
              </div>
            </CardFooter>
          </Card>
        )
      })}
    </div>
  )
}
