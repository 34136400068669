import { Skeleton, TableCell, TableRow } from '@hub-la/shadcn'

export const TableRowLoading: React.FC = () => (
  <TableRow>
    <TableCell>
      <div className="flex flex-col gap-2">
        <Skeleton className="h-10 w-40" />

        <Skeleton className="h-5 w-64" />
      </div>
    </TableCell>

    <TableCell>
      <Skeleton className="h-4 w-20" />
    </TableCell>

    <TableCell>
      <Skeleton className="h-5 w-14 rounded-full" />
    </TableCell>

    <TableCell>
      <Skeleton className="ml-2 h-6 w-6 rounded-full" />
    </TableCell>
  </TableRow>
)
