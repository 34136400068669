import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject, injectable } from 'inversify'
import { GetIdentityResponse } from '../domain/dtos/get-identity-response'

@injectable()
export class GetIdentity {
  public constructor(@inject(HttpClient) private readonly httpClient: HttpClient) { }

  public async execute(): Promise<GetIdentityResponse> {
    const URI = process.env['NX_BFF_WEB_URL'] ?? process.env['REACT_APP_HUB_API']

    if (!URI) {
      throw new Error('errors.general')
    }

    const response = await this.httpClient.request({
      url: `${URI}/kyc/get-identity`,
      method: HttpMethod.GET,
    })

    switch (response.statusCode) {
      case HttpStatusCode.OK:
        return response.data
      default:
        throw new Error(response?.data?.code)
    }
  }
}
