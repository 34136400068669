import { object, string } from 'yup'

export const validationSchema = () => {
  return object({
    highlightType: string().required(),
    post: object()
      .nullable()
      .when('highlightType', {
        is: 'post',
        then: object().required('errors.post'),
      }),
    videoAsset: object()
      .nullable()
      .when('highlightType', {
        is: 'video',
        then: object().required('customHighlight.inputs.videoAsset.error'),
      }),
    desktopAsset: object()
      .nullable()
      .when('highlightType', {
        is: 'image',
        then: object().required('customHighlight.inputs.desktopAsset.error'),
      }),
    mobileAsset: object()
      .nullable()
      .when('highlightType', {
        is: 'image',
        then: object().required('customHighlight.inputs.mobileAsset.error'),
      }),
    logoAsset: object().nullable(),
  })
}
