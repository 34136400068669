import { container } from '@hub-la/fe-container'
import { UserPendenciesPage, UserPendenciesProvider } from '@hub-la/fe-user-pendencies'
import { withRoleplayRedirect } from '../components/roleplay-redirect'

export const UserPendenciesInit = withRoleplayRedirect(() => {
  return (
    <UserPendenciesProvider container={container}>
      <UserPendenciesPage />
    </UserPendenciesProvider>
  )
})
