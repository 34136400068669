import { useFormikContext } from 'formik'
import get from 'lodash/get'
import { useTranslation } from 'react-i18next'

export const useField = () => {
  const { errors, touched, getFieldMeta } = useFormikContext()
  const { t } = useTranslation()

  const hasError = (field: string) => get(touched, field) && Boolean(get(errors, field))

  const getErrorText = (field: string) => get(touched, field) && t(get(errors, field))

  const isValid = (field: string) => {
    const meta = getFieldMeta(field)

    return !meta?.error
  }

  return { hasError, getErrorText, isValid }
}
