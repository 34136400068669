/* tslint:disable */
/* eslint-disable */
/**
 * BFF Web Product
 * API reference used to call the backend from the web frontend
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { PlanPriceDto } from './plan-price-dto';
// May contain unused imports in some cases
// @ts-ignore
import { UserRefDto } from './user-ref-dto';

/**
 * 
 * @export
 * @interface OfferDto
 */
export interface OfferDto {
    /**
     * 
     * @type {string}
     * @memberof OfferDto
     */
    'id'?: string;
    /**
     * 
     * @type {UserRefDto}
     * @memberof OfferDto
     */
    'owner': UserRefDto;
    /**
     * 
     * @type {string}
     * @memberof OfferDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof OfferDto
     */
    'priceType': OfferDtoPriceTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof OfferDto
     */
    'price'?: number;
    /**
     * 
     * @type {number}
     * @memberof OfferDto
     */
    'oldPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof OfferDto
     */
    'installments'?: number;
    /**
     * 
     * @type {Array<PlanPriceDto>}
     * @memberof OfferDto
     */
    'plans'?: Array<PlanPriceDto>;
    /**
     * 
     * @type {string}
     * @memberof OfferDto
     */
    'status': OfferDtoStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof OfferDto
     */
    'isDefault': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OfferDto
     */
    'isAvailableForAffiliates': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof OfferDto
     */
    'isArchived': boolean;
    /**
     * 
     * @type {string}
     * @memberof OfferDto
     */
    'createdAt': string;
}

/**
    * @export
    * @enum {string}
    */
export enum OfferDtoPriceTypeEnum {
    ONE_TIME = 'one_time',
    RECURRING = 'recurring',
    MEMBERSHIP_FEE = 'membership_fee'
}
/**
    * @export
    * @enum {string}
    */
export enum OfferDtoStatusEnum {
    IS_DRAFT = 'isDraft',
    IS_PUBLISHED = 'isPublished',
    IS_SELLING = 'isSelling',
    IS_WAITLISTED = 'isWaitlisted',
    IS_NOT_ENABLED = 'isNotEnabled',
    IS_NOT_SELLING = 'isNotSelling',
    IS_UNAVAILABLE = 'isUnavailable',
    IS_DELETED = 'isDeleted'
}


