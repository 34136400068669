import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useToast } from '@hub-la/shadcn'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { QueryKey } from '../../domain/enums/query-key'
import { ListMemberGroups } from '../../usecases/list-member-groups'

export const useListMemberGroups = () => {
  const container = useContainer()
  const { toast } = useToast()
  const { t } = useTranslation()

  return useQuery([QueryKey.listMemberGroups], () => new ListMemberGroups(container.get(HttpClient)).execute(), {
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
    initialData: [],
    onError: (error) => {
      toast({
        title: t((error as Error)?.message) ?? '',
        variant: 'destructive',
      })
    },
  })
}
