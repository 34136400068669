import { useToast } from '@hub-la/shadcn'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { TicketDefaultValuesMapper } from '../../../domain/mappers/make-ticket-default-values'
import { TicketForm } from '../../components/ticket-form/ticket-form'
import { useGetEvent } from '../../hooks/use-get-event'
import { usePostTicket } from '../../hooks/use-post-ticket'
import { TicketSchemaValidationType } from '../../validations/ticket-validation'

export const CreateTicketPage = () => {
  const history = useHistory()
  const { toast } = useToast()
  const { t } = useTranslation()
  const { productId } = useParams<{ productId: string }>()
  const { data: product } = useGetEvent(productId)

  const { mutate, isLoading } = usePostTicket({
    onSuccess: () => history.push(`/edit/${productId}/tickets`),
    onError: (error) => {
      toast({
        description: t('backendErrors.' + error.message),
        variant: 'destructive',
      })
    },
  })

  const defaultValues: TicketSchemaValidationType = useMemo(() => {
    return TicketDefaultValuesMapper(productId, undefined)
  }, [productId])

  return (
    <TicketForm
      productId={productId}
      onSubmit={(data) => {
        mutate({ ...data, productId: product?.mainOfferId ?? '' })
      }}
      isSubmitting={isLoading}
      defaultValues={defaultValues}
    />
  )
}
