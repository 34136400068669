import { useGetUser } from '@hub-la/fe-get-user'
import { FormikProvider, useFormik } from 'formik'
import { useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { Trans, useTranslation } from 'react-i18next'
import { usePostSignInByEmailCompleteRegistration } from '../../hooks/use-post-sign-in-by-email-complete-registration'
import { signInByEmailCompleteRegistrationSchema } from '../../validations/signin-email'
import { AuthCard } from '../auth-card/auth-card'
import { PasswordStatus } from '../password-status'

import { Alert, AlertDescription, Button, Input, Label } from '@hub-la/shadcn'
import { useAuthRedirect } from '../../hooks/use-auth-redirect'

const SignInByEmailCompleteRegistration = ({ code, authId }) => {
  const [showSkipButton, setShowSkipButton] = useState<boolean>(false)
  const { t } = useTranslation()
  const { redirectAutoIfAuthenticated } = useAuthRedirect()
  const { executeRecaptcha } = useGoogleReCaptcha()
  const { data: userRefData } = useGetUser()
  const { error, mutate, isLoading, isError, reset, isSuccess } = usePostSignInByEmailCompleteRegistration({
    onSuccess: () => {
      redirectAutoIfAuthenticated()
    },
    onError: () => {
      setShowSkipButton(true)
    },
  })

  const form = useFormik({
    initialValues: {
      email: userRefData?.email || '',
      password: '',
      passwordConfirmation: '',
    },
    onSubmit: async (values) => {
      const captcha = await executeRecaptcha?.('complete_registration')
      mutate({ ...values, authId, code: parseInt(code), captcha })
    },
    validationSchema: signInByEmailCompleteRegistrationSchema,
    enableReinitialize: true,
  })

  return (
    <AuthCard
      showTerms={false}
      title="Complete seu cadastro"
      description="Crie a sua senha para continuar acessando sua conta"
    >
      <FormikProvider value={form}>
        <form onSubmit={form.handleSubmit} className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="email">E-mail</Label>
            <Input
              id="email"
              name="email"
              type="email"
              autoComplete="off"
              onChange={form.handleChange}
              value={form.values.email}
              disabled
            />
            {form.touched.email && form.errors.email && (
              <p className="text-sm text-red-500">
                <Trans i18nKey={form.errors.email} components={[<a href="/signin" />]} />
              </p>
            )}
          </div>

          <div className="space-y-2">
            <Label htmlFor="password">Senha</Label>
            <Input
              id="password"
              name="password"
              type="password"
              autoComplete="new-password"
              autoFocus
              onChange={form.handleChange}
              value={form.values.password}
            />
            {form.touched.password && form.errors.password && (
              <p className="text-sm text-red-500">{t(form.errors.password)}</p>
            )}
          </div>

          <div className="space-y-2">
            <Label htmlFor="passwordConfirmation">Confirmar senha</Label>
            <Input
              id="passwordConfirmation"
              name="passwordConfirmation"
              type="password"
              autoComplete="new-password"
              onChange={form.handleChange}
              value={form.values.passwordConfirmation}
            />
            {form.touched.passwordConfirmation && form.errors.passwordConfirmation && (
              <p className="text-sm text-red-500">{t(form.errors.passwordConfirmation)}</p>
            )}
          </div>

          <PasswordStatus passwordValue={form.values.password} />

          <Button type="submit" className="w-full" disabled={isLoading || isSuccess}>
            {isLoading || isSuccess ? 'Processando...' : 'Criar senha'}
          </Button>

          {showSkipButton && (
            <Button variant="outline" className="w-full" onClick={() => redirectAutoIfAuthenticated()} type="button">
              Pular essa etapa
            </Button>
          )}
        </form>
      </FormikProvider>

      {isError && (
        <Alert variant="destructive" className="mt-4">
          <AlertDescription>{t((error as Error)?.message)}</AlertDescription>
        </Alert>
      )}
    </AuthCard>
  )
}

export { SignInByEmailCompleteRegistration }
