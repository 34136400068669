import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { SignedUrlResponse } from '../domain/dtos'
import { Envs } from '../envs'

type GetAssetSignedUrlInput = {
  name: string
  description?: string
  mimeType: string
}

export class GetAssetSignedUrl {
  public constructor(@inject(HttpClient) private readonly httpClient: HttpClient<SignedUrlResponse>) {}

  public execute(input: GetAssetSignedUrlInput): Promise<SignedUrlResponse> {
    const body = {
      name: input.name,
      description: input.description ? input.description : '',
      mimeType: input.mimeType,
    }

    return new Promise((resolve, reject) => {
      this.httpClient
        .request({
          url: `${Envs.BFF_MEMBERS_AREA_URL}/assets`,
          body,
          method: HttpMethod.POST,
        })
        .then(({ statusCode, data }) => {
          if (statusCode === undefined) {
            return false
          }
          if (!data || ![HttpStatusCode.CREATED, HttpStatusCode.OK].includes(statusCode)) {
            return reject(`Ocorreu um erro na url assinada upload de arquivo`) //@TODO error code of bff
          }
          return resolve(data)
        })
        .catch(() => {
          return reject(`Ocorreu um erro na url assinada upload de arquivo`) //@TODO error code of bff
        })
    })
  }
}
