import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { OffersByProductIdResponseDto } from '@hub-la/sdk-bff-product'
import { inject } from 'inversify'
import { ChangeStatusInput } from '../../domain/dtos/offers/change-status'
import { GeneralError } from '../../domain/errors/general'
import { Envs } from '../../envs'

export class PostChangeStatus {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(input?: ChangeStatusInput, signal?: AbortSignal): Promise<OffersByProductIdResponseDto> {
    const response = await this.httpClient.request({
      method: HttpMethod.PUT,
      url: `${Envs.BFF_PRODUCT_URL}/products/${input?.productId}/offers/${input?.offerId}/status`,
      body: {
        status: input?.status,
      },
      signal,
    })

    if (response.statusCode === HttpStatusCode.OK || response.statusCode === HttpStatusCode.CREATED) {
      return response.data
    }

    throw new GeneralError()
  }
}
