import { useContainer } from "@hub-la/fe-container"
import { HttpClient } from "@hub-la/fe-core-http-client"
import { useQuery } from "@tanstack/react-query"
import { QueryKey } from "../../domain/enums/query-key"
import { GetSmartInstallmentCyclesSummarie } from "../../usecases/get-smart-installment-cycles-summarie"

export function useGetSmartInstallmentCyclesSummarie() {
  const container = useContainer()

  return useQuery([QueryKey.getSmartInstallmentCyclesSummarie], () => new GetSmartInstallmentCyclesSummarie(container.get(HttpClient)).execute(), {
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
    initialData: {
      amountTotalPaidCents: 0,
      amountTotalDraftCents: 0,
      amountTotalOverdueCents: 0
    },
  })
}

