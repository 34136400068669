export const pt = {
  empty: 'Nenhum afiliado encontrado',
  defaultCommission: 'Padrão',
  defaultCommissionTooltip: 'A comissão padrão reflete a configuração atual do programa de afiliados do produto.',
  export: {
    title: 'Exportar afiliados',
    fileExtension: 'Extensão do arquivo',
    confirm: 'Exportar',
    cancel: 'Cancelar',
  },
  copyClipboard: {
    copyAffiliateId: 'Copiar ID de afiliado',
    affiliateIdCopied: 'ID de afiliado copiado',
    error: 'Erro ao copiar',
  },
  changeCommissionModal: {
    title: 'Editar comissão',
    description: 'Ofereça uma comissão personalizada para pessoas que queiram promover seu produto.',
    alert:
      'As alterações realizadas só afetarão as compras futuras, ou seja, as renovações de clientes que foram trazidos pelos afiliados antes das mudanças continuarão gerando comissão com base no acordo anterior.',
    input: {
      sell: 'Comissão por primeira compra (%)',
      renewal: 'Comissão por renovação (%)',
      type: 'Tipo de comissão',
      typePlaceholder: 'Selecione o tipo de comissão',
      option_1: 'Primeira compra e renovação',
      option_2: 'Apenas primeira compra',
      option_3: 'Apenas renovação',
    },
    toast: {
      defaultError: 'Erro ao alterar comissão',
      success: 'Comissão alterada com sucesso',
    },
    cancel: 'Cancelar',
    submit: 'Salvar',
  },
}
