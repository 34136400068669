import { Avatar, AvatarFallback, AvatarImage } from '@hub-la/shadcn'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import DefaultAvatar from '../../assets/default-avatar.svg'
import IconTelegramImage from '../../assets/icon-telegram.svg'

interface IProps {
  name: string
  username?: string | null
  canEdit?: boolean
  picture?: string | null
}

export const ConfirmAccount = ({ name, username, picture, canEdit = false }: IProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <div data-testid="confirm-account-content" className="flex flex-row items-center space-x-4">
      <div className="relative">
        <Avatar className="w-[52px] h-[52px] border border-[#E0E4D8]">
          <AvatarImage src={picture ?? DefaultAvatar} alt={name} />
          <AvatarFallback>{name ? name.charAt(0) : 'U'}</AvatarFallback>
        </Avatar>
        <div className="absolute bottom-0 right-0 rounded-full bg-[#5f9de2] border border-[#538bbf] w-[22px] h-[22px] flex items-center justify-center">
          <img src={IconTelegramImage} alt="Telegram" className="w-4 h-4" />
        </div>
      </div>
      <div className="flex flex-col justify-center flex-1">
        <p className="text-left text-[#1A1C18] font-bold truncate max-w-[240px]">{name}</p>
        {username && <p className="text-left text-[#444840] font-medium truncate max-w-[240px]">@{username}</p>}
      </div>
      {canEdit && (
        <Link
          data-testid="link-edit-account"
          to="/verification-telegram/intro"
          className="text-sm font-bold text-[#314000] hover:underline"
        >
          {t('confirmAccount.buttonEdit')}
        </Link>
      )}
    </div>
  )
}

export default ConfirmAccount
