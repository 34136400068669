import { Card, CardContent, CardHeader, Checkbox, cn, Pix } from '@hub-la/shadcn'
import { format } from 'date-fns'
import { Banknote, ChevronRight, CreditCard } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { SmartInstallment } from '../../domain/dtos/get-smart-installment-cycle'
import { PaymentMethod } from '../../domain/enums/payment-method'
import { SmartInstallmentStatus } from '../../domain/enums/smart-installment-status'
import { formatCurrency } from '../../domain/vos/format-currency'

const StatusToColor = {
  [SmartInstallmentStatus.STATUS_PAID]: 'bg-green-100 text-green-400 dark:bg-green-950',
  [SmartInstallmentStatus.STATUS_EXPIRED]: 'bg-red-100 text-red-400 dark:bg-red-950',
}

const PaymentMethodToIcon = {
  [PaymentMethod.PIX]: <Pix />,
  [PaymentMethod.BANK_SLIP]: <Banknote />,
  [PaymentMethod.CREDIT_CARD]: <CreditCard />,
}

interface SmartInstallmentCardProps {
  showProductName?: boolean
  onCheckedChange?: (value: boolean) => void
  checked?: boolean
  onClick?: () => void
  smartInstallment: SmartInstallment
}

export const SmartInstallmentCard = ({ smartInstallment, ...props }: SmartInstallmentCardProps) => {
  const { t } = useTranslation()

  const getDateText = () => {
    if (smartInstallment.status === SmartInstallmentStatus.STATUS_EXPIRED && smartInstallment.overdueDate) {
      return t('overdueDate', { date: format(new Date(smartInstallment.overdueDate), 'dd MMM. yyyy') })
    }
    if (smartInstallment.status === SmartInstallmentStatus.STATUS_PAID && smartInstallment.paidDate) {
      return t('paidDate', { date: format(new Date(smartInstallment.paidDate), 'dd MMM. yyyy') })
    }
    if (smartInstallment.status === SmartInstallmentStatus.STATUS_PROCESSING) {
      return t('processingPayment')
    }

    return t('scheduledDate', { date: format(new Date(smartInstallment.scheduledDate), 'dd MMM. yyyy') })
  }

  return (
    <Card
      className={cn(
        'text-sm flex flex-col md:flex-row md:items-center',
        props.onClick ? 'cursor-pointer hover:bg-secondary/80' : '',
        props.checked ? 'bg-secondary/80' : '',
      )}
      onClick={props.onClick}
    >
      <CardHeader className="pr-4">
        <div className="flex items-center">
          {props.onCheckedChange && (
            <Checkbox className="mr-4" onCheckedChange={props.onCheckedChange} checked={props.checked} />
          )}
          <div
            className={cn(
              'rounded-full max-w-max p-4 [&>*]:w-5 [&>*]:h-5',
              StatusToColor[smartInstallment.status] ?? 'bg-muted',
            )}
          >
            {PaymentMethodToIcon[smartInstallment.paymentMethod]}
          </div>
        </div>
      </CardHeader>
      <CardContent className="flex-1 flex items-center justify-between md:pb-0 md:pl-0">
        <div className="flex-1">
          <h3>
            {props.showProductName
              ? smartInstallment.productName
              : format(new Date(smartInstallment.scheduledDate), 'dd MMM. yyyy')}
          </h3>
          <p className="text-muted-foreground">{getDateText()}</p>
        </div>
        <div>
          <p>{formatCurrency(smartInstallment.amount / 100)}</p>
          <span className="text-muted-foreground">
            {t('installmentOfInstallments', {
              installment: smartInstallment.installment,
              installments: smartInstallment.installments,
            })}
          </span>
        </div>
        {props.onClick && <ChevronRight className="h-5 w-5 hidden md:block ml-4" />}
      </CardContent>
    </Card>
  )
}
