import { Button, Popover, PopoverContent, PopoverTrigger } from '@hub-la/shadcn'
import EmojiPicker, { EmojiClickData } from 'emoji-picker-react'
import { ChevronDown } from 'lucide-react'
import React from 'react'

type Props = {
  value: string
  onChange: (value: string) => void
}

export const InputEmoji: React.FC<Props> = (props) => {
  const { value, onChange } = props

  const onEmojiClick = (event: EmojiClickData) => {
    onChange(event.emoji)
  }

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="outline" className="flex gap-2 justify-between">
          <span>{value}</span>
          <ChevronDown className="h-4 w-4 opacity-50" />
        </Button>
      </PopoverTrigger>

      <PopoverContent className="w-auto p-0">
        <EmojiPicker lazyLoadEmojis onEmojiClick={onEmojiClick} />
      </PopoverContent>
    </Popover>
  )
}
