export const TermsOfUseInit = () => {
  return (
    <div className="h-screen">
      <object
        aria-label="Terms of Use"
        data="https://firebasestorage.googleapis.com/v0/b/chatpay-cd120.appspot.com/o/public%2Fapp%2Fterms_of_use.pdf?alt=media"
        width="100%"
        height="100%"
        type="application/pdf"
      />
    </div>
  )
}
