import moment, { Moment } from 'moment-timezone'

type DateTimeRange = {
  from: string
  to: string
}

/**
 * Given the time period (e.g. last 30 days), returns the dates
 * surrounding it. The 'from' date should have all zeros in the time part,
 * meanwhile the 'to' date should be at the end of the day.
 */
export class GetDateRangeFromPeriod {
  public execute(period: number): DateTimeRange {
    const endDate = moment().tz('America/Sao_Paulo')
    const startDate = moment().tz('America/Sao_Paulo').subtract(period, 'days').startOf('day')

    const toDateString = (m: Moment) => m.format('YYYY-MM-DDTHH:mm:ssZ')

    return {
      from: toDateString(startDate.startOf('day')),
      to: toDateString(endDate.endOf('day')),
    }
  }
}
