import { Tabs, TabsList, TabsTrigger, Theme, useTheme } from '@hub-la/shadcn'
import { Laptop, Moon, Sun } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

export const ModeToggle = () => {
  const { setTheme } = useTheme()
  const { t } = useTranslation()
  const [currentTheme, setCurrentTheme] = useState<Theme>(
    (localStorage.getItem('shadcn-ui-theme') as Theme) || 'system',
  )

  useEffect(() => {
    localStorage.setItem('shadcn-ui-theme', currentTheme)
    setTheme(
      currentTheme === 'system'
        ? window.matchMedia('(prefers-color-scheme: dark)').matches
          ? 'dark'
          : 'light'
        : currentTheme,
    )
  }, [currentTheme])

  return (
    <div className="space-y-2">
      <p className=" font-semibold gap-2">{t('appearance.colorMode')}</p>
      <div className="max-w-[200px] items-center">
        <Tabs value={currentTheme} onValueChange={(value) => setCurrentTheme(value as Theme)}>
          <TabsList className="grid grid-cols-3">
            <TabsTrigger value={'dark'} className="flex flex-row items-center">
              <Moon className="w-4 h-4" />
            </TabsTrigger>

            <TabsTrigger value={'light'} className="flex flex-row items-center">
              <Sun className="w-4 h-4" />
            </TabsTrigger>

            <TabsTrigger value={'system'} className="flex flex-row items-center">
              <Laptop className="w-4 h-4" />
            </TabsTrigger>
          </TabsList>
        </Tabs>
      </div>
      <h4 className="text-sm text-muted-foreground font-medium leading-none">{t(`appearance.${currentTheme}`)}</h4>
    </div>
  )
}
