import { CardsOrTokens } from '../domain/dtos/cards-or-tokens'
import { AvailableCarriers } from '../domain/enums/available-carriers'

/**
 * Represents a class that generates cards or tokens based on the selected carrier.
 */
export class GenerateCardsOrTokens {
  private carrier: AvailableCarriers

  /**
   * Creates an instance of GenerateCardsOrTokens.
   * @param carrier - The selected carrier.
   */
  constructor(carrier: AvailableCarriers) {
    this.carrier = carrier
  }

  /**
   * Executes the generation of cards or tokens based on the selected carrier.
   * @param token - The token to be used for generating cards or tokens.
   * @param customerId - The ID of the customer associated with the card or token.
   * @returns The generated cards or tokens based on the selected carrier.
   */
  public execute = (token = '', customerId = ''): CardsOrTokens => {
    switch (this.carrier) {
      case AvailableCarriers.MULTI:
        return {
          iugu: { type: AvailableCarriers.IUGU, saveForFutureUse: true, oneTimeToken: token },
          yuno: { type: AvailableCarriers.YUNO, saveForFutureUse: true, cardOwnerId: customerId },
        }
      case AvailableCarriers.IUGU:
        return {
          iugu: { type: AvailableCarriers.IUGU, saveForFutureUse: true, oneTimeToken: token },
        }
      case AvailableCarriers.YUNO:
        return {
          yuno: { type: AvailableCarriers.YUNO, saveForFutureUse: true, reusableToken: token, cardOwnerId: customerId },
        }
      default:
        return {}
    }
  }
}
