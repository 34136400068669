import { useTranslation } from 'react-i18next'
import { OfferSection } from '../../../../components/offer-section'
import { PaymentMethodSelect } from '../../checkout/components/payment-method/payment-method-select'
import { Options } from './components/options'
import { PriceConfiguration } from './components/price-configuration'

export const PricingEventContent = () => {
  const { t } = useTranslation()

  return (
    <>
      <OfferSection title={t('offer.pricingTab.pricing.title')}>
        <PriceConfiguration />
      </OfferSection>

      <OfferSection title={t('offer.checkoutTab.paymentMethods.title')}>
        <PaymentMethodSelect />
      </OfferSection>

      <OfferSection title={t('offer.pricingTab.options.title')}>
        <Options />
      </OfferSection>
    </>
  )
}
