import { useTranslation } from 'react-i18next'
import { useCancelRefundRequest } from '../../../hooks/use-cancel-refund-request'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Button,
  toast
} from "@hub-la/shadcn"

type ConfirmCancelRefundRequestModalProps = {
  refundId: string
  open: boolean
  onClose: () => void
  onSuccess?: () => void
}

export const ConfirmCancelRefundRequestModal = ({
  refundId,
  open,
  onClose,
  onSuccess
}: ConfirmCancelRefundRequestModalProps) => {
  const { t } = useTranslation()
  const { mutateAsync: cancelRefundRequest, isLoading } = useCancelRefundRequest()

  const onSubmit = () => {
    cancelRefundRequest({ refundId })
      .then(() => {
        onClose()
        onSuccess?.()
      })
  }

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[525px]">
        <DialogHeader>
          <DialogTitle className="text-2xl font-semibold">
            {t('userRefund.modals.confirmCancel.title')}
          </DialogTitle>
          <DialogDescription>
            {t('userRefund.modals.confirmCancel.description')}
          </DialogDescription>
        </DialogHeader>
        <DialogFooter className="sm:justify-end">
          <div className="flex flex-col-reverse sm:flex-row gap-2 sm:gap-4 w-full sm:w-auto">
            <Button variant="outline" onClick={onClose}>
              {t('userRefund.buttons.cancel')}
            </Button>
            <Button
              variant="default"
              onClick={onSubmit}
              disabled={isLoading}
              loading={isLoading}
            >
              {t('userRefund.buttons.cancelRefundRequest')}
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
