/* tslint:disable */
/* eslint-disable */
/**
 * BFF Web Product
 * API reference used to call the backend from the web frontend
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface BNPLSettingsDto
 */
export interface BNPLSettingsDto {
    /**
     * 
     * @type {number}
     * @memberof BNPLSettingsDto
     */
    'maxInstallments': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof BNPLSettingsDto
     */
    'paymentMethods': Array<BNPLSettingsDtoPaymentMethodsEnum>;
}

/**
    * @export
    * @enum {string}
    */
export enum BNPLSettingsDtoPaymentMethodsEnum {
    CREDIT_CARD = 'credit_card',
    BANK_SLIP = 'bank_slip',
    PIX = 'pix',
    BNPL = 'bnpl'
}


