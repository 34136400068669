export enum QueryKey {
  getOffers = 'getOffers',
  getInvoice = 'getInvoice',
  getInvoices = 'getInvoices',
  getSmartInstallment = 'getSmartInstallment',
  getSmartInstallments = 'getSmartInstallments',
  getInvoicesSummaries = 'getInvoicesSummaries',
  getSmartInstallmentsSummaries = 'getSmartInstallmentsSummaries',
  getSmartInstallmentInvoices = 'getSmartInstallmentInvoices',
  refundInvoice = 'refundInvoice',
  cancelInvoice = 'cancelInvoice',
  cancelSmartInstallment = 'cancelSmartInstallment',
  downloadInvoicesXlsx = 'downloadInvoicesXlsx',
  downloadSmartInstallmentsXlsx = 'downloadSmartInstallmentsXlsx',
}
