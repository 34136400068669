import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@hub-la/shadcn'
import { ErrorCode } from '../../../../domain/dtos/error-code'
import { Envs } from '../../../../envs'

type ErrorStatusProps = {
  errorCode?: ErrorCode
  status?: number
  details?: {
    offerId: string
    product: {
      picture: string
      name: string
      ownerName: string
    }
    lifetime: boolean
    days: number
  }
}

const statusToTitle = {
  0: 'invitePage.title.unspecified',
  2: 'invitePage.title.cancelled',
  4: 'invitePage.title.alreadyAccepted',
}

const statusToDescription = {
  0: 'invitePage.description.unspecified',
  2: 'invitePage.description.cancelled',
  4: 'invitePage.description.alreadyAccepted',
}

export const ErrorStatus: React.FC<ErrorStatusProps> = ({ details, status }) => {
  const { t } = useTranslation()

  const showAccessButton = status && details
  const hasOfferId = details?.offerId
  const accessButtonUrl = hasOfferId
    ? `${Envs.APP_URL}/signin?redirectTo=/g/${details?.offerId}`
    : `${Envs.APP_URL}/signin?redirectTo=/user_groups`

  return (
    <div className="max-w-[588px] mt-8 mx-auto">
      <h3 className="text-2xl font-semibold mb-3">
        {status ? t(statusToTitle[status]) : t('invitePage.title.errorDefault')}
      </h3>
      <p className="text-sm mb-6">
        {status ? t(statusToDescription[status]) : t('invitePage.description.errorDefault')}
      </p>
      {details && (
        <div className="p-4 my-6 border border-gray-300 rounded-md">
          <div className="flex items-center justify-between">
            <div className="flex gap-3">
              {details?.product?.picture && (
                <img src={details?.product?.picture} width={82} height={52} alt="product logo" className="rounded" />
              )}
              <div>
                <h4 className="text-lg font-semibold mb-0.5">{details?.product?.name}</h4>
                <p className="text-xs">{details?.product?.ownerName}</p>
              </div>
            </div>
            <p className="text-xs">
              {details?.lifetime
                ? t('invitePage.inviteLifetime')
                : t('invitePage.inviteFreeTime', {
                    days: details?.days,
                  })}
            </p>
          </div>
          {showAccessButton && (
            <a href={accessButtonUrl} className="block mt-4">
              <Button variant="outline" className="w-full">
                {hasOfferId ? t('invitePage.errorButtons.access') : t('invitePage.errorButtons.userGroups')}
              </Button>
            </a>
          )}
        </div>
      )}
      {!showAccessButton && (
        <a href={accessButtonUrl} className="block mt-4">
          <Button variant="outline" className="w-full">
            {hasOfferId ? t('invitePage.errorButtons.access') : t('invitePage.errorButtons.userGroups')}
          </Button>
        </a>
      )}
    </div>
  )
}

export const InviteError: React.FC<{ error: any }> = ({ error }) => {
  const { t } = useTranslation()

  const userGroupsUrl = `${Envs.APP_URL}/signin?redirectTo=/user_groups`

  return (
    <div className="max-w-[588px] mt-8 mx-auto">
      <h3 className="text-2xl font-semibold mb-3">{t(error.message ?? 'invitePage.title.errorDefault')}</h3>
      <p className="text-sm mb-6">{t(error.message ?? 'invitePage.description.errorDefault')}</p>
      <a href={userGroupsUrl} className="block mt-4">
        <Button variant="outline" className="w-full">
          {t('invitePage.errorButtons.userGroups')}
        </Button>
      </a>
    </div>
  )
}
