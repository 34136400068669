import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'

import { CohortsByProductIdResponseDto } from '@hub-la/sdk-bff-product'
import { useQuery } from '@tanstack/react-query'
import { ListCohortsInput } from '../../../domain/dtos/list-cohorts'
import { QueryKey } from '../../../domain/enums/query-key'
import { ListCohorts } from '../../../usecases/cohorts/get-cohorts-list'

export const useGetCohortsList = (input?: ListCohortsInput) => {
  const container = useContainer()

  return useQuery<CohortsByProductIdResponseDto>(
    [QueryKey.getListCohorts, input?.productId, input?.page, input?.pageSize],
    ({ signal }) => new ListCohorts(container.get(HttpClient)).execute(input, signal),
    {
      enabled: !!input?.productId,
      refetchOnWindowFocus: false,
      retry: false,
    },
  )
}
