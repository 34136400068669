import {
  AutomationSettingsPlanDtoIntervalEnum,
  EditOfferAfterPurchaseSettingsDtoRedirectTypeEnum,
  EditOfferPricingSettingsDtoCurrencyEnum,
  EditOfferRequestDto,
  OrderBumpDtoNameEnum,
  UpsellDtoNameEnum,
} from '@hub-la/sdk-bff-product'
import { OfferSchemaValidationType } from '../../presentation/validations/offer-validation'
import { USER_GROUPS_REDIRECT_URL } from '../constants/after-purchase-redirect'
import { PaymentMethod } from '../enums/payment-method.enum'
import { PriceType } from '../enums/price-type.enum'
import { RedirectType } from '../enums/redirect-type.enum'
import { TimeString } from '../vos/time-string'

export const makeOfferInput = (input: OfferSchemaValidationType): any => {
  // todo: implement USER_GROUPS redirectType on backend
  if (
    input.afterPurchase.redirectType === RedirectType.EXTERNAL_URL &&
    input.afterPurchase.redirectUrl === USER_GROUPS_REDIRECT_URL
  ) {
    input.afterPurchase.redirectType = RedirectType.USER_GROUPS
  }

  return {
    name: input.name,
    cohortId: input.cohortId,
    productId: input.productId,
    pricing: makePricingMapper(input),
    checkout: makeCheckoutMapper(input),
    afterPurchase: makeAfterPurchaseMapper(input),
    upsell:
      Boolean(input?.afterPurchase?.upsell?.offer?.id) && input?.afterPurchase?.redirectType === RedirectType.UPSELL
        ? ({
            type: {
              mediaURL:
                input.afterPurchase.upsell.isYoutubeEmbed && input.afterPurchase.upsell.youtubeEmbedUrl
                  ? input.afterPurchase.upsell.youtubeEmbedUrl
                  : null,
              name: UpsellDtoNameEnum.UPSELL,
            },
            extraOfferId: input?.afterPurchase?.upsell?.offer?.id ?? '',
            isEnabled: true,
            stopFunnel: true,
            id: input?.afterPurchase?.upsell?.id,
          } as any)
        : null,
    orderBump:
      Boolean(input?.checkout?.orderbump?.offers) || Boolean(input?.checkout?.orderbump?.offer)
        ? {
            type: {
              name: OrderBumpDtoNameEnum.ORDER_BUMP,
              extraOffers: input.checkout?.orderbump?.offers?.length ? input.checkout.orderbump.offers : null,
            },
            isEnabled: true,
            stopFunnel: true,
            extraOfferId: input?.checkout?.orderbump?.offer?.id,
            id: input?.checkout?.orderbump?.id,
          }
        : null,
    isAvailableForAffiliates: input?.isAvailableForAffiliates,
    installmentCostsPayer: input?.installmentCostsPayer,
  }
}

const makeCheckoutMapper = (input: OfferSchemaValidationType): EditOfferRequestDto['checkout'] => {
  const priceType = input?.pricing?.priceType
  const isRecurring = priceType === PriceType.RECURRING

  const defaultPlan: any = isRecurring && input?.checkout?.defaultPlan ? input?.checkout?.defaultPlan : undefined

  const hasTimerSeconds = input?.checkout?.timerSeconds && new TimeString(input?.checkout?.timerSeconds).toSeconds() > 0
  const timerSeconds =
    hasTimerSeconds && input?.checkout?.timerSeconds && input?.checkout?.isTimer
      ? new TimeString(input?.checkout?.timerSeconds).toSeconds()
      : null
  // String because of the color picker hex and yup no inferrence this type (we version of yup is not supporting when)
  const timerBgColor = input?.checkout?.timerBgColor ? String(input?.checkout?.timerBgColor) : '#f20000'
  const paymentMethods = isRecurring
    ? input?.checkout?.paymentMethods.filter((paymentMethod) => paymentMethod !== PaymentMethod.BANK_SLIP)
    : input?.checkout?.paymentMethods

  const logoUrl = input?.checkout?.creatorLogoUrl ? input?.checkout?.creatorLogoUrl : null
  const bannerDesktopUrl = input?.checkout?.bannerDesktopUrl ? input?.checkout?.bannerDesktopUrl : null
  const bannerMobileUrl = input?.checkout?.bannerMobileUrl ? input?.checkout?.bannerMobileUrl : null
  const bannerDisplayMode = input?.checkout?.bannerDisplayMode ? input?.checkout?.bannerDisplayMode : null
  const bannerSize = input?.checkout?.bannerSize ? input?.checkout?.bannerSize : null
  const sideBannerMobileUrl = input?.checkout?.sideBannerMobileUrl ? input?.checkout?.sideBannerMobileUrl : null
  const sideBannerDesktopUrl = input?.checkout?.sideBannerDesktopUrl ? input?.checkout?.sideBannerDesktopUrl : null
  const footerBannerMobileUrl = input?.checkout?.footerBannerMobileUrl ? input?.checkout?.footerBannerMobileUrl : null
  const footerBannerDesktopUrl = input?.checkout?.footerBannerDesktopUrl
    ? input?.checkout?.footerBannerDesktopUrl
    : null
  const footerBannerSize = input?.checkout?.footerBannerSize ? input?.checkout?.footerBannerSize : null
  const guaranteeSealMobileUrl = input?.checkout?.guaranteeSealMobileUrl
    ? input?.checkout?.guaranteeSealMobileUrl
    : null
  const guaranteeSealDesktopUrl = input?.checkout?.guaranteeSealDesktopUrl
    ? input?.checkout?.guaranteeSealDesktopUrl
    : null
  const purchaseButtonText = input?.checkout?.purchaseButtonText ? input?.checkout?.purchaseButtonText : null
  const purchaseButtonBgColor = input?.checkout?.purchaseButtonBgColor ? input?.checkout?.purchaseButtonBgColor : null
  const purchaseButtonTextColor = input?.checkout?.purchaseButtonTextColor
    ? input?.checkout?.purchaseButtonTextColor
    : null
  const defaultInstallment = input?.checkout?.defaultInstallment ? input?.checkout?.defaultInstallment : 1
  const termsUrl = input?.checkout?.termsUrl && input?.checkout?.isTermsUrl ? input?.checkout?.termsUrl : null
  const youtubeEmbedUrl =
    input?.checkout?.youtubeEmbedUrl && input?.checkout?.isYoutubeEmbed ? input?.checkout?.youtubeEmbedUrl : null
  const supportUrl =
    input?.checkout?.creatorSupportUrl && input?.checkout?.isCreatorSupport ? input?.checkout?.creatorSupportUrl : null

  return {
    logoUrl,
    bannerDesktopUrl,
    sideBannerMobileUrl,
    bannerMobileUrl,
    bannerDisplayMode,
    bannerSize,
    purchaseButtonText,
    sideBannerDesktopUrl,
    footerBannerMobileUrl,
    footerBannerDesktopUrl,
    footerBannerSize,
    guaranteeSealMobileUrl,
    guaranteeSealDesktopUrl,
    purchaseButtonBgColor,
    purchaseButtonTextColor,
    defaultPlan,
    defaultInstallment: defaultInstallment,
    purchaseExperience: input?.checkout?.purchaseExperience,
    // priceFormat: input?.checkout?.priceFormat,
    lightDarkMode: input?.checkout?.lightDarkMode,
    layoutTemplate: input?.checkout?.checkoutlayoutType,
    bgColor: input?.checkout?.bgColor,
    logoPosition: input?.checkout?.logoPosition,
    paymentMethods,
    bnplSettings: input?.checkout?.bnplSettings,
    showBillingAddress: input?.checkout?.billingAddress,
    showCouponField: input?.checkout?.showCouponField,
    termsUrl: termsUrl,
    timerBgColor,
    timerSeconds,
    youtubeEmbedUrl,
    supportUrl,
  }
}

const makePricingMapper = (input: OfferSchemaValidationType): EditOfferRequestDto['pricing'] => {
  const priceType = input.pricing.priceType
  const isRecurring = priceType === PriceType.RECURRING

  const plans = isRecurring
    ? (input?.pricing?.plans ?? []).map((plan) => ({
        ...plan,
        price: plan?.oldPrice ?? plan?.price,
        oldPrice: plan?.oldPrice ? plan?.price : null,
      }))
    : []

  const automationSettings: EditOfferRequestDto['pricing']['automationSettings'] | null =
    input.pricing.isAutomationSettings && priceType !== PriceType.ONE_TIME
      ? {
          plans: [
            {
              interval: AutomationSettingsPlanDtoIntervalEnum.MONTHLY,
              disableAutoRenewalAfterCycles: input.pricing.automationSettings.disableAutoRenewalAfterCycles,
              creditsToAddOnFinalCycle: input.pricing.automationSettings.creditsToAddOnFinalCycle,
            },
          ],
        }
      : null

  const membershipFee: EditOfferRequestDto['pricing']['membershipFee'] | null =
    input.pricing.isMembershipFee && priceType !== PriceType.ONE_TIME
      ? {
          price: input.pricing.membershipFee.price,
          installments: input.pricing.membershipFee.installments,
        }
      : null

  const smartInstallment: EditOfferRequestDto['pricing']['smartInstallment'] = input.pricing.isSmartInstallment
    ? {
        installments: input.pricing.smartInstallment.installments,
        allowedDebit: input.pricing.smartInstallment.allowedDebit,
      }
    : null

  // Backend zero and null is lifetime
  const accessDays =
    input?.pricing?.accessDays === 'lifetime' || input?.pricing?.accessDays === 0
      ? null
      : parseInt(input?.pricing?.accessDays)

  return {
    currency: EditOfferPricingSettingsDtoCurrencyEnum.BRL,
    accessDays,
    priceType,
    price: isRecurring ? undefined : input?.pricing?.oldPrice ?? input?.pricing?.price,
    oldPrice: input?.pricing?.oldPrice ? input?.pricing?.price : null,
    installments: isRecurring ? undefined : input?.pricing?.installments,
    membershipFee,
    automationSettings,
    smartInstallment,
    plans,
  }
}

const makeAfterPurchaseMapper = (input: OfferSchemaValidationType): EditOfferRequestDto['afterPurchase'] => {
  const upsell =
    Boolean(input?.afterPurchase?.upsell?.offerId) && input?.afterPurchase?.redirectType === RedirectType.UPSELL
      ? {
          offerId: input.afterPurchase.upsell.offerId,
          productId: input.afterPurchase.upsell.productId,
          redirectType: input.afterPurchase.upsell.redirectType,
          youtubeEmbedUrl: input.afterPurchase.upsell.youtubeEmbedUrl,
        }
      : null

  // todo: implement USER_GROUPS redirectType on backend
  if (input?.afterPurchase?.redirectType === RedirectType.USER_GROUPS) {
    return {
      redirectType: EditOfferAfterPurchaseSettingsDtoRedirectTypeEnum.EXTERNAL_URL,
      redirectUrl: USER_GROUPS_REDIRECT_URL,
      upsell: null,
    }
  }

  return {
    redirectType: input?.afterPurchase?.redirectType,
    upsell: upsell,
    redirectUrl: input?.afterPurchase?.redirectUrl,
  }
}
