import { Search } from 'lucide-react'
import React from 'react'

type TableEmptyStateProps = {
  title: string
  subtitle?: string
}

const TableEmptyState: React.FC<TableEmptyStateProps> = ({ title, subtitle }) => {
  return (
    <div className="flex items-center justify-center w-full h-full min-h-[400px]">
      <div className="flex flex-col items-center text-center">
        <div className="w-16 h-16 bg-secondary rounded-full flex items-center justify-center mb-6">
          <Search className="w-8 h-8 text-muted-foreground" />
        </div>
        <h3 className="text-xl font-semibold mb-2">{title}</h3>
        {subtitle && <p className="text-sm text-muted-foreground max-w-[300px]">{subtitle}</p>}
      </div>
    </div>
  )
}

TableEmptyState.displayName = 'TableEmptyState'

export { TableEmptyState }
