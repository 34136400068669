import { TableEmptyState } from '@hub-la/shadcn'
import { motion } from 'framer-motion'
import isEmpty from 'lodash/isEmpty'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useListMemberGroups } from '../../../hooks/use-list-member-groups'
import { Loading } from '../loading'
import { Group } from './group'

export const GroupsList: React.FC = () => {
  const { t } = useTranslation()
  const { data = [], isFetching } = useListMemberGroups()
  const isDataEmpty = isEmpty(data)
  const canRenderEmptyState = !isFetching && isDataEmpty

  if (isFetching && isDataEmpty) {
    return <Loading />
  }

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.3,
      },
    },
  }

  const itemVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        type: 'spring',
        stiffness: 300,
        damping: 24,
      },
    },
  }

  return (
    <div>
      {canRenderEmptyState ? (
        <TableEmptyState title={t('empty')} />
      ) : (
        <motion.div
          className="grid grid-cols-3 md:grid-cols-4 gap-4 md:gap-6"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          {data.map((data) => (
            <motion.div key={data.id} variants={itemVariants}>
              <Group
                id={data.id}
                name={data.name}
                picture={data.pictureUrl}
                type={data?.type}
                eventData={data?.eventData}
              />
            </motion.div>
          ))}
        </motion.div>
      )}
    </div>
  )
}
