import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { Envs } from '../envs'

export class GetTypeFormData {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(responseId: string): Promise<any> {
    const response = await this.httpClient.request({
      method: HttpMethod.POST,
      url: `${Envs.FUNCTIONS_URL}/typeform/getData/pt`,
      body: {
        data: { responseId },
      },
    })

    if (response.statusCode === HttpStatusCode.CREATED || response.statusCode === HttpStatusCode.OK) {
      return response.data
    }

    throw new Error('Falha ao requistar a resposta do typeform')
  }
}
