import { useFormikContext } from 'formik'
import { Trans, useTranslation } from 'react-i18next'
import NumberFormat, { NumberFormatValues } from 'react-number-format'
import { DocumentType } from '../../domain/enums/bank-account'
import { IsStepValid } from '../../usecases/is-step-valid'
import { useField } from '../hooks/use-field'
import { FormSteps, Values } from '../pages/account-verification-modal'
import { FormHeader } from './form-header'

import {
  Alert,
  AlertDescription,
  AlertTitle,
  Button,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@hub-la/shadcn'

import { AlertTriangle } from 'lucide-react'
import { InputWrapper } from './input-wrapper'

type Props = {
  onComplete: () => void
  loading?: boolean
}

export const SelectModality: React.FC<Props> = ({ onComplete, loading }) => {
  const { t } = useTranslation()
  const { hasError, getErrorText, isValid } = useField()
  const { values, handleChange, handleBlur, setFieldValue } = useFormikContext<Values>()

  const isCNPJ = values.identity.document.type === DocumentType.CNPJ
  const isStepValid = new IsStepValid(isValid).execute(FormSteps.COMPANY_DATA)

  const handleCNPJChange = (val: NumberFormatValues) => {
    setFieldValue('identity.document.value', val.value)
  }

  return (
    <div className="flex flex-col space-y-6">
      <FormHeader
        title={t('accountVerification.form.modality.title')}
        subtitle={t('accountVerification.form.modality.subtitle')}
      />
      <Select
        value={values.identity.document.type.toString()}
        onValueChange={(value) => setFieldValue('identity.document.type', Number(value) as DocumentType)}
      >
        <SelectTrigger>
          <SelectValue placeholder={t('accountVerification.form.modality.label')} />
        </SelectTrigger>
        <SelectContent className="z-[6000]">
          <SelectItem value={DocumentType.CPF.toString()}>{t('accountVerification.form.modality.2')}</SelectItem>
          <SelectItem value={DocumentType.CNPJ.toString()}>{t('accountVerification.form.modality.1')}</SelectItem>
        </SelectContent>
      </Select>

      <Alert variant="destructive" className="bg-amber-50 border-amber-200 text-amber-700">
        <AlertTriangle className="h-4 w-4 text-amber-700" aria-hidden="true" />
        <AlertTitle>{t('accountVerification.form.modality.tooltip.title')}</AlertTitle>
        <AlertDescription>
          <Trans
            i18nKey="accountVerification.form.modality.tooltip.subtitle"
            components={[<strong className="font-semibold" />]}
          />
        </AlertDescription>
      </Alert>

      {isCNPJ && (
        <div className="flex flex-col space-y-4">
          <InputWrapper
            id="identity.fullName"
            value={values.identity.fullName}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={t('businessName')}
            error={hasError('identity.fullName')}
            helperText={getErrorText('identity.fullName')}
          />
          <NumberFormat
            error={hasError('identity.document.value')}
            helperText={getErrorText('identity.document.value')}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.identity?.document.value}
            type="tel" // numbers only
            id="identity.document.value"
            placeholder={t('cnpj')}
            format="##.###.###/####-##"
            customInput={InputWrapper}
            onValueChange={handleCNPJChange}
          />
        </div>
      )}

      <div className="self-end">
        <Button
          disabled={!isStepValid && isCNPJ}
          onClick={onComplete}
          className={loading ? 'opacity-50 cursor-not-allowed' : ''}
          loading={loading}
        >
          {t('accountVerification.form.continue')}
        </Button>
      </div>
    </div>
  )
}
