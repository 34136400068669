import { CheckCircle, ExternalLink, MousePointerClick } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { IntegrationProvider } from '../../../domain/dtos/integration-provider'

type Props = {
  provider: IntegrationProvider
}

export const AboutDetail = ({ provider }: Props) => {
  const { t } = useTranslation()

  const features: string[] =
    t(`integrations.${provider}.about.features`, { returnObjects: true, defaultValue: [] }) ?? []

  const permissions: string[] =
    t(`integrations.${provider}.about.permissions`, { returnObjects: true, defaultValue: [] }) ?? []

  const docs: { title: string; link: string }[] =
    t(`integrations.${provider}.about.docs`, { returnObjects: true, defaultValue: [] }) ?? []

  return (
    <div className="space-y-8">
      <div>
        <h2 className="text-lg font-bold mb-2">{t(`aboutTab.sections.about`)}</h2>
        <p className="text-sm font-normal text-muted-foreground">{t(`integrations.${provider}.about.description`)}</p>
      </div>

      {features.length > 0 && (
        <div>
          <h2 className="text-lg font-bold mb-2">{t(`aboutTab.sections.features`)}</h2>
          <ul className="space-y-2">
            {features.map((feature, index) => (
              <li key={index} className="flex items-center gap-2">
                <CheckCircle size={16} className="text-muted-foreground" />
                <span className="text-sm font-normal text-muted-foreground">{feature}</span>
              </li>
            ))}
          </ul>
        </div>
      )}

      {permissions.length > 0 && (
        <div>
          <h2 className="text-lg font-bold mb-2">{t(`aboutTab.sections.permissions`)}</h2>
          <p className="text-sm font-normal text-muted-foreground mb-2">
            {t('aboutTab.sendEvents', {
              provider: t(`integrations.${provider}.title`),
            })}
          </p>

          <ul className="space-y-2">
            {permissions.map((permission, index) => (
              <li key={index} className="flex items-center gap-2">
                <MousePointerClick size={16} className="text-muted-foreground" />
                <span className="text-sm font-normal text-muted-foreground">{permission}</span>
              </li>
            ))}
          </ul>
        </div>
      )}

      {docs.length > 0 && (
        <div>
          <h2 className="text-lg font-bold mb-2">{t(`aboutTab.sections.docs`)}</h2>

          <ul className="space-y-2">
            {docs.map((doc, index) => (
              <li key={index}>
                <a
                  href={doc.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center hover:underline"
                >
                  <span className="mr-2 text-sm font-normal text-muted-foreground">{doc.title}</span>
                  <ExternalLink className="h-4 w-4 text-muted-foreground" />
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}
