import { Button, Dialog, DialogContent, Input, Label, Separator } from '@hub-la/shadcn'
import { useFormik } from 'formik'
import { ArrowRightLeft, ExternalLink } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { IntegrationProvider } from '../../domain/dtos/integration-provider'
import { useCreateIntegration } from '../hooks/use-create-integration'

export type Values = {
  url: string
  apiKey: string
  secretToken: string
}

type ConnectAccountModalProps = {
  isOpen: boolean
  provider: IntegrationProvider
  schema?: string[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validation?: any
  onClose: () => void
  onSubmit: () => void
}

export const ConnectAccountModal = (props: ConnectAccountModalProps) => {
  const { schema, isOpen, provider, validation, onClose, onSubmit } = props
  const { t } = useTranslation()

  const needUrl = schema?.find((value) => value === 'url')
  const needApiKey = schema?.find((value) => value === 'apiKey')
  const needSecretToken = schema?.find((value) => value === 'secretToken')

  const { isLoading, mutateAsync: createIntegration } = useCreateIntegration()

  const formik = useFormik<Values>({
    validationSchema: validation,
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: true,
    initialValues: {
      url: '',
      apiKey: '',
      secretToken: '',
    },
    onSubmit: async (values: Values) => {
      await createIntegration({
        provider,
        auth: {
          url: values?.url,
          apiKey: values?.apiKey,
          secretToken: values?.secretToken,
        },
      })
      onSubmit()
      formik.resetForm()
    },
  })

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="p-6 max-w-lg">
        <div className="flex flex-col space-y-4">
          <div className="flex flex-col space-y-8">
            <div className="flex flex-col justify-center items-center">
              <div className="flex flex-row justify-center items-center pt-12 space-x-4 mb-4">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/chatpay-cd120.appspot.com/o/public%2Fintegrations%2Fhubla.png?alt=media"
                  width={48}
                  height={48}
                  alt={provider}
                  className="w-12 h-12"
                />
                <ArrowRightLeft className="h-4 w-4" />
                <img
                  src={t(`integrations.${provider}.icon`)}
                  width={48}
                  height={48}
                  alt={provider}
                  className="w-12 h-12"
                />
              </div>
              <h4 className="text-lg font-semibold text-center">
                Integrar a Hubla com {t(`integrations.${provider}.title`)}
              </h4>
              <a
                href={`https://hubla.gitbook.io/docs/integracoes/${provider}`}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center underline"
              >
                <span className="mr-2 font-sm">Como integrar com {t(`integrations.${provider}.title`)}</span>
                <ExternalLink className="h-4 w-4 text-muted-foreground" />
              </a>
            </div>

            <Separator className="w-full" orientation="horizontal" />
          </div>

          <p className="text-sm text-center text-muted-foreground">
            {t(`integrations.${provider}.create.description`)}
          </p>
          {!!needUrl && (
            <div className="grid w-full items-center gap-1.5">
              <Label htmlFor="name">API Url</Label>
              <Input
                placeholder="https://example.com"
                id="url"
                disabled={isLoading}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.url}
                className={formik.errors.url && formik.touched.url ? 'border-red-500' : ''}
              />
              {formik.touched.url && formik.errors.url && (
                <p className="text-sm text-destructive mt-1">{t(formik.errors.url)}</p>
              )}
            </div>
          )}

          {!!needApiKey && (
            <div className="grid w-full items-center gap-1.5">
              <Label htmlFor="name">API Key</Label>
              <Input
                placeholder="Adicione sua chave de API"
                id="apiKey"
                disabled={isLoading}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.apiKey}
                className={formik.errors.apiKey && formik.touched.apiKey ? 'border-red-500' : ''}
              />
              {formik.touched.apiKey && formik.errors.apiKey && (
                <p className="text-sm text-destructive mt-1">{t(formik.errors.apiKey)}</p>
              )}
            </div>
          )}

          {!!needSecretToken && (
            <div className="grid w-full items-center gap-1.5">
              <Label htmlFor="name">Chave secreta</Label>
              <Input
                placeholder="Adicione sua chave secreta"
                id="secretToken"
                disabled={isLoading}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.secretToken}
                className={formik.errors.secretToken && formik.touched.secretToken ? 'border-red-500' : ''}
              />
              {formik.touched.secretToken && formik.errors.secretToken && (
                <p className="text-sm text-destructive mt-1">{t(formik.errors.secretToken)}</p>
              )}
            </div>
          )}
        </div>

        <Button
          onClick={() => formik.submitForm()}
          disabled={!formik.isValid || !formik.dirty}
          loading={isLoading}
          className="w-full mt-6"
        >
          {t('buttons.integrate')}
        </Button>
      </DialogContent>
    </Dialog>
  )
}
