import { memo } from 'react'
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params'
import { Filters } from '../../components/filters'
import { useGetOffers } from '../../hooks/use-get-offers'
import { CommaDelimitedArrayParam } from '../../utils/query-string-encoder'
import { CouponsTable } from './coupons-table'

export const DashboardCoupons = memo(() => {
  const { data: offers = [], isFetching } = useGetOffers()

  const [filters, setFilters] = useQueryParams({
    search: withDefault(StringParam, ''),
    offerIds: withDefault(CommaDelimitedArrayParam, []),
    statuses: withDefault(CommaDelimitedArrayParam, []),
    page: withDefault(NumberParam, 1),
    pageSize: withDefault(NumberParam, 25),
  })

  const isFetchingOffers = isFetching && offers.length <= 0

  return (
    <div className="flex flex-col gap-4">
      <h1 className="text-3xl font-semibold leading-tight">Cupons</h1>

      <Filters filters={filters} setFilters={setFilters} offers={offers} isLoading={isFetchingOffers} />

      <CouponsTable filters={filters} setFilters={setFilters} offers={offers} isLoading={isFetchingOffers} />
    </div>
  )
})
