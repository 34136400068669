import {
  CreditCardHolder,
  CreditCardNumber,
  TokenizationError,
  Tokenizer,
  useMultigatewayHandler,
} from '@hub-la/fe-tokenizer'
import { useFormikContext } from 'formik'
import { CardBrand } from '../../domain/enums/card-brand'
import { CardType } from '../../domain/enums/card-type'
import { Values } from '../pages/user-subscription-detail/components/change-payment-method-modal'

const getCardBrand = (brand?: string) => {
  switch (brand) {
    case 'visa':
      return CardBrand.CARD_BRAND_VISA
    case 'mastercard':
      return CardBrand.CARD_BRAND_MASTERCARD
    case 'amex':
      return CardBrand.CARD_BRAND_AMEX
    case 'elo':
      return CardBrand.CARD_BRAND_ELO
    default:
      return CardBrand.CARD_BRAND_OTHER
  }
}

export const useGetCreditCardToken = (offerId: string) => {
  const { values, setFieldValue, setFieldError, setFieldTouched } = useFormikContext<Values>()
  const gateway = useMultigatewayHandler(offerId)

  const mutate = async () => {
    const { creditCard, document, sessionId } = values
    const { cvv, expiration } = creditCard

    if (!cvv || !expiration || !creditCard.number) {
      return
    }

    const number = CreditCardNumber.build(creditCard.number).getValue()
    const holder = CreditCardHolder.build(creditCard.holder).getValue()

    try {
      const token = await new Tokenizer(gateway).tokenize({
        cvv,
        expiration,
        holder,
        number,
        sessionId,
        document,
      })
      setFieldValue('creditCard.token', token.tokenId)
      const cardDetails = {
        expMonth: expiration.split('/')[0],
        expYear: '20' + expiration.split('/')[1],
        holder: creditCard.holder,
        last4: creditCard.number.replace(' ', '').slice(-4),
        brand: getCardBrand(creditCard.type),
        type: CardType.CARD_TYPE_CREDIT,
        bin: creditCard.number.replace(' ', '').slice(0, 6),
      }
      setFieldValue('creditCard.cardDetails', cardDetails)
    } catch (error) {
      const e = new TokenizationError()
      setFieldTouched('creditCard.token', true)
      setFieldError('changePaymentMethodModal.creditCard.errors.token', e.message)
    }
  }

  return {
    mutate,
  }
}
