import { Avatar, AvatarFallback, AvatarImage, Button, Textarea } from '@hub-la/shadcn'
import { Loader2 } from 'lucide-react'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TextNewlineLengthUseCase } from '../../../usecases/text-newline-length'
import avatar from '../../assets/default-user-avatar.png'

type InputCommentProps = {
  fullName?: string
  defaultComment?: string
  avatarUrl?: string | null
  isLoading?: boolean
  inputLabel?: string
  avatarSize?: number
  cancelButtonLabel?: string
  commentButtonLabel?: string
  onComment?: (comment: string, hasMention: boolean) => void
  onCancel?: () => void
  isReply?: boolean
  showAvatar?: boolean
  mentionUserId?: string
  mentionUserName?: string
  commenterName?: string
}

export const InputComment = ({
  fullName,
  showAvatar = true,
  avatarSize = 10,
  defaultComment,
  avatarUrl,
  isLoading,
  inputLabel,
  cancelButtonLabel,
  commentButtonLabel,
  onCancel,
  onComment,
  isReply,
  commenterName,
  mentionUserName,
}: InputCommentProps) => {
  const { t } = useTranslation()
  const [comment, setComment] = useState(defaultComment ?? '')
  const textareaRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    if (mentionUserName && textareaRef.current) {
      const mentionText = `@${mentionUserName} `
      setComment(mentionText)
      textareaRef.current.focus()
      textareaRef.current.setSelectionRange(mentionText.length, mentionText.length)
    }
  }, [mentionUserName])

  const handleCancel = () => {
    setComment('')
    if (onCancel) {
      onCancel()
    }
  }

  const handleComment = () => {
    if (onComment) {
      onComment(comment, comment.includes(`@${mentionUserName}`))
    }
    setComment('')
  }

  const isSubmitButtonOnEditEnabled = defaultComment !== comment && comment.length > 0

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Backspace' && textareaRef.current) {
      const selectionStart = textareaRef.current.selectionStart
      const selectionEnd = textareaRef.current.selectionEnd
      const mentionStart = comment.lastIndexOf(`@${mentionUserName}`, selectionStart)

      if (
        mentionStart !== -1 &&
        selectionStart === selectionEnd &&
        selectionStart === mentionStart + `@${mentionUserName}`.length
      ) {
        event.preventDefault()
        const newComment = comment.slice(0, mentionStart) + comment.slice(selectionStart)
        setComment(newComment)
        setTimeout(() => {
          if (textareaRef.current) {
            textareaRef.current.setSelectionRange(mentionStart, mentionStart)
          }
        }, 0)
      }
    }
  }

  return (
    <div className="flex gap-3 relative pb-4">
      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center">
          <Loader2 className="h-8 w-8 animate-spin" role="status" aria-label="Loading" />
        </div>
      )}
      {showAvatar && (
        <Avatar className={`w-${avatarSize} h-${avatarSize}`}>
          <AvatarImage src={avatarUrl ?? avatar} alt="User avatar" className="object-cover" />
          <AvatarFallback>{commenterName ? commenterName.charAt(0).toUpperCase() : 'U'}</AvatarFallback>
        </Avatar>
      )}
      <div className={`w-full ${isLoading ? 'opacity-0 pointer-events-none' : ''}`}>
        <div className="space-y-2">
          <Textarea
            ref={textareaRef}
            id="comment"
            placeholder={inputLabel ?? t('inputComment.label')}
            value={comment}
            onChange={(e) => {
              const totalLength = new TextNewlineLengthUseCase().execute(e.target.value)
              if (totalLength <= 1050) {
                setComment(e.target.value)
              }
            }}
            onKeyDown={handleKeyDown}
            className="min-h-[36px]"
          />
          <p className="text-sm text-muted-foreground">
            {t('inputComment.charactersRemaining', { count: new TextNewlineLengthUseCase().execute(comment) })}
          </p>
        </div>
        {(comment.length > 0 || isReply) && (
          <div className="mt-4 flex justify-end space-x-2">
            <Button variant="outline" onClick={handleCancel}>
              {cancelButtonLabel ?? t('inputComment.cancel')}
            </Button>
            <Button onClick={handleComment} disabled={!isSubmitButtonOnEditEnabled}>
              {commentButtonLabel ?? t('inputComment.submit')}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}
