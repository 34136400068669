import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation } from '@tanstack/react-query'
import { PutLikeCommentInput } from '../../domain/dtos/put-like-comment-input'
import { QueryKey } from '../../domain/enums/query-keys'
import { PutLikeComment } from '../../usecases/put-like-comment'

export const usePutLikeComment = ({ onSuccess }: { onSuccess?: () => void } = {}) => {
  const container = useContainer()

  /**@todo: invalidate get comments query or update comments items */
  return useMutation(
    [QueryKey.putComment],
    (input: PutLikeCommentInput) => new PutLikeComment(container.get(HttpClient)).execute(input),
    {
      retry: false,
      onSuccess,
    },
  )
}
