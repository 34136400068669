import { HttpClient, HttpMethod, HttpResponse, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { GeneralError } from '../domain/errors/general'
import { Envs } from '../envs'

export class GetSectionProgress {
  public constructor(@inject(HttpClient) private readonly httpClient: HttpClient) {}

  public async execute(sectionId?: string): Promise<number> {
    if (!sectionId) return 0

    const response: HttpResponse<number> = await this.httpClient.request({
      method: HttpMethod.GET,
      url: this.makeUrl(sectionId),
    })

    if (response.statusCode === HttpStatusCode.OK) {
      return this.makeData(response?.data)
    }

    throw new GeneralError()
  }

  private makeUrl(sectionId: string) {
    return `${Envs.BFF_MEMBERS_AREA_URL}/hub/sections/${sectionId}/progress`
  }

  private makeData(progress: number | undefined) {
    if (!progress || progress < 0) {
      return 0
    }

    return progress
  }
}
