import { yupResolver } from '@hookform/resolvers/yup'
import { Badge, Button, Dialog, DialogContent, DialogHeader, DialogTitle, useToast } from '@hub-la/shadcn'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Member } from '../../../../domain/dtos/member'
import { useGetCohortsList } from '../../../hooks/cohorts/use-get-cohorts-list'
import { usePostChangeMembersCohorts } from '../../../hooks/members/use-post-change-members-cohorts'
import {
  changeParticipantCohortSchemaValidation,
  ChangeParticipantCohortSchemaValidationType,
} from '../../../validations/change-participant-cohort'
import { CohortSelect } from './cohorts-selected'

type ChangeParticipantsCohortsModalProps = {
  isOpen: boolean
  productId: string
  membersSelected: Member[]
  onClose: () => void
}

export const ChangeParticipantsCohortsModal = ({
  isOpen,
  productId,
  onClose,
  membersSelected,
}: ChangeParticipantsCohortsModalProps) => {
  const { t } = useTranslation()
  const { toast } = useToast()

  const { data: cohorts, isFetching: isFetchingCohorts } = useGetCohortsList({
    productId,
    page: 1,
    pageSize: 999,
  })

  const isOneParticipantSelected = membersSelected.length === 1

  const { mutate: changeParticipantsCohorts, isLoading } = usePostChangeMembersCohorts({
    onSuccess: (data) => {
      onClose()
      if ((data?.errors ?? []).length > 0) {
        const errorsParticipantsName = membersSelected
          .filter((member) => (data?.errors ?? []).includes(member.id))
          .map((member) => member.name)

        toast({
          description: t(
            isOneParticipantSelected
              ? 'tabs.participants.changeMembersCohortsModal.snackbar.failOne'
              : 'tabs.participants.changeMembersCohortsModal.snackbar.failMany',
            { users: errorsParticipantsName },
          ),
          variant: 'destructive',
        })
      } else if ((data?.successes ?? []).length > 0) {
        toast({
          description: t(
            isOneParticipantSelected
              ? 'tabs.participants.changeMembersCohortsModal.snackbar.successOne'
              : 'tabs.participants.changeMembersCohortsModal.snackbar.successMany',
            { count: data?.successes.length },
          ),
        })
      }
    },
    onError: () => {
      onClose()
      toast({
        description: t('tabs.participants.changeMembersCohortsModal.snackbar.genericError'),
        variant: 'destructive',
      })
    },
  })

  const handleConfirm = ({ cohort }) => {
    changeParticipantsCohorts({
      newCohorts: [cohort],
      selectedMembers: membersSelected,
    })
  }

  const form = useForm<ChangeParticipantCohortSchemaValidationType>({
    defaultValues: {
      cohort: '',
    },
    resolver: yupResolver(changeParticipantCohortSchemaValidation),
    mode: 'onChange',
  })
  const { isValid } = form.formState

  return (
    <Dialog open={isOpen} onOpenChange={() => onClose()}>
      <DialogContent className="sm:max-w-xl">
        <DialogHeader>
          <DialogTitle>{t('tabs.participants.changeMembersCohortsModal.title')}</DialogTitle>
        </DialogHeader>

        <form onSubmit={form.handleSubmit(handleConfirm)} className="space-y-4">
          <p className="text-sm text-muted-foreground">
            {t('tabs.participants.changeMembersCohortsModal.description')}
          </p>

          {membersSelected.length > 1 && (
            <Badge variant="secondary" className="mt-3">
              {t('tabs.participants.changeMembersCohortsModal.membersSelectTag', {
                total: membersSelected.length,
              })}
            </Badge>
          )}

          <Controller
            name="cohort"
            control={form.control}
            render={({ field, fieldState }) => (
              <CohortSelect
                {...field}
                options={cohorts?.items ?? []}
                disabled={isFetchingCohorts}
                error={Boolean(fieldState.error?.type)}
                helperText={
                  fieldState.error?.type &&
                  t(`tabs.participants.changeMembersCohortsModal.fieldErrors.${fieldState.error?.type}`)
                }
              />
            )}
          />

          <div className="mt-8 flex justify-end gap-2">
            <Button variant="outline" onClick={onClose} loading={isLoading}>
              {t('tabs.participants.changeMembersCohortsModal.cancel')}
            </Button>

            <Button type="submit" loading={isLoading} disabled={!isValid}>
              {t('tabs.participants.changeMembersCohortsModal.confirm')}
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  )
}
