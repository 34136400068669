import {
  DialogHeader,
  DialogFooter,
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogTitle,
  Button,
  DialogClose,
  toast,
} from '@hub-la/shadcn'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SmartInstallment } from '../../../../domain/dtos/get-smart-installment-cycle'
import { SmartInstallmentStatus } from '../../../../domain/enums/smart-installment-status'
import { useChargeSmartInstallment } from '../../../hooks/use-charge-smart-installment'
import { useHistory } from 'react-router-dom'
import { SmartInstallmentCard } from '../../../components/smart-installment-card'

export const ChargeSmartInstallmentDialog = ({
  smartInstallments,
  smartInstallmentCycleId,
}: {
  smartInstallments: SmartInstallment[]
  smartInstallmentCycleId: string
}) => {
  const { t } = useTranslation()

  const history = useHistory()

  const smartInstallmentAbleToCharge = smartInstallments.filter(
    ({ status }) => status === SmartInstallmentStatus.STATUS_EXPIRED || status === SmartInstallmentStatus.STATUS_DRAFT,
  )

  const [open, setOpen] = useState(false)

  const [selectedSmartInstallment, setSelectedSmartInstallment] = useState<SmartInstallment | null>(null)

  const { mutate, isLoading } = useChargeSmartInstallment()

  const onSubmit = () => {
    if (!selectedSmartInstallment) {
      return
    }

    if (selectedSmartInstallment?.status === SmartInstallmentStatus.STATUS_EXPIRED) {
      history.push(`/user_invoices/${selectedSmartInstallment.id}`)
      return
    }

    mutate(
      {
        subscriptionId: selectedSmartInstallment.subscriptionId,
        sourceInvoiceId: smartInstallmentCycleId,
        installment: selectedSmartInstallment.installment,
      },
      {
        onSuccess: () => {
          toast({ variant: 'destructive', title: 'O pagamento da sua parcela esta sendo processado' })
          setOpen(false)
        },
      },
    )
  }

  return (
    <Dialog open={open} onOpenChange={() => setOpen((prevState) => !prevState)}>
      <DialogTrigger asChild>
        <Button disabled={!smartInstallmentAbleToCharge.length} className="w-full md:max-w-max">
          {t('chargeSmartInstallmentDialog.cta')}
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[550px]">
        <DialogHeader>
          <DialogTitle>{t('chargeSmartInstallmentDialog.title')}</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4 max-h-[320px] overflow-y-scroll">
          {smartInstallmentAbleToCharge.map((smartInstallment) => (
            <SmartInstallmentCard
              checked={smartInstallment.id === selectedSmartInstallment?.id}
              onCheckedChange={(checked) => {
                return checked ? setSelectedSmartInstallment(smartInstallment) : setSelectedSmartInstallment(null)
              }}
              smartInstallment={smartInstallment}
            />
          ))}
        </div>
        <DialogFooter>
          <DialogClose>
            <Button variant="outline">{t('cancel')}</Button>
          </DialogClose>
          <Button onClick={onSubmit} loading={isLoading} disabled={!selectedSmartInstallment} type="submit">
            {t('chargeSmartInstallmentDialog.actions.submit')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
