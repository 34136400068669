import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { CreateProductInput } from '../domain/dtos/create-product-input'
import { GeneralError } from '../domain/errors/general'
import { Envs } from '../envs'

export class CreateProduct {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(input: CreateProductInput): Promise<string> {
    const response = await this.httpClient.request({
      method: HttpMethod.POST,
      url: `${Envs.BFF_PRODUCT_URL}/products`,
      body: input,
    })

    if ([HttpStatusCode.CREATED, HttpStatusCode.OK].includes(response.statusCode)) {
      return response.data.id
    }

    throw new GeneralError()
  }
}
