import { useContainer } from '@hub-la/fe-container'
import { Analytics } from '@hub-la/fe-core-analytics'
import {
  Events,
  makePostEventData,
  PostResponse,
  PostStatus,
  SectionResponse,
  stopEventPropagation,
  UpdatePostRequest,
  useSavePost,
} from '@hub-la/fe-post'
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  useToast,
} from '@hub-la/shadcn'
import { Clock, Copy, ExternalLink, Image, Link, MoreHorizontal, Send, Trash2 } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IsPostPublishable } from '../../../usecases/is-post-publishable'
import { useDeletePost } from '../../hooks/use-delete-post'
import { EditorCover } from '../editor-cover/editor-cover'
import { EditorReleaseDays } from '../editor-release-days/editor-release-days'
import { ConfirmationModal } from './confirmation-modal'
import { DuplicatePostModal } from './duplicate-modal'
import { MoveSectionMenu } from './move-section-menu'

export interface Post {
  post: PostResponse
  setIsEditCoverOpen: React.Dispatch<React.SetStateAction<boolean>>
  isEditCoverOpen: boolean
  sectionId: string
  sections: SectionResponse[]
  productId: string
  userId: string
}

export const PostMenu: React.FC<Post> = ({
  post,
  productId,
  userId,
  sectionId,
  sections,
  isEditCoverOpen,
  setIsEditCoverOpen,
}) => {
  const { mutateAsync: deletePost } = useDeletePost()
  const savePost = useSavePost()
  const container = useContainer()
  const { toast } = useToast()
  const analytics = container.get(Analytics)

  const [confirmationDeleteModalOpen, setConfirmationDeleteModalOpen] = useState<boolean>(false)
  const [releaseEditorOpen, setReleaseEditorOpen] = useState<boolean>(false)
  const [duplicatePostModalOpen, setDuplicatePostModalOpen] = useState<boolean>(false)

  const { t } = useTranslation()
  const postDetailLink = `/post/${post.id}`

  const handleDeleteAfterConfirmation = () => {
    deletePost(post.id).then(() => {
      analytics.track(
        Events.HUB.REMOVED,
        makePostEventData({
          userId,
          productId,
        }),
      )
    })
  }

  const handleEditCover = () => {
    analytics.track(Events.HUB.CONTENT_COVER.EDIT_CLICKED, {
      userId,
      productId,
      postId: post.id,
    })
    setIsEditCoverOpen(true)
  }

  const handleReleaseDays = (event) => {
    stopEventPropagation(event)
    setReleaseEditorOpen(true)
  }

  const handleDelete = () => {
    setConfirmationDeleteModalOpen(true)
  }

  const handlePublish = async () => {
    const payload: UpdatePostRequest = {
      content: post.content,
      productId,
      id: post.id,
      title: post.title,
      status: PostStatus.PUBLISHED,
    }

    if (post.attachments) {
      payload.attachmentAssetIds = post.attachments.map((attachment) => attachment.id)
    }

    if (post.customCover) {
      payload.customCoverAssetId = post.customCover.id
    }

    if (post.cover) {
      payload.coverAssetId = post.cover.id
    }

    await savePost.mutateAsync({
      id: post.id,
      payload,
    })
  }

  const handleDuplicatePost = (event) => {
    stopEventPropagation(event)
    setDuplicatePostModalOpen(true)
  }

  const copyPostLink = () => {
    const link = `${window.location.origin}/post/${post.id}`
    navigator.clipboard.writeText(link)
    toast({
      title: t('dashboard.menu.linkCopied'),
      variant: 'default',
    })
  }

  const isPostPublishable = new IsPostPublishable().execute(post)

  return (
    <>
      <ConfirmationModal
        open={confirmationDeleteModalOpen}
        onClose={() => setConfirmationDeleteModalOpen(false)}
        onConfirm={handleDeleteAfterConfirmation}
      />

      <EditorReleaseDays
        productId={productId}
        open={releaseEditorOpen}
        onClose={() => setReleaseEditorOpen(false)}
        post={post}
      />

      <EditorCover
        userId={userId}
        open={isEditCoverOpen}
        onClose={() => setIsEditCoverOpen(false)}
        post={post}
        productId={productId}
      />

      <DuplicatePostModal
        open={duplicatePostModalOpen}
        onClose={() => setDuplicatePostModalOpen(false)}
        postId={post.id}
      />

      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" className="h-8 w-8 p-0">
            <MoreHorizontal className="h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>

        <DropdownMenuContent align="end">
          <DropdownMenuItem onSelect={handlePublish} disabled={!isPostPublishable}>
            <Send className="mr-2 h-4 w-4" />

            <span>{t('dashboard.menu.publish')}</span>
          </DropdownMenuItem>

          <DropdownMenuItem onSelect={() => window.open(postDetailLink)}>
            <ExternalLink className="mr-2 h-4 w-4" />

            <span>{t('dashboard.menu.view')}</span>
          </DropdownMenuItem>

          <DropdownMenuItem onSelect={() => copyPostLink()}>
            <Link className="mr-2 h-4 w-4" />

            <span>{t('dashboard.menu.copyLink')}</span>
          </DropdownMenuItem>

          <DropdownMenuItem onSelect={handleEditCover}>
            <Image className="mr-2 h-4 w-4" />

            <span>{t('dashboard.menu.editCover')}</span>
          </DropdownMenuItem>

          <DropdownMenuItem onSelect={handleReleaseDays}>
            <Clock className="mr-2 h-4 w-4" />
            <span>Agendar liberação</span>
          </DropdownMenuItem>

          <DropdownMenuItem onSelect={handleDuplicatePost}>
            <Copy className="mr-2 h-4 w-4" />

            <span>{t('dashboard.menu.duplicateSection')}</span>
          </DropdownMenuItem>

          {sections.length > 1 && (
            <MoveSectionMenu
              sectionId={sectionId}
              postId={post.id}
              productId={productId}
              userId={userId}
              sections={sections}
            />
          )}

          <DropdownMenuItem onSelect={handleDelete}>
            <Trash2 className="mr-2 h-4 w-4" />

            <span>{t('dashboard.menu.remove')}</span>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  )
}
