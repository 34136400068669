import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { GeneralError } from '../../domain/errors/general'
import { BindOrUnbindResourceToProductInput } from '../../domain/dtos/member-area/bind-or-unbind-resource-to-product-input'
import { Envs } from '../../envs'

export class BindOrUnbindResourceToProduct {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(input: BindOrUnbindResourceToProductInput, action: 'bind' | 'unbind'): Promise<undefined> {
    const actionParam = action === 'bind' ? 'bind-resource' : 'unbind-resource'
    const response = await this.httpClient.request({
      method: HttpMethod.POST,
      url: `${Envs.BFF_PRODUCT_URL}/products/${actionParam}/${input.offerId}`,
      body: {
        externalResourceId: input.externalResourceId,
        cohortIds: input.cohortIds,
      },
    })

    const successCondition =
      action === 'bind'
        ? response.statusCode === HttpStatusCode.CREATED
        : response.statusCode === HttpStatusCode.NO_CONTENT
    if (successCondition) {
      return
    }

    throw new GeneralError()
  }
}
