import { useContainer } from '@hub-la/fe-container'
import { Analytics } from '@hub-la/fe-core-analytics'
import { Auth } from '@hub-la/fe-core-auth'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation } from '@tanstack/react-query'
import { QueryKey } from '../../domain/dtos/query-keys'
import { SignUpByEmailInput } from '../../domain/dtos/sign-up-by-email-input'
import { PostSignUpByEmail } from '../../usecases/post-sign-up-by-email'

export const usePostSignUpByEmail = ({ onError }: { onError?: (error: Error) => void }) => {
  const container = useContainer()

  return useMutation(
    [QueryKey.postSignUpByEmail],
    (input: SignUpByEmailInput) =>
      new PostSignUpByEmail(container.get(HttpClient), container.get(Auth), container.get(Analytics)).execute(input),
    {
      retry: false,
      onError: (error: Error) => {
        if (onError) {
          onError(error)
        }
      },
    },
  )
}
