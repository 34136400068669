import { useContainer } from '@hub-la/fe-container'
import { Analytics, AnalyticsEvent } from '@hub-la/fe-core-analytics'
import { Button, Dialog, DialogContent, DialogHeader, DialogTitle } from '@hub-la/shadcn'
import { AlertCircle } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useCheckGroupMember } from '../../hooks/use-check-group-member'

interface IProps {
  groupId: string
}

export const NotGroupMember = ({ groupId }: IProps): JSX.Element => {
  const { t } = useTranslation()
  const history = useHistory()
  const container = useContainer()
  const analytics = container.get(Analytics)
  const { data } = useCheckGroupMember(groupId)

  const isOpen = (): boolean => {
    /* TODO: access/has-access-to-any doesnt work because it validates the groupId, and the groupId is the mainOfferId, but the user has purchased a different offer */
    return false

    //return data !== undefined ? !data : false
  }

  const handleOnClick = () => {
    analytics.track(AnalyticsEvent.VERIFICATION_TELEGRAM.MODAL_NOT_MEMBER.CLICKED)
    history.push('/user_groups')
  }

  return (
    <Dialog open={isOpen()}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle className="flex items-center gap-2">
            <AlertCircle className="h-6 w-6 text-primary-foreground bg-[#F08A71] rounded-full p-1" />
            <span className="text-xl font-bold text-[#1A1C18]">{t(`notGroupMember.title`)}</span>
          </DialogTitle>
        </DialogHeader>
        <div className="flex flex-col gap-4">
          <p className="text-left text-base">{t(`notGroupMember.description`)}</p>
          <Button variant="ghost" onClick={handleOnClick} className="w-full">
            {t(`notGroupMember.button`)}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}
