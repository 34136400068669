import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { ErrorCode } from '../../domain/dtos/error-code'
import { PostAcceptInviteInput } from '../../domain/dtos/post-accept-invite-input'
import { GeneralError } from '../../domain/errors/general'
import { InviteAlreadyPhoneNumber } from '../../domain/errors/invite-already-phone-number'
import { InviteEmailMismatch } from '../../domain/errors/invite-email-mismatch'
import { InviteInvalidType } from '../../domain/errors/invite-invalid-type'
import { InviteNotFound } from '../../domain/errors/invite-not-found'
import { InviteUnsupportedStatus } from '../../domain/errors/invite-unsupported-status'
import { Envs } from '../../envs'

export class PostAcceptInvite {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(input: PostAcceptInviteInput, signal?: AbortSignal): Promise<null> {
    const response = await this.httpClient.request({
      method: HttpMethod.POST,
      url: `${Envs.BFF_MEMBERS_AREA_URL}/invites/${input.inviteId}/accept`,
      signal,
      body: this.makeBody(input),
    })

    if (response.statusCode === HttpStatusCode.OK || response.statusCode === HttpStatusCode.CREATED) {
      return response.data
    }

    switch (response.data.errorCode) {
      case ErrorCode.INVITE_ALREADY_PHONE_NUMBER:
        throw new InviteAlreadyPhoneNumber()
      case ErrorCode.INVITE_EMAIL_MISMATCH:
        throw new InviteEmailMismatch()
      case ErrorCode.INVITE_NOT_FOUND:
        throw new InviteNotFound()
      case ErrorCode.INVITE_INVALID_TYPE:
        throw new InviteInvalidType()
      case ErrorCode.INVITE_UNSUPPORTED_STATUS:
        throw new InviteUnsupportedStatus()
      case ErrorCode.USER_USER_WITH_MULTIPLE_ACCOUNTS:
        throw new InviteAlreadyPhoneNumber()
      default:
        throw new GeneralError()
    }
  }

  private makeBody(input: PostAcceptInviteInput) {
    const body = {
      input: {
        email: input.email,
        fullName: input.fullName,
        phoneNumber: input.phoneNumber,
      },
    }

    return body
  }
}
