import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { PostAddFreeMembersInput } from '../../domain/dtos/post-add-free-members-input'
import { PostAddFreeMembersResponse } from '../../domain/dtos/post-add-free-members-response'
import { GeneralError } from '../../domain/errors/general'
import { Envs } from '../../envs'

export class PostAddFreeMembers {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient<PostAddFreeMembersResponse>,
  ) {}

  public async execute(
    input: PostAddFreeMembersInput,
    signal?: AbortSignal,
  ): Promise<PostAddFreeMembersResponse | null> {
    const response = await this.httpClient.request({
      method: HttpMethod.POST,
      url: `${Envs.BFF_MEMBERS_AREA_URL}/members/create-invites-free-subscription`,
      signal,
      body: this.makeBody(input),
    })

    await this.sleep((input?.selectedItems?.length ?? 1) * 500) // waiting payments backend queue to update list

    if (response.statusCode === HttpStatusCode.OK || response.statusCode === HttpStatusCode.CREATED) {
      return response?.data ?? null
    }

    throw new GeneralError()
  }

  private makeBody(input: PostAddFreeMembersInput) {
    const body = {
      productId: input.productId,
      receiverEmails: input.emails,
      days: input.days,
      lifetime: input.lifetime,
      offerId: input.offerId,
    }

    return body
  }

  private sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }
}
