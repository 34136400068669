import { ErrorCode } from './error-code'
import { InviteType } from './invite-type'
import { UserRef } from './user-ref'

export enum InviteAcceptErrorCode {
  UNSPECIFIED = 'unspecified',
  USER_MISSING_EMAIL = 'user-missing-email',
  USER_MISSING_NAME = 'user-missing-name',
  USER_MISSING_PHONE_NUMBER = 'user-missing-phone-number',
}

export interface InviteAcceptError {
  code: InviteAcceptErrorCode
  description: string
}

export type GetAcceptInviteOutput = {
  errorCode: ErrorCode
  status: number
  details: {
    product: {
      id: string
      name: string
      picture: string
      ownerName: string
    }
    receiverEmail: string
    lifetime: boolean
    user: UserRef
    days: number
    offerId: string
  }
  errors: InviteAcceptError[]
  type: InviteType
}
