import { yupResolver } from '@hookform/resolvers/yup'
import {
  Alert,
  AlertDescription,
  Button,
  DialogDescription,
  DialogTitle,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  Input,
  Sheet,
  SheetContent,
  SheetFooter,
  SheetHeader,
} from '@hub-la/shadcn'
import { Lightbulb } from 'lucide-react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import { EmailsAutocomplete } from '../../components/emails-autocomplete'
import { SelectOffers } from '../../components/select-offers'
import { useAddDailyCreditsByEmailBatch } from '../../hooks/use-add-daily-credits-by-email-batch'
import { useGetOffers } from '../../hooks/use-get-offers'
import {
  AddDailyCreditsByEmailFormValues,
  addDailyCreditsByEmailSchema,
} from '../../validations/add-daily-credits-by-email'

type Props = {
  open: boolean
  onClose: () => void
}

export const AddDailyCreditsByEmailBatchModal = ({ open, onClose }: Props) => {
  const { t } = useTranslation()
  const { data: offers = [], isFetching, isLoading: isLoadingOffers } = useGetOffers()

  const form = useForm<AddDailyCreditsByEmailFormValues>({
    defaultValues: {
      offerIds: [],
      credits: 0,
      emails: [],
    },
    resolver: yupResolver(addDailyCreditsByEmailSchema),
  })

  const { mutate, isLoading: isLoadingCredits } = useAddDailyCreditsByEmailBatch({
    onSuccess: (data) => {
      const failedEmails = data?.failedSubscriptions?.map((subscription) => subscription.email)
      const successCount = data?.successSubscriptions?.length

      if (failedEmails?.length) {
        toast(
          t('addDailyCreditsByEmailModal.toast.emailError', {
            successCount: successCount,
            failedCount: failedEmails.length,
          }),
          {
            description: failedEmails.join(', '),
          },
        )
        onClose()
        form.reset()
        return
      }

      toast(t('addDailyCreditsByEmailModal.toast.success'))
      onClose()
      form.reset()
    },
    onError: () => {
      toast(t('addDailyCreditsByEmailModal.toast.defaultError'))
    },
  })

  const isLoading = isLoadingOffers || isLoadingCredits

  const onSubmit = (value) => {
    mutate(value)
  }

  return (
    <Sheet open={open} onOpenChange={onClose}>
      <SheetContent className="p-6 max-w-lg overflow-y-auto">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <SheetHeader>
              <DialogTitle className="text-lg text-primary font-semibold">
                {t('addDailyCreditsByEmailModal.title')}
              </DialogTitle>
              <DialogDescription className="text-sm text-gray-500">
                {t('addDailyCreditsByEmailModal.description')}
              </DialogDescription>
            </SheetHeader>
            <Alert className="mt-6">
              <Lightbulb className="h-4 w-4" />
              <AlertDescription>{t('addDailyCreditsByEmailModal.alert')}</AlertDescription>
            </Alert>
            <div className="flex flex-col gap-3 mt-3">
              <FormField
                control={form.control}
                name="offerIds"
                render={({ field, fieldState }) => (
                  <FormItem className="flex flex-col">
                    <FormLabel>{t('addDailyCreditsByEmailModal.products')}</FormLabel>

                    <FormControl>
                      <SelectOffers
                        offers={offers}
                        value={field.value}
                        onChange={(value) => field.onChange(value)}
                        isLoading={isFetching}
                        showMultiple
                      />
                    </FormControl>
                    {fieldState.error?.message && (
                      <FormDescription className="text-red-500">{fieldState.error?.message}</FormDescription>
                    )}
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="credits"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel>{t('addDailyCreditsByEmailModal.credits')}</FormLabel>

                    <FormControl>
                      <Input
                        {...field}
                        type="number"
                        min={1}
                        max={365}
                        className="w-full"
                        value={field.value}
                        onChange={(e) => {
                          const value = e.target.value
                          if (value === '' || value === '0') {
                            field.onChange('')
                            return
                          }

                          const parsedValue = parseInt(value.replace(/\D/g, ''), 10)

                          if (parsedValue > 365) {
                            field.onChange(365)
                            return
                          }

                          field.onChange(parsedValue)
                        }}
                      />
                    </FormControl>
                    {fieldState.error?.message && (
                      <FormDescription className="text-red-500">{fieldState.error?.message}</FormDescription>
                    )}
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="emails"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel>{t('addDailyCreditsByEmailModal.emails')}</FormLabel>

                    <FormControl>
                      <EmailsAutocomplete
                        value={field.value}
                        setValue={field.onChange}
                        error={Boolean(fieldState.error?.message)}
                        helperText={fieldState.error?.message}
                      />
                    </FormControl>

                    <FormDescription>{t('addDailyCreditsByEmailModal.emailsHelperText')}</FormDescription>
                  </FormItem>
                )}
              />
            </div>

            <SheetFooter className="sm:justify-end gap-2 mt-6">
              <Button variant="outline" onClick={onClose}>
                {t('addDailyCreditsByEmailModal.cancel')}
              </Button>
              <Button
                variant="default"
                onClick={() => form.handleSubmit(onSubmit)}
                disabled={!form.formState.isValid || form.formState.isSubmitting || isLoading}
              >
                {t('addDailyCreditsByEmailModal.submit')}
              </Button>
            </SheetFooter>
          </form>
        </Form>
      </SheetContent>
    </Sheet>
  )
}
