import { Button, Skeleton } from '@hub-la/shadcn'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const Loading: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col gap-1">
        <div className="flex justify-between items-center">
          <h2 className="text-2xl font-semibold">{t('changePaymentMethodModal.title')}</h2>
        </div>
        <p className="text-muted-foreground">{t('changePaymentMethodModal.subtitle')}</p>
      </div>

      <div className="flex gap-1">
        <Skeleton className="w-40 h-14" />
        <Skeleton className="w-16 h-14" />
      </div>

      <div className="flex flex-col sm:flex-row gap-3">
        <Button variant="outline" onClick={onClose} className="flex-1">
          {t('changePaymentMethodModal.cancel')}
        </Button>
        <Button disabled className="flex-1">
          {t('changePaymentMethodModal.save')}
        </Button>
      </div>
    </div>
  )
}
