import { HttpClient, HttpMethod, HttpStatusCode } from '@hub-la/fe-core-http-client'
import { inject } from 'inversify'
import { GroupResource } from '../../domain/dtos/groups'
import { GeneralError } from '../../domain/errors/general'
import { Envs } from '../../envs'

export class GetResources {
  public constructor(
    @inject(HttpClient)
    private readonly httpClient: HttpClient,
  ) {}

  public async execute(offerId?: string, hasProductAssociation?: boolean): Promise<GroupResource[]> {
    const response = await this.httpClient.request({
      method: HttpMethod.GET,
      url: `${Envs.BFF_PRODUCT_URL}/resources/get-resources-by-filters/${offerId}`,
      params: { hasProductAssociation: hasProductAssociation },
    })

    if ([HttpStatusCode.OK, HttpStatusCode.CREATED].includes(response.statusCode)) {
      return (Array.isArray(response.data) && response.data.length ? response.data : []) as GroupResource[]
    }

    throw new GeneralError()
  }
}
