import { Ticket } from '@hub-la/fe-event'
import { Button, Card, CardContent, Separator, useToast } from '@hub-la/shadcn'
import { ArrowLeft } from 'lucide-react'
import 'moment/locale/pt-br'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import { FormatAddress } from '../../../domain/vos/format-address'
import { FormatDate } from '../../../domain/vos/format-date'
import PlaceholderImage from '../../assets/placeholder-image.svg'
import { AppHeader } from '../../components/app-header'
import { useGetPayerProduct } from '../../hooks/use-get-payer-product'
import { Loading } from './loading'

const EventDetails: React.FC = () => {
  const { t } = useTranslation()
  const { toast } = useToast()
  const history = useHistory()
  const { productId } = useParams<{ productId: string }>()
  const { data, isLoading, isFetched, isError } = useGetPayerProduct({
    productId,
    onError: (e: any) => {
      toast({
        title:
          t('error', {
            code: e?.code,
          }) ?? '',
        variant: 'destructive',
      })
      history.push('/user_groups')
    },
  })

  const eventData = data?.payerTickets && data?.payerTickets[0] ? data?.payerTickets[0].eventData : null

  const startDate = eventData?.endDate ? new FormatDate(eventData?.startDate).getValue() : null
  const startHours =
    eventData?.endDate && eventData?.endDate[0] ? new FormatDate(eventData?.startDate).getHours() : null
  const endDate = eventData?.endDate ? new FormatDate(eventData?.endDate).getValue() : null
  const endHours = eventData?.endDate ? new FormatDate(eventData?.endDate).getHours() : null
  const isSameDate = startDate === endDate

  if (isLoading || isError) {
    return <Loading />
  }

  // Redirect to members area if the product is not an event
  if ((data?.type && data?.type !== 'event') || (!data && isFetched)) {
    return <Redirect to={`/g/${productId}`} />
  }

  return (
    <div className="flex flex-col container gap-4">
      <AppHeader />
      <div className="container mx-auto px-4 py-8 max-w-4xl">
        <div className="mb-8">
          <Button variant="ghost" onClick={() => history.push('/user_groups')} className="flex items-center">
            <ArrowLeft className="mr-2" />
            {t('back')}
          </Button>
        </div>

        <div className="space-y-6">
          <Card>
            <CardContent className="p-6">
              <div className="flex items-center space-x-4">
                <img
                  src={data?.pictureUrl ?? PlaceholderImage}
                  alt="Event"
                  className="w-24 h-16 object-cover rounded-lg"
                />
                <div>
                  <h2 className="text-2xl font-bold">{data?.name}</h2>
                  <p className="text-muted-foreground">{data?.id}</p>
                </div>
              </div>
              {Boolean(eventData?.location || startDate || endDate) && <Separator className="my-4" />}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {startDate && endDate && (
                  <div>
                    <p className="font-semibold">
                      {startDate}
                      {!isSameDate && ` – ${endDate}`}
                    </p>
                    <p className="text-muted-foreground">
                      {startHours} – {endHours} GMT-3
                    </p>
                  </div>
                )}
                {eventData?.location && (
                  <div>
                    <p className="font-semibold">{eventData.location.name}</p>
                    <p className="text-muted-foreground">{new FormatAddress(eventData.location).format()}</p>
                  </div>
                )}
              </div>
            </CardContent>
          </Card>

          <Card>
            <CardContent className="p-6">
              <div className="space-y-6  flex justify-center">
                {(data?.payerTickets ?? []).map((ticket) => (
                  <Ticket
                    key={ticket.userDocument}
                    eventName={ticket.eventName}
                    name={ticket.ticketName}
                    userName={ticket.userName}
                    variant="big"
                    bgColor={ticket.primaryColor}
                    secondaryColor={ticket?.secondaryColor ?? '#0000001A'}
                    startDate={ticket.eventData.startDate ? new Date(ticket.eventData.startDate) : undefined}
                    endDate={ticket.eventData.endDate ? new Date(ticket.eventData.endDate) : undefined}
                    eventLogo={data?.pictureUrl}
                    textColor={ticket.textColor}
                    location={
                      ticket.eventData.location ? new FormatAddress(ticket.eventData.location).format() : undefined
                    }
                    code={ticket?.code}
                  />
                ))}
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  )
}

export { EventDetails }
