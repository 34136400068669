/* tslint:disable */
/* eslint-disable */
/**
 * BFF Web Product
 * API reference used to call the backend from the web frontend
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { EventDataDto } from './event-data-dto';

/**
 * 
 * @export
 * @interface ProductSettingsInfoResponseDto
 */
export interface ProductSettingsInfoResponseDto {
    /**
     * 
     * @type {string}
     * @memberof ProductSettingsInfoResponseDto
     */
    'picture'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductSettingsInfoResponseDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProductSettingsInfoResponseDto
     */
    'category': ProductSettingsInfoResponseDtoCategoryEnum;
    /**
     * 
     * @type {string}
     * @memberof ProductSettingsInfoResponseDto
     */
    'description'?: string;
    /**
     * 
     * @type {EventDataDto}
     * @memberof ProductSettingsInfoResponseDto
     */
    'eventData'?: EventDataDto | null;
}

/**
    * @export
    * @enum {string}
    */
export enum ProductSettingsInfoResponseDtoCategoryEnum {
    HOME_AND_CONSTRUCTION = 'homeAndConstruction',
    PRIVATECLUB = 'privateclub',
    CULINARY_AND_GASTRONOMY = 'culinaryAndGastronomy',
    DESIGN = 'design',
    PERSONAL_DEVELOPMENT = 'personalDevelopment',
    LAW = 'law',
    EDUCATION = 'education',
    INVESTMENTS_AND_FINANCIAL_EDUCATION = 'investmentsAndFinancialEducation',
    PROFESSIONAL_EDUCATION = 'professionalEducation',
    ENTREPRENEURSHIP = 'entrepreneurship',
    ENTERTAINMENT = 'entertainment',
    SPIRITUALITY = 'spirituality',
    GAMES = 'games',
    HOBBIES = 'hobbies',
    LANGUAGES = 'languages',
    DIGITAL_MARKETING = 'digitalMarketing',
    MILES_AND_TRIPS = 'milesAndTrips',
    FASHION_AND_BEAUTY = 'fashionAndBeauty',
    MUSIC_AND_ARTS = 'musicAndArts',
    NEWS = 'news',
    PROMOTIONS_AND_CASHBACK = 'promotionsAndCashback',
    RELATIONSHIP = 'relationship',
    SIGNAL_AND_TRADING_ROOMS = 'signalAndTradingRooms',
    HEALTH_AND_FITNESS = 'healthAndFitness',
    WELLNESS = 'wellness',
    INVESTMENTS = 'investments',
    LITERATURE = 'literature',
    PERSONAL_GROWTH = 'personalGrowth',
    GASTRONOMY = 'gastronomy',
    OTHER = 'other'
}


