import { Section } from '../domain/dtos'

type CheckSectionMustBeExpandedInput = {
  postId: string
  sections: Section[]
}

export class CheckSectionMustBeExpanded {
  execute({ postId, sections }: CheckSectionMustBeExpandedInput): string {
    let id = ''

    sections.forEach((section) => {
      const post = section.posts.some((post) => post.id === postId)

      if (post) {
        id = section.id
      }
    })

    return id
  }
}
