import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useQuery } from '@tanstack/react-query'
import { GetSectionsInput } from '../../domain/dtos/get-sections.dto'
import { Section } from '../../domain/dtos/section.dto'
import { GetSections } from '../../usecases/get-sections'

export const useGetSections = (input: GetSectionsInput) => {
  const container = useContainer()

  return useQuery<Section[], Error, Section[]>(
    ['get-sections'],
    () => new GetSections(container.get(HttpClient)).execute(input),
    {
      retryOnMount: false,
      refetchOnWindowFocus: false,
      initialData: [],
      retry: false,
      enabled: !!input.productId,
    }
  );
}
