import { cn } from '@hub-la/shadcn'
import { Loader2 } from 'lucide-react'
import React from 'react'
import { Document, Page, pdfjs } from 'react-pdf'

// Make sure to set the worker source for pdf.js
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

export interface Props {
  url?: string
  height: number | string
  width: number | string
  className?: string
}

export const Pdf: React.FC<Props> = ({ url, height, width, className }) => {
  return (
    <div className={cn('flex items-center justify-center', className)} style={{ height, width }}>
      <Document
        loading={<Loader2 className="h-8 w-8 animate-spin" />}
        file={url}
        className="flex items-center justify-center h-full w-full"
      >
        <Page
          key={'page_1'}
          width={width}
          height={height}
          pageNumber={1}
          renderAnnotationLayer={false}
          className="document-pdf-page"
        />
      </Document>
    </div>
  )
}
