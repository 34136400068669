/* tslint:disable */
/* eslint-disable */
/**
 * BFF Web Product
 * API reference used to call the backend from the web frontend
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { EventDataDto } from './event-data-dto';

/**
 * 
 * @export
 * @interface ProductDto
 */
export interface ProductDto {
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'picture'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'status': ProductDtoStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ProductDto
     */
    'isVisible': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'mainOfferId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'type': ProductDtoTypeEnum;
    /**
     * 
     * @type {EventDataDto}
     * @memberof ProductDto
     */
    'eventData'?: EventDataDto;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'ownerId'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ProductDtoStatusEnum {
    IS_DRAFT = 'isDraft',
    IS_PUBLISHED = 'isPublished',
    IS_SELLING = 'isSelling',
    IS_WAITLISTED = 'isWaitlisted',
    IS_NOT_ENABLED = 'isNotEnabled',
    IS_NOT_SELLING = 'isNotSelling',
    IS_UNAVAILABLE = 'isUnavailable',
    IS_DELETED = 'isDeleted'
}
/**
    * @export
    * @enum {string}
    */
export enum ProductDtoTypeEnum {
    DIGITAL = 'digital',
    EVENT = 'event'
}


