/* tslint:disable */
/* eslint-disable */
/**
 * BFF Web Product
 * API reference used to call the backend from the web frontend
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface OneTimePaymentPlanDto
 */
export interface OneTimePaymentPlanDto {
    /**
     * Is charged only once.
     * @type {string}
     * @memberof OneTimePaymentPlanDto
     */
    'name': OneTimePaymentPlanDtoNameEnum;
    /**
     * Price the extra Offer will be sold by. Should override that Offer\'s original price.
     * @type {number}
     * @memberof OneTimePaymentPlanDto
     */
    'priceInCents': number;
    /**
     * Max number of installments checkout will show for the extra Offer.
     * @type {object}
     * @memberof OneTimePaymentPlanDto
     */
    'maxInstallments': object;
}

/**
    * @export
    * @enum {string}
    */
export enum OneTimePaymentPlanDtoNameEnum {
    ONE_TIME = 'ONE_TIME'
}


