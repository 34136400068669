import { container } from '@hub-la/fe-container'
import { UserInvoicesPage, UserInvoicesProvider } from '@hub-la/fe-user-invoices'
import { withRoleplayRedirect } from '../components/roleplay-redirect'

export const UserInvoicesInit = withRoleplayRedirect(() => {
  return (
    <UserInvoicesProvider container={container}>
      <UserInvoicesPage />
    </UserInvoicesProvider>
  )
})
