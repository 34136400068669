import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useMutation } from '@tanstack/react-query'
import { QueryKey } from '../../domain/dtos/query-keys'
import { SignOutRoleplayInput } from '../../domain/dtos/signout-roleplay-input'
import { SignoutRoleplay } from '../../usecases/signout-roleplay'

export const useSignOutRoleplay = (input?: SignOutRoleplayInput) => {
  const container = useContainer()

  return useMutation([QueryKey.postSignOutRoleplay], () => new SignoutRoleplay(container.get(HttpClient)).execute(), {
    retry: false,
    onSuccess: input?.onSuccess,
  })
}
