import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '../../domain/enums/query-key'
import { RefundStatus } from '../../domain/enums/refund-status'
import { GetRefunds } from '../../usecases/get-refunds'

export const useGetRefunds = ({
  page,
  pageSize,
  status,
}: {
  page: number
  pageSize: number
  status?: RefundStatus
}) => {
  const container = useContainer()

  return useQuery(
    [QueryKey.getRefunds, page, pageSize, status],
    ({ signal }) => new GetRefunds(container.get(HttpClient)).execute({ page, pageSize, status }, signal),
    {
      retry: 3,
      retryOnMount: false,
      refetchOnWindowFocus: false,
      initialData: {
        page: 1,
        pageSize: 0,
        items: [],
        total: 0,
        lastPage: 0,
      },
    },
  )
}
