import { useContainer } from '@hub-la/fe-container'
import { HttpClient } from '@hub-la/fe-core-http-client'
import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '../../domain/enums/query-key'
import { GetSubscriptionValue } from '../../usecases/get-subscription-value'

export const useGetSubscriptionValue = (id: string) => {
  const container = useContainer()

  return useQuery(
    [QueryKey.getSubscriptionValue, id],
    () => new GetSubscriptionValue(container.get(HttpClient)).execute(id),
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!id,
    },
  )
}
